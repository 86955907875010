import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  backgroundresumetitle: {
    backgroundColor: '#ededed',
    fontSize: 8,
    justifyContent: 'center',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    paddingTop: 3,
    paddingBottom: 3,
    width: '100%'
  },
  imageicon: {
    backgroundColor: '#F49A28',
    borderRadius: 50,
    width: 30,
    height: 30,
    marginLeft: 220,
    flexDirection: 'row',
    position: 'absolute',
    padding: 3,
  },
})