/** @format */

const destiny = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAABNCAYAAADjCemwAAAACXBIWX
MAACxKAAAsSgF3enRNAAAEsUlEQVR4nO1c7XHbMAxFe/0fbRBvYG1Qb1BvUI/gTFB3A2UDdwNngq
oTVJ6gzgbyBOwpB7UsCsYkCFLMHd8d7yJH4scjQAIgpHfGGKgIw/vKVzgqaQJU0gSopAlQSROgki
ZAJU2ASpoAlTQBPizcfgMAWwDYAMAKAD467rsCwIClxzJm7usfLOVG7bC4SPLBEwAcAeCUr9uIib
SMZWeMuRhdXLDebOPIJWmT+nUAsHb8/xlV7oIqaKvepMItlqmeO0cdP1B6Lwn6/y8yzMzBIVeThH
TGmDawvhafG5k6xxxSl7LyxhhzSjiwBieEI+/4FkmbBjQwg+nwfzkmJxlxqUijhE3SsE05+8aYfS
7iUnT+yBAWum5Jy4ZR10PppO0WJGwuLUPcplTSVkxncxPmmryL5lqq6Xt2xIZ6QJvLF7MtN9lrBs
uI1x3aab6YPIVH6957ANirjVSJ/Q2Z2T7wWV8voQ+UXrveEbWhGPWkW75v5zpPsij2wslU2RQ03K
gpOvHLuv6G7swtTCr0mdxzRXW01XpSy09MXY+eKtdbgYErumVxUGCe2kc+Usa5VodXFusVY8oYT9
tvK3gmuXrahuzgcf+KDGIMMAk4k8ZnV7R39WiDN5awRrDWUIkJnXkq2T7rlN3mZWnS6EJ7a2ejJE
tnvSfSFiqhUbtorJ22Ide37LItue6E7R6tv+88bLieXK+E7b5A07g9e9xjd/Y50Pi1QUPct0ijgc
kQQ/k/xJJmN+5z0GHfHxNhHZH0GT6SY98fZXbEkhbaeLyN9Behp1FqYfDc55722hJLoH3ekPU4L5
a00NmzB7eOWJDphuKzNkYt/jY0SfORHLqA+7hbHOznrszuyOHe+i1KMmNJo5JzCxc8apvxRbCTbY
gv6nNYTKVMumu/IJY02ji12zgcyG/HgPWtZUii9XGg/YraFGJJo2rhQ1qPKQUz1jiIW8/u8Vk70P
nVkwC77ufonTTWDyMujY/DDq8c8Q3oW26ssncEKU8BfSzKYQfGgfaNrLqI88ExIOZP/c4iQkM01B
M6k660BQ5jQNSW0wQf5z4LaUDC3b4xLkr84RXJm9U2tN6W1NOVRJp2LL7FOmOPAKVnF1lIAyIlEm
nTLnQi1VIUNDuarJPC0pP+qEiZNmlcR1XTAQIK3dFVJ1A7E3Ky2H9a1+fYgJ8ADRqvsxF8RTdKLR
KiHRoaSDrAOiKkLcWJeA0H9dBRAtVoGAs+l5pStQxJj1hMPWdMvt5361pdRRjQpeGKv6knLqeK3P
ZETe88Y15SNEz9+2SZ3onVhVr4qcyQXO28lNSkNRnSOemJ/ZDasE5NGjD+n1HM9efye9WM2CVJAy
Y8o0Ec3Smz5ffmIk2bOK6u1Cn3i5AGjhyzUOJSSG3RpMUSx71gkZWwpUiTEqchpW+aNBcJLnOkGM
KWJg0c2d22Yeo6fFmMsBJIA8fCPjjeL8jxYtrNUsqnwHbMGy8UVwwERKUUaKCk76e1zAn6jDNmCq
V/1doDJX2XY8DwEU1DfUIJK4IwKPBjJiNK3BxWekAJW+wbHBxK/rxhUxpZM+o3IQWo3xoSoJImQC
VNgEqaAJU0ASppAlTSBKikhQIAfgMC+8VaRIcHuAAAAABJRU5ErkJggg==`;

export default destiny;
