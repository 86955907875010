import { Theme, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {},
  overlay: {
    width: '60%'
  },
  image: {
    width: 200
  },
  mainTitle: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  hintText: {
    fontFamily: 'Roboto',
    color: '#AFAFAF'
  },
  infoContainer:{
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    padding: 20,
    width: '100%',
  },
  info:{
    maxWidth: '40%', 
    padding: 24, 
    margin: 20
  },
  subtitle:{
    fontWeight: 'bold', 
    color: '#767676'
  }, 
  text:{
    color: '#AFAFAF', 
    fontSize: 16, 
    margin: '12px 0px'
  }
}))