import React from 'react';

import { Grid, Box, Chip } from '@material-ui/core';

import ContentCard from 'components/ContentCard';
import MainTitle from 'components/MainTitle';
import QR from 'static/img/qrexample.png';
import ItemListData from 'components/ItemListData';
import AddressPreview from 'components/AddressPreview';
import documentTypeById from 'lib/helpers/documentTypeById';
import positionsById from 'lib/helpers/positionById';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import useStyles from './styles';

const Detail = ({ data }: any) => {
  const classes = useStyles();
  const role = useSelector((state: any) => state.user.selectedUserType.role);
  const type = useSelector((state: any) => state.user.selectedUserType.name);
  const entity = useSelector((state: any) => state.user.user.typeEntity);

  return (
    <>
      {entity === 'person' ? (
        <Grid container xs={12}>
          <Grid item container xs={12} xl={6} justifyContent="center">
            <ContentCard className={classes.containerLeft}>
              <Box display="flex" justifyContent="space-between">
                <MainTitle>Datos del Usuario</MainTitle>
                <Chip
                  size="medium"
                  color="primary"
                  variant="outlined"
                  label={`${type} - ${role}`}
                />
              </Box>
              <Box marginY={2}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={8}>
                    <ItemListData
                      data={[
                        {
                          title: 'Usuario',
                          data: data?.username,
                        },
                        {
                          title: 'Correo Electrónico',
                          data: data?.email,
                        },
                        {
                          title: 'Fecha de Registro',
                          data: dayjs(data?.fechaRegistroUsuario).format(
                            'DD/MM/YYYY HH:mm:ss'
                          ),
                        },
                        {
                          title: 'Fecha Última Actualización',
                          data: dayjs(data?.fechaRegistroUsuario).format(
                            'DD/MM/YYYY HH:mm:ss'
                          ),
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="flex-end">
                      <img src={QR} width="150px" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <MainTitle>Datos de la Persona</MainTitle>
              <Box marginY={2}>
                <Grid container xs={12} spacing={2}>
                  <Box width="100%">
                    <ItemListData
                      data={[
                        {
                          title: 'Nombre',
                          data: `${data?.primerNombre} ${data?.segundoNombre} ${data?.primerApellido} ${data?.segundoApellido}`,
                        },
                        {
                          title: 'Documento de Identidad',
                          data: `${documentTypeById(
                            data?.idTipoDocumento,
                            1
                          )} ${data?.numeroDocumento}`,
                        },

                        {
                          title: 'Correo Electrónico',
                          data: data?.email,
                        },
                      ]}
                    />
                  </Box>
                </Grid>
              </Box>
            </ContentCard>
          </Grid>
          <Grid item container xs={12} xl={6} justifyContent="center">
            <ContentCard className={classes.containerRight}>
              <MainTitle>Datos de Contacto</MainTitle>
              <Box marginY={2}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <ItemListData
                      data={[
                        {
                          title: 'Celular',
                          data: data?.celular
                            ? `(${data?.indicativoCelular}) ${data?.celular}`
                            : 'N.D',
                        },
                        {
                          title: 'Télefono',
                          data: data?.telefono
                            ? `(+${data?.indicativoTelefono}) ${data?.telefono}`
                            : 'N.D',
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ItemListData
                      data={[
                        {
                          title: 'WhatsApp',
                          data: data?.whatsapp
                            ? `(${data?.indicativoWhatsapp}) ${data?.whatsapp}`
                            : 'N.D',
                        },
                        {
                          title: 'Telegram',
                          data: data?.telegram
                            ? `(${data?.indicativoTelegram}) ${data?.telegram}`
                            : 'N.D',
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
              <MainTitle>
                Datos de Ubicación - Dirección Geolocalizada
              </MainTitle>
              <Box
                marginY={1}
                minHeight="200px"
                display="flex"
                alignItems="center"
              >
                <Grid container xs={12} spacing={2}>
                  <AddressPreview
                    directionType={data?.idTipoDireccion}
                    country={data?.pais}
                    department={data?.departamento}
                    city={data?.ciudad}
                    roadType={data?.tipoVia}
                    numberVia={data?.numeroVia}
                    viaAppend={data?.apendiceVia}
                    crossingAppend={data?.apendiceCruce}
                    numberCrossing={data?.numeroCruce}
                    cornerMeters={data?.metrosEsquina}
                    additional={data?.adicional}
                    postalCode={data?.codigoPostal}
                  />
                </Grid>
              </Box>
            </ContentCard>
          </Grid>
        </Grid>
      ) : (
        <Grid container xs={12} justifyContent="center">
          <Grid item container xs={12} xl={6} justifyContent="center">
            <ContentCard className={classes.containerLeft}>
              <Box display="flex" justifyContent="space-between">
                <MainTitle>Datos del Usuario</MainTitle>
                <Chip
                  size="medium"
                  color="primary"
                  variant="outlined"
                  label={`${type} - ${role}`}
                />
              </Box>
              <Box marginTop={2}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={8}>
                    <ItemListData
                      data={[
                        {
                          title: 'Usuario',
                          data: data?.username,
                        },
                        {
                          title: 'Correo Electrónico',
                          data: data?.email,
                        },
                        {
                          title: 'Fecha de Registro',
                          data: dayjs(data?.fechaRegistroUsuario).format(
                            'DD/MM/YYYY HH:mm:ss'
                          ),
                        },
                        {
                          title: 'Fecha Última Actualización',
                          data: dayjs(data?.fechaRegistroUsuario).format(
                            'DD/MM/YYYY HH:mm:ss'
                          ),
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="flex-end">
                      <img src={QR} width="150px" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </ContentCard>
          </Grid>
          <Grid item container xs={12} xl={6} justifyContent="center">
            <ContentCard className={classes.containerRight}>
              <MainTitle>Datos de la empresa</MainTitle>
              <Box marginTop={2}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <ItemListData
                      data={[
                        {
                          title: 'NIT',
                          data: `${data?.numeroDocumento}-${data?.digitoVerificacion}`,
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ItemListData
                      data={[
                        {
                          title: 'Razón Social',
                          data: data?.razonSocial,
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
                <MainTitle>Datos de Contacto</MainTitle>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <ItemListData
                      data={[
                        {
                          title: 'Celular',
                          data: data?.celular
                            ? `(${data?.indicativoCelular}) ${data?.celular}`
                            : 'N.D',
                        },
                        {
                          title: 'Télefono',
                          data: data?.telefono
                            ? `(+${data?.indicativoTelefono}) ${
                                data?.telefono
                              } Ext.(${data?.extension ?? ''})`
                            : 'N.D',
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ItemListData
                      data={[
                        {
                          title: 'WhatsApp',
                          data: data?.whatsapp
                            ? `(${data?.indicativoWhatsapp}) ${data?.whatsapp}`
                            : 'N.D',
                        },
                        {
                          title: 'Telegram',
                          data: data?.telegram
                            ? `(${data?.indicativoTelegram}) ${data?.telegram}`
                            : 'N.D',
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
            </ContentCard>
          </Grid>

          <Grid item container xs={12} xl={6} justifyContent="center">
            <ContentCard className={classes.containerLeft}>
              <MainTitle>Datos del Representante Legal</MainTitle>
              <Box marginTop={2}>
                <ItemListData
                  data={[
                    {
                      title: 'Nombre',
                      data: `${data?.primerNombreRL} ${data?.segundoNombreRL} ${data?.primerApellidoRL} ${data?.segundoApellidoRL}`,
                    },
                    {
                      title: 'Documento de Identidad',
                      data: `${documentTypeById(data?.idTipoDocumentoRL, 1)} ${
                        data?.numeroDocumentoRL
                      }`,
                    },
                    {
                      title: 'Cargo',
                      data: positionsById(data?.idCargoRL),
                    },
                    {
                      title: 'Correo Electrónico',
                      data: data?.emailRL,
                    },
                    {
                      title: 'Celular',
                      data: data?.celularRL
                        ? `(${data?.indicativoCelularRL}) ${data?.celularRL}`
                        : 'N.D',
                    },
                  ]}
                />
              </Box>
            </ContentCard>
          </Grid>
          <Grid item container xs={12} xl={6} justifyContent="center">
            <ContentCard className={classes.containerRight}>
              <MainTitle>
                Datos de Ubicación - Dirección Geolocalizada
              </MainTitle>
              <Box
                marginY={1}
                minHeight="200px"
                display="flex"
                alignItems="center"
              >
                <AddressPreview
                  directionType={data?.idTipoDireccion}
                  country={data?.pais}
                  department={data?.departamento}
                  city={data?.ciudad}
                  roadType={data?.tipoVia}
                  numberVia={data?.numeroVia}
                  viaAppend={data?.apendiceVia}
                  crossingAppend={data?.apendiceCruce}
                  numberCrossing={data?.numeroCruce}
                  cornerMeters={data?.metrosEsquina}
                  additional={data?.adicional}
                  postalCode={data?.codigoPostal}
                />
              </Box>
            </ContentCard>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Detail;
