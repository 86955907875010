import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    textAlign: 'center',
    padding: 30,
    fontSize: 8,
    fontFamily: 'Roboto',
    fontWeight: 300,
  },
  sectionanimals: {
    marginLeft: 20,
    width: '65.5%',
  },
  section: {
    flexDirection: 'row',
    fontSize: 10,
  },
  sectiondate: {
    width: 150,
    height: 20,
    flex: 2,
    flexDirection: 'row',
    flexGrow: 2,
    fontSize: 10,
  },
  sectionweitgh: {
    width: '10px',
    flex: 4,
    flexDirection: 'row',
    flexGrow: 4,
    fontSize: 10,
    marginLeft: 250,
    position: 'relative',
    top: 20,
  },
  sectionresume: {
    width: '10px',
    flex: 5,
    flexDirection: 'row',
    flexGrow: 5,
    fontSize: 10,
    marginLeft: 40,
    marginTop: 10,
  },
  section2: {
    marginLeft: 5,
  },
  timeall: {
    marginRight: 30,
    alignItems: 'center',
  },
  sectionnumbers: {
    marginLeft: 5,
    flex: 2,
    flexDirection: 'row',
    flexGrow: 2,
    marginRight: 70,
  },
  widthsectionnumbers: {
    width: 60,
    position: 'relative',
    left: 15,
    fontSize: 5,
  },
  sectionnumberstext: {
    width: 40,
    textAlign: 'left',
    fontSize: 7,
    marginLeft: 5,
  },
  weigthanimals: {
    width: 60,
    fontSize: 8,
    marginTop: 4,
  },
  section4: {
    marginTop: -121.3,
    marginLeft: 238,
  },
  section3: {
    marginTop: 20,
    marginLeft: '45%',
    width: '50%',
  },
  column: {
    marginLeft: 250,
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
  },
  tableMagro: {
    display: 'table',
    width: '48.3%',
    borderStyle: 'solid',
  },
  tableorigin: {
    display: 'table',
    borderStyle: 'solid',
    top: 10,
    width: 50,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableRowMargin: {
    margin: 'auto',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  tableRowBackground: {
    backgroundColor: '#ededed',
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '16.5%',
  },
  tableColViceras: {
    width: '16.5%',
  },
  tableColOrigin: {
    width: 50,
  },
  tableCell: {
    marginTop: 5,
    fontSize: 8,
    textAlign: 'center',
  },
  tableCellViceras: {
    height: '13px',
    fontSize: 8,
    marginTop: 5,
    textAlign: 'center',
  },
  tableCellPost: {
    height: '13px',
    fontSize: 8,
    marginTop: 5,
    textAlign: 'center',
  },
  tableCellRPost: {
    height: '11px',
    fontSize: 8,
    marginTop: 3,
    textAlign: 'center',
  },
  tableCellVisceras: {
    height: '13px',
    fontSize: 8,
    marginTop: 5,
    textAlign: 'center',
  },
  tableCellAM: {
    height: '13px',
    fontSize: 8,
    marginTop: 5,
    textAlign: 'center',
  },
  tableCellText: {
    fontSize: 8,
    textAlign: 'center',
  },
  tableHead: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCellOrigin: {
    marginTop: 5,
    fontSize: 10,
    textAlign: 'center',
  },
  detailsreport: {
    marginTop: 14,
  },
  background: {
    backgroundColor: '#ededed',
  },
  backgroundanimals: {
    backgroundColor: '#be5948',
    color: 'white',
    width: 70,
    height: 30,
    justifyContent: 'center',
    margin: 'auto',
  },
  backgroundanimals1: {
    backgroundColor: '#e29662',
    color: 'white',
    width: 70,
    height: 30,
    justifyContent: 'center',
    margin: 'auto',
  },
  backgroundanimals2: {
    backgroundColor: '#f1ae6c',
    color: 'white',
    width: 70,
    height: 30,
    justifyContent: 'center',
    margin: 'auto',
  },
  backgroundanimals3: {
    backgroundColor: '#ffc676',
    color: 'white',
    width: 70,
    height: 30,
    justifyContent: 'center',
    margin: 'auto',
  },
  backgroundanimalsrounded: {
    backgroundColor: '#d47d57',
    color: 'white',
    width: 30,
    height: 30,
    borderRadius: 50,
    margin: 'auto',
    justifyContent: 'center',
  },
  backgroundanimalsrounded1: {
    backgroundColor: '#ffc676',
    color: 'white',
    width: 30,
    height: 30,
    borderRadius: 50,
    margin: 'auto',
    justifyContent: 'center',
  },
  backgroundanimalsrounded2: {
    backgroundColor: '#e29662',
    color: 'white',
    width: 30,
    height: 30,
    borderRadius: 50,
    margin: 'auto',
    justifyContent: 'center',
  },
  backgroundresumetitle: {
    backgroundColor: '#ededed',
    fontSize: 8,
    justifyContent: 'center',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    paddingTop: 3,
    paddingBottom: 3,
  },
  imageicon: {
    backgroundColor: '#F49A28',
    borderRadius: 50,
    width: 30,
    height: 30,
    marginLeft: 10,
    justifyContent: 'center',
    position: 'absolute',
    padding: 3,
  },
  backgroundresumetitleImage: {
    backgroundColor: '#ededed',
    fontSize: 8,
    justifyContent: 'center',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    paddingTop: 3,
    paddingBottom: 3,
  },
  backgroundresumetitle1: {
    backgroundColor: '#ededed',
    height: 20,
    fontSize: 8,
    justifyContent: 'center',
    marginBottom: 15,
  },
  backgroundweigth: {
    backgroundColor: '#ededed',
    width: 70,
    height: 15,
    justifyContent: 'center',
  },
  backgroundorigin: {
    width: '31%',
  },
  imageplant: {
    backgroundColor: '#F49A28',
    borderRadius: 50,
    width: 30,
    height: 30,
    marginLeft: 10,
    justifyContent: 'center',
    position: 'absolute',
    padding: 3,
  },
  backgroundguide: {
    backgroundColor: '#ededed',
    height: 30,
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  backgroundEntrance: {
    backgroundColor: '#ededed',
    height: 30,
    width: '50%',
    margin: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  backgroundST: {
    backgroundColor: '#ededed',
    height: 185,
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  backgroundMagro: {
    backgroundColor: '#ededed',
    height: 130,
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    // flexDirection: 'row',
  },
  gpost: {
    top: 8,
    width: 40,
    height: 20,
  },
  gsmi: {
    top: 8,
    width: 25,
    height: 20,
  },
  gmagroC: {
    top: 8,
    width: 50,
    height: 20,
    textAlign: 'left',
  },
  gmagroCE: {
    top: 8,
    width: 40,
    height: 20,
    textAlign: 'left',
  },
  gmagroST: {
    top: 8,
    width: 30,
    height: 20,
    textAlign: 'left',
  },
  gmagroS: {
    top: 9,
    width: 80,
    height: 20,
    textAlign: 'left',
  },
  gmagroSEUROP: {
    top: 9,
    width: 20,
    height: 20,
  },
  ST: {
    top: 9,
    width: 35,
    height: 20,
  },
  textgsmi: {
    width: 60,
    top: 4,
    height: 20,
  },
  textShort: {
    width: 30,
    top: 8,
    height: 20,
  },
  textLong: {
    width: 90,
    top: 8,
    height: 20,
  },
  textEntrance: {
    width: 200,
    top: 8,
    height: 20,
  },
  textMagro: {
    width: 80,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textMagroC: {
    width: 85,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textST: {
    width: 80,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textMagroSEUROP: {
    width: 50,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textST: {
    width: 110,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textSTShort: {
    width: 70,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textMagroC: {
    width: 90,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textpost: {
    width: 120,
    top: 8,
    height: 20,
  },
  textpostTwo: {
    width: 70,
    top: 8,
    // left: 2,
    width: 40,
    height: 20,
  },
  textgsmi: {
    width: 50,
    top: 4,
    height: 20,
  },
  textMagro: {
    width: 80,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textST: {
    width: 80,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textMagroSEUROP: {
    width: 50,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textST: {
    width: 110,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textSTShort: {
    width: 70,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textMagroC: {
    width: 90,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textpost: {
    width: 120,
    top: 8,
    height: 20,
  },
  textpostTwo: {
    width: 70,
    top: 8,
    // left: 2,
    width: 40,
    height: 20,
  },
  textgsmi: {
    width: 50,
    top: 4,
    height: 20,
  },
  textMagro: {
    width: 80,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textST: {
    width: 80,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textMagroSEUROP: {
    width: 50,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textST: {
    width: 110,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textSTShort: {
    width: 70,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textMagroC: {
    width: 90,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textpost: {
    width: 120,
    top: 8,
    height: 20,
  },
  textpostTwo: {
    width: 70,
    top: 8,
    // left: 2,
    width: 40,
    height: 20,
  },
  textgsmi: {
    width: 50,
    top: 4,
    height: 20,
  },
  textMagro: {
    width: 80,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textST: {
    width: 80,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textMagroSEUROP: {
    width: 50,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textST: {
    width: 110,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textSTShort: {
    width: 70,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textMagroC: {
    width: 90,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textpost: {
    width: 120,
    top: 8,
    height: 20,
  },
  textpostTwo: {
    width: 70,
    top: 8,
    height: 20,
  },
  textconventions: {
    flex: 2,
    flexDirection: 'row',
    flexGrow: 2,
    marginTop: 3,
  },
  textconventionsShort: {
    flex: 2,
    flexDirection: 'row',
    flexGrow: 2,
    marginTop: 3,
  },
  textconventionsLong: {
    flex: 2,
    flexDirection: 'row',
    flexGrow: 4,
    marginTop: 3,
  },
  textconventionsST: {
    marginTop: 5,
    flexDirection: 'row',
  },
  numberanimals: {
    width: '10%',
    margin: 'auto',
    display: 'flex',
  },
  numberanimals1: {
    width: '12%',
    margin: 'auto',
  },
  numberanimals2: {
    width: '17%',
    margin: 'auto',
  },
  numberanimals3: {
    width: '13%',
    margin: 'auto',
  },
  numberanimals4: {
    width: '12%',
    margin: 'auto',
    marginRight: 40,
  },
  marginanimals: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  totalanimals: {
    backgroundColor: '#ededed',
    marginTop: 10,
  },
  backgroundimage: {
    backgroundColor: '#ededed',
    height: 70,
    width: 60,
    marginRight: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundimage1: {
    backgroundColor: '#ededed',
    height: 70,
    width: 70,
    marginRight: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableplant: {
    display: 'table',
    borderStyle: 'solid',
    marginTop: 10,
  },
  tableRowplant: {
    flexDirection: 'row',
  },
  tableColplant: {
    width: '50%',
    textAlign: 'left',
  },
  tableColDetails: {
    width: '50%',
    textAlign: 'left',
  },
  tableColplantRigth: {
    width: '50%',
    textAlign: 'right',
    borderStyle: 'solid',
  },
  tableColDetailsRigth: {
    width: '50%',
    textAlign: 'right',
  },
  tableCellplant: {
    marginTop: 3,
    fontSize: 7,
  },
  background: {
    backgroundColor: '#ededed',
    fontSize: 10,
  },
  backgroundcode: {
    backgroundColor: '#ededed',
    width: 70,
    height: 20,
    fontSize: 8,
    marginBottom: 5,
    justifyContent: 'center',
  },
  backgrounddate: {
    backgroundColor: '#ededed',
    width: 120,
    height: 20,
    marginLeft: 3,
    fontSize: 8,
  },
  contentdate: {
    flex: 2,
    flexDirection: 'row',
    flexGrow: 2,
    marginRight: 45,
    alignItems: 'center',
  },
  image: {
    height: '45px',
    width: '45px',
  },
  tablecontainer: {
    width: '31.2%',
    marginTop: 7,
    marginRight: 18,
  },
  tablecontainer2: {
    width: '31%',
    marginTop: 7,
    marginRight: 18,
  },
  tablecontainer3: {
    width: '31%',
    marginTop: 7,
  },
  conventions: {
    marginTop: 30,
  },
  sectionorigin: {
    flexDirection: 'row',
    marginTop: 20,
  },
  border: {
    border: '1 solid #EDEDED',
    width: 70,
    height: 20,
    justifyContent: 'center',
    margin: 'auto',
    backgroundColor: '#ffffff',
    top: -143,
    position: 'relative',
    borderRadius: 3,
  },
  borderMagro: {
    border: '1 solid #EDEDED',
    width: 70,
    height: 20,
    justifyContent: 'center',
    margin: 'auto',
    backgroundColor: '#ffffff',
    top: -140,
    position: 'relative',
    borderRadius: 3,
  },
  borderAM: {
    border: '1 solid #EDEDED',
    width: 70,
    height: 20,
    justifyContent: 'center',
    margin: 'auto',
    backgroundColor: '#ffffff',
    top: -45,
    position: 'relative',
    borderRadius: 3,
  },
  borderAMT: {
    border: '1 solid #EDEDED',
    width: 70,
    height: 20,
    justifyContent: 'center',
    margin: 'auto',
    backgroundColor: '#ffffff',
    top: -45,
    position: 'relative',
    borderRadius: 3,
  },
  borderPM: {
    border: '1 solid #EDEDED',
    width: 70,
    height: 20,
    justifyContent: 'center',
    margin: 'auto',
    backgroundColor: '#ffffff',
    top: -45,
    position: 'relative',
    borderRadius: 3,
  },
  borderST: {
    border: '1 solid #EDEDED',
    width: 70,
    height: 20,
    justifyContent: 'center',
    margin: 'auto',
    backgroundColor: '#ffffff',
    top: -193,
    position: 'relative',
    borderRadius: 3,
  },
  copy: {
    position: 'absolute',
    bottom: 25,
    textAlign: 'center',
    fontSize: 6,
    marginLeft: 43,
  },
  datereport: { left: 3, width: 245, textAlign: 'left' },
  datemargin: {
    marginTop: 5,
    marginBottom: 7,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: 'black',
  },
  datecolor: {
    marginLeft: 5,
  },
  phone: {
    marginTop: 7,
    marginBottom: 7,
  },
  fontlicense: { fontSize: 20 },
  licenseContainer: {
    width: '40%',
    height: '100%',
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    paddingLeft: 15,
  },
  plate: {
    paddingTop: 15,
    paddingBottom: 15,
    border: 1,
    borderColor: '#E2E2E1',
    borderRadius: 5,
    margin: 1,
  },
  plateText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#212121',
    fontSize: 18,
  },
  displayContent: {
    display: 'flex',
  },
  sectionnumbers: {
    marginLeft: 5,
    flexDirection: 'row',
  },
  resumedetailsm: {
    color: '#ffffff',
    borderRadius: 50,
    width: 25,
    height: 25,
    justifyContent: 'center',
  },
  textBold: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
});
