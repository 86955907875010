import { connect, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';

import {
  SIGN_IN,
  TOGGLE_MODAL,
  SET_USER_TYPE,
} from 'core/redux/types/userTypes';
import {
  LOGIN_MUTATION,
  LOGIN_TOKEN_MUTATION,
} from 'core/graphql/queries/login';
import * as userActions from 'core/redux/actions/userActions';
import activeUser from 'api/activeUser';

import config from 'config/secrets';

import { BlockAccountDialogProps } from 'components/BlockedAccountDialog';

import LoginValidations from 'lib/validations/LoginValidations';
import ID from 'lib/helpers/random-id';

import { Login } from './Login';

const LoginContainer = ({ openModalSelect, typeUsers, isLogged }: any) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  let params: any = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [hasError, setHasError] = useState({
    message: '',
    error: false,
  });
  const dispatch = useDispatch();
  const [blockUser, setBlockUser] = useState<BlockAccountDialogProps>({
    open: false,
    onlyAcceptButton: false,
    info: {
      username: '',
      name: '',
      link: '',
    },
  });
  const [login, { loading }] = useMutation(LOGIN_MUTATION, {
    errorPolicy: 'all',
  });
  const [loginToken, { loading: loadingToken }] = useMutation(
    LOGIN_TOKEN_MUTATION,
    {
      errorPolicy: 'all',
    }
  );

  useEffect(() => {
    if (params.id) {
      activeUser(params.id)
        .then((data) => {
          enqueueSnackbar(`${data?.message}`, {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            autoHideDuration: 3000,
          });
        })
        .catch((error) => {
          enqueueSnackbar(`${error}`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            autoHideDuration: 3000,
          });
        });
    }
  }, [params.id]);

  const redirect = new URLSearchParams(window.location.search).get('leave');

  const handleBlockUserClose = () => {
    setBlockUser({ ...blockUser, open: false });
  };

  const handlePromise = (promise: Promise<any>) => {
    promise
      .then(({ data, errors }) => {
        if (errors && errors[0]) {
          setHasError({
            message: errors[0].message,
            error: true,
          });
        } else {
          const { login } = data;
          document.cookie = `auth=${login.token}`;
          Promise.resolve().then(() => {
            const update = login.data.actualizacion;
            const typeEntity = login.data.datosBasicos.usuarioEmpresa
              ? 'company'
              : 'person';
            const entity =
              login.data.datosBasicos[
                `usuario${typeEntity === 'person' ? 'Persona' : 'Empresa'}`
              ];
            const name =
              typeEntity === 'person'
                ? `${entity.primerNombre ?? ''} ${entity.segundoNombre ?? ''} ${
                    entity.primerApellido ?? ''
                  } ${entity.SegundoApellido ?? ''}`
                : entity.razonSocial;
            setHasError({
              message: '',
              error: false,
            });
            if (update.success !== 2) {
              setBlockUser({
                open: true,
                info: {
                  name,
                  username: login.data.datosBasicos.username,
                  link: `${config.BACKEND.dev['update-data']}/?auth=${login.token}`,
                },
              });
            } else {
              const data = login.data;
              if (data) {
                if (data.tiposXRol) {
                  dispatch({
                    type: SIGN_IN,
                    payload: {
                      isLogged: true,
                      token: login.token,
                      isEmployee: login.data.datosBasicos.usuarioInterno,
                      user: {
                        id: data.datosBasicos.idUsuario,
                        name: `${
                          data?.datosBasicos?.usuarioPersona?.primerNombre ?? ''
                        } ${
                          data?.datosBasicos?.usuarioPersona?.segundoNombre ??
                          ''
                        }`,
                        lastName: `${
                          data?.datosBasicos?.usuarioPersona?.primerApellido ??
                          ''
                        } ${
                          data?.datosBasicos?.usuarioPersona?.SegundoApellido ??
                          ''
                        }`,
                        bussinesName:
                          data?.datosBasicos?.usuarioEmpresa?.razonSocial,
                        username: data.datosBasicos.username,
                        typeEntity,
                        avatar: entity?.avatar,
                      },
                      typeUsers: data.tiposXRol.map((item: any) => ({
                        _id: ID(),
                        id: item.idTipoUsuario,
                        name: item.tipoUsuario,
                        role: item.rol,
                        idRole: item.idRol,
                        idUserType: item.idTipoUsuario,
                      })),
                      subscriptionPlan: {
                        type: data?.subscriptionPlan?.tiposSuscripcion,
                        sponsor:
                          data?.subscriptionPlan?.razonSocialPatrocinador,
                        initialDate:
                          data?.subscriptionPlan?.fechaInicioSuscripcion,
                        finalDate:
                          data?.subscriptionPlan?.fechaFinalSuscripcion,
                      },
                    },
                  });
                  if (data.tiposXRol.length > 1) {
                    dispatch({
                      type: TOGGLE_MODAL,
                    });
                  } else {
                    handleSelectUser(data.tiposXRol[0].idTipoUsuario);
                  }
                } else {
                  setBlockUser({
                    open: true,
                    title: 'Cuenta bloqueada',
                    onlyAcceptButton: true,
                    message: `**${name}** tu cuenta se encuentra bloqueada debido a que no se te han asignado permisos. **_Contactate con tu administrador_**`,
                    showName: false,
                    info: {
                      name,
                    },
                  });
                }
              }
            }
          });
        }
      })
      .catch((err) => {
        setHasError({
          message: err.toString(),
          error: true,
        });
      });
  };

  const onSubmit = (values: any) => {
    handlePromise(
      login({
        variables: values,
        context: {
          headers: {
            'x-auth-jwt':
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm9jZXNzIjoibG9naW4iLCJpYXQiOjE1MTYyMzkwMjJ9.MuizKNA5ZeVb-aGq5wLT9FQYPsIa-Dfp0cJWdRC2zSM',
          },
        },
      })
    );
  };

  const handleSelectUser = (id: number | string) => {
    const navTo = redirect ? redirect : '/app/dashboard';
    dispatch({
      type: SET_USER_TYPE,
      payload: id,
    });
    history.push(navTo);
  };

  useEffect(() => {
    try {
      if (!isLogged) {
        const token = urlParams.get('auth-token');
        if (token) {
          if (jwtDecode(token)) {
            handlePromise(
              loginToken({
                context: {
                  headers: {
                    'x-auth-jwt': token,
                  },
                },
              })
            );
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const reduxProps = {
    loading: loading || loadingToken,
    openModalSelect,
    typeUsers,
    handleSelectUser,
    hasError: hasError.error,
    errorMessage: hasError.message,
    blockUser,
    handleBlockUserClose,
  };

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={LoginValidations}
      onSubmit={onSubmit}
    >
      {(formikProps: any) => <Login {...formikProps} {...reduxProps} />}
    </Formik>
  );
};

const mapStateToProps = (reducers: any) => {
  return reducers.user;
};

export default connect(mapStateToProps, userActions)(LoginContainer);
