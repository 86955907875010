import React from "react";
import { createContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "react-redux";

interface HasPermissionProps {
  children: React.ReactNode;
}

const HasPermissionContext = createContext({});

const HasPermissionProvider = ({ children }: HasPermissionProps) => {
  const path = useLocation();
  const history = useHistory();
  const [inPath, setInPath] = React.useState(true);

  const commonRoutes = [
    "/app/profile",
    "/app/dashboard",
    "/app/settings",
    "/404",
    "/app/welcome",
  ];
  const navigation = useSelector((state: any) => state.user.navigation);
  const modules = navigation?.filter((items: any) => items?.state);
  const subPermissions = modules?.map((nav: any) => {
    if (nav?.children.length !== 0)
      return nav?.children
        ?.filter((item: any) => item?.state)
        .map((child: any) => child?.url);
  });
  const p: any[] = [];
  const permissions = modules?.map((nav: any) => nav?.url);
  subPermissions?.map(
    (item: any) => item && item.map((sub: any) => p.push(sub))
  );

  useEffect(() => {
    const allRoutes = [...commonRoutes, ...p, ...permissions];
    const subRoutes = allRoutes.map((e: string) => {
      const match = path.pathname.match(new RegExp("(?<=" + e + ")/*/"))?.input;
      if (match) return match;
    });
    setInPath(_.includes([...allRoutes, ...subRoutes], path.pathname));
  }, [path]);

  useEffect(() => {
    if (!inPath) history.push("/401");
  }, [inPath]);

  return (
    <>
      <HasPermissionContext.Provider value>
        <>{children}</>
      </HasPermissionContext.Provider>
    </>
  );
};

export { HasPermissionContext as default, HasPermissionProvider };
