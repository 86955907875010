import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px',
      padding: '15px'
    }
  },
  avatarTitle:{
    backgroundColor: '#6AC759'
  },
  divContainerTitle : {
    backgroundColor: '#F2F2F2',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '230px',
    justifyContent: 'center',
    height: '34px'
  },
  divContainerTitle2 : {
    backgroundColor: '#F2F2F2',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    justifyContent: 'center',
    height: '34px'
  },
  dataTitle:{
    marginBottom: 0,
    color: '#767676',
    fontWeight: 'bolder',
  },
  titleContainer: {
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    color: '#121212',
  },
  fontTitleContainer: {
    fontSize:'17px'
  },
  gridItemContainer:{
    '& .MuiGrid-align-items-xs-center':{
      paddingLeft : '10px',
      paddingRight: '10px',
      marginBottom: '10px'
    }
  },
  gridContainer:{
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70px',
    
  },
  gridContainerGuide:{
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    '& .MuiGrid-align-items-xs-center':{
      justifyContent: 'center'
    }
  },
  gridContainerR:{
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '110px',
  },
  iconContainer:{
    borderRadius: '50px',
    border: '2px solid #DD9835',
    width: '42px',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#DD9835',
  },
  titleIconContainer: {
    color: '#AFAFAF',
    fontSize: '15px',
    fontWeight: 'bolder',
    textAlign: 'center'
  },
  itemInfoLabel:{
    fontSize: '17px',
    color: '#AFAFAF',
    fontWeight: 'bold',
    width: '130px'
  },
  fontTitle:{
    color: '#AFAFAF', 
    fontWeight: 'bolder'
  },
  listData:{
    fontSize: '15px',
    fontWeight: 'bolder',
    color: '#DD9835',
  },
  listtitle:{
    fontSize: '15px',
    fontWeight: 'bolder',
    color: '#AFAFAF',
  },
  gridContainerTitle:{
    paddingLeft: '20px', 
    marginBottom: '10px' 
  },
  gridContainerSubtitle:{
    paddingRight: '20px',
    marginBottom: '10px'
  },
  fontTitleList:{
    fontSize: '16px',
    fontWeight: 'bolder',
    color: '#AFAFAF',
  },
  fontSubtitleList:{
    fontSize: '16px',
    color: '#767676',
  }

}));

export { useStyles as default }