import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container:{
    width: '100%',
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
  },
  titleContainer:{
    width: '100%',
    background:'#F2F2F2', 
    color: '#767676',
    padding:8, 
    display: 'flex', 
    justifyContent:'center', 
    alignItems: 'center'
  },
  icon:{
    fontSize: 22, 
    margin: '0px 12px'
  },
  listContainer:{
    width: '100%', 
    padding: 4
  },
  itemListData:{
    display:'flex',
    justifyContent:'space-between',
    padding:'4px 0px',
    "& .MuiTypography-root":{
      color:"#AFAFAF", 
    }
  },
  itemChild:{
    fontWeight:'bolder', 
    paddingLeft: 12 
  }, 
}))

export { useStyles as default }