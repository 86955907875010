const blobToFile = (dataUrl: string, filename: string) => {
  const divided = dataUrl.split(',')
  const match = divided[0].match(/:(.*?);/)
  if (match) {
    const base64 = atob(divided[1])
    let n = base64.length
    const u8arr = new Uint8Array(n)
    const mime = match[1]

    while(n--) {
      u8arr[n] = base64.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }
}

export default blobToFile