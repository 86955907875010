import React from 'react';

import { GenerateMultilevelRoutes as Routes } from 'routes';

import routes from './pages';

const Services = () => {
  return <Routes nested={routes} redirect="/weight-resume" />;
};

export default Services;
