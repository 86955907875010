import React from 'react';
import { TextField } from '@material-ui/core';

import useStyles from './styles';

interface NumberFormatProps {
  label: string;
  value: number | null;
  name: string;
  error?: string;
  helperText?: string;
  onChange: (event: any) => void;
  disable?: boolean;
  required?: boolean;
}

const TextFieldNumber = ({
  label,
  name,
  value,
  error,
  helperText,
  onChange,
  disable,
  required,
}: NumberFormatProps) => {
  const handleChange = (event: any) => {
    event.target.value = Number(event.target.value);
    onChange(event);
  };
  const classes = useStyles();

  return (
    <TextField
      label={label}
      value={value}
      size="small"
      variant="outlined"
      fullWidth
      name={name}
      error={!!error}
      helperText={helperText}
      InputLabelProps={{ shrink: true }}
      onChange={handleChange}
      type="number"
      className={classes.input}
      disabled={disable}
      required={required}
      // onInput={(e: any) => {
      //   e.target.value = Math.max(0, parseInt(e.target.value))
      //                            .toString()
      //                            .slice(0, 3);
      //                        }}
    />
  );
};

export default TextFieldNumber;
