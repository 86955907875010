import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
  },
  cardItemAddDriver: {
    width: 220,
    height: 288,
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      },
    },
  },
  cardActionArea: {
    height: '100%',
  },
  iconCard: {
    fontSize: 75,
    color: '#767676',
  },
  mainText: {
    color: '#767676',
    fontWeight: 'bolder',
    textAlign: 'center'
  },
  loadingColor: {
    color: '#E2E2E2'
  },
  btnStep:{
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& :hover":{
      color: '#fff'
    }
  },
  hiddenButton: {
    display: 'none'
  },
  fontInfoStep:{
    fontSize: 14,
    color: theme.palette.primary.main,
    marginLeft: 10,
  },
  backButton:{
    "& .MuiGrid-item": {
      display: 'flex',
    },
    "& .MuiGrid-root:hover":{
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      cursor: 'pointer',
      width: 'max-content',
    },
    "& .MuiTypography-body1":{
      fontWeight: 'bold',
      marginLeft: 5,
    }
  },
  fontPlate:{
    color: '#DD9835',
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  plateContainer:{
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 40px 10px 40px'
  },
}))
export { useStyles as default }