import { gql } from "apollo-boost";

export const INDICATIVE = gql`
  query {
    countries{
      id
      name
      iso2
      phoneCodes
    }
  }
`;
