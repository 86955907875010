import React from 'react';

import { Image, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface TableProps {
  title: string;
  info: string;
  icon?: string;
  widthContainer?: number | string;
  widtdTitle?: number | string;
  widthInfo?: number | string;
}

const TitleContainer = ({
  title,
  info,
  icon,
  widthContainer,
  widtdTitle,
  widthInfo,
}: TableProps) => {
  return (
    <View
      style={{
        width: `${widthContainer ? (widthContainer as String) : '100%'}`,
      }}
    >
      {icon ? (
        <View style={[styles.backgroundresumetitle, { flexDirection: 'row' }]}>
          <View style={{ width: '15%' }}>
            <View style={[styles.imageicon, { marginTop: -10 }]}>
              <Image src={icon} />
            </View>
          </View>
          <View style={{ flexDirection: 'row', width: '85%' }}>
            <View
              style={{
                width: widtdTitle ?? '40%',
              }}
            >
              <Text style={{ fontWeight: 'bold' }}>{title}</Text>
            </View>
            <View
              style={{
                width: widthInfo ?? '60%',
              }}
            >
              <Text style={{ fontWeight: 'normal' }}>{info}</Text>
            </View>
          </View>
        </View>
      ) : (
        <View style={[styles.backgroundresumetitle, { flexDirection: 'row' }]}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: widtdTitle ?? '40%' }}>
              <Text style={{ fontWeight: 'bold' }}>{title}</Text>
            </View>
            <View style={{ width: widthInfo ?? '60%' }}>
              <Text style={{ fontWeight: 'normal' }}>{info}</Text>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export default TitleContainer;
