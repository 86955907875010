import { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import MainTitle from "components/MainTitle";
import PhoneNumber from "components/PhoneNumber";
import { TypeCardGroup } from "components/TypeCard";
import TextTerms from "components/PageComponents/TYC/terms";
import HabeasData from "components/PageComponents/TYC/habeasData";
import PersonalData from "components/PageComponents/TYC/personalData";
import TextFieldNumber from "components/TextFieldNumber";
import ActiveUser from "components/PageComponents/ActiveUser";

import { UserRegisterValidations } from "lib/validations/UserRegister";
import unmaskPhone from "lib/helpers/unmaskPhone";
import getDocTypes, { DocTypes } from "api/getDocTypes";
import constants from "config/constants";

import { useFormik } from "formik";
import clsx from "clsx";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import useStyles from "./styles";

const UserResgister = () => {
  const classes = useStyles();
  const [documentTypes, setDocumentTypes] = useState<Array<DocTypes>>([]);
  const [personType, setPersonType] = useState(1);
  const [userType, setUserType] = useState<any>([]);
  const [viewPass, setViewPass] = useState(false);

  const [open, setOpen] = useState(false);
  const [openTYC, setOpenTYC] = useState(false);
  const [typeTYC, setTypeTYC] = useState(0);

  const handleOpenTYC = () => setOpenTYC(true);
  const handleCloseTYC = () => setOpenTYC(false);
  const [openNoAccept, setOpenNoAccept] = useState(false);
  const [openHabeasData, setOpenHabeasData] = useState(false);
  const handleOpenNoAccept = () => {
    setOpenNoAccept(true);
    handleCloseTYC();
  };
  const handleCloseNoAccept = () => setOpenNoAccept(false);

  const [openAccept, setOpenAccept] = useState(false);
  const handleOpenAccept = () => {
    setOpenAccept(true);
    handleCloseTYC();
  };
  const handleCloseAccept = () => setOpenAccept(false);

  const handleOpenHabeasData = () => {
    setOpenHabeasData(true);
    handleCloseTYC();
  };

  const handleCloseHabeasData = () => {
    setOpenAccept(true);
    setOpenHabeasData(false);
  };

  const type =
    typeTYC === 1
      ? "Terminos de Uso"
      : typeTYC === 2
      ? "Tratamiento de Datos Personales"
      : "";

  useEffect(() => {
    getDocTypes().then((data) => setDocumentTypes(data));
  }, []);

  const formik = useFormik({
    initialValues: {
      IdTipoDocumento: 3,
      NumeroDocumento: null,
      PrimerNombre: null,
      SegundoNombre: null,
      PrimerApellido: null,
      SegundoApellido: null,
      RazonSocial: null,
      DigitoVerificacion: null,
      Telefono: null,
      Celular: null,
      Email: null,
      IndicativoCelular: constants.DEFAULT_COUNTRY_ID,
      IndicativoTelefono: constants.DEFAULT_DEPARTMENT_ID,
      Extension: "",
      Usuario: "",
      Contraseña: "",
      ConfirmContraseña: "",
      AceptaPoliticaConfidencialidad: false,
      AceptaHabeasData: false,
      AceptaTerminosUso: false,
      TipoUsuario: [],
    },
    validationSchema: UserRegisterValidations,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleChangePersonType = (value: any) => {
    setPersonType(value);
    if (value === 1) {
      formik.setFieldValue("PrimerNombre", null);
      formik.setFieldValue("SegundoNombre", null);
      formik.setFieldValue("PrimerApellido", null);
      formik.setFieldValue("SegundoApellido", null);
      formik.setFieldValue("IdTipoDocumento", 3);
      formik.setFieldValue("NumeroDocumento", null);
    } else {
      formik.setFieldValue("RazonSocial", null);
      formik.setFieldValue("IdTipoDocumento", 1);
      formik.setFieldValue("NumeroDocumento", null);
      formik.setFieldValue("DigitoVerificacion", null);
    }
  };

  const handleChangeUserType = (id: number) => {
    if (userType.includes(id)) {
      const remove = userType.filter((item: any) => item !== id);
      setUserType(remove);
      formik.setFieldValue("TipoUsuario", remove);
    } else {
      setUserType([...userType, id]);
      formik.setFieldValue("TipoUsuario", [...userType, id]);
    }
  };

  return (
    <>
      <Dialog
        open={openNoAccept}
        onClose={handleCloseNoAccept}
        maxWidth="sm"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogContent>
          <i className={clsx("icon-circle-alert", classes.iconDialogCheck)} />
          <Typography variant="h5" className={classes.title}>
            No ha Aceptado {type}
          </Typography>
          <Typography variant="subtitle1" className={classes.captionGray}>
            Al no aceptar {type}, no podrá registrarse, ni hacer uso de la
            plataforma Infoporcinos, si requiere el uso de esta debe leer y
            aceptar: los Terminos de uso del sitio web y la politica de
            Tratamiento de Datos Personales.
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            onClick={handleCloseNoAccept}
            variant="contained"
            color="primary"
          >
            Informado
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAccept}
        onClose={handleCloseAccept}
        maxWidth="sm"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogContent>
          <i className={clsx("icon-circle-check", classes.iconDialogCheck)} />
          <Typography variant="h5" className={classes.title}>
            Ha Aceptado {type}.
          </Typography>
          <Typography variant="subtitle1" className={classes.captionGray}>
            Se ha aceptado {type} y será notificado con está acción a través del
            correo electrónico registrado.
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            onClick={handleCloseAccept}
            variant="contained"
            color="primary"
          >
            Informado
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openHabeasData}
        onClose={handleCloseHabeasData}
        maxWidth="md"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogContent>
          <HabeasData />
          <DialogActions classes={{ root: classes.dialogFooter }}>
            <Button
              onClick={handleCloseHabeasData}
              variant="contained"
              color="primary"
            >
              Informado
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openTYC}
        onClose={handleCloseTYC}
        className={classes.dialogContainer}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {typeTYC === 1 ? (
            <TextTerms />
          ) : typeTYC === 2 ? (
            <PersonalData />
          ) : null}
          <DialogActions>
            {(typeTYC === 1 && formik.values.AceptaTerminosUso === true) ||
            (typeTYC === 2 &&
              formik.values.AceptaPoliticaConfidencialidad === true) ||
            (typeTYC === 3 && formik.values.AceptaHabeasData === true) ? (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Typography variant="subtitle1" color="primary">
                    Usted ha leído, aceptado y ha sido notificado con nuestros{" "}
                    {type}
                  </Typography>
                  <Typography variant="subtitle1" color="secondary">
                    {dayjs().format("DD-MM-YYYY HH:mm:ss")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCloseTYC}
                  >
                    Cerrar
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenNoAccept}
                >
                  No Aceptar
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    {
                      typeTYC === 1
                        ? (formik.setFieldValue("AceptaTerminosUso", true),
                          handleOpenAccept())
                        : typeTYC === 2
                        ? (formik.setFieldValue("AceptaHabeasData", true),
                          formik.setFieldValue(
                            "AceptaPoliticaConfidencialidad",
                            true
                          ),
                          handleOpenHabeasData())
                        : null;
                    }
                  }}
                >
                  Aceptar
                </Button>
              </>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Box className={classes.container}>
        <Typography variant="h6" color="secondary">
          REGISTRO DE USUARIO
        </Typography>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Box marginY={2}>
            <MainTitle>Tipo de Usuario</MainTitle>
            <Typography variant="subtitle1" color="secondary">
              A continuación seleccione si será un usuario persona o empresa,
              además si será un comercializador, prodcutor o ambos en el
              sistema.
            </Typography>
            <Box marginY={2}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    label="Tipo usuario"
                    size="small"
                    variant="outlined"
                    value={personType}
                    fullWidth
                    required
                    select
                    onChange={(e: any) =>
                      handleChangePersonType(e.target.value)
                    }
                  >
                    <MenuItem key={1} value={1}>
                      Empresa
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      Persona
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Box display="flex" marginRight={2}>
                    <TypeCardGroup
                      userTypes={[1, 2]}
                      onChange={(e: any) => handleChangeUserType(e)}
                    />
                  </Box>
                  <Typography color="error">
                    {formik.errors.TipoUsuario}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <MainTitle>Datos Generales</MainTitle>
            <Box marginY={2}>
              <Grid container spacing={2}>
                {personType === 1 && (
                  <>
                    <Grid item xs={3}>
                      <TextField
                        label="Tipo de documento"
                        name="IdTipoDocumento"
                        size="small"
                        variant="outlined"
                        fullWidth
                        required
                        select
                        value={formik.values.IdTipoDocumento}
                        onChange={formik.handleChange}
                        disabled
                      >
                        {documentTypes.map((type: DocTypes) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={3}>
                      <TextFieldNumber
                        label="Número de documento"
                        name="NumeroDocumento"
                        error={formik.errors.NumeroDocumento}
                        value={formik.values.NumeroDocumento}
                        helperText={formik.errors.NumeroDocumento}
                        onChange={formik.handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextFieldNumber
                        label="Digito de Verificación"
                        name="DigitoVerificacion"
                        error={formik.errors.DigitoVerificacion}
                        value={formik.values.DigitoVerificacion}
                        helperText={formik.errors.DigitoVerificacion}
                        onChange={formik.handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Razón Social"
                        name="RazonSocial"
                        size="small"
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.RazonSocial}
                        error={!!formik.errors.RazonSocial}
                        helperText={formik.errors.RazonSocial}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </>
                )}
                {personType === 2 && (
                  <>
                    <Grid item xs={3}>
                      <TextField
                        label="Primer Nombre"
                        name="PrimerNombre"
                        size="small"
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.PrimerNombre}
                        error={!!formik.errors.PrimerNombre}
                        helperText={formik.errors.PrimerNombre}
                        onChange={formik.handleChange}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        label="Segundo Nombre"
                        name="SegundoNombre"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={formik.values.SegundoNombre}
                        error={!!formik.errors.SegundoNombre}
                        helperText={formik.errors.SegundoNombre}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Primer Apellido"
                        name="PrimerApellido"
                        size="small"
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.PrimerApellido}
                        error={!!formik.errors.PrimerApellido}
                        helperText={formik.errors.PrimerApellido}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Segundo Apellido"
                        name="SegundoApellido"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={formik.values.SegundoApellido}
                        error={!!formik.errors.SegundoApellido}
                        helperText={formik.errors.SegundoApellido}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Tipo de documento"
                        name="IdTipoDocumento"
                        size="small"
                        variant="outlined"
                        fullWidth
                        required
                        select
                        value={formik.values.IdTipoDocumento}
                        onChange={formik.handleChange}
                      >
                        {documentTypes.map((type: DocTypes) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={3}>
                      <TextFieldNumber
                        label="Número de documento"
                        name="NumeroDocumento"
                        error={formik.errors.NumeroDocumento}
                        value={formik.values.NumeroDocumento}
                        helperText={formik.errors.NumeroDocumento}
                        onChange={formik.handleChange}
                        required
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <MainTitle>Datos de Contacto</MainTitle>
            <Box marginY={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PhoneNumber
                    type="mobile"
                    names={{
                      indicative: "IndicativoCelular",
                      phone: "Celular",
                    }}
                    values={{
                      indicative: formik.values.IndicativoCelular,
                      phone: (formik.values.Celular = unmaskPhone(
                        formik.values.Celular
                      )),
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative: !!formik.errors.IndicativoCelular,
                      phone: formik.errors.Celular as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.IndicativoCelular as string,
                      phone: formik.errors.Celular as string,
                    }}
                    required
                    withoutToken
                  />
                </Grid>
                <Grid item xs={6}>
                  <PhoneNumber
                    type="landline"
                    names={{
                      indicative: "IndicativoTelefono",
                      phone: "Telefono",
                      ext: "Extension",
                    }}
                    values={{
                      indicative: formik.values.IndicativoTelefono,
                      phone: formik.values.Telefono,
                      ext: formik.values.Extension,
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative: !!formik.errors.IndicativoTelefono,
                      phone: formik.errors.Telefono as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.IndicativoCelular as string,
                      phone: formik.errors.Telefono,
                    }}
                    extension={personType === 1}
                    withoutToken
                    required={personType === 1}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Correo eléctronico"
                    value={formik.values.Email}
                    error={!!formik.errors.Email}
                    helperText={formik.errors.Email}
                    name="Email"
                    size="small"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <MainTitle>Datos de Acceso</MainTitle>
            <Box marginY={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    label="Nombre de Usuario"
                    name="Usuario"
                    size="small"
                    variant="outlined"
                    fullWidth
                    required
                    value={formik.values.Usuario}
                    error={!!formik.errors.Usuario}
                    helperText={formik.errors.Usuario}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      startAdornment: (
                        <i className="icon-user" style={{ fontSize: 24 }} />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Contraseña"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="Contraseña"
                    value={formik.values.Contraseña}
                    onChange={formik.handleChange}
                    error={!!formik.errors.Contraseña}
                    helperText={formik.errors.Contraseña}
                    type={viewPass ? "text" : "password"}
                    InputProps={{
                      startAdornment: (
                        <i className="icon-lock" style={{ fontSize: 24 }} />
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setViewPass(!viewPass)}>
                            {!viewPass ? (
                              <i className="icon-eye" />
                            ) : (
                              <i className="icon-eye-slash" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Confirmar contraseña"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="ConfirmContraseña"
                    value={formik.values.ConfirmContraseña}
                    onChange={formik.handleChange}
                    error={!!formik.errors.ConfirmContraseña}
                    helperText={formik.errors.ConfirmContraseña}
                    type={viewPass ? "text" : "password"}
                    InputProps={{
                      startAdornment: (
                        <i className="icon-lock" style={{ fontSize: 24 }} />
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setViewPass(!viewPass)}>
                            {!viewPass ? (
                              <i className="icon-eye" />
                            ) : (
                              <i className="icon-eye-slash" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  className={classes.checkboxContainer}
                >
                  <Box display="flex" alignItems="center" marginX={2}>
                    <Checkbox
                      checked={formik.values.AceptaPoliticaConfidencialidad}
                      name="AceptaPoliticaConfidencialidad"
                      onClick={() => {
                        handleOpenTYC();
                        setTypeTYC(2);
                      }}
                      color="primary"
                      className={classes.checkboxLabel}
                    />

                    <Button
                      variant="text"
                      onClick={() => {
                        handleOpenTYC();
                        setTypeTYC(2);
                      }}
                    >
                      Acepto Tratamiento de datos personales.
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center" marginX={2}>
                    <Checkbox
                      checked={formik.values.AceptaTerminosUso}
                      name="AceptaTerminosUso"
                      onClick={() => {
                        handleOpenTYC();
                        setTypeTYC(1);
                      }}
                      color="primary"
                      className={classes.checkboxLabel}
                    />

                    <Button
                      variant="text"
                      onClick={() => {
                        handleOpenTYC();
                        setTypeTYC(1);
                      }}
                    >
                      Acepto los Términos de Uso del sitio web
                    </Button>
                  </Box>
                </Grid>
                <Box margin="auto">
                  <Typography color="error" align="center">
                    {formik.errors.AceptaPoliticaConfidencialidad ||
                      formik.errors.AceptaTerminosUso ||
                      formik.errors.AceptaHabeasData}
                  </Typography>
                </Box>
              </Grid>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: "0px 4px" }}
              component={Link}
              to="/auth/login"
            >
              Volver Atrás
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ margin: "0px 4px" }}
            >
              Solicitar Usuario
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default UserResgister;
