import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  title:{
    fontWeight: 'bold',
    color: '#767676',
    fontSize: '19px',
  },
  data:{
    color: '#AFAFAF'
  },
  result:{
    color: '#767676',
    fontSize: '14px'
  },
  colorChip:{
    backgroundColor: '#767676 !important',
    color: 'white',
    "& .MuiChip-labelSmall":{
      textDecoration: 'none',
      color: 'white'
    }
  },
  dataGrid: {
    border: 'none',
    "& .MuiDataGrid-columnSeparator" :{
      color: 'transparent',
    },
    "& .MuiDataGrid-colCellTitle" :{
      color: '#AFAFAF',
    },
    "& .MuiDataGrid-cell:focus" :{
      outline: 'none'
    },

    "& .MuiDataGrid-cell:focus-within" :{
      outline: 'none',
      outlineOffset: 'none'
    },

    "& .MuiDataGrid-row.Mui-selected" :{
      backgroundColor: 'transparent'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14
    }
  },
  cardContainer:{
    width: '100%',
    padding: '35px !important'
  },
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& :hover":{
      color: '#fff'
    }
  },
  breadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  },
  labelGraphic: {
    display: 'none'
  }
}))

export { useStyles as default }