import React from 'react';

import {
  Card,
  Avatar,
  Typography,
  Box,
  CardActionArea,
  ListItemText,
} from '@material-ui/core';

import clsx from 'clsx';

import useStyles from './styles';
import avatars from 'lib/helpers/dynamic-images/avatars';

interface ExistingUserProps {
  avatar?: string;
  name: string;
  info?: string | number;
  column?: boolean;
  selected?: boolean;
  onClick?: any;
  disabled?: boolean;
  icon?: string;
  extrainfo?: string | number;
}

const ExistingUser = ({
  avatar,
  info,
  name,
  column,
  selected,
  onClick,
  disabled,
  icon,
  extrainfo,
}: ExistingUserProps) => {
  const classes = useStyles();
  return (
    <Card
      className={clsx({
        [classes.card]: true,
        selected: selected,
        disabled: disabled,
      })}
    >
      <CardActionArea
        disabled={disabled}
        className={clsx({
          [classes.content]: true,
          column: column,
        })}
        onClick={onClick}
      >
        {icon ? (
          <Avatar className={clsx({ [classes.disabled]: disabled })}>
            <i className={clsx(icon, classes.icon)} />
          </Avatar>
        ) : (
          <Avatar src={avatar ?? avatars(name)} />
        )}
        <ListItemText
          primary={name}
          secondary={info}
          className={clsx({
            [classes.info]: true,
            center: column,
            disabled: disabled,
          })}
        />
        {extrainfo ? (
          <Typography variant="body2" color="secondary">
            {extrainfo}
          </Typography>
        ) : null}
      </CardActionArea>
    </Card>
  );
};

export default ExistingUser;
