import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box, Tooltip, Fab } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import domtoimage from 'dom-to-image';
import dayjs from 'dayjs';

import FilterReports from 'components/FilterReports';
import ContentCard from 'components/ContentCard';
import Chart from 'components/Chart';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import CurrentDateIndicator from 'components/CurrentDateIndicator';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import Results from 'components/Results';
import DialogPDFViewer from 'components/DialogPDFViewer/index';

import {
  ReportFilter,
  MyDocRangeAnteMortem,
  GraphRangeAnteMortem,
  GraphRangeAnteMortemTwo,
} from './../../Reports/ReportFilter';

import useStyles from './styles';

const Main = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/ante-mortem',
      title: 'Ante-Mortem',
    },

    { link: '/app/reports', title: 'Mis Reportes' },
  ];
  const initDate = dayjs().startOf('month').format('YYYY-MM-DDT00:00:00-05:00');
  const current = dayjs().format('YYYY-MM-DDT23:59:59-05:00');
  const currentR = dayjs().format('DD/MM/YYYY');

  const [openFilterAnteMortem, setOpenFilterAnteMortem] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');
  const [imageGraphTwo, setImageGraphTwo] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenQrAnteMortenFilter');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraph = () => {
    const graph: any = document.getElementById('graphrangeantemortem');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraphTwo = () => {
    const graph: any = document.getElementById('graphrangeantemortemtwo');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraphTwo(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeAnteMortem = () => {
    // generateScreenshot();
    // screenshotGraph();
    // screenshotGraphTwo();
    setOpenFilterAnteMortem(true);
  };

  useEffect(() => {
    generateScreenshot();
    screenshotGraph();
    screenshotGraphTwo();
  }, [openFilterAnteMortem]);

  const rows = [
    {
      id: 1,
      numberLote: '654372',
      producer: 'Leidy Marcela Álvarez Mejía',
      farm: 'Cerditos Leidy',
      totalAnimals: 24,
      fallen: 0,
      agitated: 0,
      diedOnCorrals: 0,
      diedOnTransport: 0,
      diedOnDisembarkation: 0,
    },
    {
      id: 2,
      numberLote: '437220',
      producer: 'Leidy Álvarez',
      farm: 'Cerditos Gorditos',
      totalAnimals: 30,
      fallen: 2,
      agitated: 0,
      diedOnCorrals: 0,
      diedOnTransport: 0,
      diedOnDisembarkation: 2,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'numberLote',
      headerName: 'Nro. Lote',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'producer',
      headerName: 'Productor',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
    },
    {
      field: 'farm',
      headerName: 'Granja',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'totalAnimals',
      headerName: 'Animales Lote',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'fallen',
      headerName: 'Caídos',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'agitated',
      headerName: 'Agitados',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'diedOnCorrals',
      headerName: 'Muertos Corrales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'diedOnTransport',
      headerName: 'Muertos Transporte',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'diedOnDisembarkation',
      headerName: 'Muertos Desembarque',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: '',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/ante-mortem/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.btnDetails}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportFilter />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphRangeAnteMortem />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphRangeAnteMortemTwo />
      </div>

      <div className={classes.breadContainer}>
        <CurrentDateIndicator initialDate={initDate} finalDate={current} />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <FilterReports to="ante-mortem/search" />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ContentCard>
            <ContentCard.Header>
              Animales Muertos Transporte / Desembarque
            </ContentCard.Header>
            <Chart
              type="doughnut"
              data={[
                {
                  data: 1,
                  label: 'Muertos en transporte',
                  bg: '#FFC676',
                },
                {
                  data: 4,
                  label: 'Muertos en desembarque',
                  bg: '#D47D57',
                },
              ]}
              graphicSize={150}
            />
          </ContentCard>
        </Grid>
        <Grid item xs={6}>
          <ContentCard>
            <ContentCard.Header>
              Animales Caídos / Agitados / Muertos Corrales
            </ContentCard.Header>
            <Chart
              type="doughnut"
              data={[
                {
                  data: 1,
                  label: 'Animales caídos en corrales',
                  bg: '#FFC676',
                },
                {
                  data: 1,
                  label: 'Animales agitados en corrales',
                  bg: '#E29662',
                },
                {
                  data: 2,
                  label: 'Animales muertos en corrales',
                  bg: '#D47D57',
                },
              ]}
              graphicSize={150}
            />
          </ContentCard>
        </Grid>
        <Grid item xs={6}>
          <ContentCard marginTop="0px">
            <Box className={classes.withPadding}>
              <Typography variant="h5" className={classes.textImportant}>
                Report Ante-mortem del día
              </Typography>
              <Button
                startIcon={<i className="icon-eye" />}
                variant="contained"
                className="raleway"
                color="primary"
                onClick={openModalRangeAnteMortem}
              >
                Visualizar
              </Button>
            </Box>
          </ContentCard>
        </Grid>
        <Grid item xs={6}>
          <ContentCard marginTop="0px">
            <Box className={classes.withPadding}>
              <Typography variant="h5" className={classes.textImportant}>
                Total Animales Caídos / Agitados / Muertos
              </Typography>
              <Typography variant="h4" className={classes.infoImportant}>
                08
              </Typography>
            </Box>
          </ContentCard>
        </Grid>
        <Grid item xs={12}>
          <ContentCard marginTop="0px">
            <Box className={classes.withPadding}>
              <Typography variant="h5" className={classes.textImportant}>
                Detalle Inspección Ante-Mortem del día
              </Typography>
              <Results number={rows.length} />
            </Box>
            <Box>
              <DataGrid
                columns={columns}
                rows={rows}
                autoHeight
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 15, 20]}
                page={page}
                onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                onPageChange={(e: any) => setPage(e.page)}
                components={{
                  Footer: TableFooter,
                }}
                className={classes.dataGrid}
              />
            </Box>
          </ContentCard>
        </Grid>
      </Grid>
      <DialogPDFViewer
        open={openFilterAnteMortem}
        onClose={() => setOpenFilterAnteMortem(false)}
        done={Boolean(imageQr)}
      >
        <MyDocRangeAnteMortem
          qr={imageQr}
          graph={imageGraph}
          graphTwo={imageGraphTwo}
          range={currentR}
        />
      </DialogPDFViewer>
    </div>
  );
};

export default Main;
