import React from 'react';
import {
  Slider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import Cropper from 'react-easy-crop';

import UPLOAD_PICTURE_MUTATION from 'core/graphql/mutations/uploadProfilePicture';
import { NEW_AVATAR } from 'core/redux/types/userTypes';

import base64ToFile from 'lib/helpers/base64ToFile';
import useSetToken from 'lib/hooks/useSetToken';
import ID from 'lib/helpers/random-id';

import { getCroppedImg } from './canvasUtils';

import useStyles from './styles';

interface FormDialogProps {
  open: boolean;
  img: any;
  onClick: () => void;
  onClose: () => void;
}

export default function FormDialog({
  open,
  img,
  onClose,
  onClick,
}: FormDialogProps) {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const setToken = useSetToken();
  const [cropImg, setCrop] = React.useState({ crop: { x: 0, y: 0 } });
  const [zoomImg, setZoom] = React.useState({ zoom: 1 });
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [uploadPicture, { loading }] = useMutation(UPLOAD_PICTURE_MUTATION);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onCropChange = (crop: any) => {
    setCrop({ crop });
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom: any) => {
    setZoom({ zoom });
  };

  const showCroppedImage = React.useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(img, croppedAreaPixels);
      const uploadFile = base64ToFile(croppedImage, `${ID()}.png`);

      uploadPicture({
        variables: { file: uploadFile },
        context: {
          headers: {
            'x-auth-jwt': token,
          },
        },
      })
        .then(({ data, errors }: any) => {
          if (data?.updateProfilePicture) {
            dispatch({
              type: NEW_AVATAR,
              payload: data?.updateProfilePicture?.results?.path,
            });
            enqueueSnackbar('Perfil actualizado', {
              variant: 'success',
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            });
            setToken(data?.updateProfilePicture?.token?.value);
            onClose();
          }

          if (errors) {
            enqueueSnackbar('Ha ocurrido un error', {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            });
          }
        })
        .catch((error) =>
          enqueueSnackbar(error.toString(), {
            variant: 'error',
            autoHideDuration: 5000,
          })
        );
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        className={classes.pictureDialog}
      >
        {loading && (
          <div className={classes.loadingBackdrop}>
            <Typography variant="h4" color="textSecondary">
              Guardando
            </Typography>
            <LinearProgress className="progress" color="primary" />
          </div>
        )}
        <DialogContent>
          <div className={classes.dialogContainer}>
            <Typography variant="h5" className={classes.titleDialog}>
              Cambiar imagen de perfil
            </Typography>
            <Button
              variant="contained"
              className={classes.doneButtonModal}
              onClick={showCroppedImage}
            >
              Hecho
            </Button>
          </div>

          <div className={classes.cropperContainer}>
            <Cropper
              image={img}
              crop={cropImg.crop}
              zoom={zoomImg.zoom}
              aspect={1 / 1}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.buttonsModal}>
          <ZoomOutIcon style={{ color: '#DD9835' }}></ZoomOutIcon>
          <Slider
            className={classes.slider}
            value={zoomImg.zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => onZoomChange(zoom)}
          />
          <ZoomInIcon style={{ color: '#DD9835' }}></ZoomInIcon>
        </DialogActions>
      </Dialog>
    </div>
  );
}
