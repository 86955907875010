import { gql } from 'apollo-boost'

export const GET_NOTIFICATIONS = gql`
  query notifications($limit: Int) {
    notifications(limit: $limit) {
      id
      id_user
      description
      date
      state
      inactive_date
      read
      read_date
      category {
        prefix
      }
      sender {
        id
        image
        username
      }
    }
  }
`

export const GET_UNREAD_NOTIFICATIONS = gql`
  query unread {
    unreadNotifications
  }
`

export const TOOGLE_READ_STATE = gql`
  mutation toggleRead($id: Int!) {
    toggleReadState(id: $id)
  }
`

export const MARK_ADD_READ_ALL = gql`
  mutation {
    markAsReadAll
  }
`