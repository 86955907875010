import PagesContainer from 'components/PagesContainer';

import { GenerateRoutes as Routes } from 'routes';

import { Main, Details, route } from './views';

const Transport = () => {
  return (
    <PagesContainer>
      <Routes MainPage={Main} DetailsPage={Details} nested={route} />
    </PagesContainer>
  );
};

export default Transport;
