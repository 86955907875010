import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import { NestedRoute } from 'lib/interfaces/routes';

interface GenerateMultilevelRoutesProps {
  nested: Array<NestedRoute>;
  redirect?: string;
}

const GenerateMultilevelRoutes = ({
  nested,
  redirect,
}: GenerateMultilevelRoutesProps) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {nested.map((route, i: number) => (
        <Route
          key={i}
          path={`${path}${route.route}`}
          component={route.Node}
          exact={route.exact ?? false}
        />
      ))}
      {redirect && <Redirect from={path} to={`${path}${redirect}`} exact />}
    </Switch>
  );
};

export default GenerateMultilevelRoutes;
