import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import domtoimage from 'dom-to-image';
import clsx from 'clsx';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import ItemListInfo from 'components/ItemListInfo';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableInfo from 'components/TableInfo';
import GobackLink from 'components/GobackLink';
import LoteInfoTitle from 'components/LoteInfoTitle';
import DialogPDFViewer from 'components/DialogPDFViewer/index';

import { MyDocInmune, ReportLot } from './../../Reports/ReportLot';

import useStyles from './styles';

const index = () => {
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/inmunecastration/details',
      title: 'Certificados de Inmunocastración',
    },
  ];
  const classes = useStyles();

  const [imageQr, setImageQr] = useState('');
  const [openInmune, setOpenInmune] = useState(false);

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenLotImmune');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalLoteInmune = () => {
    setOpenInmune(true);
  };

  useEffect(() => {
    generateScreenshot();
  }, [openInmune]);

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoteInfoTitle
              lot="7645"
              namereport="Nro. Reporte"
              numberreport="011-65432"
              title="Certificados de inmunocastración"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6} xl={6}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Agentes relacionados
              </Typography>
            </div>
            <div className={classes.flexContainer}>
              <div style={{ width: '48%' }}>
                <TableInfo
                  icon="icon-productor"
                  title="Productor"
                  items={{
                    Nombre: 'Cualquier productor',
                    NIT: '45134132',
                    Teléfono: '+57 314 6543 543',
                    Email: 'info@productor.com',
                    Dirección: 'Calle 105 A 23-23',
                  }}
                />
              </div>
              <div style={{ width: '48%' }}>
                <TableInfo
                  icon="icon-granja"
                  title="Granja"
                  items={{
                    Nombre: 'Nombre Planta',
                    Responsable: '45134132',
                    Teléfono: '+57 314 6543 543',
                    Email: 'info@productor.com',
                    Dirección: 'Calle 105 A 23-23',
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6} xl={6}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Proceso Veterinario
              </Typography>
            </div>
            <div className={classes.flexContainer}>
              <div style={{ width: '48%' }}>
                <TableInfo
                  title="Médico Veterinario"
                  items={{
                    Nombre: 'Cualquier productor',
                    Registro: '765432234',
                    Teléfono: '+57 314 6543 543',
                  }}
                />
              </div>
              <div style={{ width: '48%' }}>
                <TableInfo
                  title="Protocolo de Vacunación"
                  items={{
                    'Fecha Primera Dósis': '22/10/2019',
                    'Fecha Segundo Lote': '24/10/2019',
                    'Nro. Lote de Vacuna': '+57 314 6543 543',
                  }}
                />
              </div>
            </div>
            <div className={classes.centerContainer}>
              <div className={classes.miniGrayContainer}>
                <ItemListInfo
                  icon="icon-ticket"
                  titleInfo="Certificado de inmunocastración"
                  numberInfo="2315375"
                  width="100%"
                />
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Resumen del Proceso
              </Typography>
            </div>
            <div
              className={clsx(classes.flexContainer, classes.responsiveColumn)}
            >
              <div className={classes.paddingContainer}>
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={58}
                  titleInfo="Total Animales Inmunocastrados"
                  avatarTotalColor="#E29662"
                  avatarTotalSize={60}
                  avatarTextSize={22}
                  titleInfoSize={250}
                />
              </div>
              <div
                className={clsx(
                  classes.flexContainer,
                  classes.bigWidthContainer
                )}
              >
                <div className={classes.midContainer}>
                  <ItemListInfo
                    icon="icon-calendar"
                    titleInfo="
                    Fecha de Embarque"
                    numberInfo="22/10/2019"
                    width="100%"
                  />
                  <ItemListInfo
                    icon="icon-ticket"
                    titleInfo="Lote de Ceba"
                    numberInfo="123456"
                    width="100%"
                  />
                  <ItemListInfo
                    icon="icon-planta"
                    titleInfo="Planta Destino"
                    numberInfo="Central Ganadera"
                    width="100%"
                  />
                </div>
                <div className={classes.midContainer}>
                  <ItemListInfo
                    icon="icon-user"
                    titleInfo="Tranportador"
                    numberInfo="Nombre Transportador"
                    width="100%"
                  />
                  <ItemListInfo
                    icon="icon-truck"
                    titleInfo="Placa Vehículo"
                    numberInfo="JRD 678"
                    width="100%"
                  />
                  <ItemListInfo
                    icon="icon-document"
                    titleInfo="Guía Sanitaria ICA"
                    numberInfo="9876543"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </ContentCard>
      <Grid container justify="flex-end" alignItems="center">
        <Box mr={2}>
          <Typography className={classes.colorUp}>
            Opciones del Reporte
          </Typography>
        </Box>
        <Button
          startIcon={<i className="icon-eye" />}
          variant="contained"
          className="raleway"
          color="primary"
          onClick={openModalLoteInmune}
        >
          Previsualizar
        </Button>
      </Grid>
      <DialogPDFViewer
        open={openInmune}
        onClose={() => setOpenInmune(false)}
        done={Boolean(imageQr)}
      >
        <MyDocInmune qr={imageQr} />
      </DialogPDFViewer>
    </div>
  );
};

export default index;
