import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import useStyles from "./styles";

interface ItemListDataProps {
  data: Array<{
    title: string;
    data: any;
    colorData?: string;
    link?: any;
  }>;
  direction?: "column" | "row";
  withoutListStyle?: boolean;
}

const ItemListData = ({
  data,
  direction,
  withoutListStyle,
}: ItemListDataProps) => {
  const classes = useStyles();
  return (
    <ul
      className={clsx(classes.listContainer, direction, {
        [classes.withoutListStyle]: withoutListStyle,
      })}
    >
      {data.map((data: any) => (
        <li>
          <div className={classes.itemListData}>
            <Typography>{data.title}</Typography>
            {data.link ? (
              <div className={classes.itemFlex}>
                <Typography
                  style={{ color: data.colorData }}
                  className={classes.itemChild}
                >
                  {data.data}
                </Typography>
                <IconButton onClick={data.link}>
                  <WhatsAppIcon color="primary" />
                </IconButton>
              </div>
            ) : (
              <Typography
                style={{ color: data.colorData }}
                className={classes.itemChild}
              >
                {data.data}
              </Typography>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ItemListData;
