import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  copy: {
    position: 'absolute', 
    bottom: 25, 
    textAlign: 'center', 
    fontSize: 6, 
    marginLeft: 43
  },
  phone: {
    marginTop: 7,
    marginBottom: 7
  },
})