import { BannerContainer, Overlay, TextContainer } from './styles';

export const AuthBannerImage = () => {
  return (
    <BannerContainer>
      <TextContainer>
        <h1>
          ¡Bienvenido <br /> A InfoPorcinos!
        </h1>
        <h5>
          La mejor plataforma de Información para el sector de la carne de
          cerdo.
        </h5>
      </TextContainer>
      <Overlay />
    </BannerContainer>
  );
};
