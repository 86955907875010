import React from 'react';
import { Typography } from '@material-ui/core';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import GobackLink from 'components/GobackLink';
import ContentCard from 'components/ContentCard';
import IndicativeTable from 'components/IndicativeTable';
import Maps from 'components/maps/corrals/central-ganadera';

import useStyles from './styles';

const CorralsRealTime = () => {
  const classes = useStyles();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/inventory/corral', title: 'Inventario Corrales' },
  ];
  return (
    <>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard>
        <ContentCard.Header date={new Date().toDateString()}>
          Inventario de Animales en Pie en Corrales de Planta de Beneficio
        </ContentCard.Header>
        <div className={classes.containerMap}>
          <Maps />
        </div>

        <div className={classes.tableContainer}>
          <IndicativeTable
            title="Distribución de Corrales por Tiempo de Estancia"
            item={{
              'Menos de 4 horas (Reposo Obligatorio)': '#767676',
              '4- 8 horas - Disponibles para Beneficio': '#57E28C',
              '8-12 horas': '#ED8C2B',
              '+12 horas': '#E2575C',
            }}
          />
        </div>
      </ContentCard>
    </>
  );
};

export default CorralsRealTime;
