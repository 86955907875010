import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container:{
    width: '100%',
    margin: '20px 0px',
    padding: '20px 40px',
    borderRadius: 20, 
    boxShadow: theme.shadows[3], 
  },  
  titleContainer:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    margin: '20px 0px', 
    borderRadius: 20, 
    boxShadow: theme.shadows[3], 
    padding: '20px ',
  }, 
  title: {
    display:'flex', 
    alignItems:'center',
    fontSize: 24,
    "& i":{
      fontSize: 30, 
      marginLeft: 4
    }
  }, 
  gridContainer:{
    marginTop: 24
  }, 
  switch:{
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      background: theme.palette.success.main,
      opacity: 1
    },
    "& .MuiSwitch-colorSecondary.Mui-checked":{
      color: "#fff"        
    }
  },
  card: {
    minWidth: 200,
    borderRadius: 10,
    boxShadow: theme.shadows[2],
    margin: 12,
  },
  content: {
    height: 120,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
  },
  download:{
    color: '#AFAFAF', 
    marginRight: 8, 
    "&:hover":{
      color: '#DD9835'
    }
  }, 
  flexColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    width: '100%',
    margin: '8px',
    borderRadius: 10,
  },
  scheduleTitle: {
    width: '100%',
    padding: '4px',
    textAlign: 'center',
    background: '#dd983573',
    color: '#767676',
  },
  scheduleDisabledTitle: {
    width: '100%',
    padding: '4px',
    textAlign: 'center',
    background: '#AFAFAF',
    color: '#FFF',
  },
  scheduleContent: {
    width: '100%',
    height: '60px',
    padding: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerLeft:{
    width: '100%', 
    marginRight: '12px !important', 
    marginTop: '12px !important', 
    marginBottom: '12px !important', 
    [theme.breakpoints.down('lg')]:{
    marginRight: "0px !important", 
    }
  }, 
  containerRight:{
    width: '100%', 
    marginLeft: '12px !important', 
    marginTop: '12px !important', 
    marginBottom: '12px !important', 
    [theme.breakpoints.down('lg')]:{
    marginLeft: "0px !important", 
    }
  }
}));

export default useStyles;
