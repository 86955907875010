import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import _ from 'lodash';
import { Remarkable } from 'remarkable';

import AvatarBadge from '../avatarBadge';

import useStyles from './styles';

export interface BlockAccountDialogProps {
  open: boolean;
  title?: string;
  message?: string;
  onlyAcceptButton?: boolean;
  showName?: boolean;
  info: {
    name?: string;
    username?: string;
    link?: string;
  };
  onClose?: () => void;
}

const BlockedAccountDialog = ({
  open,
  title,
  message,
  onlyAcceptButton,
  showName = true,
  info: { name = '', username, link },
  onClose,
}: BlockAccountDialogProps) => {
  const classes = useStyles();

  const redirectToAuth = () => window.location.replace(link ?? '');

  const MD = () => {
    if (message) {
      const md = new Remarkable();
      return md.render(message);
    }
    return ', estamos en un proceso de actualización, te solicitamos complementar tus datos, si es el caso, para continuar usando la plataforma InfoPorcinos™.';
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      fullWidth
      classes={{ paper: classes.root }}
    >
      <AvatarBadge
        sizes={{ avatar: 100, badge: 35 }}
        classes={{ root: classes.avatarRoot, avatar: classes.avatar }}
        Icon={LockOutlined}
      >
        {name?.toUpperCase()?.substr(0, 1)}
      </AvatarBadge>
      <DialogContent>
        <Typography variant="h4" className={classes.title}>
          {title ?? 'Verificación de Datos'}
        </Typography>
        <Typography color="textSecondary">
          Hola,
          {showName && (
            <span className={classes.user}> {_.upperCase(name)}</span>
          )}{' '}
          <span dangerouslySetInnerHTML={{ __html: MD() }} />
        </Typography>
      </DialogContent>
      <DialogActions>
        {!onlyAcceptButton ? (
          <>
            <Button size="small" onClick={onClose}>
              No soy {username}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={redirectToAuth}
            >
              Continuar
            </Button>
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={onClose}>
            Aceptar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BlockedAccountDialog;
