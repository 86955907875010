import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  button:{
    fontSize: 30, 
    color: "#767676", 
    "&.disable":{
      color: "transparent",
    }
  },
  
}))

export { useStyles as default }
