import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container:{
    width:'60%', 
    padding: '24px !important', 
  },
  title:{
    fontWeight: 'bold', 
    borderBottom: 'solid 1px #E2E2E2', 
  }, 
  infoContainer:{
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    padding: 20
  },
  info:{
    maxWidth: '40%', 
    padding: 24, 
    margin: 20
  },
  subtitle:{
    fontWeight: 'bold', 
    color: '#767676'
  }, 
  text:{
    color: '#AFAFAF', 
    fontSize: 16, 
    margin: '12px 0px'
  }
}))

export { useStyles as default }