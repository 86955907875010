import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogContainer:{
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px'
    }
  },
  contentDialog:{
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'center', 
  },
  containerPrimaryInfo:{
    marginBottom: 20
  }, 
  primaryInfoContent:{
    display: 'flex',
    padding: '4px 8px',
    background: '#F2F2F2',
    justifyContent: 'space-between',
  }, 
  info:{
    color: '#AFAFAF',  
    fontWeight: 'bold', 
    margin: '0px 4px'
  }, 
  itemGrey :{
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dialogFooter:{
    display: 'flex', 
    justifyContent: 'space-evenly',
    alignItems: 'center', 
    margin: '12px 0px'
  }
}))

export { useStyles as default }
