import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const navbarWidth = 280

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 4,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeOut
    }),
    [theme.breakpoints.up('lg')]: {
      width: navbarWidth,
      minWith: navbarWidth
    }
  },
  wrapperFolded: {
    [theme.breakpoints.up('lg')]: {
      width: 64,
      minWidth: 64
    }
  },
  navbar: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    flex: '1 1 auto',
    width: navbarWidth,
    minWidth: navbarWidth,
    height: '100%',
    zIndex: 4,
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter
    }),
    boxShadow: theme.shadows[3],
    left: 0,
    background: '#2C2C2C'
  },
  folded: {
    position: 'absolute',
    width: 64,
    minWidth: 64,
    top: 0,
    bottom: 0,
  },
  foldedAndOpened: {
    width: navbarWidth,
    minWidth: navbarWidth,
  },
  navbarContent: {
    flex: '1 1 auto',
  },
  btns: {
    width: navbarWidth, 
    minWidth: navbarWidth, 
    display: 'flex',
    justifyContent: 'space-between', 
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter
    }),
    padding: '8px 12px'
  }, 
  logout:{
    background: '#FFFFFF'
  },
  down:{
    color: theme.palette.primary.main, 
    "&:hover":{
      color: theme.palette.primary.dark, 
    }
  },
  version:{
    width: navbarWidth,
    minWidth: navbarWidth,
    color: '#AFAFAF', 
    textAlign: 'center', 
    paddingBottom: 4
  },
  foldedAndClosed: {
    '& $navbarContent': {
      '& .logo-icon': {
        width: 32,
        height: 32,
      },
      '& .logo-text': {
        opacity: 0,
      },
      '& .react-badge': {
        opacity: 0,
      },
      '& .list-item-text, & .arrow-icon, & .item-badge': {
        opacity: 0,
      },
      '& .list-subheader .list-subheader-text': {
        opacity: 0,
      },
      '& .list-subheader:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        minWidth: 16,
        borderTop: '2px solid',
        opacity: 0.2,
      },
      '& .collapse-children': {
        display: 'none',
      },
      '& .user': {
        '& .name, & .username': {
          opacity: 0,
        },
        '& .avatar': {
          width: 40,
          height: 40,
          top: 32,
          padding: 0,
        },
      },
      '& .list-item.active': {
        marginLeft: 12,
        width: 40,
        padding: 12,
        borderRadius: 20,
        '&.square': {
          borderRadius: 0,
          marginLeft: 0,
          paddingLeft: 24,
          width: '100%',
        },
      },
    },
    '& $btns':{
      display: 'none'
    }, 
    '& $version':{
      display: 'none'
    }
  },
}))

export default useStyles