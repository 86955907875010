import { gql } from "apollo-boost";

export const ALL_NAVIGATION = gql`
  query ($idRol: Int!) {
    getAllNavigation(input: { idRol: $idRol }) {
      data {
        idCategory
        idSubCategory
        type
        name
        url
        icon
        state
        order
        children {
          idCategory
          idSubCategory
          type
          name
          url
          icon
          state
          order
        }
      }
    }
  }
`;
