import {
  Badge,
  Box,
  Button,
  ButtonBase,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
} from '@material-ui/core';
import {
  Check,
  Delete,
  MoreHoriz,
  NotificationsActive,
  NotificationsNoneOutlined,
  SettingsOutlined,
  ChevronLeft,
} from '@material-ui/icons';
import {useContext, useState} from 'react';
import {useMutation} from '@apollo/client';
import {useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {AnimatePresence, motion} from 'framer-motion';
import dayjs from 'dayjs';
import clsx from 'clsx';
import _ from 'lodash';

import config from 'config/secrets';

import {
  MARK_ADD_READ_ALL,
  TOOGLE_READ_STATE,
} from 'core/graphql/queries/notifications';

import NotificationContext from 'lib/context/NotificationContext';
import notificationIcons from 'lib/helpers/notification-icon';
import avatars from 'lib/helpers/dynamic-images/avatars';

import NoDataOverlay from 'components/NoDataOverlay';

import AvatarBadge from '../avatarBadge';

import useStyles from './styles';

const NotificationItem = ({updateNotifications, ...noti}: any) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const [anchorEl, setAnchorEl] = useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const [toggleReadState] = useMutation(TOOGLE_READ_STATE, {
    context: {headers: {'x-auth-jwt': token}},
  });

  const handleOpen = (e: any) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleReadState = () => {
    handleClose();
    toggleReadState({variables: {id: noti.id}})
      .then((response) => {
        if (response.data) {
          updateNotifications();
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), {
          anchorOrigin: {horizontal: 'right', vertical: 'top'},
        })
      );
  };

  function getAvatar() {
    if (noti.avatar) return avatars(noti.avatar);
    if (noti?.sender) {
      if (noti?.sender?.image) return `${config.SOCKETS}${noti?.sender?.image}`;
      return avatars(noti?.sender?.username);
    }
  }

  function getIcon() {
    return (
      notificationIcons[
        noti.category.prefix as keyof typeof notificationIcons
        ] ?? notificationIcons.DEFAULT
    );
  }

  const options = [
    {
      description: `Marcar como ${noti.read ? 'no' : ''} leída`,
      Icon: Check,
      func: handleReadState,
    },
    {
      description: 'Eliminar esta notificación', Icon: Delete, func: () => {
      }
    },
  ];

  return (
    <div
      className={clsx({
        [classes.list_item_container]: true,
        [classes.notRead]: !noti.read,
      })}
    >
      <ButtonBase className={classes.list_item}>
        <div>
          <AvatarBadge
            className={classes.avatar_item}
            classes={{
              icon: classes.avatar_icon,
            }}
            src={getAvatar()}
            Icon={getIcon()}
            sizes={{
              avatar: 50,
              badge: 20,
            }}
          />
        </div>
        <div className={classes.notification_text}>
          <Typography
            color="textPrimary"
            className={classes.notification_text_main}
            dangerouslySetInnerHTML={{__html: noti.description}}
          />
          <Typography component="p" variant="caption" color="textSecondary">
            {_.capitalize(dayjs(noti.date).startOf(noti.date).toString())}{' '}
            {noti.read_date &&
            `• Leído el ${dayjs(noti.read_date).format('dddd, DD [de] MMMM')}`}
          </Typography>
        </div>
      </ButtonBase>
      <div className={classes.close_btn_container}>
        <IconButton size="small" onClick={handleOpen}>
          <MoreHoriz/>
        </IconButton>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {options.map((item, i) => (
            <ListItem
              key={i}
              classes={{root: classes.list_item_noti_element}}
              button
              onClick={item.func}
            >
              <ListItemIcon classes={{root: classes.list_item_noti_icon}}>
                <item.Icon/>
              </ListItemIcon>
              <ListItemText primary={item.description}/>
            </ListItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};

const Notifications = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const {notifications, refetch, unread} = useContext(NotificationContext);
  const [markReadAll] = useMutation(MARK_ADD_READ_ALL, {
    context: {headers: {'x-auth-jwt': token}},
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [settings, setSettings] = useState(false)

  const variants = {
    enter: {
      x: -300,
      opacity: 0
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    }
  }

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSettings(false);
  };

  const updateNotifications = () => {
    refetch();
  };

  const handleReadAll = () => {
    markReadAll().then((response) => {
      if (response.data) refetch();
    });
  };

  const handleClickSettings = () => setSettings(true)

  const handleCloseSettings = () => setSettings(false)

  return (
    <>
      <IconButton className={classes.button} onClick={handleClick}>
        <Badge badgeContent={unread} color="primary">
          {unread > 0 ? <NotificationsActive/> : <NotificationsNoneOutlined/>}
        </Badge>
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.menu_paper,
          list: classes.menu_list,
        }}
      >
        <AnimatePresence initial={false}>
          {!settings && (
            <motion.div
              key='notifications'
              initial={{x: -300, opacity: 0}}
              animate={{x: 0, opacity: 1}}
              exit={{x: 300, opacity: 0}}
              transition={{
                duration: 0.3,
                x: {type: 'spring', stiffness: 200, damping: 30},
                opacity: {duration: 0.3},
                delay: 0.5
              }}
            >
              <ListItem className={classes.menu_list_title}>
                <Typography variant="h6">Notificaciones</Typography>
                <IconButton size="small" onClick={handleClickSettings}>
                  <SettingsOutlined/>
                </IconButton>
              </ListItem>
              <Divider/>
              {notifications.length ? (
                <>
                  {notifications.map((noti: any) => (
                    <NotificationItem
                      key={noti.id}
                      updateNotifications={updateNotifications}
                      {...noti}
                    />
                  ))}
                  {unread > 0 && (
                    <Box paddingRight={3} textAlign="right">
                      <Typography
                        variant="caption"
                        className={classes.read_all}
                        color="textSecondary"
                        onClick={handleReadAll}
                      >
                        Marcar todas como leidas
                      </Typography>
                    </Box>
                  )}
                  <ListItem style={{justifyContent: 'center'}}>
                    <Button variant="text" size="small">
                      Ver todas
                    </Button>
                  </ListItem>
                </>
              ) : (
                <ListItem>
                  <NoDataOverlay message="No tienes notificaciones"/>
                </ListItem>
              )}
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence initial={false}>
          {settings && (
            <motion.div
              key='settings'
              initial={{x: 300, opacity: 0}}
              animate={{x: 0, opacity: 1}}
              exit={{x: -300, opacity: 0}}
              transition={{
                duration: 0.3,
                x: {type: 'spring', stiffness: 200, damping: 30},
                opacity: {duration: 0.3},
                delay: 0.5
              }}
            >
              <span>
                <IconButton onClick={handleCloseSettings}>
                  <ChevronLeft/>
                </IconButton>
                Config
              </span>
            </motion.div>
          )}
        </AnimatePresence>
      </Menu>
    </>
  );
};

export default Notifications;
