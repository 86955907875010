import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  TextField,
  StepIconProps,
  TextFieldProps,
  Grow,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Fab,
  Card,
  Link,
  Typography,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import DateFnsUtils from '@date-io/date-fns';
import ES from 'date-fns/locale/es';
import dayjs from 'dayjs';

import unmaskPhone from 'lib/helpers/unmaskPhone';
import {
  DriverValidationStepOne,
  DriverValidationStepThree,
  DriverValidationStepTwo,
  VehicleValidationStepOne,
  VehicleValidationStepTwo,
} from 'lib/validations/TransportValidation';
import constants from 'config/constants';

import PhoneNumber from 'components/PhoneNumber';
import SelectCountry from 'components/SelectCountry/index';
import DocumentTypes from 'components/DocumentTypes';
import InputPlate from 'components/InputPlate/index';
import Direction from 'components/Direction/index';
import GobackLink from 'components/GobackLink';
import ContentCard from 'components/ContentCard';
import MainTitle from 'components/MainTitle';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import CardSelectDriver from 'components/TransportComponents/CardSelectDriver';

import DialogConfirmData from '../../Details/DialogConfirmData/index';
import { CREATE_DRIVER } from 'core/graphql/mutations/transport';
import {
  GET_DRIVERS_EXISTS,
  GET_TYPE_LICENSE_CATEGORY,
  GET_VEHICLE,
} from 'core/graphql/queries/transport';

import useStyles from './styles';
import Loader from 'components/Loader';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

type CompononentProps = {
  id: number;
};

const RegisterDriverXVehicle = ({ id }: CompononentProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [openNotificationConfirm, setOpenNotificationConfirm] = useState({
    open: false,
    icon: '',
    title: '',
    message: '',
  });
  const [conf, setConf] = useState(false);
  const [stepFormDriver, setStepFormDriver] = useState(0);
  const [selectedDate, setSelectedDate] = useState<any>(dayjs());
  const [licenseCategory, setLicenseCategory] = useState([
    { idCategoriaLicencia: '', fechaVigencia: dayjs().format('YYYY/MM/DD') },
  ]);
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '#',
      title: 'Registrar Conductor',
    },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];

  const { data, loading, error } = useQuery(GET_VEHICLE, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idVehiculo: Number(id) },
  });

  const {
    data: dataTypeLicenseCategory,
    loading: loadingTypeLicenseCategory,
    error: errorTypeLicenseCategory,
  } = useQuery(GET_TYPE_LICENSE_CATEGORY, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const [createDriver, { loading: loadingDriver }] = useMutation(
    CREATE_DRIVER,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const postDriver = (data: any) => {
    createDriver({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.postDriver.data.success === 1) {
          enqueueSnackbar('Conductor creado exitosamente.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
          history.push('/app/network/transports');
        } else if (mutationResult.data.postDriver.data.success === 2) {
          enqueueSnackbar(
            'El conductor ingresado ya existe, quedara asociado.',
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'info',
            }
          );
          history.push('/app/network/transports');
        } else {
          enqueueSnackbar(mutationResult.data.postDriver.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        numeroLicenciaConduccion: Number(data.licenseNumber),
        fechaExpedicionLicencia: data.expeditionDate,
        categoriasLicencia: data.licenseCategory,
        idTipoDocumento: data.documentType,
        numeroDocumento: Number(data.document),
        primerNombre: data.firstName,
        segundoNombre: data.secondName,
        primerApellido: data.surname,
        segundoApellido: data.secondSurname,
        idTipoDireccion: data.directionType,
        idPais: data.country,
        idDepartamento: data.departament,
        idCiudad: data.city,
        idTipoVia: Number(data.viaType),
        numeroVia: Number(data.viaNumber),
        apendiceVia: data.apendNumber,
        numeroCruce: Number(data.cruceNumber),
        apendiceCruce: data.apendCruceNumber,
        metrosEsquina: Number(data.cornerMeters),
        adicional: data.aditional,
        codigoPostal: data.postalCode,
        indicativoTelefono: data.indicativePhone,
        telefono: data.phone,
        indicativoCelular: data.indicativeMobile,
        celular: data.mobile,
        email: data.email,
        esConductorReemplazo: data.replacement,
        idVehiculo: Number(id),
      },
    });
  };

  useEffect(() => {
    setConf(false);
  }, [openNotificationConfirm]);

  const handleNConfirm = (data: any) => {
    setOpenNotificationConfirm({
      open: false,
      icon: '',
      title: '',
      message: '',
    });
    setConf(true);
  };

  const handleOpenNConfirm = (
    icon: 'alert' | 'success' | 'error',
    title: string,
    message: string
  ) => {
    setOpenNotificationConfirm({
      open: true,
      icon: icon,
      title: title,
      message: message,
    });
  };

  const handleCloseNConfirm = () => {
    setOpenNotificationConfirm({
      open: false,
      icon: '',
      title: '',
      message: '',
    });
  };

  const formikDriver = useFormik({
    initialValues: {
      documentType: 1,
      document: '',
      firstName: '',
      secondName: '',
      surname: '',
      secondSurname: '',
      licenseNumber: '',
      expeditionDate: dayjs(),
      licenseCategory: [
        {
          idCategoriaLicencia: '',
          fechaVigencia: dayjs().format('YYYY/MM/DD'),
        },
      ],
      replacement: false,
      indicativePhone: constants.DEFAULT_DEPARTMENT_ID,
      phone: '',
      ext: '',
      indicativeMobile: constants.DEFAULT_COUNTRY_ID,
      mobile: '',
      email: '',
      directionType: 1,
      country: constants.DEFAULT_COUNTRY_ID,
      departament: constants.DEFAULT_DEPARTMENT_ID,
      city: constants.DEFAULT_CITY_ID,
      viaType: '',
      viaNumber: '',
      apendNumber: '',
      cruceNumber: '',
      apendCruceNumber: '',
      cornerMeters: '',
      aditional: '',
      postalCode: '',
      latitude: '',
      longitude: '',
    },
    validationSchema:
      stepFormDriver === 0
        ? DriverValidationStepOne
        : stepFormDriver === 1
        ? DriverValidationStepTwo
        : DriverValidationStepThree,
    validateOnChange: false,
    onSubmit: (values) => {
      switch (stepFormDriver) {
        case 0:
          setStepFormDriver(stepFormDriver + 1);
          break;
        case 1:
          setStepFormDriver(stepFormDriver + 1);
          break;
        case 2:
          postDriver(values);
          break;
        default:
          break;
      }
    },
  });

  const [
    getDataDriverExists,
    { data: dataDriverE, error: errorDriverE, loading: loadingDriverE },
  ] = useLazyQuery(GET_DRIVERS_EXISTS);

  const resultDriverE = dataDriverE?.getExistDriver?.data;

  useEffect(() => {
    if (dataDriverE) {
      handleOpenNConfirm(
        'alert',
        'El Conductor con el documento ingresdo ya existe',
        '¿Desea utilizar los datos guardados de este Conductor?'
      );
    }
  }, [dataDriverE]);

  if (dataDriverE && conf) {
    formikDriver.values.documentType = resultDriverE?.tipoDocumento;
    formikDriver.values.document = resultDriverE?.numeroDocumento;
    formikDriver.values.firstName = resultDriverE?.primerNombre;
    formikDriver.values.secondName = resultDriverE?.segundoNombre;
    formikDriver.values.surname = resultDriverE?.primerApellido;
    formikDriver.values.secondSurname = resultDriverE?.segundoApellido;
    formikDriver.values.licenseNumber = resultDriverE?.numeroLicenciaConduccion;
    formikDriver.values.expeditionDate = resultDriverE?.fechaExpedicionLicencia;
    formikDriver.values.replacement = resultDriverE?.esConductordeReemplazo;
    formikDriver.values.licenseCategory = resultDriverE?.categoriasLicencia;
    formikDriver.values.indicativeMobile = resultDriverE?.indicativoCelular;
    formikDriver.values.mobile = resultDriverE?.celular;
    formikDriver.values.email = resultDriverE?.correo;
    formikDriver.values.directionType = resultDriverE?.tipoDireccion;
    formikDriver.values.country = resultDriverE?.idPais;
    formikDriver.values.departament = resultDriverE?.idDepartamento;
    formikDriver.values.city = resultDriverE?.idCiudad;
    formikDriver.values.viaType = resultDriverE?.idTipoVia;
    formikDriver.values.viaNumber = resultDriverE?.numeroVia;
    formikDriver.values.apendNumber = resultDriverE?.apendiceVia;
    formikDriver.values.cruceNumber = resultDriverE?.numeroCruce;
    formikDriver.values.apendCruceNumber = resultDriverE?.apendiceCruce;
    formikDriver.values.cornerMeters = resultDriverE?.metrosEsquina;
    formikDriver.values.aditional = resultDriverE?.adicional;
    formikDriver.values.postalCode = resultDriverE?.codigoPostal;
    formikDriver.values.latitude = resultDriverE?.longitud;
    formikDriver.values.longitude = resultDriverE?.latitud;
    resultDriverE?.categoriasLicencia?.map((item: any, index: number) => {
      licenseCategory.push({
        idCategoriaLicencia: item?.idCategoriaLicencia,
        fechaVigencia: item?.fechaVigencia,
      });
    });
    licenseCategory.splice(0, 1);
  }

  const verifyDriver = (e: any) => {
    getDataDriverExists({
      variables: { numeroDocumento: e.target.value },
      context: { headers: { 'x-auth-jwt': token } },
    });
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    formikDriver.handleChange;
    formikDriver.values.expeditionDate = date;
  };

  const handleFormChange = (index: number, event: any, name: string) => {
    const data = [...licenseCategory];
    if (name === 'idCategoriaLicencia') {
      data[index].idCategoriaLicencia = event.target.value;
    } else {
      data[index].fechaVigencia = dayjs(event).format('YYYY/MM/DD');
    }
    setLicenseCategory(data);
    formikDriver.values.licenseCategory = data;
    formikDriver.handleChange;
  };

  const addFields = () => {
    let newfield = {
      idCategoriaLicencia: '',
      fechaVigencia: dayjs().format('YYYY/MM/DD'),
    };
    setLicenseCategory([...licenseCategory, newfield]);
  };

  const removeFields = (index: number) => {
    let data = [...licenseCategory];
    data.splice(index, 1);
    setLicenseCategory(data);
    formikDriver.values.licenseCategory = data;
  };

  const renderInput = (props: TextFieldProps): any => (
    <TextField
      label="Fecha de Vigencia"
      size="small"
      fullWidth
      variant="outlined"
      onClick={props.onClick}
      value={props.value}
      onChange={props.onChange}
    />
  );

  return (
    <>
      <Loader visible={loading}>Cargando...</Loader>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Box mb={2}>
          <Grid container justifyContent="center">
            <MainTitle>Registrar Conductor al Vehículo: </MainTitle>
            <Typography>
              <span className={classes.fontPlate}>
                {data?.getDetailVehicle?.data?.placa}
              </span>
            </Typography>
          </Grid>
        </Box>

        <form onSubmit={formikDriver.handleSubmit}>
          <Grid container>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={1}
            >
              <Fab
                color="primary"
                onClick={() => setStepFormDriver(stepFormDriver - 1)}
                className={clsx({
                  [classes.btnStep]: true,
                  [classes.hiddenButton]: stepFormDriver === 0,
                })}
              >
                <Box
                  component="i"
                  fontSize="20px"
                  fontWeight="bold"
                  className="icon-arrow-left"
                />
              </Fab>
            </Grid>

            <Grid item xs={10}>
              {stepFormDriver === 0 && (
                <Grow in timeout={800}>
                  <div>
                    <Box marginBottom={3} marginTop={1}>
                      <MainTitle fontSize="h6">
                        Datos Generales del Conductor
                        <span className={classes.fontInfoStep}>
                          (Paso {stepFormDriver + 1} de 3)
                        </span>
                      </MainTitle>
                    </Box>

                    <Box mb={2}>
                      <Grid container spacing={3}>
                        <Grid container item spacing={1}>
                          <Grid item xs={6}>
                            <DocumentTypes
                              name="documentType"
                              value={formikDriver.values.documentType}
                              onChange={formikDriver.handleChange}
                              entity={1}
                              errors={formikDriver.errors.documentType}
                              helperText={formikDriver.errors.documentType}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Doumento"
                              variant="outlined"
                              size="small"
                              name="document"
                              type="number"
                              onBlur={(e) => verifyDriver(e)}
                              value={formikDriver.values.document}
                              onChange={formikDriver.handleChange}
                              error={
                                formikDriver.touched.document &&
                                Boolean(formikDriver.errors.document)
                              }
                              helperText={
                                formikDriver.touched.document &&
                                formikDriver.errors.document
                              }
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Primer Nombre"
                            variant="outlined"
                            size="small"
                            name="firstName"
                            value={formikDriver.values.firstName}
                            onChange={formikDriver.handleChange}
                            error={
                              formikDriver.touched.firstName &&
                              Boolean(formikDriver.errors.firstName)
                            }
                            helperText={
                              formikDriver.touched.firstName &&
                              formikDriver.errors.firstName
                            }
                            required
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Segundo Nombre"
                            variant="outlined"
                            size="small"
                            name="secondName"
                            value={formikDriver.values.secondName}
                            onChange={formikDriver.handleChange}
                            error={
                              formikDriver.touched.secondName &&
                              Boolean(formikDriver.errors.secondName)
                            }
                            helperText={
                              formikDriver.touched.secondName &&
                              formikDriver.errors.secondName
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Primer Apellido"
                            variant="outlined"
                            size="small"
                            name="surname"
                            value={formikDriver.values.surname}
                            onChange={formikDriver.handleChange}
                            error={
                              formikDriver.touched.surname &&
                              Boolean(formikDriver.errors.surname)
                            }
                            helperText={
                              formikDriver.touched.surname &&
                              formikDriver.errors.surname
                            }
                            required
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Segundo Apellido"
                            variant="outlined"
                            size="small"
                            name="secondSurname"
                            value={formikDriver.values.secondSurname}
                            onChange={formikDriver.handleChange}
                            error={
                              formikDriver.touched.secondSurname &&
                              Boolean(formikDriver.errors.secondSurname)
                            }
                            helperText={
                              formikDriver.touched.secondSurname &&
                              formikDriver.errors.secondSurname
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mt={2.5} mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            label="Número de Licencia"
                            variant="outlined"
                            size="small"
                            name="licenseNumber"
                            value={formikDriver.values.licenseNumber.toUpperCase()}
                            onChange={formikDriver.handleChange}
                            error={
                              formikDriver.touched.licenseNumber &&
                              Boolean(formikDriver.errors.licenseNumber)
                            }
                            helperText={
                              formikDriver.touched.licenseNumber &&
                              formikDriver.errors.licenseNumber
                            }
                            required
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={ES}
                          >
                            <DatePicker
                              label="Fecha Expedición"
                              format="dd/MM/yyyy"
                              autoOk
                              value={dayjs(formikDriver.values.expeditionDate)}
                              onChange={handleDateChange}
                              variant="inline"
                              fullWidth
                              inputVariant="outlined"
                              size="small"
                              labelFunc={() =>
                                dayjs(
                                  formikDriver.values.expeditionDate
                                ).format('DD/MM/YYYY')
                              }
                              maxDate={dayjs()}
                              minDate={dayjs().subtract(10, 'year')}
                              minDateMessage="La fecha final debe ser mayor a la inicial"
                              maxDateMessage="La fecha final debe ser mayor a la inicial"
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="replacement"
                                color="primary"
                                onChange={formikDriver.handleChange}
                                checked={formikDriver.values.replacement}
                              />
                            }
                            label="Reemplazo"
                          />
                          <Tooltip
                            title='Selecciona esta opción si deseas que este conductor sea "Conductor de Reemplazo" del Vehículo que se le va a asociar.'
                            aria-label="add"
                            arrow
                          >
                            <Box
                              component="i"
                              className="icon-question"
                              fontSize="22px"
                              style={{ cursor: 'pointer', marginTop: -2 }}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>

                    {licenseCategory.map((item: any, index: number) => (
                      <Grow in timeout={500} key={index}>
                        <Box mb={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <TextField
                                label="Categoría de Licencia"
                                select
                                fullWidth
                                variant="outlined"
                                size="small"
                                name="idCategoriaLicencia"
                                value={item.idCategoriaLicencia}
                                onChange={(event: any) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    'idCategoriaLicencia'
                                  )
                                }
                                required
                              >
                                {loadingTypeLicenseCategory ? (
                                  <CircularProgress
                                    variant="indeterminate"
                                    size={25}
                                  />
                                ) : errorTypeLicenseCategory ? (
                                  <p>Error</p>
                                ) : (
                                  dataTypeLicenseCategory?.getTypeLicenseCategory?.map(
                                    (item: any) => (
                                      <MenuItem
                                        key={item.idTipoCategoria}
                                        value={item.idTipoCategoria}
                                      >
                                        {item.description}
                                      </MenuItem>
                                    )
                                  )
                                )}
                              </TextField>
                            </Grid>
                            <Grid item xs={4}>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={ES}
                              >
                                <DatePicker
                                  autoOk
                                  label="Fecha de Vigencia"
                                  format="DD/MM/YYYY"
                                  variant="inline"
                                  inputVariant="outlined"
                                  fullWidth
                                  value={item.fechaVigencia}
                                  onChange={(event: any) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      'fechaVigencia'
                                    )
                                  }
                                  labelFunc={() =>
                                    dayjs(item.fechaVigencia).format(
                                      'DD/MM/YYYY'
                                    )
                                  }
                                  TextFieldComponent={renderInput}
                                  maxDate={dayjs()}
                                  minDate={formikDriver.values.expeditionDate}
                                  required
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid container item xs={2} spacing={2}>
                              {licenseCategory.length <= 2 && (
                                <Grid item>
                                  <Tooltip title="Agregar Categoría" arrow>
                                    <Fab
                                      color="default"
                                      className={classes.btnAddCategory}
                                      onClick={addFields}
                                    >
                                      <Box
                                        component="i"
                                        fontSize="20px"
                                        className="icon-plus"
                                      />
                                    </Fab>
                                  </Tooltip>
                                </Grid>
                              )}
                              {licenseCategory.length > 1 && (
                                <Grid item>
                                  <Tooltip title="Eliminar Categoría" arrow>
                                    <Fab
                                      className={classes.btnDeleteCategory}
                                      onClick={() => removeFields(index)}
                                    >
                                      <Box
                                        component="i"
                                        fontSize="20px"
                                        className="icon-close"
                                      />
                                    </Fab>
                                  </Tooltip>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </Grow>
                    ))}
                  </div>
                </Grow>
              )}

              {stepFormDriver === 1 && (
                <Grow in timeout={800}>
                  <div>
                    <Box marginBottom={3} marginTop={1}>
                      <MainTitle fontSize="h6">
                        Datos de Contacto{' '}
                        <span className={classes.fontInfoStep}>
                          (Paso {stepFormDriver + 1} de 3)
                        </span>
                      </MainTitle>
                    </Box>

                    <Box mb={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <PhoneNumber
                            type="landline"
                            extension
                            names={{
                              indicative: 'indicativePhone',
                              phone: 'phone',
                              ext: 'ext',
                            }}
                            values={{
                              indicative: formikDriver.values.indicativePhone,
                              phone: (formikDriver.values.phone = unmaskPhone(
                                formikDriver.values.phone
                              )),
                            }}
                            onChange={formikDriver.handleChange}
                            errors={{
                              indicative:
                                !!formikDriver.errors.indicativePhone &&
                                !!formikDriver.touched.indicativePhone,
                              phone: formikDriver.errors.phone as string,
                            }}
                            helperTexts={{
                              indicative: formikDriver.errors
                                .indicativePhone as string,
                              phone: formikDriver.errors.phone as string,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mb={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <PhoneNumber
                            type="mobile"
                            names={{
                              indicative: 'indicativeMobile',
                              phone: 'mobile',
                            }}
                            values={{
                              indicative: formikDriver.values.indicativeMobile,
                              phone: (formikDriver.values.mobile = unmaskPhone(
                                formikDriver.values.mobile
                              )),
                            }}
                            onChange={formikDriver.handleChange}
                            errors={{
                              indicative:
                                !!formikDriver.errors.indicativeMobile &&
                                !!formikDriver.touched.indicativeMobile,
                              phone: formikDriver.errors.mobile as string,
                            }}
                            helperTexts={{
                              indicative: formikDriver.errors
                                .indicativeMobile as string,
                              phone: formikDriver.errors.mobile as string,
                            }}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mb={3}>
                      <Grid container>
                        <TextField
                          fullWidth
                          label="Correo Electrónico"
                          variant="outlined"
                          size="small"
                          type="email"
                          name="email"
                          value={formikDriver.values.email}
                          onChange={formikDriver.handleChange}
                          error={
                            formikDriver.touched.email &&
                            Boolean(formikDriver.errors.email)
                          }
                          helperText={
                            formikDriver.touched.email &&
                            formikDriver.errors.email
                          }
                          required
                        />
                      </Grid>
                    </Box>
                  </div>
                </Grow>
              )}

              {stepFormDriver === 2 && (
                <Grow in timeout={800}>
                  <div>
                    <Box marginBottom={3} marginTop={1}>
                      <MainTitle fontSize="h6">
                        Datos de Ubicación
                        <span className={classes.fontInfoStep}>
                          (Paso {stepFormDriver + 1} de 3)
                        </span>
                      </MainTitle>
                    </Box>

                    <Direction
                      names={{
                        directionType: 'directionType',
                        viaType: 'viaType',
                        viaNumber: 'viaNumber',
                        apendNumber: 'apendNumber',
                        cruceNumber: 'cruceNumber',
                        apendCruceNumber: 'apendCruceNumber',
                        cornerMeters: 'cornerMeters',
                        aditional: 'aditional',
                        postalCode: 'postalCode',
                        latitude: 'latitud',
                        longitude: 'longitud',
                        idPais: 'country',
                        idDepartamento: 'departament',
                        city: 'city',
                      }}
                      values={{
                        directionType: formikDriver.values.directionType,
                        viaType: formikDriver.values.viaType,
                        viaNumber: formikDriver.values.viaNumber,
                        apendNumber: formikDriver.values.apendNumber,
                        cruceNumber: formikDriver.values.cruceNumber,
                        apendCruceNumber: formikDriver.values.apendCruceNumber,
                        cornerMeters: formikDriver.values.cornerMeters,
                        aditional: formikDriver.values.aditional,
                        postalCode: formikDriver.values.postalCode,
                        latitude: formikDriver.values.latitude,
                        longitude: formikDriver.values.longitude,
                        idPais: Number(formikDriver.values.country),
                        idDepartamento: Number(formikDriver.values.departament),
                        city: Number(formikDriver.values.city),
                      }}
                      errors={{
                        directionType: formikDriver.errors.directionType,
                        viaType: formikDriver.errors.viaType,
                        viaNumber: formikDriver.errors.viaNumber,
                        apendNumber: formikDriver.errors.apendNumber,
                        cruceNumber: formikDriver.errors.cruceNumber,
                        apendCruceNumber: formikDriver.errors.apendCruceNumber,
                        cornerMeters: formikDriver.errors.cornerMeters,
                        aditional: formikDriver.errors.aditional,
                        postalCode: formikDriver.errors.postalCode,
                        latitude: formikDriver.errors.latitude,
                        longitude: formikDriver.errors.longitude,
                        idPais: formikDriver.errors.country as string,
                        idDepartamento: formikDriver.errors
                          .departament as string,
                        city: formikDriver.errors.city as string,
                      }}
                      setFieldValue={formikDriver.setFieldValue}
                      handleChange={formikDriver.handleChange}
                      handleBlur={formikDriver.handleBlur}
                      touched={formikDriver.touched}
                    />
                  </div>
                </Grow>
              )}
              <Box mt={2}>
                <Grid container spacing={1} justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      // onClick={() => handleBack()}
                    >
                      Atrás
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={stepFormDriver !== 2}
                      endIcon={
                        loadingDriver && (
                          <CircularProgress
                            className={classes.loadingColor}
                            thickness={5}
                            size={20}
                          />
                        )
                      }
                    >
                      {loadingDriver ? 'Guardando...' : 'Guardar'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={1}
            >
              <Fab
                color="primary"
                type="submit"
                className={clsx({
                  [classes.btnStep]: true,
                  [classes.hiddenButton]: stepFormDriver === 2,
                })}
              >
                <Box
                  component="i"
                  fontSize="20px"
                  fontWeight="bold"
                  className="icon-arrow-right"
                />
              </Fab>
            </Grid>
          </Grid>
        </form>
      </ContentCard>
      <DialogConfirmData
        open={openNotificationConfirm.open}
        icon={openNotificationConfirm.icon}
        title={openNotificationConfirm.title}
        message={openNotificationConfirm.message}
        handleAccept={handleNConfirm}
        handleClose={handleCloseNConfirm}
      />
    </>
  );
};
export default RegisterDriverXVehicle;
