import { gql } from "apollo-boost";

export const REPORT_TYPES = gql`
  query($count: Boolean){
    reportTypes(withCount: $count){
      _id
      name
      icon
      count
    }
  }
`;
