import { createTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export default createTheme({
  palette: {
    primary: {
      main: "#DD9835",
      contrastText: "#FFF",
      100: "#FFC676",
      200: "#F1AE6C",
      300: "#E29662",
      400: "#D47D57",
      500: "#BE5948",
      600: "#B0413E",
    },
    secondary:{
      main: '#767676'
    }
  },
  typography: {
    h6: {
      fontWeight: 600,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 10,
      },
    },
    MuiButton: {
      root: {
        textTransform: "inherit",
        outline: "none",
        fontWeight: 'bold',
        fontFamily: "Raleway",
        "&.raleway": {
          fontWeight: "bold",
        },
        "&.bold": {
          fontWeight: "bold",
        },
        "&.view": {
          color: "#FFF",
          background: grey[600],
          borderRadius: 50,
          padding: "1.6px 0",
          "&:hover": {
            background: grey[700],
            color: "#FFF",
          },
        },
      },
      outlined: {
        borderWidth: 2,
        borderRadius: 50,
      },
      outlinedPrimary: {
        borderWidth: 2,
        padding: "8px 20px",
        "&:hover": {
          color: "#DD9835",
          borderWidth: 2,
        },
      },
      contained: {
        boxShadow: "none",
        borderRadius: 50,
      },
      containedPrimary: {
        padding: "8px 20px",
        "&:hover": {
          color: "#FFF",
        },
      },
      containedSecondary: {
        padding: "8px 20px",
        "&:hover": {
          color: "#FFF",
        },
      },
    },
    MuiFab:{
      root:{
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
      }, 
    },
    MuiInputLabel: {
      outlined: {},
    },
    MuiOutlinedInput: {
      marginDense: {
        borderRadius: 50,
        overflow: "hidden",
      },
      root:{
        "&  input[type=number]": {  
          "&::-webkit-inner-spin-button":{
            display: 'none !important'
          }
        }
      }
    },
    MuiInput: {
      marginDense: {
        borderRadius: 50,
        overflow: "hidden",
      },
      root:{
        "&  input[type=number]": {  
          "&::-webkit-inner-spin-button":{
            display: 'none !important'
          }
        }
      }
    },
    
    MuiTable: {
      root: {
        "&.table-agent, &.table-present": {
          "&:not(.table-present) .MuiTableRow-root:nth-child(odd)": {
            background: "rgba(0, 0, 0, 0.02)",
          },
          "& .MuiTableCell-root": {
            fontSize: 14,
            borderBottom: "none",
            color: "#AFAFAF",
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: 10,
      },
      body:{
        color: '#767676'
      },
      head: {
        color: "#AFAFAF",
        fontWeight: "bold",
        fontSize: 14,
      },
    },
    MuiAvatar: {
      root: {
        background: "#DD9835",
        },
        colorDefault:{
        backgroundColor: "#DD9835",
      }
    },
  },
});
