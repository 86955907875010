import { Avatar, Grid, Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

interface ItemOutlinedInfoProps {
  icon: string;
  title: string;
  info?: string | number;
  variant?: boolean;
}

const ItemOutlinedInfo = ({
  icon,
  title,
  info,
  variant,
}: ItemOutlinedInfoProps) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center">
      <Grid>
        {variant ? (
          <Avatar className={classes.avatarColor}>
            <Box component="i" className={icon} fontSize="30px" />
          </Avatar>
        ) : (
          <div className={classes.iconContainer}>
            <Box component="i" className={icon} fontSize="30px" />
          </div>
        )}
      </Grid>
      <Grid>
        <Box ml={2}>
          <Typography className={classes.titleIconContainer}>
            {title}
          </Typography>
          <Typography className={classes.infoIconContainer}>{info}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ItemOutlinedInfo;
