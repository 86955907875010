import React, { useState } from 'react';

import { Image, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface TableProps {
  title: string;
  icon?: string;
  items: object;
  widthContainer?: number | string;
  widthTitle?: number | string;
  widthItem?: number | string;
}

const HorizontalTableInfo = ({
  title,
  icon,
  items,
  widthContainer,
  widthTitle,
  widthItem,
}: TableProps) => {
  let cont = -1;
  let contii = -1;
  return (
    <View
      style={{
        flexDirection: 'row',
        width: '100%',
        marginTop: '5px',
      }}
    >
      <View
        style={[
          styles.backgroundresumetitle,
          { width: '31.2%', justifyContent: 'center' },
        ]}
      >
        {icon && (
          <View style={styles.imageicon}>
            <Image src={icon} />
          </View>
        )}
        <Text>{title}</Text>
      </View>

      <View
        style={[
          styles.tablecontainer,
          {
            width: `${widthContainer ? (widthContainer as String) : '31.2%'}`,
          },
        ]}
      >
        <View style={styles.table}>
          {_.map(items, (value, key) => {
            cont++;
            if (cont <= 2) {
              return (
                <View style={styles.tableRow}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: widthTitle ? widthTitle : '30%' },
                    ]}
                  >
                    <View style={styles.tableCell}>
                      <Text
                        style={{
                          fontFamily: 'Nunito',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        {key}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.tableColRigth,
                      { width: widthItem ? widthItem : '70%' },
                    ]}
                  >
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                </View>
              );
            }
          })}
        </View>
      </View>

      <View
        style={[
          styles.tablecontainer,
          {
            width: `${widthContainer ? (widthContainer as String) : '31.2%'}`,
            justifyContent: 'center',
          },
        ]}
      >
        <View style={styles.table}>
          {_.map(items, (value, key) => {
            contii++;
            if (contii > 2) {
              return (
                <View style={styles.tableRow}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: widthTitle ? widthTitle : '30%' },
                    ]}
                  >
                    <View style={styles.tableCell}>
                      <Text
                        style={{
                          fontFamily: 'Nunito',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        {key}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.tableColRigth,
                      { width: widthItem ? widthItem : '70%' },
                    ]}
                  >
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                </View>
              );
            }
          })}
        </View>
      </View>
    </View>
  );
};

export default HorizontalTableInfo;
