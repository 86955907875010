import Main from './Main';
import Details from './Details';
import Search from './Search'; 
import CorralsRealTime from './CorralsRealTime';
import { NestedRoute } from 'lib/interfaces/routes';


const routes: NestedRoute[] = [
  {
    route: '/real-time',
    Node: CorralsRealTime,
    exact: true
  }
];

export {
  Main,
  Search,
  Details, 
  routes
}