import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  PieSeries,
  AccumulationTooltip,
  Inject,
  AccumulationDataLabel,
} from '@syncfusion/ej2-react-charts';
import { Typography, Box, Grid } from '@material-ui/core';
import _ from 'lodash';
import clsx from 'clsx';

import useStyles from './styles';

interface GraphicpdfProps {
  data: Array<{
    data: number;
    label: string;
    bg: string;
  }>;
  explodeIndex?: number;
  textTotalData?: number | string;
  topTextTotalData?: number;
  withoutTotalInfo?: Boolean;
  withoutConnector?: Boolean;
  fontConnector?: string;
  startAngle?: number;
  endAngle?: number;
  fontSizeTotal?: number;
  fontNumberSizeTotal?: number;
  colorTotal?: string;
  widthChart?: string;
  removepercentages?: Boolean;
  numberTotal?: Boolean;
}

const Graphicpdfsf = ({
  data,
  explodeIndex,
  textTotalData,
  withoutTotalInfo,
  withoutConnector,
  fontConnector,
  topTextTotalData,
  startAngle,
  endAngle,
  fontSizeTotal,
  fontNumberSizeTotal,
  colorTotal,
  widthChart,
  removepercentages,
  numberTotal,
}: GraphicpdfProps) => {
  const classes = useStyles();

  const dataObject = data.map((data: any) => data.data);
  const total = dataObject.reduce((a: number, b: number) => a + b, 0);
  const total2 = dataObject.reduce((a: number, b: number) => a + b, 0);
  const data1: any[] = [];
  const data2: any[] = [];
  let sumaCantidad = 0;

  data.map((item: any) => {
    sumaCantidad += item.cantidad;
  });

  data.map((item: any) => {
    data1.push({
      x: `${((item.data / total) * 100).toFixed(2)}% (${
        item.data > 9 ? item.data : '0' + item.data
      }) - ${item.label}`,
      y: ((item.data / total) * 100).toFixed(2),
      color: item.bg,
    });
  });

  data.map((item: any) => {
    data2.push({
      x: `${item.data > 9 ? item.data : '0' + item.data}`,
      y: item.data,
      color: item.bg,
    });
  });

  const onTextRender = (IAccTextRenderEventArgs: any) => {
    IAccTextRenderEventArgs.text = `${
      !withoutConnector
        ? removepercentages
          ? IAccTextRenderEventArgs.point.y
          : IAccTextRenderEventArgs.point.y + '%'
        : ''
    }`;
  };

  return (
    <div style={{ width: 500, height: 500 }}>
      <AccumulationChartComponent
        legendSettings={{
          visible: false,
          position: 'Right',
          width: '30%',
        }}
        enableSmartLabels={true}
        tooltip={{ enable: false }}
        textRender={onTextRender.bind(this)}
        width={widthChart ? widthChart : '700'}
        height={widthChart ? widthChart : '700'}
        background="#0000"
      >
        <Inject
          services={[
            AccumulationLegend,
            PieSeries,
            AccumulationTooltip,
            AccumulationDataLabel,
          ]}
        />
        <AccumulationSeriesCollectionDirective>
          <AccumulationSeriesDirective
            name="Project"
            dataSource={removepercentages ? data2 : data1}
            xName="x"
            yName="y"
            pointColorMapping="color"
            animation={{ enable: false }}
            border={{ width: 1, color: '#000' }}
            innerRadius="60%"
            startAngle={startAngle ? startAngle : -65}
            endAngle={endAngle ? endAngle : 295}
            explodeIndex={explodeIndex ? explodeIndex : null}
            radius="64%"
            explode={true}
            explodeOffset="10%"
            dataLabel={{
              border: 2,
              visible: true,
              position: 'Outside',
              connectorStyle: {
                type: 'Line',
                length: '3%',
                width: withoutConnector ? 0 : 4,
                color: 'black',
              },
              font: {
                size: fontConnector ? fontConnector : '30px',
                color: 'black',
              },
            }}
            annotations={{
              visible: true,
              content: 'annotationText',
              region: 'series',
            }}
          ></AccumulationSeriesDirective>
        </AccumulationSeriesCollectionDirective>
      </AccumulationChartComponent>

      <div
        style={{
          top: topTextTotalData ? topTextTotalData : -410,
          width: widthChart ? widthChart : '700px ',
        }}
        className={classes.textInfo}
      >
        {!withoutTotalInfo ? (
          <div className={classes.divContainer}>
            <Typography
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: fontNumberSizeTotal ? fontNumberSizeTotal : 40,
                color: colorTotal ? colorTotal : '#212121',
              }}
            >
              {numberTotal ? sumaCantidad : total}
            </Typography>
          </div>
        ) : (
          ''
        )}
        <div
          className={clsx(classes.divContainer, classes.widthContainerTotal)}
        >
          {textTotalData ? (
            <Typography
              style={{
                textAlign: 'center',
                fontSize: fontSizeTotal ? fontSizeTotal : 30,
                color: colorTotal ? colorTotal : '#212121',
              }}
            >
              {textTotalData}
            </Typography>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Graphicpdfsf;
