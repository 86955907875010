import { gql } from "apollo-boost";

export const GET_INTERNAL_USER = gql`
  query($uid: Int!, $idUserType: Int!){
    getInternalUser(uid: $uid, input: {IdTipoUsuario: $idUserType}){
      data{
        idUsuario
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        idTipoDocumento
        tipoDocumento
        numeroDocumento
        idCargo
        cargo
        idPais
        celular
        email
        username
        fechaRegistro
        estado
        idRol
        rol
        ultimaConexion
        tiempoActivo
      }
      token{
        tokenNuevo
      }
    }
  }
`;