import { gql } from "apollo-boost";

export const RESEND_INVITATION = gql`
 mutation ($ids: [Int]!){
  resendInvitations(input: $ids){
    success
    token{
      tokenNuevo
      fechaExpiracion
    }
  }
}
`;