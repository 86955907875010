import { gql } from "apollo-boost";

export const UPDATE_PROFILE_DATA = gql`
  mutation putGeneralDataProfile(
    $idTipoDocumento: Int
    $numeroDocumento: String
    $digitoVerificacion: String
    $primerNombre: String
    $segundoNombre: String
    $primerApellido: String
    $segundoApellido: String
    $razonSocial: String
    $indicativoTelefono: Int
    $telefono: String
    $extension: String
    $indicativoCelular: Int
    $celular: String
    $indicativoWhatsapp: Int
    $whatsapp: String
    $indicativoTelegram: Int
    $telegram: String
    $email: String
    $usuarioFacebook: String
    $paginaWeb: String
    $idCiudad: Int
    $idTipoDireccion: Int
    $idTipoVia: Int
    $numeroVia: String
    $apendiceVia: String
    $numeroCruce: String
    $apendiceCruce: String
    $metrosEsquina: String
    $adicional: String
    $codigoPostal: String
    $idRepresentanteLegal: Int
    $idTipoDocumentoRL: Int
    $numeroDocumentoRL: String
    $primerNombreRL: String
    $segundoNombreRL: String
    $primerApellidoRL: String
    $segundoApellidoRL: String
    $indicativoTelefonoRL: Int
    $telefonoRL: String
    $indicativoCelularRL: Int
    $celularRL: String
    $indicativoWhatsappRL: Int
    $whatsappRL: String
    $indicativoTelegramRL: Int
    $telegramRL: String
    $emailRL: String
  ) {
    putGeneralDataProfile(
      input: {
        idTipoDocumento: $idTipoDocumento
        numeroDocumento: $numeroDocumento
        digitoIdentificador: $digitoVerificacion
        primerNombre: $primerNombre
        segundoNombre: $segundoNombre
        primerApellido: $primerApellido
        segundoApellido: $segundoApellido
        razonSocial: $razonSocial
        indicativoTelefono: $indicativoTelefono
        telefono: $telefono
        extension: $extension
        indicativoCelular: $indicativoCelular
        celular: $celular
        indicativoWhatsapp: $indicativoWhatsapp
        whatsapp: $whatsapp
        indicativoTelegram: $indicativoTelegram
        telegram: $telegram
        email: $email
        usuarioFacebook: $usuarioFacebook
        paginaWeb: $paginaWeb
        idCiudad: $idCiudad
        idTipoDireccion: $idTipoDireccion
        idTipoVia: $idTipoVia
        numeroVia: $numeroVia
        apendiceVia: $apendiceVia
        numeroCruce: $numeroCruce
        apendiceCruce: $apendiceCruce
        metrosEsquina: $metrosEsquina
        adicional: $adicional
        codigoPostal: $codigoPostal
        idRepresentante: $idRepresentanteLegal
        idTipoDocumentoRL: $idTipoDocumentoRL
        numeroDocumentoRL: $numeroDocumentoRL
        primerNombreRL: $primerNombreRL
        segundoNombreRL: $segundoNombreRL
        primerApellidoRL: $primerApellidoRL
        segundoApellidoRL: $segundoApellidoRL
        indicativoTelefonoRL: $indicativoTelefonoRL
        telefonoRL: $telefonoRL
        indicativoCelularRL: $indicativoCelularRL
        celularRL: $celularRL
        indicativoWhatsappRL: $indicativoWhatsappRL
        whatsappRL: $whatsappRL
        indicativoTelegramRL: $indicativoTelegramRL
        telegramRL: $telegramRL
        emailRL: $emailRL
      }
    ) {
      data {
        success
        message
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const UNSUBSCRIBE = gql`
  mutation putDarDeBaja($IdMotivo: Int!) {
    putDarDeBaja(input: { IdMotivo: $IdMotivo }) {
      data {
        success
        message
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const REGISTER_FARM = gql`
  mutation postFarm(
    $code: String!
    $name: String!
    $idResponsable: Int!
    $idDocument: Int!
    $documentNumber: Int!
    $firstName: String!
    $secondName: String
    $firstLastname: String!
    $secondLastname: String
    $email: String!
    $typeDirection: Int!
    $idCity: Int!
    $viaType: Int!
    $viaNumber: Int!
    $apendNumber: String!
    $cruceNumber: Int!
    $apendCruceNumber: String!
    $cornerMeters: Int!
    $aditional: String!
    $postalCode: String!
    $longitud: String!
    $latitud: String!
    $direction: String
    $indicative: Int!
    $phone: String!
    $whatsappIndicative: Int!
    $whatsapp: String!
    $telegramIndicative: Int
    $telegram: String!
    $farmEmail: String!
    $farmCertificate: String!
    $validityDate: Date
  ) {
    postFarm(
      input: {
        codigoGranja: $code
        nombreGranja: $name
        idPersonaResponsable: $idResponsable
        idTipoDocumento: $idDocument
        numeroDocumento: $documentNumber
        primerNombre: $firstName
        segundoNombre: $secondName
        primerApellido: $firstLastname
        segundoApellido: $secondLastname
        emailResponsable: $email
        tipoDireccion: $typeDirection
        idciudad: $idCity
        idTipoVia: $viaType
        numeroVia: $viaNumber
        apendiceVia: $apendNumber
        numeroCruce: $cruceNumber
        apendiceCruce: $apendCruceNumber
        metrosEsquina: $cornerMeters
        adicional: $aditional
        codigoPostal: $postalCode
        longitud: $longitud
        latitud: $latitud
        direccion: $direction
        indicativoCelular: $indicative
        numeroCelular: $phone
        indicativoWhatsapp: $whatsappIndicative
        numeroWhatsapp: $whatsapp
        indicativoTelegram: $telegramIndicative
        numeroTelegram: $telegram
        email: $farmEmail
        certificadoGranja: $farmCertificate
        fechaValidezCertificadoGranja: $validityDate
      }
    ) {
      data {
        success
        id
      }
    }
  }
`;

export const CHANGE_STATE_FARM = gql`
  mutation putStatusFarm($idFarm: Int, $state: Boolean) {
    putStatusFarm(input: { idGranja: $idFarm, estadoGranja: $state }) {
      data {
        success
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const UPDATE_FARM = gql`
  mutation putFarm(
    $idGranja: Int!
    $codigoGranja: String!
    $nombreGranja: String!
    $idPersonaResponsable: Int!
    $idTipoDocumento: Int!
    $numeroDocumento: String!
    $primerNombre: String!
    $segundoNombre: String
    $primerApellido: String!
    $segundoApellido: String
    $emailResponsable: String!
    $tipoDireccion: Int!
    $idCiudad: Int!
    $idTipoVia: Int!
    $numeroVia: Int!
    $apendiceVia: String!
    $numeroCruce: Int!
    $apendiceCruce: String!
    $metrosEsquina: Int!
    $adicional: String!
    $codigoPostal: String!
    $longitud: String!
    $latitud: String!
    $direccion: String!
    $indicativoCelular: Int!
    $numeroCelular: String!
    $indicativoWhatsapp: Int!
    $numeroWhatsapp: String!
    $indicativoTelegram: Int!
    $numeroTelegram: String!
    $email: String!
    $certificadoGranja: String!
    $fechaValidezCertificadoGranja: String
  ) {
    putFarm(
      input: {
        idGranja: $idGranja
        codigoGranja: $codigoGranja
        nombreGranja: $nombreGranja
        idPersonaResponsable: $idPersonaResponsable
        idTipoDocumento: $idTipoDocumento
        numeroDocumento: $numeroDocumento
        primerNombre: $primerNombre
        segundoNombre: $segundoNombre
        primerApellido: $primerApellido
        segundoApellido: $segundoApellido
        emailResponsable: $emailResponsable
        tipoDireccion: $tipoDireccion
        idciudad: $idCiudad
        idTipoVia: $idTipoVia
        numeroVia: $numeroVia
        apendiceVia: $apendiceVia
        numeroCruce: $numeroCruce
        apendiceCruce: $apendiceCruce
        metrosEsquina: $metrosEsquina
        adicional: $adicional
        codigoPostal: $codigoPostal
        longitud: $longitud
        latitud: $latitud
        direccion: $direccion
        indicativoCelular: $indicativoCelular
        numeroCelular: $numeroCelular
        indicativoWhatsapp: $indicativoWhatsapp
        numeroWhatsapp: $numeroWhatsapp
        indicativoTelegram: $indicativoTelegram
        numeroTelegram: $numeroTelegram
        email: $email
        certificadoGranja: $certificadoGranja
        fechaValidezCertificadoGranja: $fechaValidezCertificadoGranja
      }
    ) {
      data {
        success
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const ASSIGN_EXISTING_DESTINY = gql`
  mutation postAssociateExistingDestination($idDestino: Int) {
    postAssociateExistingDestination(input: { idDestino: $idDestino }) {
      data {
        success
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const REGISTER_DESTINY = gql`
  mutation (
    $code: String
    $name: String
    $nit: String
    $digitoVerificacion: Int
    $idDestinyType: Int
    $idResponsable: Int!
    $idDocument: Int!
    $documentNumber: Int
    $firstName: String
    $secondName: String
    $firstLastname: String
    $secondLastname: String
    $email: String
    $typeDirection: Int
    $idCity: Int!
    $viaType: Int!
    $viaNumber: Int!
    $viaApend: String!
    $cruceNumber: Int!
    $cruceApend: String!
    $cornerMeters: Int!
    $aditional: String!
    $postalCode: String!
    $longitud: String
    $latitud: String
    $direction: String
    $stratum: Int
    $indicativePhone: Int!
    $phone: String!
    $extension: String!
    $indicativeMobile: Int
    $cellphone: String
    $whatsappIndicative: Int
    $whatsapp: String
    $telegramIndicative: Int
    $telegram: String
    $destinyEmail: String!
    $schedule: [HorariosXDestino]
  ) {
    postDestination(
      input: {
        codigoDestino: $code
        nombreDestino: $name
        nit: $nit
        digitoVerificacion: $digitoVerificacion
        idTipodestino: $idDestinyType
        idPersonaResponsable: $idResponsable
        idTipoDocumento: $idDocument
        numeroDocumento: $documentNumber
        primerNombre: $firstName
        segundoNombre: $secondName
        primerApellido: $firstLastname
        segundoApellido: $secondLastname
        emailResponsable: $email
        tipoDireccion: $typeDirection
        idciudad: $idCity
        idTipoVia: $viaType
        numeroVia: $viaNumber
        apendiceVia: $viaApend
        numeroCruce: $cruceNumber
        apendiceCruce: $cruceApend
        metrosEsquina: $cornerMeters
        adicional: $aditional
        codigoPostal: $postalCode
        longitud: $longitud
        latitud: $latitud
        direccion: $direction
        estrato: $stratum
        indicativoTelefono: $indicativePhone
        numeroTelefono: $phone
        extensionTelefono: $extension
        indicativoCelular: $indicativeMobile
        numeroCelular: $cellphone
        indicativoWhatsapp: $whatsappIndicative
        numeroWhatsapp: $whatsapp
        indicativoTelegram: $telegramIndicative
        numeroTelegram: $telegram
        email: $destinyEmail
        horariosDestino: $schedule
      }
    ) {
      data {
        success
        id
      }
    }
  }
`;

export const CHANGE_STATE_DESTINY = gql`
  mutation putStatusDestination($idDestiny: Int!, $state: Boolean!) {
    putStatusDestination(
      input: { idDestino: $idDestiny, estadoDestino: $state }
    ) {
      data {
        success
        message
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const UPDATE_DESTINY = gql`
  mutation putDestination(
    $idDestino: Int
    $codigoDestino: String
    $nombreDestino: String
    $idTipoDestino: Int
    $idPersonaResponsable: Int
    $idTipoDocumento: Int
    $numeroDocumento: Int
    $primerNombre: String
    $segundoNombre: String
    $primerApellido: String
    $segundoApellido: String
    $emailResponsable: String
    $tipoDireccion: Int
    $idCiudad: Int
    $idTipoVia: Int
    $numeroVia: Int
    $apendiceVia: String
    $numeroCruce: Int
    $apendiceCruce: String
    $metrosEsquina: Int
    $adicional: String
    $codigoPostal: String
    $longitud: String
    $latitud: String
    $direccion: String
    $estrato: Int
    $indicativoTelefono: Int
    $numeroTelefono: String
    $extensionTelefono: String
    $indicativoCelular: Int
    $numeroCelular: String
    $indicativoWhatsapp: Int
    $numeroWhatsapp: String
    $indicativoTelegram: Int
    $numeroTelegram: String
    $email: String
    $horariosDestino: [HorariosXDestino]
  ) {
    putDestination(
      input: {
        idDestino: $idDestino
        codigoDestino: $codigoDestino
        nombreDestino: $nombreDestino
        idTipodestino: $idTipoDestino
        idPersonaResponsable: $idPersonaResponsable
        idTipoDocumento: $idTipoDocumento
        numeroDocumento: $numeroDocumento
        primerNombre: $primerNombre
        segundoNombre: $segundoNombre
        primerApellido: $primerApellido
        segundoApellido: $segundoApellido
        emailResponsable: $emailResponsable
        tipoDireccion: $tipoDireccion
        idciudad: $idCiudad
        idTipoVia: $idTipoVia
        numeroVia: $numeroVia
        apendiceVia: $apendiceVia
        numeroCruce: $numeroCruce
        apendiceCruce: $apendiceCruce
        metrosEsquina: $metrosEsquina
        adicional: $adicional
        codigoPostal: $codigoPostal
        longitud: $longitud
        latitud: $latitud
        direccion: $direccion
        estrato: $estrato
        indicativoTelefono: $indicativoTelefono
        numeroTelefono: $numeroTelefono
        extensionTelefono: $extensionTelefono
        indicativoCelular: $indicativoCelular
        numeroCelular: $numeroCelular
        indicativoWhatsapp: $indicativoWhatsapp
        numeroWhatsapp: $numeroWhatsapp
        indicativoTelegram: $indicativoTelegram
        numeroTelegram: $numeroTelegram
        email: $email
        horariosDestino: $horariosDestino
      }
    ) {
      data {
        success
        message
        id
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const UPDATE_MARKETER_TYPES = gql`
  mutation updateMarketerType($IdsTipoComercializador: [Int!]) {
    putTipoComercializador(
      input: { IdsTipoComercializador: $IdsTipoComercializador }
    ) {
      data {
        success
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;
