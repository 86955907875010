import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogContainer:{
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px'
    }
   },
  btn:{
     fontWeight:'bolder'
  },
  containerRoles:{
    display:'flex', 
    justifyContent:'center'
  },
  title:{
    display:'flex', 
    justifyContent:'center', 
    fontWeight:'bolder', 
  },
  containerCenter:{
    display:'flex', 
    flexDirection:'column',
    alignItems:'center', 
  } ,
  formContainer:{
    margin: '12px 0'
  }, 
  dialogFooter:{
    display:'flex', 
    justifyContent:'center', 
    padding: 16
  }, 
  iconDialogCheck:{
    fontSize:70,
    color:theme.palette.primary.main
  },
  captionGray:{
    color:'#767676',
    padding:12,
    paddingLeft:24, 
  },
}))

export { useStyles as default }