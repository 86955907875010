import React from 'react';
import { Typography, Box } from '@material-ui/core';

const textTerms = () => {
  return (
    <div>
      <Typography variant="h6" color="secondary" align="center">
        Términos y Condiciones de Uso Plataforma y la Política de Datos de
        Navegación de InfoPorcinos™
      </Typography>
      <Box
        width="50%"
        display="flex"
        justifyContent="space-between"
        margin="12px auto"
      >
        <Typography variant="subtitle2">
          Fecha de versión: 01 Julio 2022
        </Typography>
        <Typography variant="subtitle2">Código: 2022 - TERCONAVWEB</Typography>
      </Box>
      <Box margin="12px 0px">
        <Typography style={{ padding: '8px 0px' }}>
          Este documento establece los ‘Términos y Condiciones’ bajo los cuales
          los Usuarios de la Plataforma: Productores de Cerdos en Pie,
          Comercializadores de Carne y Plantas de Beneficio Animal (Usuarios),
          entre otros, pueden usar <strong>InfoPorcinos™</strong>. Por favor lea
          esta página cuidadosamente. Si usted no acepta los términos y
          condiciones establecidos aquí, no use <strong>InfoPorcinos™</strong>{' '}
          (el Web Site) ni sus servicios. Mediante el uso de este Web Site usted
          está indicando su aceptación a estos ‘Términos y Condiciones’.
          MERCADEO VIRTUAL S.A. (la 'Compañía') puede modificar estos 'Términos
          y Condiciones' en cualquier momento. Usted debe visitar esta página
          periódicamente para revisar los ‘Términos y Condiciones’ debido a que
          los mismos son obligatorios para usted. Los términos 'usted' o
          'usuario', tal como se usan aquí, se refieren a todas las personas
          naturales o jurídicas o entidades de cualquier naturaleza que accedan
          a este Web Site por cualquier razón.
          <br />
        </Typography>
        <Typography style={{ padding: '8px 0px' }}>
          <strong>
            Información Incluida en el Web Site: www.infoporcinos.com
          </strong>
          <br />
          Como Personas Naturales o Empresas (Usuarios) usted es responsable por
          sus propias comunicaciones e información y por las consecuencias de
          incluir o colocar dicha información o comunicaciones en el Web Site.
          Usted no podrá: (i) incluir o colocar en el Web Site material que esté
          protegido por las leyes sobre derechos de autor, a menos que usted sea
          el propietario de tales derechos o haya obtenido permiso del
          propietario de tales derechos para incluir tal material en el Web
          Site, (ii) incluir o colocar en el Web Site material que revele
          secretos industriales o comerciales, a menos que usted sea el
          propietario de los mismos o haya obtenido autorización del
          propietario, (iii) incluir en el Web Site material que de cualquier
          forma pueda implicar una violación de derechos de propiedad
          intelectual o industrial o cualquier otro derecho, (iv) incluir en el
          Web Site material que sea obsceno, difamatorio, abusivo, amenazante u
          ofensivo para cualquier otro usuario o cualquier otra persona o
          entidad, (v) incluir en el Web Site imágenes o declaraciones
          pornográficas o que incluyan sexo explícito o que sea considerada
          pornografía infantil en los términos de la normativa colombiana o
          internacional (vi) incluir o colocar en el Web Site publicidad o
          anuncios publicitarios sin la debida autorización de la Compañía,
          cadenas de cartas, virus, caballos de troya, bombas de tiempo o
          cualquier programa de computador o herramienta con la intención de
          dañar, interferir, interceptar o apropiarse de cualquier sistema,
          datos o información. La Compañía no otorga garantía alguna, expresa o
          implícita, acerca de la veracidad, exactitud o confiabilidad de la
          información incluida en el Web Site por Personas Naturales o Empresas
          (Usuarios) ni apoya o respalda las opiniones expresadas por los
          usuarios. Usted reconoce y declara que la confianza por usted
          depositada en cualquier material incluido en el Web Site por las
          Personas Naturales o Empresas (Usuarios) se hará bajo su propio
          riesgo. La Compañía actúa como un medio pasivo para la distribución y
          publicación en Internet de información presentada por las Personas
          Naturales o Empresas (Usuarios) y no tiene obligación de revisar
          anticipadamente tal información ni es responsable de revisar o
          monitorear la información incluida en el Web Site por los Candidatos o
          Empresas. Si la Compañía es notificada por un Candidato o Empresa
          acerca de la existencia de información que no cumpla con estos
          Términos y Condiciones, la Compañía puede investigar tal información y
          determinar de buena fe y a su exclusiva discreción si remueve o
          elimina tal información o solicita que sea removida o eliminada del
          Web Site. La Compañía se reserva el derecho de expulsar Personas
          Naturales o Empresas (Usuarios) o de prohibir su acceso futuro al Web
          Site por violación de estos Términos y Condiciones o de la ley
          aplicable. Igualmente, la Compañía se reserva el derecho de eliminar
          del Web Site información presentada o incluida por un Personas
          Naturales o Empresas (Usuarios) cuando lo considere apropiado o
          necesario a su exclusiva discreción. Si estima o cree que tal
          información puede generar responsabilidad para la Compañía o puede
          causar la pérdida de los servicios de sus proveedores de internet
          (ISPs) o de otros proveedores.
          <br />
        </Typography>
        <Typography style={{ padding: '8px 0px' }}>
          <strong>
            Inscripción Usuarios: Personas Naturales o Empresas (Personas
            Jurídicas)
          </strong>
          <br />
          Cualquier Persona Natural o Empresa (Persona Jurídica) puede
          registrarse como Usuario de <strong>InfoPorcinos™</strong> ingresando
          al Web Site completando los siguientes pasos: Instructivo de registro
          para empresas: Ingrese a www.infoporcinos.com ; En el menú superior
          ubique y haga clic en: Empresas / Registrar Empresa; Diligencie los
          campos requeridos por el sistema; Valide, revise y lea el Aviso de
          Privacidad y Términos y Condiciones. Si los acepta, puede continuar
          con el registro de la Empresa en <strong>InfoPorcinos™</strong>; Para
          finalizar haga clic en: Continuar. Una vez registrada la Empresa,
          podrá acceder a servicios básicos de consulta sin costo. Sin embargo,
          para poder utilizar la herramienta o funcionalidades de{' '}
          <strong>InfoPorcinos™</strong> deberá pagar por los productos de
          acuerdo con sus necesidades, dentro de las diferentes opciones
          disponibles.
          <br />
        </Typography>
        <Typography style={{ padding: '8px 0px' }}>
          <strong>
            Registro y Contraseña (Password) en el Web Site:
            www.infoporcinos.com
          </strong>
          <br />
          Usted es responsable por mantener la confidencialidad de su contraseña
          o contraseña. Usted será responsable por todos los usos de su registro
          en Web Site, sean o no autorizados por usted. Usted acuerda notificar
          inmediatamente a la Compañía cualquier uso no autorizado de su
          registro y contraseña o contraseña. Las Personas Naturales o Empresas
          (Usuarios) podrán restablecer el correo electrónico asociado con su
          cuenta de <strong>InfoPorcinos™</strong> mediante: (i) el envío de
          mensajes de texto, desde la plataforma al móvil registrado en la
          cuenta, o (ii) a través del reconocimiento óptico de caracteres del
          documento de identidad del usuario. Para el proceso de reconocimiento
          óptico de caracteres, el usuario deberá adjuntar su documento de
          identidad cuando el sistema le indique, a fin de validar la
          información contenida en el mismo y permitir el cambio de correo
          electrónico. En ningún caso, el documento de identidad ingresado en la
          plataforma de <strong>InfoPorcinos™</strong>, será almacenado o usado
          para otros fines distintos a los indicados en el presente título, por
          lo que se procederá a su eliminación inmediata una vez finalizado el
          procedimiento.
          <br />
        </Typography>
        <Typography style={{ padding: '8px 0px' }}>
          <strong>Uso Del Material</strong>
          <br />
          La Compañía lo autoriza a usted a consultar, revisar y usar el
          material que se encuentra en el Web Site, únicamente para su uso
          personal y comercial y según lo establecido en estos Términos y
          Condiciones. El contenido de este Web Site, incluyendo, pero sin
          limitarse a los textos, gráficas, imágenes, logotipos, iconos,
          software y cualquier otro material (el 'Material) están protegidos
          bajo las leyes colombianas de derechos de autor, leyes de propiedad
          industrial y otras leyes aplicables. Todo el Material es de propiedad
          de la Compañía o de sus proveedores o clientes. El uso no autorizado
          del material puede constituir una violación de las leyes colombianas o
          extranjeras sobre derechos de autor, leyes de propiedad industrial u
          otras leyes. Usted no podrá vender o modificar el Material en manera
          alguna, ni ejecutar o anunciar públicamente el Material, ni
          distribuirlo para propósitos comerciales. Usted no podrá copiar o
          adaptar el código HTML que la Compañía crea para generar sus páginas,
          ya que el mismo está protegido por los derechos de autor de la
          Compañía.
          <br />
        </Typography>
        <Typography style={{ padding: '8px 0px' }}>
          <strong>Uso Autorizado del Web Site: www.infoporcinos.com</strong>
          <br />
          Reglas Generales: los Candidatos y Empresas Personas Naturales o
          Empresas (Usuarios) no pueden usar el Web Site con el fin de
          transmitir, distribuir, almacenar o destruir material (i) en violación
          de cualquier ley aplicable o regulación, (ii) de manera que se
          infrinjan las leyes sobre derechos de autor, propiedad industrial,
          secretos comerciales o cualquier otro derecho de propiedad intelectual
          de terceros o de manera que viole la privacidad, publicidad u otros
          derechos personales de terceros, o (iii) en forma que sea difamatoria,
          obscena, amenazante o abusiva. Reglas de Seguridad del Web Site: A los
          Candidatos y Empresas les está prohibido violar o intentar violar la
          seguridad del Web Site. Específicamente, los usuarios no podrán (i)
          acceder a información que no esté dirigida o autorizada a dicho
          usuario o acceder a servidores o cuentas a los cuales el usuario no
          está autorizado a acceder, (ii) intentar probar la vulnerabilidad de
          un sistema o red sin la debida autorización o violar las medidas de
          seguridad o autenticación, (iii) intentar interferir con los servicios
          prestados a un usuario, servidor o red, incluyendo , pero sin
          limitarse al envío de virus a través del Web Site, (iv) enviar correo
          electrónico no solicitado, incluyendo promociones y/o publicidad de
          productos o servicios. La violación de cualquier sistema o red de
          seguridad puede resultar en responsabilidades civiles y penales. La
          Compañía investigará la ocurrencia de hechos que puedan constituir
          violaciones a lo anterior y cooperará con cualquier autoridad
          competente en la persecución de los usuarios que estén envueltos en
          tales violaciones.
          <br />
        </Typography>
        <Typography style={{ padding: '8px 0px' }}>
          <strong>Usos Prohibidos del Web Site: www.infoporcinos.com</strong>
          <br />
          El Web Site puede ser usado únicamente para propósitos legales. La
          Compañía prohíbe el uso del Web Site en cualquiera de las siguientes
          formas: Incluir en el Web Site cualquier información sobre el producto
          a vender u ofrecer, falsa o inexacta o información que no corresponda
          a la realidad. Incluir en el Web Site cualquier derecho de franquicia,
          esquema de pirámide, membresía a un club o grupo, representación de
          ventas, agencia comercial o cualquier oportunidad de negocios que
          requiera un pago anticipado o pagos periódicos, solicitando el
          reclutamiento de otros miembros, sub-distribuidores o sub-agentes.
          Borrar o revisar cualquier material incluido en el Web Site por
          cualquiera otra persona o entidad, sin la debida autorización. Usar
          cualquier elemento, diseño, software o rutina para interferir o
          intentar interferir con el funcionamiento adecuado de este Web Site o
          cualquier actividad que sea llevada a cabo en el Web Site. Intentar,
          descifrar, compilar o desensamblar cualquier software comprendido en
          el Web Site o que de cualquier manera haga parte del Web Site. En
          general, incluir o colocar en Web Site información falsa, inexacta,
          incompleta o engañosa. Si usted tiene una contraseña o contraseña que
          le permita el acceso a un área no pública de este Web Site, no podrá
          revelar o compartir esa contraseña o contraseña con terceras personas
          o usar la contraseña o contraseña para propósitos no autorizados.
          <br />
        </Typography>
      </Box>
      <Box margin="12px 0px">
        <Typography variant="h6" color="secondary" align="center">
          Sobre la Política de Datos de Navegación de InfoPorcinos™
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            1. ¿QUÉ SON Y QUE NO SON LAS COOKIES/DATOS DE NAVEGACIÓN?
          </strong>
          <br />
          Las cookies/datos de navegación son pequeños archivos de información o
          fragmentos de texto enviados por un Portal o Aplicación tecnológica de
          propiedad de <strong>MERCADEO VIRTUAL S.A. (InfoPorcinos™)</strong> o
          alguna de sus sociedades controladas o que se encuentren bajo el mismo
          nivel de control (en adelante Portales y Aplicaciones (
          <strong>InfoPorcinos™</strong>) y que se almacenan en el navegador o
          dispositivo del usuario. A través de las cookies, los Portales
          recuerdan información sobre la visita de los usuarios, lo que permite
          que se proporcione una mejor y más segura experiencia de navegación en
          el mismo. Las cookies se asocian tanto a usuarios anónimos, es decir,
          aquellos que visitan los Portales sin identificarse o registrarse,
          como aquellos que sí lo hacen.
          <br />
          Por otro lado, las cookies no son un virus o cualquier otro tipo de
          programa malicioso que pueda dañar dispositivos de los usuarios.
          Adicionalmente, las cookies no pueden borrar ni leer información del
          computador o dispositivo de los usuarios.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>2. ¿CÓMO OBTIENE InfoPorcinos™ LAS COOKIES?</strong>
          <br />
          Las cookies se crean y/o actualizan en el computador o dispositivo del
          usuario de manera automática, cuando este accede a un Portal de{' '}
          <strong>InfoPorcinos™</strong>, lo cual permite a{' '}
          <strong>InfoPorcinos™</strong> o a las terceras personas que este
          contrate, realizar seguimiento a las cookies del usuario y por ende a
          la información que estas cookies contienen u obtienen del usuario. Es
          importante aclarar que las cookies solo son leídas por el sitio web
          que las creó.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            3. ¿QUÉ CLASE DE COOKIES UTILIZA InfoPorcinos™ Y PARA QUÉ LAS
            UTILIZA?
          </strong>
          <ol>
            <li>
              <strong>Cookies Esenciales</strong>
              <br />
              Estas cookies son esenciales para el Uso del Portal, en el sentido
              de facilitar el proceso de registro en el mismo, así como permitir
              a los usuarios un acceso más rápido y seguro a los servicios
              seleccionados dentro del Portal. Sin estas cookies, es posible que
              <strong> InfoPorcinos™</strong> no les pueda ofrecer a los
              usuarios ciertos servicios dentro del Portal y este puede que no
              funcione con normalidad.
            </li>
            <li>
              <strong>Cookies Funcionales</strong>
              <br />
              Mediante el uso de las cookies funcionales, es posible para
              <strong> InfoPorcinos™</strong> o el tercero que este contrate,
              personalizar los servicios que se ofrecen en el Portal,
              facilitando a cada usuario información que es o puede ser de su
              interés, en atención al uso que realiza de los servicios y a las
              páginas específicas que visita dentro del Portal. A través de
              estas cookies, <strong> InfoPorcinos™ </strong>o las terceras
              personas que contrate, podrán ofrecer a los usuarios una mejor
              experiencia y mejor rendimiento en la navegación dentro del
              Portal.
            </li>
            <li>
              <strong>Cookies de Publicidad.</strong>
              <br />
              <strong> InfoPorcinos™</strong> o el tercero que este contrate,
              mediante el uso de estas cookies, podrá entregarle al usuario
              publicidad que considere que puede llegar a ser de su interés,
              según las preferencias y comportamientos que el usuario tiene o ha
              tenido dentro de la red de Portales de
              <strong> InfoPorcinos™</strong>. A través de estas cookies, se
              podrá optimizar la publicidad que el usuario ve en el Portal. Las
              cookies de publicidad podrán ser entregadas a anunciantes de
              <strong> InfoPorcinos™</strong>, para que sean utilizadas por
              estos para sus campañas de mercadeo y publicidad.
            </li>
            <li>
              <strong>Cookies de Análisis o Desempeño</strong>
              <br />
              Contrate, puede realizar distintos análisis y estudios de la
              información recolectada, con el fin de mejorar los productos y
              servicios que <strong> InfoPorcinos™</strong> ofrece a los
              usuarios.
            </li>
            <li>
              <strong> InfoPorcinos™</strong> y los terceros con los que
              contrate servicios para el uso de cookies, son los únicos que
              podrán acceder a la información almacenada en las cookies que se
              han instalado dentro del equipo del usuario. La información
              almacenada en las cookies no puede ser leída por otros usuarios,
              ni estos podrán acceder a la misma.
            </li>
          </ol>
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            4. ¿PUEDO DESHABILITAR LA INSTALACIÓN Y USO DE COOKIES POR PARTE DE
            InfoPorcinos™?
          </strong>
          <br />
          El usuario puede deshabilitar tanto la instalación de las cookies como
          el uso de estas por parte de <strong>InfoPorcinos™</strong>. Para
          deshabilitar la instalación y uso de cookies, el usuario deberá
          realizar el procedimiento por navegador tal como se indica a
          continuación:
          <br />
          <ul>
            <li>
              Internet Explorer: Herramientas {'->'} Opciones de Internet {'->'}{' '}
              Privacidad {'->'} Configuración. Para más información, puede
              consultar el Soporte de Microsoft o la Ayuda del navegador
            </li>
            <li>
              Firefox: Herramientas {'->'} Opciones {'->'} Privacidad {'->'}{' '}
              Historial {'->'} Configuración Personalizada. Para más
              información, puede consultar el Soporte de Mozilla o la Ayuda del
              navegador.
            </li>
            <li>
              Chrome: Configuración {'->'} Mostrar opciones avanzadas {'->'}{' '}
              Privacidad {'->'} Configuración de contenido. Para más
              información, puede consultar el Soporte de Google o la Ayuda del
              navegador.
            </li>
            <li>
              Safari: Preferencias {'->'} Seguridad. Para más información, puede
              consultar el Soporte de Apple o la Ayuda del navegador.
            </li>
          </ul>
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>5. ¿QUÉ OCURRE SI SE DESHABILITAN LAS COOKIES?</strong>
          <br />
          Si el usuario deshabilita la instalación o el uso de las cookies para
          los Portales, podrá perder o afectar algunas funcionalidades del
          sitio, como, por ejemplo:
          <br />
          <ol>
            <li>
              Poder ingresar a los Portales sin necesidad de indicar en cada
              ocasión su usuario y contraseña, esto le ahorrará tiempo y le
              permitirá utilizar funcionalidades como boletines, alertas,
              noticias guardadas, entre otros.
            </li>
            <li>Publicación de comentarios dentro de los Portales.</li>
            <li>Acceso al contenido sin restricción dentro de los Portales.</li>
            <li>
              Seguridad en el uso de la información que se ingresa en los
              Portales.
            </li>
            <li>Rapidez en el uso de algún servicio dentro de los Portales.</li>
          </ol>
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            6. ¿InfoPorcinos™ COMPARTE LA INFORMACIÓN OBTENIDA A TRAVÉS DE LAS
            COOKIES CON TERCERAS PERSONAS?
          </strong>
          <br />
          <strong>InfoPorcinos™</strong> podrá compartir información obtenida a
          través de las cookies con personas externas o terceros (aliados,
          clientes, proveedores o empresas vinculadas a{' '}
          <strong>InfoPorcinos™</strong>, con el fin de mejorar la usabilidad y
          servicios al usuario. Así mismo, la información que se recibe a través
          de las cookies será utilizada por
          <strong> InfoPorcinos™</strong> y los anteriores terceros, para los
          fines descritos en el presente documento, y cualquiera de sus
          actualizaciones.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            7. ¿DÓNDE ESTÁ ALMACENADA LA INFORMACIÓN OBTENIDA A TRAVÉS DE LAS
            COOKIES?
          </strong>
          <br />
          <strong>InfoPorcinos™</strong> podrá contratar terceras personas
          encargadas de almacenar y obtener la información a través de las
          cookies, o que incluyan cookies dentro de los portales de{' '}
          <strong>InfoPorcinos™</strong>, personas que podrán estar localizadas
          dentro de Colombia o en el exterior. Así mismo,{' '}
          <strong>InfoPorcinos™</strong> podrá entregar a terceros, la
          información que se obtenga de las cookies para crear perfiles de
          usuarios, ofrecer campañas personalizadas, sin que lo anterior,
          implique entrega de información personal.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            8. ¿QUÉ DEBO TENER EN CUENTA SOBRE LA POLÍTICA DE USO DE COOKIES Y
            DÓNDE ME PUEDO CONTACTAR EN CASO DE DUDAS?
          </strong>
          <br />
          La presente Política contiene la información necesaria que debe
          conocer todo usuario de los Portales sobre el uso de las cookies que
          realiza <strong>InfoPorcinos™</strong> o los terceros que este
          contrate. <strong>InfoPorcinos™</strong> podrá modificar el presente
          documento en cualquier momento y sin previo aviso para mantenerlos
          vigentes y actualizados. Por lo anterior, recomendamos a los usuarios
          revisar la fecha de elaboración o actualización de estos, la cual se
          establece al final del presente documento.
          <br />
        </Typography>

        <Typography style={{ padding: '12px 0px' }}>
          En caso de dudas o inquietudes acerca del uso de cookies o sobre
          cualquiera de los puntos detallados en esta Política, por favor
          escriba al siguiente correo electrónico: info@infoporcinos.com
          <br />
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <strong>MERCADEO VIRTUAL S.A.</strong>
          <br />
          Calle 48 No. 77 – 78 Sector Estadio Velódromo <br />
          Zona Postal 050034
          <br /> Medellín – Colombia <br />
          Pbx + 57 604 3228603
          <br />
          <a href="http://www.mercadeo-virtual.com" target="_blank">
            www.mercadeo-virtual.com
          </a>{' '}
          /{' '}
          <a
            href="http://www.infoporcinos.com/sitio/Principal.aspx"
            target="_blank"
          >
            www.infoporcinos.com
          </a>
          <br />
          Correo: info@infoporcinos.com
        </Box>
        <Box marginRight={2}>
          <img src="https://raw.githubusercontent.com/repositoriosmv/infoporcinos-images/main/logo-mv1.png" />
        </Box>
      </Box>
    </div>
  );
};

export default textTerms;
