import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from '@material-ui/core';

import ExistingUser from 'components/ExistingUser';

import { ASSIGN_EXISTING_DESTINY } from 'core/graphql/mutations/profile';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import useStyles from './styles';

const ExistingDestiny = ({ data, open, onClose }: any) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  let params: any = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [assign, {}] = useMutation(ASSIGN_EXISTING_DESTINY, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const AssignDestiny = () => {
    assign({
      variables: {
        idDestino: data?.idDestino,
      },
    })
      .then(() => {
        enqueueSnackbar(
          `Se ha asignado la ${
            params.type === 2
              ? 'Planta de Desposte'
              : 'Planta de Acondicionamiento'
          } exitosamente.`,
          {
            variant: 'success',
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          }
        );
        history.goBack();
      })
      .catch(() => {
        enqueueSnackbar(
          `No se pudo asignar la ${
            params.type === 2
              ? 'Planta de Desposte'
              : 'Planta de Acondicionamiento'
          }`,
          {
            variant: 'error',
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          }
        );
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            ¡Destino Existente!
          </Typography>
          <Typography color="secondary" align="center">
            Ya Existe un destino con el NIT ingresado, puedes asignarlo a tus
            destinos o verificar los datos para registrar uno diferente.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box marginY={1} display="flex" justifyContent="center">
            <ExistingUser
              name={data?.nombreDestino}
              info={data?.codigoDestino}
              selected={false}
              column
              disabled
              extrainfo={`NIT: ${data?.nit} - ${data?.digitoVerificacion}`}
            />
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button variant="contained" color="primary" onClick={AssignDestiny}>
            Asociar
          </Button>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExistingDestiny;
