import { makeStyles } from "@material-ui/core";
import { marginTop } from "styled-system";

const useStyles = makeStyles(theme => ({
  colorHr: {
    borderColor: '#E2E2E2',
  },
  fontTitle:{
    fontWeight: 'bold',
    marginRight: '14px',
  },
  fontResultado: {
    fontSize: '18px',
  },
  display: {
    display: 'flex',
  },
  marginBottomT:{
    marginBottom: '10px'
  },
  dataGrid: {
    border: 'none',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator" :{
      display:'none'
    },
    "& .MuiDataGrid-colCellTitle" :{
      color: '#AFAFAF',
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14
    }
  },
  colorChip:{
    backgroundColor: '#767676 !important',
    "& .MuiChip-labelSmall":{
      textDecoration: 'none',
      color: 'white'
    }
  },
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
  }, 
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& :hover":{
      color: '#fff'
    }
  },
}))

export { useStyles as default }