import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  iconContainer:{
    borderRadius: '50px',
    border: '2px solid #DD9835',
    width: '42px',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#DD9835',
  },
  titleIconContainer: {
    color: '#AFAFAF',
    fontSize: '17px',
    fontWeight: 'bolder',
  },
  infoIconContainer:{
    color: '#767676', 
    fontSize: '15px'
  },
  avatarColor:{
    background: '#DD9835'
  } 
}))

export { useStyles as default }