import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import React from "react";

import Login from "pages/LoginContainer";
import ForgotPassword from "pages/ForgotPassword";
import RecoverPassword from "pages/RecoverPassword";
import RequestUserContainer from "pages/RequestUserContainer";
import RegisterUser from "pages/RegisterUser";

import UserResgister from "pages/UserRegister";

export default () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Redirect from="/auth" to="/auth/login" exact />
      <Route path={`${path}/login`} component={Login} exact />
      <Route path={`${path}/login/:id`} component={Login} exact />
      <Route
        path={`${path}/forgot-password`}
        component={ForgotPassword}
        exact
      />
      <Route
        path={`${path}/recover-password`}
        component={RecoverPassword}
        exact
      />
      <Route path={`${path}/request-user`} component={UserResgister} exact />
      <Route path={`${path}/register`} component={RegisterUser} exact />
    </Switch>
  );
};
