import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formCheck: {
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
  },
  formControlSpacing: {
    margin: "8px 0px 0px 0px",
  },

  toogleButton: {
    "&.MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#DD9835",
      color: "#FFF",
    },
    "&.MuiToggleButton-root": {
      backgroundColor: "#E9E9E9",
      color: "#767676",
    },
  },

  coordinatesSeparate: {
    margin: "20px 0px 10px 0px",
  },

  label: {
    fontSize: 16,
    color: "#AFAFAF",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  flexContainerCoords: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  postalCode: {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "black",
    },
  },

  boxBorder: {
    border: "1px solid #E9E9E9",
    borderRadius: 10,
    padding: "10px 0px",
  },

  boxBorderData: {
    border: "1px solid #E9E9E9",
    borderRadius: 10,
    padding: "20px 20px",
  },
}));

export default useStyles;
