import Corrals from './Corrals'; 
import Cavas from './Cavas'; 

import { NestedRoute } from 'lib/interfaces/routes';

const routes: Array<NestedRoute> = [
  {
    route: '/corral',
    Node: Corrals
  }, 
  {
    route: '/cava', 
    Node: Cavas
  }
]; 

export default routes;
