import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  breadContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px 0",
  },
  generalContainer: {
    "& .kkIWUE": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px !important",
      },
    },
    "& .cKVytO": {
      [theme.breakpoints.between("sm", "md")]: {
        padding: "14px !important",
      },
    },
  },
  gridHeaderContainer: {
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  contentCard: {
    width: "100%",
  },
  numStatistics: {
    fontWeight: 600,
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 40,
    },
  },
  titleHeaderTable: {
    fontSize: 24,
    fontWeight: 600,
  },
  textBold: {
    fontWeight: 600,
  },
  textUsersGray: {
    fontWeight: 600,
    color: "#767676",
    fontFamily: "Roboto",
  },
  activityContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "12px 0",
  },
  activityContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "24px 0",
    color: "#AFAFAF",
  },
  historialContent: {
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  filterHistorial: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  containerButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 0",
  },
  contentTableHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 12,
  },
  contentHeaderChild1: {
    width: "40%",
    display: "flex",
    [theme.breakpoints.between("sm", "md")]: {
      width: "60%",
    },
  },
  result: {
    display: "flex",
    alignItems: "center",
    marginLeft: 8,
    "& i": {
      fontSize: 24,
    },
  },
  containerTab: {
    padding: 12,
  },
  tabs: {
    width: "50%",
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
  },
  tabsContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  tabTitle: {
    fontWeight: 900,
    minWidth: 100,
    textTransform: "capitalize",
    fontSize: 14,
  },
  tabsChild: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  dataGrid: {
    minHeight: 400,
    border: "none",
    display: "flex",
    margin: "12px 0",
    "& .MuiBox-root": {
      margin: "20px 0",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      textAlign: "center",
      whiteSpace: "break-spaces",
      lineHeight: "normal",
      color: "#AFAFAF",
      fontWeight: "bold",
      fontSize: 14,
    },
    "& .MuiDataGrid-columnsContainer": {
      justifyContent: "center",
    },
  },
  noData: {
    minHeight: 400,
    margin: "12px 0",
    border: "none",
    "& .MuiDataGrid-columnsContainer": {
      display: "none",
    },
  },
  btnDetails: {
    width: "35px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& :hover": {
      color: "#fff",
    },
  },
  containerState: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  stateItems1: {
    width: 10,
    height: 10,
    borderRadius: "100%",
    background: "#23DB6A",
  },
  stateItems2: {
    width: 10,
    height: 10,
    borderRadius: "100%",
    background: "#CB3737",
  },
  headerTableGrid: {
    fontSize: 14,
    color: "#AFAFAF",
  },
  avatar: {
    width: "30px",
    height: "30px",
    fontSize: "12px",
  },
  labelSubs: {
    fontWeight: 900,
    fontSize: 16,
  },
  containerItemSubs: {
    "&.center": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  titleSubs: {
    fontSize: 14,
    [theme.breakpoints.between("sm", "lg")]: {
      fontSize: 12,
    },
  },
  subtitleSubs: {
    color: "#767676",
    fontWeight: "bold",
    fontSize: 18,
    [theme.breakpoints.between("sm", "lg")]: {
      fontSize: 16,
    },
  },
}));

export { useStyles as default };
