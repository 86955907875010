export default (number: number | string, separator?: string, decimals?: number): string => {
  separator = separator || '.'
  decimals = decimals || 2
  const formatNumber = +number

  return (
    formatNumber.toLocaleString().split(separator)[0] +
    separator +
    formatNumber.toFixed(decimals).split(separator)[1]
  )
}
