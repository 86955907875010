import { Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

import { ReactComponent as TechnicalError } from 'static/img/pigs/technical-error.svg';

import useStyles from './styles';

const Page404 = () => {
  const classes = useStyles();
  const history = useHistory();

  const toHome = () => history.push('/app/dashboard');

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Paper elevation={5} className={classes.content}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <TechnicalError className={classes.image} />
            </Grid>
            <Grid item xs={8} className={classes.text_container}>
              <Typography variant="h1">¡Ups! Sin acceso</Typography>
              <Typography color="textSecondary">
                No tienes acceso a esta página.
              </Typography>
              <Button onClick={toHome} variant="contained" color="primary">
                Volver al Inicio
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default Page404;
