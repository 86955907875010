import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Box, TextField } from '@material-ui/core';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import domtoimage from 'dom-to-image';
import clsx from 'clsx';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableInfo from 'components/TableInfo';
import GobackLink from 'components/GobackLink';
import Chart from 'components/Chart';
import ExportToExcel from 'components/ExportToExcel';
import LoteInfoTitle from 'components/LoteInfoTitle';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import DialogPDFViewer from 'components/DialogPDFViewer/index';

import {
  MyDocSacrifice,
  ReportLot,
  GraphSacrifice,
} from './../../Reports/ReportLot';

import useStyles from './styles';

const index = () => {
  const [openLotDistribution, setOpenLotDistribution] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenst');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const generateScreenshotGraph = () => {
    const graph: any = document.getElementById('graphsacrifice');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeAnteMortem = () => {
    setOpenLotDistribution(true);
  };

  useEffect(() => {
    generateScreenshot();
    generateScreenshotGraph();
  }, [openLotDistribution]);

  const columns: GridColDef[] = [
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'canalID',
      headerName: 'Canal ID',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'brazalete',
      headerName: 'Brazalete',
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'seurop',
      headerName: 'SEUROP',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'pcc',
      headerName: 'PCC (Kg)',
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'pcr',
      headerName: 'PCR (Kg)',
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'temp',
      headerName: 'Temp (°C)',
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'gd',
      headerName: 'GD (m.m)',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ml',
      headerName: 'ML (m.m)',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mc',
      headerName: 'MC (%)',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'pmc',
      headerName: 'PMC (Kg)',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'indicio',
      headerName: 'Indicio (PSE)',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'inspeccion',
      headerName: 'Inspección Veterinaria',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const rows = [
    {
      id: 1,
      loteIP: '1234',
      canalID: '1234',
      brazalete: '1234532',
      seurop: 'E',
      pcc: '100,2',
      pcr: '100,2',
      temp: '29,2',
      gd: '29,2',
      ml: '29,2',
      mc: '29,2',
      pmc: '70,2',
      indicio: '.',
      inspeccion: '2',
    },
    {
      id: 2,
      loteIP: '1234',
      canalID: '1234',
      brazalete: '1234532',
      seurop: 'E',
      pcc: '100,2',
      pcr: '100,2',
      temp: '29,2',
      gd: '29,2',
      ml: '29,2',
      mc: '29,2',
      pmc: '70,2',
      indicio: '.',
      inspeccion: '2',
    },
    {
      id: 3,
      loteIP: '1234',
      canalID: '1234',
      brazalete: '1234532',
      seurop: 'E',
      pcc: '100,2',
      pcr: '100,2',
      temp: '29,2',
      gd: '29,2',
      ml: '29,2',
      mc: '29,2',
      pmc: '70,2',
      indicio: '.',
      inspeccion: '2',
    },
    {
      id: 4,
      loteIP: '1234',
      canalID: '1234',
      brazalete: '1234532',
      seurop: 'E',
      pcc: '100,2',
      pcr: '100,2',
      temp: '29,2',
      gd: '29,2',
      ml: '29,2',
      mc: '29,2',
      pmc: '70,2',
      indicio: '.',
      inspeccion: '2',
    },
    {
      id: 5,
      loteIP: '1234',
      canalID: '1234',
      brazalete: '1234532',
      seurop: 'E',
      pcc: '100,2',
      pcr: '100,2',
      temp: '29,2',
      gd: '29,2',
      ml: '29,2',
      mc: '29,2',
      pmc: '70,2',
      indicio: '.',
      inspeccion: '2',
    },
    {
      id: 6,
      loteIP: '1234',
      canalID: '1234',
      brazalete: '1234532',
      seurop: 'E',
      pcc: '100,2',
      pcr: '100,2',
      temp: '29,2',
      gd: '29,2',
      ml: '29,2',
      mc: '29,2',
      pmc: '70,2',
      indicio: '.',
      inspeccion: '2',
    },
    {
      id: 7,
      loteIP: '1234',
      canalID: '1234',
      brazalete: '1234532',
      seurop: 'E',
      pcc: '100,2',
      pcr: '100,2',
      temp: '29,2',
      gd: '29,2',
      ml: '29,2',
      mc: '29,2',
      pmc: '70,2',
      indicio: '.',
      inspeccion: '2',
    },
    {
      id: 8,
      loteIP: '1234',
      canalID: '1234',
      brazalete: '1234532',
      seurop: 'E',
      pcc: '100,2',
      pcr: '100,2',
      temp: '29,2',
      gd: '29,2',
      ml: '29,2',
      mc: '29,2',
      pmc: '70,2',
      indicio: '.',
      inspeccion: '2',
    },
  ];

  const rowsSecond = [
    {
      id: 1,
      loteIP: '1234',
      canalID: '1234',
      brazalete: '1234532',
      seurop: 'E',
      pcc: '100,2',
      pcr: '100,2',
      temp: '29,2',
      gd: '29,2',
      ml: '29,2',
      mc: '29,2',
      pmc: '70,2',
      indicio: '.',
      inspeccion: '2',
    },
    {
      id: 2,
      loteIP: '1234',
      canalID: '1234',
      brazalete: '1234532',
      seurop: 'E',
      pcc: '100,2',
      pcr: '100,2',
      temp: '29,2',
      gd: '29,2',
      ml: '29,2',
      mc: '29,2',
      pmc: '70,2',
      indicio: '.',
      inspeccion: '2',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/magro/details',
      title: 'Reporte Evaluación de Magro',
    },
  ];

  const classes = useStyles();

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphSacrifice />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoteInfoTitle
              lot="7645"
              namereport="Nro. Reporte"
              numberreport="011-65432"
              title="Certificado de sacrificio y transporte"
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Agentes relacionados
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <TableInfo
              icon="icon-planta"
              title="Planta Beneficio"
              items={{
                Nombre: 'Nombre Planta',
                NIT: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TableInfo
              icon="icon-comercializador"
              title="Comercializador"
              items={{
                Nombre: 'Nombre comercializador',
                NIT: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@comercializador.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TableInfo
              icon="icon-map-marker"
              title="Destino"
              items={{
                Nombre: 'Nombre del Destino',
                Responsable: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@destino.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>

          <Grid item xs={12} xl={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Resumen de Despacho
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} xl={6}>
            <TableInfo
              icon="icon-truck"
              title="Transporte"
              items={{
                Nombre: 'Nombre del Conductor',
                Documento: '45134132',
                Teléfono: '+57 314 6543 543',
                'Placa Vehículo': 'SRM 091, Medellín',
                'Marca Vehículo': 'Toyota',
              }}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <TableInfo
              title="Registro Transporte"
              items={{
                'Fecha / Hora LLegada Planta': 'N/D',
                'Fecha / Hora Ingreso Planta': 'N/D',
                'Fecha / Hora Salida Planta': 'N/D',
                'Fecha / Hora Inicio Despacho': '01/10/2019 09:26:00 a.m.',
                'Fecha / Hora Final Despacho': '01/10/2019 09:26:00 a.m.',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Resumen del Certificado
              </Typography>
            </div>
          </Grid>
          <Grid item xl={7} xs={12}>
            <div className={classes.flexContainerEvenly}>
              <div className={classes.itemtotalInfo}>
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={72}
                  titleInfo="Total Canales Despachadas"
                  avatarTotalColor="#B0413E"
                  avatarTotalSize={75}
                  avatarTextSize={25}
                  titleInfoSize={250}
                />
              </div>
              <div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    avatarTotalInfo={'46,03 %'}
                    titleInfo="Promedio Porcentaje de Magro (MC)"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    avatarTotalInfo={'70,06 m.m'}
                    titleInfo="Promedio Grasa Dorsal (GD)"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={180}
                    position="right"
                    titleInverted
                  />
                </div>
              </div>
            </div>
            <div className={classes.flexContainerEvenly}>
              <div className={classes.itemtotalInfo}>
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={72}
                  titleInfo="Canales Evaluadas"
                  avatarTotalColor="#E29662"
                  avatarTotalSize={55}
                  avatarTextSize={22}
                  titleInfoSize={250}
                />
              </div>
              <div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    avatarTotalInfo={'29,08 m.m'}
                    titleInfo="Promedio Lomo (LM)"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    avatarTotalInfo={'84,32 %'}
                    titleInfo="Rendimiento Canal Caliente (RCCL)"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
              </div>
            </div>
            <div className={classes.flexContainerEvenly}>
              <div className={classes.itemtotalInfo}>
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={0}
                  titleInfo="Sala de Emergencia"
                  avatarTotalColor="#FFC676"
                  avatarTotalSize={40}
                  avatarTextSize={22}
                  titleInfoSize={250}
                />
              </div>
              <div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    avatarTotalInfo={'84, 32%'}
                    titleInfo="Rendimiento Canal Refrigerada (RCRL)"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xl={5} xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.orangeTypo}>PPB</Typography>

              <ItemTotalInfo
                avatarTotalInfo={'45'}
                titleInfo="Pesados"
                avatarTotalColor="#F2F2F2"
                avatarTotalSize={20}
                avatarTextSize={16}
                extended
                titleInfoSize={150}
                position="bottom"
                titleInverted
              />
              <ItemTotalInfo
                avatarTotalInfo={'7.009,00'}
                titleInfo="Total Pesados (Kg)"
                avatarTotalColor="#F2F2F2"
                avatarTotalSize={20}
                avatarTextSize={16}
                extended
                titleInfoSize={150}
                position="bottom"
                titleInverted
              />
              <ItemTotalInfo
                avatarTotalInfo={'155,75'}
                titleInfo="Promedio (Kg)"
                avatarTotalColor="#F2F2F2"
                avatarTotalSize={20}
                avatarTextSize={16}
                extended
                titleInfoSize={150}
                position="bottom"
                titleInverted
              />
            </div>
            <div className={classes.grayContainer}>
              <Typography className={classes.orangeTypo}>PCC</Typography>

              <ItemTotalInfo
                avatarTotalInfo={'45'}
                titleInfo="Pesados"
                avatarTotalColor="#F2F2F2"
                avatarTotalSize={20}
                avatarTextSize={16}
                extended
                titleInfoSize={150}
                position="bottom"
                titleInverted
              />
              <ItemTotalInfo
                avatarTotalInfo={'7.009,00'}
                titleInfo="Total Pesados (Kg)"
                avatarTotalColor="#F2F2F2"
                avatarTotalSize={20}
                avatarTextSize={16}
                extended
                titleInfoSize={150}
                position="bottom"
                titleInverted
              />
              <ItemTotalInfo
                avatarTotalInfo={'155,75'}
                titleInfo="Promedio (Kg)"
                avatarTotalColor="#F2F2F2"
                avatarTotalSize={20}
                avatarTextSize={16}
                extended
                titleInfoSize={150}
                position="bottom"
                titleInverted
              />
            </div>
            <div className={classes.grayContainer}>
              <Typography className={classes.orangeTypo}>PCR</Typography>
              <ItemTotalInfo
                avatarTotalInfo={'45'}
                titleInfo="Pesados"
                avatarTotalColor="#F2F2F2"
                avatarTotalSize={20}
                avatarTextSize={16}
                extended
                titleInfoSize={150}
                position="bottom"
                titleInverted
              />
              <ItemTotalInfo
                avatarTotalInfo={'7.009,00'}
                titleInfo="Total Pesados (Kg)"
                avatarTotalColor="#F2F2F2"
                avatarTotalSize={20}
                avatarTextSize={16}
                extended
                titleInfoSize={150}
                position="bottom"
                titleInverted
              />
              <ItemTotalInfo
                avatarTotalInfo={'155,75'}
                titleInfo="Promedio (Kg)"
                avatarTotalColor="#F2F2F2"
                avatarTotalSize={20}
                avatarTextSize={16}
                extended
                titleInfoSize={150}
                position="bottom"
                titleInverted
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Listado de Canales con Despachadas
              </Typography>
              <Box position="absolute" right={8} m={2}>
                <ExportToExcel
                  data={rows}
                  headers={columns.map((columns: any) => columns.field)}
                  fileName="Listado-Canales-Despachadas"
                />
              </Box>
            </div>

            <div>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
              />
            </div>
            <div
              className={clsx(classes.flexContainer, classes.responsiveColumn)}
            >
              <div className={classes.marginBox}>
                <ItemTotalInfo
                  avatarTotalInfo={70}
                  titleInfo="Total Canales"
                  avatarTotalColor="#F2F2F2"
                  avatarTotalSize={35}
                  avatarTextSize={16}
                  extended
                  titleInfoSize={200}
                  position="right"
                  titleInverted
                />
              </div>
              <div>
                <div
                  className={clsx(
                    classes.flexContainerEvenly,
                    classes.responsiveColumn
                  )}
                >
                  <Typography className={classes.labelTypo}>
                    Totales y Promedios
                  </Typography>
                  <div className={classes.centerContainer}>
                    <div className={classes.marginBox}>
                      <ItemTotalInfo
                        avatarTotalInfo={'5.232,80'}
                        titleInfo="PCC (Kg)"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        titleInfoSize={200}
                        position="right"
                        titleInverted
                      />
                    </div>
                    <div className={classes.marginBox}>
                      <ItemTotalInfo
                        avatarTotalInfo={'98,9'}
                        titleInfo="PCC Prom. (Kg)"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        titleInfoSize={200}
                        position="right"
                        titleInverted
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className={classes.marginBox}>
                  <ItemTotalInfo
                    avatarTotalInfo={'5.092,80'}
                    titleInfo="PCR (Kg)"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
                <div className={classes.marginBox}>
                  <ItemTotalInfo
                    avatarTotalInfo={'96,3'}
                    titleInfo="PCR Prom. (Kg)"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Listado de Canales con Novedad
              </Typography>
              <Box position="absolute" right={8} m={2}>
                <ExportToExcel
                  data={rowsSecond}
                  headers={columns.map((columns: any) => columns.field)}
                  fileName="Listado-Canales-Novedad"
                />
              </Box>
            </div>
            <div>
              <DataGrid
                rows={rowsSecond}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
              />
            </div>
            <div
              className={clsx(classes.flexContainer, classes.responsiveColumn)}
            >
              <div className={classes.marginBox}>
                <ItemTotalInfo
                  avatarTotalInfo={2}
                  titleInfo="Total Canales"
                  avatarTotalColor="#F2F2F2"
                  avatarTotalSize={35}
                  avatarTextSize={16}
                  extended
                  titleInfoSize={200}
                  position="right"
                  titleInverted
                />
              </div>
              <div>
                <div
                  className={clsx(
                    classes.flexContainerEvenly,
                    classes.responsiveColumn
                  )}
                >
                  <Typography className={classes.labelTypo}>
                    Totales y Promedios
                  </Typography>
                  <div className={classes.centerContainer}>
                    <div className={classes.marginBox}>
                      <ItemTotalInfo
                        avatarTotalInfo={'196'}
                        titleInfo="PCC (Kg)"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        titleInfoSize={200}
                        position="right"
                        titleInverted
                      />
                    </div>
                    <div className={classes.marginBox}>
                      <ItemTotalInfo
                        avatarTotalInfo={'98,9'}
                        titleInfo="PCC Prom. (Kg)"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        titleInfoSize={200}
                        position="right"
                        titleInverted
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className={classes.marginBox}>
                  <ItemTotalInfo
                    avatarTotalInfo={'192'}
                    titleInfo="PCR (Kg)"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
                <div className={classes.marginBox}>
                  <ItemTotalInfo
                    avatarTotalInfo={'96,3'}
                    titleInfo="PCR Prom. (Kg)"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              className={clsx(classes.grayContainer, classes.responsiveColumn)}
            >
              <div style={{ width: '100%' }}>
                <Typography className={classes.paperSubtitles}>
                  Observaciones Canales sin Registro de Peso
                </Typography>
              </div>
              <div className={classes.flexContainer}>
                <div>
                  <ItemTotalInfo
                    avatarTotalInfo={2}
                    titleInfo="Animales en Pie"
                    avatarTotalColor="#FFF"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
                <div>
                  <ItemTotalInfo
                    avatarTotalInfo={0}
                    titleInfo="Canales Calientes"
                    avatarTotalColor="#FFF"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
                <div>
                  <ItemTotalInfo
                    avatarTotalInfo={0}
                    titleInfo="Canales Refrigeradas"
                    avatarTotalColor="#FFF"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="right"
                    titleInverted
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} xl={8}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Resumen Decomisos - Post Mortem
              </Typography>
            </div>
            <div className={classes.flexContainer}>
              <div className={classes.minContainer}>
                <TableInfo
                  withoutBox
                  title=""
                  items={{
                    'Nombre Inspector': 'Juan David Mejía Ramirez',
                    'Nro. Documento': '3124123',
                    'Nro. Matrícula': '1234123412',
                  }}
                />
              </div>
              <div
                className={clsx(
                  classes.chartContainer,
                  classes.bigWidthContainer
                )}
              >
                <Chart
                  type="doughnut"
                  withoutTotalInfo={true}
                  data={[
                    {
                      label: 'Pulmón',
                      data: 102,
                      bg: '#FFC676',
                    },
                    {
                      label: 'Piel',
                      data: 9,
                      bg: '#F1AE6C',
                    },
                    {
                      label: 'Vísceras Rojas',
                      data: 6,
                      bg: '#E29662',
                    },
                    {
                      label: 'Pierna',
                      data: 6,
                      bg: '#D47D57',
                    },
                    {
                      label: 'Corazón',
                      data: 2,
                      bg: '#BE5948',
                    },
                    {
                      label: 'Vísceras Blancas',
                      data: 2,
                      bg: '#B0413E',
                    },
                  ]}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} xl={4}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Observaciones de Despacho
              </Typography>
            </div>
            <div style={{ width: '100%' }}>
              <div className={classes.marginTopContainer}>
                <ItemTotalInfo
                  avatarTotalInfo={0}
                  titleInfo="Total Vísceras Despachadas"
                  avatarTotalColor="#F2F2F2"
                  avatarTotalSize={35}
                  avatarTextSize={16}
                  extended
                  titleInfoSize={200}
                  position="right"
                  titleInverted
                />
              </div>
              <div className={classes.marginTopContainer}>
                <ItemTotalInfo
                  avatarTotalInfo={0}
                  titleInfo="Total Esparrancadores"
                  avatarTotalColor="#F2F2F2"
                  avatarTotalSize={35}
                  avatarTextSize={16}
                  extended
                  titleInfoSize={200}
                  position="right"
                  titleInverted
                />
              </div>
              <div className={classes.marginTopContainer}>
                <ItemTotalInfo
                  avatarTotalInfo={'6°'}
                  titleInfo="Temperatura del Camión"
                  avatarTotalColor="#F2F2F2"
                  avatarTotalSize={35}
                  avatarTextSize={16}
                  extended
                  titleInfoSize={200}
                  position="right"
                  titleInverted
                />
              </div>
              <div className={classes.marginTopContainer}>
                <ItemTotalInfo
                  avatarTotalInfo={'13°'}
                  titleInfo="Total Poleas del Camión"
                  avatarTotalColor="#F2F2F2"
                  avatarTotalSize={35}
                  avatarTextSize={16}
                  extended
                  titleInfoSize={200}
                  position="right"
                  titleInverted
                />
              </div>
              <div className={classes.marginTopContainer}>
                <ItemTotalInfo
                  avatarTotalInfo={'N/D'}
                  titleInfo="Sello de Seguridad del Camión"
                  avatarTotalColor="#F2F2F2"
                  avatarTotalSize={35}
                  avatarTextSize={16}
                  extended
                  titleInfoSize={200}
                  position="right"
                  titleInverted
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Observaciones
              </Typography>
            </div>
            <div>
              <TextField
                variant="outlined"
                style={{ width: '100%' }}
              ></TextField>
            </div>
          </Grid>
        </Grid>
      </ContentCard>
      <Grid container justify="flex-end" alignItems="center">
        <Box mr={2}>
          <Typography className={classes.fontSubtitle}>
            Opciones del Reporte
          </Typography>
        </Box>
        <Button
          startIcon={<i className="icon-eye" />}
          variant="contained"
          className="raleway"
          color="primary"
          onClick={openModalRangeAnteMortem}
        >
          Previsualizar
        </Button>
      </Grid>
      <DialogPDFViewer
        open={openLotDistribution}
        onClose={() => setOpenLotDistribution(false)}
        done={Boolean(imageQr)}
      >
        <MyDocSacrifice qr={imageQr} graph={imageGraph} />
      </DialogPDFViewer>
    </div>
  );
};

export default index;
