import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Box,
  Fab,
} from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import ItemListInfo from 'components/ItemListInfo';
import ItemTotalInfo from 'components/ItemTotalInfo';
import clsx from 'clsx';
import useStyles from './styles';
import LevelIndicator from 'components/LevelIndicator';
import { Link } from 'react-router-dom';
import AvatarIconInfo from 'components/AvatarIconInfo';
interface MapDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function MapDialog({ open, onClose }: MapDialogProps) {
  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalCanales',
      headerName: 'Total Canales',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'fecha',
      headerName: 'Fecha Ingreso Cavas',
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: '',
      headerName: '',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/details/lote/${params.getValue(params.id, 'id')}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      loteIP: '0123',
      totalCanales: '42',
      fecha: '02/12/2020 09:00:00',
    },
    {
      id: 2,
      loteIP: '0123',
      totalCanales: '42',
      fecha: '02/12/2020 09:00:00',
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialogContent}
      maxWidth="sm"
    >
      <DialogContent style={{ height: '650px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={7}>
            <AvatarIconInfo
              text="Inventario de Cava"
              icon="icon-cold"
              iconSize={32}
              avatarColor="#DD9835"
              textBold
              avatarSize={50}
            />
          </Grid>
          <Grid item xs={2}>
            <div className={clsx(classes.grayContainer, classes.firstSquare)}>
              <Typography className={classes.label}>Estado</Typography>
              <svg width="15" height="15">
                <circle fill="#68CE70" r="5" cx="5" cy="7"></circle>
              </svg>
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={clsx(classes.grayContainer, classes.firstSquare)}>
              <Typography className={classes.label}>Nro. Cava</Typography>
              <Typography className={classes.labelBold}>C12</Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.spaceEvenly}>
              <div className={classes.FlexContainer}>
                <div>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={84}
                    titleInfo="Total Canales en Cava"
                    avatarTotalColor="#FFC676"
                  />
                </div>
                <div className={clsx(classes.flex, classes.totalLotes)}>
                  <Typography className={classes.label}>
                    Total Lotes InfoPorcinos
                  </Typography>
                  <Typography className={classes.labelBold}>02</Typography>
                </div>
              </div>
              <div
                className={clsx(classes.FlexContainer, classes.middleWidth1)}
              >
                <ItemListInfo
                  icon="icon-canal"
                  titleInfo="Peso Canales Calientes"
                  numberInfo="2.663,96 Kg"
                />
                <ItemListInfo
                  icon="icon-weight"
                  titleInfo="Peso Promedio Canal"
                  numberInfo="102,65 Kg"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              className={clsx(classes.grayContainer, classes.levelContainer)}
            >
              <div
                className={clsx(classes.middleWidth, classes.titleContainer)}
              >
                <LevelIndicator
                  value={11}
                  levels={[[0, 12], [12, 24], [24, 36], [36]]}
                />
              </div>
              <div className={classes.middleWidth}>
                <ItemListInfo titleInfo="Días en Cavas" numberInfo="2" />
                <ItemListInfo
                  titleInfo="Tiempo en Cava"
                  numberInfo="24:00:00"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              className={clsx(classes.grayContainer, classes.titleContainer)}
            >
              <Typography className={classes.secondsTitle}>
                Resumen de la Calidad de los Canales
              </Typography>
            </div>
            <div className={clsx(classes.flex, classes.paddings)}>
              <div className={clsx(classes.FlexContainer, classes.middleWidth)}>
                <div className={clsx(classes.spaceEvenly, classes.fullWidht)}>
                  <Typography className={classes.label}>
                    {'Promedio %Magro'}
                  </Typography>
                  <Typography className={classes.labelBold}>{'60%'}</Typography>
                </div>
                <div className={clsx(classes.spaceEvenly, classes.fullWidht)}>
                  <Typography className={classes.label}>
                    {'Promedio LM'}
                  </Typography>
                  <Typography className={classes.labelBold}>
                    {'42,22 m.m'}
                  </Typography>
                </div>
              </div>
              <div className={clsx(classes.FlexContainer, classes.middleWidth)}>
                <div className={clsx(classes.spaceEvenly, classes.fullWidht)}>
                  <Typography className={classes.label}>
                    {'Promedio GD'}
                  </Typography>
                  <Typography className={classes.labelBold}>
                    {'42,22 m.m'}
                  </Typography>
                </div>
                <div className={clsx(classes.spaceEvenly, classes.fullWidht)}>
                  <Typography className={classes.label}>
                    {'Rendimiento CC'}
                  </Typography>
                  <Typography className={classes.labelBold}>
                    {'56,66%'}
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              className={clsx(classes.grayContainer, classes.titleContainer)}
            >
              <Typography className={classes.secondsTitle}>
                Listado Lotes en la Cava
              </Typography>
            </div>
            <div>
              <div>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  autoHeight
                  className={classes.dataGrid}
                  rowsPerPageOptions={[]}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.buttonsModalStart}>
        <Button
          className={classes.saveButtonModal}
          type="submit"
          onClick={onClose}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
