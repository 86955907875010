import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import GobackLink from 'components/GobackLink';
import All from './Views/all';
import ReportsPerType from './Views/filtered';
import FilterShareReports from 'components/PageComponents/SharedReports/FilterByDateRange';

import { useParams } from 'react-router';
import dayjs from 'dayjs';

import useStyles from './styles';

const ReportsDetails = () => {
  const classes = useStyles();
  let params: any = useParams();
  const [filterType, setFilterType] = useState(params.type);
  const [range, setRange] = useState([dayjs().startOf('M'), dayjs()]);

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/network/shared-reports/details-reports/Todos',
      title: 'Detalle por reporte',
    },
    {
      link: '/app/network/shared-reports',
      title: 'Gestion de reportes',
    },
  ];

  return (
    <>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard>
        <Box mb={2}>
          <FilterShareReports
            onChangeReportType={(e: any) => setFilterType(e)}
            onChangeRange={(e: any) => setRange(e)}
          />
        </Box>
        <Box minHeight={300}>
          {filterType === 'Todos' ? (
            <All />
          ) : (
            <ReportsPerType type={filterType} />
          )}
        </Box>
      </ContentCard>
    </>
  );
};

export default ReportsDetails;
