import axios from "axios";
import config from "config/secrets";

async function forgotPassword(email: string) {
  try {
    const url = `${config.AUTH}${config.APP_URI}${config.APP_VERSION}/forgot-password`;
    const response = await axios.post(url, {
      param: email,
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

async function recoverPassword(data: any, token: string) {
  try {
    const url = `${config.AUTH}${config.APP_URI}${config.APP_VERSION}/recover-password`;
    const response = await axios.put(
      url,
      {
        passwordNueva: data,
      },
      {
        headers: config.authHeaders(token),
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export { forgotPassword, recoverPassword };
