import { NestedRoute } from 'lib/interfaces/routes';
import Entrance from './Entrance';
import Mobilization from './Mobilization';
import Antemortem from './AnteMortem';
import InmunoCastration from './InmuneCastration';
import WhiteEntrails from './WhiteEntrails';
import PostMortem from './PostMortem';
import Magro from './Magro';
import Distribution from './Distribution';
import BalanceTicket from './BalanceTicket';

const routes: NestedRoute[] = [
  {
    route: '/entrance',
    Node: Entrance,
  },
  {
    route: '/mobilization-guides',
    Node: Mobilization,
  },
  {
    route: '/ante-mortem',
    Node: Antemortem,
  },
  {
    route: '/inmunecastration',
    Node: InmunoCastration,
  },
  {
    route: '/viscera',
    Node: WhiteEntrails,
  },
  {
    route: '/post-mortem',
    Node: PostMortem,
  },
  {
    route: '/magro',
    Node: Magro,
  },
  {
    route: '/transport-sacrifice',
    Node: Distribution,
  },
  {
    route: '/bascule',
    Node: BalanceTicket,
  },
];

export default routes;
