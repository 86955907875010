import React from "react";

import { Box, Typography, Switch, Divider } from "@material-ui/core";

import PageContainer from "components/PagesContainer";
import Breadcrumb, { BreadcrumbProps } from "components/BreadcrumbBuilder";
import ContentCard from "components/ContentCard";
import MainTitle from "components/MainTitle";
import RoleManagement from "./Components/RoleManagement";

import { useSelector } from "react-redux";

import useStyles from "./styles";

const Settings = () => {
  const classes = useStyles();
  const idRol = useSelector((state: any) => state.user.selectedUserType.idRole);

  console.log(idRol);

  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: "/app/settings", title: "Ajustes" },
  ];

  const NotificationsData = [
    {
      title: "Inivitaciones Aceptadas",
      description:
        "Recibir una notificación cuando un cliente haya aceptado una invitación.",
      state: false,
    },
    {
      title: "Estado de la Línea",
      description:
        "Recibir una notificación cuando se realice un paro de línea.",
      state: true,
    },
    {
      title: "Solicitud de Soporte",
      description: "Notificar cuando existe solicitud de soporte en el módulo.",
      state: false,
    },
    {
      title: "Inicio de Sesión",
      description: "Registro de inicio de sesión otras cuentas.",
      state: true,
    },
  ];

  return (
    <PageContainer>
      <Box display="flex" justifyContent="flex-end">
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </Box>
      <ContentCard>
        <Box marginBottom={3}>
          <MainTitle>Notificaciones</MainTitle>
          {NotificationsData.map((item: any) => {
            return (
              <Box marginY={1}>
                <Box display="flex" justifyContent="space-between" paddingY={2}>
                  <Box>
                    <Typography variant="h6" color="secondary">
                      {item.title}
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {item.description}
                    </Typography>
                  </Box>
                  <Box>
                    <Switch value={item.state} className={classes.switch} />
                  </Box>
                </Box>
                <Divider />
              </Box>
            );
          })}
        </Box>
        {idRol === 1 || idRol === 2 ? (
          <Box marginBottom={3}>
            <RoleManagement />
          </Box>
        ) : null}
      </ContentCard>
    </PageContainer>
  );
};

export default Settings;
