import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiDialog-paperWidthMd": {
      borderRadius: "20px",
    },
  },
  dataContainer: {
    padding: "0px 40px",
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
  },

  textCenter: {
    textAlign: "center",
  },
  containerSubtitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  captionGray: {
    color: "#767676",
    paddingLeft: 24,
    fontWeight: "bold",
  },
  rolName: {
    paddingLeft: 4,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  containerPermiss: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: theme.shadows[2],
    padding: 8,
    borderRadius: 10,
  },
  containerSubcategories: {
    boxShadow: theme.shadows[2],
    padding: "8px 24px",
    borderRadius: "0px 0px 10px 10px",
  },
  formRegister: {
    padding: 24,
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
  },
  formControl: {
    width: "100%",
  },
  btnRegister: {
    margin: 8,
  },
  containerRoles: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  permissContainer: {
    margin: "24px 0",
    display: "flex",
    flexFlow: "row wrap",
    textAlign: "left",
  },
  permissItem: {
    width: "45%",
    margin: 12,
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #eaeaf0",
  },
  itemList: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
  permissNewRolItem: {
    width: "100%",
    margin: 12,
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #eaeaf0",
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "center",
    padding: 16,
  },
  iconDialogCheck: {
    fontSize: 60,
    color: theme.palette.primary.main,
  },
}));

export { useStyles as default };
