import A from '../avatars/a.png';
import B from '../avatars/b.png';
import C from '../avatars/c.png';
import D from '../avatars/d.png';
import E from '../avatars/e.png';
import F from '../avatars/f.png';
import G from '../avatars/g.png';
import H from '../avatars/h.png';
import I from '../avatars/i.png';
import J from '../avatars/j.png';
import K from '../avatars/k.png';
import L from '../avatars/l.png';
import M from '../avatars/m.png';
import N from '../avatars/n.png';
import O from '../avatars/o.png';
import P from '../avatars/p.png';
import Q from '../avatars/q.png';
import R from '../avatars/r.png';
import S from '../avatars/s.png';
import T from '../avatars/t.png';
import U from '../avatars/u.png';
import V from '../avatars/v.png';
import W from '../avatars/w.png';
import X from '../avatars/x.png';
import Y from '../avatars/y.png';
import Z from '../avatars/z.png';

const imagen = (imagen: string) => {
  switch (imagen) {
    case "a":
      return A
    case "b":
      return B
    case "c":
      return C
    case "d":
      return D
    case "e":
      return E
    case "f":
      return F
    case "g":
      return G
    case "h":
      return H
    case "i":
      return I
    case "j":
      return J
    case "k":
      return K
    case "l":
      return L
    case "m":
      return M
    case "n":
      return N
    case "o":
      return O
    case "p":
      return P
    case "q":
      return Q
    case "r":
      return R
    case "s":
      return S
    case "t":
      return T
    case "u":
      return U
    case "v":
      return V
    case "w":
      return W
    case "x":
      return X
    case "y":
      return Y
    case "z":
      return Z
    default:
      return "";
  }
}

export default {imagen}
