/** @format */

const whiteCava = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADsAAAA7CAYAAADFJfKzAAAACXBIWX
MAACxKAAAsSgF3enRNAAADSElEQVRogd2b0VHkMAyGxQ7vpIR0QDpgS6CDzVVwlLAl7FVwoQNKCB
UQKjio4EIFvvGdcmO8li3bkmH4ZzzD7iaxvkRJJFlcGGPgA9QBwNp62t0HgE4AMCNwU7WGPQHAAQ
CuETilQfKkSME+oPEzGkjJ/e06sl2Hx3oCgBchGwHsPVs59ua9VmPMQBxz9rYNbdMZYxbGdtlD4s
ou3ucrxhWmtF3R2FUvlgSsfap+874rAaZA7wVs/CcpFzHGjOZcvktTbhxyXatJ0D5R2Bhwn4BVB5
W6Z11NhEv3if1CrjsK26byng0B50gFFBSDilJgNVBQjqBygVVBrS41D47AHP0AgDtlW6A26+nxPc
vNYOx79MadP2OefcbJC6rGjW3A8AsAfmOAr6UR5/nJTB5UYN1s5HvtWSc0IuSmm9wDuJJ8QB2EgX
1Qq0fmvtblb8++dSKM3ot6qMylJsTjZD1UJLbgnKlIyc6/6UiFi8dAmCcNzIGVAjUx2D4wiTRwCl
YS1OqOgo0F8lLAMVhp0LOTzXUjKWAKVh2UgtUEDsE2AY3BagH7sM1AU7Ax4L4QOCXOySwCtUNzRS
C3ePaGwYBfwBOTZoq3ovHPjG3VQcHJeiYM976invFErjtnSeKraltq6XbarvNJZBnXSydT0SqJDF
hhTImb0eRq+V8FYTzma0bo1UVJvE7sj1QNqiOWMDgVg1A+GtPB2S+lfeD3JVkeKggMRsZZpIKRF+
+70pWAoshLA3RAMB90CISLNWs82cAtQYFIBJoBtwSlYJsBtwSNwWoAnyUs7g5+u4A0aApWA/jEhZ
UG5cBKA08UbI/G2NfDrQIoFzYGfGLYZW0/YmWRdOPcUVJ65cJSwHOFvWL5rEY+mpMPs1QDO2Pw/q
iYeG/Adu32FZvLitWqUXNrzKSWLIcWqWaL3sWJ0Q9V2iSWJW1YbrmnpiuOLe2eipxyjzqwZrdMSV
1LFVirDyoX9M35Ww1Yo8PNB71nVDb2TYBrIhJvcJYkYhFUSfiZNaRATwxQTrioCizlxr67lXarLY
RLHyvt+yuNB1RtW14IWOTfYqTa+bZC+4NQA9iC3tI7n+sl+IDijDFxT6sO7UZNX5sH2Kum3pj5Tg
DwB0DK4MqUR5MPAAAAAElFTkSuQmCC`;

export default whiteCava;
