import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  result:{
    display:'flex', 
    alignItems:'center',
    margin: '0px 8px',
    "& i":{
      fontSize:24,
    }
  },

}))

export { useStyles as default }