import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CenteredDimmer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255);
  z-index: 5;
  top: 0;
  left: 0;
`;

interface LoaderProps {
  visible: boolean;
  duration?: number;
  onDurationEnd?: (value: boolean) => void;
  children?: React.ReactNode;
}

const Loader = ({
  visible,
  duration,
  onDurationEnd,
  children,
}: LoaderProps) => {
  const [isVisible, setVisibility] = useState(false);

  useEffect(() => {
    setVisibility(visible);
  }, [visible]);

  if (!isVisible) return null;

  if (duration) {
    setTimeout(() => {
      setVisibility(false);

      if (onDurationEnd) {
        onDurationEnd(false);
      }
    }, duration);
  }

  return (
    <CenteredDimmer>
      <Box marginBottom={2}>
        <CircularProgress size={60} />
      </Box>
      <Typography>{children}</Typography>
    </CenteredDimmer>
  );
};

export default Loader;
