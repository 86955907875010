import React, { useState } from 'react';

import {
  Grid,
  Box,
  Typography,
  Chip,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Button,
} from '@material-ui/core';

import ContentCard from 'components/ContentCard';
import MainTitle from 'components/MainTitle';
import ItemListData from 'components/ItemListData';
import AddressPreview from 'components/AddressPreview';
import ItemTotalInfo from 'components/ItemTotalInfo';

import documentTypeById from 'lib/helpers/documentTypeById';
import dayjs from 'dayjs';

import useStyles from './styles';

const Detail = ({ data }: any) => {
  const classes = useStyles();
  const [value, setValue] = useState('thismonth');

  const resumen = [
    {
      title: 'Total Animales Enviados',
      data: 'N.D',
    },
    {
      title: 'Total Lotes Beneficiados',
      data: 'N.D',
    },
    {
      title: 'Promedio Grasa Dorsal',
      data: 'N.D',
    },
    {
      title: 'Promedio Lomo',
      data: 'N.D',
    },
    {
      title: 'Promedio Porcentaje de Magro',
      data: 'N.D',
    },
    {
      title: 'Promedio Pesaje',
      data: 'N.D',
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const chipDate = () => {
    if (value === 'today') {
      return dayjs().format('dddd, D MMMM YYYY');
    } else if (value === 'thismonth') {
      return dayjs().format('MMMM');
    } else {
      return dayjs().year();
    }
  };

  return (
    <Grid container xs={12}>
      <Grid item container xs={12} xl={6} justifyContent="center">
        <ContentCard className={classes.containerLeft}>
          <Box display="flex" justifyContent="space-between">
            <MainTitle>Datos de la Granja</MainTitle>
            <Box display="flex" alignItems="center">
              <a
                download
                href={data?.certificadoGranja}
                className={classes.download}
              >
                Descargar certificado
              </a>
              <Chip
                size="medium"
                color="primary"
                variant="outlined"
                label={
                  data?.estado
                    ? 'Activa'
                    : `Inactiva desde ${dayjs(data?.fechaInactivacion).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )}`
                }
              />
            </Box>
          </Box>
          <Box marginTop={2}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={8}>
                <ItemListData
                  data={[
                    {
                      title: 'Nombre',
                      data: data?.nombreGranja,
                    },
                    {
                      title: 'Código',
                      data:
                        data?.codigoGranja != '' ? data?.codigoGranja : 'N.D',
                    },
                    {
                      title: 'Fecha de Registro',
                      data: dayjs(data?.fechaCreacion).format(
                        'DD/MM/YYYY HH:mm:ss'
                      ),
                    },
                    {
                      title: 'Fecha de Validez Certificado',
                      data: dayjs(data?.fechaValidezCertificadoGranja).format(
                        'DD/MM/YYYY'
                      ),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-end">
                  <img
                    style={{ maxWidth: 150 }}
                    src={`data:image/png;base64, ${data?.codigoQR}`}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <MainTitle>Datos del Responsable de la Granja</MainTitle>
          <Box marginY={2}>
            <Grid container xs={12} spacing={2}>
              <Box width="100%">
                <ItemListData
                  data={[
                    {
                      title: 'Nombre',
                      data: `${data?.primerNombre} ${data?.segundoNombre} ${data?.primerApellido} ${data?.segundoApellido}`,
                    },
                    {
                      title: 'Documento de Identidad',
                      data: `${documentTypeById(data?.idTipoDocumento, 1)} ${
                        data?.numeroDocumento
                      }`,
                    },

                    {
                      title: 'Correo Electrónico',
                      data: data?.emailResponsable,
                    },
                  ]}
                />
              </Box>
            </Grid>
          </Box>
        </ContentCard>
      </Grid>
      <Grid item container xs={12} xl={6} justifyContent="center">
        <ContentCard className={classes.containerRight}>
          <MainTitle>Datos de Contacto</MainTitle>
          <Box marginY={2}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={6}>
                <ItemListData
                  data={[
                    {
                      title: 'Correo Electrónico',
                      data: data?.email,
                    },
                    {
                      title: 'Celular',
                      data: `(${data?.indicativoCelular}) ${data?.numeroCelular}`,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <ItemListData
                  data={[
                    {
                      title: 'WhatsApp',
                      data: data?.numeroWhatsapp
                        ? `(+${data?.indicativoWhatsapp}) ${data?.numeroWhatsapp}`
                        : 'N.D',
                    },
                    {
                      title: 'Telegram',
                      data: data?.numeroTelegram
                        ? `(+${data?.indicativoTelegram}) ${data?.numeroTelegram}`
                        : 'N.D',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
          <MainTitle>Datos de Ubicación - Dirección Geolocalizada</MainTitle>
          <Box marginY={1} minHeight="180px" display="flex" alignItems="center">
            <Grid container xs={12} spacing={2}>
              <AddressPreview
                directionType={data?.tipoDireccion}
                country={data?.pais}
                department={data?.departamento}
                city={data?.ciudad}
                roadType={data?.tipoVia}
                numberVia={data?.numeroVia}
                viaAppend={data?.apendiceVia}
                crossingAppend={data?.apendiceCruce}
                numberCrossing={data?.numeroCruce}
                cornerMeters={data?.metrosEsquina}
                additional={data?.adicional}
                postalCode={data?.codigoPostal}
                longitude={data?.longitud}
                latitude={data?.latitud}
                direction={data?.direccion}
              />
            </Grid>
          </Box>
        </ContentCard>
      </Grid>
      <Grid item container xs={12}>
        <ContentCard marginTop="12px" style={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between">
            <MainTitle>Resumen de la Granja</MainTitle>
            <Chip
              size="medium"
              color="primary"
              variant="outlined"
              label={chipDate()}
            />
          </Box>
          <Box width="100%" display="flex" justifyContent="center" marginY={2}>
            <RadioGroup row value={value} onChange={handleChange}>
              <FormControlLabel
                value="today"
                control={<Radio color="primary" />}
                label="Hoy"
              />
              <FormControlLabel
                value="thismonth"
                control={<Radio color="primary" />}
                label="Menusal"
              />
              <FormControlLabel
                value="thisyear"
                control={<Radio color="primary" />}
                label="Anual"
              />
            </RadioGroup>
          </Box>
          <Box marginY={3}>
            <Grid container xs={12} spacing={3} justifyContent="center">
              {resumen.map((item: any) => {
                return (
                  <Grid item xs={2}>
                    <ItemTotalInfo
                      avatarTotalInfo={item.data}
                      titleInfo={item.title}
                      avatarTotalColor="#AFAFAF"
                      avatarTotalSize={35}
                      avatarTextSize={16}
                      titleInfoSize={200}
                      extended
                      position="bottom"
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </ContentCard>
      </Grid>
    </Grid>
  );
};

export default Detail;
