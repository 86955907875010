import { Checkbox, CheckboxProps, Icon, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    transform: 'rotate(45deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  checked: {
    transform: 'rotate(0deg)',
  },
  white_icon: {
    color: '#FFF',
  },
}));

const PinCheckbox = (props: CheckboxProps) => {
  const classes = useStyles();
  return (
    <Checkbox
      color="primary"
      size="small"
      icon={
        <Icon className={clsx(classes.white_icon, 'material-icons-outlined')}>
          push_pin
        </Icon>
      }
      checkedIcon={<Icon className={classes.white_icon}>push_pin</Icon>}
      classes={{
        root: classes.root,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export default PinCheckbox;
