import React from 'react';
import { TextField, MenuItem, Grid, CircularProgress } from '@material-ui/core';
import { POSITIONS } from 'core/graphql/queries/positions';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

interface PositionsProps {
  name: string;
  value: string | number | null;
  errors?: string;
  helperText?: string;
  disable?: boolean;
  onChange: (event: any) => void;
}

const Positions = ({
  name,
  value,
  errors,
  helperText,
  onChange,
  disable,
}: PositionsProps) => {
  const token = useSelector((state: any) => state.user.token);

  const { loading, error, data } = useQuery(POSITIONS, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const handleChange = (event: any) => {
    onChange(event);
  };

  return (
    <TextField
      label="Cargo"
      size="small"
      variant="outlined"
      fullWidth
      name={name}
      value={value}
      onChange={handleChange}
      error={!!errors}
      helperText={helperText}
      disabled={disable}
      select
      required
    >
      {loading ? (
        <CircularProgress variant="indeterminate" size={25} />
      ) : error ? (
        <p>Error</p>
      ) : (
        data.positions.map((positions: any) => (
          <MenuItem key={positions.id} value={positions.id}>
            {positions.description}
          </MenuItem>
        ))
      )}
    </TextField>
  );
};

export default Positions;
