/** @format */

const whiteDispatch = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAABRCAYAAACqj0o2AAAACXBIWX
MAACxKAAAsSgF3enRNAAAEa0lEQVR4nO2cz27UMBDGB8SxUvMGXSTEtXkAUJcn6FZC4tjwBssTsH
2D5Qbi0O0dia3EnUXiwon0jkR647g8QZClz8ga2W6cTP5Ump9kdZN4k+lnezwep31Q1zUp3Xio+n
VHRRRARRRARRRARRRARRRARRRARRRARRRARRRARRRARRRARRRARRRARRRARRRARRRARRRARRTgUe
QWORGtiCibsP0VES2JaD+mEbGNqpKIjoc1pxXXRLQY04DYcL4PAtIURkpsOHOu0TvHxriZ0wnY8Z
+mIo4+ZBjbKQnZdHaeQg90mZQ9GuIIoCIKMLSI+cDPG4QhRNxBvDkR/cS5gojWI//uYgwhYgUhn+
DYzPKXPT3LrLDqhFJKRB1DiFggJHmGY9Mrr7BcGxuzoPgMG1vTt4gFRDM/v+PcFseLCcWel12ETF
mxNGUFoUoIuIY//IXvlzDYGH6BulZo6d55EbnGVz7WxWxSH9KHiDP4wDlEyXC8wnUr4BXO5biebH
wDVndUMc88d47bCWmyOIHisorU85V1Xdfbuq4zXNuw+21wPkO9deL9V869doHzdcN7cdsMRYo90j
1xhR5o2Qbq2d5qsb1xHflOX1hf2LpHSou4ccKZA2eNaww9cepZn3mAun9QxloTdxJSenau0Ms+Or
3LGneDz9YXmmz0F9R7j+MxM9QFbHNpNmv34BNdn5fXdb3H8dzxUxucz53jXcIzJH1iZx/ZR5z42G
m9tRMXutgA3MaJBfOlYxLqkcGYto8Qp3I+W2HMkH2Jz0sM806rhJ7x+cjgpDfEsi9nMzE5SYkpUy
CjbzkK2TxUFse04Ccc2xblwk4Rnmnybor1MZw5Cwg2c1p2Sj6wM0P1RIKvXHjO33t0e0CAPkScIc
rfs+TodgJDOEOgXzHbyi7RgrSIxpDfCA0O2TWTdvraU7amCa8g3lvMtC7HiAXLNm9USIpYNEz7n4
8gpAlNPngalmPE/JF6cykRM0848I2IXhPRmWcFcD7w0N4wAW+J6A0RvSCid0T017n2tK98YunkBn
1lGcgXuqVgdbYt17YZ7Gm6dn7Ozu08v4td41v2gXX0vEs+8Rj+ogpcdyP524CT3qD32aXUacswZ+
bxaSFsfOpSeLJFJZaj1h0dprxamBJsHzU0PpZU3bH16EmkrgT8/jeRjsAb1CegN1UX84ncjzUlNr
v18S5hSiK3i203oWfFeuIyYcpfOC23wHd8reauWG4FZumK3cPnHtxdvSO4FF89vtPIdwrDb2y0dO
53TRq+iSi0WdV3mbHnusngmP2N7ZL850IV85l/0eJ7tD6/lkf8kzR8a5QQgpWwjfu/s6QNM8FewM
OEGElbkgKFh0UxkkeItLH5HcYakRcDC2hLhhgxRur+uvhwdrH5Qhuj7Z23HEb9mxPYVbD40drWyr
3oP1wTQPOJAqiIAqiIAqiIAqiIAqiIAqiIAqiIAqiIAqiIAqiIAqiIAqiIAqiIAqiIAqiIAqiIAq
iIAqiIXSGif2a6+FzIO0VXAAAAAElFTkSuQmCC`;

export default whiteDispatch;
