import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  },

  contentCard: {
    width: '100%',
  },

  mainTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    color: '#767676',
  },

  grayContainer: {
    background: '#F2F2F2',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
    margin: '10px 10px',
  },

  subtitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  columnContainerFlexStart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  displayFlexStart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  itemContainer: {
    margin: '12px 10px',
  },

  resumeAvailableContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 12px',
    width: '100%',
    padding: '20px 30px',
  },

  tableHeader: {},

  viewButton: {
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      color: '#fff',
    },
  },

  availableContainer: {
    display: 'flex',
  },

  fullWidhtContainer: {
    padding: '20px 30px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  bigNumber: {
    borderRadius: '50%',
    background: '#BE5948',
    color: '#FFF',
    fontSize: '28px',
    fontWeight: 'bold',
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 20px',
  },

  label: {
    color: '#AFAFAF',
    fontSize: 13,
    width: 105,
    fontWeight: 350,
    [theme.breakpoints.down('sm')]: {
      width: 85,
    },
  },

  label2: {
    marginTop: 10,
    textAlign: 'center',
  },

  label3: {
    fontSize: 14,
    width: '200px !important',
    margin: '0px 5px',
  },

  canalesContainer: {
    margin: '0px 20px',
  },

  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  hourContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  cavasHours: {
    fontSize: 18,
    fontWeight: 'bolder',
    color: '#AFAFAF',
  },

  containerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  flexContainerBottom: {
    margin: '0px !important',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  circleTypoSmall: {
    borderRadius: '50%',
    color: '#FFF',
    fontSize: '20px',
    fontWeight: 'bold',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 15px',
  },

  number1: {
    background: '#E29662',
  },
  number2: {
    background: '#FFC676',
  },

  number3: {
    background: '#F1AE6C',
  },

  number4: {
    background: '#D47D57',
  },

  cavaLotes: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto 20px',
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  promContainer: {
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  roundContainer: {
    borderRadius: '20px',
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 'bold',
    width: 100,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 15px',
  },

  roundContainer2: {
    borderRadius: '20px',
    background: '#F2F2F2',
    color: '#767676',
    fontSize: '16px',
    fontWeight: 'bold',
    width: 100,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 15px',
  },

  subtitle1: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#AFAFAF',
  },

  subtitle2: {
    fontWeight: 'bold',
    fontSize: 24,
    color: '#DD9835',
  },

  secondTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    color: '#767676',
    padding: '10px 0px',
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dataGrid: {
    border: 'none',
    display: 'flex',

    '& .MuiBox-root': {
      margin: '20px 0',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal',
      color: '#AFAFAF',
      fontWeight: 'bold',
      fontSize: 14,
    },
  },
  options:{
    color:'#767676', 
    margin: '0px 12px'
  }
}));

export default useStyles;
