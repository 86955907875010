import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  dialogContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  dialogContainerFullScreen: {
    height: 'unset'
  },
  dialogPaper: {
    minWidth: 800,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  },
  dialogTitle: {
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    }),
    '& .MuiTypography-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    }
  },
  dialogActions: {
    bottom: 0,
    width: '100%'
  },
  dialogContent: {
    padding: '0px 12px',
    overflowY: 'hidden',
    height: '75vh'
  },
  pdfViewer: {
    width: '100%',
    height: '100%',
    border: '1px solid #ddd',
    borderRadius: 10,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  },
  dialogFullScreen: {
    minWidth: '100%',
    margin: 0,
    borderRadius: 0,
    height: '100vh',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  },
  dialogTitleFullScreen: {
    padding: '8px 24px',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  },
  closeBtn: {
    background: '#212121',
    color: '#FFF',
    borderRadius: 50,
    padding: theme.spacing(1, 3),
    '&:hover': {
      background: '#212121',
      color: '#FFF'
    }
  }
}))