import { object, string } from 'yup';
import messages from './messages';

const ForgotPasswordValidations = object().shape({
  email: string()
    .required(messages.REQUIRED_FIELD)
    .email(messages.INVALID_EMAIL_ADDRESS),
});

export default ForgotPasswordValidations;
