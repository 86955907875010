import React, { useState } from 'react';

import { Image, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface TableProps {
  items: any;
}

const TableDecomiso = ({ items }: TableProps) => {
  const itemsSort = items.sort(function (a: any, b: any) {
    return b.Porcentaje - a.Porcentaje;
  });

  const data: any[] = [];
  itemsSort.map((item: any) => {
    if (item.Porcentaje < 1) {
      data.push(item);
    }
  });

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <View style={{ width: '48.3%' }}>
        {data.map(
          (item: any, index: any) =>
            index < data.length / 2 && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 1,
                }}
              >
                <View style={{ width: '28%', textAlign: 'left' }}>
                  <Text style={{ fontWeight: 'bold' }}>
                    {item?.Porcentaje.toFixed(2)}%
                  </Text>
                </View>
                <View style={{ width: '28%', textAlign: 'left' }}>
                  <Text style={{ fontWeight: 'bold' }}>({item?.Cantidad})</Text>
                </View>
                <View
                  style={{ width: '40%', textAlign: 'left', marginBottom: 2 }}
                >
                  <Text>{item?.parte}</Text>
                </View>
              </View>
            )
        )}
      </View>

      <View style={{ width: '48.3%' }}>
        {data.map(
          (item: any, index: any) =>
            index > data.length / 2 && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ width: '28%', textAlign: 'left' }}>
                  <Text style={{ fontWeight: 'bold' }}>
                    {item?.Porcentaje.toFixed(2)}%
                  </Text>
                </View>
                <View style={{ width: '28%', textAlign: 'left' }}>
                  <Text style={{ fontWeight: 'bold' }}>({item?.Cantidad})</Text>
                </View>
                <View
                  style={{ width: '40%', textAlign: 'left', marginBottom: 2 }}
                >
                  <Text>{item?.parte}</Text>
                </View>
              </View>
            )
        )}
      </View>
    </View>
  );
};

export default TableDecomiso;
