import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

import useStyles from './styles';

interface CurrentDateIndicatorProps {
  initialDate: Date | string;
  finalDate: Date | string;
}

const currentDate = new Date();

const CurrentDateIndicator = ({
  initialDate = moment(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-01`).format('YYYY-MM-DDT00:00:00-05:00'),
  finalDate = currentDate,
}: CurrentDateIndicatorProps) => {
  const classes = useStyles();
  return (
    <Typography>
      Desde el{' '}
      <span className={classes.dateIndicator}>
        {moment(initialDate).format('LL')}
      </span>{' '}
      hasta el{' '}
      <span className={classes.dateIndicator}>
        {moment(finalDate).format('LL')}
      </span>{' '}
      (Precarga de datos del sistema)
    </Typography>
  );
};

export default CurrentDateIndicator;
