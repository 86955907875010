import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5%',
    paddingTop: '2.5%',
    maxWidth: 'inherit',
  },

  breadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  },

  contentCard: {
    width: '100%',
  },

  headerContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  spacingTf: {
    margin: '0px 50px',
  },

  paperTitles: {
    fontSize: 20,
    fontWeight: 'bolder',
    color: '#767676',
  },

  labelUp: {
    fontWeight: 'bolder',
    fontSize: 18,
    margin: '0px 10px',
  },

  colorUp: {
    color: '#AFAFAF',
  },
  colorUpOrange: {
    color: '#DD9835',
  },

  icon: {
    fontSize: 30,
    padding: 5,
    color: 'white',
    background: theme.palette.primary.main,
    borderRadius: '100%',
    margin: 'auto 10px',
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  responsiveColumn: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  grayContainer: {
    background: '#F2F2F2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 10px',
    margin: '10px 0px',
  },
  miniGrayContainer: {
    background: '#F2F2F2',
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0px',
  },

  bigWidthContainer: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100% important!',
    },
  },

  minContainer: {
    width: '45%',
  },

  midContainer: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },

  centerContainer: {
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'center',
  },

  paddingContainer: {
    padding: '0px 100px',
  },
}));

export default useStyles;
