import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Bar, Doughnut } from 'react-chartjs-2';
import _ from 'lodash';
import clsx from 'clsx';

import useStyles from './styles';

interface GraphicProps {
  data: Array<{
    data: number | Array<number>;
    label: string;
    bg: string | Array<string>;
  }>;
  labels?: Array<string>;
  min?: number;
  max?: number;
  weightBar?: number;
  infoTotalData?: string;
  withoutTotalInfo?: Boolean;
  withoutPorcentage?: Boolean;
  withoutData?: Boolean;
  withoutLabel?: Boolean;
  classNameLabel?: string;
  graphicSize?: number;
  position?: 'top' | 'right' | 'bottom' | 'left';
  type: 'bar' | 'doughnut';
}

const BarGraphic = ({
  data,
  labels,
  min,
  max,
  weightBar,
  infoTotalData,
  withoutTotalInfo,
  withoutPorcentage,
  withoutData,
  withoutLabel,
  classNameLabel,
  graphicSize,
  position,
  type,
}: GraphicProps) => {
  const classes = useStyles();
  const [chartBarData, setChartBarData]: any = React.useState({});
  const chartBar = () => {
    setChartBarData({
      labels: labels,
      datasets: data.map((data: any) => ({
        label: data.label,
        data: data.data,
        backgroundColor: data.bg,
        barThickness: weightBar ? weightBar : 20,
      })),
    });
  };

  const labelsObject = data?.map((data: any) => data.label);
  const dataObject = data?.map((data: any) => data.data);
  const backgroundObject = data?.map((data: any) => data.bg);

  const total = dataObject?.reduce((a: number, b: number) => a + b, 0);

  const [chartDoughnutData, setChartDoughnutData]: any = React.useState({});
  const chartDoughnut = () => {
    setChartDoughnutData({
      labels: labelsObject,
      datasets: [
        {
          data: dataObject,
          backgroundColor: backgroundObject,
          fill: false,
          borderWidth: 3,
        },
      ],
    });
  };

  const size = graphicSize
    ? {
        height: graphicSize,
        width: graphicSize,
      }
    : {
        height: 200,
        width: 200,
      };
  const infoSize = graphicSize
    ? {
        fontSize: graphicSize * 0.15,
      }
    : {};
  const subInfoSize = graphicSize
    ? {
        fontSize: graphicSize * 0.07,
      }
    : {};

  useEffect(() => {
    type === 'bar' ? chartBar() : chartDoughnut();
  }, [data]);

  return (
    <>
      {type === 'bar' ? (
        <div className={clsx(classes.generalContainerBar, position)}>
          <div className={classes.graphContainer}>
            <Bar
              data={chartBarData}
              options={{
                indexAxis: 'x',
                maintainAspectRatio: true,
                scales: {
                  x: {
                    min: min ? min : 0,
                    max: max ? max : 100,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    min: min ? min : 0,
                    max: max ? max : 100,
                    grid: {
                      display: true,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              className={classes.graphic}
              style={{
                ...size,
              }}
            />
          </div>
          <ul
            className={clsx(classes.labelsBar, position, {
              [classes.withoutLabel]: withoutLabel,
            })}
          >
            {data?.map((data: any) => (
              <li style={{ color: data.bg }}>
                <div className={classes.itemLabels}>
                  <Typography className={classes.titleLabelBar}>
                    {data.label}
                  </Typography>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className={clsx(classes.generalContainer, position)}>
          <div className={classes.graphContainer}>
            <div
              className={clsx(classes.infoGraphicContainer, {
                [classes.withoutTotalInfo]: withoutTotalInfo,
              })}
            >
              <Typography
                style={{ ...infoSize }}
                variant="h4"
                className={classes.infoGraphic}
              >
                {total < 10 ? `0${total}` : total}
              </Typography>
              <Typography
                style={{ ...subInfoSize }}
                variant="subtitle1"
                className={classes.infoGraphic}
              >
                {infoTotalData}
              </Typography>
            </div>
            <Doughnut
              data={chartDoughnutData}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              className={classes.graphic}
              style={{
                ...size,
              }}
            />
          </div>
          <div
            className={clsx(classes.labels, position, {
              [classes.withoutLabel]: withoutLabel,
            })}
          >
            <ul className={classNameLabel}>
              {data?.map((data: any) => (
                <li style={{ color: data.bg }}>
                  <div className={classes.itemLabels}>
                    <Typography
                      className={clsx(classes.porcentage, {
                        [classes.withoutPorcentage]: withoutPorcentage,
                      })}
                    >
                      {((data.data * 100) / total).toFixed(2)}%
                    </Typography>
                    <Typography className={classes.titleLabel}>
                      {data.label}
                    </Typography>
                    <Typography
                      className={clsx(classes.dataLabel, {
                        [classes.withoutData]: withoutData,
                      })}
                    >
                      {data.data}
                    </Typography>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default BarGraphic;
