import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 15,
    textAlign: 'center',
    overflow: 'inherit',
  },
  avatarRoot: {
    margin: '0 auto',
    marginTop: -50,
  },
  avatar: {
    boxShadow: theme.shadows[3],
    background: '#E9C554',
  },
  title: {
    padding: theme.spacing(1.5, 0),
    fontFamily: 'Raleway',
    fontWeight: 700,
  },
  user: {
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    color: '#767676'
  },
}));

export default useStyles;
