import React from 'react';

import { Text, View, Image } from '@react-pdf/renderer';

import styles from './styles';

interface Props {
  icon: string;
  title: string;
  padding?: string | number;
}

const IcoSubtitle = ({ icon, title, padding }: Props) => {
  return (
    <View style={styles.backgroundresumetitle}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View style={[styles.imageicon, { padding: padding && padding }]}>
          <Image src={icon} />
        </View>
        <Text>{title}</Text>
      </View>
    </View>
  );
};

export default IcoSubtitle;
