

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px',
      padding: '15px'
    }
  },
  avatarTitle:{
    backgroundColor: '#6AC759'
  },
  divContainerTitle : {
    backgroundColor: '#F2F2F2',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '230px',
    justifyContent: 'center',
    height: '34px'
  },
  divContainerTitle2 : {
    backgroundColor: '#F2F2F2',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    justifyContent: 'center',
    height: '34px'
  },
  dataTitle:{
    marginBottom: 0,
    color: '#767676',
    fontWeight: 'bolder'
  },
  titleContainer: {
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
    marginBottom: '20px',
    color: '#121212',
  },
  fontTitleContainer: {
    fontSize:'17px'
  },
  infoOutlineContainer: {
    '& .MuiGrid-align-items-xs-center':{
      display: 'flex',
      justifyContent: 'center',
    }
  },
  gridContainer:{
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '120px',
    marginBottom: '20px',
    marginTop:'20px',
    padding: '10px'
  },
  gridItemContainer:{
    '& .MuiGrid-align-items-xs-center':{
      justifyContent: 'center'
    }
  },
  itemInfoLabel:{
    fontSize: '17px',
    color: '#AFAFAF',
    fontWeight: 'bold',
    width: '130px'
  },
  itemList:{
    width: '100%',
    justifyContent :'normal'
  },
  paperOne:{
    border: '1px solid black', 
    backgroundColor: 'yellow',
    padding: '2px',
    width: '80%'
  },
  paperTwo:{
    border: '3px solid black',
    backgroundColor: 'yellow',
    padding: '10px',
    textAlign: 'center',
    height: '8vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fontTitleP:{
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  fontSubTitleP:{
    color: 'black', 
    fontSize: '13px',
    fontWeight: 'bolder',
  },
  oval:{
    borderRadius:'30%',
    width: '11px', 
    height: '6px',
    backgroundColor: 'black'
  },
  titleContainerSello: {
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#121212',
  },
  fontTitleInfo:{
    color: '#AFAFAF', 
    fontWeight: 'bolder'
  },
  titleInfoWeight:{
    fontSize: '15px',
    fontWeight: 'bolder',
    color: '#AFAFAF',
  },
  dataInfoWeight:{
    fontSize: '20px',
    fontWeight: 'bolder',
    color: '#DD9835',
  },
  fontTitle: {
    fontSize: '16px',
    fontWeight: 'bolder',
    color: '#AFAFAF',
  },
  fontSubtitle: {
    fontSize: '16px',
    color: '#767676',
  },
  avatarInfoBezels:{
    background: '#FFC676',
    width: '65px',
    height: '30px',
  },
  gridTitleTransport:{
    paddingLeft: '20px',
    marginBottom: '10px'
  },
  gridSubtitleTransport:{
    paddingRight: '20px', 
    marginBottom: '10px'
  }
}));

export { useStyles as default }