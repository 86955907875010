import { Document, Page, Text, Image, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import moment, { Moment } from 'moment';
import React from 'react';
import _ from 'lodash';

import PDFHeader from 'components/pdf-components/PDFHeader';

import PlantImage from 'static/img/reportsIcons/plantIcon';
import EntranceLogo from 'static/img/reportsIcons/entranceLogo';

import formatNumber from 'lib/helpers/formatNumber';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import styles from './styles';
import {Dayjs} from "dayjs";

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

interface MyDocProps {
  initialDate?: Date | number | string | Moment | Dayjs;
  finalDate?: Date | number | string | Moment | Dayjs;
  barcode?: string;
  uid?: number;
  reportId?: number;
  url?: any;
}

export const MyDoc = ({
  initialDate,
  finalDate,
  barcode,
  uid,
  reportId,
  ...props
}: MyDocProps) => {
  const { details = [] } = props.url;
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <PDFHeader
          initialDate={initialDate}
          finalDate={finalDate}
          number="072345689"
          qr={barcode}
          reportImage={EntranceLogo}
          title="Reporte Técnico de Portería"
          code="R P 001"
        />
        <View style={styles.detailsplant}>
          <View style={[styles.backgroundresumetitle, { marginBottom: 5 }]}>
            <Text
              style={{
                fontFamily: 'Nunito',
                fontWeight: 'bold',
                width: '100%',
              }}
            >
              Resumen del proceso
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.tablecontainer}>
              <View style={styles.backgroundplant}>
                <View style={styles.imageplant}>
                  <Image src={PlantImage} />
                </View>
                <Text style={{ fontFamily: 'Nunito', fontWeight: 'bold' }}>
                  Planta de beneficio
                </Text>
              </View>
              <View style={[styles.tableplant, { width: '100%' }]}>
                <View style={styles.tableRowplant}>
                  <View style={[styles.tableColplant, { width: '30%' }]}>
                    <View style={styles.tableCellplant}>
                      <Text
                        style={{
                          fontFamily: 'Nunito',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        Nombre
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                    <Text style={styles.tableCellplantRigth}>
                      {_.startCase(
                        props.url?.data?.planta?.nombre.toLowerCase()
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRowplant}>
                  <View style={[styles.tableColplant, { width: '30%' }]}>
                    <View style={styles.tableCellplant}>
                      <Text
                        style={{
                          fontFamily: 'Nunito',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        {!props?.url?.data?.planta?.numeroDocumento
                          ? 'Nit'
                          : 'Documento'}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                    <Text style={styles.tableCellplantRigth}>
                      {!props?.url?.data?.planta?.nit
                        ? props?.url?.data?.planta?.numeroDocumento
                        : props?.url?.data?.planta?.nit}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRowplant}>
                  <View style={[styles.tableColplant, { width: '30%' }]}>
                    <View style={styles.tableCellplant}>
                      <Text
                        style={{
                          fontFamily: 'Nunito',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        Teléfono
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                    <Text style={styles.tableCellplantRigth}>
                      {props?.url.data?.planta?.telefono === '0' ||
                      props?.url.data?.planta?.telefono === 'NINGUNO'
                        ? 'N.D.'
                        : props?.url.data?.planta?.telefono}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRowplant}>
                  <View style={[styles.tableColplant, { width: '30%' }]}>
                    <View style={styles.tableCellplant}>
                      <Text
                        style={{
                          fontFamily: 'Nunito',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        Email
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                    <Text
                      style={[
                        styles.tableCellplantRigth,
                        { textTransform: 'lowercase' },
                      ]}
                    >
                      {props.url.data.planta.email ?? 'N.D'}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRowplant}>
                  <View style={[styles.tableColplant, { width: '30%' }]}>
                    <View style={styles.tableCellplant}>
                      <Text
                        style={{
                          fontFamily: 'Nunito',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        Dirección
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                    <Text style={styles.tableCellplantRigth}>
                      {_.startCase(
                        props.url.data.planta.direccion.toLowerCase()
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRowplant}>
                  <View style={[styles.tableColplant, { width: '30%' }]}>
                    <View style={styles.tableCellplant}>
                      <Text
                        style={{
                          fontFamily: 'Nunito',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        Ciudad
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                    <Text style={styles.tableCellplantRigth}>
                      {_.capitalize(props.url.data.planta.ciudad)},
                      Colombia
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: '67%', marginTop: 6 }}>
              <View style={styles.backgroundresumetitle}>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    width: '100%',
                  }}
                >
                  Resumen de Transporte
                </Text>
              </View>
              <View style={styles.sectionresume}>
                <View style={styles.sectionnumbers}>
                  <View style={styles.resumedetail}>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        fontSize: 9,
                      }}
                    >
                      {new Intl.NumberFormat().format(
                        props.url.data.resumenTransporte.totalAnimales
                      )}
                    </Text>
                  </View>
                  <View style={styles.widthsectionnumbers}>
                    <Text style={styles.sectionnumberstext}>Total</Text>
                    <Text style={styles.sectionnumberstext}>Animales</Text>
                  </View>
                </View>
                <View style={styles.sectionnumbers}>
                  <View
                    style={[
                      styles.resumedetailsm,
                      { backgroundColor: '#D47D57' },
                    ]}
                  >
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        fontSize: 9,
                      }}
                    >
                      {props.url.data.resumenTransporte.totalLotes}
                    </Text>
                  </View>
                  <View style={styles.widthsectionnumbers}>
                    <Text style={styles.sectionnumberstext}>Total Lotes</Text>
                    <Text style={styles.sectionnumberstext}>Ingresados</Text>
                  </View>
                </View>
                <View style={styles.sectionnumbers}>
                  <View
                    style={[
                      styles.resumedetailsm,
                      { backgroundColor: '#E29660' },
                    ]}
                  >
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        fontSize: 9,
                      }}
                    >
                      {
                        props.url.data.resumenTransporte
                          .totalGuiasMovilizacion
                      }
                    </Text>
                  </View>
                  <View style={styles.widthsectionnumbers}>
                    <Text style={styles.sectionnumberstext}>
                      Total Guías de
                    </Text>
                    <Text style={styles.sectionnumberstext}>Movilización</Text>
                  </View>
                </View>
                <View style={styles.sectionnumbers}>
                  <View
                    style={[
                      styles.resumedetailsm,
                      { backgroundColor: '#F1AE6C' },
                    ]}
                  >
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        fontSize: 9,
                      }}
                    >
                      {
                        props.url.data.resumenTransporte
                          .totalCamionesIngresados
                      }
                    </Text>
                  </View>
                  <View style={styles.widthsectionnumbers}>
                    <Text style={styles.sectionnumberstext}>
                      Total Camiones
                    </Text>
                    <Text style={styles.sectionnumberstext}>Ingresados</Text>
                  </View>
                </View>
              </View>
              <View style={styles.sectionweitgh}>
                <View style={{ alignItems: 'center' }}>
                  <View
                    style={[
                      styles.backgroundweigth,
                      { backgroundColor: '#E29661' },
                    ]}
                  >
                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                      {formatNumber(
                        props.url.data.resumenTransporte
                          .totalPesoAnimalesPie
                      )}{' '}
                      Kg
                    </Text>
                  </View>
                  <Text style={styles.weigthanimals}>Peso Animales</Text>
                  <Text style={[styles.weigthanimals, { marginTop: 0 }]}>
                    en Pie (Kg)
                  </Text>
                </View>
                <View style={{ alignItems: 'center' }}>
                  <View
                    style={[
                      styles.backgroundweigth,
                      { backgroundColor: '#F1AE6C' },
                    ]}
                  >
                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                      {Number(
                        props.url.data.resumenTransporte
                          .promedioPesoAnimalesEnPie
                      ).toFixed(2)}{' '}
                      Kg
                    </Text>
                  </View>
                  <Text style={styles.weigthanimals}>Peso Promedio</Text>
                  <Text style={[styles.weigthanimals, { marginTop: 0 }]}>
                    Animales en Pie (Kg)
                  </Text>
                </View>
                <View style={{ alignItems: 'center' }}>
                  <View
                    style={[
                      styles.backgroundweigth,
                      { backgroundColor: '#FBC576' },
                    ]}
                  >
                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                      {
                        props.url.data.resumenTransporte
                          .tiempoPromedioEsperaPlanta
                      }{' '}
                      Min
                    </Text>
                  </View>
                  <Text style={styles.weigthanimals} break>
                    Tiempo Promedio
                  </Text>
                  <Text style={[styles.weigthanimals, { marginTop: 0 }]}>
                    Estancia en Planta
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.backgroundorigin}>
          <View style={styles.backgroundresumetitle}>
            <Text
              style={{
                fontFamily: 'Nunito',
                fontWeight: 'bold',
                width: '100%',
              }}
            >
              Origen Animales por Granja
            </Text>
          </View>
          <View style={styles.origincontainer}>
            {_.orderBy(
              props.url.data.origenAnimalesProductor,
              ['porcentaje'],
              ['desc']
            ).map((origen, i) => (
              <View key={i} style={styles.originitem} wrap={false}>
                <View
                  style={[
                    styles.originpercent,
                    { backgroundColor: i % 2 ? '#F1AE6C' : '#D47D57' },
                  ]}
                >
                  <Text
                    style={{
                      width: '100%',
                      color: '#FFF',
                      fontFamily: 'Roboto',
                      fontWeight: 'bold',
                      fontSize: 6,
                    }}
                  >
                    {Number(origen.porcentaje).toFixed(2)} %
                  </Text>
                </View>
                <View style={{ textAlign: 'left', width: '80%' }}>
                  <Text
                    style={{
                      fontSize: 7,
                      width: '100%',
                      textOverflow: 'ellipsis',
                      color: '#363636',
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: '#D47D57',
                      }}
                    >
                      Productor:
                    </Text>{' '}
                    {_.startCase(origen.nombreProductor.toLowerCase())}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      width: '100%',
                      textOverflow: 'ellipsis',
                      color: '#363636',
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: '#D47D57',
                      }}
                    >
                      Granja:
                    </Text>{' '}
                    {_.startCase(origen.nombreGranja.toLowerCase())}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Nunito',
                      fontStyle: 'italic',
                      fontSize: 7,
                      color: '#363636',
                    }}
                  >
                    {origen.cantidad} animales
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View>
          <View style={styles.backgroundresumetitle}>
            <Text
              style={{
                fontFamily: 'Nunito',
                fontWeight: 'bold',
                width: '100%',
              }}
            >
              Detalles del Reporte
            </Text>
          </View>
          {details.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <View
                wrap={false}
                style={[
                  styles.titleFlex,
                  {
                    paddingVertical: 5,
                    fontSize: 9,
                    width: '100%',
                    flexDirection: 'row',
                    marginTop: index > 0 ? 15 : 7,
                    backgroundColor: '#D6D6D6',
                  },
                ]}
              >
                <View
                  style={{
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: 'row',
                    flex: 1,
                  }}
                >
                  <Text style={{ width: '100%' }}>
                    <Text style={{ fontFamily: 'Nunito', fontWeight: 'bold' }}>
                      Granja:
                    </Text>{' '}
                    {_.startCase(item.name.toLowerCase())}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: 'row',
                    flex: 1,
                  }}
                >
                  <Text style={{ width: '100%' }}>
                    <Text style={{ fontFamily: 'Nunito', fontWeight: 'bold' }}>
                      Productor:
                    </Text>{' '}
                    {_.startCase(item.farmer.toLowerCase())}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    textAlign: 'right',
                    flexDirection: 'row',
                    flex: 1,
                  }}
                >
                  <Text style={{ width: '100%' }}>
                    <Text style={{ fontFamily: 'Nunito', fontWeight: 'bold' }}>
                      Promedio Peso en Pie:
                    </Text>{' '}
                    {formatNumber(item.average)} Kg
                  </Text>
                </View>
              </View>
              <View>
                <View style={[styles.table, { marginBottom: 10 }]} wrap={false}>
                  <View wrap={false} style={[styles.tableRow]}>
                    <View style={styles.tableCol}>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Nro. GSMI
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 40, width: 40 }]}
                    >
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Lote IP
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 30, width: 30 }]}
                    >
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Total
                      </Text>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Animales
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { width: 40, maxWidth: 40 }]}
                    >
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Placa
                      </Text>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Vehículo
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Responsable
                      </Text>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Portería
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Llegada
                      </Text>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Planta
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Salida
                      </Text>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Planta
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Estancia
                      </Text>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Planta
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Inicio
                      </Text>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Desembarque
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Fin
                      </Text>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Desembarque
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Tiempo
                      </Text>
                      <Text style={[styles.tableCell, styles.tableHead]}>
                        Desembarque
                      </Text>
                    </View>
                  </View>
                  {item.data.map((item: any, index: number) => (
                    <View
                      key={index}
                      wrap={false}
                      style={[
                        styles.tableRow,
                        {
                          backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                        },
                      ]}
                    >
                      <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontSize: 6 }]}>
                          {item.GSMI}
                        </Text>
                      </View>
                      <View
                        style={[styles.tableCol, { maxWidth: 40, width: 40 }]}
                      >
                        <Text style={[styles.tableCell, { fontSize: 6 }]}>
                          {item.codigoLote}
                        </Text>
                      </View>
                      <View
                        style={[styles.tableCol, { width: 30, maxWidth: 30 }]}
                      >
                        <Text style={(styles.tableCell, { fontSize: 6 })}>
                          {item.totalAnimales}
                        </Text>
                      </View>
                      <View
                        style={[styles.tableCol, { maxWidth: 40, width: 40 }]}
                      >
                        <Text style={[styles.tableCell, { fontSize: 6 }]}>
                          {item.placa}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text
                          style={[
                            styles.tableCell,
                            { fontSize: 6 },
                            { maxLines: 2 },
                          ]}
                        >
                          {_.startCase(item.responsablePorteria.toLowerCase())}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontSize: 6 }]}>
                          {moment(item.llegadaPlanta).format(
                            'YYYY/MM/DD hh:mm a'
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontSize: 6 }]}>
                          {moment(item.salidaPlanta).format(
                            'YYYY/MM/DD hh:mm a'
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontSize: 6 }]}>
                          {item.tiempoPlanta}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontSize: 6 }]}>
                          {moment(item.inicioDesembarque).format(
                            'YYYY/MM/DD hh:mm a'
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontSize: 6 }]}>
                          {moment(item.finDesembarque).format(
                            'YYYY/MM/DD hh:mm a'
                          )}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontSize: 6 }]}>
                          {item.TiempoDesembarque}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </React.Fragment>
          ))}
        </View>
        <View style={styles.conventions} wrap={false}>
          <View style={styles.backgroundguide}>
            <View style={styles.textconventions}>
              <View style={styles.gsmi}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * GSMI
                </Text>
              </View>
              <View style={styles.textgsmi}>
                <Text>Guía Sanitaria de Movilización Interna de Animales</Text>
              </View>
            </View>
          </View>
          <View style={styles.border}>
            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
              Convenciones
            </Text>
          </View>
        </View>
        <View style={styles.copy} wrap>
          <Text>
            Ⓒ 1995- {new Date().getFullYear()} Todos los derechos están
            reservados de forma exclusiva para Mercadeo Virtual S.A. Los datos
            suministrados por el usuario son de carácter confidencial y
            reservado, se regirán acorde a los términos de la Política de
            Confidencialidad y Reserva definida por la empresa Mercadeo Virtual
            S.A y la legislacion existente en Colombia sobre el registro, manejo
            y confidencialidad de los datos (Habeas Data).
          </Text>
          <Text style={styles.phone}>
            Teléfono (+574) 3228 603 | Correo:
            atencionalcliente@infoporcinos.com | Medellín Colombia
          </Text>
          <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
            www.infoporcinos.com
          </Text>
        </View>
        <View
          style={{
            width: 611,
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            paddingBottom: 25,
            paddingHorizontal: 30,
            flexDirection: 'row',
          }}
          fixed
        >
          <Text style={[{ flex: 1, textAlign: 'left' }]}>
            Fecha de impresión: {moment().format('DD/MM/YYYY')}. uid: {uid}
          </Text>
          <Text
            style={[styles.textBold, { flex: 1, textAlign: 'right' }]}
            render={({ pageNumber, totalPages }) =>
              pageNumber > 1 && `Pág Nro. ${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </Page>
    </Document>
  );
};

export const ReportFilter = ({ data }: any) => (
  <div id="barcode" style={{ width: 500 }}>
    <QRCode
      value={`http://localhost:3000/view/report/${1}`}
      renderAs="svg"
      size={500}
      level="H"
    />
  </div>
);
