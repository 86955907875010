import React, { useState, useRef, useEffect } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import makeJSDateObject from 'lib/date/makeJSDateObject';
import DateFnsUtils from '@date-io/date-fns';
import ES from 'date-fns/locale/es';
import { motion, AnimatePresence } from 'framer-motion';
import { Icon, Popup } from 'semantic-ui-react';
import { useFormik } from 'formik';
import moment from 'moment';
import 'moment/locale/es';

import useStyles, {
  Filter,
  FilterContainer,
  Title,
  Inputs,
  ButtonSearch,
  ButtonFilters,
  Params,
  InputSearchContainer,
} from './styles';
import { paramsBar, childrenParamsBar } from './animations';
import {
  ClickAwayListener,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import WeekPicker from '../WeekPicker';

interface IForm {
  filter: 0 | 1 | 2 | 3 | 4 | 5;
  initialDate?: Date | number;
  finalDate?: Date | number;
  dateRange: Array<any>;
  lote?: string | number;
  canal?: string | number;
  plant?: number;
}

const filtersAvaible: ('TODAY' | 'WEEK' | 'RANGE' | 'LOTE' | 'CANAL')[] = [
  'TODAY',
  'WEEK',
  'RANGE',
  'LOTE',
  'CANAL',
];

const FilterReports = ({
  to,
  filtersActive = filtersAvaible,
}: {
  to: string;
  filtersActive?: string[];
}) => {
  const classes = useStyles();
  const history = useHistory();
  const selectFilter = useRef<HTMLInputElement>(null);
  const typeReport = [
    { value: 'lote', name: 'Lotes' },
    { value: 'canal', name: 'Canales' },
  ];
  const [typeValue, setTypeValue] = useState('lote');
  const [active, setActive] = useState(false);
  const url = window.location.pathname;

  const { values, handleChange, handleBlur, setFieldValue, handleSubmit } =
    useFormik<IForm>({
      initialValues: {
        filter: 0,
        initialDate: new Date(),
        finalDate: new Date().setDate(new Date().getDate() + 1),
        dateRange: [new Date().setDate(new Date().getDate() - 1), new Date()],
        lote: '',
        canal: '',
        plant: 6,
      },
      onSubmit: (data) => {
        const filter = Number(data.filter);
        if (filter === 1)
          if (url === '/app/reports/magro') {
            history.push(
              `${to}?type=today&filter=${typeValue}&initialdate=${moment(
                moment().format('YYYY/MM/DD 00:00:00')
              ).valueOf()}&finaldate=${moment(
                moment().format('YYYY/MM/DD 23:59:59')
              ).valueOf()}`
            );
          } else {
            history.push(
              `${to}?type=today&initialdate=${moment(
                moment().format('YYYY/MM/DD 00:00:00')
              ).valueOf()}&finaldate=${moment(
                moment().format('YYYY/MM/DD 23:59:59')
              ).valueOf()}`
            );
          }
        if (filter === 2)
          if (url === '/app/reports/magro') {
            history.push(
              `${to}?type=week&filter=${typeValue}&initialdate=${data?.dateRange[0]?.valueOf()}&finaldate=${data?.dateRange[1]?.valueOf()}`
            );
          } else {
            history.push(
              `${to}?type=week&initialdate=${data?.dateRange[0]?.valueOf()}&finaldate=${data?.dateRange[1]?.valueOf()}`
            );
          }
        if (filter === 3)
          if (url === '/app/reports/magro') {
            history.push(
              `${to}?type=range&filter=${typeValue}&initialdate=${data?.initialDate?.valueOf()}&finaldate=${data?.finalDate?.valueOf()}`
            );
          } else {
            history.push(
              `${to}?type=range&initialdate=${data?.initialDate?.valueOf()}&finaldate=${data?.finalDate?.valueOf()}`
            );
          }
        if (filter === 4) history.push(`${to}?type=lote&code=${data.lote}`);
        if (filter === 5) history.push(`${to}?type=canal&code=${data.canal}`);
      },
    });

  useEffect(() => {
    const filter = Number(values.filter);
    if (filter !== 2 && filter !== 3) setActive(false);
  }, [values.filter]);

  const filters = [
    { key: 'TODAY', value: 1, text: 'Hoy' },
    { key: 'WEEK', value: 2, text: 'Semana' },
    { key: 'RANGE', value: 3, text: 'Rango de fecha' },
    { key: 'LOTE', value: 4, text: 'Lote InfoPorcinos' },
    { key: 'CANAL', value: 5, text: 'Canal' },
  ];

  const handleChangeFilters = (input: any) => {
    if (input.target.value === 3 || input.target.value === 2) setActive(true);
    setFieldValue(input.target.name, input.target.value);
    if (input.target.value === 3) {
      setActive(true);
    }
  };

  const handleType = (event: any) => {
    setTypeValue(event.target.value);
  };

  return (
    <ClickAwayListener onClickAway={() => setActive(false)}>
      <Filter>
        <FilterContainer ref={selectFilter} active={active}>
          {url !== '/app/inventory/corral' && url !== '/app/inventory/cava' ? (
            <div>
              <Title>Filtros de Búsqueda</Title>
            </div>
          ) : url === '/app/inventory/corral' ? (
            <div>
              <Title>
                Consulta del Inventario de Animales en Pie en Corrales
              </Title>
            </div>
          ) : (
            <div>
              <Title>Consulta Inventario Cavas</Title>
            </div>
          )}
          <Inputs onSubmit={handleSubmit}>
            <TextField
              label="Planta de Beneficio"
              color="primary"
              name="plant"
              variant="outlined"
              defaultValue={6}
              onBlur={handleBlur}
              onChange={handleChange}
              size="small"
              select
              fullWidth
              className={classes.filterValues}
            >
              <MenuItem value={6}>Sociedad Central Ganadera S.A</MenuItem>
            </TextField>
            <TextField
              label="Seleccione filtro"
              color="primary"
              name="filter"
              variant="outlined"
              defaultValue={values.filter}
              onBlur={handleBlur}
              onChange={handleChangeFilters}
              size="small"
              select
              fullWidth
              className={classes.filterValues}
            >
              {url === '/app/reports/magro' ||
              url === '/app/reports/transport-sacrifice' ||
              url === '/app/reports/post-mortem'
                ? filtersActive.map((value, index) => {
                    const found = filters.find(
                      (filter) => filter.key === value
                    );
                    if (found) {
                      return (
                        <MenuItem key={found.key} value={found.value}>
                          {found.text}
                        </MenuItem>
                      );
                    }
                    return null;
                  })
                : url === '/app/inventory/corral' ||
                  url == '/app/inventory/cava'
                ? filtersActive.map((value, index) => {
                    const found = filters.find(
                      (filter) => filter.key === value
                    );
                    if (found) {
                      if (found?.value == 1 || found?.value == 4) {
                        return (
                          <MenuItem key={found.key} value={found.value}>
                            {found.text}
                          </MenuItem>
                        );
                      }
                    }
                    return null;
                  })
                : filtersActive.slice(0, 4).map((value, index) => {
                    const found = filters.find(
                      (filter) => filter.key === value
                    );
                    if (found) {
                      return (
                        <MenuItem key={found.key} value={found.value}>
                          {found.text}
                        </MenuItem>
                      );
                    }
                    return null;
                  })}
            </TextField>
            <AnimatePresence>
              {values.filter === 4 && (
                <InputSearchContainer
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 200 }}
                  exit={{ opacity: 0, width: 0 }}
                >
                  <TextField
                    label="Buscar lote"
                    name="lote"
                    onChange={handleChange}
                    size="small"
                    className={classes.loteField}
                    variant="outlined"
                  />
                </InputSearchContainer>
              )}
            </AnimatePresence>
            {(url === '/app/reports/magro' ||
              url === '/app/reports/transport-sacrifice' ||
              url === '/app/reports/post-mortem') && (
              <AnimatePresence>
                {values.filter === 5 && (
                  <InputSearchContainer
                    initial={{ opacity: 0, width: 0 }}
                    animate={{ opacity: 1, width: 200 }}
                    exit={{ opacity: 0, width: 0 }}
                  >
                    <TextField
                      label="Buscar Canal"
                      name="canal"
                      onChange={handleChange}
                      size="small"
                      className={classes.loteField}
                      variant="outlined"
                    />
                  </InputSearchContainer>
                )}
              </AnimatePresence>
            )}

            {url === '/app/reports/magro' && (
              <AnimatePresence>
                {(values.filter === 1 ||
                  values.filter === 2 ||
                  values.filter === 3) && (
                  <InputSearchContainer
                    initial={{ opacity: 0, width: 0 }}
                    animate={{ opacity: 1, width: 200 }}
                    exit={{ opacity: 0, width: 0 }}
                  >
                    <TextField
                      label="Detalle"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="canal"
                      value={typeValue}
                      onChange={handleType}
                      style={{ minWidth: 100 }}
                      select
                    >
                      {typeReport.map((item: any) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </InputSearchContainer>
                )}
              </AnimatePresence>
            )}

            <ButtonSearch type="submit">
              <Icon className="icon-search" size="big" inverted />
            </ButtonSearch>
            {(values.filter === 2 || values.filter === 3) && (
              <Popup
                content={
                  active
                    ? 'Cerrar parámetros de búsqueda'
                    : 'Parámetros de Búsqueda'
                }
                position="top right"
                inverted
                trigger={
                  <ButtonFilters
                    active={active}
                    type="button"
                    onClick={() => setActive(!active)}
                  >
                    {!active ? (
                      <Icon name="sliders horizontal" inverted />
                    ) : (
                      <Icon className="icon-close" size="big" inverted />
                    )}
                  </ButtonFilters>
                }
              />
            )}
          </Inputs>
        </FilterContainer>
        <Params
          initial={false}
          animate={active ? 'open' : 'closed'}
          variants={paramsBar}
          margin={{
            top: selectFilter.current ? selectFilter.current.offsetHeight : 0,
          }}
        >
          <AnimatePresence>
            <motion.div
              exit={childrenParamsBar.closed}
              variants={childrenParamsBar}
            >
              <h2>Parámetros de Búsqueda</h2>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ES}>
                {values.filter === 3 && (
                  <div className={classes.containerRageDate}>
                    <DatePicker
                      autoOk
                      label="Fecha Inicial"
                      value={values.initialDate}
                      onChange={(date: any) =>
                        setFieldValue('initialDate', date)
                      }
                      fullWidth
                      inputVariant="outlined"
                      size="small"
                      variant="inline"
                      labelFunc={() =>
                        format(makeJSDateObject(values.initialDate), 'dd/MM/y')
                      }
                    />
                    <Typography variant="subtitle1" color="secondary">
                      Hasta
                    </Typography>
                    <DatePicker
                      autoOk
                      label="Fecha Final"
                      value={values.finalDate}
                      onChange={(date: any) => {
                        setFieldValue('finalDate', date);
                      }}
                      fullWidth
                      inputVariant="outlined"
                      size="small"
                      variant="inline"
                      labelFunc={() =>
                        format(makeJSDateObject(values.finalDate), 'dd/MM/y')
                      }
                      minDate={values.initialDate}
                      minDateMessage="La fecha final debe ser mayor a la inicial"
                    />
                  </div>
                )}
                {values.filter === 2 && (
                  <WeekPicker
                    onChange={(range) => setFieldValue('dateRange', range)}
                  />
                )}
              </MuiPickersUtilsProvider>
              {values.filter !== 3 && values.filter !== 2 && (
                <h4>Debes seleccionar un tipo de filtro</h4>
              )}
            </motion.div>
          </AnimatePresence>
        </Params>
      </Filter>
    </ClickAwayListener>
  );
};

export default FilterReports;
