import React from 'react';

import { Grid, Box, Typography } from '@material-ui/core';

import useStyles from './styles';

interface AddressPreviewProps {
  directionType?: string | number;
  country?: string;
  department?: string;
  city?: string;
  roadType?: any;
  numberVia?: string | number;
  viaAppend?: string;
  numberCrossing?: number | string;
  crossingAppend?: string;
  cornerMeters?: string | number;
  additional?: string;
  postalCode?: string;
  direction?: any;
  latitude?: string;
  longitude?: string;
}

const AddressPreview = ({
  directionType,
  country,
  department,
  city,
  roadType,
  numberVia,
  viaAppend,
  numberCrossing,
  crossingAppend,
  cornerMeters,
  additional,
  postalCode,
  direction,
  latitude,
  longitude,
}: AddressPreviewProps) => {
  const classes = useStyles();

  return (
    <>
      {direction ? (
        <Grid container xs={12} spacing={2}>
          <Box className={classes.container}>
            <Box margin="0px 8px">
              <Typography
                align="center"
                className={classes.itemsText}
                color="secondary"
              >
                {latitude ?? ''}
              </Typography>
              <div className={classes.AddressUnderline}></div>
              <Typography align="center" className={classes.AddressLabel}>
                Latitud
              </Typography>
            </Box>
            <Box margin="0px 8px">
              <Typography
                align="center"
                className={classes.itemsText}
                color="secondary"
              >
                {longitude ?? ''}
              </Typography>
              <div className={classes.AddressUnderline}></div>
              <Typography align="center" className={classes.AddressLabel}>
                Longitud
              </Typography>
            </Box>
            <Box margin="0px 8px">
              <Typography
                align="center"
                className={classes.itemsText}
                color="secondary"
              >
                {direction ?? ''}
              </Typography>
              <div className={classes.AddressUnderline}></div>
              <Typography align="center" className={classes.AddressLabel}>
                Dirección
              </Typography>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography
              color="secondary"
              align="center"
              style={{ fontSize: 18 }}
            >
              {`${
                directionType === 1 ? 'Urbano' : 'Rural'
              } - ${city} , ${department}, ${country} `}
            </Typography>
          </Grid>
          {directionType === 1 ? (
            <Grid item xs={12}>
              <Box className={classes.container}>
                <Box margin="0px 8px">
                  <Typography
                    align="center"
                    className={classes.itemsText}
                    color="secondary"
                  >
                    {roadType ?? ''}
                  </Typography>
                  <div className={classes.AddressUnderline}></div>
                  <Typography align="center" className={classes.AddressLabel}>
                    Tipo Vía
                  </Typography>
                </Box>
                <Box margin="0px 8px">
                  <Typography
                    align="center"
                    className={classes.itemsText}
                    color="secondary"
                  >
                    {numberVia ?? ''}
                  </Typography>
                  <div className={classes.AddressUnderline}></div>
                  <Typography align="center" className={classes.AddressLabel}>
                    Nro. Vía
                  </Typography>
                </Box>
                <Box margin="0px 8px">
                  <Typography
                    align="center"
                    className={classes.itemsText}
                    color="secondary"
                  >
                    {viaAppend ?? ''}
                  </Typography>
                  <div className={classes.AddressUnderline}></div>
                  <Typography align="center" className={classes.AddressLabel}>
                    Apendice Vía
                  </Typography>
                </Box>
                <Box margin="0px 8px">
                  <Typography
                    align="center"
                    className={classes.itemsText}
                    color="secondary"
                  >
                    #
                  </Typography>
                </Box>
                <Box margin="0px 8px">
                  <Typography
                    align="center"
                    className={classes.itemsText}
                    color="secondary"
                  >
                    {numberCrossing ?? ''}
                  </Typography>
                  <div className={classes.AddressUnderline}></div>
                  <Typography align="center" className={classes.AddressLabel}>
                    Nro. Cruce
                  </Typography>
                </Box>
                <Box margin="0px 8px">
                  <Typography
                    align="center"
                    className={classes.itemsText}
                    color="secondary"
                  >
                    {crossingAppend ?? ''}
                  </Typography>
                  <div className={classes.AddressUnderline}></div>
                  <Typography align="center" className={classes.AddressLabel}>
                    Apendice Cruce
                  </Typography>
                </Box>
                <Box margin="0px 8px">
                  <Typography
                    align="center"
                    className={classes.itemsText}
                    color="secondary"
                  >
                    -
                  </Typography>
                </Box>
                <Box margin="0px 8px">
                  <Typography
                    align="center"
                    className={classes.itemsText}
                    color="secondary"
                  >
                    {cornerMeters ?? ''}
                  </Typography>
                  <div className={classes.AddressUnderline}></div>
                  <Typography align="center" className={classes.AddressLabel}>
                    Metros Esquina
                  </Typography>
                </Box>
              </Box>
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                marginTop={2}
              >
                {additional && (
                  <Box margin="0px 8px">
                    <Typography
                      align="center"
                      className={classes.itemsText}
                      color="secondary"
                    >
                      {additional ?? ''}
                    </Typography>
                    <div className={classes.AddressUnderline}></div>
                    <Typography align="center" className={classes.AddressLabel}>
                      Adicional
                    </Typography>
                  </Box>
                )}
                <Box margin="0px 8px">
                  <Typography
                    align="center"
                    className={classes.itemsText}
                    color="secondary"
                  >
                    {postalCode ?? ''}
                  </Typography>
                  <div className={classes.AddressUnderline}></div>
                  <Typography align="center" className={classes.AddressLabel}>
                    Código Postal
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Box className={classes.container}>
                <Box margin="0px 8px">
                  <Typography
                    align="center"
                    className={classes.itemsText}
                    color="secondary"
                  >
                    {additional ?? ''}
                  </Typography>
                  <div className={classes.AddressUnderline}></div>
                  <Typography align="center" className={classes.AddressLabel}>
                    Dirección
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default AddressPreview;
