import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { GroupAdd } from '@material-ui/icons';
import clsx from 'clsx';

import IpLogo from 'static/img/logos/ip-icon.svg';

import avatar from 'lib/helpers/dynamic-images/avatars';

import { useStyles } from './styles';

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 25,
    height: 25,
    boxShadow: theme.shadows[2],
    background: '#BE5948',
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
}))(Avatar);

interface AvatarBadgeProps {
  children?: React.ReactNode;
  className?: string;
  classes?: {
    root?: any;
    smallAvatar?: any;
    avatar?: any;
    icon?: any;
    badge?: any;
  };
  Icon?: React.ElementType;
  sizes?: {
    badge?: number;
    avatar?: number;
  };
  src?: any;
  alt?: any;
}

const AvatarBadge: React.FC<AvatarBadgeProps> = ({
  children,
  className,
  classes = {
    root: null,
    smallAvatar: null,
    avatar: null,
    icon: null,
    badge: null,
  },
  Icon = GroupAdd,
  sizes = {
    badge: 25,
    avatar: 55,
  },
  src = null,
  alt = 'null',
}) => {
  const styles = useStyles();

  const getImage = () => {
    if (typeof children === 'string') return avatar(children as string);
    return IpLogo
  }

  return (
    <div className={clsx(styles.root, classes.root, className)}>
      <Badge
        className={classes.badge}
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <SmallAvatar
            className={classes.smallAvatar}
            children={<Icon className={classes.icon} />}
            style={{ width: sizes.badge, height: sizes.badge }}
          />
        }
      >
        <Avatar
          className={clsx(styles.avatar_badge, classes.avatar)}
          alt={alt}
          src={src ?? getImage()}
          style={{
            width: sizes.avatar,
            height: sizes.avatar,
            fontSize: Math.ceil(sizes?.avatar ?? 0 / 2),
          }}
        >
          {children}
        </Avatar>
      </Badge>
    </div>
  );
};

export default AvatarBadge;
