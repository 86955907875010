import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Grid,
  Checkbox,
  Avatar,
  Typography,
  Button,
  Box,
  Tabs,
  FormControlLabel,
  Switch,
  CardActionArea,
  Card,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import ContentCard from 'components/ContentCard';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import GobackLink from 'components/GobackLink';
import LicensePlate from 'components/LicensePlate/index';
import MainTitle from 'components/MainTitle';
import Loader from 'components/Loader';
import ItemListData from 'components/ItemListData';
import Slider from 'components/Slider';

import {
  GET_DRIVER,
  GET_VEHICLES_DRIVER,
} from 'core/graphql/queries/transport';
import avatar from 'lib/helpers/dynamic-images/avatars';

import useStyles from './styles';

const Details = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: `/app/network/transports/details/${id}`,
      title: 'Detalle Conductor',
    },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];
  const [state, setState] = useState(true);
  const [selectedValue, setSelectedValue] = useState({});
  const [dataSelect, setDataSelect] = useState({
    placa: '',
    numeroLicenciaTransito: '',
    marca: '',
    modelo: '',
    color: '',
    descripcionServicio: '',
    descripcionTipoVehiculo: '',
    numeroPisos: '',
    capacidadCarga: '',
    descripcionTipoCarga: '',
    nombreCompletoPropietario: '',
    tipoServicio: '',
  });
  const token = useSelector((state: any) => state.user.token);
  const { pathname } = useLocation();

  const [
    getDriver,
    { data: dataDriver, loading: loadingDriver, error: errorDriver },
  ] = useLazyQuery(GET_DRIVER, {
    fetchPolicy: 'network-only',
  });

  const {
    data: dataVehicles,
    loading: loadingVehicles,
    error: errorVehicles,
    refetch: refetchVehicles,
  } = useQuery(GET_VEHICLES_DRIVER, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idConductor: Number(id) },
  });

  const generalData = dataDriver?.getDetailDriver?.data;
  const dataVehiclesG = dataVehicles?.getVehiclesXDriver?.data;

  const handleChange = () => {
    setState(!state);
  };

  const handleChangeSelect = (data: any) => {
    setSelectedValue(data?.placa);
    setDataSelect(data);
  };

  useEffect(() => {
    if (!loadingVehicles && !loadingDriver && dataVehicles && !errorVehicles) {
      setSelectedValue(dataVehiclesG[0]?.placa ?? {});
      setDataSelect({
        placa: dataVehiclesG[0]?.placa,
        numeroLicenciaTransito: dataVehiclesG[0]?.numeroLicenciaTransito,
        marca: dataVehiclesG[0]?.marca,
        modelo: dataVehiclesG[0]?.modelo,
        color: dataVehiclesG[0]?.color,
        descripcionServicio: dataVehiclesG[0]?.servicio,
        descripcionTipoVehiculo: dataVehiclesG[0]?.descripcionTipoVehiculo,
        numeroPisos: dataVehiclesG[0]?.numeroPisos,
        capacidadCarga: dataVehiclesG[0]?.capacidadCarga,
        descripcionTipoCarga: dataVehiclesG[0]?.descripcionTipoCarga,
        nombreCompletoPropietario: dataVehiclesG[0]?.nombreCompletoPropietario,
        tipoServicio: dataVehiclesG[0]?.tipoServicio,
      });
    }
  }, [loadingVehicles, loadingDriver]);

  useEffect(() => {
    if (id) {
      getDriver({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idConductor: Number(id) },
      });
    }
  }, [id]);

  useEffect(() => {
    const path = pathname.slice(0, -3);
    if (path === '/app/network/transports/details') {
      refetchVehicles();
    }
  }, [pathname]);

  return (
    <>
      <Loader visible={loadingDriver || loadingVehicles}>Cargando...</Loader>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <Grid container spacing={4}>
        <Grid container item sm={6}>
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.contentCard}>
              <ContentCard.Header>
                <MainTitle fontSize="h5">Datos del Conductor</MainTitle>
              </ContentCard.Header>

              <Box mt={1}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item container xs={8}>
                    <Box component="div" display="flex">
                      <Box mr={2}>
                        <Avatar
                          src={avatar(generalData?.primerNombre)}
                          style={{ width: 80, height: 80 }}
                        />
                      </Box>
                      <Box maxWidth="60%">
                        <Typography variant="h6" className={classes.nameInfo}>
                          {generalData?.primerNombre +
                            ' ' +
                            generalData?.segundoNombre +
                            ' ' +
                            generalData?.primerApellido +
                            ' ' +
                            generalData?.segundoApellido}
                        </Typography>
                        <Typography className={classes.ubicationInfo}>
                          {generalData?.ciudad}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item container xs={4} justifyContent="flex-end">
                    <img
                      style={{ maxWidth: 160 }}
                      src={`data:image/png;base64, ${generalData?.codigoQr}`}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={3}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <ItemListData
                      data={[
                        {
                          title: 'Tipo de Documento',
                          data: generalData?.tipoDocumento,
                        },
                        {
                          title: 'Numero de Documento',
                          data: generalData?.numeroDocumento,
                        },
                        {
                          title: 'Celular',
                          data: generalData?.celular,
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <ItemListData
                      data={[
                        {
                          title: 'Número de Licencia',
                          data: generalData?.numeroLicenciaConduccion,
                        },
                        {
                          title: 'Correo Electronico',
                          data: generalData?.correo,
                        },
                        {
                          title: 'Dirección',
                          data: 'Carrera 77 A # 48 - 18',
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Grid container justify="center">
                <Box mt={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/app/network/transports/forms/detail-driver/${id}`}
                  >
                    Ver detalle
                  </Button>
                </Box>
              </Grid>
            </ContentCard>
          </Grid>
        </Grid>

        <Grid container item sm={6} alignItems="stretch">
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.contentCardPlacas}>
              <Box mb={3}>
                <ContentCard.Header
                  date={`${dataVehiclesG?.length ?? 0} Vehículos`}
                >
                  <MainTitle fontSize="h5">Vehículos Asociados</MainTitle>
                </ContentCard.Header>
              </Box>

              <ContentCard.Content>
                <Box display="flex" justifyContent="center">
                  {!loadingVehicles && (
                    <Grid container className={classes.sliderContainer}>
                      <Slider>
                        {dataVehiclesG?.map((item: any) => {
                          return (
                            <LicensePlate
                              placa={item?.placa}
                              ciudad={item?.ciudad}
                              onClick={() => handleChangeSelect(item)}
                              checked={selectedValue === item?.placa}
                              selectable
                            />
                          );
                        })}
                      </Slider>
                    </Grid>
                  )}
                </Box>
              </ContentCard.Content>
            </ContentCard>
          </Grid>

          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.contentCard}>
              {!loadingVehicles && !errorVehicles && (
                <>
                  <Box mb={2}>
                    <MainTitle fontSize="h5">
                      Datos Generales de Vehículo:
                      <span className={classes.fontPlateColor}>
                        {dataSelect.placa}
                      </span>
                    </MainTitle>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <ItemListData
                        data={[
                          {
                            title: 'Marca',
                            data: dataSelect.marca,
                          },
                          {
                            title: 'Tipo de Vehículo',
                            data: dataSelect.descripcionTipoVehiculo,
                          },
                          {
                            title: 'Modelo',
                            data: dataSelect.modelo,
                          },
                          {
                            title: 'Color',
                            data: dataSelect.color,
                          },
                          {
                            title: 'Número de Pisos',
                            data: dataSelect.numeroPisos,
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ItemListData
                        data={[
                          {
                            title: 'Servicio',
                            data: 'Privado',
                          },
                          {
                            title: 'Nro. Lincencia de Transito',
                            data: dataSelect.numeroLicenciaTransito,
                          },
                          {
                            title: 'Capacidad',
                            data:
                              dataSelect.capacidadCarga +
                              ' ' +
                              dataSelect.descripcionTipoCarga,
                          },
                          {
                            title: 'Propietario',
                            data: dataSelect.nombreCompletoPropietario,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </ContentCard>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Details;
