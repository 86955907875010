import { AppBar, Avatar, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import config from 'config/secrets';
import avatar from 'lib/helpers/dynamic-images/avatars';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '2.4rem',
    paddingBottom: '4rem',
    marginBottom: '4rem',
    zIndex: 0,
    background: '#404040',
    boxSizing: 'border-box',
  },
  avatar: {
    width: 72,
    height: 72,
    position: 'absolute',
    top: 110,
    padding: 8,
    background: '#2C2C2C',
    boxSizing: 'content-box',
    left: '50%',
    transform: 'translateX(-50%)',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    '& > img': {
      borderRadius: '50%',
    },
  },
  name: {
    fontSize: theme.spacing(2),
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontFamily: 'Raleway',
  },
  username: {
    color: theme.palette.primary.main,
  },
  plan: {
    color: '#AFAFAF',
  },
}));

const UserNavbarHeader = () => {
  const folded = useSelector((state: any) => state.ui.sidebar.folded);
  const user = useSelector((state: any) => state.user.user);
  const isPerson = user.typeEntity === 'person';
  const classes = useStyles({ folded });
  const subscriptionType = useSelector(
    (state: any) => state.user.subscriptionPlan.type
  );

  const getFirstChar = (): string =>
    user[isPerson ? 'username' : 'bussinesName'].substr(0, 1).toLowerCase();

  const getFullName = (): string =>
    _.startCase(
      (isPerson
        ? `${user.name} ${user.lastName}`
        : user.bussinesName
      ).toLowerCase()
    );

  return (
    <AppBar
      position="static"
      elevation={0}
      classes={{ root: classes.root }}
      className="user"
    >
      <Typography className={clsx('name', classes.name)}>
        {getFullName()}
      </Typography>
      <Typography className={clsx('username', classes.username)}>
        @{user.username}
      </Typography>
      <Avatar
        className={clsx(classes.avatar, 'avatar')}
        alt="user photo"
        src={
          user.avatar
            ? `${config.BACKEND.dev.backend}${user.avatar}`
            : avatar(getFirstChar())
        }
      />
      <Typography className={clsx('username', classes.plan)}>
        {subscriptionType}
      </Typography>
    </AppBar>
  );
};

export default UserNavbarHeader;
