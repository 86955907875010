import React from 'react';

import { GenerateRoutes as Routes } from 'routes';

import PagesContainer from 'components/PagesContainer';

import { Main, Details, Search } from './views';

const Entrance = () => (
  <PagesContainer>
    <Routes MainPage={Main} DetailsPage={Details} SearchPage={Search} />
  </PagesContainer>
);

export default Entrance;
