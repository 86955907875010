import { gql } from "apollo-boost";

export const GIVE_UP_ROLE = gql`
  mutation asignRoleAdmin($idRole: Int!, $idUser: Int!, $idUserType: Int!) {
    asignRoleAdmin(input: {
      IdRole: $idRole
      IdUser: $idUser
      IdTipoUsuario: $idUserType
    })
    {
      message
      data{
        succes
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;