import { useState } from 'react'

interface IPagination {
  next: () => void,
  prev: () => void,
  jump: (page: number) => void,
  currentData: () => any,
  maxPage: number
}

export default (data: any[], itemsPerPage: number): IPagination => {
  if (!Array.isArray(data)) throw new Error('data passed to usePagination doesn\'t an Array')
  const [currentPage, setCurrentPage] = useState(1)
  const maxPage = Math.ceil(data.length/itemsPerPage)

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage
    const end = begin + itemsPerPage
    return data.slice(begin, end)
  }

  function next() {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage))
  }

  function prev() {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1))
  }

  function jump(page: number) {
    setCurrentPage(Math.min(Math.max(1, page), maxPage))
  }

  return { next, prev, jump, currentData, maxPage }
}