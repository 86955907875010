import React, { useState } from 'react';

import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Button,
  Divider,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { CHANGE_PASSWORD } from 'core/graphql/mutations/changePassword';
import useSignOut from 'lib/hooks/useSignOut';
import { ChangePassword as ChangePasswordValidation } from 'lib/validations/Profile';

import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { useFormik } from 'formik';

import useStyles from './styles';

const ChangePassword = () => {
  const classes = useStyles();
  const { signOut } = useSignOut();
  const [showAP, setShowAP] = useState(false);
  const [showNP, setShowNP] = useState(false);
  const [showRP, setShowRP] = useState(false);
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();

  const [updatePassword, { loading }] = useMutation(CHANGE_PASSWORD);

  const formik = useFormik({
    initialValues: {
      actualPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: ChangePasswordValidation,
    onSubmit: (values: any) => {
      updatePassword({
        variables: {
          passwordActual: values.actualPassword,
          passwordNueva: values.newPassword,
          process: 'PROFILE',
        },
        context: { headers: { 'x-auth-jwt': token } },
      }).then((response) => {
        if (response.data.changeDataPassword.data.success === 1) {
          enqueueSnackbar('Contraseña actualizada correctamente.', {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
          signOut();
        } else if (response.data.changeDataPassword.data.success === 0) {
          enqueueSnackbar('Contraseña actual no coincide.', {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
        }
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography variant="h6" className={classes.title}>
            Ajustes de Seguridad
          </Typography>
        </Box>
        <Divider />
        <Box marginY={2}>
          <Typography variant="h6" color="secondary">
            Cambio de Contraseña
          </Typography>
          <Typography color="secondary">
            Al realizar el cambio de contraseña debes tener en cuenta las
            siguientes características para la nueva contraseña:
          </Typography>
          <Typography color="secondary">
            <ul>
              <li>Debe tener Minímo 8 caracteres</li>
              <li>Debe contener almenos una mayúscula</li>
              <li>Debe contener almenos una minúscula</li>
              <li>Debe contener al menos un número</li>
              <li>No se permiten caracteres especiales ni espacios</li>
            </ul>
          </Typography>
        </Box>
        <Box marginY={2}>
          <Grid container spacing={2}>
            <Grid container item md={12} spacing={1}>
              <Grid item md={6}>
                <TextField
                  label="Contraseña Actual"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type={showAP ? 'text' : 'password'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.actualPassword}
                  name="actualPassword"
                  helperText={formik.errors.actualPassword}
                  error={Boolean(formik.errors.actualPassword)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowAP(!showAP)}>
                          {showAP ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Nueva contraseña"
                variant="outlined"
                size="small"
                fullWidth
                type={showNP ? 'text' : 'password'}
                onChange={formik.handleChange}
                value={formik.values.newPassword}
                name="newPassword"
                helperText={formik.errors.newPassword}
                error={Boolean(formik.errors.newPassword)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowNP(!showNP)}>
                        {showNP ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Repita la nueva contraseña"
                variant="outlined"
                size="small"
                fullWidth
                type={showRP ? 'text' : 'password'}
                onChange={formik.handleChange}
                value={formik.values.confirmNewPassword}
                name="confirmNewPassword"
                helperText={formik.errors.confirmNewPassword}
                error={Boolean(formik.errors.confirmNewPassword)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowRP(!showRP)}>
                        {showRP ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginY={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            endIcon={
              loading && (
                <CircularProgress style={{ color: '#FFF' }} size={20} />
              )
            }
          >
            {loading ? 'Actualizando ' : 'Actualizar'}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ChangePassword;
