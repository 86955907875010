import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  containerTab:{
    padding: 12
  },
  avatar:{
    marginRight: 8,
    width: 30,
    height: 30, 
    background: '#DD9835',
  }, 
  containerCollapse:{
    padding: 0, 
    border: 'none', 
  }, 
  isOpen:{
    background: '#F7F7F7'
  }, 
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "&:hover":{
      color: '#fff'
    }
  },
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: '8px 0',
  }, 
  cardHeader:{
    display:'flex', 
    justifyContent:'space-between'
  }, 
  title:{
    fontWeight:'bold'
  }, 
  search:{
    width:'30%'
  },
  tabSmall:{
    fontWeight:900,
    minWidth: 50,
    textTransform:'capitalize', 
    fontSize:14
  },
  tabsChild:{
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }, 
  tableGrid:{
    height: 300,  
    marginTop:24,      
  },
  dataGrid:{
    minHeight: 400,
    border:'none', 
    display:'flex',
    margin: '12px 0',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator":{
      display:'none'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14, 
    }, 
    "& .MuiDataGrid-columnsContainer":{
      justifyContent: 'center'
    }
  },
  headerTableGrid:{
    fontSize:14, 
    color: '#AFAFAF',
    fontFamily:'Roboto',
  },
  viewBtn:{
    background:theme.palette.success.main,
    color: '#fff',
    fontWeight:'bold',
    '&:hover': {
      background: theme.palette.success.dark,
    },
  },
}))

export default useStyles