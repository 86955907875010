import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  breadContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "8px 0",
  },
  contentCard: {
    width: "100%",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    color: "#767676",
    padding: "6px 0",
  },
  userNameContainer: {
    display: "flex",
    margin: "0px 20px",
  },
  avatarUser: {
    width: 100,
    height: 100,
    fontWeight: "bolder",
    fontSize: 24,
  },
  containerUserInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#767676",
    marginLeft: 30,
  },
  boldText: {
    fontWeight: "bolder",
  },
  dataUserContainer: {
    margin: 12,
  },
  listContainer: {
    color: theme.palette.primary.main,
  },
  itemListData: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 0",
    "& .MuiTypography-root": {
      color: "#767676",
    },
  },
  itemList: {
    display: "flex",
    justifyContent: "space-between",
    color: "#767676",
    padding: "8px 0",
  },
  itemChild: {
    fontWeight: "bolder",
  },
  footerActions: {
    display: "flex",
    justifyContent: "center",
    marginTop: 12,
  },
  switch: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      background: theme.palette.success.main,
      opacity: 1,
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "#fff",
    },
  },
  listHorizontalContainer: {
    display: "flex",
  },
  titleHeader: {
    color: "#767676",
    fontWeight: "bolder",
    padding: "6px 0",
  },
  itemsCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#767676",
    textAlign: "center",
  },
  activityContainer: {
    margin: "20px 0",
  },
  itemGrey: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  itemWhite: {
    display: "flex",
    justifyContent: "space-evenly",
    color: "#767676",
  },
  dialogContainer: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "20px",
    },
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "center",
    padding: 16,
    marginBottom: 12,
  },
}));

export { useStyles as default };
