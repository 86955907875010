import React from 'react';
import FlyerMobile from 'static/img/mobileApp.png';
import { Typography, Divider, Box } from '@material-ui/core';
import useStyles from './styles';

const MobileApp = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography variant="h6" className={classes.title}>
          Aplicación Móvil
        </Typography>
      </Box>
      <Divider />
      <div className={classes.imgContainer}>
        <img src={FlyerMobile} className={classes.img}></img>
      </div>
    </Box>
  );
};

export default MobileApp;
