import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  alignText: {
    textAlign: 'center',
  },
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px',
    }
  },
  loadingDialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '25px',
      alignItems: 'center',
      padding: '30px',
      background: 'transparent',
      boxShadow: 'none',
    }
  },
  
  cardItem: {
    width: 120,
    height: 150,
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      },
    },
  },

  cardSelected: {
    backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      }
  },
  
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },

  cardActionArea: {
    height: '100%',
  },

  roleIcon: {
    fontSize: 45,
    color: '#767676',
    margin: '0px 20px',
  },

  mainText: {
    color: '#767676',
    fontSize: 13,
    fontWeight: 'bolder',
    textAlign: 'center'
  },

  secondaryText: {
    color: '#AFAFAF',
    fontSize: 14,
  },

  fontTitle: {
    fontWeight: 'bold',
  } ,
  fontContent: {
    color: '#AFAFAF',
    fontSize: '16px',
  }, 
  spacing:{
    marginLeft: '50px'
  },
  sizeImage: {
    width: '240px',
    height: '176px'
  },
  gridMargin:{
    marginBottom: '40px'
  },
  liBusiness:{
    float: 'left', 
    marginRight: 15,
    listStyle: 'none'
  },
  liDriver: {
    float: 'left', 
    marginRight: 15,
    listStyle: 'none'
  },
  liVehicle:{
    float: 'left', 
    listStyle: 'none'
  },
  subTitileColor:{
    color: '#AFAFAF',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  colorHr: {
    borderColor: '#E2E2E2'
  },
  loadingColor: {
    color: '#dd9835'
  },
  subTitleGray:{
    width:'100%',
    fontWeight: 600,
    margin: '24px 0px', 
    color:'#AFAFAF',
    borderBottom: '1px solid #eaeaf0',
    paddingLeft:12, 
  },
}))

export { useStyles as default }