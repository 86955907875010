import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import _ from 'lodash';

import { getDateRange, getDetails } from 'api/entranceReportService';

import useSetToken from 'lib/hooks/useSetToken';
import ID from 'lib/helpers/random-id';

import EntranceTable from 'components/EntranceTable';
import Loader from 'components/Loader';

import withSearchLayout, { WrappedComponentProps } from 'hoc/withSearchLayout';

import { ReportFilter, MyDoc } from '../../Reports/ReportFilter';

const Search = ({ ...props }: WrappedComponentProps) => {
  const {
    pagination,
    values: { initialDate, endDate, searchByLote },
    states: { loading, results, reportData, disableReport },
  } = props;
  const { selectedUserType, user } = useSelector((state: any) => state.user);
  const token = useSelector((state: any) => state.user.token);
  const setToken = useSetToken();
  const history = useHistory();

  const [founding, setFounding] = useState(false);

  useEffect(() => {
    if (initialDate && endDate) {
      getDateRange(
        {
          fechaInicial: dayjs(initialDate).format('YYYY-MM-DDT00:00:00-05:00'),
          fechaFinal: dayjs(endDate).format('YYYY-MM-DDT23:59:59-05:00'),
          idUsuario: user.id,
          tipoUsuario: selectedUserType.name,
          valorFiltro: '',
          tipoFiltro: 'Ninguno',
          idPLanta: 6,
        },
        token
      )
        .then((data) => {
          loading.set(false);
          const result = data.Data;
          const processedInfo = _.map(
            _.groupBy(result.detalleDelReportes, 'nombreGranja'),
            (item, name) => ({
              _id: ID(),
              name,
              farmer: item[0].nombreProductor,
              totalLotes: item.length,
              totalAnimals: item.reduce((a, b) => a + b.totalAnimales, 0),
              average: result.promedioGranjaProductors.find(
                (el: any) => el.nombreGranja === item[0].nombreGranja
              ).pesoPromedio,
              data: item,
            })
          );
          disableReport.set(false);
          reportData.set((prev: any) => ({
            ...prev,
            loaded: false,
            results: processedInfo,
            url: {
              data: result,
              details: results.get,
            },
          }));
          results.set(processedInfo);
          setToken(data.token.tokenNuevo);
        })
        .catch((err) => {
          loading.set(false);
          console.error(err);
        });
    }
    if (searchByLote.isValid) {
      loading.set(true);
      getDetails(
        {
          codigoLote: +searchByLote.value,
          idLoteIP: 0,
          seccion: 'datos porteria',
          idUsuario: user.id,
          tipoUsuario: selectedUserType.name,
        },
        token
      ).then(async (data) => {
        if (data.StatusCode === 200) {
          loading.set(false);
          setFounding(true);
          setTimeout(
            () =>
              history.push(`/app/reports/entrance/details/${data.Data.LoteID}`),
            2000
          );
        }
      });
    }
  }, [initialDate, endDate]);

  return (
    <>
      <Loader visible={founding}>
        Se encontro un resultado, redireccionando
      </Loader>
      <EntranceTable data={pagination.data} />
    </>
  );
};

export default withSearchLayout(Search, {
  ReportComponent: MyDoc,
  SchemasComponent: ReportFilter,
  pictureIds: ['barcode'],
  breadcrumbs: [
    { link: '/app/reports/entrance', title: 'Portería' },
    { link: '/app/reports', title: 'Mis Reportes' },
  ],
});
