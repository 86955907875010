import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  contentCard: {
    width: '100%',
    padding: '24px !important',
  },
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: 5
  },
  contenText:{
    alignSelf: 'center',
    marginLeft: '20px'
  },
  nameInfo:{
    fontWeight: 'bolder',
  },
  ubicationInfo:{
    color: '#AFAFAF',
    textAlign: 'center'
  },
  listContainer: {
    display: 'flex',
    margin: 0,
    flexDirection: 'column',
    color: theme.palette.primary.main,
    '&.column': {
      flexDirection: 'column',
    },
    '&.row': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  itemListData: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    "& .MuiTypography-root": {
      color: "#767676",
    }
  },
  switch: {
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      background: theme.palette.success.main,
      opacity: 1
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "#fff"
    }
  },
  tabsContainer: {
    alignItems: 'center',
    [theme.breakpoints.between( 600, 1350)]: {
      maxWidth: '280px',
    },
    [theme.breakpoints.between( 1350, 1790)]: {
      maxWidth: '475px',
    },
    [theme.breakpoints.between( 1790, 2050)]: {
      maxWidth: '93%',
    },
    "& .MuiTabs-indicator": {
      display: 'none'
    },
    "& .MuiTabScrollButton-root":{
      background: theme.palette.primary.main,
      borderRadius: '50px',
      width: '40px',
      height: '40px',
      alignItems: 'center',
      color: 'white',
      "& .MuiSvgIcon-fontSizeSmall":{
        fontSize: '25px'
      }
    }
  },
  tabContainer: {
    cursor: 'pointer',
    minHeight: '110px',
  },
  placaSelected: {
    transform: 'translateY(-8px)',
    transition: 'transform 300ms',
  },
  fontPlateColor:{
    color: theme.palette.primary.main,
    marginLeft: 10
  },
  driverItem: {
    width: 'fit-content',
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 10,
    borderTopRightRadius: 10,
    marginBottom: 15,
    padding: 3,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15
  },
  driverItemName:{
    color: '#767676',
    fontWeight: 'bold'
  },
  driverItemDocument:{
    color: '#AFAFAF',
  },
  gridContainer:{
    display:'block'
  }
}))
export { useStyles as default }