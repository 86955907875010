import React from 'react';
import { Typography, Box } from '@material-ui/core';

const textHabeas = () => {
  return (
    <div>
      <Typography variant="h6" color="secondary" align="center">
        SOBRE CÓMO MERCADEO VIRTUAL S.A. EN LA PLATAFORMA{' '}
        <a
          href="http://www.infoporcinos.com/sitio/Principal.aspx"
          target="_blank"
        >
          WWW.INFOPORCINOS.COM
        </a>{' '}
        HARA EL TRATAMIENTO DE LA TITULARIDAD Y TIPOS DE DATOS PERSONALES DE LOS
        USUARIOS.
      </Typography>
      <Box
        width="50%"
        display="flex"
        justifyContent="space-between"
        margin="12px auto"
      >
        <Typography variant="subtitle2">
          Fecha de versión: 01 Julio 2022
        </Typography>
        <Typography variant="subtitle2">Código: 2022 - TRATADATA</Typography>
      </Box>

      <Box margin="12px 0px">
        <Typography style={{ padding: '12px 0px' }}>
          <ol style={{ listStyle: 'upper-latin' }}>
            <li>
              Reconoce en todo momento, el derecho al Habeas Data como el
              derecho que tiene toda persona para conocer, actualizar y
              rectificar toda aquella información que se relacione con ella y
              que se recopile o almacene en bases de datos, de entidades
              públicas o privadas. Tomando para tal fin una serie de opciones
              técnicas e informáticas donde el TITULAR pueda estar informado
              sobre sus datos registrados según la legislación sobre este tema.
            </li>
            <br />

            <li>
              <strong>MERCADEO VIRTUAL S.A.</strong> se declara:{' '}
              <strong>OPERADOR DE LA INFORMACIÓN </strong>, en términos de la
              Ley 1266 de 2008, es decir, la organización que recibe la fuente
              de datos personales sobre varios titulares de la información, los
              administra y los pone en conocimiento de los usuarios bajo los
              parámetros de la Ley y las buenas prácticas en el manejo y
              seguridad de los Datos Personales.
              <strong> MERCADEO VIRTUAL S.A.</strong> se declara como un
              <strong> INFOMEDIARIO</strong> entre: la Planta de Beneficio de
              Porcinos CENTRAL GANADERA S.A., los Productores de Cerdos en Pie y
              los Comercializadores (Compradores de Cerdos en Pie) usuarios /
              clientes de la Planta de Beneficio, para tal fin{' '}
              <strong>MERCADEO VIRTUAL S.A.</strong> define de forma exclusiva
              que utilizara la Plataforma de información{' '}
              <strong>InfoPorcinos™</strong> (
              <a
                href="http://www.infoporcinos.com/sitio/Principal.aspx"
                target="_blank"
              >
                www.infoporcinos.com{' '}
              </a>
              ) para informar a su Usuarios / Titulares de los Datos Personales.
            </li>
            <br />
            <li>
              Define y declara que los <strong>TITULARES DE LOS DATOS</strong>{' '}
              de forma individual: A la Planta de Sacrificio de Porcinos CENTRAL
              GANADERA S.A., el Productor de los Animales en Pie y al
              Comercializador. Entendiéndose como la(s) persona(s) natural(es) o
              jurídica(s) a quien(es) se refiere la información que reposa en la
              Base de Datos y sujetos al derecho de Habeas Data. La TITULARIDAD
              de los Datos Personales y Técnicos surgen de la utilización y
              contratación de los Servicios de Beneficio y Faenado de un Lote de
              Animales en pie (Cerdos) que es prestado por la Planta de
              Beneficio CENTRAL GANADERA S.A. quien en su actividad comercial,
              requiere del registro y el manejo de los Datos Personales de sus
              Clientes y los terceros relacionados (Productores,
              Transportadores, entre otros) desde que ingresan a la Planta de
              Beneficio como Animales en Pie hasta su Salida de la Planta de
              Beneficio como Canales Calientes, Refrigeradas, Vísceras Rojas y
              Subproductos objeto del contrato de servicio de la Planta.
            </li>
            <br />
            <li>
              Define y declara <strong>USUARIOS</strong> de forma individual: A
              la Planta de Sacrificio CENTRAL GANADERA S.A., el Productor de los
              Animales en Pie y al Comercializador (comprador de dichos Animales
              en Pie y Usuario del servicio de Beneficio Animal). Entendiéndose
              que a la(s) persona(s) natural(es) o jurídica(s) que puede(n)
              acceder a información personal de uno o varios titulares de la
              información suministrada por{' '}
              <strong>MERCADEO VIRTUAL S.A.</strong> a través de la Plataforma{' '}
              <strong>InfoPorcinos™</strong> (
              <a
                href="http://www.infoporcinos.com/sitio/Principal.aspx"
                target="_blank"
              >
                www.infoporcinos.com{' '}
              </a>
              ) o la fuente, o directamente por el titular de la información
              bajo autorización expresa del Titular como lo expresa la
              legislación y procesos del Habeas Data.
            </li>
            <br />
            <li>
              <strong>MERCADEO VIRTUAL S.A.</strong> como el OPERADOR DE LA
              INFORMACIÓN define que todos los DATOS que se recolecten en los
              procesos de sacrificio y faenado al interior de la Planta de
              Beneficio Animal, en este caso CENTRAL GANADERA S.A., serán
              definidos como <strong>DATOS SEMI PRIVADOS</strong> (el dato que
              no tiene naturaleza íntima, reservada ni pública y cuyo
              conocimiento o divulgación puede interesar no sólo a su titular
              sino a un sector de personas) y <strong>DATOS PRIVADOS</strong>{' '}
              (aquel dato de naturaleza íntima y reservada, que sólo es
              relevante para el titular). Ante dicha consideración legal ninguno
              de los Usuarios de la Plataforma
              <strong> InfoPorcinos™</strong> podrá compartir con terceros los
              datos sin el consentimiento informado de las partes relacionadas
              de forma expresa.
            </li>
            <br />
            <li>
              Define que, ante la naturaleza de los DATOS, requiere realizar de
              una AUTORIZACIÓN ESCRITA O DIGITAL de parte del TITULAR en el caso
              de que la información referente a los DATOS que se dispongan
              almacenados en la Plataforma de Información{' '}
              <strong>InfoPorcinos™ </strong>(
              <a
                href="http://www.infoporcinos.com/sitio/Principal.aspx"
                target="_blank"
              >
                www.infoporcinos.com{' '}
              </a>
              ) bajo su nombre titular, se utilicen para otro objetivo diferente
              al del registro o de tipo de Investigación Académica, Desarrollo o
              Innovación (I+D+i).
            </li>
            <br />
            <li>
              Una vez compartidos, revelados o cedidos por cualquier medio, los
              datos con un tercero por parte de alguno de los Titulares, exime,
              excluye y exonera a <strong>MERCADEO VIRTUAL S.A.</strong> de
              cualquier tipo de responsabilidad civil, comercial o penal sobre
              irregularidades o faltas al manejo de los Datos Personales y a la
              vulneración de la confidencialidad de los datos definidos en la
              legislación Colombiana e internacional.
            </li>
            <br />
            <li>
              Bajo ningún tipo de caso o justificación los DATOS (archivos
              digitales o reportes impresos) de un TITULAR, pueden ser
              comercializados o conocidos por un tercero sin previa autorización
              de los otros Titulares de los Datos relacionados.
            </li>
            <br />
          </ol>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <strong>
            <strong>MERCADEO VIRTUAL S.A.</strong>
          </strong>
          <br />
          Calle 48 No. 77 – 78 Sector Estadio Velódromo <br />
          Zona Postal 050034
          <br /> Medellín – Colombia <br />
          Pbx + 57 604 3228603
          <br />
          <a href="http://www.mercadeo-virtual.com" target="_blank">
            www.mercadeo-virtual.com
          </a>{' '}
          /{' '}
          <a
            href="http://www.infoporcinos.com/sitio/Principal.aspx"
            target="_blank"
          >
            www.infoporcinos.com
          </a>
          <br />
          Correo: info@infoporcinos.com
        </Box>
        <Box marginRight={2}>
          <img src="https://raw.githubusercontent.com/repositoriosmv/infoporcinos-images/main/logo-mv1.png" />
        </Box>
      </Box>
    </div>
  );
};

export default textHabeas;
