import React, { useEffect } from 'react';
import {
  Typography,
  Container,
  Grid,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import Chart from 'components/Chart';
import dayjs, { Dayjs } from 'dayjs';
import PinCheckbox from './../../../../components/PinCheckbox/index';
import FilterReports from 'components/FilterReports';

const index = () => {
  const classes = useStyles();

  const [pcc, setPcc] = React.useState('weekly');
  const [measurement, setMeasurement] = React.useState('weekly');

  const handleChangePcc = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPcc((event.target as HTMLInputElement).value);
  };

  const handlechangeMeasurement = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMeasurement((event.target as HTMLInputElement).value);
  };

  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/services', title: 'Informes y Estadísticas' },
  ];

  return (
    <Container className={classes.root}>
      <div className={classes.breadContainer}>
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard>
        <FilterReports to="app/services/resumen-pessage/" />
      </ContentCard>

      <Grid container spacing={4} direction="row">
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Resumen General Medición
                  </Typography>
                  {measurement === 'today' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('dddd, D MMMM YYYY')}
                    </Typography>
                  ) : measurement === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : measurement ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().week() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>

                <RadioGroup
                  aria-label="timer"
                  name="timer3"
                  value={measurement}
                  onChange={handlechangeMeasurement}
                >
                  <div className={classes.centerContainer}>
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="today"
                      control={<Radio color="primary" />}
                      label="Hoy"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="weekly"
                      control={<Radio color="primary" />}
                      label="Semanal"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="monthly"
                      control={<Radio color="primary" />}
                      label="Mensual"
                    />
                  </div>
                </RadioGroup>

                {measurement === 'today' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          200
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Canales Pesadas
                      </Typography>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          420
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Lotes Pesados
                      </Typography>
                    </div>
                  </div>
                ) : measurement === 'monthly' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          220
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Canales Pesadas
                      </Typography>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          460
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Lotes Pesados
                      </Typography>
                    </div>
                  </div>
                ) : measurement ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          260
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Canales Pesadas
                      </Typography>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          480
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Lotes Pesados
                      </Typography>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Gráfica Peso Canal Caliente (PCC)
                  </Typography>
                  {pcc === 'today' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('dddd, D MMMM YYYY')}
                    </Typography>
                  ) : pcc === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : pcc ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().week() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>

                <RadioGroup
                  aria-label="timer"
                  name="timer3"
                  value={pcc}
                  onChange={handleChangePcc}
                >
                  <div className={classes.centerContainer}>
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="today"
                      control={<Radio color="primary" />}
                      label="Hoy"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="weekly"
                      control={<Radio color="primary" />}
                      label="Semanal"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="monthly"
                      control={<Radio color="primary" />}
                      label="Mensual"
                    />
                  </div>
                </RadioGroup>
                {pcc === 'today' ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div>
                      <Chart
                        type="bar"
                        data={[
                          {
                            label: 'Promedio Pesaje',
                            data: [1, 1, 2, 2, 60, 20, 5],
                            bg: '#E29662',
                          },
                        ]}
                        weightBar={45}
                        graphicSize={600}
                        labels={[
                          '70/80 Kg',
                          '80/90 Kg.',
                          '90/100 Kg.',
                          '100/110 Kg.',
                          '110/120 Kg.',
                          '120/130 Kg.',
                          '130/140 Kg.',
                        ]}
                        max={400}
                        position={'top'}
                      />
                    </div>
                  </div>
                ) : pcc === 'monthly' ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div>
                      <Chart
                        type="bar"
                        data={[
                          {
                            label: 'Promedio Pesaje',
                            data: [4, 4, 40, 40, 340, 120, 40],
                            bg: '#E29662',
                          },
                        ]}
                        weightBar={45}
                        graphicSize={600}
                        labels={[
                          '70/80 Kg',
                          '80/90 Kg.',
                          '90/100 Kg.',
                          '100/110 Kg.',
                          '110/120 Kg.',
                          '120/130 Kg.',
                          '130/140 Kg.',
                        ]}
                        max={400}
                        position={'top'}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div>
                      <Chart
                        type="bar"
                        data={[
                          {
                            label: 'Promedio Pesaje',
                            data: [1, 1, 10, 10, 320, 80, 10],
                            bg: '#E29662',
                          },
                        ]}
                        weightBar={45}
                        graphicSize={600}
                        labels={[
                          '70/80 Kg',
                          '80/90 Kg.',
                          '90/100 Kg.',
                          '100/110 Kg.',
                          '110/120 Kg.',
                          '120/130 Kg.',
                          '130/140 Kg.',
                        ]}
                        max={400}
                        position={'top'}
                      />
                    </div>
                  </div>
                )}
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Promedio General PCC
                  </Typography>
                </div>

                <div className={classes.flexContainer}>
                  <div
                    className={clsx(
                      classes.centerContainer,
                      classes.centerContainerColumn
                    )}
                  >
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          110.50
                        </Typography>
                        <Typography className={classes.labelNumberBar}>
                          {' ‏‏‎ Kg'}
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        {'Promedio PCC (Propio)'}
                      </Typography>
                    </div>
                  </div>
                  <div
                    className={clsx(
                      classes.centerContainer,
                      classes.centerContainerColumn
                    )}
                  >
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          110.50
                        </Typography>
                        <Typography className={classes.labelNumberBar}>
                          {' ‏‏‎Kg'}
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Promedio PCC (Sistema Infoporcinos)
                      </Typography>
                    </div>
                  </div>
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Promedio General PCC por Granja
                  </Typography>
                </div>

                <div className={classes.flexContainer}>
                  <div
                    className={clsx(
                      classes.centerContainer,
                      classes.centerContainerColumn
                    )}
                  >
                    <Typography className={classes.labelNumberBar}>
                      Nombre de la granja
                    </Typography>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          110.50
                        </Typography>
                        <Typography className={classes.labelNumberBar}>
                          {' ‏‏‎ Kg'}
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        {'Promedio PCC'}
                      </Typography>
                    </div>
                  </div>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default index;
