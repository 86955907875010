import * as yup from 'yup';
import messages from './messages';

export const GeneralDataCompany = yup.object({
  //Validaciones Datos Generales Empresa
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
  razonSocial: yup.string().required(messages.REQUIRED_FIELD),
  primerNombreRL: yup.string().required(messages.REQUIRED_FIELD),
  primerApellidoRL: yup.string().required(messages.REQUIRED_FIELD),

  indicativoCelularRL: yup.string().required(messages.REQUIRED_FIELD),
  celularRL: yup
    .number()
    .typeError(messages.ONLY_NUMBERS)
    .required(messages.REQUIRED_FIELD),
  emailRL: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
  apendiceCruce: yup.string().max(10, 'Límite de caracteres excedido'),
  apendiceVia: yup.string().max(10, 'Límite de caracteres excedido'),

  metrosEsquina: yup
    .number().when('idTipoDireccion',  {is: (value: any) => Number(value) === 1,
      then: yup.number().typeError(messages.ONLY_NUMBERS)
      .required(messages.REQUIRED_FIELD)
    }),
      indicativoCelular: yup.string().required(messages.REQUIRED_FIELD),
  celular: yup
    .number()
    .typeError(messages.ONLY_NUMBERS)
    .required(messages.REQUIRED_FIELD),
});

export const GeneralDataPerson = yup.object({
  //Validaciones Datos Generales Persona
  idTipoDocumento: yup
    .number()
    .typeError(messages.ONLY_NUMBERS)
    .required(messages.ONLY_NUMBERS),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
  indicativoTelefono: yup.string().required(messages.REQUIRED_FIELD),
  indicativoCelular: yup.string().required(messages.REQUIRED_FIELD),
  primerNombre: yup.string().required(messages.REQUIRED_FIELD),
  primerApellido: yup.string().required(messages.REQUIRED_FIELD),
  numeroVia: yup.string().required(messages.REQUIRED_FIELD),
  numeroCruce: yup.string().required(messages.REQUIRED_FIELD),
  metrosEsquina: yup.string().required(messages.REQUIRED_FIELD),
  celular: yup
    .number()
    .typeError(messages.ONLY_NUMBERS)
    .required(messages.ONLY_NUMBERS),
});

export const FarmRegister = yup.object({
  name: yup.string().required(messages.REQUIRED_FIELD),
  farmEmail:  yup.string().email(messages.INVALID_EMAIL_ADDRESS).required(messages.REQUIRED_FIELD),
  
  idDocument: yup
    .number().when("idResponsable", {
      is: (value: number) => value === 0,
      then: yup.number().typeError(messages.ONLY_NUMBERS)
    .required(messages.REQUIRED_FIELD),
    }), 
  documentNumber: yup
    .number().when("idResponsable", {
      is: (value: number) => value === 0,
      then: yup.number().typeError(messages.ONLY_NUMBERS)
    .required(messages.REQUIRED_FIELD),
    }), 

  email: yup
    .string().when("idResponsable", {
      is: (value: number) => value === 0,
      then: yup.string().email(messages.INVALID_EMAIL_ADDRESS)
      .required(messages.REQUIRED_FIELD),
    })
,

  firstName: yup.string().when('idResponsable',{
    is: (value: number) => value === 0,
    then: yup.string().required(messages.REQUIRED_FIELD)}
  ),
  firstLastname: yup.string().when('idResponsable',{
    is: (value: number) => value === 0,
    then: yup.string().required(messages.REQUIRED_FIELD)}
  ),
  phone: yup
    .string()
    .required(messages.REQUIRED_FIELD),
  
});

export const FarmEdit = yup.object({
  nombreGranja: yup.string().required(messages.REQUIRED_FIELD),
  idTipoDocumento: yup
    .number().when('idPersonaResponsable',{
      is: (value: number) => value === 0,
      then: yup.number().typeError(messages.ONLY_NUMBERS)
      .required(messages.REQUIRED_FIELD)}
    ),
  numeroDocumento: yup
    .number().when('idPersonaResponsable',{
      is: (value: number) => value === 0,
      then: yup.number().typeError(messages.ONLY_NUMBERS)
      .required(messages.REQUIRED_FIELD)}
  ),
  emailResponsable: yup
    .string().when('idPersonaResponsable',{
      is: (value: number) => value === 0,
      then: yup.string().email(messages.INVALID_EMAIL_ADDRESS)
      .required(messages.REQUIRED_FIELD)}
    ),
  primerNombre: yup.string().when('idPersonaResponsable',{
    is: (value: number) => value === 0,
    then: yup.string().required(messages.REQUIRED_FIELD)}
  ),
  primerApellido: yup.string().when('idPersonaResponsable',{
    is: (value: number) => value === 0,
    then: yup.string().required(messages.REQUIRED_FIELD)}
  ),
  numeroCelular: yup
    .string()

    .required(messages.REQUIRED_FIELD),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
});

export const DestinyRegister = yup.object({
  name: yup.string().required(messages.REQUIRED_FIELD),
  nit : yup.number().when('idDestinyType',  {is: (value: number) => value === 2 || value === 3,
    then: yup.number().typeError(messages.ONLY_NUMBERS)
    .required(messages.REQUIRED_FIELD)}),
  destinyEmail:  yup.string().email(messages.INVALID_EMAIL_ADDRESS).required(messages.REQUIRED_FIELD),
  
  idDocument: yup
    .number().when("idResponsable", {
      is: (value: number) => value === 0,
      then: yup.number().typeError(messages.ONLY_NUMBERS)
    .required(messages.REQUIRED_FIELD),
    }), 
  documentNumber: yup
    .number().when("idResponsable", {
      is: (value: number) => value === 0,
      then: yup.number().typeError(messages.ONLY_NUMBERS)
    .required(messages.REQUIRED_FIELD),
    }), 

  email: yup
    .string().when("idResponsable", {
      is: (value: number) => value === 0,
      then: yup.string().email(messages.INVALID_EMAIL_ADDRESS)
      .required(messages.REQUIRED_FIELD),
    })
,

  firstName: yup.string().when('idResponsable',{
    is: (value: number) => value === 0,
    then: yup.string().required(messages.REQUIRED_FIELD)}
  ),
  firstLastname: yup.string().when('idResponsable',{
    is: (value: number) => value === 0,
    then: yup.string().required(messages.REQUIRED_FIELD)}
  ),
  cellphone: yup
    .string()
    .required(messages.REQUIRED_FIELD),
  
});

export const DestinyEdit = yup.object({
  nombreDestino: yup.string().required(messages.REQUIRED_FIELD),
  nit : yup.number().when('idTipoDestino',  {is: (value: number) => value === 2 || value === 3,
  then: yup.number().typeError(messages.ONLY_NUMBERS)
  .required(messages.REQUIRED_FIELD)}),
  idTipoDocumento: yup
    .number().when('idPersonaResponsable',{
      is: (value: number) => value === 0,
      then: yup.number().typeError(messages.ONLY_NUMBERS)
      .required(messages.REQUIRED_FIELD)}
    ),

    numeroDocumento: yup
    .number().when('idPersonaResponsable',{
      is: (value: number) => value === 0,
      then: yup.number().typeError(messages.ONLY_NUMBERS)
      .required(messages.REQUIRED_FIELD)}
    ),

  emailResponsable: yup
    .string().when('idPersonaResponsable',{
      is: (value: number) => value === 0,
      then: yup.string().email(messages.INVALID_EMAIL_ADDRESS)
      .required(messages.REQUIRED_FIELD)}
    ),
  primerNombre: yup.string().when('idPersonaResponsable',{
    is: (value: number) => value === 0,
    then: yup.string().required(messages.REQUIRED_FIELD)}
  ),
  primerApellido: yup.string().when('idPersonaResponsable',{
    is: (value: number) => value === 0,
    then: yup.string().required(messages.REQUIRED_FIELD)}
  ),
  numeroCelular: yup
    .string()

    .required(messages.REQUIRED_FIELD),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
});

export const ChangePassword = yup.object().shape({
  actualPassword: yup.string().required('Este campo es requerido'),

  newPassword: yup
    .string()
    .test(
      'min-length',
      'Minímo 8 caracteres',
      (value: any) => value?.length >= 8
    )
    .test(
      'pass-uppercase',
      'Debe contener almenos una mayúscula',
      (value: any) => {
        let nMay = 0;
        const t1 = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
        for (let index = 0; index < value?.length; index++) {
          if (t1.indexOf(value.charAt(index)) !== -1) {
            nMay++;
          }
        }
        return nMay > 0 ? true : false;
      }
    )
    .test(
      'pass-lowercase',
      'Debe contener almenos una minúscula',
      (value: any) => {
        let nMin = 0;
        const t2 = 'abcdefghijklmnñopqrstuvwxyz';
        for (let index = 0; index < value?.length; index++) {
          if (t2.indexOf(value.charAt(index)) !== -1) {
            nMin++;
          }
        }
        return nMin > 0 ? true : false;
      }
    )
    .test('pass-number', 'Debe contener al menos un número', (value: any) => {
      let nNum = 0;
      const t3 = '0123456789';
      for (let index = 0; index < value?.length; index++) {
        if (t3.indexOf(value.charAt(index)) !== -1) {
          nNum++;
        }
      }
      return nNum > 0 ? true : false;
    })
    .matches(/[a-zA-Z0-9]/, {
      message: 'No se permiten caracteres especiales ni espacios',
    })
    .required('Este campo es obligatorio'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Las contraseñas deben coincidir')
    .required('Este campo es obligatorio'),
});

export const OtherValidation = yup.object().shape({
  selector: yup.string().required('Seleccione un motivo para dar de baja'),
});