export default {
  dataSource: [
    {
      id: 1,
      data: { 1: 'Utiliza las actions del table para poder usarla correctamente' }
    }
  ],
  tableHeaders: [
    { key: '1', title: 'Default Header', options: { collapsing: false, sorted: false, textAlign: 'left' } }
  ],
  options: {}
}
