import { Document, Page, Image, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import mobilizationLogo from 'static/img/reportsIcons/mobilizationLogo';
import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';

import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import ItemTotal from 'components/pdf-components/ItemTotal';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import DetailGSMITable from 'components/pdf-components/DetailGSMITable/index';

import styles from './styles/lote';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const detalles = [
  {
    fecha: '10/11/2019',
    nroGuia: '011 - 0786692',
    lote: 1234,
    origen: 'Nombre de la Granja',
    placa: 'SBE 195',
    conductor: 'Nombre del Conductor',
    totalAnimales: 52,
  },
  {
    fecha: '10/11/2019',
    nroGuia: '011 - 0786692',
    lote: 1234,
    origen: 'Nombre de la Granja',
    placa: 'SBE 195',
    conductor: 'Nombre del Conductor',
    totalAnimales: 52,
  },
  {
    fecha: '10/11/2019',
    nroGuia: '011 - 0786692',
    lote: 1234,
    origen: 'Nombre de la Granja',
    placa: 'SBE 195',
    conductor: 'Nombre del Conductor',
    totalAnimales: 52,
  },
  {
    fecha: '10/11/2019',
    nroGuia: '011 - 0786692',
    lote: 1234,
    origen: 'Nombre de la Granja',
    placa: 'SBE 195',
    conductor: 'Nombre del Conductor',
    totalAnimales: 52,
  },
  {
    fecha: '10/11/2019',
    nroGuia: '011 - 0786692',
    lote: 1234,
    origen: 'Nombre de la Granja',
    placa: 'SBE 195',
    conductor: 'Nombre del Conductor',
    totalAnimales: 52,
  },
];

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
  range?: null | number | string;
}

export const MyDocGSMI = ({ qr, uid, range }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        range={range}
        reportImage={mobilizationLogo}
        number="023456789"
        title="GSMI - Guía Sanitaria Movilización Animales"
        code="MI-001"
      />
      <Subtitle
        title="Resumen de Movilización Animales"
        widthContainer="100%"
      />

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="13"
            info="Total Guías de Movilización"
            position="right"
            textAlign="left"
            backgroundColor="#ffc676"
            widthInfo="55px"
            widthCircle={30}
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="8"
            info="Granjas (Origen)"
            position="right"
            textAlign="left"
            backgroundColor="#e29662"
            widthInfo="60px"
            widthCircle={25}
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="1"
            info="Destino Central Ganadera S.A."
            position="right"
            textAlign="left"
            backgroundColor="#d47d57"
            widthInfo="60px"
            widthCircle={25}
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="620"
            info="Total Animales Movilizados"
            position="bottom"
            widthValue={60}
            widthInfo="85px"
            fontSizeValue={12}
            backgroundColor="#be5948"
          />
        </View>
      </View>

      <Subtitle title="Lista de Guías de Movilización" widthContainer="100%" />

      <View>
        <DetailGSMITable
          razonSocial="Intercomercializadora Meat Packing S.A.S"
          totalLotes={6}
          totalAnimales={324}
          data={detalles}
        />
        <DetailGSMITable
          razonSocial="Intercomercializadora Meat Packing S.A.S"
          totalLotes={6}
          totalAnimales={324}
          data={detalles}
        />
        <DetailGSMITable
          razonSocial="Intercomercializadora Meat Packing S.A.S"
          totalLotes={6}
          totalAnimales={324}
          data={detalles}
        />
      </View>

      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportFilter = () => {
  return (
    <div id="screenQrGSMI" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte GSMI - Guía Sanitaria Movilización Animales"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
