import { makeStyles } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  fontTitle: {
    fontWeight: "bold",
    marginRight: "20px",
  },
  dataGrid: {
    border: "none",
    "& .MuiDataGrid-columnSeparator": {
      color: "transparent",
    },
    "& .MuiDataGrid-colCellTitle": {
      color: "#AFAFAF",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },

    "& .MuiDataGrid-cell:focus-within": {
      outline: "none",
      outlineOffset: "none",
    },

    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "transparent",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      textAlign: "center",
      whiteSpace: "break-spaces",
      lineHeight: "normal",
      color: "#AFAFAF",
      fontWeight: "bold",
      fontSize: 14,
    },
  },
  colorHr: {
    borderColor: "#E2E2E2",
  },
  fontColor: {
    color: "#AFAFAF",
  },
  textAling: {
    display: "flex !important",
    justifyContent: "center !important",
  },
  colorChip: {
    backgroundColor: "#767676 !important",
    color: "white",
    "& .MuiChip-labelSmall": {
      textDecoration: "none",
      color: "white",
    },
  },
  tabSmall: {
    fontSize: "14px",
    textTransform: "capitalize",
    color: "#AFAFAF",
  },
  icon: {
    cursor: "pointer",
    color: "white",
    width: "15px",
    height: "15px",
  },
  fabIconAdd: {
    width: "35px",
    height: "0px",
    background: green[600],
    "&:hover": {
      backgroundColor: green[900],
    },
  },
  fabIconDelete: {
    width: "35px",
    height: "0px",
    background: red[600],
    "&:hover": {
      backgroundColor: red[900],
    },
  },
  btnDetails: {
    width: "35px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff !important",
  },
  buttonContainer: {
    width: "40px",
    height: "40px",
  },
  fontDocument: {
    fontWeight: "bolder",
    marginRight: "5px",
  },
  colorNoRow: {
    backgroundColor: "transparent !important",
  },
  heightContainer: {
    "& .MuiDataGrid-root.MuiDataGrid-autoHeight": {
      height: "200px",
    },
  },
  notDataContainer: {
    "& .MuiDataGrid-columnsContainer": {
      display: "none",
    },
  },
}));

export { useStyles as default };
