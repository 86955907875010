import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#F2F2F2',
    height: '100vh', 
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  content: {
    padding: theme.spacing(6),
    borderRadius: theme.spacing(3),
    overflow: 'hidden',
    animation: '$appear .3s ease-in-out'
  },
  image: {
    width: 400,
    transform: 'scaleX(-1)'
  },
  text_container: {
    textAlign: 'right',
    '& .MuiTypography-h1': {
      fontFamily: 'Raleway',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: theme.spacing(9),
      animation: '$appear-right .3s ease-in backwards',
      animationDelay: '.3s'
    },
    '& .MuiTypography-colorTextSecondary': {
      fontSize: theme.spacing(2),
      animation: '$appear-right .3s ease-in backwards',
      animationDelay: '.6s'
    },
    '& .MuiButton-containedPrimary': {
      marginTop: theme.spacing(3),
      animation: '$appear-right .3s ease-in backwards',
      animationDelay: '.9s',
      '& .MuiButton-label': {
        fontWeight: 'bold',
        fontSize: 14
      }
    }
  }
}))

export default useStyles