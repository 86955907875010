import { gql } from "apollo-boost";

export const FORWARD_INVITATIONS = gql`
  query {
    forwardInvitations{
      idInvitacion
      nombresCompletos
      tipoDocumento
      nroDocumento
      fechaInvitacion
      email
      tipoUsuario 
      numeroIntentos
      estadoInvitacion
      idEstadoInvitacion
    }
  }
`;
