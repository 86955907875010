import React from 'react';
import { Typography, Grid, Tooltip, Fab, Box, Button } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import useStyles from './styles';
import clsx from 'clsx';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import FilterReports from 'components/FilterReports';
import { Link } from 'react-router-dom';
import ItemListInfo from 'components/ItemListInfo';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import CurrentDateIndicator from 'components/CurrentDateIndicator';
import dayjs from 'dayjs';
import Results from 'components/Results';

const index = () => {
  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'list',
      headerName: 'Lista de Distribución',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'destinie',
      headerName: 'Destino',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'canalesEnviadas',
      headerName: 'Canales Enviadas',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'certificate',
      headerName: 'Nro. Certificado',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'news',
      headerName: 'Novedades',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: '',
      headerName: '',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/transport-sacrifice/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
    {
      id: 2,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
    {
      id: 3,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
    {
      id: 4,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
    {
      id: 5,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
    {
      id: 6,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/transport-sacrifice',
      title: 'Distribución y Despacho',
    },

    { link: '/app/reports', title: 'Mis Reportes' },
  ];

  const initDate = dayjs().startOf('month').format('YYYY-MM-DDT00:00:00-05:00');
  const current = dayjs().format('YYYY-MM-DDT23:59:59-05:00');

  return (
    <div>
      <div className={classes.breadContainer}>
        <CurrentDateIndicator initialDate={initDate} finalDate={current} />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <FilterReports to="/app/reports/transport-sacrifice/search" />

      <Grid container spacing={2} direction="row">
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Resumen Certiciado de Distribución y Despacho
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.paddingContainer
                  )}
                >
                  <div>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={65}
                      titleInfo="Total Canales Despachadas"
                      avatarTotalColor="#FFC676"
                      avatarTotalSize={60}
                      avatarTextSize={22}
                      titleInfoSize={250}
                    />
                  </div>
                  <div className={classes.width}>
                    <ItemListInfo
                      icon="icon-canal"
                      titleInfo="Canales Evaluadas"
                      numberInfo="65"
                    />
                    <ItemListInfo
                      icon="icon-map-marker"
                      titleInfo="Total Destinos"
                      numberInfo="6"
                    />
                    <ItemListInfo
                      icon="icon-document"
                      titleInfo="Total Certificados"
                      numberInfo="6"
                    />
                    <ItemListInfo
                      icon="icon-circle-alert"
                      titleInfo="Lotes con Novedad"
                      numberInfo="1"
                    />
                    <ItemListInfo
                      icon="icon-truck"
                      titleInfo="Número de Camiones"
                      numberInfo="4"
                    />
                  </div>
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Resumen Pesaje
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.columnWithoutCenter,
                    classes.paddingContainer
                  )}
                >
                  <div
                    className={clsx(
                      classes.flexContainer,
                      classes.chartContainer
                    )}
                  >
                    <div>
                      <ItemTotalInfo
                        position="right"
                        avatarTotalInfo={'PPB'}
                        titleInfo="Peso Porcentaje de Báscula"
                        avatarTotalColor="#FFC676"
                        avatarTotalSize={60}
                        avatarTextSize={22}
                        titleInfoSize={250}
                      />
                    </div>

                    <div className={classes.width}>
                      <ItemListInfo titleInfo="Pesados" numberInfo="45" />
                      <ItemListInfo
                        titleInfo="Total Pesados (Kg)"
                        numberInfo="7.009,00"
                      />
                      <ItemListInfo
                        titleInfo="Promedio (Kg)"
                        numberInfo="155,75"
                      />
                    </div>
                  </div>
                  <div
                    className={clsx(
                      classes.flexContainer,
                      classes.chartContainer
                    )}
                  >
                    <div>
                      <ItemTotalInfo
                        position="right"
                        avatarTotalInfo={'PCC'}
                        titleInfo="Peso Canal Caliente"
                        avatarTotalColor="#E29662"
                        avatarTotalSize={60}
                        avatarTextSize={22}
                        titleInfoSize={250}
                      />
                    </div>

                    <div className={classes.width}>
                      <ItemListInfo titleInfo="Pesados" numberInfo="45" />
                      <ItemListInfo
                        titleInfo="Total Pesados (Kg)"
                        numberInfo="6.009,00"
                      />
                      <ItemListInfo
                        titleInfo="Promedio (Kg)"
                        numberInfo="133,75"
                      />
                    </div>
                  </div>
                  <div
                    className={clsx(
                      classes.flexContainer,
                      classes.chartContainer
                    )}
                  >
                    <div>
                      <ItemTotalInfo
                        position="right"
                        avatarTotalInfo={'PCR'}
                        titleInfo="Peso canal Refrigerada"
                        avatarTotalColor="#BE5948"
                        avatarTotalSize={60}
                        avatarTextSize={22}
                        titleInfoSize={250}
                      />
                    </div>

                    <div className={classes.width}>
                      <ItemListInfo titleInfo="Pesados" numberInfo="45" />
                      <ItemListInfo
                        titleInfo="Total Pesados (Kg)"
                        numberInfo="5.845,00"
                      />
                      <ItemListInfo
                        titleInfo="Promedio (Kg)"
                        numberInfo="130,75"
                      />
                    </div>
                  </div>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <ContentCard className={classes.contentCard}>
                  <div className={classes.columnContainer}>
                    <Typography className={classes.titlePage}>
                      486.99 mm
                    </Typography>
                    <Typography className={classes.descriptionPage}>
                      Promedio Porcentaje de Magro (MC)
                    </Typography>
                  </div>
                </ContentCard>
              </Grid>
              <Grid item xs={6}>
                <ContentCard className={classes.contentCard}>
                  <div className={classes.columnContainer}>
                    <Typography className={classes.titlePage}>
                      36.39 mm
                    </Typography>
                    <Typography className={classes.descriptionPage}>
                      Promedio Lomo (LM)
                    </Typography>
                  </div>
                </ContentCard>
              </Grid>
              <Grid item xs={6}>
                <ContentCard className={classes.contentCard}>
                  <div className={classes.columnContainer}>
                    <Typography className={classes.titlePage}>
                      288.99 mm
                    </Typography>
                    <Typography className={classes.descriptionPage}>
                      Promedio Grasa Dorsal (GD)
                    </Typography>
                  </div>
                </ContentCard>
              </Grid>
              <Grid item xs={6}>
                <ContentCard className={classes.contentCard}>
                  <div className={classes.columnContainer}>
                    <Typography className={classes.titlePage}>
                      106.10 Kg
                    </Typography>
                    <Typography className={classes.descriptionPage}>
                      Promedio Pesaje (PCC)
                    </Typography>
                  </div>
                </ContentCard>
              </Grid>
              <Grid item xs={6}>
                <ContentCard className={classes.contentCard}>
                  <div className={classes.columnContainer}>
                    <Typography className={classes.titlePage}>
                      85,66 %
                    </Typography>
                    <Typography className={classes.descriptionPage}>
                      Rendimiento (RCCL %)
                    </Typography>
                  </div>
                </ContentCard>
              </Grid>
              <Grid item xs={6}>
                <ContentCard className={classes.contentCard}>
                  <div className={classes.columnContainer}>
                    <Typography className={classes.titlePage}>
                      86,45 %
                    </Typography>
                    <Typography className={classes.descriptionPage}>
                      Rendimiento (RCRL %)
                    </Typography>
                  </div>
                </ContentCard>
              </Grid>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Decomisos Post Mortem
                  </Typography>
                </div>
                <div className={clsx(classes.chartContainer)}>
                  <div
                    className={clsx(
                      classes.flexContainer,
                      classes.chartContainer
                    )}
                  >
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={2}
                      titleInfo="Total Canales Completas Decomisadas"
                      avatarTotalColor="#BE5948"
                      avatarTotalSize={60}
                      avatarTextSize={22}
                      titleInfoSize={120}
                    />
                    <div className={classes.columnContainer}>
                      <div className={classes.chartContainer}>
                        <ItemTotalInfo
                          avatarTotalInfo={2}
                          titleInfo="Decomisadas (SBE)"
                          avatarTotalColor="#D47D57"
                          avatarTotalSize={35}
                          avatarTextSize={16}
                          extended
                          position="right"
                        />
                      </div>
                      <div className={classes.chartContainer}>
                        <ItemTotalInfo
                          avatarTotalInfo={0}
                          titleInfo="Decomisadas (LB)"
                          avatarTotalColor="#E29662"
                          avatarTotalSize={35}
                          avatarTextSize={16}
                          extended
                          position="right"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={clsx(
                      classes.flexContainer,
                      classes.chartContainer
                    )}
                  >
                    <div className={classes.flexContainer}>
                      <Typography className={classes.titlePage}>2%</Typography>
                      <Typography className={classes.descriptionPage}>
                        Porcentaje Total Decomisos
                      </Typography>
                    </div>
                    <div className={classes.flexContainer}>
                      <Typography className={classes.titlePage}>
                        83,76%
                      </Typography>
                      <Typography className={classes.descriptionPage}>
                        Total (Kgs) Carne Decomisada
                      </Typography>
                    </div>
                  </div>
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.evenlyContainer}>
                  <Typography className={classes.paperTitles}>
                    Reporte Inspección Post-Mortem
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/app/reports/post-mortem"
                  >
                    Ver
                  </Button>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ContentCard>
            <div className={classes.headerContent}>
              <div>
                <Typography className={classes.paperTitles}>
                  Detalle Sacrificio y Transporte del día
                </Typography>
              </div>
              <div>
                <Results number={rows.length} />
              </div>
            </div>
            <div>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
              />
            </div>
          </ContentCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default index;
