import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
 dialogContainer:{
  "& .MuiDialog-paperWidthSm": {
    borderRadius: '20px'
  }
 },
 titleContainer:{
   display: 'flex',
   justifyContent: 'center', 
   alignItems: 'center'
 }, 
 subtitleInfo:{
   width: '90%',
   margin: '0 auto',
   borderBottom: '1px solid #AFAFAF'
 }, 
 generalInfoContainer:{
   padding: 12,
   display:'flex', 
 }, 
 listTotalContainer:{
    width: '50%',
    "& .MuiListItem-root":{
      width: '100% !important'
    },
 },
 timeContainer:{
   display: 'flex', 
   flexDirection: 'column',
   alignItems: 'center',
 }, 
 hoursContainer:{
   width: '100%',
   display: 'flex',
   justifyContent: 'space-evenly',
   background: '#F2F2F2',
   padding: 20,
 },
 levelContianer:{
  width: '40%'
 },
 specificInfoContainer:{
   display:'flex', 
   alignItems: 'center',
   justifyContent: 'space-evenly'
 },
 totalInfo:{
   width: '50%'
 },
 specificInfoItem:{
   width: '50%',
   display: 'flex', 
   flexDirection: 'column', 
   justifyContent: 'center',
   alignItems: 'center'
 }, 
 specificTitle:{
   fontWeight: 'bold', 
   color: '#AFAFAF'
 }, 
 dialogFooter:{
  display:'flex', 
  justifyContent:'center', 
  padding: 16, 
}, 
}))

export { useStyles as default }
