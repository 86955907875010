import { Theme, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(3)
  },
  container: {
    display: 'flex'
  },
  pageSizeInput: {
    width: 160
  },
  roundedInput: {
    borderRadius: 50
  },
  inputDense: {
    paddingTop: 8,
    paddingBottom: 8
  }
}))