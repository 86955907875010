import useStyles from './styles';
import logo from './../../static/img/icons/logoipt.png';
import getNamebyId from 'lib/helpers/getNamebyId';
import { useQuery } from '@apollo/client';
import { CITIES_BY_ID } from 'core/graphql/queries/countries';
import { useSelector } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';

type svgProps = {
  names: {
    letters: string;
    numbers: string;
  };
  values: {
    letters: string | null;
    numbers: string | number | null;
  };
  errors: {
    letters?: string | boolean;
    numbers?: string | boolean;
  };
  helperText: {
    letters?: string;
    numbers?: string;
  };
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  width?: number;
  ciudad: string | number;
  onClick?: any;
  color?: string;
};

const InputPlate = ({
  names,
  values,
  errors,
  helperText,
  onChange,
  onBlur,
  width,
  ciudad,
  color,
  onClick,
}: svgProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);

  const {
    loading: loadingCity,
    error: errorCity,
    data: dataCity,
  } = useQuery(CITIES_BY_ID, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idCity: ciudad },
  });

  const nameCity = dataCity?.citiesById[0]?.nameCity;

  return (
    <>
      <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1330 637"
        style={{
          width: width ? width : 180,
        }}
        onClick={onClick}
      >
        <g id="Background">
          <g id="Background-2" data-name="Background">
            <rect
              width={1330}
              height={637}
              rx="25.56"
              style={{ fill: `${color ? color : '#ffc600'}` }}
            />
            <rect x={10} y={11} width={1312} height={620} rx="25.56" />
            <rect
              x={26}
              y={22}
              width={1277}
              height={596}
              rx="25.56"
              style={{ fill: `${color ? color : '#ffc600'}` }}
            />
          </g>
          <g id="Puntos_de_la_placa" data-name="Puntos de la placa">
            <circle cx="303.5" cy="62.5" r="18.5" style={{ fill: '#fff' }} />
            <circle cx="1078.5" cy="62.5" r="18.5" style={{ fill: '#fff' }} />
            <circle cx="303.5" cy="574.5" r="18.5" style={{ fill: '#fff' }} />
            <circle cx="1078.5" cy="574.5" r="18.5" style={{ fill: '#fff' }} />
          </g>
        </g>
        <text
          id="Ciudad"
          transform="translate(415.65 589.5)"
          style={{
            fontSize: '123px',
            fontFamily: 'GillSansMT-Condensed, Gill Sans MT Condensed',
            letterSpacing: '0.050999587144308946em',
          }}
        >
          {ciudad?.toString().length > 0
            ? typeof ciudad === 'string'
              ? ciudad?.toUpperCase()
              : nameCity ?? ''
            : 'CIUDAD PLACA'}
        </text>
        <image
          width={200}
          height={200}
          transform="translate(615 262) scale(0.77)"
          xlinkHref={logo}
        />
      </svg>
      <Grid container alignItems="center" className={classes.gridContainer}>
        <Grid item xs={6}>
          <TextField
            name={names.letters}
            value={values.letters?.replace(/[0-9]/, '')}
            onChange={(e) => onChange(e)}
            onBlur={onBlur}
            inputProps={{ maxLength: 3 }}
            className={classes.input}
            error={!!errors.letters}
            helperText={helperText.letters}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name={names.numbers}
            value={values.numbers}
            onChange={(e) => onChange(e)}
            onBlur={onBlur}
            className={classes.input}
            type="number"
            onInput={(e: any) => {
              e.target.value = e.target.value.toString().slice(0, 3);
            }}
            inputProps={{
              pattern: '[0-9]*',
            }}
            helperText={helperText.numbers}
            required
          />
        </Grid>
      </Grid>
    </>
  );
};
export default InputPlate;
