import React from "react";

import { Box, Grid, Typography, Card, CardActionArea } from "@material-ui/core";

import MainTitle from "components/MainTitle";

import Farm from "static/img/pigs/farms.svg";

import clsx from "clsx";
import { useHistory } from "react-router-dom";

import useStyles from "./styles";
import ItemTotalInfo from "components/ItemTotalInfo";

const Farms = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box>
        <Grid container spacing={2} xs={12} alignItems="flex-end">
          <Grid item xs={6}>
            <img width="80%" src={Farm} />
          </Grid>
          <Grid item xs={6}>
            <MainTitle>GESTIONAR GRANJAS</MainTitle>
            <Typography color="secondary" style={{ marginTop: 8 }}>
              A continuación podrás gestionar tus granjas, ya sea visualizando
              el detalle, editando la información o registrando una nueva.
            </Typography>
            <Box marginY={3}>
              <Box display="flex">
                <ItemTotalInfo
                  position="left"
                  avatarTotalInfo={0}
                  titleInfo="Total Granjas Registradas"
                  avatarTotalColor="#ffc676"
                  avatarTotalSize={30}
                  titleInfoSize={290}
                  extended
                  classNameLabel={classes.total}
                />
              </Box>
              <Box marginTop={6} display="flex" justifyContent="center">
                <Card className={classes.cardContainer}>
                  <CardActionArea
                    className={classes.cardContent}
                    onClick={() => history.push(`/app/profile/farms/register/`)}
                  >
                    <i className={clsx("icon-granja", classes.cardIcon)} />
                    <Typography className={classes.cardTitle}>
                      Registrar Granja
                    </Typography>
                  </CardActionArea>
                </Card>
                <Card className={classes.cardContainer}>
                  <CardActionArea
                    className={classes.cardContent}
                    onClick={() => history.push(`/app/profile/farms/`)}
                  >
                    <i
                      className={clsx("icon-granja-search", classes.cardIcon)}
                    />
                    <Typography className={classes.cardTitle}>
                      Ver mis Granjas
                    </Typography>
                  </CardActionArea>
                </Card>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Farms;
