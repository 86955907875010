import React, { useEffect } from 'react';
import {
  Typography,
  Container,
  Grid,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import Chart from 'components/Chart';
import dayjs, { Dayjs } from 'dayjs';
import FilterReports from 'components/FilterReports';

const index = () => {
  const classes = useStyles();

  const [seurop, setSeurop] = React.useState('weekly');
  const [evaluate, setEvaluate] = React.useState('weekly');
  const [measurement, setMeasurement] = React.useState('weekly');
  const [canalesMedidas, setCanalesMedidas] = React.useState('weekly');
  const [canalesEvaluadas, setCanalesEvaluadas] = React.useState('weekly');
  const handleChangeSeurop = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeurop((event.target as HTMLInputElement).value);
  };
  const handleChangeCanalesEvaluadas = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCanalesEvaluadas((event.target as HTMLInputElement).value);
  };
  const handleChangeCanalesMedidas = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCanalesMedidas((event.target as HTMLInputElement).value);
  };
  const handleChangeEvaluate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEvaluate((event.target as HTMLInputElement).value);
  };
  const handlechangeMeasurement = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMeasurement((event.target as HTMLInputElement).value);
  };

  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/services', title: 'Informes y Estadísticas' },
  ];

  return (
    <Container className={classes.root}>
      <div className={classes.breadContainer}>
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <FilterReports to="/app/services/resumen-measurement" />
      </ContentCard>

      <Grid container spacing={4} direction="row">
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Resumen General Medición
                  </Typography>
                  {measurement === 'today' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('dddd, D MMMM YYYY')}
                    </Typography>
                  ) : measurement === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : measurement ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().week() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>

                <RadioGroup
                  aria-label="timer"
                  name="timer3"
                  value={measurement}
                  onChange={handlechangeMeasurement}
                >
                  <div className={classes.centerContainer}>
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="today"
                      control={<Radio color="primary" />}
                      label="Hoy"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="weekly"
                      control={<Radio color="primary" />}
                      label="Semanal"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="monthly"
                      control={<Radio color="primary" />}
                      label="Mensual"
                    />
                  </div>
                </RadioGroup>

                {measurement === 'today' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          200
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Canales Pesadas
                      </Typography>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          420
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Lotes Pesados
                      </Typography>
                    </div>
                  </div>
                ) : measurement === 'monthly' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          220
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Canales Pesadas
                      </Typography>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          460
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Lotes Pesados
                      </Typography>
                    </div>
                  </div>
                ) : measurement ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          260
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Canales Pesadas
                      </Typography>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div className={classes.containerItemGrid}>
                        <Typography className={classes.labelNumber}>
                          480
                        </Typography>
                      </div>
                      <Typography className={classes.labelTitle}>
                        Lotes Pesados
                      </Typography>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Clasificación SEUROP
                  </Typography>
                  {seurop === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : seurop ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().week() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <RadioGroup
                    aria-label="timer"
                    name="timer1"
                    value={seurop}
                    onChange={handleChangeSeurop}
                  >
                    <div className={classes.centerContainer}>
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="weekly"
                        control={<Radio color="primary" />}
                        label="Semanal"
                      />
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="monthly"
                        control={<Radio color="primary" />}
                        label="Mensual"
                      />
                    </div>
                  </RadioGroup>
                  {seurop === 'today' ? (
                    <div className={classes.chartContainer}>
                      <Chart
                        type="doughnut"
                        withoutTotalInfo={true}
                        data={[
                          {
                            label: 'S - Superior',
                            data: 50,
                            bg: '#FFC676',
                          },
                          {
                            label: 'E - Excelente',
                            data: 20,
                            bg: '#F1AE6C',
                          },
                          {
                            label: 'U - Muy Buena - Estándar',
                            data: 5,
                            bg: '#E29662',
                          },
                          {
                            label: 'R - Buena',
                            data: 10,
                            bg: '#D47D57',
                          },
                          {
                            label: 'O - Menos Buena',
                            data: 2,
                            bg: '#BE5948',
                          },
                          {
                            label: 'P - Mediocre',
                            data: 5,
                            bg: '#B0413E',
                          },
                        ]}
                      />
                      <div className={classes.containerButton}></div>
                    </div>
                  ) : seurop === 'monthly' ? (
                    <div className={classes.chartContainer}>
                      <Chart
                        type="doughnut"
                        withoutTotalInfo={true}
                        data={[
                          {
                            label: 'S - Superior',
                            data: 60,
                            bg: '#FFC676',
                          },
                          {
                            label: 'E - Excelente',
                            data: 50,
                            bg: '#F1AE6C',
                          },
                          {
                            label: 'U - Muy Buena - Estándar',
                            data: 30,
                            bg: '#E29662',
                          },
                          {
                            label: 'R - Buena',
                            data: 50,
                            bg: '#D47D57',
                          },
                          {
                            label: 'O - Menos Buena',
                            data: 25,
                            bg: '#BE5948',
                          },
                          {
                            label: 'P - Mediocre',
                            data: 25,
                            bg: '#B0413E',
                          },
                        ]}
                      />
                      <div className={classes.containerButton}></div>
                    </div>
                  ) : seurop ? (
                    <div className={classes.chartContainer}>
                      <Chart
                        type="doughnut"
                        withoutTotalInfo={true}
                        data={[
                          {
                            label: 'S - Superior',
                            data: 40,
                            bg: '#FFC676',
                          },
                          {
                            label: 'E - Excelente',
                            data: 30,
                            bg: '#F1AE6C',
                          },
                          {
                            label: 'U - Muy Buena - Estándar',
                            data: 10,
                            bg: '#E29662',
                          },
                          {
                            label: 'R - Buena',
                            data: 20,
                            bg: '#D47D57',
                          },
                          {
                            label: 'O - Menos Buena',
                            data: 5,
                            bg: '#BE5948',
                          },
                          {
                            label: 'P - Mediocre',
                            data: 5,
                            bg: '#B0413E',
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Promedio General Medición
                  </Typography>
                  {evaluate === 'today' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('dddd, D MMMM YYYY')}
                    </Typography>
                  ) : evaluate === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : evaluate ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().week() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>

                <RadioGroup
                  aria-label="timer"
                  name="timer3"
                  value={evaluate}
                  onChange={handleChangeEvaluate}
                >
                  <div className={classes.centerContainer}>
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="today"
                      control={<Radio color="primary" />}
                      label="Hoy"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="weekly"
                      control={<Radio color="primary" />}
                      label="Semanal"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="monthly"
                      control={<Radio color="primary" />}
                      label="Mensual"
                    />
                  </div>
                </RadioGroup>

                {evaluate === 'today' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            298.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (Propio)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            34.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          Promedio Lomo (Propio)
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            488.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC) (Propio)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            290.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          Promedio Grasa Dorsal (Sistema InfoPorcinos)
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            34.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎mm'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (Sistema Infoporcinos)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            488.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎mm'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Porcentaje de Magro (Sistema Infoporcinos)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : evaluate === 'monthly' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            300.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (Propio)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            36.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          Promedio Lomo (Propio)
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            490.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC) (Propio)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            292.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          Promedio Grasa Dorsal (Sistema InfoPorcinos)
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            36.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎mm'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (Sistema Infoporcinos)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            490.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎mm'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Porcentaje de Magro (Sistema Infoporcinos)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : evaluate ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            288.49
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (GD) (Propio)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            34.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          Promedio Lomo (Propio)
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            486.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC) (Propio)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            294.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promeido Grasa Dorsal (Sistema InfoPorcinos)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            34.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          Promedio Lomo (Sistema Infoporcinos)
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            492.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎Kg'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          Porcentaje de Magro (Sistema InfoPorcinos)
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default index;
