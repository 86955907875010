import { gql } from "apollo-boost";

export const CREATE_USER = gql`
  mutation postInternalUser(
    $idTipoUsuario: Int!
    $firstName: String!
    $secondName: String
    $firstSurname: String!
    $secondSurname: String
    $documentType: Int!
    $documentNumber: String!
    $idPosition: Int!
    $indicativePhone: Int!
    $phoneNumber: String!
    $email: String!
    $username: String!
    $password: String!
    $idRole: Int!
  ) {
    postInternalUser(
      input: {
        idTipoUsuario: $idTipoUsuario
        primerNombre: $firstName
        segundoNombre: $secondName
        primerApellido: $firstSurname
        segundoApellido: $secondSurname
        idTipoDocumento: $documentType
        numeroDocumento: $documentNumber
        idCargo: $idPosition
        idIndicativoCelular: $indicativePhone
        celular: $phoneNumber
        email: $email
        username: $username
        password: $password
        idRol: $idRole
      }
    ) {
      data {
        success
        message
        idUsuarioInterno
      }
    }
  }
`;

export const UPDATE_INTERNALUSER = gql`
  mutation putInternalUser(
    $idTipoUsuario: Int!
    $idUsuarioInterno: Int!
    $firstName: String!
    $secondName: String
    $firstSurname: String!
    $secondSurname: String
    $documentType: Int!
    $documentNumber: String!
    $idPosition: Int!
    $indicativePhone: Int!
    $phoneNumber: String!
    $email: String!
    $username: String!
    $password: String!
    $idRole: Int!
  ) {
    putInternalUser(
      input: {
        idTipoUsuario: $idTipoUsuario
        idUsuarioInterno: $idUsuarioInterno
        primerNombre: $firstName
        segundoNombre: $secondName
        primerApellido: $firstSurname
        segundoApellido: $secondSurname
        idTipoDocumento: $documentType
        numeroDocumento: $documentNumber
        idCargo: $idPosition
        idIndicativoCelular: $indicativePhone
        celular: $phoneNumber
        email: $email
        username: $username
        password: $password
        idRol: $idRole
      }
    ) {
      data {
        success
        message
      }
    }
  }
`;

export const ASSIGN_ROLE = gql`
  mutation assignRole(
    $idTipoUsuario: Int!
    $idUsuarioInterno: Int!
    $idRol: Int!
  ) {
    assignRole(
      input: {
        idTipoUsuario: $idTipoUsuario
        idUsuarioInterno: $idUsuarioInterno
        idRol: $idRol
      }
    ) {
      data {
        success
        message
      }
    }
  }
`;

export const CHANGE_INTERNALUSER_STATE = gql`
  mutation putStatusInternalUser($idUsuarioInterno: Int!, $estado: Boolean!) {
    putStatusInternalUser(
      input: { idUsuarioInterno: $idUsuarioInterno, estado: $estado }
    ) {
      data {
        success
        message
      }
    }
  }
`;
