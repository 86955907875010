import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Card,
  CardActionArea,
  Box,
  TextField,
} from '@material-ui/core';

import Slider from 'components/Slider';
import ExistingUser from 'components/ExistingUser';
import InformativeScreen from 'components/InformativeScreens';

import {
  DESTINATION_EXISTING,
  SEARCH_DESTINATION_BY_NIT,
} from 'core/graphql/queries/profile';
import { ASSIGN_EXISTING_DESTINY } from 'core/graphql/mutations/profile';

import clsx from 'clsx';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import useStyles from './styles';

const SelectTypeDestiny = ({ children }: any) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [openOptions, setOpenOptions] = useState(false);
  const [openExisting, setOpenExisting] = useState(false);
  const [search, setSearch] = useState('');

  const { data, refetch } = useQuery(DESTINATION_EXISTING, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idTipoDestino: selected },
  });

  const [searchNit, { data: dataByNit }] = useLazyQuery(
    SEARCH_DESTINATION_BY_NIT,
    {
      context: { headers: { 'x-auth-jwt': token } },
      variables: { nit: search },
    }
  );

  const [assign, {}] = useMutation(ASSIGN_EXISTING_DESTINY, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const handleOpen = () => {
    setOpen(true);
    setOpenOptions(false);
    setOpenExisting(false);
  };
  const handleClose = () => setOpen(false);
  const handleOpenOptions = () => {
    setOpenOptions(true);
    setOpen(false);
    setOpenExisting(false);
  };
  const handleCloseOptions = () => setOpenOptions(false);
  const handleOpenExisting = () => {
    refetch();
    setOpenOptions(false);
    setOpenExisting(true);
  };
  const handleCloseExisting = () => setOpenExisting(false);

  const handleSearch = () => {
    searchNit({
      variables: { nit: search },
    });
  };

  const AssignDestiny = (id: any) => {
    assign({
      variables: {
        idDestino: id,
      },
    })
      .then(() => {
        handleCloseExisting();
        enqueueSnackbar(
          `Se ha asignado la ${
            selected === 2
              ? 'Planta de Desposte'
              : 'Planta de Acondicionamiento'
          } exitosamente.`,
          {
            variant: 'success',
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          }
        );
      })
      .catch(() => {
        handleCloseExisting();
        enqueueSnackbar(
          `No se pudo asignar la ${
            selected === 2
              ? 'Planta de Desposte'
              : 'Planta de Acondicionamiento'
          }`,
          {
            variant: 'error',
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          }
        );
      });
  };

  const types = [
    {
      name: 'Punto de Venta',
      icon: 'icon-map-marker',
      id: 1,
    },
    {
      name: 'Planta de Desposte',
      icon: 'icon-organs',
      id: 2,
    },
    {
      name: 'Planta de Acondicionamiento',
      icon: 'icon-canal',
      id: 3,
    },
  ];

  const handleSelect = (id: number) => {
    if (id === 1) {
      history.push('/app/profile/points-of-sale/register/1');
    } else {
      setSelected(id);
      handleOpenOptions();
    }
  };

  return (
    <>
      {children({ handleOpen, open })}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            Registrar Destino
          </Typography>
          <Typography color="secondary" align="center">
            Selecciona el tipo de Destino que desea registrar o asignar uno ya
            existente si es el caso.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.container}>
            {types.map((item: any) => {
              return (
                <>
                  <Card className={classes.cardContainer}>
                    <CardActionArea
                      className={classes.cardContent}
                      onClick={() => handleSelect(item.id)}
                    >
                      <i className={clsx(item.icon, classes.cardIcon)} />
                      <Typography className={classes.cardTitle}>
                        {item.name}
                      </Typography>
                    </CardActionArea>
                  </Card>
                </>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openOptions}
        onClose={handleCloseOptions}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            {selected === 2
              ? 'Registrar Planta de Desposte'
              : 'Registrar Planta de Acondicionamiento'}
          </Typography>
          <Typography color="secondary" align="center">
            Puedes registrar una nueva{' '}
            {selected === 2
              ? 'Planta de Desposte '
              : 'Planta de Acondicionamiento '}{' '}
            o consultar y asignar una de las que están registradas actualmente
            en el sistema.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.container}>
            <Card className={classes.cardContainer}>
              <CardActionArea
                className={classes.cardContent}
                component={Link}
                to={`points-of-sale/register/${selected}`}
              >
                <i className={clsx('icon-plus', classes.cardIcon)} />
                <Typography className={classes.cardTitle}>Registrar</Typography>
              </CardActionArea>
            </Card>
            <Card className={classes.cardContainer}>
              <CardActionArea
                className={classes.cardContent}
                onClick={handleOpenExisting}
              >
                <i className={clsx('icon-search', classes.cardIcon)} />
                <Typography className={classes.cardTitle}>
                  Consultar Existentes
                </Typography>
              </CardActionArea>
            </Card>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button variant="contained" color="secondary" onClick={handleOpen}>
            Volver Atrás
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openExisting}
        onClose={handleCloseExisting}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            {selected === 2
              ? 'Plantas de Desposte '
              : 'Plantas de Acondicionamiento '}
            existentes
          </Typography>
          <Typography color="secondary" align="center">
            Consulta las{' '}
            {selected === 2
              ? 'Plantas de Desposte '
              : 'Plantas de Acondicionamiento '}
            resgistradas actualmente en el sistema por su NIT y al seleccionar
            una de ellas se asignará a tus destinos.
          </Typography>
        </DialogTitle>
        <DialogContent>
          {data?.getDestinationsXType?.data?.length > 0 ? (
            <>
              <Box display="flex" justifyContent="center" marginBottom={2}>
                <TextField
                  label="Busqueda por NIT"
                  size="small"
                  variant="outlined"
                  name="codigoDestino"
                  style={{ width: '50%' }}
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <i className="icon-search" style={{ fontSize: 20 }} />
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  style={{ marginLeft: 8 }}
                >
                  Buscar NIT
                </Button>
              </Box>
              <Box>
                <Slider>
                  {dataByNit ? (
                    dataByNit?.validateDestination?.data?.success === 1 ? (
                      <ExistingUser
                        column
                        name={
                          dataByNit?.validateDestination?.data?.data
                            ?.nombreDestino
                        }
                        info={`NIT: ${dataByNit?.validateDestination?.data?.data?.nit} - ${dataByNit?.validateDestination?.data?.data?.digitoVerificacion}`}
                        onClick={() =>
                          AssignDestiny(
                            dataByNit?.validateDestination?.data?.data
                              ?.idDestino
                          )
                        }
                      />
                    ) : (
                      <Box display="flex" justifyContent="center">
                        <InformativeScreen
                          type="notFound"
                          info={`No encontramos ${
                            selected === 2
                              ? 'Plantas de Desposte'
                              : 'Plantas de Acondicionamiento'
                          } con el NIT ingresado, verifica la información o registra una nueva.`}
                          subtitle="¡Ups!"
                          onlyError
                        />
                      </Box>
                    )
                  ) : (
                    data?.getDestinationsXType?.data?.map((item: any) => {
                      return (
                        <ExistingUser
                          column
                          name={item?.nombreDestino}
                          info={`NIT: ${item?.nit}`}
                          onClick={() => AssignDestiny(item?.idDestino)}
                        />
                      );
                    })
                  )}
                </Slider>
              </Box>
            </>
          ) : (
            <Box display="flex" justifyContent="center">
              <InformativeScreen
                type="notFound"
                info={`No encontramos ${
                  selected === 2
                    ? 'Plantas de Desposte'
                    : 'Plantas de Acondicionamiento'
                } para asignar, debes registrar una.`}
                subtitle="¡Ups!"
                onlyError
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenOptions}
          >
            Volver Atrás
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`points-of-sale/register/${selected}`}
          >
            Registrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectTypeDestiny;
