import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import LicensePlate from '../../LicensePlate';
import useStyles from './styles';

interface cardProps {
  state?: 'active' | 'inactive';
  id?: string | number;
  plate: string;
  ubication: string;
  vehicleType: string;
  loadType: string | number;
  capacity: string;
  actionFuncion?: (event: any) => void;
}

const CardDetailVehicle = ({
  state,
  id,
  plate,
  ubication,
  vehicleType,
  loadType,
  capacity,
  actionFuncion,
}: cardProps) => {
  const classes = useStyles();

  return (
    <Grid>
      <Card elevation={4} className={classes.cardContainer}>
        <CardContent>
          <Grid container justifyContent="center">
            <LicensePlate placa={plate} ciudad=" " width={180} checked />
          </Grid>
          <Grid container justifyContent="center">
            <Box mt={1} mb={1} maxWidth="70%">
              <Typography className={classes.ubicationInfo}>
                {ubication.toUpperCase()}
              </Typography>
            </Box>
          </Grid>

          <Box mt={1}>
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.dataTitle}>
                  Tipo de Vehículo
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dataInfo}>
                  {vehicleType}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box mt={1}>
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.dataTitle}>
                  Capacidad y Tipo de Carga
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dataInfo}>
                  {capacity + ' ' + loadType}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardContent>

        <CardActions style={{ marginBottom: 5 }}>
          <Grid container justify="center">
            {state && (
              <Box mr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  className={
                    state === 'active'
                      ? classes.buttonColorGreen
                      : classes.buttonColorRed
                  }
                  onClick={actionFuncion}
                >
                  {state === 'active' ? 'Activar' : 'Inactivar'}
                </Button>
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={`/app/network/transports/forms/detail-vehicle/${id}`}
            >
              Ver detalle
            </Button>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default CardDetailVehicle;
