import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Divider,
  Tooltip,
  Box,
  Fab,
} from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import domtoimage from 'dom-to-image';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import dayjs from 'dayjs';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import GobackLink from 'components/GobackLink';
import DialogPDFViewer from 'components/DialogPDFViewer/index';

import { MyDocInmune, ReportFilter } from './../../Reports/ReportFilter';

import useStyles from './styles';

const Search = () => {
  const classes = useStyles();
  const url = new URLSearchParams(window.location.search);
  const [type]: any = React.useState(url.get('type'));
  const initialDate: any = React.useState(url.get('initialdate'));
  const finalDate: any = React.useState(url.get('finaldate'));
  const [imageQr, setImageQr] = useState('');
  const [openInmune, setOpenInmune] = useState(false);

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenQrFilterInmune');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeInmune = () => {
    setOpenInmune(true);
  };

  useEffect(() => {
    generateScreenshot();
  }, [openInmune]);

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'certificatenumber',
      headerName: 'Nro. Certificado',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'loteceba',
      headerName: 'Lote de Ceba',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'productor',
      headerName: 'Productor',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'origenGranja',
      headerName: 'Granja Origen',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'animals',
      headerName: 'Animales',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: '',
      headerName: '',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/inmunecastration/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
    {
      id: 2,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
    {
      id: 3,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
    {
      id: 4,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
    {
      id: 5,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
    {
      id: 6,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/inmunecastration',
      title: 'Certificados de Inmunocastración',
    },
  ];

  const getDateFilter = () => {
    switch (type) {
      case 'lote':
        return url.get('code');
      case 'today':
        return dayjs().format('DD/MM/YYYY');
      case 'week':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      case 'range':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      default:
        return 'El tipo de filtro solicitado no existe';
    }
  };

  const getInfoFilter = () => {
    switch (type) {
      case 'lote':
        return (
          <>
            <Typography className={classes.filterType}>Lote:</Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{url.get('code')}</span>
            </Typography>
          </>
        );
      case 'today':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">
                {dayjs().format('dddd, D MMMM YYYY')} (Hoy)
              </span>
            </Typography>
          </>
        );
      case 'week':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{'Semana' + ' ' + dayjs().week()}</span>{' '}
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      case 'range':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      default:
        return (
          <Typography className={classes.filterType}>
            El tipo de filtro solicitado no existe
          </Typography>
        );
    }
  };

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportFilter />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard className={classes.contentCard}>
        <div className={classes.root}>
          <div className={classes.flex}>
            <Typography className={classes.title}>
              Búsqueda: Fecha Puntual
            </Typography>
            {!url.get('code') && (
              <Button
                variant="contained"
                color="primary"
                onClick={openModalRangeInmune}
              >
                Generar Reporte
              </Button>
            )}
          </div>
          <div className={classes.flexContainer}>
            <div className={classes.flex}>
              <Typography className={classes.labelOrange}>
                {getInfoFilter()}
              </Typography>
            </div>
            <div className={clsx(classes.result, classes.flex)}>
              <LibraryBooksOutlinedIcon style={{ marginRight: 20 }} />
              <Typography>{'200 resultados'}</Typography>
            </div>
          </div>

          <Divider />

          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            className={classes.dataGrid}
            components={{
              Footer: TableFooter,
            }}
          />
        </div>
      </ContentCard>
      <DialogPDFViewer
        open={openInmune}
        onClose={() => setOpenInmune(false)}
        done={Boolean(imageQr)}
      >
        <MyDocInmune qr={imageQr} range={getDateFilter()} />
      </DialogPDFViewer>
    </div>
  );
};

export default Search;
