import { Document, Page, Text, View, Font, Image } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import plantIcon from 'static/img/reportsIcons/plantIcon';
import productorIcon from 'static/img/reportsIcons/productorIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';
import infotrakingLogo from 'static/img/reportsIcons/infotrakingLogo';
import whiteEntrance from 'static/img/reportsIcons/whiteEntrance';
import whiteFarm from 'static/img/reportsIcons/whiteFarm';
import whiteCorral from 'static/img/reportsIcons/whiteCorral';
import whiteSacrifice from 'static/img/reportsIcons/whiteSacrifice';
import whiteCava from 'static/img/reportsIcons/whiteCava';
import whiteDispatch from 'static/img/reportsIcons/whiteDispatch';
import clock from 'static/img/reportsIcons/clock';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import TableInfo from 'components/pdf-components/TableInfo/index';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ListData from 'components/pdf-components/ListData/index';
import ItemTotal from 'components/pdf-components/ItemTotal/index';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import IcoSubtitle from 'components/pdf-components/IconSubtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import ItemTotalLeft from 'components/pdf-components/ItemTotalLeft';
import ChartList from 'components/pdf-components/ChartList';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF';

import styles from './styles/lote';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const detallePesaje = [
  {
    fecha: '01/10/2019',
    hora: '02:46:00',
    nroAnimales: 0,
    tipoCerdo: 'N/D',
    sexo: 'N/D',
    inmunocastrado: 'SI',
    pesoTotal: '0.00',
    pesoPromedio: '0.00',
  },
  {
    fecha: '01/10/2019',
    hora: '02:46:00',
    nroAnimales: 24,
    tipoCerdo: 'CE',
    sexo: 'AMBOS',
    inmunocastrado: 'SI',
    pesoTotal: '3.360,00',
    pesoPromedio: '140,00',
  },
  {
    fecha: '01/10/2019',
    hora: '02:46:00',
    nroAnimales: 24,
    tipoCerdo: 'CE',
    sexo: 'AMBOS',
    inmunocastrado: 'SI',
    pesoTotal: '3.360,00',
    pesoPromedio: '140,00',
  },
];

const corrales = [
  {
    nroCorral: 0,
    cantidad: 24,
    fechaIngreso: '24/2/2020 02:47:00',
    fechaSalida: '24/2/2020 06:50:00',
  },
  {
    nroCorral: 1,
    cantidad: 24,
    fechaIngreso: '24/2/2020 02:47:00',
    fechaSalida: '24/2/2020 06:50:00',
  },
];

const detalleLB = [
  {
    parte: 'Oreja',
    cantidad: 1,
  },
  {
    parte: 'Pelviz',
    cantidad: 2,
  },
  {
    parte: 'Pezuña',
    cantidad: 1,
  },
  {
    parte: 'Piel',
    cantidad: 2,
  },
  {
    parte: 'Pulmón',
    cantidad: 63,
  },
  {
    parte: 'Vísceras Blancas',
    cantidad: 2,
  },
  {
    parte: 'Vísceras Rojas',
    cantidad: 5,
  },
];
const detalleSBE = [
  {
    parte: 'Oreja',
    cantidad: 0,
  },
  {
    parte: 'Pelviz',
    cantidad: 0,
  },
  {
    parte: 'Pezuña',
    cantidad: 0,
  },
  {
    parte: 'Piel',
    cantidad: 0,
  },
  {
    parte: 'Pulmón',
    cantidad: 0,
  },
  {
    parte: 'Vísceras Blancas',
    cantidad: 5,
  },
  {
    parte: 'Vísceras Rojas',
    cantidad: 0,
  },
];

const envios = [
  {
    fecha: '24/2/2020 02:47:00',
    cantidad: 24,
  },
  {
    fecha: '24/2/2020 02:47:00',
    cantidad: 24,
  },
];

const clasificacion = [
  {
    chart: 'S',
    magro: '%M > 60%',
    no: '12',
    percentage: '16%',
  },
  {
    chart: 'E',
    magro: '%55 < %M > 60%',
    no: '28',
    percentage: '35%',
  },
  {
    chart: 'U',
    magro: '%50 < %M > 55%',
    no: '28',
    percentage: '35%',
  },
  {
    chart: 'R',
    magro: '%45 < %M > 50%',
    no: '08',
    percentage: '10%',
  },
  {
    chart: 'O',
    magro: '%40 < %M > 45%',
    no: '01',
    percentage: '02%',
  },
  {
    chart: 'P',
    magro: '%M < 40%',
    no: '01',
    percentage: '02%',
  },
];

const detalleCava = [
  {
    nroCava: 'C11',
    nroRiel: 2,
    cantidad: 11,
  },
  {
    nroCava: 'C11',
    nroRiel: 2,
    cantidad: 11,
  },
];

const detalleCertificado = [
  {
    fecha: '26/2/2020 1:25:23 AM',
    certificado: '1451441',
    canales: 48,
    destino: 'Alimentos Cárnicos S.A.',
  },
];

interface MyDocProps {
  qr: string;
  graph: string;
  uid?: null | number | string;
}

export const MyDocInfotraking = ({ qr, graph }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={6700}
        reportImage={infotrakingLogo}
        number="023456789"
        title="Reporte InfoTraking"
        code="IT-001"
      />

      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={productorIcon}
          title="Productor"
          items={{
            Nombre: 'Nombre productor',
            NIT: '34123412-2',
            Teléfono: '+57 314 6543 543',
            Email: 'info@productor.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '10px' }}>
        <Subtitle
          title="Trazabilidad Interna del Proceso de Beneficio"
          widthContainer="100%"
        />
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '15px' }}>
        <IcoSubtitle icon={whiteEntrance} title="Portería" />
      </View>

      <View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ width: '31%', marginTop: '5px' }}>
            <TableInfo
              icon={whiteFarm}
              title="Granja Origen"
              widthContainer="92%"
              items={{
                Nombre: 'Nombre Granja',
                Responsable: 'Nombre Responsable',
                Teléfono: '+57 314 6543 543',
                Email: 'info@granja.com',
                Dirección: 'Calle 105 A 23-23',
                Ciudad: 'Medellín, Colombia',
              }}
            />
          </View>

          <View
            style={{
              width: '31%',
              marginTop: '10px',
              justifyContent: 'center',
            }}
          >
            <View style={{ marginBottom: 6 }}>
              <ItemTotal
                value="48"
                info="Animales Remisionados"
                position="left"
                alignFontInfo="left"
                widthValue={80}
                widthInfo="100px"
                heightValue={15}
                fontSizeValue={8}
                fontColor="#212121"
                backgroundColor="#ededed"
              />
            </View>
            <View style={{ marginBottom: 6 }}>
              <ItemTotal
                value="00880"
                info="Nro. Remisión"
                position="left"
                alignFontInfo="left"
                widthValue={80}
                widthInfo="100px"
                heightValue={15}
                fontColor="#212121"
                fontSizeValue={8}
                backgroundColor="#ededed"
              />
            </View>
            <View style={{ marginBottom: 6 }}>
              <ItemTotal
                value="020-0485001919"
                info="Nro. Guía Movilización"
                position="left"
                alignFontInfo="left"
                widthValue={80}
                widthInfo="100px"
                heightValue={15}
                fontSizeValue={8}
                fontColor="#212121"
                backgroundColor="#ededed"
              />
            </View>
            <View style={{ marginBottom: 6 }}>
              <ItemTotal
                value="025"
                info="Lote de Ceba"
                position="left"
                alignFontInfo="left"
                widthValue={80}
                widthInfo="100px"
                heightValue={15}
                fontSizeValue={8}
                fontColor="#212121"
                backgroundColor="#ededed"
              />
            </View>
            <View>
              <ItemTotal
                value="675534314"
                info="Certificado Inmunocastración"
                position="left"
                alignFontInfo="left"
                widthValue={80}
                widthInfo="100px"
                heightValue={15}
                fontSizeValue={8}
                fontColor="#212121"
                backgroundColor="#ededed"
              />
            </View>
          </View>

          <View style={{ width: '31%', marginTop: '10px' }}>
            <View
              style={{
                flexDirection: 'row',
                backgroundColor: '#ededed',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 40,
              }}
            >
              <View style={{ width: '12%' }}>
                <Image src={clock} style={{ width: 20, height: 20 }} />
              </View>
              <View style={{ width: '44.8%' }}>
                <Text>Duración del Proceso</Text>
              </View>
              <View style={{ width: '36.8%' }}>
                <View
                  style={{
                    backgroundColor: '#fff',
                    width: '80%',
                    height: 25,
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>00:28:25</Text>
                </View>
              </View>
            </View>
            <ListData
              widthContainer="100%"
              items={{
                'Fecha Salida Granja': '22/2/2020 2:00:00',
                'Fecha Llegada Planta': '24/2/2020 2:00:00',
                'Fecha Ingreso Planta': '24/2/2020 2:05:00',
                'Fecha Salida Planta': '24/2/2020 2:10:00',
              }}
            />
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <View style={{ width: '65.4%' }}>
            <View style={styles.backgroundresumetitle}>
              <View style={styles.imageicon}>
                <Image src={whiteEntrance} />
              </View>
              <Text>Detalles del Transporte</Text>
            </View>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <View style={{ width: '48.3%' }}>
                <ListData
                  widthContainer="100%"
                  items={{
                    Conductor: 'Fernando Lopez Contreras',
                    'Nro. Documento': '2546264343',
                    'Licencia de Conducir': '21343323223-2',
                  }}
                />
              </View>
              <View style={{ width: '48.3%' }}>
                <ListData
                  widthContainer="100%"
                  items={{
                    'Marca Vehículo': 'Toyota',
                    Modelo: '65-G',
                    Placa: 'JRG 432',
                  }}
                />
              </View>
            </View>
          </View>
          <View style={{ width: '31.2%' }}>
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                marginBottom: '5px',
              }}
            >
              <IcoSubtitle icon={marketerIcon} title="Detalle Portería" />
            </View>
            <View
              style={{
                backgroundColor: '#ededed',
                height: 40,
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Text>Fernando Lopez Contreras</Text>
              <Text style={{ fontWeight: 'bold' }}>Responsable Portería</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '15px' }}>
        <IcoSubtitle icon={whiteCorral} title="Corrales" padding={7} />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '31.2%', marginTop: '10px' }}>
          <View style={{ marginBottom: 6 }}>
            <ItemTotal
              value="102470"
              info="Nro. Tiquete de Báscula"
              position="left"
              alignFontInfo="left"
              widthValue="70px"
              widthInfo="80px"
              fontSizeValue={8}
              fontColor="#212121"
              backgroundColor="#ededed"
            />
          </View>
          <View style={{ marginBottom: 6 }}>
            <ItemTotal
              value="48"
              info="Animales Recibidos"
              position="left"
              alignFontInfo="left"
              widthValue="70px"
              widthInfo="80px"
              fontSizeValue={8}
              fontColor="#212121"
              backgroundColor="#ededed"
            />
          </View>
          <View style={{ marginBottom: 6 }}>
            <ItemTotal
              value="0"
              info="Total Muertos"
              position="left"
              alignFontInfo="left"
              widthValue="70px"
              widthInfo="80px"
              fontSizeValue={8}
              fontColor="#212121"
              backgroundColor="#ededed"
            />
          </View>
        </View>
        <View style={{ width: '31.2%', marginTop: '10px' }}>
          <View style={{ marginBottom: 6 }}>
            <ItemTotal
              value="48"
              info="Nro. Animales Pesados"
              position="left"
              alignFontInfo="left"
              widthValue="70px"
              widthInfo="80px"
              fontSizeValue={8}
              fontColor="#212121"
              backgroundColor="#ededed"
            />
          </View>
          <View style={{ marginBottom: 6 }}>
            <ItemTotal
              value="140,00"
              info="Peso Promedio Lote (Kg)"
              position="left"
              alignFontInfo="left"
              widthValue="70px"
              widthInfo="80px"
              fontSizeValue={8}
              fontColor="#212121"
              backgroundColor="#ededed"
            />
          </View>
          <View style={{ marginBottom: 6 }}>
            <ItemTotal
              value="6.720,00"
              info="Total Peso Lote (Kg)"
              position="left"
              alignFontInfo="left"
              widthValue="70px"
              widthInfo="80px"
              fontSizeValue={8}
              fontColor="#212121"
              backgroundColor="#ededed"
            />
          </View>
        </View>
        <View
          style={{
            width: '31.2%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              backgroundColor: '#ededed',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 40,
              width: '100%',
            }}
          >
            <View style={{ width: '12%' }}>
              <Image src={clock} style={{ width: 20, height: 20 }} />
            </View>
            <View style={{ width: '44.8%' }}>
              <Text>Duración del Proceso</Text>
            </View>
            <View style={{ width: '36.8%' }}>
              <View
                style={{
                  backgroundColor: '#fff',
                  width: '80%',
                  height: 25,
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>00:28:25</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: '5%',
            }}
          >
            <ListData
              widthContainer="100%"
              widthtTitle="53%"
              widthValue="50%"
              items={{
                'Fecha Incio Desembarque': '22/2/2020 2:12:10',
                'Fecha Final Desembarque': '24/2/2020 2:32:00',
                'Fecha Tiquete': '24/2/2020 2:40:15',
              }}
            />
          </View>
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '20px' }}>
        <Subtitle title="Detalle del Pesaje" widthContainer="100%" />
      </View>

      <View style={{ width: '100%', marginTop: '10px' }}>
        <View style={[styles.tableMagro, { marginBottom: 5 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '5px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 51.6, width: 51.6 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Pesaje</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 51.6, width: 51.6 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Fecha</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 61.6, width: 61.6 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Hora</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 61.6, width: 61.6 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Nro. Animales Pesados
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 61.6, width: 61.6 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Tipo Cerdo
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 51.6, width: 51.6 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Sexo</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 71.6, width: 71.6 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Inmunocastrado
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 71.6, width: 71.6 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Peso Total (Kg)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 71.6, width: 71.6 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Peso Promedio (Kg)
              </Text>
            </View>
          </View>
          {detallePesaje?.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 51.6, width: 51.6 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {index}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 51.6, width: 51.6 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.fecha}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 61.6, width: 61.6 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.hora}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 61.6, width: 61.6 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.nroAnimales}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 61.6, width: 61.6 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.tipoCerdo}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 51.6, width: 51.6 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.sexo}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 71.6, width: 71.6 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.inmunocastrado}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 71.6, width: 71.6 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.pesoTotal}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 71.6, width: 71.6 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.pesoPromedio}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={{ flexDirection: 'row', width: '100%', marginBottom: '10px' }}
        >
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 51.6,
                width: 51.6,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 113.2, width: 113.2, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 61.6, width: 61.6, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              48
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 184.8, width: 184.8, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 71.6, width: 71.6, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              6.720,00
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 71.6, width: 71.6, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              140,00
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          marginTop: '10px',
          marginBottom: '5px',
        }}
      >
        <Subtitle title="Corrales de Reposo" widthContainer="100%" />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '48.3%' }}>
          <View style={[styles.tableMagro, { marginBottom: 5 }]} wrap={false}>
            <View
              wrap={false}
              style={[
                styles.tableRow,
                {
                  marginBottom: '5px',
                  marginTop: '5px',
                  alignItems: 'center',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Nro. Corral
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Cantidad
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 79.5, width: 79.5 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Fecha Ingreso
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 79.5, width: 79.5 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Fecha Salida
                </Text>
              </View>
            </View>
            {corrales?.map((item: any, index: number) => (
              <View
                key={index}
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                  },
                ]}
              >
                <View
                  style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.nroCorral}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.cantidad}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { maxWidth: 79.5, width: 79.5 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.fechaIngreso}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { maxWidth: 79.5, width: 79.5 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.fechaSalida}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View
          style={{
            width: '48.3%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="Oscar Dario Uribe Cardona"
            info="Responsable Corrales"
            position="left"
            alignFontInfo="left"
            widthValue="150px"
            widthInfo="100px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
      </View>
      <View
        style={{
          width: '95%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '5px',
        }}
      >
        <View style={{ width: '35%' }}>
          <ItemTotal
            value="0"
            info="Nro. Animales Observados"
            position="left"
            alignFontInfo="left"
            widthValue="70px"
            widthInfo="85px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
        <View style={{ width: '54%' }}>
          <ItemTotal
            value="Guillermo Andrés Atehortúa Patiño"
            info="Responsable Inspección Ante-Mortem"
            position="left"
            alignFontInfo="left"
            widthValue="175px"
            widthInfo="135px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '20px' }}>
        <Subtitle
          title="Observaciones Ante-Mortem (Antes del Pesaje de Animales en Pie)"
          widthContainer="100%"
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="0"
            info="Nro. Caídos Transporte"
            position="bottom"
            alignFontInfo="center"
            widthValue="50px"
            widthInfo="100px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="0"
            info="Nro. Agitados Transporte"
            position="bottom"
            alignFontInfo="center"
            widthValue="50px"
            widthInfo="100px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="0"
            info="Nro. Muertos Transporte"
            position="bottom"
            alignFontInfo="center"
            widthValue="50px"
            widthInfo="100px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="0"
            info="Nro. Muertos Desembarque"
            position="bottom"
            alignFontInfo="center"
            widthValue="50px"
            widthInfo="100px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '20px' }}>
        <Subtitle
          title="Observaciones Ante-Mortem (Después del Pesaje de Animales en Pie)"
          widthContainer="100%"
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="0"
            info="Nro. Caídos Corrales"
            position="bottom"
            alignFontInfo="center"
            widthValue="50px"
            widthInfo="100px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="0"
            info="Nro. Agitados Corrales"
            position="bottom"
            alignFontInfo="center"
            widthValue="50px"
            widthInfo="100px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="0"
            info="Nro. Muertos Corrales"
            position="bottom"
            alignFontInfo="center"
            widthValue="50px"
            widthInfo="100px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="0"
            info="Nro. Muertos Corrales Obs."
            position="bottom"
            alignFontInfo="center"
            widthValue="50px"
            widthInfo="100px"
            fontSizeValue={8}
            fontColor="#212121"
            backgroundColor="#ededed"
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          marginTop: '15px',
          marginBottom: '5px',
        }}
      >
        <IcoSubtitle icon={whiteSacrifice} title="Sacrificio" />
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View style={{ width: '65%' }}>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <Subtitle title="Resumen Decomisos" widthContainer="100%" />
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '5px',
            }}
          >
            <View style={{ width: '48.3%' }}>
              <View style={{ flexDirection: 'row', width: '100%' }}>
                <Subtitle
                  title="Decomisos Línea de Beneficio"
                  widthContainer="100%"
                />
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  backgroundColor: '#ededed',
                  marginTop: '5px',
                }}
              >
                <Text>Responsable Veterinario</Text>
                <Text style={{ fontWeight: 'bold' }}>
                  Jorge Luis Roldan Bedoya
                </Text>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 40,
                  backgroundColor: '#ededed',
                  marginTop: '5px',
                }}
              >
                <ItemTotal
                  value="0"
                  info="Canales Completas Decomisadas"
                  position="right"
                  widthValue={45}
                  widthInfo="65px"
                  fontSizeValue={12}
                  backgroundColor="#d47d57"
                />
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 40,
                  backgroundColor: '#ededed',
                  marginTop: '5px',
                }}
              >
                <ItemTotal
                  value="76"
                  info="Total Decomisos del Lote"
                  position="right"
                  widthValue={45}
                  widthInfo="60px"
                  fontSizeValue={12}
                  backgroundColor="#e29662"
                />
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                <Subtitle title="Detalle Decomisos" widthContainer="100%" />
              </View>

              <View
                style={[styles.tableMagro, { marginBottom: 5 }]}
                wrap={false}
              >
                <View
                  wrap={false}
                  style={[
                    styles.tableRow,
                    {
                      marginBottom: '5px',
                      marginTop: '5px',
                      alignItems: 'center',
                    },
                  ]}
                >
                  <View
                    style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}
                  >
                    <Text style={[styles.tableCell, styles.tableHead]}>
                      Parte Decomisada
                    </Text>
                  </View>
                  <View
                    style={[styles.tableCol, { maxWidth: 80.5, width: 80.5 }]}
                  >
                    <Text style={[styles.tableCell, styles.tableHead]}>
                      Cantidad
                    </Text>
                  </View>
                </View>
                {detalleLB?.map((item: any, index: number) => (
                  <View
                    key={index}
                    wrap={false}
                    style={[
                      styles.tableRow,
                      {
                        backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                      },
                    ]}
                  >
                    <View
                      style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}
                    >
                      <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                        {item.parte}
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 80.5, width: 80.5 }]}
                    >
                      <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                        {item.cantidad}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  height: 20,
                  backgroundColor: '#ededed',
                  marginTop: '5px',
                }}
              >
                <View
                  style={{
                    width: '55%',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Text>Carne Decomisada (Kg)</Text>
                </View>
                <View
                  style={{
                    width: '41%',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Text>2,200 Kg</Text>
                </View>
              </View>
            </View>
            <View style={{ width: '48.3%' }}>
              <Subtitle title="Decomisos Sala S.B.E." widthContainer="100%" />

              <View
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  backgroundColor: '#ededed',
                  marginTop: '5px',
                }}
              >
                <Text>Responsable Veterinario</Text>
                <Text style={{ fontWeight: 'bold' }}>Luis Lopez Contreras</Text>
              </View>

              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 40,
                  backgroundColor: '#ededed',
                  marginTop: '5px',
                }}
              >
                <ItemTotal
                  value="0"
                  info="Canales Completas Decomisadas"
                  position="right"
                  widthValue={45}
                  widthInfo="65px"
                  fontSizeValue={12}
                  backgroundColor="#d47d57"
                />
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 40,
                  backgroundColor: '#ededed',
                  marginTop: '5px',
                }}
              >
                <ItemTotal
                  value="5"
                  info="Total Decomisos del Lote"
                  position="right"
                  widthValue={45}
                  widthInfo="60px"
                  fontSizeValue={12}
                  backgroundColor="#e29662"
                />
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                <Subtitle title="Detalle Decomisos" widthContainer="100%" />
              </View>

              <View
                style={[styles.tableMagro, { marginBottom: 5 }]}
                wrap={false}
              >
                <View
                  wrap={false}
                  style={[
                    styles.tableRow,
                    {
                      marginBottom: '5px',
                      marginTop: '5px',
                      alignItems: 'center',
                    },
                  ]}
                >
                  <View
                    style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}
                  >
                    <Text style={[styles.tableCell, styles.tableHead]}>
                      Parte Decomisada
                    </Text>
                  </View>
                  <View
                    style={[styles.tableCol, { maxWidth: 80.5, width: 80.5 }]}
                  >
                    <Text style={[styles.tableCell, styles.tableHead]}>
                      Cantidad
                    </Text>
                  </View>
                </View>
                {detalleSBE?.map((item: any, index: number) => (
                  <View
                    key={index}
                    wrap={false}
                    style={[
                      styles.tableRow,
                      {
                        backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                      },
                    ]}
                  >
                    <View
                      style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}
                    >
                      <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                        {item.parte}
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 80.5, width: 80.5 }]}
                    >
                      <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                        {item.cantidad}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  height: 20,
                  backgroundColor: '#ededed',
                  marginTop: '5px',
                }}
              >
                <View
                  style={{
                    width: '55%',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Text>Carne Decomisada (Kg)</Text>
                </View>
                <View
                  style={{
                    width: '41%',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Text>0,124 Kg</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ width: '32.7%' }}>
          <View
            style={{
              flexDirection: 'row',
              backgroundColor: '#ededed',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 40,
            }}
          >
            <View style={{ width: '12%' }}>
              <Image src={clock} style={{ width: 20, height: 20 }} />
            </View>
            <View style={{ width: '44.8%' }}>
              <Text>Duración del Proceso</Text>
            </View>
            <View style={{ width: '36.8%' }}>
              <View
                style={{
                  backgroundColor: '#fff',
                  width: '80%',
                  height: 25,
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>00:28:25</Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '5px',
            }}
          >
            <View
              style={{
                width: '35%',
                backgroundColor: '#ededed',
                height: 55,
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Text>Envíos Sacrificio</Text>
            </View>
            <View style={{ width: '61%' }}>
              <View
                style={[styles.tableMagro, { marginBottom: 5 }]}
                wrap={false}
              >
                <View
                  wrap={false}
                  style={[
                    styles.tableRow,
                    {
                      marginTop: '5px',
                      alignItems: 'center',
                    },
                  ]}
                >
                  <View style={[styles.tableCol, { maxWidth: 80, width: 80 }]}>
                    <Text style={[styles.tableCell, styles.tableHead]}>
                      Fecha
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 30, width: 30 }]}>
                    <Text style={[styles.tableCell, styles.tableHead]}>
                      Cant.
                    </Text>
                  </View>
                </View>
                {envios?.map((item: any, index: number) => (
                  <View
                    key={index}
                    wrap={false}
                    style={[
                      styles.tableRow,
                      {
                        backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                      },
                    ]}
                  >
                    <View
                      style={[styles.tableCol, { maxWidth: 80, width: 80 }]}
                    >
                      <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                        {item.fecha}
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 30, width: 30 }]}
                    >
                      <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                        {item.cantidad}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
          <ListData
            widthContainer="100%"
            widthtTitle="50%"
            widthValue="50%"
            items={{
              'Fecha Incio Post-Mortem': '22/2/2020 2:12:10',
              'Fecha Final Post-Mortem': '24/2/2020 2:32:00',
            }}
          />

          <View
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              height: 40,
              backgroundColor: '#ededed',
              marginTop: '5px',
            }}
          >
            <Text>Responsable Medición</Text>
            <Text style={{ fontWeight: 'bold' }}>
              Miguel Angel Cetré Figueroa
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              marginTop: '5px',
            }}
          >
            <Subtitle title="Resumen Pesaje" widthContainer="100%" />
          </View>

          <View>
            <View style={{ marginTop: '20px' }}>
              <ItemTotal
                value="5,256.99 Kg"
                info="Peso Total Canal Caliente Lote (PCC - Kg)"
                position="left"
                alignFontInfo="left"
                widthValue="70px"
                widthInfo="85px"
                heightValue={30}
                fontSizeValue={8}
                fontColor="#212121"
                backgroundColor="#ededed"
              />
            </View>
            <View style={{ marginTop: '15px' }}>
              <ItemTotal
                value="116.82 Kg"
                info="Peso Promedio Canal Caliente Lote (Kg)"
                position="left"
                alignFontInfo="left"
                widthValue="70px"
                widthInfo="85px"
                heightValue={30}
                fontSizeValue={8}
                fontColor="#212121"
                backgroundColor="#ededed"
              />
            </View>
            <View style={{ marginTop: '15px' }}>
              <ItemTotal
                value="2,948.27 Kg"
                info="Peso Total Carne Magra Canal Caliente Lote (Kg)"
                position="left"
                alignFontInfo="left"
                widthValue="70px"
                widthInfo="85px"
                heightValue={30}
                fontSizeValue={8}
                fontColor="#212121"
                backgroundColor="#ededed"
              />
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: 40,
          marginTop: '10px',
          marginBottom: '10px',
          backgroundColor: '#ededed',
        }}
      >
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="46,03%"
            info="MC (%)"
            position="right"
            backgroundColor="#be5948"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="29,08 m.m."
            info="GD (m.m)"
            position="right"
            backgroundColor="#d47d57"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="70,06 m.m."
            info="LM (m.m)"
            position="right"
            backgroundColor="#f1ae6c"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="85,66%"
            info="RCCL (%)"
            position="right"
            backgroundColor="#ffc676"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%' }}>
        <Subtitle
          title="Distribución de Canales Porcinas según Clasificación S.E.U.R.O.P."
          widthContainer="100%"
        />
      </View>

      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '5px',
        }}
      >
        <View
          style={{
            width: '58.3%',
            height: '150px',
            backgroundColor: '#ededed',
            marginTop: '5px',
            justifyContent: 'center',
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View
              style={{
                height: '120px',
                width: '50%',
                marginTop: 10,
                marginBottom: 5,
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 15,
              }}
            >
              <Image src={graph} style={{ height: '150px', width: '150px' }} />
            </View>

            <View
              style={{
                width: '60%',
                marginRight: 25,
              }}
            >
              <ChartList
                data={[
                  {
                    label: 'S - Superior',
                    data: 12,
                    bg: '#FFC676',
                  },
                  {
                    label: 'E - Excelente',
                    data: 28,
                    bg: '#F1AE6C',
                  },
                  {
                    label: 'U - Muy Buena',
                    data: 28,
                    bg: '#E29662',
                  },
                  {
                    label: 'R - Menos Buena',
                    data: 8,
                    bg: '#D47D57',
                  },
                  {
                    label: 'O - Aceptable',
                    data: 1,
                    bg: '#BE5948',
                  },
                  {
                    label: 'P - Pobre',
                    data: 1,
                    bg: '#B0413E',
                  },
                ]}
              />
            </View>
          </View>
        </View>
        <View style={{ width: '38.3%', marginTop: '5px' }}>
          <View style={{ width: '48.3%', marginBottom: '5px' }}>
            <View style={styles.tableMagro}>
              <View
                style={[
                  styles.tableRow,
                  {
                    marginBottom: '5px',
                    marginTop: '5px',
                    alignItems: 'center',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}></Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    % Magro
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>No.</Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>%</Text>
                </View>
              </View>
              {clasificacion.map((item: any, index: number) => (
                <View
                  key={index}
                  wrap={false}
                  style={[
                    styles.tableRow,
                    {
                      backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                    },
                  ]}
                >
                  <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                    <Text
                      style={[
                        styles.tableCellPost,
                        { fontSize: 8, fontWeight: 'bold' },
                      ]}
                    >
                      {item.chart}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                    <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                      {item.magro}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                    <Text
                      style={
                        (styles.tableCellPost, { fontSize: 7, marginTop: 5 })
                      }
                    >
                      {item.no}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                    <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                      {item.percentage}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <ItemTotalLeft
            value="08"
            info="Total Canales con Grasa Dorsal Mayor a 18,5"
            backgroundColor="#373737"
            heightValue={12}
            fontSizeValue={8}
            widthValue={50}
            widthInfo="150px"
            alignInfo="left"
          />
          <ItemTotalLeft
            value="19,51%"
            info="Porcentaje Canales Grasa Dorsal Mayor a 18,5"
            backgroundColor="#373737"
            heightValue={12}
            fontSizeValue={8}
            widthValue={50}
            widthInfo="150px"
            alignInfo="left"
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          marginTop: '15px',
          marginBottom: '10px',
        }}
      >
        <IcoSubtitle icon={whiteCava} title="Cavas" padding={6} />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '31.2%' }}>
          <View style={{ marginBottom: '5px' }}>
            <ItemTotal
              value="48"
              info="Total Canales Almacenadas"
              position="left"
              alignFontInfo="left"
              widthValue="70px"
              widthInfo="85px"
              fontSizeValue={8}
              fontColor="#fff"
              backgroundColor="#D47D57"
            />
          </View>
          <View
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              height: 45,
              backgroundColor: '#ededed',
              marginTop: '5px',
            }}
          >
            <Text>Responsable Cavas</Text>
            <Text style={{ fontWeight: 'bold' }}>Fernando Lopez Contreras</Text>
          </View>
        </View>
        <View style={{ width: '31.2%' }}>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              marginTop: '5px',
            }}
          >
            <Subtitle title="Detalle Cavas" widthContainer="100%" />
          </View>

          <View style={[styles.tableMagro, { marginBottom: 5 }]} wrap={false}>
            <View
              wrap={false}
              style={[
                styles.tableRow,
                {
                  marginBottom: '5px',
                  marginTop: '5px',
                  alignItems: 'center',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 57.6, width: 57.6 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Nro. Cava
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 57.6, width: 57.6 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Nro. Riel
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 57.6, width: 57.6 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>Cant.</Text>
              </View>
            </View>
            {detalleCava?.map((item: any, index: number) => (
              <View
                key={index}
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                  },
                ]}
              >
                <View
                  style={[styles.tableCol, { maxWidth: 57.6, width: 57.6 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.nroCava}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { maxWidth: 57.6, width: 57.6 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.nroRiel}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { maxWidth: 57.6, width: 57.6 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.cantidad}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={{ width: '31.2%' }}>
          <View
            style={{
              flexDirection: 'row',
              backgroundColor: '#ededed',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 40,
            }}
          >
            <View style={{ width: '12%' }}>
              <Image src={clock} style={{ width: 20, height: 20 }} />
            </View>
            <View style={{ width: '44.8%' }}>
              <Text>Duración del Proceso</Text>
            </View>
            <View style={{ width: '36.8%' }}>
              <View
                style={{
                  backgroundColor: '#fff',
                  width: '80%',
                  height: 25,
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>00:28:25</Text>
              </View>
            </View>
          </View>

          <View style={{ marginTop: '5px' }}>
            <ListData
              widthContainer="100%"
              widthtTitle="50%"
              widthValue="50%"
              items={{
                'Fecha Primer Despacho': '26/2/2020 2:12:10',
                'Fecha Último Despacho': '24/2/2020 2:32:00',
              }}
            />
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <IcoSubtitle icon={whiteDispatch} title="Despacho" />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '65.6%' }}>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View style={{ width: '45.3%' }}>
              <ItemTotal
                value="1"
                info="Nro. Despachos"
                position="left"
                alignFontInfo="left"
                widthValue="70px"
                widthInfo="80px"
                fontSizeValue={8}
                fontColor="#212121"
                backgroundColor="#ededed"
              />
            </View>
            <View style={{ width: '48.3%' }}>
              <ItemTotal
                value="48"
                info="Nro. Canales Despachadas"
                position="left"
                alignFontInfo="left"
                widthValue="70px"
                widthInfo="100px"
                fontSizeValue={8}
                fontColor="#212121"
                backgroundColor="#ededed"
              />
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              marginTop: '5px',
            }}
          >
            <Subtitle
              title="Detalle Certificados de Sacrificio y Transporte"
              widthContainer="100%"
            />
          </View>

          <View style={[styles.tableMagro, { marginBottom: 5 }]} wrap={false}>
            <View
              wrap={false}
              style={[
                styles.tableRow,
                {
                  marginBottom: '5px',
                  marginTop: '5px',
                  alignItems: 'center',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Fecha Desacho
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Nro. Certificado
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 72.5, width: 72.5 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Canales
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 102.5, width: 102.5 }]}
              >
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Destino Canales
                </Text>
              </View>
            </View>
            {detalleCertificado?.map((item: any, index: number) => (
              <View
                key={index}
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                  },
                ]}
              >
                <View
                  style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.fecha}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.certificado}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { maxWidth: 72.5, width: 72.5 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.canales}
                  </Text>
                </View>
                <View
                  style={[styles.tableCol, { maxWidth: 102.5, width: 102.5 }]}
                >
                  <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                    {item.destino}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={{ width: '31.2%' }}>
          <View
            style={{
              flexDirection: 'row',
              backgroundColor: '#ededed',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 40,
            }}
          >
            <View style={{ width: '12%' }}>
              <Image src={clock} style={{ width: 20, height: 20 }} />
            </View>
            <View style={{ width: '44.8%' }}>
              <Text>Duración del Proceso</Text>
            </View>
            <View style={{ width: '36.8%' }}>
              <View
                style={{
                  backgroundColor: '#fff',
                  width: '80%',
                  height: 25,
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>00:28:25</Text>
              </View>
            </View>
          </View>

          <View style={{ marginTop: '5px' }}>
            <ListData
              widthContainer="100%"
              widthtTitle="50%"
              widthValue="50%"
              items={{
                'Fecha Primer Despacho': '26/2/2020 2:12:10',
                'Fecha Último Despacho': '24/2/2020 2:32:00',
              }}
            />
          </View>
        </View>
      </View>

      <View style={styles.conventions} wrap={false}>
        <View style={styles.backgroundMagro}>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * GD (m.m)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Grasa Dorsal</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * MC (Kg)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Peso Magro Canal</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * MC (%)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Porcentaje de Magro Canal</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * LM (m.m)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Profundidad del Lomo</Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              marginLeft: '10px',
            }}
          >
            <View style={styles.textconventions}>
              <View style={styles.gmagroS}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * Clasificación Canal
                </Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * S
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Superior</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * E
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Excelente</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * U
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Muy Buena</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * R
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Menos Buena</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * O
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Aceptable</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * P
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Pobre</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.borderMagro}>
          <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
            Convenciones
          </Text>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screenqrinfotranking" style={{ width: 500 }}>
      <QRCode size={500} value="Reporte InfoTraking" renderAs="svg" level="H" />
    </div>
  );
};

export const GraphInfotraking = () => {
  return (
    <div id="graphinfotraking" style={{ width: 500, marginTop: -20 }}>
      <Graphicpdfsf
        textTotalData="Total"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        startAngle={-90}
        endAngle={270}
        data={[
          {
            label: 'S - Superior',
            data: 12,
            bg: '#FFC676',
          },
          {
            label: 'E - Excelente',
            data: 28,
            bg: '#F1AE6C',
          },
          {
            label: 'U - Muy Buena',
            data: 28,
            bg: '#E29662',
          },
          {
            label: 'R - Menos Buena',
            data: 8,
            bg: '#D47D57',
          },
          {
            label: 'O - Aceptable',
            data: 1,
            bg: '#BE5948',
          },
          {
            label: 'P - Pobre',
            data: 1,
            bg: '#B0413E',
          },
        ]}
      />
    </div>
  );
};
