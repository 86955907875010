import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import cavaLogo from 'static/img/reportsIcons/cavaLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import productorIcon from 'static/img/reportsIcons/productorIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import ItemTotal from 'components/pdf-components/ItemTotal';
import ListData from 'components/pdf-components/ListData';
import TableInfo from 'components/pdf-components/TableInfo/index';

import styles from './styles/reports';
import NumberTotal from 'components/pdf-components/NumberTotal';
import ItemTotalLeft from 'components/pdf-components/ItemTotalLeft';
import ChartList from 'components/pdf-components/ChartList';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF';
import LoteInfoTitle from './../../../../../../components/LoteInfoTitle/index';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const clasificacion = [
  {
    chart: 'S',
    magro: '%M > 60%',
    no: '12',
    percentage: '16%',
  },
  {
    chart: 'E',
    magro: '%55 < %M > 60%',
    no: '28',
    percentage: '35%',
  },
  {
    chart: 'U',
    magro: '%50 < %M > 55%',
    no: '28',
    percentage: '35%',
  },
  {
    chart: 'R',
    magro: '%45 < %M > 50%',
    no: '08',
    percentage: '10%',
  },
  {
    chart: 'O',
    magro: '%40 < %M > 45%',
    no: '01',
    percentage: '02%',
  },
  {
    chart: 'P',
    magro: '%M < 40%',
    no: '01',
    percentage: '02%',
  },
];

const detalleEvaluacion = [
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
  {
    lote: '306001',
    canal: '306001',
    seurop: 'E',
    pcc: '100,2',
    pcr: '138,22',
    temp: '29,22',
    gd: '11,60',
    lm: '70,60',
    mc: '70,60',
    pmc: '70,60',
    indicio: '-',
    inspeccion: '2',
  },
];

interface MyDocProps {
  qr: string;
  graph?: string;
  uid?: null | number | string;
  range?: null | number | string;
}

export const MyDocCavaLot = ({ qr, graph, uid, range }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={6700}
        reportImage={cavaLogo}
        number="023456789"
        title="Reporte Inventario Canales Refrigeradas"
        code="AM-001"
      />

      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={productorIcon}
          title="Productor"
          items={{
            Nombre: 'Nombre productor',
            NIT: '34123412-2',
            Teléfono: '+57 314 6543 543',
            Email: 'info@productor.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ marginTop: '5px', marginBottom: '5px' }}>
        <Subtitle
          title="Resumen Inventario Canales Refrigeradas del Lote"
          widthContainer="100%"
        />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View
          style={{
            width: '21.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="C16"
            info="Nro de Cava"
            position="right"
            textAlign="left"
            backgroundColor="#d47d57"
            widthInfo="50px"
            widthCircle={32}
          />
        </View>
        <View
          style={{
            width: '21.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="68"
            info="Total Canales Lote"
            position="right"
            widthValue={50}
            widthInfo="45px"
            fontSizeValue={12}
            backgroundColor="#be5948"
          />
        </View>
        <View
          style={{
            width: '21.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View style={{ marginBottom: '3px' }}>
            <NumberTotal
              value="83,76%"
              info="Rendimiento Pie / Canal"
              position="bottom"
              widthInfo="120px"
              widthValue="120px"
              justifyValue="left"
              justifyInfo="left"
              colorValue="#373737"
              fontSizeValue={10}
            />
          </View>
          <NumberTotal
            value="6.891,87 Kg"
            info="Peso Total Canales Calientes"
            position="bottom"
            widthInfo="120px"
            widthValue="120px"
            justifyValue="left"
            justifyInfo="left"
            colorValue="#373737"
            fontSizeValue={10}
          />
        </View>
        <View
          style={{
            width: '29.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ListData
            widthContainer="95%"
            widthtTitle="55%"
            widthValue="45%"
            items={{
              'Ingreso Cava': '22/01/2020 2:00:00',
              'Tiempo en Cavas': '48:00:00',
              'Temperatura Promedio': '13°C',
            }}
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: 45,
          marginTop: '10px',
          marginBottom: '10px',
          backgroundColor: '#ededed',
        }}
      >
        <View
          style={{
            width: '19.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="46,03 %"
            info="MC (%)"
            position="right"
            backgroundColor="#be5948"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '19.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="29,08 m.m."
            info="GD (m.m)"
            position="right"
            backgroundColor="#d47d57"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '19.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="70,06 m.m."
            info="LM (m.m)"
            position="right"
            backgroundColor="#e29662"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '19.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="85,66 %"
            info="RCCL (%)"
            position="right"
            backgroundColor="#f1ae6c"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '19.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="16,08 m.m."
            info="RCRL (%)"
            position="right"
            backgroundColor="#ffc676"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
      </View>

      <View>
        <Subtitle
          title="Distribución de Canales Procinas según Clasificación S.E.U.R.O.P."
          widthContainer="100%"
        />

        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          <View
            style={{
              width: '58.3%',
              height: '150px',
              backgroundColor: '#ededed',
              marginTop: '5px',
              justifyContent: 'center',
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View
                style={{
                  height: '120px',
                  width: '50%',
                  marginTop: 10,
                  marginBottom: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 15,
                }}
              >
                <Image
                  src={graph}
                  style={{ height: '150px', width: '150px' }}
                />
              </View>

              <View
                style={{
                  width: '60%',
                  marginRight: 25,
                }}
              >
                <ChartList
                  data={[
                    {
                      label: 'S - Superior',
                      data: 12,
                      bg: '#FFC676',
                    },
                    {
                      label: 'E - Excelente',
                      data: 28,
                      bg: '#F1AE6C',
                    },
                    {
                      label: 'U - Muy Buena',
                      data: 28,
                      bg: '#E29662',
                    },
                    {
                      label: 'R - Menos Buena',
                      data: 8,
                      bg: '#D47D57',
                    },
                    {
                      label: 'O - Aceptable',
                      data: 1,
                      bg: '#BE5948',
                    },
                    {
                      label: 'P - Pobre',
                      data: 1,
                      bg: '#B0413E',
                    },
                  ]}
                />
              </View>
            </View>
          </View>
          <View style={{ width: '38.3%' }}>
            <View style={{ width: '48.3%' }}>
              <View style={styles.tableMagro}>
                <View
                  style={[
                    styles.tableRow,
                    {
                      marginBottom: '5px',
                      marginTop: '5px',
                      alignItems: 'center',
                    },
                  ]}
                >
                  <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                    <Text style={[styles.tableCell, styles.tableHead]}></Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                    <Text style={[styles.tableCell, styles.tableHead]}>
                      % Magro
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                    <Text style={[styles.tableCell, styles.tableHead]}>
                      No.
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                    <Text style={[styles.tableCell, styles.tableHead]}>%</Text>
                  </View>
                </View>
                {clasificacion.map((item: any, index: number) => (
                  <View
                    key={index}
                    wrap={false}
                    style={[
                      styles.tableRow,
                      {
                        backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                      },
                    ]}
                  >
                    <View
                      style={[styles.tableCol, { maxWidth: 40, width: 40 }]}
                    >
                      <Text
                        style={[
                          styles.tableCellPost,
                          { fontSize: 8, fontWeight: 'bold' },
                        ]}
                      >
                        {item.chart}
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 75, width: 75 }]}
                    >
                      <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                        {item.magro}
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 45, width: 45 }]}
                    >
                      <Text
                        style={
                          (styles.tableCellPost, { fontSize: 7, marginTop: 5 })
                        }
                      >
                        {item.no}
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 50, width: 50 }]}
                    >
                      <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                        {item.percentage}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <ItemTotalLeft
              value="08"
              info="Total Canales con Grasa Dorsal Mayor a 18,5"
              backgroundColor="#373737"
              heightValue={12}
              fontSizeValue={8}
              widthValue={50}
              widthInfo="150px"
              alignInfo="left"
            />
            <ItemTotalLeft
              value="19,51%"
              info="Porcentaje Canales Grasa Dorsal Mayor a 18,5"
              backgroundColor="#373737"
              heightValue={12}
              fontSizeValue={8}
              widthValue={50}
              widthInfo="150px"
              alignInfo="left"
            />
          </View>
        </View>
      </View>

      <View style={{ marginTop: '5px', marginBottom: '5px' }}>
        <Subtitle
          title="Detalle del Inventario de Canales Refrigeradas"
          widthContainer="100%"
        />
      </View>

      <View style={{ width: '100%' }}>
        <View style={[styles.tableMagro, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '10px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote IP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canal ID</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>SEUROP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>PCC</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>(Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>PCR</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>(Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Temp</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>(°C)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>GD</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>(M.M)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>LM</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>(M.M)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>MC</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>(%)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>PMC</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>(Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Indicio</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>(PSE)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Inspección
              </Text>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Veterinaria
              </Text>
            </View>
          </View>
          {detalleEvaluacion.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.lote}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.canal}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.seurop}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.pcc}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.pcr}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.temp}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.gd}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.lm}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.mc}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.pmc}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.indicio}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.inspeccion}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 46.25,
                width: 46.25,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 92.5, width: 92.5, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              100,2
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              138,22
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              29,22
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              11,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              70,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              70,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              70,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              34
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.conventions} wrap={false}>
        <View style={styles.backgroundMagro}>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <View style={{ width: '23.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PCC (Kg)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Peso Canal Caliente</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * GD (m.m)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Grasa Dorsal</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * MC (%)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Porcentaje de Magro Canal</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '23.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * MC (Kg)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Peso Magro Canal</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * LM (m.m)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Profundidad del Lomo</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (M)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Macho</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '26.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (H)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Hembra</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * Inst. Med.
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Instrumento de Medición</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroCE}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PSE
                  </Text>
                </View>
                <View style={styles.textMagroC}>
                  <Text>Pálido, Suave, Exudativo</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '23.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (*)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Si tiene PSE</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (.)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>No tiene indicio PSE</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (?)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Probable PSE</Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              marginLeft: '10px',
            }}
          >
            <View style={styles.textconventions}>
              <View style={styles.gmagroS}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * Clasificación Canal
                </Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * S
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Superior</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * E
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Excelente</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * U
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Muy Buena</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * R
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Menos Buena</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * O
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Aceptable</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * P
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Pobre</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.borderMagro}>
          <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
            Convenciones
          </Text>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screenqrcavalote" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte Inventario Canales Refrigeradas"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphCavaLot = () => {
  return (
    <div id="graphcavalot" style={{ width: 500, marginTop: -20 }}>
      <Graphicpdfsf
        textTotalData="Total"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        startAngle={-90}
        endAngle={270}
        data={[
          {
            label: 'S - Superior',
            data: 12,
            bg: '#FFC676',
          },
          {
            label: 'E - Excelente',
            data: 28,
            bg: '#F1AE6C',
          },
          {
            label: 'U - Muy Buena',
            data: 28,
            bg: '#E29662',
          },
          {
            label: 'R - Menos Buena',
            data: 8,
            bg: '#D47D57',
          },
          {
            label: 'O - Aceptable',
            data: 1,
            bg: '#BE5948',
          },
          {
            label: 'P - Pobre',
            data: 1,
            bg: '#B0413E',
          },
        ]}
      />
    </div>
  );
};
