import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  BasicVariant,
  PrimaryVariant,
  PrimaryOutlineVariant,
  SecondaryVariant,
} from "./_variants";

import { shadowRegular } from "./_properties";
import { slideInLeft } from "./_animations";

export const StyledButton = styled.button<any>`
  ${(props) => {
    if (!props.variant) {
      props.variant = "none";
    }
    return props.variant === "primary"
      ? PrimaryVariant
      : props.variant === "primary-outline"
      ? PrimaryOutlineVariant
      : props.variant === "secondary"
      ? SecondaryVariant
      : BasicVariant;
  }}
  ${(props) => {
    if (props.withShadow) {
      return shadowRegular;
    }
  }}

  &:active {
    transform: scale(0.95);
  }
`;
export const StyledLink = styled(Link)<any>`
  ${(props) => {
    if (!props.variant) {
      props.variant = "none";
    }
    return props.variant === "primary"
      ? PrimaryVariant
      : props.variant === "primary-outline"
      ? PrimaryOutlineVariant
      : props.variant === "secondary"
      ? SecondaryVariant
      : BasicVariant;
  }}
  ${(props) => {
    if (props.withShadow) {
      return shadowRegular;
    }
  }}

  &:hover {
    color: #ffff;
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const FabButton = styled.button`
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 100px;
  background: #dd9835;
  color: #fff;
  border: 0;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 75%;

    &.right {
      transform: rotate(270deg);
      margin-left: 3px;
    }

    &.left {
      transform: rotate(90deg);
      margin-right: 3px;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    ${shadowRegular()}
    transition: .3s ease;
  }
`;

export const ButtonInfo = styled.span`
  width: 20px;
  height: 20px;
  background-color: #dd9835;
  color: #ffff;
  line-height: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  transition: 0.2s ease;

  &:hover {
    ${shadowRegular()}
    background-color: #CC8B31;
    transition: 0.2s ease;
  }
`;

export const ButtonsRow = styled.div<any>`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  text-align: left;
  margin: 15px 0 30px;
`;

export const FeaturedText = styled.h3`
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  margin: 5px 0;
`;

export const DescriptionText = styled.p`
  font-size: 12px;
  color: #afafaf;
  font-family: "Roboto", sans-serif;
`;

export const PagesTitle = styled.h1<any>`
  font-family: "Raleway", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: #ffff;

  ${(props) =>
    props.animatedIn ? slideInLeft({ delay: props.animatedIn.delay }) : ""}
  & > span.destacado {
    color: #dd9835;
    background: #ffffff;
    padding: 0 5px 5px;
    margin-top: -5px;
  }

  @media screen and (min-width: 300px) and (max-width: 800px) {
    font-size: 28px;
  }
`;

export const CardRow = styled.div`
  margin: 0 2%;
  width: 70%;
`;

const Container = styled.div`
  max-width: 350px;
  padding: 20px 50px;
  border-radius: 10px;
  background-color: #fff;
  ${shadowRegular()}
  transition: .3s ease;

  &:hover {
    transform: scale(1.05);
    transition: 0.3s ease;
  }
`;

const Title = styled.h2`
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #212121;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-family: "Roboto", arial, serif;
  font-size: 14px;
  color: #707070;
`;

export const Card = {
  Container,
  Title,
  Text,
};
