import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Fab,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import SelectDate from 'components/SelectDate';
import ExistingUser from 'components/ExistingUser';
import reportsIcons from 'lib/helpers/reportsIcons';

import dayjs from 'dayjs';
import _ from 'lodash';
import clsx from 'clsx';

import useStyles from './styles';

interface EditFrequentProps {
  children: any;
}

const EditFrequent = ({ children }: EditFrequentProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openEditReports, setOpenEditRopenEditReports] = useState(false);
  const [openReschedule, setOpenReschedule] = useState(false);
  const [openDenyAccess, setOpenDenyAccess] = useState(false);
  const [idDestinyDelete, setIdDestinyDelete] = useState(0);
  const [shareUnlimited, setShareUnlimited] = useState(false);
  const [initialDate, setInitialDate] = useState(dayjs().day(1));
  const [finalDate, setFinalDate] = useState(dayjs());
  const [reportsSelected, setReportsSelected] = useState<any>([]);
  const data = {
    name: 'Tiquete de Báscula',
    details: {
      'Lote Infoporcinos': 2040,
      'Nro de Reporte': 203050,
      'Fecha de Creación': dayjs().format('DD-MM-YYYY'),
    },
  };

  const reports = [
    {
      reportType: 'Todos',
      id: 1,
    },
    {
      reportType: 'Portería',
      id: 2,
    },
    {
      reportType: 'Guias de Movilización',
      id: 3,
    },
    {
      reportType: 'Tiquete de Báscula',
      id: 4,
    },
    {
      reportType: 'Inmunocastración',
      id: 5,
    },
    {
      reportType: 'Antemortem',
      id: 6,
    },
    {
      reportType: 'Visceras Blancas',
      id: 7,
    },
    {
      reportType: 'Postmortem',
      id: 8,
    },
    {
      reportType: 'Evaluación de magro',
      id: 9,
    },
    {
      reportType: 'Distribución y Despacho',
      id: 10,
    },
  ];

  const [destinations, setDestinations] = useState([
    {
      name: 'Exito Laureles',
      info: 'Calle 43 # 13 A 21',
      id: 4,
      since: dayjs().format('DD-MM-YYYY'),
      until: 'Indefinidamente',
    },
    {
      name: 'Ricata',
      info: 'Carrera 43C # 13 C 11',
      id: 5,
      since: dayjs().format('DD-MM-YYYY'),
      until: dayjs().date(20).format('DD-MM-YYYY'),
    },
    {
      name: 'Zenú',
      info: 'Carrera 77A # 18 - 11',
      id: 6,
      since: dayjs().format('DD-MM-YYYY'),
      until: dayjs().date(20).format('DD-MM-YYYY'),
    },
  ]);

  const changeReportsSelected = (report: number) => {
    if (report === 1) {
      if (reportsSelected.includes(1)) {
        setReportsSelected([]);
      } else {
        setReportsSelected(reports.map((item: any) => item.id));
      }
    } else if (reportsSelected.includes(report)) {
      const removed = reportsSelected.filter(
        (item: any) => item !== report && item !== 1
      );
      setReportsSelected(removed);
    } else {
      setReportsSelected([...reportsSelected, report]);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenEditReport = () => {
    handleClose();
    setOpenEditRopenEditReports(true);
  };

  const handleOpenDenyAccess = (id: number) => {
    handleClose();
    setOpenDenyAccess(true);
    setIdDestinyDelete(id);
  };
  const handleCloseDenyAccess = () => {
    setOpenDenyAccess(false);
    handleOpen();
  };

  const removeDestiny = (e: number) => {
    const removed = destinations.filter((item: any) => item.id !== e);
    setDestinations(removed);
    handleCloseDenyAccess();
  };

  const handleCloseEditReports = () => {
    setOpenEditRopenEditReports(false);
    handleOpen();
  };

  const handleOpenReschedule = () => {
    handleClose();
    setOpenReschedule(true);
  };
  const handleCloseReschedule = () => {
    setOpenReschedule(false);
    handleOpen();
  };

  return (
    <>
      {children({ handleOpen })}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            Editar Accesos del usuario
          </Typography>
          <Typography color="secondary" align="center">
            A continuación podrá editar los tipos reportes y la fecha limite a
            los que tiene acceso el ususario o denegar totalmente el acceso a
            los reportes con el destino especifico.
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <Box width="100%" margin="12px 0px">
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Destino</TableCell>
                  <TableCell align="center">Compartido Desde</TableCell>
                  <TableCell align="center">Compartido Hasta</TableCell>
                  <TableCell>Editar Reportes</TableCell>
                  <TableCell>Reprogramar Acceso</TableCell>
                  <TableCell>Denegar Acceso</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {destinations.map((item: any) => {
                  return (
                    <TableRow className={classes.itemGrey}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="center">{item.since}</TableCell>
                      <TableCell align="center">{item.until}</TableCell>
                      <TableCell align="center">
                        <Fab
                          size="small"
                          color="primary"
                          onClick={handleOpenEditReport}
                        >
                          <i
                            className="icon-print"
                            style={{ fontSize: '20px' }}
                          />
                        </Fab>
                      </TableCell>

                      <TableCell align="center">
                        <Fab
                          size="small"
                          color="primary"
                          onClick={handleOpenReschedule}
                        >
                          <i
                            className="icon-calendar"
                            style={{ fontSize: '20px' }}
                          />
                        </Fab>
                      </TableCell>
                      <TableCell align="center">
                        <Fab
                          size="small"
                          color="primary"
                          onClick={() => handleOpenDenyAccess(item.id)}
                        >
                          <i
                            className="icon-close"
                            style={{ fontSize: '20px' }}
                          />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditReports}
        onClose={handleCloseEditReports}
        className={classes.dialogContainer}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            Editar Reportes con Acceso
          </Typography>
          <Typography color="secondary" align="center">
            A continuación tiene la opción de definir un tiempo limite para dar
            acceso a los usuarios seleccionados para ver el reporte, si no
            define un tiempo limite, el reporte se compartirá de manera
            indefinida.
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <Grid container xs={12}>
            {reports.map((item: any) => {
              return (
                <Grid xs={4} item>
                  <ExistingUser
                    name={item.reportType}
                    icon={reportsIcons(item.reportType)}
                    selected={reportsSelected.includes(item.id)}
                    onClick={() => {
                      changeReportsSelected(item.id);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            onClick={handleCloseEditReports}
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleCloseEditReports}
            variant="contained"
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openReschedule}
        onClose={handleCloseReschedule}
        className={classes.dialogContainer}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            Reprogramar fecha final de Acceso al reporte
          </Typography>
          <Typography color="secondary" align="center">
            A continuación tiene la opción de definir un tiempo limite para dar
            acceso a los usuarios seleccionados para ver el reporte, si no
            define un tiempo limite, el reporte se compartirá de manera
            indefinida.
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <Box width="100%" marginTop={2}>
            <Grid
              container
              xs={12}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={4}>
                <SelectDate
                  value={initialDate}
                  label="Fecha Inicial"
                  onChange={(e: any) => setInitialDate(e)}
                  disabled={true}
                />
              </Grid>
              <Grid container item xs={1} justifyContent="center">
                Hasta
              </Grid>
              <Grid item xs={4}>
                <SelectDate
                  label="Fecha Final"
                  onChange={(e: any) => setFinalDate(e)}
                  disabled={shareUnlimited}
                  limitDate={initialDate}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={2}>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  value={shareUnlimited}
                  name="promotion"
                  color="primary"
                  onChange={() => setShareUnlimited(!shareUnlimited)}
                />
              }
              label="Compartir de manera indefinida"
              labelPlacement="start"
            />
          </Box>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            onClick={handleCloseReschedule}
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleCloseReschedule}
            variant="contained"
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDenyAccess}
        onClose={handleCloseDenyAccess}
        className={classes.dialogContainer}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.contentDialog}>
          <i className={clsx('icon-circle-alert', classes.iconDialogCheck)} />
          <Typography variant="h6">
            Denegar Acceso a los reportes con destino{' '}
            {destinations
              .filter((item: any) => item.id === idDestinyDelete)
              .map((destiny: any) => destiny.name)}
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            onClick={handleCloseDenyAccess}
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => removeDestiny(idDestinyDelete)}
            variant="contained"
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditFrequent;
