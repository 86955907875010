import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import IsoType from 'static/img/logos/ip-icon.svg';

const useStyles = makeStyles({
  header_logo: {
    width: 150,
    minWidth: 150,
  },
  header_logo_folded: {
    width: 50,
    minWidth: 50,
  },
});

const Logo = ({ size }: { size?: 'large' | 'small' }) => {
  const classes = useStyles();
  const { folded, foldedOpen } = useSelector((state: any) => state.ui.sidebar);

  const foldedAndClosed = folded && !foldedOpen;
  const foldedAndOpened = folded && foldedOpen;

  if (size) {
    if (size === 'large')
      return (
        <img
          src={IsoType}
          alt="logo-ip"
          className={classes.header_logo_folded}
        />
      );
  }

  if (foldedAndClosed)
    return (
      <img src={IsoType} alt="logo-ip" className={classes.header_logo_folded} />
    );
  if (foldedAndOpened)
    return (
      <img src={IsoType} alt="logo-ip" className={classes.header_logo_folded} />
    );
  if (!folded)
    return (
      <img src={IsoType} alt="logo-ip" className={classes.header_logo_folded} />
    );
  return null;
};

export default Logo;
