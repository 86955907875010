import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";

import MainTitle from "components/MainTitle";
import Results from "components/Results";
import CardDetailFD from "components/PageComponents/Contacts/CardDetailFD";
import InformativeScreen from "components/InformativeScreens";

import {
  GET_DESTINATIONS_BY_USER,
  GET_FARMS_BY_USER,
} from "core/graphql/queries/contacts";

import useStyles from "./styles";

type DialogProps = {
  open: boolean;
  idUser: number;
  type: string;
  contactName: string;
  handleClose: () => void;
};

const DialogDetailFD = ({
  open,
  idUser,
  type,
  contactName,
  handleClose,
}: DialogProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);

  const [searchFilter, setSearchFilter] = useState({
    search: "",
  });

  const [
    getFarms,
    { data: dataFarms, loading: loadingFarms, error: errorFarms },
  ] = useLazyQuery(GET_FARMS_BY_USER, {
    fetchPolicy: "network-only",
  });

  const [
    getDestinations,
    {
      data: dataDestinations,
      loading: loadingDestinations,
      error: errorDestinations,
    },
  ] = useLazyQuery(GET_DESTINATIONS_BY_USER, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (open && type === "granjas") {
      getFarms({
        context: { headers: { "x-auth-jwt": token } },
        variables: { idUsuarioContacto: idUser },
      });
    } else if (open && type === "destinos") {
      getDestinations({
        context: { headers: { "x-auth-jwt": token } },
        variables: { idUsuarioContacto: idUser },
      });
    }
  }, [open, type]);

  const filterFarms = dataFarms?.getFarmsXContact?.data?.filter((item: any) => {
    return item?.nombreGranja?.includes(searchFilter.search);
  });

  const filterDestination =
    dataDestinations?.getDestinationsXContact?.data?.filter((item: any) => {
      return item?.nombreDestino?.includes(searchFilter.search);
    });

  const handleInputSearch = (event: any) => {
    setSearchFilter({
      search: event.target.value,
    });
  };

  useEffect(() => {
    if (!open) {
      setSearchFilter({
        search: "",
      });
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="body"
      className={classes.borderRadiudDialog}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dia
      log-title"
    >
      <DialogContent>
        <Grid container className={classes.headerContainer}>
          <Grid item xs={7} container direction="row" alignItems="center">
            <MainTitle>
              {type === "granjas"
                ? "Granjas registradas por:"
                : "Destinos registrados por:"}
            </MainTitle>
            <Box ml={2}>
              <Typography className={classes.fontName} variant="h6">
                {contactName}
              </Typography>
            </Box>
          </Grid>

          <Grid
            container
            item
            xs={5}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid container item xs={4}>
              <Box>
                <Results
                  number={
                    type === "granjas"
                      ? filterFarms?.length
                      : filterDestination?.length
                  }
                />
              </Box>
            </Grid>
            <Grid container item xs={8}>
              <TextField
                label="Búsqueda"
                variant="outlined"
                fullWidth
                onChange={handleInputSearch}
                value={searchFilter.search}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Box
                        component="i"
                        fontSize="20px"
                        className="icon-search"
                      />
                    </InputAdornment>
                  ),
                }}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid spacing={1}>
          {type === "granjas" && (
            <>
              <Grid container spacing={2}>
                {filterFarms?.length > 0 &&
                  filterFarms?.map((item: any, index: any) => (
                    <Grid item key={item?.idGranja} style={{ margin: 18 }}>
                      <CardDetailFD
                        name={item?.nombreGranja}
                        cellPhone={item?.numeroCelular}
                        ubication={item?.ubicacion}
                        direction={item?.direccion}
                      />
                    </Grid>
                  ))}

                {filterFarms?.length === 0 && !loadingFarms && (
                  <Grid item container justifyContent="center">
                    <InformativeScreen
                      type="notFound"
                      subtitle="¡No hay nada por aquí!"
                      info={
                        "No se encontraron granjas registradas para este contacto."
                      }
                      onlyError
                    />
                  </Grid>
                )}

                {errorFarms && !loadingFarms && (
                  <Grid item container justifyContent="center">
                    <InformativeScreen
                      type="technicalError"
                      subtitle="¡Ha ocurrido un error inesperado!"
                      info="Estamos trabajando para resolverlo."
                      onlyError
                    />
                  </Grid>
                )}
              </Grid>
              {loadingFarms && (
                <Box textAlign="center" marginTop={5}>
                  <CircularProgress color="primary" size={80} />
                  <Typography>Cargando...</Typography>
                </Box>
              )}
            </>
          )}

          {type === "destinos" && (
            <>
              <Grid container spacing={2}>
                {filterDestination?.length > 0 &&
                  filterDestination?.map((item: any, index: any) => (
                    <Grid item key={item?.idDestino} style={{ margin: 18 }}>
                      <CardDetailFD
                        name={item?.nombreDestino}
                        destinationType={item?.tipoDestino}
                        cellPhone={item?.celular}
                        ubication={item?.ubicacion}
                        direction={item?.direccion}
                      />
                    </Grid>
                  ))}

                {filterDestination?.length === 0 && !loadingDestinations && (
                  <Grid item container justifyContent="center">
                    <InformativeScreen
                      type="notFound"
                      subtitle="¡No hay nada por aquí!"
                      info={
                        "No se encontraron destinos registrados para este contacto."
                      }
                      onlyError
                    />
                  </Grid>
                )}

                {errorDestinations && !loadingDestinations && (
                  <Grid item container justifyContent="center">
                    <InformativeScreen
                      type="technicalError"
                      subtitle="¡Ha ocurrido un error inesperado!"
                      info="Estamos trabajando para resolverlo."
                      onlyError
                    />
                  </Grid>
                )}
              </Grid>
              {loadingDestinations && (
                <Box textAlign="center" marginTop={5}>
                  <CircularProgress color="primary" size={80} />
                  <Typography>Cargando...</Typography>
                </Box>
              )}
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button variant="contained" onClick={handleClose} color="primary">
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default DialogDetailFD;
