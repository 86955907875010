import React from 'react';
import { Box, Avatar, Fab, Typography } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { Share } from '@material-ui/icons';

import FooterGrid from 'components/datagrid-custom-components/TableFooter';

import dayjs from 'dayjs';
import clsx from 'clsx';

import useStyles from './styles';

const SharedWithMe = ({ type }: any) => {
  const classes = useStyles();
  const users = [
    {
      id: 1,
      nameType: 'Tiquete de Báscula',
      iconType: 'icon-ticket',
      loteIP: 2041,
      reportNumber: 203050,
      created_at: dayjs().format('DD/MM/YYYY'),
      comercializador: 'Ivan Echeverri',
      productor: 'Nestor Angel',
      sharedBy: 'Paula Bolivar',
      sharedSince: dayjs().format('DD/MM/YYYY'),
      sharedUntil: dayjs().date(30).format('DD/MM/YYYY'),
    },
    {
      id: 2,
      nameType: 'Portería',
      iconType: 'icon-porteria',
      loteIP: 2042,
      reportNumber: 203052,
      created_at: dayjs().date(20).format('DD/MM/YYYY'),
      comercializador: 'Ivan Echeverri',
      productor: 'Nestor Angel',
      sharedBy: 'Sebastian Mosquera',
      sharedSince: dayjs().format('DD/MM/YYYY'),
      sharedUntil: dayjs().date(30).format('DD/MM/YYYY'),
    },
    {
      id: 3,
      nameType: 'Viseras Blancas',
      iconType: 'icon-sausage',
      loteIP: 1920,
      reportNumber: 103052,
      created_at: dayjs().date(20).format('DD/MM/YYYY'),
      comercializador: 'Ivan Echeverri',
      productor: 'Nestor Angel',
      sharedBy: 'Sebastian Mosquera',
      sharedSince: dayjs().format('DD/MM/YYYY'),
      sharedUntil: dayjs().date(30).format('DD/MM/YYYY'),
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: 'Tipo de Reporte',
      renderCell: (params: GridValueGetterParams) => (
        <Box display="flex" alignItems="center">
          <Avatar className={classes.avatar}>
            <i className={clsx(params.getValue(params.id, 'iconType'))} />
          </Avatar>

          <Typography>{params.getValue(params.id, 'nameType')}</Typography>
        </Box>
      ),
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 200,
      sortable: false,
      headerAlign: 'center',
      hide: type !== 'Todos',
    },
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'reportNumber',
      headerName: 'N° de Reporte',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'created_at',
      headerName: 'Fecha Creación',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'sharedSince',
      headerName: 'Compartido Desde',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'sharedUntil',
      headerName: 'Compartido Hasta',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'sharedBy',
      headerName: 'Compartido Por',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 200,
    },
    {
      field: 'productor',
      headerName: 'Productor',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 200,
    },
    {
      field: 'comercializador',
      headerName: 'Comercializador',
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 200,
      sortable: false,
    },
    {
      field: 'Destino',
      headerName: 'Destino',
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 200,
      sortable: false,
      hide: type !== 'Distribución y Despachos',
    },
    {
      field: 'Ver Reporte',
      headerName: '',
      renderCell: () => (
        <Box>
          <Fab color="primary" className={classes.btnDetails}>
            <Box component="i" fontSize="20px" className="icon-print" />
          </Fab>
        </Box>
      ),
      disableColumnMenu: true,
      sortable: false,
      headerClassName: classes.headerTableGrid,
      headerAlign: 'center',
      align: 'center',
      width: 110,
    },
  ];

  return (
    <Box>
      <DataGrid
        pageSize={10}
        columns={columns}
        rows={users}
        className={classes.dataGrid}
        components={{
          Footer: FooterGrid,
        }}
      />
    </Box>
  );
};

export default SharedWithMe;
