import { Document, Page, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import plantIcon from 'static/img/reportsIcons/plantIcon';
import productorIcon from 'static/img/reportsIcons/productorIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';
import mobilizationLogo from 'static/img/reportsIcons/mobilizationLogo';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import TableInfo from 'components/pdf-components/TableInfo/index';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ListData from 'components/pdf-components/ListData/index';
import ItemTotal from 'components/pdf-components/ItemTotal/index';

import styles from './styles/lote';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
}

export const MyDoc = ({ qr }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={6700}
        reportImage={mobilizationLogo}
        number="023456789"
        title="GSMI - Guía Sanitaria Movilización Animales"
        code="MI-001"
      />
      <View style={styles.detailsplant}>
        <View style={styles.backgroundresumetitle}>
          <Text>Agentes Relacionados con el Negocio</Text>
        </View>
      </View>

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={productorIcon}
          title="Productor"
          items={{
            Nombre: 'Nombre productor',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@productor.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          marginTop: '10px',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: '50%', marginTop: 7 }}>
          <Subtitle
            title="Resumen de la Movilización de Animales"
            widthContainer="96%"
          />
          <View
            style={{
              width: '100%',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                width: '40%',
                backgroundColor: '#ededed',
                marginTop: '10px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ItemTotal
                value="52"
                info="Animales Movilizados"
                widthInfo="40px"
                position="bottom"
              />
            </View>

            <ListData
              widthContainer="50%"
              items={{
                'Lote Ceba': '123456',
                'Tiquete de Báscula': '12345678',
                'Fecha Guía Sanitaria': '15/10/2019',
                'Guía Valida Hasta': '15/10/2019',
                'Guía de Movilización': '11-2344123',
              }}
              widthtTitle="60%"
              widthValue="40%"
            />
          </View>
          <View
            style={{
              width: '100%',
            }}
          >
            <View
              style={{
                margin: 'auto',
                textAlign: 'center',
                marginTop: '10px',
                width: '65%',
              }}
            >
              <Text>
                El total de animales puede estar dividido en varios Lotes
                InfoPorcinos en el sistema.
              </Text>
            </View>
          </View>
        </View>

        <TableInfo
          icon={marketerIcon}
          title="Detalles del Transporte"
          widthContainer="50%"
          items={{
            Conductor: 'Luis Fernando Lopez Contreras',
            Documento: '2546264343',
            Licencia: '21343323223-2',
            Placa: 'RJD 324',
            Marca: 'Toyota',
            Modelo: '65-G3',
            Color: 'Blancos',
          }}
        />
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screen" style={{ width: 500 }}>
      <QRCode
        size={500}
        value="Reporte por Lote Guía Sanitaria Movilización Animales"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
