import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    textAlign: 'center',
    padding: 30,
    fontSize: 8,
    fontFamily: 'Roboto',
    fontWeight: 300,
  },
  table: {
    display: 'table',
    width: '48.3%',
    borderStyle: 'solid',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '16.5%',
  },
  tableCell: {
    marginTop: 5,
    fontSize: 8,
    textAlign: 'center',
  },
  tableHead: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCellAnte: {
    height: '13px',
    fontSize: 8,
    marginTop: 5,
    textAlign: 'center',
  },
});
