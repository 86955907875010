import { Document, Page, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import corralLogo from 'static/img/reportsIcons/corralLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import HorizontalTableInfo from 'components/pdf-components/HorizontalTableInfo/index';
import ItemTotal from 'components/pdf-components/ItemTotal';
import ListData from 'components/pdf-components/ListData';

import styles from './styles/date';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const detalle = [
  {
    lote: '1234',
    animales: 28,
    co: 1,
    sbe: 0,
    muertosCo: 0,
    saldo: 1,
    cr: 28,
    enviados: 0,
    muertosCr: 0,
    totalE: 28,
    horas: '4:32:00',
    nroCorrales: 'C10, C11, C12, C13',
  },
  {
    lote: '1234',
    animales: 28,
    co: 1,
    sbe: 0,
    muertosCo: 0,
    saldo: 1,
    cr: 28,
    enviados: 0,
    muertosCr: 0,
    totalE: 28,
    horas: '4:32:00',
    nroCorrales: 'C10, C11, C12, C13',
  },
  {
    lote: '1234',
    animales: 28,
    co: 1,
    sbe: 0,
    muertosCo: 0,
    saldo: 1,
    cr: 28,
    enviados: 0,
    muertosCr: 0,
    totalE: 28,
    horas: '4:32:00',
    nroCorrales: 'C10, C11, C12, C13',
  },
  {
    lote: '1234',
    animales: 28,
    co: 1,
    sbe: 0,
    muertosCo: 0,
    saldo: 1,
    cr: 28,
    enviados: 0,
    muertosCr: 0,
    totalE: 28,
    horas: '4:32:00',
    nroCorrales: 'C10, C11, C12, C13',
  },
  {
    lote: '1234',
    animales: 28,
    co: 1,
    sbe: 0,
    muertosCo: 0,
    saldo: 1,
    cr: 28,
    enviados: 0,
    muertosCr: 0,
    totalE: 28,
    horas: '4:32:00',
    nroCorrales: 'C10, C11, C12, C13',
  },
  {
    lote: '1234',
    animales: 28,
    co: 1,
    sbe: 0,
    muertosCo: 0,
    saldo: 1,
    cr: 28,
    enviados: 0,
    muertosCr: 0,
    totalE: 28,
    horas: '4:32:00',
    nroCorrales: 'C10, C11, C12, C13',
  },
  {
    lote: '1234',
    animales: 28,
    co: 1,
    sbe: 0,
    muertosCo: 0,
    saldo: 1,
    cr: 28,
    enviados: 0,
    muertosCr: 0,
    totalE: 28,
    horas: '4:32:00',
    nroCorrales: 'C10, C11, C12, C13',
  },
  {
    lote: '1234',
    animales: 28,
    co: 1,
    sbe: 0,
    muertosCo: 0,
    saldo: 1,
    cr: 28,
    enviados: 0,
    muertosCr: 0,
    totalE: 28,
    horas: '4:32:00',
    nroCorrales: 'C10, C11, C12, C13',
  },
];

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
  fecha?: null | number | string;
}

export const MyDocCorralDate = ({ qr, uid, fecha }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        fecha={fecha}
        reportImage={corralLogo}
        number="023456789"
        title="Reporte Inventario Animales en Pie"
        code="AM-001"
      />

      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ width: '100%' }}>
        <HorizontalTableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            Nit: '34123412-2',
            Telefono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <View
          style={{
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: '#767676',
            marginTop: '10px',
          }}
        ></View>
        <HorizontalTableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Resumen Inventario Animales en Pie"
          widthContainer="100%"
        />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '48.3%' }}>
          <View style={{ marginBottom: '5px', marginTop: '5px' }}>
            <Subtitle title="Ingreso Potería" widthContainer="100%" />
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="8"
                info="Total Camiones Ingresados"
                position="right"
                textAlign="left"
                backgroundColor="#be5948"
                widthInfo="55px"
                widthCircle={30}
              />
            </View>
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="0"
                info="Total Ingresos Denegados"
                position="right"
                textAlign="left"
                backgroundColor="#d47d57"
                widthInfo="65px"
                widthCircle={30}
              />
            </View>
          </View>

          <View style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Subtitle
              title="Animales en Corrales de Observación"
              widthContainer="100%"
            />
          </View>

          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View
              style={{
                width: '42.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="8"
                info="Total Animalesen Observación"
                position="right"
                textAlign="left"
                backgroundColor="#d47d57"
                widthInfo="65px"
                widthCircle={35}
              />
            </View>
            <View style={{ width: '54.3%' }}>
              <ListData
                widthContainer="90%"
                widthtTitle="80%"
                widthValue="20%"
                items={{
                  'Sala Beneficio de Emergencia': '0',
                  'Muertos en Observación': '0',
                }}
              />
            </View>
          </View>
        </View>
        <View style={{ width: '48.3%' }}>
          <View style={{ marginTop: '5px', marginBottom: '10px' }}>
            <Subtitle title="Ingreso General Corrales" widthContainer="100%" />
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotal
                value="8"
                info="Total Lotes"
                position="right"
                widthValue={50}
                widthInfo="40px"
                fontSizeValue={12}
                backgroundColor="#be5948"
              />
            </View>
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotal
                value="264"
                info="Total Animales en Corrales"
                position="right"
                widthValue={50}
                widthInfo="50px"
                fontSizeValue={12}
                backgroundColor="#d47d57"
              />
            </View>
          </View>

          <View style={{ marginTop: '10px', marginBottom: '5px' }}>
            <Subtitle
              title="Animales en Corrales de Reposo"
              widthContainer="100%"
            />
          </View>

          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View
              style={{
                width: '42.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="265"
                info="Total Animales en Reposo"
                position="right"
                textAlign="left"
                backgroundColor="#d47d57"
                widthInfo="60px"
                widthCircle={35}
              />
            </View>
            <View style={{ width: '54.3%' }}>
              <ListData
                widthContainer="80%"
                widthtTitle="80%"
                widthValue="20%"
                items={{
                  'Línea de Sacrificio': '0',
                  'Muertos en Reposo': '0',
                }}
              />
            </View>
          </View>
        </View>
      </View>

      <View style={{ marginTop: '10px', marginBottom: '5px' }}>
        <Subtitle
          title="Detalle del Inventario Animales en Pie Corrales"
          widthContainer="100%"
        />
      </View>

      <View style={{ width: '100%' }}>
        <View style={[styles.tableMagro, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '10px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 40.25, width: 40.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote IP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Animales</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 36.25, width: 36.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>CO</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 36.25, width: 36.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>SBE</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 42.25, width: 42.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Muertos</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>CO</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 36.25, width: 36.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Saldo</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 36.25, width: 36.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>CR</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Enviados</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Sacrificio
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Muertos</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>CR</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Total</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Existencia
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Horas en</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>Corrales</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 96.25, width: 96.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Nro. Corrales
              </Text>
            </View>
          </View>
          {detalle.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View
                style={[styles.tableCol, { maxWidth: 40.25, width: 40.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.lote}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.animales}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 36.25, width: 36.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.co}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 36.25, width: 36.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.sbe}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 42.25, width: 42.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.muertosCo}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 36.25, width: 36.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.saldo}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 36.25, width: 36.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.cr}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.enviados}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.muertosCr}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.totalE}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.horas}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 96.25, width: 96.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.nroCorrales}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 40.25,
                width: 40.25,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              280
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 36.25, width: 36.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              9
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 36.25, width: 36.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              0
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 42.25, width: 42.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              0
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 36.25, width: 36.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              9
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 36.25, width: 36.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              280
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              0
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              0
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              280
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 142.5, width: 142.5, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
        </View>
      </View>

      <View style={styles.conventions} wrap={false}>
        <View style={styles.backgroundguide}>
          <View style={styles.textconventionsLong}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * CO
              </Text>
            </View>
            <View style={styles.textLong}>
              <Text>Corral Observación</Text>
            </View>
          </View>
          <View style={styles.textconventionsLong}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * SBE
              </Text>
            </View>
            <View style={styles.textSLong}>
              <Text>Sala Sacrificio Emergencia</Text>
            </View>
          </View>
          <View style={styles.textconventionsLong}>
            <View style={[styles.gsmi, { width: 30 }]}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * CR
              </Text>
            </View>
            <View style={styles.textLong}>
              <Text>Corral Reposo</Text>
            </View>
          </View>
        </View>
        <View style={styles.borderAM}>
          <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
            Convenciones
          </Text>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportDate = () => {
  return (
    <div id="screenqrcorraldate" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte Inventario Animales en Pie"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
