import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import SideBar from "components/Sidebar";
import constants from "config/constants";

import { General as Routes } from "routes";

import SidebarFolded from "components/SidebarFolded";
import ToolbarLayout from "components/ToolbarLayout";
import { Footer } from "../styles";
import useStyles from "./styles";
import { HasPermissionProvider } from "lib/context/HasPermissionsContext";
import { useQuery } from "@apollo/client";
import {
  GetInitialStepsQuery,
  GetInitialStepsProps,
} from "core/graphql/queries/initialDialogStatus";
interface GeneralLayoutProps {
  user?: any;
  role?: any;
  handleSignOut?: any;
  typeUsers?: any;
}

export default (props: GeneralLayoutProps) => {
  const { user, role, typeUsers } = props;
  const classes = useStyles();
  const userType = useSelector(
    (state: any) => state.user.selectedUserType.idUserType
  );
  const subscriptionType = useSelector(
    (state: any) => state.user.subscriptionPlan.type
  );
  const token = useSelector((state: any) => state.user.token);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(!open);
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const login = useSelector((state: any) => state.user.isLogged);

  const { loading, data, error } = useQuery<GetInitialStepsProps>(
    GetInitialStepsQuery,
    {
      context: {
        headers: {
          "x-auth-jwt": token,
        },
      },
    }
  );

  useEffect(() => {
    if (userType === 2) {
      if (
        login === true &&
        data?.initialSteps?.data?.comercializador?.finalizado === false
      ) {
        handleOpenDialog();
      } else if (
        login === true &&
        data?.initialSteps?.data?.comercializador?.finalizado === true
      ) {
        handleCloseDialog();
      }
    } else if (userType === 1) {
      if (
        login === true &&
        data?.initialSteps?.data?.productor?.finalizado === false
      ) {
        handleOpenDialog();
      } else if (
        login === true &&
        data?.initialSteps?.data?.productor?.finalizado === true
      ) {
        handleCloseDialog();
      }
    }
  }, [userType]);

  return (
    <HasPermissionProvider>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <SideBar open={open} onClose={handleClose} user={user} role={role} />
          <SidebarFolded />
          <div className={classes.contentWrapper}>
            <ToolbarLayout
              setOpen={setOpen}
              role={role}
              typeUsers={typeUsers}
              user={user}
              subscriptionType={subscriptionType}
            />
            <div className={classes.content}>
              <Routes />
              <Footer>{constants.FOOTER}</Footer>
            </div>
          </div>
        </div>
      </div>
    </HasPermissionProvider>
  );
};
