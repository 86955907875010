import React from "react";

import { Box, Grid, Typography, Card, CardActionArea } from "@material-ui/core";

import MainTitle from "components/MainTitle";

import Destination from "static/img/pigs/destinations.svg";

import clsx from "clsx";
import { useHistory } from "react-router-dom";

import useStyles from "./styles";

const Destinations = () => {
  const classes = useStyles();
  const history = useHistory();
  const types = [
    {
      name: "Punto de Venta",
      icon: "icon-map-marker",
      id: 1,
    },
    {
      name: "Planta de Desposte",
      icon: "icon-organs",
      id: 2,
    },
    {
      name: "Planta de Acondicionamiento",
      icon: "icon-canal",
      id: 3,
    },
  ];

  return (
    <>
      <Box>
        <Grid container spacing={2} xs={12} alignItems="center">
          <Grid item xs={6}>
            <img width="80%" src={Destination} />
          </Grid>
          <Grid item xs={6}>
            <MainTitle>REGISTRAR PUNTOS DE VENTA</MainTitle>
            <Typography color="secondary" style={{ marginTop: 8 }}>
              A continuación podrás registrar los destinos a los que llegarán
              tus productos, ya sean puntos de venta, plantas de desposte o
              plantas de acondicionamiento.
              <br />
              Para empezar a registrar tus destinos selecciona un tipo:
            </Typography>
            <Box marginY={3} className={classes.container}>
              {types.map((item: any) => {
                return (
                  <>
                    <Card className={classes.cardContainer}>
                      <CardActionArea
                        className={classes.cardContent}
                        onClick={() =>
                          history.push(
                            `/app/profile/points-of-sale/register/${item.id}`
                          )
                        }
                      >
                        <i className={clsx(item.icon, classes.cardIcon)} />
                        <Typography className={classes.cardTitle}>
                          {item.name}
                        </Typography>
                      </CardActionArea>
                    </Card>
                  </>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Destinations;
