import { gql } from "@apollo/client";

export const GENERAL_DATA = gql`
  query {
    getGeneralDataProfile {
      data {
        datosGenerales {
          idUsuario
          codigoQR
          usuario
          politicaConfidencialidad
          habeasData
          terminosUso
          fechaRegistroUsuario
          idTipoDocumento
          numeroDocumento
          digitoVerificacion
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          razonSocial
          imagen
          email
          indicativoTelefono
          idIndicativoTelefono
          telefono
          extension
          indicativoCelular
          idIndicativoCelular
          celular
          indicativoWhatsapp
          idIndicativoWhatsapp
          whatsapp
          indicativoTelegram
          idIndicativoTelegram
          telegram
          usuarioFacebook
          paginaWeb
          idCiudad
          idDepartamento
          idPais
          ciudad
          departamento
          pais
          tipoDireccion
          idTipoVia
          tipoVia
          numeroVia
          apendiceVia
          numeroCruce
          apendiceCruce
          metrosEsquina
          adicional
          codigoPostal
          longitud
          latitud
          direccion
          ubicacion
          idUsuarioRL
          idTipoDocumentoRL
          numeroDocumentoRL
          primerNombreRL
          segundoNombreRL
          primerApellidoRL
          segundoApellidoRL
          indicativoCelularRL
          idIndicativoCelularRL
          celularRL
          indicativoTelefonoRL
          idIndicativoTelefonoRL
          telefonoRL
          indicativoWhatsappRL
          idIndicativoWhatsappRL
          whatsappRL
          indicativoTelegramRL
          idIndicativoTelegramRL
          telegramRL
          emailRL
        }
        planSuscripcion {
          tiposSuscripcion
          fechaInicioSuscripcion
          fechaFinalSuscripcion
          razonSocialPatrocinador
        }
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const EXISTING_MANAGER = gql`
  query {
    getManagers {
      data {
        idEmpleado
        idTipoDocumento
        numeroDocumento
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        indicativoCelular
        celular
        email
      }
    }
  }
`;

export const UNSUBCRIBE_REASONS = gql`
  query unsubscribeAdvanced {
    unsubscribeReason {
      id
      description
    }
  }
`;

export const FARMS = gql`
  query getFarms {
    getFarms {
      data {
        idGranja
        codigoGranja
        nombreGranja
        numeroCelular
        estado
        direccion
        email
        ubicacion
        numeroWhatsapp
        fechaCreacion
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const FARM_MANAGER = gql`
  query getFarmManagerXUser($idPersonaResponsable: Int!) {
    getFarmManagerXUser(
      input: { idPersonaResponsable: $idPersonaResponsable }
    ) {
      data {
        idPersonaResponsable
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        nombreCompleto
        idTipoDocumento
        abreviatura
        numeroDocumento
        identificacion
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const FARM_DETAIL = gql`
  query getFarmDetail($idFarm: Int!) {
    getFarmDetail(input: { idGranja: $idFarm }) {
      data {
        codigoGranja
        nombreGranja
        codigoQR
        idPersonaResponsable
        idTipoDocumento
        numeroDocumento
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        emailResponsable
        tipoDireccion
        idCiudad
        idDepartamento
        idPais
        ciudad
        departamento
        pais
        idTipoVia
        tipoVia
        numeroVia
        apendiceVia
        numeroCruce
        apendiceCruce
        metrosEsquina
        adicional
        codigoPostal
        longitud
        latitud
        direccion
        ubicacion
        idIndicativoCelular
        indicativoCelular
        numeroCelular
        idIndicativoWhatsapp
        indicativoWhatsapp
        numeroWhatsapp
        idIndicativoTelegram
        indicativoTelegram
        numeroTelegram
        email
        certificadoGranja
        estado
        fechaCreacion
        fechaInactivacion
        fechaValidezCertificadoGranja
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const DESTINATIONS = gql`
  query getDestinations {
    getDestinations {
      data {
        idDestino
        codigoDestino
        nombreDestino
        indicativoTelefono
        numeroTelefono
        extensionTelefono
        telefono
        indicativoWhatsapp
        numeroWhatsapp
        whatsapp
        email
        direccion
        ubicacion
        estado
        tipoDestino
      }
    }
  }
`;

export const DESTINATION_EXISTING = gql`
  query getDestinationsXType($idTipoDestino: Int!) {
    getDestinationsXType(input: { idTipoDestino: $idTipoDestino }) {
      data {
        idDestino
        nit
        nombreDestino
        telefono
        direccion
      }
    }
  }
`;

export const SEARCH_DESTINATION_BY_NIT = gql`
  query validateDestination($nit: String!) {
    validateDestination(input: { nit: $nit }) {
      data {
        success
        data {
          codigoDestino
          idDestino
          nit
          digitoVerificacion
          idUsuarioCreacion
          nombreDestino
          codigoQR
          idPersonaResponsable
          idTipoDocumento
          numeroDocumento
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          emailResponsable
          tipoDireccion
          idCiudad
          idDepartamento
          idPais
          idTipoVia
          numeroVia
          apendiceVia
          numeroCruce
          apendiceCruce
          metrosEsquina
          adicional
          codigoPostal
          longitud
          latitud
          direccion
          ubicacion
          idIndicativoTelefono
          indicativoTelefono
          numeroTelefono
          extensionTelefono
          idIndicativoCelular
          indicativoCelular
          numeroCelular
          idIndicativoWhatsapp
          indicativoWhatsapp
          numeroWhatsapp
          idIndicativoTelegram
          indicativoTelegram
          numeroTelegram
          email
          estado
          fechaCreacion
          fechaInactivacion
          horariosDestino {
            dia
            horaInicial
            horaFinal
            idTipoHorario
          }
        }
        message
      }
    }
  }
`;

export const DESTINATION_MANAGER = gql`
  query getDestinationManagerXUser($idPersonaResponsable: Int!) {
    getDestinationManagerXUser(
      input: { idPersonaResponsable: $idPersonaResponsable }
    ) {
      data {
        idPersonaResponsable
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        nombreCompleto
        idTipoDocumento
        abreviatura
        numeroDocumento
        identificacion
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const DESTINATION_DETAIL = gql`
  query getDestinationDetail($idDestiny: Int!) {
    getDestinationDetail(input: { idDestino: $idDestiny }) {
      data {
        codigoDestino
        nombreDestino
        idTipoDestino
        codigoQR
        nit
        digitoVerificacion
        idUsuarioCreacion
        idPersonaResponsable
        idTipoDocumento
        numeroDocumento
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        emailResponsable
        tipoDireccion
        idCiudad
        idDepartamento
        idPais
        ciudad
        departamento
        pais
        idTipoVia
        tipoVia
        numeroVia
        apendiceVia
        numeroCruce
        apendiceCruce
        metrosEsquina
        adicional
        codigoPostal
        longitud
        latitud
        direccion
        ubicacion
        idIndicativoTelefono
        indicativoTelefono
        numeroTelefono
        extensionTelefono
        idIndicativoCelular
        indicativoCelular
        numeroCelular
        idIndicativoWhatsapp
        indicativoWhatsapp
        numeroWhatsapp
        idIndicativoTelegram
        indicativoTelegram
        numeroTelegram
        email
        estado
        estrato
        fechaCreacion
        fechaInactivacion
        horariosDestino {
          dia
          horaInicial
          horaFinal
          idTipoHorario
        }
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const LEGAL_DATA = gql`
  query getLegalData {
    getLegalData {
      data {
        nombreCompleto
        idTipoDocumento
        numeroDocumento
        digitoIdentificador
        direccion
        indicativoCelular
        celular
        email
        username
        fechaRegistro
        fechaAbeasData
        fechaPolitica
        fechaTerminos
        fechaBaja
        nombreCompleto
        nombreCompletoRL
        tipoDocumentoRL
        numeroDocumentoRL
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const MARKETER_TYPE = gql`
  query getMarketerType {
    getMarketerType {
      data {
        idTipoComercializador
        nombre
        descripcion
        estado
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;
