import { gql } from "apollo-boost";

export const GET_ROLES = gql`
  query getRoles {
    getRoles {
      data {
        misRoles {
          idRol
          descripcion
          estado
        }
        rolesSistema {
          idRol
          descripcion
          estado
        }
      }
    }
  }
`;
