import { Document, Page, Image, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import dayjs from 'dayjs';

import magroLogo from 'static/img/reportsIcons/magroLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import HorizontalTableInfo from 'components/pdf-components/HorizontalTableInfo/index';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF/index';
import ItemTotal from 'components/pdf-components/ItemTotal';
import NumberTotal from 'components/pdf-components/NumberTotal';
import ItemTotalLeft from 'components/pdf-components/ItemTotalLeft';
import ListData from 'components/pdf-components/ListData';
import TableDecomiso from 'components/pdf-components/TableDecomiso/index';
import ChartListMagro from 'components/pdf-components/CharListMagro/index';

import styles from './styles/lote';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const resumenProceso = [
  {
    canalesEvaluadas: 30475,
    lotesEvaluados: 657,
    productoresDiferentes: 3,
    granjasDiferentes: 66,
    promedioPCC: 121.31344199066889,
    promedioMC: 52.960727361563514,
    promedioLM: 67.43084576547234,
    promedioGD: 18.40859397394138,
    rendimientoPieCanal: 79.2011125,
    pesototalCanalesCalientes: 3845246.239999996,
    totalCarneMagra: 1614474.5000000014,
  },
];

const animalesR = {
  resumenRecepcionAnimales: {
    animalesRemisionados: 32316,
    remisionesRecibidas: 656,
    animalesRecibidos: 32038,
    camionesRecibidos: 656,
    totalAnimalesEnPiePesados: 151,
    pesoTotalAnimalesEnPie: 35238,
    pesoPromediolAnimalesEnPie: 233.36423841059602,
    totalCanalesConGrasaDorsalMayora185: 0,
    PorcentajeCanalesConGrasaDorsalMayora185: 0,
  },
};

const decomisos = {
  DecomisosPlanillaMagro: {
    detalleDecomisosPartePlanilaMagros: [
      {
        parte: 'CARNE',
        Cantidad: 299,
        Porcentaje: 0.6708698871412866,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'PIEL',
        Cantidad: 1124,
        Porcentaje: 2.5219322847719265,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'PULMÓN',
        Cantidad: 37392,
        Porcentaje: 83.89687899661199,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'VISCERAS ROJAS',
        Cantidad: 2893,
        Porcentaje: 6.491058807691445,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'OREJA',
        Cantidad: 476,
        Porcentaje: 1.068006910632951,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'PEZUÑA',
        Cantidad: 877,
        Porcentaje: 1.9677354214812988,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'VISCERAS BLANCAS',
        Cantidad: 875,
        Porcentaje: 1.9632479974870425,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'CORAZÓN',
        Cantidad: 112,
        Porcentaje: 0.25129574367834145,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'TODA LA CANAL',
        Cantidad: 13,
        Porcentaje: 0.029168255962664633,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'COSTILLA',
        Cantidad: 45,
        Porcentaje: 0.10096703987076219,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'CABEZA',
        Cantidad: 62,
        Porcentaje: 0.139110143821939,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'RIÑON',
        Cantidad: 136,
        Porcentaje: 0.30514483160941464,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'BRAZUELO',
        Cantidad: 13,
        Porcentaje: 0.029168255962664633,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'ESPINAZO',
        Cantidad: 18,
        Porcentaje: 0.04038681594830488,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'EMPELLA',
        Cantidad: 14,
        Porcentaje: 0.03141196795979268,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'PIERNA',
        Cantidad: 92,
        Porcentaje: 0.20642150373578047,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'PERNIL',
        Cantidad: 40,
        Porcentaje: 0.08974847988512194,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'BRAZO',
        Cantidad: 17,
        Porcentaje: 0.03814310395117683,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'HIGADO',
        Cantidad: 54,
        Porcentaje: 0.12116044784491463,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'BAZO',
        Cantidad: 11,
        Porcentaje: 0.024680831968408534,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
      {
        parte: 'SOLOMITO',
        Cantidad: 6,
        Porcentaje: 0.013462271982768292,
        codigoLote: 1934,
        idLoteIP: 12555851,
      },
    ],
    totalCanalesCompletasDecomisadasSB: 0,
    totalCanalesCompletasDecomisadasSBE: 0,
    totalCanalesCompletasDecomisadas: 0,
    totalKgsCarneDecomisadaSB: 0,
    totalKgsCarneDecomisadaSBE: 0,
    totalPartesDecomisadas: 44569,
    porcentajeCanalesDecomisadas: 0,
  },
};

const anteMortemData = {
  anteMortem: {
    agitados: 207,
    caidos: 285,
    muertosTransporte: 155,
    muertosCorrales: 60,
    observacion: 719,
    agitadosCorrales: 25,
    caidosCorrales: 25,
    muertosDesembarque: 12,
    muertosObservacion: 40,
    animalesDecomisados: 0,
  },
};

const totalesDetalle = {
  totalesDetalleLoteEvaluado: {
    totalAnimalesLotes: 32087,
    totalAnimalesEvaluadosLotes: 30475,
    totalPesoLotes: 35238,
    promPCCLotes: 121.31344199066889,
    promMCLotes: 52.960727361563514,
    promLMLotes: 67.43084576547234,
    promGDLotes: 18.40859397394138,
    RendimientoLoteLotes: 79.2011125,
  },
};

const clasificacion = {
  clasificacionSEUROP: {
    cantS: 249,
    porcS: 0.7760152086514788,
    cantE: 9062,
    porcE: 28.241967151806026,
    cantU: 15051,
    porcU: 46.9068470096924,
    cantR: 5524,
    porcR: 17.2156948296818,
    cantO: 585,
    porcO: 1.823168261289619,
    cantP: 78,
    porcP: 0.2430891015052825,
  },
};

const agentes = {
  agentesRelacionadosConElNegocio: {
    PlantaBeneficio: {
      nombre: 'SOCIEDAD CENTRAL GANADERA S.A.',
      nit: '811015602-1',
      telefono: '4710183',
      email: 'info@centralganadera.com',
      direccion: 'CALLE 103 EE 63 D 70 ',
      ciudad: 'MEDELLIN',
    },
    ComercializadorTiqueteBascula: {
      nombreCompleto: 'ALIMENTOS CARNICOS S.A.S',
      nit: '890304130-4',
      telefono: '3786400',
      email: 'gbenjumeac@alimentoscarnicos.com.co',
      direccion: 'CALLE 25 AS 48  150 ',
      ciudad: 'ENVIGADO',
    },
    ProductorTiqueteBascula: {
      nombreCompleto: 'ALIMENTOS CARNICOS S.A.S',
      nit: '890304130-4',
      telefono: '3786400',
      email: 'gbenjumeac@alimentoscarnicos.com.co',
      direccion: 'CALLE 25 AS 48  150 ',
      ciudad: 'ENVIGADO',
    },
  },
};

const detalles = {
  detalleLotesEvaluados: [
    {
      fechaLote: '2021-07-01T05:35:00Z',
      LoteID: 12535078,
      CodigoLote: 1219,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 54896,
      promPCC: '109.792',
      promMC: '53.88',
      promLM: '64.112',
      promGD: '16.392',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-01T06:11:00Z',
      LoteID: 12535080,
      CodigoLote: 1221,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 66196,
      promPCC: '120.356',
      promMC: '54.3873',
      promLM: '65.4182',
      promGD: '15.8909',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-01T07:02:00Z',
      LoteID: 12535084,
      CodigoLote: 1225,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 57920.3,
      promPCC: '113.092',
      promMC: '53.4412',
      promLM: '67.4824',
      promGD: '17.7255',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-01T07:46:00Z',
      LoteID: 12535087,
      CodigoLote: 1227,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 6960,
      promPCC: '128.889',
      promMC: '48.3455',
      promLM: '70.6764',
      promGD: '25.92',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-01T07:56:00Z',
      LoteID: 12535088,
      CodigoLote: 1228,
      totalAnimalesLote: 50,
      canalesEvaluadas: 49,
      pesotTotalLote: 59719.9,
      promPCC: '121.631',
      promMC: '50.3551',
      promLM: '74.9796',
      promGD: '23.7796',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-01T08:29:00Z',
      LoteID: 12535090,
      CodigoLote: 1230,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 60831,
      promPCC: '114.775',
      promMC: '51.9038',
      promLM: '74.2566',
      promGD: '21.3434',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-01T08:52:00Z',
      LoteID: 12535092,
      CodigoLote: 1232,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 59084.3,
      promPCC: '111.155',
      promMC: '49.4736',
      promLM: '67.2906',
      promGD: '23.5623',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-01T09:01:00Z',
      LoteID: 12535093,
      CodigoLote: 1233,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 64408.8,
      promPCC: '119.081',
      promMC: '48.2167',
      promLM: '65.3481',
      promGD: '25.037',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-01T09:41:00Z',
      LoteID: 12535094,
      CodigoLote: 1234,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 73991,
      promPCC: '137.02',
      promMC: '50.2278',
      promLM: '74.7852',
      promGD: '23.9407',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-01T10:04:00Z',
      LoteID: 12535095,
      CodigoLote: 1235,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 71946.2,
      promPCC: '130.562',
      promMC: '50.4611',
      promLM: '73.5926',
      promGD: '23.363',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-01T10:25:00Z',
      LoteID: 12535097,
      CodigoLote: 1237,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 72933.6,
      promPCC: '137.068',
      promMC: '50.7491',
      promLM: '74.9434',
      promGD: '23.2151',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T04:22:00Z',
      LoteID: 12535116,
      CodigoLote: 1253,
      totalAnimalesLote: 9,
      canalesEvaluadas: 0,
      pesotTotalLote: 19774.7,
      promPCC: '199.744',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T04:30:00Z',
      LoteID: 12535117,
      CodigoLote: 1254,
      totalAnimalesLote: 2,
      canalesEvaluadas: 0,
      pesotTotalLote: 4262.5,
      promPCC: '193.75',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T04:51:00Z',
      LoteID: 12535119,
      CodigoLote: 1256,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 67995,
      promPCC: '123.627',
      promMC: '53.6455',
      promLM: '74.48',
      promGD: '18.8145',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T05:24:00Z',
      LoteID: 12535121,
      CodigoLote: 1257,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 64844,
      promPCC: '124.463',
      promMC: '54.9962',
      promLM: '76.0538',
      promGD: '17.1154',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T05:36:00Z',
      LoteID: 12535125,
      CodigoLote: 1260,
      totalAnimalesLote: 49,
      canalesEvaluadas: 49,
      pesotTotalLote: 62279,
      promPCC: '127.1',
      promMC: '56.1694',
      promLM: '79.8776',
      promGD: '16.1551',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T06:22:00Z',
      LoteID: 12535129,
      CodigoLote: 1263,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 66314,
      promPCC: '120.571',
      promMC: '50.2309',
      promLM: '66.2982',
      promGD: '22.24',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T06:45:00Z',
      LoteID: 12535131,
      CodigoLote: 1265,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 61162.2,
      promPCC: '110.947',
      promMC: '53.9962',
      promLM: '69.0491',
      promGD: '17.2',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T06:52:00Z',
      LoteID: 12535132,
      CodigoLote: 1266,
      totalAnimalesLote: 9,
      canalesEvaluadas: 0,
      pesotTotalLote: 15169.8,
      promPCC: '170.725',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T06:53:00Z',
      LoteID: 12535133,
      CodigoLote: 1267,
      totalAnimalesLote: 12,
      canalesEvaluadas: 0,
      pesotTotalLote: 23618.1,
      promPCC: '178.925',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T07:21:00Z',
      LoteID: 12535136,
      CodigoLote: 1269,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 66738,
      promPCC: '128.342',
      promMC: '53.7615',
      promLM: '74.9538',
      promGD: '18.7308',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T07:37:00Z',
      LoteID: 12535138,
      CodigoLote: 1271,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 67538,
      promPCC: '122.796',
      promMC: '54.3436',
      promLM: '75.9782',
      promGD: '18.08',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T07:47:00Z',
      LoteID: 12535139,
      CodigoLote: 1272,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 59035,
      promPCC: '118.07',
      promMC: '56.768',
      promLM: '77.648',
      promGD: '14.808',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T08:00:00Z',
      LoteID: 12535140,
      CodigoLote: 1273,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 65287,
      promPCC: '118.704',
      promMC: '52.1945',
      promLM: '71.2873',
      promGD: '20.3345',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T08:36:00Z',
      LoteID: 12535141,
      CodigoLote: 1274,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 64141,
      promPCC: '116.62',
      promMC: '52.7455',
      promLM: '69.8836',
      promGD: '19.2145',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T09:08:00Z',
      LoteID: 12535142,
      CodigoLote: 1275,
      totalAnimalesLote: 31,
      canalesEvaluadas: 30,
      pesotTotalLote: 34214.9,
      promPCC: '113.64',
      promMC: '56.2667',
      promLM: '73.1333',
      promGD: '14.6667',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T09:14:00Z',
      LoteID: 12535143,
      CodigoLote: 1276,
      totalAnimalesLote: 24,
      canalesEvaluadas: 24,
      pesotTotalLote: 26399,
      promPCC: '109.996',
      promMC: '57.075',
      promLM: '74.7833',
      promGD: '13.7833',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T09:23:00Z',
      LoteID: 12535144,
      CodigoLote: 1277,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 63452.5,
      promPCC: '121.552',
      promMC: '56.2098',
      promLM: '77.8353',
      promGD: '15.6706',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T09:52:00Z',
      LoteID: 12535145,
      CodigoLote: 1278,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 60843,
      promPCC: '112.672',
      promMC: '56.7315',
      promLM: '78.3704',
      promGD: '15.0148',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T10:21:00Z',
      LoteID: 12535147,
      CodigoLote: 1280,
      totalAnimalesLote: 54,
      canalesEvaluadas: 51,
      pesotTotalLote: 61078.5,
      promPCC: '119.147',
      promMC: '54.5529',
      promLM: '77.1686',
      promGD: '18',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T10:42:00Z',
      LoteID: 12535149,
      CodigoLote: 1282,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 58881,
      promPCC: '115.453',
      promMC: '55.3471',
      promLM: '77.1529',
      promGD: '16.8235',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T11:02:00Z',
      LoteID: 12535150,
      CodigoLote: 1283,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 62954,
      promPCC: '116.581',
      promMC: '50.0852',
      promLM: '67.9185',
      promGD: '22.7852',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T11:41:00Z',
      LoteID: 12535153,
      CodigoLote: 1285,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 61607,
      promPCC: '110.013',
      promMC: '56.1268',
      promLM: '73.9214',
      promGD: '15.0214',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T12:13:00Z',
      LoteID: 12535155,
      CodigoLote: 1287,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 69492,
      promPCC: '126.349',
      promMC: '52.5236',
      promLM: '74.4436',
      promGD: '20.4655',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T15:03:00Z',
      LoteID: 12535161,
      CodigoLote: 1292,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 62864,
      promPCC: '112.257',
      promMC: '55.9714',
      promLM: '75.1643',
      promGD: '15.4929',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T15:38:00Z',
      LoteID: 12535163,
      CodigoLote: 1294,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 59052,
      promPCC: '111.419',
      promMC: '54.4472',
      promLM: '73.7736',
      promGD: '17.4868',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T17:17:00Z',
      LoteID: 12535165,
      CodigoLote: 1296,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 59469.2,
      promPCC: '109.693',
      promMC: '55.5038',
      promLM: '72.1434',
      promGD: '15.5849',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T18:12:00Z',
      LoteID: 12535168,
      CodigoLote: 1298,
      totalAnimalesLote: 49,
      canalesEvaluadas: 46,
      pesotTotalLote: 55453.6,
      promPCC: '117.451',
      promMC: '56.1152',
      promLM: '76.2087',
      promGD: '15.4957',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T18:40:00Z',
      LoteID: 12535170,
      CodigoLote: 1300,
      totalAnimalesLote: 16,
      canalesEvaluadas: 0,
      pesotTotalLote: 30645.2,
      promPCC: '184.7',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-02T19:21:00Z',
      LoteID: 12535173,
      CodigoLote: 1303,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 67400.6,
      promPCC: '120.084',
      promMC: '55.3927',
      promLM: '74.48',
      promGD: '16.2182',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T04:33:00Z',
      LoteID: 12535201,
      CodigoLote: 1331,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 64369,
      promPCC: '119.202',
      promMC: '56.45',
      promLM: '77.5852',
      promGD: '15.2741',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T05:19:00Z',
      LoteID: 12535203,
      CodigoLote: 1333,
      totalAnimalesLote: 54,
      canalesEvaluadas: 50,
      pesotTotalLote: 60823.4,
      promPCC: '120.968',
      promMC: '56.844',
      promLM: '77.976',
      promGD: '14.776',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T05:26:00Z',
      LoteID: 12535204,
      CodigoLote: 1334,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 72597,
      promPCC: '129.637',
      promMC: '53.3839',
      promLM: '80.0786',
      promGD: '20.3357',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T05:28:00Z',
      LoteID: 12535205,
      CodigoLote: 1335,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 61999,
      promPCC: '114.813',
      promMC: '55.9074',
      promLM: '78.6296',
      promGD: '16.2889',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T06:13:00Z',
      LoteID: 12535206,
      CodigoLote: 1336,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 5926,
      promPCC: '107.745',
      promMC: '53.7509',
      promLM: '68.1236',
      promGD: '17.3891',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T06:48:00Z',
      LoteID: 12535209,
      CodigoLote: 1339,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 61181.1,
      promPCC: '113.096',
      promMC: '55.7556',
      promLM: '73.3037',
      promGD: '15.437',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T07:57:00Z',
      LoteID: 12535214,
      CodigoLote: 1344,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 63416,
      promPCC: '117.437',
      promMC: '55.9815',
      promLM: '72.237',
      promGD: '14.8963',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T08:34:00Z',
      LoteID: 12535217,
      CodigoLote: 1346,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 68260.3,
      promPCC: '128.63',
      promMC: '55.0038',
      promLM: '77.8566',
      promGD: '17.4717',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T08:58:00Z',
      LoteID: 12535221,
      CodigoLote: 1349,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 6000,
      promPCC: '109.091',
      promMC: '55.8109',
      promLM: '69.9855',
      promGD: '14.7055',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T09:07:00Z',
      LoteID: 12535222,
      CodigoLote: 1350,
      totalAnimalesLote: 47,
      canalesEvaluadas: 47,
      pesotTotalLote: 52386,
      promPCC: '111.46',
      promMC: '54.4064',
      promLM: '74.2043',
      promGD: '17.617',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T09:31:00Z',
      LoteID: 12535224,
      CodigoLote: 1352,
      totalAnimalesLote: 46,
      canalesEvaluadas: 46,
      pesotTotalLote: 51133,
      promPCC: '111.159',
      promMC: '54.0543',
      promLM: '75.287',
      promGD: '18.3739',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-05T09:40:00Z',
      LoteID: 12535225,
      CodigoLote: 1353,
      totalAnimalesLote: 47,
      canalesEvaluadas: 47,
      pesotTotalLote: 52087,
      promPCC: '110.823',
      promMC: '56.5532',
      promLM: '75.9404',
      promGD: '14.8',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T04:56:00Z',
      LoteID: 12535261,
      CodigoLote: 1387,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 62503.1,
      promPCC: '119.783',
      promMC: '54.35',
      promLM: '73.8692',
      promGD: '17.6385',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T05:19:00Z',
      LoteID: 12535264,
      CodigoLote: 1390,
      totalAnimalesLote: 55,
      canalesEvaluadas: 47,
      pesotTotalLote: 52031.4,
      promPCC: '107.192',
      promMC: '56.4638',
      promLM: '72.3574',
      promGD: '14.2128',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T05:42:00Z',
      LoteID: 12535266,
      CodigoLote: 1392,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 62538.5,
      promPCC: '111.477',
      promMC: '56.7055',
      promLM: '73.7236',
      promGD: '14.1164',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T06:22:00Z',
      LoteID: 12535269,
      CodigoLote: 1395,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 60169,
      promPCC: '109.398',
      promMC: '52.7018',
      promLM: '68.6909',
      promGD: '19.0545',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T06:34:00Z',
      LoteID: 12535270,
      CodigoLote: 1396,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 66941.9,
      promPCC: '123.735',
      promMC: '55.4811',
      promLM: '78.1283',
      promGD: '16.8302',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T06:54:00Z',
      LoteID: 12535271,
      CodigoLote: 1397,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 62152.6,
      promPCC: '116.832',
      promMC: '54.6846',
      promLM: '71.1385',
      promGD: '16.6077',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T07:12:00Z',
      LoteID: 12535272,
      CodigoLote: 1398,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 6292,
      promPCC: '116.519',
      promMC: '55.3815',
      promLM: '75.7333',
      promGD: '16.4889',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T07:32:00Z',
      LoteID: 12535273,
      CodigoLote: 1399,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 65087,
      promPCC: '122.806',
      promMC: '52.7358',
      promLM: '72',
      promGD: '19.6679',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T08:01:00Z',
      LoteID: 12535274,
      CodigoLote: 1400,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 5737,
      promPCC: '112.49',
      promMC: '54.4843',
      promLM: '71.1686',
      promGD: '16.8941',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T08:29:00Z',
      LoteID: 12535276,
      CodigoLote: 1402,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 60003.6,
      promPCC: '112.794',
      promMC: '56.0528',
      promLM: '76.5132',
      promGD: '15.6528',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T09:15:00Z',
      LoteID: 12535279,
      CodigoLote: 1405,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 64276,
      promPCC: '116.865',
      promMC: '50.5745',
      promLM: '69.8909',
      promGD: '22.4509',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T09:47:00Z',
      LoteID: 12535283,
      CodigoLote: 1409,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 61568.4,
      promPCC: '113.878',
      promMC: '50.9',
      promLM: '67.2593',
      promGD: '21.4519',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T10:07:00Z',
      LoteID: 12535285,
      CodigoLote: 1411,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 57587,
      promPCC: '106.643',
      promMC: '55.8389',
      promLM: '68.2741',
      promGD: '14.3111',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T10:41:00Z',
      LoteID: 12535288,
      CodigoLote: 1413,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 59332,
      promPCC: '111.747',
      promMC: '56.183',
      promLM: '73.0113',
      promGD: '14.7472',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T11:39:00Z',
      LoteID: 12535293,
      CodigoLote: 1417,
      totalAnimalesLote: 53,
      canalesEvaluadas: 50,
      pesotTotalLote: 62112.6,
      promPCC: '121.041',
      promMC: '56.196',
      promLM: '74.008',
      promGD: '14.936',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T12:12:00Z',
      LoteID: 12535295,
      CodigoLote: 1419,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 62765.8,
      promPCC: '120.133',
      promMC: '54.1846',
      promLM: '74.8462',
      promGD: '18.0923',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T12:29:00Z',
      LoteID: 12535297,
      CodigoLote: 1421,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 68547,
      promPCC: '129.334',
      promMC: '53.2906',
      promLM: '75.6',
      promGD: '19.5698',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-06T13:16:00Z',
      LoteID: 12535300,
      CodigoLote: 1424,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 63831,
      promPCC: '122.275',
      promMC: '56.6135',
      promLM: '81.0846',
      promGD: '15.7462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T05:07:00Z',
      LoteID: 12535340,
      CodigoLote: 1460,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 6179,
      promPCC: '112.345',
      promMC: '52.3582',
      promLM: '69.0909',
      promGD: '19.6436',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T05:53:00Z',
      LoteID: 12535344,
      CodigoLote: 1464,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 61418,
      promPCC: '111.669',
      promMC: '57.0145',
      promLM: '74.3418',
      promGD: '13.7964',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T06:18:00Z',
      LoteID: 12535345,
      CodigoLote: 1465,
      totalAnimalesLote: 56,
      canalesEvaluadas: 54,
      pesotTotalLote: 59083.9,
      promPCC: '109.069',
      promMC: '50.2333',
      promLM: '67.6741',
      promGD: '22.0719',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T06:48:00Z',
      LoteID: 12535346,
      CodigoLote: 1466,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 70905.3,
      promPCC: '128.749',
      promMC: '49.0964',
      promLM: '75.2145',
      promGD: '25.7018',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T06:56:00Z',
      LoteID: 12535347,
      CodigoLote: 1467,
      totalAnimalesLote: 14,
      canalesEvaluadas: 14,
      pesotTotalLote: 15541,
      promPCC: '111.007',
      promMC: '56.8714',
      promLM: '76.9429',
      promGD: '14.5143',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T07:03:00Z',
      LoteID: 12535348,
      CodigoLote: 1468,
      totalAnimalesLote: 39,
      canalesEvaluadas: 38,
      pesotTotalLote: 42054.9,
      promPCC: '110.387',
      promMC: '46.0263',
      promLM: '60.6211',
      promGD: '27.3263',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T07:26:00Z',
      LoteID: 12535349,
      CodigoLote: 1469,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 69881,
      promPCC: '124.579',
      promMC: '51.3382',
      promLM: '74.5018',
      promGD: '22.2473',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T08:25:00Z',
      LoteID: 12535350,
      CodigoLote: 1470,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 65048,
      promPCC: '118.269',
      promMC: '46.6673',
      promLM: '65.9127',
      promGD: '27.4545',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T09:01:00Z',
      LoteID: 12535352,
      CodigoLote: 1471,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 68852.9,
      promPCC: '127.294',
      promMC: '49.7222',
      promLM: '75.7407',
      promGD: '24.8889',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T10:44:00Z',
      LoteID: 12535357,
      CodigoLote: 1476,
      totalAnimalesLote: 19,
      canalesEvaluadas: 19,
      pesotTotalLote: 18512,
      promPCC: '97.4316',
      promMC: '44.1211',
      promLM: '51.5158',
      promGD: '28.3368',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T10:45:00Z',
      LoteID: 12535358,
      CodigoLote: 1477,
      totalAnimalesLote: 31,
      canalesEvaluadas: 31,
      pesotTotalLote: 37851,
      promPCC: '122.1',
      promMC: '45.5581',
      promLM: '61.2774',
      promGD: '28.1677',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T12:43:00Z',
      LoteID: 12535363,
      CodigoLote: 1482,
      totalAnimalesLote: 14,
      canalesEvaluadas: 14,
      pesotTotalLote: 1299,
      promPCC: '92.7857',
      promMC: '48.8786',
      promLM: '59.3429',
      promGD: '22.8571',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-07T12:50:00Z',
      LoteID: 12535364,
      CodigoLote: 1483,
      totalAnimalesLote: 36,
      canalesEvaluadas: 36,
      pesotTotalLote: 39635,
      promPCC: '110.097',
      promMC: '46.0861',
      promLM: '58.7222',
      promGD: '26.8667',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T04:47:00Z',
      LoteID: 12535401,
      CodigoLote: 1519,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 6223.4,
      promPCC: '109.055',
      promMC: '54.734',
      promLM: '70.3245',
      promGD: '16.3698',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T06:16:00Z',
      LoteID: 12535403,
      CodigoLote: 1521,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 62693,
      promPCC: '116.098',
      promMC: '56.6204',
      promLM: '76.7704',
      promGD: '14.8593',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T06:38:00Z',
      LoteID: 12535404,
      CodigoLote: 1522,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 72268,
      promPCC: '129.05',
      promMC: '53.2857',
      promLM: '73.6929',
      promGD: '19.1929',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T07:01:00Z',
      LoteID: 12535405,
      CodigoLote: 1523,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 58781,
      promPCC: '108.854',
      promMC: '57.0593',
      promLM: '75.3407',
      promGD: '13.9185',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T07:37:00Z',
      LoteID: 12535406,
      CodigoLote: 1524,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 60548,
      promPCC: '114.053',
      promMC: '52.6245',
      promLM: '68.483',
      promGD: '19.1321',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T09:43:00Z',
      LoteID: 12535413,
      CodigoLote: 1529,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 64742.2,
      promPCC: '119.709',
      promMC: '52.5611',
      promLM: '72.9852',
      promGD: '20.1185',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T10:00:00Z',
      LoteID: 12535414,
      CodigoLote: 1530,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 60844,
      promPCC: '112.674',
      promMC: '53.25',
      promLM: '70.1407',
      promGD: '18.5333',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T10:52:00Z',
      LoteID: 12535417,
      CodigoLote: 1532,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 64957.9,
      promPCC: '122.07',
      promMC: '55.5434',
      promLM: '78.2415',
      promGD: '16.7472',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T11:28:00Z',
      LoteID: 12535419,
      CodigoLote: 1534,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 70147,
      promPCC: '129.902',
      promMC: '55.8796',
      promLM: '80.1259',
      promGD: '16.6296',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T11:54:00Z',
      LoteID: 12535420,
      CodigoLote: 1535,
      totalAnimalesLote: 20,
      canalesEvaluadas: 20,
      pesotTotalLote: 21367,
      promPCC: '106.835',
      promMC: '51.315',
      promLM: '65.86',
      promGD: '20.56',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T11:57:00Z',
      LoteID: 12535421,
      CodigoLote: 1536,
      totalAnimalesLote: 3,
      canalesEvaluadas: 3,
      pesotTotalLote: 2831,
      promPCC: '94.3667',
      promMC: '54.2667',
      promLM: '68.2667',
      promGD: '16.6667',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T11:59:00Z',
      LoteID: 12535422,
      CodigoLote: 1537,
      totalAnimalesLote: 27,
      canalesEvaluadas: 27,
      pesotTotalLote: 3131,
      promPCC: '115.963',
      promMC: '50.0852',
      promLM: '65.1852',
      promGD: '22.237',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T12:58:00Z',
      LoteID: 12535424,
      CodigoLote: 1539,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 65269,
      promPCC: '116.552',
      promMC: '56.0821',
      promLM: '77.3071',
      promGD: '15.7643',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-08T13:41:00Z',
      LoteID: 12535425,
      CodigoLote: 1540,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 6634,
      promPCC: '118.464',
      promMC: '55.1518',
      promLM: '73.9857',
      promGD: '16.4643',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T04:03:00Z',
      LoteID: 12535445,
      CodigoLote: 1560,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 62209.7,
      promPCC: '114.993',
      promMC: '54.7074',
      promLM: '70.5185',
      promGD: '16.4444',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T04:36:00Z',
      LoteID: 12535447,
      CodigoLote: 1562,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 62773,
      promPCC: '120.717',
      promMC: '56.8019',
      promLM: '77.3538',
      promGD: '14.7077',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T05:18:00Z',
      LoteID: 12535448,
      CodigoLote: 1563,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 5926,
      promPCC: '107.745',
      promMC: '56.3309',
      promLM: '72.4291',
      promGD: '14.4218',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T05:43:00Z',
      LoteID: 12535449,
      CodigoLote: 1564,
      totalAnimalesLote: 48,
      canalesEvaluadas: 48,
      pesotTotalLote: 52243,
      promPCC: '108.84',
      promMC: '55.0021',
      promLM: '67.1',
      promGD: '15.325',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T06:41:00Z',
      LoteID: 12535452,
      CodigoLote: 1567,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 62674.5,
      promPCC: '115.567',
      promMC: '55.1887',
      promLM: '71.034',
      promGD: '15.8415',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T07:14:00Z',
      LoteID: 12535454,
      CodigoLote: 1568,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 69849.9,
      promPCC: '134.167',
      promMC: '51.8577',
      promLM: '72.6308',
      promGD: '21.0923',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T07:38:00Z',
      LoteID: 12535456,
      CodigoLote: 1570,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 63309,
      promPCC: '115.107',
      promMC: '52.0255',
      promLM: '67.0036',
      promGD: '19.7164',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T07:53:00Z',
      LoteID: 12535458,
      CodigoLote: 1572,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 55541.2,
      promPCC: '104.619',
      promMC: '57.7925',
      promLM: '76.4679',
      promGD: '13.0566',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T08:27:00Z',
      LoteID: 12535461,
      CodigoLote: 1574,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 61739.2,
      promPCC: '116.219',
      promMC: '52.1208',
      promLM: '68.3547',
      promGD: '19.8566',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T08:52:00Z',
      LoteID: 12535463,
      CodigoLote: 1575,
      totalAnimalesLote: 55,
      canalesEvaluadas: 50,
      pesotTotalLote: 54881.1,
      promPCC: '108.622',
      promMC: '52.692',
      promLM: '70.848',
      promGD: '19.496',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T09:04:00Z',
      LoteID: 12535465,
      CodigoLote: 1577,
      totalAnimalesLote: 52,
      canalesEvaluadas: 51,
      pesotTotalLote: 5519,
      promPCC: '108.216',
      promMC: '57.3255',
      promLM: '77.1451',
      promGD: '13.8745',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T09:32:00Z',
      LoteID: 12535467,
      CodigoLote: 1579,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 70812,
      promPCC: '128.749',
      promMC: '53.7836',
      promLM: '79.1418',
      promGD: '19.5418',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T10:03:00Z',
      LoteID: 12535470,
      CodigoLote: 1582,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 67813,
      promPCC: '123.296',
      promMC: '55.1727',
      promLM: '77.3309',
      promGD: '17.12',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T10:24:00Z',
      LoteID: 12535472,
      CodigoLote: 1584,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 63694,
      promPCC: '115.807',
      promMC: '53.4273',
      promLM: '73.92',
      promGD: '19.0327',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T10:58:00Z',
      LoteID: 12535473,
      CodigoLote: 1585,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 62816,
      promPCC: '114.211',
      promMC: '53.8418',
      promLM: '72.72',
      promGD: '18.1673',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T11:02:00Z',
      LoteID: 12535474,
      CodigoLote: 1586,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 58358,
      promPCC: '112.227',
      promMC: '57.8904',
      promLM: '79.4154',
      promGD: '13.5',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T11:51:00Z',
      LoteID: 12535476,
      CodigoLote: 1588,
      totalAnimalesLote: 49,
      canalesEvaluadas: 49,
      pesotTotalLote: 56876,
      promPCC: '116.073',
      promMC: '57.3878',
      promLM: '79.6571',
      promGD: '14.2939',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T12:48:00Z',
      LoteID: 12535479,
      CodigoLote: 1591,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 61498.3,
      promPCC: '117.687',
      promMC: '54.45',
      promLM: '76.3462',
      promGD: '17.9769',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T13:12:00Z',
      LoteID: 12535480,
      CodigoLote: 1592,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 62851,
      promPCC: '114.275',
      promMC: '51.8218',
      promLM: '71.9345',
      promGD: '21.0036',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T13:55:00Z',
      LoteID: 12535481,
      CodigoLote: 1593,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 67046,
      promPCC: '121.902',
      promMC: '52.6109',
      promLM: '71.92',
      promGD: '19.8255',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T14:35:00Z',
      LoteID: 12535484,
      CodigoLote: 1596,
      totalAnimalesLote: 14,
      canalesEvaluadas: 0,
      pesotTotalLote: 26667.7,
      promPCC: '185.5',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T15:02:00Z',
      LoteID: 12535488,
      CodigoLote: 1599,
      totalAnimalesLote: 54,
      canalesEvaluadas: 49,
      pesotTotalLote: 61673.8,
      promPCC: '122.752',
      promMC: '53.8857',
      promLM: '76.5143',
      promGD: '18.8653',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-09T17:14:00Z',
      LoteID: 12535492,
      CodigoLote: 1603,
      totalAnimalesLote: 55,
      canalesEvaluadas: 50,
      pesotTotalLote: 57918.2,
      promPCC: '115.054',
      promMC: '53.058',
      promLM: '68.616',
      promGD: '18.512',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-10T05:05:00Z',
      LoteID: 12535522,
      CodigoLote: 1630,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 59578,
      promPCC: '108.324',
      promMC: '53.0509',
      promLM: '68.0582',
      promGD: '18.4145',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-10T06:27:00Z',
      LoteID: 12535523,
      CodigoLote: 1631,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 69879,
      promPCC: '124.784',
      promMC: '54.1286',
      promLM: '74.2929',
      promGD: '18.0571',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-10T07:27:00Z',
      LoteID: 12535525,
      CodigoLote: 1633,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 6709.4,
      promPCC: '119.648',
      promMC: '51.7208',
      promLM: '70.0679',
      promGD: '20.7925',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-10T09:17:00Z',
      LoteID: 12545527,
      CodigoLote: 1635,
      totalAnimalesLote: 55,
      canalesEvaluadas: 51,
      pesotTotalLote: 57689.9,
      promPCC: '112.282',
      promMC: '56.3333',
      promLM: '72.1098',
      promGD: '14.3529',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-11T07:27:00Z',
      LoteID: 12545533,
      CodigoLote: 1641,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 66546,
      promPCC: '120.993',
      promMC: '52.04',
      promLM: '70.5091',
      promGD: '20.4',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-11T08:11:00Z',
      LoteID: 12545535,
      CodigoLote: 1643,
      totalAnimalesLote: 6,
      canalesEvaluadas: 6,
      pesotTotalLote: 6165,
      promPCC: '102.75',
      promMC: '57.55',
      promLM: '81.8',
      promGD: '14.4667',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-11T08:25:00Z',
      LoteID: 12545537,
      CodigoLote: 1645,
      totalAnimalesLote: 39,
      canalesEvaluadas: 37,
      pesotTotalLote: 43186.4,
      promPCC: '116.232',
      promMC: '56.4054',
      promLM: '76.2811',
      promGD: '15.0703',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-11T09:16:00Z',
      LoteID: 12545540,
      CodigoLote: 1648,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 53809.6,
      promPCC: '102.802',
      promMC: '58.2712',
      promLM: '77.5308',
      promGD: '12.5615',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-11T09:45:00Z',
      LoteID: 12545541,
      CodigoLote: 1649,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 61939,
      promPCC: '116.866',
      promMC: '53.8585',
      promLM: '74.3698',
      promGD: '18.4755',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-11T10:01:00Z',
      LoteID: 12545543,
      CodigoLote: 1651,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 63031.8,
      promPCC: '116.496',
      promMC: '55.7278',
      promLM: '74.5407',
      promGD: '15.7333',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-11T11:04:00Z',
      LoteID: 12545546,
      CodigoLote: 1654,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 55266,
      promPCC: '108.365',
      promMC: '52.9569',
      promLM: '71.4275',
      promGD: '19.2314',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-11T11:38:00Z',
      LoteID: 12545549,
      CodigoLote: 1657,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 67225.6,
      promPCC: '124.272',
      promMC: '52.1426',
      promLM: '76.837',
      promGD: '21.5037',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T04:31:00Z',
      LoteID: 12545573,
      CodigoLote: 1680,
      totalAnimalesLote: 55,
      canalesEvaluadas: 51,
      pesotTotalLote: 56302.8,
      promPCC: '105.413',
      promMC: '53.3667',
      promLM: '66.4235',
      promGD: '17.6157',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T05:26:00Z',
      LoteID: 12545576,
      CodigoLote: 1683,
      totalAnimalesLote: 51,
      canalesEvaluadas: 48,
      pesotTotalLote: 6340.3,
      promPCC: '126.042',
      promMC: '54.9958',
      promLM: '77.0083',
      promGD: '17.3167',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T06:22:00Z',
      LoteID: 12545578,
      CodigoLote: 1685,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 62175.2,
      promPCC: '116.958',
      promMC: '54.1566',
      promLM: '75.8642',
      promGD: '18.3245',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T06:59:00Z',
      LoteID: 12545579,
      CodigoLote: 1686,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 6276,
      promPCC: '120.692',
      promMC: '53.7154',
      promLM: '72.7615',
      promGD: '18.3692',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T07:35:00Z',
      LoteID: 12545580,
      CodigoLote: 1687,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 64992.1,
      promPCC: '122.272',
      promMC: '54.7404',
      promLM: '75.9538',
      promGD: '17.4769',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T08:00:00Z',
      LoteID: 12545582,
      CodigoLote: 1689,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 66665,
      promPCC: '130.716',
      promMC: '51.1529',
      promLM: '71.7255',
      promGD: '21.9529',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T08:42:00Z',
      LoteID: 12545584,
      CodigoLote: 1691,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 65488,
      promPCC: '119.069',
      promMC: '51.7618',
      promLM: '68.8073',
      promGD: '20.4655',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T09:01:00Z',
      LoteID: 12545585,
      CodigoLote: 1692,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 6324,
      promPCC: '117.111',
      promMC: '53.9667',
      promLM: '75.1926',
      promGD: '18.4889',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T09:18:00Z',
      LoteID: 12545586,
      CodigoLote: 1693,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 61008,
      promPCC: '110.924',
      promMC: '54.8873',
      promLM: '70.9164',
      promGD: '16.2618',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T09:46:00Z',
      LoteID: 12545589,
      CodigoLote: 1696,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 74093,
      promPCC: '137.209',
      promMC: '53.1352',
      promLM: '76.6815',
      promGD: '20.0148',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T10:22:00Z',
      LoteID: 12545592,
      CodigoLote: 1699,
      totalAnimalesLote: 54,
      canalesEvaluadas: 51,
      pesotTotalLote: 71145.4,
      promPCC: '136.504',
      promMC: '53.5314',
      promLM: '77.6314',
      promGD: '19.6078',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T11:35:00Z',
      LoteID: 12545596,
      CodigoLote: 1703,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 55978,
      promPCC: '105.619',
      promMC: '56.3868',
      promLM: '72.4453',
      promGD: '14.3321',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T11:53:00Z',
      LoteID: 12545597,
      CodigoLote: 1704,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 63887,
      promPCC: '127.774',
      promMC: '52.418',
      promLM: '71.696',
      promGD: '20.08',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T12:24:00Z',
      LoteID: 12545601,
      CodigoLote: 1708,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 62440.6,
      promPCC: '119.898',
      promMC: '50.5269',
      promLM: '66.4769',
      promGD: '21.8462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T12:49:00Z',
      LoteID: 12545602,
      CodigoLote: 1709,
      totalAnimalesLote: 54,
      canalesEvaluadas: 51,
      pesotTotalLote: 63030.2,
      promPCC: '123.202',
      promMC: '52.1824',
      promLM: '74.149',
      promGD: '20.9176',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T13:11:00Z',
      LoteID: 12545604,
      CodigoLote: 1711,
      totalAnimalesLote: 50,
      canalesEvaluadas: 49,
      pesotTotalLote: 53906.1,
      promPCC: '109.851',
      promMC: '51.549',
      promLM: '67.2571',
      promGD: '20.4735',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T13:42:00Z',
      LoteID: 12545607,
      CodigoLote: 1713,
      totalAnimalesLote: 56,
      canalesEvaluadas: 53,
      pesotTotalLote: 70384.9,
      promPCC: '132.164',
      promMC: '52.5',
      promLM: '75.9547',
      promGD: '20.8151',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T14:05:00Z',
      LoteID: 12545609,
      CodigoLote: 1715,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 69961,
      promPCC: '129.557',
      promMC: '52.2463',
      promLM: '73.9852',
      promGD: '20.7926',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T14:38:00Z',
      LoteID: 12545611,
      CodigoLote: 1717,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 68326.4,
      promPCC: '126.317',
      promMC: '50.8907',
      promLM: '72.5407',
      promGD: '22.5111',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-12T15:44:00Z',
      LoteID: 12545614,
      CodigoLote: 1719,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 65233,
      promPCC: '118.375',
      promMC: '55.5037',
      promLM: '73.5185',
      promGD: '15.8593',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T05:30:00Z',
      LoteID: 12545647,
      CodigoLote: 1750,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 67264,
      promPCC: '122.298',
      promMC: '55.3436',
      promLM: '74.96',
      promGD: '16.3855',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T06:08:00Z',
      LoteID: 12545649,
      CodigoLote: 1752,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 58736.2,
      promPCC: '110.374',
      promMC: '57.1904',
      promLM: '74.7231',
      promGD: '13.5923',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T06:30:00Z',
      LoteID: 12545650,
      CodigoLote: 1753,
      totalAnimalesLote: 50,
      canalesEvaluadas: 46,
      pesotTotalLote: 59588.3,
      promPCC: '125.821',
      promMC: '55.5848',
      promLM: '75.5565',
      promGD: '16.1478',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T06:57:00Z',
      LoteID: 12545651,
      CodigoLote: 1754,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 65079,
      promPCC: '120.517',
      promMC: '56.0741',
      promLM: '77.0741',
      promGD: '15.7333',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T07:35:00Z',
      LoteID: 12545652,
      CodigoLote: 1755,
      totalAnimalesLote: 50,
      canalesEvaluadas: 48,
      pesotTotalLote: 50899.9,
      promPCC: '103.473',
      promMC: '56.0187',
      promLM: '66.9667',
      promGD: '13.775',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T08:20:00Z',
      LoteID: 12545654,
      CodigoLote: 1757,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 69469,
      promPCC: '126.307',
      promMC: '50.4982',
      promLM: '70.0655',
      promGD: '22.5964',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T08:33:00Z',
      LoteID: 12545655,
      CodigoLote: 1758,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 59778,
      promPCC: '119.556',
      promMC: '55.956',
      promLM: '75.648',
      promGD: '15.616',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T09:21:00Z',
      LoteID: 12545658,
      CodigoLote: 1760,
      totalAnimalesLote: 38,
      canalesEvaluadas: 34,
      pesotTotalLote: 37906.1,
      promPCC: '110.447',
      promMC: '54.8676',
      promLM: '71.6353',
      promGD: '16.4353',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T09:23:00Z',
      LoteID: 12545659,
      CodigoLote: 1761,
      totalAnimalesLote: 17,
      canalesEvaluadas: 17,
      pesotTotalLote: 19879,
      promPCC: '116.935',
      promMC: '56.1176',
      promLM: '74.5882',
      promGD: '15.1765',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T09:58:00Z',
      LoteID: 12545662,
      CodigoLote: 1764,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 62749,
      promPCC: '123.037',
      promMC: '56.6235',
      promLM: '77.6235',
      promGD: '15.0275',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T10:16:00Z',
      LoteID: 12545664,
      CodigoLote: 1766,
      totalAnimalesLote: 43,
      canalesEvaluadas: 42,
      pesotTotalLote: 55818.8,
      promPCC: '132.638',
      promMC: '55.4357',
      promLM: '80.4',
      promGD: '17.3524',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T11:05:00Z',
      LoteID: 12545667,
      CodigoLote: 1769,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 61818,
      promPCC: '116.638',
      promMC: '52.9208',
      promLM: '71.8113',
      promGD: '19.3434',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T11:42:00Z',
      LoteID: 12545670,
      CodigoLote: 1772,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 54413,
      promPCC: '108.826',
      promMC: '49.33',
      promLM: '62.992',
      promGD: '22.912',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T11:59:00Z',
      LoteID: 12545673,
      CodigoLote: 1774,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 68503,
      promPCC: '129.251',
      promMC: '54.3755',
      promLM: '78.8604',
      promGD: '18.6113',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T12:41:00Z',
      LoteID: 12545677,
      CodigoLote: 1778,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 65763,
      promPCC: '119.569',
      promMC: '52.8982',
      promLM: '74.7709',
      promGD: '19.9782',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T13:47:00Z',
      LoteID: 12545682,
      CodigoLote: 1783,
      totalAnimalesLote: 50,
      canalesEvaluadas: 47,
      pesotTotalLote: 52909.4,
      promPCC: '112.343',
      promMC: '54.0128',
      promLM: '72.366',
      promGD: '17.8553',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T14:23:00Z',
      LoteID: 12545684,
      CodigoLote: 1785,
      totalAnimalesLote: 10,
      canalesEvaluadas: 10,
      pesotTotalLote: 10056,
      promPCC: '100.56',
      promMC: '49.27',
      promLM: '61.68',
      promGD: '22.76',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-13T14:35:00Z',
      LoteID: 12545685,
      CodigoLote: 1786,
      totalAnimalesLote: 40,
      canalesEvaluadas: 40,
      pesotTotalLote: 44626,
      promPCC: '111.565',
      promMC: '49.25',
      promLM: '63.92',
      promGD: '23.22',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-14T05:03:00Z',
      LoteID: 12545732,
      CodigoLote: 1824,
      totalAnimalesLote: 54,
      canalesEvaluadas: 49,
      pesotTotalLote: 56745.8,
      promPCC: '110.312',
      promMC: '56.9245',
      promLM: '73.2245',
      promGD: '13.7061',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-14T07:04:00Z',
      LoteID: 12545735,
      CodigoLote: 1827,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 68349.6,
      promPCC: '126.307',
      promMC: '54.4407',
      promLM: '74.5407',
      promGD: '17.6519',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-14T07:54:00Z',
      LoteID: 12545737,
      CodigoLote: 1829,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 6874,
      promPCC: '124.982',
      promMC: '52.3036',
      promLM: '72.6473',
      promGD: '20.4291',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-14T08:52:00Z',
      LoteID: 12545739,
      CodigoLote: 1831,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 60392.3,
      promPCC: '115.987',
      promMC: '52.9769',
      promLM: '69.1923',
      promGD: '18.7615',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-14T09:25:00Z',
      LoteID: 12545741,
      CodigoLote: 1833,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 66583,
      promPCC: '123.302',
      promMC: '55.8426',
      promLM: '76.7926',
      promGD: '16.0222',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-14T12:43:00Z',
      LoteID: 12545748,
      CodigoLote: 1840,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 4660,
      promPCC: '93.2',
      promMC: '50.696',
      promLM: '61.792',
      promGD: '20.664',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-14T15:09:00Z',
      LoteID: 12545758,
      CodigoLote: 1849,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 69982,
      promPCC: '127.24',
      promMC: '52.9255',
      promLM: '76.9455',
      promGD: '20.3636',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T04:40:00Z',
      LoteID: 12555780,
      CodigoLote: 1872,
      totalAnimalesLote: 53,
      canalesEvaluadas: 50,
      pesotTotalLote: 56162.1,
      promPCC: '109.533',
      promMC: '57.172',
      promLM: '73.688',
      promGD: '13.416',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T06:34:00Z',
      LoteID: 12555787,
      CodigoLote: 1878,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 55869.7,
      promPCC: '107.1',
      promMC: '57.2558',
      promLM: '73.9385',
      promGD: '13.3538',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T07:27:00Z',
      LoteID: 12555789,
      CodigoLote: 1880,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 63746.9,
      promPCC: '117.676',
      promMC: '55.6925',
      promLM: '76.8604',
      promGD: '16.2566',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T07:53:00Z',
      LoteID: 12555791,
      CodigoLote: 1882,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 64759,
      promPCC: '129.518',
      promMC: '55.684',
      promLM: '79.768',
      promGD: '16.848',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T08:37:00Z',
      LoteID: 12555793,
      CodigoLote: 1884,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 59066,
      promPCC: '111.445',
      promMC: '52.8698',
      promLM: '68.9585',
      promGD: '18.8528',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T09:10:00Z',
      LoteID: 12555794,
      CodigoLote: 1885,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 60513,
      promPCC: '114.175',
      promMC: '52.8642',
      promLM: '71.366',
      promGD: '19.3434',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T10:32:00Z',
      LoteID: 12555796,
      CodigoLote: 1887,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 70171,
      promPCC: '129.946',
      promMC: '52.513',
      promLM: '75.5407',
      promGD: '20.7037',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T11:08:00Z',
      LoteID: 12555799,
      CodigoLote: 1889,
      totalAnimalesLote: 56,
      canalesEvaluadas: 53,
      pesotTotalLote: 66565.6,
      promPCC: '123.059',
      promMC: '53.5509',
      promLM: '74.9736',
      promGD: '19.0491',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T12:01:00Z',
      LoteID: 12555801,
      CodigoLote: 1891,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 70351,
      promPCC: '131.975',
      promMC: '53.0769',
      promLM: '77.4769',
      promGD: '20.2615',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T12:41:00Z',
      LoteID: 12555803,
      CodigoLote: 1893,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 63482,
      promPCC: '117.559',
      promMC: '56.7509',
      promLM: '77.5418',
      promGD: '14.8218',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T13:07:00Z',
      LoteID: 12555805,
      CodigoLote: 1895,
      totalAnimalesLote: 18,
      canalesEvaluadas: 0,
      pesotTotalLote: 32321.6,
      promPCC: '171.965',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T13:08:00Z',
      LoteID: 12555806,
      CodigoLote: 1896,
      totalAnimalesLote: 1,
      canalesEvaluadas: 0,
      pesotTotalLote: 412,
      promPCC: '206',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-15T16:48:00Z',
      LoteID: 12555812,
      CodigoLote: 1902,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 6818,
      promPCC: '123.964',
      promMC: '53.7564',
      promLM: '74.5236',
      promGD: '18.6618',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T05:30:00Z',
      LoteID: 12533619,
      CodigoLote: 9863,
      totalAnimalesLote: 52,
      canalesEvaluadas: 51,
      pesotTotalLote: 61094.3,
      promPCC: '117.233',
      promMC: '54.1549',
      promLM: '67.0588',
      promGD: '16.5725',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T07:05:00Z',
      LoteID: 12533622,
      CodigoLote: 9866,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 56120,
      promPCC: '105.691',
      promMC: '54.0208',
      promLM: '63.2906',
      promGD: '16.0151',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T07:27:00Z',
      LoteID: 12533625,
      CodigoLote: 9869,
      totalAnimalesLote: 49,
      canalesEvaluadas: 49,
      pesotTotalLote: 54255,
      promPCC: '110.724',
      promMC: '52.2184',
      promLM: '64.1061',
      promGD: '18.8571',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T07:33:00Z',
      LoteID: 12533626,
      CodigoLote: 9870,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 61965,
      promPCC: '119.163',
      promMC: '49.5',
      promLM: '63.3231',
      promGD: '22.7231',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T08:07:00Z',
      LoteID: 12533627,
      CodigoLote: 9871,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 67462,
      promPCC: '124.93',
      promMC: '52.8426',
      promLM: '65.6',
      promGD: '18.2222',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T08:22:00Z',
      LoteID: 12533628,
      CodigoLote: 9872,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 63253,
      promPCC: '115.005',
      promMC: '53.1327',
      promLM: '64.1309',
      promGD: '17.4982',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T08:38:00Z',
      LoteID: 12533629,
      CodigoLote: 9873,
      totalAnimalesLote: 47,
      canalesEvaluadas: 46,
      pesotTotalLote: 52206,
      promPCC: '113.222',
      promMC: '54.7196',
      promLM: '67.9304',
      promGD: '15.9043',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T09:10:00Z',
      LoteID: 12533631,
      CodigoLote: 9874,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 68085,
      promPCC: '123.791',
      promMC: '52.9673',
      promLM: '63.2',
      promGD: '17.5564',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T10:09:00Z',
      LoteID: 12533638,
      CodigoLote: 9878,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 68310.5,
      promPCC: '123.962',
      promMC: '53.5407',
      promLM: '68.5407',
      promGD: '17.7778',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T12:01:00Z',
      LoteID: 12533648,
      CodigoLote: 9887,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 6800.4,
      promPCC: '130.784',
      promMC: '51.7588',
      promLM: '64.149',
      promGD: '19.5373',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T12:28:00Z',
      LoteID: 12533650,
      CodigoLote: 9889,
      totalAnimalesLote: 51,
      canalesEvaluadas: 50,
      pesotTotalLote: 64962,
      promPCC: '129.658',
      promMC: '52.606',
      promLM: '66.168',
      promGD: '18.688',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T12:58:00Z',
      LoteID: 12533653,
      CodigoLote: 9892,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 58268,
      promPCC: '107.904',
      promMC: '49.4796',
      promLM: '60.963',
      promGD: '22.2815',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T13:34:00Z',
      LoteID: 12533656,
      CodigoLote: 9895,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 50781,
      promPCC: '101.562',
      promMC: '49.508',
      promLM: '54.192',
      promGD: '20.888',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T13:55:00Z',
      LoteID: 12533658,
      CodigoLote: 9897,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 64891.8,
      promPCC: '122.281',
      promMC: '51.9075',
      promLM: '68.317',
      promGD: '20.1585',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T14:24:00Z',
      LoteID: 12533660,
      CodigoLote: 9899,
      totalAnimalesLote: 49,
      canalesEvaluadas: 49,
      pesotTotalLote: 50759,
      promPCC: '103.59',
      promMC: '49.1061',
      promLM: '54.6449',
      promGD: '21.5755',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-01T14:57:00Z',
      LoteID: 12533663,
      CodigoLote: 9902,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 58276,
      promPCC: '107.919',
      promMC: '49.6815',
      promLM: '61.5481',
      promGD: '22.1037',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T04:55:00Z',
      LoteID: 12533683,
      CodigoLote: 9922,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 65069.1,
      promPCC: '120.326',
      promMC: '49.3547',
      promLM: '62.7849',
      promGD: '22.8377',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T05:02:00Z',
      LoteID: 12533684,
      CodigoLote: 9923,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 61517,
      promPCC: '118.302',
      promMC: '50.4885',
      promLM: '65.2154',
      promGD: '21.6385',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T07:49:00Z',
      LoteID: 12556181,
      CodigoLote: 2245,
      totalAnimalesLote: 42,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T05:50:00Z',
      LoteID: 12533687,
      CodigoLote: 9926,
      totalAnimalesLote: 50,
      canalesEvaluadas: 49,
      pesotTotalLote: 60611,
      promPCC: '123.696',
      promMC: '52.1633',
      promLM: '61.0776',
      promGD: '18.3265',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T08:43:00Z',
      LoteID: 12556183,
      CodigoLote: 2247,
      totalAnimalesLote: 56,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T06:02:00Z',
      LoteID: 12533688,
      CodigoLote: 9927,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 60464,
      promPCC: '116.277',
      promMC: '52.1904',
      promLM: '61.5462',
      promGD: '18.3846',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T09:11:00Z',
      LoteID: 12556185,
      CodigoLote: 2248,
      totalAnimalesLote: 55,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T06:30:00Z',
      LoteID: 12533689,
      CodigoLote: 9928,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 61169,
      promPCC: '122.338',
      promMC: '52.714',
      promLM: '62.384',
      promGD: '17.776',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T07:20:00Z',
      LoteID: 12533690,
      CodigoLote: 9929,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 52517,
      promPCC: '102.975',
      promMC: '54.0961',
      promLM: '60.8235',
      promGD: '15.4196',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T09:41:00Z',
      LoteID: 12556188,
      CodigoLote: 2251,
      totalAnimalesLote: 6,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T07:21:00Z',
      LoteID: 12533691,
      CodigoLote: 9930,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 68607.4,
      promPCC: '126.546',
      promMC: '50.9792',
      promLM: '62.1887',
      promGD: '20.3094',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T09:43:00Z',
      LoteID: 12556190,
      CodigoLote: 2252,
      totalAnimalesLote: 15,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T07:35:00Z',
      LoteID: 12533692,
      CodigoLote: 9931,
      totalAnimalesLote: 49,
      canalesEvaluadas: 49,
      pesotTotalLote: 53824,
      promPCC: '109.845',
      promMC: '52.3204',
      promLM: '66.4082',
      promGD: '19.1592',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T09:43:00Z',
      LoteID: 12556191,
      CodigoLote: 2253,
      totalAnimalesLote: 1,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T10:08:00Z',
      LoteID: 12556193,
      CodigoLote: 2255,
      totalAnimalesLote: 55,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T08:35:00Z',
      LoteID: 12533696,
      CodigoLote: 9934,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 68696.9,
      promPCC: '127.013',
      promMC: '52.5852',
      promLM: '67.2667',
      promGD: '18.9407',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T10:18:00Z',
      LoteID: 12556194,
      CodigoLote: 2256,
      totalAnimalesLote: 55,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T10:41:00Z',
      LoteID: 12556195,
      CodigoLote: 2257,
      totalAnimalesLote: 53,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T11:08:00Z',
      LoteID: 12556197,
      CodigoLote: 2258,
      totalAnimalesLote: 35,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T11:44:00Z',
      LoteID: 12556201,
      CodigoLote: 2260,
      totalAnimalesLote: 55,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T11:54:00Z',
      LoteID: 12556202,
      CodigoLote: 2261,
      totalAnimalesLote: 55,
      canalesEvaluadas: 0,
      pesotTotalLote: 0,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T11:34:00Z',
      LoteID: 12533702,
      CodigoLote: 9940,
      totalAnimalesLote: 3,
      canalesEvaluadas: 3,
      pesotTotalLote: 243,
      promPCC: '81',
      promMC: '52.8',
      promLM: '54.1333',
      promGD: '16',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T12:43:00Z',
      LoteID: 12556204,
      CodigoLote: 2263,
      totalAnimalesLote: 56,
      canalesEvaluadas: 0,
      pesotTotalLote: 352.6,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-02T11:37:00Z',
      LoteID: 12533703,
      CodigoLote: 9941,
      totalAnimalesLote: 46,
      canalesEvaluadas: 46,
      pesotTotalLote: 40524,
      promPCC: '88.0957',
      promMC: '51.9891',
      promLM: '56.6087',
      promGD: '17.6957',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T13:13:00Z',
      LoteID: 12556206,
      CodigoLote: 2265,
      totalAnimalesLote: 16,
      canalesEvaluadas: 0,
      pesotTotalLote: 172.7,
      promPCC: 'Null',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-20T03:05:00Z',
      LoteID: 12556010,
      CodigoLote: 2084,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 60231.7,
      promPCC: '111.378',
      promMC: '54.3352',
      promLM: '64.9259',
      promGD: '15.8815',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T07:21:00Z',
      LoteID: 12556113,
      CodigoLote: 2183,
      totalAnimalesLote: 56,
      canalesEvaluadas: 49,
      pesotTotalLote: 60132.3,
      promPCC: '118.51',
      promMC: '53.151',
      promLM: '60.498',
      promGD: '16.751',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T07:37:00Z',
      LoteID: 12556114,
      CodigoLote: 2184,
      totalAnimalesLote: 50,
      canalesEvaluadas: 49,
      pesotTotalLote: 63893.6,
      promPCC: '127.514',
      promMC: '53.0633',
      promLM: '63.1429',
      promGD: '17.4122',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T08:18:00Z',
      LoteID: 12556117,
      CodigoLote: 2187,
      totalAnimalesLote: 49,
      canalesEvaluadas: 49,
      pesotTotalLote: 63777,
      promPCC: '130.157',
      promMC: '53.2531',
      promLM: '62.7755',
      promGD: '17.0531',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T08:28:00Z',
      LoteID: 12556118,
      CodigoLote: 2188,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 69745,
      promPCC: '126.809',
      promMC: '50.7491',
      promLM: '64.3345',
      promGD: '21.0691',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T09:28:00Z',
      LoteID: 12556120,
      CodigoLote: 2190,
      totalAnimalesLote: 56,
      canalesEvaluadas: 53,
      pesotTotalLote: 65154.3,
      promPCC: '122.381',
      promMC: '51.6679',
      promLM: '65.8943',
      promGD: '20.0226',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T10:04:00Z',
      LoteID: 12556123,
      CodigoLote: 2193,
      totalAnimalesLote: 57,
      canalesEvaluadas: 57,
      pesotTotalLote: 6881,
      promPCC: '120.719',
      promMC: '51.7509',
      promLM: '64.7158',
      promGD: '19.6702',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T10:22:00Z',
      LoteID: 12556126,
      CodigoLote: 2195,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 60789.1,
      promPCC: '112.461',
      promMC: '52.0833',
      promLM: '61.9111',
      promGD: '18.6148',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T10:55:00Z',
      LoteID: 12556128,
      CodigoLote: 2197,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 58123.4,
      promPCC: '105.464',
      promMC: '51.3056',
      promLM: '60.0815',
      promGD: '19.4',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T11:04:00Z',
      LoteID: 12556129,
      CodigoLote: 2198,
      totalAnimalesLote: 55,
      canalesEvaluadas: 32,
      pesotTotalLote: 68920.7,
      promPCC: '120.373',
      promMC: '53.1563',
      promLM: '64.3125',
      promGD: '17.5',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T04:26:00Z',
      LoteID: 12533741,
      CodigoLote: 9978,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 69503.5,
      promPCC: '128.526',
      promMC: '52.2491',
      promLM: '63.2',
      promGD: '18.6264',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T11:40:00Z',
      LoteID: 12556131,
      CodigoLote: 2200,
      totalAnimalesLote: 18,
      canalesEvaluadas: 17,
      pesotTotalLote: 18347,
      promPCC: '107.924',
      promMC: '55.6765',
      promLM: '68.6588',
      promGD: '14.6353',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T11:41:00Z',
      LoteID: 12556132,
      CodigoLote: 2201,
      totalAnimalesLote: 34,
      canalesEvaluadas: 34,
      pesotTotalLote: 42552,
      promPCC: '125.153',
      promMC: '53.7882',
      promLM: '65.6235',
      promGD: '16.8353',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T05:17:00Z',
      LoteID: 12533743,
      CodigoLote: 9980,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 62906,
      promPCC: '120.973',
      promMC: '52.975',
      promLM: '58.8462',
      promGD: '16.6692',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T05:51:00Z',
      LoteID: 12533745,
      CodigoLote: 9982,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 57076,
      promPCC: '111.914',
      promMC: '54.3745',
      promLM: '59.1137',
      promGD: '14.6588',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T12:33:00Z',
      LoteID: 12556136,
      CodigoLote: 2205,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 50081,
      promPCC: '100.162',
      promMC: '49.274',
      promLM: '59.848',
      promGD: '22.376',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T06:12:00Z',
      LoteID: 12533746,
      CodigoLote: 9983,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 59642,
      promPCC: '116.945',
      promMC: '51.1059',
      promLM: '63.9373',
      promGD: '20.4627',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T07:00:00Z',
      LoteID: 12533748,
      CodigoLote: 9985,
      totalAnimalesLote: 45,
      canalesEvaluadas: 40,
      pesotTotalLote: 50951.4,
      promPCC: '117.144',
      promMC: '50.595',
      promLM: '62.57',
      promGD: '17.711',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T13:04:00Z',
      LoteID: 12556139,
      CodigoLote: 2208,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 64504.1,
      promPCC: '121.296',
      promMC: '52.434',
      promLM: '66.3925',
      promGD: '18.9962',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-22T13:42:00Z',
      LoteID: 12556140,
      CodigoLote: 2209,
      totalAnimalesLote: 55,
      canalesEvaluadas: 46,
      pesotTotalLote: 57722.6,
      promPCC: '118.356',
      promMC: '53.9891',
      promLM: '64.5913',
      promGD: '16.313',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T07:38:00Z',
      LoteID: 12533750,
      CodigoLote: 9987,
      totalAnimalesLote: 49,
      canalesEvaluadas: 49,
      pesotTotalLote: 55628,
      promPCC: '113.527',
      promMC: '52.9551',
      promLM: '66',
      promGD: '18.1469',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T08:35:00Z',
      LoteID: 12533753,
      CodigoLote: 9990,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 67008,
      promPCC: '124.089',
      promMC: '52.8778',
      promLM: '67.5481',
      promGD: '18.5704',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T08:50:00Z',
      LoteID: 12533754,
      CodigoLote: 9991,
      totalAnimalesLote: 48,
      canalesEvaluadas: 48,
      pesotTotalLote: 60123,
      promPCC: '125.256',
      promMC: '54.1083',
      promLM: '68.7167',
      promGD: '16.9833',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T09:35:00Z',
      LoteID: 12533756,
      CodigoLote: 9993,
      totalAnimalesLote: 54,
      canalesEvaluadas: 51,
      pesotTotalLote: 6600.3,
      promPCC: '124.118',
      promMC: '50.1235',
      promLM: '65.5765',
      promGD: '22.2588',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T10:04:00Z',
      LoteID: 12533758,
      CodigoLote: 9995,
      totalAnimalesLote: 50,
      canalesEvaluadas: 49,
      pesotTotalLote: 62014,
      promPCC: '123.772',
      promMC: '53.8531',
      promLM: '70.2204',
      promGD: '17.649',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T10:15:00Z',
      LoteID: 12533759,
      CodigoLote: 9996,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 59986,
      promPCC: '119.972',
      promMC: '50.174',
      promLM: '64.688',
      promGD: '22.008',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T11:27:00Z',
      LoteID: 12533762,
      CodigoLote: 9999,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 64510.8,
      promPCC: '119.07',
      promMC: '51.3321',
      promLM: '64.9887',
      promGD: '20.3396',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T12:05:00Z',
      LoteID: 12533765,
      CodigoLote: 2,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 66512.1,
      promPCC: '125.287',
      promMC: '50.7623',
      promLM: '66.1358',
      promGD: '21.4189',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-03T12:17:00Z',
      LoteID: 12533766,
      CodigoLote: 3,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 59414.5,
      promPCC: '109.809',
      promMC: '48.9811',
      promLM: '62.9585',
      promGD: '23.434',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T04:59:00Z',
      LoteID: 12556174,
      CodigoLote: 2239,
      totalAnimalesLote: 39,
      canalesEvaluadas: 38,
      pesotTotalLote: 41485,
      promPCC: '109.171',
      promMC: '52.9474',
      promLM: '63.5789',
      promGD: '17.6632',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T05:36:00Z',
      LoteID: 12556176,
      CodigoLote: 2241,
      totalAnimalesLote: 49,
      canalesEvaluadas: 48,
      pesotTotalLote: 50969.6,
      promPCC: '105.983',
      promMC: '53.0417',
      promLM: '62.95',
      promGD: '17.4',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T06:56:00Z',
      LoteID: 12556178,
      CodigoLote: 2243,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 68671,
      promPCC: '122.627',
      promMC: '53.6286',
      promLM: '66.9929',
      promGD: '17.3357',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T07:30:00Z',
      LoteID: 12556180,
      CodigoLote: 2244,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 7161,
      promPCC: '127.875',
      promMC: '54.0107',
      promLM: '65.3357',
      promGD: '16.4429',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-23T08:18:00Z',
      LoteID: 12556182,
      CodigoLote: 2246,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 62616,
      promPCC: '113.847',
      promMC: '53.3545',
      promLM: '64.9236',
      promGD: '17.3236',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-21T05:51:00Z',
      LoteID: 12556047,
      CodigoLote: 2121,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 58523.2,
      promPCC: '111.994',
      promMC: '51.0231',
      promLM: '61.8538',
      promGD: '20.1769',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-21T07:58:00Z',
      LoteID: 12556057,
      CodigoLote: 2130,
      totalAnimalesLote: 49,
      canalesEvaluadas: 48,
      pesotTotalLote: 54962.8,
      promPCC: '114.323',
      promMC: '55.9604',
      promLM: '69.9167',
      promGD: '14.475',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-21T09:11:00Z',
      LoteID: 12556060,
      CodigoLote: 2133,
      totalAnimalesLote: 42,
      canalesEvaluadas: 41,
      pesotTotalLote: 44033.7,
      promPCC: '107.212',
      promMC: '55.6463',
      promLM: '65.3561',
      promGD: '14.0195',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T04:24:00Z',
      LoteID: 12533789,
      CodigoLote: 24,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 62825.1,
      promPCC: '120.421',
      promMC: '53.9058',
      promLM: '66.0462',
      promGD: '16.7308',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T04:24:00Z',
      LoteID: 12533789,
      CodigoLote: 24,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 62825.1,
      promPCC: '120.421',
      promMC: '53.9058',
      promLM: '66.0462',
      promGD: '16.7308',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T04:43:00Z',
      LoteID: 12533790,
      CodigoLote: 25,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 65054.6,
      promPCC: '120.217',
      promMC: '54.234',
      promLM: '66.1283',
      promGD: '16.2642',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T04:43:00Z',
      LoteID: 12533790,
      CodigoLote: 25,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 65054.6,
      promPCC: '120.217',
      promMC: '54.234',
      promLM: '66.1283',
      promGD: '16.2642',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T05:38:00Z',
      LoteID: 12533793,
      CodigoLote: 27,
      totalAnimalesLote: 52,
      canalesEvaluadas: 47,
      pesotTotalLote: 57990.3,
      promPCC: '119.663',
      promMC: '53.3404',
      promLM: '67.234',
      promGD: '17.8213',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T05:38:00Z',
      LoteID: 12533793,
      CodigoLote: 27,
      totalAnimalesLote: 52,
      canalesEvaluadas: 47,
      pesotTotalLote: 57990.3,
      promPCC: '119.663',
      promMC: '53.3404',
      promLM: '67.234',
      promGD: '17.8213',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T06:12:00Z',
      LoteID: 12533795,
      CodigoLote: 29,
      totalAnimalesLote: 11,
      canalesEvaluadas: 0,
      pesotTotalLote: 23601.6,
      promPCC: '195.055',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T06:12:00Z',
      LoteID: 12533795,
      CodigoLote: 29,
      totalAnimalesLote: 11,
      canalesEvaluadas: 0,
      pesotTotalLote: 23601.6,
      promPCC: '195.055',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T06:15:00Z',
      LoteID: 12533796,
      CodigoLote: 30,
      totalAnimalesLote: 11,
      canalesEvaluadas: 0,
      pesotTotalLote: 23984.4,
      promPCC: '198.218',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T06:15:00Z',
      LoteID: 12533796,
      CodigoLote: 30,
      totalAnimalesLote: 11,
      canalesEvaluadas: 0,
      pesotTotalLote: 23984.4,
      promPCC: '198.218',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T07:02:00Z',
      LoteID: 12533798,
      CodigoLote: 32,
      totalAnimalesLote: 16,
      canalesEvaluadas: 16,
      pesotTotalLote: 1666,
      promPCC: '104.125',
      promMC: '54.0312',
      promLM: '64.125',
      promGD: '16.175',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T07:02:00Z',
      LoteID: 12533798,
      CodigoLote: 32,
      totalAnimalesLote: 16,
      canalesEvaluadas: 16,
      pesotTotalLote: 1666,
      promPCC: '104.125',
      promMC: '54.0312',
      promLM: '64.125',
      promGD: '16.175',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T07:03:00Z',
      LoteID: 12533799,
      CodigoLote: 33,
      totalAnimalesLote: 36,
      canalesEvaluadas: 36,
      pesotTotalLote: 44155,
      promPCC: '122.653',
      promMC: '53.3833',
      promLM: '65.9667',
      promGD: '17.5',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T07:03:00Z',
      LoteID: 12533799,
      CodigoLote: 33,
      totalAnimalesLote: 36,
      canalesEvaluadas: 36,
      pesotTotalLote: 44155,
      promPCC: '122.653',
      promMC: '53.3833',
      promLM: '65.9667',
      promGD: '17.5',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T07:34:00Z',
      LoteID: 12533801,
      CodigoLote: 35,
      totalAnimalesLote: 44,
      canalesEvaluadas: 33,
      pesotTotalLote: 54381.5,
      promPCC: '126.255',
      promMC: '51.803',
      promLM: '61.0667',
      promGD: '18.8606',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T07:34:00Z',
      LoteID: 12533801,
      CodigoLote: 35,
      totalAnimalesLote: 44,
      canalesEvaluadas: 33,
      pesotTotalLote: 54381.5,
      promPCC: '126.255',
      promMC: '51.803',
      promLM: '61.0667',
      promGD: '18.8606',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T08:05:00Z',
      LoteID: 12533803,
      CodigoLote: 37,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 67563.9,
      promPCC: '122.604',
      promMC: '53.2241',
      promLM: '63.5481',
      promGD: '17.237',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T08:05:00Z',
      LoteID: 12533803,
      CodigoLote: 37,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 67563.9,
      promPCC: '122.604',
      promMC: '53.2241',
      promLM: '63.5481',
      promGD: '17.237',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T08:35:00Z',
      LoteID: 12533805,
      CodigoLote: 39,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 67602.1,
      promPCC: '127.338',
      promMC: '51.9736',
      promLM: '63.9774',
      promGD: '19.1849',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T08:35:00Z',
      LoteID: 12533805,
      CodigoLote: 39,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 67602.1,
      promPCC: '127.338',
      promMC: '51.9736',
      promLM: '63.9774',
      promGD: '19.1849',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T09:04:00Z',
      LoteID: 12533809,
      CodigoLote: 42,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 63986.4,
      promPCC: '122.906',
      promMC: '53.1442',
      promLM: '65.4077',
      promGD: '17.7462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T09:04:00Z',
      LoteID: 12533809,
      CodigoLote: 42,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 63986.4,
      promPCC: '122.906',
      promMC: '53.1442',
      promLM: '65.4077',
      promGD: '17.7462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T09:25:00Z',
      LoteID: 12533812,
      CodigoLote: 44,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 65599.6,
      promPCC: '121.022',
      promMC: '53.1226',
      promLM: '67.8717',
      promGD: '18.2566',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T09:25:00Z',
      LoteID: 12533812,
      CodigoLote: 44,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 65599.6,
      promPCC: '121.022',
      promMC: '53.1226',
      promLM: '67.8717',
      promGD: '18.2566',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T10:07:00Z',
      LoteID: 12533816,
      CodigoLote: 48,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 59237,
      promPCC: '109.698',
      promMC: '48.6278',
      promLM: '60.9704',
      promGD: '23.5481',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T10:07:00Z',
      LoteID: 12533816,
      CodigoLote: 48,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 59237,
      promPCC: '109.698',
      promMC: '48.6278',
      promLM: '60.9704',
      promGD: '23.5481',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T10:58:00Z',
      LoteID: 12533818,
      CodigoLote: 50,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 70710.1,
      promPCC: '133.179',
      promMC: '51.0981',
      promLM: '65.3585',
      promGD: '20.7623',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T10:58:00Z',
      LoteID: 12533818,
      CodigoLote: 50,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 70710.1,
      promPCC: '133.179',
      promMC: '51.0981',
      promLM: '65.3585',
      promGD: '20.7623',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T11:13:00Z',
      LoteID: 12533821,
      CodigoLote: 52,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 69247,
      promPCC: '125.904',
      promMC: '51.3127',
      promLM: '66.7491',
      promGD: '20.7273',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T11:13:00Z',
      LoteID: 12533821,
      CodigoLote: 52,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 69247,
      promPCC: '125.904',
      promMC: '51.3127',
      promLM: '66.7491',
      promGD: '20.7273',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T11:26:00Z',
      LoteID: 12533822,
      CodigoLote: 53,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 68936,
      promPCC: '127.659',
      promMC: '51.2167',
      promLM: '70.0519',
      promGD: '21.5333',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T11:26:00Z',
      LoteID: 12533822,
      CodigoLote: 53,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 68936,
      promPCC: '127.659',
      promMC: '51.2167',
      promLM: '70.0519',
      promGD: '21.5333',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T11:50:00Z',
      LoteID: 12533824,
      CodigoLote: 55,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 6389.2,
      promPCC: '120.385',
      promMC: '54.4538',
      promLM: '67.6308',
      promGD: '16.2385',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T11:50:00Z',
      LoteID: 12533824,
      CodigoLote: 55,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 6389.2,
      promPCC: '120.385',
      promMC: '54.4538',
      promLM: '67.6308',
      promGD: '16.2385',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T12:18:00Z',
      LoteID: 12533826,
      CodigoLote: 57,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 62875.1,
      promPCC: '120.485',
      promMC: '52.8288',
      promLM: '67.7',
      promGD: '18.6769',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T12:18:00Z',
      LoteID: 12533826,
      CodigoLote: 57,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 62875.1,
      promPCC: '120.485',
      promMC: '52.8288',
      promLM: '67.7',
      promGD: '18.6769',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T12:56:00Z',
      LoteID: 12533827,
      CodigoLote: 58,
      totalAnimalesLote: 54,
      canalesEvaluadas: 51,
      pesotTotalLote: 62311.1,
      promPCC: '121.722',
      promMC: '51.6137',
      promLM: '65.9373',
      promGD: '20.1255',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T12:56:00Z',
      LoteID: 12533827,
      CodigoLote: 58,
      totalAnimalesLote: 54,
      canalesEvaluadas: 51,
      pesotTotalLote: 62311.1,
      promPCC: '121.722',
      promMC: '51.6137',
      promLM: '65.9373',
      promGD: '20.1255',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T13:06:00Z',
      LoteID: 12533828,
      CodigoLote: 59,
      totalAnimalesLote: 47,
      canalesEvaluadas: 47,
      pesotTotalLote: 54463,
      promPCC: '115.879',
      promMC: '52.0809',
      promLM: '68.034',
      promGD: '19.8553',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T13:06:00Z',
      LoteID: 12533828,
      CodigoLote: 59,
      totalAnimalesLote: 47,
      canalesEvaluadas: 47,
      pesotTotalLote: 54463,
      promPCC: '115.879',
      promMC: '52.0809',
      promLM: '68.034',
      promGD: '19.8553',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T16:12:00Z',
      LoteID: 12533838,
      CodigoLote: 67,
      totalAnimalesLote: 46,
      canalesEvaluadas: 44,
      pesotTotalLote: 54519.6,
      promPCC: '120.604',
      promMC: '53.4318',
      promLM: '63.1909',
      promGD: '16.8727',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-04T16:12:00Z',
      LoteID: 12533838,
      CodigoLote: 67,
      totalAnimalesLote: 46,
      canalesEvaluadas: 44,
      pesotTotalLote: 54519.6,
      promPCC: '120.604',
      promMC: '53.4318',
      promLM: '63.1909',
      promGD: '16.8727',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T06:21:00Z',
      LoteID: 12533874,
      CodigoLote: 101,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 57934,
      promPCC: '105.335',
      promMC: '54.1218',
      promLM: '63.6509',
      promGD: '15.9418',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T06:21:00Z',
      LoteID: 12533874,
      CodigoLote: 101,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 57934,
      promPCC: '105.335',
      promMC: '54.1218',
      promLM: '63.6509',
      promGD: '15.9418',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T07:01:00Z',
      LoteID: 12533877,
      CodigoLote: 104,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 64056,
      promPCC: '114.386',
      promMC: '52.5679',
      promLM: '63.4714',
      promGD: '18.2071',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T07:01:00Z',
      LoteID: 12533877,
      CodigoLote: 104,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 64056,
      promPCC: '114.386',
      promMC: '52.5679',
      promLM: '63.4714',
      promGD: '18.2071',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T07:27:00Z',
      LoteID: 12533879,
      CodigoLote: 106,
      totalAnimalesLote: 47,
      canalesEvaluadas: 46,
      pesotTotalLote: 55923.1,
      promPCC: '118.738',
      promMC: '52.1283',
      promLM: '61.7826',
      promGD: '18.5304',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T07:27:00Z',
      LoteID: 12533879,
      CodigoLote: 106,
      totalAnimalesLote: 47,
      canalesEvaluadas: 46,
      pesotTotalLote: 55923.1,
      promPCC: '118.738',
      promMC: '52.1283',
      promLM: '61.7826',
      promGD: '18.5304',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T07:49:00Z',
      LoteID: 12533882,
      CodigoLote: 108,
      totalAnimalesLote: 55,
      canalesEvaluadas: 49,
      pesotTotalLote: 60185.5,
      promPCC: '119.328',
      promMC: '50.3796',
      promLM: '62.9796',
      promGD: '20.8555',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T07:49:00Z',
      LoteID: 12533882,
      CodigoLote: 108,
      totalAnimalesLote: 55,
      canalesEvaluadas: 49,
      pesotTotalLote: 60185.5,
      promPCC: '119.328',
      promMC: '50.3796',
      promLM: '62.9796',
      promGD: '20.8555',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T09:43:00Z',
      LoteID: 12533886,
      CodigoLote: 112,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 6676.5,
      promPCC: '121.778',
      promMC: '49.4593',
      promLM: '66.1704',
      promGD: '23.3704',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T09:43:00Z',
      LoteID: 12533886,
      CodigoLote: 112,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 6676.5,
      promPCC: '121.778',
      promMC: '49.4593',
      promLM: '66.1704',
      promGD: '23.3704',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T10:06:00Z',
      LoteID: 12533888,
      CodigoLote: 114,
      totalAnimalesLote: 57,
      canalesEvaluadas: 57,
      pesotTotalLote: 6710,
      promPCC: '117.719',
      promMC: '53.6702',
      promLM: '64.3088',
      promGD: '16.7368',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T10:06:00Z',
      LoteID: 12533888,
      CodigoLote: 114,
      totalAnimalesLote: 57,
      canalesEvaluadas: 57,
      pesotTotalLote: 6710,
      promPCC: '117.719',
      promMC: '53.6702',
      promLM: '64.3088',
      promGD: '16.7368',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T10:37:00Z',
      LoteID: 12533890,
      CodigoLote: 116,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 64813,
      promPCC: '115.737',
      promMC: '53.5446',
      promLM: '64.3071',
      promGD: '16.9214',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-07T10:47:00Z',
      LoteID: 12533891,
      CodigoLote: 117,
      totalAnimalesLote: 20,
      canalesEvaluadas: 0,
      pesotTotalLote: 42507.3,
      promPCC: '193.215',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '77.925',
    },
    {
      fechaLote: '2021-06-07T12:32:00Z',
      LoteID: 12533898,
      CodigoLote: 124,
      totalAnimalesLote: 20,
      canalesEvaluadas: 0,
      pesotTotalLote: 41364.4,
      promPCC: '188.02',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '78.2764',
    },
    {
      fechaLote: '2021-06-08T04:57:00Z',
      LoteID: 12533923,
      CodigoLote: 148,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 58935.1,
      promPCC: '106.953',
      promMC: '54.3491',
      promLM: '63.3527',
      promGD: '15.5418',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T04:57:00Z',
      LoteID: 12533923,
      CodigoLote: 148,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 58935.1,
      promPCC: '106.953',
      promMC: '54.3491',
      promLM: '63.3527',
      promGD: '15.5418',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T05:25:00Z',
      LoteID: 12533925,
      CodigoLote: 150,
      totalAnimalesLote: 32,
      canalesEvaluadas: 28,
      pesotTotalLote: 31872.8,
      promPCC: '109.61',
      promMC: '53.0679',
      promLM: '61.7714',
      promGD: '17.1286',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T05:25:00Z',
      LoteID: 12533925,
      CodigoLote: 150,
      totalAnimalesLote: 32,
      canalesEvaluadas: 28,
      pesotTotalLote: 31872.8,
      promPCC: '109.61',
      promMC: '53.0679',
      promLM: '61.7714',
      promGD: '17.1286',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T05:36:00Z',
      LoteID: 12533927,
      CodigoLote: 152,
      totalAnimalesLote: 56,
      canalesEvaluadas: 50,
      pesotTotalLote: 57239.5,
      promPCC: '113.304',
      promMC: '52.122',
      promLM: '66.44',
      promGD: '19.456',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T05:36:00Z',
      LoteID: 12533927,
      CodigoLote: 152,
      totalAnimalesLote: 56,
      canalesEvaluadas: 50,
      pesotTotalLote: 57239.5,
      promPCC: '113.304',
      promMC: '52.122',
      promLM: '66.44',
      promGD: '19.456',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T05:57:00Z',
      LoteID: 12533928,
      CodigoLote: 153,
      totalAnimalesLote: 20,
      canalesEvaluadas: 20,
      pesotTotalLote: 22667,
      promPCC: '113.335',
      promMC: '54.475',
      promLM: '67.26',
      promGD: '16.14',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T05:57:00Z',
      LoteID: 12533928,
      CodigoLote: 153,
      totalAnimalesLote: 20,
      canalesEvaluadas: 20,
      pesotTotalLote: 22667,
      promPCC: '113.335',
      promMC: '54.475',
      promLM: '67.26',
      promGD: '16.14',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T07:02:00Z',
      LoteID: 12533929,
      CodigoLote: 154,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 64111.4,
      promPCC: '122.854',
      promMC: '53.3269',
      promLM: '64.5231',
      promGD: '17.3',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T07:02:00Z',
      LoteID: 12533929,
      CodigoLote: 154,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 64111.4,
      promPCC: '122.854',
      promMC: '53.3269',
      promLM: '64.5231',
      promGD: '17.3',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T07:56:00Z',
      LoteID: 12533932,
      CodigoLote: 156,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 69333.6,
      promPCC: '128.196',
      promMC: '52.3704',
      promLM: '59.4889',
      promGD: '17.7111',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T07:56:00Z',
      LoteID: 12533932,
      CodigoLote: 156,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 69333.6,
      promPCC: '128.196',
      promMC: '52.3704',
      promLM: '59.4889',
      promGD: '17.7111',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T07:59:00Z',
      LoteID: 12533933,
      CodigoLote: 157,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 71535,
      promPCC: '130.064',
      promMC: '52.54',
      promLM: '62.4727',
      promGD: '18.0509',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T07:59:00Z',
      LoteID: 12533933,
      CodigoLote: 157,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 71535,
      promPCC: '130.064',
      promMC: '52.54',
      promLM: '62.4727',
      promGD: '18.0509',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T08:42:00Z',
      LoteID: 12533937,
      CodigoLote: 160,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 6853,
      promPCC: '126.907',
      promMC: '52.3093',
      promLM: '64.0815',
      promGD: '18.7111',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T08:42:00Z',
      LoteID: 12533937,
      CodigoLote: 160,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 6853,
      promPCC: '126.907',
      promMC: '52.3093',
      promLM: '64.0815',
      promGD: '18.7111',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T09:42:00Z',
      LoteID: 12533941,
      CodigoLote: 164,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 72449,
      promPCC: '131.725',
      promMC: '49.3945',
      promLM: '63.1782',
      promGD: '22.8727',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T10:51:00Z',
      LoteID: 12533945,
      CodigoLote: 168,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 64597,
      promPCC: '119.624',
      promMC: '54.5685',
      promLM: '65.1185',
      promGD: '15.5778',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T10:56:00Z',
      LoteID: 12533946,
      CodigoLote: 169,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 62433,
      promPCC: '115.617',
      promMC: '50.6944',
      promLM: '63.9111',
      promGD: '21.0741',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T11:08:00Z',
      LoteID: 12533948,
      CodigoLote: 171,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 63749,
      promPCC: '118.054',
      promMC: '49.9852',
      promLM: '64.9704',
      promGD: '22.3407',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T11:35:00Z',
      LoteID: 12533950,
      CodigoLote: 173,
      totalAnimalesLote: 52,
      canalesEvaluadas: 51,
      pesotTotalLote: 62356,
      promPCC: '122.267',
      promMC: '50.3039',
      promLM: '65.302',
      promGD: '21.9294',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T11:53:00Z',
      LoteID: 12533952,
      CodigoLote: 175,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 64413,
      promPCC: '119.283',
      promMC: '50.3463',
      promLM: '65.437',
      promGD: '21.8963',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T12:10:00Z',
      LoteID: 12533953,
      CodigoLote: 176,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 66280.5,
      promPCC: '122.533',
      promMC: '54.3056',
      promLM: '63.6815',
      promGD: '15.6741',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T12:41:00Z',
      LoteID: 12533954,
      CodigoLote: 177,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 68883,
      promPCC: '127.561',
      promMC: '52.0574',
      promLM: '63.6593',
      promGD: '19',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T13:08:00Z',
      LoteID: 12533955,
      CodigoLote: 178,
      totalAnimalesLote: 53,
      canalesEvaluadas: 49,
      pesotTotalLote: 66825.5,
      promPCC: '129.933',
      promMC: '51.1816',
      promLM: '61.1102',
      promGD: '19.7878',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-08T14:27:00Z',
      LoteID: 12533957,
      CodigoLote: 180,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 63628,
      promPCC: '117.83',
      promMC: '50.9148',
      promLM: '65.4593',
      promGD: '21.0593',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T05:29:00Z',
      LoteID: 12533991,
      CodigoLote: 211,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 63057,
      promPCC: '120.613',
      promMC: '52.1135',
      promLM: '63.6462',
      promGD: '18.9154',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T05:43:00Z',
      LoteID: 12533992,
      CodigoLote: 212,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 60224,
      promPCC: '107.543',
      promMC: '54.6411',
      promLM: '64.4929',
      promGD: '15.3357',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T06:19:00Z',
      LoteID: 12533993,
      CodigoLote: 213,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 66504,
      promPCC: '120.916',
      promMC: '49.8564',
      promLM: '66.2909',
      promGD: '22.7927',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T06:35:00Z',
      LoteID: 12533995,
      CodigoLote: 214,
      totalAnimalesLote: 52,
      canalesEvaluadas: 49,
      pesotTotalLote: 65253.1,
      promPCC: '132.4',
      promMC: '51.2245',
      promLM: '59.1918',
      promGD: '19.3469',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T06:59:00Z',
      LoteID: 12533996,
      CodigoLote: 215,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 61957,
      promPCC: '112.649',
      promMC: '53.7545',
      promLM: '63.8618',
      promGD: '16.5164',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T07:18:00Z',
      LoteID: 12533997,
      CodigoLote: 216,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 65804,
      promPCC: '126.031',
      promMC: '52.0385',
      promLM: '63.2692',
      promGD: '18.9462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T08:05:00Z',
      LoteID: 12533999,
      CodigoLote: 218,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 66307,
      promPCC: '127.513',
      promMC: '52.1808',
      promLM: '62.9077',
      promGD: '18.6692',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T10:40:00Z',
      LoteID: 12534005,
      CodigoLote: 224,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 6571,
      promPCC: '119.473',
      promMC: '49.7309',
      promLM: '64.1818',
      promGD: '22.5745',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T11:20:00Z',
      LoteID: 12534008,
      CodigoLote: 227,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 67178.3,
      promPCC: '124.107',
      promMC: '51.2943',
      promLM: '61.7736',
      promGD: '19.7585',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T11:49:00Z',
      LoteID: 12534010,
      CodigoLote: 229,
      totalAnimalesLote: 17,
      canalesEvaluadas: 16,
      pesotTotalLote: 20557.6,
      promPCC: '120.329',
      promMC: '47.3813',
      promLM: '59.4',
      promGD: '25.1',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T11:53:00Z',
      LoteID: 12534011,
      CodigoLote: 230,
      totalAnimalesLote: 33,
      canalesEvaluadas: 33,
      pesotTotalLote: 40635,
      promPCC: '123.136',
      promMC: '48.1273',
      promLM: '61.6364',
      promGD: '24.4364',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T12:36:00Z',
      LoteID: 12534013,
      CodigoLote: 232,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 69779.9,
      promPCC: '126.631',
      promMC: '50.1926',
      promLM: '61.5778',
      promGD: '21.3556',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T12:50:00Z',
      LoteID: 12534014,
      CodigoLote: 233,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 72260.5,
      promPCC: '131.16',
      promMC: '49.6926',
      promLM: '59.6889',
      promGD: '21.7111',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T14:05:00Z',
      LoteID: 12534017,
      CodigoLote: 236,
      totalAnimalesLote: 20,
      canalesEvaluadas: 20,
      pesotTotalLote: 24561,
      promPCC: '122.805',
      promMC: '46.98',
      promLM: '55.96',
      promGD: '25',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T14:07:00Z',
      LoteID: 12534018,
      CodigoLote: 237,
      totalAnimalesLote: 30,
      canalesEvaluadas: 30,
      pesotTotalLote: 35265,
      promPCC: '117.55',
      promMC: '47.4633',
      promLM: '58.3733',
      promGD: '24.76',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-09T23:51:00Z',
      LoteID: 12534042,
      CodigoLote: 260,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 64165.4,
      promPCC: '123.15',
      promMC: '51.1692',
      promLM: '65.1923',
      promGD: '20.6231',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T02:48:00Z',
      LoteID: 12534047,
      CodigoLote: 264,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 69352,
      promPCC: '128.43',
      promMC: '53.0204',
      promLM: '64.9037',
      promGD: '17.8296',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T04:19:00Z',
      LoteID: 12534050,
      CodigoLote: 267,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 68459,
      promPCC: '129.168',
      promMC: '52.5792',
      promLM: '64.166',
      promGD: '18.3321',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T04:48:00Z',
      LoteID: 12534052,
      CodigoLote: 269,
      totalAnimalesLote: 56,
      canalesEvaluadas: 54,
      pesotTotalLote: 69891.8,
      promPCC: '129.046',
      promMC: '52.5259',
      promLM: '65.0815',
      promGD: '18.5852',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T05:31:00Z',
      LoteID: 12534053,
      CodigoLote: 270,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 66845,
      promPCC: '128.548',
      promMC: '52.3385',
      promLM: '65.4231',
      promGD: '18.9385',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T07:22:00Z',
      LoteID: 12534057,
      CodigoLote: 273,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 73046,
      promPCC: '132.811',
      promMC: '51.16',
      promLM: '62.5091',
      promGD: '20.0945',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T07:31:00Z',
      LoteID: 12534058,
      CodigoLote: 274,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 68823,
      promPCC: '127.45',
      promMC: '53.2204',
      promLM: '65.9259',
      promGD: '17.7259',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T07:54:00Z',
      LoteID: 12534059,
      CodigoLote: 275,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 69574,
      promPCC: '128.841',
      promMC: '52.3056',
      promLM: '60.8',
      promGD: '18.0741',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T08:30:00Z',
      LoteID: 12534061,
      CodigoLote: 277,
      totalAnimalesLote: 32,
      canalesEvaluadas: 30,
      pesotTotalLote: 30124.8,
      promPCC: '99.98',
      promMC: '53.24',
      promLM: '61.0667',
      promGD: '16.72',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T09:47:00Z',
      LoteID: 12534065,
      CodigoLote: 281,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 65468,
      promPCC: '125.9',
      promMC: '52.5038',
      promLM: '61.6462',
      promGD: '17.9462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T11:18:00Z',
      LoteID: 12534067,
      CodigoLote: 283,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 69647,
      promPCC: '126.631',
      promMC: '53',
      promLM: '61.9782',
      promGD: '17.28',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T11:53:00Z',
      LoteID: 12534068,
      CodigoLote: 284,
      totalAnimalesLote: 52,
      canalesEvaluadas: 47,
      pesotTotalLote: 6371.1,
      promPCC: '128.213',
      promMC: '51.4851',
      promLM: '61.634',
      promGD: '19.4383',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T12:38:00Z',
      LoteID: 12534070,
      CodigoLote: 286,
      totalAnimalesLote: 25,
      canalesEvaluadas: 24,
      pesotTotalLote: 23071.3,
      promPCC: '95.7292',
      promMC: '51.525',
      promLM: '58.8667',
      promGD: '18.8333',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T12:43:00Z',
      LoteID: 12534071,
      CodigoLote: 287,
      totalAnimalesLote: 25,
      canalesEvaluadas: 25,
      pesotTotalLote: 31255,
      promPCC: '125.02',
      promMC: '47.312',
      promLM: '60.112',
      promGD: '25.344',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T13:21:00Z',
      LoteID: 12534074,
      CodigoLote: 290,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 64839.7,
      promPCC: '119.811',
      promMC: '53.6278',
      promLM: '61.3852',
      promGD: '16.2148',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T13:46:00Z',
      LoteID: 12534076,
      CodigoLote: 292,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 6227,
      promPCC: '115.315',
      promMC: '52.1333',
      promLM: '64.4148',
      promGD: '19.0444',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T14:08:00Z',
      LoteID: 12534077,
      CodigoLote: 293,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 65424.3,
      promPCC: '123.26',
      promMC: '50.766',
      promLM: '63.3208',
      promGD: '20.8604',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T14:40:00Z',
      LoteID: 12534079,
      CodigoLote: 294,
      totalAnimalesLote: 54,
      canalesEvaluadas: 50,
      pesotTotalLote: 63414.1,
      promPCC: '126.058',
      promMC: '50.972',
      promLM: '64.048',
      promGD: '20.696',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T02:40:00Z',
      LoteID: 12534100,
      CodigoLote: 313,
      totalAnimalesLote: 14,
      canalesEvaluadas: 14,
      pesotTotalLote: 16645,
      promPCC: '118.893',
      promMC: '53.9786',
      promLM: '63.6',
      promGD: '16.1429',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T02:45:00Z',
      LoteID: 12534101,
      CodigoLote: 314,
      totalAnimalesLote: 40,
      canalesEvaluadas: 39,
      pesotTotalLote: 46939.5,
      promPCC: '119.992',
      promMC: '54.5692',
      promLM: '67.7641',
      promGD: '16.0923',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T05:01:00Z',
      LoteID: 12534109,
      CodigoLote: 320,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 62416,
      promPCC: '117.766',
      promMC: '53.9792',
      promLM: '64.1585',
      promGD: '16.2491',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T05:44:00Z',
      LoteID: 12534110,
      CodigoLote: 321,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 62697,
      promPCC: '116.106',
      promMC: '53.3741',
      promLM: '65',
      promGD: '17.3185',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T06:07:00Z',
      LoteID: 12534113,
      CodigoLote: 323,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 65618,
      promPCC: '126.188',
      promMC: '54.3692',
      promLM: '63.5846',
      promGD: '15.5615',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T07:27:00Z',
      LoteID: 12534114,
      CodigoLote: 324,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 6353,
      promPCC: '119.868',
      promMC: '53.5019',
      promLM: '65.5245',
      promGD: '17.2302',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T07:45:00Z',
      LoteID: 12534115,
      CodigoLote: 325,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 65918,
      promPCC: '122.07',
      promMC: '50.4889',
      promLM: '63.6444',
      promGD: '21.3259',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T08:30:00Z',
      LoteID: 12534117,
      CodigoLote: 326,
      totalAnimalesLote: 56,
      canalesEvaluadas: 54,
      pesotTotalLote: 68673.2,
      promPCC: '126.691',
      promMC: '52.7204',
      promLM: '65.6667',
      promGD: '18.4222',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T08:50:00Z',
      LoteID: 12534118,
      CodigoLote: 327,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 58817.4,
      promPCC: '114.89',
      promMC: '51.0745',
      promLM: '65.3961',
      promGD: '20.8078',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T09:21:00Z',
      LoteID: 12534120,
      CodigoLote: 329,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 63162,
      promPCC: '121.277',
      promMC: '50.75',
      promLM: '65.8769',
      promGD: '21.3846',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T09:38:00Z',
      LoteID: 12534122,
      CodigoLote: 331,
      totalAnimalesLote: 14,
      canalesEvaluadas: 0,
      pesotTotalLote: 25711.4,
      promPCC: '166.957',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T09:43:00Z',
      LoteID: 12534123,
      CodigoLote: 332,
      totalAnimalesLote: 2,
      canalesEvaluadas: 0,
      pesotTotalLote: 3579.4,
      promPCC: '162.7',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T10:09:00Z',
      LoteID: 12534125,
      CodigoLote: 334,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 6403,
      promPCC: '118.574',
      promMC: '52.6463',
      promLM: '66.3037',
      promGD: '18.6593',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T10:28:00Z',
      LoteID: 12534127,
      CodigoLote: 336,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 62386,
      promPCC: '113.429',
      promMC: '53.6836',
      promLM: '65.44',
      promGD: '16.9382',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T11:01:00Z',
      LoteID: 12534130,
      CodigoLote: 338,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 6554,
      promPCC: '119.164',
      promMC: '50.0618',
      promLM: '67.7091',
      promGD: '22.7782',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T12:30:00Z',
      LoteID: 12534133,
      CodigoLote: 341,
      totalAnimalesLote: 16,
      canalesEvaluadas: 0,
      pesotTotalLote: 29409.6,
      promPCC: '167.1',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-11T13:20:00Z',
      LoteID: 12534135,
      CodigoLote: 343,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 62017,
      promPCC: '116.804',
      promMC: '53.1736',
      promLM: '68.0981',
      promGD: '18.234',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-12T05:15:00Z',
      LoteID: 12534165,
      CodigoLote: 371,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 61878,
      promPCC: '112.505',
      promMC: '52.7018',
      promLM: '60.7636',
      promGD: '17.4691',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-12T06:43:00Z',
      LoteID: 12534170,
      CodigoLote: 376,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 62268.3,
      promPCC: '119.348',
      promMC: '52.3962',
      promLM: '60.7154',
      promGD: '17.9077',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-12T07:14:00Z',
      LoteID: 12534172,
      CodigoLote: 378,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 58243.8,
      promPCC: '111.554',
      promMC: '53.5808',
      promLM: '62.7154',
      promGD: '16.5462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-12T07:44:00Z',
      LoteID: 12534173,
      CodigoLote: 379,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 60725.9,
      promPCC: '114.332',
      promMC: '53.2509',
      promLM: '65.6',
      promGD: '17.6226',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-14T06:18:00Z',
      LoteID: 12534177,
      CodigoLote: 383,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 6512.3,
      promPCC: '123.078',
      promMC: '53.5843',
      promLM: '66.9961',
      promGD: '17.4118',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-14T07:31:00Z',
      LoteID: 12534178,
      CodigoLote: 384,
      totalAnimalesLote: 48,
      canalesEvaluadas: 48,
      pesotTotalLote: 58495,
      promPCC: '121.865',
      promMC: '54.5917',
      promLM: '67.45',
      promGD: '16.0083',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-14T08:11:00Z',
      LoteID: 12534181,
      CodigoLote: 387,
      totalAnimalesLote: 48,
      canalesEvaluadas: 46,
      pesotTotalLote: 54571,
      promPCC: '118.117',
      promMC: '52.9696',
      promLM: '60.8348',
      promGD: '17.087',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-14T08:59:00Z',
      LoteID: 12534184,
      CodigoLote: 390,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 61194,
      promPCC: '117.681',
      promMC: '53.7038',
      promLM: '67.0846',
      promGD: '17.2538',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-14T09:10:00Z',
      LoteID: 12534186,
      CodigoLote: 392,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 68677,
      promPCC: '132.071',
      promMC: '52.3038',
      promLM: '70.3385',
      promGD: '19.9769',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-14T10:02:00Z',
      LoteID: 12534191,
      CodigoLote: 396,
      totalAnimalesLote: 51,
      canalesEvaluadas: 47,
      pesotTotalLote: 53694.8,
      promPCC: '113.555',
      promMC: '54.6',
      promLM: '64.7064',
      promGD: '15.4383',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-14T10:46:00Z',
      LoteID: 12534196,
      CodigoLote: 400,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 69257,
      promPCC: '133.187',
      promMC: '51.0942',
      promLM: '60.4154',
      promGD: '19.7769',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-14T10:55:00Z',
      LoteID: 12534197,
      CodigoLote: 401,
      totalAnimalesLote: 52,
      canalesEvaluadas: 50,
      pesotTotalLote: 58020.4,
      promPCC: '115.542',
      promMC: '54.182',
      promLM: '65.712',
      promGD: '16.264',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-14T11:38:00Z',
      LoteID: 12534200,
      CodigoLote: 404,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 5874,
      promPCC: '115.176',
      promMC: '49.1863',
      promLM: '61.9686',
      promGD: '22.9176',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-14T11:49:00Z',
      LoteID: 12534204,
      CodigoLote: 407,
      totalAnimalesLote: 20,
      canalesEvaluadas: 0,
      pesotTotalLote: 43403.8,
      promPCC: '197.29',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '79.6166',
    },
    {
      fechaLote: '2021-06-14T20:11:00Z',
      LoteID: 12534221,
      CodigoLote: 424,
      totalAnimalesLote: 20,
      canalesEvaluadas: 0,
      pesotTotalLote: 44207.9,
      promPCC: '200.945',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '81.0262',
    },
    {
      fechaLote: '2021-06-15T05:06:00Z',
      LoteID: 12534236,
      CodigoLote: 438,
      totalAnimalesLote: 52,
      canalesEvaluadas: 51,
      pesotTotalLote: 60045.1,
      promPCC: '117.496',
      promMC: '52.8373',
      promLM: '61.7255',
      promGD: '17.4588',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T05:33:00Z',
      LoteID: 12534238,
      CodigoLote: 440,
      totalAnimalesLote: 58,
      canalesEvaluadas: 58,
      pesotTotalLote: 68157,
      promPCC: '117.512',
      promMC: '52.1655',
      promLM: '60.6414',
      promGD: '18.2345',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T06:04:00Z',
      LoteID: 12534239,
      CodigoLote: 441,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 65564,
      promPCC: '119.207',
      promMC: '50.2891',
      promLM: '61.7164',
      promGD: '21.2436',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T06:25:00Z',
      LoteID: 12534240,
      CodigoLote: 442,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 59262.1,
      promPCC: '111.455',
      promMC: '51.7962',
      promLM: '61.5245',
      promGD: '18.966',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T06:44:00Z',
      LoteID: 12534241,
      CodigoLote: 443,
      totalAnimalesLote: 48,
      canalesEvaluadas: 48,
      pesotTotalLote: 62069,
      promPCC: '129.31',
      promMC: '51.8854',
      promLM: '61.9667',
      promGD: '18.925',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T08:26:00Z',
      LoteID: 12534245,
      CodigoLote: 447,
      totalAnimalesLote: 52,
      canalesEvaluadas: 50,
      pesotTotalLote: 6640.4,
      promPCC: '128.86',
      promMC: '51.242',
      promLM: '63.416',
      promGD: '20.152',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T08:53:00Z',
      LoteID: 12534246,
      CodigoLote: 448,
      totalAnimalesLote: 52,
      canalesEvaluadas: 50,
      pesotTotalLote: 62047,
      promPCC: '123.838',
      promMC: '53.022',
      promLM: '65.408',
      promGD: '17.92',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T09:05:00Z',
      LoteID: 12534247,
      CodigoLote: 449,
      totalAnimalesLote: 51,
      canalesEvaluadas: 50,
      pesotTotalLote: 60017,
      promPCC: '120.034',
      promMC: '52.91',
      promLM: '62.08',
      promGD: '17.416',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T10:02:00Z',
      LoteID: 12534251,
      CodigoLote: 453,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 63248,
      promPCC: '121.631',
      promMC: '52.6885',
      promLM: '63.7231',
      promGD: '18.0923',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T10:19:00Z',
      LoteID: 12534253,
      CodigoLote: 455,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 63984,
      promPCC: '118.489',
      promMC: '52.85',
      promLM: '61.8815',
      promGD: '17.4741',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T10:32:00Z',
      LoteID: 12534255,
      CodigoLote: 457,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 61836.7,
      promPCC: '118.438',
      promMC: '50.8827',
      promLM: '67.7846',
      promGD: '21.5846',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T11:21:00Z',
      LoteID: 12534258,
      CodigoLote: 460,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 55931.2,
      promPCC: '109.316',
      promMC: '50.8373',
      promLM: '61.7961',
      promGD: '20.4471',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T11:44:00Z',
      LoteID: 12534259,
      CodigoLote: 461,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 58523,
      promPCC: '110.421',
      promMC: '50.9868',
      promLM: '64.4',
      promGD: '20.7396',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T11:56:00Z',
      LoteID: 12534263,
      CodigoLote: 465,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 58839.14,
      promPCC: '115.227',
      promMC: '54.4843',
      promLM: '63.1922',
      promGD: '15.302',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T12:18:00Z',
      LoteID: 12534265,
      CodigoLote: 467,
      totalAnimalesLote: 53,
      canalesEvaluadas: 50,
      pesotTotalLote: 66579.8,
      promPCC: '129.796',
      promMC: '52.96',
      promLM: '69.888',
      promGD: '18.92',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T12:36:00Z',
      LoteID: 12534266,
      CodigoLote: 468,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 6632,
      promPCC: '127.538',
      promMC: '52.625',
      promLM: '62.7308',
      promGD: '17.9692',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T13:14:00Z',
      LoteID: 12534268,
      CodigoLote: 470,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 6182,
      promPCC: '116.642',
      promMC: '48.0547',
      promLM: '61.4491',
      promGD: '24.5057',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-15T13:37:00Z',
      LoteID: 12534270,
      CodigoLote: 472,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 63413,
      promPCC: '115.296',
      promMC: '51.6018',
      promLM: '63.6436',
      promGD: '19.68',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T04:48:00Z',
      LoteID: 12534311,
      CodigoLote: 507,
      totalAnimalesLote: 53,
      canalesEvaluadas: 46,
      pesotTotalLote: 59788.3,
      promPCC: '125.838',
      promMC: '52.7804',
      promLM: '62.3478',
      promGD: '17.6609',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T05:17:00Z',
      LoteID: 12534313,
      CodigoLote: 509,
      totalAnimalesLote: 51,
      canalesEvaluadas: 48,
      pesotTotalLote: 56622.6,
      promPCC: '115.012',
      promMC: '54.2646',
      promLM: '64.85',
      promGD: '15.9667',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T05:25:00Z',
      LoteID: 12534314,
      CodigoLote: 510,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 61270.4,
      promPCC: '111.213',
      promMC: '53.7333',
      promLM: '65.0593',
      promGD: '16.8074',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T07:24:00Z',
      LoteID: 12534317,
      CodigoLote: 513,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 61486,
      promPCC: '109.796',
      promMC: '52.2946',
      promLM: '64.3357',
      promGD: '18.7786',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T07:49:00Z',
      LoteID: 12534318,
      CodigoLote: 514,
      totalAnimalesLote: 52,
      canalesEvaluadas: 51,
      pesotTotalLote: 61030.4,
      promPCC: '119.41',
      promMC: '54.3745',
      promLM: '64.5412',
      promGD: '15.7412',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T08:16:00Z',
      LoteID: 12534319,
      CodigoLote: 515,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 61193,
      promPCC: '113.32',
      promMC: '53.7741',
      promLM: '66.4296',
      promGD: '17.0148',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T09:14:00Z',
      LoteID: 12534320,
      CodigoLote: 516,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 58896,
      promPCC: '111.125',
      promMC: '51.6528',
      promLM: '63.8792',
      promGD: '19.6453',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T10:29:00Z',
      LoteID: 12534324,
      CodigoLote: 520,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 63044,
      promPCC: '116.748',
      promMC: '54.5148',
      promLM: '67.1259',
      promGD: '16.0593',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T10:55:00Z',
      LoteID: 12534325,
      CodigoLote: 521,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 54323,
      promPCC: '108.646',
      promMC: '49.026',
      promLM: '58.216',
      promGD: '22.408',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T11:26:00Z',
      LoteID: 12534327,
      CodigoLote: 523,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 67669,
      promPCC: '125.313',
      promMC: '51.1537',
      promLM: '65.2889',
      promGD: '20.6667',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T11:32:00Z',
      LoteID: 12534328,
      CodigoLote: 524,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 64046.3,
      promPCC: '122.958',
      promMC: '51.675',
      promLM: '62.0538',
      promGD: '19.2462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T12:36:00Z',
      LoteID: 12534331,
      CodigoLote: 527,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 65367,
      promPCC: '123.334',
      promMC: '52.5453',
      promLM: '66.8981',
      promGD: '18.9283',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T13:09:00Z',
      LoteID: 12534333,
      CodigoLote: 529,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 64217,
      promPCC: '121.164',
      promMC: '53.4113',
      promLM: '64.966',
      promGD: '17.2528',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-16T13:41:00Z',
      LoteID: 12534336,
      CodigoLote: 531,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 51009,
      promPCC: '102.018',
      promMC: '49.672',
      promLM: '58.52',
      promGD: '21.512',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-17T05:36:00Z',
      LoteID: 12534372,
      CodigoLote: 561,
      totalAnimalesLote: 52,
      canalesEvaluadas: 49,
      pesotTotalLote: 59098.1,
      promPCC: '117.402',
      promMC: '52.2694',
      promLM: '57.2163',
      promGD: '17.4041',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-17T06:00:00Z',
      LoteID: 12534374,
      CodigoLote: 563,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 60967,
      promPCC: '110.849',
      promMC: '51.7418',
      promLM: '56.7927',
      promGD: '18.1018',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-17T06:36:00Z',
      LoteID: 12534376,
      CodigoLote: 565,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 63107,
      promPCC: '119.07',
      promMC: '49.7094',
      promLM: '61.8943',
      promGD: '22.1358',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-17T07:02:00Z',
      LoteID: 12534377,
      CodigoLote: 566,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 66600.4,
      promPCC: '123.08',
      promMC: '50.6759',
      promLM: '66.3333',
      promGD: '21.6',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-17T07:26:00Z',
      LoteID: 12534379,
      CodigoLote: 568,
      totalAnimalesLote: 41,
      canalesEvaluadas: 40,
      pesotTotalLote: 5135.4,
      promPCC: '124.875',
      promMC: '53.9525',
      promLM: '65.4',
      promGD: '16.55',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-17T12:33:00Z',
      LoteID: 12534385,
      CodigoLote: 573,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 66317,
      promPCC: '125.126',
      promMC: '52.9906',
      promLM: '65.3811',
      promGD: '17.9623',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-17T14:02:00Z',
      LoteID: 12534391,
      CodigoLote: 579,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 6318,
      promPCC: '114.873',
      promMC: '52.3818',
      promLM: '62.24',
      promGD: '18.24',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-17T18:21:00Z',
      LoteID: 12534395,
      CodigoLote: 582,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 58886,
      promPCC: '111.106',
      promMC: '51.2377',
      promLM: '63.0792',
      promGD: '20.1057',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-17T18:24:00Z',
      LoteID: 12534396,
      CodigoLote: 583,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 60581,
      promPCC: '114.304',
      promMC: '50.1283',
      promLM: '62.4226',
      promGD: '21.6226',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T00:38:00Z',
      LoteID: 12534409,
      CodigoLote: 596,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 64001,
      promPCC: '116.365',
      promMC: '50.2655',
      promLM: '62.1673',
      promGD: '21.36',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T01:16:00Z',
      LoteID: 12534410,
      CodigoLote: 597,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 62844,
      promPCC: '114.262',
      promMC: '52.0618',
      promLM: '64.3345',
      promGD: '19.1418',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T04:38:00Z',
      LoteID: 12534413,
      CodigoLote: 600,
      totalAnimalesLote: 39,
      canalesEvaluadas: 37,
      pesotTotalLote: 45361.4,
      promPCC: '115.723',
      promMC: '54.0595',
      promLM: '66.5405',
      promGD: '16.6054',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T05:56:00Z',
      LoteID: 12534416,
      CodigoLote: 603,
      totalAnimalesLote: 54,
      canalesEvaluadas: 51,
      pesotTotalLote: 63691.5,
      promPCC: '121.763',
      promMC: '53.6471',
      promLM: '58.0157',
      promGD: '15.5216',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T06:57:00Z',
      LoteID: 12534418,
      CodigoLote: 605,
      totalAnimalesLote: 42,
      canalesEvaluadas: 39,
      pesotTotalLote: 50762.9,
      promPCC: '122.946',
      promMC: '54.2385',
      promLM: '62.1538',
      promGD: '15.4667',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T07:08:00Z',
      LoteID: 12534419,
      CodigoLote: 606,
      totalAnimalesLote: 9,
      canalesEvaluadas: 7,
      pesotTotalLote: 10078.5,
      promPCC: '122.887',
      promMC: '52.2857',
      promLM: '54.1143',
      promGD: '16.7429',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T07:41:00Z',
      LoteID: 12534422,
      CodigoLote: 609,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 68088.8,
      promPCC: '123.571',
      promMC: '52.3873',
      promLM: '65.76',
      promGD: '18.9309',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T07:58:00Z',
      LoteID: 12534424,
      CodigoLote: 610,
      totalAnimalesLote: 52,
      canalesEvaluadas: 51,
      pesotTotalLote: 58648.5,
      promPCC: '114.778',
      promMC: '52.9255',
      promLM: '65.8745',
      promGD: '18.1647',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T08:37:00Z',
      LoteID: 12534428,
      CodigoLote: 613,
      totalAnimalesLote: 51,
      canalesEvaluadas: 50,
      pesotTotalLote: 62843.1,
      promPCC: '122.996',
      promMC: '52.748',
      promLM: '65.96',
      promGD: '18.432',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T09:08:00Z',
      LoteID: 12534430,
      CodigoLote: 615,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 62897,
      promPCC: '120.956',
      promMC: '52.4404',
      promLM: '63.3846',
      promGD: '18.3846',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T09:14:00Z',
      LoteID: 12534431,
      CodigoLote: 616,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 71858,
      promPCC: '130.651',
      promMC: '50.5709',
      promLM: '64.0727',
      promGD: '21.2945',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T09:59:00Z',
      LoteID: 12534433,
      CodigoLote: 618,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 66253,
      promPCC: '125.006',
      promMC: '53.0717',
      promLM: '64.1132',
      promGD: '17.5925',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T10:13:00Z',
      LoteID: 12534434,
      CodigoLote: 619,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 63509,
      promPCC: '122.133',
      promMC: '49.9788',
      promLM: '67.1154',
      promGD: '22.7692',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T11:01:00Z',
      LoteID: 12534436,
      CodigoLote: 621,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 64796,
      promPCC: '122.257',
      promMC: '53.0377',
      promLM: '66.8226',
      promGD: '18.1736',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T11:08:00Z',
      LoteID: 12534437,
      CodigoLote: 622,
      totalAnimalesLote: 39,
      canalesEvaluadas: 39,
      pesotTotalLote: 48285,
      promPCC: '123.808',
      promMC: '53.0282',
      promLM: '65.2205',
      promGD: '17.8667',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T11:52:00Z',
      LoteID: 12534440,
      CodigoLote: 624,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 60063,
      promPCC: '113.326',
      promMC: '50.3358',
      promLM: '63.2075',
      promGD: '21.4642',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T11:59:00Z',
      LoteID: 12534441,
      CodigoLote: 625,
      totalAnimalesLote: 12,
      canalesEvaluadas: 0,
      pesotTotalLote: 23451.3,
      promPCC: '192.264',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T12:00:00Z',
      LoteID: 12534442,
      CodigoLote: 626,
      totalAnimalesLote: 6,
      canalesEvaluadas: 0,
      pesotTotalLote: 11137.5,
      promPCC: '168.75',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T12:58:00Z',
      LoteID: 12534447,
      CodigoLote: 631,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 63547.2,
      promPCC: '119.704',
      promMC: '50.1846',
      promLM: '64.5769',
      promGD: '21.9615',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T13:28:00Z',
      LoteID: 12534449,
      CodigoLote: 633,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 65472,
      promPCC: '121.244',
      promMC: '52.787',
      promLM: '67.3185',
      promGD: '18.6444',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T13:57:00Z',
      LoteID: 12534450,
      CodigoLote: 634,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 67065.2,
      promPCC: '126.272',
      promMC: '53.1981',
      promLM: '65.1623',
      promGD: '17.6075',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T16:03:00Z',
      LoteID: 12534455,
      CodigoLote: 638,
      totalAnimalesLote: 24,
      canalesEvaluadas: 24,
      pesotTotalLote: 24206,
      promPCC: '100.858',
      promMC: '49.8375',
      promLM: '57.3667',
      promGD: '21.05',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-18T16:11:00Z',
      LoteID: 12534456,
      CodigoLote: 639,
      totalAnimalesLote: 26,
      canalesEvaluadas: 26,
      pesotTotalLote: 25684,
      promPCC: '98.7846',
      promMC: '50.9269',
      promLM: '58.4',
      promGD: '19.6154',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-19T06:04:00Z',
      LoteID: 12534479,
      CodigoLote: 662,
      totalAnimalesLote: 52,
      canalesEvaluadas: 50,
      pesotTotalLote: 56371.9,
      promPCC: '112.296',
      promMC: '54.274',
      promLM: '64.104',
      promGD: '15.808',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-19T06:24:00Z',
      LoteID: 12534480,
      CodigoLote: 663,
      totalAnimalesLote: 48,
      canalesEvaluadas: 46,
      pesotTotalLote: 5336.4,
      promPCC: '113.696',
      promMC: '54.0304',
      promLM: '62.2174',
      promGD: '15.7913',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-20T06:05:00Z',
      LoteID: 12534484,
      CodigoLote: 666,
      totalAnimalesLote: 58,
      canalesEvaluadas: 58,
      pesotTotalLote: 63851,
      promPCC: '110.088',
      promMC: '54.9948',
      promLM: '66.8828',
      promGD: '15.2828',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-20T06:37:00Z',
      LoteID: 12534486,
      CodigoLote: 668,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 61638,
      promPCC: '118.535',
      promMC: '54.0346',
      promLM: '63.0538',
      promGD: '15.9462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-20T08:32:00Z',
      LoteID: 12534492,
      CodigoLote: 673,
      totalAnimalesLote: 57,
      canalesEvaluadas: 56,
      pesotTotalLote: 62983.1,
      promPCC: '112.248',
      promMC: '53.3054',
      promLM: '64.6857',
      promGD: '17.3643',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-20T09:12:00Z',
      LoteID: 12534494,
      CodigoLote: 675,
      totalAnimalesLote: 52,
      canalesEvaluadas: 49,
      pesotTotalLote: 58184.3,
      promPCC: '118.155',
      promMC: '53.798',
      promLM: '64.5388',
      promGD: '16.5878',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-20T09:58:00Z',
      LoteID: 12534496,
      CodigoLote: 677,
      totalAnimalesLote: 50,
      canalesEvaluadas: 48,
      pesotTotalLote: 54616.8,
      promPCC: '113.308',
      promMC: '54.2',
      promLM: '68.4667',
      promGD: '16.7917',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-20T10:20:00Z',
      LoteID: 12534498,
      CodigoLote: 679,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 70119,
      promPCC: '125.213',
      promMC: '50.625',
      promLM: '67.6429',
      promGD: '21.9286',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-20T10:30:00Z',
      LoteID: 12534499,
      CodigoLote: 680,
      totalAnimalesLote: 15,
      canalesEvaluadas: 0,
      pesotTotalLote: 30999.1,
      promPCC: '187.873',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '79.0713',
    },
    {
      fechaLote: '2021-06-20T11:55:00Z',
      LoteID: 12534504,
      CodigoLote: 684,
      totalAnimalesLote: 48,
      canalesEvaluadas: 47,
      pesotTotalLote: 53130.4,
      promPCC: '112.8',
      promMC: '52.2574',
      promLM: '65.9404',
      promGD: '19.166',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-20T12:57:00Z',
      LoteID: 12534507,
      CodigoLote: 687,
      totalAnimalesLote: 23,
      canalesEvaluadas: 0,
      pesotTotalLote: 42700.9,
      promPCC: '168.778',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '80.1549',
    },
    {
      fechaLote: '2021-06-20T13:40:00Z',
      LoteID: 12534510,
      CodigoLote: 689,
      totalAnimalesLote: 47,
      canalesEvaluadas: 46,
      pesotTotalLote: 52641.7,
      promPCC: '114.28',
      promMC: '53.8804',
      promLM: '66.4435',
      promGD: '16.8435',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T05:21:00Z',
      LoteID: 12534537,
      CodigoLote: 714,
      totalAnimalesLote: 54,
      canalesEvaluadas: 49,
      pesotTotalLote: 61804.7,
      promPCC: '119.722',
      promMC: '53.0918',
      promLM: '63.7061',
      promGD: '17.4857',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T05:45:00Z',
      LoteID: 12534539,
      CodigoLote: 716,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 66515,
      promPCC: '120.936',
      promMC: '52.5036',
      promLM: '65.3018',
      promGD: '18.6764',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T06:25:00Z',
      LoteID: 12534540,
      CodigoLote: 717,
      totalAnimalesLote: 57,
      canalesEvaluadas: 57,
      pesotTotalLote: 66246,
      promPCC: '116.221',
      promMC: '53.5386',
      promLM: '67.1018',
      promGD: '17.4947',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T06:44:00Z',
      LoteID: 12534544,
      CodigoLote: 720,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 74719,
      promPCC: '140.979',
      promMC: '50.4509',
      promLM: '60.8604',
      promGD: '20.8151',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T07:16:00Z',
      LoteID: 12534546,
      CodigoLote: 722,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 62621,
      promPCC: '125.242',
      promMC: '48.686',
      promLM: '64.672',
      promGD: '24.216',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T08:10:00Z',
      LoteID: 12534550,
      CodigoLote: 726,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 68511,
      promPCC: '131.752',
      promMC: '50.8962',
      promLM: '61.3692',
      promGD: '20.2692',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T08:59:00Z',
      LoteID: 12534551,
      CodigoLote: 727,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 67452.9,
      promPCC: '124.663',
      promMC: '55.7611',
      promLM: '78.9259',
      promGD: '16.5778',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T09:32:00Z',
      LoteID: 12534553,
      CodigoLote: 729,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 61854.8,
      promPCC: '112.229',
      promMC: '57.2873',
      promLM: '76.3709',
      promGD: '13.7891',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T12:03:00Z',
      LoteID: 12534562,
      CodigoLote: 738,
      totalAnimalesLote: 56,
      canalesEvaluadas: 54,
      pesotTotalLote: 66005.3,
      promPCC: '121.835',
      promMC: '53.5463',
      promLM: '71.2296',
      promGD: '18.3037',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-10T08:28:00Z',
      LoteID: 12534060,
      CodigoLote: 276,
      totalAnimalesLote: 25,
      canalesEvaluadas: 25,
      pesotTotalLote: 28375,
      promPCC: '113.5',
      promMC: '53.764',
      promLM: '64.304',
      promGD: '16.592',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T12:59:00Z',
      LoteID: 12534566,
      CodigoLote: 742,
      totalAnimalesLote: 55,
      canalesEvaluadas: 45,
      pesotTotalLote: 50214.6,
      promPCC: '107.187',
      promMC: '56.2933',
      promLM: '71.7511',
      promGD: '14.3378',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T14:36:00Z',
      LoteID: 12534572,
      CodigoLote: 748,
      totalAnimalesLote: 49,
      canalesEvaluadas: 48,
      pesotTotalLote: 58828.3,
      promPCC: '122.31',
      promMC: '55.5479',
      promLM: '77.4917',
      promGD: '16.6',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T14:48:00Z',
      LoteID: 12534574,
      CodigoLote: 750,
      totalAnimalesLote: 48,
      canalesEvaluadas: 46,
      pesotTotalLote: 56052,
      promPCC: '121.852',
      promMC: '56.3478',
      promLM: '78.7565',
      promGD: '15.6522',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-21T16:48:00Z',
      LoteID: 12534578,
      CodigoLote: 754,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 61754,
      promPCC: '114.359',
      promMC: '56.6111',
      promLM: '74',
      promGD: '14.3111',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T02:59:00Z',
      LoteID: 12534601,
      CodigoLote: 776,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 65639.6,
      promPCC: '123.326',
      promMC: '56.9189',
      promLM: '78.0604',
      promGD: '14.6642',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T02:59:00Z',
      LoteID: 12534601,
      CodigoLote: 776,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 65639.6,
      promPCC: '123.326',
      promMC: '56.9189',
      promLM: '78.0604',
      promGD: '14.6642',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T02:59:00Z',
      LoteID: 12534601,
      CodigoLote: 776,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 65639.6,
      promPCC: '123.326',
      promMC: '56.9189',
      promLM: '78.0604',
      promGD: '14.6642',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T03:57:00Z',
      LoteID: 12534604,
      CodigoLote: 779,
      totalAnimalesLote: 21,
      canalesEvaluadas: 17,
      pesotTotalLote: 21367.7,
      promPCC: '123.024',
      promMC: '56.3706',
      promLM: '78.3059',
      promGD: '15.5294',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T03:57:00Z',
      LoteID: 12534604,
      CodigoLote: 779,
      totalAnimalesLote: 21,
      canalesEvaluadas: 17,
      pesotTotalLote: 21367.7,
      promPCC: '123.024',
      promMC: '56.3706',
      promLM: '78.3059',
      promGD: '15.5294',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T03:57:00Z',
      LoteID: 12534604,
      CodigoLote: 779,
      totalAnimalesLote: 21,
      canalesEvaluadas: 17,
      pesotTotalLote: 21367.7,
      promPCC: '123.024',
      promMC: '56.3706',
      promLM: '78.3059',
      promGD: '15.5294',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T06:50:00Z',
      LoteID: 12534610,
      CodigoLote: 785,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 56911.4,
      promPCC: '104.854',
      promMC: '55.5731',
      promLM: '67.2769',
      promGD: '14.5077',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T06:50:00Z',
      LoteID: 12534610,
      CodigoLote: 785,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 56911.4,
      promPCC: '104.854',
      promMC: '55.5731',
      promLM: '67.2769',
      promGD: '14.5077',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T06:50:00Z',
      LoteID: 12534610,
      CodigoLote: 785,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 56911.4,
      promPCC: '104.854',
      promMC: '55.5731',
      promLM: '67.2769',
      promGD: '14.5077',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T07:23:00Z',
      LoteID: 12534611,
      CodigoLote: 786,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 57023,
      promPCC: '111.81',
      promMC: '56.1255',
      promLM: '72.1412',
      promGD: '14.6667',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T08:02:00Z',
      LoteID: 12534614,
      CodigoLote: 789,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 68468,
      promPCC: '126.793',
      promMC: '55.95',
      promLM: '76.2222',
      promGD: '15.7481',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T08:02:00Z',
      LoteID: 12534614,
      CodigoLote: 789,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 68468,
      promPCC: '126.793',
      promMC: '55.95',
      promLM: '76.2222',
      promGD: '15.7481',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T08:25:00Z',
      LoteID: 12534616,
      CodigoLote: 791,
      totalAnimalesLote: 50,
      canalesEvaluadas: 49,
      pesotTotalLote: 59833.1,
      promPCC: '121.906',
      promMC: '51.2224',
      promLM: '69.502',
      promGD: '21.4204',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T09:02:00Z',
      LoteID: 12534619,
      CodigoLote: 793,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 53644.2,
      promPCC: '107.092',
      promMC: '56.2941',
      promLM: '68.4941',
      promGD: '13.6863',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T09:40:00Z',
      LoteID: 12534621,
      CodigoLote: 795,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 61963,
      promPCC: '116.911',
      promMC: '51.117',
      promLM: '65.2302',
      promGD: '20.717',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T10:00:00Z',
      LoteID: 12534622,
      CodigoLote: 796,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 64011,
      promPCC: '116.384',
      promMC: '53.1055',
      promLM: '75.5709',
      promGD: '19.8255',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T10:24:00Z',
      LoteID: 12534624,
      CodigoLote: 798,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 61614.7,
      promPCC: '116.14',
      promMC: '51.2132',
      promLM: '72.9434',
      promGD: '22.1208',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T11:12:00Z',
      LoteID: 12534628,
      CodigoLote: 801,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 71433.3,
      promPCC: '132.002',
      promMC: '53.2167',
      promLM: '76.6074',
      promGD: '19.8667',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T11:17:00Z',
      LoteID: 12534629,
      CodigoLote: 802,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 52011,
      promPCC: '104.022',
      promMC: '48.74',
      promLM: '57.768',
      promGD: '22.744',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T11:45:00Z',
      LoteID: 12534631,
      CodigoLote: 804,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 60801,
      promPCC: '114.719',
      promMC: '50.4623',
      promLM: '64.5283',
      promGD: '21.5472',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T13:33:00Z',
      LoteID: 12534639,
      CodigoLote: 812,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 69346,
      promPCC: '123.832',
      promMC: '52.8393',
      promLM: '73.75',
      promGD: '19.8643',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T15:15:00Z',
      LoteID: 12534645,
      CodigoLote: 817,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 52324,
      promPCC: '104.648',
      promMC: '49.476',
      promLM: '59.84',
      promGD: '22.072',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T15:15:00Z',
      LoteID: 12534645,
      CodigoLote: 817,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 52324,
      promPCC: '104.648',
      promMC: '49.476',
      promLM: '59.84',
      promGD: '22.072',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T15:41:00Z',
      LoteID: 12534646,
      CodigoLote: 818,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 69757,
      promPCC: '129.18',
      promMC: '52.0759',
      promLM: '64.3481',
      promGD: '19.1185',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T15:41:00Z',
      LoteID: 12534646,
      CodigoLote: 818,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 69757,
      promPCC: '129.18',
      promMC: '52.0759',
      promLM: '64.3481',
      promGD: '19.1185',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T17:16:00Z',
      LoteID: 12534649,
      CodigoLote: 821,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 64587,
      promPCC: '123.76',
      promMC: '54.0827',
      promLM: '74.9077',
      promGD: '18.2462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T17:16:00Z',
      LoteID: 12534649,
      CodigoLote: 821,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 64587,
      promPCC: '123.76',
      promMC: '54.0827',
      promLM: '74.9077',
      promGD: '18.2462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-22T17:16:00Z',
      LoteID: 12534649,
      CodigoLote: 821,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 64587,
      promPCC: '123.76',
      promMC: '54.0827',
      promLM: '74.9077',
      promGD: '18.2462',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T05:09:00Z',
      LoteID: 12534673,
      CodigoLote: 841,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 64658,
      promPCC: '115.461',
      promMC: '54.7161',
      promLM: '65.5714',
      promGD: '15.4357',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T05:09:00Z',
      LoteID: 12534673,
      CodigoLote: 841,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 64658,
      promPCC: '115.461',
      promMC: '54.7161',
      promLM: '65.5714',
      promGD: '15.4357',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T05:09:00Z',
      LoteID: 12534673,
      CodigoLote: 841,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 64658,
      promPCC: '115.461',
      promMC: '54.7161',
      promLM: '65.5714',
      promGD: '15.4357',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T05:43:00Z',
      LoteID: 12534674,
      CodigoLote: 842,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 70568,
      promPCC: '130.681',
      promMC: '52.0815',
      promLM: '64.5259',
      promGD: '19.1481',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T05:43:00Z',
      LoteID: 12534674,
      CodigoLote: 842,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 70568,
      promPCC: '130.681',
      promMC: '52.0815',
      promLM: '64.5259',
      promGD: '19.1481',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T05:43:00Z',
      LoteID: 12534674,
      CodigoLote: 842,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 70568,
      promPCC: '130.681',
      promMC: '52.0815',
      promLM: '64.5259',
      promGD: '19.1481',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T06:01:00Z',
      LoteID: 12534675,
      CodigoLote: 843,
      totalAnimalesLote: 57,
      canalesEvaluadas: 53,
      pesotTotalLote: 63841.2,
      promPCC: '119.689',
      promMC: '52.5208',
      promLM: '66.9509',
      promGD: '18.966',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T06:01:00Z',
      LoteID: 12534675,
      CodigoLote: 843,
      totalAnimalesLote: 57,
      canalesEvaluadas: 53,
      pesotTotalLote: 63841.2,
      promPCC: '119.689',
      promMC: '52.5208',
      promLM: '66.9509',
      promGD: '18.966',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T07:01:00Z',
      LoteID: 12534676,
      CodigoLote: 844,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 64631.9,
      promPCC: '121.562',
      promMC: '53.4415',
      promLM: '65.6',
      promGD: '17.3434',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T07:31:00Z',
      LoteID: 12534677,
      CodigoLote: 845,
      totalAnimalesLote: 51,
      canalesEvaluadas: 47,
      pesotTotalLote: 56450.8,
      promPCC: '116.963',
      promMC: '51.4574',
      promLM: '61.8468',
      promGD: '19.5319',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T07:31:00Z',
      LoteID: 12534677,
      CodigoLote: 845,
      totalAnimalesLote: 51,
      canalesEvaluadas: 47,
      pesotTotalLote: 56450.8,
      promPCC: '116.963',
      promMC: '51.4574',
      promLM: '61.8468',
      promGD: '19.5319',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T08:05:00Z',
      LoteID: 12534679,
      CodigoLote: 847,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 69084,
      promPCC: '127.933',
      promMC: '51.6519',
      promLM: '62.3333',
      promGD: '18.8274',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T08:05:00Z',
      LoteID: 12534679,
      CodigoLote: 847,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 69084,
      promPCC: '127.933',
      promMC: '51.6519',
      promLM: '62.3333',
      promGD: '18.8274',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T08:44:00Z',
      LoteID: 12534680,
      CodigoLote: 848,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 65184.4,
      promPCC: '118.318',
      promMC: '53.6418',
      promLM: '69.2873',
      promGD: '17.7818',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T08:44:00Z',
      LoteID: 12534680,
      CodigoLote: 848,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 65184.4,
      promPCC: '118.318',
      promMC: '53.6418',
      promLM: '69.2873',
      promGD: '17.7818',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T09:05:00Z',
      LoteID: 12534682,
      CodigoLote: 850,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 68877,
      promPCC: '125.231',
      promMC: '51.7782',
      promLM: '63.0255',
      promGD: '19.2873',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T09:05:00Z',
      LoteID: 12534682,
      CodigoLote: 850,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 68877,
      promPCC: '125.231',
      promMC: '51.7782',
      promLM: '63.0255',
      promGD: '19.2873',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T09:51:00Z',
      LoteID: 12534683,
      CodigoLote: 851,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 67078,
      promPCC: '124.219',
      promMC: '52.0537',
      promLM: '66.5333',
      promGD: '19.5926',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T09:51:00Z',
      LoteID: 12534683,
      CodigoLote: 851,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 67078,
      promPCC: '124.219',
      promMC: '52.0537',
      promLM: '66.5333',
      promGD: '19.5926',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T10:07:00Z',
      LoteID: 12534686,
      CodigoLote: 854,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 64404,
      promPCC: '119.267',
      promMC: '52.1852',
      promLM: '67.7037',
      promGD: '19.6148',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T11:18:00Z',
      LoteID: 12534690,
      CodigoLote: 858,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 66611,
      promPCC: '118.948',
      promMC: '49.9232',
      promLM: '62.0429',
      promGD: '21.8429',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T11:42:00Z',
      LoteID: 12534692,
      CodigoLote: 860,
      totalAnimalesLote: 18,
      canalesEvaluadas: 18,
      pesotTotalLote: 17371,
      promPCC: '96.5056',
      promMC: '49.8722',
      promLM: '57.0444',
      promGD: '20.9111',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T11:49:00Z',
      LoteID: 12534693,
      CodigoLote: 861,
      totalAnimalesLote: 32,
      canalesEvaluadas: 32,
      pesotTotalLote: 30905,
      promPCC: '96.5781',
      promMC: '50.2594',
      promLM: '58.8375',
      promGD: '20.725',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-23T16:33:00Z',
      LoteID: 12534701,
      CodigoLote: 868,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 64086,
      promPCC: '116.52',
      promMC: '49.7727',
      promLM: '64',
      promGD: '22.4582',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-24T07:41:00Z',
      LoteID: 12534732,
      CodigoLote: 898,
      totalAnimalesLote: 52,
      canalesEvaluadas: 51,
      pesotTotalLote: 70493.8,
      promPCC: '137.986',
      promMC: '52.3451',
      promLM: '66.2118',
      promGD: '19.0824',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-24T08:07:00Z',
      LoteID: 12534733,
      CodigoLote: 899,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 65077.5,
      promPCC: '120.3',
      promMC: '52.7926',
      promLM: '63.3407',
      promGD: '17.8519',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-24T09:35:00Z',
      LoteID: 12534735,
      CodigoLote: 900,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 65999,
      promPCC: '119.998',
      promMC: '52.98',
      promLM: '66.3345',
      promGD: '18.1673',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-24T09:51:00Z',
      LoteID: 12534737,
      CodigoLote: 902,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 63297.6,
      promPCC: '119.079',
      promMC: '49.1321',
      promLM: '65.2377',
      promGD: '23.6604',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-24T11:12:00Z',
      LoteID: 12534739,
      CodigoLote: 904,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 61394,
      promPCC: '115.838',
      promMC: '52.4208',
      promLM: '66.3396',
      promGD: '18.9962',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-24T11:28:00Z',
      LoteID: 12534741,
      CodigoLote: 906,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 61745.3,
      promPCC: '118.496',
      promMC: '51.9981',
      promLM: '64.3154',
      promGD: '19.2308',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-24T13:47:00Z',
      LoteID: 12534743,
      CodigoLote: 908,
      totalAnimalesLote: 19,
      canalesEvaluadas: 0,
      pesotTotalLote: 36229.6,
      promPCC: '173.347',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-24T16:21:00Z',
      LoteID: 12534750,
      CodigoLote: 914,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 70746,
      promPCC: '131.011',
      promMC: '51.5722',
      promLM: '64.6074',
      promGD: '19.9185',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-24T16:52:00Z',
      LoteID: 12534753,
      CodigoLote: 917,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 6164,
      promPCC: '114.148',
      promMC: '52.1037',
      promLM: '62.9852',
      promGD: '18.8',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T05:45:00Z',
      LoteID: 12534768,
      CodigoLote: 932,
      totalAnimalesLote: 50,
      canalesEvaluadas: 49,
      pesotTotalLote: 54247.1,
      promPCC: '110.451',
      promMC: '53.6265',
      promLM: '64.498',
      promGD: '16.8327',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T06:44:00Z',
      LoteID: 12534770,
      CodigoLote: 934,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 67951,
      promPCC: '123.547',
      promMC: '52.8473',
      promLM: '63.8109',
      promGD: '17.8545',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T07:12:00Z',
      LoteID: 12534771,
      CodigoLote: 935,
      totalAnimalesLote: 52,
      canalesEvaluadas: 51,
      pesotTotalLote: 63366.8,
      promPCC: '123.996',
      promMC: '54.6275',
      promLM: '62.698',
      promGD: '14.9961',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T08:28:00Z',
      LoteID: 12534775,
      CodigoLote: 939,
      totalAnimalesLote: 53,
      canalesEvaluadas: 53,
      pesotTotalLote: 61211,
      promPCC: '115.492',
      promMC: '54.6358',
      promLM: '66.2264',
      promGD: '15.6981',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T08:31:00Z',
      LoteID: 12534776,
      CodigoLote: 940,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 68054,
      promPCC: '126.026',
      promMC: '52.8315',
      promLM: '63.9407',
      promGD: '17.9111',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T09:26:00Z',
      LoteID: 12534781,
      CodigoLote: 945,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 64392,
      promPCC: '117.076',
      promMC: '54.7418',
      promLM: '68.48',
      promGD: '15.9855',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T09:58:00Z',
      LoteID: 12534783,
      CodigoLote: 947,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 63905.8,
      promPCC: '115.995',
      promMC: '54.2037',
      promLM: '64.363',
      promGD: '15.9556',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T10:06:00Z',
      LoteID: 12534784,
      CodigoLote: 948,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 62890,
      promPCC: '118.081',
      promMC: '50.0673',
      promLM: '65.4308',
      promGD: '22.3',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T11:20:00Z',
      LoteID: 12534787,
      CodigoLote: 950,
      totalAnimalesLote: 4,
      canalesEvaluadas: 0,
      pesotTotalLote: 1246,
      promPCC: '155.75',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T11:25:00Z',
      LoteID: 12534788,
      CodigoLote: 951,
      totalAnimalesLote: 14,
      canalesEvaluadas: 0,
      pesotTotalLote: 25076.7,
      promPCC: '162.836',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T11:43:00Z',
      LoteID: 12534789,
      CodigoLote: 952,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 6944.4,
      promPCC: '127.66',
      promMC: '52.666',
      promLM: '69.7736',
      promGD: '19.3208',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T12:01:00Z',
      LoteID: 12534790,
      CodigoLote: 953,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 70165,
      promPCC: '129.935',
      promMC: '52.3907',
      promLM: '67.5259',
      promGD: '19.2815',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T12:46:00Z',
      LoteID: 12534791,
      CodigoLote: 954,
      totalAnimalesLote: 16,
      canalesEvaluadas: 0,
      pesotTotalLote: 29697.8,
      promPCC: '168.738',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T13:22:00Z',
      LoteID: 12534793,
      CodigoLote: 956,
      totalAnimalesLote: 48,
      canalesEvaluadas: 48,
      pesotTotalLote: 58892,
      promPCC: '122.692',
      promMC: '52.2042',
      promLM: '67.4',
      promGD: '19.5333',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T13:36:00Z',
      LoteID: 12534795,
      CodigoLote: 957,
      totalAnimalesLote: 53,
      canalesEvaluadas: 52,
      pesotTotalLote: 63344.6,
      promPCC: '121.54',
      promMC: '54.3538',
      promLM: '69.1077',
      promGD: '16.6923',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T13:59:00Z',
      LoteID: 12534796,
      CodigoLote: 958,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 65928,
      promPCC: '119.869',
      promMC: '53.6436',
      promLM: '66.0436',
      promGD: '17.12',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T15:03:00Z',
      LoteID: 12534801,
      CodigoLote: 962,
      totalAnimalesLote: 49,
      canalesEvaluadas: 46,
      pesotTotalLote: 63740.4,
      promPCC: '137.83',
      promMC: '52.7043',
      promLM: '60.9826',
      promGD: '17.5043',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T15:16:00Z',
      LoteID: 12534802,
      CodigoLote: 963,
      totalAnimalesLote: 49,
      canalesEvaluadas: 49,
      pesotTotalLote: 56915,
      promPCC: '116.153',
      promMC: '51.0694',
      promLM: '66.4163',
      promGD: '21.0204',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-25T09:19:00Z',
      LoteID: 12534780,
      CodigoLote: 944,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 60182.6,
      promPCC: '115.329',
      promMC: '54.1596',
      promLM: '68.1462',
      promGD: '16.7769',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-26T05:17:00Z',
      LoteID: 12534825,
      CodigoLote: 986,
      totalAnimalesLote: 52,
      canalesEvaluadas: 52,
      pesotTotalLote: 57857,
      promPCC: '111.263',
      promMC: '53.8846',
      promLM: '64.7538',
      promGD: '16.5077',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-26T06:55:00Z',
      LoteID: 12534827,
      CodigoLote: 988,
      totalAnimalesLote: 50,
      canalesEvaluadas: 49,
      pesotTotalLote: 56704.8,
      promPCC: '115.565',
      promMC: '52.7367',
      promLM: '68.0408',
      promGD: '18.8735',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-26T07:04:00Z',
      LoteID: 12534828,
      CodigoLote: 989,
      totalAnimalesLote: 8,
      canalesEvaluadas: 8,
      pesotTotalLote: 9939,
      promPCC: '124.237',
      promMC: '53.075',
      promLM: '63.7',
      promGD: '17.5',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-26T07:08:00Z',
      LoteID: 12534830,
      CodigoLote: 990,
      totalAnimalesLote: 44,
      canalesEvaluadas: 44,
      pesotTotalLote: 53124,
      promPCC: '120.736',
      promMC: '53.0114',
      promLM: '65.7909',
      promGD: '18.0091',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-27T06:09:00Z',
      LoteID: 12534834,
      CodigoLote: 994,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 66589.6,
      promPCC: '127.804',
      promMC: '53.4308',
      promLM: '67.3154',
      promGD: '17.7',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-27T06:57:00Z',
      LoteID: 12534836,
      CodigoLote: 996,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 56971.4,
      promPCC: '111.349',
      promMC: '54.2882',
      promLM: '65.3569',
      promGD: '16.0392',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-27T07:40:00Z',
      LoteID: 12534840,
      CodigoLote: 1000,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 56294,
      promPCC: '110.38',
      promMC: '53.1863',
      promLM: '66.0863',
      promGD: '17.8196',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-27T08:29:00Z',
      LoteID: 12534843,
      CodigoLote: 1003,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 61945,
      promPCC: '123.89',
      promMC: '53.128',
      promLM: '69.504',
      promGD: '18.592',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-27T08:51:00Z',
      LoteID: 12534845,
      CodigoLote: 1005,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 54254.8,
      promPCC: '103.937',
      promMC: '53.2846',
      promLM: '63.3308',
      promGD: '17.1231',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-27T09:22:00Z',
      LoteID: 12534847,
      CodigoLote: 1007,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 63950.1,
      promPCC: '118.193',
      promMC: '50.1148',
      promLM: '65.3037',
      promGD: '22.2',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-27T10:13:00Z',
      LoteID: 12534850,
      CodigoLote: 1010,
      totalAnimalesLote: 56,
      canalesEvaluadas: 48,
      pesotTotalLote: 56500.6,
      promPCC: '116.402',
      promMC: '54.1396',
      promLM: '66.4',
      promGD: '16.4583',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-27T11:47:00Z',
      LoteID: 12534856,
      CodigoLote: 1015,
      totalAnimalesLote: 47,
      canalesEvaluadas: 46,
      pesotTotalLote: 53134,
      promPCC: '115.287',
      promMC: '53.6261',
      promLM: '67.7391',
      promGD: '17.487',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-27T12:17:00Z',
      LoteID: 12534858,
      CodigoLote: 1017,
      totalAnimalesLote: 48,
      canalesEvaluadas: 48,
      pesotTotalLote: 53771,
      promPCC: '112.023',
      promMC: '54.2917',
      promLM: '65.9083',
      promGD: '16.1417',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-27T14:18:00Z',
      LoteID: 12534859,
      CodigoLote: 1018,
      totalAnimalesLote: 18,
      canalesEvaluadas: 0,
      pesotTotalLote: 33468.6,
      promPCC: '169.033',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '77.9754',
    },
    {
      fechaLote: '2021-06-27T17:50:00Z',
      LoteID: 12534866,
      CodigoLote: 1024,
      totalAnimalesLote: 15,
      canalesEvaluadas: 0,
      pesotTotalLote: 28443.8,
      promPCC: '172.387',
      promMC: '0',
      promLM: '0',
      promGD: '0',
      porcRendimientoLote: '79.5631',
    },
    {
      fechaLote: '2021-06-28T05:26:00Z',
      LoteID: 12534878,
      CodigoLote: 1035,
      totalAnimalesLote: 53,
      canalesEvaluadas: 49,
      pesotTotalLote: 57315.6,
      promPCC: '113.944',
      promMC: '53.149',
      promLM: '63.8204',
      promGD: '17.4367',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T05:50:00Z',
      LoteID: 12534880,
      CodigoLote: 1037,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 66791,
      promPCC: '119.27',
      promMC: '53.9696',
      promLM: '68.2929',
      promGD: '17.0857',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T06:18:00Z',
      LoteID: 12534883,
      CodigoLote: 1040,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 64561.5,
      promPCC: '119.311',
      promMC: '53.15',
      promLM: '66.2074',
      promGD: '17.8889',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T06:56:00Z',
      LoteID: 12534887,
      CodigoLote: 1044,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 62051,
      promPCC: '121.669',
      promMC: '51.7412',
      promLM: '68.2588',
      promGD: '20.3922',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T07:09:00Z',
      LoteID: 12534888,
      CodigoLote: 1045,
      totalAnimalesLote: 51,
      canalesEvaluadas: 50,
      pesotTotalLote: 6404.6,
      promPCC: '125.9',
      promMC: '53.956',
      promLM: '65.528',
      promGD: '16.568',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T07:46:00Z',
      LoteID: 12534892,
      CodigoLote: 1048,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 65801,
      promPCC: '121.854',
      promMC: '54.1019',
      promLM: '60.7926',
      promGD: '15.4',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T08:04:00Z',
      LoteID: 12534894,
      CodigoLote: 1050,
      totalAnimalesLote: 51,
      canalesEvaluadas: 51,
      pesotTotalLote: 54526,
      promPCC: '106.914',
      promMC: '53.8216',
      promLM: '66.6039',
      promGD: '16.9804',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T08:20:00Z',
      LoteID: 12534895,
      CodigoLote: 1051,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 65715,
      promPCC: '119.482',
      promMC: '52.9309',
      promLM: '68.5018',
      promGD: '18.6764',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T08:47:00Z',
      LoteID: 12534898,
      CodigoLote: 1053,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 66070.6,
      promPCC: '122.178',
      promMC: '49.4296',
      promLM: '67.0889',
      promGD: '23.5778',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T09:04:00Z',
      LoteID: 12534899,
      CodigoLote: 1054,
      totalAnimalesLote: 54,
      canalesEvaluadas: 54,
      pesotTotalLote: 63545,
      promPCC: '117.676',
      promMC: '54.9204',
      promLM: '67.0815',
      promGD: '15.4444',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T09:56:00Z',
      LoteID: 12534901,
      CodigoLote: 1056,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 64891,
      promPCC: '117.984',
      promMC: '53.1927',
      promLM: '64.0291',
      promGD: '17.3891',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T10:15:00Z',
      LoteID: 12534902,
      CodigoLote: 1057,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 62728,
      promPCC: '114.051',
      promMC: '54.9545',
      promLM: '65.6945',
      promGD: '15.12',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T11:30:00Z',
      LoteID: 12534904,
      CodigoLote: 1059,
      totalAnimalesLote: 40,
      canalesEvaluadas: 37,
      pesotTotalLote: 38569.3,
      promPCC: '103.549',
      promMC: '53.5676',
      promLM: '61.6541',
      promGD: '16.3568',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T11:39:00Z',
      LoteID: 12534905,
      CodigoLote: 1060,
      totalAnimalesLote: 15,
      canalesEvaluadas: 13,
      pesotTotalLote: 16877.3,
      promPCC: '127.892',
      promMC: '53.7846',
      promLM: '64.0615',
      promGD: '16.5231',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-28T15:35:00Z',
      LoteID: 12534919,
      CodigoLote: 1073,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 65387,
      promPCC: '116.762',
      promMC: '54.4429',
      promLM: '62.3286',
      promGD: '15.1929',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T05:05:00Z',
      LoteID: 12534949,
      CodigoLote: 1102,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 67332.3,
      promPCC: '122.202',
      promMC: '53.1382',
      promLM: '66.8',
      promGD: '18.0291',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T05:32:00Z',
      LoteID: 12534950,
      CodigoLote: 1103,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 57351.8,
      promPCC: '109.927',
      promMC: '53.8549',
      promLM: '66.2667',
      promGD: '16.8549',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T06:16:00Z',
      LoteID: 12534951,
      CodigoLote: 1104,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 57591,
      promPCC: '115.182',
      promMC: '53.306',
      promLM: '62.512',
      promGD: '16.92',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T06:24:00Z',
      LoteID: 12534952,
      CodigoLote: 1105,
      totalAnimalesLote: 55,
      canalesEvaluadas: 52,
      pesotTotalLote: 57180.2,
      promPCC: '109.45',
      promMC: '52.5692',
      promLM: '66.0077',
      promGD: '18.7077',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T06:58:00Z',
      LoteID: 12534953,
      CodigoLote: 1106,
      totalAnimalesLote: 54,
      canalesEvaluadas: 53,
      pesotTotalLote: 62634.1,
      promPCC: '117.991',
      promMC: '55.0094',
      promLM: '63.7208',
      promGD: '14.634',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T07:52:00Z',
      LoteID: 12534956,
      CodigoLote: 1109,
      totalAnimalesLote: 52,
      canalesEvaluadas: 50,
      pesotTotalLote: 61166.7,
      promPCC: '119.527',
      promMC: '51.77',
      promLM: '64.072',
      promGD: '19.52',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T08:25:00Z',
      LoteID: 12534958,
      CodigoLote: 1110,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 60527,
      promPCC: '110.049',
      promMC: '52.22',
      promLM: '68.0436',
      promGD: '19.6436',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T08:59:00Z',
      LoteID: 12534961,
      CodigoLote: 1113,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 5943,
      promPCC: '118.86',
      promMC: '54.366',
      promLM: '65.032',
      promGD: '15.848',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T09:33:00Z',
      LoteID: 12534963,
      CodigoLote: 1115,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 7183.7,
      promPCC: '130.792',
      promMC: '52.9075',
      promLM: '68.1434',
      promGD: '18.6415',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T09:56:00Z',
      LoteID: 12534965,
      CodigoLote: 1117,
      totalAnimalesLote: 48,
      canalesEvaluadas: 48,
      pesotTotalLote: 57215,
      promPCC: '119.198',
      promMC: '51.6229',
      promLM: '67.7167',
      promGD: '20.4583',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T10:08:00Z',
      LoteID: 12534966,
      CodigoLote: 1118,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 6484.7,
      promPCC: '117.585',
      promMC: '54.0038',
      promLM: '60.3769',
      promGD: '15.4538',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T10:37:00Z',
      LoteID: 12534969,
      CodigoLote: 1121,
      totalAnimalesLote: 54,
      canalesEvaluadas: 52,
      pesotTotalLote: 59224.3,
      promPCC: '111.36',
      promMC: '55.4346',
      promLM: '65.8077',
      promGD: '14.4154',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T10:53:00Z',
      LoteID: 12534970,
      CodigoLote: 1122,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 66903,
      promPCC: '121.642',
      promMC: '49.9109',
      promLM: '65.4255',
      promGD: '22.5455',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T11:07:00Z',
      LoteID: 12534971,
      CodigoLote: 1123,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 70398.6,
      promPCC: '127.776',
      promMC: '51.9333',
      promLM: '67.763',
      promGD: '20.0074',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T11:32:00Z',
      LoteID: 12534973,
      CodigoLote: 1124,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 55955,
      promPCC: '111.91',
      promMC: '48.262',
      promLM: '60.792',
      promGD: '24.064',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T12:00:00Z',
      LoteID: 12534974,
      CodigoLote: 1125,
      totalAnimalesLote: 56,
      canalesEvaluadas: 55,
      pesotTotalLote: 65696.9,
      promPCC: '119.229',
      promMC: '54.9',
      promLM: '64.5964',
      promGD: '14.9745',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T12:12:00Z',
      LoteID: 12534975,
      CodigoLote: 1126,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 49337,
      promPCC: '98.674',
      promMC: '48.918',
      promLM: '58.344',
      promGD: '22.592',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T14:31:00Z',
      LoteID: 12534980,
      CodigoLote: 1131,
      totalAnimalesLote: 44,
      canalesEvaluadas: 35,
      pesotTotalLote: 46466.2,
      promPCC: '111.532',
      promMC: '55.1229',
      promLM: '67.8171',
      promGD: '15.28',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-29T14:33:00Z',
      LoteID: 12534981,
      CodigoLote: 1132,
      totalAnimalesLote: 10,
      canalesEvaluadas: 8,
      pesotTotalLote: 11283,
      promPCC: '122.744',
      promMC: '53.9',
      promLM: '62.9',
      promGD: '16.1',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T04:54:00Z',
      LoteID: 12535018,
      CodigoLote: 1165,
      totalAnimalesLote: 53,
      canalesEvaluadas: 51,
      pesotTotalLote: 6562.1,
      promPCC: '124.02',
      promMC: '52.8255',
      promLM: '64.6196',
      promGD: '18.0549',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T05:26:00Z',
      LoteID: 12535019,
      CodigoLote: 1166,
      totalAnimalesLote: 55,
      canalesEvaluadas: 53,
      pesotTotalLote: 61597.4,
      promPCC: '115.926',
      promMC: '50.0962',
      promLM: '58.6943',
      promGD: '20.9057',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T05:49:00Z',
      LoteID: 12535020,
      CodigoLote: 1167,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 68243,
      promPCC: '121.863',
      promMC: '54.0518',
      promLM: '64.6857',
      promGD: '16.25',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T06:25:00Z',
      LoteID: 12535024,
      CodigoLote: 1171,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 59797,
      promPCC: '119.594',
      promMC: '54.18',
      promLM: '67.832',
      promGD: '16.688',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T07:39:00Z',
      LoteID: 12535026,
      CodigoLote: 1173,
      totalAnimalesLote: 51,
      canalesEvaluadas: 50,
      pesotTotalLote: 58738.4,
      promPCC: '117.248',
      promMC: '54.452',
      promLM: '63.864',
      promGD: '15.496',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T08:06:00Z',
      LoteID: 12535029,
      CodigoLote: 1176,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 6795,
      promPCC: '121.339',
      promMC: '53.7',
      promLM: '66.0071',
      promGD: '17.0429',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T08:29:00Z',
      LoteID: 12535031,
      CodigoLote: 1177,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 64743.1,
      promPCC: '119.719',
      promMC: '50.5222',
      promLM: '65.837',
      promGD: '21.7185',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T09:46:00Z',
      LoteID: 12535032,
      CodigoLote: 1178,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 74736.2,
      promPCC: '138.183',
      promMC: '52.3833',
      promLM: '64.2296',
      promGD: '18.637',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T11:42:00Z',
      LoteID: 12535037,
      CodigoLote: 1182,
      totalAnimalesLote: 22,
      canalesEvaluadas: 22,
      pesotTotalLote: 20256,
      promPCC: '92.0727',
      promMC: '50.2682',
      promLM: '56.6727',
      promGD: '20.2545',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T11:49:00Z',
      LoteID: 12535038,
      CodigoLote: 1183,
      totalAnimalesLote: 28,
      canalesEvaluadas: 28,
      pesotTotalLote: 25974,
      promPCC: '92.7643',
      promMC: '50.8071',
      promLM: '57.1',
      promGD: '19.5429',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-06-30T13:09:00Z',
      LoteID: 12535041,
      CodigoLote: 1186,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 63408,
      promPCC: '115.287',
      promMC: '53.4873',
      promLM: '64.2109',
      promGD: '16.9891',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T04:42:00Z',
      LoteID: 12555824,
      CodigoLote: 1914,
      totalAnimalesLote: 49,
      canalesEvaluadas: 48,
      pesotTotalLote: 62904.3,
      promPCC: '130.777',
      promMC: '56.3146',
      promLM: '81.4583',
      promGD: '16.25',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T05:19:00Z',
      LoteID: 12555826,
      CodigoLote: 1916,
      totalAnimalesLote: 51,
      canalesEvaluadas: 49,
      pesotTotalLote: 64757.7,
      promPCC: '131.602',
      promMC: '55.3122',
      promLM: '80.6286',
      promGD: '17.5673',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T05:43:00Z',
      LoteID: 12555827,
      CodigoLote: 1917,
      totalAnimalesLote: 56,
      canalesEvaluadas: 56,
      pesotTotalLote: 68952,
      promPCC: '123.129',
      promMC: '55.2196',
      promLM: '75.2214',
      promGD: '16.6214',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T07:05:00Z',
      LoteID: 12555830,
      CodigoLote: 1920,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 54498.5,
      promPCC: '100.804',
      promMC: '56.7963',
      promLM: '70.8963',
      promGD: '13.4',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T08:15:00Z',
      LoteID: 12555833,
      CodigoLote: 1923,
      totalAnimalesLote: 50,
      canalesEvaluadas: 50,
      pesotTotalLote: 62004,
      promPCC: '124.008',
      promMC: '55.446',
      promLM: '76.792',
      promGD: '16.6',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T08:59:00Z',
      LoteID: 12555837,
      CodigoLote: 1926,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 72548,
      promPCC: '131.905',
      promMC: '53.6364',
      promLM: '76.2473',
      promGD: '19.1782',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T09:24:00Z',
      LoteID: 12555838,
      CodigoLote: 1927,
      totalAnimalesLote: 50,
      canalesEvaluadas: 48,
      pesotTotalLote: 64262,
      promPCC: '127.954',
      promMC: '53.1083',
      promLM: '73.175',
      promGD: '19.35',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T09:52:00Z',
      LoteID: 12555840,
      CodigoLote: 1929,
      totalAnimalesLote: 50,
      canalesEvaluadas: 49,
      pesotTotalLote: 53891.2,
      promPCC: '109.759',
      promMC: '56.6184',
      promLM: '72.3837',
      promGD: '13.9918',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T10:31:00Z',
      LoteID: 12555842,
      CodigoLote: 1930,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 68923.4,
      promPCC: '127.461',
      promMC: '55.1444',
      promLM: '74.7778',
      promGD: '16.6519',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T10:50:00Z',
      LoteID: 12555848,
      CodigoLote: 1931,
      totalAnimalesLote: 55,
      canalesEvaluadas: 55,
      pesotTotalLote: 66046,
      promPCC: '120.084',
      promMC: '55.74',
      promLM: '75.8036',
      promGD: '15.9709',
      porcRendimientoLote: '0',
    },
    {
      fechaLote: '2021-07-16T11:48:00Z',
      LoteID: 12555851,
      CodigoLote: 1934,
      totalAnimalesLote: 55,
      canalesEvaluadas: 54,
      pesotTotalLote: 64717.3,
      promPCC: '119.659',
      promMC: '53.9333',
      promLM: '75.8815',
      promGD: '18.6741',
      porcRendimientoLote: '0',
    },
  ],
};

const dataPrepare = (array: any) => {
  const bgs = [
    '#ffc676',
    '#f1ae6c',
    '#e29662',
    '#d47d57',
    '#be5948',
    '#b0413e',
  ];
  let cont = -1;
  let sumaCantidad = 0;
  let sumaPorcentaje = 0;
  const dataR = [];

  array.map((item: any) => {
    if (item.Porcentaje < 1) {
      sumaCantidad += item.Cantidad;
      sumaPorcentaje += item.Porcentaje;
    }
  });

  decomisos.DecomisosPlanillaMagro.detalleDecomisosPartePlanilaMagros.map(
    (item) => {
      if (item.Porcentaje > 1) {
        cont++;
        dataR.push({
          label: item.parte,
          data: item.Porcentaje,
          cantidad: item.Cantidad,
          bg: bgs[cont],
        });
      }
    }
  );

  dataR.push({
    label: 'Menos del 1%',
    data: sumaPorcentaje,
    cantidad: sumaCantidad,
    bg: '#afafaf',
  });

  return dataR;
};

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
  graph?: string;
  range?: null | number | string;
}

export const MyDocRangeMagroLot = ({ qr, uid, graph, range }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        range={range}
        reportImage={magroLogo}
        number="023456789"
        title="Reporte Técnico Evaluación de Magro"
        code="EM-001"
      />

      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ width: '100%' }}>
        <HorizontalTableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={agentes?.agentesRelacionadosConElNegocio?.PlantaBeneficio}
        />
        <View
          style={{
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: '#767676',
            marginTop: '10px',
          }}
        ></View>
        <HorizontalTableInfo
          icon={marketerIcon}
          title="Comercializador"
          widthTitle="20%"
          widthItem="80%"
          items={
            agentes?.agentesRelacionadosConElNegocio
              ?.ComercializadorTiqueteBascula
          }
        />
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle title="Resumen del Proceso" widthContainer="100%" />
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value={resumenProceso[0].canalesEvaluadas}
            info="Canales Evaluadas"
            position="right"
            widthValue={45}
            widthInfo="60px"
            fontSizeValue={12}
            backgroundColor="#d47d57"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value={resumenProceso[0].lotesEvaluados}
            info="Lotes Evaluados"
            position="right"
            widthValue={45}
            widthInfo="60px"
            fontSizeValue={12}
            backgroundColor="#e29662"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value={resumenProceso[0].lotesEvaluados}
            info="Productores"
            position="right"
            widthValue={45}
            widthInfo="60px"
            fontSizeValue={12}
            backgroundColor="#f1ae6c"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value={resumenProceso[0].granjasDiferentes}
            info="Granjas Diferentes"
            position="right"
            widthValue={45}
            widthInfo="60px"
            fontSizeValue={12}
            backgroundColor="#ffc676"
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '35px',
        }}
      >
        <View style={{ width: '48.3%' }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: '#ededed',
              height: 60,
              marginTop: '10px',
              alignItems: 'center',
            }}
          >
            <ItemTotalCircle
              value={`${resumenProceso[0].promedioPCC.toFixed(2)} Kg`}
              info="Promedio PCC"
              position="bottom"
              backgroundColor="#d47d57"
              widthCircle={35}
              widthInfo={60}
              fontSizeValue={8.5}
            />
            <ItemTotalCircle
              value={`${resumenProceso[0].promedioMC.toFixed(2)}%`}
              info="Promedio MC%"
              position="bottom"
              backgroundColor="#e29662"
              widthCircle={35}
              widthInfo={60}
              fontSizeValue={8.5}
            />
            <ItemTotalCircle
              value={`${resumenProceso[0].promedioLM.toFixed(2)} m.m`}
              info="Promedio LM"
              position="bottom"
              backgroundColor="#f1ae6c"
              widthCircle={35}
              widthInfo={60}
              fontSizeValue={8.5}
            />
            <ItemTotalCircle
              value={`${resumenProceso[0].promedioGD.toFixed(2)} m.m`}
              info="Promedio GD"
              position="bottom"
              backgroundColor="#ffc676"
              widthCircle={35}
              widthInfo={60}
              fontSizeValue={8.5}
            />
          </View>

          <View style={{ marginTop: '10px' }}>
            <Subtitle
              title="Resumen Recepción Animales"
              widthContainer="100%"
            />
          </View>

          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <View style={{ marginTop: '10px' }}>
                <ItemTotal
                  value={
                    animalesR.resumenRecepcionAnimales.animalesRemisionados
                  }
                  info="Animales Remisionados"
                  position="right"
                  widthValue={45}
                  widthInfo="50px"
                  fontSizeValue={12}
                  backgroundColor="#d47d57"
                />
              </View>
              <View style={{ marginTop: '10px' }}>
                <ItemTotal
                  value={animalesR.resumenRecepcionAnimales.remisionesRecibidas}
                  info="Remisiones Recibidas"
                  position="right"
                  widthValue={45}
                  widthInfo="50px"
                  fontSizeValue={12}
                  backgroundColor="#e29662"
                />
              </View>
            </View>
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <View style={{ marginTop: '10px' }}>
                <ItemTotal
                  value={animalesR.resumenRecepcionAnimales.animalesRecibidos}
                  info="Animales Recibidos"
                  position="right"
                  widthValue={45}
                  widthInfo="50px"
                  fontSizeValue={12}
                  backgroundColor="#f1ae6c"
                />
              </View>
              <View style={{ marginTop: '10px' }}>
                <ItemTotal
                  value={animalesR.resumenRecepcionAnimales.camionesRecibidos}
                  info="Camiones Recibidos"
                  position="right"
                  widthValue={45}
                  widthInfo="50px"
                  fontSizeValue={12}
                  backgroundColor="#ffc676"
                />
              </View>
            </View>
          </View>
          <View style={{ marginTop: '10px' }}>
            <Subtitle
              title="Resumen Recepción Animales"
              widthContainer="100%"
            />
            <View
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '5px',
              }}
            >
              <View
                style={{
                  height: '60px',
                  justifyContent: 'center',
                }}
              >
                <ItemTotalLeft
                  value={
                    animalesR.resumenRecepcionAnimales.totalAnimalesEnPiePesados
                  }
                  info="Total Animales en Pie Pesados"
                  backgroundColor="#373737"
                  heightValue={15}
                  fontSizeValue={8}
                  widthValue={50}
                  widthInfo="130px"
                  alignInfo="left"
                />

                <ItemTotalLeft
                  value={`${animalesR.resumenRecepcionAnimales.pesoTotalAnimalesEnPie.toFixed(
                    2
                  )} Kg`}
                  info="Peso Total Animales en Pie (Kg)"
                  backgroundColor="#373737"
                  heightValue={15}
                  fontSizeValue={8}
                  widthValue={50}
                  widthInfo="130px"
                  alignInfo="left"
                />

                <ItemTotalLeft
                  value={`${animalesR.resumenRecepcionAnimales.pesoPromediolAnimalesEnPie.toFixed(
                    2
                  )} Kg`}
                  info="Peso Promedio del Lote (Kg)"
                  backgroundColor="#373737"
                  heightValue={15}
                  fontSizeValue={8}
                  widthValue={50}
                  widthInfo="130px"
                  alignInfo="left"
                />
              </View>
            </View>
          </View>
          <Subtitle
            title="Resumen Clasificación S.E.U.R.O.P."
            widthContainer="100%"
          />
          <View
            style={{
              width: '48.3%',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <View style={styles.tableMagro}>
              <View
                style={[
                  styles.tableRow,
                  {
                    marginBottom: '5px',
                    marginTop: '5px',
                    alignItems: 'center',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}></Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    % Magro
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>No.</Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>%</Text>
                </View>
              </View>

              {/* {itemS.map((item: any, index: number) => ( */}
              <View
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: '#F2F2F2',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                  <Text
                    style={[
                      styles.tableCellPost,
                      { fontSize: 8, fontWeight: 'bold' },
                    ]}
                  >
                    S
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {'%M > 60%'}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                  <Text
                    style={
                      (styles.tableCellPost, { fontSize: 7, marginTop: 5 })
                    }
                  >
                    {clasificacion.clasificacionSEUROP.cantS}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {clasificacion.clasificacionSEUROP.porcS.toFixed(2)}
                  </Text>
                </View>
              </View>

              <View
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: '#FFF',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                  <Text
                    style={[
                      styles.tableCellPost,
                      { fontSize: 8, fontWeight: 'bold' },
                    ]}
                  >
                    E
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {'%55 < %M > 60%'}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                  <Text
                    style={
                      (styles.tableCellPost, { fontSize: 7, marginTop: 5 })
                    }
                  >
                    {clasificacion.clasificacionSEUROP.cantE}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {clasificacion.clasificacionSEUROP.porcE.toFixed(2)}
                  </Text>
                </View>
              </View>

              <View
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: '#F2F2F2',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                  <Text
                    style={[
                      styles.tableCellPost,
                      { fontSize: 8, fontWeight: 'bold' },
                    ]}
                  >
                    U
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {'%50 < %M > 55%'}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                  <Text
                    style={
                      (styles.tableCellPost, { fontSize: 7, marginTop: 5 })
                    }
                  >
                    {clasificacion.clasificacionSEUROP.cantU}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {clasificacion.clasificacionSEUROP.porcU.toFixed(2)}
                  </Text>
                </View>
              </View>

              <View
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: '#FFF',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                  <Text
                    style={[
                      styles.tableCellPost,
                      { fontSize: 8, fontWeight: 'bold' },
                    ]}
                  >
                    R
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {'%45 < %M > 50%'}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                  <Text
                    style={
                      (styles.tableCellPost, { fontSize: 7, marginTop: 5 })
                    }
                  >
                    {clasificacion.clasificacionSEUROP.cantR}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {clasificacion.clasificacionSEUROP.porcR.toFixed(2)}
                  </Text>
                </View>
              </View>

              <View
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: '#F2F2F2',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                  <Text
                    style={[
                      styles.tableCellPost,
                      { fontSize: 8, fontWeight: 'bold' },
                    ]}
                  >
                    O
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {'%40 < %M > 45%'}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                  <Text
                    style={
                      (styles.tableCellPost, { fontSize: 7, marginTop: 5 })
                    }
                  >
                    {clasificacion.clasificacionSEUROP.cantO}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {clasificacion.clasificacionSEUROP.porcO.toFixed(2)}
                  </Text>
                </View>
              </View>

              <View
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: '#FFF',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                  <Text
                    style={[
                      styles.tableCellPost,
                      { fontSize: 8, fontWeight: 'bold' },
                    ]}
                  >
                    P
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {'%M < 40%'}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                  <Text
                    style={
                      (styles.tableCellPost, { fontSize: 7, marginTop: 5 })
                    }
                  >
                    {clasificacion.clasificacionSEUROP.cantP}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                  <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                    {clasificacion.clasificacionSEUROP.porcP.toFixed(2)}
                  </Text>
                </View>
              </View>
              {/* ))} */}
            </View>
          </View>
          <ItemTotalLeft
            value={
              animalesR.resumenRecepcionAnimales
                .totalCanalesConGrasaDorsalMayora185
            }
            info="Total Canales con Grasa Dorsal Mayor a 18,5"
            backgroundColor="#373737"
            heightValue={12}
            fontSizeValue={8}
            widthValue={50}
            widthInfo="150px"
            alignInfo="left"
          />
          <ItemTotalLeft
            value={`${animalesR.resumenRecepcionAnimales.PorcentajeCanalesConGrasaDorsalMayora185.toFixed(
              2
            )}%`}
            info="Porcentaje Canales Grasa Dorsal Mayor a 18,5"
            backgroundColor="#373737"
            heightValue={12}
            fontSizeValue={8}
            widthValue={50}
            widthInfo="150px"
            alignInfo="left"
          />
        </View>
        <View style={{ width: '48.3%' }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: '#ededed',
              height: 60,
              marginTop: '10px',
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <View style={{ width: '38.3%' }}>
              <NumberTotal
                value={`${resumenProceso[0].rendimientoPieCanal.toFixed(2)}%`}
                info="Rendimiento Pie / Canal"
                position="bottom"
                widthInfo="50px"
                widthValue="80px"
                justifyValue="center"
                justifyInfo="center"
                colorValue="#be5948"
                fontSizeValue={10}
              />
            </View>
            <View style={{ width: '58.3%' }}>
              <NumberTotal
                value={`${resumenProceso[0].pesototalCanalesCalientes.toFixed(
                  2
                )} Kg`}
                info="Peso Total Canales Calientes"
                position="right"
                widthInfo="90px"
                widthValue="80px"
                justifyValue="left"
                justifyInfo="left"
                colorValue="#373737"
                fontSizeValue={10}
              />
              <NumberTotal
                value={`${resumenProceso[0].totalCarneMagra.toFixed(2)} Kg`}
                info="Total Carne Magra"
                position="right"
                widthInfo="80px"
                widthValue="80px"
                justifyValue="left"
                justifyInfo="left"
                colorValue="#373737"
                fontSizeValue={10}
              />
            </View>
          </View>
          <Subtitle
            title="Reporte de Inspección Veterinaria"
            widthContainer="100%"
          />
          <View style={{ marginTop: '10px' }}>
            <Subtitle title="Ante - Mortem" widthContainer="100%" />
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '48.3%' }}>
              <ListData
                widthContainer="100%"
                widthtTitle="80%"
                widthValue="20%"
                items={{
                  Agitados: anteMortemData.anteMortem.agitados,
                  Caídos: anteMortemData.anteMortem.caidos,
                  'Muertos Transporte':
                    anteMortemData.anteMortem.muertosTransporte,
                  'Muertos Corrales': anteMortemData.anteMortem.muertosCorrales,
                  Observación: anteMortemData.anteMortem.observacion,
                }}
              />
            </View>
            <View style={{ width: '48.3%' }}>
              <ListData
                widthContainer="100%"
                widthtTitle="80%"
                widthValue="20%"
                items={{
                  'Agitados Corrales':
                    anteMortemData.anteMortem.agitadosCorrales,
                  'Caídos Corrales': anteMortemData.anteMortem.caidosCorrales,
                  'Muertos Desembarque':
                    anteMortemData.anteMortem.muertosDesembarque,
                  'Muertos en Observacióne':
                    anteMortemData.anteMortem.muertosObservacion,
                  'Animales Decomisados':
                    anteMortemData.anteMortem.animalesDecomisados,
                }}
              />
            </View>
          </View>

          <View style={{ marginTop: '15px' }}>
            <Subtitle
              title="Resumen Decomisos del Lote"
              widthContainer="100%"
            />
          </View>

          <View
            style={{
              height: '225px',
              marginTop: '5px',
              padding: 5,
            }}
          >
            <View>
              <View style={{ textAlign: 'center' }}>
                <Text style={{ fontWeight: 'bold' }}>Post - Mortem</Text>
                <Text>Línea y Emergencia</Text>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <NumberTotal
                  value={`${decomisos.DecomisosPlanillaMagro.porcentajeCanalesDecomisadas.toFixed(
                    2
                  )}%`}
                  info="Canales Decomisadas"
                  position="bottom"
                  widthValue={60}
                  widthInfo={100}
                />
                <View style={{ marginTop: '10px' }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: '5px',
                      marginTop: '5px',
                    }}
                  >
                    <View style={{ width: '45.3%' }}>
                      <ItemTotal
                        value={
                          decomisos.DecomisosPlanillaMagro
                            .totalCanalesCompletasDecomisadasSB
                        }
                        info="Canales Decomisadas Completas (línea)"
                        backgroundColor="#373737"
                        heightValue={15}
                        fontSizeValue={8}
                        widthValue={20}
                        widthInfo="85px"
                        position="left"
                        alignFontInfo="left"
                      />
                    </View>
                    <View style={{ width: '51.3%' }}>
                      <ItemTotal
                        value={
                          decomisos.DecomisosPlanillaMagro
                            .totalKgsCarneDecomisadaSB
                        }
                        info="Total (Kgs) Carne Decomisada (línea)"
                        backgroundColor="#373737"
                        heightValue={15}
                        fontSizeValue={8}
                        widthValue={20}
                        widthInfo="100px"
                        position="left"
                        alignFontInfo="left"
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: '5px',
                      marginTop: '5px',
                    }}
                  >
                    <View style={{ width: '45.3%' }}>
                      <ItemTotal
                        value={
                          decomisos.DecomisosPlanillaMagro
                            .totalCanalesCompletasDecomisadasSBE
                        }
                        info="Canales Decomisadas Completas (Emergencia)"
                        backgroundColor="#373737"
                        heightValue={15}
                        fontSizeValue={8}
                        widthValue={20}
                        widthInfo="85px"
                        position="left"
                        alignFontInfo="left"
                      />
                    </View>
                    <View style={{ width: '51.3%' }}>
                      <ItemTotal
                        value={
                          decomisos.DecomisosPlanillaMagro
                            .totalKgsCarneDecomisadaSBE
                        }
                        info="Total (Kgs) Carne Decomisada (Emergencia)"
                        backgroundColor="#373737"
                        heightValue={15}
                        fontSizeValue={8}
                        widthValue={20}
                        widthInfo="100px"
                        position="left"
                        alignFontInfo="left"
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View
          style={{
            width: '56.3%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              height: '120px',
              width: '50%',
              marginTop:
                dataPrepare(
                  decomisos.DecomisosPlanillaMagro
                    .detalleDecomisosPartePlanilaMagros
                ).length < 5
                  ? 15
                  : 30,
              marginBottom: 5,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image src={graph} style={{ height: '150px', width: '150px' }} />
          </View>
          <View
            style={{
              width: '80%',
              marginLeft: 10,
            }}
          >
            <View style={{ width: '80%' }}>
              <Text style={{ fontWeight: 'bold' }}>Partes Decomisadas</Text>
            </View>
            <ChartListMagro
              data={
                decomisos.DecomisosPlanillaMagro
                  .detalleDecomisosPartePlanilaMagros
              }
            />
          </View>
        </View>
        <View style={{ width: '40.3%' }}>
          <View style={{ marginBottom: '5px' }}>
            <Text style={{ fontWeight: 'bold' }}>
              Partes Decomisadas - Menos del 1%
            </Text>
          </View>

          <TableDecomiso
            items={
              decomisos.DecomisosPlanillaMagro
                .detalleDecomisosPartePlanilaMagros
            }
          />
        </View>
      </View>

      <View style={{ marginTop: -10 }}>
        <Subtitle title="Detalle de Lotes Evaluados" widthContainer="100%" />
      </View>

      <View style={{ width: '100%' }}>
        <View style={[styles.tableMagro, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '10px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Fecha</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 45.5, width: 45.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote IP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Animales</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canales</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Evaluadas
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Peso total
              </Text>
              <Text style={[styles.tableCell, styles.tableHead]}>Kg</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Prom. PCC
              </Text>
              <Text style={[styles.tableCell, styles.tableHead]}>Kg</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Prom. MC</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>%</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Prom. LM</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>m.m.</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Prom. GD</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>m.m.</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 65.5, width: 65.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Rendimiento
              </Text>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote %</Text>
            </View>
          </View>
          {detalles.detalleLotesEvaluados.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {dayjs(item.fechaLote).format('DD/MM/YYYY')}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 45.5, width: 45.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.LoteID}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.totalAnimalesLote}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.canalesEvaluadas}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.pesotTotalLote}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.promPCC}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.promMC}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.promLM}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 55.5, width: 55.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.promGD}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 65.5, width: 65.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.porcRendimientoLote}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 55.5,
                width: 55.5,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 45.5, width: 45.5, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 55.5, width: 55.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              {totalesDetalle.totalesDetalleLoteEvaluado.totalAnimalesLotes}
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 55.5, width: 55.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              {
                totalesDetalle.totalesDetalleLoteEvaluado
                  .totalAnimalesEvaluadosLotes
              }
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 55.5, width: 55.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              {totalesDetalle.totalesDetalleLoteEvaluado.totalPesoLotes.toFixed(
                2
              )}
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 55.5, width: 55.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              {totalesDetalle.totalesDetalleLoteEvaluado.promPCCLotes.toFixed(
                2
              )}
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 55.5, width: 55.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              {totalesDetalle.totalesDetalleLoteEvaluado.promMCLotes.toFixed(2)}
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 55.5, width: 55.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              {totalesDetalle.totalesDetalleLoteEvaluado.promLMLotes.toFixed(2)}
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 55.5, width: 55.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              {totalesDetalle.totalesDetalleLoteEvaluado.promGDLotes.toFixed(2)}
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 65.5, width: 65.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              {totalesDetalle.totalesDetalleLoteEvaluado.RendimientoLoteLotes.toFixed(
                2
              )}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.conventions} wrap={false}>
        <View style={styles.backgroundMagro}>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <View style={{ width: '23.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PCC (Kg)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Peso Canal Caliente</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * GD (m.m)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Grasa Dorsal</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * MC (%)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Porcentaje de Magro Canal</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '23.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * MC (Kg)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Peso Magro Canal</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * LM (m.m)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Profundidad del Lomo</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (M)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Macho</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '26.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (H)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Hembra</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * Inst. Med.
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Instrumento de Medición</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroCE}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PSE
                  </Text>
                </View>
                <View style={styles.textMagroC}>
                  <Text>Pálido, Suave, Exudativo</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '23.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (*)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Si tiene PSE</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (.)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>No tiene indicio PSE</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (?)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Probable PSE</Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              marginLeft: '10px',
            }}
          >
            <View style={styles.textconventions}>
              <View style={styles.gmagroS}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * Clasificación Canal
                </Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * S
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Superior</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * E
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Excelente</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * U
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Muy Buena</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * R
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Menos Buena</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * O
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Aceptable</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * P
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Pobre</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.borderMagro}>
          <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
            Convenciones
          </Text>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screenFilterLotMagro" style={{ width: 500 }}>
      <QRCode
        size={500}
        value="Reporte por Lote Evaluación de Magro"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphFilterMagroLot = () => {
  return (
    <div id="graphfiltermagrolot" style={{ width: 500, marginTop: -120 }}>
      <Graphicpdfsf
        textTotalData="Partes Decomisadas"
        topTextTotalData={-445}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        numberTotal
        data={dataPrepare(
          decomisos.DecomisosPlanillaMagro.detalleDecomisosPartePlanilaMagros
        )}
      />
    </div>
  );
};
