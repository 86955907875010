const avatarImages = require.context('./../../../static/img/avatars', true);

function get(word: string) {
  try {
    const letter = word.substr(0, 1).toLowerCase()
    return avatarImages(`./${letter}.png`).default ?? undefined
  } catch(error) {
    console.error(error.toString())
    return null
  }
}

export default get