import { Document, Page, Image, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import whitevisorsLogo from 'static/img/reportsIcons/whitevisorsLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import HorizontalTableInfo from 'components/pdf-components/HorizontalTableInfo/index';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF/index';
import ChartList from 'components/pdf-components/ChartList/index';
import ItemTotal from 'components/pdf-components/ItemTotal';

import styles from './styles/lote';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const detalles = [
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
  {
    lote: '7441',
    totalCanales: 15,
    totalVisceras: 58,
    viscerasDecomisadas: 6,
    viscerasAprobadas: 52,
    productor: 'Nombre del Productor',
    observaciones: 'Observación',
  },
];

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
  graph?: string;
  graphTwo?: string;
  range?: null | number | string;
}

export const MyDocRangeVisceras = ({
  qr,
  uid,
  graph,
  graphTwo,
  range,
}: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        range={range}
        reportImage={whitevisorsLogo}
        number="023456789"
        title="Inspección Médico Veterinaria - Post Mortem Canales Visceras Blancas"
        code="AM-001"
      />

      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ width: '100%' }}>
        <HorizontalTableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            Nit: '34123412-2',
            Telefono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <View
          style={{
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: '#767676',
            marginTop: '10px',
          }}
        ></View>
        <HorizontalTableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle title="Resumen del Proceso" widthContainer="100%" />
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '5px',
        }}
      >
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="820"
            info="Total Visceras Blancas"
            position="right"
            widthValue={60}
            widthInfo="70px"
            fontSizeValue={12}
            backgroundColor="#be5948"
          />
        </View>
        <View
          style={{
            width: '28.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="34"
            info="Canales con Decomisos de Visceras Blancas"
            position="right"
            textAlign="left"
            backgroundColor="#d47d57"
            widthInfo="80px"
            widthCircle={30}
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="13"
            info="Lotes Diferentes"
            position="right"
            textAlign="left"
            backgroundColor="#e29662"
            widthInfo="55px"
            widthCircle={30}
          />
        </View>
        <View
          style={{
            width: '18.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="3"
            info="Productores Diferentes"
            position="right"
            textAlign="left"
            backgroundColor="#f1ae6c"
            widthInfo="55px"
            widthCircle={30}
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View style={{ width: '48.3%' }}>
          <Subtitle
            title="Porcentaje Decomisados y Aprobados"
            widthContainer="100%"
          />

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                height: '100px',
                marginTop: 10,
                marginBottom: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image src={graph} style={{ height: '150px', width: '150px' }} />
            </View>
            <View>
              <ChartList
                data={[
                  {
                    label: 'Decomisadas',
                    data: 246,
                    bg: '#FFC676',
                  },
                  {
                    label: 'Aprobadas',
                    data: 574,
                    bg: '#F1AE6C',
                  },
                ]}
              />
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: '#ededed',
              height: 45,
            }}
          >
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="56"
                info="Decomisados por Emergencia"
                position="right"
                textAlign="left"
                backgroundColor="#d47d57"
                widthInfo="55px"
                widthCircle={30}
              />
            </View>
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="190"
                info="Decomisados en la línea"
                position="right"
                textAlign="left"
                backgroundColor="#e29662"
                widthInfo="55px"
                widthCircle={30}
              />
            </View>
          </View>
        </View>

        <View
          style={{
            width: '48.3%',
          }}
        >
          <Subtitle title="Decomisos por Productor" widthContainer="100%" />

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <View
              style={{
                height: '100px',
                marginTop: 10,
                marginBottom: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                src={graphTwo}
                style={{ height: '150px', width: '150px' }}
              />
            </View>

            <View>
              <ChartList
                data={[
                  {
                    label: 'Nombre Productor',
                    data: 8,
                    bg: '#b0413e',
                  },
                  {
                    label: 'Nombre Productor',
                    data: 8,
                    bg: '#be5948',
                  },
                  {
                    label: 'Nombre Productor',
                    data: 10,
                    bg: '#d47d57',
                  },
                  {
                    label: 'Nombre Productor',
                    data: 34,
                    bg: '#e29662',
                  },
                  {
                    label: 'Nombre Productor',
                    data: 67,
                    bg: '#f1ae6c',
                  },
                  {
                    label: 'Nombre Productor',
                    data: 72,
                    bg: '#ffc676',
                  },
                ]}
              />
            </View>
          </View>
        </View>
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Detalle del Reporte Visceras Blancas"
          widthContainer="100%"
        />
      </View>

      <View style={{ marginTop: '10px' }}>
        <Text style={{ textAlign: 'center' }}>
          Los Registros marcados con un simbolo (*) al final, indica que{' '}
          <Text style={{ fontWeight: 'bold' }}>
            la cantidad de Vísceras Inspeccionadas no coincide con la cantidad
            total del lote
          </Text>
          . Esto se debe a que el lote no se ha inspeccionado en su totalidad o
          a que se registraron novedades de animales antes de ser enviados a la
          línea de sacrificio.
        </Text>
      </View>

      <View style={{ width: '100%', marginTop: '5px' }}>
        <View style={[styles.tableMagro, { marginBottom: 5 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '5px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote IP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Total Canales
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Total Visceras
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 98.2, width: 98.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Visceras Decomisadas
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 79.2, width: 79.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Visceras Aprobadas
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 98.2, width: 98.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Productor
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 98.2, width: 98.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Observaciones
              </Text>
            </View>
          </View>
          {detalles?.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  {item.lote}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  {item.totalCanales}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  {item.totalVisceras}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 98.2, width: 98.2 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  {item.viscerasDecomisadas}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 79.2, width: 79.2 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  {item.viscerasAprobadas}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 98.2, width: 98.2 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  {item.productor}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 98.2, width: 98.2 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  {item.observaciones}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={{ flexDirection: 'row', width: '100%', marginBottom: '10px' }}
        >
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 60.2,
                width: 60.2,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellVisceras,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 60.2, width: 60.2, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellVisceras,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              99
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 60.2, width: 60.2, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellVisceras,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              348
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 98.2, width: 98.2, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellVisceras,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              36
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 79.2, width: 79.2, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellVisceras,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              312
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 196.4, width: 196.4, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportFilter = () => {
  return (
    <div id="screenQrvisceras" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte Técnico Inspección Ante-Mortem"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphRangeVisceras = () => {
  return (
    <div id="graphrangevisceras" style={{ width: 500, marginTop: -20 }}>
      <Graphicpdfsf
        textTotalData="Visceras Blancas"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={45}
        startAngle={-90}
        endAngle={270}
        withoutTotalInfo
        data={[
          {
            label: 'Decomisadas',
            data: 246,
            bg: '#FFC676',
          },
          {
            label: 'Aprobadas',
            data: 574,
            bg: '#F1AE6C',
          },
        ]}
      />
    </div>
  );
};

export const GraphRangeViscerasTwo = () => {
  return (
    <div id="graphrangeviscerastwo" style={{ width: 500, marginTop: -20 }}>
      <Graphicpdfsf
        textTotalData="Visceras Decomisadas"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        startAngle={-90}
        endAngle={270}
        data={[
          {
            label: 'Nombre Productor',
            data: 8,
            bg: '#b0413e',
          },
          {
            label: 'Nombre Productor',
            data: 8,
            bg: '#be5948',
          },
          {
            label: 'Nombre Productor',
            data: 10,
            bg: '#d47d57',
          },
          {
            label: 'Nombre Productor',
            data: 34,
            bg: '#e29662',
          },
          {
            label: 'Nombre Productor',
            data: 67,
            bg: '#f1ae6c',
          },
          {
            label: 'Nombre Productor',
            data: 73,
            bg: '#ffc676',
          },
        ]}
      />
    </div>
  );
};
