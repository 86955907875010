import Invitations from './Invitations';
import Contacts from './Contacts';
import SharedReports from './SharedReports';
import Transport from './Transport';

import { NestedRoute } from 'lib/interfaces/routes';

const routes: Array<NestedRoute> = [
  {
    route: '/invitations',
    Node: Invitations,
    // exact: true
  },
  {
    route: '/contacts',
    Node: Contacts,
    // exact: true
  },
  {
    route: '/shared-reports',
    Node: SharedReports,
  },
  {
    route: '/transports',
    Node: Transport,
    // exact: true
  }
];

export default routes