import React, { useState } from "react";

import {
  Box,
  Button,
  Typography,
  Dialog,
  Stepper,
  Step,
  StepLabel,
  DialogTitle,
  StepIconProps,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

import useTour from "components/Tour";
import Invitations from "./Views/invitations";
import Subscription from "./Views/subscription";
import MarketerTypes from "./Views/marketerTypes";
import Destinations from "./Views/destinations";
import Farms from "./Views/farms";

import { UPDATE_MARKETER_TYPES } from "core/graphql/mutations/profile";

import welcome from "static/img/pigs/welcome.svg";

import { useMutation } from "@apollo/client";

import { Step as StepTour } from "react-joyride";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import clsx from "clsx";

import useStyles from "./styles";

const Welcome = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const userType = useSelector(
    (state: any) => state.user.selectedUserType.idUserType
  );
  const [openTour, setOpenTour] = useState(false);
  const producerSteps = [
    "Invitaciones",
    "Plan de Suscripción",
    "Registrar granjas",
  ];
  const marketerSteps = [
    "Invitaciones",
    "Plan de Suscripción",
    "Definir Tipo de Comercializador",
    "Registrar Puntos de venta",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [IdTypesSelected, setIdTypesSelected] = useState<any>([]);

  const [updateTypes, { loading: loadingUpdate }] = useMutation(
    UPDATE_MARKETER_TYPES,
    {
      context: { headers: { "x-auth-jwt": token } },
    }
  );

  const handleUpdateTypes = () => {
    updateTypes({
      variables: { IdsTipoComercializador: IdTypesSelected },
    })
      .then((data) => {
        if (data?.data?.putTipoComercializador?.data?.success === 1) {
          enqueueSnackbar("Datos Registrados correctamente.", {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
        } else {
          enqueueSnackbar(
            "Debe seleccionar almenos un tipo de comercializador",
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
            }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          "Ocurrió un error no se pudieron Actualizar los Datos.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          }
        );
      });
  };

  const handleNext = () => {
    if (
      activeStep ===
      (userType === 1 ? producerSteps : marketerSteps).length - 1
    ) {
      setOpenTour(false);
    } else if (
      activeStep <=
      (userType === 1 ? producerSteps : marketerSteps).length - 1
    ) {
      if (activeStep === 2 && userType === 2) {
        handleUpdateTypes();
        setActiveStep(activeStep + 1);
      }
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    activeStep > 0 ? setActiveStep(activeStep - 1) : null;
  };

  const IconsStepper = (props: StepIconProps) => {
    const classes = useStyles();
    const { active, completed } = props;

    const iconsMarketer: { [index: string]: React.ReactElement } = {
      1: <Box component="i" className="icon-envelope" fontSize="24px" />,
      2: <Box component="i" className="icon-bell-sync" fontSize="24px" />,
      3: <Box component="i" className="icon-comercializador" fontSize="24px" />,
      4: <Box component="i" className="icon-map-marker" fontSize="24px" />,
    };

    const iconsProducer: { [index: string]: React.ReactElement } = {
      1: <Box component="i" className="icon-envelope" fontSize="24px" />,
      2: <Box component="i" className="icon-bell-sync" fontSize="24px" />,
      3: <Box component="i" className="icon-granja" fontSize="24px" />,
    };

    return (
      <Box
        className={clsx(classes.rootStepper, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {(userType === 1 ? iconsProducer : iconsMarketer)[String(props.icon)]}
      </Box>
    );
  };

  const STEPS: StepTour[] = [
    {
      content: (
        <Typography variant="h6" color="secondary">
          Primer paso
        </Typography>
      ),
      placement: "center",
      target: "#title",
    },
    {
      content: <h2>Sedundo paso</h2>,
      placement: "auto" as const,
      disableBeacon: true,
      target: "#second",
    },
  ];

  const handleOpenTour = () => setOpenTour(true);

  const tour = useTour({ steps: STEPS });

  return (
    <>
      <Dialog open={true} fullScreen scroll="paper">
        <Box className={classes.containerWelcome}>
          <Box className={classes.contentWelcome}>
            <Typography variant="subtitle1" color="primary">
              TENEMOS NUEVAS CARACTERÍSTICAS PARA TI
            </Typography>
            <Typography variant="h4" className={classes.titleWelcome}>
              ¡Bienvenido a la nueva versión de
              <br />
              InfoPorcinos™!
            </Typography>
            <Typography color="secondary" className={classes.textWelcome}>
              Nos hemos renovado para ti, estas son algunas opciones que hemos
              actualizado y que deberás completar antes de comenzar con la
              experiencia dentro de InfoPorcinos™.
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={handleOpenTour}
            >
              Continuar
            </Button>
          </Box>
          <Box className={classes.pigContainer}>
            <img src={welcome} style={{ width: "30vw" }} />
          </Box>
        </Box>
      </Dialog>
      <Dialog open={openTour} fullScreen scroll="body">
        {tour}
        <DialogTitle>
          <Stepper activeStep={activeStep} alternativeLabel>
            {(userType === 1 ? producerSteps : marketerSteps).map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={IconsStepper}
                  className={classes.steps}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>
        <DialogContent className={classes.containerSteps}>
          <Box width="80%" marginTop={3} marginBottom={2}>
            {activeStep === 0 ? (
              <Invitations />
            ) : activeStep === 1 ? (
              <Subscription />
            ) : activeStep === 2 ? (
              userType === 1 ? (
                <Farms />
              ) : (
                <MarketerTypes>
                  {({ typesSelected }: any) => {
                    setIdTypesSelected(typesSelected);
                  }}
                </MarketerTypes>
              )
            ) : activeStep === 3 ? (
              <Destinations />
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          {activeStep > 0 ? (
            <Button variant="contained" color="secondary" onClick={handleBack}>
              Anterior
            </Button>
          ) : null}
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            endIcon={
              loadingUpdate && (
                <CircularProgress style={{ color: "#FFF" }} size={20} />
              )
            }
          >
            {activeStep !==
            (userType === 1 ? producerSteps : marketerSteps).length - 1
              ? "Siguiente"
              : "Finalizar"}
          </Button>
        </DialogActions>
      </Dialog>
      s
    </>
  );
};

export default Welcome;
