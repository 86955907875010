import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "20px",
    },
  },
  icon: {
    fontSize: "-webkit-xxx-large",
    display: "block",
    textAlign: "center",
    color: "#dd9835",
    marginBottom: "15px",
  },
  title: {
    textAlign: "center",
    fontWeight: 600,
  },
  message: {
    textAlign: "center",
  },
  loadingColor: {
    color: "#767676",
  },
}));

export { useStyles as default };
