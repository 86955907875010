import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    borderRadius: theme.spacing(0.8),
    overflow: 'hidden',
    minWidth: '400px !important',
  },
  message: {
    width: '100%',
    padding: 0,
    background: theme.palette.common.white,
  },
  card: {
    width: '100%',
    boxShadow: 'none',
    padding: theme.spacing(1.5),
    position: 'relative',
    display: 'flex',
    alignItems: 'stretch',
    cursor: 'pointer',
  },
  close_button: {
    background: 'transparent',
    // border: `1px solid #DD9835`,
    marginLeft: 'auto',
    padding: 0,
    border: 0,
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 17,
      color: grey[500],
      '&:hover': {
        color: grey[700],
      },
    },
  },
  notification_avatar: {
    marginRight: theme.spacing(1.5),
  },
  message_content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    paddingRight: theme.spacing(1.5),
  },
  message_main: {
    fontFamily: 'Raleway',
    fontWeight: 600,
  },
}));

export default useStyles;
