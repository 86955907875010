import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogContainer:{
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px'
    },
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px'
    }
  },
  container:{
    display: 'flex', 
    justifyContent: 'center',
    margin: '24px 0px'
  },
  cardContainer: {
    width: 180, 
    borderRadius: 20, 
    overflow: 'hidden',
    margin: '0px 12px'
  }, 
  cardContent:{
    width: '100%',
    height: '100%',
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
    textAlign: 'center',
    padding: 12, 
    background: '#F3F3F3', 
    "&:hover":{
      background: '#DD9835',
      "& i":{
        color: '#FFF',
      }, 
      "& p":{
        color: '#FFF',
      }
    }
  }, 
  cardIcon:{
    fontSize: 60, 
    color: '#767676', 
    marginBottom: 16
  }, 
  cardTitle:{
    color: '#767676', 
    marginBottom: 16,
    fontWeight: 'bold'
  }, 
  dialogFooter:{
    display:'flex', 
    justifyContent:'center', 
    padding: 20, 
  }, 
}))

export { useStyles as default }
