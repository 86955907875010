import { Redirect } from 'react-router-dom';

import { SIGN_OUT } from '../types/userTypes';

import { logout } from 'api/authService';

export const signOut = (payload: string | number) => (dispatch: any) => {
  logout(payload).then((res) => {
    if (res.statusCode === 200) {
      Promise.resolve()
        .then(() =>
          dispatch({
            type: SIGN_OUT,
          })
        )
        .then(async () => await localStorage.removeItem('persist:root'))
        .then(() => <Redirect push exact to="/auth/login" />);
    }
  });
};
