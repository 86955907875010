import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface NumberTotalProps {
  value: string | number;
  info: string;
  position?: 'bottom' | 'right';
  widthInfo?: number | string;
  widthValue?: number | string;
  colorValue?: string;
  justifyValue?: 'center' | 'left' | 'right';
  justifyInfo?: 'center' | 'left' | 'right';
  fontSizeValue?: number | string;
}

const NumberTotal = ({
  value,
  info,
  position,
  widthInfo,
  widthValue,
  colorValue,
  justifyValue,
  justifyInfo,
  fontSizeValue,
}: NumberTotalProps) => {
  return (
    <View
      style={{
        alignItems: 'center',
        flexDirection: position == 'bottom' ? 'column' : 'row',
      }}
    >
      <View
        style={[
          styles.backgroundweigth,
          { width: `${widthValue ? widthValue : 30}` },
        ]}
      >
        <Text
          style={{
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            color: colorValue ? colorValue : '#dd9835',
            textAlign: justifyValue ? justifyValue : 'center',
            fontSize: fontSizeValue ? fontSizeValue : 15,
          }}
        >
          {value}
        </Text>
      </View>
      <Text
        style={[
          styles.weigthanimals,
          {
            width: `${widthInfo ? widthInfo : '65px'}`,
            textAlign: justifyInfo ? justifyInfo : 'left',
          },
        ]}
      >
        {info}
      </Text>
    </View>
  );
};

export default NumberTotal;
