import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Card,
  Avatar,
  Box,
  MenuItem,
} from "@material-ui/core";

import { GET_ALL_INTERNAL_USERS } from "core/graphql/queries/getAllInternalUsers";
import { GET_ROLES } from "core/graphql/queries/settings";

import { GIVE_UP_ROLE } from "core/graphql/mutations/giveUpRole";
import avatar from "lib/helpers/dynamic-images/avatars";
import useSignOut from "lib/hooks/useSignOut";

import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useSnackbar } from "notistack";

import useStyles from "./styles";

const GiveUpRole = ({ children }: any) => {
  const classes = useStyles();

  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useSignOut();
  const idUser = useSelector((state: any) => state.user.user.id);
  const idUserType = useSelector(
    (state: any) => state.user.selectedUserType.id
  );
  const [dataUser, setDataUser] = useState<any>({});
  const [value, setValue] = useState<any>(0);
  const [valueRole, setValueRole] = useState(0);

  const [roles, setRoles] = useState<any>([]);

  const { loading, error, data, refetch } = useQuery(GET_ALL_INTERNAL_USERS, {
    context: {
      headers: { "x-auth-jwt": token },
    },
    variables: {
      idUserType: useSelector(
        (state: any) => state.user.selectedUserType.idUserType
      ),
    },
  });
  const {
    loading: loadingRoles,
    error: errorRoles,
    data: dataRoles,
  } = useQuery(GET_ROLES, {
    context: { headers: { "x-auth-jwt": token } },
  });
  const [giveUpRole, {}] = useMutation(GIVE_UP_ROLE);

  useEffect(() => {
    if (!loadingRoles && dataRoles) {
      const misRoles = dataRoles?.getRoles?.data?.misRoles?.map(
        (item: any) => item
      );

      const rolesSistema = dataRoles?.getRoles?.data?.rolesSistema?.map(
        (item: any) => item
      );
      setRoles(
        [...rolesSistema, ...misRoles].filter((item: any) => item?.estado)
      );
    }
  }, [dataRoles, loadingRoles]);

  const users = _.groupBy(
    data?.getAllInternalUsers?.data?.map((user: any) => user),
    "estado"
  );
  const [openGiveUpRole, setOpenGiveUpRole] = useState(false);
  const handleOpenGiveUpRole = () => {
    setOpenGiveUpRole(true);
  };

  const handleCloseGiveUpRole = () => {
    setOpenGiveUpRole(false);
  };

  const handleChange = (event: any) => {
    setDataUser(event.target.value);
    setValue(event.target.value.idUsuario);
    setValueRole(event.target.value.idRol);
  };
  const handleChangeNewRol = (event: any) => {
    setValueRole(event.target.value);
  };

  const handleGiveUpRole = () => {
    giveUpRole({
      variables: { idRole: valueRole, idUser: value, idUserType },
      context: { headers: { "x-auth-jwt": token } },
    })
      .then((data) => {
        setOpenGiveUpRole(false);
        enqueueSnackbar(data?.data?.asignRoleAdmin?.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "success",
        });
        setTimeout(() => signOut(), 1000);
      })
      .catch(() => {
        enqueueSnackbar("No se pudo transferir el rol, ha ocurrido un error", {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        });
      });
  };

  if (loading || loadingRoles) {
    return <p>Cargando...</p>;
  }

  return (
    <>
      {children({ handleOpenGiveUpRole, openGiveUpRole })}
      {openGiveUpRole && (
        <Dialog
          open={openGiveUpRole}
          onClose={handleCloseGiveUpRole}
          maxWidth="sm"
          fullWidth
          className={classes.dialogContainer}
        >
          <DialogTitle className={classes.containerCenter}>
            <Typography variant="h5" className={classes.title}>
              Transferir Rol
            </Typography>
            <Typography variant="subtitle1" className={classes.captionGray}>
              A continuación selecciona el usuario al que vas a ceder el rol de
              Administrador. (Recuerde que solo se permite un usuario
              Administrador por empresa)
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Typography variant="subtitle1" className={classes.steps}>
                Seleccione la persona a la que se va a reasignar el rol
                Administrador
              </Typography>
              <TextField
                label="Trasnferir rol a"
                size="small"
                variant="outlined"
                fullWidth
                select
                SelectProps={{
                  renderValue: () => {
                    return dataUser?.primerNombre
                      ? `${dataUser?.primerNombre} ${dataUser?.segundoNombre} ${dataUser?.primerApellido} ${dataUser?.segundoApellido}`
                      : "";
                  },
                  MenuProps: {
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  },
                }}
                value={value}
                onChange={handleChange}
              >
                {users["true"]?.map((user: any) => {
                  const avatarUser = avatar(user?.username);
                  return (
                    <MenuItem key={user?.idUsuario} value={user}>
                      {idUser === user?.idUsuario ? null : (
                        <Card variant="outlined" className={classes.card}>
                          <Avatar src={avatarUser as string} />
                          <div className={classes.infoContainer}>
                            <Typography className={classes.name}>
                              {user?.primerNombre} {user?.segundoNombre}{" "}
                              {user?.primerApellido} {user?.segundoApellido}
                            </Typography>
                            <Typography className={classes.userName}>
                              @{user?.username} - {user?.rol}
                            </Typography>
                          </div>
                        </Card>
                      )}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Typography variant="subtitle1" className={classes.steps}>
                Seleccione su nuevo rol
              </Typography>
              <TextField
                label="Mi Nuevo Rol"
                size="small"
                variant="outlined"
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: { maxHeight: 300 },
                    },
                  },
                }}
                value={valueRole}
                onChange={handleChangeNewRol}
              >
                {roles?.map((role: any) => {
                  return (
                    <MenuItem key={role?.idRol} value={role?.idRol}>
                      {role?.descripcion}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
            <DialogActions className={classes.dialogFooter}>
              <Button variant="outlined" onClick={handleCloseGiveUpRole}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGiveUpRole}
              >
                Aceptar
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default GiveUpRole;
