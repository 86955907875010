import * as yup from "yup";

export const RegisterUserValidation = yup.object().shape({
  username: yup
    .string()
    .required("Campo requerido")
    .test(
      "without-spaces",
      "No se permiten espacios en blanco",
      (value: any) => {
        const noValido = /\s/;

        return !noValido.test(value);
      }
    ),
  email: yup.string().required("Campo requerido"),
  password: yup
    .string()
    .required("Este campo es requerido")
    .test(
      "min-length",
      "Minímo 8 caracteres",
      (value: any) => value?.length >= 8
    )
    .test(
      "pass-uppercase",
      "Debe contener almenos una mayúscula",
      (value: any) => {
        let nMay = 0;
        const t1 = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
        for (let index = 0; index < value?.length; index++) {
          if (t1.indexOf(value.charAt(index)) !== -1) {
            nMay++;
          }
        }
        if (nMay > 0) {
          return true;
        } else {
          return false;
        }
      }
    )
    .test(
      "pass-lowercase",
      "Debe contener almenos una minúscula",
      (value: any) => {
        let nMin = 0;
        const t2 = "abcdefghijklmnñopqrstuvwxyz";
        for (let index = 0; index < value?.length; index++) {
          if (t2.indexOf(value.charAt(index)) !== -1) {
            nMin++;
          }
        }
        if (nMin > 0) {
          return true;
        } else {
          return false;
        }
      }
    )
    .test("pass-number", "Debe contener al menos un número", (value: any) => {
      let nNum = 0;
      const t3 = "0123456789";
      for (let index = 0; index < value?.length; index++) {
        if (t3.indexOf(value.charAt(index)) !== -1) {
          nNum++;
        }
      }
      if (nNum > 0) {
        return true;
      } else {
        return false;
      }
    })
    .matches(/[a-zA-Z0-9]/, {
      message: "No se permiten caracteres especiales ni espacios",
    }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "La contraseña no coincide")
    .required("Este campo es requerido"),
  aceptaPoliticaConfidencialidad: yup
    .bool()
    .oneOf([true], "Debes aceptar las Políticas de tratamiento de datos personales."),
  aceptaHabeasData: yup
    .bool()
    .oneOf([true], "Debes aceptar las condiciones de Habeas Data"),
  aceptaTerminosUso: yup
    .bool()
    .oneOf([true], "Debes aceptar los términos de uso"),
});
