import {
  Collapse,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import NavigationItem from "./NavigationItem";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    paddingLeft: 10,
    "&.open": {
      backgroundColor: "rgba(0,0,0,.08)",
    },
    "& .collapse-children": {
      background: "#ffffff14",
      width: "calc(100% - 16px)",
    },
  },
  item: {
    height: 40,
    width: "calc(100% - 16px)",
    borderRadius: 5,
    paddingRight: 12,
    color: theme.palette.text.primary,
    paddingLeft: 10,
    "&.square": {
      width: "100%",
      borderRadius: "0",
    },
  },
  icon: {
    fontSize: "1.6rem",
    marginRight: "1.6rem",
    flexShrink: 0,
    color: theme.palette.common.white,
  },
  title: {
    color: "#FFF",
    fontFamily: "Raleway",
  },
  arrow_control: {
    width: "1.6rem",
    height: "1.6rem",
  },
  arrow_icon: {
    fontSize: "1.3rem",
    color: theme.palette.common.white,
  },
  open: {
    background: "rgba(255, 255, 255, 0.08)",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.08)",
    },
  },
  active: {
    background: "rgba(221, 152, 53, 0.12)",
    width: "calc(100% - 10px)",
    "&.foldedAndOpened": {
      width: "calc(100% - 16px)",
    },
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
    "&:hover": {
      background: "rgba(221, 152, 53, 0.2)",
    },
  },
}));

interface Item {
  name: string;
  children?: Item[];
  type: "module" | "item";
  url?: string;
  icon?: string;
}

interface NavigationCollapseProps {
  item: Item;
}

const ItemIcon = ({ icon = "", ...props }: any) => {
  const arrIcon = icon?.split("__");
  const isMV = arrIcon[0] === "mv";

  if (!icon) return null;
  return (
    <Icon
      className={clsx(
        props.className,
        isMV && `icon-${arrIcon[1]}`,
        "material-icons-outlined"
      )}
    >
      {!isMV && arrIcon[1]}
    </Icon>
  );
};

const NavigationCollapse = ({ item }: NavigationCollapseProps) => {
  const classes = useStyles();
  const { location } = useHistory();
  const { folded, foldedOpen } = useSelector((state: any) => state.ui.sidebar);
  const [active, setActive] = useState(item.url === location.pathname);
  const [open, setOpen] = useState(false);
  const foldedAndOpened = folded && foldedOpen;

  const handleClick = () => setOpen(!open);

  useEffect(() => {
    const hasChildrenActive = (): boolean => {
      if (item.children) {
        return Boolean(
          item.children.find((element) => element.url === location.pathname)
        );
      }
      return false;
    };
    setActive(hasChildrenActive());
  }, [location.pathname]);

  return (
    <ul className={classes.root}>
      <ListItem
        button
        className={clsx(
          classes.item,
          "list-item",
          open && classes.open,
          active && classes.active,
          (foldedAndOpened || !folded) && "foldedAndOpened"
        )}
        onClick={handleClick}
      >
        <ItemIcon className={clsx(classes.icon)} icon={item.icon} />
        <ListItemText
          primary={item.name}
          className="list-item-text"
          classes={{ primary: classes.title }}
        />
        <IconButton disableRipple className={classes.arrow_control}>
          <Icon className={clsx("arrow-icon", classes.arrow_icon)}>
            {open ? "expand_less" : "expand_more"}
          </Icon>
        </IconButton>
      </ListItem>
      {item.children && (
        <Collapse in={open} className="collapse-children">
          {item.children
            .filter((item: any) => item.state)
            .map((element: Item, key: number) => (
              <NavigationItem key={key} item={element} nested />
            ))}
        </Collapse>
      )}
    </ul>
  );
};

export default NavigationCollapse;
