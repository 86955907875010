import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { height } from 'styled-system';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5%',
    paddingTop: ' 2.5%',
    maxWidth: 'inherit',
  },

  breadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0',
  },

  contentCard: {
    width: '100%',
  },

  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  paddingItems: {
    padding: '20px 0px',
  },

  switch: {
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      background: theme.palette.success.main,
      opacity: 1,
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: '#fff',
    },
  },

  subtitleTitle: {
    color: '#767676',
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Roboto',
    paddingBottom: 7,
  },

  descriptionTitle: {
    color: '#AFAFAF',
    fontSize: 14,
  },

  accordionContainer: {
    borderColor: 'inherit',
    boxShadow: 'none',
    margin: '20px 32px',
    '&.MuiAccordion-root.Mui-expanded': {
      marginLeft: '32px',
      marginRight: '32px',
      marginTop: '20px',
    },
    '&::before': {
      height: 0,
    },
  },

  accordionSummary: {
    background: '#F2F2F2',

    borderRadius: '25px 25px 25px 25px',
    height: 20,

    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 50,
      borderRadius: '25px 25px 0px 0px',
      border: '0.5px solid #D6D6D6',
    },
  },

  accordionDetails: {
    border: '0.5px solid #D6D6D6',
    borderRadius: '0px 0px 25px 25px',
    '&.MuiAccordionDetails-root': {
      display: 'block',
    },
  },

  labelAccordion: {
    fontWeight: 'bold',
    color: '#212121',
    fontSize: 18,
  },

  labelRoles: {
    color: '#767676',
    fontSize: 16,
    fontWeight: 'bolder',
    margin: '15px 5px',
  },

  tableHeader: {
    fontSize: 14,
    color: '#AFAFAF',
    fontFamily: 'Roboto',
  },

  viewButton: {
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      color: '#fff',
    },
  },

  dataGrid: {
    border: 'none',
    display: 'flex',
    '& .MuiBox-root': {
      margin: '20px 0',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
}));

export default useStyles;
