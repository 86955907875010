import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: 'calc(100vh - 50px)',
    justifyContent: 'center',
    width: '100%',
  },
  backdrop: {
    alignItems: 'center',
    backdropFilter: 'blur(2px)',
    background: 'rgba(0, 0, 0, 0.16)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    '& .MuiTypography-h6': {
      fontFamily: 'Raleway',
      marginTop: theme.spacing(1.5),
    },
  },
}));

export default useStyles;
