import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Collapse,
  IconButton,
  Avatar,
  Box,
  Fab,
} from '@material-ui/core';
import { Share } from '@material-ui/icons';

import ShareReport from 'components/PageComponents/SharedReports/ShareReport';
import LotDetail from 'components/PageComponents/SharedReports/LotDetail';
import ShareReportsOfLot from 'components/PageComponents/SharedReports/ShareReportsOfLot';
import reportsIcons from 'lib/helpers/reportsIcons';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import useStyles from './styles';

const AllReports = () => {
  const classes = useStyles();

  const Data = [
    {
      id: 1,
      lotIP: 2040,
      createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
      marketer: 'Ivan Echeverri ',
      producer: 'Nestor Angel ',
      reports: [
        {
          reportType: 'Portería',
          reportNumber: 203050,
          createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
          usersWithAccess: 2,
        },
        {
          reportType: 'Guias de Movilización',
          reportNumber: 542188,
          createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
          usersWithAccess: 2,
        },
        {
          reportType: 'Tiquete de Báscula',
          reportNumber: 368744,
          createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
          usersWithAccess: 2,
        },
        {
          reportType: 'Inmunocastración',
          reportNumber: 213050,
          createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
          usersWithAccess: 2,
        },
        {
          reportType: 'Antemortem',
          reportNumber: 404157,
          createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
          usersWithAccess: 0,
        },
        {
          reportType: 'Visceras Blancas',
          reportNumber: 114785,
          createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
          usersWithAccess: 0,
        },
        {
          reportType: 'Postmortem',
          reportNumber: 784152,
          createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
          usersWithAccess: 0,
        },
        {
          reportType: 'Evaluación de Magro',
          reportNumber: 368591,
          createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
          usersWithAccess: 0,
        },
        {
          reportType: 'Distribución y Despachos (Éxito Laureles)',
          reportNumber: 210478,
          createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
          usersWithAccess: 0,
        },
        {
          reportType: 'Distribución y Despachos (Éxito Colombia)',
          reportNumber: 211278,
          createAt: dayjs().format('DD/MM/YYYY HH:mm:ss'),
          usersWithAccess: 0,
        },
      ],
    },
  ];

  const Row = (row: any) => {
    const dataTable = row.row;
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow className={clsx({ [classes.isOpen]: open })}>
          <TableCell>{dataTable.lotIP}</TableCell>
          <TableCell>{dataTable.createAt}</TableCell>
          <TableCell>{dataTable.producer}</TableCell>
          <TableCell>{dataTable.marketer}</TableCell>
          <TableCell align="center">
            <Box display="flex" justifyContent="center">
              <ShareReportsOfLot>
                {({ handleOpen }: any) => (
                  <Fab
                    color="primary"
                    className={classes.btnDetails}
                    onClick={handleOpen}
                  >
                    <Share />
                  </Fab>
                )}
              </ShareReportsOfLot>
            </Box>
          </TableCell>
          <TableCell align="center">
            <IconButton onClick={() => setOpen(!open)}>
              {open ? (
                <i className="icon-circle-arrow-top" />
              ) : (
                <i className="icon-circle-arrow-bottom" />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={clsx({
              [classes.isOpen]: open,
              [classes.containerCollapse]: true,
            })}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo de Reporte</TableCell>
                    <TableCell>Número de Reporte</TableCell>
                    <TableCell>Fecha de Creación</TableCell>
                    <TableCell align="center">Usuarios con Acceso</TableCell>
                    <TableCell align="center">Ver detalle</TableCell>
                    <TableCell align="center">Compartir Reporte</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.reports.map((item: any) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Avatar className={classes.avatar}>
                              <i className={reportsIcons(item.reportType)} />
                            </Avatar>
                            {item.reportType}
                          </Box>
                        </TableCell>
                        <TableCell>{item.reportNumber}</TableCell>
                        <TableCell>{item.createAt}</TableCell>
                        <TableCell align="center">
                          {item.usersWithAccess}
                        </TableCell>
                        <TableCell align="center">
                          <Box display="flex" justifyContent="center">
                            <LotDetail>
                              {({ handleOpen }: any) => (
                                <Box>
                                  <Fab
                                    color="primary"
                                    className={classes.btnDetails}
                                    onClick={handleOpen}
                                  >
                                    <Box
                                      component="i"
                                      fontSize="20px"
                                      className="icon-eye"
                                    />
                                  </Fab>
                                </Box>
                              )}
                            </LotDetail>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box display="flex" justifyContent="center">
                            <ShareReport>
                              {({ handleOpen }: any) => (
                                <Box>
                                  <Fab
                                    color="primary"
                                    className={classes.btnDetails}
                                    onClick={handleOpen}
                                  >
                                    <Share />
                                  </Fab>
                                </Box>
                              )}
                            </ShareReport>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <div className={classes.containerTab}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Lote Infoporcinos</TableCell>
            <TableCell>Fecha de Creación</TableCell>
            <TableCell>Productor</TableCell>
            <TableCell>Comercializador</TableCell>
            <TableCell align="center">Compartir Reportes del Lote</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Data.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AllReports;
