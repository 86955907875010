import React from 'react';
import { makeStyles } from '@material-ui/core';

import { GenerateRoutes as Routes } from 'routes';

import PagesContainer from 'components/PagesContainer';

import { Main, Search, Details } from './views';

const useStyles = makeStyles((theme) => ({
  Height: {
    minHeight: 'calc(100vh - 110px)',
  },
}));

const Users = () => {
  const classes = useStyles();
  return (
    <PagesContainer className={classes.Height}>
      <Routes MainPage={Main} SearchPage={Search} DetailsPage={Details} />
    </PagesContainer>
  );
};

export default Users;
