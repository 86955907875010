import { gql } from "apollo-boost";

export const DATA_INTERNAL_USERS = gql`
  query getGeneralDataInternalUsers($userType: Int!) {
    getGeneralDataInternalUsers(input: { idTipoUsuario: $userType }) {
      data {
        roles {
          idRol
          descripcion
          cantidad
          usuarios {
            idUsuario
            username
            idTipoDocumento
            numeroDocumento
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            idRol
            fechaUltimaConexion
            fechaRegistro
            estado
          }
        }
        usuariosActivos
        usuariosInactivos
        tiempoConexion
      }
    }
  }
`;

export const DETAIL_INTERNALUSER = gql`
  query getDetailInternaluser(
    $idTipoUsuario: Int!
    $idUsuarioInterno: Int!
    $fechaInicial: String!
    $fechaFinal: String!
  ) {
    getDetailInternaluser(
      input: {
        idTipoUsuario: $idTipoUsuario
        idUsuarioInterno: $idUsuarioInterno
        fechaInicial: $fechaInicial
        fechaFinal: $fechaFinal
      }
    ) {
      data {
        detalleUsuario {
          idUsuario
          idPersona
          usuario
          primerNombre
          segundoNombre
          primerApellido
          segundoApellido
          idTipoDocumento
          tipoDocumento
          numeroDocumento
          indicativoCelular
          celular
          email
          idPais
          idCargo
          cargo
          fechaRegistro
          estado
          idRol
          rol
          tiempoActivo
          fechaUltimaConexion
          tiempoActivoMovil
        }
        detalleActividadUsuario {
          idUsuario
          usuario
          rol
          fechaInicial
          fechaFinal
          tiempoTotal
          registro
        }
      }
    }
  }
`;
