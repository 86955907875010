import React, { useEffect, useState } from "react";

import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Divider,
  Grid,
} from "@material-ui/core";

import CardSelectType from "components/PageComponents/Profile/MarketerTypes/CardSelectType";

import { MARKETER_TYPE } from "core/graphql/queries/profile";
import { UPDATE_MARKETER_TYPES } from "core/graphql/mutations/profile";

import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useSnackbar } from "notistack";

import useStyles from "./styles";

const MarketerType = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const path = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [dataTypes, setDataTypes] = useState<any>([]);
  const [typesSelected, setTypesSelected] = useState<any>([]);

  const { data, loading, refetch } = useQuery(MARKETER_TYPE, {
    context: { headers: { "x-auth-jwt": token } },
  });

  const [updateTypes, { loading: loadingUpdate }] = useMutation(
    UPDATE_MARKETER_TYPES,
    {
      context: { headers: { "x-auth-jwt": token } },
    }
  );

  useEffect(() => {
    if (!loading && data) {
      setDataTypes(data?.getMarketerType?.data);
      setTypesSelected(
        data?.getMarketerType?.data
          .filter((item: any) => item?.estado)
          ?.map((i: any) => i?.idTipoComercializador)
      );
    }
  }, [loading, data]);

  useEffect(() => {
    refetch();
  }, [path]);

  const handleChange = (id: any) => {
    if (typesSelected.includes(id)) {
      const remove = typesSelected.filter((item: any) => item != id);
      setTypesSelected(remove);
    } else {
      setTypesSelected([...typesSelected, id]);
    }
  };

  if (loading) {
    return (
      <Box
        minHeight="500px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress variant="indeterminate" size={50} />
      </Box>
    );
  }

  const handleUpdateTypes = () => {
    updateTypes({
      variables: { IdsTipoComercializador: typesSelected },
    })
      .then((data) => {
        if (data?.data?.putTipoComercializador?.data?.success === 1) {
          enqueueSnackbar("Datos actualizados correctamente.", {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
        } else {
          enqueueSnackbar(
            "Debe seleccionar almenos un tipo de comercializador",
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
            }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          "Ocurrió un error no se pudieron Actualizar los Datos.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          }
        );
      });
  };

  return (
    <form>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography variant="h6" className={classes.title}>
            Tipos de Comercializador{" "}
            <i
              className={
                typesSelected.length > 0
                  ? "icon-circle-check"
                  : "icon-circle-close"
              }
              style={{
                color: typesSelected.length > 0 ? "#68CE70" : "#E2575C",
              }}
            />
          </Typography>
        </Box>
        <Divider />
        <Box marginTop={2}>
          <Typography color="secondary">
            A continuación defina el tipo de comercializador que mejor se adecue
            a su perfil. Puede seleccionar varias opciones. Al guardar los
            cambios se actualizarán las opciones de su perfil.
          </Typography>
        </Box>
        <Box marginY={3}>
          <Grid container xs={12} spacing={3}>
            {dataTypes?.map((item: any) => {
              return (
                <Grid item container xs={6}>
                  <CardSelectType
                    name={item?.idTipoComercializador}
                    title={item?.nombre}
                    description={item?.descripcion}
                    isCheck={typesSelected.includes(
                      item?.idTipoComercializador
                    )}
                    onchange={() => handleChange(item?.idTipoComercializador)}
                    value={item?.idTipoComercializador}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Box marginTop={3} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateTypes}
              endIcon={
                loadingUpdate && (
                  <CircularProgress style={{ color: "#FFF" }} size={20} />
                )
              }
            >
              Actualizar
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default MarketerType;
