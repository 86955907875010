import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  color: {
    borderColor: '#ccc'
  },
  color2: {
    backgroundColor: '#dd9835'
  }, 
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  displayTextField: {
    display: 'block',
    marginBottom: '10px'
  },
  titleInput: {
    marginBottom: '5px',
    marginLeft: '15px',
    color: '#afafaf',
    fontWeight: 600,
  },
  marginBottom: {
    marginBottom: '10px'
  },
  alignText: {
    // textAlign: 'center'
  },
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px'
    }
  },
  chipDefault:{
    boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
    border: `1px solid rgba(0, 0, 0, 0.06)`,
    justifyContent: 'flex-start',
    minWidth: '130px',
    "& .MuiAvatar-circle":{
      backgroundColor: "#bdbdbd",
      color: "#767676"
    },
    "& .MuiChip-label": {
      color: "#AFAFAF"
    },
  },

  chipSelected:{
    boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
    border: `1px solid rgba(0, 0, 0, 0.06)`,
    justifyContent: 'flex-start',
    minWidth: '130px',
    "& .MuiAvatar-colorDefault":{
      backgroundColor: "#dd9835 !important",
      color: "white"
    },
    "& .MuiChip-label": {
      color: "black"
    },
  },

  loadingColor: {
    color: 'white'
  },
  
}));

export { useStyles as default }