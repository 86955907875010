import { makeStyles } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

const appearLoading = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${appearLoading} ease-in 0.3s;
`;

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 50px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogHeader: {
    paddingBottom: 0,
  },

  dialogContent: {
    padding: '50px 150px 50px 150px',
    textAlign: 'center',
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '20px',
    },
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',

    padding: '40px 20px',

    '& .MuiBadge-dot': {
      minWidth: 22,
      height: 22,
      borderRadius: 11,
      right: 8,
      top: 5,
    },
  },

  cardItem: {
    width: 186,
    height: 264,
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      },
    },
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  cardActionArea: {
    height: '100%',
  },

  roleAvatar: {
    background: theme.palette.primary.main,
  },

  roleIcon: {
    fontSize: 60,
    color: '#767676',
    margin: '0px 20px',
  },

  mainText: {
    color: '#767676',
    fontSize: 18,
    fontWeight: 'bolder',
  },

  secondaryText: {
    color: '#AFAFAF',
    fontSize: 14,
  },

  button: {
    textTransform: 'uppercase',
    fontFamily: 'Raleway',
    fontWeight: 'bold',
  },

  marginRight: {
    marginRight: 15,
  },

  header_login: {
    fontSize: 16,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
  },
  logo_login: {
    width: 230,
  },
  request_button_container: {
    position: 'absolute',
    top: 60,
    right: 100,
    display: 'flex',
    alignItems: 'center',
    '& > .MuiTypography-root': {
      marginRight: theme.spacing(2),
      fontFamily: 'Raleway',
    },
    '& > .MuiButton-outlined': {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
}));
