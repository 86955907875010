import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#F2F2F2',
    height: '100vh'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  content: {
    padding: theme.spacing(6),
    borderRadius: theme.spacing(3),
    overflow: 'hidden',
    animation: '$appear .3s ease-in-out'
  },
  image: {
    width: 400,
    animation: '$appear-left .3s ease-in backwards',
    animationDelay: '.3s',
    '& .zoom-eye': {
      animation: '$closeEye 4s ease-in infinite',
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut
      }),
    }
  },
  '@keyframes closeEye': {
    '0%': {
      ry: 2,
      rx: 13
    },
    '5%': {
      ry: 10.852,
      rx: 10.838
    },
    '10%': {
      ry: 2,
      rx: 13
    },
    '15%': {
      ry: 10.852,
      rx: 10.838
    },
    '100%': {
      ry: 10.852,
      rx: 10.838
    }
  },
  '@keyframes appear': {
    'from': {
      transform: 'translateY(15px)',
      opacity: 0
    },
    'to': {
      transform: 'translateY(0px)',
      opacity: 1
    },
  },
  '@keyframes appear-right': {
    'from': {
      transform: 'translateX(15px)',
      opacity: 0
    },
    'to': {
      transform: 'translateX(0px)',
      opacity: 1
    },
  },
  '@keyframes appear-left': {
    'from': {
      transform: 'translateX(-15px)',
      opacity: 0
    },
    'to': {
      transform: 'translateX(0px)',
      opacity: 1
    },
  },
  text_container: {
    textAlign: 'right',
    '& .MuiTypography-h1': {
      fontFamily: 'Raleway',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: theme.spacing(9),
      animation: '$appear-right .3s ease-in backwards',
      animationDelay: '.3s'
    },
    '& .MuiTypography-colorTextSecondary': {
      fontSize: theme.spacing(2),
      animation: '$appear-right .3s ease-in backwards',
      animationDelay: '.6s'
    },
    '& .MuiButton-containedPrimary': {
      marginTop: theme.spacing(3),
      animation: '$appear-right .3s ease-in backwards',
      animationDelay: '.9s',
      '& .MuiButton-label': {
        fontWeight: 'bold',
        fontSize: 14
      }
    }
  }
}))

export default useStyles