import { NestedRoute } from 'lib/interfaces/routes';
import MeasurementResume from './MeasurementResume';
import WeightResume from './WeightResume';

const routes: NestedRoute[] = [
  {
    route: '/resumen-measurement',
    Node: MeasurementResume,
  },
  {
    route: '/resumen-pessage',
    Node: WeightResume,
  },
];

export default routes;
