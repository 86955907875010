import React from 'react';
import DashboardMarketer from './Marketer';
import DashboardProducer from './Producer';
import { useSelector } from 'react-redux';

const index = () => {
  const userType = useSelector(
    (state: any) => state.user.selectedUserType.idUserType
  );
  if (userType === 2) {
    return <DashboardMarketer />;
  } else {
    return <DashboardProducer />;
  }
};

export default index;
