import gql from 'graphql-tag';

export default gql`
  mutation updateProfilePicture($file: Upload!) {
    updateProfilePicture(file: $file) {
      results {
        path
        success
        message
      }
      token {
        value: tokenNuevo
      }
    }
  }
`;
