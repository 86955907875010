import React from 'react';
import { ListItem, Typography } from '@material-ui/core';
import clsx from 'clsx';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import useStyles from './styles';

interface ItemListInfoProps {
  icon?: string;
  titleInfo: string;
  numberInfo: string | number;
  classNameItem?: string;
  width?: string;
}

const ItemListInfo = ({
  icon,
  titleInfo,
  numberInfo,
  classNameItem,
  width,
}: ItemListInfoProps) => {
  const classes = useStyles();
  return (
    <ListItem
      style={{ width: width ?? '100%' }}
      className={clsx(classes.itemList, classNameItem)}
    >
      {icon ? (
        <i className={clsx(`${icon}`, classes.listIcon)} />
      ) : (
        <FiberManualRecordIcon className={classes.listDefaultIcon} />
      )}
      <div className={classes.infoListContainer}>
        <Typography>{titleInfo}</Typography>
        <Typography className={classes.listNumber}>{numberInfo}</Typography>
      </div>
    </ListItem>
  );
};

export default ItemListInfo;
