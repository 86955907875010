import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 200,
    borderRadius: 10,
    boxShadow: theme.shadows[2],
    '&.selected': {
      background: '#FFF4E5',
    },
    margin: 12,
    '&.disabled': {
      boxShadow: 'none',
      border: '1px solid #E7E7E7',
      color: '#AFAFAF',
    },
  },
  content: {
    padding: 12,
    display: 'flex',
    minHeight: 65,
    '&.column': {
      minHeight: 120,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  info: {
    margin: '0px 8px',
    '& .MuiListItemText-primary': {
      color: '#767676',
      fontWeight: 'bold',
    },
    '&.center': {
      margin: '8px',
      textAlign: 'center',
    },
    '&.disabled': {
      '& .MuiListItemText-primary': {
        color: '#AFAFAF',
      },
    },
  },
  icon: {
    fontSize: '20px',
  },
  disabled: {
    background: '#AFAFAF',
  },

  cardNit: {
    minWidth: 200,
    height: 150,
    borderRadius: 10,
    boxShadow: theme.shadows[2],
    '&.selected': {
      background: '#FFF4E5',
    },
    margin: 12,
    '&.disabled': {
      boxShadow: 'none',
      border: '1px solid #E7E7E7',
      color: '#AFAFAF',
    },
  },

  centerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '10px 0px',
  },

  title: {
    color: '#767676',
    fontWeight: 'bold',
  },

  subttitles: {
    color: '#AFAFAF',
  },
}));

export { useStyles as default };
