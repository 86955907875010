import uiInitialState from '../initialStates/uiInitialState';
import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  RESET_SNACKBAR,
  TOGGLE_SIDEBAR_FOLDED,
  FOLDED,
  CHANGE_TAB_ROUTE,
  CHANGE_SUB_TAB,
  CHANGE_TRANSPORT_TAB
} from '../types/uiTypes';

export default (state = uiInitialState, action: any) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          ...action.payload,
          open: true,
        },
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      };
    case RESET_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...uiInitialState.snackbar,
        },
      };
    case TOGGLE_SIDEBAR_FOLDED:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          folded: !state.sidebar.folded,
        },
      };
    case FOLDED:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          foldedOpen: action.payload,
        },
      };
    case CHANGE_TAB_ROUTE:
      return {
        ...state,
        sharedReports: {
          ...state.sharedReports,
          valueRouteTab: action.payload,
        },
      };
    case CHANGE_SUB_TAB:
      return {
        ...state,
        subTab: {
          ...state.subTab,
          valueSubTab: action.payload,
        },
      };
    case CHANGE_TRANSPORT_TAB:
      return {
        ...state,
        transport: {
          ...state.transport,
          valueTransportTab: action.payload,
        },
      };
    default:
      return { ...state };
  }
};
