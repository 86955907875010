export default (value: any, type: any) => {
  let string = false;

  if (typeof value === "string") {
    string = true;
  }

  if (string && type === "lat") {
    return Number.parseFloat(value.replace(/,/g, "."));
  } else if (!string && type === "lat") {
    return value;
  } else if (string && type === "lng") {
    let num = Number.parseFloat(value.replace(/,/g, "."));
    let isNegative = Math.sign(num); // 1 si es positivo, -1 si es negativo
    if (isNegative === 1) {
      return Number.parseFloat(value.replace(/,/g, ".")) * -1;
    } else {
      return Number.parseFloat(value.replace(/,/g, "."));
    }
  } else if (!string && type === "lng") {
    let isNegative = Math.sign(value); // 1 si es positivo, -1 si es negativo
    if (isNegative === 1) {
      return value * -1;
    } else {
      return value;
    }
  } else {
    return "do not match";
  }
};
