import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  listCard:{
    width:'90%',
    margin:'auto',
    display:'flex', 
    flexFlow:'row wrap', 
    justifyContent:'space-between'
  },
  containerCard: {
    width:'30%',
    margin:12,
    borderRadius:12, 
  }, 
  card:{
    width:'100%',
    display:'flex', 
    alignItems:'center', 
    justifyContent:'space-between', 
    padding: '12px', 
    borderRadius:12, 

  },
  child:{
    display:'flex', 
    alignItems:'center'
  },
  icon:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize:24
  },
  title:{
    marginLeft:12
  },
  viewed:{
    width:20, 
    height:20, 
    fontSize: 10,
    background: theme.palette.success.main,
  }
}));

export default useStyles;
