import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogContainer:{
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px'
    }
   },
  btn:{
    fontWeight: 'bold',
    fontSize: 16,
    margin: '12px 0px',
    padding: '5px 30px 5px 30px',
  },
  containerRoles:{
    display:'flex', 
    justifyContent:'center'
  },
  title:{
    display:'flex', 
    justifyContent:'center', 
    fontWeight:'bolder', 
  },
  containerCenter:{
    display:'flex', 
    flexDirection:'column',
    alignItems:'center', 
  } ,
  formContainer:{
    margin: '12px 0'
  },
  steps:{
    margin: '12px 0px', 
    color: theme.palette.primary.main, 
    fontWeight: 'bolder'
  },
  dialogFooter:{
    display:'flex', 
    justifyContent:'center', 
    padding: 16
  }, 
  iconDialogCheck:{
    fontSize:70,
    color:theme.palette.primary.main
  },
  captionGray:{
    color:'#767676',
    textAlign: 'center'
  },
  card:{
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    maxHeight: 100
  }, 
  infoContainer:{
    display:'flex', 
    flexDirection: 'column', 
    paddingLeft: 8, 
  },
  name:{
    color: '#767676',
    fontWeight: 'bold', 
    textTransform:'uppercase'
  },
  userName:{ 
    color: theme.palette.primary.main,
    textTransform:'capitalize'
  }, 
}))

export default useStyles