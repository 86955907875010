import React, { useEffect } from 'react';
import {
  Typography,
  Container,
  Grid,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import Chart from 'components/Chart';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

const Dashboard = () => {
  const classes = useStyles();
  dayjs.extend(isoWeek);
  const [seurop, setSeurop] = React.useState('weekly');
  const [evaluate, setEvaluate] = React.useState('weekly');
  const [measurement, setMeasurement] = React.useState('weekly');
  const [canalesMedidas, setCanalesMedidas] = React.useState('weekly');
  const [canalesEvaluadas, setCanalesEvaluadas] = React.useState('weekly');
  const handleChangeSeurop = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeurop((event.target as HTMLInputElement).value);
  };
  const handleChangeCanalesEvaluadas = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCanalesEvaluadas((event.target as HTMLInputElement).value);
  };
  const handleChangeCanalesMedidas = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCanalesMedidas((event.target as HTMLInputElement).value);
  };
  const handleChangeEvaluate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEvaluate((event.target as HTMLInputElement).value);
  };
  const handlechangeMeasurement = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMeasurement((event.target as HTMLInputElement).value);
  };

  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/dashboard', title: 'Inicio' },
  ];

  return (
    <Container className={classes.root}>
      <div className={classes.breadContainer}>
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <Grid container spacing={4} direction="row">
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard>
                <div>
                  <Typography className={classes.subtitleTitleResume}>
                    Resumen General
                  </Typography>
                </div>
                <div className={classes.flexContainerSpaced}>
                  <div
                    className={clsx(
                      classes.centerContainer,
                      classes.centerContainerColumn
                    )}
                  >
                    <div className={classes.containerItemGrid}>
                      <Typography className={classes.labelNumber}>
                        02
                      </Typography>
                    </div>
                    <Typography className={classes.labelTitle}>
                      Granjas Registradas
                    </Typography>
                  </div>
                  <div
                    className={clsx(
                      classes.centerContainer,
                      classes.centerContainerColumn
                    )}
                  >
                    <div className={classes.containerItemGrid}>
                      <Typography className={classes.labelNumber}>
                        04
                      </Typography>
                    </div>
                    <Typography className={classes.labelTitle}>
                      Remisiones Creadas
                    </Typography>
                  </div>
                  <div
                    className={clsx(
                      classes.centerContainer,
                      classes.centerContainerColumn
                    )}
                  >
                    <div className={classes.containerItemGrid}>
                      <Typography className={classes.labelNumber}>
                        06
                      </Typography>
                    </div>
                    <Typography className={classes.labelTitle}>
                      Remisiones Impresas
                    </Typography>
                  </div>
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Resumen Sacrificio (Propio)
                  </Typography>
                  {measurement === 'today' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('dddd, D MMMM YYYY')}
                    </Typography>
                  ) : measurement === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : measurement ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().isoWeek() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>

                <RadioGroup
                  aria-label="timer"
                  name="timer3"
                  value={measurement}
                  onChange={handlechangeMeasurement}
                >
                  <div className={classes.centerContainer}>
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="today"
                      control={<Radio color="primary" />}
                      label="Hoy"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="weekly"
                      control={<Radio color="primary" />}
                      label="Semanal"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="monthly"
                      control={<Radio color="primary" />}
                      label="Mensual"
                    />
                  </div>
                </RadioGroup>

                {measurement === 'today' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            298.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (GD)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            488.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            34.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (LM)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            110.50
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎Kg'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Pesaje (PPC)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : measurement === 'monthly' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            295.55
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (GD)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            485.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            35.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (LM)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            112.50
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎Kg'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Pesaje (PPC)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : measurement ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            288.49
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (GD)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            486.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            34.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (LM)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            111.80
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎Kg'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Pesaje (PPC)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Lotes y Canales Evaluadas
                  </Typography>
                  {canalesEvaluadas === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : canalesEvaluadas ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().isoWeek() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <RadioGroup
                    aria-label="timer"
                    name="timer1"
                    value={canalesEvaluadas}
                    onChange={handleChangeCanalesEvaluadas}
                  >
                    <div className={classes.centerContainer}>
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="weekly"
                        control={<Radio color="primary" />}
                        label="Semanal"
                      />
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="monthly"
                        control={<Radio color="primary" />}
                        label="Mensual"
                      />
                    </div>
                  </RadioGroup>
                </div>
                {canalesEvaluadas === 'monthly' ? (
                  <div className={classes.flexContainer}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        <Chart
                          type="bar"
                          data={[
                            {
                              label: 'Lotes Propios',
                              data: [200],
                              bg: '#FFC676',
                            },
                            {
                              label: 'Lotes Sistema',
                              data: [0, 1500],
                              bg: '#D47D57',
                            },
                          ]}
                          graphicSize={350}
                          weightBar={50}
                          labels={['Lotes Propios', 'Lotes Sistema']}
                          max={2500}
                          position={'top'}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        <Chart
                          type="bar"
                          data={[
                            {
                              label: 'Canales Propias',
                              data: [2000],
                              bg: '#FFC676',
                            },
                            {
                              label: 'Canales Sistema',
                              data: [0, 2500],
                              bg: '#D47D57',
                            },
                          ]}
                          graphicSize={350}
                          weightBar={50}
                          labels={['Canales Propias', 'Canales Sistema']}
                          max={2500}
                          position={'top'}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={classes.flexContainer}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        <Chart
                          type="bar"
                          data={[
                            {
                              label: 'Lotes Propios',
                              data: [200],
                              bg: '#FFC676',
                            },
                            {
                              label: 'Lotes Sistema',
                              data: [0, 2500],
                              bg: '#D47D57',
                            },
                          ]}
                          graphicSize={350}
                          weightBar={50}
                          labels={['Lotes Propios', 'Lotes Sistema']}
                          max={2500}
                          position={'top'}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        <Chart
                          type="bar"
                          data={[
                            {
                              label: 'Canales Propias',
                              data: [900],
                              bg: '#FFC676',
                            },
                            {
                              label: 'Canales Sistema',
                              data: [0, 2500],
                              bg: '#D47D57',
                            },
                          ]}
                          graphicSize={350}
                          weightBar={50}
                          labels={['Canales Propias', 'Canales Sistema']}
                          max={2500}
                          position={'top'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Canales Medidas y Evaluadas
                  </Typography>
                  {canalesMedidas === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : canalesMedidas ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().isoWeek() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <RadioGroup
                    aria-label="timer"
                    name="timer1"
                    value={canalesMedidas}
                    onChange={handleChangeCanalesMedidas}
                  >
                    <div className={classes.centerContainer}>
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="weekly"
                        control={<Radio color="primary" />}
                        label="Semanal"
                      />
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="monthly"
                        control={<Radio color="primary" />}
                        label="Mensual"
                      />
                    </div>
                  </RadioGroup>
                </div>
                {canalesMedidas === 'monthly' ? (
                  <Chart
                    type="bar"
                    data={[
                      {
                        label: 'Total Canales',
                        data: [20, 620, 820, 800, 610, 100, 810],
                        bg: '#FFC676',
                      },
                    ]}
                    graphicSize={550}
                    weightBar={45}
                    labels={[
                      'Dom.',
                      'Lun.',
                      'Mar.',
                      'Miér.',
                      'Jue.',
                      'Vie.',
                      'Sab.',
                    ]}
                    max={1000}
                    position={'top'}
                  />
                ) : (
                  <Chart
                    type="bar"
                    data={[
                      {
                        label: 'Total Canales',
                        data: [40, 640, 840, 820, 630, 120, 830],
                        bg: '#FFC676',
                      },
                    ]}
                    graphicSize={550}
                    weightBar={45}
                    labels={[
                      'Dom.',
                      'Lun.',
                      'Mar.',
                      'Miér.',
                      'Jue.',
                      'Vie.',
                      'Sab.',
                    ]}
                    max={1000}
                    position={'top'}
                  />
                )}
                <div className={classes.containerButton}>
                  <Button className={classes.buttonDashboard}>Ver más</Button>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard>
                <div>
                  <Typography className={classes.subtitleTitleResume}>
                    Invitaciones y Usuarios
                  </Typography>
                </div>
                <div className={classes.flexContainerSpaced}>
                  <div
                    className={clsx(
                      classes.centerContainer,
                      classes.centerContainerColumn
                    )}
                  >
                    <div className={classes.containerItemGrid}>
                      <Typography className={classes.labelNumber}>
                        30
                      </Typography>
                    </div>
                    <Typography className={classes.labelTitle}>
                      Invitaciones Aceptadas
                    </Typography>
                  </div>
                  <div
                    className={clsx(
                      classes.centerContainer,
                      classes.centerContainerColumn
                    )}
                  >
                    <div className={classes.containerItemGrid}>
                      <Typography className={classes.labelNumber}>
                        46
                      </Typography>
                    </div>
                    <Typography className={classes.labelTitle}>
                      Invitaciones Enviadas
                    </Typography>
                  </div>
                  <div
                    className={clsx(
                      classes.centerContainer,
                      classes.centerContainerColumn
                    )}
                  >
                    <div className={classes.containerItemGrid}>
                      <Typography className={classes.labelNumber}>
                        21
                      </Typography>
                    </div>
                    <Typography className={classes.labelTitle}>
                      Usuarios en el Sistema
                    </Typography>
                  </div>
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Resumen Sacrificio (Sistema Infoporcinos)
                  </Typography>
                  {evaluate === 'today' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('dddd, D MMMM YYYY')}
                    </Typography>
                  ) : evaluate === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : evaluate ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().isoWeek() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>

                <RadioGroup
                  aria-label="timer"
                  name="timer3"
                  value={evaluate}
                  onChange={handleChangeEvaluate}
                >
                  <div className={classes.centerContainer}>
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="today"
                      control={<Radio color="primary" />}
                      label="Hoy"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="weekly"
                      control={<Radio color="primary" />}
                      label="Semanal"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="monthly"
                      control={<Radio color="primary" />}
                      label="Mensual"
                    />
                  </div>
                </RadioGroup>

                {evaluate === 'today' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            298.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (GD)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            488.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            34.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (LM)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            110.50
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎Kg'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Pesaje (PPC)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : evaluate === 'monthly' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            295.55
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (GD)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            485.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            35.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (LM)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            112.50
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎Kg'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Pesaje (PPC)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : evaluate ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            288.49
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (GD)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            486.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            34.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (LM)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            111.80
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎Kg'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Pesaje (PPC)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </ContentCard>
            </Grid>

            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Clasificación SEUROP
                  </Typography>
                  {seurop === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : seurop ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().isoWeek() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <RadioGroup
                    aria-label="timer"
                    name="timer1"
                    value={seurop}
                    onChange={handleChangeSeurop}
                  >
                    <div className={classes.centerContainer}>
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="weekly"
                        control={<Radio color="primary" />}
                        label="Semanal"
                      />
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="monthly"
                        control={<Radio color="primary" />}
                        label="Mensual"
                      />
                    </div>
                  </RadioGroup>
                  {seurop === 'today' ? (
                    <div className={classes.chartContainer}>
                      <Chart
                        type="doughnut"
                        withoutTotalInfo={true}
                        data={[
                          {
                            label: 'S - Superior',
                            data: 50,
                            bg: '#FFC676',
                          },
                          {
                            label: 'E - Excelente',
                            data: 20,
                            bg: '#F1AE6C',
                          },
                          {
                            label: 'U - Muy Buena - Estándar',
                            data: 5,
                            bg: '#E29662',
                          },
                          {
                            label: 'R - Buena',
                            data: 10,
                            bg: '#D47D57',
                          },
                          {
                            label: 'O - Menos Buena',
                            data: 2,
                            bg: '#BE5948',
                          },
                          {
                            label: 'P - Mediocre',
                            data: 5,
                            bg: '#B0413E',
                          },
                        ]}
                      />
                      <div className={classes.containerButton}></div>
                    </div>
                  ) : seurop === 'monthly' ? (
                    <div className={classes.chartContainer}>
                      <Chart
                        type="doughnut"
                        withoutTotalInfo={true}
                        data={[
                          {
                            label: 'S - Superior',
                            data: 60,
                            bg: '#FFC676',
                          },
                          {
                            label: 'E - Excelente',
                            data: 50,
                            bg: '#F1AE6C',
                          },
                          {
                            label: 'U - Muy Buena - Estándar',
                            data: 30,
                            bg: '#E29662',
                          },
                          {
                            label: 'R - Buena',
                            data: 50,
                            bg: '#D47D57',
                          },
                          {
                            label: 'O - Menos Buena',
                            data: 25,
                            bg: '#BE5948',
                          },
                          {
                            label: 'P - Mediocre',
                            data: 25,
                            bg: '#B0413E',
                          },
                        ]}
                      />
                      <div className={classes.containerButton}></div>
                    </div>
                  ) : seurop ? (
                    <div className={classes.chartContainer}>
                      <Chart
                        type="doughnut"
                        withoutTotalInfo={true}
                        data={[
                          {
                            label: 'S - Superior',
                            data: 40,
                            bg: '#FFC676',
                          },
                          {
                            label: 'E - Excelente',
                            data: 30,
                            bg: '#F1AE6C',
                          },
                          {
                            label: 'U - Muy Buena - Estándar',
                            data: 10,
                            bg: '#E29662',
                          },
                          {
                            label: 'R - Buena',
                            data: 20,
                            bg: '#D47D57',
                          },
                          {
                            label: 'O - Menos Buena',
                            data: 5,
                            bg: '#BE5948',
                          },
                          {
                            label: 'P - Mediocre',
                            data: 5,
                            bg: '#B0413E',
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Pesaje Canal Caliente (PCC)
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <Chart
                      type="bar"
                      data={[
                        {
                          label: 'Promedio Pesaje',
                          data: [1, 1, 10, 10, 320, 80, 10],
                          bg: '#E29662',
                        },
                      ]}
                      weightBar={45}
                      graphicSize={600}
                      labels={[
                        '70/80 Kg',
                        '80/90 Kg.',
                        '90/100 Kg.',
                        '100/110 Kg.',
                        '110/120 Kg.',
                        '120/130 Kg.',
                        '130/140 Kg.',
                      ]}
                      max={400}
                      position={'top'}
                    />
                  </div>
                </div>
                <div className={classes.containerButton}>
                  <Button className={classes.buttonDashboard}>Ver más</Button>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
