import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "40%",
    margin: "0 auto",
    [theme.breakpoints.down(750)]: {
      width: "60%",
    },
    [theme.breakpoints.down(750)]: {
      width: "80%",
    },
  },
  logo: {
    width: 230,
    userSelect: "none",
  },
  header: {
    fontSize: 16,
    fontFamily: "Raleway",
    fontWeight: "bold",
    margin: theme.spacing(2, 0),
  },
  subtitle: {
    marginBottom: theme.spacing(1.5),
  },
  header_margin: {
    marginBottom: theme.spacing(1.5),
  },
}));

export default useStyles;
