import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import ItemOutlinedInfo from '../../../../components/ItemOutlinedInfo/index';
import ItemTotalInfo from '../../../../components/ItemTotalInfo';

type DialogObservationProps = {
  open: boolean;
  handleClose: () => void;
};

const DialoObservation = ({ open, handleClose }: DialogObservationProps) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        className={classes.borderRadiudDialog}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Grid container>
            <Grid item container xs={5} alignItems="center">
              <Avatar className={classes.avatarTitle}>
                <i className="icon-pig-search" style={{ fontSize: '30px' }}></i>
              </Avatar>
              <Box ml={3}>
                <Typography variant="h6">Corrales de Observación</Typography>
              </Box>
            </Grid>

            <Grid item xs={7} container justify="flex-end">
              <Grid item container className={classes.divContainerTitle}>
                <Box mr={2}>
                  <Typography variant="h5" className={classes.dataTitle}>
                    02:31:40
                  </Typography>
                </Box>
                <Typography className={classes.fontTitleInfo}>
                  Duración Proceso
                </Typography>
              </Grid>

              <Box ml={3}>
                <Grid item container className={classes.divContainerTitle2}>
                  <Box mr={2}>
                    <Typography variant="h5" className={classes.dataTitle}>
                      0123
                    </Typography>
                  </Box>
                  <Typography className={classes.fontTitleInfo}>
                    Lote InfoPorcinos
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid container className={classes.titleContainer}>
              <Typography className={classes.fontTitleContainer}>
                Resumen del Proceso
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={5} className={classes.infoOutlineContainer}>
              <ItemOutlinedInfo
                icon="icon-calendar"
                title="Fecha Inspección Ante-Mortem"
                info="22/01/2019 09:00:00"
              />
            </Grid>
            <Grid item xs={3} className={classes.infoOutlineContainer}>
              <ItemOutlinedInfo
                icon="icon-productor"
                title="Productor"
                info="Nombre del Productor"
              />
            </Grid>
            <Grid item xs={4} className={classes.infoOutlineContainer}>
              <ItemOutlinedInfo
                icon="icon-comercializador"
                title="Comercializador"
                info="Nombre Comercializador"
              />
            </Grid>
          </Grid>

          <Grid container className={classes.gridContainer}>
            <Grid item container xs={5} justify="center" alignItems="center">
              <Avatar className={classes.avatarColor}>
                <Box
                  component="i"
                  className="icon-pig-search"
                  fontSize="30px"
                />
              </Avatar>
              <Typography variant="h4" className={classes.dataTotal}>
                02
              </Typography>
              <Typography className={classes.titleTotal}>
                Total Animales Observados
              </Typography>
            </Grid>
            <Grid container item xs={7} direction="row">
              <Grid container className={classes.mb}>
                <Grid item xs={5}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={1}
                    titleInfo="Caídos en Corrales"
                    avatarTotalColor="#FFC676"
                    avatarTotalSize={40}
                    classNameLabel={classes.itemInfoLabelInfo}
                  />
                </Grid>
                <Grid item xs={7}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={0}
                    titleInfo="Agitados en Corrales"
                    avatarTotalColor="#E29662"
                    avatarTotalSize={40}
                    classNameLabel={classes.itemInfoLabelInfo}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={5}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={0}
                    titleInfo="Muertos en Corrales"
                    avatarTotalColor="#F1AE6C"
                    avatarTotalSize={40}
                    classNameLabel={classes.itemInfoLabelInfo}
                  />
                </Grid>
                <Grid item xs={7}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={1}
                    titleInfo="Muertos en Corral de Observación"
                    avatarTotalColor="#D47D57"
                    avatarTotalSize={40}
                    titleInfoSize={200}
                    classNameLabel={classes.itemInfoLabelInfo}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid className={classes.resumeContainer}>
              <Grid item xs={6}>
                <Typography className={classes.fontResponsable}>
                  Responsable
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.fontTitleContainer}>
                  <Typography className={classes.dataResponsable}>
                    Juan Fernando Lopez
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              className="raleway"
              onClick={handleClose}
              color="primary"
            >
              Aceptar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DialoObservation;
