import { useDispatch, useSelector } from 'react-redux'
import { CLOSE_SNACKBAR, OPEN_SNACKBAR, RESET_SNACKBAR } from 'core/redux/types/uiTypes'

export default () => {
  const dispatch = useDispatch()
  const snackbar = useSelector((state: any) => state.ui.snackbar)
  const handleClose = (_: any, reason: string) => {
    if (reason === 'clickaway') return
    Promise.resolve()
      .then(() => dispatch({
        type: CLOSE_SNACKBAR
      }))
      .then(() => setTimeout(() => dispatch({
        type: RESET_SNACKBAR
      })))
      .then(timeout => clearTimeout(timeout))
  }

  const handleOpenSnackbar = ({
    message = snackbar.message,
    type = snackbar.type,
    autoHideDuration = snackbar.autoHideDuration,
    closeBtn = snackbar.closeBtn,
    placement = snackbar.placement,
    action = snackbar.action,
    actionText = snackbar.actionText
  }) => dispatch({
    type: OPEN_SNACKBAR,
    payload: {
      message,
      type,
      autoHideDuration,
      closeBtn,
      placement,
      actionText,
      action
    }
  })

  return {
    handleOpenSnackbar,
    handleClose,
    ...snackbar
  }
}