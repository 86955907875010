import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  fontTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#212121'
  },
  fontResultado: {
    fontSize: '18px',
  },
  display: {
    display: 'flex',
  },
  colorHr: {
    borderColor: '#ccc'
  },
  dataGrid: {
    border: 'none',
    "& .MuiDataGrid-columnSeparator" :{
      display: 'none',
    },
    "& .MuiDataGrid-colCellTitle" :{
      color: '#AFAFAF',
    },
    "& .MuiDataGrid-cell:focus" :{
      outline: 'none'
    },
    "& .MuiDataGrid-cell:focus-within" :{
      outline: 'none',
      outlineOffset: 'none'
    },
    "& .MuiDataGrid-row.Mui-selected" :{
      backgroundColor: 'transparent'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14
    }
  },
  textAling: {
    display: 'flex !important',
    justifyContent: 'center !important',
  },
  fontButton: {
    fontWeight: 'bold'
  },
  fontTitleGrid: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#767676',
  } ,
  fontContent: {
    color: '#AFAFAF',
    fontSize: '16px',
  }, 
  spacing:{
    marginLeft: '50px'
  },
  sizeImage: {
    width: '220px',
    height: '156px'
  },
  fontUppercase: {
    textTransform: 'uppercase',
  },
  colorNoRow:{
    backgroundColor: 'transparent !important',
  },
  fontNoRow: {
    fontWeight: 'bold',
    fontSize: '22px',
    color: '#212121'
  },
  fontContentNoRow: {
    color: '#AFAFAF',
    fontSize: '14px',
  }, 
  containerFooter:{
    display: 'flex',
  },
  contentPagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  loadingDialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '25px',
      alignItems: 'center',
      padding: '30px',
      background: 'transparent',
      boxShadow: 'none',
    }
  },
  chipVer:{
    backgroundColor: '#767676',
    color: 'white',
  },
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& :hover":{
      color: '#fff'
    }
  },
  fontDocument: {
    fontWeight: 'bolder',
    marginRight: '5px'
  },
  TabfontText:{
    color: '#AFAFAF',
    textTransform: 'capitalize',
    fontSize: '14px'
  },
  loadingColor: {
    color: '#767676',
  },
  heightContainer:{
    '& .MuiDataGrid-root.MuiDataGrid-autoHeight':{
      height: '200px'
    }
  },
  notDataContainer:{
    '& .MuiDataGrid-columnsContainer':{
      display: 'none'
    }
  },
}));

export { useStyles as default }