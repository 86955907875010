import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    borderRadius: '20px',
    "& .MuiCardHeader-root":{
      padding: "15px 15px 0px 0px"
    },
  },

  cardItem: {
    // width: 186,
    // height: 264,
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      },
    },
  },
  cardActionArea: {
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: '#767676',
    fontWeight: 'bolder',
  },
  subtitle: {
    color: '#AFAFAF',
  },
  roleIcon: {
    // fontSize: 60,
    color: '#767676',
    margin: '0px 20px',
  },
  cardSelected: {
    backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      }
  },

}));

export { useStyles as default }