import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

interface ResultsProps {
  number: number;
}

const Results = ({ number }: ResultsProps) => {
  const classes = useStyles();
  const numberResult = (number: number) => {
    if (!number) return '00 ';
    else if (number < 10) return `0${number} `;
    return `${number} `;
  };

  return (
    <Typography className={classes.result}>
      <i className="icon-document" />
      {numberResult(number)}
      Resultados
    </Typography>
  );
};

export default Results;
