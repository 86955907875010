import { gql } from "apollo-boost";

export const SEARCH_USER = gql`
  query($user: String!){
   users(filter: $user) {
    idUsuario
    nombresCompletos
    imagenPerfil
    razonSocial
    documento
    tiposUsuario {
      id
      name
    }
    estadoInvitacion{
      id
      descripcion
    }
    fechaRespuesta
  }
}
  
`;