import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px',
      padding: '15px'
    }
  },
  avatarTitle:{
    backgroundColor: '#6AC759'
  },
  divContainerTitle : {
    backgroundColor: '#F2F2F2',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '230px',
    justifyContent: 'center',
    height: '34px'
  },
  divContainerTitle2 : {
    backgroundColor: '#F2F2F2',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    justifyContent: 'center',
    height: '34px'
  },
  dataTitle:{
    marginBottom: 0,
    color: '#767676',
    fontWeight: 'bolder',
  },
  titleContainer: {
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    color: '#121212',
  },
  fontTitleContainer: {
    fontSize:'17px'
  },
  gridItemContainer:{
    '& .MuiGrid-align-items-xs-center':{
      justifyContent: 'center'
    }
  },
  gridContainer:{
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '110px',
    marginBottom: '20px',
    marginTop:'20px'
  },
  gridContainerR:{
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '110px',
  },
  tableCellHeader:{
    '& .MuiTableCell-head':{
      width: '100px'
    }
  },
  tableCellHead:{
    borderBottom: 'none',
    color: 'black',
    fontWeight:'normal',
    backgroundColor: '#f2f2f2',
    marginLeft: '10px'
  },
  tableCell:{
    borderBottom: 'none',
  },
  tableCellData:{
    borderBottom: 'none',
    color: '#767676'
  },
  tableRow:{
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  fontTitleInfo:{
    color: '#AFAFAF', 
    fontWeight: 'bolder'
  },
  fontResponsable:{
    color: '#AFAFAF',
    fontWeight: 'bolder',
    fontSize: '16px',
    marginTop: '10px',
  },
  

}));

export { useStyles as default }