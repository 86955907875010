import { Document, Image, Page, View, Font, Text } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import dayjs from 'dayjs';
import _ from 'lodash';

import destiny from 'static/img/reportsIcons/destiny';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';
import transport from 'static/img/reportsIcons/transport';
import checkIcon from 'static/img/reportsIcons/checkIcon';
import truckLogo from 'static/img/reportsIcons/truckLogo';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import TableInfo from 'components/pdf-components/TableInfo/index';
import Subtitle from 'components/pdf-components/Subtitle/index';
import NumberTotal from 'components/pdf-components/NumberTotal/index';
import Footer from 'components/pdf-components/Footer/index';

import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import ItemTotal from 'components/pdf-components/ItemTotal/index';
import ListData from 'components/pdf-components/ListData';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF/index';
import ChartList from 'components/pdf-components/ChartList/index';
import Paginationpdf from 'components/pdf-components/Pagination/index';

import styles from './styles/lote';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const item2 = [2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1];
const item3 = [2, 1, 2, 1, 2, 1];

interface MyDocSacrificeProps {
  qr: string;
  graph: string;
}

export const MyDocSacrifice = ({ qr, graph }: MyDocSacrificeProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={6700}
        reportImage={transport}
        number="023456789"
        title="Certificado de Sacrificio y Transporte"
        code="S T-001"
      />
      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={destiny}
          title="Destino"
          items={{
            Nombre: 'Nombre Destino',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Resumen Certificado de Sacrificio y Transporte"
          widthContainer="100%"
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          marginTop: '5px',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: '63.3%' }}>
          <View
            style={{
              height: '80px',
              backgroundColor: '#ededed',
              marginTop: '5px',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <View style={{ width: '31.2%' }}>
              <View style={{ marginLeft: '10px' }}>
                <ItemTotalCircle
                  value="45"
                  info="Total Canales Despachadas"
                  widthCircle={35}
                  backgroundColor="#b0413e"
                />
              </View>
            </View>
            <View style={{ width: '31.2%' }}>
              <View style={{ marginBottom: '10px' }}>
                <ItemTotal
                  value="46,03 %"
                  info="MC %"
                  backgroundColor="#b0413e"
                  heightValue={15}
                  fontSizeValue={10}
                  widthInfo="30px"
                />
              </View>
              <View style={{ marginBottom: '10px' }}>
                <ItemTotal
                  value="20,08 m.m"
                  info="GD m.m."
                  backgroundColor="#d47d57"
                  heightValue={15}
                  fontSizeValue={10}
                  widthInfo="30px"
                />
              </View>
              <ItemTotal
                value="70,08 m.m"
                info="LM m.m."
                backgroundColor="#e29662"
                heightValue={15}
                fontSizeValue={10}
                widthInfo="30px"
              />
            </View>
            <View style={{ width: '31.2%' }}>
              <View style={{ marginBottom: '10px' }}>
                <ItemTotal
                  value="85,66 %"
                  info="RCCL %"
                  backgroundColor="#f1ae6c"
                  heightValue={15}
                  fontSizeValue={10}
                  widthInfo="25px"
                />
              </View>
              <ItemTotal
                value="16,08 %"
                info="RCRL %"
                backgroundColor="#ffc676"
                heightValue={15}
                fontSizeValue={10}
                widthInfo="25px"
              />
            </View>
          </View>

          <View
            style={{
              height: '15px',
              backgroundColor: '#ededed',
              marginTop: '5px',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <View style={{ width: '31.3%' }}>
              <Text>Pesados</Text>
            </View>
            <View style={{ width: '31.3%' }}>
              <Text>Totales</Text>
            </View>
            <View style={{ width: '31.3%' }}>
              <Text>Promedios</Text>
            </View>
          </View>

          <View
            style={{
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '90%',
            }}
          >
            <View
              style={{
                width: '31.2%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotal
                value="45"
                info="PPB"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                position="left"
              />
              <ItemTotal
                value="45"
                info="PCC"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                position="left"
              />
              <ItemTotal
                value="45"
                info="PCR"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                position="left"
              />
            </View>

            <View
              style={{
                width: '31.2%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotal
                value="7.009,00"
                info="PPB (Kg)"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                position="left"
              />
              <ItemTotal
                value="6.003,90"
                info="PCC (Kg)"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                position="left"
              />
              <ItemTotal
                value="5.859,70"
                info="PCR (Kg)"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                position="left"
              />
            </View>

            <View style={{ width: '31.2%' }}>
              <ItemTotal
                value="155,76"
                info="PPB (Kg)"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                position="left"
              />
              <ItemTotal
                value="133,42"
                info="PCC (Kg)"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                position="left"
              />
              <ItemTotal
                value="130,22"
                info="PCR (Kg)"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                position="left"
              />
            </View>
          </View>
        </View>

        <View style={{ width: '33.3%', marginTop: '5px' }}>
          <TableInfo
            icon={truckLogo}
            title="Detalles del Transporte"
            widthContainer="100%"
            widthTitle="40%"
            widthItem="60%"
            items={{
              Conductor: 'Fernando Lopez Contreras',
              Documento: '2546264343',
              Vehículo: 'Toyota',
              Modelo: '65-G',
              Placa: 'ERW 753',
              'Fecha Llegada Planta': '22/02/2020 12:00:00',
              'Fecha Salida Planta': '22/02/2020 12:00:00',
              'Inicio Embarque': '22/02/2020 12:00:00',
              'Fin Embarque': '22/02/2020 12:00:00',
            }}
          />
        </View>
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle title="Resumen Decomisos" widthContainer="100%" />
      </View>

      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          marginTop: '5px',
        }}
      >
        <View style={{ width: '26.5%', alignItems: 'center' }}>
          <ItemTotalCircle
            value="2"
            info="Total Canales Completas Decomisadas"
            widthCircle={35}
            widthInfo={80}
            textAlign="left"
            backgroundColor="#b0413e"
          />
        </View>
        <View style={{ width: '26.5%', alignItems: 'center' }}>
          <ItemTotalCircle
            value="2"
            info="Total Canales Completas Decomisadas (LB)"
            widthCircle={25}
            widthInfo={80}
            textAlign="left"
            backgroundColor="#f1ae6c"
          />
        </View>
        <View style={{ width: '26.5%', alignItems: 'center' }}>
          <ItemTotalCircle
            value="45"
            info="Total Canales Completas Decomisadas (SBE)"
            widthCircle={25}
            widthInfo={80}
            textAlign="left"
            backgroundColor="#ffc676"
          />
        </View>
        <View style={{ width: '23%', alignItems: 'center' }}>
          <NumberTotal
            value="216,00 Kg"
            info="Total Carne Decomisada (Kgs)"
            position="bottom"
            justifyInfo="center"
            widthValue={70}
            widthInfo={90}
          />
        </View>
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Resumen Inspección Veterinaria Post-Mortem"
          widthContainer="100%"
        />
      </View>

      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          marginTop: '5px',
        }}
      >
        <View
          style={{
            height: '140px',
            backgroundColor: '#ededed',
            marginTop: '5px',
            justifyContent: 'center',
            width: '53.3%',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                height: '120px',
                width: '50%',
                marginTop: 10,
                marginBottom: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                src={graph}
                style={{ height: '150px', width: '150px', marginLeft: 15 }}
              />
            </View>

            <View
              style={{ justifyContent: 'center', width: '60%', marginLeft: 10 }}
            >
              <View style={{ width: '80%' }}>
                <Text style={{ fontWeight: 'bold' }}>Partes Decomisadas</Text>
              </View>
              <ChartList
                data={[
                  {
                    label: 'Pulmón',
                    data: 101,
                    bg: '#ffc676',
                  },
                  {
                    label: 'Piel',
                    data: 6,
                    bg: '#e29662',
                  },
                  {
                    label: 'Carne',
                    data: 6,
                    bg: '#d47d57',
                  },
                  {
                    label: 'Visceras Blancas',
                    data: 2,
                    bg: '#be5948',
                  },
                  {
                    label: 'Visceras Rojas',
                    data: 2,
                    bg: '#b0413e',
                  },
                ]}
              />
            </View>
          </View>
        </View>
        <View
          style={{
            height: '140px',
            marginTop: '5px',
            width: '43.3%',
          }}
        >
          <View style={{ marginBottom: 20 }}>
            <ListData
              widthContainer="100%"
              items={{
                'Nombre Inspector': 'Julian David Mejia Ramirez',
                'Nro. Documento': '415124312',
                'Nro. Matrícula': '234124123',
              }}
            />
          </View>
          <Text>Firma</Text>
        </View>
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Características Adicionales Lote Despachado"
          widthContainer="100%"
        />
      </View>

      <View
        style={{
          marginTop: '30px',
          alignItems: 'center',
        }}
      >
        <Subtitle
          title="Este Lote no tiene registrados características adicionales."
          heightContainer="24px"
          widthContainer="60%"
        />
      </View>

      <View style={{ marginTop: '24px' }}>
        <Subtitle title="Detalle del Lote" widthContainer="100%" />
      </View>

      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'right',
          marginTop: '5px',
        }}
      >
        <View
          style={{
            width: '60%',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '60%',
              marginRight: '15px',
              height: '35',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginTop: '5px',
            }}
          >
            <Text>
              El proceso de medición se estableció con la utilización del equipo
              <Text style={{ fontWeight: 'bold' }}> HGS GP4</Text> y aplicando
              la siguiente fórmula:
            </Text>
          </View>
          <View
            style={{
              height: '35',
              backgroundColor: '#b0413e',
              marginTop: '5px',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '45%',
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                borderRadius: 100,
                width: 25,
                height: 25,
                backgroundColor: 'white',
                marginRight: '10px',
              }}
            >
              <Image src={checkIcon} />
            </View>
            <View style={{ width: '65%' }}>
              <Text style={{ color: 'white', fontWeight: 'bold' }}>
                Fórmula Estimación Magro PorkColombia 2012 (P1)
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={{ width: '100%' }}>
        <View style={[styles.tableMagro, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '10px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote IP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canal ID</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>SEUROP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>PCC (Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>PCR (Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Temp (°C)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Gd (M.M)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>LM (M.M)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>MC (%)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>PCM (Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Indicio (PSE)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 43, width: 43 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Inspección Veterinaria
              </Text>
            </View>
          </View>
          {item2.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  306001
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 45.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  30600101
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>E</Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  100,2
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  138,22
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  29,22
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  11,60
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  70,60
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  70,60
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  70,60
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>-</Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 46.25,
                width: 46.25,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 92.5, width: 92.5, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              100,2
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              138,22
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              29,22
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              11,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              70,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              70,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              70,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 46.25, width: 46.25, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              36
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View
          style={{
            width: '25.2%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ItemTotal
            value="44"
            info="Total Canales"
            backgroundColor="#ededed"
            fontColor="#373737"
          />
        </View>
        <View
          style={{
            width: '33.2%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View style={{ width: '30%', marginRight: '5px' }}>
            <Text style={{ fontWeight: 'bold' }}>Totales y Promedios</Text>
          </View>
          <View>
            <View style={{ marginBottom: '10px' }}>
              <ItemTotal
                value="5.232,80"
                info="PCC (Kg)"
                backgroundColor="#ededed"
                fontColor="#373737"
                heightValue={15}
                fontSizeValue={8}
                widthInfo="60px"
                alignFontInfo="left"
              />
            </View>
            <ItemTotal
              value="98,9"
              info="PCC Prom. (Kg)"
              backgroundColor="#ededed"
              fontColor="#373737"
              heightValue={15}
              fontSizeValue={8}
              widthInfo="60px"
              alignFontInfo="left"
            />
          </View>
        </View>
        <View
          style={{
            width: '33.2%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View style={{ marginBottom: '10px' }}>
            <ItemTotal
              value="5.092,80"
              info="PCR (Kg)"
              backgroundColor="#ededed"
              fontColor="#373737"
              heightValue={15}
              fontSizeValue={8}
              widthInfo="60px"
              alignFontInfo="left"
            />
          </View>
          <ItemTotal
            value="96,3"
            info="PCR Prom. (Kg)"
            backgroundColor="#ededed"
            fontColor="#373737"
            heightValue={15}
            fontSizeValue={8}
            widthInfo="60px"
            alignFontInfo="left"
          />
        </View>
      </View>

      <View
        style={{
          marginTop: '10px',
          width: '100%',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Subtitle title="Canales con Novedades" widthContainer="100%" />
        <View
          style={{
            width: '82%',
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          <Text>
            Las canales con novedades son separadas del listado general, para
            informar que no fueron pesadas en la báscula de animales en pie en
            el ingreso a corrales, ya que se recibieron con alguna novedad
            (agitados o caídos, sin registro de PCC, o sin registro de PCR).
            Dichas canales no se tienen en cuenta para el cálculo de los
            rendimientos.
          </Text>
        </View>
      </View>

      <View style={{ width: '100%' }}>
        <View style={[styles.tableMagro, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '10px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote IP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canal ID</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>SEUROP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>PCC (Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>PCR (Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Temp (°C)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Gd (M.M)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>LM (M.M)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>MC (%)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>PCM (Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Indicio (PSE)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 43, width: 43 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Inspección Veterinaria
              </Text>
            </View>
          </View>
          {item3.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  306001
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 45.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  30600101
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>E</Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  100,2
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  138,22
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  29,22
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  11,60
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  70,60
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  70,60
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  70,60
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>-</Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 46.25, width: 46.25 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View
          style={{
            width: '25.2%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ItemTotal
            value="1"
            info="Total Canales"
            backgroundColor="#ededed"
            fontColor="#373737"
          />
        </View>
        <View
          style={{
            width: '33.2%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View style={{ width: '30%', marginRight: '5px' }}>
            <Text style={{ fontWeight: 'bold' }}>Totales y Promedios</Text>
          </View>
          <View>
            <View style={{ marginBottom: '10px' }}>
              <ItemTotal
                value="-"
                info="PCC (Kg)"
                backgroundColor="#ededed"
                fontColor="#373737"
                heightValue={15}
                fontSizeValue={8}
                widthInfo="60px"
                alignFontInfo="left"
              />
            </View>
            <ItemTotal
              value="98,9"
              info="PCC Prom. (Kg)"
              backgroundColor="#ededed"
              fontColor="#373737"
              heightValue={15}
              fontSizeValue={8}
              widthInfo="60px"
              alignFontInfo="left"
            />
          </View>
        </View>
        <View
          style={{
            width: '33.2%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View style={{ marginBottom: '10px' }}>
            <ItemTotal
              value="-"
              info="PCR (Kg)"
              backgroundColor="#ededed"
              fontColor="#373737"
              heightValue={15}
              fontSizeValue={8}
              widthInfo="60px"
              alignFontInfo="left"
            />
          </View>
          <ItemTotal
            value="96,3"
            info="PCR Prom. (Kg)"
            backgroundColor="#ededed"
            fontColor="#373737"
            heightValue={15}
            fontSizeValue={8}
            widthInfo="60px"
            alignFontInfo="left"
          />
        </View>
      </View>

      <View
        style={{
          marginTop: '10px',
          width: '100%',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Subtitle title="Observaciones - Sin Peso" widthContainer="100%" />
        <View
          style={{
            width: '60%',
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          <Text>
            En esta sección se visualiza el total de animales o canales sin
            registro de peso.
          </Text>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          marginTop: '10px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View style={{ width: '30%', alignItems: 'center' }}>
          <ItemTotal
            value="1"
            info="Animales en Pie"
            backgroundColor="#ededed"
            fontColor="#373737"
            heightValue={15}
            fontSizeValue={8}
            widthInfo="60px"
            widthValue={40}
          />
        </View>
        <View style={{ width: '30%', alignItems: 'center' }}>
          <ItemTotal
            value="0"
            info="Canales Calientes"
            backgroundColor="#ededed"
            fontColor="#373737"
            heightValue={15}
            fontSizeValue={8}
            widthInfo="60px"
            widthValue={40}
          />
        </View>
        <View style={{ width: '30%', alignItems: 'center' }}>
          <ItemTotal
            value="0"
            info="Canales Refrigeradas"
            backgroundColor="#ededed"
            fontColor="#373737"
            heightValue={15}
            fontSizeValue={8}
            widthInfo="70px"
            widthValue={40}
          />
        </View>
      </View>

      <View
        style={{
          marginTop: '10px',
          width: '100%',
        }}
      >
        <Subtitle title="Observaciones de Despacho" widthContainer="100%" />
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View style={{ width: '31.2%' }}>
          <View>
            <View style={{ marginBottom: '10px' }}>
              <ItemTotal
                value="0"
                info="Total Vísceras Despachadas"
                backgroundColor="#ededed"
                fontColor="#373737"
                heightValue={15}
                fontSizeValue={8}
                widthInfo="90px"
                alignFontInfo="left"
              />
            </View>
            <ItemTotal
              value="0"
              info="Total Esparrancadores"
              backgroundColor="#ededed"
              fontColor="#373737"
              heightValue={15}
              fontSizeValue={8}
              widthInfo="90px"
              alignFontInfo="left"
            />
          </View>
        </View>
        <View style={{ width: '31.2%' }}>
          <View>
            <View style={{ marginBottom: '10px' }}>
              <ItemTotal
                value="5°"
                info="Temperatura del Camión"
                backgroundColor="#ededed"
                fontColor="#373737"
                heightValue={15}
                fontSizeValue={8}
                widthInfo="90px"
                alignFontInfo="left"
              />
            </View>
            <ItemTotal
              value="0"
              info="Total Poleas Camión"
              backgroundColor="#ededed"
              fontColor="#373737"
              heightValue={15}
              fontSizeValue={8}
              widthInfo="90px"
              alignFontInfo="left"
            />
          </View>
        </View>
        <View style={{ width: '31.2%' }}>
          <ItemTotal
            value="N / D"
            info="Sello de Seguridad Camión"
            backgroundColor="#ededed"
            fontColor="#373737"
            heightValue={15}
            fontSizeValue={8}
            widthInfo="90px"
            alignFontInfo="left"
          />
        </View>
      </View>

      <View
        style={{
          marginTop: '10px',
          width: '100%',
        }}
      >
        <Subtitle title="Observaciones" widthContainer="100%" />
        <View
          style={{
            marginTop: '5px',
            height: '65px',
            width: '100%',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: '#ededed',
          }}
        >
          <Text style={{ fontWeight: 'bold' }}>
            Responsable: <Text style={{ fontWeight: 'normal' }}>Luis Diaz</Text>
          </Text>
        </View>
      </View>

      <View
        style={{
          height: '90px',
          justifyContent: 'center',
        }}
      >
        <View style={{ height: '40px', justifyContent: 'center' }}>
          <Text>Firma</Text>
        </View>
        <Text>Miguel Angel Rondal Bedoya</Text>
        <Text>C.C. 214347675</Text>
        <Text style={{ fontWeight: 'bold' }}>Responsable de Despacho</Text>
      </View>

      <View style={styles.conventions} wrap={false}>
        <View style={styles.backgroundST}>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <View style={{ width: '31.2%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PPB
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Peso Porcentaje Báscula</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PCC
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Peso Canal Caliente</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PCR
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Peso Canal Refrigerada</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PMC
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Peso Magro Canal</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PPE
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Peso Pie Estimado</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '31.2%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * LM (m.m)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Profundidad del Lomo</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * GD (m.m)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Grasa Dorsal</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * MC (%)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Porcentaje de Magro Canal</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * RCCL (%)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Rendimiento Canal Caliente</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * RCRL (%)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Rendimiento Canal Refrigerada Lote</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '33.2%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PSE
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Pálido, Suave, Exudativo</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (*)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Si tiene PSE</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (.)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>No tiene indicio PSE</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (?)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Probable PSE</Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: '5px',
              marginLeft: '10px',
            }}
          >
            <View style={styles.textconventions}>
              <View style={styles.gmagroS}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * Clasificación Canal
                </Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * S
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Superior</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * E
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Excelente</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * U
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Muy Buena</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * R
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Menos Buena</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * O
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Aceptable</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * P
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Pobre</Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              marginBottom: '5px',
              marginLeft: '10px',
              alignItems: 'center',
            }}
          >
            <View style={{ width: '31.2%', flexDirection: 'row' }}>
              <View style={styles.ST}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * LB
                </Text>
              </View>
              <View style={styles.textSTShort}>
                <Text>Línea de Beneficio</Text>
              </View>
            </View>

            <View style={{ width: '31.2%', flexDirection: 'row' }}>
              <View style={styles.ST}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * SBE
                </Text>
              </View>
              <View style={styles.textST}>
                <Text>Sala Beneficio de Emergencia</Text>
              </View>
            </View>

            <View style={{ width: '31.2%', flexDirection: 'row' }}>
              <View style={styles.ST}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * N.D.
                </Text>
              </View>
              <View style={styles.textSTShort}>
                <Text>No Disponible</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.borderST}>
          <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
            Convenciones
          </Text>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screenst" style={{ width: 500 }}>
      <QRCode
        size={500}
        value="Reporte por Lote Sacrificio y Transporte"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphSacrifice = () => {
  return (
    <div id="graphsacrifice" style={{ width: 500 }}>
      <Graphicpdfsf
        textTotalData="Partes Decomisadas"
        topTextTotalData={-440}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        data={[
          {
            label: 'Pulmón',
            data: 101,
            bg: '#ffc676',
          },
          {
            label: 'Piel',
            data: 6,
            bg: '#e29662',
          },
          {
            label: 'Carne',
            data: 6,
            bg: '#d47d57',
          },
          {
            label: 'Visceras Blancas',
            data: 2,
            bg: '#be5948',
          },
          {
            label: 'Visceras Rojas',
            data: 2,
            bg: '#b0413e',
          },
        ]}
      />
    </div>
  );
};
