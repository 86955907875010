import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthLg": {
      borderRadius: '20px',
    }
  },
  fontName:{
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  gridButton: {
    justifyContent: 'flex-end', 
    display: 'flex'
  },
  cardGridContainer:{
    padding: '0px 20px'
  }
  
}));

export { useStyles as default }