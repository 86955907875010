import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CardActionArea,
  Card,
  Grid,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Avatar,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import ExistingUser from 'components/ExistingUser';
import Slider from 'components/Slider';
import SelectDate from 'components/SelectDate';
import MainTitle from 'components/MainTitle';

import reportsIcons from 'lib/helpers/reportsIcons';
import dayjs from 'dayjs';

import useStyles from './styles';

interface ShareReportsOfLotProps {
  children: any;
}

const ShareReportsOfLot = ({ children }: ShareReportsOfLotProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [initialDate, setInitialDate] = useState(dayjs());
  const [finalDate, setFinalDate] = useState(dayjs());
  const [activeStep, setActiveStep] = useState(0);
  const [usersSelected, setUsersSelected] = useState<any>([]);
  const [reportsSelected, setReportsSelected] = useState<any>([]);
  const [shareUnlimited, setShareUnlimited] = useState(false);
  const [openResumen, setOpenResumen] = useState(false);

  const steps = [
    'Selección de usuarios',
    'Selección de reportes',
    'Confirmación de datos',
  ];

  const handleNext = () => {
    activeStep <= steps.length - 1 ? setActiveStep(activeStep + 1) : null;
  };

  const handleBack = () => {
    activeStep > 0 ? setActiveStep(activeStep - 1) : null;
  };

  const usersToShare = [
    {
      name: 'Leidy Marcela Álvarez',
      info: 'CC 1000295214',
      id: 4,
      profile: 'Comercializador',
    },
    {
      name: 'Juan Sebastian Izquierdo',
      info: 'CC 154782220',
      id: 5,
      profile: 'Productor',
    },
    {
      name: 'Sebastian Mosquera',
      info: 'CC 121478510',
      id: 6,
      profile: 'Comercializador',
    },
    {
      name: 'Paula Andrea Bolivar',
      info: 'CC 19876521',
      id: 7,
      profile: 'Productor - Comercializador',
    },
  ];

  const reports = [
    {
      reportType: 'Todos',
      state: true,
      id: 1,
    },
    {
      reportType: 'Portería',
      state: true,
      id: 2,
    },
    {
      reportType: 'Guias de Movilización',
      state: false,
      id: 3,
    },
    {
      reportType: 'Tiquete de Báscula',
      state: true,
      id: 4,
    },
    {
      reportType: 'Inmunocastración',
      state: false,
      id: 5,
    },
    {
      reportType: 'Antemortem',
      state: true,
      id: 6,
    },
    {
      reportType: 'Visceras Blancas',
      state: false,
      id: 7,
    },
    {
      reportType: 'Postmortem',
      state: true,
      id: 8,
    },
    {
      reportType: 'Evaluación de magro',
      state: true,
      id: 9,
    },
    {
      reportType: 'Distribución y Despacho',
      state: false,
      id: 10,
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  };

  const changeUsersSelected = (id: number) => {
    if (usersSelected.includes(id)) {
      const removed = usersSelected.filter((item: any) => item !== id);
      setUsersSelected(removed);
    } else {
      setUsersSelected([...usersSelected, id]);
    }
  };

  const changeReportsSelected = (report: number) => {
    if (report === 1) {
      if (reportsSelected.includes(1)) {
        setReportsSelected([]);
      } else {
        setReportsSelected(
          reports
            .filter((filter: any) => filter.state)
            .map((item: any) => item.id)
        );
      }
    } else if (reportsSelected.includes(report)) {
      const removed = reportsSelected.filter(
        (item: any) => item !== report && item !== 1
      );
      setReportsSelected(removed);
    } else {
      setReportsSelected([...reportsSelected, report]);
    }
  };

  const handleOpenResumen = () => {
    setOpenResumen(true);
    handleClose();
  };
  const handleCloseResumen = () => setOpenResumen(false);

  return (
    <>
      {children({ handleOpen })}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="body"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            Compartir Reportes del Lote IP{' '}
            <Typography variant="inherit" color="primary">
              2042
            </Typography>
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label: any) => (
              <Step key={label}>
                <StepLabel className={classes.steps}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <>
              <Box mt={2} mb={2}>
                <Typography color="secondary">
                  A continuación seleccione los usuarios a los cuales va a
                  compartir los reportes de este lote.
                </Typography>
              </Box>
              <Slider>
                {usersToShare.map((item: any) => {
                  return (
                    <ExistingUser
                      name={item.name}
                      info={item.info}
                      selected={usersSelected.includes(item.id)}
                      onClick={() => {
                        changeUsersSelected(item.id);
                      }}
                      column
                    />
                  );
                })}
                <Card className={classes.card}>
                  <CardActionArea className={classes.content}>
                    <i className="icon-plus" />
                  </CardActionArea>
                </Card>
              </Slider>
            </>
          ) : activeStep === 1 ? (
            <>
              <Box marginY={2}>
                <Box mt={2} mb={2}>
                  <Typography color="secondary">
                    A continuación seleccione el o los reportes de este lote que
                    quiere compartir. Solo podrá compartir los reportes que
                    disponibles y creados hasta la fecha.
                  </Typography>
                </Box>

                <Grid container xs={12}>
                  {reports.map((item: any) => {
                    return (
                      <Grid xs={4} item>
                        <ExistingUser
                          name={item.reportType}
                          icon={reportsIcons(item.reportType)}
                          selected={reportsSelected.includes(item.id)}
                          onClick={() => {
                            changeReportsSelected(item.id);
                          }}
                          disabled={!item.state}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </>
          ) : (
            <>
              <Box mt={3}>
                <MainTitle>Usuarios A Compartir</MainTitle>
              </Box>
              <Box width="100%" margin="12px 0px">
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Documento</TableCell>
                      <TableCell>Perfil</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersToShare
                      .filter((item: any) => usersSelected.includes(item.id))
                      .map((item: any) => {
                        return (
                          <TableRow className={classes.itemGrey}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.info}</TableCell>
                            <TableCell>{item.profile}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>

              <Box mt={3}>
                <MainTitle>Reportes A Compartir</MainTitle>
              </Box>

              <Grid container xs={12}>
                {reports
                  .filter(
                    (item: any) =>
                      reportsSelected.includes(item.id) && item.id !== 1
                  )
                  .map((item: any) => {
                    return (
                      <Grid xs={4} item>
                        <Card className={classes.cardReports}>
                          <Avatar>
                            <i className={reportsIcons(item.reportType)} />
                          </Avatar>
                          <Typography>{item.reportType}</Typography>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
              <Box mt={3}>
                <MainTitle>Configuración Fecha limite de Acceso</MainTitle>
              </Box>
              <Typography className={classes.description}>
                A continuación tiene la opción de definir un tiempo limite para
                dar acceso a los usuarios seleccionados para ver el reporte, si
                no define un tiempo limite, el reporte se compartirá de manera
                indefinida.
              </Typography>
              <Box mt={3} mb={3}>
                <Box width="100%">
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={4}>
                      <SelectDate
                        value={initialDate}
                        label="Fecha Inicial"
                        onChange={(e: any) => setInitialDate(e)}
                        disabled={true}
                      />
                    </Grid>
                    <Grid container item xs={1} justifyContent="center">
                      Hasta
                    </Grid>
                    <Grid item xs={4}>
                      <SelectDate
                        label="Fecha Final"
                        onChange={(e: any) => setFinalDate(e)}
                        disabled={shareUnlimited}
                        limitDate={initialDate}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box marginTop={2} display="flex" justifyContent="center">
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        value={shareUnlimited}
                        name="promotion"
                        color="primary"
                        onChange={() => setShareUnlimited(!shareUnlimited)}
                      />
                    }
                    label="Compartir de manera indefinida"
                    labelPlacement="start"
                  />
                </Box>
              </Box>
            </>
          )}
          <DialogActions className={classes.dialogFooter}>
            <Button
              variant="contained"
              color="secondary"
              onClick={activeStep === 0 ? handleClose : handleBack}
            >
              {activeStep === 0 ? 'Cancelar' : 'Atrás'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={
                activeStep < steps.length - 1 ? handleNext : handleOpenResumen
              }
            >
              {activeStep < steps.length - 1 ? 'Siguiente' : 'Guardar'}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openResumen}
        onClose={handleCloseResumen}
        className={classes.dialogContainer}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box
              component="i"
              className={'icon-circle-check'}
              fontSize="60px"
              color="#DD9835"
            />
            <Typography variant="h6" color="primary">
              Los reportes han sido compartidos exitosamente
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <MainTitle>Reportes compartidos</MainTitle>
          <Box width="100%" my={2}>
            <Grid container xs={12}>
              {reports
                .filter(
                  (item: any) =>
                    reportsSelected.includes(item.id) && item.id !== 1
                )
                .map((item: any) => {
                  return (
                    <Grid xs={4} item>
                      <Card className={classes.cardReports}>
                        <Avatar>
                          <i className={reportsIcons(item.reportType)} />
                        </Avatar>
                        <Typography>{item.reportType}</Typography>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <MainTitle>Usuarios con Acceso</MainTitle>
          <Box width="100%" my={2}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Documento</TableCell>
                  <TableCell align="center">Compartido Por</TableCell>
                  <TableCell align="center">Compartido Desde</TableCell>
                  <TableCell align="center">Compartido Hasta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersToShare
                  .filter((item: any) => usersSelected.includes(item.id))
                  .map((item: any) => {
                    return (
                      <TableRow className={classes.itemGrey}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.info}</TableCell>
                        <TableCell align="center">{item.sharedBy}</TableCell>
                        <TableCell align="center">
                          {dayjs(initialDate).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="center">
                          {dayjs(finalDate).format('DD-MM-YYYY')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseResumen}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShareReportsOfLot;
