import { gql } from "apollo-boost";

export const ACTIVITY_USERS = gql`
  query ($uid: Int!, $fechaInicial: String!, $fechaFinal: String!, $idUserType: Int!) {
    getActivityUsers(input: {
  	Id: $uid,
    IdTipoUsuario: $idUserType,
    FechaInicial: $fechaInicial,
    FechaFinal: $fechaFinal
  }){
    data{
      idUsuario
      usuario
      rol
      fechaInicial
      fechaFinal
      tiempoTotal
      registro
    }
    token{
      tokenNuevo
      fechaExpiracion
    }
  }
}
`;