

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px',
      padding: '15px'
    }
  },
  avatarTitle:{
    backgroundColor: '#6AC759'
  },
  divContainerTitle : {
    backgroundColor: '#F2F2F2',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '230px',
    justifyContent: 'center',
    height: '34px'
  },
  divContainerTitle2 : {
    backgroundColor: '#F2F2F2',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    justifyContent: 'center',
    height: '34px'
  },
  dataTitle:{
    marginBottom: 0,
    color: '#767676',
    fontWeight: 'bolder',
  },
  titleContainer: {
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    color: '#121212',
  },
  fontTitleContainer: {
    fontSize:'17px'
  },
  gridContainer:{
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '110px',
    marginBottom: '20px',
    marginTop:'20px'
  },
  tableCell:{
    borderBottom: 'none',
  },
  tableCellData:{
    borderBottom: 'none',
    color: '#767676'
  },
  tableRow:{
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  mb:{
    marginBottom: '10px'
  },
  gridContainerGrapihc:{
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop:'20px'
  },
  content:{
    '& .MuiGrid-align-items-xs-center':{
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    }
  },
  labelGraphic:{
    width: '120%'
  },
  itemInfoLabel:{
    fontSize: '17px',
    color: '#AFAFAF',
    fontWeight: 'bold',
    width: '150px'
  },
  fontTitleInfo:{
    color: '#AFAFAF', 
    fontWeight: 'bolder'
  },
  fontTime:{
    color: '#AFAFAF',
    fontWeight: 'bolder',
    fontSize: '16px',
    maxWidth: '150px',
  },
  fontShipping:{
    color: '#AFAFAF',
    fontWeight: 'bolder',
    fontSize: '16px',
    maxWidth: '150px',
    textAlign: 'center',
  },
  titleConfiscation:{
    color: '#AFAFAF',
    fontWeight: 'bolder',
    fontSize: '15px',
    maxWidth: '150px',
  },
  dataConfiscation:{
    fontWeight: 'bold',
    fontSize: '25px',
    color: '#DD9835',
    marginRight: '10px',
  },
  fontResponsable:{
    color: '#AFAFAF',
    fontWeight: 'bolder',
    fontSize: '15px',
    maxWidth: '180px',
  },
  dataResponsable:{
    fontSize: '15px', 
    color: '#767676'
  },
  fontDetail:{
    color: '#AFAFAF',
    fontWeight: 'bolder',
    fontSize: '16px',
    marginTop: '10px',
  },
  titleDataMeasurement:{
    fontSize: '15px',
    fontWeight: 'bolder',
    color: '#AFAFAF',
  },
  SubtitleDataMeasurement:{
    fontSize: '20px',
    fontWeight: 'bolder',
    color: '#DD9835',
  },
  iconPigGridContainer:{
    marginBottom: '15px',
    width: '220px'
  },
  li:{
    color: '#FFC676', 
    fontSize: '16px'
  },
  span:{
    color: '#AFAFAF',
    fontWeight: 'bolder',
    fontSize: '16px',
    marginTop: '10px',
  },
  titleFeatures:{
    fontSize: '15px',
    marginLeft: '25px',
    color: '#767676'
  },
  titleGraphic:{
    color: '#AFAFAF',
    fontWeight: 'bolder',
    fontSize: '16px',
    marginTop: '10px',
  },
  gridWidth:{
    minWidth: '220px'
  },
  avatarColor:{
    background: '#DD9835'
  }

}));

export { useStyles as default }