import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';

import styles from './styles';

interface PaginationProps {
  uid?: string | number;
}

const Paginationpdf = ({ uid }: PaginationProps) => {
  return (
    <View
      style={{
        width: 611,
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        paddingBottom: 25,
        paddingHorizontal: 30,
        flexDirection: 'row',
      }}
      fixed
    >
      <Text style={[{ flex: 1, textAlign: 'left' }]}>
        Fecha de impresión: {dayjs().format('DD/MM/YYYY hh:mm:ss')}. uid: 299
      </Text>
      <Text
        style={[styles.textBold, { flex: 1, textAlign: 'right' }]}
        render={({ pageNumber, totalPages }) =>
          pageNumber > 1 && `Pág Nro. ${pageNumber} / ${totalPages}`
        }
        fixed
      />
    </View>
  );
};
export default Paginationpdf;
