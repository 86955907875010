import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  highlight: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  firstHighlight: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  endHighlight: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    '&:hover': {
      background: theme.palette.primary.main
    }
  }, 
  day: {
    background: 'transparent',
    boxShadow: 'none', 
  },
}))