import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

interface SingleChartValueProps {
  value: number;
  size: number;
}

export default ({ value, size, ...props }: SingleChartValueProps) => {
  const classes = useStyles();

  return (
    <Box position="relative" display="inline-flex">
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          size={size}
          thickness={5}
          className={classes.bottom}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="static"
          disableShrink
          className={classes.top}
          size={size}
          thickness={5}
          {...props}
          value={value}
        />
      </div>
      <Box
        top={0}
        bottom={0}
        left={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="subtitle2" component="div" color="textSecondary">
          {Number(value).toFixed(2)}%
        </Typography>
      </Box>
    </Box>
  );
};
