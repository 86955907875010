import styled, { css } from "styled-components";

import { shadowRegular } from "./_properties";

const primaryColor = "#DD9835";

const Container = styled.form`
  width: 100%;
  padding: 0;
  border: 0;
  margin: 0;
`;

const Label = styled.label<any>`
  text-transform: uppercase;
  font-weight: bold;
  ${({ textNormal }: { textNormal: boolean }) =>
    textNormal &&
    css`
      text-transform: none;
      font-weight: normal;
    `}
  font-size: 10px;
  padding: 0 15px;
  font-family: "Raleway", sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;

  input[type="checkbox"] + & {
    font-size: 11px;
    font-family: "Roboto", arial, serif;
    text-transform: unset;
    color: #07282c;
    font-weight: normal;
    padding: 0 5px;
    margin-bottom: 0;
  }
`;

const Control = styled.input`
  height: 40px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 12px;
  background-color: #ffffff;
  border: 1px #e2e2e2 solid;
  border-radius: 25px;
  padding: 0 20px;
  color: #b4b4b4;
  order: 1;
  transition: 0.3s ease;

  &:focus {
    outline: none;
    border: 1px ${primaryColor} solid;
    ${shadowRegular()}
    transition: .3s ease;
  }

  &[required] + ${Label}::after {
    content: "*";
    color: ${primaryColor};
    font-size: 15px;
    line-height: 10px;
  }

  &.has-error {
    border: 1px #ff7961 solid;
    color: #ff7961;
  }

  &.has-success {
    border: 1px #9ccc65 solid;
  }
`;

const Select = styled.select<any>`
  height: 40px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 12px;
  background-color: #ffffff;
  border: 1px #e2e2e2 solid;
  border-radius: 25px;
  padding: 0 20px;
  color: #b4b4b4;
  order: 1;
  transition: 0.3s ease;

  &[required] + ${Label}::after {
    content: "*";
    color: ${primaryColor};
    font-size: 15px;
    line-height: 10px;
  }

  &:focus {
    outline: none;
    color: #2a2a2a;
    border: 1px ${primaryColor} solid;
    ${shadowRegular()}
    transition: .3s ease;
  }

  &::-ms-expand {
    display: none;
  }
`;

const Option = styled.option`
  border: 1px 0 0 1px ${primaryColor} solid;
  position: absolute;
`;

const Group = styled.div<any>`
  width: 100%;
  min-height: 40px;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  ${({ horizontal }: { horizontal: boolean }) =>
    horizontal
      ? css`
          flex-direction: row;
          align-items: center;
        `
      : ""}
  justify-content: left;
`;

const GroupRow = styled.div`
  width: 100%;
  min-height: 40px;
  margin: 5px 0;
  display: flex;
  justify-content: ${(props: any) => (props.align ? props.align : "left")};
  align-items: center;
  box-sizing: border-box;
`;

const Append = styled.span`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 5px 5px 5px 10px;

  & > img {
    width: 25px;
    height: 25px;
    margin-top: -3px;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px #e2e2e2 solid;
  border-radius: 25px;
  height: 40px;
  transition: 0.3s;

  &:focus-within {
    ${shadowRegular()}
    border: 1px ${primaryColor} solid;
    transition: 0.3s;
  }

  &.has-error {
    border: 1px #ff7961 solid;
    color: #ff7961;
  }

  & > ${Control} {
    border: 0;
    width: 100%;
    height: 38px;
    padding-left: 0;
    border-radius: 0 25px 25px 0;
    background: transparent;

    &:focus {
      box-shadow: none;
    }
  }

  & > ${Select} {
    border: 0;
    width: 100%;
    padding-left: 0;
    background: transparent;

    &:focus {
      box-shadow: none;
    }
  }
`;

const Message = styled.small<any>`
  font-family: "Roboto", arial, serif;
  text-align: ${(props: any) => (props.alignText ? props.alignText : "left")};
  padding: 0 15px;
  color: #b4b4b4;
  text-overflow: ellipsis;
  overflow: hidden;
  order: 2;
  ${(props: any) => {
    let type = "";
    let multiline = "";
    if (props.error && props.hint) {
      return css`
        &::after {
          position: relative;
          float: left;
          background: red;
          content: "Error: unexpected type of message" !important;
          color: #fff;
        }
      `;
    }
    if (props.error) {
      type = "color: #ff7961";
    }
    if (!props.multiline) {
      multiline = "white-space: pre;";
    }

    return `
        ${type}
        ${multiline}
      `;
  }}
`;

const CheckBox = styled.input.attrs({
  type: "checkbox",
})`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
  }

  & + label:before {
    content: "";
    margin-right: 5px;
    display: inline-block;
    vertical-align: text-top;
    border-radius: 4px;
    border: 1px #e2e2e2 solid;
    width: 15px;
    height: 15px;
    background-color: #ffff;
    transition: 0.2s ease-in;
  }

  &:hover + label:before {
    background-color: #e2e2e2;
    transition: 0.2s ease-in;
  }

  &:focus + label:before {
    border: 1px #767676 solid;
    ${shadowRegular()}
    transition: .2s ease-in;
  }

  &:checked + label:before {
    background-color: #767676;
    border: 1px #767676 solid;
    transition: 0.1s ease-in;
  }

  &:disabled + label:before {
    box-shadow: none;
    background-color: #dddd;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 8px;
    background-color: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
    transition: 0.3s;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: ${({ direction }: { direction?: "row" | "column" }) =>
    direction === "column" ? "column" : "row"};
`;

const Radio = styled.input.attrs({
  type: "radio",
})`
  position: absolute;
  opacity: 0;

  & + ${Label} {
    font-family: "Roboto", Arial, serif;
    color: #767676;
    font-size: 13px;
    display: block;

    &::before {
      content: "";
      background: #ffffff;
      border-radius: 100%;
      border: 1px #767676 solid;
      display: inline-block;
      width: 1em;
      height: 1em;
      position: relative;
      bottom: -0.2em;
      margin-right: 0.5em;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
    }
  }

  &:checked {
    & + ${Label} {
      &:before {
        background-color: ${primaryColor};
        box-shadow: inset 0 0 0 2px #ffffff;
        border-color: ${primaryColor};
      }
    }
  }

  &:focus {
    & + ${Label} {
      &:before {
        outline: none;
        border-color: ${primaryColor};
      }
    }
  }

  &:disabled {
    + ${Label} {
      &:before {
        box-shadow: inset 0 0 0 2px ${primaryColor};
        border-color: darken(${primaryColor}, 25%);
        background: darken(${primaryColor}, 25%);
      }
    }
  }

  & + ${Label} {
    &:empty {
      &::before {
        margin-right: 0;
      }
    }
  }
`;

export const Form = {
  Container,
  Group,
  GroupRow,
  InputGroup,
  Label,
  Control,
  Select,
  Option,
  RadioGroup,
  Radio,
  Message,
  Append,
  CheckBox,
};
