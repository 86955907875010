import React from "react";

import { Box, Grid, Typography } from "@material-ui/core";

import MainTitle from "components/MainTitle";
import InvitationCard from "components/PageComponents/Invitations/InvitationCard";

import NewInvitations from "static/img/pigs/NewInvitations.svg";

import dayjs from "dayjs";

import useStyles from "./styles";

const Invitations = () => {
  const classes = useStyles();

  return (
    <>
      <Box>
        <Grid container spacing={2} xs={12} alignItems="center">
          <Grid item xs={6}>
            <img width="80%" src={NewInvitations} />
          </Grid>
          <Grid item xs={6}>
            <MainTitle>TIENES UNA INVITACIÓN PENDIENTE</MainTitle>
            <Typography color="secondary" style={{ marginTop: 8 }}>
              A continuación podrás gestionar la invitación a Infoporcinos,
              podrás aceptarla o rechazarla, al aceptarla podrás empezar a
              construir tu red de negocios.
            </Typography>
            <Box className={classes.container}>
              <InvitationCard
                name={"Leidy Marcela Álvarez Mejía"}
                username={"Leidyal20"}
                personType={"Persona"}
                invitationDate={dayjs("2020-04-20T14:04:01.537")}
                myRol={2}
                yourRol={1}
                onAccept={() => console.log("Invitación aceptada")}
                onDecline={() => console.log("Invitación rechazada")}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Invitations;
