import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  sidebar: {
    background: '#212121',
    width: 280
  },
  drawer: {
    width: 320,
    minHeight: '100vh',
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  'sidebar-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2),
    margin: theme.spacing(2.5, 0, 8.5, 0)
  },
  close: {
    color: 'white'
  },
  'user-info': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#454545',
    paddingBottom:  theme.spacing(3)
  },
  avatarContainer: {
    background: '#454545',
    padding: 8,
    borderRadius: '100%',
    marginTop: `-${theme.spacing(10/2) + 16}px`,
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    fontSize: theme.spacing(4),
  },
  names: {
    textAlign: 'center',
    padding: theme.spacing(0, 1)
  },
  name: {
    fontFamily: 'Roboto, arial',
    fontSize: 17
  },
  username: {
    color: theme.palette.primary.main
  },
  drawerFooter: {
    textAlign: 'center',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  },
  downBtn: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    borderWidth: 2,
    textTransform: 'inherit',
    borderRadius: 50,
    width: '70%',
  },
  logoutBtn: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    textTransform: 'inherit',
    borderRadius: 50,
    width: '70%'
  }
}))