import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    boxShadow: theme.shadows[3],
    margin: "0px 20px",
    borderRadius: 20,
    padding: "20px 4px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: "#767676",
    padding: 4,
    borderBottom: "1px solid #AFAFAF",
    fontWeight: "bold",
  },
  description: {
    margin: 12,
    color: "#767676",
    textAlign: "justify",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  filter: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  usersContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    margin: "12px 0px",
  },
  chip: {
    minWidth: 130,
    fontSize: 12,
  },
  subtitle: {
    color: "#767676",
    borderBottom: "1px solid #DD9835",
    fontWeight: "normal",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "12px 0px",
  },
  listItem: {
    paddingLeft: 0,
  },
  sponsored: {
    background: "#FFF4E5",
  },
  contentRow: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  gotopayCard: {
    width: 600,
    borderRadius: 20,
    boxShadow: theme.shadows[3],
    margin: "24px 12px",
  },
  gotopayContent: {
    padding: "12px 20px",
  },
  contetinfo: {
    display: "flex",
    justifyContent: "space-between",
    margin: "8px 0px",
    color: "#AFAFAF",
  },
  input: {
    margin: "8px 0",
  },
}));

export default useStyles;
