export default (id: any, type: number) => {
  if(type === 1){
    if (id === 1) return 'CC';
    else if (id === 2) return 'CE';
    else if (id === 3) return 'NIT';
    return 'PEP';
  }
  else {
    if (id === 1) return 'CÉDULA DE CIUDADANÍA';
    else if (id === 2) return 'CÉDULA DE EXTRANJERÍA';
    else if (id === 3) return 'NIT';
    return 'PERMISO ESPECIAL DE PERMANENCIA';
  }
};