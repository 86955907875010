import { Document, Image, Page, View, Font, Text } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import whitevisorsLogo from 'static/img/reportsIcons/whitevisorsLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import productorIcon from 'static/img/reportsIcons/productorIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';
import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import TableInfo from 'components/pdf-components/TableInfo/index';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotal from 'components/pdf-components/ItemTotal';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle/index';
import Footer from 'components/pdf-components/Footer';

import styles from './styles/lote';
import Graphicpdfsf from './../../../../../components/pdf-components/ChartPDFSF/index';
import Paginationpdf from './../../../../../components/pdf-components/Pagination';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const item = ['7441', '7441', '7441', '7441', '7441', '7441', '7441'];

interface MyDocViscerasProps {
  qr: string;
  graph: string;
}

export const MyDocVisceras = ({ qr, graph }: MyDocViscerasProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={6700}
        reportImage={whitevisorsLogo}
        number="023456789"
        title="Inspección Médico Veterinaria - Post Mortem
        Canales Visceras Blancas"
        code="VB-001"
      />

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={productorIcon}
          title="Productor"
          items={{
            Nombre: 'Nombre productor',
            NIT: '34123412-2',
            Teléfono: '+57 314 6543 543',
            Email: 'info@productor.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '10px' }}>
        <Subtitle title="Resumen del Proceso" widthContainer="100%" />
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          height: '90px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '50%',
          }}
        >
          <View
            style={{
              width: '50%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View style={{ marginBottom: '15px' }}>
              <ItemTotal
                value="820"
                info="Total Visceras Blancas"
                position="right"
                backgroundColor="#d47d57"
              />
            </View>
            <ItemTotalCircle
              value="56"
              info="Decomisados en Sala de Beneficio de Emergencia"
              widthInfo="80px"
              position="right"
              textAlign="left"
              backgroundColor="#e29662"
            />
          </View>
          <View
            style={{
              width: '50%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View style={{ marginBottom: '15px' }}>
              <ItemTotal
                value="34"
                info="Total Animales Lote"
                position="right"
                backgroundColor="#e29662"
              />
            </View>
            <ItemTotalCircle
              value="190"
              info="Decomisados en la Linea"
              position="right"
              textAlign="left"
              backgroundColor="#ffc676"
            />
          </View>
        </View>
        <View style={{ flexDirection: 'row', width: '50%' }}>
          <View
            style={{
              width: '100%',
              height: '100px',
              backgroundColor: '#ededed',
              flexDirection: 'row',
            }}
          >
            <View style={{ width: '46%' }}>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px !important',
                }}
              >
                <Image
                  src={graph}
                  style={{
                    height: '130px',
                    width: '130px',
                  }}
                />
              </View>
            </View>
            <View
              style={{
                width: '50%',
                marginTop: 20,
              }}
            >
              <View
                style={{
                  marginBottom: '10px',
                  flexDirection: 'row',
                  width: '10%',
                }}
              >
                <ItemTotal
                  value="574"
                  info="Total Visceras Aprobadas"
                  position="right"
                  backgroundColor="#d47d57"
                />
                <View style={{ justifyContent: 'center' }}>
                  <Text>70%</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row', width: '10%' }}>
                <ItemTotal
                  value="246"
                  info="Total Visceras Decomisadas"
                  position="right"
                  backgroundColor="#f1ae6c"
                />
                <View style={{ justifyContent: 'center' }}>
                  <Text>30%</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '10px' }}>
        <Subtitle title="Detalle del Reporte" widthContainer="100%" />
      </View>

      <View>
        <View style={[styles.table, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              { marginBottom: '5px', marginTop: '5px', alignItems: 'center' },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 100, width: 100 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canal ID</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Total Visceras
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Visceras Decomisadas
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Visceras Aprobadas
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 122, width: 122 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Observaciones
              </Text>
            </View>
          </View>
          {item.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 100, width: 100 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  {item}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  16
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
                <Text
                  style={
                    (styles.tableCellVisceras, { fontSize: 8, marginTop: 5 })
                  }
                >
                  6
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  10
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 122, width: 122 }]}>
                <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                  Observación
                </Text>
              </View>
            </View>
          ))}
          <View
            style={{ flexDirection: 'row', width: '100%', marginTop: '5px' }}
          >
            <View
              style={[
                styles.tableCol,
                {
                  maxWidth: 100,
                  width: 100,
                  backgroundColor: '#F69A35',
                  color: 'white',
                  justifyContent: 'center',
                },
              ]}
            >
              <Text
                style={[
                  styles.tableCellVisceras,
                  { fontSize: 9, fontWeight: 'bold' },
                ]}
              >
                Totales
              </Text>
            </View>
            <View
              style={[
                styles.tableCol,
                { maxWidth: 111, width: 111, backgroundColor: '#F2F2F2' },
              ]}
            >
              <Text
                style={[
                  styles.tableCellVisceras,
                  { fontSize: 8, fontWeight: 'bold' },
                ]}
              >
                224
              </Text>
            </View>
            <View
              style={[
                styles.tableCol,
                { maxWidth: 111, width: 111, backgroundColor: '#F2F2F2' },
              ]}
            >
              <Text
                style={[
                  styles.tableCellVisceras,
                  { fontSize: 8, fontWeight: 'bold' },
                ]}
              >
                84
              </Text>
            </View>
            <View
              style={[
                styles.tableCol,
                { maxWidth: 111, width: 111, backgroundColor: '#F2F2F2' },
              ]}
            >
              <Text
                style={[
                  styles.tableCellVisceras,
                  { fontSize: 8, fontWeight: 'bold' },
                ]}
              >
                140
              </Text>
            </View>
            <View
              style={[
                styles.tableCol,
                { maxWidth: 122, width: 122, backgroundColor: '#F2F2F2' },
              ]}
            ></View>
          </View>
        </View>
      </View>

      <View
        style={{
          height: '90px',
          justifyContent: 'center',
        }}
      >
        <View style={{ height: '40px', justifyContent: 'center' }}>
          <Text>Firma</Text>
        </View>
        <Text>Miguel Angel Rondal Bedoya</Text>
        <Text>C.C. 214347675</Text>
        <Text style={{ fontWeight: 'bold' }}>Responsable de Decomiso</Text>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screenLotVisceras" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte por Lote Visceras Blancas"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphVisceras = () => {
  return (
    <div id="graphvisceras" style={{ width: 500 }}>
      <Graphicpdfsf
        withoutTotalInfo
        textTotalData="Visceras Blancas"
        fontSizeTotal={60}
        colorTotal="#767676"
        topTextTotalData={-540}
        widthChart="900px"
        fontConnector="50px"
        startAngle={-1}
        endAngle={359}
        data={[
          {
            label: '',
            data: 70,
            bg: '#d47d57',
          },
          {
            label: '',
            data: 30,
            bg: '#f1ae6c',
          },
        ]}
      />
    </div>
  );
};
