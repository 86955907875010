import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Dashboard from "pages/Dashboard";
import Users from "pages/Users";
import Network from "pages/Network";
import Reports from "pages/Reports";
import Inventory from "pages/Inventory";
import Infotraking from "pages/Infotraking";
import Settings from "pages/Settings";
import Services from "pages/Services";
import Welcome from "pages/Welcome";
import NewProfile from "pages/NewProfile";

const GeneralRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/dashboard`} component={Dashboard} />
      <Route path={`${path}/welcome`} component={Welcome} exact />
      <Route path={`${path}/settings`} component={Settings} />
      <Route path={`${path}/services`} component={Services} />
      <Route path={`${path}/users`} component={Users} />
      <Route path={`${path}/profile`} component={NewProfile} />
      <Route path={`${path}/network`} component={Network} />
      <Route path={`${path}/reports`} component={Reports} />
      <Route path={`${path}/inventory`} component={Inventory} />
      <Route path={`${path}/infotraking`} component={Infotraking} exact />
      <Redirect from="*" to="/404" />
    </Switch>
  );
};

export default GeneralRoutes;
