import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

type DialogNotificationProps = {
  icon: 'alert' | 'success' | 'error' | String;
  title: String;
  message: String;
  open: any;
  handleClose: any;
};

const DialogNotification = ({
  icon,
  open,
  title,
  message,
  handleClose,
}: DialogNotificationProps) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      className={classes.borderRadiudDialog}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        {icon === 'alert' && (
          <Grid className={classes.icon}>
            <i
              className="icon-circle-alert"
              style={{ fontSize: '70px', color: '#767676' }}
            ></i>
          </Grid>
        )}
        {icon === 'success' && (
          <Grid className={classes.icon}>
            <i className="icon-circle-check" style={{ fontSize: '70px' }}></i>
          </Grid>
        )}
        {icon === 'error' && (
          <Grid className={classes.icon}>
            <i
              className="icon-circle-close"
              style={{ fontSize: '70px', color: '#e2575c' }}
            ></i>
          </Grid>
        )}
        <Grid>
          <Box mb={2}>
            <Typography className={classes.title}>{title}</Typography>
          </Box>
          <DialogContentText>
            <Box mb={1}>
              <Typography className={classes.message}>{message}</Typography>
            </Box>
          </DialogContentText>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginBottom: '15px' }}
        >
          <Button variant="contained" onClick={handleClose} color="primary">
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default DialogNotification;
