import React from 'react';
import { Divider, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

interface levels {
  levels?: number[] | [number[], number[], number[], number[]];
  colors?: string[];
  value?: number;
}

export default function index({
  levels = [1],
  colors = ['#66C952', '#F1AE6C', '#D47D57', '#B0413E'],
  value = 0,
}: levels) {
  const classes = useStyles();
  const theme = useTheme();

  const validateRange = (range: any) => {
    if (Array.isArray(range) && range.length > 1) {
      if (value > range[0] && value < range[range.length - 1]) {
        return true;
      }
    } else if (typeof range === 'number' && value >= range) {
      return true;
    }
    return false;
  };

  const getGrey = (level: any) => {
    if (
      (Array.isArray(level) && value > level[0]) ||
      (typeof level === 'number' && value > level)
    ) {
      return theme.palette.grey[500];
    }
    return '';
  };

  const getColor = (level: any, key: any) => {
    return validateRange(level)
      ? colors[key] ?? colors[colors.length - 1]
      : getGrey(level);
  };
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {levels.map((level: any, key: any) => (
        <>
          <div
            key={key}
            style={{ background: getColor(level, key) }}
            className={clsx(classes.indicator, {
              [classes.active]: validateRange(level),
            })}
          >
            {Array.isArray(level)
              ? level.map((lvl, i) => <span key={i}>{lvl}</span>)
              : level}
          </div>
          {key < levels.length - 1 && (
            <Divider orientation="vertical" flexItem />
          )}
        </>
      ))}
    </div>
  );
}
