import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  generalContainer: {
    "& .kkIWUE":{
      [theme.breakpoints.between('sm', 'md')]: {
        padding: '14px !important'
      }
    },
    "& .cKVytO":{
      [theme.breakpoints.between('sm', 'md')]: {
        padding: '14px !important'
      }
    },
  },
  dialogContainer:{
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px'
    }
   },
   breadContainer:{
    display:'flex',
    justifyContent:'space-between',
  }, 
  containerStepper:{
    width:'100%', 
    display:'flex',
    flexDirection:'column',
    justifyContent: 'center',
    alignItems:'center'
  },
  stepper:{
    width:'40%'
  }, 
  stepperControls:{
    display:'flex', 
    justifyContent:'center', 
    alignItems:'center', 
    padding: 12
  }, 
  dataContainer:{
    padding: '0px 24px',
  },
  title:{
    fontWeight: 600, 
    fontSize:24,
  },
  subTitleGray:{
    width:'100%',
    fontWeight: 600,
    margin: '36px 0px', 
    color:'#AFAFAF',
    borderBottom: '1px solid #eaeaf0',
    paddingLeft:12, 
    [theme.breakpoints.between('sm', 'md')]:{
      fontSize: 20
    }
  },
  textCenter:{
    textAlign:'center'
  },
  captionGray:{
    color:'#AFAFAF',
    padding:12,
    paddingLeft:24
  },
  formRegister:{
    width: '100%',
    padding:'24px 0px',
    display:'flex', 
    flexFlow:'row wrap',
    justifyContent:'center', 
    [theme.breakpoints.between('sm', 'md')]:{
      padding: '12px 0'
    }
  }, 
  formControl: {
    width:'100%'
  },
  btnRegister:{
    margin:8
  }, 
  containerRoles:{
    display:'flex',
    justifyContent:'space-between', 
    alignItems:'center'
  }, 
  permissContainer:{
    margin: '24px 0',
    display:'flex', 
    flexFlow:'row wrap', 
    textAlign:'left'
  }, 
  permissItem:{
    width: '45%',
    margin:12,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eaeaf0',
  }, 
  itemList:{
    width:'50%',
    display: 'flex', 
    flexDirection: 'column'
  },
  permissNewRolItem:{
    width: '100%',
    margin:12,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eaeaf0',
  },
  dialogFooter:{
    display:'flex', 
    justifyContent:'center', 
    padding: 16
  }, 
  iconDialogCheck:{
    fontSize:60,
    color:theme.palette.primary.main
  }
}))

export { useStyles as default }
