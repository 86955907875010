import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 1400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  breadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0',
  },

  headerContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  contentCard: {
    width: '100%',
  },

  searchContainer: {
    height: '90px !important',
  },

  dividerSearchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  paperTitles: {
    fontSize: 20,
    fontWeight: 'bolder',
    color: '#767676',
  },

  filtersContainer: {
    width: '100%',
  },

  selector: {
    width: '70%',
  },

  spacingTf: {
    margin: '0px 20px',
  },

  icon: {
    fontSize: 30,
    padding: 5,
    color: 'white',
    background: theme.palette.primary.main,
    borderRadius: '100%',
    margin: 'auto 10px',
  },

  iconLines: {
    fontSize: 25,
    color: '#F1AE6C',
    margin: '0px 20px',
  },

  dateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 40,
  },

  dateResume: {
    color: '#DD9835',
    border: '1px solid #DD9835',
    borderRadius: 20,
    padding: '2px 15px 2px 15px',
    marginLeft: 40,
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  totalCavas: {
    width: 280,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  roundNumber: {
    borderRadius: '50%',
    background: '#E29662',
    width: 80,
    height: 80,
    color: '#FFF',
    fontSize: 28,
    fontWeight: 'bolder',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 20px',
  },

  columnContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },

  cavasInventory: {
    width: '50%',
  },

  spaceBetweenContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  middleWidht: {
    width: '50%',
    margin: '0px 5px',
  },

  label: {
    fontSize: 16,
    fontWeight: 350,
    color: '#AFAFAF',
  },

  labelBold: {
    fontSize: 20,
    fontWeight: 'bolder',
    color: '#AFAFAF',
  },

  dot: {
    borderRadius: '50%',
    background: '#FFC676',
    width: 6,
    height: 6,
    display: 'inline-block',
    margin: '0px 10px',
  },

  saveButton: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: '#DD9835',
    borderRadius: 25,
    margin: '0px 10px 0px 10px',
    padding: '5px 30px 5px 30px',
    '&:hover': {
      backgroundColor: '#dd9835',
    },
  },

  viewButton: {
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      color: '#fff',
    },
  },

  dataGrid: {
    border: 'none',
    display: 'flex',
    padding: '20px 45px',

    '& .MuiBox-root': {
      margin: '20px 0',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-colCellTitle': {
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal',
      color: '#AFAFAF',
      fontWeight: 'bold',
      fontSize: 14,
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 1,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal',
      color: '#AFAFAF',
      fontWeight: 'bold',
      fontSize: 14,
    },
  },

  dataGridContainer: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '0px !important',
    },
  },

  levelIndicatorContainer: {
    margin: 'auto',
  },

  dataGridSpacing: {},
}));

export default useStyles;
