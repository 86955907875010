import initialState from '../initialStates/initialState';

import {
  SIGN_IN,
  SIGN_OUT,
  SET_USER_TYPE,
  LOADING,
  ERROR,
  TOGGLE_MODAL,
  GET_NAVIGATION_MENU,
  STORE_NAVIGATION_MENU,
  RESTART_CONTROLS,
  TIMEOUT_TOGGLE,
  SET_TOKEN,
  NEW_AVATAR,
  SUBSCRIPTION_TYPE
} from '../types/userTypes';

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        ...action.payload,
        loading: false,
        hasError: false,
        errorMessage: '',
      };
    case SIGN_OUT:
      return {
        ...initialState,
      };
    case GET_NAVIGATION_MENU:
      return {
        ...state,
        loading: false,
        navigation: action.payload,
      };
    case STORE_NAVIGATION_MENU:
      return {
        ...state,
        navigationStore: {
          ...state.navigationStore,
          [action.payload.name]: action.payload.menu,
        },
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        selectedUserType: state.typeUsers.find(
          (items: any) => items.idUserType === action.payload
        ),
        openModalSelect: false,
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        openModalSelect: !state.openModalSelect,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        hasError: true,
        errorMessage: action.payload,
      };
    case RESTART_CONTROLS:
      return {
        ...state,
        loading: false,
        hasError: false,
        errorMessage: '',
      };
    case TIMEOUT_TOGGLE:
      return {
        ...state,
        timeout: !state.timeout,
      };
    case NEW_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload,
        },
      };
      case SUBSCRIPTION_TYPE:
      return {
        ...state,
          subscriptionPlan: state.subscriptionPlan,
      };
    default:
      return state; // en caso de que ninguno de los types coincida, retorna el estado sin modificaciones
  }
};
