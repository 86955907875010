import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import useStyles from "./styles";

interface ComponentProps {
  children: any;
  fontSize?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  className?: string;
  align?: "center" | "right";
}

const MainTitle = ({
  children,
  fontSize,
  className,
  align,
}: ComponentProps) => {
  const classes = useStyles();
  return (
    <div>
      <Typography
        className={clsx(classes.title, className)}
        variant={fontSize ?? "h5"}
        style={{
          textAlign: align ?? "left",
        }}
      >
        {children}
      </Typography>
      <Box className={classes.border} />
    </div>
  );
};

export default MainTitle;
