import styled from 'styled-components'

export const UserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #2e2e2e;
  margin-top: 80px;
  margin-bottom: 2px;
  text-align: center;
  padding-bottom: 30px;
  color: #ffffff;

  & > img {
    border: 8px #2e2e2e solid;
    margin-top: -54px;
  }

  & > h4 {
    font-family: 'Roboto', Arial;
    width: 80%;
    font-weight: normal;
    margin: 10px 0 5px 0;
    font-size: 1.2em;
  }

  & > p {
    color: #dd9835;
  }
`

export const SideBarItemStyles = styled.div`
  width: 100%;
  padding: 15px 40px;
  font-size: 1rem;
  cursor: pointer;
  color: #ffffff;
`

export const Footer = styled.div`
  width: 100%;
  text-align: center;
  background: #121212;
  color: #ffffff;
  padding: 15px 5px;
  font-family: 'Roboto', sans-serif;
  position: absolute
`

export const UserSelect = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #dd9835;

  & > div {
    & p {
      margin-bottom: 0;

      & > small {
        font-weight: normal;
        color: #afafaf;
      }
    }
  }
`

export const IconUser = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 100px;
  background: #dd9835;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 30px;
  }
`

export const ReportsContainer = styled.div`
  width: 100%;
`
export const InventoryContainer = styled.div`
  width: 100%;
`
