import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { makeStyles } from '@material-ui/core';

export const Filter = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  transition: all 0.3s ease-in;
  ${({ active }: { active: boolean }) =>
    active &&
    css`
      border-bottom-right-radius: 0px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    `}
`;

export const Title = styled.h3`
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #767676;
`;

export const Inputs = styled.form`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonSearch = styled.button`
  background-color: #dd9835;
  margin-left: 1%;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  border: none;
`;

export const ButtonFilters = styled.button`
  background-color: #dd9835;
  margin-left: 1%;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  margin-left: 10px;
  transition: all 0.3s ease;
  ${({ active }: { active: boolean }) =>
    active &&
    css`
      background-color: #ddd;
      color: #707070;
    `}

  &:disabled {
    opacity: 0.5;
  }
`;

export const Params = styled(motion.div)`
  position: absolute;
  background: #fff;
  padding: 20px 35px;
  padding-top: 0;
  border-radius: 0 0 20px 20px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  margin-top: ${({ margin }: { margin: { top: number } }) => `${margin.top}px`};
  z-index: 1;

  & h2 {
    font-family: 'Raleway', sans-serif;
  }
`;

export const InputSearchContainer = styled(motion.div)`
  /* overflow: hidden; */
  width: 200px;
`;

export default makeStyles((theme) => ({
  filterValues: {
    minWidth: 200,
    marginRight: theme.spacing(1.5),
  },
  loteField: {
    minWidth: 130,
  },
  containerRageDate:{
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    "& h6":{
      margin: '0px 8px'
    }
  }
}));
