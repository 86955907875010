import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  Typography,
  Avatar,
  Button,
  CircularProgress,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SEARCH_USER } from 'core/graphql/queries/searchUser';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import config from 'config/secrets';

import TecnicalError from 'static/img/pigs/technical-error.svg';
import NotResult from 'static/img/pigs/not-found.svg';
import imagenes from 'static/img/avatars/index';
import ContentCard from 'components/ContentCard/index';
import Results from 'components/Results';
import MainTitle from 'components/MainTitle';

import DialogInvite from './../DialogInvite/index';

import useStyles from './styles';

type DialogSearchUserProps = {
  open: any;
  data?: any;
  handleClose: any;
};

const DialogSearchUser = ({
  open,
  data,
  handleClose,
}: DialogSearchUserProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const [openInvite, setOpenInvite] = useState({
    open: false,
    data: {},
  });

  const {
    data: dataUser,
    loading,
    error: errorUser,
    refetch,
  } = useQuery(SEARCH_USER, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { user: data },
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  const handleOpenInvite = (data: any) => {
    setOpenInvite({
      open: true,
      data: data,
    });
  };

  const handleCloseInvite = () => {
    setOpenInvite({
      open: false,
      data,
    });
  };

  const handleInvite = (icon: any, title: any, message: any) => {
    if (open) {
      setOpenInvite({
        open: false,
        data,
      });
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      className={classes.borderRadiudDialog}
    >
      <DialogTitle id="form-dialog-title">
        <Grid container justify="flex-end">
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={10}
            container
            justify="flex-start"
            alignItems="flex-end"
          >
            <MainTitle>
              Búsqueda: Nombre completo, Razón Social, NIT o Cédula de identidad
            </MainTitle>
          </Grid>
          <Grid item xs={2} container justify="flex-end" alignItems="center">
            <Results number={dataUser?.users?.length} />
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <div>
          {loading && (
            <Box textAlign="center" marginTop={5}>
              <CircularProgress color="primary" size={60} />
            </Box>
          )}

          {errorUser && (
            <Box marginTop={5}>
              <Grid
                alignItems="center"
                container
                direction="row"
                item
                justify="center"
              >
                <Grid container item justify="flex-end" xs={4}>
                  <img className={classes.sizeImage} src={TecnicalError} />
                </Grid>

                <Grid
                  alignItems="flex-start"
                  className={classes.spacing}
                  container
                  direction="column"
                  item
                  xs={6}
                >
                  <Typography className={classes.fontTitle}>
                    ¡Ha ocurrido un error inesperado!
                  </Typography>
                  <Box marginTop={1} marginBottom={2}>
                    <Typography className={classes.fontContent}>
                      Estamos trabajando para resolverlo
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    Volver Atrás
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {dataUser?.users.map((user: any, id: any) => (
            <Box key={user?.idUsuario}>
              <ContentCard
                style={{ border: '1px solid #D6D6D6' }}
                className={classes.backgroundColor}
              >
                <ContentCard.Content>
                  <Grid container>
                    <Grid container item xs={6}>
                      <Box marginRight={2}>
                        <Grid item xs={3}>
                          {user?.imagenPerfil === null ? (
                            <Avatar
                              src={imagenes.imagen(
                                `${user?.nombresCompletos
                                  ?.toString()
                                  .charAt(0)
                                  .toLowerCase()}`
                              )}
                            />
                          ) : (
                            <Avatar
                              src={`${config.BACKEND.dev.backend}${user?.imagenPerfil}`}
                            />
                          )}
                        </Grid>
                      </Box>
                      <Grid item xs={10}>
                        <Grid container>
                          <Typography className={classes.fontUppercase}>
                            {user?.nombresCompletos}
                          </Typography>
                        </Grid>
                        <Grid>
                          {user?.razonSocial === '' ? (
                            <Typography className={classes.fontW}>
                              Cédula: {user?.documento}
                            </Typography>
                          ) : (
                            <Typography className={classes.fontW}>
                              NIT: {user?.documento}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      container
                      justify="flex-end"
                      alignItems="center"
                    >
                      {user?.tiposUsuario[0]?.name === 'Productor' && (
                        <Box mr={2}>
                          <Button
                            variant="outlined"
                            style={{ color: '#afafaf', fontWeight: 'bold' }}
                          >
                            {user?.tiposUsuario[0]?.name}
                          </Button>
                        </Box>
                      )}
                      {user?.tiposUsuario[0]?.name === 'Comercializador' && (
                        <Box mr={2}>
                          <Button
                            variant="outlined"
                            style={{ color: '#afafaf', fontWeight: 'bold' }}
                          >
                            {user?.tiposUsuario[0]?.name}
                          </Button>
                        </Box>
                      )}

                      {user?.tiposUsuario[0]?.name === 'Planta' && (
                        <Box mr={2}>
                          <Button
                            variant="outlined"
                            style={{ color: '#afafaf', fontWeight: 'bold' }}
                          >
                            {user?.tiposUsuario[0]?.name}
                          </Button>
                        </Box>
                      )}

                      {user?.estadoInvitacion === null ||
                      (user?.estadoInvitacion?.descripcion === 'Rechazada' &&
                        dayjs(user?.fechaRespuesta)
                          .add(15, 'days')
                          .diff(dayjs(), 'day') <= 0) ? (
                        <Button
                          color="primary"
                          className={classes.fontW}
                          onClick={() => handleOpenInvite(user?.idUsuario)}
                          variant="contained"
                        >
                          Invitar a Mi Red
                        </Button>
                      ) : (
                        <>
                          {user?.estadoInvitacion?.descripcion ===
                            'Aceptada' && (
                            <Button
                              className={classes.buttonAccept}
                              variant="contained"
                              disabled
                            >
                              Invitación Aceptada
                            </Button>
                          )}
                          {user?.estadoInvitacion?.descripcion ===
                            'En Espera' && (
                            <Button
                              color="default"
                              variant="contained"
                              className={classes.buttonWaiting}
                              disabled
                            >
                              Invitación Pendiente
                            </Button>
                          )}
                          {user?.estadoInvitacion?.descripcion ===
                            'Rechazada' &&
                            dayjs(user?.fechaRespuesta)
                              .add(15, 'days')
                              .diff(dayjs(), 'day') > 0 && (
                              <Tooltip
                                title={`Como la invitación fue rechazada debes esperar ${dayjs(
                                  user?.fechaRespuesta
                                )
                                  .add(15, 'days')
                                  .diff(dayjs(), 'day')} días para reenviarla.`}
                                arrow
                              >
                                <Button
                                  className={classes.buttonJected}
                                  variant="contained"
                                >
                                  Invitación Rechazada
                                </Button>
                              </Tooltip>
                            )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </ContentCard.Content>
              </ContentCard>
            </Box>
          ))}

          <DialogInvite
            open={openInvite.open}
            data={openInvite.data}
            handleClose={handleCloseInvite}
            handleInvite={handleInvite}
          />

          {dataUser?.users?.length === 0 && (
            <Box marginTop={5}>
              <Grid
                alignItems="center"
                container
                direction="row"
                justify="center"
              >
                <Grid container justify="flex-end" xs={4}>
                  <img src={NotResult} />
                </Grid>

                <Grid
                  alignItems="flex-start"
                  className={classes.spacing}
                  container
                  direction="column"
                  xs={6}
                >
                  <Typography className={classes.fontTitle}>
                    ¡No encontramos resultados!
                  </Typography>
                  <Box marginTop={1} marginBottom={2}>
                    <Typography className={classes.fontContent}>
                      La búsqueda no ha arrojado ningún resultado, prueba con
                      otro Nombre Completo, Razón Social, Cédula de identidad o
                      NIT
                    </Typography>
                  </Box>
                  <Button
                    color="primary"
                    onClick={handleClose}
                    variant="contained"
                  >
                    Volver Atrás
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSearchUser;
