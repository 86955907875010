import React, { useState, useEffect } from 'react';

import {
  Box,
  Typography,
  Divider,
  Tooltip,
  Fab,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';

import FooterGrid from 'components/datagrid-custom-components/TableFooter';
import TableOverlay from 'components/datagrid-custom-components/TableOverlay';
import TableLoading from 'components/datagrid-custom-components/TableLoading';
import Results from 'components/Results';

import { FARMS } from 'core/graphql/queries/profile';
import { useQuery } from '@apollo/client';

import dayjs from 'dayjs';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useStyles from './styles';

const Farms = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const path = useLocation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedValue, setSelectedValue] = useState('active');
  const handleChangeRadio = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const { data, loading, refetch } = useQuery(FARMS, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  useEffect(() => {
    refetch();
  }, [path]);

  const rows: any[] = [];
  data?.getFarms?.data?.map((item: any) => {
    if (selectedValue === 'active' && item?.estado) {
      rows.push({
        id: item?.idGranja,
        farmCode: item?.codigoGranja == '' ? 'N.D' : item?.codigoGranja,
        farmName: item?.nombreGranja,
        farmLocation: item?.ubicacion,
        farmDirection: item?.direccion,
        dateCreation: dayjs(item?.fechaCreacion).format('DD/MM/YYYY HH:mm:ss'),
        state: item?.estado,
      });
    } else if (selectedValue === 'inactive' && !item?.estado) {
      rows.push({
        id: item?.idGranja,
        farmCode: item?.codigoGranja == '' ? 'N.D' : item?.codigoGranja,
        farmName: item?.nombreGranja,
        farmLocation: item?.ubicacion,
        farmDirection: item?.direccion,
        dateCreation: dayjs(item?.fechaCreacion).format('DD/MM/YYYY HH:mm:ss'),
        state: item?.estado,
      });
    }
  });
  const columns: GridColDef[] = [
    {
      field: 'farmCode',
      headerName: 'Código Granja ',
      width: 130,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.headerTableGrid,
    },
    {
      field: 'dateCreation',
      headerName: 'Fecha de Creación',
      width: 170,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.headerTableGrid,
    },
    {
      field: 'farmName',
      headerName: 'Nombre Granja',
      width: 220,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'left',
      headerClassName: classes.headerTableGrid,
    },
    {
      field: 'farmDirection',
      headerName: 'Dirección',
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'left',
      headerClassName: classes.headerTableGrid,
    },
    {
      field: 'farmLocation',
      headerName: 'Ubicación',
      width: 200,
      disableColumnMenu: true,
      sortable: false,

      headerAlign: 'center',
      align: 'left',
      headerClassName: classes.headerTableGrid,
    },
    {
      field: 'state',
      headerName: 'Estado',
      width: 90,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.headerTableGrid,
      renderCell: (params: GridValueGetterParams) => (
        <div>
          <div
            className={clsx({
              [classes.state]: true,
              ['active']: params.getValue(params.id, 'state'),

              ['inactive']: !params.getValue(params.id, 'state'),
            })}
          ></div>
        </div>
      ),
    },

    {
      field: '',
      headerName: '',
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.headerTableGrid,

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`farms/detail/${params.getValue(params.id, 'id')}`}
              color="primary"
              className={classes.btnDetails}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography variant="h6" className={classes.title}>
          Mis Granjas Registradas en el Sistema
          <i
            className={
              data?.getFarms?.data?.length > 0
                ? 'icon-circle-check'
                : 'icon-circle-close'
            }
            style={{
              color: data?.getFarms?.data?.length > 0 ? '#68CE70' : '#E2575C',
            }}
          />
        </Typography>
      </Box>
      <Divider />
      <Box marginY={2}>
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="top"
          >
            <FormControlLabel
              checked={selectedValue === 'active'}
              onChange={handleChangeRadio}
              style={{ color: '#707070' }}
              value="active"
              control={<Radio color="primary" />}
              label="Activos"
            />
            <FormControlLabel
              checked={selectedValue === 'inactive'}
              onChange={handleChangeRadio}
              style={{ color: '#707070' }}
              value="inactive"
              control={<Radio color="primary" />}
              label="Inactivos"
            />
          </RadioGroup>
          <Box display="flex" alignItems="center">
            <div>
              <Results number={rows?.length} />
            </div>
            <Button
              variant="contained"
              color="primary"
              className="buttonAdd"
              component={Link}
              to={`farms/register/`}
            >
              Registrar Granja
            </Button>
          </Box>
        </Box>
        <DataGrid
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 15, 20]}
          page={page}
          onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
          onPageChange={(e: any) => setPage(e.page)}
          columns={columns}
          autoHeight
          rows={rows}
          className={classes.dataGrid}
          loading={loading}
          components={{
            Footer: FooterGrid,
            NoRowsOverlay: TableOverlay,
            LoadingOverlay: TableLoading,
          }}
        />
      </Box>
    </Box>
  );
};

export default Farms;
