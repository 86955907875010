import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  'map-dialog': {
    height: 500,
  },
  'map-grid': {
    height: '100%',
  },
  'search-input': {
    display: 'flex',
    alignItems: 'center',
  },

  downButton: {
    color: 'white',
    backgroundColor: '#707070',
  },

  mapDialog: {
    height: 700,
  },
  button: {
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
  },
  fontContainer: {
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
  dialogContainer: {
    '& .MuiDialog-paperWidthMd': {
      borderRadius: '20px',
    },
  },

  flexButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  viewButton: {
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      color: '#fff',
    },
  },
}));
