import React from 'react';

import { Button, Box } from '@material-ui/core';

import clsx from 'clsx';
import Carousel from 'react-elastic-carousel';

import useStyles from './styles';

interface SliderProps {
  children: any;
  itemsToShow?: number;
  breakPoints?: any;
  className?: string;
}

const Slider = ({
  children,
  itemsToShow,
  breakPoints,
  className,
}: SliderProps) => {
  const classes = useStyles();

  const arrows = ({ type, onClick, isEdge }: any) => {
    const pointer =
      type === 'PREV' ? 'icon-circle-arrow-left' : 'icon-circle-arrow-right';
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button onClick={onClick} disabled={isEdge}>
          <i className={clsx(pointer, classes.button, { disable: isEdge })} />
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Carousel
        itemsToShow={itemsToShow ?? 3}
        isRTL={false}
        pagination={false}
        renderArrow={arrows}
        breakPoints={breakPoints}
        className={className}
      >
        {children}
      </Carousel>
    </>
  );
};

export default Slider;
