import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

interface cardProps {
  children?: any;
  icon?: string;
  fontSizeIcon?: number;
  title: string;
  subtitle?: string;
  width?: number;
  height?: number;
  fontSizeTitle?: number;
  fontSizeSubtitle?: number;
  iconSize?: number;
  chacked?: boolean;
  onClick?: (event: any) => void;
}

const CardIcon = ({
  children,
  icon,
  fontSizeIcon,
  title,
  subtitle,
  width,
  height,
  fontSizeTitle,
  fontSizeSubtitle,
  chacked,
  onClick,
}: cardProps) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx({
        [classes.cardItem]: true,
        [classes.cardSelected]: chacked,
      })}
      style={{
        width: width ?? 110,
        height: height ?? 120,
      }}
      onClick={onClick}
    >
      <CardActionArea className={classes.cardActionArea}>
        <CardContent className={classes.cardContent}>
          {children}
          <i
            className={clsx(icon, classes.roleIcon)}
            style={{
              fontSize: fontSizeIcon ?? 45,
            }}
          />
          <Typography
            className={clsx(classes.title)}
            style={{ fontSize: fontSizeTitle ?? 13 }}
          >
            {title}
          </Typography>
          <Typography
            className={clsx(classes.subtitle, {
              fontSize: fontSizeSubtitle ?? 10,
            })}
          >
            {subtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardIcon;
