import { makeStyles } from "@material-ui/styles";
import { alpha, Theme } from "@material-ui/core/styles";

import ComercializadorImg from "static/img/icons/comercializador-w.svg";
import ProductorImg from "static/img/icons/productor-w.svg";

export const typeAssets = {
  1: {
    img: ProductorImg,
    title: "Productor",
  },
  2: {
    img: ComercializadorImg,
    title: "Comercializador",
  },
};

const styles = makeStyles((theme: Theme) => ({
  content: {},
  card_container: {
    borderRadius: 100,
  },
  card: {
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5),
    cursor: "pointer",
    border: "1px solid #ddd",
    overflow: "hidden",
    maxHeight: 40,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "&:not($active):hover $card_text": {
      zIndex: 2,
      color: theme.palette.primary.dark,
    },
    "&:not($active):hover $avatar_container:before": {
      transform: "scale(1)",
    },
    "&:hover $avatar": {
      backgroundColor: alpha(theme.palette.primary.main, 0.5),
      width: 700,
      height: 700,
      marginLeft: -330,
      zIndex: 1,
    },
    "&:hover $avatar_img": {
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
  card_text: {
    padding: theme.spacing(0, 3),
    paddingLeft: 0,
    marginLeft: theme.spacing(2),
    zIndex: 2,
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
      delay: ".3s",
    }),
  },
  avatar_container: {
    maxWidth: 25,
    position: "relative",
    "&:before": {
      content: '" "',
      background: alpha(theme.palette.primary.main, 1),
      borderRadius: 25,
      width: "100%",
      height: 25,
      position: "absolute",
      left: "calc(50% - 5.5px)",
      top: "calc(50% - 12.5px)",
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
        delay: ".4s",
      }),
    },
  },
  avatar: {
    backgroundColor: alpha(theme.palette.primary.main, 0),
    transformOrigin: "center",
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
      delay: theme.transitions.duration.short,
    }),
  },
  avatar_img: {
    width: 20,
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
    "& $avatar_container:before": {
      transform: "scale(20)",
      transition: theme.transitions.create("all", {
        duration: ".5s",
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    "& $avatar": {
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
      width: 700,
      height: 700,
      marginLeft: -330,
      zIndex: 1,
    },
    "& $card_text": {
      color: theme.palette.common.white,
    },
  },
}));

export default styles;
