import { Document, Image, Page, View, Font, Text } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import antemortenLogo from 'static/img/reportsIcons/antemortenLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import productorIcon from 'static/img/reportsIcons/productorIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import TableInfo from 'components/pdf-components/TableInfo/index';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ListData from 'components/pdf-components/ListData';
import NumberTotal from 'components/pdf-components/NumberTotal/index';
import Footer from 'components/pdf-components/Footer/index';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF/index';
import Paginationpdf from 'components/pdf-components/Pagination';

import styles from './styles/lote';

// const item: any[] = [];
// const itemTwo: any[] = [];
const item = [2, 2, 2, 2, 2];
const itemTwo = [2, 2, 2];

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

interface MyDocAnteMortemProps {
  qr: string;
  graph: string;
  graphTwo: string;
}

export const MyDocAnteMortem = ({
  qr,
  graph,
  graphTwo,
}: MyDocAnteMortemProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={6700}
        reportImage={antemortenLogo}
        number="023456789"
        title="Reporte Técnico Recibo Animales en Planta"
        code="A M-001"
      />
      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={productorIcon}
          title="Productor"
          items={{
            Nombre: 'Nombre productor',
            NIT: '34123412-2',
            Teléfono: '+57 314 6543 543',
            Email: 'info@productor.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '10px' }}>
        <Subtitle
          title="Inspección de Animales Caídos / Agitados / Muertos en Corrales"
          widthContainer="100%"
        />
      </View>

      {item.length !== 0 ? (
        <View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: '10px',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Subtitle title="Resumen Inspección" widthContainer="100%" />
              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <View style={{ width: '100%', marginLeft: 8 }}>
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '110px !important',
                    }}
                  >
                    <Image
                      src={graph}
                      style={{
                        height: '150px',
                        width: '150px',
                      }}
                    />
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  minHeight: '50px',
                }}
              >
                <View style={{}}>
                  <NumberTotal
                    value="2"
                    info="Total Animales Caidos"
                    widthInfo="60px"
                  />
                </View>
                <View style={{}}>
                  <NumberTotal
                    value="1"
                    info="Total Animales Agitados"
                    widthInfo="60px"
                  />
                </View>
                <View style={{}}>
                  <NumberTotal
                    value="1"
                    info="Total Animales Muertos"
                    widthInfo="60px"
                  />
                </View>
              </View>
            </View>

            <View style={{ width: '48.3%' }}>
              <Subtitle
                title="Datos Inspección en Corrales"
                widthContainer="100%"
              />
              <ListData
                widthContainer="100%"
                items={{
                  'Operador Corrales': 'Oscar Dario Uribe Cardona',
                  'Nro Documento Inspector': '8070148',
                }}
              />
              <View style={{ marginTop: '5px' }}>
                <Subtitle
                  title="Datos Inspección Médico Veterinaria"
                  widthContainer="100%"
                />
                <ListData
                  widthContainer="100%"
                  items={{
                    'Nombre Inspector': 'Julian David Mejia Ramirez',
                    'Nro. Matrícula': '17456',
                  }}
                />
              </View>
              <View
                style={{
                  height: '50px',
                  marginTop: '10px',
                  justifyContent: 'center',
                }}
              >
                <Text>Firma</Text>
              </View>
              <View style={{ margin: 0, justifyContent: 'center' }}>
                <Text>Julian David Mejia Ramirez</Text>
                <Text>Inspector Veterinario Planta de Sacrificio</Text>
              </View>
            </View>
          </View>

          <View
            style={{ flexDirection: 'row', width: '100%', marginTop: '10px' }}
          >
            <Subtitle
              title="Detalle de Inspección de Animales Caídos / Agitados / Muertos en Corrales"
              widthContainer="100%"
            />
          </View>

          <View>
            <View style={[styles.tableAM, { marginBottom: 10 }]} wrap={false}>
              <View
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    marginBottom: '5px',
                    marginTop: '5px',
                    alignItems: 'center',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 45 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Canal ID
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 55 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Nro. Animal
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 60 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Estado
                  </Text>
                  <Text style={styles.tableHead}>Animales</Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 45 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Temp (Co)
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 60 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Frecuencia
                  </Text>
                  <Text style={styles.tableHead}>Respiratoria</Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 45 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Alteración
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 65 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Extremidades
                  </Text>
                  <Text style={styles.tableHead}>Afectadas</Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 50 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Dictamen
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 65 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Fecha
                  </Text>
                  <Text style={styles.tableHead}>Inspección</Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 65 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Fecha
                  </Text>
                  <Text style={styles.tableHead}>Sacrificio</Text>
                </View>
              </View>
              {item.map((item: any, index: number) => (
                <View
                  key={index}
                  wrap={false}
                  style={[
                    styles.tableRow,
                    {
                      backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                    },
                  ]}
                >
                  <View style={[styles.tableCol, { maxWidth: 45 }]}>
                    <Text style={[styles.tableCell, { fontSize: 8 }]}>
                      4321443
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 55 }]}>
                    <Text style={[styles.tableCell, { fontSize: 8 }]}>
                      CO-13
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: 60 }]}>
                    <Text
                      style={(styles.tableCell, { fontSize: 7, marginTop: 5 })}
                    >
                      Caído en Corrales
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 45 }]}>
                    <Text style={[styles.tableCell, { fontSize: 8 }]}>
                      N.R.
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 60 }]}>
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 8 },
                        { maxLines: 2 },
                      ]}
                    >
                      N.R.
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 45 }]}>
                    <Text style={[styles.tableCell, { fontSize: 8 }]}>
                      Otras
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 65 }]}>
                    <Text style={[styles.tableCell, { fontSize: 8 }]}>
                      N.R.
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 50 }]}>
                    <Text style={[styles.tableCellText, { fontSize: 8 }]}>
                      Beneficio con Restricciones
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 65 }]}>
                    <Text style={[styles.tableCellText, { fontSize: 8 }]}>
                      15/10/2019 12:39:12 P.M.
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 65 }]}>
                    <Text style={[styles.tableCellText, { fontSize: 8 }]}>
                      15/10/2019 12:39:12 P.M.
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
      ) : (
        <View
          style={{
            flexDirection: 'row',
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '10%',
            marginRight: '10%',
          }}
        >
          <Subtitle
            title="Este Lote no tiene registrados Animales Muertos en Transporte."
            widthContainer="60%"
            heightContainer="28px"
          />
          <NumberTotal
            value="0"
            info="Total Animales Muertos Transporte / Desembarque"
            widthInfo="95px"
          />
        </View>
      )}

      <View style={{ paddingLeft: 10, paddingRight: 10 }}>
        <Text>
          Todos los animales Agitados y Caídos en el Transporte o Desembarque NO
          SE PESAN y se ingrsan directamente al Corral de Observación, para su
          Identificación Individual y su Evaluación Médico Veterinaria.
        </Text>
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '10px' }}>
        <Subtitle
          title="Inspección de Animales Muertos en Transporte o Desembarque"
          widthContainer="100%"
        />
      </View>

      {itemTwo.length !== 0 ? (
        <View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: '90px',
            }}
          >
            <View style={{ width: '100%' }}>
              <View
                style={{
                  height: '70px',
                  marginBottom: '5px',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <View style={{ marginLeft: 8 }}>
                    <View
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100px !important',
                      }}
                    >
                      <Image
                        src={graphTwo}
                        style={{
                          height: '130px',
                          width: '130px',
                        }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      justifyContent: 'center',
                    }}
                  >
                    <View style={{}}>
                      <NumberTotal
                        value="2"
                        info="Total Animales Caidos"
                        widthInfo="90px"
                      />
                    </View>
                    <View style={{}}>
                      <NumberTotal
                        value="1"
                        info="Total Animales Agitados"
                        widthInfo="90px"
                      />
                    </View>
                    <View style={{}}>
                      <NumberTotal
                        value="1"
                        info="Total Animales Muertos"
                        widthInfo="90px"
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{ flexDirection: 'row', width: '100%', marginTop: '10px' }}
          >
            <Subtitle
              title="Detalle de Inspección de Animales Muertos en Transporte o Desembarque"
              widthContainer="100%"
            />
          </View>

          <View>
            <View style={[styles.table, { marginBottom: 5 }]} wrap={false}>
              <View
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    marginBottom: '5px',
                    marginTop: '5px',
                    alignItems: 'center',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 185, width: 185 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Fecha Registro
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 185, width: 185 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Estado
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 185, width: 185 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Operador Corrales
                  </Text>
                </View>
              </View>
              {itemTwo.map((item: any, index: number) => (
                <View
                  key={index}
                  wrap={false}
                  style={[
                    styles.tableRow,
                    {
                      backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                    },
                  ]}
                >
                  <View
                    style={[styles.tableCol, { maxWidth: 185, width: 185 }]}
                  >
                    <Text style={[styles.tableCellAM, { fontSize: 8 }]}>
                      06/10/2019 11:20:07 a.m.
                    </Text>
                  </View>
                  <View
                    style={[styles.tableCol, { maxWidth: 185, width: 185 }]}
                  >
                    <Text style={[styles.tableCellAM, { fontSize: 8 }]}>
                      Muerto en Transporte
                    </Text>
                  </View>
                  <View
                    style={[styles.tableCol, { maxWidth: 185, width: 185 }]}
                  >
                    <Text
                      style={
                        (styles.tableCellAM, { fontSize: 8, marginTop: 5 })
                      }
                    >
                      Hamilton Lopera Quiroz
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
      ) : (
        <View
          style={{
            flexDirection: 'row',
            marginTop: '10px',
            marginBottom: '5px',
            marginLeft: '10%',
            marginRight: '10%',
          }}
        >
          <Subtitle
            title="Este Lote no tiene registrados Animales Muertos en Transporte."
            widthContainer="60%"
            heightContainer="28px"
          />
          <NumberTotal
            value="0"
            info="Total Animales Muertos Transporte / Desembarque"
            widthInfo="95px"
          />
        </View>
      )}

      <View
        style={{
          width: '100%',
          marginTop: '5px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View style={{ maxWidth: '46%' }}>
          <Text>
            Todos los Animales en Pie "DECLARADOS MUERTOS" en el proceso de
            Inspección son decomisados e incinerados.
          </Text>
        </View>
      </View>

      <View style={styles.conventions} wrap={false}>
        <View style={styles.backgroundguide}>
          <View style={styles.textconventions}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * Temp (°)
              </Text>
            </View>
            <View style={styles.textgsmi}>
              <Text>Temperatura del cerdo</Text>
            </View>
          </View>
          <View style={styles.textconventions}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * AI
              </Text>
            </View>
            <View style={styles.textgsmi}>
              <Text>Anterior Izquierdo</Text>
            </View>
          </View>
          <View style={styles.textconventions}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * AD
              </Text>
            </View>
            <View style={styles.textgsmi}>
              <Text>Anterior Derecho</Text>
            </View>
          </View>
          <View style={styles.textconventions}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * PI
              </Text>
            </View>
            <View style={styles.textgsmi}>
              <Text>Posterior Izquierda</Text>
            </View>
          </View>
          <View style={styles.textconventions}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * PD
              </Text>
            </View>
            <View style={styles.textgsmi}>
              <Text>Posterior Derecho</Text>
            </View>
          </View>
          <View style={styles.textconventions}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * N.R
              </Text>
            </View>
            <View style={styles.textgsmi}>
              <Text>No</Text>
              <Text>Registrado</Text>
            </View>
          </View>
        </View>
        <View style={styles.borderAM}>
          <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
            Convenciones
          </Text>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screenLotAm" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte por Lote Ante-Mortem"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphAM = () => {
  return (
    <div id="grapham" style={{ width: 500 }}>
      <Graphicpdfsf
        textTotalData="Total Animales"
        fontSizeTotal={60}
        colorTotal="#212121"
        topTextTotalData={-680}
        widthChart="1000px"
        fontConnector="60px"
        fontNumberSizeTotal={80}
        startAngle={-1}
        endAngle={359}
        removepercentages
        data={[
          {
            label: '',
            data: 2,
            bg: '#be5948',
          },
          {
            label: '',
            data: 1,
            bg: '#e29662',
          },
          {
            label: '',
            data: 1,
            bg: '#ffc676',
          },
        ]}
      />
    </div>
  );
};

export const GraphAMT = () => {
  return (
    <div id="graphamt" style={{ width: 500 }}>
      <Graphicpdfsf
        textTotalData="Total Animales"
        fontSizeTotal={60}
        colorTotal="#212121"
        topTextTotalData={-680}
        widthChart="1000px"
        fontConnector="60px"
        fontNumberSizeTotal={80}
        startAngle={-1}
        endAngle={359}
        removepercentages
        data={[
          {
            label: '',
            data: 2,
            bg: '#be5948',
          },
          {
            label: '',
            data: 1,
            bg: '#e29662',
          },
          {
            label: '',
            data: 1,
            bg: '#ffc676',
          },
        ]}
      />
    </div>
  );
};
