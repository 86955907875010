import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme=> ({
  main:{
    padding:'40px 0px',
    display:'flex', 
    flexDirection: 'column',
    justifyContent:'center',
    alignItems:'center', 
  },
  content:{
    height: '100%',
  }, 
  menuContainer:{
    background: '#121212',
    color: '#FFF', 
    "& :hover":{
      color: '#FFF',
    },
  }, 
  list:{
    display: 'flex', 
    alignItems: 'stretch', 
    padding: 0, 
    
  }, 
  menuItemsContainer:{
    minWidth: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#2D2D2D',
    marginRight: '2px',
    padding: 12,
    fontSize: '16px',
    "& i":{
      fontSize: '24px', 
      marginLeft: 8
    },
    "&.subitem":{
      margin: 0, 
    }, 
    "&.active":{
      background: '#DD9835 !important'
    }, 
    "&.selected":{
      background: '#444444'
    }, 
  },
  subItemsContainer:{
    position: 'absolute',
    zIndex: 111,
    width: '100%',
    top: '50px',
    [theme.breakpoints.down('lg')]: {
      top: '65px',
    },
  },
  menuItems:{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
  edit:{
    position: 'relative',
    cursor: 'pointer', 
    display:'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    "&:hover":{
      "& div":{
        display: 'flex'
      }
    }, 
    borderRadius: '100%', 
    overflow: 'hidden'
  },
  overlay:{
    display: 'none', 
    justifyContent: 'center', 
    alignItems: 'center',
    position: 'absolute',
    background: '#76767659',
    width: '100%', 
    height: '100%',
    color:'#FFF'
  }, 
  dialogContainer:{
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px'
    }
  }, 
  dialogFooter:{
    justifyContent: 'center', 
    margin: '12px 0px'
  }

}));

export default useStyles