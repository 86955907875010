import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Box } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import domtoimage from 'dom-to-image';
import clsx from 'clsx';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import ItemListInfo from 'components/ItemListInfo';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableInfo from 'components/TableInfo';
import GobackLink from 'components/GobackLink';
import Chart from 'components/Chart';
import ExportToExcel from 'components/ExportToExcel';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import LoteInfoTitle from 'components/LoteInfoTitle/index';
import DialogPDFViewer from 'components/DialogPDFViewer/index';

import { MyDocLotTicket, ReportLot } from './../../Reports/ReportLot';

import useStyles from './styles';

const index = () => {
  const [openLotTicket, setOpenLotTicket] = useState(false);
  const [imageQr, setImageQr] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenLotTicket');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalLotTicket = () => {
    setOpenLotTicket(true);
  };

  useEffect(() => {
    generateScreenshot();
  }, [openLotTicket]);

  const columns: GridColDef[] = [
    {
      field: 'nroPesaje',
      headerName: 'Nro. Pesaje',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'hour',
      headerName: 'Hora',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'weightanimals',
      headerName: 'Animales Pesados',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'gender',
      headerName: 'Sexo',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'inmunocastrado',
      headerName: 'Inmunocastrado',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'weight',
      headerName: 'Peso Total (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'weightprom',
      headerName: 'Peso Promedio (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const rows = [
    {
      id: 1,
      nroPesaje: '0',
      date: '01/10/2019',
      hour: '01:00:00',
      weightanimals: '0',
      gender: 'Ambos',
      inmunocastrado: 'SI',
      weight: '0,00',
      weightprom: '0,00',
    },
    {
      id: 2,
      nroPesaje: '0',
      date: '01/10/2019',
      hour: '01:00:00',
      weightanimals: '0',
      gender: 'Ambos',
      inmunocastrado: 'SI',
      weight: '0,00',
      weightprom: '0,00',
    },
    {
      id: 3,
      nroPesaje: '0',
      date: '01/10/2019',
      hour: '01:00:00',
      weightanimals: '0',
      gender: 'Ambos',
      inmunocastrado: 'SI',
      weight: '0,00',
      weightprom: '0,00',
    },
    {
      id: 4,
      nroPesaje: '0',
      date: '01/10/2019',
      hour: '01:00:00',
      weightanimals: '0',
      gender: 'Ambos',
      inmunocastrado: 'SI',
      weight: '0,00',
      weightprom: '0,00',
    },
  ];

  const columnsSecond: GridColDef[] = [
    {
      field: 'tipoCorral',
      headerName: 'Tipo Corral',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'nroCorral',
      headerName: 'Número Corral',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'animals',
      headerName: 'Animales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'date',
      headerName: 'Fecha de Ingreso',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];
  const rowsSecond = [
    {
      id: 1,
      tipoCorral: 'Reposo',
      nroCorral: '21',
      animals: '29',
      date: '01/10/2019 10:00:00',
    },
    {
      id: 2,
      tipoCorral: 'Reposo',
      nroCorral: '21',
      animals: '28',
      date: '01/10/2019 10:00:00',
    },
    {
      id: 3,
      tipoCorral: 'Observación',
      nroCorral: '1',
      animals: '1',
      date: '01/10/2019 10:00:00',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/bascule/details',
      title: 'Tiquete de Báscula',
    },
  ];

  const classes = useStyles();

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoteInfoTitle
              lot="7645"
              namereport="Nro. Reporte"
              numberreport="011-65432"
              title="Reporte Técnico Tiquete de Báscula"
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} xl={6}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Agentes relacionados
              </Typography>
            </div>
            <div className={classes.flexContainer}>
              <div className={classes.midTableInfo}>
                <TableInfo
                  icon="icon-productor"
                  title="Productor"
                  items={{
                    Nombre: 'Nombre Productor',
                    NIT: '45134132',
                    Teléfono: '+57 314 6543 543',
                    Email: 'info@Productor.com',
                    Dirección: 'Calle 105 A 23-23',
                  }}
                />
              </div>

              <div className={classes.midTableInfo}>
                <TableInfo
                  icon="icon-planta"
                  title="Planta Beneficio"
                  items={{
                    Nombre: 'Nombre Planta',
                    NIT: '45134132',
                    Teléfono: '+57 314 6543 543',
                    Email: 'info@planta.com',
                    Dirección: 'Calle 105 A 23-23',
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Agentes relacionados
              </Typography>
            </div>
            <div className={classes.flexContainer}>
              <div className={classes.midTableInfo}>
                <TableInfo
                  icon="icon-granja"
                  title="Granja"
                  items={{
                    Nombre: 'Nombre Granja',
                    'Lote de Ceba': '45134132',
                    'Remisión Nro.': '12345134513',
                    'Salida Granja': '22/01/2019 09:00:00 a.m',
                    Operador: 'Nombre Responsable',
                  }}
                />
              </div>

              <div className={classes.midTableInfo}>
                <TableInfo
                  icon="icon-truck"
                  title="Transporte"
                  items={{
                    Nombre: 'Nombre Conductor',
                    NIT: 'ERW 753',
                    'Llegada Planta': '22/01/2019 09:00:00 a.m',
                    'Ingreso Planta': '22/01/2019 09:00:00 a.m',
                    'Salida Planta': '22/01/2019 09:00:00 a.m',
                  }}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} xl={8}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Resumen Pesaje Animales en Pie
              </Typography>
            </div>
            <div>
              <div
                className={clsx(
                  classes.flexContainer,
                  classes.paddingContainer
                )}
              >
                <div>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={58}
                    titleInfo="Total Animales en Pie Pesados"
                    avatarTotalColor="#FFC676"
                    avatarTotalSize={65}
                    avatarTextSize={22}
                    titleInfoSize={250}
                  />
                </div>
                <div className={classes.minContainerW}>
                  <div>
                    <ItemListInfo
                      icon={'icon-weight'}
                      titleInfo="Peso Promedio"
                      numberInfo="102,65 Kg"
                    />
                  </div>
                  <div>
                    <ItemListInfo
                      icon={'icon-pig'}
                      titleInfo="Peso Total"
                      numberInfo="42.0039,00 Kg"
                    />
                  </div>
                </div>
              </div>
              <div className={classes.flexContainer}>
                <div className={classes.marginBox}>
                  <ItemTotalInfo
                    avatarTotalInfo={'CE'}
                    titleInfo="Tipo de Cerdo"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="bottom"
                    titleInverted
                  />
                </div>
                <div className={classes.marginBox}>
                  <ItemTotalInfo
                    avatarTotalInfo={'A'}
                    titleInfo="Sexo Animales"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="bottom"
                    titleInverted
                  />
                </div>
                <div className={classes.marginBox}>
                  <ItemTotalInfo
                    avatarTotalInfo={'SI'}
                    titleInfo="Inmunocastrados"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={200}
                    position="bottom"
                    titleInverted
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} xl={4}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Estado Animales en Pie
              </Typography>
            </div>
            <div>
              <Chart
                type="doughnut"
                data={[
                  {
                    label: 'Animales Agitados',
                    data: 3,
                    bg: '#BE5948',
                  },
                  {
                    label: 'Animales Caídos',
                    data: 2,
                    bg: '#D47D57',
                  },
                  {
                    label: 'Muertos en Transporte',
                    data: 1,
                    bg: '#E29662',
                  },
                  {
                    label: 'Muertos Desembarque',
                    data: 1,
                    bg: '#FFC676',
                  },
                ]}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Detalle Proceso de Pesaje Animales en Pie
              </Typography>
            </div>
            <div>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Detalle Distribución Animales en Corrales
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={clsx(classes.grayContainer)}>
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={56}
                titleInfo="Animales en Corral de Reposo"
                avatarTotalColor="#F1AE6C"
                avatarTotalSize={65}
                avatarTextSize={22}
                titleInfoSize={250}
              />
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={1}
                titleInfo="Animales en Corral de Observación"
                avatarTotalColor="#FFC676"
                avatarTotalSize={65}
                avatarTextSize={22}
                titleInfoSize={250}
              />
            </div>
          </Grid>
          <Grid item xs={12} xl={6}>
            <div>
              <DataGrid
                rows={rowsSecond}
                columns={columnsSecond}
                autoHeight
                className={classes.dataGrid}
              />
            </div>
          </Grid>
        </Grid>
      </ContentCard>
      <Grid container justify="flex-end" alignItems="center">
        <Box mr={2}>
          <Typography className={classes.fontSubtitle}>
            Opciones del Reporte
          </Typography>
        </Box>
        <Button
          startIcon={<i className="icon-eye" />}
          variant="contained"
          className="raleway"
          color="primary"
          onClick={openModalLotTicket}
        >
          Previsualizar
        </Button>
      </Grid>
      <DialogPDFViewer
        open={openLotTicket}
        onClose={() => setOpenLotTicket(false)}
        done={Boolean(imageQr)}
      >
        <MyDocLotTicket qr={imageQr} />
      </DialogPDFViewer>
    </div>
  );
};

export default index;
