import React, { useState } from 'react';
import {
  Drawer,
} from '@material-ui/core';

import useNavigation from 'lib/hooks/useNavigation';

import useStyles from './styles';
import NavbarLayout from 'components/SidebarFolded/components/NavbarLayout';

interface SidebarProps {
  open: boolean;
  onClose: () => void;
  user: any;
  role: any;
}

const Sidebar: React.FC<SidebarProps> = ({ open, onClose, user, role }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.sidebar }}
    >
      <NavbarLayout withoutPin />
    </Drawer>
  );
};

export default Sidebar;
