import React from 'react';
import { Typography, Box, Table, TableRow, TableCell } from '@material-ui/core';

import dayjs from 'dayjs';

interface certificateProps {
  data: {
    name?: string;
    document?: string;
    nameRL?: string;
    documentRL?: string;
    direction?: string;
    cellphone?: string;
    email?: string;
    username?: string;
    userType?: string;
    registerDate?: any;
    entity?: string;
  };
}

const Certificate = ({ data }: certificateProps) => {
  return (
    <div>
      <Typography variant="h6" color="secondary" align="center">
        CERTIFICADO DE AUTORIZACIÓN DE POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE
        LOS DATOS PERSONALES (2022-POLPRITRADATOS)
      </Typography>
      <Box
        width="50%"
        display="flex"
        justifyContent="space-between"
        margin="12px auto"
      >
        <Typography variant="subtitle2">
          Fecha de versión: 01 Julio 2022
        </Typography>
        <Typography variant="subtitle2">
          Código: 2022 - CERPRIVATRADP003
        </Typography>
      </Box>

      <Box>
        <Table
          style={{ border: '1px solid #767676', borderCollapse: 'collapse' }}
        >
          <TableCell
            colSpan={4}
            style={{
              textAlign: 'center',
              background: '#AFAFAF',
              border: '1px solid #767676',
              borderCollapse: 'collapse',
            }}
          >
            <Typography variant="h6" style={{ color: '#000' }}>
              DATOS BÁSICOS DEL USUARIO TITULAR (OTORGANTE AUTORIZACIÓN)
            </Typography>
          </TableCell>
          <TableRow>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                {data?.entity === 'person' ? 'Nombre completo' : 'Razón Social'}
              </Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography>{data?.name}</Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                Número de documento
              </Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography>{data?.document}</Typography>
            </TableCell>
          </TableRow>
          {data?.entity !== 'person' ? (
            <>
              <TableRow>
                <TableCell
                  style={{
                    border: '1px solid #767676',
                    borderCollapse: 'collapse',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Representante Legal
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    border: '1px solid #767676',
                    borderCollapse: 'collapse',
                  }}
                >
                  <Typography>{data?.nameRL}</Typography>
                </TableCell>
                <TableCell
                  style={{
                    border: '1px solid #767676',
                    borderCollapse: 'collapse',
                  }}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    Documento Representante
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    border: '1px solid #767676',
                    borderCollapse: 'collapse',
                  }}
                >
                  <Typography>{data?.documentRL}</Typography>
                </TableCell>
              </TableRow>
            </>
          ) : null}
          <TableRow>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>Dirección</Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography>{data?.direction}</Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>Celular</Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography>{data?.cellphone}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                Correo Electrónico
              </Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography>{data?.email}</Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                Nombre de usuario
              </Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography>{data?.username}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                Perfil Usuario
              </Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography>{data?.userType}</Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                Fecha Registro
              </Typography>
            </TableCell>
            <TableCell
              style={{
                border: '1px solid #767676',
                borderCollapse: 'collapse',
              }}
            >
              <Typography>
                {dayjs(data?.registerDate).format('DD-MM-YYYY HH:mm:ss')}
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
      </Box>

      <Box margin="12px 0px">
        <Typography style={{ padding: '8px 0px' }}>
          Dando cumplimiento a lo dispuesto en la Ley 1581 de 2012, "Por el cual
          se dictan disposiciones generales para la protección de datos
          personales" y de conformidad con lo señalado en el Decreto 1377 de
          2013, con la firma y/o aceptación del presente documento, manifiesto
          que he sido informado plenamente por MERCADEO VIRTUAL S.A. a través de
          su (s) Plataforma (s) Web: <strong>InfoPorcinos™ </strong>{' '}
          <a
            href="http://www.infoporcinos.com/sitio/Principal.aspx"
            target="_blank"
          >
            www.infoporcinos.com
          </a>{' '}
          sobre los siguientes puntos:
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          1. La empresa MERCADEO VIRTUAL S.A. actuará como el Responsable del
          Tratamiento de datos personales de los cuales soy titular
          conjuntamente con: CENTRAL GANADERA S.A. (Planta de Beneficio Animal)
          y los distintos Agentes y Clientes de la RED DE NEGOCIOS de la
          Plataforma <strong>InfoPorcinos™</strong> que he creado y registrado
          en dicha plataforma, o de forma separada, se autoriza y habilita el
          recolectar, almacenar, procesar, distribuir, usar y tratar mis datos
          personales conforme la POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES Y
          PRIVACIDAD DE MERCADEO VIRTUAL S.A. (2022 - POLTRADAPER0) vigente a la
          fecha del presente documento y que está disponible en la página web{' '}
          <a
            href="http://www.infoporcinos.com/sitio/Principal.aspx"
            target="_blank"
          >
            www.infoporcinos.com
          </a>
          .
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          2. Que me ha sido informada en detalle la (s) finalidad (es) y los uso
          (s) finales de la recolección de los datos personales, la cual
          consiste en que mis Datos Personales y mis Datos Técnicos de sus
          Operaciones en nuestras Plataformas de Información relacionadas con el
          Beneficio Animal, Comercialización Productos Cárnicos (Canales
          Animales para Abasto Público, Cortes de Carne Primarios y Comerciales,
          Sistema de Trazabilidad Animal, Sistemas de Información Estadístico y
          Logística de Distribución entre otros, serán incluidos en una o más
          bases de datos y podrán ser transmitidos y/o transferidos entre
          <strong>InfoPorcinos™</strong>, sus subordinadas vinculadas, su matriz
          o controlante, las subordinadas de su matriz o controlante, (en
          adelante “Las Entidades Autorizadas”), para que directamente o a
          través de terceros, incluyendo a la Planta de Beneficio CENTRAL
          GANADERA S.A, sus diferentes agentes o integrantes de su RED DE
          NEGOCIOS, traten los Datos Personales de acuerdo con los fines
          establecidos en el presente documento de autorización. De igual forma,
          las bases de datos de
          <strong>InfoPorcinos™</strong> podrán incluir e integrar datos
          transmitidos y/o transferidos a esta por las Entidades Autorizadas y/o
          por terceros.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          3. Es de carácter facultativo o voluntario de parte del Usuario, el
          responder preguntas sobre aquellos DATOS SENSIBLES definidos como
          aquellos que afectan la intimidad del Titular y pueden dar lugar a que
          sea discriminado, como aquellos que revelan su origen racial o étnico,
          su orientación política, las convicciones religiosas o filosóficas, la
          pertenencia a sindicatos, organizaciones sociales, de derechos
          humanos, así como los datos relativos a la salud, a la vida sexual, y
          los datos biométricos o sobre aquellos datos de personas menores de
          edad. Que se me ha informado por parte de la empresa MERCADEO VIRTUAL
          S.A. que sus Plataformas de Información como{' '}
          <a
            href="http://www.infoporcinos.com/sitio/Principal.aspx"
            target="_blank"
          >
            www.infoporcinos.com
          </a>
          , entre otras relacionadas, se definen como plataformas de información
          con acceso únicamente para Usuarios Mayores de Edad, y que durante los
          procesos de registro de datos personales no se realizan ningún tipo de
          recolección de Datos Sensibles bajo ningún método, objetivo o
          justificación según los términos de la ley.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          4. Mis derechos como titular de los datos personales y datos técnicos
          (compartidos e individuales) se declaran legalmente de tipo:
          SEMIPRIVADOS Y PÚBLICOS, a la luz de la ley de Habeas Data vigente a
          la fecha del presente documento para cualquier efecto, son los
          previstos en la Constitución política de Colombia y la ley,
          especialmente en el derecho a conocer, actualizar, rectificar y
          suprimir mi información personal, así como el derecho a revocar
          (“darse de baja”) el consentimiento otorgado para el tratamiento de
          datos personales en cualquier tiempo y lugar durante la prestación del
          servicio.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          5. Los derechos pueden ser ejercidos a través de los canales
          dispuestos por MERCADEO VIRTUAL S.A. y observando POLÍTICA DE
          TRATAMIENTO DE DATOS PERSONALES Y PRIVACIDAD DE MERCADEO VIRTUAL S.A.
          (2022 - POLTRADAPER0)
          <br />
        </Typography>

        <Typography style={{ padding: '12px 0px' }}>
          6. Mediante la página web ({' '}
          <a
            href="http://www.infoporcinos.com/sitio/Principal.aspx"
            target="_blank"
          >
            www.infoporcinos.com
          </a>{' '}
          ), podré radicar cualquier tipo de requerimiento relacionado con el
          tratamiento de mis datos personales.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          7. MERCADEO VIRTUAL S.A. garantizará la confidencialidad, libertad,
          seguridad, veracidad, transparencia, acceso y circulación restringida
          de mis datos y se reservará el derecho de modificar su POLÍTICA DE
          TRATAMIENTO DE DATOS PERSONALES Y PRIVACIDAD DE MERCADEO VIRTUAL S.A.
          (2022 - POLTRADAPER0) en cualquier momento y condición. Cualquier
          cambio será informado y publicado oportunamente en la página web y/o a
          los correos electrónicos registrados por el Usuario Titular.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          8. Teniendo en cuenta lo anterior, autorizo de manera voluntaria,
          previa, explícita, informada e inequívoca a la empresa MERCADEO
          VIRTUAL S.A. para tratar mis datos personales y tomar mi huella y
          fotografías de acuerdo con su POLÍTICA DE TRATAMIENTO DE DATOS
          PERSONALES Y PRIVACIDAD DE MERCADEO VIRTUAL S.A. (2022 - POLTRADAPER0)
          para los fines relacionados con su objeto y en especial para fines
          legales, contractuales, misionales descritos en dicha política.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          9. La información obtenida para el TRATAMIENTO DE MIS DATOS PERSONALES
          la he suministrado de forma voluntaria y es verídica. La exactitud,
          veracidad y la legalidad de los Datos Personales registrados por el
          Usuario Titular son de ÚNICA Y EXCLUSIVA responsabilidad ante los
          antes de control legal y judicial de Colombia. Igualmente, MERCADEO
          VIRTUAL S.A. certifica que el USUARIO TITULAR ha sido informado en
          detalle sobre otras Políticas que se han definido por nuestra empresa
          para su sitio web{' '}
          <a
            href="http://www.infoporcinos.com/sitio/Principal.aspx"
            target="_blank"
          >
            www.infoporcinos.com
          </a>{' '}
          donde se puede confirmar y certificar que dicho Usuario previa proceso
          de información, ha leído y aceptado los siguientes acuerdos:
          <br /> <br />
          <hr />
          <Typography variant="h6" color="secondary" align="center">
            Términos y Condiciones de Uso Plataforma y la Política de Datos de
            Navegación de InfoPorcinos™
          </Typography>
          <Box
            width="50%"
            display="flex"
            justifyContent="space-between"
            margin="12px auto"
          >
            <Typography variant="subtitle2">
              Fecha de versión: 01 Julio 2022
            </Typography>
            <Typography variant="subtitle2">
              Código: 2022 - TERCONAVWEB
            </Typography>
          </Box>
        </Typography>
      </Box>
      <hr />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <strong>MERCADEO VIRTUAL S.A.</strong>
          <br />
          Calle 48 No. 77 – 78 Sector Estadio Velódromo <br />
          Zona Postal 050034
          <br /> Medellín – Colombia <br />
          Pbx + 57 604 3228603
          <br />
          <a href="http://www.mercadeo-virtual.com" target="_blank">
            www.mercadeo-virtual.com
          </a>{' '}
          /{' '}
          <a
            href="http://www.infoporcinos.com/sitio/Principal.aspx"
            target="_blank"
          >
            www.infoporcinos.com
          </a>
          <br />
          Correo: info@infoporcinos.com
        </Box>
        <Box marginRight={2}>
          <img src="https://raw.githubusercontent.com/repositoriosmv/infoporcinos-images/main/logo-mv1.png" />
        </Box>
      </Box>
    </div>
  );
};

export default Certificate;
