import { gql } from "apollo-boost";

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation(
  $idInvitacion: Int!
  $estado: Boolean!
){
  acceptInvitation(input: {
    idInvitacion: $idInvitacion,
    estado: $estado
	}){
    data{
      success
      message
    }
    token{
      tokenNuevo
      fechaExpiracion
    }
  }
}
`;