import React from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import config from "config/secrets";
import { Backdrop } from "@material-ui/core";

const Loader = () => <Backdrop open>Cargando mapa</Backdrop>;

export interface MapProps {
  initialCenter?: any;
  coords?: {
    lat: any;
    lng: any;
  };
  google: any;
  onClick?: any;
}

const MapInput = ({ initialCenter, google, coords, onClick }: MapProps) => {
  const handleClick = (_: any, __: any, clickEvent: any) => {
    onClick({ lat: clickEvent.latLng.lat(), lng: clickEvent.latLng.lng() });
  };

  return (
    <Map
      google={google}
      containerStyle={{ top: 0, left: 0 }}
      onClick={handleClick}
      zoom={18}
      zoomControl={false}
      initialCenter={initialCenter}
      fullscreenControl={false}
      mapTypeControl={false}
      streetViewControl={false}
      center={coords}
    >
      <Marker position={coords} mapCenter={coords}></Marker>
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: config.API_KEY,
  LoadingContainer: Loader,
})<MapProps>(MapInput);
