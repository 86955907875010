import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogContainer:{
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px'
    }
  },
  steps:{
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: theme.palette.primary.main, 
      fontWeight: 'bold'
     },
     '& .MuiStepLabel-completed':{
       color: '#AFAFAF'
     }
  },
  dialogFooter:{
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center', 
    margin: '20px 0px'
  },
  checkbox:{
    "& .MuiTypography-root":{
      color: '#767676', 
      fontWeight: 'bold'
    }
  }
}))

export { useStyles as default }
