import { Document, Page, Image, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import plantIcon from 'static/img/reportsIcons/plantIcon';
import ticketLogo from 'static/img/reportsIcons/ticketLogo';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import TableInfo from 'components/pdf-components/TableInfo/index';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import ItemTotal from 'components/pdf-components/ItemTotal';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF/index';
import ChartList from 'components/pdf-components/ChartList/index';
import HorizontalTableInfo from 'components/pdf-components/HorizontalTableInfo/index';
import NumberTotal from 'components/pdf-components/NumberTotal';
import DetailTable from 'components/pdf-components/DetailTable/index';

import styles from './styles/filter';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const Detalles = [
  {
    lote: '4321',
    fecha: '01/10/2019',
    granja: 'El paraiso de las maravilla',
    totalAnimales: 54,
    caidos: 0,
    agitados: 12,
    muertos: 0,
    sexo: 'A',
    inmuno: 'si',
    pesoTotal: 888.0,
    pesoProm: 110.23,
  },
  {
    lote: '4321',
    fecha: '01/10/2019',
    granja: 'El paraiso de las maravilla',
    totalAnimales: 54,
    caidos: 0,
    agitados: 12,
    muertos: 0,
    sexo: 'A',
    inmuno: 'si',
    pesoTotal: 888.0,
    pesoProm: 110.23,
  },
  {
    lote: '4321',
    fecha: '01/10/2019',
    granja: 'El paraiso de las maravilla',
    totalAnimales: 54,
    caidos: 0,
    agitados: 12,
    muertos: 0,
    sexo: 'A',
    inmuno: 'si',
    pesoTotal: 888.0,
    pesoProm: 110.23,
  },
  {
    lote: '4321',
    fecha: '01/10/2019',
    granja: 'El paraiso de las maravilla',
    totalAnimales: 54,
    caidos: 0,
    agitados: 12,
    muertos: 0,
    sexo: 'A',
    inmuno: 'si',
    pesoTotal: 888.0,
    pesoProm: 110.23,
  },
  {
    lote: '4321',
    fecha: '01/10/2019',
    granja: 'El paraiso de las maravilla',
    totalAnimales: 54,
    caidos: 0,
    agitados: 12,
    muertos: 0,
    sexo: 'A',
    inmuno: 'si',
    pesoTotal: 888.0,
    pesoProm: 110.23,
  },
];

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
  graph?: string;
  graphTwo?: string;
  range?: null | string;
}

export const MyDocFilterBalanceTicket = ({
  qr,
  uid,
  graph,
  graphTwo,
  range,
}: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        range={range}
        reportImage={ticketLogo}
        number="023456789"
        title="Reporte Técnico Tiquete de Báscula Corrales"
        code="TB-001"
      />

      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ width: '100%' }}>
        <HorizontalTableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            Nit: '34123412-2',
            Telefono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <View
          style={{
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: '#767676',
            marginTop: '10px',
          }}
        ></View>
        <HorizontalTableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />

        <View style={{ marginTop: '10px' }}>
          <Subtitle
            title="Resumen Pesaje Animales en Pie Corrales"
            widthContainer="100%"
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '5px',
        }}
      >
        <View style={{ width: '17.2%', justifyContent: 'center' }}>
          <ItemTotalCircle
            value="10"
            info="Productores Diferentes"
            widthInfo="40px"
            position="right"
            textAlign="left"
            backgroundColor="#e29662"
          />
        </View>
        <View style={{ width: '22.2%', justifyContent: 'center' }}>
          <ItemTotal
            value="1000"
            info="Animales en Pie Pesados"
            widthValue={50}
            fontSizeValue={12}
            position="right"
            backgroundColor="#F49A28"
          />
        </View>
        <View style={{ width: '22.2%', justifyContent: 'center' }}>
          <ItemTotal
            value="00:10:04"
            info="Tiempo Promedio Desembarque"
            widthValue={60}
            widthInfo="60px"
            fontSizeValue={12}
            position="right"
            backgroundColor="#d47d57"
          />
        </View>
        <View style={{ width: '17.2%', justifyContent: 'center' }}>
          <NumberTotal
            value="3.996,00 Kg"
            info="Peso Total Animales en Pie"
            position="bottom"
            widthInfo="80px"
            widthValue="100px"
            justifyValue="center"
            justifyInfo="center"
            colorValue="#373737"
            fontSizeValue={14}
          />
        </View>
        <View style={{ width: '17.2%', justifyContent: 'center' }}>
          <NumberTotal
            value="110,23 Kg"
            info="Promedio Peso Pie del Lote"
            position="bottom"
            widthInfo="60px"
            widthValue="100px"
            justifyValue="center"
            justifyInfo="center"
            colorValue="#373737"
            fontSizeValue={14}
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '5px',
        }}
      >
        <View style={{ width: '48.3%' }}>
          <Subtitle
            title="Estado Animales en Pie Ingreso Corrales"
            widthContainer="100%"
          />
          <View style={{ flexDirection: 'row', marginTop: '5px' }}>
            <View
              style={{
                width: '31.2%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ItemTotalCircle
                value="10"
                position="right"
                textAlign="left"
                backgroundColor="#e29662"
              />
            </View>
            <View style={{ width: '31.2%' }}>
              <ItemTotal
                value="01"
                info="Animales Agitados"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                widthInfo="60px"
                position="right"
              />
              <ItemTotal
                value="02"
                info="Animales Caidos"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                widthInfo="60px"
                position="right"
              />
            </View>
            <View style={{ width: '31.2%' }}>
              <ItemTotal
                value="01"
                info="Muertos Transporte"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                widthInfo="70px"
                position="right"
              />
              <ItemTotal
                value="03"
                info="Muertos Desembarque"
                backgroundColor="#fff"
                fontColor="#373737"
                heightValue={18}
                fontSizeValue={9}
                widthValue={10}
                widthInfo="70px"
                position="right"
              />
            </View>
          </View>
        </View>
        <View style={{ width: '48.3%' }}>
          <Subtitle title="Animales en Corrales" widthContainer="100%" />

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '5px',
            }}
          >
            <View
              style={{
                width: '48.3%',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="45"
                info="Animales Corral de Reposo"
                widthInfo="50px"
                position="right"
                textAlign="left"
                backgroundColor="#d47d57"
              />
            </View>
            <View style={{ width: '48.3%' }}>
              <ItemTotalCircle
                value="03"
                info="Animales Corral de Observación"
                widthInfo="50px"
                position="right"
                textAlign="left"
                backgroundColor="#f1ae6c"
              />
            </View>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '48.3%' }}>
          <Subtitle title="Tipo de Cerdos" widthContainer="100%" />

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                height: '100px',
                marginTop: 10,
                marginBottom: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image src={graph} style={{ height: '150px', width: '150px' }} />
            </View>
            <View>
              <ChartList
                data={[
                  {
                    label: 'CE',
                    data: 702,
                    bg: '#FFC676',
                  },
                  {
                    label: 'CD',
                    data: 162,
                    bg: '#F1AE6C',
                  },
                  {
                    label: 'C150',
                    data: 136,
                    bg: '#E29662',
                  },
                ]}
              />
            </View>
          </View>
        </View>
        <View style={{ width: '48.3%' }}>
          <Subtitle title="Animales Inmunocastrados" widthContainer="100%" />

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                height: '100px',
                marginTop: 10,
                marginBottom: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                src={graphTwo}
                style={{ height: '150px', width: '150px' }}
              />
            </View>

            <View style={{ marginRight: '20px' }}>
              <ChartList
                data={[
                  {
                    label: 'Sin Inmunocastrar',
                    data: 300,
                    bg: '#FFC676',
                  },
                  {
                    label: 'Inmunocatrados',
                    data: 700,
                    bg: '#F1AE6C',
                  },
                ]}
              />
            </View>
          </View>
        </View>
      </View>

      <Subtitle
        title="Detalle Pesaje de Animales en Pie por Lote"
        widthContainer="100%"
      />

      <View style={{ marginTop: '5px' }}>
        <DetailTable
          razonSocial="Intercomercializadora Meat Packing S.A.S"
          totalLotes={10}
          totalAnimales={1000}
          data={Detalles}
        />
        <DetailTable
          razonSocial="Intercomercializadora Meat Packing S.A.S"
          totalLotes={10}
          totalAnimales={1000}
          data={Detalles}
        />
        <DetailTable
          razonSocial="Intercomercializadora Meat Packing S.A.S"
          totalLotes={10}
          totalAnimales={1000}
          data={Detalles}
        />
      </View>

      <View style={styles.conventions} wrap={false}>
        <View style={styles.backgroundguide}>
          <View style={styles.textconventionsLong}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * CE
              </Text>
            </View>
            <View style={styles.textLong}>
              <Text>Cerdo de Engorde</Text>
            </View>
          </View>
          <View style={styles.textconventionsLong}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * CD
              </Text>
            </View>
            <View style={styles.textLong}>
              <Text>Cerdo de Descarte</Text>
            </View>
          </View>
          <View style={styles.textconventionsLong}>
            <View style={[styles.gsmi, { width: 30 }]}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * C150
              </Text>
            </View>
            <View style={styles.textLong}>
              <Text>Centro de más de 150kg</Text>
            </View>
          </View>
          <View style={styles.textconventionsShort}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * (H)
              </Text>
            </View>
            <View style={styles.textShort}>
              <Text>Hembra</Text>
            </View>
          </View>
          <View style={styles.textconventionsShort}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * (M)
              </Text>
            </View>
            <View style={styles.textShort}>
              <Text>Macho</Text>
            </View>
          </View>
          <View style={styles.textconventionsShort}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * (A)
              </Text>
            </View>
            <View style={styles.textShort}>
              <Text>Ambos</Text>
            </View>
          </View>
        </View>
        <View style={styles.borderAM}>
          <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
            Convenciones
          </Text>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportFilter = () => {
  return (
    <div id="screenQrTicketFilter" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte Técnico Tiquete de Báscula Corrales"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphRangeTicket = () => {
  return (
    <div id="graprangeticket" style={{ width: 500, marginTop: -20 }}>
      <Graphicpdfsf
        textTotalData="Total"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        startAngle={-50}
        endAngle={310}
        // removepercentages
        data={[
          {
            label: 'CE',
            data: 702,
            bg: '#FFC676',
          },
          {
            label: 'CD',
            data: 162,
            bg: '#F1AE6C',
          },
          {
            label: 'C150',
            data: 136,
            bg: '#E29662',
          },
        ]}
      />
    </div>
  );
};

export const GraphRangeTicketTwo = () => {
  return (
    <div id="graprangeticketwo" style={{ width: 500, marginTop: -20 }}>
      <Graphicpdfsf
        textTotalData="Total"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        startAngle={-90}
        endAngle={270}
        // removepercentages
        data={[
          {
            label: 'Sin Inmunocastrar',
            data: 300,
            bg: '#FFC676',
          },
          {
            label: 'Inmunocatrados',
            data: 700,
            bg: '#F1AE6C',
          },
        ]}
      />
    </div>
  );
};
