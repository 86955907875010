import axios from "axios";

import config from "config/secrets";

const getEmailRequest = async (requestId: string) => {
  return await axios
    .get(
      `${config.AUTH}${config.APP_URI}${config.APP_VERSION}/system_access/get_mail/${requestId}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      if (error.response) return error.response;
      return error;
    });
};

export { getEmailRequest };
