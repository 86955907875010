import React from "react";

import {
  Box,
  Typography,
  Button,
  Card,
  Avatar,
  Chip,
  Divider,
} from "@material-ui/core";

import ItemListData from "components/ItemListData";

import avatars from "lib/helpers/dynamic-images/avatars";

import dayjs from "dayjs";
import clsx from "clsx";

import useStyles from "./styles";

interface InvitationCardProps {
  name: string;
  username: string;
  avatar?: any;
  personType: string;
  invitationDate: any;
  myRol: number;
  yourRol: number;
  onAccept: (event: any) => void;
  onDecline: (event: any) => void;
  className?: any;
}

const InvitationCard = ({
  name,
  username,
  avatar,
  personType,
  invitationDate,
  myRol,
  yourRol,
  onAccept,
  onDecline,
  className,
}: InvitationCardProps) => {
  const classes = useStyles();

  const userType = (type: any) =>
    type === 1 ? "Productor" : "Comercializador";

  return (
    <>
      <Card className={clsx(classes.card, className)}>
        <Box className={classes.cardTitleContainer}>
          <Box display="flex">
            <Avatar
              src={avatar ?? avatars(username)}
              style={{ width: 50, height: 50 }}
            />
            <Box className={classes.cardTitle}>
              <Typography variant="h6" color="secondary">
                {name}
              </Typography>
              <Typography variant="subtitle2" color="secondary">
                @{username}
              </Typography>
            </Box>
          </Box>
          <Chip
            size="medium"
            color="primary"
            variant="outlined"
            label={personType}
          />
        </Box>
        <Typography
          variant="body2"
          color="secondary"
          style={{ textAlign: "end", marginBottom: 4 }}
        >
          {dayjs(invitationDate)
            .format("MMM D YYYY - h:mm A")
            .toLocaleUpperCase()}
        </Typography>
        <Divider />
        <Box>
          <ItemListData
            data={[
              {
                title: "Mi rol en su red de negocios será:",
                data: (
                  <Box className={classes.dataContainer}>
                    {userType(myRol)}{" "}
                    <i
                      className={clsx(
                        myRol === 1 ? "icon-productor" : "icon-comercializador",
                        classes.icon
                      )}
                    />
                  </Box>
                ),
              },
              {
                title: "Su rol en mi red de negocios será:",
                data: (
                  <Box className={classes.dataContainer}>
                    {userType(yourRol)}{" "}
                    <i
                      className={clsx(
                        yourRol === 1
                          ? "icon-productor"
                          : "icon-comercializador",
                        classes.icon
                      )}
                    />
                  </Box>
                ),
              },
            ]}
          />
        </Box>

        <Box marginTop={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: "0px 4px" }}
            onClick={onDecline}
          >
            Rechazar
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "0px 4px" }}
            onClick={onAccept}
          >
            Aceptar
          </Button>
        </Box>
      </Card>
    </>
  );
};

export default InvitationCard;
