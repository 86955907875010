import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import useStyles from './styles';

const DialogAuthorization = ({ children }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {children({ handleOpen })}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        className={classes.dialogContainer}
      >
        <DialogContent>
          <Typography variant="h6" color="secondary">
            Apreciado Usuario:
          </Typography>
          <br />
          <Typography color="secondary">
            Usted está solicitando la{' '}
            <strong>
              REVOCACIÓN DE LA AUTORIZACIÓN DEL TRATAMIENTO DE LOS DATOS
              PERSONALES
            </strong>{' '}
            otorgada a MERCADEO VIRTUAL S.A. a través del sitio web{' '}
            <a
              href="http://www.infoporcinos.com/sitio/Principal.aspx"
              target="_blank"
            >
              www.infoporcinos.com
            </a>{' '}
            , un derecho otorgado a las personas por la ley 1581 de 2012, sus
            Decretos reglamentarios y normas modificatorias o complementarias. Y
            acogida por nuestra empresa para establecer el proceso de registro y
            creación de Usuarios de nuestras Plataformas de Información acorde a
            la ley y las Buenas Prácticas sobre Manejo de Datos Personales.
            <br /> <br />
            En el caso de que Usted continúe en su decisión de revocar dicha
            autorización, la empresa MERCADEO VIRTUAL S.A. iniciará en este
            punto de la navegación, el Proceso de <strong>
              DAR DE BAJA
            </strong>{' '}
            como Usuario de la Plataforma de Información{' '}
            <a
              href="http://www.infoporcinos.com/sitio/Principal.aspx"
              target="_blank"
            >
              www.infoporcinos.com
            </a>{' '}
            y haciendo que la totalidad de su información y datos personales sea
            clasificada y registrada como <strong>INACTIVA</strong> en las Bases
            de Datos de cualquier servicio de información de nuestra empresa.
            <br /> <br />
            Las consecuencias de dicha revocatoria genera como consecuencia que
            Usted no puede ingresar como Usuario a nuestras Plataformas de
            Información, y nuestra empresa no podrá utilizar sus datos
            personales para ningún tipo de servicio de información, ya que se
            encuentran como inactivo.
            <br /> <br />
            Nuestra empresa notificará en los medios de comunicación previstos
            por usted sobre la culminación de la revocatoria de la Autorización
            sobre Datos Personales y Darse de Baja.
            <br /> <br />
            Agradecemos que haya sido parte de nuestras Plataformas de
            Información.
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/app/profile/unsubscribe"
          >
            Darse de baja
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogAuthorization;
