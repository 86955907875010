import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  itemList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 8,
  },
  child:{
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px', 
  },
  listIcon: {
    fontSize: 36,
    paddingRight: 12,
    color: theme.palette.primary.main,
  },
  listDefaultIcon: {
    fontSize: 26,
    paddingRight: 12,
    color: theme.palette.primary.main,
  },
  infoListContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#AFAFAF',
  },
  listNumber: {
    fontWeight: 'bold',
    fontSize: 16,
  },
}));

export { useStyles as default };
