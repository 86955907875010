import { Grid, Paper, SnackbarContent, Typography } from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { forwardRef } from 'react';
import moment from 'moment';
import _ from 'lodash';

import config from 'config/secrets';

import notificationIcons from 'lib/helpers/notification-icon';
import avatars from 'lib/helpers/dynamic-images/avatars';

import AvatarBadge from '../avatarBadge';

import useStyles from './styles';

interface NotificationContentProps {
  id: string | number;
  message: string;
  time: any;
  avatar: {
    resource: string;
    username: string;
  };
  category: keyof typeof notificationIcons;
}

const NotificationContent = forwardRef(
  ({ id, message, time, avatar, category }: NotificationContentProps, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const handleDismiss = () => closeSnackbar(id);

    function getAvatar() {
      return avatar?.resource
        ? `${config.SOCKETS}${avatar.resource}`
        : avatars(avatar.username);
    }

    function getIcon() {
      return notificationIcons[category] ?? notificationIcons.DEFAULT;
    }

    return (
      <SnackbarContent
        ref={ref}
        classes={{
          root: classes.root,
          message: classes.message,
        }}
        message={
          <Paper
            classes={{
              root: classes.card,
            }}
          >
            <div className={classes.notification_avatar}>
              <AvatarBadge Icon={getIcon()} src={avatar && getAvatar()} />
            </div>
            <Grid container alignItems="stretch">
              <Grid item xs={12} className={classes.message_content}>
                <Typography className={classes.message_main} color="primary">
                  {message}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="p"
                >
                  {_.capitalize(moment(time).startOf(time).fromNow())}
                </Typography>
              </Grid>
            </Grid>
            <div>
              <button className={classes.close_button} onClick={handleDismiss}>
                <CancelRounded />
              </button>
            </div>
          </Paper>
        }
      />
    );
  }
);

export { NotificationContent as default };
