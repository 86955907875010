import React, { useEffect, useState } from "react";
import { TextField, MenuItem, Grid, CircularProgress } from "@material-ui/core";
import { COUNTRIES, DEPARTMENTS, CITIES } from "core/graphql/queries/countries";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import constants from "config/constants";
import useStyles from "./styles";

interface SelectCountryProps {
  names: {
    country: string;
    departament: string;
    city: string;
  };
  values: {
    country: string | any;
    departament: string | any;
    city: string | any;
  };
  errors: {
    country?: string;
    departament?: string;
    city?: string;
  };
  helperText: {};
  onChange: (event: any) => void;
  disable?: boolean;
  direction?: "horizontal" | "vertical";
}

const SelectCountry = ({
  names,
  values,
  errors,
  helperText,
  onChange,
  disable,
  direction,
}: SelectCountryProps) => {
  const classes = useStyles();

  const token = useSelector((state: any) => state.user.token);
  const [valueCountry, setValueCountry] = useState<any>(48);
  const [valueDept, setValueDept] = useState(2890);
  const [valueCity, setValueCity] = useState(21074);

  const { loading, error, data } = useQuery(COUNTRIES, {
    context: { headers: { "x-auth-jwt": token } },
  });

  const {
    loading: loadingDep,
    error: errorDep,
    data: dataDep,
    ...department
  } = useQuery(DEPARTMENTS, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idCountry: values.country },
  });

  const {
    loading: loadingCity,
    error: errorCity,
    data: dataCity,
    ...city
  } = useQuery(CITIES, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idState: values.departament },
  });

  const handleChangeSelect = (event: any) => {
    onChange(event);

    if (event.target.name === names.country) {
      department.refetch({ idCountry: event.target.value });
      city.refetch({ idState: 0 });
    } else if (event.target.name === names.departament) {
      city.refetch({ idState: values.departament });
    }
  };

  useEffect(() => {
    if (values.country) {
      setValueCountry(values.country);
    }
  }, [values]);

  useEffect(() => {
    if (values.departament) {
      setValueDept(values.departament);
    }
  }, [values]);

  useEffect(() => {
    if (values.city) {
      setValueCity(values.city);
    }
  }, [values]);

  return (
    <>
      {direction === "vertical" ? (
        <Grid container spacing={2} alignItems="stretch" direction="column">
          <Grid item>
            <TextField
              label="País"
              size="small"
              variant="outlined"
              fullWidth
              name={names.country}
              value={
                valueCountry != 0
                  ? valueCountry
                  : (values.country = constants.DEFAULT_COUNTRY_ID)
              }
              onChange={handleChangeSelect}
              error={!!errors.country}
              helperText={errors.country}
              select
              className={classes.select}
              FormHelperTextProps={helperText}
              disabled={disable}
            >
              {loading ? (
                <CircularProgress variant="indeterminate" size={25} />
              ) : error ? (
                <p>Error</p>
              ) : (
                data.countries.map((countries: any) => (
                  <MenuItem key={countries.id} value={countries.id}>
                    {countries.name}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              label="Departamento"
              size="small"
              variant="outlined"
              fullWidth
              name={names.departament}
              value={
                valueDept != 0
                  ? valueDept
                  : (values.departament = constants.DEFAULT_DEPARTMENT_ID)
              }
              onChange={handleChangeSelect}
              error={!!errors.departament}
              helperText={errors.departament}
              select
              disabled={
                !dataDep || dataDep?.departments?.length == 0 || disable
              }
              FormHelperTextProps={helperText}
              className={classes.select}
            >
              {loadingDep ? (
                <CircularProgress variant="indeterminate" size={25} />
              ) : errorDep ? (
                <p>Error</p>
              ) : (
                dataDep?.departments?.map((departments: any) => (
                  <MenuItem
                    key={departments.idDepartment}
                    value={departments.idDepartment}
                  >
                    {departments.departmentName}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              label="Ciudad"
              size="small"
              variant="outlined"
              fullWidth
              name={names.city}
              value={
                valueCity != 0
                  ? valueCity
                  : (values.city = constants.DEFAULT_CITY_ID)
              }
              onChange={handleChangeSelect}
              error={!!errors.city}
              helperText={errors.city}
              select
              className={classes.select}
              disabled={!dataCity || dataCity?.cities?.length == 0 || disable}
              FormHelperTextProps={helperText}
            >
              {loadingCity ? (
                <CircularProgress variant="indeterminate" size={25} />
              ) : errorCity ? (
                <p>Error</p>
              ) : (
                dataCity?.cities?.map((cities: any) => (
                  <MenuItem key={cities.idCity} value={cities.idCity}>
                    {cities.nameCity}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={4}>
            <TextField
              label="País"
              size="small"
              variant="outlined"
              fullWidth
              name={names.country}
              value={
                valueCountry != 0
                  ? valueCountry
                  : (values.country = constants.DEFAULT_COUNTRY_ID)
              }
              onChange={handleChangeSelect}
              error={!!errors.country}
              helperText={errors.country}
              select
              className={classes.select}
              FormHelperTextProps={helperText}
              disabled={disable}
            >
              {loading ? (
                <CircularProgress variant="indeterminate" size={25} />
              ) : error ? (
                <p>Error</p>
              ) : (
                data.countries.map((countries: any) => (
                  <MenuItem key={countries.id} value={countries.id}>
                    {countries.name}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Departamento"
              size="small"
              variant="outlined"
              fullWidth
              name={names.departament}
              value={
                valueDept != 0
                  ? valueDept
                  : (values.departament = constants.DEFAULT_DEPARTMENT_ID)
              }
              onChange={handleChangeSelect}
              error={!!errors.departament}
              helperText={errors.departament}
              select
              disabled={
                !dataDep || dataDep?.departments?.length == 0 || disable
              }
              FormHelperTextProps={helperText}
              className={classes.select}
            >
              {loadingDep ? (
                <CircularProgress variant="indeterminate" size={25} />
              ) : errorDep ? (
                <p>Error</p>
              ) : (
                dataDep?.departments?.map((departments: any) => (
                  <MenuItem
                    key={departments.idDepartment}
                    value={departments.idDepartment}
                  >
                    {departments.departmentName}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Ciudad"
              size="small"
              variant="outlined"
              fullWidth
              name={names.city}
              value={
                valueCity != 0
                  ? valueCity
                  : (values.city = constants.DEFAULT_CITY_ID)
              }
              onChange={handleChangeSelect}
              error={!!errors.city}
              helperText={errors.city}
              select
              className={classes.select}
              disabled={!dataCity || dataCity?.cities?.length == 0 || disable}
              FormHelperTextProps={helperText}
            >
              {loadingCity ? (
                <CircularProgress variant="indeterminate" size={25} />
              ) : errorCity ? (
                <p>Error</p>
              ) : (
                dataCity?.cities?.map((cities: any) => (
                  <MenuItem key={cities.idCity} value={cities.idCity}>
                    {cities.nameCity}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SelectCountry;
