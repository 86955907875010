import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  color:{
    fill: '#ffc600',
    
  },
  gridContainer:{
    [theme.breakpoints.between('sm', 'md')]: {
      position: 'absolute',
      width: 250,
      marginLeft: 50
    },
    [theme.breakpoints.between('md', 'lg')]: {
      position: 'absolute',
      width: 300,
      marginLeft: 65
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      position: 'absolute',
      width: 350,
      marginLeft: 65
    },
  },
  input:{
    [theme.breakpoints.between('sm', 'md')]: {
      width: 80,
      "& .MuiInputBase-input":{
        fontSize: 35,
        fontWeight: 'bolder',
        marginTop: 27,
        textTransform: 'uppercase',
      }
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: 90,
      "& .MuiInputBase-input":{
        fontSize: 45,
        fontWeight: 'bolder',
        marginTop: 35,
        textTransform: 'uppercase',
      }
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      width: 120,
      "& .MuiInputBase-input":{
        fontSize: 55,
        fontWeight: 'bolder',
        marginTop: 33,
        textTransform: 'uppercase',
      }
    },
  }
}));

export { useStyles as default }