import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
  Button,
  Step,
  Stepper,
  StepLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import Slider from 'components/Slider';
import ExistingUser from 'components/ExistingUser';
import SelectDate from 'components/SelectDate';
import reportsIcons from 'lib/helpers/reportsIcons';

import dayjs from 'dayjs';
import _ from 'lodash';

import useStyles from './styles';

interface AddToFrequentProps {
  children: any;
}

const AddToFrequent = ({ children }: AddToFrequentProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [userSelected, setUserSelected] = useState(0);
  const [filterSelected, setFilterSelected] = useState<any>([]);
  const [reportsSelected, setReportsSelected] = useState<any>([]);
  const [initialDate, setInitialDate] = useState(dayjs());
  const [finalDate, setFinalDate] = useState<any>();
  const [shareUnlimited, setShareUnlimited] = useState(false);
  const steps = [
    'Selección de usuario',
    'Selección de filtro',
    'Selección de reportes',
    'Selección del tiempo limite',
  ];

  const usersToShare = [
    {
      name: 'Leidy Marcela Álvarez',
      info: 'CC 1000295214',
      id: 4,
      profile: 'Comercializador',
    },
    {
      name: 'Juan Sebastian Izquierdo',
      info: 'CC 154782220',
      id: 5,
      profile: 'Productor',
    },
    {
      name: 'Sebastian Mosquera',
      info: 'CC 121478510',
      id: 6,
      profile: 'Comercializador',
    },
    {
      name: 'Paula Andrea Bolivar',
      info: 'CC 19876521',
      id: 7,
      profile: 'Productor - Comercializador',
    },
  ];

  const destinations = [
    {
      name: 'Exito Laureles',
      info: 'Calle 43 # 13 A 21',
      id: 4,
    },
    {
      name: 'Ricata',
      info: 'Carrera 43C # 13 C 11',
      id: 5,
    },
    {
      name: 'Zenú',
      info: 'Carrera 77A # 18 - 11',
      id: 6,
    },
  ];

  const reports = [
    {
      reportType: 'Todos',
      id: 1,
    },
    {
      reportType: 'Portería',
      id: 2,
    },
    {
      reportType: 'Guias de Movilización',
      id: 3,
    },
    {
      reportType: 'Tiquete de Báscula',
      id: 4,
    },
    {
      reportType: 'Inmunocastración',
      id: 5,
    },
    {
      reportType: 'Antemortem',
      id: 6,
    },
    {
      reportType: 'Visceras Blancas',
      id: 7,
    },
    {
      reportType: 'Postmortem',
      id: 8,
    },
    {
      reportType: 'Evaluación de magro',
      id: 9,
    },
    {
      reportType: 'Distribución y Despacho',
      id: 10,
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  };
  const handleChange = (e: number) => {
    setUserSelected(e);
  };
  const changeFilterSelected = (filter: number) => {
    if (filterSelected.includes(filter)) {
      const removed = filterSelected.filter((item: any) => item !== filter);
      setFilterSelected(removed);
    } else {
      setFilterSelected([...filterSelected, filter]);
    }
  };

  const changeReportsSelected = (report: number) => {
    if (report === 1) {
      if (reportsSelected.includes(1)) {
        setReportsSelected([]);
      } else {
        setReportsSelected(reports.map((item: any) => item.id));
      }
    } else if (reportsSelected.includes(report)) {
      const removed = reportsSelected.filter(
        (item: any) => item !== report && item !== 1
      );
      setReportsSelected(removed);
    } else {
      setReportsSelected([...reportsSelected, report]);
    }
  };

  const handleNext = () => {
    activeStep <= steps.length - 1 ? setActiveStep(activeStep + 1) : null;
  };

  const handleBack = () => {
    activeStep > 0 ? setActiveStep(activeStep - 1) : null;
  };

  return (
    <>
      {children({ handleOpen })}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            Añadir a usuarios frecuentes
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label: any) => (
              <Step key={label}>
                <StepLabel className={classes.steps}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 ? (
            <>
              <Box mt={2} mb={2}>
                <Typography color="secondary">
                  A continuación seleccione los usuarios a los cuales va a
                  compartir los reportes de este lote.
                </Typography>
              </Box>
              <Slider>
                {usersToShare.map((item: any) => {
                  return (
                    <ExistingUser
                      name={item.name}
                      info={item.info}
                      selected={userSelected === item.id}
                      onClick={() => {
                        handleChange(item.id);
                      }}
                      column
                    />
                  );
                })}
              </Slider>
            </>
          ) : activeStep === 1 ? (
            <>
              <Box mt={2} mb={2}>
                <Typography color="secondary">
                  A continuación podrás selecciona los criterios ha tener en
                  cuenta para compartir reportes con este usuario
                </Typography>
              </Box>
              <Slider>
                {destinations.map((item: any) => {
                  return (
                    <ExistingUser
                      name={item.name}
                      info={item.info}
                      selected={filterSelected.includes(item.id)}
                      onClick={() => {
                        changeFilterSelected(item.id);
                      }}
                      column
                    />
                  );
                })}
              </Slider>
            </>
          ) : activeStep === 2 ? (
            <>
              <Box mt={2} mb={2}>
                <Typography color="secondary">
                  A continuación seleccione el o los reportes de este lote que
                  quiere compartir. Solo podrá compartir los reportes que
                  disponibles y creados hasta la fecha.
                </Typography>
              </Box>
              <Grid container xs={12}>
                {reports.map((item: any) => {
                  return (
                    <Grid xs={4} item>
                      <ExistingUser
                        name={item.reportType}
                        icon={reportsIcons(item.reportType)}
                        selected={reportsSelected.includes(item.id)}
                        onClick={() => {
                          changeReportsSelected(item.id);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : (
            <>
              <Box mt={2} mb={2}>
                <Typography color="secondary">
                  A continuación tiene la opción de definir un tiempo limite
                  para dar acceso a los usuarios seleccionados para ver el
                  reporte, si no define un tiempo limite, el reporte se
                  compartirá de manera indefinida.
                </Typography>
              </Box>
              <DialogContent>
                <Box width="100%" marginTop={2}>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={4}>
                      <SelectDate
                        value={initialDate}
                        label="Fecha Inicial"
                        onChange={(e: any) => setInitialDate(e)}
                        disabled={shareUnlimited}
                      />
                    </Grid>
                    <Grid container item xs={1} justifyContent="center">
                      Hasta
                    </Grid>
                    <Grid item xs={4}>
                      <SelectDate
                        label="Fecha Final"
                        onChange={(e: any) => setFinalDate(e)}
                        disabled={shareUnlimited}
                        limitDate={initialDate}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box marginTop={2} display="flex" justifyContent="center">
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        value={shareUnlimited}
                        name="promotion"
                        color="primary"
                        onChange={() => setShareUnlimited(!shareUnlimited)}
                      />
                    }
                    label="Compartir de manera indefinida"
                    labelPlacement="start"
                  />
                </Box>
              </DialogContent>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button
            variant="contained"
            color="secondary"
            onClick={activeStep === 0 ? handleClose : handleBack}
          >
            {activeStep === 0 ? 'Cancelar' : 'Atrás'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={activeStep < steps.length - 1 ? handleNext : handleClose}
          >
            {activeStep < steps.length - 1 ? 'Siguiente' : 'Guardar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddToFrequent;
