import { Hidden } from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import useManageUI from 'lib/hooks/useManageUI';

import NavbarLayout from './components/NavbarLayout';

import useStyles from './styles';

const SidebarFolded = () => {
  const { folded, width, foldedOpen } = useSelector(
    (state: any) => state.ui.sidebar
  );
  const {
    foldedAndClosed: foldedAndClosedAction,
    foldedAndOpened: foldedAndOpenedAction,
  } = useManageUI();
  const classes = useStyles({ width });
  const foldedAndClosed = folded && !foldedOpen;
  const foldedAndOpened = folded && foldedOpen;

  return (
    <div className={clsx(classes.wrapper, folded && classes.wrapperFolded)}>
      <Hidden mdDown>
        <div
          className={clsx(
            classes.navbar,
            folded && classes.folded,
            foldedAndOpened && classes.foldedAndOpened,
            foldedAndClosed && classes.foldedAndClosed
          )}
          onMouseEnter={() => foldedAndClosedAction()}
          onMouseLeave={() => foldedAndOpenedAction()}
        >
          <NavbarLayout className={classes.navbarContent} />
        </div>
      </Hidden>
    </div>
  );
};

export default SidebarFolded;
