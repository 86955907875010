import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  fontTitle:{
    fontWeight: 'bold',
    marginRight: '20px',
  },
  dataGrid: {
    border: 'none',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator" :{
      display:'none'
    },
    "& .MuiDataGrid-colCellTitle" :{
      color: '#AFAFAF',
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within":{
      outline: 'transparent !important'
    }
  },
  colorHr: {
    borderColor: '#E2E2E2',
  },
  fontColor: {
    color: '#AFAFAF',
  },
  textAling: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignContent: 'center'
  },
  buttonRegister:{
    fontWeight: 'bold',
  },
  radioGrup:{
    justifyContent:'center',
  },
  stateItems1:{
    width: 10,
    height: 10,
    borderRadius: '100%',
    background: '#23DB6A'
  }, 
  stateItems2:{
    width: 10,
    height: 10,
    borderRadius: '100%',
    background: '#CB3737',
  },
  colorChip:{
    backgroundColor: '#767676 !important',
    "& .MuiChip-labelSmall":{
      textDecoration: 'none',
      color: 'white'
    }
  },
  tabSmall:{
    fontSize: '14px',
    textTransform:'capitalize',
    color: '#AFAFAF'
  },
  placa :{
    width: '75px'
  },
  divContainerPlate:{
    marginTop: "22px"
  },
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& :hover":{
      color: '#fff'
    }
  },
  capitalize:{
    textTransform: 'capitalize'
  },
  colorNoRow:{
    backgroundColor: 'transparent !important',
  },
  heightContainer:{
    '& .MuiDataGrid-root.MuiDataGrid-autoHeight':{
      height: '200px'
    }
  },
  noDataFontTitle :{
    color: '#767676',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '15px'
  },
  noDataFontSubtitle: {
    color: '#AFAFAF',
    width: '85%',
  },
  notDataContainer:{
    '& .MuiDataGrid-columnsContainer':{
      display: 'none'
    }
  },
  buttonContainer:{
    width: '40px',
    height: '40px'
  },
  spacing:{
    marginLeft: '50px'
  },
  fontContent: {
    color: '#AFAFAF',
    fontSize: '16px',
  },
  sizeImage: {
    width: '240px',
    height: '176px'
  },
}))

export { useStyles as default }