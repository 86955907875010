import React from 'react';

import { GenerateMultilevelRoutes as Routes } from 'routes';

import routes from './pages';

const Inventory = () => {
  return <Routes nested={routes} redirect="/corral" />;
};

export default Inventory;
