import React, { useState, useEffect } from 'react';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import {
  Typography,
  Button,
  Divider,
  Tooltip,
  Box,
  Fab,
} from '@material-ui/core';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import domtoimage from 'dom-to-image';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import dayjs from 'dayjs';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import GobackLink from 'components/GobackLink';
import DialogPDFViewer from 'components/DialogPDFViewer';

import {
  MyDocRangeMagroLot,
  ReportLot,
  GraphFilterMagroLot,
} from './../../Reports/ReportFilter';

import {
  MyDocRangeMagroCanal,
  ReportFilterCanal,
  GraphRangeMagroCanal,
  GraphFilterMagroCanalTwo,
} from './../../Reports/ReportFilterCanal';

import useStyles from './styles';

const Search = () => {
  const url = new URLSearchParams(window.location.search);
  const [type]: any = React.useState(url.get('type'));
  const [filter]: any = React.useState(url.get('filter'));
  const initialDate: any = React.useState(url.get('initialdate'));
  const finalDate: any = React.useState(url.get('finaldate'));
  const classes = useStyles();
  const [openFilterMagro, setOpenFilterMagro] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');

  const [openFilterMagroCanal, setOpenFilterMagroCanal] = useState(false);
  const [imageQrCanal, setImageQrCanal] = useState('');
  const [imageGraphCanal, setImageGraphCanal] = useState('');
  const [imageGraphTwoCanal, setImageGraphTwoCanal] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenFilterLotMagro');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraph = () => {
    const graph: any = document.getElementById('graphfiltermagrolot');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const generateScreenshotCanal = () => {
    const qrCode: any = document.getElementById('screenfiltercanal');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        setImageQrCanal(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraphCanal = () => {
    const graph: any = document.getElementById('graphrangemagrocanal');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        setImageGraphCanal(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraphTwoCanal = () => {
    const graph: any = document.getElementById('graphfiltermagrocanaltwo');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        setImageGraphTwoCanal(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalFilterMagro = () => {
    setOpenFilterMagro(true);
  };

  const openModalFilterMagroCanal = () => {
    setOpenFilterMagroCanal(true);
  };

  useEffect(() => {
    if (filter === 'lote') {
      generateScreenshot();
      screenshotGraph();
    } else if (filter === 'canal') {
      generateScreenshotCanal();
      screenshotGraphCanal();
      screenshotGraphTwoCanal();
      screenshotGraph();
    }
  }, [filter]);

  const columns: GridColDef[] = [
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'animalesLote',
      headerName: 'Animales Lote',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'canalesEvaluadas',
      headerName: 'Canales Evaluadas',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'pesoTotal',
      headerName: 'Peso Total (kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'promPCC',
      headerName: 'Prom. PCC (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'promMC',
      headerName: 'Prom.MC (%)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'promLM',
      headerName: 'Prom. LM (m.m)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'promGD',
      headerName: 'Prom. GD (m.m)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'rendimientoLote',
      headerName: 'Rendimiento Lote (%)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: '',
      headerName: '',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/magro/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rowsLotes = [
    {
      id: 1,
      loteIP: '6543',
      animalesLote: '24',
      canalesEvaluadas: '24',
      pesoTotal: '18',
      promPCC: '46',
      promMC: '53,2',
      promLM: '64,2',
      promGD: '64,2',
      rendimientoLote: '83,76',
    },
    {
      id: 2,
      loteIP: '6543',
      animalesLote: '24',
      canalesEvaluadas: '24',
      pesoTotal: '18',
      promPCC: '46',
      promMC: '53,2',
      promLM: '64,2',
      promGD: '64,2',
      rendimientoLote: '83,76',
    },
    {
      id: 3,
      loteIP: '6543',
      animalesLote: '24',
      canalesEvaluadas: '24',
      pesoTotal: '18',
      promPCC: '46',
      promMC: '53,2',
      promLM: '64,2',
      promGD: '64,2',
      rendimientoLote: '83,76',
    },
    {
      id: 4,
      loteIP: '6543',
      animalesLote: '24',
      canalesEvaluadas: '24',
      pesoTotal: '18',
      promPCC: '46',
      promMC: '53,2',
      promLM: '64,2',
      promGD: '64,2',
      rendimientoLote: '83,76',
    },
    {
      id: 5,
      loteIP: '6543',
      animalesLote: '24',
      canalesEvaluadas: '24',
      pesoTotal: '18',
      promPCC: '46',
      promMC: '53,2',
      promLM: '64,2',
      promGD: '64,2',
      rendimientoLote: '83,76',
    },
    {
      id: 6,
      loteIP: '6543',
      animalesLote: '24',
      canalesEvaluadas: '24',
      pesoTotal: '18',
      promPCC: '46',
      promMC: '53,2',
      promLM: '64,2',
      promGD: '64,2',
      rendimientoLote: '83,76',
    },
  ];

  const rowsCanales = [
    {
      id: 1,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 2,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 3,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 4,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 5,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 6,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 7,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 8,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
  ];

  const columnsCanales: GridColDef[] = [
    {
      field: 'canalID',
      headerName: 'Canal ID',
      flex: 1,

      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'brazalete',
      headerName: 'Brazalete',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'intmed',
      headerName: 'Int. Med.',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'SEUROP',
      headerName: 'SEUROP',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'pcc',
      headerName: 'PCC (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'gd',
      headerName: 'GD (m.m.)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ml',
      headerName: 'ML (m.m)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mc',
      headerName: 'MC (%)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mckg',
      headerName: 'MC (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'PSE',
      headerName: 'Indicio (PSE)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'color',
      headerName: 'Color',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <div
          style={{
            display: 'flex',

            alignItems: 'center',
          }}
        >
          <div style={{ marginRight: 15 }}>
            <svg height="15" width="15">
              <circle fill="#B0413E" r="5" cx="10" cy="10"></circle>
            </svg>
          </div>
          <div>{params.value}</div>
        </div>
      ),
    },
    {
      field: 'sexo',
      headerName: 'Sexo',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/post-mortem',
      title: 'Post Mortem',
    },
  ];

  const getDateFilter = () => {
    switch (type) {
      case 'lote':
        return url.get('code');
      case 'canal':
        return url.get('code');
      case 'today':
        return dayjs().format('DD/MM/YYYY');
      case 'week':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      case 'range':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      default:
        return 'El tipo de filtro solicitado no existe';
    }
  };

  const getInfoFilter = () => {
    switch (type) {
      case 'lote':
        return (
          <>
            <Typography className={classes.filterType}>Lote:</Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{url.get('code')}</span>
            </Typography>
          </>
        );
      case 'canal':
        return (
          <>
            <Typography className={classes.filterType}>Canal:</Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{url.get('code')}</span>
            </Typography>
          </>
        );
      case 'today':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">
                {dayjs().format('dddd, D MMMM YYYY')} (Hoy)
              </span>
            </Typography>
          </>
        );
      case 'week':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{'Semana' + ' ' + dayjs().week()}</span>{' '}
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      case 'range':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      default:
        return (
          <Typography className={classes.filterType}>
            El tipo de filtro solicitado no existe
          </Typography>
        );
    }
  };

  return (
    <div>
      {filter === 'lote' && (
        <div>
          <div style={{ position: 'absolute', left: '-1000%' }}>
            <ReportLot />
          </div>
          <div style={{ position: 'absolute', left: '-1000%' }}>
            <GraphFilterMagroLot />
          </div>
        </div>
      )}
      {filter === 'canal' && (
        <div>
          <div style={{ position: 'absolute', left: '-1000%' }}>
            <ReportFilterCanal />
          </div>
          <div style={{ position: 'absolute', left: '-1000%' }}>
            <GraphRangeMagroCanal />
          </div>
          <div style={{ position: 'absolute', left: '-1000%' }}>
            <GraphFilterMagroCanalTwo />
          </div>
        </div>
      )}
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard className={classes.contentCard}>
        <div className={classes.root}>
          <div className={classes.flex}>
            <Typography className={classes.title}>
              Búsqueda: Fecha Puntual
            </Typography>
            {!url.get('code') && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  (filter === 'lote' && openModalFilterMagro()) ||
                  (filter === 'canal' && openModalFilterMagroCanal())
                }
              >
                Generar Reporte
              </Button>
            )}
          </div>
          <div className={classes.flexContainer}>
            <div className={classes.flex}>
              <Typography className={classes.labelOrange}>
                {getInfoFilter()}
              </Typography>
            </div>
            <div className={clsx(classes.result, classes.flex)}>
              <LibraryBooksOutlinedIcon style={{ marginRight: 20 }} />
              <Typography>{'200 resultados'}</Typography>
            </div>
          </div>
          <Divider />
          {filter === 'lote' && (
            <DataGrid
              rows={rowsLotes}
              columns={columns}
              autoHeight
              className={classes.dataGrid}
              components={{
                Footer: TableFooter,
              }}
            />
          )}
          {filter === 'canal' && (
            <DataGrid
              rows={rowsCanales}
              columns={columnsCanales}
              autoHeight
              className={classes.dataGrid}
              components={{
                Footer: TableFooter,
              }}
            />
          )}
        </div>
      </ContentCard>
      <DialogPDFViewer
        open={openFilterMagro}
        onClose={() => setOpenFilterMagro(false)}
        done={Boolean(imageQr)}
      >
        <MyDocRangeMagroLot
          qr={imageQr}
          graph={imageGraph}
          range={getDateFilter()}
        />
      </DialogPDFViewer>
      <DialogPDFViewer
        open={openFilterMagroCanal}
        onClose={() => setOpenFilterMagroCanal(false)}
        done={Boolean(imageQrCanal)}
      >
        <MyDocRangeMagroCanal
          qr={imageQrCanal}
          graph={imageGraphCanal}
          graphTwo={imageGraphTwoCanal}
          range={getDateFilter()}
        />
      </DialogPDFViewer>
    </div>
  );
};

export default Search;
