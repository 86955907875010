import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  container:{
    width: "100%",
    fontFamily: "Roboto, arial",
    display: "flex",
    justifyContent: "center",
  },
  itemsText:{
    height: '24px', 
    fontWeight: 'bold', 
    textTransform: 'uppercase'
  },
  AddressUnderline: {
    width: "100%",
    height: "4px",
    border: "2px #DD9835 solid",
    borderTop: "0"
  },
  AddressLabel: {
    marginTop: "3px",
    fontSize: "10px",
    color: "#AFAFAF",
    lineHeight: "11px"
  }
}))
