import React from 'react';
import useStyles from './styles';
import ModalMap from '../modalMap';
import mapState from 'lib/helpers/mapState';

const CavasMaps = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const colors: any = {
    0: '#AFAFAF',
    1: '#767676',
    2: '#57E28C',
    3: '#FCD532',
    4: '#ED8C2B',
    5: '#E2575C',
  };

  const data = {
    C4: {
      totalLotes: 3,
      totalAnimales: 25,
      value: 5,
    },
    C5: {
      totalLotes: 3,
      totalAnimales: 25,
      value: 2,
    },
    C6: {
      totalLotes: 2,
      totalAnimales: 4,
      value: 1,
    },
    C10: {
      totalLotes: 2,
      totalAnimales: 4,
      value: 0,
    },
    C11: {
      totalLotes: 5,
      totalAnimales: 69,
      value: 2,
    },
    C12: {
      totalLotes: 3,
      totalAnimales: 71,
      value: 3,
    },
    C13: {
      totalLotes: 8,
      totalAnimales: 42,
      value: 1,
    },
    C14: {
      totalLotes: 2,
      totalAnimales: 20,
      value: 0,
    },
    C15: {
      totalLotes: 6,
      totalAnimales: 89,
      value: 0,
    },
    C16: {
      totalLotes: 2,
      totalAnimales: 4,
      value: 0,
    },
    C20: {
      totalLotes: 2,
      totalAnimales: 4,
      value: 0,
    },

    C21: {
      totalLotes: 5,
      totalAnimales: 18,
      value: 2,
    },
    C22: {
      totalLotes: 5,
      totalAnimales: 28,
      value: 4,
    },
    C23: {
      totalLotes: 3,
      totalAnimales: 25,
      value: 3,
    },
  };

  function pad(n: number) {
    return n < 10 ? '0' + n : n;
  }

  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 705 535"
    >
      <g id="C23">
        {data.C23.value != 0 ? (
          <g
            id="C23_marked_"
            data-name="C23 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2146"
              data-name="Rectangle 2146"
              x="38.5"
              y="415.5"
              width={81}
              height="119.5"
              style={{ fill: mapState(data.C23.value) }}
            />
            <rect
              id="Rectangle_2150"
              data-name="Rectangle 2150"
              x="38.5"
              y="506.5"
              width={81}
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3060" data-name="Group 3060">
              <g id="Canales-2" style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(65.93 485.5)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_34" data-name={34} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(63.33 468.5)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C23.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C5" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(68.68 524.15)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C23
              </text>
            </g>
            <g
              id="Total_Lotes:_01-2"
              data-name="Total Lotes: 01-2"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(54.35 428.5)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C23.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C23-2" data-name="C23">
            <rect
              id="Rectangle_2149"
              data-name="Rectangle 2149"
              x={38}
              y="415.5"
              width={82}
              height="119.5"
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C23-3" data-name="C23" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(65.94 477.98)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13.054621696472168px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C23
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C22">
        {data.C22.value != 0 ? (
          <g
            id="C22_marked_"
            data-name="C22 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <path
              id="Path_1495"
              data-name="Path 1495"
              d="M0,256H82V365.3H0Z"
              style={{ fill: mapState(data.C22.value) }}
            />
            <rect
              id="Rectangle_2167"
              data-name="Rectangle 2167"
              y={338}
              width={82}
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3062" data-name="Group 3062">
              <g id="Canales-3" style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(27.43 325)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_26" data-name={26} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(24.83 308)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C22.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C14" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(27.59 354.65)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C22
              </text>
            </g>
            <g
              id="Total_Lotes:_01"
              data-name="Total Lotes: 01"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(15.85 269)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',

                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C22.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C22-2" data-name="C22">
            <rect
              id="Rectangle_2131-4"
              data-name="Rectangle 2131-4"
              y={256}
              width={82}
              height={111}
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C22-3" data-name="C22" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(27.69 314.67)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C22
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C21">
        {data.C21.value != 0 ? (
          <g
            id="C21_marked_"
            data-name="C21(marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2131"
              data-name="Rectangle 2131"
              y={60}
              width={82}
              height={147}
              style={{ fill: mapState(data.C21.value) }}
            />
            <rect
              id="Rectangle_2132"
              data-name="Rectangle 2132"
              y={181}
              width={82}
              height={26}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3038" data-name="Group 3038">
              <g id="Canales" style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(27.43 150)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_84" data-name={84} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(24.83 133)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C21.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C16" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(27.5 197.16)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C21
              </text>
            </g>
            <g
              id="Total_Lotes:_02"
              data-name="Total Lotes: 02"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(15.85 73)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C21.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C21-2" data-name="C21">
            <rect
              id="Rectangle_2131-3"
              data-name="Rectangle 2131-3"
              y={60}
              width={82}
              height={147}
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C21-3" data-name="C21" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(27.69 137.05)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C21
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C20">
        {data.C20.value != 0 ? (
          <g
            id="C20_marked_"
            data-name="C20 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2131-2"
              data-name="Rectangle 2131"
              x={93}
              y={60}
              width={82}
              height={147}
              style={{ fill: mapState(data.C20.value) }}
            />
            <rect
              id="Rectangle_2132-2"
              data-name="Rectangle 2132"
              x={93}
              y={181}
              width={82}
              height={26}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3038-2" data-name="Group 3038">
              <g
                id="Canales-2-2"
                data-name="Canales"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(120.43 150)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_84-2" data-name={84} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(117.83 133)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C20.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C16-2" data-name="C16" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(120.5 197.16)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C20
              </text>
            </g>
            <g
              id="Total_Lotes:_02-2"
              data-name="Total Lotes: 02"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(108.85 73)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C20.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C20-2" data-name="C20">
            <rect
              id="Rectangle_2131-2-2"
              data-name="Rectangle 2131-2"
              x={93}
              y={60}
              width={82}
              height={147}
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C20-3" data-name="C20" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(120.59 137.05)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C20
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C16-3" data-name="C16">
        {data.C16.value != 0 ? (
          <g
            id="C16_marked_"
            data-name="C16 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2131-3-2"
              data-name="Rectangle 2131"
              x={186}
              y={60}
              width={82}
              height={147}
              style={{ fill: mapState(data.C16.value) }}
            />
            <rect
              id="Rectangle_2132-3"
              data-name="Rectangle 2132"
              x={186}
              y={181}
              width={82}
              height={26}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3038-3" data-name="Group 3038">
              <g
                id="Canales-3-2"
                data-name="Canales"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(213.43 150)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_84-3" data-name={84} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(210.83 133)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C16.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C16-4" data-name="C16" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(213.5 197.16)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C16
              </text>
            </g>
            <g
              id="Total_Lotes:_02-3"
              data-name="Total Lotes: 02"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(201.85 73)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C16.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C21-4" data-name="C21">
            <rect
              id="Rectangle_2131-3-3"
              data-name="Rectangle 2131-3"
              x={186}
              y={60}
              width={82}
              height={147}
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C21-5" data-name="C21" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(213.69 137.05)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C16
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C15">
        {data.C15.value != 0 ? (
          <g
            id="C5_marked_"
            data-name="C5 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2146-2"
              data-name="Rectangle 2146"
              x="277.5"
              y={60}
              width="110.5"
              height="81.57"
              style={{ fill: mapState(data.C15.value) }}
            />
            <rect
              id="Rectangle_2150-2"
              data-name="Rectangle 2150"
              x="277.5"
              y="113.79"
              width="110.5"
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3060-2" data-name="Group 3060">
              <g
                id="Canales-2-3"
                data-name="Canales-2"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(320.5 109.06)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_34-2" data-name={34} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(323.81 98.02)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '19.4763126373291px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C15.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C5-2" data-name="C5" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(323.43 133.66)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C15
              </text>
            </g>
            <g
              id="Total_Lotes:_01-2-2"
              data-name="Total Lotes: 01-2"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(307.63 72.1)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C15.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C15-2" data-name="C15">
            <rect
              id="Rectangle_2137"
              data-name="Rectangle 2137"
              x="277.5"
              y={60}
              width="110.5"
              height={82}
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C15-3" data-name="C15" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(320.81 106)"
                style={{
                  isolation: 'isolate',
                  fontSize: '14px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C15
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C14-2" data-name="C14">
        {data.C14.value != 0 ? (
          <g
            id="C_14_marked_"
            data-name="C 14(marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <path
              id="Path_1495-2"
              data-name="Path 1495"
              d="M93,257h82V366.3H93Z"
              style={{ fill: mapState(data.C14.value) }}
            />
            <rect
              id="Rectangle_2167-2"
              data-name="Rectangle 2167"
              x={93}
              y={339}
              width={82}
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3062-2" data-name="Group 3062">
              <g
                id="Canales-3-3"
                data-name="Canales-3"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(120.43 326)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_26-2" data-name={26} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(117.83 309)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C14.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C14-3" data-name="C14" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(120.59 355.65)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C14
              </text>
            </g>
            <g
              id="Total_Lotes:_01-2-3"
              data-name="Total Lotes: 01"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(108.85 270)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C14.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C14-4" data-name="C14">
            <g id="C22-4" data-name="C22">
              <rect
                id="Rectangle_2131-4-3"
                data-name="Rectangle 2131-4"
                x={93}
                y="256.15"
                width={82}
                height={111}
                style={{ fill: '#d6d6d6' }}
              />
              <g id="C22-5" data-name="C22" style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(120.69 314.82)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '13px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  C14
                </text>
              </g>
            </g>
          </g>
        )}
      </g>
      <g id="C13">
        {data.C13.value != 0 ? (
          <g
            id="C13_marked_"
            data-name="C13 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2146-3"
              data-name="Rectangle 2146"
              x={131}
              y="415.5"
              width={81}
              height="119.5"
              style={{ fill: mapState(data.C13.value) }}
            />
            <rect
              id="Rectangle_2150-3"
              data-name="Rectangle 2150"
              x={131}
              y="506.5"
              width={81}
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3060-3" data-name="Group 3060">
              <g
                id="Canales-2-4"
                data-name="Canales-2"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(158.43 485.5)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_34-3" data-name={34} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(155.83 468.5)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C13.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C5-3" data-name="C5" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(161.18 524.15)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C13
              </text>
            </g>
            <g
              id="Total_Lotes:_01-2-4"
              data-name="Total Lotes: 01-2"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(146.85 428.5)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C13.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C13-2" data-name="C13">
            <rect
              id="Rectangle_2148"
              data-name="Rectangle 2148"
              x={131}
              y="415.5"
              width={81}
              height="119.5"
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C13-3" data-name="C13" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(158.85 477.98)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13.054621696472168px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C13
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C12">
        {data.C12.value != 0 ? (
          <g
            id="C12_marked_"
            data-name="C12(marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2146-4"
              data-name="Rectangle 2146"
              x="399.13"
              y="60.1"
              width="110.5"
              height="81.57"
              style={{ fill: mapState(data.C12.value) }}
            />
            <rect
              id="Rectangle_2150-4"
              data-name="Rectangle 2150"
              x="399.13"
              y="113.9"
              width="110.5"
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3060-4" data-name="Group 3060">
              <g
                id="Canales-2-5"
                data-name="Canales-2"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(442.12 109.16)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_34-4" data-name={34} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(445.43 98.12)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '19.4763126373291px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C12.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C5-4" data-name="C5" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(445.06 133.76)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C12
              </text>
            </g>
            <g
              id="Total_Lotes:_01-2-5"
              data-name="Total Lotes: 01-2"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(429.26 72.2)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C12.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C12-2" data-name="C12">
            <rect
              id="Rectangle_2138"
              data-name="Rectangle 2138"
              x={399}
              y={60}
              width="110.63"
              height={82}
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C12-3" data-name="C12" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(442.37 106) scale(1.01 1)"
                style={{
                  isolation: 'isolate',
                  fontSize: '14px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C12
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C11">
        {data.C11.value != 0 ? (
          <g
            id="C11_marked_"
            data-name="C11 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2146-5"
              data-name="Rectangle 2146"
              x={224}
              y={256}
              width={81}
              height={111}
              style={{ fill: mapState(data.C11.value) }}
            />
            <rect
              id="Rectangle_2150-5"
              data-name="Rectangle 2150"
              x={224}
              y={339}
              width={81}
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3060-5" data-name="Group 3060">
              <g
                id="Canales-2-6"
                data-name="Canales-2"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(251.43 326)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_34-5" data-name={34} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(248.83 309)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C11.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C5-5" data-name="C5" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(254.18 355.65)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C11
              </text>
            </g>
            <g
              id="Total_Lotes:_01-2-6"
              data-name="Total Lotes: 01-2"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(239.85 269)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C11.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C11-2" data-name="C11">
            <rect
              id="Rectangle_2144"
              data-name="Rectangle 2144"
              x={224}
              y={256}
              width={81}
              height={111}
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C11-3" data-name="C11" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(251.76 314.67)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C11
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C10">
        {data.C10.value != 0 ? (
          <g
            id="C10_marked_"
            data-name="C10 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2146-6"
              data-name="Rectangle 2146"
              x={224}
              y="415.5"
              width={81}
              height="119.5"
              style={{ fill: mapState(data.C10.value) }}
            />
            <rect
              id="Rectangle_2150-6"
              data-name="Rectangle 2150"
              x={224}
              y="506.5"
              width={81}
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3060-6" data-name="Group 3060">
              <g
                id="Canales-2-7"
                data-name="Canales-2"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(251.43 485.5)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_34-6" data-name={34} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(248.83 468.5)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C10.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C5-6" data-name="C5" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(254.18 524.15)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C10
              </text>
            </g>
            <g
              id="Total_Lotes:_01-2-7"
              data-name="Total Lotes: 01-2"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(239.85 428.5)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C10.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C10-2" data-name="C10">
            <rect
              id="Rectangle_2147"
              data-name="Rectangle 2147"
              x={224}
              y="415.5"
              width={81}
              height="119.5"
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C10-3" data-name="C10" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(251.76 477.98)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13.054621696472168px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C10
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C6">
        {data.C6.value != 0 ? (
          <g
            id="C6_marked_"
            data-name="C6 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2146-7"
              data-name="Rectangle 2146"
              x="503.5"
              y="256.28"
              width={81}
              height={111}
              style={{ fill: mapState(data.C6.value) }}
            />
            <rect
              id="Rectangle_2150-7"
              data-name="Rectangle 2150"
              x="503.5"
              y="339.28"
              width={81}
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3060-7" data-name="Group 3060">
              <g
                id="Canales-2-8"
                data-name="Canales-2"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(530.93 326.28)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_34-7" data-name={34} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(528.33 309.28)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C6.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C5-7" data-name="C5" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(533.68 355.94)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C6
              </text>
            </g>
            <g
              id="Total_Lotes:_01-2-8"
              data-name="Total Lotes: 01-2"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(519.35 269.28)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C6.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C6-2" data-name="C6">
            <rect
              id="Rectangle_2145"
              data-name="Rectangle 2145"
              x={503}
              y={256}
              width={82}
              height={111}
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C6-3" data-name="C6" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(534.08 314.67)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C6
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C5-8" data-name="C5">
        {data.C5.value != 0 ? (
          <g
            id="C5_marked_2"
            data-name="C5 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2146-8"
              data-name="Rectangle 2146"
              x={411}
              y={256}
              width={81}
              height={111}
              style={{ fill: mapState(data.C5.value) }}
            />
            <rect
              id="Rectangle_2150-8"
              data-name="Rectangle 2150"
              x={411}
              y={339}
              width={81}
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3060-8" data-name="Group 3060">
              <g
                id="Canales-2-9"
                data-name="Canales-2"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(438.43 326)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_34-8" data-name={34} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(435.83 309)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C5.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C5-9" data-name="C5" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(441.18 355.65)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C5
              </text>
            </g>
            <g
              id="Total_Lotes:_01-2-9"
              data-name="Total Lotes: 01-2"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(426.85 269)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C5.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C5-10" data-name="C5">
            <rect
              id="Rectangle_2145-2"
              data-name="Rectangle 2145"
              x="410.5"
              y="256.15"
              width={82}
              height={111}
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C6-4" data-name="C6" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(441.58 314.82)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C5
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="C4">
        {data.C4.value != 0 ? (
          <g
            id="C4_marked_"
            data-name="C4 (marked)"
            onClick={() => openModal()}
            style={{ cursor: 'pointer' }}
          >
            <rect
              id="Rectangle_2146-9"
              data-name="Rectangle 2146"
              x={318}
              y={256}
              width={81}
              height={111}
              style={{ fill: mapState(data.C4.value) }}
            />
            <rect
              id="Rectangle_2150-9"
              data-name="Rectangle 2150"
              x={318}
              y={339}
              width={81}
              height={28}
              style={{
                fill: '#fff',
                opacity: '0.30300000309944153',
                isolation: 'isolate',
              }}
            />
            <g id="Group_3060-9" data-name="Group 3060">
              <g
                id="Canales-2-10"
                data-name="Canales-2"
                style={{ isolation: 'isolate' }}
              >
                <text
                  transform="translate(345.43 326)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '8px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  Canales
                </text>
              </g>
              <g id="_34-9" data-name={34} style={{ isolation: 'isolate' }}>
                <text
                  transform="translate(342.83 309)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '30px',
                    fill: '#383838',
                    fontFamily: 'SegoeUI, Segoe UI',
                  }}
                >
                  {pad(data.C4.totalAnimales)}
                </text>
              </g>
            </g>
            <g id="C5-11" data-name="C5" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(348.18 355.65)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C4
              </text>
            </g>
            <g
              id="Total_Lotes:_01-2-10"
              data-name="Total Lotes: 01-2"
              style={{ isolation: 'isolate' }}
            >
              <text
                transform="translate(333.85 269)"
                style={{
                  isolation: 'isolate',
                  fontSize: '8px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                <tspan style={{ letterSpacing: '-0.09765625em' }}>T</tspan>
                <tspan x="3.41" y={0}>
                  otal Lotes: {pad(data.C4.totalLotes)}
                </tspan>
              </text>
            </g>
          </g>
        ) : (
          <g id="C4-2" data-name="C4">
            <rect
              id="Rectangle_2143"
              data-name="Rectangle 2143"
              x={318}
              y={256}
              width={81}
              height={111}
              style={{ fill: '#d6d6d6' }}
            />
            <g id="C4-3" data-name="C4" style={{ isolation: 'isolate' }}>
              <text
                transform="translate(348.27 314.67)"
                style={{
                  isolation: 'isolate',
                  fontSize: '13px',
                  fill: '#383838',
                  fontFamily: 'SegoeUI, Segoe UI',
                }}
              >
                C4
              </text>
            </g>
          </g>
        )}
      </g>
      <g id="Marks">
        <rect
          id="Rectangle_2141"
          data-name="Rectangle 2141"
          x={314}
          width={39}
          height={11}
          style={{ fill: '#86d077' }}
        />
        <rect
          id="Rectangle_2142"
          data-name="Rectangle 2142"
          x={433}
          width={42}
          height={11}
          style={{ fill: '#86d077' }}
        />
        <g id="Group_3061" data-name="Group 3061">
          <rect
            id="Rectangle_2157"
            data-name="Rectangle 2157"
            x={694}
            y={133}
            width={11}
            height={41}
            style={{ fill: '#86d077' }}
          />
          <rect
            id="Rectangle_2158"
            data-name="Rectangle 2158"
            x={694}
            y={253}
            width={11}
            height={41}
            style={{ fill: '#86d077' }}
          />
        </g>
      </g>
      <g id="Cava_VR19" data-name="Cava VR19">
        <rect
          id="Rectangle_2159"
          data-name="Rectangle 2159"
          x={378}
          y={416}
          width={82}
          height={82}
          style={{ fill: '#d6d6d6' }}
        />
        <g
          id="Cava_V.R._19"
          data-name="Cava V.R. 19"
          style={{ isolation: 'isolate' }}
        >
          <text
            transform="translate(404.19 451)"
            style={{
              isolation: 'isolate',
              fontSize: '14px',
              fill: '#383838',
              fontFamily: 'SegoeUI, Segoe UI',
            }}
          >
            Cava
          </text>
          <text
            transform="translate(397.97 469)"
            style={{
              isolation: 'isolate',
              fontSize: '14px',
              fill: '#383838',
              fontFamily: 'SegoeUI, Segoe UI',
            }}
          >
            V.R. 19
          </text>
        </g>
      </g>
      <g id="Cava_VR18" data-name="Cava VR18">
        <rect
          id="Rectangle_2160"
          data-name="Rectangle 2160"
          x={470}
          y={416}
          width={82}
          height={82}
          style={{ fill: '#d6d6d6' }}
        />
        <g
          id="Cava_V.R._18"
          data-name="Cava V.R. 18"
          style={{ isolation: 'isolate' }}
        >
          <text
            transform="translate(496.19 451)"
            style={{
              isolation: 'isolate',
              fontSize: '14px',
              fill: '#383838',
              fontFamily: 'SegoeUI, Segoe UI',
            }}
          >
            Cava
          </text>
          <text
            transform="translate(489.97 469)"
            style={{
              isolation: 'isolate',
              fontSize: '14px',
              fill: '#383838',
              fontFamily: 'SegoeUI, Segoe UI',
            }}
          >
            V.R. 18
          </text>
        </g>
      </g>
      <g id="Cava_R.Do" data-name="Cava R.Do">
        <rect
          id="Rectangle_2161"
          data-name="Rectangle 2161"
          x={563}
          y={416}
          width={82}
          height={55}
          style={{ fill: '#d6d6d6' }}
        />
        <g
          id="Cava_R._Do"
          data-name="Cava R. Do"
          style={{ isolation: 'isolate' }}
        >
          <text
            transform="translate(588.19 438)"
            style={{
              isolation: 'isolate',
              fontSize: '14px',
              fill: '#383838',
              fontFamily: 'SegoeUI, Segoe UI',
            }}
          >
            Cava
          </text>
          <text
            transform="translate(586.37 456)"
            style={{
              isolation: 'isolate',
              fontSize: '14px',
              fill: '#383838',
              fontFamily: 'SegoeUI, Segoe UI',
            }}
          >
            R. Do
          </text>
        </g>
      </g>
      <g
        id="_2do_Nivel"
        data-name=" 2do Nivel"
        style={{ isolation: 'isolate' }}
      >
        <text
          transform="translate(572.56 490)"
          style={{
            isolation: 'isolate',
            fontSize: '13px',
            fill: '#383838',
            fontFamily: 'SegoeUI, Segoe UI',
          }}
        >
          2do Nivel
        </text>
      </g>
      <g id="waysGroup">
        <rect
          id="Rectangle_2139"
          data-name="Rectangle 2139"
          y={22}
          width={683}
          height={27}
          style={{ fill: '#f2f2f2' }}
        />
        <rect
          id="Rectangle_2153"
          data-name="Rectangle 2153"
          y="214.07"
          width={278}
          height={27}
          style={{ fill: '#f2f2f2' }}
        />
        <rect
          id="Rectangle_2151"
          data-name="Rectangle 2151"
          y={378}
          width={305}
          height={28}
          style={{ fill: '#f2f2f2' }}
        />
        <rect
          id="Rectangle_2152"
          data-name="Rectangle 2152"
          x={186}
          y={219}
          width={26}
          height={187}
          style={{ fill: '#f2f2f2' }}
        />
        <path
          id="Union_2"
          data-name="Union 2"
          d="M595.43,365V241.09H278v-88.5H518.81V60H683V365Z"
          style={{ fill: '#f2f2f2' }}
        />
        <rect
          id="Rectangle_2162"
          data-name="Rectangle 2162"
          x={378}
          y={378}
          width={267}
          height={28}
          style={{ fill: '#f2f2f2' }}
        />
        <rect
          id="Rectangle_2163"
          data-name="Rectangle 2163"
          x={656}
          y={378}
          width={27}
          height={157}
          style={{ fill: '#f2f2f2' }}
        />
        <rect
          id="Rectangle_2164"
          data-name="Rectangle 2164"
          x={339}
          y={378}
          width={28}
          height={157}
          style={{ fill: '#f2f2f2' }}
        />
        <rect
          id="Rectangle_2165"
          data-name="Rectangle 2165"
          x={378}
          y={508}
          width={267}
          height={27}
          style={{ fill: '#f2f2f2' }}
        />
      </g>
      <ModalMap open={open} onClose={onClose} />
    </svg>
  );
};

export default CavasMaps;
