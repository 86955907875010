import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Breadcrumb, Button, Grid, Icon, Popup } from 'semantic-ui-react';

import { DASHBOARD } from 'core/graphql/queries/infotraking';

import useSetToken from 'lib/hooks/useSetToken';
import useSnackbar from 'lib/hooks/useSnackbar';

import Loader from 'components/Loader';
import ContentCard from 'components/ContentCard';
import FilterReports from 'components/FilterReports';
import PagesContainer from 'components/PagesContainer';

import {
  InfotrakingContainer,
  BreadcrumbContainerStyle,
  Indicator,
  Stadistics,
  DetailsTable,
  SpacingContainer,
  States,
  State,
  StateIndicator,
} from './styles';
import Dialogs from './Dialogs';
import DialoObservation from './Dialogs/Observation';
import DialogSacrifice from './Dialogs/Sacrifice';
import DialogCold from './Dialogs/Cold';
import DialogDespatch from './Dialogs/Despatch';
import DialogCorrals from './Dialogs/Corrals';
import DialogArrival from './Dialogs/Arrival';
import DialogTransport from './Dialogs/Transport';
import DialogGeneral from './Dialogs/General';

const processes: any = {
  arrival: {
    title: 'Arribo SB',
    icon: 'icon-planta-check',
  },
  cold: {
    title: 'Cavas',
    icon: 'icon-cold',
  },
  corrals: {
    title: 'Corrales',
    icon: 'icon-corral',
  },
  despatch: {
    title: 'Despachos',
    icon: 'icon-truck-cold',
  },
  general: {
    title: 'General',
    icon: 'icon-check',
  },
  observation: {
    title: 'Observación',
    icon: 'icon-pig-search',
  },
  sacrifice: {
    title: 'Beneficio',
    icon: 'icon-canal-sync',
  },
  transport: {
    title: 'Transporte',
    icon: 'icon-truck-pig',
  },
};

const Infotraking = () => {
  const [open, setOpen] = useState(false);
  const [modal, setModal] =
    useState</*'arrival' | 'cold' | 'corrals' | 'despatch' | 'general' | 'observation' | 'sacrifice' | 'transport'*/ any>(
      'general'
    );
  const [lote, setLote] = useState(null);
  const token = useSelector((state: any) => state.user.token);
  const { handleOpenSnackbar } = useSnackbar();
  const setToken = useSetToken();
  const currentDate = new Date();

  const { data, loading, error } = useQuery(DASHBOARD, {
    variables: {
      initialDate: `${currentDate.getFullYear()}-${currentDate.getMonth()}-01`,
      finalDate: moment(currentDate).subtract('month', 1).format('YYYY-MM-DD'),
    },
    context: {
      headers: {
        'x-auth-jwt': token,
      },
    },
  });

  useEffect(() => {
    if (data && data.dashboard) {
      setToken(data.dashboard.token.value);
    }
  }, [data, loading]);

  useEffect(() => {
    if (error) {
      if (error.message.includes('code 401')) {
        handleOpenSnackbar({
          autoHideDuration: 6000,
          message: 'Su sesión ha caducado',
          type: 'WARNING',
        });
      } else {
        console.error(error);
        // handleOpenSnackbar({
        //   type: 'ERROR',
        //   actionText: 'Reportar',
        //   message: `Ups! Ha ocurrido un error.`,
        //   action: async (_: any, handleClose: () => void) =>
        //     Promise.resolve()
        //       .then(() => handleClose())
        //       .then(() => handleOpenSnackbar({
        //         autoHideDuration: 3000,
        //         type: 'SUCCESS',
        //         message: 'Se ha enviado tu reporte. Gracias',
        //         closeBtn: false
        //       }))
        // })
      }
    }
  }, [error]);

  const getState = (value: number) => {
    switch (value) {
      case 1:
        return 'wait';
      case 2:
        return 'process';
      case 3:
        return 'finish';
      default:
        return 'error';
    }
  };

  const handleOpen = (type: any, info: any) => {
    setOpen(true);
    setLote(info);
    setModal(type);
  };

  const handleClose = () => setOpen(false);

  const [transporte, setTransporte] = useState(false);
  const handleTransporte = () => {
    setTransporte(!transporte);
  };

  const [arribo, setArribo] = useState(false);
  const handleArribo = () => {
    setArribo(!arribo);
  };

  const [corrales, setCorrales] = useState(false);
  const handleCorrales = () => {
    setCorrales(!corrales);
  };

  const [observation, setObservation] = useState(false);
  const handleObservation = () => {
    setObservation(!observation);
  };

  const [sacrifice, setSacrifice] = useState(false);
  const handleSacrifice = () => {
    setSacrifice(!sacrifice);
  };

  const [cavas, setCavas] = useState(false);
  const handleCavas = () => {
    setCavas(!cavas);
  };

  const [despacho, setDespacho] = useState(false);
  const handleDespacho = () => {
    setDespacho(!despacho);
  };

  const [general, setGeneral] = useState(false);
  const handleGeneral = () => {
    setGeneral(!general);
  };

  return (
    <PagesContainer>
      <InfotrakingContainer>
        {/* <Loader visible={loading}>Cargando</Loader> */}
        <BreadcrumbContainerStyle>
          <Breadcrumb>
            <Breadcrumb.Section>InfoTracking </Breadcrumb.Section>
            <Breadcrumb.Divider>|</Breadcrumb.Divider>
            <Breadcrumb.Section>InfoPorcinos</Breadcrumb.Section>
          </Breadcrumb>
        </BreadcrumbContainerStyle>
        <FilterReports to="" />
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              <ContentCard padding="20px 35px">
                <ContentCard.Header children="Lotes en Proceso InfoTracking" />
                <SpacingContainer mt={15}>
                  <ContentCard.Content>
                    <Grid columns={3} stackable>
                      <Grid.Column>
                        <Stadistics>
                          <h1>{data?.dashboard?.data?.stats?.total ?? 0}</h1>
                          <p>Total Lotes</p>
                        </Stadistics>
                      </Grid.Column>
                      <Grid.Column>
                        <Stadistics>
                          <h1>
                            {data?.dashboard?.data?.stats?.differentFarmers ??
                              0}
                          </h1>
                          <p>Productores Diferentes</p>
                        </Stadistics>
                      </Grid.Column>
                      <Grid.Column>
                        <DetailsTable
                          finish={data?.dashboard?.data?.stats?.finish ?? 0}
                          wait={data?.dashboard?.data?.stats?.wait ?? 0}
                          process={data?.dashboard?.data?.stats?.process ?? 0}
                        />
                      </Grid.Column>
                    </Grid>
                  </ContentCard.Content>
                </SpacingContainer>
              </ContentCard>
            </Grid.Column>
            <Grid.Column>
              <ContentCard padding="20px 35px">
                <ContentCard.Header children="Promedio Tiempo Procesos" />
                <SpacingContainer mt={15}>
                  <ContentCard.Content>
                    <Grid columns={3} stackable>
                      <Grid.Column>
                        <table>
                          <tr>
                            <td>
                              <b>00:00:00</b>
                            </td>
                            <td>Portería</td>
                          </tr>
                          <tr>
                            <td>
                              <b>00:00:00</b>
                            </td>
                            <td>Beneficio</td>
                          </tr>
                        </table>
                      </Grid.Column>
                      <Grid.Column>
                        <table>
                          <tr>
                            <td>
                              <b>00:00:00</b>
                            </td>
                            <td>Arribo SB</td>
                          </tr>
                          <tr>
                            <td>
                              <b>00:00:00</b>
                            </td>
                            <td>Cavas</td>
                          </tr>
                        </table>
                      </Grid.Column>
                      <Grid.Column>
                        <table>
                          <tr>
                            <td>
                              <b>00:00:00</b>
                            </td>
                            <td>Corrales</td>
                          </tr>
                          <tr>
                            <td>
                              <b>00:00:00</b>
                            </td>
                            <td>Despachos</td>
                          </tr>
                        </table>
                      </Grid.Column>
                    </Grid>
                  </ContentCard.Content>
                </SpacingContainer>
              </ContentCard>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Para borrrar */}
        <ContentCard>
          <Button variant="contained" onClick={handleTransporte}>
            Transporte
          </Button>
          <DialogTransport open={transporte} handleClose={handleTransporte} />

          <Button variant="contained" onClick={handleArribo}>
            Arribo Sala
          </Button>
          <DialogArrival open={arribo} handleClose={handleArribo} />

          <Button variant="contained" onClick={handleCorrales}>
            Ingreso Corrales
          </Button>
          <DialogCorrals open={corrales} handleClose={handleCorrales} />

          <Button variant="contained" onClick={handleObservation}>
            Corrales de Obs.
          </Button>
          <DialoObservation
            open={observation}
            handleClose={handleObservation}
          />

          <Button variant="contained" onClick={handleSacrifice}>
            Beneficio
          </Button>
          <DialogSacrifice open={sacrifice} handleClose={handleSacrifice} />

          <Button variant="contained" onClick={handleCavas}>
            Ingreso Cavas
          </Button>
          <DialogCold open={cavas} handleClose={handleCavas} />

          <Button variant="contained" onClick={handleDespacho}>
            Despacho
          </Button>
          <DialogDespatch open={despacho} handleClose={handleDespacho} />

          <Button variant="contained" onClick={handleGeneral}>
            General
          </Button>
          <DialogGeneral open={general} handleClose={handleGeneral} />
        </ContentCard>

        <ContentCard padding="20px 35px">
          <ContentCard.Header children="Trazablidad Interna de Procesos" />
          <ContentCard.Content>
            <table className="tablebig" cellSpacing={0}>
              <Dialogs
                lote={lote}
                modal={modal}
                open={open}
                onClose={handleClose}
              />
              <tbody>
                <tr>
                  <td>Nro. Lote</td>
                  <td>Fecha</td>
                  <td>Resumen del Proceso</td>
                  <td>Estado</td>
                </tr>
                {data?.dashboard?.data?.processTraceability?.map(
                  (item: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <h2>{item.code}</h2>
                      </td>
                      <td>
                        <p>{moment(item.initialDate).format('YYYY-MM-DD')}</p>
                      </td>
                      <td>
                        <div className="containerstates">
                          <div className="line">.</div>
                          {item.process.map((process: any, i: number) => (
                            <Popup
                              key={i}
                              content={processes[process.name].title}
                              position="top center"
                              style={{ borderRadius: 50, padding: '3px 15px' }}
                              inverted
                              trigger={
                                <Indicator
                                  state={getState(process.state)}
                                  onClick={() =>
                                    process.state === 3 &&
                                    handleOpen(process.name, {
                                      lote: item._id,
                                      code: item.code,
                                    })
                                  }
                                >
                                  <Icon
                                    className={processes[process.name].icon}
                                    size="big"
                                  />
                                </Indicator>
                              }
                            />
                          ))}
                          <Indicator
                            state={
                              item.process[item.process.length - 1].state === 3
                                ? 'finish'
                                : 'wait'
                            }
                          >
                            <Icon className="icon-check" size="big" />
                          </Indicator>
                        </div>
                      </td>
                      <td>
                        <div
                          className="process"
                          onClick={() =>
                            item.process[item.process.length - 1].state === 3 &&
                            handleOpen('general', {
                              lote: item._id,
                              code: item._id,
                            })
                          }
                        >
                          {item.process[item.process.length - 1].state === 3
                            ? 'Finalizado'
                            : 'En Proceso'}
                        </div>
                      </td>
                    </tr>
                  )
                ) ?? (
                  <tr>
                    <td colSpan={4}>No se han encontrado resultados</td>
                  </tr>
                )}
                {data?.dashboard?.data?.processTraceability?.length < 1 && (
                  <tr>
                    <td colSpan={4}>No se han encontrado resultados</td>
                  </tr>
                )}
              </tbody>
            </table>
            <States>
              <State>
                <StateIndicator state="finish" />
                <p>Finalizado</p>
              </State>
              <State>
                <StateIndicator state="process" />
                <p>En Proceso</p>
              </State>
              <State>
                <StateIndicator state="error" />
                <p>Error</p>
              </State>
              <State>
                <StateIndicator state="wait" />
                <p>En Espera</p>
              </State>
            </States>
          </ContentCard.Content>
        </ContentCard>
      </InfotrakingContainer>
    </PagesContainer>
  );
};

export default Infotraking;
