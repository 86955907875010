import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  Badge,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
  Button,
  Snackbar,
  TextField,
  InputAdornment,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { SIGN_IN } from "core/redux/types/userTypes";
import useStyles from "./styles";
import { Alert } from "@material-ui/lab";
import {
  LockOutlined,
  PersonOutlineOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import AuthFormContainer from "components/AuthFormContainer";
import BlockedAccountDialog from "components/BlockedAccountDialog";
const TypeUserDialog = ({ open, items = [], onSelect = () => {} }: any) => {
  const classes = useStyles();
  const getIcon = (id: number) => {
    switch (id) {
      case 1:
        return "icon-productor";
      case 2:
        return "icon-comercializador";
      default:
        break;
    }
  };
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({
      type: SIGN_IN,
      payload: {
        openModalSelect: false,
      },
    });
  };
  return (
    <Dialog
      open={open}
      className={classes.dialogContent}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogHeader}>
        Seleccionar tipo de usuario
      </DialogTitle>
      <Typography className={classes.secondaryText}>
        A continuación, seleccione el tipo de usuario con el que desea iniciar
        sesión.
      </Typography>
      <div className={classes.flexContainer}>
        {items.map((item: any, i: number) => (
          <Tooltip title="Tienes notificaciones" placement="bottom">
            <Badge badgeContent=" " variant="dot" color="error">
              <Card className={classes.cardItem}>
                <CardActionArea
                  key={i}
                  onClick={() => onSelect(item.idUserType)}
                  className={classes.cardActionArea}
                >
                  <CardContent className={classes.cardContent}>
                    <i className={clsx(getIcon(item.id), classes.roleIcon)}></i>
                    <Typography className={classes.mainText}>
                      {item.name}
                    </Typography>
                    <Typography className={classes.secondaryText}>
                      {item.role}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Tooltip>
        ))}
      </div>
    </Dialog>
  );
};
export const Login = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  loading,
  openModalSelect,
  typeUsers,
  handleSelectUser,
  hasError,
  errorMessage,
  blockUser,
  handleBlockUserClose,
}: any) => {
  const classes = useStyles();
  const [viewPass, setViewPass] = useState(false);
  return (
    <div className={classes.root}>
      <span className={classes.request_button_container}>
        <Typography color="textSecondary">¿No tienes un usuario?</Typography>
        <Button
          component={Link}
          to="/auth/request-user"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          solicitar
        </Button>
      </span>
      <AuthFormContainer onSubmit={handleSubmit} title="">
        <TextField
          name="username"
          label={values.username?.length > 0 && "Nombre de Usuario"}
          placeholder="Nombre de Usuario"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={(errors.username && touched.username) || hasError}
          helperText={errors.username && touched.username && errors.username}
          variant="outlined"
          fullWidth
          size="small"
          style={{ marginBottom: 15 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlineOutlined />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="password"
          type={viewPass ? "text" : "password"}
          label={values.password?.length > 0 && "Contraseña"}
          placeholder="Contraseña"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={(errors.password && touched.password) || hasError}
          helperText={errors.password && touched.password && errors.password}
          variant="outlined"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setViewPass(!viewPass)}>
                  {!viewPass ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div
          className="forgot-pass"
          style={{
            marginTop: !(errors.password && touched.password) ? 5 : 0,
          }}
        >
          <Link to="/auth/forgot-password">¿Olvidaste la contraseña?</Link>
        </div>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={clsx(classes.button, classes.marginRight)}
          endIcon={
            loading && <CircularProgress size={20} style={{ color: "#FFF" }} />
          }
        >
          Entrar
        </Button>
        <Button
          component={Link}
          to="/"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Volver atrás
        </Button>
      </AuthFormContainer>
      <TypeUserDialog
        open={openModalSelect}
        items={typeUsers}
        onSelect={handleSelectUser}
      />
      <BlockedAccountDialog {...blockUser} onClose={handleBlockUserClose} />
      <Snackbar
        open={hasError}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
