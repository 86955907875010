import React, { useState, useEffect } from 'react';

import { Typography, Button, Box, CircularProgress } from '@material-ui/core';

import Detail from './details';
import Edit from './edit';
import Avatar from '../../avatar';

import { GENERAL_DATA } from 'core/graphql/queries/profile';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useQuery } from '@apollo/client';

import useStyles from './styles';
import constants from 'config/constants';

const General = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const path = useLocation();

  const [edit, setEdit] = useState(false);
  const [Data, setData] = useState<any>({});

  const { data, loading, refetch } = useQuery(GENERAL_DATA, {
    context: { headers: { 'x-auth-jwt': token } },
  });
  const handleChangeView = () => {
    refetch();
    setEdit(!edit);
  };

  useEffect(() => {
    if (!loading && data) {
      setData(data?.getGeneralDataProfile?.data?.datosGenerales);
    }
  }, [loading, data]);

  useEffect(() => {
    refetch();
  }, [path]);

  const generalData = {
    username: Data?.usuario,
    email: Data?.email,
    indicativoTelefono: Data?.indicativoTelefono ?? 2895,
    idIndicativoTelefono: Data?.idIndicativoTelefono ?? 2895,
    telefono: Data?.telefono ?? '',
    extension: Data?.extension ?? '',
    imgPerfil: Data?.imagen,
    idTipoDocumento: Data?.idTipoDocumento,
    numeroDocumento: Data?.numeroDocumento,
    digitoVerificacion: Data?.digitoVerificacion,
    razonSocial: Data?.razonSocial,
    primerNombre: Data?.primerNombre,
    segundoNombre: Data?.segundoNombre,
    primerApellido: Data?.primerApellido,
    segundoApellido: Data?.segundoApellido,
    primerNombreRL: Data?.primerNombreRL ?? '',
    segundoNombreRL: Data?.segundoNombreRL ?? '',
    primerApellidoRL: Data?.primerApellidoRL ?? '',
    segundoApellidoRL: Data?.segundoApellidoRL ?? '',
    idTipoDocumentoRL: Data?.idTipoDocumentoRL ?? 1,
    numeroDocumentoRL: Data?.numeroDocumentoRL ?? '',
    idCargoRL: 1,
    idRepresentanteLegal: Data?.idUsuarioRL ?? 0,
    indicativoCelularRL: Data?.indicativoCelularRL ?? 48,
    idIndicativoCelularRL: Data?.idIndicativoCelularRL ?? 48,
    celularRL: Data?.celularRL ?? '',
    emailRL: Data?.emailRL ?? '',
    idTipoDireccion: Data?.tipoDireccion,
    idTipoVia: Data?.idTipoVia,
    tipoVia: Data?.tipoVia,
    numeroVia: Data?.numeroVia ?? 0,
    apendiceVia: Data?.apendiceVia,
    numeroCruce: Data?.numeroCruce ?? 0,
    apendiceCruce: Data?.apendiceCruce,
    metrosEsquina: Data?.metrosEsquina ?? 0,
    adicional: Data?.adicional ?? '',
    codigoPostal: Data?.codigoPostal ?? '',
    idCiudad: `${Data?.idCiudad}` ?? constants.DEFAULT_CITY_ID,
    idDepartamento: Data?.idDepartamento ?? constants.DEFAULT_DEPARTMENT_ID,
    idPais: Data?.idPais ?? constants.DEFAULT_COUNTRY_ID,
    ciudad: Data?.ciudad,
    departamento: Data?.departamento,
    pais: Data?.pais,
    indicativoCelular: Data?.indicativoCelular ?? 48,
    idIndicativoCelular: Data?.idIndicativoCelular ?? 48,
    celular: Data?.celular,
    indicativoTelegram: Data?.indicativoTelegram ?? 48,
    idIndicativoTelegram: Data?.idIndicativoTelegram ?? 48,
    telegram: Data?.telegram ?? '',
    paginaWeb: Data?.paginaWeb,
    indicativoWhatsapp: Data?.indicativoWhatsapp ?? 48,
    idIndicativoWhatsapp: Data?.idIndicativoWhatsapp ?? 48,
    whatsapp: Data?.whatsapp ?? '',
    fechaRegistroUsuario: Data?.fechaRegistroUsuario,
  };

  if (loading) {
    return (
      <Box
        minHeight="500px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress variant="indeterminate" size={50} />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.titleContainer}>
        <Typography variant="h6" className={classes.title}>
          Datos Generales{' '}
          <i className="icon-circle-check" style={{ color: '#68CE70  ' }} />
        </Typography>
        <Box display="flex" alignItems="center">
          <Box
            width={50}
            height={50}
            display="flex"
            justifyContent="center"
            margin="0px 12px"
          >
            <Avatar />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangeView}
          >
            {edit ? 'Descartar' : 'Editar'}
          </Button>
        </Box>
      </Box>

      {edit ? <Edit data={generalData} /> : <Detail data={generalData} />}
    </>
  );
};

export default General;
