import { combineReducers } from 'redux'

import userReducer from './userReducer'
import userProfileReducer from './userProfileReducer'
import worldReducer from './worldReducer'
import uiReducer from './uiReducer'
import dataTableReducer from './dataTableReducer'
import dataReducer from './dataReducer'

export default combineReducers({
  user: userReducer,
  userProfile:userProfileReducer,
  dataTableReducer,
  data: dataReducer,
  ui: uiReducer,
  world: worldReducer
})
