import {
  Badge,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CheckCircleOutlined,
  Close,
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@material-ui/icons';
import { useSnackbar, VariantType } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router';
import jwt from 'jwt-decode';

import RecoverPasswordValidations from 'lib/validations/RecoverPasswordValidations';

import AuthFormContainer from 'components/AuthFormContainer';

import { recoverPassword } from 'api/resetPassword';

import useStyles from './styles';

interface FormValues {
  password: string;
  'validate-password': string;
}

const RecoverPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [hasError, setHasError] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const token = new URLSearchParams(window.location.search).get('token');

  /**
   * Show a snackbar on ui when is called
   * @param {string} message : Message to show
   * @param {VariantType} variant (success, warning, error, info) style of alert
   * @param {boolean} withCallback Define if onClose function redirect or exect a callback function
   */

  const alertSnack = (
    message: string,
    variant: VariantType = 'error',
    withCallback = true
  ) =>
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 5000,
      onClose: () => (withCallback ? history.push('/auth/login') : null),
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      action: (key) => (
        <IconButton onClick={() => closeSnackbar(key)} size="small">
          <Close />
        </IconButton>
      ),
    });

  useEffect(() => {
    try {
      if (!token) {
        alertSnack('Token invalido', 'error');
      } else {
        const { exp }: any = jwt(token ?? '');
        if (exp && exp > new Date().getTime() / 1000) {
          setLoading(false);
        } else {
          alertSnack(
            'El enlace de recuperación de contraseña ha expirado',
            'warning'
          );
        }
      }
    } catch (error: any) {
      alertSnack(error);
    }
  }, []);

  const onSubmit = (
    data: { password: string },
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    recoverPassword(data.password, token ?? '')
      .then((res) => {
        if (res.status && res.data.success === 1) {
          alertSnack(
            'Tu contraseña ha sido actualizada correctamente, inicia sesión!',
            'success'
          );
        } else {
          setSubmitting(false);
          alertSnack(
            'Ha ocurrido un error, contactate con soporte e intentalo de nuevo',
            'error',
            false
          );
          setHasError(true);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        alertSnack(err.toString(), 'error', false);
        setHasError(true);
      });
  };

  const toggleType = () => setShowPass(!showPass);

  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.backdrop}>
          <CircularProgress color="primary" size={60} />
          <Typography variant="h6" component="b">
            Validando token
          </Typography>
        </div>
      )}
      <Formik
        initialValues={{ password: '', 'validate-password': '' }}
        onSubmit={onSubmit}
        validationSchema={RecoverPasswordValidations}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <AuthFormContainer
            onSubmit={handleSubmit}
            title="Cambio de contraseña"
            subtitle="A continuación ingrese la nueva contraseña"
          >
            <TextField
              name="password"
              type={showPass ? 'text' : 'password'}
              placeholder="Nueva contraseña"
              label={values.password && 'Nueva contraseña'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              variant="outlined"
              size="small"
              fullWidth
              required
              style={{ marginBottom: 15 }}
              error={(errors.password && touched.password) || hasError}
              helperText={
                errors.password && touched.password && errors.password
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={toggleType}>
                      {!showPass ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              name="validate-password"
              type={showPass ? 'text' : 'password'}
              placeholder="Confirmar contraseña"
              label={values['validate-password'] && 'Confirmar contraseña'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values['validate-password']}
              style={{ marginBottom: 15 }}
              variant="outlined"
              size="small"
              fullWidth
              required
              error={
                (errors['validate-password'] && touched['validate-password']) ||
                hasError
              }
              helperText={
                errors['validate-password'] &&
                touched['validate-password'] &&
                errors['validate-password']
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Badge
                      badgeContent={
                        <CheckCircleOutlined style={{ fontSize: 13 }} />
                      }
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                      <LockOutlined />
                    </Badge>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={toggleType}>
                      {!showPass ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={
                isSubmitting && <CircularProgress size={20} color="inherit" />
              }
              disabled={isSubmitting}
            >
              {isSubmitting ? 'GUARDANDO' : 'GUARDAR'}
            </Button>
          </AuthFormContainer>
        )}
      </Formik>
    </div>
  );
};

export default RecoverPassword;
