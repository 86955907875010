import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface ItemTotalProps {
  value: string | number;
  info: string;
  backgroundColor?: string;
  fontColor?: string;
  position?: 'bottom' | 'right' | 'left';
  fontSizeValue?: string | number;
  heightValue?: string | number;
  widthValue?: string | number;
  widthInfo?: string | number;
  alignFontInfo?: 'right' | 'left' | 'center';
}

const ItemTotal = ({
  value,
  info,
  backgroundColor,
  fontColor,
  position,
  fontSizeValue,
  heightValue,
  widthValue,
  widthInfo,
  alignFontInfo,
}: ItemTotalProps) => {
  return (
    <View
      style={{
        alignItems: 'center',
        flexDirection:
          position == 'bottom'
            ? 'column'
            : position == 'left'
            ? 'row-reverse'
            : 'row',
      }}
    >
      <View
        style={[
          styles.backgroundweigth,
          {
            backgroundColor: `${
              backgroundColor ? (backgroundColor as String) : '#D47D57'
            }`,
            height: heightValue ? heightValue : 20,
            width: widthValue ? widthValue : 60,
          },
        ]}
      >
        <Text
          style={{
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            fontSize: fontSizeValue ? fontSizeValue : 15,
            color: fontColor ? fontColor : '#fff',
          }}
        >
          {value}
        </Text>
      </View>
      <View style={{ justifyContent: 'center', textAlign: 'center' }}>
        <Text
          style={[
            styles.weigthanimals,
            {
              marginLeft:
                position == 'right'
                  ? '10px'
                  : position == 'bottom'
                  ? '0px'
                  : '10px',
              marginRight:
                position == 'left'
                  ? '5px'
                  : position == 'bottom'
                  ? '0px'
                  : '10px',
              width: widthInfo ? widthInfo : '50px',
              textAlign:
                position == 'right'
                  ? 'left'
                  : alignFontInfo
                  ? alignFontInfo
                  : 'center',
              marginTop: position == 'bottom' ? '3px' : '0px',
            },
          ]}
        >
          {info}
        </Text>
      </View>
    </View>
  );
};

export default ItemTotal;
