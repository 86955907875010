import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Grid, Button, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import ContentCard from 'components/ContentCard';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import CardSelectDriver from 'components/TransportComponents/CardSelectDriver/index';
import Loader from 'components/Loader';
import GobackLink from 'components/GobackLink';
import LicensePlate from 'components/LicensePlate/index';
import MainTitle from 'components/MainTitle';
import ItemListData from 'components/ItemListData';
import Slider from 'components/Slider';

import {
  GET_DRIVERS_VEHICLE,
  GET_VEHICLE,
} from 'core/graphql/queries/transport';

import useStyles from './styles';

const DetailVehicle = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: `/app/network/transports/details/vehicle/${id}`,
      title: 'Detalle Vehículo',
    },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];
  const [selectedValue, setSelectedValue] = useState({});
  const [dataSelect, setDataSelect] = useState({
    idConductor: '',
    descripcionTipoDocumento: '',
    numeroDocumento: '',
    nombreCompleto: '',
    numeroLicenciaConduccion: '',
    email: '',
    celular: '',
    indicativoCelular: '',
    direccion: '',
  });
  const token = useSelector((state: any) => state.user.token);
  const { pathname } = useLocation();

  const [
    getVehicle,
    { data: dataVehicle, loading: loadingVehicle, error: errorVehicle },
  ] = useLazyQuery(GET_VEHICLE, {
    fetchPolicy: 'network-only',
  });

  const {
    data: dataDrivers,
    loading: loadingDrivers,
    error: errorDrivers,
    refetch: refetchDrivers,
  } = useQuery(GET_DRIVERS_VEHICLE, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idVehiculo: Number(id) },
  });

  const generalData = dataVehicle?.getDetailVehicle?.data;
  const drivers = dataDrivers?.getDriversXVehicle?.data;

  const handleChangeSelect = (data: any) => {
    setSelectedValue(data?.idConductor);
    setDataSelect(data);
  };

  useEffect(() => {
    if (id) {
      getVehicle({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idVehiculo: Number(id) },
      });
    }
  }, [id]);

  useEffect(() => {
    const path = pathname.slice(0, -3);
    if (path === '/app/network/transports/details/vehicle') {
      refetchDrivers();
    }
  }, [pathname]);

  useEffect(() => {
    if (!loadingVehicle && !loadingDrivers && dataDrivers && !errorDrivers) {
      setSelectedValue(drivers[0]?.idConductor ?? {});
      setDataSelect({
        idConductor: drivers[0]?.idConductor,
        descripcionTipoDocumento: drivers[0]?.descripcionTipoDocumento,
        numeroDocumento: drivers[0]?.numeroDocumento,
        nombreCompleto: drivers[0]?.nombreCompleto,
        numeroLicenciaConduccion: drivers[0]?.numeroLicenciaConduccion,
        email: drivers[0]?.email,
        celular: drivers[0]?.celular,
        indicativoCelular: drivers[0]?.indicativoCelular,
        direccion: drivers[0]?.direccion,
      });
    }
  }, [loadingVehicle, loadingDrivers]);

  const breakPointsSlider = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  ];

  return (
    <>
      <Loader visible={loadingVehicle || loadingDrivers}>Cargando...</Loader>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <Grid container spacing={4}>
        <Grid container item sm={6}>
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.contentCard}>
              <ContentCard.Header>
                <MainTitle fontSize="h5">Datos del Vehículo</MainTitle>
              </ContentCard.Header>

              <Box mt={3} mb={3}>
                <Grid container justifyContent="center">
                  <Grid item container xs={6} justifyContent="center">
                    <LicensePlate
                      placa={generalData?.placa}
                      ciudad={generalData?.ciudad}
                      width={250}
                      checked
                    />
                  </Grid>
                  <Grid item container xs={6} justifyContent="flex-end">
                    <img
                      style={{ maxWidth: 160 }}
                      src={`data:image/png;base64, ${generalData?.codigoQr}`}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ItemListData
                    data={[
                      {
                        title: 'Marca',
                        data: generalData?.marca,
                      },
                      {
                        title: 'Tipo de Vehículo',
                        data: generalData?.descripcionTipoVehiculo,
                      },
                      {
                        title: 'Modelo',
                        data: generalData?.modelo,
                      },
                      {
                        title: 'Color',
                        data: generalData?.color,
                      },
                      {
                        title: 'Número de Pisos',
                        data: generalData?.numeroPisos,
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={6}>
                  <ItemListData
                    data={[
                      {
                        title: 'Servicio',
                        data:
                          Number(generalData?.servicio) === 1
                            ? 'Publico'
                            : Number(generalData?.servicio) === 2
                            ? 'Privado'
                            : '',
                      },
                      {
                        title: 'Nro. Lincencia de Transito',
                        data: generalData?.numeroLicenciaTransito,
                      },
                      {
                        title: 'Capacidad',
                        data:
                          generalData?.capacidadCarga +
                          ' ' +
                          generalData?.descripcionTipoCarga,
                      },
                      {
                        title: 'Propietario',
                        data:
                          generalData?.primerNombrePropietario +
                          ' ' +
                          generalData?.segundoNombrePropietario +
                          ' ' +
                          generalData?.primerApellidoPropietario +
                          ' ' +
                          generalData?.segundoApellidoPropietario,
                      },
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Box mt={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/app/network/transports/forms/detail-vehicle/${id}`}
                  >
                    Ver detalle
                  </Button>
                </Box>
              </Grid>
            </ContentCard>
          </Grid>
        </Grid>

        <Grid container item sm={6} alignItems="stretch">
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.contentCardPlacas}>
              <Box mb={3}>
                <ContentCard.Header
                  date={`${drivers?.length ?? 0} Conductores`}
                >
                  <MainTitle fontSize="h5">Conductores Asociados</MainTitle>
                </ContentCard.Header>
              </Box>

              <ContentCard.Content>
                <Box display="flex" justifyContent="center">
                  <Slider
                    breakPoints={breakPointsSlider}
                    className={classes.carouselContainer}
                  >
                    {drivers?.map((item: any) => {
                      return (
                        <CardSelectDriver
                          onClick={() => handleChangeSelect(item)}
                          name={item?.nombreCompleto}
                          ubication="Medellín"
                          height={185}
                          checked={selectedValue === item?.idConductor}
                        />
                      );
                    })}
                  </Slider>
                </Box>
              </ContentCard.Content>
            </ContentCard>
          </Grid>

          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.contentCard}>
              <ContentCard.Header>
                <MainTitle fontSize="h5">
                  Datos Generales de Conductor:
                </MainTitle>
              </ContentCard.Header>
              {!loadingDrivers && !errorDrivers && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <ItemListData
                        data={[
                          {
                            title: 'Nombre Completo',
                            data: dataSelect.nombreCompleto,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <ItemListData
                        data={[
                          {
                            title: 'Tipo de Documento',
                            data: dataSelect.descripcionTipoDocumento,
                          },
                          {
                            title: 'Documento',
                            data: dataSelect.numeroDocumento,
                          },
                          {
                            title: 'Nro. Lincencia de Conducción',
                            data: dataSelect.numeroLicenciaConduccion,
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ItemListData
                        data={[
                          {
                            title: 'Correo Electrónico',
                            data: dataSelect.email,
                          },
                          {
                            title: 'Celular',
                            data: dataSelect.celular,
                          },
                          {
                            title: 'Dirección',
                            data: dataSelect.direccion,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </ContentCard>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default DetailVehicle;
