import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  widthCard: {
    width: "100%",
    padding: "25px 50px 25px 50px !important",
  },
  sizeAvatar: {
    width: "100px",
    height: "100px",
  },
  fontColor: {
    color: "#767676",
  },
  fontUlcolor: {
    color: "#767676",
    fontWeight: "bold",
  },
  fontButton: {
    fontWeight: "bold",
  },
  fontInfo: {
    color: "#AFAFAF",
    fontWeight: "bold",
  },
  fontNumber: {
    fontSize: "45px",
    color: "#767676",
    fontWeight: "bold",
    textAlign: "center",
  },
  fontAverage: {
    color: "#AFAFAF",
    textAlign: "center",
  },
  contetnAverage: {
    justifyContent: "center",
  },
  paddingContent: {
    padding: "25px 50px 25px 50px",
  },
  contentCard: {
    width: "100%",
    padding: "25px 50px 25px 50px !important",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  userNameContainer: {
    display: "flex",
    margin: 20,
  },
  avatarUser: {
    width: 100,
    height: 100,
  },
  containerUserInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#767676",
    marginLeft: 30,
  },
  boldText: {
    fontWeight: "bolder",
  },
  dataUserContainer: {
    margin: 12,
    marginTop: "30px",
  },
  listContainer: {
    color: theme.palette.primary.main,
    margin: 0,
  },
  itemListData: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0",
    "& .MuiTypography-root": {
      color: "#767676",
    },
  },
  itemList: {
    display: "flex",
    justifyContent: "space-between",
    color: "#767676",
  },
  itemChild: {
    fontWeight: "bolder",
  },
  switch: {
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      background: theme.palette.success.main,
      opacity: 1,
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "#fff",
    },
  },
  colorHr: {
    borderColor: "#E2E2E2",
  },
  subTitileColor: {
    color: "#767676",
    fontSize: "14px",
    fontWeight: "bold",
  },
  margin: {
    marginLeft: "35px",
    marginRight: "10px",
  },
  typography: {
    padding: theme.spacing(2),
  },
  avatar: {
    width: "50px",
    height: "50px",
    backgroundColor: "#DD9835 !important",
    fontWeight: "bolder",
    fontSize: "22px",
  },
  ulContainer: {
    listStyle: "none",
  },
  spanTitle: {
    fontWeight: "bolder",
    color: "#767676",
  },
  gridHover: {
    cursor: "pointer",
    "& :hover": {
      backgroundColor: "#F2F2F2",
    },
  },
  breadContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: 5,
  },
}));

export { useStyles as default };
