import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  colorChip:{
    backgroundColor: '#767676 !important',
    color: 'white',
    "& .MuiChip-labelSmall":{
      textDecoration: 'none',
      color: 'white'
    }
  },
  result:{
    color: '#767676',
    fontSize: '14px'
  },
  dataGrid: {
    border: 'none',
    "& .MuiDataGrid-columnSeparator" :{
      color: 'transparent',
    },
    "& .MuiDataGrid-colCellTitle" :{
      color: '#AFAFAF',
    },
    "& .MuiDataGrid-cell:focus" :{
      outline: 'none'
    },

    "& .MuiDataGrid-cell:focus-within" :{
      outline: 'none',
      outlineOffset: 'none'
    },

    "& .MuiDataGrid-row.Mui-selected" :{
      backgroundColor: 'transparent'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14
    }
  },
  title:{
    fontWeight: 'bold',
    color: '#212121',
    fontSize: '22px',
  },
  colorHr: {
    borderColor: '#E2E2E2',
  },
  fontDate:{
    color: '#AFAFAF',
    fontWeight: 'bold',
    fontSize: '17px'
  },
  date:{
    color: '#DD9835',
  },
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
  },
  cardContainer:{
    width:'100%',
    padding: '35px !important'
  },

  filterType: {
    marginRight: theme.spacing(1.5),
    fontSize: '16px'
  },
  filterValue: {
    color: theme.palette.text.hint,
    fontSize: '16px',
    fontWeight: 'bold',
    '& > .param': {
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontWeight: 'bold',
    }
  },
}))

export { useStyles as default }