/** @format */

const whiteFarm = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABJCAYAAABxcwvcAAAACXBIWX
MAACxKAAAsSgF3enRNAAADq0lEQVR4nO2c7W3bMBCGr0X/RxvEG9gbRJmgzgTxBnU38AjKBHU2cC
aosoEyQZ0N7AlYKDkCKiGRLz8kssA9gJDYkSnqEY88Uoq/KKVIsPNV/LgRSQAiCUAkAYgkAJEEIJ
IARBKASAIQSQAiCUAkAXwrqC4r3jQdEV3yVumTEiTVRLQjokfj/SsRnYjoQETnTHX7INdSScVi9k
R0C+z/SkRH3hZnaUkbFrMlopuAz78PZC3WupaStOPtzrLPs3HitWP/FyJqiKhNWM9xekkzbSulVK
OUuqhpzkqpg1KqmqhDX8YRKGNvKSN6m6PQjVLqZDmpnlYptfUosxewYyFTXFhoclmpBe0cJ9Fw64
g5Rs0ybMfZlCppNREWHctLfYUrDtWx1tWVKsm8uh1f9an9O0dImjSOFmxeoH2qc0s1uvUj0e/B6z
d+z5Yx6wO/O4bzDacLr1ymbb92kFpcOYOPz9oT2W6Nq2hrQXrTuMJwy/u1QJl7ox7HUsLN7KzRim
lc+9UeksbCOLoTj10FqHhupbkar3OwN47ZxNYhVpI592pyT0a5X3oZvL7jbD+ciGZoDvlnz2F+rn
BLUbdk4XYwJqmHwJFkE/n3Mc5GmN2OhCFOoN1a/YvPVTZbEorvMaqRRDMo2w9ddDM755DO+gloRU
N815IuXK9fg/caXqbxI8Bs6JCfawvJ4aL6JHPIp1yrhR6Y603eKYGvJHS5tWTWvp24j6RVxAhx5L
la6i20FR84KiB8JJlDPspx5E5IKh4DM+obn8EGlVRHnOhcgjQheVTPD/SzaAowx3zsyTP5rPjEYr
ga0dA4ll8+QCSZdzmeE7WOGlhz0lSJ7orou8Lf+fUd18FaNhJuww4u5Sx/zZVzdaBa0DrRcc3Bxx
lyiKTT4PfYW84/WbTGJWpM0BuHaih9/e+5jBdkhETCrS/0gX92EZUj/nxtLLNqUWboTQmqoyarn7
Q+4YuObqcEgjRalK1F2QQt/qRJrueTbKJWJQmizA9xTYn6U5IgKuBJtzFRQ7ILokIeB+wsazzbEp
52K0FSZZl/nXwmonORW5IrUUQTzlnJKWlqmL/3TDhnJ5ckWx7UAnnUouSQhCSKSMK5GEtL8smkix
G19HPcR2M9x5UHTc31Uq0IQCzdknwEaVwJ5+zk6rh9M+msokKfdPtfv3HB9bTcKPJfSgAiCSB0dL
tftJbpCJosyxe6AEi4AYgkAJEEIJIARBKASAIQSQAiCUAkAYgkAJHkgoj+AjSp5O4o539zAAAAAE
lFTkSuQmCC`;

export default whiteFarm;
