import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: '8px 0',
  }, 
  generalContainer: {
    "& .kkIWUE":{
      [theme.breakpoints.between('sm', 'md')]: {
        padding: '14px !important'
      }
    },
    "& .cKVytO":{
      [theme.breakpoints.between('sm', 'md')]: {
        padding: '14px !important'
      }
    },
  },
  contentTableHeader:{
    display:'flex',
    justifyContent:'space-between',
    padding:12,
    borderBottom: '1px #E2E2E2 solid'
  },
  contentHeaderChild1:{
    display: 'flex',
    alignItems:'center',
  }, 
  result:{
    display:'flex', 
    alignItems:'center',
    marginRight: 8,
    "& i":{
      fontSize:24,
    }
  },
  titleHeaderTable:{
    fontSize: 24, 
    fontWeight: 600
  },
  textBold:{
    fontWeight: 600 , 
  },
  textUsersGray:{
    fontWeight: 600, 
    color: '#767676',
    fontFamily:'Roboto',
  },
  filterType:{
    fontWeight: 600, 
    marginLeft: 8, 
    color: theme.palette.primary.main
  },
  dataGrid:{
    border:'none', 
    display:'flex',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator":{
      display:'none'
    },
    
  },
  noData:{
    minHeight: 300,
    border:'none', 
    "& .MuiDataGrid-columnsContainer":{
      display: 'none'
    }, 
  },
  headerTableGrid:{
    fontSize:14, 
    color: '#AFAFAF',
    fontFamily:'Roboto',
  }, 
  generateReport:{
    display:'flex', 
    justifyContent:'flex-end', 
    alignItems:'center', 
    padding: '12px 0'
  }, 
  generateReportText:{
    color:'#767676', 
    margin: '0 12px'
  },
  fontUserName:{
    marginLeft: 10,
    color: '#dd9835',
    fontWeight: 'bold'
  },

}))

export { useStyles as default }