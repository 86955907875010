import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: '8px 0',
  },
  tableContainer:{
    display: 'flex',
    justifyContent:'center', 
    padding: 20
  }, 
  containerMap:{
    display:'flex', 
    justifyContent:'center', 
    margin: '30px 0px', 
    padding: 20
  }
}))

export { useStyles as default }