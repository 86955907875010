import { TOGGLE_SIDEBAR_FOLDED, FOLDED } from "core/redux/types/uiTypes"
import { useDispatch } from "react-redux"

const useManageUI = () => {
  const dispatch = useDispatch()

  const toggleSidebarFolded = () => {
    dispatch({
      type: TOGGLE_SIDEBAR_FOLDED
    })
  }

  const foldedAndOpened = () => {
    dispatch({
      type: FOLDED,
      payload: false
    })
  }
  
  const foldedAndClosed = () => {
    dispatch({
      type: FOLDED,
      payload: true
    })
  }

  return { toggleSidebarFolded, foldedAndOpened, foldedAndClosed }
}

export default useManageUI