import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  exportButton:{
    background:'#1A7343', 
    color: '#fff', 
    fontWeight: 'bold', 
    fontSize: 14,
    margin: '0px 4px',
    "&:hover":{
      background:'#0d3a22', 
    }
  },
}))

export { useStyles as default }