import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container:{
    margin: '20px 0px',
    padding: '20px 40px',
    borderRadius: 20, 
    boxShadow: theme.shadows[3], 
  },  
  titleContainer:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    marginBottom: 8, 
  }, 
  title: {
    display:'flex', 
    alignItems:'center',
    fontSize: 24,
    "& i":{
      fontSize: 30, 
      marginLeft: 4
    }
  }, 
  subtitle:{
    color: '#AFAFAF',
    fontWeight: 'bold'
  }, 
  info:{
    color: '#AFAFAF', 
    fontSize: 16
  },
  sectionContainer:{
    marginTop: 20, 
    marginBottom: 20
  }, 
  infoContainer:{
    minWidth: '30%', 
    margin:'4px 0'
  },
  infoChild:{
    display:'flex', 
    justifyContent:'space-between', 
    padding: '4px 0px'
  },
  infoTitle:{
    color: '#AFAFAF', 
    fontWeight:'bold', 
    fontSize: 16, 
    marginRight: 8
  },
  infoValue:{
    color: '#767676', 
    fontWeight:'bold', 
    fontSize: 16
  }, 
  aditionalUsersSection:{
    display: 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center'
  }, 
  captions:{
    color: '#AFAFAF',
    fontWeight: 'bold', 
    margin: '8px 0px'
  }
}));

export default useStyles;
