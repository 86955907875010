import React from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

interface ConventionsProps {
  items: Array<{
    state: boolean;
    icon?: string;
    label: string;
  }>;
  horizontal?: boolean;
}

const Conventions = ({ items, horizontal }: ConventionsProps) => {
  const classes = useStyles();
  return (
    <List
      className={clsx({
        [classes.container]: true,
        [classes.containerHorizontal]: horizontal,
      })}
    >
      {items.map((items: any) => {
        return (
          <ListItem
            className={clsx({
              [classes.contaienrItem]: !horizontal,
              [classes.itemsHorizontal]: horizontal,
            })}
          >
            <Typography className={classes.label}>
              <i className={clsx(items.icon)} />
              {items.label}
            </Typography>
            {items.state ? (
              <i className={clsx('icon-circle-check', classes.iconCheck)} />
            ) : (
              <i className={clsx('icon-circle-close', classes.iconClose)} />
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default Conventions;
