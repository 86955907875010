import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export default makeStyles((theme: Theme) => ({
  button: {},
  menu_paper: {
    minWidth: 400,
    maxWidth: 450,
    borderRadius: theme.spacing(1.2),
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    })
  },
  menu_list: {
    padding: 0,
  },
  menu_list_title: {
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:focus': {
      outline: 'none',
    },
    '& .MuiTypography-h6': {
      fontFamily: 'Raleway',
      fontWeight: 600,
      color: theme.palette.primary.main,
      fontSize: 17
    },
  },
  list_item: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: theme.spacing(1.5),
  },
  list_item_container: {
    position: 'relative',
    display: 'flex',
    '&:not(:last-child)': {
      marginBottom: 3,
    },
    '&:before': {
      content: '" "',
      background: grey[200],
      height: 1,
      width: '80%',
      position: 'absolute',
      bottom: -2,
      left: 'calc(10%)',
    },
    '&:hover $close_btn_container': {
      opacity: 1,
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  },
  close_btn_container: {
    padding: theme.spacing(1.5),
    paddingLeft: 0,
    opacity: 0,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  notification_text: {
    padding: theme.spacing(0.5, 0),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  notification_text_main: {
    fontFamily: 'Raleway',
    fontSize: 13,
    textAlign: 'left',
    '& b': {
      color: '#DD9835'
    }
  },
  avatar_item: {
    paddingRight: theme.spacing(2),
  },
  avatar_icon: {
    fontSize: '14px !important'
  },
  notRead: {
    background: 'rgba(221, 152, 53, 0.16)',
  },
  list_item_noti_element: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  list_item_noti_icon: {
    minWidth: 40,
  },
  read_all: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  settings_container: {
    height: '100%'
  }
}));
