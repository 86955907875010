import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: "flex",
    margin: 0,
    flexDirection: "column",
    color: theme.palette.primary.main,
    "&.column": {
      flexDirection: "column",
    },
    "&.row": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  withoutListStyle: {
    listStyle: "none",
    padding: 0,
  },
  itemListData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0",
    "& .MuiTypography-root": {
      color: "#767676",
    },
  },
  itemChild: {
    fontWeight: "bolder",
    paddingLeft: 12,
  },
  itemFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& .MuiButtonBase-root": {
      padding: 0,
      margin: "0px 5px",
    },
  },
}));

export { useStyles as default };
