import { makeStyles } from "@material-ui/core";
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
  },
  fontTitle: {
    fontWeight: 'bold',
  },
  containerStepper:{
    width:'100%', 
    display:'flex',
    justifyContent: 'center'
  },
  stepper:{
    width:'70%',
    "& .MuiStepConnector-line":{
      marginLeft: '7px',
      marginRight: '7px'
    }
  },
  rootStepper: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    width: 40,
    height: 40,
    marginTop: '-6px',
    backgroundColor: '#dd9835',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    width: 30,
    height: 30,
    backgroundColor: '#68ce70',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  
  cardItemAddDriver: {
    width: 220,
    height: 250,
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      },
    },
  },
  cardItemAddVehicle: {
    width: 250,
    height: 235,
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      },
    },
  },
  cardSelected: {
    backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardActionArea: {
    height: '100%',
  },
  iconCard: {
    fontSize: 75,
    color: '#767676',
    margin: '0px 20px',
  },
  mainText: {
    color: '#767676',
    fontSize: 15,
    fontWeight: 'bolder',
    textAlign: 'center'
  },
  gridMargin:{
    margin: '0px 24px'
  },
  subTitileColor:{
    color: '#AFAFAF',
    fontWeight: 'bold',
  },
  subTitleGray:{
    width:'100%',
    fontWeight: 600,
    margin: '24px 0px', 
    color:'#AFAFAF',
    borderBottom: '1px solid #eaeaf0',
    paddingLeft:12, 
  },
  colorHr: {
    borderColor: '#E2E2E2'
  },
  loadingColor: {
    color: '#E2E2E2'
  },
  plateContainer:{
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 40px 10px 40px'
  },
  btnStep:{
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& :hover":{
      color: '#fff'
    }
  },
  btnAdd:{
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    "& :hover":{
      color: '#fff'
    }
  },
  hiddenButton: {
    display: 'none'
  },
  fontInfoStep:{
    fontSize: 14,
    color: theme.palette.primary.main,
    marginLeft: 10
  },
  backButton:{
    "& .MuiGrid-item": {
      display: 'flex',
    },
    "& .MuiGrid-root:hover":{
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      cursor: 'pointer',
      width: 'max-content',
    },
    "& .MuiTypography-body1":{
      fontWeight: 'bold',
      marginLeft: 5,
    }
  },
  carouselContainerVehicle:{
    "& .rec":{
      height: "250px",
      width: '85%',
    }
  },
  carouselContainerDriver:{
    "& .rec":{
      height: "260px",
      width: '85%',
    }
  },
  btnAddCategory:{
    width: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: green[400],
    color: '#fff',
    "& .MuiFab-root:hover":{
      color: 'yellow',
      display: 'none',
    }
  },
  btnDeleteCategory:{
    width: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: red[400],
    color: '#fff',
    // "& :hover":{
    //   backgroundColor: '#68ce70',
    // }
  },

}))

export { useStyles as default }