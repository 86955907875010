import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 46,
  },

  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '20px',
    },
  },

  titleDialog: {
    color: '#000',
    fontSize: 22,
    fontWeight: 'bolder',
    textAlign: 'center',
  },

  descriptionDialog: {
    color: '#AFAFAF',
    fontSize: 16,
    fontWeight: 350,
    textAlign: 'center',
  },

  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  iconModal: {
    width: 86,
    height: 86,
    color: '#DD9835',
    fontSize: 70,
  },

  buttonsModal: {
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
    marginBottom: 20,
  },

  discardButtonModal: {
    color: '#767676',
    fontWeight: 'bold',
    fontSize: 16,
    border: '1px solid #AFAFAF',
    borderRadius: 25,
    margin: '0px 10px 0px 10px',
    padding: '5px 30px 5px 30px',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },

  saveButtonModal: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: '#DD9835',
    borderRadius: 25,
    margin: '0px 10px 0px 10px',
    padding: '5px 30px 5px 30px',
    '&:hover': {
      backgroundColor: '#dd9835',
    },
  },
}));

export default useStyles;
