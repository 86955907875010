import {
  Box,
  Chip,
  Fab,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import useStyles from './styles';
import ContentCard from 'components/ContentCard';
import { Search } from '@material-ui/icons';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import PaginationGrid from 'components/datagrid-custom-components/TableFooter/index';
import GobackLink from 'components/GobackLink/index';

// Datos quemados
const rows = [
  {
    id: 1,
    date: '30/07/2021',
    placa: 'SEB195',
    numeroDespacho: '7665435',
    conductor: 'Dominic Toreto',
    planta: 'Centran Ganadera',
    destino: 'La Vaquita',
    ciudad: 'Medellín',
    departureTime: '08:00 a.m.',
    arrival: '11:15 a.m',
    observations: 'No llego',
  },
  {
    id: 2,
    date: '30/07/2021',
    placa: 'SEB195',
    numeroDespacho: '7665435',
    conductor: 'Dominic Toreto',
    planta: 'Centran Ganadera',
    destino: 'La Vaquita',
    ciudad: 'Medellín',
    departureTime: '08:00 a.m.',
    arrival: '11:15 a.m',
    observations: 'Animales muertos',
  },
  {
    id: 3,
    date: '30/07/2021',
    placa: 'SEB195',
    numeroDespacho: '7665435',
    conductor: 'Dominic Toreto',
    planta: 'Centran Ganadera',
    destino: 'La Vaquita',
    ciudad: 'Medellín',
    departureTime: '08:00 a.m.',
    arrival: '11:15 a.m',
    observations: 'Animales faltantes',
  },
  {
    id: 4,
    date: '30/07/2021',
    placa: 'SEB195',
    numeroDespacho: '7665435',
    conductor: 'Dominic Toreto',
    planta: 'Centran Ganadera',
    destino: 'La Vaquita',
    ciudad: 'Medellín',
    departureTime: '08:00 a.m.',
    arrival: '11:15 a.m',
    observations: 'Sin documentos',
  },
  {
    id: 5,
    date: '30/07/2021',
    placa: 'SEB195',
    numeroDespacho: '7665435',
    conductor: 'Dominic Toreto',
    planta: 'Centran Ganadera',
    destino: 'La Vaquita',
    ciudad: 'Medellín',
    departureTime: '08:00 a.m.',
    arrival: '11:15 a.m',
    observations: 'Sin documentos',
  },
  {
    id: 6,
    date: '30/07/2021',
    placa: 'SEB195',
    numeroDespacho: '7665435',
    conductor: 'Dominic Toreto',
    planta: 'Centran Ganadera',
    destino: 'La Vaquita',
    ciudad: 'Medellín',
    departureTime: '08:00 a.m.',
    arrival: '11:15 a.m',
    observations: 'Animales Muertos',
  },
];

const AssignedOffices = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: `/app/network/transports/details/${id}/dispatch`,
      title: 'Historial Despachos',
    },
    {
      link: `/app/network/transports/details/${id}/dispatch`,
      title: 'Historial Despachos',
    },
    { link: `/app/network/transports/details/${id}`, title: 'Detalles' },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'placa',
      headerName: 'Placa',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'numeroDespacho',
      headerName: 'Nro. Certificado Sacrificio',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'conductor',
      headerName: 'Conductor',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
    },
    {
      field: 'destino',
      headerName: 'Origen',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
    },
    {
      field: 'planta',
      headerName: 'Destino',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
    },
    {
      field: 'ciudad',
      headerName: 'Ciudad',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'departureTime',
      headerName: 'Hora de Salida Granja',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'arrival',
      headerName: 'Hora Arribo Plata',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'observations',
      headerName: 'Observaciones',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'ver',
      headerName: ' ',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="ver deatlles" arrow>
            <Fab
              color="primary"
              component={Link}
              to={`/app/network/transports/details/${id}/dispatch/file/${params.getValue(
                params.id,
                'id'
              )}`}
              className={classes.btnDetails}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Tooltip>
        </>
      ),
    },
  ];

  const customPagination = () => {
    return (
      <Box mt={3}>
        <PaginationGrid />
      </Box>
    );
  };

  return (
    <>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard style={{ padding: 50 }}>
        <ContentCard.Content>
          <Box borderBottom={1} mt={1} className={classes.colorHr}>
            <Grid
              container
              alignItems="center"
              className={classes.marginBottomT}
            >
              <Grid item xs={6}>
                <Typography className={classes.fontTitle} variant="h6">
                  Historial de Despachos Asignados
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item xs={5}>
                  <Grid
                    className={classes.display}
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid className={classes.fontResultado}>
                      <Box mr={2}>
                        <LibraryBooksOutlinedIcon />
                      </Box>
                    </Grid>
                    <Grid>
                      <Typography>40 resultados</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    label="Búsqueda"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <div style={{ height: 'auto', width: '100%', padding: '20px' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              pageSize={20}
              className={classes.dataGrid}
              components={{
                Footer: customPagination,
              }}
              hideFooterSelectedRowCount
              rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
            />
          </div>
        </ContentCard.Content>
      </ContentCard>
    </>
  );
};
export default AssignedOffices;
