import * as yup from "yup";
import validations from "./messages";

export const UserRegisterValidations = yup.object().shape({
  IdTipoDocumento: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .test("not-default", validations.REQUIRED_FIELD, (value) => value !== 0)
    .nullable(),
  NumeroDocumento: yup
    .string()
    .required(validations.REQUIRED_FIELD)
    .test(
      "max-length",
      "Máx. 10 caracteres",
      (value = "") => value?.toString()?.length <= 10
    )
    .test("is-number", validations.ONLY_NUMBERS, (value) =>
      Number.isInteger(Number(value))
    )
    .nullable(),
  PrimerNombre: yup
    .string()
    .when("IdTipoDocumento", {
      is: (value: number) => value !== 3,
      then: yup.string().required(validations.REQUIRED_FIELD).nullable(),
    })
    .nullable(),
  PrimerApellido: yup
    .string()
    .when("IdTipoDocumento", {
      is: (value: number) => value !== 3,
      then: yup.string().required(validations.REQUIRED_FIELD).nullable(),
    })
    .nullable(),
  RazonSocial: yup
    .string()
    .when("IdTipoDocumento", {
      is: (value: number) => value === 3,
      then: yup.string().required(validations.REQUIRED_FIELD).nullable(),
    })
    .nullable(),
  DigitoVerificacion: yup
    .number()
    .default(0)
    .typeError(validations.ONLY_NUMBERS)
    .when("IdTipoDocumento", {
      is: (value: number) => value === 3,
      then: yup
        .number()
        .test("max-length", "Máx. 1 caracter", (val) =>
          val ? val?.toString()?.length <= 1 : false
        )
        .test("is-number", validations.ONLY_NUMBERS, (val) =>
          Number.isInteger(Number(val))
        )
        .nullable(),
    })
    .nullable(),
  Telefono: yup
    .number()
    .when("IdTipoDocumento", {
      is: (value: number) => value === 3,
      then: yup
        .number()
        .required(validations.REQUIRED_FIELD)
        .test("is-number", validations.ONLY_NUMBERS, (value) =>
          Number.isInteger(Number(value))
        )
        .typeError(validations.ONLY_NUMBERS)
        .nullable(),
    })
    .test("is-number", validations.ONLY_NUMBERS, (value) =>
      Number.isInteger(Number(value))
    )
    .nullable(),
  Celular: yup
    .string()
    .required(validations.REQUIRED_FIELD)
    .test("is-number", validations.ONLY_NUMBERS, (value) =>
      Number.isInteger(Number(value))
    )
    .test(
      "max-length",
      "Máx. 10 caracteres",
      (value = "") => value?.toString()?.length <= 10
    )
    .nullable(),
  Email: yup
    .string()
    .email(validations.INVALID_EMAIL_ADDRESS)
    .required(validations.REQUIRED_FIELD)
    .nullable(),
  IndicativoCelular: yup.number().required(),
  IndicativoTelefono: yup.number().required(),
  Usuario: yup
    .string()
    .required(validations.REQUIRED_FIELD)
    .test(
      "without-spaces",
      "No se permiten espacios en blanco",
      (value: any) => {
        const noValido = /\s/;
        return !noValido.test(value);
      }
    ),
  Contraseña: yup
    .string()
    .required(validations.REQUIRED_FIELD)
    .test(
      "min-length",
      "Minímo 8 caracteres",
      (value: any) => value?.length >= 8
    )
    .test(
      "pass-uppercase",
      "Debe contener almenos una mayúscula",
      (value: any) => {
        let nMay = 0;
        const t1 = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
        for (let index = 0; index < value?.length; index++) {
          if (t1.indexOf(value.charAt(index)) !== -1) {
            nMay++;
          }
        }
        if (nMay > 0) {
          return true;
        } else {
          return false;
        }
      }
    )
    .test(
      "pass-lowercase",
      "Debe contener almenos una minúscula",
      (value: any) => {
        let nMin = 0;
        const t2 = "abcdefghijklmnñopqrstuvwxyz";
        for (let index = 0; index < value?.length; index++) {
          if (t2.indexOf(value.charAt(index)) !== -1) {
            nMin++;
          }
        }
        if (nMin > 0) {
          return true;
        } else {
          return false;
        }
      }
    )
    .test("pass-number", "Debe contener al menos un número", (value: any) => {
      let nNum = 0;
      const t3 = "0123456789";
      for (let index = 0; index < value?.length; index++) {
        if (t3.indexOf(value.charAt(index)) !== -1) {
          nNum++;
        }
      }
      if (nNum > 0) {
        return true;
      } else {
        return false;
      }
    })
    .matches(/[a-zA-Z0-9]/, {
      message: "No se permiten caracteres especiales ni espacios",
    }),
  ConfirmContraseña: yup
    .string()
    .oneOf([yup.ref("Contraseña"), null], "La contraseña no coincide")
    .required(validations.REQUIRED_FIELD),
  AceptaPoliticaConfidencialidad: yup
    .bool()
    .oneOf([true], validations.PRIVACY_POLITICS),
  AceptaHabeasData: yup.bool().oneOf([true], validations.HABEAS_DATA),
  AceptaTerminosUso: yup.bool().oneOf([true], validations.TERMS_CONDITIONS),
  TipoUsuario: yup
    .array()
    .required(validations.REQUIRED_FIELD)
    .test(
      "min-length",
      "Debes seleccionar al menos un tipo de usuario",
      (value: any) => value.length !== 0
    ),
});
