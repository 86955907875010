import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Divider,
  Tooltip,
  Box,
  Fab,
} from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import domtoimage from 'dom-to-image';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import GobackLink from 'components/GobackLink';
import DialogPDFViewer from 'components/DialogPDFViewer/index';

import {
  MyDocFilterBalanceTicket,
  ReportFilter,
  GraphRangeTicket,
  GraphRangeTicketTwo,
} from './../../Reports/ReportFilter';

import useStyles from './styles';

const Search = () => {
  const classes = useStyles();
  const url = new URLSearchParams(window.location.search);
  const [type]: any = React.useState(url.get('type'));
  const initialDate: any = React.useState(url.get('initialdate'));
  const finalDate: any = React.useState(url.get('finaldate'));
  const [dateReport, setDateReport] = useState();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [openFilterTicket, setOpenFilterTicket] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');
  const [imageGraphTwo, setImageGraphTwo] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenQrTicketFilter');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraph = () => {
    const graph: any = document.getElementById('graprangeticket');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraphTwo = () => {
    const graph: any = document.getElementById('graprangeticketwo');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraphTwo(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeTicket = () => {
    setOpenFilterTicket(true);
  };

  useEffect(() => {
    generateScreenshot();
    screenshotGraph();
    screenshotGraphTwo();
  }, [openFilterTicket]);

  const columns: GridColDef[] = [
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'productor',
      headerName: 'Productor',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'animals',
      headerName: 'Animales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'cam',
      headerName: 'Caídos / Agitados / Muertos',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'weight',
      headerName: 'Peso Total (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'weightprom',
      headerName: 'Peso Prom. (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: '',
      headerName: '',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/bascule/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 2,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 3,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 4,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 5,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 6,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 7,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
  ];
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/post-mortem',
      title: 'Post Mortem',
    },
  ];

  const getDateFilter = () => {
    switch (type) {
      case 'lote':
        return url.get('code');
      case 'today':
        return dayjs().format('DD/MM/YYYY');
      case 'week':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      case 'range':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      default:
        return 'El tipo de filtro solicitado no existe';
    }
  };

  const getInfoFilter = () => {
    switch (type) {
      case 'lote':
        return (
          <>
            <Typography className={classes.filterType}>Lote:</Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{url.get('code')}</span>
            </Typography>
          </>
        );
      case 'today':
        return (
          <>
            <Typography className={classes.filterValue}>
              <span className="param">
                {dayjs().format('dddd, D MMMM YYYY')} (Hoy)
              </span>
            </Typography>
          </>
        );
      case 'week':
        return (
          <>
            <Typography className={classes.filterValue}>
              <span className="param">{'Semana' + ' ' + dayjs().week()}</span>{' '}
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      case 'range':
        return (
          <>
            <Typography className={classes.filterValue}>
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      default:
        return (
          <Typography className={classes.filterType}>
            El tipo de filtro solicitado no existe
          </Typography>
        );
    }
  };

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportFilter />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphRangeTicket />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphRangeTicketTwo />
      </div>

      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard className={classes.contentCard}>
        <div className={classes.root}>
          <div className={classes.flex}>
            <Box mr={3}>
              <Typography className={classes.title}>Búsqueda:</Typography>
            </Box>
            {!url.get('code') && (
              <Button
                variant="contained"
                color="primary"
                onClick={openModalRangeTicket}
              >
                Generar Reporte
              </Button>
            )}
          </div>
          <div className={classes.flexContainer}>
            <div className={classes.flex}>
              <Typography className={classes.labelOrange}>
                {getInfoFilter()}
              </Typography>
            </div>
            <div className={clsx(classes.result, classes.flex)}>
              <LibraryBooksOutlinedIcon style={{ marginRight: 20 }} />
              <Typography>{'200 resultados'}</Typography>
            </div>
          </div>

          <Divider />

          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            rowsPerPageOptions={[5, 10, 15, 20]}
            page={page}
            onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
            onPageChange={(e: any) => setPage(e.page)}
            className={classes.dataGrid}
            components={{
              Footer: TableFooter,
            }}
          />
        </div>
      </ContentCard>
      <DialogPDFViewer
        open={openFilterTicket}
        onClose={() => setOpenFilterTicket(false)}
        done={Boolean(imageQr)}
      >
        <MyDocFilterBalanceTicket
          qr={imageQr}
          graph={imageGraph}
          graphTwo={imageGraphTwo}
          range={getDateFilter()}
        />
      </DialogPDFViewer>
    </div>
  );
};

export default Search;
