import { Box } from '@material-ui/core';
import TablePagination from '../TablePagination';

export default (props: any) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <TablePagination {...props} />
    </Box>
  );
};
