import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  bottom: {
    color: theme.palette.grey[200]
  },
  top: {
    color: theme.palette.primary.main,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0
  }
}))