import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: '8px 0',
  },
  containerReport:{
  },
  titleContainer:{
    background:'#F2F2F2', 
    color: '#767676',
    padding: 8, 
    display:'flex', 
    justifyContent:'center', 
    alignItems: 'center',
    position: 'relative'
  },
  title:{
    fontWeight: 'bold', 
    color: '#767676'
  }, 
  agentsContainer:{
    display: 'flex',
    justifyContent: 'space-between'
  }, 
  inspectionContainer:{
    display: 'flex',
    flexDirection: 'column'
  }, 
  dataGrid:{
    border:'none', 
    display:'flex',
    margin: '20px 0',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator":{
      display:'none'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14
    }
  },
  options:{
    color:'#767676', 
    margin: '0px 12px'
  }
}))

export { useStyles as default }