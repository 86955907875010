import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import ItemOutlinedInfo from '../../../../components/ItemOutlinedInfo/index';
import ItemTotalInfo from '../../../../components/ItemTotalInfo/index';
import Chart from 'components/Chart';
import DialogPDFViewer from 'components/DialogPDFViewer/index';
import domtoimage from 'dom-to-image';

import {
  MyDocInfotraking,
  ReportLot,
  GraphInfotraking,
} from './../../Reports/ReportLot';

type DialogGeneralProps = {
  open: boolean;
  handleClose: () => void;
};

const datosPorteria = [
  {
    title: 'Nro. Remisión',
    data: '4321452-01',
  },
  {
    title: 'Nro. Guía de Movilización',
    data: '020-323431451',
  },
  {
    title: 'Nro. Lote de Ceba',
    data: '6432',
  },
  {
    title: 'Certificado de Inmunocastración',
    data: '643211448',
  },
  {
    title: 'Conductor',
    data: 'Nombre del conductor',
  },
  {
    title: 'Placa Vehículo',
    data: 'JRD654',
  },
];

const datosPorteriaFechas = [
  {
    title: 'Fecha Salida de Granja',
    data: '22/02/2020 02:00:00',
  },
  {
    title: 'Fecha Llegada Planta',
    data: '22/02/2020 02:00:00',
  },
  {
    title: 'Fecha Ingreso Planta',
    data: '22/02/2020 02:00:00',
  },
  {
    title: 'Fecha Salida Planta',
    data: '22/02/2020 02:00:00',
  },
];

const datosCorrales = [
  {
    title: 'Fecha Inicio Desembarque',
    data: '22/02/2020 02:00:00',
  },
  {
    title: 'Fecha Final Desembarque',
    data: '22/02/2020 02:00:00',
  },
  {
    title: 'Fecha Tiquete',
    data: '22/02/2020 02:00:00',
  },
  {
    title: 'Nro. Tiquete de Báscula',
    data: '4568654',
  },
];

function createDataSacrificio(fecha: string, cantidad: number) {
  return {
    fecha,
    cantidad,
  };
}

// Datos quemados
const rowsSacrificio = [
  createDataSacrificio('22/02/20202', 24),
  createDataSacrificio('22/02/20202', 24),
];

const datosSacrificio = [
  {
    title: 'Fecha Inicio Post-Mortem',
    data: '22/02/2020 02:00:00',
  },
  {
    title: 'Fecha Final Post-Mortem',
    data: '22/02/2020 02:00:00',
  },
];

function createDataCavas(cavas: string, riel: number, cantidad: number) {
  return {
    cavas,
    riel,
    cantidad,
  };
}

// Datos quemados
const rowsCavas = [
  createDataCavas('C11', 1, 27),
  createDataCavas('C12', 2, 27),
];

const datosDespacho = [
  {
    title: 'Fecha Primer Despacho',
    data: '22/02/2020 02:00:00',
  },
  {
    title: 'Fecha Último Despacho',
    data: '22/02/2020 02:00:00',
  },
];

function createDataDespacho(
  fecha: string,
  certificado: string,
  canales: number,
  placa: string,
  conductor: string,
  destino: string
) {
  return {
    fecha,
    certificado,
    canales,
    placa,
    conductor,
    destino,
  };
}

// Datos quemados
const rowsDespacho = [
  createDataDespacho(
    '22/02/20202',
    '54651355',
    48,
    'BCR521',
    'Nombre del Conductor',
    'Alimentos Carnicos S.A'
  ),
];

const DialogGeneral = ({ open, handleClose }: DialogGeneralProps) => {
  const classes = useStyles();
  const [image, setImage] = useState('a');
  const [imageGraph, setImageGraph] = useState('a');
  const [openReport, setOpenReport] = useState(false);

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenqrinfotranking');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImage(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const generateScreenshotGraph = () => {
    const qrCode: any = document.getElementById('graphinfotraking');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModal = () => {
    setOpenReport(true);
  };

  useEffect(() => {
    generateScreenshot();
    generateScreenshotGraph();
  }, []);

  return (
    <>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphInfotraking />
      </div>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="body"
        className={classes.borderRadiudDialog}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Grid container>
            <Grid item container xs={7} alignItems="center">
              <Avatar className={classes.avatarTitle}>
                <Box component="i" className="icon-check" fontSize="30px" />
              </Avatar>
              <Box ml={3}>
                <Typography variant="h6">
                  Resumen del Historial del Proceso de Beneficio{' '}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={5} container justify="flex-end">
              <Box ml={3}>
                <Grid item container className={classes.divContainerTitle2}>
                  <Box mr={2}>
                    <Typography variant="h5" className={classes.dataTitle}>
                      0123
                    </Typography>
                  </Box>
                  <Typography className={classes.fontTitleInfo}>
                    Lote InfoPorcinos
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid container className={classes.titleContainer}>
              <Typography className={classes.fontTitleContainer}>
                Agentes Relacionados
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.gridWeightContent}>
            <Grid item container xs={4}>
              <ItemOutlinedInfo
                icon="icon-productor"
                title="Productor"
                info="Nombre del Productor"
              />
            </Grid>
            <Grid item container xs={4}>
              <ItemOutlinedInfo
                icon="icon-comercializador"
                title="Comercializador"
                info="Nombre del Comercializador"
              />
            </Grid>
            <Grid item container xs={4}>
              <ItemOutlinedInfo
                icon="icon-planta"
                title="Planta de Sacrificio"
                info="Nombre de la planta"
              />
            </Grid>
          </Grid>

          <Grid container className={classes.subtitleContainer}>
            <Grid item container xs={6} alignItems="center">
              <Box ml={2}>
                <Avatar className={classes.avatarTitle}>
                  <Box
                    component="i"
                    className="icon-truck-pig"
                    fontSize="30px"
                  />
                </Avatar>
              </Box>
              <Box ml={3}>
                <Typography variant="h6">Portería</Typography>
              </Box>
            </Grid>
            <Grid item container xs={6} alignItems="center" justify="flex-end">
              <Box mr={2}>
                <Typography variant="h5" className={classes.dataTitle}>
                  02:31:40
                </Typography>
              </Box>
              <Box mr={4}>
                <Typography className={classes.fontTitleInfo}>
                  Duración Proceso
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box mb={3}>
            <Grid container className={classes.gridWeightContent}>
              <Grid item container xs={4}>
                <ItemOutlinedInfo
                  icon="icon-granja"
                  title="Granja Origen"
                  info="Nombre de la Granja"
                />
              </Grid>
              <Grid item container xs={4}>
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={54}
                  titleInfo="Total Animales Remisionados"
                  avatarTotalColor="#DD9835"
                  classNameLabel={classes.itemInfoLabel}
                  titleInfoSize={120}
                />
              </Grid>
              <Grid item container xs={4}>
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={54}
                  titleInfo="Total Animales Ingresados"
                  avatarTotalColor="#DD9835"
                  classNameLabel={classes.itemInfoLabel}
                  titleInfoSize={120}
                />
              </Grid>
            </Grid>
          </Box>

          <Grid container>
            <Grid item container xs={6}>
              {datosPorteria.map((item, index) => (
                <Grid item container>
                  <Grid item container xs={7} className={classes.gridTitleList}>
                    <Typography className={classes.fontTitleList}>
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    justify="flex-end"
                    className={classes.gridSubtitleList}
                  >
                    <Typography className={classes.fontSubtitleList}>
                      {item.data}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item container xs={6}>
              {datosPorteriaFechas.map((item, index) => (
                <Grid item container>
                  <Grid item container xs={7} className={classes.gridTitleList}>
                    <Typography className={classes.fontTitleList}>
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    justify="flex-end"
                    className={classes.gridSubtitleList}
                  >
                    <Typography className={classes.fontSubtitleList}>
                      {item.data}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
              <Grid container className={classes.subtitleContainer}>
                <Grid item container xs={6} alignItems="center">
                  <Box ml={3}>
                    <Typography className={classes.fontTitleList}>
                      Responsable Planta
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="center"
                  justify="flex-end"
                >
                  <Box mr={3}>
                    <Typography className={classes.fontSubtitleList}>
                      Nombre del Responsable
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.subtitleContainer}>
            <Grid item container xs={6} alignItems="center">
              <Box ml={2}>
                <Avatar className={classes.avatarTitle}>
                  <Box component="i" className="icon-corral" fontSize="30px" />
                </Avatar>
              </Box>
              <Box ml={3}>
                <Typography variant="h6">Corrales</Typography>
              </Box>
            </Grid>
            <Grid item container xs={6} alignItems="center" justify="flex-end">
              <Box mr={2}>
                <Typography variant="h5" className={classes.dataTitle}>
                  00:28:25
                </Typography>
              </Box>
              <Box mr={4}>
                <Typography className={classes.fontTitleInfo}>
                  Duración Proceso
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item container xs={6}>
              <Grid item container xs={6} justify="center">
                <Grid>
                  <Box mb={2}>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={54}
                      titleInfo="Total Animales Recibidos"
                      avatarTotalColor="#DD9835"
                      classNameLabel={classes.itemInfoLabel}
                      titleInfoSize={125}
                    />
                  </Box>
                </Grid>
                <Grid>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={54}
                    titleInfo="Total Animales Pesados"
                    avatarTotalColor="#E29662"
                    classNameLabel={classes.itemInfoLabel}
                    titleInfoSize={125}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={6} justify="center">
                <Grid>
                  <Box mb={2}>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={0}
                      titleInfo="Total Animales Muertos"
                      avatarTotalColor="#AFAFAF"
                      classNameLabel={classes.itemInfoLabel}
                      titleInfoSize={125}
                    />
                  </Box>
                </Grid>
                <Grid>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={0}
                    titleInfo="Total Animales en Observación"
                    avatarTotalColor="#767676"
                    classNameLabel={classes.itemInfoLabel}
                    titleInfoSize={125}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              {datosCorrales.map((item, index) => (
                <Grid item container>
                  <Grid item container xs={7} className={classes.gridTitleList}>
                    <Typography className={classes.fontTitleList}>
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    justify="flex-end"
                    className={classes.gridSubtitleList}
                  >
                    <Typography className={classes.fontSubtitleList}>
                      {item.data}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid container className={classes.subtitleContainer}>
            <Grid item container xs={3} justify="center">
              <Typography variant="h5" className={classes.fontResume}>
                Resumen Pesaje
              </Typography>
            </Grid>
            <Grid item container xs={9}>
              <Grid item container xs={6}>
                <Grid item xs={8}>
                  <Typography className={classes.fontTitleList}>
                    Peso Promedio Lote (Kg)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.fontSubtitleListResume}>
                    140,00
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={8}>
                  <Typography className={classes.fontTitleList}>
                    Total Peso Lote (Kg)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.fontSubtitleListResume}>
                    6720,00
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item container xs={6}>
              <Grid container className={classes.subtitleContainer}>
                <Grid item container xs={6} alignItems="center">
                  <Box ml={3}>
                    <Typography className={classes.fontTitleList}>
                      Responsable Ante-Mortem
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="center"
                  justify="flex-end"
                >
                  <Box mr={3}>
                    <Typography className={classes.fontSubtitleList}>
                      Nombre del Responsable
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <Grid container className={classes.subtitleContainer}>
                <Grid item container xs={6} alignItems="center">
                  <Box ml={3}>
                    <Typography className={classes.fontTitleList}>
                      Responsable Corrales
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="center"
                  justify="flex-end"
                >
                  <Box mr={3}>
                    <Typography className={classes.fontSubtitleList}>
                      Nombre del Responsable
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.titleContainer}>
            <Typography className={classes.fontTitleContainer}>
              Observaciones Ante-Mortem (Antes del Pesaje de Animales en Pie)
            </Typography>
          </Grid>

          <Grid container>
            <Grid item container xs={3} justify="center">
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={0}
                titleInfo="Nro. Caídos Transporte"
                avatarTotalColor="#AFAFAF"
                classNameLabel={classes.itemInfoLabel}
                titleInfoSize={120}
              />
            </Grid>

            <Grid item container xs={3} justify="center">
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={0}
                titleInfo="Nro. Agitados Transporte"
                avatarTotalColor="#AFAFAF"
                classNameLabel={classes.itemInfoLabel}
                titleInfoSize={120}
              />
            </Grid>

            <Grid item container xs={3} justify="center">
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={0}
                titleInfo="Nro. Muertos Transporte"
                avatarTotalColor="#AFAFAF"
                classNameLabel={classes.itemInfoLabel}
                titleInfoSize={120}
              />
            </Grid>

            <Grid item container xs={3} justify="center">
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={0}
                titleInfo="Nro. Muertos Desembarque"
                avatarTotalColor="#AFAFAF"
                classNameLabel={classes.itemInfoLabel}
                titleInfoSize={120}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.titleContainer}>
            <Typography className={classes.fontTitleContainer}>
              Observaciones Ante-Mortem (Después del Pesaje de Animales en Pie)
            </Typography>
          </Grid>

          <Grid container>
            <Grid item container xs={3} justify="center">
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={0}
                titleInfo="Nro. Caídos Corrales"
                avatarTotalColor="#AFAFAF"
                classNameLabel={classes.itemInfoLabel}
                titleInfoSize={120}
              />
            </Grid>

            <Grid item container xs={3} justify="center">
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={0}
                titleInfo="Nro. Agitados Corrales"
                avatarTotalColor="#AFAFAF"
                classNameLabel={classes.itemInfoLabel}
                titleInfoSize={120}
              />
            </Grid>

            <Grid item container xs={3} justify="center">
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={0}
                titleInfo="Nro. Muertos Corrales de Reposo"
                avatarTotalColor="#AFAFAF"
                classNameLabel={classes.itemInfoLabel}
                titleInfoSize={120}
              />
            </Grid>

            <Grid item container xs={3} justify="center">
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={0}
                titleInfo="Nro. Muertos Corrales de Observación"
                avatarTotalColor="#AFAFAF"
                classNameLabel={classes.itemInfoLabel}
                titleInfoSize={120}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.subtitleContainer}>
            <Grid item container xs={6} alignItems="center">
              <Box ml={2}>
                <Avatar className={classes.avatarTitle}>
                  <Box
                    component="i"
                    className="icon-canal-sync"
                    fontSize="30px"
                  />
                </Avatar>
              </Box>
              <Box ml={3}>
                <Typography variant="h6">Sacrificio</Typography>
              </Box>
            </Grid>
            <Grid item container xs={6} alignItems="center" justify="flex-end">
              <Box mr={2}>
                <Typography variant="h5" className={classes.dataTitle}>
                  00:44:25
                </Typography>
              </Box>
              <Box mr={4}>
                <Typography className={classes.fontTitleInfo}>
                  Duración Proceso
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item container xs={6}>
              <Grid container className={classes.titleContainerTop}>
                <Typography className={classes.fontTitleContainer}>
                  Envíos a Sacrificio
                </Typography>
              </Grid>
              <TableContainer className={classes.tableCellHeader}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCellHead}
                      >
                        Fecha
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellHead}
                      >
                        Cant.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsSacrificio.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          className={classes.tableCellData}
                        >
                          {row.fecha}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellData}
                        >
                          {row.cantidad}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item container xs={6}>
              {datosSacrificio.map((item, index) => (
                <Grid item container>
                  <Grid item container xs={7} className={classes.gridTitleList}>
                    <Typography className={classes.fontTitleList}>
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    justify="flex-end"
                    className={classes.gridSubtitleList}
                  >
                    <Typography className={classes.fontSubtitleList}>
                      {item.data}
                    </Typography>
                  </Grid>
                </Grid>
              ))}

              <Grid container className={classes.subtitleGridContainer}>
                <Grid item container xs={6} alignItems="center">
                  <Box ml={3}>
                    <Typography className={classes.fontTitleList}>
                      Responsable Medición
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="center"
                  justify="flex-end"
                >
                  <Box mr={3}>
                    <Typography className={classes.fontSubtitleList}>
                      Nombre del Responsable
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item container xs={6}>
              <Grid container className={classes.titleContainerTop}>
                <Typography className={classes.fontTitleContainer}>
                  Decomisos Línea de Beneficio
                </Typography>
              </Grid>
              <Grid item container justify="center">
                <Grid>
                  <Box mb={2}>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={0}
                      titleInfo="Canales Completas Decomisadas"
                      avatarTotalColor="#AFAFAF"
                      classNameLabel={classes.itemInfoLabel}
                      titleInfoSize={280}
                    />
                  </Box>
                </Grid>
                <Grid>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={76}
                    titleInfo="Total Partes Decomisadas"
                    avatarTotalColor="#767676"
                    classNameLabel={classes.itemInfoLabel}
                    titleInfoSize={280}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <Grid container className={classes.titleContainerTop}>
                <Typography className={classes.fontTitleContainer}>
                  Decomisos Sala Beneficio de Emergencia
                </Typography>
              </Grid>
              <Grid item container justify="center">
                <Grid>
                  <Box mb={2}>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={0}
                      titleInfo="Canales Completas Decomisadas"
                      avatarTotalColor="#AFAFAF"
                      classNameLabel={classes.itemInfoLabel}
                      titleInfoSize={280}
                    />
                  </Box>
                </Grid>
                <Grid>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={5}
                    titleInfo="Total Partes Decomisadas"
                    avatarTotalColor="#767676"
                    classNameLabel={classes.itemInfoLabel}
                    titleInfoSize={280}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.subtitleContainer}>
            <Grid item container xs={3} justify="center">
              <Typography variant="h5" className={classes.fontResume}>
                Resumen Pesaje
              </Typography>
            </Grid>
            <Grid item container xs={9}>
              <Grid item container xs={4}>
                <Grid item xs={8}>
                  <Typography className={classes.fontTitleList}>
                    PCC (Kg)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.fontSubtitleListResume}>
                    5.256,00
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={4}>
                <Grid item xs={8}>
                  <Typography className={classes.fontTitleList}>
                    Prom. PCC (Kg)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.fontSubtitleListResume}>
                    126,00
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={4}>
                <Grid item xs={8}>
                  <Typography className={classes.fontTitleList}>
                    Carne Magra(Kg)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.fontSubtitleListResume}>
                    5.256,00
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item container xs={4} justify="center">
              <Grid container className={classes.titleContainerTop}>
                <Typography className={classes.fontTitleContainer}>
                  Parámetros de Calidad
                </Typography>
              </Grid>

              <Grid
                item
                container
                justify="center"
                alignItems="flex-start"
                style={{ minHeight: '220px' }}
              >
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={'46,04%'}
                  titleInfo="MC (%)"
                  avatarTotalColor="#AF4337"
                  classNameLabel={classes.itemInfoLabel}
                  avatarTextSize={17}
                  avatarTotalSize={35}
                  extended
                />

                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={'29,08 m.m.'}
                  titleInfo="GD (m.m.)"
                  avatarTotalColor="#CB6743"
                  classNameLabel={classes.itemInfoLabel}
                  avatarTextSize={17}
                  avatarTotalSize={35}
                  extended
                />

                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={'70,06 m.m.'}
                  titleInfo="LM (m.m.)"
                  avatarTotalColor="#DC814D"
                  classNameLabel={classes.itemInfoLabel}
                  avatarTextSize={17}
                  avatarTotalSize={35}
                  extended
                />

                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={'85,66%'}
                  titleInfo="RCCL (%)"
                  avatarTotalColor="#F09C56"
                  classNameLabel={classes.itemInfoLabel}
                  avatarTextSize={17}
                  avatarTotalSize={35}
                  extended
                />
              </Grid>
            </Grid>
            <Grid item container xs={8}>
              <Grid container className={classes.titleContainerTop}>
                <Typography className={classes.fontTitleContainer}>
                  Resumen S.E.U.R.O.P.
                </Typography>
              </Grid>
              <Grid item container className={classes.gridContainerGrapihc}>
                <Chart
                  type="doughnut"
                  withoutTotalInfo
                  classNameLabel={classes.labelGraphic}
                  data={[
                    {
                      label: 'S - Superior',
                      data: 20,
                      bg: '#FFC676',
                    },
                    {
                      label: 'E - Excelente',
                      data: 13,
                      bg: '#F1AE6C',
                    },
                    {
                      label: 'U - Muy Buena',
                      data: 6,
                      bg: '#E29662',
                    },
                    {
                      label: 'R - Buena',
                      data: 12,
                      bg: '#D47D57',
                    },
                    {
                      label: 'O - Menos Buena',
                      data: 3,
                      bg: '#BE5948',
                    },
                    {
                      label: 'P - Mediocre',
                      data: 3,
                      bg: '#B0413E',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.subtitleContainer}>
            <Grid item container xs={6} alignItems="center">
              <Box ml={2}>
                <Avatar className={classes.avatarTitle}>
                  <Box component="i" className="icon-cold" fontSize="30px" />
                </Avatar>
              </Box>
              <Box ml={3}>
                <Typography variant="h6">Cavas</Typography>
              </Box>
            </Grid>
            <Grid item container xs={6} alignItems="center" justify="flex-end">
              <Box mr={2}>
                <Typography variant="h5" className={classes.dataTitle}>
                  00:44:25
                </Typography>
              </Box>
              <Box mr={4}>
                <Typography className={classes.fontTitleInfo}>
                  Duración Proceso
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item container xs={6}>
              <Grid item container justify="center">
                <Box mb={2}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={54}
                    titleInfo="Total Canales en Cavas"
                    avatarTotalColor="#DD9835"
                    classNameLabel={classes.itemInfoLabel}
                    avatarTextSize={17}
                    avatarTotalSize={35}
                    titleInfoSize={200}
                    extended
                  />
                </Box>
              </Grid>
              <TableContainer className={classes.tableCellHeader}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCellHead}
                      >
                        Nro. Cava
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellHead}
                      >
                        Nro. Riel
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellHead}
                      >
                        Cant.
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsCavas.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          className={classes.tableCellData}
                        >
                          {row.cavas}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellData}
                        >
                          {row.riel}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellData}
                        >
                          {row.cantidad}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item container xs={6}>
              {datosSacrificio.map((item, index) => (
                <Grid item container>
                  <Grid item container xs={7} className={classes.gridTitleList}>
                    <Typography className={classes.fontTitleList}>
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    justify="flex-end"
                    className={classes.gridSubtitleList}
                  >
                    <Typography className={classes.fontSubtitleList}>
                      {item.data}
                    </Typography>
                  </Grid>
                </Grid>
              ))}

              <Grid container className={classes.subtitleGridContainer}>
                <Grid item container xs={6} alignItems="center">
                  <Box ml={3}>
                    <Typography className={classes.fontTitleList}>
                      Responsable Medición
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="center"
                  justify="flex-end"
                >
                  <Box mr={3}>
                    <Typography className={classes.fontSubtitleList}>
                      Nombre del Responsable
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.subtitleContainer}>
            <Grid item container xs={6} alignItems="center">
              <Box ml={2}>
                <Avatar className={classes.avatarTitle}>
                  <Box
                    component="i"
                    className="icon-truck-cold"
                    fontSize="30px"
                  />
                </Avatar>
              </Box>
              <Box ml={3}>
                <Typography variant="h6">Despacho</Typography>
              </Box>
            </Grid>
            <Grid item container xs={6} alignItems="center" justify="flex-end">
              <Box mr={2}>
                <Typography variant="h5" className={classes.dataTitle}>
                  00:44:25
                </Typography>
              </Box>
              <Box mr={4}>
                <Typography className={classes.fontTitleInfo}>
                  Duración Proceso
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: '10px' }}>
            <Grid item container xs={6}>
              <Grid item container xs={6} justify="center">
                <ItemTotalInfo
                  position="bottom"
                  avatarTotalInfo={1}
                  titleInfo="Nro. Despacho"
                  avatarTotalColor="#DD9835"
                  classNameLabel={classes.itemInfoLabel}
                  avatarTextSize={17}
                  avatarTotalSize={35}
                  titleInfoSize={120}
                  extended
                />
              </Grid>
              <Grid item container xs={6} justify="center">
                <ItemTotalInfo
                  position="bottom"
                  avatarTotalInfo={54}
                  titleInfo="Canales Despachadas"
                  avatarTotalColor="#DD9835"
                  classNameLabel={classes.itemInfoLabel}
                  avatarTextSize={17}
                  avatarTotalSize={35}
                  titleInfoSize={175}
                  extended
                />
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              {datosDespacho.map((item, index) => (
                <Grid item container>
                  <Grid item container xs={7} className={classes.gridTitleList}>
                    <Typography className={classes.fontTitleList}>
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    justify="flex-end"
                    className={classes.gridSubtitleList}
                  >
                    <Typography className={classes.fontSubtitleList}>
                      {item.data}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid container>
            <TableContainer className={classes.tableCellHeader}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableCellHead}>
                      Fecha Despacho
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellHead}>
                      Nro. Certificado
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellHead}>
                      Canales
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellHead}>
                      Placa Vahículo
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellHead}>
                      Conductor
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellHead}>
                      Destinos Canales
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsDespacho.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.fecha}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.certificado}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.canales}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.placa}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.conductor}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.destino}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              className={classes.buttonPreview}
              onClick={openModal}
            >
              Previsualizar
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              color="primary"
              className={classes.buttonAccept}
            >
              Aceptar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <DialogPDFViewer
        open={openReport}
        onClose={() => setOpenReport(false)}
        done={Boolean(image)}
      >
        <MyDocInfotraking qr={image} graph={imageGraph} />
      </DialogPDFViewer>
    </>
  );
};

export default DialogGeneral;
