import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Card,
  CardActionArea,
  CardContent,
  Grow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { TYPE_SERVICE } from 'core/graphql/queries/transport';
import MainTitle from 'components/MainTitle/index';

import useStyles from './styles';

type DialogNewRecordProps = {
  open: boolean;
  handleClose: any;
};

const DialogNewRecord = ({ open, handleClose }: DialogNewRecordProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const [selectTypeService, setSelectTypeService] = useState(0);
  const [selectType, setSelectType] = useState(0);

  const { data, loading, error } = useQuery(TYPE_SERVICE, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  useEffect(() => {
    setSelectTypeService(0);
    setSelectType(0);
  }, [open]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        className={classes.borderRadiudDialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid container justify="flex-end">
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container className={classes.title}>
              <MainTitle>Registrar Transporte</MainTitle>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.gridMargin}
          >
            <Grid item container alignItems="flex-start">
              <Grow in timeout={800}>
                <Box marginBottom={3} marginTop={1}>
                  <MainTitle fontSize="h6">
                    Seleccione el tipo de servicio
                  </MainTitle>
                </Box>
              </Grow>
            </Grid>
            <Grid container justifyContent="center" spacing={3}>
              {data?.getTypeService.map((option: any, ind: number) => (
                <Grid item key={ind}>
                  <Grow
                    in
                    timeout={800}
                    style={{ transitionDelay: `${ind * 100}ms` }}
                  >
                    <Card
                      elevation={2}
                      className={clsx({
                        [classes.cardItem]: true,
                        [classes.cardSelected]: selectTypeService === option.id,
                      })}
                    >
                      <CardActionArea
                        onClick={() => {
                          setSelectTypeService(option.id);
                        }}
                        className={classes.cardActionArea}
                        component={Link}
                        to={
                          option.id === 1
                            ? `/app/network/transports/forms/create-vehicle/${option.id}`
                            : '/app/network/transports/'
                        }
                      >
                        <CardContent className={classes.cardContent}>
                          <Box
                            component="i"
                            className={clsx(
                              option.id === 1
                                ? 'icon-truck'
                                : option.id === 2
                                ? 'icon-truck-time'
                                : 'icon-truck-sync',
                              classes.roleIcon
                            )}
                          />
                          <Typography className={classes.mainText}>
                            {option.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                </Grid>
              ))}
            </Grid>

            {selectTypeService !== 0 && selectTypeService !== 1 && (
              <>
                <Grid item container alignItems="flex-start">
                  <Grow in timeout={800}>
                    <Box marginBottom={3} marginTop={3}>
                      <MainTitle fontSize="h6">
                        Seleccione el tipo de registro a realizar
                      </MainTitle>
                    </Box>
                  </Grow>
                </Grid>

                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item>
                    <Grow in timeout={800}>
                      <Card
                        className={clsx({
                          [classes.cardItem]: true,
                          [classes.cardSelected]: selectType === 1,
                        })}
                        elevation={2}
                      >
                        <CardActionArea
                          className={classes.cardActionArea}
                          component={Link}
                          to={`/app/network/transports/forms/create-company/${selectTypeService}`}
                        >
                          <CardContent className={classes.cardContent}>
                            <Box
                              component="i"
                              className={clsx('icon-planta', classes.roleIcon)}
                            />
                            <Typography className={classes.mainText}>
                              Empresa Transportadora
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grow>
                  </Grid>
                  <Grid item>
                    <Grow in timeout={800} style={{ transitionDelay: '100ms' }}>
                      <Card
                        className={clsx({
                          [classes.cardItem]: true,
                          [classes.cardSelected]: selectType === 2,
                        })}
                        elevation={2}
                      >
                        <CardActionArea
                          component={Link}
                          to={`/app/network/transports/forms/create-vehicle/${selectTypeService}`}
                          className={classes.cardActionArea}
                        >
                          <CardContent className={classes.cardContent}>
                            <Box
                              component="i"
                              className={clsx('icon-truck', classes.roleIcon)}
                            />
                            <Typography className={classes.mainText}>
                              Vehículo
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grow>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DialogNewRecord;
