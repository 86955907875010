import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  avatar_badge: {
    width: 55,
    height: 55,
    backgroundColor: '#AFAFAF',
  },
}));
