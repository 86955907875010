import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container:{
    margin: '20px 0px',
    padding: '20px 40px',
    borderRadius: 20, 
    boxShadow: theme.shadows[3], 
  }, 
  titleContainer:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    marginBottom: 8, 
  }, 
  title: {
    display:'flex', 
    alignItems:'center',
    fontSize: 24,
    "& i":{
      fontSize: 30, 
      marginLeft: 4
    }
  }, 
  table:{
    "& .MuiTableCell-root":{
      border: 'none',
      textTransform: 'capitalize'
    },
  },
  tableTitle:{
    color: '#AFAFAF', 
    fontWeight: 'bold',
  }, 
  gridContainer:{
    marginTop: 24
  }, 
  card:{
    padding: 16 , 
    borderRadius: 10, 
    boxShadow: theme.shadows[1], 
    width:'100%'
  }, 
  subtitle:{
    fontWeight: 'bold'
  }, 
  acceptedContainer: {
    textAlign: 'right',
    paddingRight: 15,
  },
  aceptConditions: {
    fontSize: 14,
    color: '#767676',
  },
}));

export default useStyles;
