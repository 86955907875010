import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Grid,
  Chip,
  Avatar,
  Typography,
  List,
  ListItem,
  FormControlLabel,
  Switch,
  Button,
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import ContentCard from "components/ContentCard";
import GobackLink from "components/GobackLink";
import Breadcrumb, { BreadcrumbProps } from "components/BreadcrumbBuilder";
import EditUserData from "components/PageComponents/Users/EditUserData";

import EditRolAndPermissions from "components/PageComponents/Users/EditRolAndPermissions";
import EditPassword from "components/PageComponents/Users/EditPassword";
import ItemListData from "components/ItemListData";
import InformativeScreen from "components/InformativeScreens";

import avatar from "lib/helpers/dynamic-images/avatars";
import { DETAIL_INTERNALUSER } from "core/graphql/queries/internalUsers";
import { CHANGE_INTERNALUSER_STATE } from "core/graphql/mutations/internalUsers";

import clsx from "clsx";
import dayjs from "dayjs";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import useStyles from "./styles";

const Details = () => {
  const params = useParams<{ id: string }>();
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const path = useLocation();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: `/app/users/details/${params.id}`, title: "Perfil Usuario" },
    { link: "/app/users", title: "Mis usuarios" },
  ];
  const { enqueueSnackbar } = useSnackbar();
  const [openAlert, setOpenAlert] = useState(false);
  const [dataUser, setDataUser] = useState<any>({});
  const [activityUser, setActivityUser] = useState<any>([]);
  const [state, setState] = useState(false);

  const { loading, error, data, refetch } = useQuery(DETAIL_INTERNALUSER, {
    context: {
      headers: { "x-auth-jwt": token },
    },
    variables: {
      idUsuarioInterno: Number(params.id),
      idTipoUsuario: useSelector(
        (state: any) => state.user.selectedUserType.idUserType
      ),
      fechaInicial: `${dayjs().startOf("M").format("YYYY-MM-DD")}`,
      fechaFinal: `${dayjs().format("YYYY-MM-DD")}`,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setDataUser(data?.getDetailInternaluser?.data?.detalleUsuario);
      setActivityUser(
        data?.getDetailInternaluser?.data?.detalleActividadUsuario
      );
      setState(data?.getDetailInternaluser?.data?.detalleUsuario?.estado);
    }
  }, [loading, data]);

  useEffect(() => {
    refetch();
  }, [path]);

  const [changeStateInternalUser, { loading: loadingState }] = useMutation(
    CHANGE_INTERNALUSER_STATE
  );

  const handleUpdateState = () => {
    changeStateInternalUser({
      variables: {
        idUsuarioInterno: dataUser.idUsuario,
        estado: state,
      },
      context: { headers: { "x-auth-jwt": token } },
    })
      .then((data) => {
        refetch();
        if (data?.data?.putStatusInternalUser?.data?.success === 1) {
          setOpenAlert(false);
          enqueueSnackbar(
            `El usuario ha sido ${
              state ? "activado" : "desactivado"
            } exitosamente`,
            {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
            }
          );
        } else {
          enqueueSnackbar(
            `${data?.data?.putStatusInternalUser?.data?.message}`,
            {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
            }
          );
          setOpenAlert(false);
          setState(dataUser?.estado);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        });
        console.error(error);
      });
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleChange = (e: any) => {
    setState(e.target.checked);
    handleOpenAlert();
  };

  return (
    <>
      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        className={classes.dialogContainer}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Typography variant="h5" color="primary" align="center">
            <i className="icon-circle-alert" style={{ fontSize: 70 }} />
          </Typography>
          <Typography
            color="secondary"
            variant="h6"
            align="center"
            style={{ fontSize: 22 }}
          >
            {`¿Está seguro de ${
              state ? "activar" : "desactivar"
            } este usuario?`}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button variant="outlined" onClick={() => setOpenAlert(false)}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateState}
            endIcon={
              loadingState && (
                <CircularProgress size={20} style={{ color: "#FFF" }} />
              )
            }
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      {loading ? (
        <ContentCard className={classes.contentCard}>
          <Box
            minHeight="55vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={100} />
          </Box>
        </ContentCard>
      ) : (
        <Grid container spacing={2}>
          <Grid container item sm={6}>
            <Grid item container xs={12}>
              <ContentCard className={classes.contentCard}>
                <div className={classes.headerContainer}>
                  <div className={classes.userNameContainer}>
                    <Avatar
                      className={classes.avatarUser}
                      src={avatar(dataUser?.usuario ?? "a")}
                    />
                    <div className={classes.containerUserInfo}>
                      <Typography variant="h5" className={classes.boldText}>
                        {`${dataUser?.primerNombre} ${dataUser?.segundoNombre} ${dataUser?.primerApellido} ${dataUser?.segundoApellido}`}
                      </Typography>
                      <Typography variant="subtitle1">
                        @{dataUser?.usuario}
                      </Typography>
                    </div>
                  </div>
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    label={dataUser?.rol ?? "Sin rol asignado"}
                  />
                </div>
                <div className={classes.dataUserContainer}>
                  <ItemListData
                    data={[
                      {
                        title: "Tipo de documento",
                        data: dataUser?.tipoDocumento,
                      },
                      {
                        title: "Número de documento",
                        data: dataUser?.numeroDocumento,
                      },
                      {
                        title: "Cargo",
                        data: dataUser?.cargo,
                      },
                      {
                        title: "Celular",
                        data: dataUser?.celular,
                      },
                      {
                        title: "Correo Electrónico",
                        data: dataUser?.email,
                      },
                    ]}
                  />
                </div>
                <div className={classes.footerActions}>
                  <EditUserData
                    uid={dataUser?.idUsuario}
                    refetchDetails={refetch}
                    data={dataUser}
                  />
                </div>
              </ContentCard>
            </Grid>
            <Grid item container xs={12}>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography variant="h6" className={classes.titleHeader}>
                    Ajustes avanzados
                  </Typography>
                </div>
                <div>
                  <List>
                    <ListItem className={classes.itemList}>
                      <Typography>
                        Estado usuario
                        <Tooltip
                          title="Si el usuario está inactivo, no podrá ingresar al sistema"
                          placement="top"
                        >
                          <i
                            className={clsx("icon-question")}
                            style={{ marginLeft: 4 }}
                          />
                        </Tooltip>
                      </Typography>
                      <FormControlLabel
                        label={state ? "Activo" : "Inactivo"}
                        labelPlacement="start"
                        control={
                          <Switch
                            prefix="Activo"
                            onChange={handleChange}
                            checked={state}
                            className={classes.switch}
                          />
                        }
                      />
                    </ListItem>
                    <ListItem className={classes.itemList}>
                      <Typography>Roles y permisos</Typography>
                      <EditRolAndPermissions
                        uid={dataUser?.idUsuario}
                        IdRole={dataUser?.idRol}
                        refetchDetails={refetch}
                      >
                        {({ handleOpenEditData }: any) => {
                          return (
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={handleOpenEditData}
                              className={classes.boldText}
                            >
                              Cambiar
                            </Button>
                          );
                        }}
                      </EditRolAndPermissions>
                    </ListItem>
                    <ListItem className={classes.itemList}>
                      <Typography>Contraseña</Typography>
                      <EditPassword uid={dataUser?.idUsuario}>
                        {({ handleOpenEditPassword }: any) => {
                          return (
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={handleOpenEditPassword}
                              className={classes.boldText}
                            >
                              Cambiar
                            </Button>
                          );
                        }}
                      </EditPassword>
                    </ListItem>
                  </List>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
          <Grid container item sm={6}>
            <Grid item container xs={12}>
              <ContentCard className={classes.contentCard}>
                <div className={classes.headerTitleContainer}>
                  <Typography variant="h6" className={classes.boldText}>
                    Última actividad del usuario
                  </Typography>
                </div>
                <div>
                  <List className={classes.listHorizontalContainer}>
                    <ListItem className={classes.itemsCenter}>
                      <Typography variant="h5" className={classes.boldText}>
                        {dataUser?.fechaUltimaConexion ?? "Sin Registro"}
                      </Typography>
                      <Typography>Fecha última conexión</Typography>
                      <Typography variant="caption">(DD/MM/AA)</Typography>
                    </ListItem>
                    <ListItem className={classes.itemsCenter}>
                      <Typography variant="h5" className={classes.boldText}>
                        {dataUser?.tiempoActivo ?? "00:00:00"}
                      </Typography>
                      <Typography>Tiempo Activo en web</Typography>
                      <Typography variant="caption">(HH:MM:SS)</Typography>
                    </ListItem>
                    <ListItem className={classes.itemsCenter}>
                      <Typography variant="h5" className={classes.boldText}>
                        {dataUser?.tiempoActivoMovil ?? "00:00:00"}
                      </Typography>
                      <Typography>Tiempo activo en móvil</Typography>
                      <Typography variant="caption">(HH:MM:SS)</Typography>
                    </ListItem>
                  </List>
                </div>
              </ContentCard>
            </Grid>
            <Grid item container xs={12}>
              <ContentCard className={classes.contentCard}>
                <div className={classes.headerTitleContainer}>
                  <Typography variant="h6" className={classes.titleHeader}>
                    Registro de actividad del usuario
                  </Typography>
                  <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    label="Últimos 7 registros del mes Actual"
                  />
                </div>
                <Box
                  minHeight="310px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {activityUser?.length !== 0 ? (
                    <>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Fecha</TableCell>
                            <TableCell align="center">Hora Inicial</TableCell>
                            <TableCell align="center">Hora Final</TableCell>
                            <TableCell align="center">
                              Tiempo total activo
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {activityUser
                            ?.map((activity: any) => (
                              <TableRow
                                className={classes.itemGrey}
                                key={activity.registro}
                              >
                                <TableCell align="center">
                                  {dayjs(activity.fechaInicial).format(
                                    "DD/MM/YYYY"
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {dayjs(activity.fechaInicial).format(
                                    "HH:mm:ss"
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {dayjs(activity.fechaFinal).format(
                                    "HH:mm:ss"
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {activity.tiempoTotal}
                                </TableCell>
                              </TableRow>
                            ))
                            .splice(0, 7)}
                        </TableBody>
                      </Table>
                      <div className={classes.footerActions}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.boldText}
                          component={Link}
                          to={`/app/users/details/activity/${
                            params.id
                          }/thismonth/${dayjs()
                            .startOf("M")
                            .valueOf()}/${dayjs().valueOf()}`}
                        >
                          Ver más
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Box margin="auto">
                      <InformativeScreen
                        onlyError
                        info="No se han encontrado resultados"
                        type="technicalError"
                        subtitle="¡Ups!"
                      />
                    </Box>
                  )}
                </Box>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Details;
