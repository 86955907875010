import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  alignText: {
    textAlign: 'center',
  },
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px',
    }
  },
  loadingDialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '25px',
      alignItems: 'center',
      padding: '30px',
      background: 'transparent',
      boxShadow: 'none',
    }
  },
  chipDefault:{
    boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
    border: `1px solid rgba(0, 0, 0, 0.06)`,
    justifyContent: 'flex-start',
    minWidth: '130px',
    "& .MuiAvatar-circle":{
      backgroundColor: "#bdbdbd",
      color: "#767676"
    },
    "& .MuiChip-label": {
      color: "#AFAFAF"
    },
  },

  chipSelected:{
    boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
    border: `1px solid rgba(0, 0, 0, 0.06)`,
    justifyContent: 'flex-start',
    minWidth: '130px',
    "& .MuiAvatar-colorDefault":{
      backgroundColor: "#dd9835 !important",
      color: "white"
    },
    "& .MuiChip-label": {
      color: "black"
    },
  },

  loadingColor: {
    color: '#767676',
  },

  fontTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#767676',
  } ,
  fontContent: {
    color: '#AFAFAF',
    fontSize: '16px',
  }, 
  spacing:{
    marginLeft: '50px'
  },
  sizeImage: {
    width: '240px',
    height: '176px'
  }  
}))

export { useStyles as default }