import { Document, Image, Page, View, Font, Text } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import ticketLogo from 'static/img/reportsIcons/ticketLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import productorIcon from 'static/img/reportsIcons/productorIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';
import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import TableInfo from 'components/pdf-components/TableInfo/index';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotal from 'components/pdf-components/ItemTotal';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle/index';
import ListData from 'components/pdf-components/ListData';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';

import styles from './styles/lote';
import NumberTotal from 'components/pdf-components/NumberTotal';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const detallesCorrales = [
  {
    tipo: 'Reposo',
    nroCorral: 21,
    animales: 28,
    fecha: '01/10/2019 10:00:00 a.m.',
  },
  {
    tipo: 'Reposo',
    nroCorral: 24,
    animales: 23,
    fecha: '01/10/2019 10:00:00 a.m.',
  },
  {
    tipo: 'Observación',
    nroCorral: 1,
    animales: 3,
    fecha: '01/10/2019 10:00:00 a.m.',
  },
];

const detallePesaje = [
  {
    pesaje: 'Reposo',
    fecha: '01/10/2019',
    hora: '7:01 AM',
    animales: 0,
    sexo: 'N/D',
    inmunocastrado: 'N/D',
    pesoTotal: '0.00',
    pesoPromedio: '0.00',
  },
  {
    pesaje: 'Reposo',
    fecha: '01/10/2019',
    hora: '7:02 AM',
    animales: 24,
    sexo: 'M',
    inmunocastrado: 'SI',
    pesoTotal: '1.332,00',
    pesoPromedio: '110,23',
  },
  {
    pesaje: 'Reposo',
    fecha: '01/10/2019',
    hora: '7:04 AM',
    animales: 24,
    sexo: 'M',
    inmunocastrado: 'SI',
    pesoTotal: '1.332,00',
    pesoPromedio: '110,23',
  },
  {
    pesaje: 'Reposo',
    fecha: '01/10/2019',
    hora: '7:06 AM',
    animales: 24,
    sexo: 'M',
    inmunocastrado: 'SI',
    pesoTotal: '1.332,00',
    pesoPromedio: '110,23',
  },
];

interface MyDocProps {
  qr: string;
}

export const MyDocLotTicket = ({ qr }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={6700}
        reportImage={ticketLogo}
        number="023456789"
        title="Reporte Técnico Tiquete de Báscula Corrales"
        code="TB-001"
      />

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '10px' }}>
        <Subtitle
          title="Agentes Relacionados con el Negocio"
          widthContainer="100%"
        />
      </View>

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={productorIcon}
          title="Productor"
          items={{
            Nombre: 'Nombre productor',
            NIT: '34123412-2',
            Teléfono: '+57 314 6543 543',
            Email: 'info@productor.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '10px' }}>
        <Subtitle
          title="Detalles Origen y Transporte del Lote"
          widthContainer="100%"
        />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '48%' }}>
          <ListData
            widthContainer="100%"
            items={{
              'Lote de Ceba': '62',
              'Remisión Nro.': '6431',
              'Nro. Guía': '34431243',
              'Nombre Granja': 'La Divisa Granja',
              'Hora Salida Granja': '01:12 P.M.',
            }}
          />
        </View>
        <View style={{ width: '48%' }}>
          <ListData
            widthContainer="100%"
            items={{
              'Llegada Planta': '01/10/2021 02:30:00 p.m.',
              'Ingreso Planta': '01/10/2021 02:43 P.M.',
              'Salida Planta': '01/10/2021 03:03 P.M.',
              'Placa Vehículo': 'STZ 900 - MECEO',
              'Nombre Conductor': 'Juan Villamil',
            }}
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Subtitle title="Detalle Animales en Corrales" widthContainer="100%" />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '48.3%' }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: '#ededed',
              height: 45,
            }}
          >
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="45"
                info="Animales en Corral de Reposo"
                position="right"
                textAlign="left"
                backgroundColor="#d47d57"
                widthInfo="60px"
                widthCircle={30}
              />
            </View>
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="03"
                info="Animales en Corral de Observación"
                position="right"
                textAlign="left"
                backgroundColor="#e29662"
                widthInfo="70px"
                widthCircle={30}
              />
            </View>
          </View>
          <View style={{ alignItems: 'center' }}>
            <ListData
              widthContainer="80%"
              items={{
                'Responsable Corrales': 'Juan Pedro Lopez Contreras',
              }}
            />
          </View>
        </View>

        <View
          style={{
            width: '48.3%',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <View style={styles.tableMagro}>
              <View
                style={[
                  styles.tableRow,
                  {
                    marginBottom: '5px',
                    alignItems: 'center',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 60, width: 60 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Tipo Corral
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 52, width: 52 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Nro. Corral
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 51, width: 51 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Animales
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 102, width: 102 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Fecha Ingreso
                  </Text>
                </View>
              </View>
              {detallesCorrales?.map((item: any, index: number) => (
                <View
                  key={index}
                  wrap={false}
                  style={[
                    styles.tableRow,
                    {
                      backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                    },
                  ]}
                >
                  <View style={[styles.tableCol, { maxWidth: 60, width: 60 }]}>
                    <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                      {item.tipo}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 52, width: 52 }]}>
                    <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                      {item.nroCorral}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 51, width: 51 }]}>
                    <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                      {item.animales}
                    </Text>
                  </View>
                  <View
                    style={[styles.tableCol, { maxWidth: 102, width: 102 }]}
                  >
                    <Text style={[styles.tableCellVisceras, { fontSize: 8 }]}>
                      {item.fecha}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Subtitle
          title="Pesaje Animales en Pie Corrales"
          widthContainer="100%"
        />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View
          style={{
            width: '56.3%',
          }}
        >
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View style={{ width: '31.2%', justifyContent: 'center' }}>
              <ItemTotalCircle
                value="96"
                widthCircle={35}
                info="Animales Corral de Reposo"
                widthInfo="50px"
                position="right"
                textAlign="left"
                backgroundColor="#dd9835"
              />
            </View>
            <View style={{ width: '31.2%', justifyContent: 'center' }}>
              <NumberTotal
                value="5.328,00 Kg"
                info="Peso Total Animales en Pie"
                position="bottom"
                widthInfo="80px"
                widthValue="100px"
                justifyValue="center"
                justifyInfo="center"
                colorValue="#373737"
                fontSizeValue={14}
              />
            </View>
            <View style={{ width: '31.2%', justifyContent: 'center' }}>
              <NumberTotal
                value="110,23 Kg"
                info="Promedio Peso Pie del Lote"
                position="bottom"
                widthInfo="80px"
                widthValue="100px"
                justifyValue="center"
                justifyInfo="center"
                colorValue="#373737"
                fontSizeValue={14}
              />
            </View>
          </View>
          <View
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Subtitle
              title="Estado Animales en Pie Ingreso Corrales"
              widthContainer="100%"
            />
          </View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View style={{ width: '31.2%' }}>
              <ItemTotalCircle
                value="6"
                info="Animales Caidos / Agitados / Muertos"
                position="right"
                textAlign="left"
                backgroundColor="#d47d57"
                widthInfo="60px"
                widthCircle={30}
              />
            </View>
            <View style={{ width: '29%' }}>
              <ListData
                widthContainer="100%"
                widthtTitle="20%"
                widthValue="80%"
                items={{
                  '01': 'Animales Agitados',
                  '02': 'Animales Caidos',
                }}
              />
            </View>
            <View style={{ width: '33.4%' }}>
              <ListData
                widthContainer="100%"
                widthtTitle="20%"
                widthValue="80%"
                items={{
                  '01': 'Muertos Transporte',
                  '03': 'Muertos Desembarque',
                }}
              />
            </View>
          </View>
        </View>
        <View
          style={{
            width: '40.3%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              backgroundColor: '#ededed',
              height: 40,
            }}
          >
            <View style={{ width: '31.2%', justifyContent: 'center' }}>
              <NumberTotal
                value="CE"
                info="Tipo de Cerdo"
                position="bottom"
                widthInfo="80px"
                widthValue="100px"
                justifyValue="center"
                justifyInfo="center"
                colorValue="#d47d57"
                fontSizeValue={15}
              />
            </View>
            <View style={{ width: '31.2%', justifyContent: 'center' }}>
              <NumberTotal
                value="M"
                info="Sexo Animales"
                position="bottom"
                widthInfo="80px"
                widthValue="100px"
                justifyValue="center"
                justifyInfo="center"
                colorValue="#d47d57"
                fontSizeValue={15}
              />
            </View>
            <View style={{ width: '31.2%', justifyContent: 'center' }}>
              <NumberTotal
                value="SI"
                info="Inmunocastrado"
                position="bottom"
                widthInfo="80px"
                widthValue="100px"
                justifyValue="center"
                justifyInfo="center"
                colorValue="#d47d57"
                fontSizeValue={15}
              />
            </View>
          </View>
          <View
            style={{
              marginTop: '7px',
              marginBottom: '10px',
            }}
          >
            <Subtitle title="Resumen Proceso Pesaje" widthContainer="100%" />
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: '5px',
              paddingLeft: '5px',
            }}
          >
            <View style={{ width: '48.3%', justifyContent: 'center' }}>
              <ItemTotalCircle
                value="4"
                widthCircle={30}
                info="Total Pesajes Realizados"
                widthInfo="50px"
                position="right"
                textAlign="left"
                backgroundColor="#dd9835"
              />
            </View>
            <View style={{ width: '48.3%', justifyContent: 'center' }}>
              <ItemTotal
                value="00:10:04"
                info="Tiempo Total Desembarque"
                widthValue={50}
                fontSizeValue={12}
                position="right"
                backgroundColor="#d47d57"
              />
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          marginTop: '10px',
        }}
      >
        <Subtitle
          title="Detalle Pesaje de Animales en Pie"
          widthContainer="100%"
        />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '45%' }}>
          <ListData
            widthContainer="100%"
            items={{
              'Primer Pesaje': '01/10/2019 02:30:00 p.m.',
              'Ulltimo Pesaje': '01/10/2019 02:30:00 p.m.',
            }}
          />
        </View>
        <View style={{ width: '45%' }}>
          <ListData
            widthContainer="100%"
            items={{
              'Inicio Descargue': '01/10/2019 02:00:00 p.m.',
              'Final Descargue': '01/10/2019 02:30:00 p.m.',
            }}
          />
        </View>
      </View>

      <View style={{ width: '100%', marginTop: '5px' }}>
        <View style={[styles.tableMagro, { marginBottom: 5 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '5px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 60.3, width: 60.3 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Pesaje</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Fecha</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Hora</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Animales Pesados
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Sexo</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Inmunocastrado
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Peso Total (Kg)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 78.3, width: 78.3 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Peso Promedio (Kg)
              </Text>
            </View>
          </View>
          {detallePesaje?.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 60.3, width: 60.3 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {index}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.fecha}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.hora}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.animales}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.sexo}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.inmunocastrado}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 69.3, width: 69.3 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.pesoTotal}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 78.3, width: 78.3 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.pesoPromedio}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={{ flexDirection: 'row', width: '100%', marginBottom: '10px' }}
        >
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 60.3,
                width: 60.3,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 138.6, width: 138.6, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 69.3, width: 69.3, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              72
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 138.6, width: 138.6, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 69.3, width: 69.3, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              5.328,00
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 78.3, width: 78.3, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
        </View>
      </View>

      <View style={styles.conventions} wrap={false}>
        <View style={styles.backgroundguide}>
          <View style={styles.textconventionsLong}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * CE
              </Text>
            </View>
            <View style={styles.textLong}>
              <Text>Cerdo de Engorde</Text>
            </View>
          </View>
          <View style={styles.textconventionsLong}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * CD
              </Text>
            </View>
            <View style={styles.textLong}>
              <Text>Cerdo de Descarte</Text>
            </View>
          </View>
          <View style={styles.textconventionsLong}>
            <View style={[styles.gsmi, { width: 30 }]}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * C150
              </Text>
            </View>
            <View style={styles.textLong}>
              <Text>Centro de más de 150kg</Text>
            </View>
          </View>
          <View style={styles.textconventionsShort}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * (H)
              </Text>
            </View>
            <View style={styles.textShort}>
              <Text>Hembra</Text>
            </View>
          </View>
          <View style={styles.textconventionsShort}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * (M)
              </Text>
            </View>
            <View style={styles.textShort}>
              <Text>Macho</Text>
            </View>
          </View>
          <View style={styles.textconventionsShort}>
            <View style={styles.gsmi}>
              <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                * (A)
              </Text>
            </View>
            <View style={styles.textShort}>
              <Text>Ambos</Text>
            </View>
          </View>
        </View>
        <View style={styles.borderAM}>
          <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
            Convenciones
          </Text>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screenLotTicket" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte Técnico Tiquete de Báscula Corrales"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
