import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  textInfo: {
    position: 'relative',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    margin: 0,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  divContainer:{
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  widthContainerTotal:{
    width: '30%',
  },
  fontInfo:{
    fontWeight: 'bold',
    textAlign: 'center',
  },
  fontSizeInfo:{
    fontSize: 30,
  },
  fontSizeNumberInfo:{
    fontSize: 40,
  },
  fontColorNumberInfo:{
    color: '#767676'
  }
}))

export { useStyles as default }
