import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogContainer:{
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px'
    }
   },
   dialogFooter:{
    padding: 16
  }, 
}));

export default useStyles;
