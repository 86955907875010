export default {
  snackbar: {
    action: null,
    actionText: 'ACTION',
    autoHideDuration: null,
    placement: {
      vertical: 'top',
      horizontal: 'right',
    },
    closeBtn: true,
    message: 'SNACKBAR_MESSAGE',
    type: 'DEFAULT',
    open: false,
  },
  sidebar: {
    folded: true,
    foldedOpen: false,
    width: 280,
  },
  sharedReports: {
    valueRouteTab: 'main',
  },
  subTab: {
    valueSubTab: 0,
  },
  transport: {
    valueTransportTab: 0,
  },
};
