import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import { Link } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },

  iconRoot: {
    cursor: 'pointer',
    color: '#DD9835',
    margin: '5px 10px',
    fontSize: 24,
  },
}));

interface GoBackProps {
  arrowMode?: boolean;
}

export default function GobackLink({ arrowMode }: GoBackProps) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div>
      {arrowMode ? (
        <Link onClick={history.goBack}>
          <ArrowBackIcon className={classes.iconRoot} />
        </Link>
      ) : (
        <Link
          color="textSecondary"
          onClick={history.goBack}
          className={classes.root}
        >
          Volver atrás
        </Link>
      )}
    </div>
  );
}
