import {
  Container,
  Typography,
  Divider,
  Grid,
  TextField,
  Checkbox,
  Button,
  Box,
  IconButton,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';

import TextTerms from 'components/PageComponents/TYC/terms';
import HabeasData from 'components/PageComponents/TYC/habeasData';
import PersonalData from 'components/PageComponents/TYC/personalData';
import ActiveUser from 'components/PageComponents/ActiveUser';

import { getEmailRequest } from 'api/registerUserService';
import saveUserService from 'api/saveUserService';

import { TitleContainer, FeaturedText, ButtonsRow } from 'core/styles/_utils';
import { Form } from 'core/styles/_forms';

import clsx from 'clsx';
import dayjs from 'dayjs';

import { RegisterContainer, Content, useStyles } from './styles';
import { RegisterUserValidation } from 'lib/validations/RegisterUserValidations';

const RegisterUser = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(true);
  const [viewPass, setViewPass] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTYC, setOpenTYC] = useState(false);
  const [typeTYC, setTypeTYC] = useState(0);

  const handleOpenTYC = () => setOpenTYC(true);
  const handleCloseTYC = () => setOpenTYC(false);
  const [openNoAccept, setOpenNoAccept] = useState(false);
  const [openHabeasData, setOpenHabeasData] = useState(false);
  const handleOpenNoAccept = () => {
    setOpenNoAccept(true);
    handleCloseTYC();
  };
  const handleCloseNoAccept = () => setOpenNoAccept(false);

  const [openAccept, setOpenAccept] = useState(false);
  const handleOpenAccept = () => {
    setOpenAccept(true);
    handleCloseTYC();
  };
  const handleCloseAccept = () => setOpenAccept(false);

  const handleOpenHabeasData = () => {
    setOpenHabeasData(true);
    handleCloseTYC();
  };

  const handleCloseHabeasData = () => {
    setOpenAccept(true);
    setOpenHabeasData(false);
  };

  const { setFieldValue, ...formik } = useFormik({
    initialValues: {
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
      aceptaPoliticaConfidencialidad: false,
      aceptaHabeasData: false,
      aceptaTerminosUso: false,
      idSolicitud: Number(urlParams.get('request')),
    },
    validationSchema: RegisterUserValidation,
    onSubmit: (values) => {
      setLoading(true);
      saveUserService(values)
        .then((data) => {
          setLoading(false);
          if (data.data) {
            enqueueSnackbar('Usuario registrado con éxito', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              autoHideDuration: 3000,
            });
            setOpen(true);
          } else {
            enqueueSnackbar(`${data}`, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              autoHideDuration: 3000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(`${error}`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            autoHideDuration: 3000,
          });
        });
    },
  });

  useEffect(() => {
    if (
      !urlParams.has('request') ||
      !Number.isSafeInteger(Number(urlParams.get('request')))
    ) {
      enqueueSnackbar('No tienes una solicitud válida', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
      });
      history.push('/auth/login');
    } else {
      getEmailRequest(urlParams.get('request') as string)
        .then((data) => {
          if (data.status === 400) {
            enqueueSnackbar('No se ha encontrado la solicitud requerida', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              autoHideDuration: 3000,
              onExit: () => history.push('/auth/login'),
            });
          } else if (data.status) {
            setFieldValue('email', data.data.email);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [search]);

  const hasError = (field: string): boolean => {
    const touchedKeys = Object.keys(formik.touched);
    const errorKeys = Object.keys(formik.errors);
    return touchedKeys.includes(field) && errorKeys.includes(field);
  };

  const type =
    typeTYC === 1
      ? 'Terminos de Uso'
      : typeTYC === 2
      ? 'Tratamiento de Datos Personales'
      : '';

  return (
    <>
      <Dialog
        open={openNoAccept}
        onClose={handleCloseNoAccept}
        maxWidth="sm"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogContent>
          <i className={clsx('icon-circle-alert', classes.iconDialogCheck)} />
          <Typography variant="h5" className={classes.title}>
            No ha Aceptado {type}
          </Typography>
          <Typography variant="subtitle1" className={classes.captionGray}>
            Al no aceptar {type}, no podrá registrarse, ni hacer uso de la
            plataforma Infoporcinos, si requiere el uso de esta debe leer y
            aceptar: los Terminos de uso del sitio web y la politica de
            Tratamiento de Datos Personales.
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            onClick={handleCloseNoAccept}
            variant="contained"
            color="primary"
          >
            Informado
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAccept}
        onClose={handleCloseAccept}
        maxWidth="sm"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogContent>
          <i className={clsx('icon-circle-check', classes.iconDialogCheck)} />
          <Typography variant="h5" className={classes.title}>
            Ha Aceptado {type}.
          </Typography>
          <Typography variant="subtitle1" className={classes.captionGray}>
            Se ha aceptado {type} y será notificado con está acción a través del
            correo electrónico registrado.
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            onClick={handleCloseAccept}
            variant="contained"
            color="primary"
          >
            Informado
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openHabeasData}
        onClose={handleCloseHabeasData}
        maxWidth="md"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogContent>
          <HabeasData />
          <DialogActions classes={{ root: classes.dialogFooter }}>
            <Button
              onClick={handleCloseHabeasData}
              variant="contained"
              color="primary"
            >
              Informado
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openTYC}
        onClose={handleCloseTYC}
        className={classes.dialogContainer}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {typeTYC === 1 ? (
            <TextTerms />
          ) : typeTYC === 2 ? (
            <PersonalData />
          ) : null}
          <DialogActions>
            {(typeTYC === 1 && formik.values.aceptaTerminosUso === true) ||
            (typeTYC === 2 &&
              formik.values.aceptaPoliticaConfidencialidad === true) ||
            (typeTYC === 3 && formik.values.aceptaHabeasData === true) ? (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Typography variant="subtitle1" color="primary">
                    Usted ha leído, aceptado y ha sido notificado con nuestros{' '}
                    {type}
                  </Typography>
                  <Typography variant="subtitle1" color="secondary">
                    {dayjs().format('DD-MM-YYYY HH:mm:ss')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCloseTYC}
                  >
                    Cerrar
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenNoAccept}
                >
                  No Aceptar
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    {
                      typeTYC === 1
                        ? (setFieldValue('aceptaTerminosUso', true),
                          handleOpenAccept())
                        : typeTYC === 2
                        ? (setFieldValue('aceptaHabeasData', true),
                          setFieldValue('aceptaPoliticaConfidencialidad', true),
                          handleOpenHabeasData())
                        : null;
                    }
                  }}
                >
                  Aceptar
                </Button>
              </>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
      <RegisterContainer>
        <ActiveUser open={open} />
        <Content>
          <Container>
            <TitleContainer>
              <FeaturedText>REGISTRO DE USUARIO</FeaturedText>
              <Divider />
              <Typography>
                A continuación defina un nombre de usuario y una contraseña
              </Typography>
            </TitleContainer>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} component={Form.Container}>
                <Grid item xs={12}>
                  <TextField
                    label="Correo Electrónico"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={hasError('email')}
                    helperText={hasError('email') && formik.errors.email}
                    InputProps={{
                      startAdornment: (
                        <i className="icon-envelope" style={{ fontSize: 24 }} />
                      ),
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Nombre de usuario"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={hasError('username')}
                    helperText={hasError('username') && formik.errors.username}
                    InputProps={{
                      startAdornment: (
                        <i className="icon-user" style={{ fontSize: 24 }} />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Contraseña"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={hasError('password')}
                    helperText={hasError('password') && formik.errors.password}
                    type={viewPass ? 'text' : 'password'}
                    InputProps={{
                      startAdornment: (
                        <i className="icon-lock" style={{ fontSize: 24 }} />
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setViewPass(!viewPass)}>
                            {!viewPass ? (
                              <VisibilityOutlined />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Confirmar Contraseña"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={hasError('confirmPassword')}
                    helperText={
                      hasError('confirmPassword') &&
                      formik.errors.confirmPassword
                    }
                    type={viewPass ? 'text' : 'password'}
                    InputProps={{
                      startAdornment: (
                        <i className="icon-lock" style={{ fontSize: 24 }} />
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setViewPass(!viewPass)}>
                            {!viewPass ? (
                              <VisibilityOutlined />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  className={classes.checkboxContainer}
                >
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={formik.values.aceptaTerminosUso}
                      name="aceptaTerminosUso"
                      onClick={() => {
                        handleOpenTYC();
                        setTypeTYC(1);
                      }}
                      color="primary"
                    />

                    <Button
                      variant="text"
                      onClick={() => {
                        handleOpenTYC();
                        setTypeTYC(1);
                      }}
                    >
                      Acepto los Términos de Uso del sitio web
                    </Button>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={formik.values.aceptaPoliticaConfidencialidad}
                      name="aceptaPoliticaConfidencialidad"
                      onClick={() => {
                        handleOpenTYC();
                        setTypeTYC(2);
                      }}
                      color="primary"
                    />

                    <Button
                      variant="text"
                      onClick={() => {
                        handleOpenTYC();
                        setTypeTYC(2);
                      }}
                    >
                      Acepto Tratamiento de datos personales.
                    </Button>
                  </Box>
                </Grid>
                <Box margin="auto">
                  <Typography color="error" align="center">
                    {formik.errors.aceptaTerminosUso ||
                      formik.errors.aceptaPoliticaConfidencialidad ||
                      formik.errors.aceptaHabeasData}
                  </Typography>
                </Box>
              </Grid>
              <ButtonsRow>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  endIcon={
                    loading && (
                      <CircularProgress style={{ color: '#FFF' }} size={20} />
                    )
                  }
                >
                  Aceptar
                </Button>
              </ButtonsRow>
            </form>
          </Container>
        </Content>
      </RegisterContainer>
    </>
  );
};
export default RegisterUser;
