import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import clsx from 'clsx';

import useStyles from './styles';

const ActiveUser = ({ open }: any) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogContent>
          <i className={clsx('icon-user-check', classes.iconDialogCheck)} />
          <Typography variant="h5" className={classes.title}>
            ¡Usuario Creado!
          </Typography>
          <Typography variant="subtitle1" className={classes.captionGray}>
            El usuario ha sido creado de manera exitosa, se enviará un correo
            electrónico con los pasos para la activación del usuario.
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            component={Link}
            to="/auth/login"
            variant="contained"
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActiveUser;
