import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogContainer:{
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px'
    }
  },
  contentDialog:{
    padding: '0px 40px'
  },
  steps:{
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: theme.palette.primary.main, 
      fontWeight: 'bold'
     },
     '& .MuiStepLabel-completed':{
       color: '#AFAFAF'
     }
  },
  card:{
    minWidth: 200,
    borderRadius: 10, 
    boxShadow: theme.shadows[2],
    margin: 12
  }, 
  content:{
    height: 120,
    padding: 12, 
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center', 
    fontSize: '50px'
  },
  table:{
    "& .MuiTableCell-root":{
      border: 'none'
    }
  },
  itemGrey :{
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dialogFooter:{
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center', 
    margin: '20px 0px'
  },  
  description:{
    color: '#767676', 
    margin: '12px 0px'
  }, 
  cardReports:{
    display: 'flex', 
    alignItems:'center',
    minWidth: 200,
    borderRadius: 10, 
    boxShadow: 'none',
    border: '1px solid #E7E7E7', 
    margin: 12, 
    padding: 8, 
    "& .MuiAvatar-root":{
      marginRight: 8, 
      fontSize: '20px'
    },
    "& .MuiTypography-root":{
      fontWeight: 'bold', 
      color: '#767676'
    }
  }, 
  checkbox:{
    "& .MuiTypography-root":{
      color: '#767676', 
      fontWeight: 'bold'
    }
  }
}))

export { useStyles as default }
