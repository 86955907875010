import React from 'react'
import { SpinnerStyles } from './styles'

const Spinner = () => {
  return (
    <SpinnerStyles>
      <div /><div /><div /><div /><div /><div /><div /><div />
    </SpinnerStyles>
  )
}

export default Spinner
