import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  List,
  ListItem,
  Button,
  IconButton,
  Tooltip,
  Fab,
  Box,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import dayjs from 'dayjs';
import domtoimage from 'dom-to-image';
import { Link } from 'react-router-dom';

import ContentCard from 'components/ContentCard';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import LevelIndicator from 'components/LevelIndicator';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import GobackLink from 'components/GobackLink';
import DialogPDFViewer from 'components/DialogPDFViewer/index';

import { ReportDate, MyDocCorralDate } from './../Reports/ReportDate';

import useStyles from './styles';

const Search = () => {
  const classes = useStyles();
  const url = new URLSearchParams(window.location.search);
  const [type] = useState(url.get('type'));
  const [openReportDate, setOpenReportDate] = useState(false);
  const [imageQr, setImageQr] = useState('');

  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/inventory/corral', title: 'Inventario Corrales' },
  ];

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenqrcorraldate');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModelReportDate = () => {
    setOpenReportDate(true);
  };

  useEffect(() => {
    generateScreenshot();
  }, []);

  const getDateFilter = () => {
    switch (type) {
      case 'lote':
        return url.get('code');
      case 'today':
        return dayjs().format('DD/MM/YYYY');
      default:
        return 'El tipo de filtro solicitado no existe';
    }
  };

  const getInfoFilter = () => {
    switch (type) {
      case 'lote':
        return (
          <>
            <Typography className={classes.filterType}>Lote:</Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{url.get('code')}</span>
            </Typography>
          </>
        );
      case 'today':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">
                {dayjs().format('dddd, D MMMM YYYY')} (Hoy)
              </span>
            </Typography>
          </>
        );
      default:
        return (
          <Typography className={classes.filterType}>
            El tipo de filtro solicitado no existe
          </Typography>
        );
    }
  };

  const rows = [
    {
      id: 1,
      lote_IP: '0420',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '24',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '24',
    },
    {
      id: 2,
      lote_IP: '0421',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '30',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '30',
    },
    {
      id: 3,
      lote_IP: '0421',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '30',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '30',
    },
    {
      id: 4,
      lote_IP: '0421',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '30',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '30',
    },
    {
      id: 5,
      lote_IP: '0421',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '30',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '30',
    },
    {
      id: 6,
      lote_IP: '0421',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '30',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '30',
    },
    {
      id: 7,
      lote_IP: '0421',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '30',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '30',
    },
    {
      id: 8,
      lote_IP: '0421',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '30',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '30',
    },
    {
      id: 9,
      lote_IP: '0421',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '30',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '30',
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'lote_IP',
      headerName: 'Lote IP',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'animales_lote',
      headerName: 'Animales lote',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'CO',
      headerName: 'CO',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'SBE',
      headerName: 'SBE',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'muertos_CO',
      headerName: 'Muertos CO',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'saldo',
      headerName: 'Saldo',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'CR',
      headerName: 'CR',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'enviados_sacrificio',
      headerName: 'Enviados Sacrificio',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'muertos_CR',
      headerName: 'Muertos CR',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'total',
      headerName: 'Total Existencia',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'horas_corrales',
      headerName: 'Horas en Corrales',
      renderCell: () => (
        <div className={classes.levelContainer}>
          <LevelIndicator value={11} levels={[[4], [4, 8], [8, 12], [12]]} />
        </div>
      ),
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: '',
      headerName: '',
      renderCell: (params: GridValueGetterParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`details/${params.getValue(params.id, 'id')}`}
              color="primary"
              className={classes.btnDetails}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportDate />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <Grid container>
        <ContentCard className={classes.cardContainer}>
          <Grid item container>
            <Grid item container>
              <Box mr={5}>
                <Typography className={classes.title}>Búsqueda</Typography>
              </Box>
              <Button
                variant="contained"
                className="raleway"
                color="primary"
                onClick={openModelReportDate}
              >
                Generar Reporte
              </Button>
            </Grid>
          </Grid>
          <Box borderBottom={1} mt={3} mb={3} className={classes.colorHr}>
            <Grid item container>
              <Grid item container xs={6} alignItems="flex-end">
                {getInfoFilter()}
              </Grid>
              <Grid
                item
                container
                xs={6}
                justify="flex-end"
                alignItems="center"
              >
                <Box
                  component="i"
                  className="icon-document"
                  fontSize={25}
                  color="#767676"
                />
                <Typography className={classes.result}>
                  09 Resultados
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box paddingLeft={3} paddingRight={3}>
            <DataGrid
              columns={columns}
              rows={rows}
              autoHeight
              components={{
                Footer: TableFooter,
              }}
              className={classes.dataGrid}
            />
          </Box>
        </ContentCard>
      </Grid>
      <DialogPDFViewer
        open={openReportDate}
        onClose={() => setOpenReportDate(false)}
        done={Boolean(imageQr)}
      >
        <MyDocCorralDate qr={imageQr} fecha={getDateFilter()} />
      </DialogPDFViewer>
    </>
  );
};
export default Search;
