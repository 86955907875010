

import { makeStyles } from "@material-ui/core";
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    borderRadius: '20px',
    minWidth: 250, 
    maxWidth: 250,
    "& .MuiCardHeader-root":{
      padding: "15px 15px 0px 0px"
    },
  },
  nameInfo:{
    fontWeight: 'bolder',
    textAlign: 'center'
  },
  ubicationInfo:{
    textAlign: 'center',
    color: '#AFAFAF',
    fontSize: '12px',
  },
  dataTitle:{
    color: '#AFAFAF',
    fontSize: '12px'
  },
  dataInfo:{
    fontWeight: 'bolder'
  },
  buttonColorGreen:{
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
  buttonColorRed:{
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900],
    },
  }
}));

export { useStyles as default }