import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px',
    }
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  gridMargin:{
    marginBottom: '40px'
  },
  cardItem: {
    width: 120,
    height: 150,
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      },
    },
  },
  cardSelected: {
    backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      }
  },
  cardActionArea: {
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  icon: {
    fontSize: 45,
    color: '#767676',
    margin: '0px 20px',
  },
  mainText: {
    color: '#767676',
    fontSize: 13,
    fontWeight: 'bolder',
    textAlign: 'center'
  },
}))

export { useStyles as default }