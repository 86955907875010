import { gql } from 'apollo-boost';

export const GET_ROUTES= gql`
  query GetRoutes {
    roads {
      idTipoVia
      descripcion
      abreviatura
    }
  }
`;
