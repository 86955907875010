import * as yup from 'yup';
import messages from './messages';

export const sentInvitationPersonValidations = yup.object().shape({
  firtName: yup
    .string()
    .required(messages.REQUIRED_FIELD)
    .min(2, 'El nombre debe tener al menos 2 caracteres'),
  secondName: yup
    .string()
    .min(2, 'El nombre debe tener al menos 2 caracteres'),
  firtSurname: yup
    .string()
    .required(messages.REQUIRED_FIELD)
    .min(2, 'El apellido debe tener al menos 2 caracteres'),
  secondSurname: yup
    .string()
    .min(2, 'El apellido debe tener al menos 2 caracteres'),
  documentType: yup.number().required('Debes seleccionar el tipo de documento'),
  document: yup
    .number()
    .min(100000, 'El documente debe tener al menos 6 números')
    .required(messages.REQUIRED_FIELD),
  indicative: yup.string().required(messages.REQUIRED_FIELD),
  mobile: yup
    .number()
    .typeError(messages.ONLY_NUMBERS)
    .min(10, 'El celular debe tener al menos 10 caracteres')
    .required(messages.REQUIRED_FIELD),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
})

export const sentInvitationBusinessValidations = yup.object().shape({
  businessName: yup.string().required(messages.REQUIRED_FIELD),
  nit: yup
    .number()
    .typeError(messages.ONLY_NUMBERS)
    .required(messages.REQUIRED_FIELD),
  digitoVerificacion: yup
    .number()
    .typeError(messages.ONLY_NUMBERS)
    .required(messages.REQUIRED_FIELD),
  indicativePhone: yup.string().required(messages.REQUIRED_FIELD),
  phone: yup
    .number()
    .typeError(messages.ONLY_NUMBERS)
    .min(999999, 'El Telefono debe tener al menos 7 caracteres')
    .required(messages.REQUIRED_FIELD),
  ext: yup
    .number()
    .typeError(messages.ONLY_NUMBERS),
  indicative: yup.string().required(messages.REQUIRED_FIELD),
  mobile: yup
    .number()
    .typeError(messages.ONLY_NUMBERS)
    .min(999999999, 'El celular debe tener al menos 10 caracteres')
    .required(messages.REQUIRED_FIELD),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
});

export const editInvitationPerson = yup.object().shape({
  primerNombre: yup
    .string()
    .required(messages.REQUIRED_FIELD),
  segundoNombre: yup
    .string(),
  primerApellido: yup
    .string()
    .required(messages.REQUIRED_FIELD),
  segundoApellido: yup
    .string(),
  tipoDocumento: yup.number(),
  numeroDocumento: yup
  .number()
  .min(100000, 'El Documento debe tener al menos 6 números')
  .required(messages.REQUIRED_FIELD),
  indicativoCelular: yup.number().required(messages.REQUIRED_FIELD),
  celular: yup
    .number()
    .required(messages.REQUIRED_FIELD)
    .typeError(messages.ONLY_NUMBERS)
    .min(1000000000, 'El celular debe tener al menos 10 caracteres'),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
  tipoUsuario: yup.string()
  .required(messages.REQUIRED_FIELD)
})

export const editInvitationCompany = yup.object().shape({
  razonSocial: yup
  .string()
  .required(messages.REQUIRED_FIELD),
  nit: yup
  .number()
  .min(100000, 'El Nit debe tener al menos 6 números')
  .required(messages.REQUIRED_FIELD),
  digitoVerificacion: yup
  .string()
  .required(messages.REQUIRED_FIELD),
  indicativoCelular: yup.number().required(messages.REQUIRED_FIELD),
  celular: yup
  .number()
  .required(messages.REQUIRED_FIELD)
  .typeError(messages.ONLY_NUMBERS)
  .min(1000000000, 'El celular debe tener al menos 10 caracteres'),
  indicativoTelefono: yup.number().required(messages.REQUIRED_FIELD),
  telefono: yup
  .number()
  .min(100000, 'El Teléfono debe tener al menos 6 números')
  .required(messages.REQUIRED_FIELD),
  extension: yup
  .number(),
  email:yup
  .string()
  .email(messages.INVALID_EMAIL_ADDRESS)
  .required(messages.REQUIRED_FIELD),
  tipoUsuario: yup.string()
  .required(messages.REQUIRED_FIELD)
})