import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogContainer:{
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px'
    },
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px'
    }
  },
  contentDialog:{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center'
  },
  steps:{
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: theme.palette.primary.main, 
      fontWeight: 'bold'
     },
     '& .MuiStepLabel-completed':{
       color: '#AFAFAF'
     }
  }, 
  containerPrimaryInfo:{
    marginBottom: 20
  }, 
  primaryInfoContent:{
    display: 'flex',
    padding: '4px 8px',
    background: '#F2F2F2',
    justifyContent: 'space-between',
  }, 
  info:{
    color: '#AFAFAF',  
    fontWeight: 'bold', 
    margin: '0px 4px'
  }, 
  itemGrey :{
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dialogFooter:{
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center', 
    margin: '20px 0px'
  },
  table:{
    "& .MuiTableCell-root":{
      border: 'none'
    }
  },
  iconDialogCheck:{
    fontSize:60,
    color:theme.palette.error.dark
  }, 
  checkbox:{
    "& .MuiTypography-root":{
      color: '#767676', 
      fontWeight: 'bold'
    }
  }
}))

export { useStyles as default }
