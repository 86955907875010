import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Divider,
  TextField,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";
import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { GET_ROLES } from "core/graphql/queries/settings";
import { ALL_NAVIGATION } from "core/graphql/queries/allNavigation";
import { ASSIGN_ROLE } from "core/graphql/mutations/internalUsers";
import { useSnackbar } from "notistack";
import RegisterRol from "components/PageComponents/Settings/RegisterRol";
import useStyles from "./styles";
interface UpdateRoleProps {
  uid: Number;
  refetchDetails?: any;
  IdRole: number;
  children: any;
}

const EditRolAndPermissions = ({
  uid,
  IdRole,
  refetchDetails,
  children,
}: UpdateRoleProps) => {
  const classes = useStyles();

  const [openEditData, setOpenEditData] = useState(false);
  const handleOpenEditData = () => {
    setOpenEditData(true);
  };
  const handleCloseEditData = () => {
    setOpenEditData(false);
  };

  const [idRole, setIdRole] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const userType = useSelector((state: any) => state.user.selectedUserType.id);
  const [visibility, setVisibility] = useState(true);
  const [roles, setRoles] = useState<any>([]);
  const [permissions, setPermissions] = useState<any>([]);
  const token = useSelector((state: any) => state.user.token);

  useEffect(() => {
    setIdRole(IdRole);
  }, [IdRole]);

  const {
    loading: loadingRoles,
    error: errorRoles,
    data: dataRoles,
  } = useQuery(GET_ROLES, {
    context: { headers: { "x-auth-jwt": token } },
  });

  const { loading, error, data, refetch } = useQuery(ALL_NAVIGATION, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idRol: idRole },
  });

  useEffect(() => {
    if (!loading && data) {
      setPermissions(data?.getAllNavigation?.data);
    }
  }, [loading, data]);
  const [assignRole, { loading: loadingChangeRol }] = useMutation(ASSIGN_ROLE);

  useEffect(() => {
    if (!loadingRoles && dataRoles) {
      const misRoles = dataRoles?.getRoles?.data?.misRoles?.map(
        (item: any) => item
      );

      const rolesSistema = dataRoles?.getRoles?.data?.rolesSistema?.map(
        (item: any) => item
      );
      setRoles(
        [...rolesSistema, ...misRoles].filter((item: any) => item?.estado)
      );
    }
  }, [dataRoles, loadingRoles]);

  const handleTabChange = (value: number) => {
    setIdRole(value);
    refetch();
  };

  const handleAssignRole = () => {
    assignRole({
      variables: {
        idRol: idRole,
        idTipoUsuario: userType,
        idUsuarioInterno: uid,
      },
      context: { headers: { "x-auth-jwt": token } },
    })
      .then((data) => {
        if (data?.data?.assignRole?.data?.success === 1) {
          refetchDetails();
          setOpenEditData(false);
          enqueueSnackbar(`${data?.data?.assignRole?.data?.message}`, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          setOpenEditData(false);
          enqueueSnackbar(`${data?.data?.assignRole?.data?.message}`, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setOpenEditData(false);
        enqueueSnackbar(`${error?.message}`, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  };

  return (
    <>
      {children({ handleOpenEditData })}
      <Dialog
        open={openEditData}
        onClose={handleCloseEditData}
        maxWidth="md"
        fullWidth
        className={clsx({
          [classes.dialogContainer]: true,
          [classes.visibility]: !visibility,
        })}
      >
        <DialogTitle className={classes.containerCenter}>
          <Typography variant="h6" align="center" color="secondary">
            Cambiar Rol del usuario
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            A continuación seleccione el nuevo rol que le asignara al usuario.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box paddingX={3} paddingY={2}>
            <div className={classes.containerRoles}>
              <Box width={300}>
                <TextField
                  label="Rol para el usuario"
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="role"
                  select
                  required
                  value={idRole}
                  onChange={(e: any) => {
                    handleTabChange(e.target.value);
                  }}
                >
                  {roles?.map((item: any) => (
                    <MenuItem key={item.idRol} value={item.idRol}>
                      {item?.descripcion}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <div>
                <RegisterRol>
                  {({ handleOpenRol, openRol }: any) => {
                    setVisibility(!openRol);
                    return (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<i className="icon-plus" />}
                        onClick={handleOpenRol}
                      >
                        Registrar Rol
                      </Button>
                    );
                  }}
                </RegisterRol>
              </div>
            </div>
          </Box>

          <Box paddingX={3}>
            {permissions?.map((item: any) => {
              return (
                <>
                  <Box marginY={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bold" }}
                          color="secondary"
                        >
                          {item?.name}
                        </Typography>
                      </Grid>
                      <Grid item container xs={9}>
                        {item?.children?.length !== 0 ? (
                          item?.children?.map((subcategory: any) => (
                            <Grid item xs={4}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={subcategory?.state}
                                    color="primary"
                                    disabled
                                  />
                                }
                                label={subcategory?.name}
                              />
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={item?.state}
                                  color="primary"
                                  disabled
                                />
                              }
                              label={item?.name}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                </>
              );
            })}
          </Box>

          <DialogActions className={classes.dialogFooter}>
            <Button
              variant="outlined"
              className={classes.btn}
              onClick={handleCloseEditData}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={handleAssignRole}
              endIcon={
                loadingChangeRol && (
                  <CircularProgress size={20} style={{ color: "#FFF" }} />
                )
              }
            >
              Actualizar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditRolAndPermissions;
