import ArrivalModal from './Arrival'
// import ColdModal from './Cold'
import CorralsModal from './Corrals'
// import DespatchModal from './Despatch'
// import GeneralModal from './General'
// import ObservationModal from './Observation'
// import SacrificeModal from './Sacrifice'
import TransportModal from './Transport'

const modals: any = {
  arrival: ArrivalModal,
  // cold: ColdModal,
  corrals: CorralsModal,
  // despatch: DespatchModal,
  // general: GeneralModal,
  // observation: ObservationModal,
  // sacrifice: SacrificeModal,
  transport: TransportModal
}

export default ({ modal = 'arrival', ...props }: { modal: any, open: boolean, onClose: () => void, lote: any }) => {
  const Modal = modals[modal]
  if (!Modal) return <code>Dialog undefined</code>
  return <Modal {...props} />
}