import { useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { logout } from 'api/authService';
import { SIGN_OUT } from 'core/redux/types/userTypes';
import storage from 'redux-persist/lib/storage';

export default () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const uid = useSelector((state: any) => state.user.user.id);
  const { enqueueSnackbar } = useSnackbar();

  const expiredSession = (status: number | string) => {
    if (status === 401) {
      logout(uid).then((res: any) => {
        if (res.statusCode === 200) {
          Promise.resolve()
            .then(() =>
              dispatch({
                type: SIGN_OUT,
              })
            )
            .then(() => localStorage.removeItem('persist:root'))
            .then(() => history.push(`/auth/login?leave=${pathname}`));
          enqueueSnackbar('Sesión expirada. Debes iniciar sesión', {
            variant: 'warning',
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'top',
            },
          });
        }
      });
      return false;
    }
    return true;
  };

  const signOut = () => {
    logout(uid).then((res: any) => {
      if (res.statusCode === 200) {
        Promise.resolve()
          .then(() =>
            dispatch({
              type: SIGN_OUT,
            })
          )
          .then(() => storage.removeItem('persist:root'))
          .then(() => history.push('/auth/login'));
      }
    });
  };

  return { expiredSession, signOut };
};
