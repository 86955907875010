import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import {
  GET_DRIVERS_COMPANY,
  GET_DRIVERS_VEHICLE,
} from 'core/graphql/queries/transport';
import { PUT_STATUS_DRIVER } from 'core/graphql/mutations/transport';

import CardDetailDriver from 'components/TransportComponents/CardDetailDriver/index';
import Results from 'components/Results/index';
import InformativeScreen from 'components/InformativeScreens';
import AcceptDialog from 'components/PageComponents/Profile/AcceptDialog';
import MainTitle from 'components/MainTitle';

import DialogAddDriver from './DialogAddDriver/index';

import useStyles from './styles';
import DialogTransportNotification from './../DialogTransportNotification/index';

type DialogProps = {
  open: boolean;
  data: any;
  state: string;
  isCompany?: boolean;
  handleClose: () => void;
};

const DialogAssociatedDrivers = ({
  open,
  data,
  state,
  isCompany,
  handleClose,
}: DialogProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const [dataNotification, setDataNotification] = useState('');

  const [openDialogAdd, setOpenDialogAdd] = useState({
    open: false,
    data: {},
  });
  const [openNotification, setOpenNotification] = useState({
    open: false,
    driver: '',
    action: false,
    idDriver: 0,
    message: '',
  });

  const handleOpenDialogAddDriver = (data: any) => {
    setOpenDialogAdd({
      open: true,
      data: data,
    });
  };

  const handleCloseDialogAddDriver = () => {
    setOpenDialogAdd({
      open: false,
      data: {},
    });
  };

  const handleOpenDialogNotification = (
    driver: string,
    action: boolean,
    idDriver: number,
    message: any
  ) => {
    setOpenNotification({
      open: true,
      driver: driver,
      action: action,
      idDriver: idDriver,
      message: message,
    });
  };

  const handleCloseDialogNotification = () => {
    setOpenNotification({
      open: false,
      driver: '',
      action: false,
      idDriver: 0,
      message: '',
    });
  };

  const [
    getDrivers,
    { data: dataDriver, loading: loadingDriver, error: errorDriver },
  ] = useLazyQuery(GET_DRIVERS_VEHICLE, {
    fetchPolicy: 'network-only',
  });

  const [
    getDriversC,
    {
      data: dataDriversCompany,
      loading: loadingDriversCompany,
      error: errorDriversCompany,
    },
  ] = useLazyQuery(GET_DRIVERS_COMPANY, {
    fetchPolicy: 'network-only',
  });

  const [putStatusDriver, { loading: loadingStatusDriver }] = useMutation(
    PUT_STATUS_DRIVER,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  useEffect(() => {
    if (!openDialogAdd.open && !isCompany && open) {
      getDrivers({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idVehiculo: data.id },
      });
    } else if (!openDialogAdd.open && isCompany && open) {
      getDriversC({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idEmpresaTransportadora: data.id },
      });
    }
  }, [openDialogAdd.open, isCompany, open]);

  useEffect(() => {
    if ((open && !isCompany) || loadingStatusDriver) {
      getDrivers({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idVehiculo: data.id },
      });
    } else if (open && isCompany) {
      getDriversC({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idEmpresaTransportadora: data.id },
      });
    }
  }, [isCompany, open, loadingStatusDriver]);

  const activeDrivers: number[] = [];
  const inactiveDrivers: number[] = [];
  dataDriver?.getDriversXVehicle?.data?.map((item: any) => {
    if (item?.estado) {
      activeDrivers.push(item);
    } else {
      inactiveDrivers.push(item);
    }
  });

  const activeDriversCompany: number[] = [];
  const inactiveDriversCompany: number[] = [];
  dataDriversCompany?.getDriversXCompany?.data.map((item: any) => {
    if (item?.estado) {
      activeDriversCompany.push(item);
    } else {
      inactiveDriversCompany.push(item);
    }
  });

  const changeStatusDriver = (
    idDriver: number,
    idVehicle: number,
    stateDriver: boolean
  ) => {
    putStatusDriver({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.putDriverStatus.data.success === 1) {
          enqueueSnackbar(
            state
              ? 'Conductor Activado Exitosamente'
              : 'Conductor Inactivado Exitosamente',
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }
          );
          handleCloseDialogNotification();
          if (state === 'active' && activeDrivers.length === 1) {
            handleClose();
          } else if (state === 'inactive' && inactiveDrivers.length === 1) {
            handleClose();
          }
        } else {
          enqueueSnackbar(mutationResult.data.putDriverStatus.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        idConductor: idDriver,
        idVehiculo: idVehicle,
        estadoConductor: stateDriver,
      },
    });
  };

  const message = (name: any) => {
    if (!isCompany && state === 'active' && activeDrivers.length === 1) {
      return (
        <Typography className={classes.fontTitleDialog}>
          Tenga en cuenta que el conductor{' '}
          <span className={classes.fontDataDialog}>{name}</span> es el único
          conductor asociado a este vehículo, si lo inactiva también se
          inactivara este vehículo{' '}
          <span className={classes.fontDataDialog}>({data.placa})</span>.
        </Typography>
      );
    } else if (
      !isCompany &&
      state === 'inactive' &&
      inactiveDrivers.length === 1
    ) {
      return (
        <Typography className={classes.fontTitleDialog}>
          ¿Está seguro que desea activar el conductor{' '}
          <span className={classes.fontDataDialog}>{name}</span>?
        </Typography>
      );
    } else if (!isCompany && state === 'active' && activeDrivers.length > 1) {
      return (
        <Typography className={classes.fontTitleDialog}>
          ¿Está seguro que desea inactivar el conductor{' '}
          <span className={classes.fontDataDialog}>{name}</span>?
        </Typography>
      );
    } else if (
      !isCompany &&
      state === 'inactive' &&
      inactiveDrivers.length > 1
    ) {
      return (
        <Typography className={classes.fontTitleDialog}>
          ¿Está seguro que desea activar el conductor{' '}
          <span className={classes.fontDataDialog}>{name}</span>?
        </Typography>
      );
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        scroll="body"
        className={clsx({
          [classes.borderRadiudDialog]: true,
          [classes.hiddenDialog]: openDialogAdd.open,
        })}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dia
        log-title"
      >
        <DialogContent>
          <Grid container style={{ marginBottom: '5px' }}>
            <Grid item container xs={8} alignItems="center">
              <MainTitle>
                {isCompany
                  ? 'Conductores asociados a la empresa:'
                  : 'Conductores asociados al vehículo:'}
              </MainTitle>
              <Typography>
                <span className={classes.fontName}>{data.placa}</span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.gridButton}
              alignItems="center"
            >
              <Box mr={5}>
                <Results
                  number={
                    isCompany
                      ? state === 'active'
                        ? activeDriversCompany.length
                        : inactiveDriversCompany.length
                      : state === 'active'
                      ? activeDrivers.length
                      : inactiveDrivers.length
                  }
                />
              </Box>
              {!isCompany && (
                <Box ml={5}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenDialogAddDriver(data)}
                  >
                    Agregar Conductor
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>

          {!isCompany ? (
            <Grid container className={classes.cardGridContainer}>
              {state === 'active' &&
                activeDrivers.length !== 0 &&
                activeDrivers.map(
                  (item: any, index: number) =>
                    item?.estado && (
                      <Grid item key={index} style={{ margin: 18 }}>
                        <CardDetailDriver
                          key={index}
                          id={item?.idConductor}
                          action="inactivar"
                          name={item?.nombreCompleto}
                          ubication="Medellín"
                          document={item?.numeroDocumento}
                          actionFuncion={() =>
                            handleOpenDialogNotification(
                              item?.nombreCompleto,
                              false,
                              item?.idConductor,
                              message(item?.nombreCompleto)
                            )
                          }
                        />
                      </Grid>
                    )
                )}

              {state === 'inactive' &&
                inactiveDrivers.length !== 0 &&
                inactiveDrivers.map(
                  (item: any, index: number) =>
                    !item?.estado && (
                      <Grid item key={index} style={{ margin: 18 }}>
                        <CardDetailDriver
                          key={index}
                          id={item?.idConductor}
                          action="activar"
                          name={item?.nombreCompleto}
                          ubication="Medellín"
                          document={item?.numeroDocumento}
                          actionFuncion={() =>
                            handleOpenDialogNotification(
                              item?.nombreCompleto,
                              true,
                              item?.idConductor,
                              message(item?.nombreCompleto)
                            )
                          }
                        />
                      </Grid>
                    )
                )}
            </Grid>
          ) : (
            <Grid container className={classes.cardGridContainer}>
              {state === 'active' &&
                activeDriversCompany.map(
                  (item: any, index: number) =>
                    item?.estado && (
                      <Grid item key={index} style={{ margin: 18 }}>
                        <CardDetailDriver
                          key={index}
                          id={item?.idConductor}
                          name={item?.nombreCompleto}
                          ubication="Medellín"
                          document={item?.numeroDocumento}
                        />
                      </Grid>
                    )
                )}

              {state === 'inactive' &&
                inactiveDriversCompany.map(
                  (item: any, index: number) =>
                    !item?.estado && (
                      <Grid item key={index} style={{ margin: 18 }}>
                        <CardDetailDriver
                          key={index}
                          id={item?.idConductor}
                          name={item?.nombreCompleto}
                          ubication="Medellín"
                          document={item?.numeroDocumento}
                        />
                      </Grid>
                    )
                )}
            </Grid>
          )}

          {isCompany &&
            state === 'inactive' &&
            inactiveDriversCompany.length === 0 &&
            !loadingDriversCompany && (
              <InformativeScreen
                type="transport"
                subtitle="¡No encontramos nada!"
                info="Esta Empresa no tiene conductores inactivos asociados."
                onlyError
              />
            )}

          {isCompany &&
            state === 'active' &&
            activeDriversCompany.length === 0 &&
            !loadingDriversCompany && (
              <InformativeScreen
                type="transport"
                subtitle="¡No encontramos nada!"
                info="Esta Empresa no tiene conductores activos asociados."
                onlyError
              />
            )}

          {!isCompany &&
            state === 'inactive' &&
            inactiveDrivers.length === 0 &&
            !loadingDriver && (
              <InformativeScreen
                type="transport"
                subtitle="¡No encontramos nada!"
                info="Este Vehículo no tiene conductores inactivos asociados."
                onlyError
              />
            )}

          {!isCompany &&
            state === 'active' &&
            activeDrivers.length === 0 &&
            !loadingDriver && (
              <InformativeScreen
                type="transport"
                subtitle="¡No encontramos nada!"
                info="Este Vehículo no tiene conductores activos asociados."
                onlyError
              />
            )}

          {isCompany
            ? loadingDriversCompany && (
                <Box mt={5} mb={3}>
                  <Grid container justifyContent="center">
                    <CircularProgress color="primary" thickness={5} size={60} />
                  </Grid>
                  <Grid container justifyContent="center">
                    <Box mt={1}>
                      <Typography>Cargando ...</Typography>
                    </Box>
                  </Grid>
                </Box>
              )
            : loadingDriver && (
                <Box mt={5} mb={3}>
                  <Grid container justifyContent="center">
                    <CircularProgress color="primary" thickness={5} size={60} />
                  </Grid>
                  <Grid container justifyContent="center">
                    <Box mt={1}>
                      <Typography>Cargando ...</Typography>
                    </Box>
                  </Grid>
                </Box>
              )}
        </DialogContent>

        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button variant="contained" onClick={handleClose} color="primary">
              Aceptar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <DialogAddDriver
        open={openDialogAdd.open}
        data={openDialogAdd.data}
        handleClose={handleCloseDialogAddDriver}
      />
      {/* <AcceptDialog
        onClick={() =>
          changeStatusDriver(
            openNotification.idDriver,
            data.id,
            openNotification.action
          )
        }
        discardModal
        open={openNotification.open}
        onClose={() => handleCloseDialogNotification()}
        icon="icon-circle-alert"
        modalTitle={openNotification.message}
        modalDescription="Este cambio es reversible, puede volver a activar este Vehículo cuando desee desde esta misma opción."
      /> */}
      <DialogTransportNotification
        open={openNotification.open}
        icon="warning"
        title={
          activeDrivers.length === 1
            ? `¿Esta seguro que desea ${
                openNotification.action ? 'activar' : 'inactivar'
              } este conductor?`
            : ''
        }
        desciption="Este cambio es reversible, puede volver a activar este Vehículo cuando desee desde esta misma opción."
        handleConfirm={() =>
          changeStatusDriver(
            openNotification.idDriver,
            data.id,
            openNotification.action
          )
        }
        handleClose={() => handleCloseDialogNotification()}
      >
        <Box mt={2} mb={2}>
          <Typography>{openNotification.message}</Typography>
        </Box>
      </DialogTransportNotification>
    </>
  );
};
export default DialogAssociatedDrivers;
