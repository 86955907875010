import * as yup from 'yup';
import messages from './messages';

// Validacion de los pasos del formulario crear empresa
export const CompanyValidationStepOne = yup.object({
  companyName: yup.string().required(messages.REQUIRED_FIELD),
  nit: yup.number().required(messages.REQUIRED_FIELD),
  digitoVerificacion: yup.number().required(messages.REQUIRED_FIELD),
});

export const CompanyValidationStepTwo = yup.object({
  indicativePhone: yup.number().required('Debes seleccionar el indicativo del departamento'),
  phone: yup
    .number()
    .min(1000000, 'El telefono fijo debe tener al menos 7 números')
    .required(messages.REQUIRED_FIELD)
    .typeError(messages.ONLY_NUMBERS),
  indicativeMobile: yup.number().required('Debes seleccionar el indicativo del pais'),
  mobile: yup
    .number()
    .min(1000000000, 'El celular debe tener al menos 10 números')
    .required(messages.REQUIRED_FIELD)
    .typeError(messages.ONLY_NUMBERS),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
});

export const CompanyValidationStepThree = yup.object({
  country: yup.number().required(messages.REQUIRED_FIELD),
  departament: yup.number().required(messages.REQUIRED_FIELD),
  city: yup.number().required(messages.REQUIRED_FIELD),
  viaType: yup.number().required('Debes seleccionar el tipo de vía'),
  viaNumber: yup.string().required(messages.REQUIRED_FIELD),
  cruceNumber: yup.string().required(messages.REQUIRED_FIELD),
  cornerMeters: yup.string().required(messages.REQUIRED_FIELD),
  postalCode: yup.string().required(messages.REQUIRED_FIELD),
});

export const CompanyValidation = yup.object({
  companyName: yup.string().required(messages.REQUIRED_FIELD),
  nit: yup.number().required(messages.REQUIRED_FIELD),
  verificationDigit: yup.number().required(messages.REQUIRED_FIELD),
  country: yup.number().required(messages.REQUIRED_FIELD),
  departament: yup.number().required(messages.REQUIRED_FIELD),
  city: yup.number().required(messages.REQUIRED_FIELD),
  indicativePhone: yup.number().required('Debes seleccionar el indicativo del departamento'),
  phone: yup
    .number()
    .min(1000000, 'El telefono fijo debe tener al menos 7 números')
    .required(messages.REQUIRED_FIELD)
    .typeError(messages.ONLY_NUMBERS),
  indicativeMobile: yup.number().required('Debes seleccionar el indicativo del pais'),
  mobile: yup
    .number()
    .min(1000000000, 'El celular debe tener al menos 10 números')
    .required(messages.REQUIRED_FIELD)
    .typeError(messages.ONLY_NUMBERS),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
  viaType: yup.number().required('Debes seleccionar el tipo de vía'),
  viaNumber: yup.string().required(messages.REQUIRED_FIELD),
  cruceNumber: yup.string().required(messages.REQUIRED_FIELD),
  cornerMeters: yup.string().required(messages.REQUIRED_FIELD),
  postalCode: yup.string().required(messages.REQUIRED_FIELD),
});

// Validacion de los pasos del formulario crear conductor
export const DriverValidationStepOne = yup.object({
  documentType: yup.string().required('Debes seleccionar el tipo de documento'),
  document: yup
    .number()
    .min(100000, 'El documente debe tener al menos 6 números')
    .required(messages.REQUIRED_FIELD),
  firstName: yup.string().required(messages.REQUIRED_FIELD),
  surname: yup.string().required(messages.REQUIRED_FIELD),
  licenseNumber: yup
    .number()
    .min(100000, 'La licencia debe tener al menos 6 números')
    .required(messages.REQUIRED_FIELD),
});

export const DriverValidationStepTwo = yup.object({
  phone: yup
    .number()
    .min(1000000, 'El telefono fijo debe tener al menos 7 números')
    .typeError(messages.ONLY_NUMBERS),
  indicativeMobile: yup.number().required('Debes seleccionar el indicativo del pais'),
  mobile: yup
    .number()
    .min(1000000000, 'El celular debe tener al menos 10 números')
    .required(messages.REQUIRED_FIELD)
    .typeError(messages.ONLY_NUMBERS),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
});

export const DriverValidationStepThree = yup.object({
  viaType: yup.number().required('Debes seleccionar el tipo de vía'),
  viaNumber: yup.string().required(messages.REQUIRED_FIELD),
  cruceNumber: yup.string().required(messages.REQUIRED_FIELD),
  cornerMeters: yup.string().required(messages.REQUIRED_FIELD),
  postalCode: yup.string().required(messages.REQUIRED_FIELD),
});

export const DriverValidation = yup.object({
  firstName: yup.string().required(messages.REQUIRED_FIELD),
  surname: yup.string().required(messages.REQUIRED_FIELD),
  documentType: yup.string().required('Debes seleccionar el tipo de documento'),
  document: yup
    .number()
    .min(100000, 'El documente debe tener al menos 6 números')
    .required(messages.REQUIRED_FIELD),
  indicativeMobile: yup.number().required('Debes seleccionar el indicativo'),
  mobile: yup
    .number()
    .min(1000000000, 'El celular debe tener al menos 10 números')
    .required(messages.REQUIRED_FIELD)
    .typeError(messages.ONLY_NUMBERS),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
  licenseNumber: yup
    .number()
    .min(100000, 'La licencia debe tener al menos 6 números')
    .required(messages.REQUIRED_FIELD),
  viaType: yup.number().required('Debes seleccionar el tipo de vía'),
  viaNumber: yup.string().required(messages.REQUIRED_FIELD),
  cruceNumber: yup.string().required(messages.REQUIRED_FIELD),
  cornerMeters: yup.string().required(messages.REQUIRED_FIELD),
  postalCode: yup.string().required(messages.REQUIRED_FIELD),
})

// Validacion de los pasos del formulario crear vehículo
export const VehicleValidationStepOne = yup.object({
  licenseNumber: yup.string().matches(/[0-9]/, {
    message: "No se permiten caracteres especiales ni espacios",
  }),
  lettersPlate: yup.string().required(messages.REQUIRED_FIELD).matches(/[a-zA-Z]/, {
    message: "No se permiten caracteres especiales ni espacios",
  }),
  numberPlate:  yup.number().required(messages.REQUIRED_FIELD),
  country: yup.number().required(messages.REQUIRED_FIELD),
  departament: yup.number().required(messages.REQUIRED_FIELD),
  city: yup.number().required(messages.REQUIRED_FIELD),
  vehicleBrand: yup.string().required(messages.REQUIRED_FIELD),
  vehicleModel: yup.string().required(messages.REQUIRED_FIELD),
  color: yup.string().required(messages.REQUIRED_FIELD),
  vehicleType: yup.string().required(messages.REQUIRED_FIELD),
  service: yup.string().required(messages.REQUIRED_FIELD),
  numberFloors: yup.number().required(messages.REQUIRED_FIELD),
  loadType: yup.string().required(messages.REQUIRED_FIELD),
  capacity: yup.number().required(messages.REQUIRED_FIELD),
});

export const VehicleValidationStepTwo = yup.object({
  firstNameOwner: yup.string().required(messages.REQUIRED_FIELD),
  surnameOwner: yup.string().required(messages.REQUIRED_FIELD),
  documentType: yup.string().required('Debes seleccionar el tipo de documento'),
  documentOwner: yup.number().required(messages.REQUIRED_FIELD),
  indicativeMobile: yup.string().required('Debes seleccionar el tipo de documento'),
  mobile: yup.number().required(messages.REQUIRED_FIELD),
  email: yup.string().email(messages.INVALID_EMAIL_ADDRESS).required(messages.REQUIRED_FIELD),
});

export const VehicleValidation = yup.object({
  licenseNumber: yup.string().matches(/[0-9]/, {
    message: "No se permiten caracteres especiales ni espacios",
  }),
  lettersPlate: yup.string().required(messages.REQUIRED_FIELD).matches(/[a-zA-Z]/, {
    message: "No se permiten caracteres especiales ni espacios",
  }),
  numberPlate:  yup.number().required(messages.REQUIRED_FIELD),
  country: yup.number().required(messages.REQUIRED_FIELD),
  departament: yup.number().required(messages.REQUIRED_FIELD),
  city: yup.number().required(messages.REQUIRED_FIELD),
  vehicleBrand: yup.string().required(messages.REQUIRED_FIELD),
  vehicleModel: yup.string().required(messages.REQUIRED_FIELD),
  color: yup.string().required(messages.REQUIRED_FIELD),
  vehicleType: yup.string().required(messages.REQUIRED_FIELD),
  service: yup.string().required(messages.REQUIRED_FIELD),
  numberFloors: yup.number().required(messages.REQUIRED_FIELD),
  loadType: yup.string().required(messages.REQUIRED_FIELD),
  capacity: yup.number().required(messages.REQUIRED_FIELD),
  firstNameOwner: yup.string().required(messages.REQUIRED_FIELD),
  surnameOwner: yup.string().required(messages.REQUIRED_FIELD),
  documentType: yup.string().required('Debes seleccionar el tipo de documento'),
  documentOwner: yup.number().required(messages.REQUIRED_FIELD),
  indicativeMobile: yup.string().required('Debes seleccionar el indicativo del celular'),
  mobile: yup.number().required(messages.REQUIRED_FIELD),
  email: yup.string().email(messages.INVALID_EMAIL_ADDRESS).required(messages.REQUIRED_FIELD),
})

export const PutVehicleValidation = yup.object({
  typeService: yup.string().required('Debes seleccionar el tipo de servicio'),
  firstNameOwner: yup.string().required(messages.REQUIRED_FIELD),
  surnameOwner: yup.string().required(messages.REQUIRED_FIELD),
  documentType: yup.string().required('Debes seleccionar el tipo de documento'),
  documentOwner: yup.number().required(messages.REQUIRED_FIELD),
  indicativeMobile: yup.string().required('Debes seleccionar el indicativo del celular'),
  mobile: yup.number().required(messages.REQUIRED_FIELD),
  email: yup.string().email(messages.INVALID_EMAIL_ADDRESS).required(messages.REQUIRED_FIELD),
})

export const PutDriverValidation = yup.object({
  indicativeMobile: yup.number().required('Debes seleccionar el indicativo'),
  mobile: yup
    .number()
    .min(1000000000, 'El celular debe tener al menos 10 números')
    .required(messages.REQUIRED_FIELD)
    .typeError(messages.ONLY_NUMBERS),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
  viaType: yup.number().required('Debes seleccionar el tipo de vía'),
  viaNumber: yup.string().required(messages.REQUIRED_FIELD),
  cruceNumber: yup.string().required(messages.REQUIRED_FIELD),
  cornerMeters: yup.string().required(messages.REQUIRED_FIELD),
  postalCode: yup.string().required(messages.REQUIRED_FIELD),
})

export const SelectVehicleValidation = yup.object({
  selectBusiness: yup
  .string()
  .required('Debes seleccionar una empresa de transporte'),
})

export const SelectDriverValidation = yup.object({
  firstName: yup.string().required(messages.REQUIRED_FIELD),
  secondName: yup.string(),
  surname: yup.string().required(messages.REQUIRED_FIELD),
  secondSurname: yup.string(),
  documentType: yup.number().required('Debes seleccionar el tipo de documento'),
  document: yup
    .number()
    .min(100000, 'El documente debe tener al menos 6 números')
    .required(messages.REQUIRED_FIELD),
  indicative: yup.number().required('Debes seleccionar el indicativo'),
  mobile: yup
    .number()
    .min(1000000000, 'El celular debe tener al menos 10 números')
    .required(messages.REQUIRED_FIELD)
    .typeError(messages.ONLY_NUMBERS),
  email: yup
    .string()
    .email(messages.INVALID_EMAIL_ADDRESS)
    .required(messages.REQUIRED_FIELD),
  licenseNumber: yup
    .number()
    .min(10000000, 'La licencia debe tener al menos 8 números')
    .required(messages.REQUIRED_FIELD),
})

export const SearchDriverValidation = yup.object({
  document: yup
    .number()
    .min(100000, 'El documento debe tener al menos 6 números')
    .required(messages.REQUIRED_FIELD)
})

export const SearchVehicleValidation = yup.object({
  licensePlate: yup
    .string()
    .required(messages.REQUIRED_FIELD)
})
