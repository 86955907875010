import React, { useState, useEffect } from 'react';
import { Typography, Grid, Tooltip, Fab, Box, Button } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import domtoimage from 'dom-to-image';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import FilterReports from 'components/FilterReports';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import CurrentDateIndicator from 'components/CurrentDateIndicator';
import Chart from 'components/Chart';
import ItemListData from 'components/ItemListData';
import Results from 'components/Results';

import {
  ReportFilter,
  MyDocRangeVisceras,
  GraphRangeVisceras,
  GraphRangeViscerasTwo,
} from './../../Reports/ReportFilter';

import useStyles from './styles';
import DialogPDFViewer from './../../../../../../components/DialogPDFViewer/index';

const index = () => {
  const classes = useStyles();

  const [openFilterVisceras, setOpenFilterVisceras] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');
  const [imageGraphTwo, setImageGraphTwo] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenQrvisceras');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraph = () => {
    const graph: any = document.getElementById('graphrangevisceras');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraphTwo = () => {
    const graph: any = document.getElementById('graphrangeviscerastwo');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraphTwo(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeVisceras = () => {
    setOpenFilterVisceras(true);
  };

  useEffect(() => {
    generateScreenshot();
    screenshotGraph();
    screenshotGraphTwo();
  }, [openFilterVisceras]);

  const columns: GridColDef[] = [
    {
      field: 'nroLote',
      headerName: 'Nro. Lote',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalCanales',
      headerName: 'Total Canales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalVisceras',
      headerName: 'Total Vísceras',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'viscerasDecomisadas',
      headerName: 'Vísceras Decomisadas',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'víscerasAprobadas',
      headerName: 'Vísceras Aprobadas',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'productor',
      headerName: 'Productor',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: '',
      headerName: '',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/viscera/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      nroLote: '654372',
      totalCanales: '15',
      totalVisceras: '58',
      viscerasDecomisadas: '6',
      víscerasAprobadas: '6',
      productor: 'Nombre Productor',
    },
    {
      id: 2,
      nroLote: '654372',
      totalCanales: '22',
      totalVisceras: '58',
      viscerasDecomisadas: '6',
      víscerasAprobadas: '6',
      productor: 'Nombre Productor',
    },
    {
      id: 3,
      nroLote: '654372',
      totalCanales: '12',
      totalVisceras: '58',
      viscerasDecomisadas: '6',
      víscerasAprobadas: '6',
      productor: 'Nombre Productor',
    },
    {
      id: 4,
      nroLote: '654372',
      totalCanales: '15',
      totalVisceras: '58',
      viscerasDecomisadas: '6',
      víscerasAprobadas: '6',
      productor: 'Nombre Productor',
    },
    {
      id: 5,
      nroLote: '654372',
      totalCanales: '22',
      totalVisceras: '58',
      viscerasDecomisadas: '6',
      víscerasAprobadas: '6',
      productor: 'Nombre Productor',
    },
    {
      id: 6,
      nroLote: '654372',
      totalCanales: '13',
      totalVisceras: '58',
      viscerasDecomisadas: '6',
      víscerasAprobadas: '6',
      productor: 'Nombre Productor',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/viscera',
      title: 'Vísceras Blancas',
    },

    { link: '/app/reports', title: 'Mis Reportes' },
  ];

  const initDate = dayjs().startOf('month').format('YYYY-MM-DDT00:00:00-05:00');
  const current = dayjs().format('YYYY-MM-DDT23:59:59-05:00');
  const currentR = dayjs().format('DD/MM/YYYY');

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportFilter />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphRangeVisceras />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphRangeViscerasTwo />
      </div>
      <div className={classes.breadContainer}>
        <CurrentDateIndicator initialDate={initDate} finalDate={current} />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <FilterReports to="/app/reports/viscera/search" />

      <Grid container spacing={2} direction="row">
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Resumen del día
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.paddingContainer
                  )}
                >
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={200}
                    titleInfo="Total Vísceras Blancas"
                    avatarTotalColor="#FFC676"
                    avatarTotalSize={90}
                    avatarTextSize={30}
                    titleInfoSize={120}
                  />
                  <div>
                    <ItemListData
                      data={[
                        {
                          title: 'Total Canales',
                          data: '80',
                        },

                        {
                          title: 'Canales con Decomisos Vísceras Blancas',
                          data: '23',
                        },

                        {
                          title: 'Lotes Diferentes',
                          data: '20',
                        },
                        {
                          title: 'Productores Diferentes',
                          data: '20',
                        },
                      ]}
                    />
                  </div>
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Decomisos por Productor
                  </Typography>
                </div>

                <Chart
                  type="doughnut"
                  infoTotalData="Vísceras"
                  graphicSize={180}
                  data={[
                    {
                      label: 'Nombre del Productor',
                      data: 148,
                      bg: '#BE5948',
                    },
                    {
                      label: 'Nombre del productor',
                      data: 98,
                      bg: '#D47D57',
                    },
                    {
                      label: 'Nombre del Productor',
                      data: 25,
                      bg: '#FFC676',
                    },
                  ]}
                />
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Resumen Decomisos Vísceras Blancas
                  </Typography>
                </div>

                <Chart
                  type="doughnut"
                  infoTotalData="Aprobadas"
                  graphicSize={180}
                  data={[
                    {
                      label: 'Decomisadas',
                      data: 165,
                      bg: '#D47D57',
                    },
                    {
                      label: 'Aprobadas',
                      data: 35,
                      bg: '#FFC676',
                    },
                  ]}
                />
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Origen Decomisos
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.paddingContainer
                  )}
                >
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={10}
                    titleInfo="Decomisados en SBE"
                    avatarTotalColor="#D47D57"
                    avatarTotalSize={35}
                    avatarTextSize={22}
                    extended
                    titleInfoSize={200}
                  />
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={18}
                    titleInfo="Decomisados en Línea de Beneficios"
                    avatarTotalColor="#E29662"
                    avatarTotalSize={35}
                    avatarTextSize={22}
                    extended
                    titleInfoSize={200}
                  />
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.flexSpacing}>
                  <Typography className={classes.paperTitles}>
                    Reporte Vísceras Blancas del Día
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={openModalRangeVisceras}
                  >
                    Ver
                  </Button>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ContentCard>
            <div className={classes.headerContent}>
              <div>
                <Typography className={classes.paperTitles}>
                  Detalle Inspección Vísceras Blancas del Día
                </Typography>
              </div>
              <div>
                <Results number={rows.length} />
              </div>
            </div>
            <div>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
              />
            </div>
          </ContentCard>
        </Grid>
      </Grid>
      <DialogPDFViewer
        open={openFilterVisceras}
        onClose={() => setOpenFilterVisceras(false)}
        done={Boolean(imageQr)}
      >
        <MyDocRangeVisceras
          qr={imageQr}
          graph={imageGraph}
          graphTwo={imageGraphTwo}
          range={currentR}
        />
      </DialogPDFViewer>
    </div>
  );
};

export default index;
