import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    textAlign: 'center',
    padding: 30,
    fontSize: 8,
    fontFamily: 'Roboto',
    fontWeight: 300,
  },
  tableMagro: {
    display: 'table',
    width: '48.3%',
    borderStyle: 'solid',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '16.5%',
  },
  tableCell: {
    marginTop: 5,
    fontSize: 8,
    textAlign: 'center',
  },
  tableHead: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCellPost: {
    height: '13px',
    fontSize: 8,
    marginTop: 5,
    textAlign: 'center',
  },
  conventions: {
    marginTop: 30,
  },
  backgroundguide: {
    backgroundColor: '#ededed',
    height: 30,
    width: '75%',
    margin: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  textconventionsLong: {
    flex: 2,
    flexDirection: 'row',
    flexGrow: 4,
    marginTop: 3,
  },

  gsmi: {
    top: 8,
    width: 25,
    height: 20,
  },

  textLong: {
    width: 90,
    top: 8,
    height: 20,
  },
  textSLong: {
    width: 100,
    top: 8,
    height: 20,
  },
  textShort: {
    width: 30,
    top: 8,
    height: 20,
  },
  textconventionsShort: {
    flex: 2,
    flexDirection: 'row',
    flexGrow: 2,
    marginTop: 3,
  },

  borderAM: {
    border: '1 solid #EDEDED',
    width: 70,
    height: 20,
    justifyContent: 'center',
    margin: 'auto',
    backgroundColor: '#ffffff',
    top: -45,
    position: 'relative',
    borderRadius: 3,
  },
});
