import React, { useState, useEffect } from 'react';

import {
  TextField,
  Box,
  Grid,
  Button,
  Card,
  CardActionArea,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { WhatsApp, Telegram } from '@material-ui/icons';

import MainTitle from 'components/MainTitle';
import PhoneNumber from 'components/PhoneNumber';
import DocumentType from 'components/DocumentTypes';
import Direction from 'components/Direction';
import Slider from 'components/Slider';
import ExistingUser from 'components/ExistingUser';
import Schedule from 'components/Schedule';

import { DESTINATION_MANAGER } from 'core/graphql/queries/profile';
import { UPDATE_DESTINY } from 'core/graphql/mutations/profile';
import { DestinyEdit } from 'lib/validations/Profile';
import unmaskPhone from 'lib/helpers/unmaskPhone';
import constants from 'config/constants';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import useStyles from './styles';

const Edit = ({ data }: any) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const path = useLocation();
  let idDestiny: any = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState(0);
  const [select, setSelect] = useState(0);
  const typeDestiny = Number(data?.idTipoDestino);
  const [valuesAtencion, setValuesAtencion] = useState<any>([]);
  const [valuesRestricciones, setValuesRestricciones] = useState<any>([]);
  const [horarios, setHorarios] = useState<any>([]);

  const type = () => {
    switch (typeDestiny) {
      case 1:
        return 'Punto de Venta';
      case 2:
        return 'Planta de Desposte';
      case 3:
        return 'Planta de Acondicionamiento';
      default:
        return '';
    }
  };

  const {
    data: dataUsers,
    loading,
    refetch,
  } = useQuery(DESTINATION_MANAGER, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idPersonaResponsable: data?.idPersonaResponsable },
  });
  const [updateData, { loading: loadingUpdate }] = useMutation(UPDATE_DESTINY);

  useEffect(() => {
    refetch();
  }, [path]);

  useEffect(() => {
    const atencion = valuesAtencion.map((x: any) => {
      return {
        ...x,
        horaInicial: dayjs(x.horaInicial).format('HH:mm:ssZ'),
        horaFinal: dayjs(x.horaFinal).format('HH:mm:ssZ'),
      };
    });
    const restriccion = valuesRestricciones.map((x: any) => {
      return {
        ...x,
        horaInicial: dayjs(x.horaInicial).format('HH:mm:ssZ'),
        horaFinal: dayjs(x.horaFinal).format('HH:mm:ssZ'),
      };
    });

    setHorarios(
      [...atencion, ...restriccion]
        .filter((items: any) => delete items.__typename)
        .filter((item: any) => item.id)
        .filter((items: any) => delete items.id)
    );
  }, [valuesAtencion, valuesRestricciones]);

  const handleChangeSelect = (i: number) => {
    setSelect(i);
    formik.setFieldValue('idPersonaResponsable', i);
  };

  const handleChangeForm = (type: number) => {
    setForm(type);
    if (type === 0) {
      formik.setFieldValue('idPersonaResponsable', data?.idPersonaResponsable);
      formik.setFieldValue('primerNombre', data?.primerNombre);
      formik.setFieldValue('segundoNombre', data?.segundoNombre);
      formik.setFieldValue('primerApellido', data?.primerApellido);
      formik.setFieldValue('segundoApellido', data?.segundoApellido);
      formik.setFieldValue('numeroDocumento', data?.numeroDocumento);
      formik.setFieldValue('emailResponsable', data?.emailResponsable);
    } else if (type === 1) {
      formik.setFieldValue('idPersonaResponsable', 0);
      formik.setFieldValue('primerNombre', '');
      formik.setFieldValue('segundoNombre', '');
      formik.setFieldValue('primerApellido', '');
      formik.setFieldValue('segundoApellido', '');
      formik.setFieldValue('numeroDocumento', '');
      formik.setFieldValue('emailResponsable', '');
    } else formik.setFieldValue('idPersonaResponsable', select);
  };

  const formik = useFormik({
    initialValues: {
      ...data,
      idTipoDestino: Number(data.idTipoDestino),
      nit: data.nit ?? '',
      idPersonaResponsable: Number(data?.idPersonaResponsable),
      coords: data?.direccion != '' || data?.latitud != '' ? 2 : 1,
      idIndicativoTelefono:
        data?.idIndicativoTelefono != 0
          ? data?.idIndicativoTelefono
          : constants.DEFAULT_DEPARTMENT_ID,
      idIndicativoWhatsapp:
        data?.idIndicativoWhatsapp != 0
          ? data?.idIndicativoWhatsapp
          : constants.DEFAULT_COUNTRY_ID,
      idIndicativoTelegram:
        data?.idIndicativoTelegram != 0
          ? data?.idIndicativoTelegram
          : constants.DEFAULT_COUNTRY_ID,
    },
    enableReinitialize: true,
    validationSchema: DestinyEdit,
    onSubmit: (values: any) => {
      delete values.__typename;
      const coords = values.coords;
      const directionType = Number(values.tipoDireccion);
      updateData({
        variables: {
          idDestino: Number(idDestiny.id),
          codigoDestino: values.codigoDestino,
          nombreDestino: values.nombreDestino,
          idPersonaResponsable: values.idPersonaResponsable,
          idTipoDocumento: Number(values.idTipoDocumento),
          primerNombre: values.primerNombre,
          segundoNombre: values.segundoNombre,
          primerApellido: values.primerApellido,
          segundoApellido: values.segundoApellido,
          emailResponsable: values.emailResponsable,
          idCiudad: Number(values.idCiudad),
          estrato: values.estrato,
          numeroTelefono: values.numeroTelefono ?? '',
          extensionTelefono: values.extensionTelefono ?? '',
          numeroCelular: unmaskPhone(values.numeroCelular),
          numeroWhatsapp: values.numeroWhatsapp
            ? unmaskPhone(values.numeroWhatsapp)
            : '',
          numeroTelegram: values.numeroTelegram
            ? unmaskPhone(values.numeroTelegram)
            : '',
          email: values.email,
          idTipoDestino: Number(data.idTipoDestino),
          numeroDocumento: Number(values.numeroDocumento),
          indicativoTelefono: values.numeroTelefono
            ? Number(values.idIndicativoTelefono)
            : 0,
          indicativoCelular: Number(values.idIndicativoCelular),
          indicativoWhatsapp: values.numeroWhatsapp
            ? Number(values.idIndicativoWhatsapp)
            : 0,
          indicativoTelegram: values.numeroTelegram
            ? Number(values.idIndicativoTelegram)
            : 0,
          tipoDireccion: coords === 1 ? directionType : 2,
          idTipoVia:
            coords === 1 ? (directionType === 1 ? values.idTipoVia : 1) : 1,
          numeroVia:
            coords === 1
              ? directionType === 1
                ? Number(values.numeroVia)
                : 0
              : 0,
          apendiceVia:
            coords === 1 ? (directionType === 1 ? values.apendiceVia : '') : '',
          numeroCruce:
            coords === 1
              ? directionType === 1
                ? Number(values.numeroCruce)
                : 0
              : 0,
          apendiceCruce:
            coords === 1
              ? directionType === 1
                ? values.apendiceCruce
                : ''
              : '',
          metrosEsquina:
            coords === 1
              ? directionType === 1
                ? Number(values.metrosEsquina)
                : 0
              : 0,
          adicional: coords === 1 ? values.adicional : '',
          codigoPostal:
            coords === 1
              ? directionType === 1
                ? values.codigoPostal
                : ''
              : '',
          latitud: coords === 1 ? '' : values.latitud,
          longitud: coords === 1 ? '' : values.longitud,
          direccion: coords === 1 ? '' : values.direccion,
          horariosDestino: horarios,
        },
        context: { headers: { 'x-auth-jwt': token } },
      })
        .then((data) => {
          enqueueSnackbar(`Datos actualizados exitosamente`, {
            variant: 'success',
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
        })
        .catch((error) => {
          const message = error.message.split(':');
          const field = message[0];
          const value = message[1];
          enqueueSnackbar(value, {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
          formik.setStatus(formik.setErrors({ [field ?? '']: value }));
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={classes.container}>
        <Grid container xs={12}>
          <Box marginTop={2} width="100%">
            <MainTitle>Datos {type()}</MainTitle>
            <Grid
              item
              container
              spacing={2}
              xs={12}
              className={classes.gridContainer}
            >
              <Grid item xs={4}>
                <TextField
                  label={`Código ${type()}`}
                  size="small"
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.codigoDestino}
                  name="codigoDestino"
                  helperText={formik.errors.codigoDestino}
                  error={!!formik.errors.codigoDestino}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={`Nombre ${type()}`}
                  size="small"
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.nombreDestino}
                  name="nombreDestino"
                  helperText={formik.errors.nombreDestino}
                  error={!!formik.errors.nombreDestino}
                  required
                />
              </Grid>
              {typeDestiny === 2 || typeDestiny === 3 ? (
                <>
                  <Grid item xs={2}>
                    <TextField
                      label="NIT"
                      required
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="nit"
                      value={formik.values.nit}
                      onChange={formik.handleChange}
                      helperText={formik.errors.nit}
                      error={Boolean(formik.errors.nit)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label="Dig. Verificación"
                      variant="outlined"
                      size="small"
                      type="number"
                      name="digitoVerificacion"
                      value={formik.values.digitoVerificacion}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.digitoVerificacion)}
                      helperText={formik.errors.digitoVerificacion}
                      required
                      disabled
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={4}>
                <TextField
                  label="Correo Electrónico"
                  required
                  size="small"
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                  helperText={formik.errors.email}
                  error={!!formik.errors.email}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={2} width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginY={2}
            >
              <MainTitle>Datos Responsable {type()}</MainTitle>
              <Box display="flex">
                {form === 0 ? (
                  <>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => handleChangeForm(1)}
                    >
                      Registrar Nuevo
                    </Button>
                    {dataUsers ? (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleChangeForm(2)}
                        >
                          Seleccionar Existente
                        </Button>
                      </>
                    ) : loading ? (
                      <CircularProgress size={20} />
                    ) : null}
                  </>
                ) : form === 1 ? (
                  <>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => handleChangeForm(0)}
                    >
                      Descartar
                    </Button>
                    {dataUsers ? (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleChangeForm(2)}
                        >
                          Seleccionar Existente
                        </Button>
                      </>
                    ) : null}
                  </>
                ) : form === 2 ? (
                  <>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => handleChangeForm(0)}
                    >
                      Descartar
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => handleChangeForm(1)}
                    >
                      Registrar Nuevo
                    </Button>
                  </>
                ) : null}
              </Box>
            </Box>
            <Box marginY={2}>
              {form === 0 || form === 1 ? (
                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  className={classes.gridContainer}
                >
                  <Grid item xs={3}>
                    <TextField
                      label="Primer Nombre"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="primerNombre"
                      value={formik.values.primerNombre}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.primerNombre)}
                      helperText={formik.errors.primerNombre}
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Segundo Nombre"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="segundoNombre"
                      value={formik.values.segundoNombre}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.segundoNombre)}
                      helperText={formik.errors.segundoNombre}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Primer Apellido"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="primerApellido"
                      value={formik.values.primerApellido}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.primerApellido)}
                      helperText={formik.errors.primerApellido}
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Segundo Apellido"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="segundoApellido"
                      value={formik.values.segundoApellido}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.segundoApellido)}
                      helperText={formik.errors.segundoApellido}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DocumentType
                      onChange={formik.handleChange}
                      value={formik.values.idTipoDocumento}
                      name="idTipoDocumento"
                      entity={1}
                      disable={form === 0}
                      errors={formik.errors.idTipoDocumento as string}
                      helperText={formik.errors.idTipoDocumento as string}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Número de documento"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="numeroDocumento"
                      value={formik.values.numeroDocumento}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.numeroDocumento)}
                      helperText={formik.errors.numeroDocumento}
                      disabled={form === 0}
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Correo Electrónico"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="emailResponsable"
                      value={formik.values.emailResponsable}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.emailResponsable)}
                      helperText={formik.errors.emailResponsable}
                      required
                    />
                  </Grid>
                </Grid>
              ) : (
                dataUsers && (
                  <Slider>
                    {dataUsers?.getDestinationManagerXUser?.data?.map(
                      (i: any) => (
                        <ExistingUser
                          name={i?.nombreCompleto}
                          info={i?.numeroDocumento}
                          selected={select === i?.idPersonaResponsable}
                          onClick={() =>
                            handleChangeSelect(i?.idPersonaResponsable)
                          }
                          column
                        />
                      )
                    )}
                    <Grid>
                      <Card
                        className={classes.card}
                        onClick={() => handleChangeForm(1)}
                      >
                        <CardActionArea className={classes.content}>
                          <i className="icon-plus" />
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Slider>
                )
              )}
            </Box>
          </Box>
          <Box marginTop={2} width="100%">
            <MainTitle>Datos de Ubicación {type()}</MainTitle>
            <Grid
              container
              spacing={2}
              xs={12}
              className={classes.gridContainer}
            >
              <Grid item xs={12}>
                <Direction
                  withCoordinates
                  names={{
                    directionType: 'tipoDireccion',
                    viaType: 'idTipoVia',
                    viaNumber: 'numeroVia',
                    apendNumber: 'apendiceVia',
                    cruceNumber: 'numeroCruce',
                    apendCruceNumber: 'apendiceCruce',
                    cornerMeters: 'metrosEsquina',
                    aditional: 'adicional',
                    postalCode: 'codigoPostal',
                    idPais: 'idPais',
                    idDepartamento: 'idDepartamento',
                    city: 'idCiudad',
                    latitude: 'latitud',
                    longitude: 'longitud',
                    direction: 'direccion',
                    coords: 'coords',
                  }}
                  values={{
                    directionType: formik.values.tipoDireccion,
                    viaType: formik.values.idTipoVia,
                    viaNumber: formik.values.numeroVia,
                    apendNumber: formik.values.apendiceVia,
                    cruceNumber: formik.values.numeroCruce,
                    apendCruceNumber: formik.values.apendiceCruce,
                    cornerMeters: formik.values.metrosEsquina,
                    aditional: formik.values.adicional,
                    postalCode: formik.values.codigoPostal,
                    idPais: Number(formik.values.idPais),
                    idDepartamento: Number(formik.values.idDepartamento),
                    city: Number(formik.values.idCiudad),
                    latitude: formik.values.latitud,
                    longitude: formik.values.longitud,
                    direction: formik.values.direccion,
                    coords: formik.values.coords,
                  }}
                  errors={{
                    directionType: formik.errors.tipoDireccion as string,
                    viaType: formik.errors.idTipoVia as string,
                    viaNumber: formik.errors.numeroVia as string,
                    apendNumber: formik.errors.apendiceVia as string,
                    cruceNumber: formik.errors.numeroCruce as string,
                    apendCruceNumber: formik.errors.apendiceCruce as string,
                    cornerMeters: formik.errors.metrosEsquina as string,
                    aditional: formik.errors.adicional as string,
                    postalCode: formik.errors.codigoPostal as string,
                    idPais: formik.errors.idPais as string,
                    idDepartamento: formik.errors.idDepartamento as string,
                    city: formik.errors.idCiudad as string,
                    latitude: formik.errors.latitud as string,
                    longitude: formik.errors.longitud as string,
                    direction: formik.errors.direccion as string,
                  }}
                  setFieldValue={formik.setFieldValue}
                  handleChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={2} width="100%">
            <MainTitle>Datos de Contacto {type()}</MainTitle>
            <Grid
              item
              container
              spacing={2}
              xs={12}
              className={classes.gridContainer}
            >
              <Grid item xs={6} xl={4}>
                <PhoneNumber
                  type="mobile"
                  names={{
                    indicative: 'idIndicativoCelular',
                    phone: 'numeroCelular',
                  }}
                  values={{
                    indicative: formik.values.idIndicativoCelular,
                    phone: (formik.values.numeroCelular = unmaskPhone(
                      formik.values.numeroCelular
                    )),
                  }}
                  onChange={formik.handleChange}
                  errors={{
                    indicative: !!formik.errors.idIndicativoCelular,
                    phone: formik.errors.numeroCelular as string,
                  }}
                  helperTexts={{
                    indicative: formik.errors.idIndicativoCelular as string,
                    phone: formik.errors.numeroCelular as string,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={6} xl={4}>
                <PhoneNumber
                  type="landline"
                  names={{
                    indicative: 'idIndicativoTelefono',
                    phone: 'numeroTelefono',
                    ext: 'extensionTelefono',
                  }}
                  values={{
                    indicative: formik.values.idIndicativoTelefono,
                    phone: formik.values.numeroTelefono ?? 0,
                    ext: formik.values.extensionTelefono ?? '',
                  }}
                  onChange={formik.handleChange}
                  errors={{
                    indicative: !!formik.errors.idIndicativoTelefono,
                    phone: formik.errors.numeroTelefono as string,
                  }}
                  helperTexts={{
                    indicative: formik.errors.idIndicativoTelefono as string,
                    phone: formik.errors.numeroTelefono as string,
                  }}
                  extension
                />
              </Grid>
              <Grid item xs={6} xl={4}>
                <PhoneNumber
                  type="mobile"
                  labelIndicative="Ind. WhatsApp"
                  labelInput="WhatsApp"
                  names={{
                    indicative: 'idIndicativoWhatsapp',
                    phone: 'numeroWhatsapp',
                  }}
                  values={{
                    indicative: formik.values.idIndicativoWhatsapp,
                    phone: formik.values.numeroWhatsapp,
                  }}
                  onChange={formik.handleChange}
                  errors={{
                    indicative: !!formik.errors.idIndicativoWhatsapp,
                    phone: formik.errors.numeroWhatsapp as string,
                  }}
                  helperTexts={{
                    indicative: formik.errors.idIndicativoWhatsapp as string,
                    phone: formik.errors.numeroWhatsapp as string,
                  }}
                  icon={<WhatsApp />}
                />
              </Grid>
              <Grid item xs={6} xl={4}>
                <PhoneNumber
                  type="mobile"
                  labelIndicative="Ind. Telegram"
                  labelInput="Telegram"
                  names={{
                    indicative: 'idIndicativoTelegram',
                    phone: 'numeroTelegram',
                  }}
                  values={{
                    indicative: formik.values.idIndicativoTelegram,
                    phone: formik.values.numeroTelegram,
                  }}
                  onChange={formik.handleChange}
                  errors={{
                    indicative: !!formik.errors.idIndicativoTelegram,
                    phone: formik.errors.numeroTelegram as string,
                  }}
                  helperTexts={{
                    indicative: formik.errors.idIndicativoTelegram as string,
                    phone: formik.errors.numeroTelegram as string,
                  }}
                  icon={<Telegram />}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={2} width="100%">
            <MainTitle> Horario de Atención </MainTitle>
            <Grid item xs={12}>
              <Box marginY={2}>
                <Schedule data={formik.values.horariosDestino} type={1}>
                  {({ allValues }: any) => {
                    setValuesAtencion(allValues);
                  }}
                </Schedule>
              </Box>
            </Grid>
          </Box>
          <Box marginTop={2} width="100%">
            <MainTitle> Horario Cargue / Descargue </MainTitle>
            <Grid item xs={12}>
              <Box marginY={2}>
                <Schedule data={formik.values.horariosDestino} type={2}>
                  {({ allValues }: any) => {
                    setValuesRestricciones(allValues);
                  }}
                </Schedule>
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Box width="100%" display="flex" justifyContent="center" marginY={3}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            endIcon={
              loadingUpdate && (
                <CircularProgress style={{ color: '#FFF' }} size={20} />
              )
            }
          >
            {loadingUpdate ? 'Actualizando ' : 'Actualizar'}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default Edit;
