import Main from "./Main";
import Details from "./Details";
import RegisterUsers from "./Register";
import DetailsActivity from "./Details/ActivityRegister/index";
import { NestedRoute } from "lib/interfaces/routes";

const routes: NestedRoute[] = [
  {
    route: "/register-user",
    Node: RegisterUsers,
    exact: true,
  },
  {
    route: "/details/activity/:id/:type/:initialDate/:finalDate",
    Node: DetailsActivity,
    exact: true,
  },
];

export { Main, Details, routes };
