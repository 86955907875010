import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';
import LicensePlate from '../../LicensePlate';

interface cardProps {
  plate: string;
  ubication: string;
  vehicleType: string;
  capacity: string;
  checked?: boolean;
  width?: number;
  height?: number;
  onClick?: (event: any) => void;
}

const CardSelectVehicle = ({
  plate,
  ubication,
  vehicleType,
  capacity,
  checked,
  width,
  height,
  onClick,
}: cardProps) => {
  const classes = useStyles();

  return (
    <>
      <Card
        elevation={2}
        className={clsx({
          [classes.cardItem]: true,
          [classes.cardSelected]: checked,
        })}
        style={{
          width: width ?? 250,
          height: height ?? 235,
        }}
      >
        <CardActionArea className={classes.cardActionArea} onClick={onClick}>
          <CardContent>
            <Grid container justifyContent="center">
              <LicensePlate
                placa={plate}
                ciudad={ubication}
                width={180}
                checked
              />
            </Grid>

            <Box mt={2}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.dataTitle}>
                    Tipo de Vehículo
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataInfo}>
                    {vehicleType}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box mt={1}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.dataTitle}>
                    Capacidad y Tipo de Carga
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataInfo}>
                    {capacity}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default CardSelectVehicle;
