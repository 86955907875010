import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: 16,
    fontWeight: 'bolder',
  },

  dialogContent: {
    padding: '8px 20px',

    '& .MuiDialog-paperWidthSm': {
      borderRadius: '20px',
    },
  },

  grayContainer: {
    background: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
  },

  totalLotes: {
    width: '100%',
    marginTop: 20,
  },

  firstSquare: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  label: {
    color: '#AFAFAF',
    fontSize: 14,
  },

  labelBold: {
    color: '#767676',
    fontWeight: 'bolder',
    fontSize: 16,
  },

  spaceEvenly: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  FlexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  paddings: {
    padding: '10px 15px',
  },

  levelContainer: {
    height: 72,
    padding: '5px 10px',
  },

  middleWidth1: {
    width: '50%',
    '& .MuiListItem-root': {
      width: '100% !important',
    },
  },

  middleWidth: {
    width: '50%',
    '& .MuiListItem-root': {
      width: '100% !important',
    },

    '& .MuiSvgIcon-root': {
      color: '#AFAFAF',
    },

    '& circle': {
      r: '5 !important',
    },

    '& .makeStyles-listNumber-145': {
      color: '#68CE70 !important',
    },
  },

  fullWidht: {
    width: '100%',
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  secondsTitle: {
    fontSize: 14,
    fontWeight: 'bolder',
    color: '#AFAFAF',
  },

  saveButtonModal: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: '#DD9835',
    borderRadius: 25,
    margin: '0px 10px 0px 10px',
    padding: '5px 30px 5px 30px',
    '&:hover': {
      backgroundColor: '#dd9835',
    },
  },

  viewButton: {
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      color: '#fff',
    },
  },

  buttonsModalStart: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 16px 16px 16px',
  },

  dataGrid: {
    border: 'none',
    display: 'flex',

    '& .MuiBox-root': {
      margin: '20px 0',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-colCellTitle': {
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal',
      color: '#AFAFAF',
      fontWeight: 'bold',
      fontSize: 14,
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 1,
    },
    '& .MuiTablePagination-root': {
      display: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
  },
}));

export { useStyles as default };
