import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { height } from 'styled-system';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5%',
    paddingTop: ' 2.5%',
    maxWidth: 'inherit',
  },

  breadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0',
  },

  subtitleTitle: {
    color: '#767676',
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Roboto',
    paddingBottom: 20,
  },

  contentCard: {
    width: '100%',
  },

  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 0px 10px 0px',
  },

  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  flexContainerSpaced: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
  },

  centerContainerColumn: {
    flexDirection: 'column',
  },

  iconPadding: {
    margin: '10px 0px',
  },

  chartContainer: {
    padding: '15px 10px',
  },

  subtitleTitleResume: {
    color: '#767676',
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Roboto',
  },

  containerItemGrid: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  icon: {
    fontSize: 30,
    padding: 5,
    color: 'white',
    background: theme.palette.primary.main,
    borderRadius: '100%',
    marginBottom: 5,
  },

  iconResume: {
    fontSize: 30,
    padding: 4,
    color: 'white',
    background: theme.palette.primary.main,
    borderRadius: '100%',
    marginRight: 20,
  },

  labelTitle: {
    color: '#AFAFAF',
    fontSize: 16,
    fontFamily: 'Roboto',
  },

  labelTitleResume: {
    color: '#AFAFAF',
    fontSize: 16,
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
  },

  labelNumber: {
    color: '#767676',
    fontSize: 30,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },

  labelNumberBar: {
    color: '#767676',
    fontSize: 20,
    fontFamily: 'Roboto',
  },

  titleBoxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  dateResume: {
    color: '#DD9835',
    border: '1px solid #DD9835',
    borderRadius: 20,
    padding: '2px 15px 2px 15px',
  },

  containerButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  buttonDashboard: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: '#DD9835',
    borderRadius: 25,
    padding: '5px 30px 5px 30px',
    '&:hover': {
      backgroundColor: '#dd9835',
    },
  },

  containerMeditionProcess: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px 40px 20px 40px',
    [theme.breakpoints.down('lg')]: {
      padding: '15px 5px 0px 5px',
      width: '90%',
    },
  },
}));

export default useStyles;
