import React, { useEffect, useState } from "react";

import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";

import Terms from "components/PageComponents/TYC/terms";
import HabeasData from "components/PageComponents/TYC/habeasData";
import Certificate from "components/PageComponents/TYC/certificate";
import PersonalData from "components/PageComponents/TYC/personalData";
import Conventions from "components/PageComponents/Profile/Conventions";
import DialogAuthorization from "components/PageComponents/TYC/dialogCancelAuthorization";
import CertificatePDF from "./certificate";

import { LEGAL_DATA } from "core/graphql/queries/profile";
import documentTypeById from "lib/helpers/documentTypeById";

import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";

import useStyles from "./styles";

const LegalDocuments = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const entity = useSelector((state: any) => state.user.user.typeEntity);
  const { pathname: path } = useLocation();
  const separated = path.split("/");
  const { data, loading, refetch } = useQuery(LEGAL_DATA, {
    context: { headers: { "x-auth-jwt": token } },
  });

  useEffect(() => {
    refetch();
  }, [path]);

  const userType = useSelector(
    (state: any) => state.user.selectedUserType.name
  );

  const certificateData = {
    name: data?.getLegalData?.data?.nombreCompleto,
    document: `${documentTypeById(
      data?.getLegalData?.data?.idTipoDocumento,
      1
    )} - ${data?.getLegalData?.data?.numeroDocumento}${
      entity !== "person"
        ? `-${data?.getLegalData?.data?.digitoIdentificador}`
        : ""
    }`,
    nameRL: data?.getLegalData?.data?.nombreCompletoRL,
    documentRL: `${documentTypeById(
      data?.getLegalData?.data?.tipoDocumentoRL,
      1
    )} - ${data?.getLegalData?.data?.numeroDocumentoRL}`,
    direction: data?.getLegalData?.data?.direccion,
    cellphone: data?.getLegalData?.data?.celular,
    email: data?.getLegalData?.data?.email,
    username: data?.getLegalData?.data?.username,
    userType: userType,
    registerDate: data?.getLegalData?.data?.fechaRegistro,
    entity: entity,
  };

  const type = () => {
    switch (separated[3]) {
      case "terms":
        return "Términos de Uso del sitio web";
      case "privacy-policies":
        return "Privacidad y Tratamiento de Datos Personales";
      case "titularidad-datos":
        return "Declaración Sobre Titularidad Datos Personales";
      case "autorizacion-datos":
        return "Autorización de Datos Personales";
      default:
        return "";
    }
  };

  const component = () => {
    switch (separated[3]) {
      case "terms":
        return <Terms />;
      case "privacy-policies":
        return <PersonalData />;
      case "titularidad-datos":
        return <HabeasData />;
      case "autorizacion-datos":
        return <Certificate data={certificateData} />;
      default:
        return "";
    }
  };

  const accept = () => {
    switch (separated[3]) {
      case "terms":
        return "Términos de uso del sitio web aceptados ";
      default:
        return "Tratamiento de datos personales aceptado ";
    }
  };

  const acceptedDate = () => {
    switch (separated[3]) {
      case "terms":
        return data?.getLegalData?.data?.fechaTerminos;
      case "privacy-policies":
        return data?.getLegalData?.data?.fechaPolitica;
      case "titularidad-datos":
        return data?.getLegalData?.data?.fechaAbeasData;
      case "autorizacion-datos":
        return data?.getLegalData?.data?.fechaAbeasData;
      default:
        return data?.getLegalData?.data?.fechaRegistro;
    }
  };

  if (loading) {
    return (
      <Box
        minHeight="500px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress variant="indeterminate" size={50} />
      </Box>
    );
  }

  return (
    <form>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography variant="h6" className={classes.title}>
            {type()}
            <i className="icon-circle-check" style={{ color: "#68CE70  " }} />
          </Typography>
          <div className={classes.acceptedContainer}>
            <Typography className={classes.aceptConditions}>
              {accept()} por el usuario el día:
            </Typography>

            <Typography style={{ fontWeight: "bold" }}>
              {dayjs(acceptedDate()).format("DD-MM-YYYY HH:mm:ss")}
            </Typography>
          </div>
        </Box>
        <Divider />
        <Box marginY={3}>
          <Box>{component}</Box>
        </Box>
        <Box width="100%" marginTop={2}>
          <Typography variant="h6" color="secondary">
            Estado del proceso
          </Typography>
          <Typography color="secondary">
            Mercadeo Virtual S.A. Ha realizado el siguiente proceso de
            información y comunicación con el presente usuario en las
            direcciones de correo electrónico que él mismo ha registrado y
            recibiendo por el mismo canal la confirmación de dicha información:
          </Typography>
          <Conventions
            horizontal
            items={[
              {
                icon: "icon-document",
                label: "Presentación y lectura del documento",
                state: true,
              },
              {
                icon: "icon-envelope",
                label: "Correo electrónico enviado al usuario",
                state: true,
              },
              {
                icon: "icon-bell-check",
                label: "Notificación al usuario",
                state: true,
              },
            ]}
          />
          <Box
            width="100%"
            marginY={2}
            display="flex"
            justifyContent="flex-end"
          >
            {separated[3] === "autorizacion-datos" ? (
              <DialogAuthorization>
                {({ handleOpen }: any) => (
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: 8 }}
                    onClick={handleOpen}
                  >
                    Revocar Autorización
                  </Button>
                )}
              </DialogAuthorization>
            ) : null}
            <CertificatePDF data={certificateData} />
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default LegalDocuments;
