import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export default makeStyles(theme => ({
  agentsTitle: {
    fontWeight: 600
  },
  agentsAvatar: {
    background: theme.palette.primary.main,
    fontSize: 28,
    marginRight: theme.spacing(2)
  }
}))

export const EntranceReportContent = styled.div`
  font-family: Roboto, sans-serif;

  & .number {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background: #ffc676;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 28px;
    font-weight: bold;
  }

  & .numbercontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    & p {
      width: 44%;
      text-align: left;
      color: #afafaf;
      margin-left: 30px;
    }
  }

  & .hr {
    margin-bottom: 3%;
    border: 0.5px solid #e2e2e2;
  }
  & .hr1 {
    margin-bottom: 1%;
    border: 0.5px solid #e2e2e2;
  }

  & .icon {
    color: #f1ae6c;
  }

  & .resume3 {
    margin-bottom: 17px;
    margin-top: 2px;
  }

  & .title {
    height: 0px;
    margin-top: 9px;
    margin-bottom: -7px;
  }
`
