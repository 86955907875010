import { gql } from "apollo-boost";

export const GET_DOCUMENT_TYPES = gql`
  query($entity: Int!){
    getDocumentTypes(entity: $entity){
      idTipoDocumento
      descripcion
      abreviatura
    }
  }
`;