import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface ItemTotalProps {
  value: string | number;
  info: string;
  backgroundColor?: string;
  fontSizeValue?: string | number;
  heightValue?: string | number;
  widthValue?: string | number;
  widthInfo?: string | number;
  widthContainer?: string | number;
  alignInfo?: 'left' | 'center' | 'right';
  fontWeight?: boolean;
  fontColor?: string;
}

const ItemTotalLeft = ({
  value,
  info,
  backgroundColor,
  fontSizeValue,
  heightValue,
  widthValue,
  widthInfo,
  alignInfo,
  fontWeight,
  fontColor,
  widthContainer,
}: ItemTotalProps) => {
  return (
    <View
      style={{
        alignItems: 'center',
        flexDirection: 'row-reverse',
        width: widthContainer ? widthContainer : '100%',
        justifyContent: 'space-between',
      }}
    >
      <View
        style={[
          styles.backgroundweigth,
          {
            backgroundColor: `${
              backgroundColor ? (backgroundColor as String) : '#D47D57'
            }`,
            height: heightValue ? heightValue : 20,
            width: widthValue ? widthValue : 60,
          },
        ]}
      >
        <Text
          style={{
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            fontSize: fontSizeValue ? fontSizeValue : 15,
            color: fontColor ? fontColor : 'white',
          }}
        >
          {value}
        </Text>
      </View>
      <Text
        style={[
          styles.weigthanimals,
          {
            marginRight: '10px',
            width: widthInfo ? widthInfo : '50px',
            textAlign: alignInfo ? alignInfo : 'center',
            fontWeight: fontWeight ? 'bold' : 'normal',
            height: heightValue ? heightValue : 20,
          },
        ]}
      >
        {info}
      </Text>
    </View>
  );
};

export default ItemTotalLeft;
