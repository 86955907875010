import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container:{
    border: '1px solid #D6D6D6', 
    borderRadius: 20, 
    overflow: 'hidden',
    margin: 12, 
  }, 
  titleContainer:{
    width: '100%', 
    display:'flex', 
    justifyContent:'center', 
    padding: 12, 
    background: '#F2F2F2'
  }, 
  title: {
    fontWeight: 'bold', 
    color: '#767676', 
    fontSize: 16
  }, 
  listContainer:{
    padding: 12
  }, 
  list:{
    width:'100%',
    display: 'flex', 
    justifyContent: 'space-between', 
    "&.column": {
      flexDirection: 'column'
    }, 
    "&.row": {
      flexDirection: 'row'
    }
  }, 
  listItem: {
    width:'auto',
    maxWidth: 400,
    display: 'flex', 
    alignItems: 'center'
  }, 
  listIcon:{
    fontSize: 30,
    paddingRight:12,
  }
}))

export { useStyles as default }