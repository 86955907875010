import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: { padding: '20px 45px' },
  breadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  },

  contentCard: {
    width: '100%',
  },

  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    color: '#000',
    fontSize: 24,
    fontWeight: 'bolder',
  },

  label: {
    color: '#AFAFAF',
    fontSize: 18,
    fontWeight: 'bolder',
    padding: '10px 20px 10px 0px',
  },
  labelOrange: {
    color: '#DD9835',
    fontSize: 18,
    fontWeight: 'bolder',
  },

  result: {
    fontSize: 18,
    color: '#767676',
  },

  viewButton: {
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      color: '#fff',
    },
  },

  filterType: {
    marginRight: theme.spacing(1.5),
    fontSize: '16px',
  },
  filterValue: {
    color: theme.palette.text.hint,
    fontSize: '16px',
    fontWeight: 'bold',
    '& > .param': {
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },

  saveButton: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: '#DD9835',
    borderRadius: 25,
    margin: '0px 10px 0px 10px',
    padding: '5px 30px 5px 30px',
    '&:hover': {
      backgroundColor: '#dd9835',
    },
  },

  dataGrid: {
    border: 'none',
    display: 'flex',
    padding: '20px 45px',

    '& .MuiBox-root': {
      margin: '20px 0',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal',
      color: '#AFAFAF',
      fontWeight: 'bold',
      fontSize: 14,
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 1,
    },
  },
}));

export default useStyles;
