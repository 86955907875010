import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Grid, CircularProgress } from '@material-ui/core';
import { GET_DOCUMENT_TYPES } from 'core/graphql/queries/getDocumentTypes';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

interface DocumentTypesProps {
  name: string;
  value: number | null;
  errors?: string;
  helperText?: string;
  disable?: boolean;
  onChange: (event: any) => void;
  entity: 1 | 2;
}

const DocumentTypes = ({
  name,
  value,
  errors,
  helperText,
  onChange,
  disable,
  entity,
}: DocumentTypesProps) => {
  const token = useSelector((state: any) => state.user.token);
  const [valueDoc, setValueDoc] = useState(0);

  const { loading, error, data } = useQuery(GET_DOCUMENT_TYPES, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { entity: entity },
  });

  const handleChange = (event: any) => {
    onChange(event);
  };

  useEffect(() => {
    if (value) {
      setValueDoc(value);
    }
  }, [value]);

  return (
    <TextField
      label="Tipo de documento"
      required
      size="small"
      variant="outlined"
      fullWidth
      name={name}
      value={valueDoc}
      onChange={handleChange}
      error={!!errors}
      helperText={helperText}
      disabled={disable}
      select
    >
      {loading ? (
        <CircularProgress variant="indeterminate" size={25} />
      ) : error ? (
        <p>Error</p>
      ) : (
        data.getDocumentTypes.map((getDocumentTypes: any) => (
          <MenuItem
            key={getDocumentTypes.idTipoDocumento}
            value={getDocumentTypes.idTipoDocumento}
          >
            {getDocumentTypes.descripcion}
          </MenuItem>
        ))
      )}
    </TextField>
  );
};

export default DocumentTypes;
