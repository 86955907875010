import { useMemo, useState, useEffect, useCallback } from 'react';

import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';

interface tourProps {
  steps: Step[];
}

const Tour = ({ steps }: tourProps) => {
  const joyrideStyles = {
    buttonNext: {
      borderRadius: '50px',
      backgroundColor: '#DD9835',
      color: '#FFF',
      padding: '8px 16px',
      fontSize: '14px',
    },
    buttonBack: {
      backgroundColor: '#767676',
      borderRadius: '50px',
      color: '#FFF',
      padding: '8px 16px',
      fontSize: '14px',
    },
    tooltip: {
      borderRadius: '20px',
    },
    spotlight: {
      borderRadius: '20px',
    },
    tooltipTitle: {
      color: '#DD9835',
    },
    tooltipContent: {
      padding: '20px 10px 5px 10px',
    },
    options: {
      zIndex: 1300,
    },
    buttonClose: {
      color: '#767676',
    },
  };

  // useEffect(() => {
  //   if (!localStorageKey) {
  //     setRun(true);
  //     return;
  //   }
  //   const tourViewed = window.localStorage.getItem(localStorageKey);
  //   if (tourViewed) {
  //     return;
  //   }
  //   window.localStorage.setItem(localStorageKey, '1');
  //   setRun(true);
  // }, [localStorageKey]);

  const handleJoyrideCallback = useCallback((data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    // if (finishedStatuses.includes(status)) {
    //   console.log(status);
    // }
  }, []);

  const tour = useMemo(
    () => (
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        run={true}
        scrollToFirstStep={true}
        showSkipButton={true}
        steps={steps}
        styles={joyrideStyles}
        locale={{
          back: 'Atrás',
          close: 'Cerrar',
          next: 'Siguiente',
          last: 'Entendido',
          skip: 'Omitir',
        }}
        disableOverlayClose
      />
    ),
    []
  );
  return tour;
};

export default Tour;
