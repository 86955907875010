import Main from './Main';
import Details from './Details';
import Search from './Search';
import LoteDetails from './Details/Lote';
import CavasRealTime from './CavasRealTime';
import { NestedRoute } from 'lib/interfaces/routes';

const routes: NestedRoute[] = [
  {
    route: '/details/lote/:id',
    Node: LoteDetails,
    exact: true,
  },
  {
    route: '/real-time',
    Node: CavasRealTime,
    exact: true,
  },
];

export { Main, Search, Details, routes };
