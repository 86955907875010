import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import _ from 'lodash';

import { useRowStyles, useTableStyles } from './styles';

const Row = ({ row }: { row: any }) => {
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.farmer}</TableCell>
        <TableCell align="right">
          {row.totalLotes} lote{row.totalLotes !== 1 && 's'}
        </TableCell>
        <TableCell align="right">{row.average} kg</TableCell>
        <TableCell align="right">{row.totalAnimals} animales</TableCell>
        <TableCell align="right">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={6}
          className={clsx({ [classes.tableCellNestedTable]: open })}
          classes={{ root: clsx({ [classes.whenClose]: !open }) }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer>
              <Box bgcolor="rgba(0,0,0, 0.03)">
                <Table>
                  <TableHead className={classes.nestedTableHead}>
                    <TableRow>
                      <TableCell align="center">Nro. GSMI</TableCell>
                      <TableCell align="center">Lote IP</TableCell>
                      <TableCell align="center">Total Animales</TableCell>
                      <TableCell align="center">Placa Vehículo</TableCell>
                      <TableCell align="center">Responsable Portería</TableCell>
                      <TableCell align="center">Llegada Planta</TableCell>
                      <TableCell align="center">Salida Planta</TableCell>
                      <TableCell align="center">Estancia Planta</TableCell>
                      <TableCell align="center">Inicio Desembarque</TableCell>
                      <TableCell align="center">Fin Desembarque</TableCell>
                      <TableCell align="center">Tiempo Desembarque</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.orderBy(row.data, ['llegadaPlanta'], ['asc']).map(
                      (item, i) => (
                        <TableRow key={i}>
                          <TableCell align="center">{item.GSMI}</TableCell>
                          <TableCell align="center">
                            {item.codigoLote}
                          </TableCell>
                          <TableCell align="center">
                            {item.totalAnimales}
                          </TableCell>
                          <TableCell align="center">{item.placa}</TableCell>
                          <TableCell align="center">
                            {_.startCase(
                              item.responsablePorteria.toLowerCase()
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {moment(item.llegadaPlanta).format(
                              'YYYY/MM/DD hh:mm a'
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {moment(item.salidaPlanta).format(
                              'YYYY/MM/DD hh:mm a'
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {item.tiempoPlanta}
                          </TableCell>
                          <TableCell align="center">
                            {moment(item.inicioDesembarque).format(
                              'YYYY/MM/DD hh:mm a'
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {moment(item.finDesembarque).format(
                              'YYYY/MM/DD hh:mm a'
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {item.TiempoDesembarque}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <Button
                              size="small"
                              className={classes.viewBtn}
                              component={Link}
                              to={`/app/reports/entrance/details/${item.loteIP}`}
                            >
                              Ver
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ({ data = [] }) => {
  const classes = useTableStyles();
  return (
    <TableContainer classes={{ root: classes.root }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Granja</TableCell>
            <TableCell>Productor</TableCell>
            <TableCell align="right">Lotes por Granja</TableCell>
            <TableCell align="right">Promedio Peso en Pie (Kg)</TableCell>
            <TableCell align="right">Total Animales por Granja</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((element, index) => (
            <Row key={index} row={element} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
