/** @format */

const userIcon = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWX
MAACxKAAAsSgF3enRNAAADMklEQVR4nO2b7W3cMAyGmaL/z5kg1wnibuBu4A163SAjXDa4EXwT1B
vUmaCXCerbwJnAgRImMAjJtPVBGQVfgAjsSIL8RB+kqNyM4wgqt74om3kpIEYKiJECYqSAGCkgRg
qIkQJipIAYKSBGCoiRAmL0dQN9KAGgBoCKvO8AoAWAS6Z+vctE85msHsexH3n1WDZLP3NMsQIAGg
D4DQB3C8rfYdkG64oqx3mQmTL3jt9d8acL3DNOSTFJj6DGAsdA+QUAtwCwR7vFd1dS9h7bkJPgfK
4sq02zoF5jqVdJ9VsSUEc+8rSiLoXUSfVbag0y68ZfMq32K9voydr0XcIFkFqDqI9z9GiD1qFtJp
EUILrzdB5t0Doiu5kUIDqdeo82aJ21U9RLGosxkgJEF1Ofvz6tIxKjSQGi06P2aOPAtJlEUtu8+e
v/mzy/4LthYf0Cgewm775JQJIcQU+T593KkKEhcJ6kRpCkJ11aQoZ2HMdipk6BZajK/zHUMHa0fO
yA76cfXeK7wVL+KNnnHMcdZrr89Kx7tizWSZXDDzIf+OhR71EazpuEp9jU9o6jDNuRyD5XP7dww6
xAv8gWjrQrXIEk0it4jDQWY5Q7L1aiuWKzHmOubLmxHIDqie0WlAcMTdqJiUlyDXpAW5ILm5M5rj
2hJZcEoBo/JhQM1RWBJx1RKQEVCMblNV/xGLXDtYYeqVa4NlVoLsBnBJXGHUjkYJkg8+Jw/DrPXH
ttSR196MIEvd6WAk7puJRwiZTwqxzw+xRRvtTIWZMkXGoniZEUu9M2OIcEcD7s4IC0SUC2s56UcO
YgRTszitVJ22mhBJw5SFHWo1gdpLvLklsbsS3JBYcYfpDxUf5MntdmLGLJlvn44Znm/lSMaP7B8p
zjDGdw9CVIoSOI5rt8rrXEFr0mE5Q/Cx1B9AqK7PU4u2gfgq7JhAKiKeQtAvJJc38qFBC9o5N7et
n6EHSPKHQNopVfst+MfwdCD+JufBsLAUS39y3Le7sPmWIdnsVsXecQXyiGo9jm+BeBhRpCF2nNiz
HSvBgjBcRIATFSQIwUECMFxEgBMVJAjBQQIwXESAExUkBzAoBXMXEANwG3EOQAAAAASUVORK5CYI
I=`;

export default userIcon;
