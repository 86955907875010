import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rectangle: {
    '&:hover': {
      fill: '#4fe489',
    },
  },
}));

export { useStyles as default };
