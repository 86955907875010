import styled, { css } from 'styled-components'
import { space, SpaceProps } from 'styled-system'

interface FlexProps {
  direction?: 'row' | 'column';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  wrap?: 'wrap' | 'no-wrap';
}

interface FlexContainerProps extends FlexProps {
  bg?: string;
  fg?: string;
  leftAuto?: boolean;
  children?: React.ReactNode;
}

const FlexContainer = styled.div<FlexContainerProps & SpaceProps & any>`
  display: flex;
  ${space}
  ${({ bg }) => bg && css`background: ${bg};`}
  ${({ fg }) => fg && css`color: ${fg};`}
  ${({ leftAuto }) => leftAuto && css`margin-left: auto;`}
  ${({
  direction = 'row',
  alignItems = 'flex-start',
  justify = 'flex-start',
  wrap = 'wrap'
}) => css`
    flex-direction: ${direction};
    align-items: ${alignItems};
    justify-content: ${justify};
    flex-wrap: ${wrap};
  `}
`

export default FlexContainer