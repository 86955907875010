import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  backgroundresumetitleImage: {
    backgroundColor: '#ededed',
    fontSize: 8,
    justifyContent: 'center',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    paddingTop: 3,
    paddingBottom: 3,
  },
  imageicon: {
    backgroundColor: '#F49A28',
    borderRadius: 50,
    width: 30,
    height: 30,
    marginLeft: 10,
    justifyContent: 'center',
    position: 'absolute',
    padding: 3,
  },
  tableMagro: {
    display: 'table',
    width: '48.3%',
    borderStyle: 'solid',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '16.5%',
  },
  tableCell: {
    marginTop: 5,
    fontSize: 8,
    textAlign: 'center',
  },
  tableHead: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCellDetail: {
    height: '13px',
    fontSize: 8,
    marginTop: 5,
    textAlign: 'center',
  },
})