import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface SubtitleProps {
  title?: string;
  widthContainer?: number | string;
  heightContainer?: number | string;
}

const Subtitle = ({
  title,
  widthContainer,
  heightContainer,
}: SubtitleProps) => {
  return (
    <View
      style={[
        styles.backgroundresumetitle,
        {
          width: `${widthContainer ? (widthContainer as String) : '31.2%'}`,
          height: `${heightContainer ? (heightContainer as String) : '16px'}`,
        },
      ]}
    >
      <Text>{title}</Text>
    </View>
  );
};

export default Subtitle;
