import axios from "axios";

import config from "config/secrets"

const saveUserService = async (data: any) => {
  return await axios
  
    .post(
      `${config.AUTH}${config.APP_URI}${config.APP_VERSION}/system_access/save_info`,
      data
    )
    .then((response) => response.data)
    .catch((error) => error);
};

export default saveUserService;
