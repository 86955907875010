import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  
  tablecontainer: {
    marginTop: 7,
    marginRight: 18,
  },
  backgroundresumetitle: {
    backgroundColor: '#ededed',
    fontSize: 8,
    justifyContent: 'center',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    paddingTop: 3,
    paddingBottom: 3,
  },
  imageicon: {
    backgroundColor: '#F49A28',
    borderRadius: 50,
    width: 30,
    height: 30,
    marginLeft: 10,
    justifyContent: 'center',
    position: 'absolute',
    padding: 3,
  },
  table: {
    display: 'table',
    borderStyle: 'solid',
    marginTop: 10,
  },
  tableCol: {
    width: '50%',
    textAlign: 'left',
  },
  tableCell: {
    marginTop: 3,
    fontSize: 7,
  },
  tableColRigth: {
    width: '50%',
    textAlign: 'right',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
  }
})