import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import weekOfYear from 'dayjs/plugin/weekOfYear'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';

import { persistor, store } from 'config/createStore';

import Spinner from 'components/Spinner';

import './static/fonts/style.css';

dayjs.extend(weekOfYear)
dayjs.locale('es')

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<Spinner />}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
