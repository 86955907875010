export default {
  DEFAULT_COUNTRY_ID: 48,
  DEFAULT_DEPARTMENT_ID: 2890,
  DEFAULT_CITY_ID: 21074,
  VERSION: "4.0",
  REPORTS_TYPES: [
    "Todos",
    "Portería",
    "Guias de Movilización",
    "Tiquete de Báscula",
    "Inmunocastración",
    "Antemortem",
    "Visceras Blancas",
    "Postmortem",
    "Evaluación de Magro",
    "Distribución y Despachos",
  ],
  DAYS: [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábados",
    "Domingos",
    "Festivos",
  ],
  FOOTER: `Copyright © ${new Date().getFullYear()} Todos los Derechos de Autor y
  Patrimoniales están Reservados de Forma Exclusiva para MERCADEO
  VIRTUAL S.A.`,
};
