import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  }, 
  headerContainer:{
    display: 'flex', 
    justifyContent:'center'
  }, 
  headerContent:{
    width:'100%',
    display:'flex', 
    justifyContent:'space-between',
    alignItems: 'center'
  }, 
  headerTitle:{
    color:'#767676', 
  },
  headerFilter:{
    width:'20%'
  }, 
  contentCard:{
    width:'100%',
  },
  listContainer:{
    display:'flex', 
    flexFlow:'row wrap',
    justifyContent: 'center'
  }, 
  listHorizontalContainer:{
    display:'flex', 
  },
  itemsCenter:{
    display:'flex', 
    flexDirection:'column', 
    justifyContent:"center",
    textAlign:'center', 
    color:'#767676'
  },
  listTime:{
    fontWeight:'bold', 
    color: theme.palette.primary.main,
  },  
  resumeUsersContainer:{
    maxHeight: 190,
    display:'flex', 
    justifyContent:'center',
    alignItems:'center', 
    padding: '20px 0',
    
  },
  graphContainer:{
    display:'flex', 
    justifyContent:'center',
    alignItems:'center', 
    position:'relative', 
  },
  infoGraphicContainer:{
    display:'flex', 
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center', 
    position: 'absolute', 
    color: '#767676', 
    fontWeight: 'bolder', 
    zIndex:1
  },
  infoGraphic:{
    color: '#767676', 
    fontWeight: 'bolder'
  },
  resumeContainer:{
    display:'flex', 
    flexFlow:'row wrap',
    justifyContent:'space-evenly', 
    marginTop: 12,
    alignItems: 'center', 
  },   
  totalAnimalsContainer:{
    display:'flex', 
    justifyContent:'space-evenly', 
    marginTop: 12,
    alignItems: 'center'
  },
  listTotalContainer:{
    width: '50%',
    "& .MuiListItem-root":{
      width: '100% !important'
    },
  }, 
  previewContainer:{
    width: '100%',
    display:'flex', 
    justifyContent: 'space-between', 
    alignItems:'center'
  },
  titleDataGrid:{
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
  headerChild:{
    display: 'flex', 
    alignItems:'center'
  },
  result:{
    display:'flex', 
    alignItems:'center',
    marginRight: 12,
    "& i":{
      fontSize:24,
    }
  },
  searchContainer:{
    display:'flex', 
    alignItems:'center',
    "& .MuiOutlinedInput-inputMarginDense":{
      padding: '6.5px 14px !important',
      minWidth: 180
    }
  },
  btnSearch:{
    background: theme.palette.primary.main, 
    padding: 4,
    margin: '0px 8px',
    color: '#fff', 
    fontWeight: 'bold', 
    "&:hover": {
      background: theme.palette.primary.dark,
      color: '#fff',
      fontWeight:'bold',
    },
  },
  dataGrid:{
    border:'none', 
    display:'flex',
    margin: '20px 0',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator":{
      display:'none'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14
    }
  }, 
  levelContainer:{
    width:'100%', 
    display:'flex',
    justifyContent:'center'
  },
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& :hover":{
      color: '#fff'
    }
  },
  options:{
    color:'#767676', 
    margin: '0px 12px'
  },
  filterType: {
    marginRight: theme.spacing(1.5),
    fontSize: '16px'
  },
  filterValue: {
    color: theme.palette.text.hint,
    fontSize: '16px',
    fontWeight: 'bold',
    '& > .param': {
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontWeight: 'bold',
    }
  },
  cardContainer:{
    width:'100%',
    padding: '35px !important'
  },
  title:{
    fontWeight: 'bold',
    color: '#212121',
    fontSize: '22px',
  },
  colorHr: {
    borderColor: '#E2E2E2',
  },

}))

export { useStyles as default }