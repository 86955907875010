import styled from "styled-components";
import { fadeIn, slideInLeft } from "core/styles/_animations";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

export const RegisterContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 50px);
  padding-left: 20px;
  padding: 24px 0px;
  ${(props) => {
    return props.transition === "fadeIn"
      ? fadeIn({ time: ".2s" })
      : props.transition === "slideIn"
      ? slideInLeft({ time: ".4s" })
      : "";
  }}

  & img.error-img {
    width: 200px;
    height: 200px;
  }

  @media screen and (max-width: 991px) {
    min-height: 50vh;
    margin: 50px 0;
  }
`;

export const Content = styled.div`
  width: 50%;
  

  @media screen and (max-width: 991px) {
    width: 85%;
  }
`;

export const CheckBoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 15px 0;

  & > div {
    display: block;
  }
`;

export const PasswordMessagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const useStyles = makeStyles((theme: Theme) => ({
  subtitle_types: {
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: 230,
  },
  checkboxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: 'column'
  },
  checkboxLabel: {
    "& .MuiIconButton-root": {
      padding: 4,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 12,
    },
  },
  dialogContainer: {
    '& .MuiDialog-paperWidthMd': {
      borderRadius: '20px',
    },
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '20px',
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      textAlign: 'center'
    },
  },

  iconDialogCheck:{
    fontSize:60,
    color:theme.palette.primary.main
  }, 
  title:{
    fontWeight: 600, 
    fontSize:24,
  },
  captionGray:{
    color:'#AFAFAF',
    padding:12,
    paddingLeft:24
  },
  dialogFooter:{
    display:'flex', 
    justifyContent:'center', 
    padding: 16
  }, 
}));
