import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  colorHr: {
    borderColor: '#ccc'
  },
  background: {
    backgroundColor: 'gray'
  },
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthLg": {
      borderRadius: '20px'
    },
    "& .MuiDialogContent-root":{
      padding: '0px 40px 40px 40px'
    }
  },
  padding: {
    padding: 10
  },
  paddingCard: {
    padding: 20
  },
  fontW: {
    fontWeight: 'bold',
  },
  fontTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#767676',
  } ,
  fontContent: {
    color: '#AFAFAF',
    fontSize: '16px',
  }, 
  spacing:{
    marginLeft: '50px'
  },
  sizeImage: {
    width: '240px',
    height: '176px'
  },
  backgroundColor: {
    backgroundColor: '#F2F2F2 !important',
  },
  fontUppercase: {
    textTransform: 'uppercase',
  },
  buttonWaiting: {
    fontWeight: 'bold',
    color: 'white !important',
    backgroundColor: '#767676 !important'
  },
  buttonJected: {
    fontWeight: 'bold',
    color: 'white !important',
    backgroundColor: '#e2575c !important'
  },
  buttonAccept: {
    fontWeight: 'bold',
    color: 'white !important',
    backgroundColor: '#68ce70 !important'
  },
  fontUser:{
    color: '#DD9835',
    fontWeight: 'bold'
  },
   
}));

export { useStyles as default }