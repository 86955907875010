import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 1450,
    margin: '50px auto',
  },
  content: {
    display: 'flex',
    width: '100%',
    boxShadow: 'none',
    overflow: 'hidden',
    borderRadius: 20,
  },

  labelSubtitle: {
    marginTop: 30,
    padding: '25px 0 25px 0',
    color: '#767676',
    fontSize: 18,
    fontWeight: 'bold',
  },

  sidebar: {
    width: '100%',
    maxWidth: 280,
    background: '#212121',
    color: 'white',
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      maxWidth: 240,
    },
  },

  containerGrid: {
    padding: 46,
  },

  canvas: {
    width: '100%',
    overflowY: 'auto',
    maxHeight: '900px',
  },
  circleContainer: {
    padding: 20,
  },
  circleText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontSize: 22,
    fontWeight: 'bold',
  },
  circleBarTitle: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 20,
    marginLeft: 60,
  },

  msjDescription: {
    fontSize: 14,
    color: '#AFAFAF',
    margin: '0 0 5px 0',
  },

  downButton: {
    color: 'white',
    backgroundColor: '#707070',
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  downReason: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  titlePage: {
    width: '20%',
    paddingBottom: 15,
    color: '#212121',
    fontSize: 24,
    fontWeight: 'bold',
  },

  circleBarSubTitle: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '0 10px 0 10px',
  },
  profilePicture: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',

    borderRadius: '50%',
    '& .editHover': {
      display: 'none',
    },

    '&:hover .editHover': {
      display: 'block',
      opacity: 1,
    },
  },

  editHover: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },

  avatar: {
    position: 'relative',
    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  spanAvatar: {
    background: 'rgba(118,118,118, 0)',
    borderRadius: '50%',
    width: theme.spacing(14),
    height: theme.spacing(14),
    position: 'absolute',

    '&:hover': {
      transition: 'opacity .2s ease-in-out',
      background: 'rgba(118,118,118,0.5)',
    },
  },

  circularProgressBottom: {
    color: 'white',
  },

  circularProgressTop: {
    color: theme.palette.primary.main,
    position: 'absolute',
  },

  avatarLetter: {
    fontSize: 50,
    marginLeft: 5,
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  infoIcon: {
    marginLeft: 'auto',
    marginRight: 0,
  },

  dialogContent: {
    textAlign: 'center',
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '20px',
    },
  },

  iconModal: {
    fontSize: 70,
    color: theme.palette.primary.main,
  },

  titleDialog: {
    fontWeight: 'bold',
    fontSize: 24,
  },

  textEdit: {
    color: '#767676',
    padding: 12,
    paddingLeft: 24,
  },

  buttonsModal: {
    display: 'flex',
    justifyContent: 'center',
    padding: 16,
  },

  discardButtonModal: {
    color: '#767676',
    fontWeight: 'bold',
    fontSize: 16,
    border: '1px solid #AFAFAF',
    borderRadius: 25,
    margin: '0px 10px 0px 10px',
    padding: '5px 30px 5px 30px',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  saveButtonModal: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: '#DD9835',
    borderRadius: 25,
    margin: '0px 10px 0px 10px',
    padding: '5px 30px 5px 30px',
    '&:hover': {
      backgroundColor: '#dd9835',
    },
  },

  doneButtonModal: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: '#DD9835',
    borderRadius: 25,
    padding: '5px 30px 5px 30px',
    '&:hover': {
      backgroundColor: '#dd9835',
    },
  },

  dialogContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  pictureDialog: {
    width: '100%',
    height: 800,
    position: 'relative',
  },
  loadingBackdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 500,
    '& .progress': {
      width: '40%',
      zIndex: 600,
      marginTop: theme.spacing(3),
    },
  },
  cropperContainer: {
    width: '800px',
    height: '380px',
    position: 'relative',
    flex: 1,
    marginTop: 35,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  floatButton: {
    position: 'fixed',
    bottom: 50,
    right: 50,
    '&.MuiBackdrop-root': {
      backgroundColor: '#FFF',
    },
  },

  slider: {
    padding: '22px 0px',
    width: '50%',
  },

  icon: {
    fontSize: 35,
  },
  iconAlert: {
    fontSize: 70,
    color: '#B0413E',
  },
  buttonAlert: {
    color: 'white',
    backgroundColor: '#B0413E',
    '&:hover': {
      backgroundColor: '#E2575C',
    },
  },

  capitalizeSign: {
    textTransform: 'capitalize',
    '& p': {
      textTransform: 'capitalize',
    },
  },

  listDeactivate: {
    '& li': {
      color: '#AFAFAF',
      fontStyle: 'italic',
    },
    '& li::marker': {
      color: '#DD9835',
    },
  },

  labelReasons: {
    padding: '10px 0 5px 0',
    color: '#767676',
    fontSize: 18,
    fontWeight: 'bold',
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
}));

const useListLinkStyles = makeStyles((theme) => ({
  root: {
    margin: '2px 0 2px 0',
    background: '#2D2D2D',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  textList: {
    paddingLeft: 50,
  },

  iconMenu: {
    justifyContent: 'center',
  },

  itemText: {
    paddingRight: 5,
    '&.MuiListItemText-root': {
      flex: 'inherit',
    },
  },

  itemTextHide: {
    display: 'none',
  },

  active_current: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    background: '#444444',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export { useStyles as default, useListLinkStyles };
