import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const LotNumberDateTitleContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  color: #767676;
  & .lot {
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 200px;
    height: 38px;
    font-weight: bold;
    & .paragraphlot {
      text-align: left;
      margin-bottom: 0%;
      color: #afafaf;
    }
  }

  & .reportsgray {
    float: right;
  }

  & .numbers {
    color: #dd9835;
    font-weight: bold;
  }
  & .numbersdate {
    color: #dd9835;
    font-weight: bold;
  }

  & .title {
    height: 0px;
    margin-top: 9px;
    margin-bottom: 30px;
    text-align: left;
  }
`;

export default makeStyles((theme) => ({
  chipsContainer: {
    display: 'flex',
  },
  infoChip: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 170,
    padding: theme.spacing(0.5, 1.2),
    background: '#F2F2F2',
    marginLeft: theme.spacing(2),
  },
  chipTitle: {
    color: '#AFAFAF',
    fontWeight: 'bold',
  },
  chipDescription: {
    color: '#DD9835',
    fontWeight: 'bold',
    marginLeft: 12,
  },
}));
