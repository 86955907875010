import { css } from "styled-components";

import { shadowRegular } from "./_properties";

export const BasicVariant = css`
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #212121;
  font-weight: bold;
  font-size: 12px;
  padding: 10px 30px;
  border-radius: 9999px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    text-decoration: none;
    transition: all 0.2s ease;
    background: #e8e8e8;
    color: #212121;
  }

  &:focus {
    outline: none;
  }
`;

export const PrimaryVariant = css`
  color: #ffff;
  background: #dd9835;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 9999px;
  padding: 10px 50px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #ca8722;
    text-decoration: none;
    transition: all 0.2s ease;
    ${shadowRegular()}
  }

  &:focus {
    outline: none;
  }
`;

export const PrimaryOutlineVariant = css`
  color: #dd9835;
  background: #ffff;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border: 2px #dd9835 solid;
  border-radius: 9999px;
  padding: 10px 50px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #dd9835;
    color: #ffff;
    text-decoration: none;
    transition: all 0.2s ease;
    ${shadowRegular()}
  }

  &:focus {
    outline: none;
  }
`;

export const SecondaryVariant = css`
  color: #ffff;
  background: #767676;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 9999px;
  padding: 10px 50px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #5c5c5c;
    text-decoration: none;
    transition: all 0.2s ease;
    ${shadowRegular()}
  }

  &:focus {
    outline: none;
  }
`;
