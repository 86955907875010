export default (nit: number): any => {
  let cont = 0;
  let modulo11 = [41, 37, 29, 23, 19, 17, 13, 7, 3];
  const myArr = String(nit).split("").map((nit)=>{
    return Number(nit)
  })
  for (let i = 0; i < myArr.length; i++) {
    cont += myArr[i] * modulo11[i];
  }  

  let div = (cont / 11);
  let decimals = div - Math.floor(div);
  let mult = decimals * 11;
  let round = Math.round(mult);

  if (round === 1 || round === 0) {
    return (round)
  }else{
    return (11 - round)
  }
};
