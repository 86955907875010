import { css, keyframes } from 'styled-components'

const fadeInKeyframes = keyframes`
  from {
    filter: blur(5px);
    opacity: 0;
    transform: scale(0);
    clip
  }
  to {
    filter: blur(0);
    opacity: 1;
    transform: scale(1);
  }
`

export const fadeIn = ({ time = '1s', type = 'ease-in', delay = '0s' } = {}) =>
  css`
    animation: ${time} ${fadeInKeyframes} ${type} ${delay};
    animation-fill-mode: backwards;
  `

const scaleInKeyframes = keyframes`
  0% {
    transform: scale(0);
    border-radius: 99999px;
  }

  40% {
    transform: scale(0.4);
    border-radius: 99999px;
  }

  100% {
    transform: scale(1);
    border-radius: 0; 
  }
`

export const scaleIn = ({ time = '1s', type = 'ease' } = {}) =>
  css`animation: ${time} ${scaleInKeyframes} ${type}`

const collapseInKeyframes = keyframes`
  from {
    transform:  scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
`
export const collapseIn = ({ time = '.6s', type = 'ease', delay = '0s' } = {}) =>
  css`
    will-change: transform;
    transform-origin: top center;
    overflow: hidden;
    contain: content;
    animation: ${collapseInKeyframes} ${time} ${type} ${delay};
  `

const slideInLeftKeyframes = keyframes`
  0% {
    position: absolute;
    left: -100%;
    opacity: 0;
    filter: blur(5px)
  }

  80% {
    opacity: 1;
    filter: blur(0)
  }

  100% {
    position: relative;
    left: 0;
  }
`

export const slideInTop = ({ time = '0.5s', type = 'ease-out' } = {}) =>
  css`animation: ${slideInTopKeyframes} ${time} ${type}`

const slideInTopKeyframes = keyframes`
  0% {
    margin-top: -100%;
    opacity: 0;
    filter: blur(5px)
  }

  80% {
    opacity: 1;
    filter: blur(0)
  }

  100% {
    margin-top: 0;
  }
`

export const slideInLeft = ({ time = '1s', type = 'ease', delay = '0s' } = {}) =>
  css`
    animation: ${slideInLeftKeyframes} ${time} ${type} ${delay};
    animation-fill-mode: backwards;  
  `

const fadeInOutKeyframes = keyframes`
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.5; 
  }

  to {
    opacity: 1;
  }
`

export const fadeInOut = ({ time = '1.2s', type = 'linear', animationIterationCount = 'infinite' } = {}) =>
  css`animation: ${fadeInOutKeyframes} ${time} ${type} ${animationIterationCount}`
