import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
  },
  fontTitle: {
    fontWeight: 'bold',
  },
  gridMargin:{
    margin: '0px 24px'
  },
  loadingColor: {
    color: '#E2E2E2'
  },
  plateContainer:{
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 40px 10px 40px'
  },
  container:{
    "& .gtlCRa":{
      padding: '40px !important'
    }
  }
}))

export { useStyles as default }