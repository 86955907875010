import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";

import { ChangeInternalUserPasswordValidations } from "lib/validations/ChangeInternalUserPasswordValidations";
import { CHANGE_INTERNAL_USER_PASSWORD } from "core/graphql/mutations/changePassword";

import { useSnackbar } from "notistack";
import clsx from "clsx";

import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";

import useStyles from "./styles";

interface EditPasswordProps {
  uid: Number;
  children: any;
}

const EditPassword = ({ uid, children }: EditPasswordProps) => {
  const classes = useStyles();

  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const [changePassword, { loading }] = useMutation(
    CHANGE_INTERNAL_USER_PASSWORD
  );

  const [openEditPassword, setOpenEditPassword] = React.useState(false);
  const handleOpenEditPassword = () => {
    setOpenEditPassword(true);
  };
  const [viewPass, setViewPass] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: ChangeInternalUserPasswordValidations,
    onSubmit: (values, { resetForm }) => {
      changePassword({
        variables: { newPassword: values.password, uid },
        context: { headers: { "x-auth-jwt": token } },
      })
        .then((data) => {
          if (data?.data?.changeDataPassword?.data?.success) {
            enqueueSnackbar("La contraseña se ha actualizado correctamente.", {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            resetForm();
            setOpenEditPassword(false);
          } else {
            enqueueSnackbar(
              "Ha ocurrido un error, no se pudo actualizar la contraseña.",
              {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            );
            resetForm();
            setOpenEditPassword(false);
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(
            "Ha ocurrido un error, no se pudo actualizar la contraseña.",
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        });
    },
  });
  const handleCloseEditPassword = () => {
    formik.resetForm();
    setOpenEditPassword(false);
  };

  return (
    <>
      {children({ handleOpenEditPassword })}

      <Dialog
        open={openEditPassword}
        onClose={handleCloseEditPassword}
        maxWidth="sm"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogTitle className={classes.containerCenter}>
          <Typography variant="h5" className={classes.title}>
            Editar Contraseña
          </Typography>
          <Typography variant="subtitle1" className={classes.captionGray}>
            A continuación ingresa la nueva contraseña
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.formContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Nueva contraseña"
                    name="password"
                    value={formik.values.password}
                    error={Boolean(formik.errors.password)}
                    helperText={formik.errors.password}
                    onChange={formik.handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    type={viewPass ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setViewPass(!viewPass)}>
                            {!viewPass ? (
                              <VisibilityOutlined />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Confirmar nueva contraseña"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    error={Boolean(formik.errors.confirmPassword)}
                    helperText={formik.errors.confirmPassword}
                    onChange={formik.handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    type={viewPass ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setViewPass(!viewPass)}>
                            {!viewPass ? (
                              <VisibilityOutlined />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <DialogActions className={classes.dialogFooter}>
              <Button
                variant="outlined"
                className={classes.btn}
                onClick={handleCloseEditPassword}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                type="submit"
                endIcon={
                  loading && (
                    <CircularProgress size={20} style={{ color: "#FFF" }} />
                  )
                }
              >
                Actualizar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditPassword;
