import { gql } from "apollo-boost";


export const PUT_STATUS_VEHICLE = gql`
  mutation putStatusVehicle(
    $idConductor: Int
    $idVehiculo: Int
    $estadoConductor: Boolean
){
  putVehicleStatus(input: {
    idConductor: $idConductor
    idVehiculo: $idVehiculo
    estadoConductor: $estadoConductor
	}){
    data{
      success
      message
    }
    token{
      tokenNuevo
      fechaExpiracion
    }
  }
}
`;

export const PUT_STATUS_DRIVER = gql`
  mutation putDriverStatus(
    $idConductor: Int
    $idVehiculo: Int
    $estadoConductor: Boolean
){
  putDriverStatus(input: {
    idConductor: $idConductor
    idVehiculo: $idVehiculo
    estadoConductor: $estadoConductor
	}){
    data{
      success
      message
    }
    token{
      tokenNuevo
      fechaExpiracion
    }
  }
}
`;

export const POST_DRIVER_VEHICLE = gql `
  mutation postDriverXVehicle(
    $idConductor: Int
    $idVehiculo: Int
  ){
    postDriverXVehicle(input: {
      idConductor: $idConductor
      idVehiculo: $idVehiculo
    }){
      data{
        success
        message
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const POST_COMPANY_VEHICLE = gql `
  mutation postCompanyXVehicle(
    $idEmpresaTransportadora: Int
    $idVehiculo: Int
    $idConductor: Int
  ){
    postCompanyXVehicle(input: {
      idEmpresaTransportadora: $idEmpresaTransportadora
      idVehiculo: $idVehiculo
      idConductor: $idConductor
    }){
      data{
        success
        message
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation postCompany(
    $idTipoDocumento: Int!
    $numeroDocumento: String!
    $digitoVerificacion: Int!
    $razonSocial: String!
    $tipoDireccion: Int!
    $idCiudad: Int!
    $idTipoVia: Int!
    $numeroVia: Int!
    $apendiceVia: String
    $numeroCruce: Int!
    $apendiceCruce: String
    $metrosEsquina: Int!
    $adicional: String
    $codigoPostal: String!
    $longitud: String
    $latitud: String
    $indicativoTelefono: Int!
    $telefono: String!
    $extension: String
    $indicativoCelular: Int!
    $celular: String!
    $email: String!
  ){
    postCompanyConveyor(input: {
      idTipoDocumento: $idTipoDocumento
      numeroDocumento: $numeroDocumento
      digitoVerificacion: $digitoVerificacion
      razonSocial: $razonSocial
      tipoDireccion: $tipoDireccion
      idCiudad: $idCiudad
      idTipoVia: $idTipoVia
      numeroVia: $numeroVia
      apendiceVia: $apendiceVia
      numeroCruce: $numeroCruce
      apendiceCruce: $apendiceCruce
      metrosEsquina: $metrosEsquina
      adicional: $adicional
      codigoPostal: $codigoPostal
      longitud: $longitud
      latitud: $latitud
      indicativoTelefono: $indicativoTelefono
      telefono: $telefono
      extension: $extension
      indicativoCelular: $indicativoCelular
      celular: $celular
      email: $email
    }){
      data{
        success
        message
        id
      }
    }
} 
`;

export const UPDATE_COMPANY = gql`
  mutation putCompany(
    $IdEmpresaTransportadora: Int
    $indicativoTelefono: Int
    $telefono: String
    $extension: String
    $indicativoCelular: Int
    $celular: String
    $email: String
    $tipoDireccion: Int
    $idTipoVia: Int
    $idCiudad: Int
    $numeroVia: String
    $apendiceVia: String
    $numeroCruce: String
    $apendiceCruce: String
    $metrosEsquina: String
    $adicional: String
    $codigoPostal: String
  ){
    putCompanyConveyor(input: {
      IdEmpresaTransportadora: $IdEmpresaTransportadora
      indicativoTelefono: $indicativoTelefono
      telefono: $telefono
      extension: $extension
      indicativoCelular: $indicativoCelular
      celular: $celular
      email: $email
      tipoDireccion: $tipoDireccion
      idTipoVia: $idTipoVia
      idCiudad: $idCiudad
      numeroVia: $numeroVia
      apendiceVia: $apendiceVia
      numeroCruce: $numeroCruce
      apendiceCruce: $apendiceCruce
      metrosEsquina: $metrosEsquina
      adicional: $adicional
      codigoPostal: $codigoPostal
    }){
      data{
        success
        message
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const CREATE_DRIVER = gql`
  mutation postDriver(
    $numeroLicenciaConduccion: Int
  	$fechaExpedicionLicencia: String
  	$categoriasLicencia: [FechaVigenciaXCategoriaLicencia]
    $idTipoDocumento: Int 
    $numeroDocumento: Int  
    $primerNombre: String  
    $segundoNombre: String  
    $primerApellido: String  
    $segundoApellido: String   
    $idTipoDireccion: Int  
    $idPais: Int
    $idDepartamento: Int  
    $idCiudad: Int  
    $idTipoVia: Int  
    $numeroVia: Int 
    $apendiceVia: String
    $numeroCruce: Int  
    $apendiceCruce: String  
    $metrosEsquina: Int
    $adicional: String  
    $codigoPostal: String
    $indicativoTelefono: Int
    $telefono: String
    $indicativoCelular: Int
    $celular: String
    $email: String
    $esConductorReemplazo: Boolean
    $idVehiculo: Int
    $idEmpresaTransportadora: Int
  ){
    postDriver(input: {
      numeroLicenciaConduccion: $numeroLicenciaConduccion
      fechaExpedicionLicencia: $fechaExpedicionLicencia
      categoriasLicencia: $categoriasLicencia
      idTipoDocumento: $idTipoDocumento
      numeroDocumento: $numeroDocumento
      primerNombre: $primerNombre
      segundoNombre: $segundoNombre
      primerApellido: $primerApellido
      segundoApellido: $segundoApellido
      idTipoDireccion: $idTipoDireccion
      idPais: $idPais
      idDepartamento: $idDepartamento
      idCiudad: $idCiudad
      idTipoVia: $idTipoVia
      numeroVia: $numeroVia
      apendiceVia: $apendiceVia
      numeroCruce: $numeroCruce
      apendiceCruce: $apendiceCruce
      metrosEsquina: $metrosEsquina
      adicional: $adicional
      codigoPostal: $codigoPostal
      indicativoTelefono: $indicativoTelefono
      telefono: $telefono
      indicativoCelular: $indicativoCelular
      celular: $celular
      email: $email
      esConductorReemplazo: $esConductorReemplazo
      idVehiculo: $idVehiculo
      idEmpresaTransportadora: $idEmpresaTransportadora
    }){
      data{
        success
        message
        id
      }
    }
  } 
`;

export const UPDATE_DRIVER = gql`
  mutation putDriver(
    $idConductor: Int
    $primerNombre: String
    $segundoNombre: String
    $primerApellido: String
    $segundoApellido: String
    $numeroLicencia: String
    $esConductorReemplazo: Boolean
    $indicativoTelefono: Int
    $telefono: String
    $indicativoCelular: Int
    $celular: String
    $email: String
    $tipoDireccion: Int
    $idTipoVia: Int
    $idCiudad: Int
    $numeroVia: String
    $apendiceVia: String
    $numeroCruce: String
    $apendiceCruce: String
    $metrosEsquina: String
    $adicional: String
    $codigoPostal: String
  ){
    putDriver(input: {
      idConductor: $idConductor
      primerNombre: $primerNombre
      segundoNombre: $segundoNombre
      primerApellido: $primerApellido
      segundoApellido: $segundoApellido
      numeroLicencia: $numeroLicencia
      esConductorReemplazo: $esConductorReemplazo
      indicativoTelefono: $indicativoTelefono
      telefono: $telefono
      indicativoCelular: $indicativoCelular
      celular: $celular
      email: $email
      tipoDireccion: $tipoDireccion
      idTipoVia: $idTipoVia
      idCiudad: $idCiudad
      numeroVia: $numeroVia
      apendiceVia: $apendiceVia
      numeroCruce: $numeroCruce
      apendiceCruce: $apendiceCruce
      metrosEsquina: $metrosEsquina
      adicional: $adicional
      codigoPostal: $codigoPostal
    }){
      data{
        success
        message
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const CREATE_VEHICLE = gql`
  mutation postVehicle(
    $numeroLicenciaTransito: Int
    $numeroPlaca: String
    $idPaisPlaca: Int
    $idDepartamentoPlaca: Int
    $idCiudadPlaca: Int
    $marca: String
    $modelo: Int
    $color: String
    $idEmpresaTransportadora: Int
    $idTipoVehiculo: Int
    $servicio: Int
    $idTipoCarga: Int
    $capacidadCarga: String
    $numeroPisos: Int
    $idTipoDocumentoPropietario: Int
    $numeroDocumentoPropietario: Int
    $primerNombrePropietario: String
    $segundoNombrePropietario: String
    $primerApellidoPropietario: String
    $segundoApellidoPropietario: String
    $idIndicativoCelularPropietario: Int
    $celularPropietario: String
    $emailPropietario: String
    $idTipoServicio: Int
    $idTipoUsuario: Int
    $idConductor: Int
){
  postVehicle(input: {
    numeroLicenciaTransito: $numeroLicenciaTransito
    numeroPlaca: $numeroPlaca
    idPaisPlaca: $idPaisPlaca
    idDepartamentoPlaca: $idDepartamentoPlaca
    idCiudadPlaca: $idCiudadPlaca
    marca: $marca
    modelo: $modelo
    color: $color
    idEmpresaTransportadora: $idEmpresaTransportadora
    idTipoVehiculo: $idTipoVehiculo
    servicio: $servicio
    idTipoCarga: $idTipoCarga
    capacidadCarga: $capacidadCarga
    numeroPisos: $numeroPisos
    idTipoDocumentoPropietario: $idTipoDocumentoPropietario
    numeroDocumentoPropietario: $numeroDocumentoPropietario
    primerNombrePropietario: $primerNombrePropietario
    segundoNombrePropietario: $segundoNombrePropietario
    primerApellidoPropietario: $primerApellidoPropietario
    segundoApellidoPropietario: $segundoApellidoPropietario
    idIndicativoCelularPropietario: $idIndicativoCelularPropietario
    celularPropietario: $celularPropietario
    emailPropietario: $emailPropietario
    idTipoServicio: $idTipoServicio
    idTipoUsuario: $idTipoUsuario
    idConductor: $idConductor
  }){
    data{
      success
      message
      id
    }
  }
} 
`;

export const UPDATE_VEHICLE = gql`
  mutation putVehicle(
    $idVehiculo: Int
    $numeroLicenciaTransito: Int
    $placa: String
    $idPaisPlaca: Int
    $idDepartamentoPlaca: Int
    $idCiudadPlaca: Int
    $marca: String
    $modelo: Int
    $color: String
    $idTipoVehiculo: Int
    $servicio: Int
    $numeroPisos: Int
    $idTipoCarga: Int
    $capacidadCarga: String
    $primerNombrePropietario: String
    $segundoNombrePropietario: String
    $primerApellidoPropietario: String
    $segundoApellidoPropietario: String
    $idTipoDocumentoPropietario: Int
    $numeroDocumentoPropietario: String
    $idIndicativoCelularPropietario: Int
    $celularPropietario: String
    $emailPropietario: String
    $idTipoServicio: Int
  ){
    putVehicle(input: {
      idVehiculo: $idVehiculo
      numeroLicenciaTransito: $numeroLicenciaTransito
      placa: $placa
      idPaisPlaca: $idPaisPlaca
      idDepartamentoPlaca: $idDepartamentoPlaca
      idCiudadPlaca: $idCiudadPlaca
      marca: $marca
      modelo: $modelo
      color: $color
      idTipoVehiculo: $idTipoVehiculo
      servicio: $servicio
      numeroPisos: $numeroPisos
      idTipoCarga: $idTipoCarga
      capacidadCarga: $capacidadCarga
      primerNombrePropietario: $primerNombrePropietario
      segundoNombrePropietario: $segundoNombrePropietario
      primerApellidoPropietario: $primerApellidoPropietario
      segundoApellidoPropietario: $segundoApellidoPropietario
      idTipoDocumentoPropietario: $idTipoDocumentoPropietario
      numeroDocumentoPropietario: $numeroDocumentoPropietario
      idIndicativoCelularPropietario: $idIndicativoCelularPropietario
      celularPropietario: $celularPropietario
      emailPropietario: $emailPropietario
      idTipoServicio: $idTipoServicio
    }){
      data{
        success
        message
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;


