import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  MenuItem,
  TextField,
  Grow,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router';

import constants from 'config/constants';
import unmaskPhone from 'lib/helpers/unmaskPhone';
import { PutVehicleValidation } from 'lib/validations/TransportValidation';

import {
  GET_TYPE_LOAD,
  GET_VEHICLE,
  TYPE_SERVICE,
  TYPE_VEHICLE,
} from 'core/graphql/queries/transport';
import { UPDATE_VEHICLE } from 'core/graphql/mutations/transport';

import PhoneNumber from 'components/PhoneNumber';
import SelectCountry from 'components/SelectCountry/index';
import DocumentTypes from 'components/DocumentTypes';
import GobackLink from 'components/GobackLink';
import ContentCard from 'components/ContentCard';
import MainTitle from 'components/MainTitle';
import LicensePlate from 'components/LicensePlate';
import Loader from 'components/Loader';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';

import useStyles from './styles';

type CompononentProps = {
  id: number;
};

const DetailVehicle = ({ id }: CompononentProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: `/app/network/transports/forms/detail-vehicle/${id}`,
      title: 'Detalle Vehículo',
    },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];

  const { data, loading, error, refetch } = useQuery(GET_VEHICLE, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idVehiculo: id },
  });

  const {
    data: dataTypeService,
    loading: loadingTypeService,
    error: errorTypeService,
  } = useQuery(TYPE_SERVICE, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const result = data?.getDetailVehicle?.data;

  const {
    data: dataTypeVehicle,
    loading: loadingTypeVehicle,
    error: errorTypeVehicle,
  } = useQuery(TYPE_VEHICLE, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const {
    data: dataTypeLoad,
    loading: loadingTypeLoad,
    error: errorTypeLoad,
  } = useQuery(GET_TYPE_LOAD, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const [putVehicle, { loading: loadingPutVehicle }] = useMutation(
    UPDATE_VEHICLE,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const updateVehicle = (data: any) => {
    putVehicle({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.putVehicle.data.success === 1) {
          enqueueSnackbar('Datos actualizados exitosamente.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
        } else {
          enqueueSnackbar(mutationResult.data.postVehicle.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        idVehiculo: result?.idVehiculo,
        numeroLicenciaTransito: data.licenseNumber,
        placa: data.licensePlate,
        idPaisPlaca: data.country,
        idDepartamentoPlaca: data.departament,
        idCiudadPlaca: data.city,
        marca: data.vehicleBrand,
        modelo: data.vehicleModel,
        color: data.color,
        idTipoVehiculo: data.vehicleType,
        servicio: data.service,
        numeroPisos: data.numberFloors,
        idTipoCarga: data.loadType,
        capacidadCarga: data.capacity,
        primerNombrePropietario: data.firstNameOwner,
        segundoNombrePropietario: data.secondNameOwner,
        primerApellidoPropietario: data.surnameOwner,
        segundoApellidoPropietario: data.secondSurnameOwner,
        idTipoDocumentoPropietario: data.documentType,
        numeroDocumentoPropietario: data.documentOwner,
        idIndicativoCelularPropietario: data.indicativeMobile,
        celularPropietario: data.mobile,
        emailPropietario: data.email,
        idTipoServicio: data.typeService,
      },
    });
  };

  const service = [
    {
      id: 1,
      description: 'PUBLICO',
    },
    {
      id: 2,
      description: 'PRIVADO',
    },
  ];

  const formikVehicle = useFormik({
    initialValues: {
      typeService: 1,
      licenseNumber: '',
      licensePlate: '',
      country: constants.DEFAULT_COUNTRY_ID,
      departament: constants.DEFAULT_DEPARTMENT_ID,
      city: constants.DEFAULT_CITY_ID,
      vehicleBrand: '',
      vehicleModel: '',
      color: '',
      vehicleType: '',
      service: '',
      numberFloors: '',
      loadType: '',
      capacity: '',
      firstNameOwner: '',
      secondNameOwner: '',
      surnameOwner: '',
      secondSurnameOwner: '',
      documentType: 1,
      documentOwner: '',
      indicativeMobile: constants.DEFAULT_COUNTRY_ID,
      mobile: '',
      email: '',
    },
    validationSchema: PutVehicleValidation,
    onSubmit: (values) => {
      updateVehicle(values);
    },
  });

  useEffect(() => {
    if (!loading) {
      formikVehicle.setValues({
        typeService: result?.idTipoServicio,
        licenseNumber: result?.numeroLicenciaTransito,
        licensePlate: result?.placa,
        country: result?.idPaisPlaca,
        departament: result?.idDepartamentoPlaca,
        city: result?.idCiudadPlaca,
        vehicleBrand: result?.marca,
        vehicleModel: result?.modelo,
        color: result?.color,
        vehicleType: result?.idTipoVehiculo,
        service: result?.servicio,
        numberFloors: result?.numeroPisos,
        loadType: result?.idTipoCarga,
        capacity: result?.capacidadCarga,
        firstNameOwner: result?.primerNombrePropietario,
        secondNameOwner: result?.segundoNombrePropietario,
        surnameOwner: result?.primerApellidoPropietario,
        secondSurnameOwner: result?.segundoApellidoPropietario,
        documentType: result?.idTipoDocumentoPropietario,
        documentOwner: result?.numeroDocumentoPropietario,
        indicativeMobile: result?.idIndicativoCelularPropietario,
        mobile: result?.celularPropietario,
        email: result?.emailPropietario,
      });
    }
  }, [loading]);

  const initialData = {
    typeService: result?.idTipoServicio,
    firstNameOwner: result?.primerNombrePropietario,
    secondNameOwner: result?.segundoNombrePropietario,
    surnameOwner: result?.primerApellidoPropietario,
    secondSurnameOwner: result?.segundoApellidoPropietario,
    documentType: result?.idTipoDocumentoPropietario,
    documentOwner: result?.numeroDocumentoPropietario,
    indicativeMobile: result?.idIndicativoCelularPropietario,
    mobile: result?.celularPropietario,
    email: result?.emailPropietario,
  };

  const dataUpdate = {
    typeService: formikVehicle.values.typeService,
    firstNameOwner: formikVehicle.values.firstNameOwner,
    secondNameOwner: formikVehicle.values.secondNameOwner,
    surnameOwner: formikVehicle.values.surnameOwner,
    secondSurnameOwner: formikVehicle.values.secondSurnameOwner,
    documentType: formikVehicle.values.documentType,
    documentOwner: formikVehicle.values.documentOwner,
    indicativeMobile: formikVehicle.values.indicativeMobile,
    mobile: formikVehicle.values.mobile,
    email: formikVehicle.values.email,
  };

  useEffect(() => {
    if (!loadingPutVehicle) {
      refetch();
    }
  }, [loadingPutVehicle]);

  return (
    <>
      <Loader visible={loading}>Cargando...</Loader>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard className={classes.container}>
        <Box mt={2}>
          <Grid container justifyContent="center">
            <MainTitle>Detalle Vehículo</MainTitle>
          </Grid>
        </Box>

        <Grow in timeout={800}>
          <form onSubmit={formikVehicle.handleSubmit} style={{ padding: 40 }}>
            <Box marginBottom={3}>
              <MainTitle fontSize="h6">Tipo de Servicio</MainTitle>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Tipo de Servicio"
                  variant="outlined"
                  size="small"
                  name="typeService"
                  select
                  value={formikVehicle.values.typeService}
                  onChange={(e: any) => {
                    formikVehicle.handleChange(e);
                  }}
                  error={
                    formikVehicle.touched.typeService &&
                    Boolean(formikVehicle.errors.typeService)
                  }
                  helperText={
                    formikVehicle.touched.typeService &&
                    formikVehicle.errors.typeService
                  }
                >
                  {loadingTypeService ? (
                    <CircularProgress variant="indeterminate" size={25} />
                  ) : errorTypeService ? (
                    <p>Error</p>
                  ) : (
                    dataTypeService?.getTypeService.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </Grid>
            </Grid>

            <Box marginBottom={3} marginTop={2}>
              <MainTitle fontSize="h6">Datos Generales del Vehículo</MainTitle>
            </Box>

            <Box mb={2}>
              <Grid container>
                <TextField
                  fullWidth
                  label="Número de Licencia *"
                  variant="outlined"
                  size="small"
                  name="licenseNumber"
                  value={formikVehicle.values.licenseNumber}
                  onChange={formikVehicle.handleChange}
                  error={
                    formikVehicle.touched.licenseNumber &&
                    Boolean(formikVehicle.errors.licenseNumber)
                  }
                  helperText={
                    formikVehicle.touched.licenseNumber &&
                    formikVehicle.errors.licenseNumber
                  }
                  disabled
                />
              </Grid>
            </Box>

            <Grid spacing={2}>
              <Box mb={1}>
                <Grid container spacing={2}>
                  <Grid container item spacing={2} alignItems="center">
                    <Grid item xs={4} className={classes.plateContainer}>
                      <LicensePlate
                        placa={formikVehicle.values.licensePlate}
                        ciudad={Number(formikVehicle.values.city)}
                        width={350}
                        checked
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <SelectCountry
                        direction="vertical"
                        names={{
                          country: 'country',
                          departament: 'departament',
                          city: 'city',
                        }}
                        values={{
                          country: formikVehicle.values.country,
                          departament: formikVehicle.values.departament,
                          city: formikVehicle.values.city,
                        }}
                        errors={{
                          country: formikVehicle.errors.country,
                          departament: formikVehicle.errors.departament,
                          city: formikVehicle.errors.city,
                        }}
                        onChange={formikVehicle.handleChange}
                        helperText={{
                          country: formikVehicle.errors.country,
                          departament: formikVehicle.errors.departament,
                          city: formikVehicle.errors.city,
                        }}
                        disable
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box mb={1.5}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Marca del Vehículo"
                      variant="outlined"
                      size="small"
                      name="vehicleBrand"
                      value={formikVehicle.values.vehicleBrand}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.vehicleBrand &&
                        Boolean(formikVehicle.errors.vehicleBrand)
                      }
                      helperText={
                        formikVehicle.touched.vehicleBrand &&
                        formikVehicle.errors.vehicleBrand
                      }
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Modelo del Vehículo"
                      variant="outlined"
                      size="small"
                      name="vehicleModel"
                      type="number"
                      value={formikVehicle.values.vehicleModel}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.vehicleModel &&
                        Boolean(formikVehicle.errors.vehicleModel)
                      }
                      helperText={
                        formikVehicle.touched.vehicleModel &&
                        formikVehicle.errors.vehicleModel
                      }
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Color del Vehículo"
                      variant="outlined"
                      size="small"
                      name="color"
                      value={formikVehicle.values.color}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.color &&
                        Boolean(formikVehicle.errors.color)
                      }
                      helperText={
                        formikVehicle.touched.color &&
                        formikVehicle.errors.color
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={1.5}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Tipo de Vehículo *"
                      variant="outlined"
                      size="small"
                      name="vehicleType"
                      select
                      value={formikVehicle.values.vehicleType}
                      onChange={(e: any) => {
                        formikVehicle.handleChange(e);
                      }}
                      error={
                        formikVehicle.touched.vehicleType &&
                        Boolean(formikVehicle.errors.vehicleType)
                      }
                      helperText={
                        formikVehicle.touched.vehicleType &&
                        formikVehicle.errors.vehicleType
                      }
                      disabled
                    >
                      {loadingTypeVehicle ? (
                        <CircularProgress variant="indeterminate" size={25} />
                      ) : errorTypeVehicle ? (
                        <p>Error</p>
                      ) : (
                        dataTypeVehicle?.getTypeVehicle.map((item: any) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.description}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      label="Tipo de Servicio"
                      select
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="service"
                      value={formikVehicle.values.service}
                      onChange={(e: any) => {
                        formikVehicle.handleChange(e);
                      }}
                      error={
                        formikVehicle.touched.service &&
                        Boolean(formikVehicle.errors.service)
                      }
                      helperText={
                        formikVehicle.touched.service &&
                        formikVehicle.errors.service
                      }
                      disabled
                    >
                      {service.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Número de Pisos *"
                      variant="outlined"
                      size="small"
                      name="numberFloors"
                      value={formikVehicle.values.numberFloors}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.numberFloors &&
                        Boolean(formikVehicle.errors.numberFloors)
                      }
                      helperText={
                        formikVehicle.touched.numberFloors &&
                        formikVehicle.errors.numberFloors
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <TextField
                      label="Tipo de Carga *"
                      select
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="loadType"
                      value={formikVehicle.values.loadType}
                      onChange={(e: any) => {
                        formikVehicle.handleChange(e);
                      }}
                      error={
                        formikVehicle.touched.loadType &&
                        Boolean(formikVehicle.errors.loadType)
                      }
                      helperText={
                        formikVehicle.touched.loadType &&
                        formikVehicle.errors.loadType
                      }
                      disabled
                    >
                      {loadingTypeLoad ? (
                        <CircularProgress variant="indeterminate" size={25} />
                      ) : errorTypeLoad ? (
                        <p>Error</p>
                      ) : (
                        dataTypeLoad?.getTypeLoad?.map((item: any) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.description}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Capacidad *"
                      variant="outlined"
                      size="small"
                      name="capacity"
                      value={formikVehicle.values.capacity}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.capacity &&
                        Boolean(formikVehicle.errors.capacity)
                      }
                      helperText={
                        formikVehicle.touched.capacity &&
                        formikVehicle.errors.capacity
                      }
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      label="Unidad"
                      select
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="loadType"
                      value={formikVehicle.values.loadType}
                      onChange={formikVehicle.handleChange}
                      disabled
                    >
                      {loadingTypeLoad ? (
                        <CircularProgress variant="indeterminate" size={25} />
                      ) : errorTypeLoad ? (
                        <p>Error</p>
                      ) : (
                        dataTypeLoad?.getTypeLoad?.map(
                          (item: any) =>
                            formikVehicle.values.loadType === item.id && (
                              <MenuItem key={item.id} value={item.id}>
                                {item.loadUnit}
                              </MenuItem>
                            )
                        )
                      )}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>

              <Box marginBottom={3} marginTop={2}>
                <MainTitle fontSize="h6">
                  Datos de Generales del Propietario
                </MainTitle>
              </Box>

              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Primer Nombre"
                      variant="outlined"
                      size="small"
                      name="firstNameOwner"
                      value={formikVehicle.values.firstNameOwner}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.firstNameOwner &&
                        Boolean(formikVehicle.errors.firstNameOwner)
                      }
                      helperText={
                        formikVehicle.touched.firstNameOwner &&
                        formikVehicle.errors.firstNameOwner
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Segundo Nombre"
                      variant="outlined"
                      size="small"
                      name="secondNameOwner"
                      value={formikVehicle.values.secondNameOwner}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.secondNameOwner &&
                        Boolean(formikVehicle.errors.secondNameOwner)
                      }
                      helperText={
                        formikVehicle.touched.secondNameOwner &&
                        formikVehicle.errors.secondNameOwner
                      }
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={2.5}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Primer Apellido"
                      variant="outlined"
                      size="small"
                      name="surnameOwner"
                      value={formikVehicle.values.surnameOwner}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.surnameOwner &&
                        Boolean(formikVehicle.errors.surnameOwner)
                      }
                      helperText={
                        formikVehicle.touched.surnameOwner &&
                        formikVehicle.errors.surnameOwner
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Segundo Apellido"
                      variant="outlined"
                      size="small"
                      name="secondSurnameOwner"
                      value={formikVehicle.values.secondSurnameOwner}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.secondSurnameOwner &&
                        Boolean(formikVehicle.errors.secondSurnameOwner)
                      }
                      helperText={
                        formikVehicle.touched.secondSurnameOwner &&
                        formikVehicle.errors.secondSurnameOwner
                      }
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DocumentTypes
                      name="documentType"
                      entity={1}
                      value={formikVehicle.values.documentType}
                      onChange={formikVehicle.handleChange}
                      errors={formikVehicle.errors.documentType}
                      helperText={formikVehicle.errors.documentType}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Documneto *"
                      variant="outlined"
                      size="small"
                      name="documentOwner"
                      value={formikVehicle.values.documentOwner}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.documentOwner &&
                        Boolean(formikVehicle.errors.documentOwner)
                      }
                      helperText={
                        formikVehicle.touched.documentOwner &&
                        formikVehicle.errors.documentOwner
                      }
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PhoneNumber
                      type="mobile"
                      names={{
                        indicative: 'indicativeMobile',
                        phone: 'mobile',
                      }}
                      values={{
                        indicative: Number(
                          formikVehicle.values.indicativeMobile
                        ),
                        phone: (formikVehicle.values.mobile = unmaskPhone(
                          formikVehicle.values.mobile
                        )),
                      }}
                      onChange={formikVehicle.handleChange}
                      errors={{
                        indicative:
                          !!formikVehicle.errors.indicativeMobile &&
                          !!formikVehicle.touched.indicativeMobile,
                        phone: formikVehicle.errors.mobile as string,
                      }}
                      helperTexts={{
                        indicative: formikVehicle.errors
                          .indicativeMobile as string,
                        phone: formikVehicle.errors.mobile as string,
                      }}
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Correo Electrónico *"
                      variant="outlined"
                      size="small"
                      type="email"
                      name="email"
                      value={formikVehicle.values.email}
                      onChange={formikVehicle.handleChange}
                      error={
                        formikVehicle.touched.email &&
                        Boolean(formikVehicle.errors.email)
                      }
                      helperText={
                        formikVehicle.touched.email &&
                        formikVehicle.errors.email
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid component={Box} container justify="center" mt={3}>
              <Box mr={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.goBack()}
                >
                  Volver
                </Button>
              </Box>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                endIcon={
                  loadingPutVehicle && (
                    <CircularProgress
                      className={classes.loadingColor}
                      thickness={5}
                      size={20}
                    />
                  )
                }
                disabled={
                  JSON.stringify(initialData) === JSON.stringify(dataUpdate)
                }
              >
                {loadingPutVehicle ? 'Actualizando...' : 'Actualizar'}
              </Button>
            </Grid>
          </form>
        </Grow>
      </ContentCard>
    </>
  );
};
export default DetailVehicle;
