import { Avatar, Box, Button, Fab, Typography } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { Edit, Share } from '@material-ui/icons';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SharedReportsLayout from 'layouts/SharedReportsLayout';

import AddToFrequent from 'components/PageComponents/SharedReports/AddToFrequent';
import EditFrequent from 'components/PageComponents/SharedReports/EditFrequent';
import ShareReportsOfLot from 'components/PageComponents/SharedReports/ShareReportsOfLot';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import avatars from 'lib/helpers/dynamic-images/avatars';

import useStyles from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
}
const Frequent = () => {
  const classes = useStyles();
  const valueTab = useSelector(
    (state: any) => state.ui.sharedReports.valueRouteTab
  );
  const users = [
    {
      id: 1,
      name: 'Leidy Marcela Álvarez Mejía',
      userName: 'LeidyAl20',
      document: 'CC 100987328',
      rol: 'Comercializador',
      state: 'Activo',
    },

    {
      id: 2,
      name: 'Angie Dahiana Lopez',
      userName: 'Angie120',
      document: 'CC 34098098',
      rol: 'Productor',
      state: 'Activo',
    },
    {
      id: 3,
      name: 'Luis Restrepo Gomez',
      userName: 'Luis',
      document: 'CC 1093753',
      rol: 'Comercializador',
      state: 'Inactivo',
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'Usuario',
      width: 200,
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Avatar
            className={classes.avatar}
            src={avatars(`${params.getValue(params.id, 'userName')}`)}
          />
          <Typography>{params.getValue(params.id, 'userName')}</Typography>
        </>
      ),
    },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 300,
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
    },
    {
      field: 'document',
      headerName: 'Documento',
      width: 200,
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
    },
    {
      field: 'rol',
      headerName: 'Tipo de Usuario',
      width: 160,
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
    },
    {
      field: 'Detalle',
      headerName: '',
      renderCell: (params: GridValueGetterParams) => (
        <Box>
          <Fab
            color="primary"
            className={classes.btnDetails}
            component={Link}
            to={`/app/network/shared-reports/frequent-details/${params.getValue(
              params.id,
              'id'
            )}/Todos`}
          >
            <Box component="i" fontSize="20px" className="icon-eye" />
          </Fab>
        </Box>
      ),
      disableColumnMenu: true,
      sortable: false,
      headerClassName: classes.headerTableGrid,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'Compartir',
      headerName: '',
      renderCell: (params: GridValueGetterParams) => (
        <ShareReportsOfLot>
          {({ handleOpen }: any) => (
            <Box>
              <Fab
                color="primary"
                className={classes.btnDetails}
                onClick={handleOpen}
              >
                <Share />
              </Fab>
            </Box>
          )}
        </ShareReportsOfLot>
      ),
      disableColumnMenu: true,
      sortable: false,
      headerClassName: classes.headerTableGrid,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'Editar',
      headerName: '',
      renderCell: (params: GridValueGetterParams) => (
        <EditFrequent>
          {({ handleOpen }: any) => (
            <Fab
              color="primary"
              className={classes.btnDetails}
              onClick={handleOpen}
            >
              <Edit />
            </Fab>
          )}
        </EditFrequent>
      ),
      disableColumnMenu: true,
      sortable: false,
      headerClassName: classes.headerTableGrid,
      headerAlign: 'center',
      align: 'center',
    },
  ];
  return (
    <>
      <SharedReportsLayout>
        <TabPanel index="frequent" value={valueTab}>
          <div className={classes.containerTab}>
            <Box display="flex" justifyContent="flex-end">
              <AddToFrequent>
                {({ handleOpen }: any) => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                    startIcon={<i className="icon-plus" />}
                  >
                    Añadir Usuario
                  </Button>
                )}
              </AddToFrequent>
            </Box>
            <div className={classes.tableGrid}>
              <DataGrid
                rows={users}
                columns={columns}
                pageSize={users.length}
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
                autoHeight
              />
            </div>
          </div>
        </TabPanel>
      </SharedReportsLayout>
    </>
  );
};

export default Frequent;
