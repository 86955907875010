import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5%',
    paddingTop: '2.5%',
    maxWidth: 'inherit',
  },

  breadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  },

  contentCard: {
    width: '100%',
  },

  headerContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  spacingTf: {
    margin: '0px 20px',
  },

  paperTitles: {
    fontSize: 20,
    fontWeight: 'bolder',
    color: '#767676',
  },

  icon: {
    fontSize: 30,
    padding: 5,
    color: 'white',
    background: theme.palette.primary.main,
    borderRadius: '100%',
    margin: 'auto 10px',
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  paddingContainer: {
    padding: '10px 0px 0px 0px',
  },

  viewButton: {
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      color: '#fff',
    },
  },

  dataGrid: {
    border: 'none',
    display: 'flex',
    padding: '20px 45px',

    '& .MuiBox-root': {
      margin: '20px 0',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal',
      color: '#AFAFAF',
      fontWeight: 'bold',
      fontSize: 14,
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 1,
    },
  },
}));

export default useStyles;
