import { gql } from 'apollo-boost';

export const CHANGE_PASSWORD = gql`
  mutation updatePassword(
    $passwordActual: String!
    $passwordNueva: String!
    $process: String!
  ) {
    changeDataPassword(
      input: {
        passwordActual: $passwordActual
        passwordNueva: $passwordNueva
        process: $process
      }
    ) {
      data {
        success
        message
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const CHANGE_INTERNAL_USER_PASSWORD = gql`
  mutation updatePassword($newPassword: String!, $uid: Int!) {
    changeDataPassword(
      input: {
        passwordNueva: $newPassword
        process: "INTERNAL_USERS"
        uid: $uid
      }
    ) {
      data {
        success
        message
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;
