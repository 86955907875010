import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
} from '@material-ui/core';

import useStyles from './styles';

interface SponsoredCoderops {
  open: boolean;
  close: any;
}

const SponsoredCode = ({ open, close }: SponsoredCoderops) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        className={classes.dialogContent}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent className={classes.dialogContainer}>
          <Typography className={classes.titleDialog}>
            Planes de Suscripción
          </Typography>
          <Typography variant="subtitle1" className={classes.textEdit}>
            A continuación indique los datos de patrocinio para que sea válida
            la suscripción.
          </Typography>
          <TextField
            label="Código de Patrocinio"
            variant="outlined"
            size="small"
            fullWidth
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant="contained" color="secondary" onClick={close}>
            Atrás
          </Button>
          <Button variant="contained" color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SponsoredCode;
