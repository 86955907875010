import { Document, Page, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import corralLogo from 'static/img/reportsIcons/corralLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import productorIcon from 'static/img/reportsIcons/productorIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';
import whiteFarm from 'static/img/reportsIcons/whiteFarm';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import ItemTotal from 'components/pdf-components/ItemTotal';
import ListData from 'components/pdf-components/ListData';
import TableInfo from 'components/pdf-components/TableInfo/index';

import styles from './styles/date';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const detalle = [
  {
    fecha: '05/10/2019',
    totalAnimales: 28,
    tipoCorral: 'Reposo',
    nroCorral: 'CR10',
    muertosCorral: 0,
    horas: '4:32:00',
  },
  {
    fecha: '05/10/2019',
    totalAnimales: 28,
    tipoCorral: 'Reposo',
    nroCorral: 'CR11',
    muertosCorral: 0,
    horas: '4:32:00',
  },
  {
    fecha: '05/10/2019',
    totalAnimales: 4,
    tipoCorral: 'Observación',
    nroCorral: 'CO',
    muertosCorral: 1,
    horas: '4:32:00',
  },
];

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
  range?: null | number | string;
}

export const MyDocCorralLote = ({ qr, uid, range }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={6700}
        reportImage={corralLogo}
        number="023456789"
        title="Reporte Inventario Animales en Pie"
        code="AM-001"
      />

      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={productorIcon}
          title="Productor"
          items={{
            Nombre: 'Nombre productor',
            NIT: '34123412-2',
            Teléfono: '+57 314 6543 543',
            Email: 'info@productor.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View style={{ width: '31.2%' }}>
          <TableInfo
            icon={whiteFarm}
            title="Granja Origen"
            widthContainer="100%"
            items={{
              Nombre: 'Nombre Granja',
              Responsable: 'Nombre Responsable',
              Teléfono: '+57 314 6543 543',
              Email: 'info@granja.com',
              Dirección: 'Calle 105 A 23-23',
              Ciudad: 'Medellín, Colombia',
            }}
          />
        </View>
        <View style={{ width: '65.6%' }}>
          <View style={{ marginTop: '7px' }}>
            <Subtitle title="Resumen Ingreso Corrales" widthContainer="100%" />
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <View
              style={{
                width: '42.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotal
                value="60"
                info="Total Animales del Lote"
                position="right"
                widthValue={50}
                widthInfo="50px"
                fontSizeValue={12}
                backgroundColor="#be5948"
              />
            </View>
            <View style={{ width: '54.3%' }}>
              <ListData
                widthContainer="90%"
                widthtTitle="50%"
                widthValue="50%"
                items={{
                  'Fecha Ingreso Planta': '05/08/2019 09:00:00',
                  'Fecha Ingreso Corrales': '05/08/2019 09:20:00',
                }}
              />
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <View
              style={{
                width: '42.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotal
                value="3"
                info="Total Corrales"
                position="right"
                widthValue={50}
                widthInfo="50px"
                fontSizeValue={12}
                backgroundColor="#d47d57"
              />
            </View>
            <View style={{ width: '54.3%' }}>
              <ListData
                widthContainer="90%"
                widthtTitle="50%"
                widthValue="50%"
                items={{
                  'Responable Corrales': 'Luis Fernando Contreras',
                  'Médico Veterinario': 'Diego Lopez',
                }}
              />
            </View>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '48.3%' }}>
          <View style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Subtitle
              title="Animales en Corrales de Observación"
              widthContainer="100%"
            />
          </View>

          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View
              style={{
                width: '42.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="4"
                info="Total Animalesen Observación"
                position="right"
                textAlign="left"
                backgroundColor="#d47d57"
                widthInfo="65px"
                widthCircle={30}
              />
            </View>
            <View style={{ width: '54.3%' }}>
              <ListData
                widthContainer="90%"
                widthtTitle="80%"
                widthValue="20%"
                items={{
                  'Sala Beneficio de Emergencia': '0',
                  'Muertos en Observación': '0',
                }}
              />
            </View>
          </View>
        </View>
        <View
          style={{
            width: '48.3%',
          }}
        >
          <View style={{ marginTop: '5px', marginBottom: '5px' }}>
            <Subtitle
              title="Animales en Corrales de Reposo"
              widthContainer="100%"
            />
          </View>

          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View
              style={{
                width: '42.3%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ItemTotalCircle
                value="56"
                info="Total Animales en Reposo"
                position="right"
                textAlign="left"
                backgroundColor="#d47d57"
                widthInfo="60px"
                widthCircle={30}
              />
            </View>
            <View style={{ width: '54.3%' }}>
              <ListData
                widthContainer="80%"
                widthtTitle="80%"
                widthValue="20%"
                items={{
                  'Línea de Sacrificio': '0',
                  'Muertos en Reposo': '0',
                }}
              />
            </View>
          </View>
        </View>
      </View>

      <View style={{ marginTop: '5px', marginBottom: '5px' }}>
        <Subtitle
          title="Detalle del Inventario Animales en Pie"
          widthContainer="100%"
        />
      </View>

      <View style={{ width: '100%' }}>
        <View style={[styles.tableMagro, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '10px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Fecha</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Total Animales
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Tipo de Corral
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Nro. Corral
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Muertos en Corral
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Horas en Corrales
              </Text>
            </View>
          </View>
          {detalle.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.fecha}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.totalAnimales}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.tipoCorral}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.nroCorral}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.muertosCorral}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.horas}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 92.5,
                width: 92.5,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 92.5, width: 92.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 185, width: 185, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 92.5, width: 92.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              1
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 92.5, width: 92.5, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screenqrcorrallote" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte Inventario Animales en Pie"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
