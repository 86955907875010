import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  List,
  ListItem,
  Button,
  IconButton,
  Tooltip,
  Fab,
  Box,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { Link } from 'react-router-dom';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import ItemListInfo from 'components/ItemListInfo';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import Chart from 'components/Chart';
import LevelIndicator from 'components/LevelIndicator';
import ButtonCounter from 'components/ButtonCounter';
import FilterReports from 'components/FilterReports';

import useStyles from './styles';

const Main = () => {
  const classes = useStyles();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/inventory/corral', title: 'Inventario Corrales' },
  ];

  const rows = [
    {
      id: 1,
      lote_IP: '0420',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '24',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '24',
    },
    {
      id: 2,
      lote_IP: '0421',
      animales_lote: '20',
      CO: '3',
      SBE: '12',
      muertos_CO: '1',
      saldo: '1',
      CR: '30',
      enviados_sacrificio: '2',
      muertos_CR: '0',
      total: '30',
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'lote_IP',
      headerName: 'Lote IP',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'animales_lote',
      headerName: 'Animales lote',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'CO',
      headerName: 'CO',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'SBE',
      headerName: 'SBE',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'muertos_CO',
      headerName: 'Muertos CO',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'saldo',
      headerName: 'Saldo',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'CR',
      headerName: 'CR',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'enviados_sacrificio',
      headerName: 'Enviados Sacrificio',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'muertos_CR',
      headerName: 'Muertos CR',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'total',
      headerName: 'Total Existencia',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'horas_corrales',
      headerName: 'Horas en Corrales',
      renderCell: () => (
        <div className={classes.levelContainer}>
          <LevelIndicator value={11} levels={[[4], [4, 8], [8, 12], [12]]} />
        </div>
      ),
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: '',
      headerName: '',
      renderCell: (params: GridValueGetterParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`corral/details/${params.getValue(params.id, 'id')}`}
              color="primary"
              className={classes.btnDetails}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <div>
      <div className={classes.breadContainer}>
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <Grid container spacing={2} justify="center" alignItems="stretch">
        <Grid container spacing={4} item sm={12}>
          <Grid item container xs={12}>
            <FilterReports to="corral/search" />
          </Grid>
        </Grid>
        <Grid container spacing={2} item sm={6} alignItems="stretch">
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard marginTop="0px" className={classes.contentCard}>
              <ContentCard.Header>Ingresos Portería</ContentCard.Header>
              <List className={classes.listContainer}>
                <ButtonCounter
                  icon="icon-truck"
                  titleInfo="Camiones ingresados"
                  numberInfo="00"
                  width="45%"
                />
                <ButtonCounter
                  icon="icon-productor"
                  titleInfo="Productores"
                  numberInfo="02"
                  width="45%"
                />
                <ButtonCounter
                  icon="icon-truck-deny"
                  titleInfo="Ingresos Denegados"
                  numberInfo="00"
                  width="45%"
                />
                <ButtonCounter
                  icon="icon-granja"
                  titleInfo="Granjas"
                  numberInfo="08"
                  width="45%"
                />
              </List>
            </ContentCard>
          </Grid>
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard marginTop="0px" className={classes.contentCard}>
              <ContentCard.Header>
                Promedio tiempo de espera ingreso de camiones a planta
              </ContentCard.Header>
              <List className={classes.listHorizontalContainer}>
                <ListItem className={classes.itemsCenter}>
                  <Typography variant="h5" className={classes.listTime}>
                    00:14:00
                  </Typography>
                  <Typography>Espera ingreso a planta</Typography>
                  <Typography variant="caption">(HH:MM:SS)</Typography>
                </ListItem>
                <ListItem className={classes.itemsCenter}>
                  <Typography variant="h5" className={classes.listTime}>
                    01:16:32
                  </Typography>
                  <Typography>Estancia en planta</Typography>
                  <Typography variant="caption">(HH:MM:SS)</Typography>
                </ListItem>
                <ListItem className={classes.itemsCenter}>
                  <Typography variant="h5" className={classes.listTime}>
                    00:43:00
                  </Typography>
                  <Typography>Tiempo Desembarque</Typography>
                  <Typography variant="caption">(HH:MM:SS)</Typography>
                </ListItem>
              </List>
            </ContentCard>
          </Grid>
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard marginTop="0px" className={classes.contentCard}>
              <ContentCard.Header>
                Estado Animales - Ante Mortem
              </ContentCard.Header>
              <Chart
                type="doughnut"
                infoTotalData="Animales"
                data={[
                  {
                    label: 'Animales Agitados',
                    data: 2,
                    bg: '#FFC676',
                  },
                  {
                    label: 'Animales Caidos',
                    data: 2,
                    bg: '#F1AE6C',
                  },
                  {
                    label: 'Muertos Transporte',
                    data: 1,
                    bg: '#E29662',
                  },
                  {
                    label: 'Muertos Desembarque',
                    data: 1,
                    bg: '#D47D57',
                  },
                ]}
              />
            </ContentCard>
          </Grid>
        </Grid>
        <Grid container spacing={2} item sm={6} alignItems="stretch">
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard marginTop="0px" className={classes.contentCard}>
              <ContentCard.Header>Resumen Ingreso Corrales</ContentCard.Header>
              <div className={classes.resumeContainer}>
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={8}
                  titleInfo="Total lotes ingresados"
                  avatarTotalColor="#DD9835"
                  titleInfoSize={100}
                />
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={244}
                  titleInfo="Total Animales en Corrales"
                  avatarTotalColor="#DD9835"
                  titleInfoSize={100}
                />
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={6}
                  titleInfo="Total Animales Antemortem"
                  avatarTotalColor="#DD9835"
                  titleInfoSize={100}
                />
              </div>
            </ContentCard>
          </Grid>
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard marginTop="0px" className={classes.contentCard}>
              <ContentCard.Header>
                Animales en Corrales de Reposo
              </ContentCard.Header>
              <div className={classes.totalAnimalsContainer}>
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={240}
                  titleInfo="Total animales en reposo"
                  avatarTotalColor="#FFC676"
                />
                <div className={classes.listTotalContainer}>
                  <ItemListInfo
                    titleInfo="Línea de sacrificio"
                    numberInfo="00"
                  />
                  <ItemListInfo titleInfo="Muertos en reposo" numberInfo="01" />
                </div>
              </div>
            </ContentCard>
          </Grid>
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard marginTop="0px" className={classes.contentCard}>
              <ContentCard.Header>
                Animales en Corrales de Observación
              </ContentCard.Header>
              <div className={classes.totalAnimalsContainer}>
                <ItemTotalInfo
                  position="right"
                  avatarTotalInfo={6}
                  titleInfo="Total animales en observación"
                  avatarTotalColor="#D47D57"
                />
                <div className={classes.listTotalContainer}>
                  <ItemListInfo
                    titleInfo="Sala de beneficio de emergencia"
                    numberInfo="00"
                  />
                  <ItemListInfo
                    titleInfo="Muertos en observación"
                    numberInfo="01"
                  />
                </div>
              </div>
            </ContentCard>
          </Grid>
          <Grid item container xs={12}>
            <ContentCard marginTop="0px" className={classes.previewContainer}>
              <Typography variant="h6" className={classes.headerTitle}>
                Inventario de Animales en Tiempo Real
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="corral/real-time"
              >
                Ver
              </Button>
            </ContentCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="center">
        <Grid container spacing={4} item sm={12}>
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard marginTop="20px" className={classes.contentCard}>
              <div className={classes.titleDataGrid}>
                <Typography variant="h6" className={classes.headerTitle}>
                  Lotes Actualmente en Corrales
                </Typography>
                <div className={classes.headerChild}>
                  <Typography className={classes.result}>
                    <i className="icon-document"></i>
                    08 Resultados
                  </Typography>
                  <div className={classes.searchContainer}>
                    <TextField
                      placeholder="Lote Infoporcino"
                      variant="outlined"
                      size="small"
                    />
                    <IconButton className={classes.btnSearch}>
                      <i className="icon-search" />
                    </IconButton>
                  </div>
                </div>
              </div>
              <DataGrid
                columns={columns}
                rows={rows}
                autoHeight
                components={{
                  Footer: TableFooter,
                }}
                className={classes.dataGrid}
              />
            </ContentCard>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Main;
