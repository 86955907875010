import React, { useState } from "react";

import { Box, Grid, List, ListItem, Collapse } from "@material-ui/core";

import PageContainer from "components/PagesContainer";

import { routes } from "./views/";
import { GenerateMultilevelRoutes as Routes } from "routes";

import { Link, useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import { useSelector } from "react-redux";

import useStyles from "./styles";

const NewProfile = () => {
  const classes = useStyles();
  const { pathname: path } = useLocation();
  const base = "/app/profile";
  const history = useHistory();
  const userType = useSelector(
    (state: any) => state.user.selectedUserType.idUserType
  );
  const role = useSelector((state: any) => state.user.selectedUserType.idRole);
  const [open, setOpen] = useState<any>(null);

  const items = (id: number) => {
    switch (id) {
      case 1:
        return "Datos Generales";
      case 2:
        return userType === 1 ? "Actividad Producción" : "Actividad Comercial";
      case 3:
        return "Marco Legal";
      case 4:
        return "Configuración Avanzada";
      case 5:
        return "Suscripción";
      case 6:
        return "Aplicación Móvil";
      case 7:
        return "Darse De Baja";

      default:
        return "";
    }
  };

  const menuItems = _.groupBy(
    routes.filter((items: any) => items.display),
    "id"
  );

  return (
    <>
      <PageContainer>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Box className={classes.menuContainer}>
              <List className={classes.list}>
                {_.map(menuItems, (value, key) => {
                  return (
                    <>
                      {role !== 2 && Number(key) === 2 ? null : (
                        <ListItem
                          button
                          onClick={() => {
                            value.length > 1
                              ? open === Number(key)
                                ? setOpen(null)
                                : setOpen(Number(key))
                              : history.push(
                                  `${base}/${
                                    Number(key) === 1
                                      ? ""
                                      : Number(key) === 5
                                      ? "subscription"
                                      : Number(key) === 6
                                      ? "mobile-app"
                                      : "unsubscribe"
                                  }`
                                );
                          }}
                          className={clsx({
                            [classes.menuItemsContainer]: true,
                            selected: open === Number(key),
                            active:
                              path ===
                              `${base}/${
                                Number(key) === 1
                                  ? ""
                                  : Number(key) === 5
                                  ? "subscription"
                                  : Number(key) === 6
                                  ? "mobile-app"
                                  : Number(key) === 7
                                  ? "unsubscribe"
                                  : null
                              }`,
                          })}
                        >
                          <Box className={classes.menuItems}>
                            {items(Number(key))}
                            {value.length > 1 ? (
                              <i
                                className={
                                  open === Number(key)
                                    ? "icon-circle-arrow-top"
                                    : "icon-circle-arrow-bottom"
                                }
                              />
                            ) : null}
                          </Box>
                          {value.length > 1 ? (
                            <Collapse
                              in={open === Number(key)}
                              timeout="auto"
                              unmountOnExit
                              className={classes.subItemsContainer}
                            >
                              <List disablePadding>
                                {value.map((item) => {
                                  return userType === 1 &&
                                    (item.title === "Mis Puntos de Venta" ||
                                      item.title ===
                                        "Tipo Comercializador") ? null : userType ===
                                      2 &&
                                    item.title === "Mis Granjas" ? null : (
                                    <ListItem
                                      component={Link}
                                      to={`${base}${item.route}`}
                                      button
                                      className={clsx({
                                        [classes.menuItemsContainer]: true,
                                        selected: open,
                                        subitem: true,
                                        active: path === `${base}${item.route}`,
                                      })}
                                    >
                                      <Box className={classes.menuItems}>
                                        {item.title}
                                      </Box>
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </Collapse>
                          ) : null}
                        </ListItem>
                      )}
                    </>
                  );
                })}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Routes nested={routes} />
          </Grid>
        </Grid>
      </PageContainer>
    </>
  );
};

export default NewProfile;
