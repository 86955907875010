/** @format */

const whiteSacrifice = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAABRCAYAAACqj0o2AAAACXBIWX
MAACxKAAAsSgF3enRNAAAGRklEQVR4nO1cu47bRhQ9DlIGkFKlXG2VcuUvMF2mstIHWKZLZ+ULrH
xB6C8w9w+0nTtz6xThlqnCzQ9EatIkAAMCd5Dji+FD4h2ScngAQuRwOCQP79zXzOhZWZaY0Q+fzf
z1x0yiAWYSDTCTaICZRAPMJBrg/0DiGsAOwCrUDS7VT3TEHACkADJ1viJsC2AD4ErKjlJ+MH+ais
QL2lZlWablxyjo+ddlWeZlPTYh3vVSuvNSJO93ALfq3JVIJqTOjTr/RPtrBMCQJEYAEul6pdoKAH
sAsRCmUV33hsqqrnlPxxv5zansDsC1tBkUQ5AYC0kfALwG8MJTp5KmVwDeAfhT9JyPzAoPpBMdfH
VTuW8wg+LwecC2lyJdmrRHkUZW8GuR1IUc34p0bYWMPXXjvZATqXtBEVbI74bKtAGyQSCjUin4A6
n3an8nhqHpukrxZ8osVIYkouMd1dVlBZU5Q8TPEOR9hyAw60Ce3raqjfcewhIqi+Qah72nzi7Auw
YhcalcjNTwY2gycjkfe+pG8iyu7CDHQUi0NizsYty1WMZItjrFn8v5oxz/I78Z/a5FH2akTx/keE
Nl+yBOtoOxFLovX9R8+Ui6mkahdB1fy930Pd0r9ujPnK7l+6xDSaF1d96p7qTPx0pn7WRLyCA4Va
D1F5P1h+cjlFLHEbik8sLwHYOT6KQwbyAwb5CKJiPAltgnxXFD/SQ0iVZ+4pr0T+o5n4hui2p0Uy
xtHKkdxp7O/QXgF9F7exWl8PM47Hu/XQusSGTH1+fQ7oXcNuWeq19GJlHN3+p+bc/ja8sUViRy2O
V76Lb4Na2RYEYuJPoktQnhrLLgU03KulDzYYibzcMDBgiZgGBsqcvnLcre1QveDc1gZObZpdA+Yq
RckqZEgKvri3Odr+hzoercrWBJB96sujMbk01DvbuG7PKaJFRb+CXpuS7W1tVZNOQlzWBFYiF5Qj
Rkp4+SE/SNvMUU//7kIXFL+138Pr6+6aPawFCsOazj7riUrrhW4Vsux9z1dOQBT0ze5Vk4j5iF7s
7WDXJStC6820iKLKNt25Cq4kSCj+S6jT+YL5afLIlsROoyOadsnMHpYlD0xxpEGkM0migiz01DcT
uHM9vhnnFqdn1UEqEG2N34SlepjFRXPJdAKEneXhqJWpIcGUmNflrVJFn7EIihDExIEiF6qSj9OL
RM+UiNxkXc/S9qtE9vS+lKdWT6yLO0pizdQd5x6FlhbpC+csa/AvAdgC8A/Abgh0CD6zuagnJNg/
pmGCoB4ZCrsO0bIfHrIZKnEimZkzh2KoxDuFDhGWe5zQnEBCZ5Vt37V9l/CjT5qKCJns8CtD+6JO
aUuLjqMHZyKlZE4JNdsx9jCpnthPZ3DfXOQfgZYRMhMSUpedFDGmP5CJyG4xRaMBKH8BO7bBwmnu
MjcrLBTRDg1FzQDPfQLk4dONt9jsSwe3TjaSNBQExlCYZ7iMcek9Pd8MKVKu/TZidMQSfyC/ZxuH
Np695zLug4yxRI5Bfs6wwfxCL/SGU30m4waZyaJFpFFJUOfE5WfyFOfZDlGJ+aJDJ83ftdiPByKt
a5D2KSMGeVc/kgOS3l+FnOpeZr/CbgI7bNsG3aOHPdltjlvKLpSoJLn9BUSdv3Dedvacoe68OtZb
JjCiSyY3xOyJdKdqbaXnpcnFdCWkX4WylbWMbpU5PEvv5cVuNr7mg9oFvScWuVNZqaJFr7ci7Ntq
DpziyBJtI4FUnkLE5f8IfYUts34j+ytPfJGv2HiWRx2HL2XR3Py9MgGZ06HCxmRkxBEleG3ZiXoj
kDk8t0PQ03V7K/gz8BKeRcYt+FOzwZQM8gc7PLMutZYmOnwpay0h4G/xbCUUmoQS8vxu7O7AAnRO
D6RIUfE4G63fAYuSvrlaC+6SZJhzk5uv6g7zF2d849f8Hiw1GMhm/ogFVC8Cy2D2N35zoC7ylEg1
jcDzXO8aCLIX0Ym8Qj7T9KMuFLSl89p6gDMjEp6KDTORibxEhS+dciUXolqkusslS+HmJtyikYOy
mrZ4nVYStkurAwputGl8xL+te6SPRiE74dQy9e2l//FZ5xZYdRLDMukMSNh6hc9Gi4uTYtmP+s3A
DzonEDzCQaYCbRADOJBphJNMBMogFmEvsCwL/cOwdGjwYd7QAAAABJRU5ErkJggg==`;

export default whiteSacrifice;
