import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  DialogActions,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import useStyles from './styles';

type DialogProps = {
  open: boolean;
  icon: 'warning' | 'error' | 'success' | string;
  children?: any;
  title?: string;
  desciption?: string;
  data?: string;
  handleConfirm: (event: any) => void;
  handleClose: (event: any) => void;
};

const DialogTransportNotification = ({
  open,
  icon,
  children,
  title,
  desciption,
  data,
  handleConfirm,
  handleClose,
}: DialogProps) => {
  const classes = useStyles();

  const assignIcon = (icon: string) => {
    switch (icon) {
      case 'warning':
        return 'icon-circle-alert';
        break;
      case 'error':
        return 'icon-circle-close';
        break;
      case 'success':
        return 'icon-circle-check';
        break;
      default:
        return icon;
        break;
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        className={classes.borderRadiudDialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Grid container justifyContent="center">
            <i className={clsx(assignIcon(icon), classes.icon)}></i>
          </Grid>
          <Grid container justifyContent="center">
            {title && (
              <Grid item xs={12} className={classes.titleContainer}>
                <Typography className={classes.fontTitle}>{title}</Typography>
              </Grid>
            )}
            {children}
            {data && (
              <Grid item xs={12}>
                <Typography className={classes.fontData}>{data}</Typography>
              </Grid>
            )}
            {desciption && (
              <Grid item xs={12}>
                <Typography className={classes.fontDescription}>
                  {desciption}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid component={Box} container justify="center" mb="15px" mt="20px">
            <Box mr={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Box>

            <Button variant="contained" color="primary" onClick={handleConfirm}>
              Aceptar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DialogTransportNotification;
