import React from "react";

import { GenerateRoutes as Routes } from "routes";

import PagesContainer from "components/PagesContainer";

import { Main, Details, routes } from "./views";

const Users = () => {
  return (
    <PagesContainer>
      <Routes MainPage={Main} DetailsPage={Details} nested={routes} />
    </PagesContainer>
  );
};

export default Users;
