import Rect, { useState } from 'react';

import {
  Grid,
  TextField,
  MenuItem,
  Box,
  Typography,
  Fab,
  Grow,
} from '@material-ui/core';

import SelectDate from 'components/SelectDate';
import Results from 'components/Results';
import constants from 'config/constants';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ES from 'date-fns/locale/es';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useParams, useLocation } from 'react-router-dom';

import useStyles from './styles';

interface FilterShareReportsProps {
  onChangeReportType: any;
  onChangeRange: any;
}

const FilterShareReports = ({
  onChangeRange,
  onChangeReportType,
}: FilterShareReportsProps) => {
  const classes = useStyles();
  let params: any = useParams();
  const route = useLocation().pathname;
  const [filterBy, setFilterBy] = useState(1);
  const [reportType, setReportType] = useState(params.type);
  const [initialDate, setInitialDate] = useState(dayjs().startOf('M'));
  const [finalDate, setFinalDate] = useState(dayjs());
  const [range, setRange] = useState(false);

  const handleFilter = (event: any) => {
    setReportType(event.target.value);
    onChangeReportType(event.target.value);
  };

  const handleRange = () => {
    setRange(!range);
  };

  const handleFilterRange = (event: any) => {
    setFilterBy(event.target.value);
    if (event.target.value === 1) {
      setRange(false);
      setInitialDate(dayjs().startOf('M'));
      setFinalDate(dayjs());
      onChangeRange([initialDate, finalDate]);
    } else setRange(true);
  };

  const changeRange = () => {
    onChangeRange([initialDate, finalDate]);
    setRange(false);
  };

  return (
    <>
      <Grid container xs={12} spacing={2} className={classes.cardHeader}>
        <Grid item xs={4}>
          <Typography
            variant="h5"
            color="secondary"
            style={{ fontWeight: 'bold' }}
          >
            {reportType === 'Todos'
              ? `${reportType} los Reportes`
              : `Reportes ${reportType} `}
            {route.includes('details-client') ||
            route.includes('frequent-details') ? (
              <>
                {` con `}
                <Typography variant="inherit" color="primary">
                  Leidy Álvarez
                </Typography>
              </>
            ) : null}
          </Typography>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            <Typography variant="inherit" color="primary">
              {dayjs(initialDate).format('DD/MM/YYYY')}{' '}
              <Typography variant="inherit" color="secondary">
                hasta
              </Typography>{' '}
              {dayjs(finalDate).format('DD/MM/YYYY')}{' '}
              <Typography variant="inherit" color="secondary">
                {filterBy === 2 ? `(Rango de fecha)` : `(Mes Actual)`}
              </Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={8}
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Results number={4} />
          <Grid item xs={5} lg={3}>
            <Box display="flex" width="100%">
              <TextField
                variant="outlined"
                size="small"
                select
                label="Filtro por Tiempo"
                value={filterBy}
                fullWidth
                onChange={handleFilterRange}
              >
                <MenuItem value={1} key={1}>
                  Mes Actual
                </MenuItem>
                <MenuItem value={2} key={2}>
                  Rango de Fecha
                </MenuItem>
              </TextField>

              {filterBy === 2 ? (
                <Box className={classes.iconButton}>
                  <Fab size="small" color="primary" onClick={handleRange}>
                    <i className="icon-calendar" style={{ fontSize: 20 }} />
                  </Fab>
                </Box>
              ) : null}
            </Box>
            <Grow in={range}>
              <Box className={clsx(classes.rangeContainer)}>
                <Box mb={2}>
                  <Typography variant="h6">Parámetros de Búsqueda</Typography>
                </Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ES}>
                  <Box width="100%" display="flex">
                    <Grid container xs={12} spacing={2} alignItems="center">
                      <Grid item xs={5}>
                        <DatePicker
                          autoOk
                          label="Fecha Inicial"
                          value={initialDate}
                          onChange={(date: any) => setInitialDate(date)}
                          fullWidth
                          inputVariant="outlined"
                          size="small"
                          variant="inline"
                          maxDate={dayjs()}
                          maxDateMessage="La fecha final no puede ser mayor a la fecha actual"
                          labelFunc={() =>
                            dayjs(initialDate).format('DD/MM/YYYY')
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="subtitle1"
                          color="secondary"
                          align="center"
                        >
                          Hasta
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <DatePicker
                          autoOk
                          label="Fecha Final"
                          value={finalDate}
                          onChange={(date: any) => {
                            setFinalDate(date);
                          }}
                          fullWidth
                          inputVariant="outlined"
                          size="small"
                          variant="inline"
                          minDate={initialDate}
                          minDateMessage="La fecha final debe ser mayor a la inicial"
                          maxDate={dayjs()}
                          maxDateMessage="La fecha final no puede ser mayor a la fecha actual"
                          labelFunc={() =>
                            dayjs(finalDate).format('DD/MM/YYYY')
                          }
                        />
                      </Grid>
                    </Grid>
                    <Box className={classes.iconButton}>
                      <Fab size="small" color="primary" onClick={changeRange}>
                        <i className="icon-search" style={{ fontSize: 20 }} />
                      </Fab>
                    </Box>
                  </Box>
                </MuiPickersUtilsProvider>
              </Box>
            </Grow>
          </Grid>

          <Grid item xs={4} lg={3}>
            <TextField
              variant="outlined"
              size="small"
              select
              label="Tipo de reporte"
              value={reportType}
              fullWidth
              onChange={handleFilter}
            >
              {constants.REPORTS_TYPES.map((item: any) => {
                return (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FilterShareReports;
