import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  MenuItem,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import DocumentTypes from "components/DocumentTypes";
import TextFieldNumber from "components/TextFieldNumber";
import PhoneNumber from "components/PhoneNumber";
import { useFormik } from "formik";
import unmaskPhone from "lib/helpers/unmaskPhone";
import { UpdateInternalUserDataValidations } from "lib/validations/InternalUsersValidations";
import { UPDATE_INTERNALUSER } from "core/graphql/mutations/internalUsers";
import { POSITIONS } from "core/graphql/queries/positions";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import useStyles from "./styles";

interface UpdateDataProps {
  uid: Number;
  refetchDetails: any;
  data: any;
}

const EditUserData = ({ uid, refetchDetails, data }: UpdateDataProps) => {
  const classes = useStyles();

  const token = useSelector((state: any) => state.user.token);
  const idUserType = useSelector(
    (state: any) => state.user.selectedUserType.idUserType
  );
  const [updateData, { loading }] = useMutation(UPDATE_INTERNALUSER);
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading: loadingPositions,
    error: errorPositions,
    data: dataPositions,
  } = useQuery(POSITIONS, {
    context: { headers: { "x-auth-jwt": token } },
  });

  const [openEditData, setOpenEditData] = React.useState(false);
  const handleOpenEditData = () => {
    setOpenEditData(true);
  };

  const formik = useFormik({
    initialValues: {
      firstName: data?.primerNombre,
      secondName: data?.segundoNombre,
      firstSurname: data?.primerApellido,
      secondSurname: data?.segundoApellido,
      documentType: Number(data?.idTipoDocumento),
      documentNumber: Number(data?.numeroDocumento),
      idPosition: data?.idCargo,
      indicativePhone: data?.indicativoCelular,
      phoneNumber: data?.celular,
      email: data?.email,
      username: data?.usuario,
      password: "",
      idRole: data?.idRol,
    },
    enableReinitialize: true,
    validationSchema: UpdateInternalUserDataValidations,
    onSubmit: (values, { resetForm }) => {
      updateData({
        variables: {
          ...values,
          documentNumber: String(data?.numeroDocumento),
          idTipoUsuario: Number(idUserType),
          idUsuarioInterno: uid,
        },
        context: { headers: { "x-auth-jwt": token } },
      })
        .then((data) => {
          if (data?.data?.putInternalUser?.data?.success === 1) {
            enqueueSnackbar(`${data?.data?.putInternalUser?.data?.message}`, {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
            });
            resetForm();
            refetchDetails();
            setOpenEditData(false);
          } else {
            enqueueSnackbar(`${data?.data?.putInternalUser?.data?.message}`, {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(`Ha ocurrido un error. ${error.message}`, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
        });
    },
  });

  const handleCloseEditData = () => {
    formik.resetForm();
    setOpenEditData(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.btn}
        onClick={handleOpenEditData}
      >
        Editar Datos
      </Button>
      <Dialog
        open={openEditData}
        onClose={handleCloseEditData}
        maxWidth="md"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogTitle>
          <Typography variant="h5" className={classes.title} color="secondary">
            Datos Generales
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.formContainer}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    label="Primer Nombre"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.firstName)}
                    helperText={formik.errors.firstName}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Segundo Nombre"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="secondName"
                    value={formik.values.secondName}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Primer Apellido"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="firstSurname"
                    value={formik.values.firstSurname}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.firstSurname)}
                    helperText={formik.errors.firstSurname}
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Segundo Apellido"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="secondSurname"
                    value={formik.values.secondSurname}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DocumentTypes
                    entity={1}
                    name="documentType"
                    value={formik.values.documentType}
                    onChange={formik.handleChange}
                    errors={formik.errors.documentType}
                    helperText={formik.errors.documentType}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFieldNumber
                    label="Número de documento"
                    name="documentNumber"
                    error={formik.errors.documentNumber}
                    value={formik.values.documentNumber}
                    helperText={formik.errors.documentNumber}
                    onChange={formik.handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <PhoneNumber
                    type="mobile"
                    names={{
                      indicative: "indicativePhone",
                      phone: "phoneNumber",
                    }}
                    values={{
                      indicative: Number(formik.values.indicativePhone),
                      phone: (formik.values.phoneNumber = unmaskPhone(
                        formik.values.phoneNumber
                      )),
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative:
                        !!formik.errors.indicativePhone &&
                        !!formik.touched.indicativePhone,
                      phone: formik.errors.phoneNumber as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.indicativePhone as string,
                      phone: formik.errors.phoneNumber as string,
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    label="Cargo"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="idPosition"
                    value={formik.values.idPosition}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.idPosition)}
                    helperText={formik.errors.idPosition}
                    select
                    required
                  >
                    {loadingPositions ? (
                      <CircularProgress variant="indeterminate" size={25} />
                    ) : errorPositions ? (
                      <p>Error</p>
                    ) : (
                      dataPositions.positions.map((positions: any) => (
                        <MenuItem key={positions.id} value={positions.id}>
                          {positions.description}
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Correo Electrónico"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.email)}
                    helperText={formik.errors.email}
                    required
                  />
                </Grid>
              </Grid>
            </div>

            <DialogActions className={classes.dialogFooter}>
              <Button
                variant="contained"
                onClick={handleCloseEditData}
                color="secondary"
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                endIcon={
                  loading && (
                    <CircularProgress size={20} style={{ color: "#FFF" }} />
                  )
                }
              >
                Actualizar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditUserData;
