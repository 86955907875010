import axios from "axios";

import config from "config/secrets";

export interface DocTypes {
  value: number;
  alias: string;
  description: string;
}

interface DocTypeResponse {
  idTipoDocumento: number;
  abreviatura: string;
  descripcion: string;
}

const getDocTypes = async (): Promise<Array<DocTypes>> => {
  return await axios
    .get(`${config.SOCKETS}${config.APP_URI}/document_types`)
    .then(({ data }) => {
      if (data.success) {
        return data.data.map((value: DocTypeResponse) => ({
          value: value.idTipoDocumento,
          alias: value.abreviatura,
          description: value.descripcion,
        }));
      }
      return [];
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export { getDocTypes as default };
