import React from 'react';
import { Typography, Grid, Tooltip, Fab, Box, Button } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import useStyles from './styles';
import clsx from 'clsx';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import FilterReports from 'components/FilterReports';
import { Link } from 'react-router-dom';
import ItemListInfo from 'components/ItemListInfo';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import CurrentDateIndicator from 'components/CurrentDateIndicator';
import dayjs from 'dayjs';
import Chart from 'components/Chart';
import Results from 'components/Results';

const index = () => {
  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalCanales',
      headerName: 'Total Canales',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'canalesdecomisadas',
      headerName: 'Canales Completas Decomisadas',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'canalesdecomisos',
      headerName: 'Canales con Decomisos',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalDecomisadas',
      headerName: 'Total Partes Decomisadas',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: '',
      headerName: '',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/post-mortem/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      loteIP: '654372',
      totalCanales: '68',
      canalesdecomisadas: '2',
      canalesdecomisos: '18',
      totalDecomisadas: '46',
    },
    {
      id: 2,
      loteIP: '654372',
      totalCanales: '68',
      canalesdecomisadas: '2',
      canalesdecomisos: '18',
      totalDecomisadas: '46',
    },
    {
      id: 3,
      loteIP: '654372',
      totalCanales: '68',
      canalesdecomisadas: '2',
      canalesdecomisos: '18',
      totalDecomisadas: '46',
    },
    {
      id: 4,
      loteIP: '654372',
      totalCanales: '68',
      canalesdecomisadas: '2',
      canalesdecomisos: '18',
      totalDecomisadas: '46',
    },
    {
      id: 5,
      loteIP: '654372',
      totalCanales: '68',
      canalesdecomisadas: '2',
      canalesdecomisos: '18',
      totalDecomisadas: '46',
    },
    {
      id: 6,
      loteIP: '654372',
      totalCanales: '68',
      canalesdecomisadas: '2',
      canalesdecomisos: '18',
      totalDecomisadas: '46',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/post-mortem',
      title: 'Post Mortem',
    },

    { link: '/app/reports', title: 'Mis Reportes' },
  ];

  const initDate = dayjs().startOf('month').format('YYYY-MM-DDT00:00:00-05:00');
  const current = dayjs().format('YYYY-MM-DDT23:59:59-05:00');

  return (
    <div>
      <div className={classes.breadContainer}>
        <CurrentDateIndicator initialDate={initDate} finalDate={current} />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <FilterReports to="/app/reports/post-mortem/search" />

      <Grid container spacing={2} direction="row">
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Resumen Decomisos de Canales
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.paddingContainer
                  )}
                >
                  <div>
                    <Chart
                      type="doughnut"
                      withoutData={true}
                      withoutPorcentage={true}
                      infoTotalData=""
                      data={[
                        {
                          label: 'LB',
                          data: 3,
                          bg: '#D47D57',
                        },
                        {
                          label: 'SBE',
                          data: 1,
                          bg: '#FFC676',
                        },
                      ]}
                    />
                  </div>

                  <div>
                    <div className={classes.marginTop}>
                      <ItemTotalInfo
                        position="right"
                        avatarTotalInfo={'2,5%'}
                        titleInfo="Porcentaje Total Decomisos"
                        avatarTotalColor="#F1AE6C"
                        avatarTotalSize={35}
                        avatarTextSize={22}
                        extended
                        titleInfoSize={200}
                      />
                    </div>

                    <div className={classes.marginTop}>
                      <ItemTotalInfo
                        position="right"
                        avatarTotalInfo={516}
                        titleInfo="Total (Kgs) Carne Decomisada"
                        avatarTotalColor="#D47D57"
                        avatarTotalSize={35}
                        avatarTextSize={22}
                        extended
                        titleInfoSize={200}
                      />
                    </div>
                  </div>
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Decomisos Línea de Beneficio (LB)
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.paddingContainer
                  )}
                >
                  <div>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={3}
                      titleInfo="Canales Completas Decomisadas"
                      avatarTotalColor="#D47D57"
                      avatarTotalSize={60}
                      avatarTextSize={22}
                      titleInfoSize={250}
                    />
                  </div>

                  <div>
                    <div className={classes.marginTop}>
                      <ItemTotalInfo
                        position="right"
                        avatarTotalInfo={'2%'}
                        titleInfo="Porcentaje Total Decomisos"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={22}
                        extended
                        titleInfoSize={200}
                        titleInverted
                      />
                    </div>
                    <div className={classes.marginTop}>
                      <ItemTotalInfo
                        position="right"
                        avatarTotalInfo={'462 Kg'}
                        titleInfo="Total (Kgs) Carne Decomisada"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={22}
                        extended
                        titleInfoSize={200}
                        titleInverted
                      />
                    </div>
                  </div>
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Decomisos Sala de Beneficio de Emergencia (SBE)
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.paddingContainer
                  )}
                >
                  <div>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={1}
                      titleInfo="Canales Completas Decomisadas"
                      avatarTotalColor="#FFC676"
                      avatarTotalSize={60}
                      avatarTextSize={22}
                      titleInfoSize={250}
                    />
                  </div>

                  <div>
                    <div className={classes.marginTop}>
                      <ItemTotalInfo
                        position="right"
                        avatarTotalInfo={'0,5%'}
                        titleInfo="Porcentaje Total Decomisos"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={22}
                        extended
                        titleInfoSize={200}
                        titleInverted
                      />
                    </div>

                    <div className={classes.marginTop}>
                      <ItemTotalInfo
                        position="right"
                        avatarTotalInfo={'54 Kg'}
                        titleInfo="Total (Kgs) Carne Decomisada"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={22}
                        extended
                        titleInfoSize={200}
                        titleInverted
                      />
                    </div>
                  </div>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Resumen Partes Decomisadas
                  </Typography>
                </div>

                <Chart
                  type="doughnut"
                  infoTotalData=""
                  data={[
                    {
                      label: 'Pulmón',
                      data: 46,
                      bg: '#FFC676',
                    },
                    {
                      label: 'Piel',
                      data: 23,
                      bg: '#F1AE6C',
                    },
                    {
                      label: 'Vísceras Rojas',
                      data: 11,
                      bg: '#E29662',
                    },
                    {
                      label: 'Pierna',
                      data: 17,
                      bg: '#D47D57',
                    },
                    {
                      label: 'Corazón',
                      data: 6,
                      bg: '#BE5948',
                    },
                    {
                      label: 'Vísceras Blancas',
                      data: 6,
                      bg: '#B0413E',
                    },
                  ]}
                />
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Resumen Causas Decomisos
                  </Typography>
                </div>

                <Chart
                  type="bar"
                  data={[
                    {
                      label: 'Causa',
                      data: [110, 70, 20, 20, 320, 80, 20],
                      bg: '#E29662',
                    },
                  ]}
                  weightBar={50}
                  withoutLabel={true}
                  graphicSize={550}
                  labels={[
                    'Causa',
                    'Causa',
                    'Causa',
                    'Causa',
                    'Causa',
                    'Causa',
                  ]}
                  max={400}
                  position={'top'}
                />
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ContentCard>
            <div className={classes.headerContent}>
              <div>
                <Typography className={classes.paperTitles}>
                  Detalle Inspección Post Mortem del día
                </Typography>
              </div>
              <div>
                <Results number={rows.length} />
              </div>
            </div>
            <div>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
              />
            </div>
          </ContentCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default index;
