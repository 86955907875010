const initialState = {
  isLogged: false,
  isEmployee: false,
  loading: false,
  hasError: false,
  errorMessage: '',
  user: {},
  selectedUserType: {},
  typeUsers: [],
  openModalSelect: false,
  navigation: [],
  navigationStore: [],
  timeout: false,
  subscriptionPlan: {},
};

export default initialState;
