import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  agentsTitle: {
    fontWeight: 600
  },
  agentsAvatar: {
    background: theme.palette.primary.main,
    fontSize: 28,
    marginRight: theme.spacing(2)
  }
}))