import React, { useEffect } from 'react';
import { Text, View } from '@react-pdf/renderer';

import _ from 'lodash';
import clsx from 'clsx';

import styles from './styles';

interface ChartListProps {
  data: any;
  removepercentages?: boolean;
}

const ChartListMagro = ({ data, removepercentages }: ChartListProps) => {
  const dataObject = data.sort(function (a: any, b: any) {
    return b.Porcentaje - a.Porcentaje;
  });

  const dataPrepare = (array: any) => {
    const bgs = [
      '#ffc676',
      '#f1ae6c',
      '#e29662',
      '#d47d57',
      '#be5948',
      '#b0413e',
    ];
    let cont = -1;
    let sumaCantidad = 0;
    let sumaPorcentaje = 0;
    const dataR = [];

    array.map((item: any) => {
      if (item.Porcentaje < 1) {
        sumaCantidad += item.Cantidad;
        sumaPorcentaje += item.Porcentaje;
      }
    });

    array.map((item: any) => {
      if (item.Porcentaje > 1) {
        cont++;
        dataR.push({
          label: item.parte,
          data: item.Porcentaje,
          cantidad: item.Cantidad,
          bg: bgs[cont],
        });
      }
    });

    dataR.push({
      label: 'MENOS DEL 1%',
      data: sumaPorcentaje,
      cantidad: sumaCantidad,
      bg: '#afafaf',
    });

    return dataR;
  };

  return (
    <View style={styles.labelsBar}>
      {dataPrepare(dataObject).map((item: any) => (
        <View style={styles.itemLabels}>
          <View style={styles.titleLabelBar}>
            <View
              style={{
                borderRadius: 100,
                width: 5,
                height: 5,
                backgroundColor: item.bg,
                marginTop: 2,
                marginRight: 5,
              }}
            ></View>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ marginRight: 5 }}>
                <Text style={{ fontWeight: 'bold' }}>
                  {`${
                    item.data > 10
                      ? item.data.toFixed(3)
                      : '0' + item.data.toFixed(3)
                  }%`}
                </Text>
              </View>
              <View style={{ marginRight: 5 }}>
                <Text style={{ fontWeight: 'bold' }}>
                  ({item.cantidad < 10 ? `0${item.cantidad}` : item.cantidad})
                </Text>
              </View>
              <Text>{item.label}</Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
};
export default ChartListMagro;
