import { gql } from "apollo-boost";

export const DASHBOARD = gql`
  query getDashboard($initialDate: String!, $finalDate: String!) {
    dashboard: getDashboardInfotraking(
      fechaInicial: $initialDate
      fechaFinal: $finalDate
    ) {
      data {
        stats:datosLotes {
          total:totalLotes
          differentFarmers:productoresDiferentes
          wait:lotesEnEspera
          process:lotesEnProceso
          finish:lotesEnFinalizado
        }
        averages:promedioTiempos {
          transport:transporteMinutos
          arrival:arriboSBMinutos
          corrals:corralesHoras
          sacrifice:beneficiosMinutos
          cold:cavasMinutos
          despatch:despachosMinutos
        }
        processTraceability:trazabilidadProcesos {
          _id:idLote
          initialDate:fechaInicio
          code:codigoLote
          process:proceso {
            name:nombreProceso
            state:estado
          }
        }
      }
      token {
        value:tokenNuevo
      }
    }
  }
`

export const TRANSPORT_LOTE_QUERY = gql`
  query transportByLote($loteIP: Int!) {
    entrance:reportPorteriaByIdLote(idLoteIP: $loteIP) {
      data {
        LoteID
        productor {
          name:nombreCompleto
        }
        farm:granja {
          name:nombre
          city:ciudad
          sidewalk:vereda
        }
        negociation:negociacion {
          _id:numeroNegociacion
          date:fechaNegociacion
        }
        entrance:datosGeneralesPorteria {
          arrivalDate:fechaLlegadaPlanta
          entryDate:fechaIngresoPlanta
          time:tiempoPorteria
          plate:placaVehiculo
          driverName:nombreConductor
          responsable
          leaveDate:fechaSalida
          initDisembarkation:inicioDesembarque
          timeDisembarkation:tiempoDesembarque
        }
        remision {
          _id:numeroRemision
          remitedAnimals:cantidadAnimalesRemisionados
          loteCeba
          date:fechaRemision
        }
      }
      token {
        value:tokenNuevo
      }
    }
    corrals:reportCorralesByIdLote(idLoteIP: $loteIP) {
      data {
        disembarkation:DatosCorrales {
          downAnimals:muertosDesembarque
        }
        immunocastrationCertificate:certificadoInmunoCastracion {
          _id:numeroCertificado
        }
        details:detallePesajes {
          type:tipoCerdo
          quantity:animalesPesados
        }
      }
    }
  }
`

export const ARRIVAL_LOTE_QUERY = gql`
  query arrival($loteIP: Int!) {
    entrance:reportPorteriaByIdLote(idLoteIP: $loteIP) {
      data {
        LoteID
        GSMI {
          value:numeroGSMI
        }
        general:datosGeneralesPorteria {
          arrivalDate:fechaLlegadaPlanta
          entryDate:fechaIngresoPlanta
          leaveDate:fechaSalida
          stayHours:horasEstanciaPlanta
          stayMinutes:minutosEstanciaPlanta
          driver:nombreConductor
          plate:placaVehiculo
          time:tiempoPorteria
        }
        farmer:productor {
          city:ciudad
        }
        farm:granja {
          name:nombre
          city:ciudad
          sidewalk:vereda
        }
        remision {
          id:numeroRemision
          remitedAnimals:cantidadAnimalesRemisionados
          date:fechaRemision
        }
      }
      token {
        value:tokenNuevo
      }
    }
    corrals:reportCorralesByIdLote(idLoteIP: $loteIP) {
      data {
        disembarkation:DatosCorrales {
          downAnimals:muertosDesembarque
        }
        immunocastrationCertificate:certificadoInmunoCastracion {
          _id:numeroCertificado
        }
        details:detallePesajes {
          type:tipoCerdo
          quantity:animalesPesados
        }
      }
    }
  }
`

export const CORRALS_LOTE_QUERY = gql`
  query getCorrals($idLoteIP: Int!) {
    entrance:reportPorteriaByIdLote(idLoteIP: $idLoteIP){
      data{
        LoteID
        farmer:productor{
          fullName:nombreCompleto
        }
        marketer:comercializador{
          fullName:nombreCompleto
        }
        remision{
          loteCeba
        }
        entrance:datosGeneralesPorteria{
          startDisembarkation:inicioDesembarque
          endDisembarkation:finDesembarque
        }
      }
    }
    corrals:reportCorralesByIdLote(idLoteIP: $idLoteIP){
      data{
        LoteID
        transport:DatosCorrales{
          transportDown:caidosTransporte
          transportDie:muertosTransporte
          transportAgitated:agitadosTransporte
          dieDisembarkation:muertosDesembarque
          restingCorralTotalAnimals:totaAnimalesCorralReposo
        }
        details:DetalleCorrales{
          entryDate:fechaIngreso
          quantity:cantidadAnimales
          id:numeroCorral
        }
        weighing:DatosPesaje{
          animalQuantity:cantidadAnimalesPesados
          tiquetDate:fechatiqueteBascula
          tiquetNumber:numeroTiqueteBascula
          average:promedioPesoTotal
          total:pesoTotalDeTodosLosPesajes
        }
        weighingDetails:detallePesajes{
          _id:numeroPesaje
          date:fechaPesaje
          totalAnimals:animalesPesados
          bumpWeight:PesoTotalBache
          weighingAnimals:animalesPesados
          stateInmuno:estadoInmunocastrados
          totalWeightState:estadoPesoManual
          bumpAverage:promedioBache
          genre:sexo
          pigType:tipoCerdo
        }
      }
      token {
        value:tokenNuevo
      }
    }
  }
`
