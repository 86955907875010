export const SET_USER_TYPE = 'SET_USER_TYPE';

export const SIGN_IN = 'SIGN_IN';

export const SIGN_OUT = 'SIGN_OUT';

export const LOADING = 'LOADING';

export const ERROR = 'ERROR';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const GET_NAVIGATION_MENU = 'USER_REDUCER::GET_NAVIGATION_MENU';

export const SET_TOKEN = 'USER_REDUCER::SET_TOKEN';

export const STORE_NAVIGATION_MENU = 'USER_REDUCER::STORE_NAVIGATION_MENU';

export const RESTART_CONTROLS = 'RESTART_CONTROLS';

export const SET_INACTIVITY = 'SET_INACTIVITY';

export const DELETE_INACTIVITY_ID = 'DELETE_INACTIVITY_ID';

export const TIMEOUT_TOGGLE = 'TIMEOUT_TOGGLE';

export const NEW_AVATAR = 'USER_REDUCER::NEW_AVATAR';

export const SUBSCRIPTION_TYPE = 'SUBSCRIPTION_TYPE'
