import React, { useState } from 'react';
import {
  isSameDay,
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  format,
} from 'date-fns';
import { DatePicker } from '@material-ui/pickers';
import { Fab, Typography } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';

import makeJSDateObject from 'lib/date/makeJSDateObject';
import ES from 'date-fns/locale/es';

import useStyles from './styles';

export default ({ onChange = (value: any[]) => {} }) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(moment());

  const handleChangeDate = (date: any) => {
    const selectedDateClone = makeJSDateObject(date);
    setSelectedDate(date);
    onChange([
      moment(startOfWeek(selectedDateClone)),
      moment(endOfWeek(selectedDateClone)),
    ]);
  };

  const renderWeekPickerDay = (
    date: any,
    _selectedDays: any,
    DayComponentProps: any
  ) => {
    const dateClone = makeJSDateObject(date);
    const selectedDateClone = makeJSDateObject(selectedDate);
    const start = startOfWeek(selectedDateClone);
    const end = endOfWeek(selectedDateClone);

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    return (
      <div
        className={clsx({
          [classes.highlight]: dayIsBetween,
          [classes.firstHighlight]: isFirstDay,
          [classes.endHighlight]: isLastDay,
        })}
      >
        <Fab
          className={clsx(classes.day, {
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
          })}
          size="small"
        >
          <Typography>{format(dateClone, 'd')}</Typography>
        </Fab>
      </div>
    );
  };
  const date = `Semana del ${format(
    makeJSDateObject(selectedDate),
    "d 'de' MMMM",
    { locale: ES }
  )}`;

  return (
    <DatePicker
      value={selectedDate}
      onChange={handleChangeDate}
      variant="inline"
      fullWidth
      inputVariant="outlined"
      size="small"
      renderDay={renderWeekPickerDay}
      labelFunc={() => date}
    />
  );
};
