import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    textAlign: 'center',
    padding: 30,
    fontSize: 8,
    fontFamily: 'Roboto',
    fontWeight: 300,
  },
  backgroundresumetitle: {
    backgroundColor: '#ededed',
    fontSize: 8,
    justifyContent: 'center',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    paddingTop: 3,
    paddingBottom: 3,
  },
  imageicon: {
    backgroundColor: '#F49A28',
    borderRadius: 50,
    width: 30,
    height: 30,
    marginLeft: 10,
    justifyContent: 'center',
    position: 'absolute',
    padding: 3,
  },
  tableMagro: {
    display: 'table',
    width: '48.3%',
    borderStyle: 'solid',
  },
  tableCellDetail: {
    height: '12px',
    fontSize: 8,
    marginTop: 5,
    textAlign: 'center',
  },
  tableCellVisceras: {
    height: '13px',
    fontSize: 8,
    marginTop: 5,
    textAlign: 'center',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '16.5%',
  },
  tableCell: {
    marginTop: 5,
    fontSize: 8,
    textAlign: 'center',
  },
  tableHead: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  conventions: {
    marginTop: 30,
  },
  backgroundMagro: {
    backgroundColor: '#ededed',
    height: 65,
    width: '100%',
    margin: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    // flexDirection: 'row',
  },
  textconventionsST: {
    marginTop: 5,
    flexDirection: 'row',
  },
  gmagroC: {
    top: 8,
    width: 50,
    height: 20,
    textAlign: 'left',
  },
  textMagro: {
    width: 80,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textMagroC: {
    width: 85,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  gmagroCE: {
    top: 8,
    width: 40,
    height: 20,
    textAlign: 'left',
  },
  gmagroST: {
    top: 8,
    width: 30,
    height: 20,
    textAlign: 'left',
  },
  textST: {
    width: 80,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  textconventions: {
    flex: 2,
    flexDirection: 'row',
    flexGrow: 2,
    marginTop: 3,
  },
  gmagroS: {
    top: 9,
    width: 80,
    height: 20,
    textAlign: 'left',
  },
  gmagroSEUROP: {
    top: 9,
    width: 20,
    height: 20,
  },
  textMagroSEUROP: {
    width: 50,
    top: 4,
    height: 20,
    justifyContent: 'center',
    textAlign: 'left',
  },
  borderMagro: {
    border: '1 solid #EDEDED',
    width: 70,
    height: 20,
    justifyContent: 'center',
    margin: 'auto',
    backgroundColor: '#ffffff',
    top: -75,
    position: 'relative',
    borderRadius: 3,
  },
});
