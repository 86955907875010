import React from 'react';

import { GenerateRoutes as Routes } from 'routes';

import PagesContainer from 'components/PagesContainer';

import { Main, Search, Details, routes } from './views';

const Antemortem = () => {
  return (
    <PagesContainer>
      <Routes
        MainPage={Main}
        SearchPage={Search}
        DetailsPage={Details}
        nested={routes}
      />
    </PagesContainer>
  );
};

export default Antemortem;
