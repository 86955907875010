import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  breadContainer:{
    display:'flex',
    justifyContent:'flex-end',
    margin: '8px 0',
  }, 
  cardContainer:{
    minHeight: 530
  },
  cardHeader:{
    display:'flex', 
    justifyContent:'space-between'
  }, 
  title:{
    fontWeight:'bold', 
    fontSize:24
  }, 
  search:{
    width:'30%'
  },
  containerTab:{
    padding: 12
  },
  tabSmall:{
    fontWeight:900,
    minWidth: 50,
    textTransform:'capitalize', 
    fontSize:14
  },
})); 
export default useStyles;
