import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  input: {
    background: 'white',
    borderRadius: '20px',
  },

  autocomplete: {
    width: 300,
    marginLeft: 10,
  },
}));
