import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    textAlign: 'center',
  },
  dialogContent: {
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '20px',
    },
  },
  textEdit: {
    color: '#767676',
    paddingBottom: 10,
  },
  titleDialog: {
    fontSize: 24,
    fontWeight: 600,
    paddingBottom: 10,
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
  },
}));

export default useStyles;
