import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container:{
    margin: '20px 0px',
    padding: '20px 40px',
    borderRadius: 20, 
    boxShadow: theme.shadows[3], 
  },  
  titleContainer:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    marginBottom: 8, 
  }, 
  title: {
    display:'flex', 
    alignItems:'center',
    fontSize: 24,
    "& i":{
      fontSize: 30, 
      marginLeft: 4
    }
  }, 

  dataGrid:{
    minHeight: 400,
    border:'none', 
    display:'flex',
    margin: '12px 0',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator":{
      display:'none'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14, 
    }, 
    "& .MuiDataGrid-columnsContainer":{
      justifyContent: 'center'
    }
  },
  headerTableGrid:{
    fontSize:14, 
    color: '#AFAFAF',
    fontFamily:'Roboto',
  },
  state:{
    width: 8,
    height: 8,
    borderRadius: '100%',
    marginRight: 4,
    "&.active":{
      background:theme.palette.success.main
    }, 
    "&.inactive":{
      background:theme.palette.error.main
    }
  }, 
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "&:hover":{
      color: '#fff'
    }
  },
}));

export default useStyles;
