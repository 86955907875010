import React from 'react';

import { Image, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface TableInfoProps {
  title: string;
  icon?: string;
  items: object;
  widthContainer?: number | string;
  widthTitle?: number | string;
  widthItem?: number | string;
}

const TableInfo = ({
  title,
  icon,
  items,
  widthContainer,
  widthTitle,
  widthItem,
}: TableInfoProps) => {
  return (
    <View
      style={[
        styles.tablecontainer,
        { width: `${widthContainer ? (widthContainer as String) : '31.2%'}` },
      ]}
    >
      <View style={styles.backgroundresumetitle}>
        {icon && (
          <View style={styles.imageicon}>
            <Image src={icon} />
          </View>
        )}
        <Text>{title}</Text>
      </View>

      <View style={styles.table}>
        {_.map(items, (value, key) => (
          <View style={styles.tableRow}>
            <View
              style={[
                styles.tableCol,
                { width: widthTitle ? widthTitle : '30%' },
              ]}
            >
              <View style={styles.tableCell}>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  {key}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableColRigth,
                { width: widthItem ? widthItem : '70%' },
              ]}
            >
              <Text style={styles.tableCell}>{value}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default TableInfo;
