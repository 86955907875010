import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import moment, { Moment } from 'moment';

import LogoIP from 'static/img/reportsIcons/logoIp';

import styles from './styles';
import dayjs, { Dayjs } from 'dayjs';

interface PDFHeaderProps {
  initialDate?: Date | number | string | Moment | Dayjs;
  finalDate?: Date | number | string | Moment | Dayjs;
  qr?: string;
  reportImage: string;
  title: string;
  lote?: number | string | null;
  range?: number | string | null;
  fecha?: number | string | null;
  userTitile?: string;
  number: number | string;
  code: string | number;
}

const PDFHeader = ({
  initialDate,
  finalDate,
  title,
  qr,
  reportImage,
  number = 0,
  lote = null,
  range,
  fecha,
  userTitile,
  code,
}: PDFHeaderProps) => {
  const getDate = () => {
    if (dayjs.isDayjs(initialDate) && dayjs.isDayjs(finalDate))
      return `${dayjs(initialDate).format('DD/MM/YYYY')} - ${dayjs(
        finalDate
      ).format('DD/MM/YYYY')}`;
    if (moment.isMoment(initialDate) && moment.isMoment(finalDate))
      return `${moment(initialDate).format('DD/MM/YYYY')} - ${moment(
        finalDate
      ).format('DD/MM/YYYY')}`;
    return null;
  };
  return (
    <View style={styles.header}>
      <View
        style={[styles.headerImageContainer, { width: 60, marginRight: 5 }]}
      >
        <Image style={{ height: 40, width: 40 }} src={LogoIP} />
      </View>
      <View
        style={[styles.headerImageContainer, { width: 45, marginRight: 5 }]}
      >
        <Image style={{ height: 40, width: 40 }} src={reportImage} />
      </View>
      <View style={{ width: 286, marginRight: 5, position: 'relative' }}>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'left',
            height: 38,
            marginBottom: 5,
            paddingLeft: 5,
          }}
        >
          <Text
            style={{
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              fontSize: title?.length > 38 ? 13 : 14,
            }}
          >
            {title}
          </Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {lote && (
              <>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontStyle: 'italic',
                    fontSize: 8,
                  }}
                >
                  {userTitile ? userTitile : 'Lote InfoPorcinos:'}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    color: '#DD9835',
                    fontSize: 11,
                    marginLeft: 8,
                    letterSpacing: 1.5,
                  }}
                >
                  {lote}
                </Text>
              </>
            )}
            {range && (
              <>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontStyle: 'italic',
                    fontSize: 8,
                  }}
                >
                  Rango de Fecha:
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    color: '#DD9835',
                    fontSize: 11,
                    marginLeft: 8,
                    letterSpacing: 1.5,
                  }}
                >
                  {range}
                </Text>
              </>
            )}
            {fecha && (
              <>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontStyle: 'italic',
                    fontSize: 8,
                  }}
                >
                  Fecha:
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    color: '#DD9835',
                    fontSize: 11,
                    marginLeft: 8,
                    letterSpacing: 1.5,
                  }}
                >
                  {fecha}
                </Text>
              </>
            )}
          </View>
        </View>
        <View style={{ width: '100%', flexDirection: 'row' }}>
          <View
            style={[
              styles.headerDetail,
              { width: 'auto', flex: 1, marginRight: 2.5 },
            ]}
          >
            <Text style={{ width: '100%' }}>
              Fecha Reporte{' '}
              <Text
                style={[
                  styles.textBold,
                  { letterSpacing: 1.5, marginLeft: 10 },
                ]}
              >
                {moment().format('DD/MM/YYYY')}
              </Text>
            </Text>
          </View>
          <View
            style={[
              styles.headerDetail,
              { width: 'auto', flex: 1, marginLeft: 2.5 },
            ]}
          >
            <Text style={{ width: '100%' }}>
              Nro. Reporte{' '}
              <Text
                style={[
                  styles.textBold,
                  { letterSpacing: 1.5, marginLeft: 10 },
                ]}
              >
                {('000000000' + number).slice(-9)}
              </Text>
            </Text>
          </View>
        </View>
      </View>
      <View
        style={[
          {
            width: 80,
            height: 60,
            marginRight: 5,
            justifyContent: 'space-between',
          },
        ]}
      >
        <View style={styles.headerDetail}>
          <Text style={{ width: '100%' }}>
            Cod. Doc. <Text style={styles.textBold}>{code}</Text>
          </Text>
        </View>
        <View style={styles.headerDetail}>
          <Text style={{ width: '100%' }}>
            Versión <Text style={styles.textBold}>1.2</Text>
          </Text>
        </View>
        <View style={styles.headerDetail}>
          <Text
            style={[styles.textBold, { width: '100%' }]}
            render={({ pageNumber, totalPages }) =>
              `Pág Nro. ${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </View>
      <View style={{ height: 60, width: 60 }}>
        <Image src={qr} />
      </View>
    </View>
  );
};

export default PDFHeader;
