import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";

import avatar from "lib/helpers/dynamic-images/avatars";
import removeAccents from "lib/helpers/removeAccents";

import useStyles from "./styles";

interface cardProps {
  id?: string | number;
  name: string;
  businessName?: string;
  phone?: string;
  cellPhone?: string;
  destinationType?: string;
  ubication: string;
  direction: string;
}

const CardDetailFD = ({
  id,
  name,
  businessName,
  phone,
  cellPhone,
  destinationType,
  ubication,
  direction,
}: cardProps) => {
  const classes = useStyles();

  return (
    <Grid key={id}>
      <Card elevation={4} className={classes.cardContainer}>
        <CardContent className={classes.cardContent}>
          <Grid container justifyContent="center">
            <Avatar
              src={avatar(removeAccents(name))}
              className={classes.avatarContainer}
            />
          </Grid>
          <Grid
            container
            justifyContent="center"
            className={classes.dataContainer}
          >
            <Box mt={1}>
              <Typography className={classes.nameInfo}>{name}</Typography>
              <Typography className={classes.ubicationInfo}>
                {ubication}
              </Typography>
            </Box>
          </Grid>

          {cellPhone && (
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.dataTitle}>Celular</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dataInfo}>
                  {cellPhone}
                </Typography>
              </Grid>
            </Grid>
          )}

          {phone && (
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.dataTitle}>Teléfono</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dataInfo}>{phone}</Typography>
              </Grid>
            </Grid>
          )}

          {businessName && (
            <Box mt={1}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.dataTitle}>
                    Razón Social
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataInfo}>
                    {businessName}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          {destinationType && (
            <Box mt={1}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.dataTitle}>
                    Tipo de Destino
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataInfo}>
                    {destinationType}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          {direction && (
            <Box mt={1}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.dataTitle}>
                    Dirección
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.fontDirection}>
                    {direction}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CardDetailFD;
