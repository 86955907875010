export const paramsBar = {
  open: {
    clipPath: 'circle(150vh at 100% 0)',
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 20,
      restDelta: 2,
      staggerChildren: 0.07,
      delayChildren: 0.5
    }
  },
  closed: {
    clipPath: 'circle(0px at 100% 0)',
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
      staggerChildren: 0.05,
      staggerDirection: -1
    }
  }
}

export const childrenParamsBar = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -1 }
    }
  },
  closed: {
    x: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}