import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container:{
    margin: '20px 0px',
    padding: '20px 40px',
    borderRadius: 20, 
    boxShadow: theme.shadows[3], 
  },  
  titleContainer:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    margin: '20px 0px', 
    borderRadius: 20, 
    boxShadow: theme.shadows[3], 
    padding: '20px 40px',
  }, 
  title: {
    display:'flex', 
    alignItems:'center',
    fontSize: 24,
    "& i":{
      fontSize: 30, 
      marginLeft: 4
    }
  }, 
  table:{
    "& .MuiTableCell-root":{
      border: 'none',
      textTransform: 'capitalize'
    },
  },
  tableTitle:{
    color: '#AFAFAF', 
    fontWeight: 'bold',
  }, 
  gridContainer:{
    marginTop: 24
  }, 
  containerLeft:{
    width: '100%', 
    marginRight: '12px !important', 
    marginTop: '12px !important', 
    marginBottom: '12px !important', 
    [theme.breakpoints.down('lg')]:{
    marginRight: "0px !important", 
    }
  }, 
  containerRight:{
    width: '100%', 
    marginLeft: '12px !important', 
    marginTop: '12px !important', 
    marginBottom: '12px !important', 
    [theme.breakpoints.down('lg')]:{
    marginLeft: "0px !important", 
    }
  }
}));

export default useStyles;
