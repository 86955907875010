/** @format */

const whiteTicket = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJEAAACRCAYAAADD2FojAAAACXBIWX
MAACxKAAAsSgF3enRNAAAHE0lEQVR4nO2d7XXbNhSG3/T0f9QJog2sDeoNqkwQeQN3A3cDb2B1gt
ob2BtIGygbyBOwhw3YKAoBgrz4uADe5xz+SCKCoPQEuAAuwQ9d14EQCb/w2yNSKBERQ4mIGEpExF
AiIoYSETGUiIihREQMJSJiKBERQ4mIGEpExFAiIoYSETGUiIihREQMJSJiKBERQ4mIGEpExFAiIu
ZXfoVWbgHsAKyV1m+KE4Bnc0SFjwyNswfwRWPFFvAGYAvgHOsClOhnahJo4M20rFFgTPQjNQrU87
vpmqNAib5Tq0AD21gFU6Jv1C5QzypWwZRoWqAXAHcAfutjSKVHX7fPAI4Jv7f/aT2wnhLoznymJA
4AbkbqGy24brklqlGgnofUF2xVoloFQorJxWtalKhmgbLQmkQUKAItrZ1pEWhtAtzrNbmDOU4J6h
CUViTKLdDKzBjfA/g08dmjqcs+5npXUPohfuXHvnOzi3z/267rzhN1GOMsqNsYr7HusfaYKHcL1J
f9D4CPC87tz3kyo61os80hqFkiDQKFWEr5A8CrZpFqlagWgQZujEgqqTGwzi3QvYdA70aKg/nzyo
zYxpYrBvp/ezTl66KyoDp3EL2eCKKnguW1xz3cagusKVC6Ohy6rlt5luMa0fnIkFSiWmIiDROJK0
cdjqa78p33eXZkIvZZipuFdYxCDRJpmYl2ZQ7uFkwc9iL97ShPDaVLpGktzCbRy0UAPRdbWke0VN
cllCyRtsVUWxcjqcPJkq04tXSSlFIl0rgab/thl7ZCA7b8IDVxUYkSlZbOEWtVfu4MtlRmK6VJxH
yg5UTLCChJolIFkibH2/YCUJN3VIpEJQj0Zvl7aexik5ASzaCUFsgWc0jWunaWgP1FUGZwtEsUUq
CN+d/bmfgg9ISdbRT1aeFjPCvHebpW9BtZC1tZ1qJCr6edAtXXdf9njzW4MR5aWzsL3YVtLNmFT4
FbJFeL8+TZta1N12i7/0d1udeVt0DDcRuhTNvxOnGtvrW677puc3X+1uPeT56ZAElbohYEGg5XVy
Mt+/KYyimS4JNLlFwiTd1Z7FHY1mQU2gjVtZ3MsNx1rSXcaU2R1SJRimH8wePHDSWSz7XmoHomXo
NEoYfxDw4RUou0Ee4Z9G72HdK9lFNRDHR/da4rHXXjEbeEDLZ3HjHZNfsZ6bRZY6JaBFpbytAkEi
5GYIeRa/V1eTb/GZbK05REoUdhriG8NpFSHEklyhETxQiiXZNvN44nSFPGSNWSWqJYo7CDI6kdFC
kuKSWKPYzfUaQ8pJIoVToHRcpAColSzgOBImUg8kjhIeDIZ848kM8I8OA412fUdr2A2uzoLOaN2H
J4Bu5nlLVkHshHpL1ApLNSkWzfVZFDfFsOT89Xkxfjiy1Z3dU1waNr++LoSqe6to/m2qqei29pM/
S5z0AtnQdCYyJtHPFndY8MzX0lpmQeCAFEcj37rkWkzUSqSLSHF2PHRC6WLCf4BMu2GGllWbPyjZ
F2E+fmjJF84rdo1499c48UKbtAnVn4LVYinx9tyQ1SJH+Boi8gp7jRqR9t6ZcumQeqQSQ1GQgpJM
opkkuEkkVSlcKSSiKKVKlAqSWiSBUKlEMiilSZQLkkiiWSVIQUIq1rEyinRK2K5DrXVadUT+8WJ1
GLIs3d1d6nLtkfJMgtUWsizUl/KUIgLRLVLNL56rO+z5MVI5AmiUoWKXSyV1ECaZOoZJFC/ajFCa
RRopZFKlIgrRKVLNLSlItiBdIskWaRXHM2z60J1Cl/ad7Z5Dfb9vdZkpY6VSYmUmXPHjuuzWFl7s
H27tf3Il41odlwxS2SbRnCd0/FWPeV5ShBIq0irU33NVx/7r7aVQjUHx/+M6kMfJr+25lPNUyV2f
NX4Ge5YtxHVkqSCBlFChWXVCcQCnzfWa5gO8QmDlUKhAJbooFcLdJnx4tgJGUXKxAKfgdszBbpq+
MzS1qjqgVC4W+jjiVS6nmgogVCBe/F9xFpP/Olu65NHObsZNKEQKhAIniINLXZwxjDbvjDJhDvM9
+t0YxAKDiwHmPqhzuaHy72u8KaEgiVtEQDMVqkuTQnECqTCJlFalIgVCgRMonUrECoVCIkFqlpgV
CxREgkUvMCoXKJkECkft6oaYHQgESIKJLrTQHNCIRGJEIEkSjQBa1IhIAiUaArWpIIAUSiQCO0Jh
FmiHS5YfvK5BFRoBFqWjubi8/wfEhA2zreU9K0QGhcInhmM7poXiA02p1d4pNfbYMCGVqXCAtFok
AXUKJvzBGJAl1Bib7jI9KRAv0MJfqRs0mL/fNKpqN5EpYCjdD66IwEgC0REUOJiBhKRMRQIiKGEh
ExlIiIoUREDCUiYigREUOJiBhKRMRQIiKGEhExlIiIoUREDCUiYigREUOJiBhKRMRQIiKGEhExlI
jIAPAvseCHl8JzdywAAAAASUVORK5CYII=`;

export default whiteTicket;
