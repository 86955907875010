import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  iconButton:{
    marginLeft: 8, 
    "& .MuiFab-root":{
      width: 35, 
      height: 35
    }
  }, 
  rangeContainer:{
    background: '#FFF', 
    borderRadius: '0 0 20px 20px', 
    boxShadow: "0px 5px 5px rgb(0 0 0 / 10%)", 
    position: 'absolute', 
    zIndex: 10000, 
    // display: 'none', 
    padding: '20px 16px', 
    // '&.active':{
    //   display: 'block',
    // }
  }, 
  contentRange:{
    display: 'flex'
  }, 
  cardHeader:{
    display:'flex', 
    justifyContent:'space-between'
  },
}))

export { useStyles as default }
  