import React from 'react';
import { Typography, List, ListItem } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import _ from 'lodash';
import clsx from 'clsx';

import useStyles from './styles';

interface IndicativeTableProps {
  title: string;
  item: object;
  direction?: 'column' | 'row';
  width?: string;
}

const IndicativeTable = ({
  title,
  item,
  direction,
  width,
}: IndicativeTableProps) => {
  const classes = useStyles();
  return (
    <div style={{ width: width }} className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
      </div>
      <div className={classes.listContainer}>
        <List className={clsx(classes.list, direction)}>
          {_.map(item, (value, key) => (
            <ListItem className={classes.listItem}>
              <FiberManualRecordIcon
                style={{ color: value }}
                className={classes.listIcon}
              />
              <Typography>{key}</Typography>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default IndicativeTable;
