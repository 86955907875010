import { makeStyles } from "@material-ui/core";

export const useTableStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #DDD',
    borderRadius: 5
  }
}))

export const useRowStyles = makeStyles(theme => ({
  viewBtn: {
    color: theme.palette.common.white,
    background: theme.palette.grey[600],
    borderRadius: 50,
    padding: theme.spacing(0.2, 0),
    '&:hover': {
      background: theme.palette.grey[700],
      color: '#FFF'
    }
  },
  tableCellNestedTable: {
    padding: 0,
    paddingBottom: theme.spacing(2)
  },
  nestedTable: {
    '&:nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  nestedTableHead: {
    '& th': {
      fontSize: 12
    }
  },
  whenClose: {
    padding: 0
  },
  cell: {
    paddingRight: 4,
    paddingLeft: 0
  }
}))