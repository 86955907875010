import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiDialog-paperWidthMd": {
      borderRadius: "20px",
    },
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "20px",
    },
  },
  visibility: {
    opacity: 0,
  },
  tabsContainer: {
    width: "85%",
    minHeight: 0,
    "& .MuiTabs-indicator": {
      display: "none",
    },
    lineHeight: 0.75,
  },
  permissContainer: {
    margin: "24px 0",
    display: "flex",
    flexFlow: "row wrap",
    textAlign: "left",
  },
  btn: {
    fontWeight: "bolder",
  },
  containerRoles: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnRoles: {
    border: "2px solid #767676",
    padding: 12,
    borderRadius: 50,
    marginRight: 12,
    fontWeight: "normal",
    [theme.breakpoints.between("xs", "xl")]: {
      minWidth: "100px !important",
    },
    lineHeight: 0.75,
    minHeight: 0,
  },
  btnRolesActive: {
    border: "2px solid #DD9835",
    padding: 12,
    borderRadius: 50,
    color: "#DD9835",
    fontWeight: "bolder",
    marginRight: 12,
    [theme.breakpoints.between("xs", "xl")]: {
      minWidth: "100px !important",
    },
    lineHeight: 0.75,
    minHeight: 0,
  },
  containerPermissions: {
    display: "flex",
    flexFlow: "row wrap",
    margin: "20px 0",
  },
  itemList: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
  permissItem: {
    width: "45%",
    margin: 12,
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #eaeaf0",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bolder",
  },
  containerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formContainer: {
    margin: "12px 0",
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  iconDialogCheck: {
    fontSize: 60,
    color: theme.palette.primary.main,
  },
  captionGray: {
    color: "#767676",
    paddingLeft: 24,
    fontWeight: "bold",
  },
}));

export { useStyles as default };
