import React, { useMemo } from 'react';

import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Link,
  Image,
} from '@react-pdf/renderer';
import { Button, CircularProgress } from '@material-ui/core';

import dayjs from 'dayjs';

import logomv from 'static/img/logos/logo-mv';

interface dataProps {
  data: {
    name?: string;
    document?: string;
    nameRL?: string;
    documentRL?: string;
    direction?: string;
    cellphone?: string;
    email?: string;
    username?: string;
    userType?: string;
    registerDate?: any;
    entity?: string;
  };
}

const CertificatePDF = ({ data }: dataProps) => {
  const Doc = () => (
    <Document>
      <Page size="LETTER" style={{ padding: '20px' }}>
        <Text
          style={{
            textAlign: 'center',
            color: '#767676',
            fontWeight: 'bold',
            fontSize: 16,
          }}
        >
          CERTIFICADO DE AUTORIZACIÓN DE POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE
          LOS DATOS PERSONALES (2022-POLPRITRADATOS)
        </Text>
        <View
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            margin: '20px 0px',
          }}
        >
          <View style={{ width: '50%' }}>
            <Text style={{ fontSize: 10, textAlign: 'center' }}>
              Fecha de versión: 01 Julio 2022
            </Text>
          </View>
          <View style={{ width: '50%' }}>
            <Text style={{ fontSize: 10, textAlign: 'center' }}>
              Código: 2022 - CERPRIVATRADP003
            </Text>
          </View>
        </View>
        <View
          style={{
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: '#767676',
          }}
        >
          <View
            style={{
              backgroundColor: '#AFAFAF',
              borderWidth: 0.5,
              borderStyle: 'solid',
              borderColor: '#767676',
              padding: '10px',
            }}
          >
            <Text
              style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}
            >
              DATOS BÁSICOS DEL USUARIO TITULAR (OTORGANTE AUTORIZACIÓN)
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                {data?.entity === 'person' ? 'Nombre completo' : 'Razón Social'}
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontSize: 10, color: '#767676' }}>
                {data?.name ?? 'N.D'}
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                Número de documento
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontSize: 10, color: '#767676' }}>
                {data?.document ?? 'N.D'}
              </Text>
            </View>
          </View>

          {data?.entity !== 'person' ? (
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '25%',
                  borderWidth: 0.5,
                  borderStyle: 'solid',
                  borderColor: '#767676',
                  padding: '4px',
                }}
              >
                <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                  Representante Legal
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  borderWidth: 0.5,
                  borderStyle: 'solid',
                  borderColor: '#767676',
                  padding: '4px',
                }}
              >
                <Text style={{ fontSize: 10, color: '#767676' }}>
                  {data?.nameRL ?? 'N.D'}
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  borderWidth: 0.5,
                  borderStyle: 'solid',
                  borderColor: '#767676',
                  padding: '4px',
                }}
              >
                <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                  Documento Representante
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  borderWidth: 0.5,
                  borderStyle: 'solid',
                  borderColor: '#767676',
                  padding: '4px',
                }}
              >
                <Text style={{ fontSize: 10, color: '#767676' }}>
                  {data?.documentRL ?? 'N.D'}
                </Text>
              </View>
            </View>
          ) : null}
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                Dirección
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontSize: 10, color: '#767676' }}>
                {data?.direction ?? 'N.D'}
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 10 }}>Celular</Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontSize: 10, color: '#767676' }}>
                {data?.cellphone ?? 'N.D'}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                Correo Electrónico
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontSize: 10, color: '#767676' }}>
                {data?.email ?? 'N.D'}
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                Nombre de usuario
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontSize: 10, color: '#767676' }}>
                {data?.username ?? 'N.D'}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                Perfil Usuario
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontSize: 10, color: '#767676' }}>
                {data?.userType ?? 'N.D'}
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                Fecha Registro
              </Text>
            </View>
            <View
              style={{
                width: '25%',
                borderWidth: 0.5,
                borderStyle: 'solid',
                borderColor: '#767676',
                padding: '4px',
              }}
            >
              <Text style={{ fontSize: 10, color: '#767676' }}>
                {dayjs(data?.registerDate).format('DD-MM-YYYY HH:mm:ss') ??
                  'N.D'}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ margin: '12px 0px' }}>
          <Text style={{ fontSize: 10 }}>
            Dando cumplimiento a lo dispuesto en la Ley 1581 de 2012, "Por el
            cual se dictan disposiciones generales para la protección de datos
            personales" y de conformidad con lo señalado en el Decreto 1377 de
            2013, con la firma y/o aceptación del presente documento, manifiesto
            que he sido informado plenamente por MERCADEO VIRTUAL S.A. a través
            de su (s) Plataforma (s) Web: InfoPorcinos™{' '}
            <Link src="http://www.infoporcinos.com/sitio/Principal.aspx">
              www.infoporcinos.com
            </Link>{' '}
            sobre los siguientes puntos:
          </Text>
        </View>
        <View style={{ margin: '8px 0px' }}>
          <Text style={{ fontSize: 10 }}>
            1. La empresa MERCADEO VIRTUAL S.A. actuará como el Responsable del
            Tratamiento de datos personales de los cuales soy titular
            conjuntamente con: CENTRAL GANADERA S.A. (Planta de Beneficio
            Animal) y los distintos Agentes y Clientes de la RED DE NEGOCIOS de
            la Plataforma InfoPorcinos™ que he creado y registrado en dicha
            plataforma, o de forma separada, se autoriza y habilita el
            recolectar, almacenar, procesar, distribuir, usar y tratar mis datos
            personales conforme la POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES Y
            PRIVACIDAD DE MERCADEO VIRTUAL S.A. (2022 - POLTRADAPER0) vigente a
            la fecha del presente documento y que está disponible en la página
            web{' '}
            <Link src="http://www.infoporcinos.com/sitio/Principal.aspx">
              www.infoporcinos.com
            </Link>
            .
          </Text>
        </View>
        <View style={{ margin: '8px 0px' }}>
          <Text style={{ fontSize: 10 }}>
            2. Que me ha sido informada en detalle la (s) finalidad (es) y los
            uso (s) finales de la recolección de los datos personales, la cual
            consiste en que mis Datos Personales y mis Datos Técnicos de sus
            Operaciones en nuestras Plataformas de Información relacionadas con
            el Beneficio Animal, Comercialización Productos Cárnicos (Canales
            Animales para Abasto Público, Cortes de Carne Primarios y
            Comerciales, Sistema de Trazabilidad Animal, Sistemas de Información
            Estadístico y Logística de Distribución entre otros, serán incluidos
            en una o más bases de datos y podrán ser transmitidos y/o
            transferidos entre InfoPorcinos™, sus subordinadas vinculadas, su
            matriz o controlante, las subordinadas de su matriz o controlante,
            (en adelante “Las Entidades Autorizadas”), para que directamente o a
            través de terceros, incluyendo a la Planta de Beneficio CENTRAL
            GANADERA S.A, sus diferentes agentes o integrantes de su RED DE
            NEGOCIOS, traten los Datos Personales de acuerdo con los fines
            establecidos en el presente documento de autorización. De igual
            forma, las bases de datos de InfoPorcinos™ podrán incluir e integrar
            datos transmitidos y/o transferidos a esta por las Entidades
            Autorizadas y/o por terceros.
          </Text>
        </View>
        <View style={{ margin: '8px 0px' }}>
          <Text style={{ fontSize: 10 }}>
            3. Es de carácter facultativo o voluntario de parte del Usuario, el
            responder preguntas sobre aquellos DATOS SENSIBLES definidos como
            aquellos que afectan la intimidad del Titular y pueden dar lugar a
            que sea discriminado, como aquellos que revelan su origen racial o
            étnico, su orientación política, las convicciones religiosas o
            filosóficas, la pertenencia a sindicatos, organizaciones sociales,
            de derechos humanos, así como los datos relativos a la salud, a la
            vida sexual, y los datos biométricos o sobre aquellos datos de
            personas menores de edad. Que se me ha informado por parte de la
            empresa MERCADEO VIRTUAL S.A. que sus Plataformas de Información
            como{' '}
            <Link src="http://www.infoporcinos.com/sitio/Principal.aspx">
              www.infoporcinos.com
            </Link>
            , entre otras relacionadas, se definen como plataformas de
            información con acceso únicamente para Usuarios Mayores de Edad, y
            que durante los procesos de registro de datos personales no se
            realizan ningún tipo de recolección de Datos Sensibles bajo ningún
            método, objetivo o justificación según los términos de la ley.
          </Text>
        </View>
        <View style={{ margin: '8px 0px' }}>
          <Text style={{ fontSize: 10 }}>
            4. Mis derechos como titular de los datos personales y datos
            técnicos (compartidos e individuales) se declaran legalmente de
            tipo: SEMIPRIVADOS Y PÚBLICOS, a la luz de la ley de Habeas Data
            vigente a la fecha del presente documento para cualquier efecto, son
            los previstos en la Constitución política de Colombia y la ley,
            especialmente en el derecho a conocer, actualizar, rectificar y
            suprimir mi información personal, así como el derecho a revocar
            (“darse de baja”) el consentimiento otorgado para el tratamiento de
            datos personales en cualquier tiempo y lugar durante la prestación
            del servicio.
          </Text>
        </View>
        <View style={{ margin: '8px 0px' }}>
          <Text style={{ fontSize: 10 }}>
            5. Los derechos pueden ser ejercidos a través de los canales
            dispuestos por MERCADEO VIRTUAL S.A. y observando POLÍTICA DE
            TRATAMIENTO DE DATOS PERSONALES Y PRIVACIDAD DE MERCADEO VIRTUAL
            S.A. (2022 - POLTRADAPER0).
          </Text>
        </View>
        <View style={{ margin: '8px 0px' }}>
          <Text style={{ fontSize: 10 }}>
            6. Mediante la página web ({' '}
            <Link src="http://www.infoporcinos.com/sitio/Principal.aspx">
              www.infoporcinos.com
            </Link>{' '}
            ), podré radicar cualquier tipo de requerimiento relacionado con el
            tratamiento de mis datos personales.
          </Text>
        </View>
      </Page>
      <Page size="LETTER" style={{ padding: '20px' }}>
        <View style={{ margin: '8px 0px' }}>
          <Text style={{ fontSize: 10 }}>
            7. MERCADEO VIRTUAL S.A. garantizará la confidencialidad, libertad,
            seguridad, veracidad, transparencia, acceso y circulación
            restringida de mis datos y se reservará el derecho de modificar su
            POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES Y PRIVACIDAD DE MERCADEO
            VIRTUAL S.A. (2022 - POLTRADAPER0) en cualquier momento y condición.
            Cualquier cambio será informado y publicado oportunamente en la
            página web y/o a los correos electrónicos registrados por el Usuario
            Titular.
          </Text>
        </View>
        <View style={{ margin: '8px 0px' }}>
          <Text style={{ fontSize: 10 }}>
            8. Teniendo en cuenta lo anterior, autorizo de manera voluntaria,
            previa, explícita, informada e inequívoca a la empresa MERCADEO
            VIRTUAL S.A. para tratar mis datos personales y tomar mi huella y
            fotografías de acuerdo con su POLÍTICA DE TRATAMIENTO DE DATOS
            PERSONALES Y PRIVACIDAD DE MERCADEO VIRTUAL S.A. (2022 -
            POLTRADAPER0) para los fines relacionados con su objeto y en
            especial para fines legales, contractuales, misionales descritos en
            dicha política.
          </Text>
        </View>
        <View style={{ margin: '8px 0px' }}>
          <Text style={{ fontSize: 10 }}>
            9. La información obtenida para el TRATAMIENTO DE MIS DATOS
            PERSONALES la he suministrado de forma voluntaria y es verídica. La
            exactitud, veracidad y la legalidad de los Datos Personales
            registrados por el Usuario Titular son de ÚNICA Y EXCLUSIVA
            responsabilidad ante los antes de control legal y judicial de
            Colombia. Igualmente, MERCADEO VIRTUAL S.A. certifica que el USUARIO
            TITULAR ha sido informado en detalle sobre otras Políticas que se
            han definido por nuestra empresa para su sitio web{' '}
            <Link src="http://www.infoporcinos.com/sitio/Principal.aspx">
              www.infoporcinos.com
            </Link>{' '}
            donde se puede confirmar y certificar que dicho Usuario previa
            proceso de información, ha leído y aceptado los siguientes acuerdos:
          </Text>
        </View>
        <View
          style={{
            padding: '4px',
            margin: '8px 0px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#767676',
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              color: '#767676',
              fontWeight: 'bold',
              fontSize: 12,
            }}
          >
            Términos y Condiciones de Uso Plataforma y la Política de Datos de
            Navegación de InfoPorcinos™.
          </Text>
          <View
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              margin: '8px 0px',
            }}
          >
            <View style={{ width: '50%' }}>
              <Text style={{ fontSize: 10, textAlign: 'center' }}>
                Fecha de versión: 01 Julio 2022
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ fontSize: 10, textAlign: 'center' }}>
                Código: 2022 - TERCONAVWEB
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            margin: '8px 0px',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View style={{ width: '50%' }}>
            <Text style={{ fontSize: 12 }}>MERCADEO VIRTUAL S.A.</Text>
            <Text style={{ fontSize: 10, margin: '2px 0px', color: '#767676' }}>
              Calle 48 No. 77 – 78 Sector Estadio Velódromo
            </Text>
            <Text style={{ fontSize: 10, margin: '2px 0px', color: '#767676' }}>
              Zona Postal 050034
            </Text>
            <Text style={{ fontSize: 10, margin: '2px 0px', color: '#767676' }}>
              Medellín – Colombia
            </Text>
            <Text style={{ fontSize: 10, margin: '2px 0px', color: '#767676' }}>
              Pbx + 57 604 3228603
            </Text>
            <Text style={{ fontSize: 10, color: '#767676', margin: '2px 0px' }}>
              <Link
                style={{ fontSize: 10 }}
                src="http://www.mercadeo-virtual.com/"
              >
                www.mercadeo-virtual.com
              </Link>
              {'  '}/ {'  '}
              <Link
                style={{ fontSize: 10 }}
                src="http://www.infoporcinos.com/sitio/Principal.aspx"
              >
                www.infoporcinos.com
              </Link>
            </Text>
            <Text style={{ fontSize: 10, margin: '2px 0px', color: '#767676' }}>
              Correo: info@infoporcinos.com
            </Text>
          </View>
          <View style={{ width: '50%' }}>
            <Image
              src={logomv}
              style={{ width: '150px', alignSelf: 'flex-end' }}
            ></Image>
          </View>
        </View>
      </Page>
    </Document>
  );

  return useMemo(
    () => (
      <div>
        <PDFDownloadLink
          document={<Doc />}
          fileName="(2022-CERPRIVATRADP003).pdf"
        >
          {({ loading, error }) => {
            return (
              <Button
                variant="contained"
                color="primary"
                endIcon={
                  loading && (
                    <CircularProgress style={{ color: '#FFF' }} size={20} />
                  )
                }
              >
                {error ? 'Error' : 'Descargar Certificado'}
              </Button>
            );
          }}
        </PDFDownloadLink>
      </div>
    ),
    []
  );
};

export default CertificatePDF;
