import { Document, Page, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import cavaLogo from 'static/img/reportsIcons/cavaLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import HorizontalTableInfo from 'components/pdf-components/HorizontalTableInfo/index';
import ItemTotal from 'components/pdf-components/ItemTotal';
import ListData from 'components/pdf-components/ListData';

import styles from './styles/reports';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const detalle = [
  {
    nroCava: 'C14',
    nroLotes: 1,
    lotesIp: '1234',
    totalCanales: 42,
    canalesDespachas: 0,
    canalesDisponibles: 42,
    diasCavas: '2',
    tiempoTotal: '48:00:00',
  },
  {
    nroCava: 'C16',
    nroLotes: 2,
    lotesIp: '1234, 4321',
    totalCanales: 84,
    canalesDespachas: 0,
    canalesDisponibles: 84,
    diasCavas: '2',
    tiempoTotal: '48:00:00',
  },
  {
    nroCava: 'C17',
    nroLotes: 1,
    lotesIp: '1234',
    totalCanales: 42,
    canalesDespachas: 0,
    canalesDisponibles: 42,
    diasCavas: '2',
    tiempoTotal: '48:00:00',
  },
  {
    nroCava: 'C20',
    nroLotes: 1,
    lotesIp: '1234',
    totalCanales: 42,
    canalesDespachas: 0,
    canalesDisponibles: 42,
    diasCavas: '2',
    tiempoTotal: '48:00:00',
  },
  {
    nroCava: 'C22',
    nroLotes: 1,
    lotesIp: '1234',
    totalCanales: 42,
    canalesDespachas: 0,
    canalesDisponibles: 42,
    diasCavas: '2',
    tiempoTotal: '48:00:00',
  },
  {
    nroCava: 'C26',
    nroLotes: 1,
    lotesIp: '1234',
    totalCanales: 42,
    canalesDespachas: 0,
    canalesDisponibles: 42,
    diasCavas: '2',
    tiempoTotal: '48:00:00',
  },
  {
    nroCava: 'C27',
    nroLotes: 1,
    lotesIp: '1234',
    totalCanales: 42,
    canalesDespachas: 0,
    canalesDisponibles: 42,
    diasCavas: '2',
    tiempoTotal: '48:00:00',
  },
  {
    nroCava: 'C28',
    nroLotes: 1,
    lotesIp: '1234',
    totalCanales: 42,
    canalesDespachas: 0,
    canalesDisponibles: 42,
    diasCavas: '3 - 5',
    tiempoTotal: '72:00:00',
  },
  {
    nroCava: 'C29',
    nroLotes: 2,
    lotesIp: '1234, 4321',
    totalCanales: 84,
    canalesDespachas: 0,
    canalesDisponibles: 84,
    diasCavas: '3 - 5',
    tiempoTotal: '72:00:00',
  },
];

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
  fecha?: null | number | string;
}

export const MyDocCavaDate = ({ qr, uid, fecha }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        fecha={fecha}
        reportImage={cavaLogo}
        number="023456789"
        title="Reporte Inventario Canales Refrigeradas"
        code="AM-001"
      />

      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ width: '100%' }}>
        <HorizontalTableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            Nit: '34123412-2',
            Telefono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <View
          style={{
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: '#767676',
            marginTop: '10px',
          }}
        ></View>
        <HorizontalTableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Resumen Inventario Canales Refrigeradas"
          widthContainer="100%"
        />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View
          style={{
            width: '21.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="265"
            info="Total Cavas Ocupadas"
            position="right"
            textAlign="left"
            backgroundColor="#d47d57"
            widthInfo="50px"
            widthCircle={32}
          />
        </View>
        <View
          style={{
            width: '21.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="8"
            info="Total Lotes"
            position="right"
            widthValue={50}
            widthInfo="40px"
            fontSizeValue={12}
            backgroundColor="#be5948"
          />
        </View>
        <View
          style={{
            width: '21.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="264"
            info="Total Canales Refrigeradas"
            position="right"
            widthValue={50}
            widthInfo="50px"
            fontSizeValue={12}
            backgroundColor="#be5948"
          />
        </View>
        <View
          style={{
            width: '29.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ListData
            widthContainer="90%"
            widthtTitle="70%"
            widthValue="30%"
            items={{
              'Temperatura Promedio Cavas': '13°C',
              'Tiempo Promedio Refrigeración': '48:00:00',
              'Peso Total Canales': '56.000,00 Kg',
            }}
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: 45,
          marginTop: '10px',
          marginBottom: '10px',
          backgroundColor: '#ededed',
        }}
      >
        <View
          style={{
            width: '19.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="46,03 %"
            info="MC (%)"
            position="right"
            backgroundColor="#be5948"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '19.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="29,08 m.m."
            info="GD (m.m)"
            position="right"
            backgroundColor="#d47d57"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '19.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="70,06 m.m."
            info="LM (m.m)"
            position="right"
            backgroundColor="#e29662"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '19.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="85,66 %"
            info="RCCL (%)"
            position="right"
            backgroundColor="#f1ae6c"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
        <View
          style={{
            width: '19.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="16,08 m.m."
            info="RCRL (%)"
            position="right"
            backgroundColor="#ffc676"
            widthCircle={30}
            widthInfo={35}
            fontSizeValue={8}
          />
        </View>
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Detalle del Inventario de Canales Refrigeradas"
          widthContainer="100%"
        />
      </View>

      <View style={{ width: '100%' }}>
        <View style={[styles.tableMagro, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '10px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Nro. Cava
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 67, width: 67 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Nro. Lotes
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Lotes IP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Total Canales
              </Text>
              <Text style={[styles.tableCell, styles.tableHead]}>Lotes</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canales</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Despachadas
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canales</Text>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Disponibles
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Días en Cavas
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Tiempo Total
              </Text>
              <Text style={[styles.tableCell, styles.tableHead]}>Cavas</Text>
            </View>
          </View>
          {detalle.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View
                style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.nroCava}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 67, width: 67 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.nroLotes}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.lotesIp}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.totalCanales}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.canalesDespachas}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.canalesDisponibles}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.diasCavas}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 69.37, width: 69.37 }]}
              >
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item.tiempoTotal}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 69.37,
                width: 69.37,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 67, width: 67, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              10
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 69.37, width: 69.37, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 69.37, width: 69.37, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              427
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 69.37, width: 69.37, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              0
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 69.37, width: 69.37, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              427
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 138.74, width: 138.74, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportDate = () => {
  return (
    <div id="screenqrcavadate" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte Inventario Canales Refrigeradas"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
