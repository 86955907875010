import React from 'react';
import { Typography, List, ListItem, Box } from '@material-ui/core';
import _ from 'lodash';
import clsx from 'clsx';

import useStyles from './styles';

interface TableInfoProps {
  title: string;
  icon?: string;
  items: object;
  withoutBox?: boolean;
}

const TableInfo = ({ title, icon, items, withoutBox }: TableInfoProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {withoutBox === true ? (
        <List className={classes.listContainer}>
          {_.map(items, (value, key) => (
            <ListItem className={classes.itemListData}>
              <Typography>{key}</Typography>
              <Typography className={classes.itemChild}>{value}</Typography>
            </ListItem>
          ))}
        </List>
      ) : (
        <Box className={classes.container}>
          <div className={classes.titleContainer}>
            <Box component="i" className={clsx(`${icon}`, classes.icon)} />
            <Typography>{title}</Typography>
          </div>
          <List className={classes.listContainer}>
            {_.map(items, (value, key) => (
              <ListItem className={classes.itemListData}>
                <Typography>{key}</Typography>
                <Typography className={classes.itemChild}>{value}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default TableInfo;
