import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Box,
  DialogActions,
  Button,
  Grow,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useHistory } from 'react-router';

import MainTitle from 'components/MainTitle';
import CardSelectDriver from 'components/TransportComponents/CardSelectDriver';

import { GET_UNASSOCIATED_DRIVER } from 'core/graphql/queries/transport';
import { POST_DRIVER_VEHICLE } from 'core/graphql/mutations/transport';

import useStyles from './styles';
import Slider from 'components/Slider';

type DialogProps = {
  open: boolean;
  data: any;
  handleClose: (event: any) => void;
};

const DialogAddDriver = ({ open, data, handleClose }: DialogProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectOption, setSelectOption] = useState(0);
  const [selectDriver, setSelectDriver] = useState<number>();

  const [
    getDrivers,
    { data: dataDrivers, loading: loadingDrivers, error: errorDrivers },
  ] = useLazyQuery(GET_UNASSOCIATED_DRIVER, {
    fetchPolicy: 'network-only',
  });

  const drivers = dataDrivers?.getUnassociatedDrivers.data;

  useEffect(() => {
    if (open && selectOption === 1) {
      getDrivers({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idVehiculo: data.id },
      });
    }
  }, [selectOption, open]);

  const [postDriverXVehicle, { loading: loadingDriverVehicle }] = useMutation(
    POST_DRIVER_VEHICLE,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const associateDriverXVehicle = (
    idDriver: number,
    idVehicle: number,
    event: any
  ) => {
    postDriverXVehicle({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.postDriverXVehicle.data.success === 1) {
          enqueueSnackbar('Conductor creado exitosamente.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
          handleClose(event);
        } else {
          enqueueSnackbar(mutationResult.data.postDriverXVehicle.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        idConductor: idDriver,
        idVehiculo: idVehicle,
      },
    });
  };

  useEffect(() => {
    setSelectOption(0);
  }, [open]);

  const breakPointsSlider = [
    { width: 1, itemsToShow: 1 },
    { width: 450, itemsToShow: 2, itemsToScroll: 2 },
    { width: 700, itemsToShow: 3 },
    { width: 1050, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6, itemsToScroll: 3 },
  ];

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={selectOption !== 1 ? 'sm' : 'lg'}
        className={clsx({
          [classes.borderRadiusDialogSm]: selectOption !== 1,
          [classes.borderRadiudDialogLg]: selectOption === 1,
        })}
        scroll="body"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid container justify="flex-end">
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container className={classes.title}>
              <MainTitle>Agregar Conductor al Vehículo</MainTitle>
            </Grid>
            <Grid container className={classes.title}>
              <Typography variant="h5" className={classes.fontPlate}>
                {data.placa}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ overflowY: 'hidden' }}>
          {selectOption !== 1 && (
            <>
              <MainTitle fontSize="h6">Seleccione la opción deseada</MainTitle>

              <Grid container justifyContent="center">
                <Grid item style={{ margin: 20 }}>
                  <Grow in timeout={800} style={{ transitionDelay: '100ms' }}>
                    <Card
                      elevation={2}
                      className={clsx({
                        [classes.cardItem]: true,
                        [classes.cardSelected]: selectOption === 1,
                      })}
                    >
                      <CardActionArea
                        onClick={() => setSelectOption(1)}
                        className={classes.cardActionArea}
                      >
                        <CardContent className={classes.cardContent}>
                          <Box
                            component="i"
                            className={clsx(
                              'icon-user-search',
                              classes.roleIcon
                            )}
                          />
                          <Typography className={classes.mainText}>
                            Buscar Conductor
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                </Grid>
                <Grid item style={{ margin: 20 }}>
                  <Grow in timeout={800} style={{ transitionDelay: '200ms' }}>
                    <Card
                      elevation={2}
                      className={clsx({
                        [classes.cardItem]: true,
                        [classes.cardSelected]: selectOption === 2,
                      })}
                      onClick={() =>
                        history.push(
                          `/app/network/transports/forms/register-driver-vehicle/${data.id}`
                        )
                      }
                    >
                      <CardActionArea
                        onClick={() => setSelectOption(2)}
                        className={classes.cardActionArea}
                      >
                        <CardContent className={classes.cardContent}>
                          <Box
                            component="i"
                            className={clsx('icon-user', classes.roleIcon)}
                          />
                          <Typography className={classes.mainText}>
                            Registrar Conductor
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                </Grid>
              </Grid>
            </>
          )}

          {selectOption === 1 && !loadingDrivers && drivers && (
            <div>
              <Grid container justifyContent="center">
                <Box
                  mt={1}
                  mb={4}
                  maxWidth="55%"
                  textAlign="center"
                  color="#afafaf"
                >
                  <Typography>
                    {drivers?.length > 0
                      ? 'Seleccione el Conductor que desea asociar al Vehículo anteriormente registrado. Si desea registrar un Conductor, seleccione la opción Registrar Conductor. '
                      : 'No se encontraron Conductores para asociar, esto significa que los conductores registrados ya estan asociados a este vehículo, para registrar uno nuevo seleccione la opción Registrar Conductor.'}

                    <Box mt={2} mb={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          history.push(
                            `/app/network/transports/forms/register-driver-vehicle/${data.id}`
                          )
                        }
                      >
                        Registrar Conductor
                      </Button>
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid
                container
                spacing={5}
                justifyContent="center"
                alignItems="center"
              >
                <Slider
                  breakPoints={breakPointsSlider}
                  className={classes.carouselContainerDriver}
                >
                  {drivers?.map((item: any, ind: number) => {
                    return (
                      <CardSelectDriver
                        name={item?.nombreCompleto}
                        ubication={item?.ciudad}
                        document={item?.numeroDocumento}
                        width={220}
                        height={240}
                        onClick={() => setSelectDriver(item?.idConductor)}
                        checked={selectDriver === item?.idConductor}
                      />
                    );
                  })}
                  <Card elevation={2} className={classes.cardItemAddDriver}>
                    <CardActionArea
                      className={classes.cardActionArea}
                      onClick={() =>
                        history.push(
                          `/app/network/transports/forms/register-driver-vehicle/${data.id}`
                        )
                      }
                    >
                      <CardContent>
                        <Grid container justifyContent="center">
                          <Box
                            component="i"
                            className={clsx(
                              'icon-circle-plus',
                              classes.iconCard
                            )}
                          />
                          <Typography className={classes.mainText}>
                            Registrar Conductor
                          </Typography>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Slider>
              </Grid>
            </div>
          )}
          {selectOption === 1 && loadingDrivers && (
            <Box textAlign="center" marginTop={5}>
              <CircularProgress color="primary" size={60} />
            </Box>
          )}
        </DialogContent>

        <DialogActions className={classes.marginContainer}>
          <Grid container justify="center" spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cerrar
              </Button>
            </Grid>

            {selectOption === 1 && drivers?.length !== 0 && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) =>
                    associateDriverXVehicle(Number(selectDriver), data.id, e)
                  }
                  disabled={selectDriver === 0 || selectDriver === undefined}
                >
                  Asignar Conductor
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DialogAddDriver;
