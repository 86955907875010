import { Document, Image, Page, View, Font, Text } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import postmortenLogo from 'static/img/reportsIcons/postmortenLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import productorIcon from 'static/img/reportsIcons/productorIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import TableInfo from 'components/pdf-components/TableInfo/index';
import Subtitle from 'components/pdf-components/Subtitle/index';
import NumberTotal from 'components/pdf-components/NumberTotal/index';
import Footer from 'components/pdf-components/Footer/index';

import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF/index';
import ChartList from 'components/pdf-components/ChartList/index';
import Paginationpdf from 'components/pdf-components/Pagination';

import styles from './styles/lote';

const item = [
  'LB',
  'SBE',
  'LB',
  'LB',
  'SBE',
  'LB',
  'SBE',
  'LB',
  'SBE',
  'SBE',
  'SBE',
  'LB',
  'SBE',
  'LB',
  'SBE',
  'LB',
  'SBE',
  'SBE',
  'LB',
];

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

interface MyDocPostMortemProps {
  qr: string;
  graph: string;
  graphTwo: string;
}

export const MyDocPostMortem = ({
  qr,
  graph,
  graphTwo,
}: MyDocPostMortemProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={6700}
        reportImage={postmortenLogo}
        number="023456789"
        title="Inspección Médico Veterinaria - Post Mortem"
        code="P M-001"
      />
      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={productorIcon}
          title="Productor"
          items={{
            Nombre: 'Nombre productor',
            NIT: '34123412-2',
            Teléfono: '+57 314 6543 543',
            Email: 'info@productor.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Resumen Inspección Post-Mortem"
          widthContainer="100%"
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          marginTop: '5px',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            width: '40.3%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              width: '50%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View style={{ marginBottom: '10px' }}>
              <ItemTotalCircle
                value="2"
                info="Total Animales Inspeccionados"
                position="right"
                textAlign="left"
                backgroundColor="#be5948"
                widthInfo="55px"
                widthCircle={30}
              />
            </View>
            <View style={{ marginBottom: '10px' }}>
              <ItemTotalCircle
                value="2"
                info="Canales Completas Decomisadas (L.B)"
                position="right"
                textAlign="left"
                backgroundColor="#f1ae6c"
                widthInfo="65px"
                widthCircle={25}
              />
            </View>
            <View>
              <ItemTotalCircle
                value="0"
                info="Canales Completas Decomisadas (S.B.E)"
                position="right"
                textAlign="left"
                backgroundColor="#ffc676"
                widthInfo="65px"
                widthCircle={25}
              />
            </View>
          </View>
          <View
            style={{
              width: '53%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View style={{ marginBottom: '10px' }}>
              <NumberTotal
                value="2%"
                info="Porcentaje de Canales con Decomisos"
                position="bottom"
                justifyInfo="center"
                widthValue={70}
                widthInfo="80px"
              />
            </View>
            <View>
              <NumberTotal
                value="216,00 Kg"
                info="Total Carne Decomisada (Kgs)"
                position="bottom"
                justifyInfo="center"
                widthValue={70}
                widthInfo="90px"
              />
            </View>
          </View>
        </View>
        <View
          style={{
            width: '59.3%',
            flexDirection: 'row',
            backgroundColor: '#ededed',
          }}
        >
          <View
            style={{
              height: '120px',
              width: '50%',
              marginTop: 10,
              marginBottom: 5,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image
              src={graph}
              style={{ height: '150px', width: '150px', marginRight: 5 }}
            />
          </View>
          <View style={{ justifyContent: 'center', width: '50%' }}>
            <Text style={{ fontWeight: 'bold' }}>Partes Decomisadas</Text>
            <ChartList
              data={[
                {
                  label: 'Pulmón',
                  data: 101,
                  bg: '#ffc676',
                },
                {
                  label: 'Piel',
                  data: 6,
                  bg: '#e29662',
                },
                {
                  label: 'Carne',
                  data: 6,
                  bg: '#d47d57',
                },
                {
                  label: 'Visceras Blancas',
                  data: 2,
                  bg: '#be5948',
                },
                {
                  label: 'Visceras Rojas',
                  data: 2,
                  bg: '#b0413e',
                },
              ]}
            />
          </View>
        </View>
      </View>

      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View style={{ width: '29.2%' }}>
          <Subtitle
            title="Decomisos Línea de Beneficio"
            widthContainer="100%"
          />
          <View
            style={{
              height: '50px',
              backgroundColor: '#ededed',
              marginTop: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NumberTotal
              value="2%"
              info="Porcentaje de Cananales Completas Decomisadas"
              position="right"
              widthValue={60}
              widthInfo={100}
            />
          </View>

          <View
            style={{
              height: '50px',
              backgroundColor: '#ededed',
              marginTop: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NumberTotal
              value="02"
              info="Total Cananales Completas Decomisadas"
              position="right"
              widthValue={60}
              widthInfo={100}
            />
          </View>

          <View
            style={{
              height: '50px',
              backgroundColor: '#ededed',
              marginTop: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NumberTotal
              value="216,00 Kg"
              info="Total Carne Decomisada (Kgs) "
              position="right"
              widthValue={70}
              widthInfo={50}
            />
          </View>

          <View
            style={{
              height: '50px',
              backgroundColor: '#ededed',
              marginTop: '10px',
              justifyContent: 'center',
            }}
          >
            <Text>Miguel Angel Rondal Bedoya</Text>
            <Text>C.C. 214347675</Text>
            <Text style={{ fontWeight: 'bold' }}>
              Inspector Médico Veterinario
            </Text>
          </View>
        </View>
        <View style={{ width: '29.2%' }}>
          <Subtitle title="Decomisos Sala S.B.E." widthContainer="100%" />
          <View
            style={{
              height: '50px',
              backgroundColor: '#ededed',
              marginTop: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NumberTotal
              value="0%"
              info="Porcentaje de Cananales Completas Decomisadas"
              position="right"
              widthValue={60}
              widthInfo={100}
            />
          </View>

          <View
            style={{
              height: '50px',
              backgroundColor: '#ededed',
              marginTop: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NumberTotal
              value="0"
              info="Total Cananales Completas Decomisadas"
              position="right"
              widthValue={60}
              widthInfo={100}
            />
          </View>

          <View
            style={{
              height: '50px',
              backgroundColor: '#ededed',
              marginTop: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NumberTotal
              value="0,00 Kg"
              info="Total Carne Decomisada (Kgs)"
              position="right"
              widthValue={70}
              widthInfo={50}
            />
          </View>

          <View
            style={{
              height: '50px',
              backgroundColor: '#ededed',
              marginTop: '10px',
              justifyContent: 'center',
            }}
          >
            <Text>Julian David Mejia Ramirez</Text>
            <Text>C.C. 214347675</Text>
            <Text style={{ fontWeight: 'bold' }}>
              Inspector Médico Veterinario
            </Text>
          </View>
        </View>
        <View style={{ width: '35.2%' }}>
          <Subtitle title="Causas Decomisos Partes" widthContainer="100%" />
          <View style={{ alignItems: 'center' }}>
            <Image src={graphTwo} style={{ width: '150px', height: '150px' }} />
          </View>

          <View
            style={{ justifyContent: 'center', width: '130%', marginTop: -30 }}
          >
            <View style={{ width: '80%' }}>
              <Text style={{ fontWeight: 'bold' }}>Detalle de Causas</Text>
            </View>
            <ChartList
              data={[
                {
                  label: 'Broncoaspiración - Pulmón',
                  data: 68,
                  bg: '#FFC676',
                },
                {
                  label: 'Congestión - Pulmón',
                  data: 20,
                  bg: '#F1AE6C',
                },
                {
                  label: 'Nuemonía - Pulmón',
                  data: 6,
                  bg: '#E29662',
                },
                {
                  label: 'Adherencias - Carne',
                  data: 12,
                  bg: '#D47D57',
                },
                {
                  label: 'Abscesos - Piel',
                  data: 6,
                  bg: '#BE5948',
                },
                {
                  label: 'Diagnóstico - Parte Afectada',
                  data: 17,
                  bg: '#B0413E',
                },
              ]}
            />
          </View>
        </View>
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Detalle Decomisos Partes Post-Mortem"
          widthContainer="100%"
        />
      </View>

      <View>
        <View style={[styles.table, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              { marginBottom: '5px', marginTop: '5px', alignItems: 'center' },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 78, width: 78 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canal ID</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 60, width: 60 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Cantidad</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 78, width: 78 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Parte Decomisada
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 89, width: 89 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Causa</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 78, width: 78 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Decomiso (Kg)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 70, width: 70 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Origen</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 97, width: 97 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Observaciones
              </Text>
            </View>
          </View>
          {item.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 78, width: 78 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  45143324
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 60, width: 60 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>2</Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 78, width: 78 }]}>
                <Text
                  style={(styles.tableCellPost, { fontSize: 7, marginTop: 5 })}
                >
                  Pulmón
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 89, width: 89 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  Broncoaspiración
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 78, width: 78 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  0,00
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 70, width: 70 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  {item}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 97, width: 97 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  Ninguna Observación.
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 78,
                width: 78,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 60, width: 60, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              64
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 167, width: 167, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 78, width: 78, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              0,00
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 167, width: 167, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
        </View>
      </View>

      <View
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View style={[styles.conventions, { width: '60%' }]} wrap={false}>
          <View style={styles.backgroundguide}>
            <View style={styles.textconventions}>
              <View style={styles.gpost}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * SBE
                </Text>
              </View>
              <View style={styles.textpost}>
                <Text>Sala de Beneficio de Emergencia</Text>
              </View>
            </View>
            <View style={styles.textconventions}>
              <View style={styles.gpost}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * LB
                </Text>
              </View>
              <View style={styles.textpost}>
                <Text>Línea de Beneficio</Text>
              </View>
            </View>
          </View>
          <View style={styles.borderPM}>
            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
              Convenciones
            </Text>
          </View>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screenLotPM" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte por Lote Post Mortem"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphPM = () => {
  return (
    <div id="graphpm" style={{ width: 500 }}>
      <Graphicpdfsf
        textTotalData="Partes Decomisadas"
        topTextTotalData={-440}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        data={[
          {
            label: 'Pulmón',
            data: 101,
            bg: '#ffc676',
          },
          {
            label: 'Piel',
            data: 6,
            bg: '#e29662',
          },
          {
            label: 'Carne',
            data: 6,
            bg: '#d47d57',
          },
          {
            label: 'Visceras Blancas',
            data: 2,
            bg: '#be5948',
          },
          {
            label: 'Visceras Rojas',
            data: 2,
            bg: '#b0413e',
          },
        ]}
      />
    </div>
  );
};

export const GraphPMTwo = () => {
  return (
    <div id="graphpmtwo" style={{ width: 500, marginTop: -80 }}>
      <Graphicpdfsf
        textTotalData="Decomisos"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        startAngle={-1}
        endAngle={359}
        data={[
          {
            label: 'Broncoaspiración - Pulmón',
            data: 68,
            bg: '#FFC676',
          },
          {
            label: 'Congestión - Pulmón',
            data: 20,
            bg: '#F1AE6C',
          },
          {
            label: 'Nuemonía - Pulmón',
            data: 6,
            bg: '#E29662',
          },
          {
            label: 'Adherencias - Carne',
            data: 12,
            bg: '#D47D57',
          },
          {
            label: 'Abscesos - Piel',
            data: 6,
            bg: '#BE5948',
          },
          {
            label: 'Diagnóstico - Parte Afectada',
            data: 17,
            bg: '#B0413E',
          },
        ]}
      />
    </div>
  );
};
