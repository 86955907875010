import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: '12px 0',
  },
  textImportant:{
    fontWeight: 'bold',
    color: '#767676', 
  },
  withPadding:{
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',
    padding: 8
  }, 
  infoImportant:{
    color: '#DD9835', 
    fontWeight: 'bold'
  }, 
  dataGrid:{
    border:'none', 
    display:'flex',
    margin: '20px 0',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator":{
      display:'none'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14
    }
  },
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& :hover":{
      color: '#fff'
    }
  },
  result:{
    display:'flex', 
    alignItems:'center',
    marginRight: 12,
    "& i":{
      fontSize:24,
    }
  },
})); 
export { useStyles as default }