import dataTableInitialState from './../initialStates/dataTableInitialState'
import { SELECT_ALL, INITIALIZE_TABLE, SET_DATASOURCE, RESET_TABLE } from '../types/dataTableTypes'

export default (state = dataTableInitialState, action: any) => {
  switch (action.type) {
    case INITIALIZE_TABLE:
      return {
        ...state,
        ...action.payload
      }
    case RESET_TABLE:
      return {
        ...state,
        dataSource: [],
        tableHeaders: []
      }
    case SET_DATASOURCE:
      return {
        ...state,
        dataSource: action.payload
      }
    case SELECT_ALL:
      return {
        ...state,
        dataSource: action.payload
      }

    default: return { ...state }
  }
}
