import * as yup from "yup";
import validations from "./messages";

export const NewInternalUserValidations = yup.object({
  firstName: yup
    .string()
    .required(validations.REQUIRED_FIELD)
    .min(3, "El nombre debe tener al menos 3 caracteres"),

  firstSurname: yup.string().required(validations.REQUIRED_FIELD),

  documentType: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .typeError(validations.ONLY_NUMBERS),
  documentNumber: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .typeError(validations.ONLY_NUMBERS),
  idPosition: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .typeError(validations.ONLY_NUMBERS),
  indicativePhone: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .typeError(validations.ONLY_NUMBERS),
  phoneNumber: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .min(10, "Ingresa un número de celular válido")
    .typeError(validations.ONLY_NUMBERS),
  email: yup
    .string()
    .email(validations.INVALID_EMAIL_ADDRESS)
    .required(validations.REQUIRED_FIELD),
  username: yup.string().required(validations.REQUIRED_FIELD),
  password: yup
    .string()
    .required(validations.REQUIRED_FIELD)
    .test(
      "min-length",
      "Minímo 8 caracteres",
      (value: any) => value?.length >= 8
    )
    .test(
      "pass-uppercase",
      "Debe contener almenos una mayúscula",
      (value: any) => {
        let nMay = 0;
        const t1 = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
        for (let index = 0; index < value?.length; index++) {
          if (t1.indexOf(value.charAt(index)) !== -1) {
            nMay++;
          }
        }
        return nMay > 0 ? true : false;
      }
    )
    .test(
      "pass-lowercase",
      "Debe contener almenos una minúscula",
      (value: any) => {
        let nMin = 0;
        const t2 = "abcdefghijklmnñopqrstuvwxyz";
        for (let index = 0; index < value?.length; index++) {
          if (t2.indexOf(value.charAt(index)) !== -1) {
            nMin++;
          }
        }
        return nMin > 0 ? true : false;
      }
    )
    .test("pass-number", "Debe contener al menos un número", (value: any) => {
      let nNum = 0;
      const t3 = "0123456789";
      for (let index = 0; index < value?.length; index++) {
        if (t3.indexOf(value.charAt(index)) !== -1) {
          nNum++;
        }
      }
      return nNum > 0 ? true : false;
    })
    .matches(/[a-zA-Z0-9]/, {
      message: "No se permiten caracteres especiales ni espacios",
    }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "La contraseña no coincide")
    .required(validations.REQUIRED_FIELD),
});

export const UpdateInternalUserDataValidations = yup.object({
  firstName: yup
    .string()
    .required(validations.REQUIRED_FIELD)
    .min(3, "El nombre debe tener al menos 3 caracteres"),
  firstSurname: yup.string().required(validations.REQUIRED_FIELD),
  documentType: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .typeError(validations.ONLY_NUMBERS),
  documentNumber: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .typeError(validations.ONLY_NUMBERS),
  idPosition: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .typeError(validations.ONLY_NUMBERS),
  indicativePhone: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .typeError(validations.ONLY_NUMBERS),
  phoneNumber: yup
    .number()
    .required(validations.REQUIRED_FIELD)
    .min(10000, "Ingresa un número de celular válido")
    .typeError(validations.ONLY_NUMBERS),
  email: yup
    .string()
    .email(validations.INVALID_EMAIL_ADDRESS)
    .required(validations.REQUIRED_FIELD),
});
