import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  backgroundweigth: {
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    color: '#dd9835',
    fontSize: 15,
  },
  weigthanimals: {
    width: 40,
    fontSize: 8,
    fontFamily: 'Roboto',
  },
})