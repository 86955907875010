import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dialogContainer:{
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px'
    }
  },
  contentDialog:{
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'center', 
  },
  containerPrimaryInfo:{
    marginBottom: 20
  }, 
  primaryInfoContent:{
    display: 'flex',
    padding: '4px 8px',
    background: '#F2F2F2',
    justifyContent: 'space-between',
  }, 
  card:{
    minWidth: 200,
    borderRadius: 10, 
    boxShadow: theme.shadows[2],
    margin: 12
  }, 
  content:{
    height: 120,
    padding: 12, 
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center', 
    fontSize: '50px'
  }, 
  info:{
    color: '#AFAFAF',  
    fontWeight: 'bold', 
    margin: '0px 4px'
  }, 
  table:{
    "& .MuiTableCell-root":{
      border: 'none'
    }
  },
  itemGrey :{
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  btnDetails:{
    width: '35px',
    height: '30px',
    "&:hover":{
      color: '#fff'
    }
  },
  dialogFooter:{
    display: 'flex', 
    justifyContent: 'space-evenly',
    alignItems: 'center', 
    margin: '12px 0px'
  }, 
  subtitleContainer:{
    width: '100%', 
    margin: '12px 0px', 
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }, 
  description:{
    color: '#767676', 
    margin: '12px 0px'
  }, 
  checkbox:{
    "& .MuiTypography-root":{
      color: '#767676', 
      fontWeight: 'bold'
    }
  }
}))

export { useStyles as default }
