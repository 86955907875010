import styled from 'styled-components';

import Background from 'static/img/backgrounds/banner-auth.png';

const randomImage = [
  Background,
  'https://c4.wallpaperflare.com/wallpaper/101/984/1018/pigs-dirt-lot-cubs-wallpaper-preview.jpg',
  'https://images.unsplash.com/photo-1537033206914-9d3551ff8103?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NXx8cGlnfGVufDB8fDB8&ixlib=rb-1.2.1&w=1000&q=80',
  'https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/rZJIMvhmliwmde8a6/videoblocks-pig-is-eating-straw-small-group-of-piggies-domestic-animals-live-in-barn-source-of-money-for-farm_bwo0guqgw_thumbnail-1080_01.png',
];

export const BannerContainer = styled.div`
  background-image: url(${randomImage[
    Math.floor(Math.random() * randomImage.length)
  ]});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const Overlay = styled.div`
  background: #dd9835;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  & > h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
  }

  & > h5 {
    font-family: 'Roboto', Arial !important;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    width: 80%;
    margin-top: 0 !important;
  }
`;
