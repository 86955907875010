import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import domtoimage from 'dom-to-image';

import GobackLink from 'components/GobackLink';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import ItemTotalInfo from 'components/ItemTotalInfo/index';
import ItemListInfo from 'components/ItemListInfo/index';
import TableInfo from 'components/TableInfo/index';
import LoteInfoTitle from 'components/LoteInfoTitle';

import { ReportLot, MyDoc } from './../../Reports/ReportLot';

import useStyles from './styles';
import DialogPDFViewer from './../../../../../../components/DialogPDFViewer/index';

const pageBreadcrumbs: BreadcrumbProps[] = [
  {
    link: '/app/reports/mobilization-guides/details',
    title: 'Guías de Movilización',
  },
  { link: '/app/reports/mobilization-guides', title: 'Mis Resportes' },
];

const Details = () => {
  const classes = useStyles();
  const [openLotGSMI, setOpenLotGSMI] = useState(false);
  const [imageQr, setImageQr] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screen');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalLotGSMI = () => {
    // generateScreenshot();
    setOpenLotGSMI(true);
  };

  useEffect(() => {
    generateScreenshot();
  }, [openLotGSMI]);
  return (
    <>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <Grid container>
        <ContentCard className={classes.cardContainer}>
          <ContentCard.Content>
            <Grid item container>
              <LoteInfoTitle
                lot="6700"
                namereport="Nro. Reporte"
                numberreport="011-65432"
                title="Guías de Movilización"
              />
            </Grid>

            <Grid container className={classes.titleContainer}>
              <Grid item container justify="center" alignItems="center">
                <Typography variant="h6">Agentes Relacionados</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item container xs={6} className={classes.gridContainer}>
                <Grid item container spacing={2}>
                  <Grid item container xs={6}>
                    <TableInfo
                      icon="icon-productor"
                      title="Productor"
                      items={{
                        Nombre: 'Nombre productor',
                        NIT: '45134132',
                        Teléfono: '+57 314 6543 543',
                        Email: 'info@productor.com',
                        Dirección: 'Calle 105 A 23-23',
                      }}
                    />
                  </Grid>

                  <Grid item container xs={6}>
                    <TableInfo
                      icon="icon-planta"
                      title="Planta Beneficio"
                      items={{
                        Nombre: 'Nombre Planta',
                        NIT: '45134132',
                        Teléfono: '+57 314 6543 543',
                        Email: 'info@planta.com',
                        Dirección: 'Calle 105 A 23-23',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={6} className={classes.gridContainer}>
                <Grid item container spacing={2}>
                  <Grid item container xs={6}>
                    <TableInfo
                      icon="icon-granja"
                      title="Granja"
                      items={{
                        Nombre: 'Nombre Granja',
                        Responsable: 'Nombre Responsable',
                        Teléfono: '+57 314 6543 543',
                        Email: 'info@granja.com',
                        Dirección: 'Calle 105 A 23-23',
                      }}
                    />
                  </Grid>

                  <Grid item container xs={6}>
                    <TableInfo
                      icon="icon-truck"
                      title="Transporte"
                      items={{
                        Nombre: 'Nombre Conductor',
                        Documento: '45134132',
                        Placa: 'ERW 753',
                        Ciudad: 'Medellín',
                        Vehículo: 'Toyota 45-G',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.titleContainer}>
              <Grid item container justify="center" alignItems="center">
                <Typography variant="h6">
                  Resumen de la Movilización de Animales
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item container xs={4} justify="center" alignItems="center">
                <ItemTotalInfo
                  avatarTotalInfo={58}
                  avatarTotalColor="#E29662"
                  avatarTotalSize={80}
                  avatarTextSize={30}
                  titleInfo="Total Animales Movilizados"
                  position="right"
                />
              </Grid>
              <Grid item container xs={4}>
                <ItemListInfo
                  icon="icon-granja"
                  titleInfo="Codigo Granja"
                  numberInfo="654321"
                />
                <ItemListInfo
                  icon="icon-document"
                  titleInfo="Lote de Ceba"
                  numberInfo="123456"
                />
                <ItemListInfo
                  icon="icon-ticket"
                  titleInfo="Tiquete de Báscula"
                  numberInfo="051465578"
                />
              </Grid>
              <Grid item container xs={4}>
                <ItemListInfo
                  icon="icon-calendar"
                  titleInfo="Fecha Guía Movilización"
                  numberInfo="22/01/2019"
                />
                <ItemListInfo
                  icon="icon-ticket"
                  titleInfo="Guía Valida Hasta"
                  numberInfo="23/01/2019"
                />
                <ItemListInfo
                  icon="icon-document"
                  titleInfo="Nro. Guía de Movilización"
                  numberInfo="11-2315375"
                />
              </Grid>
            </Grid>
          </ContentCard.Content>
        </ContentCard>
        <Grid container justify="flex-end" alignItems="center">
          <Box mr={2}>
            <Typography className={classes.fontSubtitle}>
              Opciones del Reporte
            </Typography>
          </Box>
          <Button
            startIcon={<i className="icon-eye" />}
            variant="contained"
            className="raleway"
            color="primary"
            onClick={openModalLotGSMI}
          >
            Previsualizar
          </Button>
        </Grid>
      </Grid>
      <DialogPDFViewer
        open={openLotGSMI}
        onClose={() => setOpenLotGSMI(false)}
        done={Boolean(imageQr)}
      >
        <MyDoc qr={imageQr} />
      </DialogPDFViewer>
    </>
  );
};

export default Details;
