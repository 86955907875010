import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: '8px 0',
  }, 
  containerHeader:{
    display:'flex', 
    justifyContent:'space-between', 
    marginBottom: 32, 
    alignItems: 'center'
  }, 
  haderChild:{
    display:'flex'
  },
  avatarUser:{
    width: 50, 
    height:50, 
    fontWeight:'bolder', 
    fontSize:24
  }, 
  containerUserInfo:{
    marginLeft:12
  },
  nameUser:{
    color: theme.palette.grey[400], 
    fontWeight:'bolder'
  },
  userName: {
    color: theme.palette.grey[400]
  },
  cardHeader:{
    display:'flex',
    justifyContent:'space-between', 
    alignItems: 'center', 
  }, 
  title:{
    fontWeight:'bold'
  }, 
  search:{
    width:'30%'
  },
  containerTab:{
    padding: 12
  },
  tabSmall:{
    fontWeight:900,
    minWidth: 50,
  },
  tabsChild:{
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  dataGrid:{
    minHeight: 400,
    border:'none', 
    display:'flex',
    margin: '12px 0',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator":{
      display:'none'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14, 
    }, 
    "& .MuiDataGrid-columnsContainer":{
      justifyContent: 'center'
    }
  },
  headerTableGrid:{
    fontSize:14, 
    color: '#AFAFAF',
    fontFamily:'Roboto',
  },
  avatar:{
    width: '30px', 
    height: '30px', 
    fontSize: '20px', 
    background: '#DD9835', 
    marginRight:8
  }, 
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "&:hover":{
      color: '#fff'
    }
  },

  
}))

export default useStyles