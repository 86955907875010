import React, { useState } from 'react';

import { Box, Fab } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { Share } from '@material-ui/icons';

import FooterGrid from 'components/datagrid-custom-components/TableFooter';
import LotDetail from 'components/PageComponents/SharedReports/LotDetail';
import ShareReport from 'components/PageComponents/SharedReports/ShareReport';

import dayjs from 'dayjs';

import useStyles from './styles';

const ReportPerType = ({ type }: any) => {
  const classes = useStyles();

  const users = [
    {
      id: 1,
      nameType: 'Tiquete de Báscula',
      iconType: 'icon-ticket',
      loteIP: 2041,
      reportNumber: 203050,
      created_at: dayjs().format('DD/MM/YYYY'),
      comercializador: 'Ivan Echeverri',
      productor: 'Nestor Angel',
      sharedBy: 'Paula Bolivar',
      sharedSice: dayjs().format('DD/MM/YYYY'),
      sharedUntil: dayjs().date(30).format('DD/MM/YYYY'),
    },
    {
      id: 2,
      nameType: 'Portería',
      iconType: 'icon-porteria',
      loteIP: 2042,
      reportNumber: 203052,
      created_at: dayjs().date(20).format('DD/MM/YYYY'),
      comercializador: 'Ivan Echeverri',
      productor: 'Nestor Angel',
      sharedBy: 'Sebastian Mosquera',
      sharedSice: dayjs().format('DD/MM/YYYY'),
      sharedUntil: dayjs().date(30).format('DD/MM/YYYY'),
    },
    {
      id: 3,
      nameType: 'Viseras Blancas',
      iconType: 'icon-sausage',
      loteIP: 1920,
      reportNumber: 103052,
      created_at: dayjs().date(20).format('DD/MM/YYYY'),
      comercializador: 'Ivan Echeverri',
      productor: 'Nestor Angel',
      sharedBy: 'Sebastian Mosquera',
      sharedSice: dayjs().format('DD/MM/YYYY'),
      sharedUntil: dayjs().date(30).format('DD/MM/YYYY'),
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'reportNumber',
      headerName: 'N° de Reporte',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'created_at',
      headerName: 'Fecha Creación',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'sharedBy',
      headerName: 'Usuarios con Acceso',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 170,
    },
    {
      field: 'productor',
      headerName: 'Productor',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 300,
    },
    {
      field: 'comercializador',
      headerName: 'Comercializador',
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 300,
      sortable: false,
    },
    {
      field: 'Destino',
      headerName: 'Destino',
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 200,
      sortable: false,
      hide: type !== 'Distribución y Despachos',
    },
    {
      field: 'Detalle',
      headerName: '',
      renderCell: () => (
        <LotDetail>
          {({ handleOpen }: any) => (
            <Box>
              <Fab
                color="primary"
                className={classes.btnDetails}
                onClick={handleOpen}
              >
                <Box component="i" fontSize="20px" className="icon-eye" />
              </Fab>
            </Box>
          )}
        </LotDetail>
      ),
      disableColumnMenu: true,
      sortable: false,
      headerClassName: classes.headerTableGrid,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'Compartir',
      headerName: '',
      renderCell: () => (
        <ShareReport>
          {({ handleOpen }: any) => (
            <Box>
              <Fab
                color="primary"
                className={classes.btnDetails}
                onClick={handleOpen}
              >
                <Share />
              </Fab>
            </Box>
          )}
        </ShareReport>
      ),
      disableColumnMenu: true,
      sortable: false,
      headerClassName: classes.headerTableGrid,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <>
      <Box minHeight={300}>
        <DataGrid
          pageSize={10}
          columns={columns}
          rows={users}
          className={classes.dataGrid}
          components={{
            Footer: FooterGrid,
          }}
        />
      </Box>
    </>
  );
};

export default ReportPerType;
