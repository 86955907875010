import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Button,
  Fab,
  Tooltip,
  Grow,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { GoogleApiWrapper } from "google-maps-react";
import clsx from "clsx";
import config from "config/secrets";
import MapInput from "components/MapInput";
import PlacesInput from "components/PlacesInput";
import formatCoords from "lib/helpers/formatCoords";
import useStyles from "./styles";

interface CoordinatesMap {
  latProp?: any;
  lngPro?: any;
  google: any;
  values: any;
  names: any;
  setFieldValue: any;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
}

const CoordsForm = ({
  values,
  names,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
  touched,
}: CoordinatesMap) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [initialCenter, setInitialCenter] = useState({
    lat: values?.latitude ?? 0,
    lng: values?.longitude ?? 0,
  });
  const [direction, setDirection] = useState("");
  const [showInputSearch, setShowInputSearch] = useState(false);
  const [directionId, setDirectionId] = useState();
  const [loc, setLoc] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [onError, setOnError] = useState({ state: false, error: "" });

  const geocoder = new google.maps.Geocoder();

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClickMap = (coords: any) => setCoords(coords);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoords({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setInitialCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setLoc({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    geocoder
      .geocode({ location: loc ? loc : coords })
      .then((response) => {
        setOnError({ state: false, error: "" });
        if (response.results[0]) {
          setDirection(response.results[0].formatted_address);
        } else {
          console.log("No se encontraron resultados");
        }
      })
      .catch((e) => {
        setOnError({
          state: true,
          error: "Ha ocurrido un error, intentalo nuevamente más tarde.",
        });
        console.log("1 Geocoder failed due to: " + e);
      });
  }, [values.latitude, values.longitude, loc]);

  const locateCoords = (lat: any, lng: any) => {
    setIsLoading(true);
    geocoder
      .geocode({
        location: {
          lat: formatCoords(lat, "lat"),
          lng: formatCoords(lng, "lng"),
        },
      })
      .then((response) => {
        setIsLoading(false);
        setOnError({ state: false, error: "" });
        if (response.results[0]) {
          setFieldValue(names.direction, response.results[0].formatted_address);
        } else {
          console.log("No se encontraron resultados");
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setOnError({
          state: true,
          error: "Ha excedido el límite de peticiones, intentelo más tarde.",
        });
        console.log("2 Geocoder failed due to: " + e);
      });
  };

  useEffect(() => {
    directionId &&
      geocoder
        .geocode({ placeId: directionId })
        .then((response) => {
          setOnError({ state: false, error: "" });
          if (response.results[0]) {
            setLoc({
              lat: response.results[0].geometry.location.lat(),
              lng: response.results[0].geometry.location.lng(),
            });
          } else {
            console.log("No se encontraron resultados");
          }
        })
        .catch((e) => {
          setOnError({
            state: true,
            error: "Ha ocurrido un error, intentalo nuevamente más tarde.",
          });
          console.log("3 Geocoder failed due to: " + e);
        });
  }, [directionId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <TextField
          label="Latitud"
          name={names.latitude}
          value={String(values.latitude)}
          onChange={handleChange}
          error={!!(errors.latitude && touched.latitude)}
          helperText={errors.latitude && touched.latitude && errors.latitude}
          onBlur={handleBlur}
          variant="outlined"
          color="primary"
          size="small"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          label="Longitud"
          name={names.longitude}
          value={String(values.longitude)}
          onChange={handleChange}
          error={!!(errors.longitude && touched.longitude)}
          helperText={errors.longitude && touched.longitude && errors.longitude}
          onBlur={handleBlur}
          variant="outlined"
          color="primary"
          size="small"
          fullWidth
          required
        />
      </Grid>
      <Grid item container justifyContent="center" xs={2}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<i className="icon-sync" />}
          onClick={() => locateCoords(values.latitude, values.longitude)}
        >
          Dirección
        </Button>
      </Grid>
      <Grid item xs={10}>
        <TextField
          label="Dirección"
          name={names.direction}
          value={values.direction}
          error={!!(errors.direction && touched.direction)}
          helperText={errors.direction && touched.direction && errors.direction}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          color="primary"
          size="small"
          fullWidth
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {isLoading && <CircularProgress size={20} color="primary" />}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item container justifyContent="center" xs={2}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<i className="icon-map-marker" />}
          onClick={handleOpen}
        >
          Ver Mapa
        </Button>
      </Grid>
      {onError.state && (
        <Grid item container justifyContent="center">
          <Typography color="error" align="center">
            {onError.error}
          </Typography>
        </Grid>
      )}
      <Dialog
        open={open}
        onClose={handleClickClose}
        maxWidth="md"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogContent className={classes.mapDialog}>
          <MapInput
            onClick={handleClickMap}
            initialCenter={loc ?? initialCenter}
            coords={loc ?? coords}
          />
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="space-between"
            spacing={2}
            className={classes["map-grid"]}
          >
            <Grid container spacing={2} item xs={1}>
              <Grid item>
                <div className={classes["search-input"]}>
                  <Grid>
                    <Tooltip title="Buscar por dirección" placement="right">
                      <Fab
                        size="small"
                        onClick={() => setShowInputSearch(!showInputSearch)}
                        className={clsx(classes.button, classes.downButton)}
                      >
                        {!showInputSearch ? <SearchIcon /> : <CloseIcon />}
                      </Fab>
                    </Tooltip>
                  </Grid>
                  <Grow in={showInputSearch} timeout={1000} unmountOnExit>
                    <Grid>
                      <PlacesInput
                        handleChange={(e: any) => setDirectionId(e)}
                      />
                    </Grid>
                  </Grow>
                </div>
              </Grid>
              <Grid item>
                <Tooltip title="Marcar ubicación inicial" placement="right">
                  <Fab
                    className={classes.downButton}
                    size="small"
                    onClick={() => setCoords(initialCenter)}
                  >
                    <LocationSearchingIcon />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container item spacing={2} justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.downButton}
                  onClick={() => {
                    setOpen(false);
                    setShowInputSearch(false);
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpen(false);
                    setShowInputSearch(false);
                    setInitialCenter({ lat: coords.lat, lng: coords.lng });
                    setFieldValue(names.longitude, loc.lng.toString());
                    setFieldValue(names.latitude, loc.lat.toString());
                    setFieldValue(names.direction, direction);
                  }}
                >
                  Aceptar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default GoogleApiWrapper({
  apiKey: config.API_KEY,
})<CoordinatesMap>(CoordsForm);
