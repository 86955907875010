import { Dispatch, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_TOKEN } from "core/redux/types/userTypes"

export default (): Dispatch<string> => {
  const currentToken = useSelector((state: any) => state.user.token)
  const [token, setToken] = useState(currentToken)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: SET_TOKEN,
      payload: token
    })
  }, [token])

  return setToken
}