

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthMd": {
      borderRadius: '20px',
      padding: '15px'
    }
  },
  avatarTitle:{
    backgroundColor: '#6AC759'
  },
  divContainerTitle : {
    backgroundColor: '#F2F2F2',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '230px',
    justifyContent: 'center',
    height: '34px'
  },
  divContainerTitle2 : {
    backgroundColor: '#F2F2F2',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    justifyContent: 'center',
    height: '34px'
  },
  dataTitle:{
    marginBottom: 0,
    color: '#767676',
    fontWeight: 'bolder'
  },
  titleContainer: {
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
    marginBottom: '20px',
    color: '#121212',
  },
  fontTitleContainer: {
    fontSize:'17px'
  },
  infoOutlineContainer: {
    '& .MuiGrid-align-items-xs-center':{
      display: 'flex',
      justifyContent: 'center',
    }
  },
  gridContainer:{
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '120px',
    marginBottom: '20px',
    marginTop:'20px'
  },
  mb:{
    marginBottom: '10px'
  },
  resumeContainer:{
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    width: '50%'
  },
  fontTitleInfo:{
    color: '#AFAFAF', 
    fontWeight: 'bolder'
  },
  titleTotal:{
    color: '#AFAFAF',
    fontSize: '17px',
    fontWeight: 'bolder',
    maxWidth: '130px',
  },
  dataTotal:{
    color: '#767676',
    marginRight: '20px',
    fontWeight: 'bolder'
  },
  fontResponsable:{
    color: '#afafaf',
    fontWeight: 'bolder',
    fontSize: '15px',
  },
  dataResponsable:{
    color: '#767676', 
    fontSize: '15px'
  },
  avatarColor:{
    background:'#DD9835',
    marginRight:"25px"
  },
  itemInfoLabelInfo:{
    fontWeight: 'bold',
  },

}));

export { useStyles as default }