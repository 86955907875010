import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import ItemOutlinedInfo from '../../../../components/ItemOutlinedInfo/index';
import ItemTotalInfo from '../../../../components/ItemTotalInfo';
import ItemListInfo from '../../../../components/ItemListInfo/index';
import logo from 'static/img/icons/logoip.png';
import LicensePlate from 'components/LicensePlate';

type DialogDespatchProps = {
  open: boolean;
  handleClose: () => void;
};

const datos = [
  {
    title: 'Total Peso Canal Refrigerada (Kg)',
    data: '6.597,60',
  },
  {
    title: 'Promedio Peso Canal Refrigerada (Kg)',
    data: '117,81',
  },
];

const datos2 = [
  {
    title: 'Ingreso Camión Planta',
    data: 'N/D',
  },
  {
    title: 'Inicio Cargue Canales',
    data: '22/01/2019 09:30:00',
  },
  {
    title: 'Salida Camión Planta',
    data: 'N/D',
  },
  {
    title: 'Final Cargue Canales',
    data: '22/01/2019 09:47:00',
  },
  {
    title: 'Tiempo Permanencia en Planta',
    data: '00:00:00',
  },
  {
    title: 'Duración Cargue Camión',
    data: '00:17:00',
  },
];

const datos3 = [
  {
    title: 'Nombre Conductor',
    data: 'Jaime Lemus Lopez',
  },
  {
    title: 'Nro. Documento',
    data: '12321323',
  },
];

const datos4 = [
  {
    title: 'Nro. Placa',
    data: 'ERW 654',
  },
  {
    title: 'Ciudad Placa',
    data: 'Bogotá',
  },
];

const DialogDespatch = ({ open, handleClose }: DialogDespatchProps) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="body"
        className={classes.borderRadiudDialog}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Grid container>
            <Grid item container xs={5} alignItems="center">
              <Avatar className={classes.avatarTitle}>
                <i className="icon-truck-cold" style={{ fontSize: '30px' }}></i>
              </Avatar>
              <Box ml={3}>
                <Typography variant="h6">Despacho Canales</Typography>
              </Box>
            </Grid>

            <Grid item xs={7} container justify="flex-end">
              <Grid item container className={classes.divContainerTitle}>
                <Box mr={2}>
                  <Typography variant="h5" className={classes.dataTitle}>
                    02:31:40
                  </Typography>
                </Box>
                <Typography className={classes.fontTitleInfo}>
                  Duración Proceso
                </Typography>
              </Grid>

              <Box ml={3}>
                <Grid item container className={classes.divContainerTitle2}>
                  <Box mr={2}>
                    <Typography variant="h5" className={classes.dataTitle}>
                      0123
                    </Typography>
                  </Box>
                  <Typography className={classes.fontTitleInfo}>
                    Lote InfoPorcinos
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid container className={classes.titleContainer}>
              <Typography className={classes.fontTitleContainer}>
                Resumen del Proceso
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-comercializador"
                title="Comercializador"
                info="Nombre Comercializador"
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-map-marker"
                title="Destino"
                info="Nombre del Destino"
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-calendar"
                title="Fecha Despacho Canales"
                info="22/01/2019 09:00:00"
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item container xs={8}>
              <div className={classes.gridContainer} style={{ width: '95%' }}>
                <Grid item container xs={5} justify="center">
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={54}
                    titleInfo="Total Canales Despachadas"
                    avatarTotalColor="#DD9835"
                    classNameLabel={classes.itemInfoLabel}
                  />
                </Grid>
                <Grid item container xs={7}>
                  <Grid item container>
                    <div className={classes.itemList}>
                      <ItemListInfo
                        titleInfo="Nro. Lista Distribución"
                        numberInfo="4531"
                        classNameItem={classes.itemList}
                      />
                      <ItemListInfo
                        titleInfo="Nro. Certificado"
                        numberInfo="130603"
                        classNameItem={classes.itemList}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item container xs={4} className={classes.gridContainer}>
              {datos.map((index) => (
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="center"
                  style={{ marginBottom: '10px' }}
                >
                  <Grid item xs={8}>
                    <Typography className={classes.titleInfoWeight}>
                      {index.title}
                    </Typography>
                  </Grid>
                  <Grid item container xs={4} justify="flex-end">
                    <Typography className={classes.dataInfoWeight}>
                      {index.data}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item container xs={5} alignItems="center">
              <Grid item container xs={7} justify="center">
                <Typography className={classes.fontTitle}>
                  Total Vísceras Despachadas
                </Typography>
              </Grid>
              <Grid item container xs={5} justify="center">
                <Avatar variant="square" className={classes.avatarInfoBezels}>
                  0
                </Avatar>
              </Grid>
            </Grid>
            <Grid item container xs={7} className={classes.titleContainerSello}>
              <Grid item container xs={6} justify="center">
                <Typography className={classes.fontTitle}>
                  Responsable Despachos
                </Typography>
              </Grid>
              <Grid item container xs={6} justify="center">
                <Typography className={classes.fontSubtitle}>
                  Jorge Jimenez Cadavid
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.titleContainer}>
            <Typography className={classes.fontTitleContainer}>
              Datos Transporte Canales
            </Typography>
          </Grid>

          <Grid container>
            {datos2.map((item, index) => (
              <Grid item container xs={6}>
                <Grid
                  item
                  container
                  xs={7}
                  className={classes.gridTitleTransport}
                >
                  <Typography className={classes.fontTitle}>
                    {item.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={5}
                  justify="flex-end"
                  className={classes.gridSubtitleTransport}
                >
                  <Typography className={classes.fontSubtitle}>
                    {item.data}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid container className={classes.gridContainer}>
            <Grid item container xs={6} alignItems="center">
              <Grid item container xs={5} justify="center">
                <LicensePlate placa="ERM654" ciudad="Bogota" width={150} />
              </Grid>
              <Grid item container xs={7}>
                {datos4.map((item, index) => (
                  <Grid item container key={index}>
                    <Grid
                      item
                      container
                      xs={7}
                      className={classes.gridTitleTransport}
                    >
                      <Typography className={classes.fontTitle}>
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={5}
                      justify="flex-end"
                      className={classes.gridSubtitleTransport}
                    >
                      <Typography className={classes.fontSubtitle}>
                        {item.data}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              {datos3.map((item, index) => (
                <Grid item container key={index}>
                  <Grid
                    item
                    container
                    xs={7}
                    className={classes.gridTitleTransport}
                  >
                    <Typography className={classes.fontTitle}>
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    justify="flex-end"
                    className={classes.gridSubtitleTransport}
                  >
                    <Typography className={classes.fontSubtitle}>
                      {item.data}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid
              item
              container
              className={classes.titleContainerSello}
              style={{ width: '60%' }}
            >
              <Grid item container xs={6} justify="center">
                <Typography className={classes.fontTitle}>
                  Sello Seguridad Camión
                </Typography>
              </Grid>
              <Grid item container xs={6} justify="center">
                <Typography className={classes.fontSubtitle}>123154</Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              className="raleway"
              onClick={handleClose}
              color="primary"
            >
              Aceptar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogDespatch;
