import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Collapse,
  IconButton,
  Avatar,
  Box,
  Fab,
  Typography,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

import avatars from "lib/helpers/dynamic-images/avatars";
import documentTypeById from "lib/helpers/documentTypeById";

import { Link } from "react-router-dom";
import clsx from "clsx";
import dayjs from "dayjs";

import useStyles from "./styles";

interface RowProps {
  row: any;
  filter: any;
}

const Row = ({ row, filter }: RowProps) => {
  const classes = useStyles();
  const dataTable = row;
  const [open, setOpen] = useState(false);

  const usersFiltered = dataTable?.usuarios?.filter(
    (users: any) => `${users.estado}` === filter
  );

  return (
    <>
      {usersFiltered?.length === 0 ? null : (
        <>
          <TableRow className={clsx({ [classes.isOpen]: open })}>
            <TableCell>
              <Box display="flex" alignItems="center">
                <Avatar
                  src={avatars(dataTable?.descripcion)}
                  className={classes.avatar}
                />
                <Typography variant="h6" color="secondary">
                  {dataTable?.descripcion}{" "}
                  {/* <Typography variant="caption">
                ({dataTable.origin === 1 ? "Rol del sistema" : "Mis Roles"})
              </Typography> */}
                </Typography>
              </Box>
            </TableCell>

            <TableCell>{usersFiltered?.length} Usuarios con este Rol</TableCell>

            <TableCell align="center">
              <IconButton onClick={() => setOpen(!open)}>
                {open ? (
                  <i className="icon-circle-arrow-top" />
                ) : (
                  <i className="icon-circle-arrow-bottom" />
                )}
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className={clsx({
                [classes.isOpen]: open,
                [classes.containerCollapse]: true,
              })}
              colSpan={5}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Usuario</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Documento</TableCell>
                      <TableCell>Fecha de Creación</TableCell>
                      <TableCell>Fecha Última Conexión</TableCell>
                      <TableCell align="center">Estado</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersFiltered?.map((item: any) => {
                      return (
                        <TableRow key={item?.idUsuario}>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Avatar
                                className={classes.avatar}
                                src={avatars(item?.username)}
                              />
                              @{item?.username}
                            </Box>
                          </TableCell>
                          <TableCell>{`${item?.primerNombre} ${item?.segundoNombre} ${item?.primerApellido} ${item?.segundoApellido}`}</TableCell>
                          <TableCell>
                            {documentTypeById(Number(item?.idTipoDocumento), 1)}
                            -{item?.numeroDocumento}
                          </TableCell>
                          <TableCell>
                            {dayjs(item?.fechaRegistro).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </TableCell>
                          <TableCell>
                            {item?.fechaUltimaConexion
                              ? dayjs(item?.fechaUltimaConexion).format(
                                  "DD/MM/YYYY HH:mm:ss"
                                )
                              : "N.D"}
                          </TableCell>
                          <TableCell align="center">
                            <FiberManualRecord
                              style={{
                                color: item?.estado ? "#68CE70" : "#E2575C",
                                fontSize: 16,
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Box display="flex" justifyContent="center">
                              <Fab
                                color="primary"
                                className={classes.btnDetails}
                                component={Link}
                                to={`/app/users/details/${item?.idUsuario}`}
                              >
                                <Box
                                  component="i"
                                  fontSize="20px"
                                  className="icon-eye"
                                />
                              </Fab>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

export default Row;
