import { makeStyles } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    borderRadius: "20px",
    "& .MuiCardHeader-root": {
      padding: "15px 15px 0px 0px",
    },
  },
  cardContent: {
    minWidth: 250,
    maxWidth: 250,
    minHeight: 265,
    maxHeight: 265,
  },
  nameInfo: {
    fontWeight: "bolder",
    textAlign: "center",
  },
  ubicationInfo: {
    textAlign: "center",
    color: "#AFAFAF",
    fontSize: "12px",
  },
  dataTitle: {
    color: "#AFAFAF",
    fontSize: "12px",
  },
  dataInfo: {
    fontWeight: "bolder",
  },
  buttonColorGreen: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[800],
    },
  },
  buttonColorRed: {
    backgroundColor: red[700],
    "&:hover": {
      backgroundColor: red[900],
    },
  },
  dataContainer: {
    height: "50px",
  },
  avatarContainer: {
    width: 70,
    height: 70,
  },
  fontDirection: {
    display: "-webkit-box",
    maxWidth: "218px",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    fontWeight: "bolder",
  },
}));

export { useStyles as default };
