import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Collapse,
  IconButton,
  Avatar,
  Box,
  Fab,
} from '@material-ui/core';

import reportsIcons from 'lib/helpers/reportsIcons';
import avatars from 'lib/helpers/dynamic-images/avatars';
import SharedReportsLayout from 'layouts/SharedReportsLayout';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import useStyles from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const Main = () => {
  const classes = useStyles();
  const valueTab = useSelector(
    (state: any) => state.ui.sharedReports.valueRouteTab
  );

  const Data = [
    {
      id: 1,
      user: 'Leidy',
      name: 'Leidy Marcela Álvarez Mejía',
      documenType: 'CC',
      documentNumber: 1000295214,
      userType: 'Comercializador',
      reports: [
        {
          reportType: 'Todos',
          shared: 2,
          sharedWithMe: 10,
          requests: 20,
          myRequests: 2,
        },
        {
          reportType: 'Portería',
          shared: 2,
          sharedWithMe: 4,
          requests: 12,
          myRequests: 0,
        },
        {
          reportType: 'Guias de Movilización',
          shared: 2,
          sharedWithMe: 4,
          requests: 12,
          myRequests: 0,
        },
        {
          reportType: 'Tiquete de Báscula',
          shared: 2,
          sharedWithMe: 4,
          requests: 12,
          myRequests: 0,
        },
        {
          reportType: 'Inmunocastración',
          shared: 2,
          sharedWithMe: 4,
          requests: 12,
          myRequests: 0,
        },
        {
          reportType: 'Antemortem',
          shared: 0,
          sharedWithMe: 6,
          requests: 8,
          myRequests: 2,
        },
        {
          reportType: 'Visceras Blancas',
          shared: 0,
          sharedWithMe: 6,
          requests: 8,
          myRequests: 2,
        },
        {
          reportType: 'Postmortem',
          shared: 0,
          sharedWithMe: 6,
          requests: 8,
          myRequests: 2,
        },
        {
          reportType: 'Evaluación de Magro',
          shared: 0,
          sharedWithMe: 6,
          requests: 8,
          myRequests: 2,
        },
        {
          reportType: 'Distribución y Despachos',
          shared: 0,
          sharedWithMe: 6,
          requests: 8,
          myRequests: 2,
        },
      ],
    },
    {
      id: 2,
      user: 'Pabolivar',
      name: 'Paula Andrea Bolivar',
      documenType: 'CC',
      documentNumber: 1248005840,
      userType: 'Productor',
      reports: [
        {
          reportType: 'Todos',
          shared: 20,
          sharedWithMe: 10,
          requests: 18,
          myRequests: 5,
        },
        {
          reportType: 'Antemortem',
          shared: 0,
          sharedWithMe: 6,
          requests: 8,
          myRequests: 2,
        },
        {
          reportType: 'Visceras Blancas',
          shared: 20,
          sharedWithMe: 4,
          requests: 10,
          myRequests: 3,
        },
      ],
    },
    {
      id: 3,
      user: 'Sebitas',
      name: 'Sebastian Mosquera',
      documenType: 'CC',
      documentNumber: 1000247833,
      userType: 'Comercializador',
      reports: [
        {
          reportType: 'Todos',
          shared: 14,
          sharedWithMe: 8,
          requests: 20,
          myRequests: 14,
        },
        {
          reportType: 'Portería',
          shared: 12,
          sharedWithMe: 4,
          requests: 8,
          myRequests: 14,
        },
        {
          reportType: 'Tiquete de Báscula',
          shared: 2,
          sharedWithMe: 4,
          requests: 12,
          myRequests: 0,
        },
      ],
    },
  ];

  const Row = (row: any) => {
    const dataTable = row.row;
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow className={clsx({ [classes.isOpen]: open })}>
          <TableCell>
            <Box display="flex" alignItems="center">
              <Avatar
                src={avatars(dataTable.user)}
                className={classes.avatar}
              />
              {dataTable.user}
            </Box>
          </TableCell>
          <TableCell>{dataTable.name}</TableCell>
          <TableCell>{`${dataTable.documenType} ${dataTable.documentNumber}`}</TableCell>
          <TableCell>{dataTable.userType}</TableCell>
          <TableCell align="center">
            <IconButton onClick={() => setOpen(!open)}>
              {open ? (
                <i className="icon-circle-arrow-top" />
              ) : (
                <i className="icon-circle-arrow-bottom" />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={clsx({
              [classes.isOpen]: open,
              [classes.containerCollapse]: true,
            })}
            colSpan={5}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo de Reporte</TableCell>
                    <TableCell align="center">Compartidos</TableCell>
                    <TableCell align="center">Compartidos Conmigo</TableCell>
                    {/* <TableCell align="center">Solicitudes Enviadas</TableCell>
                    <TableCell align="center">Solicitudes Recibidas</TableCell> */}
                    <TableCell align="center">Detalle</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.reports.map((item: any) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Avatar className={classes.avatar}>
                              <i className={reportsIcons(item.reportType)} />
                            </Avatar>
                            {item.reportType}
                          </Box>
                        </TableCell>
                        <TableCell align="center">{item.shared}</TableCell>
                        <TableCell align="center">
                          {item.sharedWithMe}
                        </TableCell>
                        {/* <TableCell align="center">{item.requests}</TableCell>
                        <TableCell align="center">{item.myRequests}</TableCell> */}
                        <TableCell align="center">
                          <Box display="flex" justifyContent="center">
                            <Fab
                              color="primary"
                              className={classes.btnDetails}
                              component={Link}
                              to={`/app/network/shared-reports/details-client/${dataTable.id}/${item.reportType}`}
                            >
                              <Box
                                component="i"
                                fontSize="20px"
                                className="icon-eye"
                              />
                            </Fab>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <SharedReportsLayout>
      <TabPanel index="clients" value={valueTab}>
        <div className={classes.containerTab}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Usuario</TableCell>
                <TableCell>Nombre / Razón social</TableCell>
                <TableCell>Documento</TableCell>
                <TableCell>Tipo de Usuario</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Data.map((row) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </div>
      </TabPanel>
    </SharedReportsLayout>
  );
};

export default Main;
