import {makeStyles} from '@material-ui/core'
import styled from 'styled-components'

export const NumberCircle = styled.div`
  background-color: #ffc676;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 0 auto;
  margin-bottom: 12px;
  margin-top: 15px;
  & > h1 {
    font-size: ${({value}: { value: any }) => (value?.toString()?.length > 3 ? 1.5 : 2)}em;
  }
`
export const EntranceReportContent = styled.div`
  font-family: Roboto, sans-serif;
  color: #afafaf;
  font-size: 16px;
  & .flexdate {
    display: flex;
    margin-bottom: -37px;
    & .date {
      margin-right: 10px;
    }
  }
  & .margintopcolumns1 {
    margin-top: 30px;
  }

  & .timeflex {
    display: flex;
    justify-content: center;
    align-items: center;

    & .time {
      background-color: #ffc676;
      color: white;
      width: 115px;
      font-weight: bold;
      height: 45px;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-right: 24px;
      font-size: 19px;
      padding: 29px;

      & .textalign {
        text-align: center;
      }
    }
  }
  & .margintopcolumns {
    margin-top: 15px;

    & .flexcolumns {
      display: flex;
      align-items: center;
      height: 84px;
      & .contentcolumns {
        width: 100%;
      }
    }
    & .numbercars {
      display: flex;
      align-items: center;
      margin-left: 7px;
      & p {
        margin-bottom: 0;
        margin-left: 10px;
      }
      & .p {
        margin-left: auto;
        font-size: 19px;

        &.productor {
          margin-left: 3rem;
        }
      }
    }
  }
  & .header {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-family: Roboto, sans-serif;
    & .button {
      background-color: #dd9835;
      color: white;
    }
    & p {
      font-weight: normal;
      font-size: 15px;
    }
    & .text {
      width: 326px;
      margin-top: 9px;
    }
  }
  .productors {
    position: relative;
    top: -77px;
    left: 67%;
    color: #767676;
    font-weight: bold;
  }

  & h4 {
    color: #767676;
  }
  & td {
    color: #767676;
  }
  & .icons {
    color: #f1ae6c;
  }

  & .contentbackground {
    background-color: white;
    border-radius: 50px;
    margin-top: 20px;
  }

  & .numbercirclecontainer {
    width: 135px;
    margin: 0 auto;

    & p {
      width: 93px;
      text-align: center;
      margin: 0 auto;
    }
  }

  & .buttoncontainer {
    margin-left: auto;
  }
  & table {
    width: 100%;
    text-align: center;
  }
  & .table1 {
    text-align: left;
    & .textcenter {
      text-align: center;
      color: #707070;
    }
    & .textright {
      text-align: right;
      font-size: 19px;
    }
    & td {
      color: #afafaf;
      height: 37px;
    }
  }

  & .documents {
    position: relative;
    left: 522px;
  }
  & .tabledocuments {
    width: 100%;
    border: 0px;
    text-align: center;
    margin-top: 40px;
    & th {
      color: #afafaf !important;
    }
    & tr td {
      border-bottom: 1px solid #dfdada;
      height: 62px;
    }
  }
  & .datecontainer {
    margin-left: auto;
    border: solid 2px #dd9835;
    border-radius: 22px;
    color: #dd9835;
    padding: 3px 19px;
    width: 14%;
    font-weight: 500;
  }
  & .marginresults {
    margin-right: 50px;
  }

  @media screen and (max-width: 900px) {
    & .table1 {
      font-size: 12px;
      & .textright {
        font-size: 12px;
      }
    }
    & .header {
      & .button {
        display: block;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: -16px;
      }
      & p {
        font-weight: normal;
        font-size: 15px;
        margin-top: 33px;
        margin-bottom: -21px;
      }
    }
    & .tabledocuments {
      font-size: 10px;
      & p {
        width: 72px;
      }
    }
  }
`

const useStyles = makeStyles((theme): any => ({
  table: {
    '& .left': {
      textAlign: 'left'
    },
    '& .right': {
      textAlign: 'right'
    }
  },
  percentCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .badge': {
      height: 5,
      width: 5,
      background: theme.palette.primary.main,
      borderRadius: 5
    }
  },
  quantityCell: {
    padding: theme.spacing(0, 2),
    textAlign: 'right'
  },
  donutChartContainer: {
    position: 'relative'
  },
  chartLabel: {
    position: 'absolute',
    top: 'calc(50% - (19.2px / 2))',
    left: 'calc(50% - (47.5px / 2))'
  },
  chart: {
    height: 200,
    marginBottom: '-33px'
  },
  numberStadistic: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    marginLeft: 7,
    cursor: 'pointer',
    '& p': {
      marginBottom: 0,
      marginLeft: 10
    },
    '& .p': {
      marginLeft: 'auto',
      fontSize: 19
    }
  },
  dialog: {
    width: '100%',
    maxHeight: 500
  },
  iconAvatar: {
    fontSize: 28
  },
  accordionSummary: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  accordionSummaryAvatar: {
    background: '#FFC676',
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1.5)
  },
  accordionDetails: {
    flexGrow: 1
  },
  detailsFarmer: {
    paddingTop: 0
  },
  accordionDetailsAvatar: {
    background: '#D47D57'
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15)
  },
  noWrapText: {
    whiteSpace: 'nowrap'
  },
  paperDialog: {
    overflow: 'hidden'
  },
  paperInput: {
    width: '100%'
  },
  dialogChartRoot: {
    flexGrow: 1
  },
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  dialogChart: {
    height: '80vh'
  },
  animalsOriginScrollSection: {
    height: '87vh',
    maxHeight: '87vh',
    overflowY: 'scroll'
  },
  totalAnimals: {
    display: 'flex',
    alignItems: 'center'
  },
  iconOrigin: {
    color: theme.palette.primary.main,
    fontSize: 20,
    marginRight: theme.spacing(1)
  },
  cardReport: {
    padding: '3% 5%',
    background: theme.palette.common.white,
    borderRadius: 20,
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cardTitle: {
    fontSize: '18px !important',
    color: '#767676',
    fontWeight: 'bold !important'
  },
  chipLabel: {
    fontSize: 13
  }
}))

export const useRowStyles = makeStyles(theme => ({
  viewBtn: {
    color: theme.palette.common.white,
    background: theme.palette.grey[600],
    borderRadius: 50,
    padding: theme.spacing(0.2, 0),
    '&:hover': {
      background: theme.palette.grey[700],
      color: '#FFF'
    }
  },
  tableCellNestedTable: {
    padding: theme.spacing(2, 0)
  },
  nestedTable: {
    '&:nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  nestedTableHead: {
    '& th': {
      fontSize: 12
    }
  },
  whenClose: {
    padding: 0
  },
  cell: {
    paddingRight: 0,
    paddingLeft: 0
  }
}))

export { useStyles as default }