import { Icon, ListItem, ListItemText, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => {
  const transition = theme.transitions.create('all', {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  });

  return {
    item: {
      height: 40,
      width: 'calc(100% - 26px)',
      borderRadius: 5,
      paddingLeft: 10,
      marginLeft: 10,
      marginRight: 16,
      transition,
      color: theme.palette.text.primary,
      '&$active': {
        color: theme.palette.primary.main + '!important',
        marginRight: 0,
        width: 'calc(100% - 20px)',
        transition,
        background: 'rgba(221, 152, 53, 0.12)',
      },
      '& .list-item-icon': {},
      '& .list-item-text': {},
      '&.nested': {
        width: '100%',
        marginLeft: 0,
        paddingLeft: 20,
      },
      cursor: 'pointer',
      textDecoration: 'none!important',
    },
    icon: {
      fontSize: '1.6rem',
      marginRight: '1.6rem',
      flexShrink: 0,
      color: theme.palette.common.white,
    },
    title: {
      color: '#FFF',
      fontFamily: 'Raleway',
    },
    active: {
      '&.nested': {
        width: '100%',
        marginLeft: 0,
        paddingLeft: 20,
      },
    },
  };
});

interface NavigationItemProps {
  item: any;
  nested?: boolean;
}

const ItemIcon = ({ icon = '', ...props }: any) => {
  const arrIcon = icon.split('__');
  const isMV = arrIcon[0] === 'mv';

  if (!icon) return null;
  return (
    <Icon
      className={clsx(
        props.className,
        isMV && `icon-${arrIcon[1]}`,
        'material-icons-outlined'
      )}
    >
      {!isMV && arrIcon[1]}
    </Icon>
  );
};

const NavigationItem = ({ item, nested }: NavigationItemProps) => {
  const { location } = useHistory();
  const classes = useStyles();
  const active = !!location.pathname.includes(item.url);

  return (
    <ListItem
      component={Link}
      to={item.url ?? ''}
      button
      className={clsx(
        classes.item,
        'list-item',
        active && classes.active,
        nested && 'nested'
      )}
    >
      <ItemIcon
        className={clsx(classes.icon, 'list-item-icon')}
        icon={item.icon}
      />
      <ListItemText
        primary={item.name}
        className="list-item-text"
        classes={{ primary: classes.title }}
      />
    </ListItem>
  );
};

export default NavigationItem;
