import { Document, Page, Text, Image, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import moment from 'moment';
import React from 'react';
import _ from 'lodash';

import isNanReturn from 'lib/helpers/isNanReturn';

import entranceLogo from 'static/img/reportsIcons/entranceLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import productorIcon from 'static/img/reportsIcons/productorIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';
import farmIcon from 'static/img/reportsIcons/farm';
import transportIcon from 'static/img/reportsIcons/transport';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';

import styles from './styles/lote';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

interface MyDocProps {
  qr: string;
  dataGeneral: any;
  dataEntrance: any;
  dataCorrals: any;
  uid: null | number | string;
}

export const MyDoc = ({
  qr,
  dataGeneral,
  dataEntrance,
  dataCorrals,
  uid = null,
}: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={dataGeneral.CodigoLote}
        reportImage={entranceLogo}
        number="023456789"
        title="Reporte Técnico de Portería"
        code="R P 001"
      />
      <View style={styles.detailsplant}>
        <View style={styles.backgroundresumetitle}>
          <Text>Agentes Relacionados con el Negocio</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.tablecontainer}>
            <View style={styles.backgroundresumetitle}>
              <View style={styles.imageplant}>
                <Image src={plantIcon} />
              </View>
              <Text>Planta de beneficio</Text>
            </View>

            <View style={styles.tableplant}>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Nombre
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {_.startCase(dataEntrance.planta.razonSocial.toLowerCase())}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      {!dataEntrance.planta.numeroDocumento
                        ? 'Nit'
                        : 'Documento'}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {!dataEntrance.planta.nit
                      ? dataEntrance.planta.numeroDocumento
                      : dataEntrance.planta.nit}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Teléfono
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {dataEntrance.planta.telefono1 === '0' ||
                    dataEntrance.planta.telefono1 === 'NINGUNO'
                      ? 'N.D.'
                      : dataEntrance.planta.telefono1}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Celular
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {dataEntrance.planta.celular1 === '0' ||
                    dataEntrance.planta.celular1 === 'NINGUNO'
                      ? 'N.D.'
                      : dataEntrance.planta.celular1}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '20%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Email
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '80%' }]}>
                  <Text
                    style={[
                      styles.tableCellplant,
                      { textTransform: 'lowercase' },
                    ]}
                  >
                    {dataEntrance.planta.email === 'Null'
                      ? 'N.D.'
                      : dataEntrance.planta.email}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Dirección
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {_.capitalize(dataEntrance.planta.tipoVia)}{' '}
                    {_.capitalize(dataEntrance.planta.numeroVia)}{' '}
                    {dataEntrance.planta.apendice} #{' '}
                    {_.capitalize(dataEntrance.planta.numeroCruce)}{' '}
                    {dataEntrance.planta.apendiceCruce} -{' '}
                    {_.capitalize(dataEntrance.planta.metrosEsquina)}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Ciudad
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {_.capitalize(dataEntrance.planta.ciudad)},{' '}
                    {_.capitalize(dataEntrance.planta.pais)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.tablecontainer2}>
            <View style={styles.backgroundresumetitle}>
              <View style={styles.imageplant}>
                <Image src={productorIcon} />
              </View>
              <Text>Productor</Text>
            </View>
            <View style={styles.tableplant}>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Nombre
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {_.startCase(
                      dataEntrance.productor.nombreCompleto.toLowerCase()
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      {!dataEntrance.productor.numeroDocument
                        ? 'Nit'
                        : 'Documento'}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {!dataEntrance.productor.nit
                      ? dataEntrance.productor.numeroDocumento
                      : dataEntrance.productor.nit}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Teléfono
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {dataEntrance.productor.telefono1 === '0' ||
                    dataEntrance.productor.telefono1 === 'NINGUNO'
                      ? 'N.D.'
                      : dataEntrance.productor.telefono1}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Celular
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {dataEntrance.productor.celular1 === '0' ||
                    dataEntrance.productor.celular1 === 'NINGUNO' ||
                    dataEntrance.productor.celular1.length > 10 ||
                    dataEntrance.productor.celular1.length > 10 ||
                    dataEntrance.productor.celular1 === 'Null'
                      ? 'N.D.'
                      : dataEntrance.productor.celular1}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '20%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Email
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '80%' }]}>
                  <Text
                    style={[
                      styles.tableCellplant,
                      { textTransform: 'lowercase' },
                    ]}
                  >
                    {dataEntrance.productor.email === 'Null'
                      ? 'N.D.'
                      : dataEntrance.productor.email}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Dirección
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {dataEntrance.productor.direccion === 'Null' ||
                    dataEntrance.productor.direccion === 'NO' ||
                    dataEntrance.productor.direccion === 'NINGUNO 0 0 0' ||
                    dataEntrance.productor.direccion === '0'
                      ? 'N.D.'
                      : _.startCase(
                          dataEntrance.productor.direccion.toLowerCase()
                        )}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Ciudad
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {_.capitalize(dataEntrance.productor.ciudad)},{' '}
                    {_.capitalize(dataEntrance.productor.pais)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.tablecontainer3}>
            <View style={styles.backgroundresumetitle}>
              <View style={styles.imageplant}>
                <Image src={marketerIcon} />
              </View>
              <Text>Comercializador</Text>
            </View>
            <View style={styles.tableplant}>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Nombre
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {_.startCase(
                      dataEntrance.comercializador.nombreCompleto.toLowerCase()
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      {!dataEntrance.comercializador.numeroDocumento
                        ? 'Nit'
                        : 'Documento'}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {!dataEntrance.comercializador.nit
                      ? dataEntrance.comercializador.numeroDocumento
                      : dataEntrance.comercializador.nit}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Teléfono
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {dataEntrance.comercializador.telefono1 === '0' ||
                    dataEntrance.comercializador.telefono1 === 'NINGUNO'
                      ? 'N.D.'
                      : dataEntrance.comercializador.telefono1}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Celular
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {dataEntrance.comercializador.celular1 === '0' ||
                    dataEntrance.comercializador.celular1 === 'NINGUNO' ||
                    dataEntrance.comercializador.celular1.length > 10 ||
                    dataEntrance.comercializador.celular1.length > 10
                      ? 'N.D.'
                      : dataEntrance.comercializador.celular1}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '20%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Email
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '80%' }]}>
                  <Text
                    style={[
                      styles.tableCellplant,
                      { textTransform: 'lowercase' },
                    ]}
                  >
                    {dataEntrance.comercializador.email === 'Null'
                      ? 'N.D.'
                      : dataEntrance.comercializador.email}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Dirección
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {dataEntrance.comercializador.direccion.includes(
                      'NINGUNO'
                    ) || dataEntrance.comercializador.direccion === 'Null'
                      ? 'N.D.'
                      : dataEntrance.comercializador.tipoVia === 'Null'
                      ? _.startCase(
                          dataEntrance.comercializador.direccion.toLowerCase()
                        )
                      : `${_.capitalize(
                          dataEntrance.comercializador.tipoVia
                        )} ${_.capitalize(
                          dataEntrance.comercializador.numeroVia
                        )} ${
                          dataEntrance.comercializador.apendice
                        } #${_.capitalize(
                          dataEntrance.comercializador.numeroCruce
                        )} ${
                          dataEntrance.comercializador.apendiceCruce ===
                            'Null' && dataEntrance.comercializador.apendiceCruce
                        } - ${_.capitalize(
                          dataEntrance.comercializador.metrosEsquina
                        )}`}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '30%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Ciudad
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                  <Text style={styles.tableCellplant}>
                    {_.capitalize(dataEntrance.comercializador.ciudad)},{' '}
                    {_.capitalize(dataEntrance.comercializador.pais)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.sectionorigin}>
        <View style={styles.backgroundorigin}>
          <View style={styles.backgroundresumetitle}>
            <View style={styles.imageplant}>
              <Image src={farmIcon} />
            </View>
            <Text>Granja Origen</Text>
          </View>
          <View style={styles.tableplant}>
            <View style={styles.tableRowplant}>
              <View style={[styles.tableColplant, { width: '30%' }]}>
                <View style={styles.tableCellplant}>
                  <Text
                    style={{
                      fontFamily: 'Nunito',
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    Nombre
                  </Text>
                </View>
              </View>
              <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                <Text style={styles.tableCellplant}>
                  {_.startCase(dataEntrance.granja.nombre.toLowerCase())}
                </Text>
              </View>
            </View>
            <View style={styles.tableRowplant}>
              <View style={[styles.tableColplant, { width: '30%' }]}>
                <View style={styles.tableCellplant}>
                  <Text
                    style={{
                      fontFamily: 'Nunito',
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    Responsable
                  </Text>
                </View>
              </View>
              <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                <Text style={styles.tableCellplant}>
                  {dataEntrance.granja.responsableGranja === 'Null'
                    ? 'N.D.'
                    : dataEntrance.granja.responsableGranja}
                </Text>
              </View>
            </View>
            <View style={styles.tableRowplant}>
              <View style={[styles.tableColplant, { width: '30%' }]}>
                <View style={styles.tableCellplant}>
                  <Text
                    style={{
                      fontFamily: 'Nunito',
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    Teléfono
                  </Text>
                </View>
              </View>
              <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                <Text style={styles.tableCellplant}>
                  {dataEntrance.granja.telefono === 'Null'
                    ? 'N.D.'
                    : dataEntrance.granja.telefono}
                </Text>
              </View>
            </View>
            <View style={styles.tableRowplant}>
              <View style={[styles.tableColplant, { width: '30%' }]}>
                <View style={styles.tableCellplant}>
                  <Text
                    style={{
                      fontFamily: 'Nunito',
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    Dirección
                  </Text>
                </View>
              </View>
              <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                <Text style={styles.tableCellplant}>
                  {_.startCase(dataEntrance.granja.direccion.toLowerCase())}
                </Text>
              </View>
            </View>
            <View style={styles.tableRowplant}>
              <View style={[styles.tableColplant, { width: '30%' }]}>
                <View style={styles.tableCellplant}>
                  <Text
                    style={{
                      fontFamily: 'Nunito',
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                  >
                    Ciudad
                  </Text>
                </View>
              </View>
              <View style={[styles.tableColplantRigth, { width: '70%' }]}>
                <Text style={styles.tableCellplant}>
                  {_.capitalize(dataEntrance.granja.ciudad)},{' '}
                  {_.capitalize(dataEntrance.granja.pais)}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.sectionanimals}>
          <View style={styles.backgroundresumetitle}>
            <View style={styles.imageplant}>
              <Image src={transportIcon} />
            </View>
            <Text>Detalles del Transporte Animales en Pie</Text>
          </View>

          <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={[styles.tableplant, { width: '60%' }]}>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '70%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Conductor
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '30%' }]}>
                  <Text style={styles.tableCellplant}>
                    {_.startCase(
                      dataEntrance.datosGeneralesPorteria.nombreConductor.toLowerCase()
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '70%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Nro. Documento
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '30%' }]}>
                  <Text style={styles.tableCellplant}>N.D</Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '70%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Licencia de Conducir
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '30%' }]}>
                  <Text style={styles.tableCellplant}>N.D</Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '70%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Marca Vehículo
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '30%' }]}>
                  <Text style={styles.tableCellplant}>N.D</Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '70%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Modelo
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '30%' }]}>
                  <Text style={styles.tableCellplant}>N.D</Text>
                </View>
              </View>
              <View style={styles.tableRowplant}>
                <View style={[styles.tableColplant, { width: '70%' }]}>
                  <View style={styles.tableCellplant}>
                    <Text
                      style={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Color
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableColplantRigth, { width: '30%' }]}>
                  <Text style={styles.tableCellplant}>N.D</Text>
                </View>
              </View>
            </View>

            <View style={styles.licenseContainer}>
              <View
                style={{
                  border: 2,
                  borderColor: '#E2E2E1',
                  borderRadius: 7,
                  width: '80%',
                }}
              >
                <View style={styles.plate}>
                  <Text style={styles.plateText}>
                    {dataEntrance.datosGeneralesPorteria.placaVehiculo}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.detailsreport}>
        <View style={styles.backgroundresumetitle}>
          <Text>Resumen Transporte Animales en Pie</Text>
        </View>
        <View style={[styles.table, { padding: 5 }]}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  Nro. GSMI ICA
                </Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text style={{ textAlign: 'right', marginRight: 10 }}>
                  {dataEntrance.GSMI.numeroGSMI}
                </Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  Llegada Planta
                </Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text style={{ textAlign: 'right', marginRight: 10 }}>
                  {moment(
                    dataEntrance.datosGeneralesPorteria.fechaLlegadaPlanta
                  ).format('DD/MM/YYYY hh:mm a')}
                </Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  Inicio Desembarque
                </Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text style={{ textAlign: 'right', marginRight: 10 }}>
                  {moment(
                    dataEntrance.datosGeneralesPorteria.inicioDesembarque
                  ).format('DD/MM/YYYY hh:mm a')}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: '10.5%' }]}>
              <View style={styles.tableCell}>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  Res. portería
                </Text>
              </View>
            </View>
            <View style={[styles.tableCol, { width: '22.5%' }]}>
              <View style={styles.tableCell}>
                <Text style={{ textAlign: 'right', marginRight: 10 }}>
                  {_.startCase(
                    dataEntrance.datosGeneralesPorteria.responsable.toLowerCase()
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  Salida Planta
                </Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text style={{ textAlign: 'right', marginRight: 10 }}>
                  {moment(
                    dataEntrance.datosGeneralesPorteria.fechaSalida
                  ).format('DD/MM/YYYY hh:mm a')}
                </Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  Final Desembarque
                </Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text style={{ textAlign: 'right', marginRight: 10 }}>
                  {moment(
                    dataEntrance.datosGeneralesPorteria.finDesembarque
                  ).format('DD/MM/YYYY hh:mm a')}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.totalanimals}>
        <View style={styles.marginanimals}>
          <View style={styles.numberanimals}>
            <View style={styles.backgroundanimalsrounded}>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  color: 'white',
                  fontSize: 13,
                }}
              >
                {dataGeneral.cantidadAnimalesLote === 'Null'
                  ? 0
                  : dataGeneral.cantidadAnimalesLote}
              </Text>
            </View>
            <Text style={{ marginTop: 5 }}>Total Animales</Text>
          </View>
          <View style={styles.numberanimals1}>
            <View style={styles.backgroundanimals}>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  color: 'white',
                  fontSize: 10,
                }}
              >
                {dataCorrals.DatosPesaje.pesoTotalDeTodosLosPesajes === 'Null'
                  ? 'N.D.'
                  : Number(
                      isNanReturn(
                        dataCorrals.DatosPesaje.pesoTotalDeTodosLosPesajes
                      )
                    ).toFixed(2)}{' '}
                Kg
              </Text>
            </View>
            <Text style={{ marginTop: 5, marginLeft: 3, fontSize: 8 }}>
              Peso Animales
            </Text>
            <Text style={{ fontSize: 8, marginLeft: 3 }}> en Pie (Kg)</Text>
          </View>
          <View style={styles.numberanimals2}>
            <View style={styles.backgroundanimals1}>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  color: 'white',
                  fontSize: 10,
                }}
              >
                {Number(
                  isNanReturn(
                    dataCorrals.DatosPesaje.pesoTotalDeTodosLosPesajes /
                      dataCorrals.DatosPesaje.cantidadAnimalesPesados
                  )
                ).toFixed(2)}{' '}
                Kg
              </Text>
            </View>
            <Text style={{ marginTop: 5, fontSize: 7 }}>Peso Promedio</Text>
            <Text style={{ fontSize: 7 }}> Animales en Pie (Kg)</Text>
          </View>
          <View style={styles.numberanimals3}>
            <View style={styles.backgroundanimals2}>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  color: 'white',
                  fontSize: 10,
                }}
              >
                {dataEntrance.datosGeneralesPorteria.tiempoPorteria}
              </Text>
              <Text>hh:mm:ss</Text>
            </View>
            <Text style={{ marginTop: 5, fontSize: 7 }}>Tiempo Total</Text>
            <Text style={{ fontSize: 7 }}> Estancia en la Planta</Text>
          </View>
          <View style={styles.numberanimals4}>
            <View style={styles.backgroundanimals3}>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  color: 'white',
                  fontSize: 10,
                }}
              >
                {dataEntrance.datosGeneralesPorteria.tiempoDesembarque}
              </Text>
              <Text>hh:mm:ss</Text>
            </View>
            <Text style={{ marginTop: 5, fontSize: 7 }}>Tiempo Total de</Text>
            <Text style={{ fontSize: 7 }}> Desembarque</Text>
          </View>
        </View>
      </View>
      <View style={styles.conventions}>
        <View style={styles.backgroundguide}>
          <View style={styles.textconventions}>
            <View style={styles.gsmi}>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                * GSMI
              </Text>
            </View>
            <View style={styles.textgsmi}>
              <Text>Guía Sanitaria de Movilización Interna de Animales</Text>
            </View>
          </View>
        </View>
        <View style={styles.border}>
          <Text
            style={{ fontFamily: 'Nunito', fontWeight: 'bold', color: 'black' }}
          >
            Convenciones
          </Text>
        </View>
      </View>
      <View style={styles.copy}>
        <Text style={{ fontSize: 7, fontFamily: 'Roboto' }}>
          Ⓒ 1995- {new Date().getFullYear()} Todos los derechos están reservados
          de forma exclusiva para Mercadeo Virtual S.A. Los datos suministrados
          por el usuario son de carácter confidencial y reservado, se regirán
          acorde a los términos de la Política de Confidencialidad y Reserva
          definida por la empresa Mercadeo Virtual S.A y la legislacion
          existente en Colombia sobre el registro, manejo y confidencialidad de
          los datos (Habeas Data).
        </Text>
        <Text style={styles.phone}>
          Teléfono (+574) 3228 603 | Correo: atencionalcliente@infoporcinos.com
          | Medellín Colombia
        </Text>
        <Text
          style={{ fontFamily: 'Roboto', fontWeight: 'bold', color: 'black' }}
        >
          www.infoporcinos.com
        </Text>
      </View>
      <View
        style={{
          width: 611,
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          paddingBottom: 25,
          paddingHorizontal: 30,
          flexDirection: 'row',
        }}
        fixed
      >
        <Text style={[{ flex: 1, textAlign: 'left' }]}>
          Fecha de impresión: {moment().format('DD/MM/YYYY')}. uid: {uid}
        </Text>
        <Text
          style={[styles.textBold, { flex: 1, textAlign: 'right' }]}
          render={({ pageNumber, totalPages }) =>
            pageNumber > 1 && `Pág Nro. ${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </View>
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screen" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte por Lote Portería"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
