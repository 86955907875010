import React, { useState, useEffect } from 'react';

import {
  Typography,
  Button,
  Box,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import Detail from './detail';
import Edit from './edit';
import GobackLink from 'components/GobackLink';
import AcceptDialog from 'components/PageComponents/Profile/AcceptDialog';

import { FARM_DETAIL } from 'core/graphql/queries/profile';
import { CHANGE_STATE_FARM } from 'core/graphql/mutations/profile';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useQuery, useMutation } from '@apollo/client';

import useStyles from './styles';

const FarmsDetails = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const path = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  let idFarm: any = useParams();
  const [edit, setEdit] = useState(false);
  const [dataFarm, setDataFarm] = useState<any>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [state, setState] = useState(false);

  const { data, loading, refetch } = useQuery(FARM_DETAIL, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idFarm: Number(idFarm.id) },
  });

  useEffect(() => {
    refetch();
  }, [path]);

  const [changeState, {}] = useMutation(CHANGE_STATE_FARM);

  useEffect(() => {
    if (!loading && data) {
      setDataFarm(data?.getFarmDetail?.data);
      setState(data?.getFarmDetail?.data?.estado);
    }
  }, [loading, data]);

  const handleChangeView = () => {
    refetch();
    setEdit(!edit);
  };

  const handleChangeState = () => {
    changeState({
      variables: { idFarm: Number(idFarm.id), state: state },
      context: { headers: { 'x-auth-jwt': token } },
    })
      .then(() => {
        refetch();
        setOpenAlert(false);
        if (state) {
          enqueueSnackbar(`La granja se activó exitosamente`, {
            variant: 'success',
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
        } else {
          enqueueSnackbar(`La granja se inactivó exitosamente`, {
            variant: 'success',
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
        }
      })
      .catch(() => {
        setOpenAlert(false);
        enqueueSnackbar('No se ha podido cambiar el estado de la Granja.', {
          variant: 'error',
          autoHideDuration: 5000,
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      });
  };

  const handleChange = (e: any) => {
    setState(e.target.checked);
    setOpenAlert(true);
  };

  if (loading) {
    return (
      <Box
        minHeight="500px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress variant="indeterminate" size={50} />
      </Box>
    );
  }

  return (
    <>
      <AcceptDialog
        onClick={handleChangeState}
        discardModal={true}
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        icon="icon-circle-alert"
        modalTitle={`¿Está seguro de ${
          state ? 'activar' : 'desactivar'
        } esta granja?`}
        modalDescription={''}
      />
      <Box className={classes.titleContainer}>
        <Box display="flex" alignItems="center">
          <GobackLink arrowMode />{' '}
          <Typography variant="h6" className={classes.title}>
            {edit ? 'Edición Datos de la Granja' : 'Detalle de la Granja'}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <FormControlLabel
            label={state ? 'Activa' : 'Inactiva'}
            labelPlacement="start"
            style={{ marginRight: 4 }}
            control={
              <Switch
                onChange={handleChange}
                checked={state}
                className={classes.switch}
              />
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangeView}
          >
            {edit ? 'Descartar' : 'Editar'}
          </Button>
        </Box>
      </Box>
      {edit ? <Edit data={dataFarm} /> : <Detail data={dataFarm} />}
    </>
  );
};

export default FarmsDetails;
