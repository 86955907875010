import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
 
  containerTab:{
    padding: 12
  },
}))

export default useStyles