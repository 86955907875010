import { gql } from "apollo-boost";

const values = `
  data {
    datosBasicos {
      idUsuario
      username
      usuarioInterno
      usuarioEmpresa {
        razonSocial
        avatar: imagenPerfil
      }
      usuarioPersona {
        primerNombre
        segundoNombre
        primerApellido
        SegundoApellido
        avatar: imagenPerfil
      }
    }
    tiposXRol {
      idTipoUsuario
      tipoUsuario
      idUsuarioXTipo
      idRol
      rol
    }
    codigosUsuarioActiguo {
      idProductor
      idComercializador
      idPlantaSacrificio
    }
    actualizacion {
      success
      message
    }
    subscriptionPlan {
      tiposSuscripcion
      fechaInicioSuscripcion
      razonSocialPatrocinador
      fechaFinalSuscripcion
    }
  }
  token
`;

export const LOGIN_MUTATION = gql`
  mutation login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      ${values}
    }
  }
`;

export const LOGIN_TOKEN_MUTATION = gql`
  mutation {
    login: loginToken {
      ${values}
    }
  }
`;
