import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dataGrid:{
    minHeight: 400,
    border:'none', 
    display:'flex',
    margin: '12px 0',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator":{
      display:'none'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14, 
    }, 
    "& .MuiDataGrid-columnsContainer":{
      justifyContent: 'center'
    }
  },
  headerTableGrid:{
    fontSize:14, 
    color: '#AFAFAF',
    fontFamily:'Roboto',
  },
  avatar:{
    width: '30px', 
    height: '30px', 
    fontSize: '20px', 
    marginRight: 12
  }, 
  viewBtn:{
    background:theme.palette.success.main,
    color: '#fff',
    fontWeight:'bold',
    '&:hover': {
      background: theme.palette.success.dark,
    },
  },
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "&:hover":{
      color: '#fff'
    }
  },
  state:{
    width: 8,
    height: 8,
    borderRadius: '100%',
    marginRight: 4,
    "&.active":{
      background:theme.palette.success.main
    }, 
    "&.wating":{
      background: "#767676"
    }, 
    "&.rejected":{
      background:theme.palette.error.main
    }
  }, 
  btnAction:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 4px',
    color:'#FFF',
    "&.acept":{
      background:theme.palette.success.main, 
      "&.MuiFab-root:hover":{
        background:theme.palette.success.dark, 

      },
    }, 
    "&.deny":{
      background:theme.palette.error.main, 
      "&.MuiFab-root:hover":{
      background:theme.palette.error.dark, 
      },
    }
  }
}))

export default useStyles