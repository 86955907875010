import React, { useEffect } from 'react';
import useStyles from './styles';
import logo from './../../static/img/icons/logoipt.png';
import getNamebyId from 'lib/helpers/getNamebyId';
import { useLazyQuery } from '@apollo/client';
import { CITIES_BY_ID } from 'core/graphql/queries/countries';
import { useSelector } from 'react-redux';

type svgProps = {
  width?: number;
  placa: string;
  ciudad: string | number;
  onClick?: any;
  selectable?: boolean;
  checked?: boolean;
};

const LicensePlate = ({
  width,
  placa,
  ciudad,
  onClick,
  selectable,
  checked,
}: svgProps) => {
  const token = useSelector((state: any) => state.user.token);

  const [getCity, { data: dataCity, loading: loadingCity, error: errorCity }] =
    useLazyQuery(CITIES_BY_ID, {
      fetchPolicy: 'network-only',
    });

  const nameCity = dataCity?.citiesById[0]?.nameCity;

  useEffect(() => {
    if (typeof ciudad === 'number') {
      getCity({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idCity: ciudad },
      });
    }
  }, [ciudad]);

  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1330 637"
      style={{
        width: width ? width : 180,
        cursor: 'pointer',
        marginRight: selectable ? 10 : 0,
      }}
      onClick={onClick}
    >
      <g id="Background">
        <g id="Background-2" data-name="Background">
          <rect
            width={1330}
            height={637}
            rx="25.56"
            style={{ fill: `${checked ? '#ffc600' : '#AFAFAF'}` }}
          />
          <rect x={10} y={11} width={1312} height={620} rx="25.56" />
          <rect
            x={26}
            y={22}
            width={1277}
            height={596}
            rx="25.56"
            style={{ fill: `${checked ? '#ffc600' : '#AFAFAF'}` }}
          />
        </g>
        <g id="Puntos_de_la_placa" data-name="Puntos de la placa">
          <circle cx="303.5" cy="62.5" r="18.5" style={{ fill: '#fff' }} />
          <circle cx="1078.5" cy="62.5" r="18.5" style={{ fill: '#fff' }} />
          <circle cx="303.5" cy="574.5" r="18.5" style={{ fill: '#fff' }} />
          <circle cx="1078.5" cy="574.5" r="18.5" style={{ fill: '#fff' }} />
        </g>
      </g>
      <text
        id="Placa"
        transform="translate(44 464.77)"
        style={{
          fontSize: '483px',
          fontFamily: 'GillSansMT-Condensed, Gill Sans MT Condensed',
        }}
      >
        {placa?.toString().length > 0
          ? placa?.slice(0, -3).toUpperCase()
          : 'ABC'}
      </text>
      <text
        id="Número"
        transform="translate(741.65 462.98)"
        style={{
          fontSize: '483px',
          fontFamily: 'GillSansMT-Condensed, Gill Sans MT Condensed',
        }}
      >
        {placa?.toString().length > 0 ? placa?.slice(-3).toUpperCase() : '123'}
      </text>
      <text
        id="Ciudad"
        transform="translate(415.65 589.5)"
        style={{
          fontSize: '123px',
          fontFamily: 'GillSansMT-Condensed, Gill Sans MT Condensed',
          letterSpacing: '0.050999587144308946em',
        }}
      >
        {ciudad?.toString().length > 0
          ? typeof ciudad === 'string'
            ? ciudad?.toUpperCase()
            : nameCity ?? ''
          : 'CIUDAD PLACA'}
      </text>
      <image
        width={200}
        height={200}
        transform="translate(615 262) scale(0.77)"
        xlinkHref={logo}
      />
    </svg>
  );
};
export default LicensePlate;
