import { gql } from "apollo-boost";

export const SEND_INVITATION = gql`
 mutation ($idUser: Int!, $idUserType: Int!){
  sendInvitation(input: {
    idUser: $idUser,
    idUserType: $idUserType
  }){
      data {
        message
        success
      }
    
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;