import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import {
  TextField,
  Grid,
  MenuItem,
  Typography,
  ListSubheader,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';

import { INDICATIVE_DEPARTMENTS } from 'core/graphql/queries/indicativeDepartment';
import { INDICATIVE } from 'core/graphql/queries/indicative';
import constants from 'config/constants';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Flag from 'react-world-flags';

import useStyles from './styles';

function TextMaskCustom(props: any) {
  const { ...other } = props;
  return (
    <MaskedInput
      {...other}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

interface PhoneNumberProps {
  names: {
    indicative: string;
    phone: string;
    ext?: string;
  };
  values: {
    indicative: number | null;
    phone: number | null;
    ext?: string | null;
  };
  disable?: boolean;
  onChange: (event: any) => void;
  onChangeIndicative?: (event: any) => void;
  errors: {
    indicative?: string | boolean;
    phone?: string;
    ext?: string;
  };
  helperTexts: {
    indicative?: string;
    phone?: string;
    ext?: string;
  };
  required?: boolean;
  type: 'mobile' | 'landline';
  extension?: boolean;
  labelIndicative?: string;
  labelInput?: string;
  icon?: any;
  withoutToken?: boolean;
}

const PhoneNumber = ({
  values,
  disable,
  onChange,
  names,
  errors,
  helperTexts,
  required,
  type,
  extension,
  labelIndicative,
  labelInput,
  icon,
  withoutToken,
}: PhoneNumberProps) => {
  const token = useSelector((state: any) => state.user.token);
  const otherToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm9jZXNzIjoibG9naW4iLCJpYXQiOjE1MTYyMzkwMjJ9.MuizKNA5ZeVb-aGq5wLT9FQYPsIa-Dfp0cJWdRC2zSM';
  const classes = useStyles();
  const [valueIndicative, setValueIndicative] = useState(0);

  const handleChange = (event: any) => {
    onChange(event);
  };

  const {
    data: dataCountry,
    error: errorCountry,
    loading: loadingCountry,
  } = useQuery(INDICATIVE, {
    context: { headers: { 'x-auth-jwt': withoutToken ? otherToken : token } },
  });

  const { loading, error, data } = useQuery(INDICATIVE_DEPARTMENTS, {
    context: { headers: { 'x-auth-jwt': withoutToken ? otherToken : token } },
    variables: { idCountry: constants.DEFAULT_COUNTRY_ID },
  });

  const options = (
    type === 'mobile' ? dataCountry?.countries : data?.departments
  )?.map((option: any) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const categories = _.groupBy(options, 'firstLetter');

  function optionsFactory(letter: string): Array<any> {
    const categoryOpts = categories[letter]?.map((item: any) =>
      type === 'mobile' ? (
        item?.phoneCodes.trim() !== '+' && (
          <MenuItem key={item?.id} value={Number(item?.id)}>
            <Grid container alignItems="center">
              <Flag className={classes.small} code={item?.iso2} />
              <Typography className={clsx('itemName', classes.containerMenu)}>
                {item?.name}
              </Typography>
              <Typography color="textSecondary">
                ({item?.phoneCodes})
              </Typography>
            </Grid>
          </MenuItem>
        )
      ) : (
        <MenuItem key={item?.id} value={item?.id}>
          <Typography className={clsx('itemName', classes.containerMenu)}>
            {item?.name}
          </Typography>
          <Typography color="textSecondary">(+ {item?.codeArea})</Typography>
        </MenuItem>
      )
    );

    return [
      <ListSubheader className={classes.categories}>{letter}</ListSubheader>,
      categoryOpts,
    ];
  }

  useEffect(() => {
    if (values.indicative) {
      setValueIndicative(values.indicative);
    }
  }, [values]);

  return (
    <div>
      {type === 'mobile' ? (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label={labelIndicative ?? 'Ind. Celular'}
              size="small"
              variant="outlined"
              select
              fullWidth
              disabled={disable}
              value={valueIndicative}
              onChange={onChange}
              name={names?.indicative}
              error={!!errors?.indicative}
              helperText={helperTexts?.indicative}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: { maxHeight: 300 },
                  },
                },
              }}
              className={classes.container}
              required={required}
            >
              {Object.keys(categories).map((letter) => optionsFactory(letter))}
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              label={labelInput ?? 'Celular'}
              required={required}
              value={values.phone}
              name={names.phone}
              onChange={handleChange}
              error={!!errors.phone}
              helperText={helperTexts.phone}
              size="small"
              variant="outlined"
              fullWidth
              disabled={disable}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: TextMaskCustom,
                endAdornment: (
                  <InputAdornment position="end" className={classes.inputIcon}>
                    {icon}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label={labelIndicative ?? 'Ind. Teléfono'}
              size="small"
              variant="outlined"
              select
              fullWidth
              disabled={disable}
              value={valueIndicative}
              onChange={handleChange}
              name={names.indicative}
              error={!!errors.indicative}
              helperText={helperTexts.indicative}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: { maxHeight: 300 },
                  },
                },
              }}
              className={classes.container}
              required={required}
            >
              {loading ? (
                <CircularProgress variant="indeterminate" size={25} />
              ) : (
                Object.keys(categories).map((letter) => optionsFactory(letter))
              )}
            </TextField>
          </Grid>
          <Grid item xs={extension ? 5 : 8}>
            <TextField
              disabled={disable}
              label={labelInput ?? 'Teléfono fijo'}
              value={values?.phone}
              onChange={onChange}
              name={names?.phone}
              error={!!errors?.phone}
              helperText={helperTexts?.phone}
              required={required}
              inputProps={{
                maxlength: 7,
              }}
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          {extension ? (
            <Grid item xs={3}>
              <TextField
                disabled={disable}
                label="Extensión"
                value={values?.ext}
                onChange={onChange}
                name={names?.ext}
                error={!!errors?.ext}
                helperText={helperTexts?.ext}
                inputProps={{
                  maxlength: 4,
                }}
                size="small"
                variant="outlined"
                fullWidth
              />
            </Grid>
          ) : null}
        </Grid>
      )}
    </div>
  );
};

export default PhoneNumber;
