import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme=>({ 
  title:{
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  }, 
  border:{
    width: "50px",
    borderBottom: `${theme.palette.primary.main} 3px solid`,
    borderRadius: "20px",
  }
}))

export default useStyles