import * as yup from 'yup'; 
import validations from './messages'

export const ChangeInternalUserPasswordValidations = yup.object({
    password: yup.string().required(validations.REQUIRED_FIELD)
    .test('min-length', 'Minímo 8 caracteres', (value: any) => value?.length >= 8)
    .test('pass-uppercase', 'Debe contener almenos una mayúscula', (value: any) => {
      let nMay = 0;
      const t1 = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
      for (let index = 0; index < value?.length; index++) {
        if (t1.indexOf(value.charAt(index)) !== -1) {
          nMay++;
        }
      }
      return nMay > 0 ? true : false;
    })
    .test('pass-lowercase', 'Debe contener almenos una minúscula', (value: any) => {
      let nMin = 0;
      const t2 = 'abcdefghijklmnñopqrstuvwxyz';
      for (let index = 0; index < value?.length; index++) {
        if (t2.indexOf(value.charAt(index)) !== -1) {
          nMin++;
        }
      }
      return nMin > 0 ? true : false;
    })
    .test('pass-number', 'Debe contener al menos un número', (value: any) => {
      let nNum = 0;
      const t3 = '0123456789';
      for (let index = 0; index < value?.length; index++) {
        if (t3.indexOf(value.charAt(index)) !== -1) {
          nNum++;
        }
      }
      return nNum > 0 ? true : false;
    })
    .matches(/[a-zA-Z0-9]/, {
      message: 'No se permiten caracteres especiales ni espacios',
    }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'La contraseña no coincide')
      .required(validations.REQUIRED_FIELD),
      
})