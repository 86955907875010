import React, { useEffect } from 'react';
import { Text, View } from '@react-pdf/renderer';

import _ from 'lodash';
import clsx from 'clsx';

import styles from './styles';

interface ChartListProps {
  data: Array<{
    data: number;
    label: string;
    bg: string;
  }>;
  removepercentages?: boolean;
}

const ChartList = ({ data, removepercentages }: ChartListProps) => {
  const dataObject = data.map((data: any) => data.data);
  const total = dataObject.reduce((a: number, b: number) => a + b, 0);
  return (
    <View style={styles.labelsBar}>
      {data.map((item: any) => (
        <View style={styles.itemLabels}>
          <View style={styles.titleLabelBar}>
            <View
              style={{
                borderRadius: 100,
                width: 5,
                height: 5,
                backgroundColor: item.bg,
                marginTop: 2,
                marginRight: 5,
              }}
            ></View>
            {removepercentages ? (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ marginRight: 5 }}>
                  <Text style={{ fontWeight: 'bold' }}>
                    {item.data < 10 ? `0${item.data}` : item.data}
                  </Text>
                </View>
                <Text>{item.label}</Text>
              </View>
            ) : (
              <View style={{ flexDirection: 'row' }}>
                <View style={{ marginRight: 5 }}>
                  <Text style={{ fontWeight: 'bold' }}>
                    {(item.data / total) * 100 < 10
                      ? `0${((item.data / total) * 100).toFixed(2)}%`
                      : ((item.data / total) * 100).toFixed(2) + '%'}
                  </Text>
                </View>
                <View style={{ marginRight: 5 }}>
                  <Text>({item.data < 10 ? `0${item.data}` : item.data})</Text>
                </View>
                <Text>{item.label}</Text>
              </View>
            )}
          </View>
        </View>
      ))}
    </View>
  );
};
export default ChartList;
