import { Document, Page, Image, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import antemortenLogo from 'static/img/reportsIcons/antemortenLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import HorizontalTableInfo from 'components/pdf-components/HorizontalTableInfo/index';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF/index';
import ChartList from 'components/pdf-components/ChartList/index';

import styles from './styles/filter';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const data = [
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Caído en Corrales',
  },
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Agitado en Corrales',
  },
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Agitado en Corrales',
  },
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Agitado en Corrales',
  },
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Caído en Corrales',
  },
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Caído en Corrales',
  },
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Agitado en Corrales',
  },
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Agitado en Corrales',
  },
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Caído en Corrales',
  },
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Agitado en Corrales',
  },
  {
    fecha: '22/01/2020',
    lote: '3060',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    loteCeba: '43213',
    corral: 'CO-13',
    estado: 'Agitado en Corrales',
  },
];

const data2 = [
  {
    fecha: '22/01/2020',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    estado: 'Muerto en Transporte',
    responsable: 'Nombre Responsable',
  },
  {
    fecha: '22/01/2020',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    estado: 'Muerto en Desembarque',
    responsable: 'Nombre Responsable',
  },
  {
    fecha: '22/01/2020',
    productor: 'Nombre Productor',
    granja: 'Nombre Granja',
    estado: 'Muerto en Desembarque',
    responsable: 'Nombre Responsable',
  },
];

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
  graph?: string;
  graphTwo?: string;
  range?: null | number | string;
}

export const MyDocRangeAnteMortem = ({
  qr,
  uid,
  graph,
  graphTwo,
  range,
}: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        range={range}
        reportImage={antemortenLogo}
        number="023456789"
        title="Reporte Técnico Inspección Ante-Mortem"
        code="AM-001"
      />

      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ width: '100%' }}>
        <HorizontalTableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            Nit: '34123412-2',
            Telefono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <View
          style={{
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: '#767676',
            marginTop: '10px',
          }}
        ></View>
        <HorizontalTableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Inspección de Animales Caídos / Agitados / Muertos en Corrales"
          widthContainer="100%"
        />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View
          style={{
            width: '40%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View style={{ width: '48.3%' }}>
              <View style={{ marginTop: '5px', marginBottom: '5px' }}>
                <ItemTotalCircle
                  value="12"
                  info="Total Animales Caidos / Agitados / Muertos"
                  position="right"
                  textAlign="left"
                  backgroundColor="#be5948"
                  widthInfo="55px"
                  widthCircle={30}
                />
              </View>
              <ItemTotalCircle
                value="02"
                info="Productores"
                position="right"
                textAlign="left"
                backgroundColor="#f1ae6c"
                widthInfo="55px"
                widthCircle={30}
              />
            </View>
            <View style={{ width: '48%' }}>
              <View style={{ marginTop: '5px', marginBottom: '5px' }}>
                <ItemTotalCircle
                  value="05"
                  info="Granjas Diferentes"
                  position="right"
                  textAlign="left"
                  backgroundColor="#ffc676"
                  widthInfo="65px"
                  widthCircle={30}
                />
              </View>
            </View>
          </View>
        </View>
        <View style={{ width: '60%' }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                height: '100px',
                marginTop: 10,
                marginBottom: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image src={graph} style={{ height: '150px', width: '150px' }} />
            </View>

            <View>
              <ChartList
                removepercentages
                data={[
                  {
                    label: 'Agitados Corrales',
                    data: 3,
                    bg: '#FFC676',
                  },
                  {
                    label: 'Caídos Corrales',
                    data: 6,
                    bg: '#F1AE6C',
                  },
                  {
                    label: 'Muertos en Corrales',
                    data: 3,
                    bg: '#E29662',
                  },
                ]}
              />
            </View>
          </View>
        </View>
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Detalle de la Inspección de Animales Caídos / Agitados / Muertos en Corrales"
          widthContainer="100%"
        />
      </View>

      <View style={{ width: '100%', marginTop: '10px' }}>
        <View style={[styles.table, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '5px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 69.2, width: 69.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Fecha</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote IP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 108.2, width: 108.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Productor
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 108.2, width: 108.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Granja</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Lote Ceba
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 69.2, width: 69.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Corral</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 79.2, width: 79.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Estado</Text>
            </View>
          </View>
          {data.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 69.2, width: 69.2 }]}>
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.fecha}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.lote}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 108.2, width: 108.2 }]}
              >
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.productor}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 108.2, width: 108.2 }]}
              >
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.granja}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.loteCeba}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 69.2, width: 69.2 }]}>
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.corral}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 79.2, width: 79.2 }]}>
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.estado}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Animales Muertos en Transporte o Desembarque"
          widthContainer="100%"
        />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View
          style={{
            width: '40%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View style={{ width: '48.3%' }}>
              <View style={{ marginTop: '5px', marginBottom: '5px' }}>
                <ItemTotalCircle
                  value="02"
                  info="Total Animales Caidos / Agitados / Muertos"
                  position="right"
                  textAlign="left"
                  backgroundColor="#be5948"
                  widthInfo="55px"
                  widthCircle={30}
                />
              </View>
              <ItemTotalCircle
                value="01"
                info="Productores"
                position="right"
                textAlign="left"
                backgroundColor="#f1ae6c"
                widthInfo="55px"
                widthCircle={30}
              />
            </View>
            <View style={{ width: '48%' }}>
              <View style={{ marginTop: '5px', marginBottom: '5px' }}>
                <ItemTotalCircle
                  value="02"
                  info="Granjas Diferentes"
                  position="right"
                  textAlign="left"
                  backgroundColor="#ffc676"
                  widthInfo="65px"
                  widthCircle={30}
                />
              </View>
            </View>
          </View>
        </View>
        <View style={{ width: '60%' }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                height: '100px',
                marginTop: 10,
                marginBottom: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                src={graphTwo}
                style={{ height: '150px', width: '150px' }}
              />
            </View>

            <View>
              <ChartList
                removepercentages
                data={[
                  {
                    label: 'Muertos en Transporte',
                    data: 1,
                    bg: '#FFC676',
                  },
                  {
                    label: 'Muertos en Desembarque',
                    data: 1,
                    bg: '#F1AE6C',
                  },
                ]}
              />
            </View>
          </View>
        </View>
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle
          title="Detalle de Animales Muertos en Transporte o Desembarque"
          widthContainer="100%"
        />
      </View>

      <View style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
        <View style={[styles.table, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '5px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Fecha</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Productor
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Granja</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Estado</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Responsable
              </Text>
            </View>
          </View>
          {data2.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.fecha}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.productor}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.granja}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.estado}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 111, width: 111 }]}>
                <Text style={[styles.tableCellAnte, { fontSize: 8 }]}>
                  {item.responsable}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>

      <View
        style={{
          width: '100%',
          marginTop: '5px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View style={{ maxWidth: '46%' }}>
          <Text>
            Todos los Animales en Pie "DECLARADOS MUERTOS" en el proceso de
            Inspección son decomisados e incinerados.
          </Text>
        </View>
      </View>

      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportFilter = () => {
  return (
    <div id="screenQrAnteMortenFilter" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte Técnico Inspección Ante-Mortem"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphRangeAnteMortem = () => {
  return (
    <div id="graphrangeantemortem" style={{ width: 500, marginTop: -20 }}>
      <Graphicpdfsf
        textTotalData="Total"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        startAngle={-90}
        endAngle={270}
        removepercentages
        data={[
          {
            label: 'Agitados Corrales',
            data: 3,
            bg: '#FFC676',
          },
          {
            label: 'Caídos Corrales',
            data: 6,
            bg: '#F1AE6C',
          },
          {
            label: 'Muertos en Corrales',
            data: 3,
            bg: '#E29662',
          },
        ]}
      />
    </div>
  );
};

export const GraphRangeAnteMortemTwo = () => {
  return (
    <div id="graphrangeantemortemtwo" style={{ width: 500, marginTop: -20 }}>
      <Graphicpdfsf
        textTotalData="Total"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        startAngle={-1}
        endAngle={359}
        removepercentages
        data={[
          {
            label: 'Muertos en Transporte',
            data: 1,
            bg: '#FFC676',
          },
          {
            label: 'Muertos en Desembarque',
            data: 1,
            bg: '#F1AE6C',
          },
        ]}
      />
    </div>
  );
};
