import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
} from '@material-ui/core';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import useStyles from './styles';
import ContentCard from 'components/ContentCard';
import GobackLink from './../../../../../../../components/GobackLink/index';

function createData(
  batchNumber: number,
  pigType: string,
  amount: number,
  PCCTotal: number,
  PCCAverage: number,
  GDverage: number,
  MCAverage: number
) {
  return {
    batchNumber,
    pigType,
    amount,
    PCCTotal,
    PCCAverage,
    GDverage,
    MCAverage,
  };
}

// Datos quemados
const rows = [
  createData(
    4567890,
    'Cerdo de engorde (C.E.)',
    30,
    3240,
    111.89,
    111.89,
    53.5
  ),
  createData(
    4567890,
    'Cerdo de engorde (C.E.)',
    30,
    3240,
    111.89,
    111.89,
    53.5
  ),
];

const DispatchFile = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [openNotification, setOpenNotification] = useState(false);

  const handleOpenNotification = () => {
    setOpenNotification(true);
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: `/app/network/transports/details/1/dispatch/file/${id}`,
      title: 'Ficha de Despachos',
    },
    {
      link: `/app/network/transports/details/${id}/dispatch`,
      title: 'Historial Despachos',
    },
    { link: `/app/network/transports/details/${id}`, title: 'Detalles' },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];

  return (
    <>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard className={classes.contentCard}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6">Ficha de Despacho Canales</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Paper elevation={0} className={classes.paperContainer}>
              <Grid container>
                <Box mr={2}>
                  <Typography className={classes.fontPaper}>
                    Nro. Depacho
                  </Typography>
                </Box>
                <Typography className={classes.datePaper}>011-65432</Typography>
              </Grid>
            </Paper>

            <Box ml={3}>
              <Paper elevation={0} className={classes.paperContainer}>
                <Grid container>
                  <Box mr={2}>
                    <Typography className={classes.fontPaper}>Fecha</Typography>
                  </Box>
                  <Typography className={classes.datePaper}>
                    06/03/2021
                  </Typography>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>

        <Box mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper elevation={0} className={classes.paperTitle}>
                <Typography className={classes.fontTitlePaper}>
                  Datos del Comercializador
                </Typography>
              </Paper>
              <div className={classes.dataUserContainer}>
                <ul className={classes.listContainer}>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Nombre</Typography>
                      <Typography className={classes.itemChild}>
                        Sebastian Mosquera
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Identificación</Typography>
                      <Typography className={classes.itemChild}>
                        1234567890
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Dirección y ciudad</Typography>
                      <Typography className={classes.itemChild}>
                        Carrera 33 #17-41
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Ciudad</Typography>
                      <Typography className={classes.itemChild}>
                        Bogotá, Cundinamarca
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Contacto</Typography>
                      <Typography className={classes.itemChild}>
                        +57 3104567890
                      </Typography>
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} className={classes.paperTitle}>
                <Typography className={classes.fontTitlePaper}>
                  Datos del Destino (Cliente)
                </Typography>
              </Paper>
              <div className={classes.dataUserContainer}>
                <ul className={classes.listContainer}>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Nombre</Typography>
                      <Typography className={classes.itemChild}>
                        Sebastian Mosquera
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Identificación</Typography>
                      <Typography className={classes.itemChild}>
                        1234567890
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Dirección y ciudad</Typography>
                      <Typography className={classes.itemChild}>
                        Carrera 33 #17-41
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Ciudad</Typography>
                      <Typography className={classes.itemChild}>
                        Bogotá, Cundinamarca
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Contacto</Typography>
                      <Typography className={classes.itemChild}>
                        +57 3104567890
                      </Typography>
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper elevation={0} className={classes.paperTitle}>
                <Typography className={classes.fontTitlePaper}>
                  Datos de la Planta de Beneficio
                </Typography>
              </Paper>
              <div className={classes.dataUserContainer}>
                <ul className={classes.listContainer}>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Planta de Sacrificio</Typography>
                      <Typography className={classes.itemChild}>
                        Frigorificos Ble L.T.D.A.
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Hora de Salida Planta</Typography>
                      <Typography className={classes.itemChild}>
                        8:00 a.m.
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Hora llegada Estimada</Typography>
                      <Typography className={classes.itemChild}>
                        2:00 p.m.
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Guía de Movilización N°</Typography>
                      <Typography className={classes.itemChild}>
                        234567
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Permiso Policia N°</Typography>
                      <Typography className={classes.itemChild}>
                        123456789
                      </Typography>
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} className={classes.paperTitle}>
                <Typography className={classes.fontTitlePaper}>
                  Datos de Transporte
                </Typography>
              </Paper>
              <div className={classes.dataUserContainer}>
                <ul className={classes.listContainer}>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Nombre Conductor</Typography>
                      <Typography className={classes.itemChild}>
                        Dominic Toreto
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Identificación</Typography>
                      <Typography className={classes.itemChild}>
                        NIT 1234567890
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Placa Vehículo</Typography>
                      <Typography className={classes.itemChild}>
                        SMV 195
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>Contacto</Typography>
                      <Typography className={classes.itemChild}>
                        +57 3104567890
                      </Typography>
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Box>

        <Paper elevation={0} className={classes.paperTitle}>
          <Typography className={classes.fontTitlePaper}>
            Listado de Canales Despachadas
          </Typography>
        </Paper>

        <Box mt={2}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead className={classes.headTable}>
                <TableRow>
                  <TableCell align="center">Numero de Lote</TableCell>
                  <TableCell align="center">Tipo de Cerdo</TableCell>
                  <TableCell align="center">Cantidad</TableCell>
                  <TableCell align="center">PCC Total&nbsp;(Kg)</TableCell>
                  <TableCell align="center">Prom. PCC&nbsp;(Kg)</TableCell>
                  <TableCell align="center">Prom. GD&nbsp;(m.m.)</TableCell>
                  <TableCell align="center">Prom. MC&nbsp;(%)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.batchNumber}>
                    <TableCell align="center">{row.batchNumber}</TableCell>
                    <TableCell align="center">{row.pigType}</TableCell>
                    <TableCell align="center">{row.amount}</TableCell>
                    <TableCell align="center">{row.PCCTotal}</TableCell>
                    <TableCell align="center">{row.PCCAverage}</TableCell>
                    <TableCell align="center">{row.GDverage}</TableCell>
                    <TableCell align="center">{row.MCAverage}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box mt={4} mb={3}>
          <Box mb={1}>
            <Typography className={classes.fontTitlePaper}>
              Observaciones
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            name="secondName"
          />
        </Box>

        <Grid container justify="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenNotification}
          >
            Imprimir
          </Button>
        </Grid>
      </ContentCard>
    </>
  );
};
export default DispatchFile;
