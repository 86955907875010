import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      background: theme.palette.success.main,
      opacity: 1,
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: '#fff',
    },
  },

  flexDiv: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0px',
  },

  flexDivSwitches: {
    display: 'flex',
    alignItems: 'center',
  },

  flexDivSwitchesxDay: {
    display: 'flex',
    alignItems: 'center',
    width: 130,
    justifyContent: 'space-between',
  },

  flexDivSwitchesxAll: {
    display: 'flex',
    alignItems: 'center',
    width: 200,
    justifyContent: 'space-between',
  },

  flexDivSchedule: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  flexDivColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  daysStyles: {
    textAlign: 'left',
  },

  viewButton: {
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      color: '#fff',
    },
    margin: '0px 5px',
  },

  pickerMargins: {
    margin: '0px 5px',
  },
}));

export default useStyles;
