import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiDialog-paperWidthMd": {
      borderRadius: "20px",
    },
  },
  containerPermiss: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: theme.shadows[2],
    padding: 8,
    borderRadius: 10,
  },
  containerSubcategories: {
    boxShadow: theme.shadows[2],
    padding: "8px 24px",
    borderRadius: "0px 0px 10px 10px",
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "center",
  },
}));

export { useStyles as default };
