import axios from 'axios'

import config from 'config/secrets'

export const logout = async (uid: string | number): Promise<any> => {
  try {
    const response = await axios.post(`${config.AUTH}${config.APP_URI}${config.APP_VERSION}/sign-out`, {
      IdUsuario: uid
    })
    return response.data
  } catch (error) {
    console.error(error.toString())
  }
}