import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form:{
    width:'100%',
  }, 
  select:{
  }
}));

export default useStyles;
