import { Avatar, ButtonBase, Grid, Paper, Typography } from "@material-ui/core";
import { useState } from "react";
import clsx from "clsx";

import useStyles, { typeAssets as assets } from "./styles";

const TypeCard = ({
  type,
  onClick,
}: {
  type: 1 | 2;
  onClick?: (id: number) => void;
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<boolean>(false);

  const toggle = () => setSelected((prev) => !prev);

  const handleClick = () => onClick && onClick(type);

  return (
    <Grid item>
      <ButtonBase
        className={classes.card_container}
        onClick={() => {
          toggle();
          handleClick();
        }}
        disableRipple
      >
        <Paper
          elevation={0}
          className={clsx(classes.card, { [classes.active]: selected })}
        >
          <div className={classes.avatar_container}>
            <Avatar className={classes.avatar}>
              <img
                src={assets[type].img}
                className={classes.avatar_img}
                alt={assets[type].title}
              />
            </Avatar>
          </div>
          <Typography className={classes.card_text}>
            {assets[type].title}
          </Typography>
        </Paper>
      </ButtonBase>
    </Grid>
  );
};

const TypeCardGroup = ({
  userTypes = [],
  onChange,
}: {
  userTypes: Array<1 | 2>;
  onChange: (id: number) => void;
}) => {
  const classes = useStyles();

  const onChangeCard = (id: number) => onChange(id);

  return (
    <Grid container className={classes.content} spacing={2}>
      {userTypes.map((type: 1 | 2) => (
        <TypeCard key={type} type={type} onClick={onChangeCard} />
      ))}
    </Grid>
  );
};

export { TypeCard as default, TypeCardGroup };
