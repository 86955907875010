import React, { useState } from 'react';

import { Box, Avatar, Button, Dialog, DialogActions } from '@material-ui/core';
import { AddAPhoto } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import ModalPicture from 'components/ProfilePictureSelector/modalProfilePicture';

import avatars from 'lib/helpers/dynamic-images/avatars';
import config from 'config/secrets';

import { useSelector } from 'react-redux';

import useStyles from './styles';

const UserProfile = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const [openPreview, setOpenPreview] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [image, setSelectedImage] = useState(null);
  const { typeEntity, avatar, username } = useSelector(
    (state: any) => state.user.user
  );
  const userType = useSelector(
    (state: any) => state.user.selectedUserType.idUserType
  );

  const handleOpen = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const setImage = (img: any) => {
    setSelectedImage(img);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.length !== 0) {
      const img: any = e.target?.files?.[0];
      var image = new Image();
      image.src = URL.createObjectURL(img);
      image.onload = function () {
        var height = image.height;
        var width = image.width;
        if (img.size <= 5242880) {
          if (width > 300 && height > 300) {
            setOpen(true);
            setImage(image.src);
          } else {
            enqueueSnackbar(
              'Las dimensiones de la imagen deben ser mínimo de 300px x 300px',
              {
                variant: 'error',
                autoHideDuration: 5000,
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top',
                },
              }
            );
          }
        } else {
          setOpen(false);

          enqueueSnackbar('El archivo supera los 5Mb', {
            variant: 'error',
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
        }
      };
    }
  };

  const handleClickImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (image) {
      e.preventDefault();
      setSelectedImage(null);
    }
  };

  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.edit}>
          <Avatar
            src={
              avatar !== ''
                ? `${config.BACKEND.dev.backend}${avatar}`
                : avatars(username)
            }
            className={classes.avatar}
          />
          <div className={classes.overlay} onClick={handleOpen}>
            <AddAPhoto />
          </div>
        </Box>

        <Dialog
          open={openPreview}
          onClose={handleClosePreview}
          fullWidth
          maxWidth="sm"
          className={classes.dialogContainer}
        >
          <Box
            height="400px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="20px"
          >
            <img
              height="100%"
              src={
                avatar !== ''
                  ? `${config.BACKEND.dev.backend}${avatar}`
                  : avatars(username)
              }
            />
          </Box>
          <DialogActions className={classes.dialogFooter}>
            <input
              accept="image/*"
              id="icon-button-file"
              type="file"
              hidden
              onChange={handleChange}
            />
            <Button variant="contained" color="primary">
              <label htmlFor="icon-button-file" style={{ cursor: 'pointer' }}>
                Cambiar Imagen
              </label>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClosePreview}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <ModalPicture
          open={open}
          img={image}
          onClose={handleClickClose}
          onClick={() => handleClickImage}
        />
      </Box>
    </>
  );
};

export default UserProfile;
