import React, { createRef, useCallback, useEffect, useRef } from 'react';
import MobileDetect from 'mobile-detect';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();

const handlerNameByEvent: any = {
  'ps-scroll-y': 'onScrollY',
  'ps-scroll-x': 'onScrollX',
  'ps-scroll-up': 'onScrollUp',
  'ps-scroll-down': 'onScrollDown',
  'ps-scroll-left': 'onScrollLeft',
  'ps-scroll-right': 'onScrollRigth',
  'ps-y-reach-start': 'onYReachStart',
  'ps-y-reach-end': 'onYReachEnd',
  'ps-x-reach-start': 'onXReachStart',
  'ps-x-reach-end': 'onXReachEnd',
};
Object.freeze(handlerNameByEvent);

const VirtualScrollbars = React.forwardRef(function VirtualScrollbars(
  props: VirtualScrollbarsProps,
  ref: any
) {
  const { customScrollbars } = props;
  ref = ref || createRef();
  const ps = useRef<any>(null);
  const handlerByEvent = useRef(new Map());

  const hookUpEvents = useCallback(() => {
    Object.keys(handlerNameByEvent).forEach((key: string) => {
      const event:
        | 'onScrollY'
        | 'onScrollX'
        | 'onScrollUp'
        | 'onScrollDown'
        | 'onScrollLeft'
        | 'onScrollRight'
        | 'onYReachStart'
        | 'onYReachEnd'
        | 'onXReachStart'
        | 'onXReachEnd' = handlerNameByEvent[key];
      const callback = props[event];

      if (callback) {
        const handler = () => callback(ref?.current);
        handlerByEvent.current.set(key, handler);
        ref?.current.addEventListener(key, handler, false);
      }
    });
  }, [ref]);

  const unHookUpEvents = useCallback(() => {
    Object.keys(handlerByEvent.current).forEach((value, key) => {
      if (ref.current) {
        ref.current.removeEventListener(key, value, false);
      }
    });
    handlerByEvent.current.clear();
  }, [ref]);

  const destroyPs = useCallback(() => {
    unHookUpEvents();

    if (!ps.current) {
      return;
    }
    ps?.current?.destroy();
    ps.current = null;
  }, [unHookUpEvents]);

  const createPs = useCallback(() => {
    if (isMobile || !ref || ps.current) {
      return;
    }
    ps.current = new PerfectScrollbar(ref.current, {
      ...props.option,
      suppressScrollX: props.hiddenScrollX,
    });
    hookUpEvents();
  }, [hookUpEvents, props.option, ref]);

  useEffect(() => {
    function updatePs() {
      if (!ps.current) {
        return;
      }
      ps.current.update();
    }
    updatePs();
  }, []);

  useEffect(() => {
    customScrollbars ? createPs() : destroyPs();
  }, [createPs, customScrollbars, destroyPs]);

  useEffect(() => {
    function scrollToTop() {
      if (ref.current) ref.current.scrollTop = 0;
    }
    if (props.scrollToTopOnChildChange) {
      scrollToTop();
    }
  }, [props.children, props.scrollToTopOnChildChange, ref]);

  useEffect(() => {
    return () => {
      destroyPs();
    };
  }, [destroyPs]);

  return (
    <div
      id={props.id}
      className={props.className}
      style={
        customScrollbars && (props.enable || true) && !isMobile
          ? {
              position: 'relative',
              overflow: 'hidden',
            }
          : {}
      }
      ref={ref}
    >
      {props.children}
    </div>
  );
});

interface VirtualScrollbarsProps {
  onScrollY?: any;
  onScrollX?: any;
  onScrollUp?: any;
  onScrollDown?: any;
  onScrollLeft?: any;
  onScrollRight?: any;
  onYReachStart?: any;
  onYReachEnd?: any;
  onXReachStart?: any;
  onXReachEnd?: any;
  scrollToTopOnChildChange?: boolean;
  customScrollbars?: boolean;
  children: React.ReactNode;
  className?: string;
  enable?: boolean;
  option?: any;
  id?: string;
  hiddenScrollX?: boolean;
}

VirtualScrollbars.defaultProps = {
  className: '',
  enable: true,
  scrollToTopOnChildChange: false,
  customScrollbars: true,
  option: {
    wheelPropagation: true,
  },
  ref: undefined,
  onScrollY: undefined,
  onScrollX: undefined,
  onScrollUp: undefined,
  onScrollDown: undefined,
  onScrollLeft: undefined,
  onScrollRight: undefined,
  onYReachStart: undefined,
  onYReachEnd: undefined,
  onXReachStart: undefined,
  onXReachEnd: undefined,
  hiddenScrollX: false,
};

export default VirtualScrollbars;
