import { Document, Page, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import immunocastrationLogo from 'static/img/reportsIcons/immunocastrationLogo';
import farmIcon from 'static/img/reportsIcons/farm';
import productorIcon from 'static/img/reportsIcons/productorIcon';
import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import TableInfo from 'components/pdf-components/TableInfo/index';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ListData from 'components/pdf-components/ListData';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';

import styles from './styles/lote';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

interface MyDocINMcProps {
  qr: string;
}

export const MyDocInmune = ({ qr }: MyDocINMcProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={6700}
        reportImage={immunocastrationLogo}
        number="023456789"
        title="Certificado de Inmunocastración"
        code="CL-001"
      />
      <Subtitle title="Resumen del Proceso" widthContainer="100%" />

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          icon={productorIcon}
          title="Productor"
          widthContainer="48.3%"
          items={{
            Nombre: 'Nombre de la Empresa',
            NIT: '34123412-2',
            Teléfono: '+57 045 2323 322',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <TableInfo
          icon={farmIcon}
          title="Datos Granja de Origen"
          widthContainer="48.3%"
          items={{
            'Nombre Granja': 'Nombre de la Granja',
            Dirección: 'Cr 31 #12-54',
            Ciudad: 'Medellín, Colombia',
            Teléfono: '+57 314 6543 543',
            Responsable: 'Nombre del Responsable',
          }}
        />
      </View>

      <View style={{ flexDirection: 'row', marginTop: '5px' }}>
        <TableInfo
          title="Protocolo de Vacunación"
          widthContainer="48.3%"
          items={{
            'Fecha Primera Dosis': '22/10/2019',
            'Fecha Segunda Dosis': '24/10/2019',
            'Nro del Lote de Vacuna': '422146',
          }}
        />
        <TableInfo
          title="Datos Médico Veterinario Responsable"
          widthContainer="48.3%"
          items={{
            'Nombre Veterinario': 'Nombre del Médico Veterinario',
            'Registro Profesional': '7543234',
            Teléfono: '315 4233 323',
          }}
        />
      </View>

      <View style={{ flexDirection: 'row', width: '100%', marginTop: '10px' }}>
        <Subtitle title="Datos del Embarque" widthContainer="100%" />
      </View>
      <View style={{ flexDirection: 'row' }}>
        <ListData
          widthContainer="48.3%"
          items={{
            'Fecha de Embarque': '22/10/2019',
            'Número de Cerdos': '52',
            'Planta Destino': 'Central Ganadera S.A.',
            'Lote de Ceba': 'R221',
          }}
        />
        <ListData
          widthContainer="48.3%"
          items={{
            'Nombre del Transportador': 'Nombre Completo Transportador',
            'Placas del Vehículo': 'JRD 4C3',
            'Guía Sanitaria ICA': '654414',
            'Certificado de Inmunocastración': '11-223121332',
          }}
        />
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportLot = () => {
  return (
    <div id="screenLotImmune" style={{ width: 500 }}>
      <QRCode
        size={500}
        value="Reporte por Lote Certificado de Inmunocastración"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
