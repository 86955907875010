import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { PersonOutlineOutlined } from "@material-ui/icons";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Formik, FormikHandlers, FormikHelpers } from "formik";

import ForgotPasswordValidations from "lib/validations/ForgotPasswordValidations";

import { EmailCheck } from "static/img/icons";

import { forgotPassword } from "api/resetPassword";

import useStyles from "./styles";
import AuthFormContainer from "components/AuthFormContainer";

const ForgotPassword = () => {
  const classes = useStyles();
  const [hasError, setHasError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const onSubmit = (
    data: { email: string },
    { setSubmitting }: FormikHelpers<any>
  ) => {
    forgotPassword(data.email)
      .then((res) => {
        if (res.success === 1) {
          setSuccess(true);
          setSubmitting(false);
        } else {
          setHasError(true);
          setSubmitting(false);
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 6000,
            preventDuplicate: true,
          });
        }
      })
      .catch((err) => {
        setHasError(true);
        enqueueSnackbar(err.toString(), {
          variant: "error",
          autoHideDuration: 5000,
          preventDuplicate: true,
        });
      });
  };

  const handleRedirect = () => {
    history.push("/auth/login");
  };

  return (
    <div className={classes.root}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={ForgotPasswordValidations}
        initialValues={{
          email: "",
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        }) => (
          <>
            <AuthFormContainer
              onSubmit={handleSubmit}
              title="¿Olvidó la contraseña?"
              subtitle="A continuación ingrese el correo para recuperar la contraseña"
            >
              <TextField
                name="email"
                label={values.email.length > 0 && "Correo electrónico"}
                variant="outlined"
                placeholder="Correo Electrónico"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={(errors.email && touched.email) || hasError}
                helperText={errors.email}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop={1.5}
              >
                <Button
                  variant="text"
                  component={Link}
                  to="/auth/login"
                  children="Volver atrás"
                />
                <Button
                  variant="contained"
                  color="primary"
                  children={isSubmitting ? "ENVIANDO" : "ENVIAR"}
                  className="bold"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  endIcon={
                    isSubmitting && (
                      <CircularProgress
                        variant="indeterminate"
                        color="inherit"
                        size={20}
                      />
                    )
                  }
                />
              </Box>
            </AuthFormContainer>
            <Dialog open={success} maxWidth="xs" fullWidth>
              <DialogContent className={classes.alert_content}>
                <img src={EmailCheck} className={classes.email_check_img} />
                <Typography color="textPrimary" variant="h5">
                  ¡Correo enviado con éxito!
                </Typography>
                <Typography color="textSecondary">
                  Revisa la bandeja de entrada del correo electrónico ingresado{" "}
                  <b>({values.email})</b> para realizar el cambio de contraseña
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRedirect}
                >
                  CONTINUAR
                </Button>
              </DialogContent>
            </Dialog>
          </>
        )}
      </Formik>
    </div>
  );
};

export { ForgotPassword as default };
