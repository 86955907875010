import { makeStyles } from "@material-ui/core";

const DEFAULT_SIZE = 60;
const useStyles = makeStyles((theme) => ({
  resumeChild: {
    display: "flex",
    alignItems: "center",
    "& $avatar": {
      background: "#767676",
    },
    "&.top": {
      flexDirection: "column-reverse",
      textAlign: "center",
      "& $avatar": {
        marginTop: 12,
      },
    },
    "&.bottom": {
      flexDirection: "column",
      textAlign: "center",
      "& $avatar": {
        marginBottom: 12,
      },
    },
    "&.left": {
      justifyContent: "flex-end",
      flexDirection: "row-reverse",
      "& $avatar": {
        marginLeft: 12,
      },
    },
    "&.right": {
      "& $avatar": {
        marginRight: 12,
      },
    },
  },
  avatar: {
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
    fontSize: 24,
    fontWeight: "bold",
    borderRadius: 500,
  },
  extended_avatar: {
    width: "inherit",
    minWidth: DEFAULT_SIZE * 1.7,
    padding: "0px 8px",
  },
  color_inverted: {
    color: "#000000",
  },
  resumeInfo: {
    color: "#AFAFAF",
  },
}));

export { useStyles as default };
