import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  Grid,
  Checkbox,
  Typography,
  Button,
  Box,
  Paper,
  Tabs,
  FormControlLabel,
  Switch,
  Avatar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import ContentCard from 'components/ContentCard';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import Loader from 'components/Loader';
import GobackLink from 'components/GobackLink';
import MainTitle from 'components/MainTitle';
import ItemListData from 'components/ItemListData';
import LicensePlate from 'components/LicensePlate';
import Slider from 'components/Slider';

import {
  GET_COMPANY,
  GET_DRIVERS_COMPANY,
  GET_VEHICLES_COMPANY,
} from 'core/graphql/queries/transport';
import avatar from 'lib/helpers/dynamic-images/avatars';

import DialogViewDrivers from './DialogViewDrivers/index';

import useStyles from './styles';

const DetailCompany = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState(true);
  const [selectedValue, setSelectedValue] = useState({});
  const [openViewDrivers, setOpenViewDrivers] = useState({
    open: false,
    data: {
      idCompany: 0,
      companyName: '',
    },
  });
  const [dataSelect, setDataSelect] = useState({
    placa: '',
    numeroLicenciaTransito: '',
    marca: '',
    modelo: '',
    color: '',
    descripcionServicio: '',
    descripcionTipoVehiculo: '',
    numeroPisos: '',
    capacidadCarga: '',
    descripcionTipoCarga: '',
    nombreCompletoPropietario: '',
    tipoServicio: '',
  });

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: `/app/network/transports/details/company/${id}`,
      title: 'Detalle Empresa',
    },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];

  const [
    getCompany,
    { data: dataCompany, loading: loadingCompany, error: errorCompany },
  ] = useLazyQuery(GET_COMPANY, {
    fetchPolicy: 'network-only',
  });

  const {
    data: dataVehicles,
    loading: loadingVehicles,
    error: errorVehicles,
    refetch: refetchVehicles,
  } = useQuery(GET_VEHICLES_COMPANY, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idEmpresaTransportadora: Number(id) },
  });

  // const {
  //   data: dataDrivers,
  //   loading: loadingDrivers,
  //   error: errorDrivers,
  //   refetch: refetchDrivers,
  // } = useQuery(GET_DRIVERS_COMPANY, {
  //   context: { headers: { 'x-auth-jwt': token } },
  //   variables: { idEmpresaTransportadora: Number(id) },
  // });

  useEffect(() => {
    if (id) {
      getCompany({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idTransportadora: Number(id) },
      });
    }
  }, [id]);

  const generalData = dataCompany?.getDetailCompanyConveyor?.data;
  const dataVehiclesG = dataVehicles?.getVehiclesXCompany?.data;
  // const dataDriversC = dataDrivers?.getDriversXCompany?.data;

  const handleChange = () => {
    setState(!state);
  };
  const handleChangeSelect = (data: any) => {
    setSelectedValue(data?.placa);
    setDataSelect(data);
  };

  useEffect(() => {
    if (!loadingVehicles && !loadingCompany && dataVehicles && !errorVehicles) {
      setSelectedValue(dataVehiclesG[0]?.placa ?? {});
      setDataSelect({
        placa: dataVehiclesG[0]?.placa,
        numeroLicenciaTransito: dataVehiclesG[0]?.numeroLicenciaTransito,
        marca: dataVehiclesG[0]?.marca,
        modelo: dataVehiclesG[0]?.modelo,
        color: dataVehiclesG[0]?.color,
        descripcionServicio: dataVehiclesG[0]?.servicio,
        descripcionTipoVehiculo: dataVehiclesG[0]?.descripcionTipoVehiculo,
        numeroPisos: dataVehiclesG[0]?.numeroPisos,
        capacidadCarga: dataVehiclesG[0]?.capacidadCarga,
        descripcionTipoCarga: dataVehiclesG[0]?.descripcionTipoCarga,
        nombreCompletoPropietario: dataVehiclesG[0]?.nombreCompletoPropietario,
        tipoServicio: dataVehiclesG[0]?.tipoServicio,
      });
    }
  }, [loadingVehicles, loadingCompany]);

  const handleOpenViewDriver = (idCompany: number, companyName: string) => {
    setOpenViewDrivers({
      open: true,
      data: {
        idCompany: idCompany,
        companyName: companyName,
      },
    });
  };

  const handleCloseViewDriver = (idCompany: number, companyName: string) => {
    setOpenViewDrivers({
      open: false,
      data: {
        idCompany: idCompany,
        companyName: companyName,
      },
    });
  };

  return (
    <>
      <Loader visible={loadingCompany || loadingVehicles}>Cargando...</Loader>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <Grid container spacing={4}>
        <Grid container item sm={6}>
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.contentCard}>
              <ContentCard.Header>
                <MainTitle fontSize="h5">
                  Datos Generales de la Empresa
                </MainTitle>
              </ContentCard.Header>

              <Box mt={3}>
                <Grid container justifyContent="center">
                  <Avatar
                    src={avatar(generalData?.razonSocial)}
                    style={{ width: 80, height: 80 }}
                  />
                </Grid>
                <Grid container justifyContent="center">
                  <Box mt={2} mb={1} maxWidth="60%">
                    <Typography variant="h5" className={classes.nameInfo}>
                      {generalData?.razonSocial}
                    </Typography>
                    <Typography className={classes.ubicationInfo}>
                      Antioquia - Medellín
                    </Typography>
                  </Box>
                </Grid>
              </Box>

              <Box mt={3}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <ItemListData
                      data={[
                        {
                          title: 'Nit',
                          data: `${generalData?.nit}-${generalData?.digitoVerificacion}`,
                        },
                        {
                          title: 'Teléfono',
                          data: `${generalData?.telefono} ${
                            generalData?.extension
                              ? 'ext. ' + generalData?.extension
                              : ''
                          }`,
                        },
                        {
                          title: 'Celular',
                          data: generalData?.celular,
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={7}>
                    <ItemListData
                      data={[
                        {
                          title: 'Correo Electrónico',
                          data: generalData?.email,
                        },
                        {
                          title: 'Dirección',
                          data: generalData?.direccion,
                        },
                      ]}
                    />
                    <ul className={classes.listContainer}>
                      <li>
                        <div className={classes.itemListData}>
                          <Typography>Conductores Registrados</Typography>
                          <Button
                            size="small"
                            variant="contained"
                            className="raleway"
                            color="primary"
                            onClick={() =>
                              handleOpenViewDriver(
                                Number(id),
                                generalData?.razonSocial
                              )
                            }
                          >
                            Ver ({generalData?.totalConductores})
                          </Button>
                        </div>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Box>

              <Grid container justify="center">
                <Box mt={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/app/network/transports/forms/detail-company/${id}`}
                  >
                    Ver detalle
                  </Button>
                </Box>
              </Grid>
            </ContentCard>
          </Grid>
        </Grid>

        <Grid container item sm={6} alignItems="stretch">
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.contentCard}>
              <Box mb={3}>
                <ContentCard.Header
                  date={`${dataVehiclesG?.length ?? 0} Vehículos`}
                >
                  <MainTitle fontSize="h5">Vehículos Asociados</MainTitle>
                </ContentCard.Header>
              </Box>

              <ContentCard.Content>
                <Box mt={5} display="flex" justifyContent="center">
                  <Slider>
                    {dataVehiclesG?.map((item: any) => {
                      return (
                        <LicensePlate
                          placa={item?.placa}
                          ciudad={item?.ciudad}
                          onClick={() => handleChangeSelect(item)}
                          checked={selectedValue === item?.placa}
                          selectable
                        />
                      );
                    })}
                  </Slider>
                </Box>
              </ContentCard.Content>
            </ContentCard>
          </Grid>

          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.contentCard}>
              <ContentCard.Header>
                <Box mb={2}>
                  <MainTitle fontSize="h5">
                    Datos Generales de Vehículo:
                    <span className={classes.fontPlateColor}>
                      {dataSelect.placa}
                    </span>
                  </MainTitle>
                </Box>
              </ContentCard.Header>
              <ContentCard.Content>
                {!loadingVehicles && !errorVehicles && (
                  <>
                    <Grid container>
                      <Grid
                        item
                        container
                        xs={6}
                        justifyContent="center"
                        className={classes.gridContainer}
                      >
                        <ItemListData
                          data={[
                            {
                              title: 'Marca',
                              data: dataSelect.marca,
                            },
                            {
                              title: 'Tipo de Vehículo',
                              data: dataSelect.descripcionTipoVehiculo,
                            },
                            {
                              title: 'Modelo',
                              data: dataSelect.modelo,
                            },
                            {
                              title: 'Color',
                              data: dataSelect.color,
                            },
                            {
                              title: 'Número de Pisos',
                              data: dataSelect.numeroPisos,
                            },
                          ]}
                        />
                      </Grid>

                      <Grid
                        item
                        container
                        xs={6}
                        justifyContent="center"
                        className={classes.gridContainer}
                      >
                        <ItemListData
                          data={[
                            {
                              title: 'Servicio',
                              data: 'Privado',
                            },
                            {
                              title: 'Nro. Lincencia de Transito',
                              data: dataSelect.numeroLicenciaTransito,
                            },
                            {
                              title: 'Capacidad',
                              data:
                                dataSelect.capacidadCarga +
                                ' ' +
                                dataSelect.descripcionTipoCarga,
                            },
                            {
                              title: 'Propietario',
                              data: dataSelect.nombreCompletoPropietario,
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </ContentCard.Content>
            </ContentCard>
          </Grid>
        </Grid>
      </Grid>
      <DialogViewDrivers
        open={openViewDrivers.open}
        data={openViewDrivers.data}
        handleClose={() =>
          handleCloseViewDriver(
            openViewDrivers.data.idCompany,
            openViewDrivers.data.companyName
          )
        }
      />
    </>
  );
};
export default DetailCompany;
