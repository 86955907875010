import { gql } from "apollo-boost";

export const TYPE_SERVICE = gql`
  query typeService {
    getTypeService {
      id
      description
    }
  }
`;

export const TYPE_VEHICLE = gql`
  query getTypeVehicle {
    getTypeVehicle {
      id
      description
    }
  }
`;

export const GET_TYPE_LOAD = gql`
  query typeLoad {
    getTypeLoad {
      id
      description
      loadUnit
    }
  }
`;

export const GET_TYPE_LICENSE_CATEGORY = gql`
  query getTypeLicenseCategory {
    getTypeLicenseCategory {
      idTipoCategoria
      description
    }
  }
`;

export const GET_TOTAL_TRANSPORTS = gql`
  query getTotalTransport {
    getTotalTransport{
      data{
        transportadoras
        conductores
        vehiculos
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const GET_COMPANY_EXISTS = gql`
  query getExistCompanyConveyor($nit: String!) {
    getExistCompanyConveyor(input: {
        nit: $nit
    }){
			data{
        idEmpresaTransportadora
        razonSocial
        nit
        digitoVerificacion
        idPais
        idCiudad
        idDepartamento
        indicativoTelefono
        telefono
        extension
        indicativoCelular
        celular
        email
        idTipoVia
        numeroVia
        apendiceVia
        numeroCruce
        apendiceCruce
        metrosEsquina
        adicional
        codigoPostal
        tipoDireccion
        direccion
        fechaRegistro
        estado
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query getCompany {
    getCompanysConveyors{
      data{
        idEmpresaTransportadora
        razonSocial
        nit
        digitoVerificacion
        tipoDireccion
        idPais
        idDepartamento
        idCiudad
        ciudad
        idTipoVia
        numeroVia
        apendiceVia
        numeroCruce
        apendiceCruce
        metrosEsquina
        adicional
        direccion
        codigoPostal
        longitud
        latitud
        indicativoTelefono
        telefono
        extension
        indicativoCelular
        celular
        email
        fechaRegistro
        estado
        totalConductores
        totalVehiculos
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query ($idTransportadora: Int!) {
    getDetailCompanyConveyor(input: {
        idTransportadora: $idTransportadora
    }){
			data{
        idEmpresaTransportadora
        razonSocial
        nit
        digitoVerificacion
        idCiudad
        idDepartamento
        idPais
        idTipoVia
        numeroVia
        apendiceVia
        numeroCruce
        apendiceCruce
        metrosEsquina
        adicional
        codigoPostal
        longitud
        latitud
        tipoDireccion
        direccion
        indicativoCelular 
        celular 
        indicativoTelefono 
        telefono 
        extension  
        email 
        fechaRegistro
        estado
        totalVehiculos
        totalConductores
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const GET_DRIVERS_COMPANY = gql`
  query getDriversXCompany($idEmpresaTransportadora: Int!) {
   getDriversXCompany(input: {
    idEmpresaTransportadora: $idEmpresaTransportadora
  }) {
    data {
      idConductor
      idTipoDocumento
      numeroDocumento
      primerNombre
      segundoNombre
      primerApellido
      segundoApellido
      nombreCompleto
      numeroLicenciaConduccion
      esConductordeReemplazo
      indicativoCelular
      celular
      email
      idCiudad
      direccion
      fechaRegistro
      estado
    }
  }
}
`;

export const GET_VEHICLES_COMPANY = gql`
  query getVehiclesXCompany($idEmpresaTransportadora: Int!) {
   getVehiclesXCompany(input: {
    idEmpresaTransportadora: $idEmpresaTransportadora
  }) {
    data {
      idVehiculo
      numeroLicenciaTransito
      placa
      idPaisPlaca
      idDepartamentoPlaca
      idCiudadPlaca
      ciudad
      marca
      modelo
      color
      idTipoVehiculo
      descripcionTipoVehiculo
      servicio
      numeroPisos
      idTipoCarga
      descripcionTipoCarga
      capacidadCarga
      primerNombrePropietario
      segundoNombrePropietario
      primerApellidoPropietario
      segundoApellidoPropietario
      nombreCompletoPropietario
      estado
      fechaRegistro
    }
  }
}
`;

export const GET_RELATIONS_COMPANY = gql`
  query ($idTransportadora: Int!) {
    getDetailCompanyConveyor(input: {
        idTransportadora: $idTransportadora
    }){
			data{
        idTransportadora
        razonSocial
        nit
        conductores{
          idConductor
          nombre
          numeroDocumento
          numeroLicencia
          estado
        }
        vehiculos{
          idVehiculo
          placa
          ciudad
          tipoVehiculo
          capacidad
          estado
        }
        fechaRegistro
        estado
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const GET_DRIVERS = gql`
  query getDrivers {
    getDrivers{
			data{
        idConductor
        idTipoDocumento
        numeroDocumento
				nombreConductor
        primerNombre 
        segundoNombre 
        primerApellido 
        segundoApellido 
        nombreConductor
        indicativoCelular
        celular
        email
        numeroLicenciaConduccion
        ciudad
        direccion
        fechaRegistro
        estado
        esConductordeReemplazo
        totalVehiculos
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const GET_DRIVERS_EXISTS = gql`
  query getExitDriver ($numeroDocumento: String!) {
    getExistDriver (input: {
      numeroDocumento: $numeroDocumento
    }) {
      data {
        idConductor
        tipoDocumento
        numeroDocumento
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        indicativoTelefono
        telefono
        indicativoCelular
        celular
        correo
        numeroLicenciaConduccion
        fechaExpedicionLicencia
        categoriasLicencia{
          idCategoriaLicencia
          fechaVigencia
        }
        fechaRegistro
        estado
        esConductordeReemplazo
        tipoDireccion
        idPais
        idDepartamento
        idCiudad
        ciudad
        idTipoVia
        numeroVia
        apendiceVia
        numeroCruce
        apendiceCruce
        metrosEsquina
        adicional
        codigoPostal
        codigoQr
      }
    }
  }
`;

export const GET_UNASSOCIATED_DRIVER = gql`
  query getUnassociatedDrivers($idVehiculo: Int!) {
    getUnassociatedDrivers(input: {
      idVehiculo: $idVehiculo
    }) {
      data {
        idConductor
        nombreCompleto
        descripcionTipoDocumento
        numeroDocumento
        ciudad
        numeroLicenciaConduccion
      }
    }
  }
`;

export const GET_UNASSOCIATED_VEHICLE = gql`
  query getUnassociatedVehicles($idConductor: Int!) {
   getUnassociatedVehicles(input: {
    idConductor: $idConductor
  }) {
    data {
      idVehiculo
      placa
      ciudadPlaca
      descripcionTipoVehiculo
    	capacidadCarga
      descripcionTipoCarga
    }
  }
}
`;

export const GET_DRIVER = gql`
  query ($idConductor: Int!) {
    getDetailDriver(input: {
      idConductor: $idConductor
    }){
			data{
        idConductor
        tipoDocumento
        numeroDocumento
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        indicativoTelefono
        telefono
        indicativoCelular
        celular
        correo
        numeroLicenciaConduccion
        fechaExpedicionLicencia
        categoriasLicencia{
          idCategoriaLicencia
          fechaVigencia
        }
        fechaRegistro
        estado
        esConductordeReemplazo
        tipoDireccion
        idPais
        idDepartamento
        idCiudad
        ciudad
        idTipoVia
        numeroVia
        apendiceVia
        numeroCruce
        apendiceCruce
        metrosEsquina
        adicional
        codigoPostal
        codigoQr
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const GET_VEHICLES_DRIVER = gql`
  query getVehiclesXDriver($idConductor: Int!) {
   getVehiclesXDriver(input: {
    idConductor: $idConductor
  }) {
    data {
      idVehiculo
      numeroLicenciaTransito
      placa
      idPaisPlaca
      idDepartamentoPlaca
      idCiudadPlaca
      ciudad
      marca
      modelo
      color
      idTipoVehiculo
      descripcionTipoVehiculo
      servicio
      numeroPisos
      idTipoCarga
      descripcionTipoCarga
      capacidadCarga
      primerNombrePropietario
      segundoNombrePropietario
      primerApellidoPropietario
      segundoApellidoPropietario
      nombreCompletoPropietario
      estado
      fechaRegistro
      idServicio
      descripcionServicio
      tipoServicio
    }
  }
}
`;

export const GET_VEHICLES = gql`
  query getVehicles {
    getVehicles{
      data{
       	idVehiculo
        placa
        marca
        empresaTransportadora
        idPaisPlaca
        idDepartamentoPlaca
        idCiudadPlaca
        ciudad
        modelo
        color
        capacidadCarga
        numeroPisos
        tipoVehiculo
        tipoDocumentoPropietario
        numeroDocumentoPropietario
        nombrePropietario
        estado
        numeroLicenciaTransito
        totalConductores
      }
      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const GET_VEHICLES_EXISTS  = gql`
  query getExistVehicle($numeroPlaca: String!) {
    getExistVehicle(input: {
        numeroPlaca: $numeroPlaca
    }){
      data{
        idVehiculo
        numeroLicenciaTransito
        placa
        idPaisPlaca
        idDepartamentoPlaca
        idCiudadPlaca
        ciudad
        marca
        modelo
        color
        idTipoVehiculo
        descripcionTipoVehiculo
        servicio
        numeroPisos
        idTipoCarga
        capacidadCarga
        descripcionTipoCarga
        idTipoDocumentoPropietario
        numeroDocumentoPropietario
        primerNombrePropietario
        segundoNombrePropietario
        primerApellidoPropietario
        segundoApellidoPropietario
        idIndicativoCelularPropietario
        celularPropietario
        emailPropietario
        estado
        idTipoServicio
        idEmpresaTransportadora
        empresa
        fechaRegistro
      }

      token{
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const GET_VEHICLE = gql`
  query ($idVehiculo: Int!) {
  getDetailVehicle(input: {
    idVehiculo: $idVehiculo
  }){
    data{
      idVehiculo
      numeroLicenciaTransito
      placa
      idPaisPlaca
      idDepartamentoPlaca
      idCiudadPlaca
      ciudad
      marca
      modelo
      color
      idTipoVehiculo
      descripcionTipoVehiculo
      servicio
      numeroPisos
      idTipoCarga
      capacidadCarga
      descripcionTipoCarga
      idTipoDocumentoPropietario
      numeroDocumentoPropietario
      primerNombrePropietario
      segundoNombrePropietario
      primerApellidoPropietario
      segundoApellidoPropietario
      idIndicativoCelularPropietario
      celularPropietario
      emailPropietario
      estado
      idTipoServicio
      idEmpresaTransportadora
      empresa
      fechaRegistro
      codigoQr
    }
    token{
      tokenNuevo
      fechaExpiracion
    }
  }
}
`;

export const GET_DRIVERS_VEHICLE = gql`
  query getDriversXVehicle($idVehiculo: Int!) {
   getDriversXVehicle(input: {
    idVehiculo: $idVehiculo
  }) {
    data {
      idConductor
      idTipoDocumento
      descripcionTipoDocumento
      numeroDocumento
      primerNombre
      segundoNombre
      primerApellido
      segundoApellido
      nombreCompleto
      numeroLicenciaConduccion
      esConductordeReemplazo
      indicativoCelular
      celular
      email
      idCiudad
      direccion
      fechaRegistro
      estado
    }
  }
}
`;