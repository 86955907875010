import React from 'react';
import { useSelector } from 'react-redux';

import SharedReportsLayout from 'layouts/SharedReportsLayout';

import CardTypeReports from 'components/PageComponents/SharedReports/CardTypeReports';
import useStyles from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const Reports = () => {
  const classes = useStyles();
  const valueTab = useSelector(
    (state: any) => state.ui.sharedReports.valueRouteTab
  );
  return (
    <>
      <SharedReportsLayout>
        <TabPanel index="reports" value={valueTab}>
          <div className={classes.containerTab}>
            <CardTypeReports />
          </div>
        </TabPanel>
      </SharedReportsLayout>
    </>
  );
};

export default Reports;
