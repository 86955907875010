import React from 'react';

import ModalCR from '../modalCR';
import ModalCO from '../modalCO';
import mapState from 'lib/helpers/mapState';

const CorralMaps = () => {
  const data = {
    C10: {
      state: 0,
      results: {
        data: 24,
      },
    },
    C11: {
      state: 0,
      results: {
        data: 12,
      },
    },
    C12: {
      state: 1,
      results: {
        data: 20,
      },
    },
    C13: {
      state: 2,
      results: {
        data: 4,
      },
    },
    C14: {
      state: 3,
      results: {
        data: 45,
      },
    },
    C15: {
      state: 4,
      results: {
        data: 30,
      },
    },
  };

  const [openCO, setOpenCO] = React.useState(false);
  const handleOpenCO = () => {
    setOpenCO(true);
  };

  const handleCloseCO = () => {
    setOpenCO(false);
  };
  const [openCR, setOpenCR] = React.useState(false);
  const handleOpenCR = () => {
    setOpenCR(true);
  };

  const handleCloseCR = () => {
    setOpenCR(false);
  };

  return (
    <>
      <ModalCR openCR={openCR} closeCR={handleCloseCR} />
      <ModalCO openCO={openCO} closeCO={handleCloseCO} />

      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1216 630.4"
      >
        <g id="Group1">
          <g id="CR54">
            {data.C10.state != 0 ? (
              <g
                id="CR54-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_1990"
                  data-name="Rectangle 1990"
                  x="19.5"
                  width="53.2"
                  height="128.1"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2710" data-name="Group 2710">
                  <text
                    transform="translate(27.41 80.33)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(32.2 66.75)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2038"
                  data-name="Rectangle 2038"
                  x="19.4"
                  y="108.6"
                  width="53.3"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(31.47 121.87)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR54
                </text>
              </g>
            ) : (
              <g id="CR54-Void">
                <rect
                  id="Rectangle_1990-2"
                  data-name="Rectangle 1990"
                  x="19.5"
                  width="53.2"
                  height="128.1"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(29.76 66.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR54
                </text>
              </g>
            )}
          </g>
          <g id="CR55" onClick={handleOpenCO}>
            {data.C10.state != 0 ? (
              <g
                id="CR55-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <g id="Path_1396" data-name="Path 1396">
                  <polygon
                    points="134.3 135.5 108.7 128.1 81.1 128.1 81.1 0 134.3 0 134.3 135.5"
                    style={{ fill: mapState(data.C10.state) }}
                  />
                  <polygon
                    points="134.3 135.5 108.7 128.1 81.1 128.1 81.1 0 134.3 0 134.3 135.5"
                    style={{ fill: mapState(data.C10.state) }}
                  />
                  <polygon
                    points="134.3 135.5 108.7 128.1 81.1 128.1 81.1 0 134.3 0 134.3 135.5"
                    style={{ fill: mapState(data.C10.state) }}
                  />
                  <polygon
                    points="134.3 135.5 108.7 128.1 81.1 128.1 81.1 0 134.3 0 134.3 135.5"
                    style={{ fill: mapState(data.C10.state) }}
                  />
                  <polygon
                    points="134.3 135.5 108.7 128.1 81.1 128.1 81.1 0 134.3 0 134.3 135.5"
                    style={{ fill: mapState(data.C10.state) }}
                  />
                  <polygon
                    points="134.3 135.5 108.7 128.1 81.1 128.1 81.1 0 134.3 0 134.3 135.5"
                    style={{ fill: mapState(data.C10.state) }}
                  />
                  <polygon
                    points="134.3 135.5 108.7 128.1 81.1 128.1 81.1 0 134.3 0 134.3 135.5"
                    style={{ fill: mapState(data.C10.state) }}
                  />
                </g>
                <g id="Group_2710-2" data-name="Group 2710">
                  <text
                    transform="translate(88.99 80.33)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(93.78 66.75)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <polygon
                  id="Rectangle_2038-2"
                  data-name="Rectangle 2038"
                  points="81 108.6 134.3 108.6 134.3 135.5 108.7 128.1 81 128.1 81 108.6"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(93.02 121.87)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR55
                </text>
              </g>
            ) : (
              <g id="CR55-Void">
                <path
                  id="Path_1396-2"
                  data-name="Path 1396"
                  d="M134.3,135.5l-25.6-7.4H81.1V0h53.2Z"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(91.33 71.11)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR55
                </text>
              </g>
            )}
          </g>
          <g id="CR56">
            {data.C10.state != 0 ? (
              <g
                id="CR56-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <g id="CR56-Void">
                  <path
                    id="Path_1395"
                    data-name="Path 1395"
                    d="M195.9,150.8l-53.2-13.9V0h53.2Z"
                    style={{ fill: mapState(data.C10.state) }}
                  />
                </g>
                <g id="Group_2710-3" data-name="Group 2710">
                  <text
                    transform="translate(150.56 80.33)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(155.36 66.75)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <polygon
                  id="Rectangle_2038-3"
                  data-name="Rectangle 2038"
                  points="142.6 117.4 195.9 117.4 195.9 150.8 142.6 136.9 142.6 117.4"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(154.62 130.66)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR56
                </text>
              </g>
            ) : (
              <g id="CR56-Void-2" data-name="CR56-Void">
                <path
                  id="Path_1395-2"
                  data-name="Path 1395"
                  d="M195.9,150.8l-53.2-13.9V0h53.2Z"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(152.57 78.76)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR56
                </text>
              </g>
            )}
          </g>
          <g id="CR57">
            {data.C10.state != 0 ? (
              <g
                id="CR57-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <path
                  id="Path_1394"
                  data-name="Path 1394"
                  d="M204.2,152.3l53.2,13.8V0H204.2Z"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2710-4" data-name="Group 2710">
                  <text
                    transform="translate(212.14 80.33)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(216.94 66.75)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <polygon
                  id="Rectangle_2038-4"
                  data-name="Rectangle 2038"
                  points="204.3 132.9 257.6 132.9 257.6 166.3 204.3 152.4 204.3 132.9"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(216.32 146.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR57
                </text>
              </g>
            ) : (
              <g id="CR57-Void">
                <path
                  id="Path_1394-2"
                  data-name="Path 1394"
                  d="M204.2,152.3l53.2,13.8V0H204.2Z"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(214.49 86.41)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR57
                </text>
              </g>
            )}
          </g>
          <g id="CR58">
            {data.C10.state != 0 ? (
              <g
                id="CR58-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <g id="CR58-Void">
                  <path
                    id="Path_1393"
                    data-name="Path 1393"
                    d="M319,175.7H292.4l-26.6-7.6V0H319Z"
                    style={{ fill: mapState(data.C10.state) }}
                  />
                </g>
                <g id="Group_2710-5" data-name="Group 2710">
                  <text
                    transform="translate(273.72 80.33)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(278.52 66.75)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <polygon
                  id="Rectangle_2038-5"
                  data-name="Rectangle 2038"
                  points="265.8 148.7 319.1 148.7 319.1 175.7 292.5 175.7 265.8 168.2 265.8 148.7"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(277.78 161.92)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR58
                </text>
              </g>
            ) : (
              <g id="CR58-Void-2" data-name="CR58-Void">
                <path
                  id="Path_1393-2"
                  data-name="Path 1393"
                  d="M319,175.7H292.4l-26.6-7.6V0H319Z"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(276.07 91.2)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR58
                </text>
              </g>
            )}
          </g>
        </g>
        <g id="Group2">
          <g id="CR23">
            {data.C10.state != 0 ? (
              <g
                id="CR23-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003"
                  data-name="Rectangle 2003"
                  x="362.3"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717" data-name="Group 2717">
                  <text
                    transform="translate(370.18 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(374.98 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043"
                  data-name="Rectangle 2043"
                  x="362.3"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(374.24 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR23
                </text>
              </g>
            ) : (
              <g id="CR23-Void">
                <rect
                  id="Rectangle_2019"
                  data-name="Rectangle 2019"
                  x="362.3"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(372.53 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR23
                </text>
              </g>
            )}
          </g>
          <g id="CR24">
            {data.C10.state != 0 ? (
              <g
                id="CR24-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-2"
                  data-name="Rectangle 2003"
                  x="423.8"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-2" data-name="Group 2717">
                  <text
                    transform="translate(431.76 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(436.56 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-2"
                  data-name="Rectangle 2043"
                  x="423.9"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(435.82 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR24
                </text>
              </g>
            ) : (
              <g id="CR24-Void">
                <rect
                  id="Rectangle_2017"
                  data-name="Rectangle 2017"
                  x="423.8"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(434.11 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR24
                </text>
              </g>
            )}
          </g>
          <g id="CR25">
            {data.C10.state != 0 ? (
              <g
                id="CR25-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-3"
                  data-name="Rectangle 2003"
                  x="485.4"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-3" data-name="Group 2717">
                  <text
                    transform="translate(493.34 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(498.13 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-3"
                  data-name="Rectangle 2043"
                  x="485.5"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(497.4 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR25
                </text>
              </g>
            ) : (
              <g id="CR25-Void">
                <rect
                  id="Rectangle_2015"
                  data-name="Rectangle 2015"
                  x="485.4"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(499.59 44.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR25
                </text>
              </g>
            )}
          </g>
          <g id="CR26">
            {data.C10.state != 0 ? (
              <g
                id="CR26-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-4"
                  data-name="Rectangle 2003"
                  x={547}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-4" data-name="Group 2717">
                  <text
                    transform="translate(554.92 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(559.71 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-4"
                  data-name="Rectangle 2043"
                  x={547}
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(558.98 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR26
                </text>
              </g>
            ) : (
              <g id="CR26-Void">
                <rect
                  id="Rectangle_2013"
                  data-name="Rectangle 2013"
                  x={547}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(557.26 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR26
                </text>
              </g>
            )}
          </g>
          <g id="CR27">
            {data.C10.state != 0 ? (
              <g
                id="CR27-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-5"
                  data-name="Rectangle 2003"
                  x="608.6"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-5" data-name="Group 2717">
                  <text
                    transform="translate(616.49 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(621.29 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-5"
                  data-name="Rectangle 2043"
                  x="608.6"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(620.55 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR27
                </text>
              </g>
            ) : (
              <g id="CR27-Void">
                <rect
                  id="Rectangle_2011"
                  data-name="Rectangle 2011"
                  x="608.6"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(618.84 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR27
                </text>
              </g>
            )}
          </g>
          <g id="CR28">
            {data.C10.state != 0 ? (
              <g
                id="CR28-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-6"
                  data-name="Rectangle 2003"
                  x="670.2"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-6" data-name="Group 2717">
                  <text
                    transform="translate(678.07 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(682.87 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-6"
                  data-name="Rectangle 2043"
                  x="670.2"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(682.13 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR28
                </text>
              </g>
            ) : (
              <g id="CR28-Void">
                <rect
                  id="Rectangle_2009"
                  data-name="Rectangle 2009"
                  x="670.2"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(680.42 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR28
                </text>
              </g>
            )}
          </g>
          <g id="CR29">
            {data.C10.state != 0 ? (
              <g
                id="CR29-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-7"
                  data-name="Rectangle 2003"
                  x="731.7"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-7" data-name="Group 2717">
                  <text
                    transform="translate(739.65 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(744.44 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-7"
                  data-name="Rectangle 2043"
                  x="731.8"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(743.71 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR29
                </text>
              </g>
            ) : (
              <g id="CR29-Void">
                <rect
                  id="Rectangle_2007"
                  data-name="Rectangle 2007"
                  x="731.7"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(742 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR29
                </text>
              </g>
            )}
          </g>
          <g id="CR30">
            {data.C15.state != 0 ? (
              <g
                id="CR30-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2005"
                  data-name="Rectangle 2005"
                  x="793.3"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C15.state) }}
                />
                <rect
                  id="Rectangle_2042"
                  data-name="Rectangle 2042"
                  x="793.3"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(804.51 76.44)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR30
                </text>
                <text
                  transform="translate(799.47 46.17)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '9px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Regular, Roboto',
                  }}
                >
                  Animales
                </text>
                <text
                  transform="translate(805.23 32.59)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '25px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  {data.C15.results.data < 10
                    ? `0${data.C15.results.data}`
                    : data.C15.results.data}
                </text>
              </g>
            ) : (
              <g id="CR30-Void">
                <rect
                  id="Rectangle_2001"
                  data-name="Rectangle 2001"
                  x="793.3"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(803.58 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR30
                </text>
              </g>
            )}
          </g>
          <g id="CR31">
            {data.C11.state != 0 ? (
              <g
                id="CR31-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-8"
                  data-name="Rectangle 2003"
                  x="854.9"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C11.state) }}
                />
                <g id="Group_2717-8" data-name="Group 2717">
                  <text
                    transform="translate(862.8 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(867.6 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C11.results.data < 10
                      ? `0${data.C11.results.data}`
                      : data.C11.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-8"
                  data-name="Rectangle 2043"
                  x="854.9"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(866.86 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR31
                </text>
              </g>
            ) : (
              <g id="CR31-Void">
                <rect
                  id="Rectangle_2001-2"
                  data-name="Rectangle 2001"
                  x="854.9"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(865.15 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR32
                </text>
              </g>
            )}
          </g>
          <g id="CR32">
            {data.C10.state != 0 ? (
              <g
                id="CR32-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-9"
                  data-name="Rectangle 2003"
                  x="916.5"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-9" data-name="Group 2717">
                  <text
                    transform="translate(924.38 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(929.18 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-9"
                  data-name="Rectangle 2043"
                  x="916.5"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(928.44 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR32
                </text>
              </g>
            ) : (
              <g id="CR32-Void">
                <rect
                  id="Rectangle_2001-3"
                  data-name="Rectangle 2001"
                  x="916.5"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(926.73 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR32
                </text>
              </g>
            )}
          </g>
          <g id="CR33">
            {data.C10.state != 0 ? (
              <g
                id="CR33-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-10"
                  data-name="Rectangle 2003"
                  x={978}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-10" data-name="Group 2717">
                  <text
                    transform="translate(985.96 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(990.75 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-10"
                  data-name="Rectangle 2043"
                  x="978.1"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(990.02 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR33
                </text>
              </g>
            ) : (
              <g id="CR33-Void">
                <rect
                  id="Rectangle_1999"
                  data-name="Rectangle 1999"
                  x={978}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(988.31 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR33
                </text>
              </g>
            )}
          </g>
          <g id="CR34">
            {data.C10.state != 0 ? (
              <g
                id="CR34-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-11"
                  data-name="Rectangle 2003"
                  x="1039.6"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-11" data-name="Group 2717">
                  <text
                    transform="translate(1047.54 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(1052.33 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-11"
                  data-name="Rectangle 2043"
                  x="1039.7"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(1051.6 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR34
                </text>
              </g>
            ) : (
              <g id="CR34-Void">
                <rect
                  id="Rectangle_1997"
                  data-name="Rectangle 1997"
                  x="1039.6"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(1049.89 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR34
                </text>
              </g>
            )}
          </g>
          <g id="CR35">
            {data.C10.state != 0 ? (
              <g
                id="CR35-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-12"
                  data-name="Rectangle 2003"
                  x="1101.2"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-12" data-name="Group 2717">
                  <text
                    transform="translate(1109.11 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(1113.91 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-12"
                  data-name="Rectangle 2043"
                  x="1101.2"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(1113.17 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR35
                </text>
              </g>
            ) : (
              <g id="CR35-Void">
                <rect
                  id="Rectangle_1995"
                  data-name="Rectangle 1995"
                  x="1101.2"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(1111.47 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR35
                </text>
              </g>
            )}
          </g>
          <g id="CR36">
            {data.C10.state != 0 ? (
              <g
                id="CR36-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-13"
                  data-name="Rectangle 2003"
                  x="1162.8"
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-13" data-name="Group 2717">
                  <text
                    transform="translate(1170.69 46.17)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(1175.49 32.59)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-13"
                  data-name="Rectangle 2043"
                  x="1162.8"
                  y="64.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(1174.75 77.42)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR36
                </text>
              </g>
            ) : (
              <g id="CR36-Void">
                <rect
                  id="Rectangle_1993"
                  data-name="Rectangle 1993"
                  x="1162.8"
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(1173.05 45.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR36
                </text>
              </g>
            )}
          </g>
        </g>
        <g id="Group2.2">
          <g id="CR37">
            {data.C10.state != 0 ? (
              <g
                id="CR37-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-14"
                  data-name="Rectangle 2003"
                  x="362.3"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-14" data-name="Group 2717">
                  <text
                    transform="translate(370.18 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(374.98 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-14"
                  data-name="Rectangle 2043"
                  x="362.3"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(374.24 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR37
                </text>
              </g>
            ) : (
              <g id="CR37-Void">
                <rect
                  id="Rectangle_2020"
                  data-name="Rectangle 2020"
                  x="362.3"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(372.53 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR37
                </text>
              </g>
            )}
          </g>
          <g id="CR38">
            {data.C10.state != 0 ? (
              <g
                id="CR38-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-15"
                  data-name="Rectangle 2003"
                  x="423.8"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-15" data-name="Group 2717">
                  <text
                    transform="translate(431.76 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(436.56 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-15"
                  data-name="Rectangle 2043"
                  x="423.9"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(435.82 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR38
                </text>
              </g>
            ) : (
              <g id="CR38-Void">
                <rect
                  id="Rectangle_2018"
                  data-name="Rectangle 2018"
                  x="423.8"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(434.11 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR38
                </text>
              </g>
            )}
          </g>
          <g id="CR39">
            {data.C10.state != 0 ? (
              <g
                id="CR39-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-16"
                  data-name="Rectangle 2003"
                  x="485.4"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-16" data-name="Group 2717">
                  <text
                    transform="translate(493.34 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(498.13 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-16"
                  data-name="Rectangle 2043"
                  x="485.5"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(497.4 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR39
                </text>
              </g>
            ) : (
              <g id="CR39-Void">
                <rect
                  id="Rectangle_2016"
                  data-name="Rectangle 2016"
                  x="485.4"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(495.68 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR39
                </text>
              </g>
            )}
          </g>
          <g id="CR40">
            {data.C10.state != 0 ? (
              <g
                id="CR40-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-17"
                  data-name="Rectangle 2003"
                  x={547}
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-17" data-name="Group 2717">
                  <text
                    transform="translate(554.92 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(559.71 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-17"
                  data-name="Rectangle 2043"
                  x={547}
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(558.98 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR40
                </text>
              </g>
            ) : (
              <g id="CR40-Void">
                <rect
                  id="Rectangle_2014"
                  data-name="Rectangle 2014"
                  x={547}
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(557.26 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR40
                </text>
              </g>
            )}
          </g>
          <g id="CR41">
            {data.C10.state != 0 ? (
              <g
                id="CR41-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-18"
                  data-name="Rectangle 2003"
                  x="608.6"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-18" data-name="Group 2717">
                  <text
                    transform="translate(616.49 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(621.29 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-18"
                  data-name="Rectangle 2043"
                  x="608.6"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(620.55 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR41
                </text>
              </g>
            ) : (
              <g id="CR41-Void">
                <rect
                  id="Rectangle_2012"
                  data-name="Rectangle 2012"
                  x="608.6"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(618.84 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR41
                </text>
              </g>
            )}
          </g>
          <g id="CR42">
            {data.C10.state != 0 ? (
              <g
                id="CR42-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-19"
                  data-name="Rectangle 2003"
                  x="670.2"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-19" data-name="Group 2717">
                  <text
                    transform="translate(678.07 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(682.87 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-19"
                  data-name="Rectangle 2043"
                  x="670.2"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(682.13 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR42
                </text>
              </g>
            ) : (
              <g id="CR42-Void">
                <rect
                  id="Rectangle_2010"
                  data-name="Rectangle 2010"
                  x="670.2"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(680.42 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR42
                </text>
              </g>
            )}
          </g>
          <g id="CR43">
            {data.C10.state != 0 ? (
              <g
                id="CR43-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-20"
                  data-name="Rectangle 2003"
                  x="731.7"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-20" data-name="Group 2717">
                  <text
                    transform="translate(739.65 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(744.44 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-20"
                  data-name="Rectangle 2043"
                  x="731.8"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(743.71 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR43
                </text>
              </g>
            ) : (
              <g id="CR43-Void">
                <rect
                  id="Rectangle_2008"
                  data-name="Rectangle 2008"
                  x="731.7"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(742 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR43
                </text>
              </g>
            )}
          </g>
          <g id="CR44">
            {data.C10.state != 0 ? (
              <g
                id="CR44-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-21"
                  data-name="Rectangle 2003"
                  x="793.3"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-21" data-name="Group 2717">
                  <text
                    transform="translate(801.23 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(806.02 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-21"
                  data-name="Rectangle 2043"
                  x="793.3"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(805.29 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR44
                </text>
              </g>
            ) : (
              <g id="CR44-Void">
                <rect
                  id="Rectangle_2006"
                  data-name="Rectangle 2006"
                  x="793.3"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(803.58 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR44
                </text>
              </g>
            )}
          </g>
          <g id="CR45">
            {data.C10.state != 0 ? (
              <g
                id="CR45-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-22"
                  data-name="Rectangle 2003"
                  x="854.9"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-22" data-name="Group 2717">
                  <text
                    transform="translate(862.8 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(867.6 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-22"
                  data-name="Rectangle 2043"
                  x="854.9"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(866.86 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR45
                </text>
              </g>
            ) : (
              <g id="CR45-Void">
                <rect
                  id="Rectangle_2004"
                  data-name="Rectangle 2004"
                  x="854.9"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(865.15 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR45
                </text>
              </g>
            )}
          </g>
          <g id="CR46">
            {data.C10.state != 0 ? (
              <g
                id="CR46-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-23"
                  data-name="Rectangle 2003"
                  x="916.5"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-23" data-name="Group 2717">
                  <text
                    transform="translate(924.38 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(929.18 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-23"
                  data-name="Rectangle 2043"
                  x="916.5"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(928.44 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR46
                </text>
              </g>
            ) : (
              <g id="CR46-Void">
                <rect
                  id="Rectangle_2002"
                  data-name="Rectangle 2002"
                  x="916.5"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(926.73 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR46
                </text>
              </g>
            )}
          </g>
          <g id="CR47">
            {data.C10.state != 0 ? (
              <g
                id="CR47-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-24"
                  data-name="Rectangle 2003"
                  x={978}
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-24" data-name="Group 2717">
                  <text
                    transform="translate(985.96 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(990.75 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-24"
                  data-name="Rectangle 2043"
                  x="978.1"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(990.02 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR47
                </text>
              </g>
            ) : (
              <g id="CR47-Void">
                <rect
                  id="Rectangle_2000"
                  data-name="Rectangle 2000"
                  x={978}
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(988.31 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR47
                </text>
              </g>
            )}
          </g>
          <g id="CR48">
            {data.C10.state != 0 ? (
              <g
                id="CR48-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-25"
                  data-name="Rectangle 2003"
                  x="1039.6"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-25" data-name="Group 2717">
                  <text
                    transform="translate(1047.54 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(1052.33 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-25"
                  data-name="Rectangle 2043"
                  x="1039.7"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(1051.6 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR48
                </text>
              </g>
            ) : (
              <g id="CR48-Void">
                <rect
                  id="Rectangle_1998"
                  data-name="Rectangle 1998"
                  x="1039.6"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(1049.89 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR48
                </text>
              </g>
            )}
          </g>
          <g id="CR49">
            {data.C10.state != 0 ? (
              <g
                id="CR49-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-26"
                  data-name="Rectangle 2003"
                  x="1101.2"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-26" data-name="Group 2717">
                  <text
                    transform="translate(1109.11 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(1113.91 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-26"
                  data-name="Rectangle 2043"
                  x="1101.2"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(1113.17 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR49
                </text>
              </g>
            ) : (
              <g id="CR49-Void">
                <rect
                  id="Rectangle_1996"
                  data-name="Rectangle 1996"
                  x="1101.2"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(1111.47 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR49
                </text>
              </g>
            )}
          </g>
          <g id="CR50">
            {data.C10.state != 0 ? (
              <g
                id="CR50-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-27"
                  data-name="Rectangle 2003"
                  x="1162.8"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-27" data-name="Group 2717">
                  <text
                    transform="translate(1170.69 138.22)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(1175.49 124.63)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-27"
                  data-name="Rectangle 2043"
                  x="1162.8"
                  y="156.2"
                  width="53.2"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(1174.75 169.47)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR50
                </text>
              </g>
            ) : (
              <g id="CR50-Void">
                <rect
                  id="Rectangle_1994"
                  data-name="Rectangle 1994"
                  x="1162.8"
                  y={92}
                  width="53.2"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(1173.05 137.22)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR50
                </text>
              </g>
            )}
          </g>
        </g>
        <g id="separator">
          <rect
            id="Rectangle_1984"
            data-name="Rectangle 1984"
            x="878.3"
            y="400.2"
            width="27.9"
            height="27.9"
            style={{ fill: '#e2e2e2' }}
          />
          <rect
            id="Rectangle_1985"
            data-name="Rectangle 1985"
            x="878.3"
            y="435.1"
            width="27.9"
            height="175.7"
            style={{ fill: '#e2e2e2' }}
          />
          <rect
            id="Rectangle_2034"
            data-name="Rectangle 2034"
            x="878.3"
            y="619.2"
            width="27.9"
            height="11.2"
            style={{ fill: '#e2e2e2' }}
          />
          <rect
            id="Rectangle_2039"
            data-name="Rectangle 2039"
            x="878.2"
            y="400.3"
            width="27.9"
            height="27.9"
            style={{
              fill: '#7a7a7a',
              opacity: '0.20000000298023224',
              isolation: 'isolate',
            }}
          />
          <rect
            id="Rectangle_2035"
            data-name="Rectangle 2035"
            y="133.4"
            width="11.3"
            height="28.2"
            style={{ fill: '#e2e2e2' }}
          />
          <path
            id="Path_1392"
            data-name="Path 1392"
            d="M869.9,400.3v27.9H693V210.6H291.2L107.7,161.8H19.5V133.9h88.2l1,.3,182.5,48.5H720.9V400.3Z"
            style={{ fill: '#f2f2f2' }}
          />
          <rect
            id="Rectangle_2040"
            data-name="Rectangle 2040"
            x="878.1"
            y="435.1"
            width="27.9"
            height="175.7"
            style={{ fill: '#f2f2f2' }}
          />
          <g id="Group_2715" data-name="Group 2715">
            <g id="Group_2714" data-name="Group 2714">
              <g id="Group_2712" data-name="Group 2712">
                <path
                  id="Path_1398"
                  data-name="Path 1398"
                  d="M892.4,413.57h0Zm-.2,0h0Zm-2.5-1-.1-.1a.1.1,0,0,1,.1.1Z"
                  style={{ fill: '#383838' }}
                />
                <path
                  id="Path_1399"
                  data-name="Path 1399"
                  d="M897.7,411h-2.2a3.4,3.4,0,0,0-6.6-1.6,4.3,4.3,0,0,0,0,1.6h-2.4a.63.63,0,0,0-.6.5l-1.8,9.1a.92.92,0,0,0,.7,1.1h14.6a.9.9,0,0,0,.9-.9v-.2l-2-9.1A.56.56,0,0,0,897.7,411Zm-5.5-3.1a2.3,2.3,0,1,1-2.3,2.3h0a2.26,2.26,0,0,1,2.3-2.3Zm-5,4.2h2.2v.1h0l.1.1h0l.1.1h0l.1.1h0l.1.1h2.4c.1,0,.2-.1.3-.1h0a2.19,2.19,0,0,0,.5-.3h0c.1,0,.1-.1.2-.1h0l.1-.1h0v-.1h0c0-.1.1-.1.1-.2h2.1c.1,0,.3.1.3.2l1.6,7.5a.53.53,0,0,1-.5.7H886a.65.65,0,0,1-.6-.6v-.1l1.5-7.5c0,.3.1.2.3.2Z"
                  style={{ fill: '#383838' }}
                />
              </g>
              <g id="Group_2713" data-name="Group 2713">
                <path
                  id="Path_1400"
                  data-name="Path 1400"
                  d="M890.13,416.57l-.6.6v1.2h-.8v-3.9h.8v1.9l1.6-1.9h.9l-1.4,1.6,1.5,2.3h-.9Z"
                  style={{ fill: '#383838' }}
                />
                <path
                  id="Path_1401"
                  data-name="Path 1401"
                  d="M895.33,418.07a.86.86,0,0,1,.3.6.84.84,0,0,1-.4.7,2.66,2.66,0,0,1-1.2.3,2,2,0,0,1-1.1-.2.66.66,0,0,1-.4-.5c0-.1,0-.3.1-.3a.52.52,0,0,1,.4-.2c-.1,0-.2-.1-.3-.2a.48.48,0,0,1,0-.7c.1-.1.2-.2.3-.2a1,1,0,0,1-.5-.8.67.67,0,0,1,.4-.7,1.69,1.69,0,0,1,1-.3,1.27,1.27,0,0,1,.6.1.55.55,0,0,1,.3-.5,1.42,1.42,0,0,1,.6-.2l.1.6h-.1a.6.6,0,0,0-.4.1.22.22,0,0,0-.2.2,1,1,0,0,1,.5.8.67.67,0,0,1-.4.7,1.69,1.69,0,0,1-1,.3.75.75,0,0,1-.5-.1c-.1,0-.2.1-.2.3s0,.1.1.2a.35.35,0,0,1,.2.1h1C894.83,417.77,895.13,417.87,895.33,418.07Zm-.7.9c.2-.1.2-.2.2-.3s0-.1-.1-.2-.2-.1-.3-.1h-.8c-.3,0-.5.1-.5.3s.1.2.2.3a1.48,1.48,0,0,0,.7.1,1.27,1.27,0,0,0,.6-.1Zm-1-3a.52.52,0,0,0-.2.4.76.76,0,0,0,.2.4c.1.1.2.1.4.1a.6.6,0,0,0,.4-.1.52.52,0,0,0,.2-.4.76.76,0,0,0-.2-.4.6.6,0,0,0-.4-.1c-.2,0-.3,0-.4.1Z"
                  style={{ fill: '#383838' }}
                />
              </g>
            </g>
            <rect
              id="Rectangle_2041"
              data-name="Rectangle 2041"
              x="881.06"
              y="403.05"
              width="22.3"
              height="22.3"
              style={{ fill: 'none' }}
            />
          </g>
          <path
            id="Path_1402"
            data-name="Path 1402"
            d="M786.4,409.4l-1-1-5.4,5.4,5.4,5.4,1-1-3.7-3.7h18.9v-1.4H782.7Z"
            style={{ fill: '#383838' }}
          />
          <path
            id="Path_1403"
            data-name="Path 1403"
            d="M607.9,192.2l-1-1-5.4,5.4,5.4,5.4,1-1-3.7-3.7H623v-1.4H604.1Z"
            style={{ fill: '#383838' }}
          />
          <path
            id="Path_1404"
            data-name="Path 1404"
            d="M435.3,192.2l-1-1-5.4,5.4,5.4,5.4,1-1-3.7-3.7h18.9v-1.4H431.6Z"
            style={{ fill: '#383838' }}
          />
          <path
            id="Path_1405"
            data-name="Path 1405"
            d="M41.1,143.4l-1-1-5.4,5.4,5.4,5.4,1-1-3.7-3.7H56.3v-1.4H37.4Z"
            style={{ fill: '#383838' }}
          />
          <path
            id="Path_1406"
            data-name="Path 1406"
            d="M184,163.2l-.7-1.2-6.6,3.9,3.9,6.6,1.2-.7-2.7-4.5,18.3,4.7.3-1.4-18.3-4.7Z"
            style={{ fill: '#383838' }}
          />
          <path
            id="Path_1407"
            data-name="Path 1407"
            d="M701.5,299.5l1,1,3.7-3.7v18.9h1.4V296.8l3.7,3.7,1-1-5.4-5.4Z"
            style={{ fill: '#383838' }}
          />
          <path
            id="Path_1408"
            data-name="Path 1408"
            d="M892.2,511.7l-5.4,5.4,1,1,3.7-3.7v18.9h1.4V514.4l3.7,3.7,1-1Z"
            style={{ fill: '#383838' }}
          />
        </g>
        <g id="Group3">
          <g id="CR51">
            {data.C10.state != 0 ? (
              <g
                id="CR51-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_1987"
                  data-name="Rectangle 1987"
                  x="19.5"
                  y="395.2"
                  width="264.1"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-28" data-name="Group 2717">
                  <text
                    transform="translate(132.86 445.48)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(137.65 431.9)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-28"
                  data-name="Rectangle 2043"
                  x="19.5"
                  y="459.4"
                  width="264.1"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(136.92 472.64)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR51
                </text>
              </g>
            ) : (
              <g id="CR51-Void">
                <rect
                  id="Rectangle_1989"
                  data-name="Rectangle 1989"
                  x="19.5"
                  y="395.2"
                  width="264.1"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(135.21 440.4)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR51
                </text>
              </g>
            )}
          </g>
          <g id="CR52">
            {data.C10.state != 0 ? (
              <g
                id="CR52-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_1987-2"
                  data-name="Rectangle 1987"
                  x="19.5"
                  y="305.8"
                  width="264.1"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-29" data-name="Group 2717">
                  <text
                    transform="translate(132.86 356.05)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(137.65 342.47)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-29"
                  data-name="Rectangle 2043"
                  x="19.5"
                  y="369.9"
                  width="264.1"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(136.92 383.21)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR53
                </text>
              </g>
            ) : (
              <g id="CR52-Void">
                <rect
                  id="Rectangle_1988"
                  data-name="Rectangle 1988"
                  x="19.5"
                  y="305.8"
                  width="264.1"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(135.21 354.79)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR52
                </text>
              </g>
            )}
          </g>
          <g id="CR53">
            {data.C10.state != 0 ? (
              <g
                id="CR53-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_1987-3"
                  data-name="Rectangle 1987"
                  x="19.5"
                  y="216.3"
                  width="264.1"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-30" data-name="Group 2717">
                  <text
                    transform="translate(132.86 266.61)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(137.65 253.03)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-30"
                  data-name="Rectangle 2043"
                  x="19.5"
                  y="280.5"
                  width="264.1"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(136.92 293.77)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR53
                </text>
              </g>
            ) : (
              <g id="CR53-Void">
                <rect
                  id="Rectangle_1987-4"
                  data-name="Rectangle 1987"
                  x="19.5"
                  y="216.3"
                  width="264.1"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(135.21 261.53)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR53
                </text>
              </g>
            )}
          </g>
        </g>
        <g id="Group4">
          <g id="CR13">
            {data.C10.state != 0 ? (
              <g
                id="CR13-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037"
                  data-name="Rectangle 2037"
                  x="327.67"
                  y="217.57"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-6"
                  data-name="Rectangle 2038"
                  x="327.67"
                  y="281.67"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(354.85 294.99)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR13
                </text>
                <g id="Group_2710-6" data-name="Group 2710">
                  <text
                    transform="translate(350.79 263.73)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(355.58 250.15)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CR13-Void">
                <rect
                  id="Rectangle_2029"
                  data-name="Rectangle 2029"
                  x="327.7"
                  y="217.6"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(353.14 262.74)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR13
                </text>
              </g>
            )}
          </g>
          <g id="CR14">
            {data.C12.state != 0 ? (
              <g
                id="CR14-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-2"
                  data-name="Rectangle 2037"
                  x="418.67"
                  y="217.57"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C12.state) }}
                />
                <rect
                  id="Rectangle_2038-7"
                  data-name="Rectangle 2038"
                  x="418.67"
                  y="281.67"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(445.79 294.99)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR14
                </text>
                <g id="Group_2710-7" data-name="Group 2710">
                  <text
                    transform="translate(441.73 263.73)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(446.52 250.15)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C12.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CR14-Void">
                <rect
                  id="Rectangle_2032"
                  data-name="Rectangle 2032"
                  x="418.6"
                  y="217.6"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(444.07 262.74)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR14
                </text>
              </g>
            )}
          </g>
          <g id="CR15">
            {data.C10.state != 0 ? (
              <g
                id="CR15-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-3"
                  data-name="Rectangle 2037"
                  x="509.57"
                  y="217.57"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-8"
                  data-name="Rectangle 2038"
                  x="509.57"
                  y="281.67"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(536.73 294.99)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR15
                </text>
                <g id="Group_2710-8" data-name="Group 2710">
                  <text
                    transform="translate(532.67 263.73)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(537.46 250.15)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CR15-Void">
                <rect
                  id="Rectangle_2032-2"
                  data-name="Rectangle 2032"
                  x="509.6"
                  y="217.6"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(535.01 262.74)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR15
                </text>
              </g>
            )}
          </g>
          <g id="CR16">
            {data.C14.state != 0 ? (
              <g
                id="CR16-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-4"
                  data-name="Rectangle 2037"
                  x="600.37"
                  y="217.57"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C14.state) }}
                />
                <rect
                  id="Rectangle_2038-9"
                  data-name="Rectangle 2038"
                  x="600.37"
                  y="281.67"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(627.51 294.99)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR16
                </text>
                <g id="Group_2710-9" data-name="Group 2710">
                  <text
                    transform="translate(623.45 263.73)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(628.25 250.15)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C14.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CR16-Void">
                <rect
                  id="Rectangle_2032-3"
                  data-name="Rectangle 2032"
                  x="600.4"
                  y="217.6"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(625.8 262.74)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR16
                </text>
              </g>
            )}
          </g>
        </g>
        <g id="Group4.2">
          <g id="CR17">
            {data.C10.state != 0 ? (
              <g
                id="CR17-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-5"
                  data-name="Rectangle 2037"
                  x="327.67"
                  y="309.57"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-10"
                  data-name="Rectangle 2038"
                  x="327.67"
                  y="373.77"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(354.85 387.03)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR17
                </text>
                <g id="Group_2710-10" data-name="Group 2710">
                  <text
                    transform="translate(350.79 355.78)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(355.58 342.2)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CR17-Void">
                <rect
                  id="Rectangle_2030"
                  data-name="Rectangle 2030"
                  x="327.7"
                  y="309.6"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(353.14 354.79)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR17
                </text>
              </g>
            )}
          </g>
          <g id="CR18">
            {data.C10.state != 0 ? (
              <g
                id="CR18-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-6"
                  data-name="Rectangle 2037"
                  x="418.67"
                  y="309.57"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-11"
                  data-name="Rectangle 2038"
                  x="418.67"
                  y="373.77"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(445.79 387.03)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR18
                </text>
                <g id="Group_2710-11" data-name="Group 2710">
                  <text
                    transform="translate(441.73 355.78)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(446.52 342.2)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CR18-Void">
                <rect
                  id="Rectangle_2031"
                  data-name="Rectangle 2031"
                  x="418.6"
                  y="309.6"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(444.07 354.79)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR18
                </text>
              </g>
            )}
          </g>
          <g id="CR19">
            {data.C10.state != 0 ? (
              <g
                id="CR19-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-7"
                  data-name="Rectangle 2037"
                  x="509.57"
                  y="309.57"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-12"
                  data-name="Rectangle 2038"
                  x="509.57"
                  y="373.77"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(536.73 387.03)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR19
                </text>
                <g id="Group_2710-12" data-name="Group 2710">
                  <text
                    transform="translate(532.67 355.78)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(537.46 342.2)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CR19-Void">
                <rect
                  id="Rectangle_2033"
                  data-name="Rectangle 2033"
                  x="509.6"
                  y="309.6"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(535.01 354.79)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR19
                </text>
              </g>
            )}
          </g>
        </g>
        <g id="Group5">
          <g id="CR21">
            {data.C10.state != 0 ? (
              <g
                id="CR21-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-28"
                  data-name="Rectangle 2003"
                  x="727.8"
                  y="217.6"
                  width="58.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-31" data-name="Group 2717">
                  <text
                    transform="translate(738.38 263.73)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(743.18 250.15)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-31"
                  data-name="Rectangle 2043"
                  x="727.8"
                  y="281.7"
                  width="58.5"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(742.44 294.99)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR21
                </text>
              </g>
            ) : (
              <g id="CR21-Void">
                <rect
                  id="Rectangle_2022"
                  data-name="Rectangle 2022"
                  x="727.8"
                  y="217.6"
                  width="58.5"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(740.73 262.74)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR21
                </text>
              </g>
            )}
          </g>
          <g id="CR22">
            {data.C10.state != 0 ? (
              <g
                id="CR22-Filled"
                onClick={handleOpenCR}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2003-29"
                  data-name="Rectangle 2003"
                  x="727.8"
                  y="309.6"
                  width="58.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <g id="Group_2717-32" data-name="Group 2717">
                  <text
                    transform="translate(738.38 355.78)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(743.18 342.2)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
                <rect
                  id="Rectangle_2043-32"
                  data-name="Rectangle 2043"
                  x="727.8"
                  y="373.8"
                  width="58.5"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(742.44 387.03)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR22
                </text>
              </g>
            ) : (
              <g id="CR22-Void">
                <rect
                  id="Rectangle_2023"
                  data-name="Rectangle 2023"
                  x="727.8"
                  y="309.6"
                  width="58.5"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(740.73 354.79)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CR22
                </text>
              </g>
            )}
          </g>
        </g>
        <g id="Group6">
          <g id="CO1">
            {data.C10.state != 0 ? (
              <g
                id="CO1-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-8"
                  data-name="Rectangle 2037"
                  x="326.77"
                  y="435.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-13"
                  data-name="Rectangle 2038"
                  x="326.77"
                  y="499.27"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(356.99 512.55)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO1
                </text>
                <g id="Group_2710-13" data-name="Group 2710">
                  <text
                    transform="translate(349.81 481.3)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(354.6 467.71)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO1-Void">
                <rect
                  id="Rectangle_1986"
                  data-name="Rectangle 1986"
                  x="326.7"
                  y="435.1"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(357.95 480.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO1
                </text>
              </g>
            )}
          </g>
          <g id="CO2">
            {data.C12.state != 0 ? (
              <g
                id="CO2-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-9"
                  data-name="Rectangle 2037"
                  x="418.67"
                  y="435.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C12.state) }}
                />
                <rect
                  id="Rectangle_2038-14"
                  data-name="Rectangle 2038"
                  x="418.67"
                  y="499.27"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(448.92 512.55)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO2
                </text>
                <g id="Group_2710-14" data-name="Group 2710">
                  <text
                    transform="translate(441.73 481.3)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(446.52 467.71)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C12.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO2-Void">
                <rect
                  id="Rectangle_1986-2"
                  data-name="Rectangle 1986"
                  x="418.6"
                  y="435.1"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(449.87 480.18)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO2
                </text>
              </g>
            )}
          </g>
          <g id="CO3">
            {data.C10.state != 0 ? (
              <g
                id="CO3-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-10"
                  data-name="Rectangle 2037"
                  x="510.57"
                  y="435.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-15"
                  data-name="Rectangle 2038"
                  x="510.57"
                  y="499.27"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(540.84 512.55)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO3
                </text>
                <g id="Group_2710-15" data-name="Group 2710">
                  <text
                    transform="translate(533.65 481.3)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(538.44 467.71)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO3-Void">
                <rect
                  id="Rectangle_2026"
                  data-name="Rectangle 2026"
                  x="510.6"
                  y="435.1"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(541.8 479.3)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO3
                </text>
              </g>
            )}
          </g>
          <g id="CO4">
            {data.C10.state != 0 ? (
              <g
                id="CO4-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-11"
                  data-name="Rectangle 2037"
                  x="602.47"
                  y="435.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-16"
                  data-name="Rectangle 2038"
                  x="602.47"
                  y="499.27"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(632.76 512.55)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO4
                </text>
                <g id="Group_2710-16" data-name="Group 2710">
                  <text
                    transform="translate(625.57 481.3)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(630.37 467.71)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO4-Void">
                <rect
                  id="Rectangle_2024"
                  data-name="Rectangle 2024"
                  x="602.5"
                  y="435.1"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(633.72 479.3)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO4
                </text>
              </g>
            )}
          </g>
          <g id="CO5">
            {data.C10.state != 0 ? (
              <g
                id="CO5-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-12"
                  data-name="Rectangle 2037"
                  x="694.37"
                  y="435.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-17"
                  data-name="Rectangle 2038"
                  x="694.37"
                  y="499.27"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(724.68 512.55)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO5
                </text>
                <g id="Group_2710-17" data-name="Group 2710">
                  <text
                    transform="translate(717.49 481.3)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(722.29 467.71)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="C05-Void">
                <rect
                  id="Rectangle_2021"
                  data-name="Rectangle 2021"
                  x="694.4"
                  y="435.1"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(725.64 479.3)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO5
                </text>
              </g>
            )}
          </g>
          <g id="CO6">
            {data.C10.state != 0 ? (
              <g
                id="CO6-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-13"
                  data-name="Rectangle 2037"
                  x="786.37"
                  y="435.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-18"
                  data-name="Rectangle 2038"
                  x="786.37"
                  y="499.27"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(816.6 512.55)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO6
                </text>
                <g id="Group_2710-18" data-name="Group 2710">
                  <text
                    transform="translate(809.41 481.3)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(814.21 467.71)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO6-Void">
                <rect
                  id="Rectangle_1991"
                  data-name="Rectangle 1991"
                  x="786.3"
                  y="435.1"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(817.56 479.3)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO6
                </text>
              </g>
            )}
          </g>
        </g>
        <g id="Group6.2">
          <g id="CO7">
            {data.C10.state != 0 ? (
              <g
                id="CO7-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-14"
                  data-name="Rectangle 2037"
                  x="326.77"
                  y="527.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-19"
                  data-name="Rectangle 2038"
                  x="326.77"
                  y="591.37"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(356.99 604.59)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO7
                </text>
                <g id="Group_2710-19" data-name="Group 2710">
                  <text
                    transform="translate(349.81 573.34)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(354.6 559.76)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO7-Void">
                <rect
                  id="Rectangle_2036"
                  data-name="Rectangle 2036"
                  x="326.7"
                  y="527.2"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(357.95 571.35)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO7
                </text>
              </g>
            )}
          </g>
          <g id="CO8">
            {data.C10.state != 0 ? (
              <g
                id="CO8-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-15"
                  data-name="Rectangle 2037"
                  x="418.67"
                  y="527.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-20"
                  data-name="Rectangle 2038"
                  x="418.67"
                  y="591.37"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(448.92 604.59)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO8
                </text>
                <g id="Group_2710-20" data-name="Group 2710">
                  <text
                    transform="translate(441.73 573.34)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(446.52 559.76)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO8-Void">
                <rect
                  id="Rectangle_2028"
                  data-name="Rectangle 2028"
                  x="418.6"
                  y="527.2"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(449.87 571.35)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO8
                </text>
              </g>
            )}
          </g>
          <g id="CO9">
            {data.C10.state != 0 ? (
              <g
                id="CO9-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-16"
                  data-name="Rectangle 2037"
                  x="510.57"
                  y="527.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-21"
                  data-name="Rectangle 2038"
                  x="510.57"
                  y="591.37"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(540.84 604.59)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO9
                </text>
                <g id="Group_2710-21" data-name="Group 2710">
                  <text
                    transform="translate(533.65 573.34)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(538.44 559.76)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO9-Void">
                <rect
                  id="Rectangle_2027"
                  data-name="Rectangle 2027"
                  x="510.6"
                  y="527.2"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(541.8 571.35)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO9
                </text>
              </g>
            )}
          </g>
          <g id="CO10">
            {data.C10.state != 0 ? (
              <g
                id="CO10-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-17"
                  data-name="Rectangle 2037"
                  x="602.47"
                  y="527.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-22"
                  data-name="Rectangle 2038"
                  x="602.47"
                  y="591.37"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(629.32 604.59)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO10
                </text>
                <g id="Group_2710-22" data-name="Group 2710">
                  <text
                    transform="translate(625.57 573.34)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(630.37 559.76)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO10-Void">
                <rect
                  id="Rectangle_2025"
                  data-name="Rectangle 2025"
                  x="602.5"
                  y="527.2"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(629.32 572.35)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO10
                </text>
              </g>
            )}
          </g>
          <g id="CO11">
            {data.C13.state != 0 ? (
              <g
                id="CO11-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-18"
                  data-name="Rectangle 2037"
                  x="694.37"
                  y="527.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C13.state) }}
                />
                <rect
                  id="Rectangle_2038-23"
                  data-name="Rectangle 2038"
                  x="694.37"
                  y="591.37"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(721.24 604.59)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO11
                </text>
                <g id="Group_2710-23" data-name="Group 2710">
                  <text
                    transform="translate(717.49 573.34)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(722.29 559.76)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C13.results.data < 10
                      ? `0${data.C13.results.data}`
                      : data.C13.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO11-Void">
                <rect
                  id="Rectangle_2025-2"
                  data-name="Rectangle 2025"
                  x="694.4"
                  y="527.2"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(721.24 572.35)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO11
                </text>
              </g>
            )}
          </g>
          <g id="CO12">
            {data.C10.state != 0 ? (
              <g
                id="CO12-Filled"
                onClick={handleOpenCO}
                style={{ cursor: 'pointer' }}
              >
                <rect
                  id="Rectangle_2037-19"
                  data-name="Rectangle 2037"
                  x="786.37"
                  y="527.17"
                  width="83.6"
                  height="83.7"
                  style={{ fill: mapState(data.C10.state) }}
                />
                <rect
                  id="Rectangle_2038-24"
                  data-name="Rectangle 2038"
                  x="786.37"
                  y="591.37"
                  width="83.6"
                  height="19.5"
                  style={{
                    fill: '#fff',
                    opacity: '0.30000001192092896',
                    isolation: 'isolate',
                  }}
                />
                <text
                  transform="translate(813.16 604.59)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#fff',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO12
                </text>
                <g id="Group_2710-24" data-name="Group 2710">
                  <text
                    transform="translate(809.41 573.34)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '9px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Regular, Roboto',
                    }}
                  >
                    Animales
                  </text>
                  <text
                    transform="translate(814.21 559.76)"
                    style={{
                      isolation: 'isolate',
                      fontSize: '25px',
                      fill: '#fff',
                      fontFamily: 'Roboto-Bold, Roboto',
                      fontWeight: 700,
                    }}
                  >
                    {data.C10.results.data < 10
                      ? `0${data.C10.results.data}`
                      : data.C10.results.data}
                  </text>
                </g>
              </g>
            ) : (
              <g id="CO12-Void">
                <rect
                  id="Rectangle_1992"
                  data-name="Rectangle 1992"
                  x="786.3"
                  y="527.2"
                  width="83.6"
                  height="83.7"
                  style={{ fill: '#d6d6d6' }}
                />
                <text
                  transform="translate(813.16 572.35)"
                  style={{
                    isolation: 'isolate',
                    fontSize: '12px',
                    fill: '#383838',
                    fontFamily: 'Roboto-Bold, Roboto',
                    fontWeight: 700,
                  }}
                >
                  CO12
                </text>
              </g>
            )}
          </g>
        </g>
      </svg>
    </>
  );
};

export default CorralMaps;
