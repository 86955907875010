export { default as Main } from './Main'
import { NestedRoute } from 'lib/interfaces/routes';
export { default as Details } from './Details/index'
import AssignedDispatch from './Details/AssignedDispatch';
import DetailVehicle from './Details/DetailVehicle';
import DetailCompany from './Details/DetailCompany';
import DispatchFile from './Details/DispatchFile/index';
import Forms from './Forms/index';


export const route: NestedRoute[] = [
  {
    route: '/forms/:form/:id?/:idService?',
    Node: Forms,
    exact: true
  },
  {
    route: '/details/vehicle/:id?',
    Node: DetailVehicle,
    exact: true
  },
  {
    route: '/details/company/:id?',
    Node: DetailCompany,
    exact: true
  },
  {
    route: '/details/:id/dispatch/',
    Node: AssignedDispatch,
    exact: true
  },
  {
    route: '/details/:id/dispatch/file/:id',
    Node: DispatchFile,
    exact: true
  },
]