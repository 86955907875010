import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Tabs,
  Tab,
  TextField,
  Tooltip,
  Typography,
  Button,
  Fab,
  IconButton,
  Badge,
  CircularProgress,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import PaginationGrid from 'components/datagrid-custom-components/TableFooter/index';
import avatar from 'lib/helpers/dynamic-images/avatars';
import LicensePlate from 'components/LicensePlate';
import InformativeScreen from 'components/InformativeScreens';
import TableOverlay from 'components/datagrid-custom-components/TableOverlay';
import MainTitle from 'components/MainTitle';
import Results from 'components/Results';

import DialogAssociatedDrivers from './DialogAssociatedDrivers/index';
import DialogAssociatedVehicles from './DialogAssociatedVehicles/index';
import DialogNewRecord from './DialogNewRecord/index';

import {
  GET_COMPANIES,
  GET_DRIVERS,
  GET_VEHICLES,
} from 'core/graphql/queries/transport';
import { CHANGE_TRANSPORT_TAB } from 'core/redux/types/uiTypes';

import useStyles from './styles';

const pageBreadcrums: BreadcrumbProps[] = [
  {
    link: '/app//network/transports',
    title: 'Gestión de Transporte',
  },
];
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const Main = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const [selectedValue, setSelectedValue] = useState('active');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch();
  const [openNewTransport, setOpenNewTransport] = useState({
    open: false,
  });
  const [openDetailVehicle, setOpenDetailVehicle] = useState({
    open: false,
    data: {},
    isCompany: false,
    state: '',
  });
  const [openDetailDriver, setOpenDetailDriver] = useState({
    open: false,
    data: {},
    isCompany: false,
    state: '',
  });
  const valueTab = useSelector(
    (state: any) => state.ui.transport.valueTransportTab
  );

  function setCurrentTab(currentTab: number) {
    dispatch({
      type: CHANGE_TRANSPORT_TAB,
      payload: currentTab,
    });
  }

  const [
    getVehicles,
    { data: dataVehicle, loading: loadingVehicle, error: errorVehicle },
  ] = useLazyQuery(GET_VEHICLES, {
    fetchPolicy: 'network-only',
  });

  const [
    getDrivers,
    { data: dataDrivers, loading: loadingDrivers, error: errorDrivers },
  ] = useLazyQuery(GET_DRIVERS, {
    fetchPolicy: 'network-only',
  });

  const [
    getCompanies,
    { data: dataCompany, loading: loadingCompany, error: errorCompany },
  ] = useLazyQuery(GET_COMPANIES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    switch (valueTab) {
      case 0:
        getVehicles({
          context: { headers: { 'x-auth-jwt': token } },
        });
        break;
      case 1:
        getDrivers({
          context: { headers: { 'x-auth-jwt': token } },
        });
        break;
      case 2:
        getCompanies({
          context: { headers: { 'x-auth-jwt': token } },
        });
        break;
      default:
        break;
    }
  }, [valueTab]);

  useEffect(() => {
    if (!openDetailVehicle.open && valueTab === 0) {
      getVehicles({
        context: { headers: { 'x-auth-jwt': token } },
      });
    } else if (!openDetailDriver.open && valueTab === 1) {
      getDrivers({
        context: { headers: { 'x-auth-jwt': token } },
      });
    } else if (!openDetailVehicle.open && valueTab === 2) {
      getCompanies({
        context: { headers: { 'x-auth-jwt': token } },
      });
    }
  }, [openDetailVehicle.open, openDetailDriver.open]);

  const vehicles: any[] = [];
  dataVehicle?.getVehicles?.data.map((item: any) => {
    if (selectedValue === 'active' && item?.estado) {
      vehicles.push({
        id: item?.idVehiculo,
        placa: item?.placa,
        ciudad: item?.ciudad,
        empresa: item?.empresaTransportadora ?? 'N.D',
        conductores: item?.totalConductores,
        tipoVehiculo: item?.tipoVehiculo,
        capacidad: item?.capacidadCarga,
        estado: item?.estado,
      });
    } else if (selectedValue === 'inactive' && !item?.estado) {
      vehicles.push({
        id: item?.idVehiculo,
        placa: item?.placa,
        ciudad: item?.ciudad,
        empresa: item?.empresaTransportadora ?? 'N.D',
        conductores: item?.totalConductores,
        tipoVehiculo: item?.tipoVehiculo,
        capacidad: item?.capacidadCarga,
        estado: item?.estado,
      });
    }
  });

  const companies: any[] = [];
  dataCompany?.getCompanysConveyors?.data.map((com: any) => {
    if (selectedValue === 'active' && com?.estado) {
      companies.push({
        id: com?.idEmpresaTransportadora,
        razonSocial: com?.razonSocial,
        nit: com?.nit,
        idCiudad: com?.ciudad?.toUpperCase(),
        numeroConductores: com?.totalConductores,
        numeroVehiculos: com?.totalVehiculos,
        estado: com?.estado,
      });
    } else if (selectedValue === 'inactive' && !com?.estado) {
      companies.push({
        id: com?.idEmpresaTransportadora,
        razonSocial: com?.razonSocial,
        nit: com?.nit,
        idCiudad: com?.ciudad,
        numeroConductores: com?.totalConductores,
        numeroVehiculos: com?.totalVehiculos,
        estado: com?.estado,
      });
    }
  });

  const drivers: any[] = [];
  dataDrivers?.getDrivers?.data.map((item: any, ind: number) => {
    if (selectedValue === 'active' && item?.estado) {
      drivers.push({
        id: item?.idConductor,
        nombre: item?.nombreConductor,
        documento: item?.numeroDocumento,
        vehiculos: item?.totalVehiculos,
        licencia: item?.numeroLicenciaConduccion,
        estado: item?.estado,
      });
    } else if (selectedValue === 'inactive' && !item?.estado) {
      drivers.push({
        id: item?.idConductor,
        nombre: item?.nombreConductor,
        documento: item?.numeroDocumento,
        vehiculos: item?.totalVehiculos,
        licencia: item?.numeroLicenciaConduccion,
        estado: item?.estado,
      });
    }
  });

  const [searchFilter, setSearchFilter] = useState({
    search: '',
  });

  const filterVehicle = vehicles.filter((item) => {
    if (valueTab == 0) {
      return (
        item?.placa?.includes(searchFilter.search.toUpperCase()) ||
        item?.empresa?.includes(searchFilter.search.toUpperCase())
      );
    } else {
      return vehicles;
    }
  });

  const filterDriver = drivers.filter((item) => {
    if (valueTab == 1) {
      return (
        item?.nombre?.includes(searchFilter.search.toUpperCase()) ||
        item?.empresa?.includes(searchFilter.search.toUpperCase())
      );
    } else {
      return drivers;
    }
  });

  const filterBussines = companies?.filter((item) => {
    if (valueTab == 2) {
      return (
        item?.razonSocial?.includes(searchFilter.search.toUpperCase()) ||
        item?.nit?.includes(searchFilter.search)
      );
    } else {
      return companies;
    }
  });

  const handleInputSearch = (event: any) => {
    setSearchFilter({
      search: event.target.value,
    });
  };

  useEffect(() => {
    setSearchFilter({
      search: '',
    });
  }, [valueTab]);

  const handleNewTransport = () => {
    setOpenNewTransport({
      open: true,
    });
  };

  const handleCloseNewTransport = () => {
    setOpenNewTransport({
      open: false,
    });
  };

  const handleChangeRadio = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleOption = () => {
    handleNewTransport();
  };

  const handleOpenDetailVehicle = (
    id: any,
    placa: any,
    isCompany: boolean,
    state: string
  ) => {
    setOpenDetailVehicle({
      open: true,
      data: {
        id: id,
        placa: placa,
      },
      isCompany: isCompany,
      state: state,
    });
  };

  const handleCloseDetailVehicle = (data: any, isCompany: boolean) => {
    setOpenDetailVehicle({
      open: false,
      data: data,
      isCompany: isCompany,
      state: '',
    });
  };

  const handleOpenDetailDriver = (
    id: any,
    name: any,
    isCompany: boolean,
    state: string
  ) => {
    setOpenDetailDriver({
      open: true,
      data: {
        id: id,
        name: name,
      },
      isCompany: isCompany,
      state: state,
    });
  };

  const handleCloseDetailDriver = (data: any) => {
    setOpenDetailDriver({
      open: false,
      data: {},
      isCompany: false,
      state: '',
    });
  };

  const columnsEmpresa: GridColDef[] = [
    {
      field: 'image',
      headerName: ' ',
      cellClassName: classes.textAling,
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Avatar
            src={avatar(params.getValue(params.id, 'razonSocial') as string)}
          />
        </>
      ),
    },
    {
      field: 'razonSocial',
      headerName: 'Razón Social',
      flex: 1,
      disableColumnMenu: true,
      headerAlign: 'center',
      cellClassName: classes.capitalize,
    },
    {
      field: 'nit',
      headerName: 'NIT',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'idCiudad',
      headerName: 'Ciudad',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'numeroConductores',
      headerName: 'Conductores',
      flex: 0.9,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="Conductores Registrados" arrow>
            <IconButton
              className={classes.buttonContainer}
              onClick={() => {
                handleOpenDetailVehicle(
                  params.getValue(params.id, 'id'),
                  params.getValue(params.id, 'razonSocial'),
                  true,
                  selectedValue
                );
              }}
            >
              <Box style={{}}>
                <Badge
                  badgeContent={params.getValue(params.id, 'numeroConductores')}
                  color="primary"
                  style={{ marginTop: '40px' }}
                >
                  <Box
                    component="i"
                    className="icon-user"
                    style={{ marginTop: '-1px' }}
                    fontSize="28px"
                  />
                </Badge>
              </Box>
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: 'numeroVehiculos',
      headerName: 'Vehículos',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="Vehículos Registrados" arrow>
            <IconButton
              className={classes.buttonContainer}
              onClick={() => {
                handleOpenDetailDriver(
                  params.getValue(params.id, 'id'),
                  params.getValue(params.id, 'razonSocial'),
                  true,
                  selectedValue
                );
              }}
            >
              <Box style={{}}>
                <Badge
                  badgeContent={params.getValue(params.id, 'numeroVehiculos')}
                  color="primary"
                  style={{ marginTop: '40px' }}
                >
                  <Box
                    component="i"
                    className="icon-truck"
                    style={{ marginTop: '-1px' }}
                    fontSize="28px"
                  />
                </Badge>
              </Box>
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 0.7,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <div>
          {params.getValue(params.id, 'estado') ? (
            <div className={classes.stateItems1}></div>
          ) : (
            <div className={classes.stateItems2}></div>
          )}
        </div>
      ),
    },
    {
      field: 'ver',
      headerName: ' ',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 0.6,
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="ver deatlles" arrow>
            <Fab
              component={Link}
              to={`/app/network/transports/details/company/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.btnDetails}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Tooltip>
        </>
      ),
    },
  ];

  const columnsConductor: GridColDef[] = [
    {
      field: 'image',
      headerName: ' ',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Avatar
            src={avatar(params.getValue(params.id, 'nombre') as string)}
          />
        </>
      ),
    },
    {
      field: 'nombre',
      headerName: 'Nombre Completo',
      flex: 1.5,
      disableColumnMenu: true,
      headerAlign: 'center',
      cellClassName: classes.capitalize,
    },
    {
      field: 'documento',
      headerName: 'Documento',
      flex: 0.9,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'licencia',
      headerName: 'Nro. Licencia de Conducción',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'vehiculos',
      headerName: 'Vehículos',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="Vehículos Registrados" arrow>
            <IconButton
              className={classes.buttonContainer}
              onClick={() => {
                handleOpenDetailDriver(
                  params.getValue(params.id, 'id'),
                  params.getValue(params.id, 'nombre'),
                  false,
                  selectedValue
                );
              }}
            >
              <Box style={{}}>
                <Badge
                  badgeContent={params.getValue(params.id, 'vehiculos')}
                  color="primary"
                  style={{ marginTop: '40px' }}
                >
                  <Box
                    component="i"
                    className="icon-truck"
                    style={{ marginTop: '-1px' }}
                    fontSize="28px"
                  />
                </Badge>
              </Box>
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <div>
          {params.getValue(params.id, 'estado') ? (
            <div className={classes.stateItems1}></div>
          ) : (
            <div className={classes.stateItems2}></div>
          )}
        </div>
      ),
    },
    {
      field: 'ver',
      headerName: ' ',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 0.6,
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="ver deatlles" arrow>
            <Fab
              color="primary"
              className={classes.btnDetails}
              component={Link}
              to={`/app/network/transports/details/${params.getValue(
                params.id,
                'id'
              )}`}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Tooltip>
        </>
      ),
    },
  ];

  const columnsVehiculo: GridColDef[] = [
    {
      field: 'placa',
      headerName: 'Placa',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <div className={classes.divContainerPlate}>
          <LicensePlate
            placa={params.getValue(params.id, 'placa')?.toString() || 'ABC123'}
            ciudad=" "
            width={75}
            checked
          />
        </div>
      ),
    },
    {
      field: 'ciudad',
      headerName: 'Ciudad',
      flex: 0.8,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'empresa',
      headerName: 'Empresa',
      flex: 1,
      disableColumnMenu: true,
      headerAlign: 'center',
      cellClassName: classes.capitalize,
    },
    {
      field: 'conductores',
      headerName: 'Conductores',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="Conductores Registrados" arrow>
            <IconButton
              className={classes.buttonContainer}
              onClick={() => {
                handleOpenDetailVehicle(
                  params.getValue(params.id, 'id'),
                  params.getValue(params.id, 'placa'),
                  false,
                  selectedValue
                );
              }}
            >
              <Box style={{}}>
                <Badge
                  badgeContent={params.getValue(params.id, 'conductores')}
                  color="primary"
                  style={{ marginTop: '40px' }}
                >
                  <Box
                    component="i"
                    className="icon-user"
                    style={{ marginTop: '-1px' }}
                    fontSize="28px"
                  />
                </Badge>
              </Box>
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: 'tipoVehiculo',
      headerName: 'Tipo Vehículo',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'capacidad',
      headerName: 'Capacidad',
      flex: 0.9,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <div>
          {params.getValue(params.id, 'estado') ? (
            <div className={classes.stateItems1}></div>
          ) : (
            <div className={classes.stateItems2}></div>
          )}
        </div>
      ),
    },
    {
      field: 'ver',
      headerName: ' ',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="ver deatlles" arrow>
            <Fab
              color="primary"
              className={classes.btnDetails}
              component={Link}
              to={`/app/network/transports/details/vehicle/${params.getValue(
                params.id,
                'id'
              )}`}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Tooltip>
        </>
      ),
    },
  ];

  const customPagination = () => {
    return (
      <Box mt={3}>
        <PaginationGrid />
      </Box>
    );
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Breadcrumb breadcrumbs={pageBreadcrums} />
        </Grid>
      </Grid>

      <ContentCard>
        <ContentCard.Content>
          <Grid container>
            <Grid container item xs={6} alignItems="center">
              <MainTitle>Gestión de Transporte</MainTitle>
            </Grid>
            <Grid container item xs={6} alignItems="center" justify="flex-end">
              <Grid>
                {valueTab === 0 && (
                  <Results number={filterVehicle?.length ?? vehicles?.length} />
                )}
                {valueTab === 1 && (
                  <Results number={filterDriver?.length ?? drivers?.length} />
                )}
                {valueTab === 2 && (
                  <Results
                    number={filterBussines?.length ?? companies?.length}
                  />
                )}
              </Grid>
              <Box ml={5}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonRegister}
                  onClick={() => handleOption()}
                  startIcon={
                    <Box
                      component="i"
                      className="icon-plus"
                      fontWeight="bold"
                    />
                  }
                >
                  Registrar Transporte
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box borderBottom={1} marginTop={1} className={classes.colorHr}>
            <Grid container>
              <Grid item xs={5}>
                <Tabs
                  value={valueTab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    label="Vehículos"
                    {...a11yProps(0)}
                    className={classes.tabSmall}
                  />
                  <Tab
                    label="Conductores"
                    {...a11yProps(1)}
                    className={classes.tabSmall}
                  />
                  <Tab
                    label="Empresas Transportadoras"
                    {...a11yProps(2)}
                    className={classes.tabSmall}
                  />
                </Tabs>
              </Grid>
              <Grid container item xs={7}>
                <Grid item container alignItems="center" justify="flex-end">
                  <Box mr={5}>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      className={classes.radioGrup}
                    >
                      <FormControlLabel
                        checked={selectedValue === 'active'}
                        onChange={handleChangeRadio}
                        style={{ color: '#707070' }}
                        value="active"
                        control={<Radio color="primary" />}
                        label="Activos"
                      />

                      <FormControlLabel
                        checked={selectedValue === 'inactive'}
                        onChange={handleChangeRadio}
                        style={{ color: '#707070' }}
                        value="inactive"
                        control={<Radio color="primary" />}
                        label="Inactivos"
                      />
                    </RadioGroup>
                  </Box>
                  <Box minWidth="40%">
                    <TextField
                      label={'Búsqueda'}
                      variant="outlined"
                      fullWidth
                      onChange={handleInputSearch}
                      value={searchFilter.search}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Box
                              component="i"
                              className="icon-search"
                              color="#AFAFAF"
                              fontSize="25px"
                            />
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <TabPanel value={valueTab} index={0}>
            <Box
              height="auto"
              width="100%"
              className={
                searchFilter.search != '' && filterVehicle.length == 0
                  ? classes.heightContainer
                  : classes.colorNoRow
              }
            >
              <div style={{ height: 'auto', width: '100%' }}>
                {!loadingVehicle && vehicles.length !== 0 && (
                  <DataGrid
                    rows={
                      searchFilter.search != ''
                        ? filterVehicle
                        : vehicles.reverse()
                    }
                    columns={columnsVehiculo}
                    className={
                      searchFilter.search != '' && filterVehicle.length == 0
                        ? clsx(classes.dataGrid, classes.notDataContainer)
                        : classes.dataGrid
                    }
                    components={{
                      Footer: customPagination,
                      NoRowsOverlay: TableOverlay,
                    }}
                    hideFooter={
                      searchFilter.search != '' && filterVehicle.length == 0
                    }
                    autoHeight
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    page={page}
                    onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                    onPageChange={(e: any) => setPage(e.page)}
                    loading={loadingVehicle}
                  />
                )}
                {!loadingVehicle && !errorVehicle && vehicles.length === 0 && (
                  <Box mt={2}>
                    <InformativeScreen
                      type="notFound"
                      subtitle="¡No hay nada por aquí!"
                      info={
                        selectedValue === 'active'
                          ? 'No posee ningún registro de vehículos asociados o todos se encuentran inactivos.'
                          : 'No posee ningún registro de vehículos asociados o todos se encuentran activos.'
                      }
                      onlyError
                    />
                  </Box>
                )}
                {errorVehicle && vehicles.length === 0 && (
                  <Box mt={2}>
                    <InformativeScreen
                      type="technicalError"
                      subtitle="¡Ha ocurrido un error inesperado!"
                      info="Estamos trabajando para resolverlo."
                      onlyError
                    />
                  </Box>
                )}
                {loadingVehicle && (
                  <Box textAlign="center" marginTop={5}>
                    <CircularProgress color="primary" size={60} />
                  </Box>
                )}
              </div>
            </Box>
          </TabPanel>

          <TabPanel value={valueTab} index={1}>
            <Box
              height="auto"
              width="100%"
              className={
                searchFilter.search != '' && filterDriver.length == 0
                  ? classes.heightContainer
                  : classes.colorNoRow
              }
            >
              <div style={{ height: 'auto', width: '100%' }}>
                {!loadingDrivers && drivers.length !== 0 && (
                  <DataGrid
                    rows={
                      searchFilter.search != ''
                        ? filterDriver
                        : drivers.reverse()
                    }
                    columns={columnsConductor}
                    className={
                      searchFilter.search != '' && filterDriver.length == 0
                        ? clsx(classes.dataGrid, classes.notDataContainer)
                        : classes.dataGrid
                    }
                    components={{
                      Footer: customPagination,
                      NoRowsOverlay: TableOverlay,
                    }}
                    hideFooter={
                      searchFilter.search != '' && filterDriver.length == 0
                    }
                    autoHeight
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    page={page}
                    onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                    onPageChange={(e: any) => setPage(e.page)}
                    loading={loadingDrivers}
                  />
                )}
                {!loadingDrivers && !errorDrivers && drivers.length === 0 && (
                  <Box mt={2}>
                    <InformativeScreen
                      type="notFound"
                      subtitle="¡No hay nada por aquí!"
                      info={
                        selectedValue === 'active'
                          ? 'No posee ningún registro de conductores asociados o todos se encuentran inactivos.'
                          : 'No posee ningún registro de conductores asociados o todos se encuentran activos.'
                      }
                      onlyError
                    />
                  </Box>
                )}
                {errorDrivers && drivers.length === 0 && (
                  <Box mt={2}>
                    <InformativeScreen
                      type="technicalError"
                      subtitle="¡Ha ocurrido un error inesperado!"
                      info="Estamos trabajando para resolverlo."
                      onlyError
                    />
                  </Box>
                )}
                {loadingDrivers && (
                  <Box textAlign="center" marginTop={5}>
                    <CircularProgress color="primary" size={60} />
                  </Box>
                )}
              </div>
            </Box>
          </TabPanel>

          <TabPanel value={valueTab} index={2}>
            <Box
              height="auto"
              width="100%"
              className={
                searchFilter.search != '' && filterBussines.length == 0
                  ? classes.heightContainer
                  : classes.colorNoRow
              }
            >
              <div style={{ height: 'auto', width: '100%' }}>
                {!loadingCompany && companies.length !== 0 && (
                  <DataGrid
                    rows={
                      searchFilter.search != ''
                        ? filterBussines
                        : companies.reverse()
                    }
                    columns={columnsEmpresa}
                    className={
                      searchFilter.search != '' && filterBussines.length == 0
                        ? clsx(classes.dataGrid, classes.notDataContainer)
                        : classes.dataGrid
                    }
                    components={{
                      Footer: customPagination,
                      NoRowsOverlay: TableOverlay,
                    }}
                    hideFooter={
                      searchFilter.search != '' && filterBussines.length == 0
                    }
                    autoHeight
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    page={page}
                    onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                    onPageChange={(e: any) => setPage(e.page)}
                    loading={loadingCompany}
                  />
                )}
                {!loadingCompany && !errorCompany && companies.length === 0 && (
                  <Box mt={2}>
                    <InformativeScreen
                      type="notFound"
                      subtitle="¡No hay nada por aquí!"
                      info={
                        selectedValue === 'active'
                          ? 'No posee ningún registro de empresas transportadoras asociadas o todas se encuentran inactivas.'
                          : 'No posee ningún registro de empresas transportadoras asociadas o todas se encuentran activas.'
                      }
                      onlyError
                    />
                  </Box>
                )}
                {errorCompany && companies.length === 0 && (
                  <Box mt={2}>
                    <InformativeScreen
                      type="technicalError"
                      subtitle="¡Ha ocurrido un error inesperado!"
                      info="Estamos trabajando para resolverlo."
                      onlyError
                    />
                  </Box>
                )}
                {loadingCompany && (
                  <Box textAlign="center" marginTop={5}>
                    <CircularProgress color="primary" size={60} />
                  </Box>
                )}
              </div>
            </Box>
          </TabPanel>
        </ContentCard.Content>
      </ContentCard>

      <DialogNewRecord
        open={openNewTransport.open}
        handleClose={handleCloseNewTransport}
      />
      <DialogAssociatedDrivers
        open={openDetailVehicle.open}
        data={openDetailVehicle.data}
        state={openDetailVehicle.state}
        isCompany={openDetailVehicle.isCompany}
        handleClose={() =>
          handleCloseDetailVehicle(
            openDetailVehicle.data,
            openDetailVehicle.isCompany
          )
        }
      />
      <DialogAssociatedVehicles
        open={openDetailDriver.open}
        data={openDetailDriver.data}
        state={openDetailDriver.state}
        isCompany={openDetailDriver.isCompany}
        handleClose={() => handleCloseDetailDriver(openDetailDriver.data)}
      />
    </>
  );
};

export default Main;
