import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import ItemOutlinedInfo from './../../../../components/ItemOutlinedInfo';
import ItemTotalInfo from './../../../../components/ItemTotalInfo';
import useStyles from './styles';

type DialogCorralsProps = {
  open: boolean;
  handleClose: () => void;
};

function createData(
  pesaje: number,
  fecha: string,
  hora: string,
  animales: number,
  tipo: string,
  sexo: string,
  inmdo: string,
  pesoTotal: string,
  pesoPromedio: string
) {
  return {
    pesaje,
    fecha,
    hora,
    animales,
    tipo,
    sexo,
    inmdo,
    pesoTotal,
    pesoPromedio,
  };
}

// Datos quemados
const rows = [
  createData(
    0,
    '29/10/2019',
    '9:42.A.M',
    0,
    'N/D',
    'N/D',
    'N/D',
    '0,00',
    '0,00'
  ),
  createData(
    1,
    '29/10/2019',
    '9:42.A.M',
    28,
    'CE',
    'AMBOS',
    'SI',
    '3.080,34',
    '110,06'
  ),
  createData(
    2,
    '29/10/2019',
    '9:42.A.M',
    26,
    'CE',
    'AMBOS',
    'SI',
    '2.893,45',
    '111,23'
  ),
];

const datos2 = [
  {
    title: 'Fecha Inicio Descargue',
    data: '22/01/2019 09:23:00',
  },
  {
    title: 'Fecha Final Descargue',
    data: '22/01/2019 09:30:00',
  },
  {
    title: 'Nro. Tiquete de Báscula',
    data: '7343223',
  },
  {
    title: 'Fecha Tiquete',
    data: '22/01/2019',
  },
  {
    title: 'Nro. Animales Pesados',
    data: '54',
  },
  {
    title: 'Nro. Certificado Inmunocastración',
    data: 'N.D.',
  },
];

const DialogCorrals = ({ open, handleClose }: DialogCorralsProps) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="body"
        className={classes.borderRadiudDialog}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Grid container>
            <Grid item container xs={5} alignItems="center">
              <Avatar className={classes.avatarTitle}>
                <i className="icon-corral" style={{ fontSize: '30px' }}></i>
              </Avatar>
              <Box ml={3}>
                <Typography variant="h6">Ingreso Corrales </Typography>
              </Box>
            </Grid>

            <Grid item xs={7} container justify="flex-end">
              <Grid item container className={classes.divContainerTitle}>
                <Box mr={2}>
                  <Typography variant="h5" className={classes.dataTitle}>
                    02:31:40
                  </Typography>
                </Box>
                <Typography className={classes.fontTitleInfo}>
                  Duración Proceso
                </Typography>
              </Grid>

              <Box ml={3}>
                <Grid item container className={classes.divContainerTitle2}>
                  <Box mr={2}>
                    <Typography variant="h5" className={classes.dataTitle}>
                      0123
                    </Typography>
                  </Box>
                  <Typography className={classes.fontTitleInfo}>
                    Lote InfoPorcinos
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid container className={classes.titleContainer}>
              <Typography className={classes.fontTitleContainer}>
                Resumen del Proceso
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-calendar"
                title="Fecha Ingreso Corrales"
                info="22/01/2019 10:10:00"
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-productor"
                title="Productor"
                info="Nombre del Productor"
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-comercializador"
                title="Comercializador"
                info="Nombre Comercializador"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-corral"
                title="Nro. Corral Reposo"
                info="21-22-OBS"
                variant
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-pig"
                title="Nro Animales Recibidos"
                info="54"
                variant
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-ticket"
                title="Lote de Ceba"
                info="010"
                variant
              />
            </Grid>
          </Grid>

          <Grid container className={classes.gridContainer}>
            <Grid item container xs={5} justify="center" alignItems="center">
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={54}
                titleInfo="Total Animales Recibidos"
                avatarTotalColor="#DD9835"
                avatarTotalSize={60}
                classNameLabel={classes.itemInfoLabel}
              />
            </Grid>
            <Grid container item xs={7} direction="row">
              <Grid container className={classes.mb}>
                <Grid item xs={6}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={1}
                    titleInfo="Caídos en Transporte"
                    avatarTotalColor="#FFC676"
                    avatarTotalSize={40}
                    classNameLabel={classes.itemInfoLabelInfo}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={0}
                    titleInfo="Agitados en Transporte"
                    avatarTotalColor="#E29662"
                    avatarTotalSize={40}
                    classNameLabel={classes.itemInfoLabelInfo}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={0}
                    titleInfo="Muertos en Transporte"
                    avatarTotalColor="#F1AE6C"
                    avatarTotalSize={40}
                    classNameLabel={classes.itemInfoLabelInfo}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={1}
                    titleInfo="Muertos Desembarque"
                    avatarTotalColor="#D47D57"
                    avatarTotalSize={40}
                    classNameLabel={classes.itemInfoLabelInfo}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.titleContainer}>
            <Typography className={classes.fontTitleContainer}>
              Detalle Pesaje de Animales En Pie
            </Typography>
          </Grid>

          <Grid container>
            {datos2.map((item, index) => (
              <Grid item container xs={6}>
                <Grid
                  item
                  container
                  xs={7}
                  alignItems="center"
                  className={classes.containerTitleDetail}
                >
                  <Typography className={classes.fontTitleDetail}>
                    {item.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={5}
                  justify="flex-end"
                  alignItems="center"
                  className={classes.containerDataDetail}
                >
                  <Typography className={classes.fontDataDetail}>
                    {item.data}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableCell}>
                      Pesaje
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      Fecha
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      Hora
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      Nro. Animales
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      Tipo Cerdo
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      Sexo
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      Inmunocastrados
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      Peso Total (Kg)
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      Peso Promedio (Kg)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.pesaje}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.fecha}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.hora}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.animales}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.tipo}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.sexo}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.inmdo}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.pesoTotal}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellData}
                      >
                        {row.pesoPromedio}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Box mt={4} mb={3}>
            <Grid container>
              <Grid
                item
                container
                xs={6}
                alignItems="center"
                className={classes.gridWeightContent}
              >
                <Grid item xs={8}>
                  <ItemOutlinedInfo
                    icon="icon-weight"
                    title="Peso Total del Lote (Kg)"
                    variant
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.fontWeight}>
                    5.973,00
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={6}
                alignItems="center"
                className={classes.gridWeightContent}
              >
                <Grid item xs={8}>
                  <ItemOutlinedInfo
                    icon="icon-weight"
                    title="Peso Promedio del Lote (Kg)"
                    variant
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.fontWeight}>110,60</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              className="raleway"
              onClick={handleClose}
              color="primary"
            >
              Aceptar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogCorrals;
