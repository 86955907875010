import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import ItemOutlinedInfo from 'components/ItemOutlinedInfo';
import useStyles from './styles';

type DialogColdProps = {
  open: boolean;
  handleClose: () => void;
};

function createData(cava: string, riel: string, cantidad: string) {
  return {
    cava,
    riel,
    cantidad,
  };
}

// Datos quemados
const rows = [createData('C11', '1', '27'), createData('C12', '2', '27')];

const DialogCold = ({ open, handleClose }: DialogColdProps) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="body"
        className={classes.borderRadiudDialog}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Grid container>
            <Grid item container xs={5} alignItems="center">
              <Avatar className={classes.avatarTitle}>
                <i className="icon-cold" style={{ fontSize: '30px' }}></i>
              </Avatar>
              <Box ml={3}>
                <Typography variant="h6">Ingreso Canales a Cavas </Typography>
              </Box>
            </Grid>

            <Grid item xs={7} container justify="flex-end">
              <Grid item container className={classes.divContainerTitle}>
                <Box mr={2}>
                  <Typography variant="h5" className={classes.dataTitle}>
                    02:31:40
                  </Typography>
                </Box>
                <Typography className={classes.fontTitleInfo}>
                  Duración Proceso
                </Typography>
              </Grid>

              <Box ml={3}>
                <Grid item container className={classes.divContainerTitle2}>
                  <Box mr={2}>
                    <Typography variant="h5" className={classes.dataTitle}>
                      0123
                    </Typography>
                  </Box>
                  <Typography className={classes.fontTitleInfo}>
                    Lote InfoPorcinos
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid container className={classes.titleContainer}>
              <Typography className={classes.fontTitleContainer}>
                Resumen del Proceso
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-calendar"
                title="Fecha Ingreso a Cavas"
                info="22/01/2019 09:00:00"
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-productor"
                title="Productor"
                info="Nombre del Productor"
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
              <ItemOutlinedInfo
                icon="icon-comercializador"
                title="Comercializador"
                info="Nombre Comercializador"
              />
            </Grid>
          </Grid>

          <Grid container className={classes.gridContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
                <ItemOutlinedInfo
                  icon="icon-canales"
                  title="Nro. Canales Ingresadass"
                  info="55"
                  variant
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
                <ItemOutlinedInfo
                  icon="icon-weight"
                  title="PCC Lote Ingreso Cavas"
                  info="6.912,90"
                  variant
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.gridItemContainer}>
                <ItemOutlinedInfo
                  icon="icon-pig-search"
                  title="Temperatura Cuarto Frío"
                  info="N/D"
                  variant
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={7}>
              <Grid item container justify="center" style={{ maxWidth: '95%' }}>
                <TableContainer className={classes.tableCellHeader}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          className={classes.tableCellHead}
                        >
                          Nro. Cava
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.tableCellHead}
                        >
                          Nro. Riel
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellHead}
                        >
                          Cant.
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            className={classes.tableCellData}
                          >
                            {row.cava}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCellData}
                          >
                            {row.riel}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCellData}
                          >
                            {row.cantidad}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <Grid item xs={5} className={classes.gridContainerR}>
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                direction="column"
              >
                <Typography className={classes.fontResponsable}>
                  Responsable
                </Typography>
                <Typography className={classes.fontTitleContainer}>
                  Jose Jimenez Cadavid
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              className="raleway"
              onClick={handleClose}
              color="primary"
            >
              Aceptar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogCold;
