import React from 'react';
import { GridOverlay } from '@material-ui/data-grid';
import { Box, Grid, Typography } from '@material-ui/core';

import { ReactComponent as NotFound } from 'static/img/pigs/not-found.svg';

import useStyles from './styles';

const TableOverlay = () => {
  const classes = useStyles();
  return (
    <GridOverlay className={classes.root}>
      <Box className={classes.infoContainer}>
        <Box>
          <NotFound />
        </Box>
        <Box className={classes.info}>
          <Typography variant="h5" className={classes.subtitle}>
            ¡No encontramos resultados!
          </Typography>
          <Typography className={classes.text}>
            La búsqueda no ha arrojado ningún resultado, <br />
            intenta nuevamente más tarde.
          </Typography>
        </Box>
      </Box>
    </GridOverlay>
  );
};

export default TableOverlay;
