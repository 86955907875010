/** @format */

const whiteCorral = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAAAxCAYAAABj/A/AAAAACXBIWX
MAACxKAAAsSgF3enRNAAACqklEQVRoge2a4XGjMBCFN578P3dw7iDp4FxCSuA6cAfxdZASKMEdhH
TgdEAJpAJllEE58bySFrH6lX0zzNh4YdHTp7UQkHMutz065y7OuadC3NbN5zg55/YNc3TOuX5uUz
KudJLB/deh4YUGnRvl8EZPc46x1pCjW6pvdLFjlGVqRMkZ2tLVGDK4W2lT0jE5tCmJ6QhKUiKlI+
jSkI6YEk3jkY4sJWvoCDo2pCNIa3hydGQpkdBxhe9DAzomhhYNSpAObMsNJSU6QpHrlSlBOroGRR
zpCB0ZGz9iES/REQrcQZkSvCiuM7ZSgnSETsTOWBRxCR2lBBp0hN/Q+FpKUnRwHbJop4QOaZKtdI
QNh2cNJaXOS1IipUOLkhwdWpRIOy4usN/tldKhRUmJjhQl2fuPyk5j27yGjlTC5DS4go4UJVLj13
bYTbtpdl9Cx1ZKYkSzN1gJSiS15CSkI0XJaUdEe1rqhfKaiKiPIv4U4oMeos+lHF5n+H4QHBO35Z
2IhkL8MMd9H79jgiZBYklMTldBzLgxh/QaF3GcIT9aZgjIDAHdK57LF7RHZv+oUA9iHZl9k7AuFa
VpiDfjldn/j/nH2CIux1vCqNWyIQMyQ0CaQ8ZPcu4Uz5dS0xxGCMgMAZkhIDMEZIaAzBCQGQIyQ0
BmCEhzpuqX+Dpm/yBYylsj7kZxhGXNamkb8pz4TdMQLsebliE2ZECahExzT6E0F4cokUNlcYiUDb
lqLdIU1DSHDRmQGQIyQ0BmCMgMAXGG4MPvFpLk2Hod0uMXcdzf7lUwd+AeSK1RL5g7SJ725/Qw58
g99N7DWwmsIb/nraV+rXiNYqspq7RTeLXhrzDuXRCT0odwxru1LdPd13tVRE+Vw8AjeRHGejxPFT
m8fA7p9NznqKk/ExG9fAJqJDjsjkdbugAAAABJRU5ErkJggg==`;

export default whiteCorral;
