import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    padding: '5%',
    paddingTop: '2.5%',
    minHeight: 'calc(100vH - 142px )',
  },
}));

interface PageContainerProps {
  children: ReactNode;
  className?: string;
}

const PageContainer = ({ children, className }: PageContainerProps) => {
  const classes = useStyles();

  return <div className={clsx(classes.root, className)}>{children}</div>;
};

export default PageContainer;
