import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  },

  contentCard: {
    width: '100%',
  },

  mainTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    color: '#767676',
  },

  grayContainer: {
    background: '#F2F2F2',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
  },

  subtitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  subtitle1: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#AFAFAF',
  },

  subtitle2: {
    fontWeight: 'bold',
    fontSize: 24,
    color: '#DD9835',
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  secondTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    color: '#767676',
    padding: '10px 0px',
  },

  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  columnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  columnContainerFlexStart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  displayFlexStart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  itemContainer: {
    margin: '12px 10px',
  },

  widthContainer: {
    width: '50%',
  },
  widthContainer2: {
    width: '40%',
  },
  widthContainer3: {
    width: '60%',
  },
  widthContainer4: {
    width: '30%',
  },
  widthContainer5: {
    width: '70%',
  },

  label: {
    fontSize: 14,
    color: '#AFAFAF',
    fontWeight: 'normal',
  },
  labelBold: {
    fontSize: 14,
    color: '#AFAFAF',
    fontWeight: 'bolder',
  },

  dataGrid: {
    border: 'none',
    display: 'flex',

    '& .MuiBox-root': {
      margin: '20px 0',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '& .MuiDataGrid-columnHeaderTitle': {
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal',
      color: '#AFAFAF',
      fontWeight: 'bold',
      fontSize: 14,
    },
  },
}));

export default useStyles;
