import { Avatar, Box, Chip, Typography } from '@material-ui/core';
import React from 'react';
import ContentCard from '../ContentCard';
import useStyles from './styles';

interface AgentCardProps {
  agent?: 'comercializador' | 'productor' | string;
  icon?: string;
  withoutChip?: boolean;
  withoutAvatar?: boolean;
  chipText?: string;
  children: React.ReactNode;
}

const AgentCard = ({
  agent,
  icon,
  withoutChip = false,
  withoutAvatar = false,
  chipText = 'Agente Relacionado',
  children,
}: AgentCardProps) => {
  const classes = useStyles();
  return (
    <ContentCard padding={20} marginTop={0} style={{ height: '100%' }}>
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <Box display="flex" alignItems="center">
          {!withoutAvatar && (
            <Avatar classes={{ root: classes.agentsAvatar }}>
              <i className={`icon-${icon}`} />
            </Avatar>
          )}
          <Typography
            variant="h6"
            color="textSecondary"
            classes={{ root: classes.agentsTitle }}
          >
            {agent}
          </Typography>
        </Box>
        {!withoutChip && (
          <Chip
            color="primary"
            variant="outlined"
            label={chipText}
            size="small"
          />
        )}
      </Box>
      <ContentCard.Content>{children}</ContentCard.Content>
    </ContentCard>
  );
};

export default AgentCard;
