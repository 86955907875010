import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Chip,
} from "@material-ui/core";
import { useParams } from "react-router";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/react-hooks";

import { GET_DETAIL_CONTACT } from "core/graphql/queries/contacts";
import avatar from "lib/helpers/dynamic-images/avatars";

import ContentCard from "components/ContentCard";
import Breadcrumb, { BreadcrumbProps } from "components/BreadcrumbBuilder";
import GobackLink from "components/GobackLink";
import ItemListData from "components/ItemListData";
import Loader from "components/Loader";

import DialogDeleteContact from "./DialogStatusContact/index";
import DialogDetailFD from "./DialogDetailFD/index";

import useStyles from "./styles";

const Details = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { id } = useParams<{ id: string }>();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: `/app/network/contacts/details/${id}`,
      title: "Cliente",
    },
    {
      link: "/app/network/contacts/",
      title: "Mis Contactos",
    },
  ];

  const [value, setValue] = useState("month");

  const [openDialogConfirm, setDialogConfirm] = useState({
    open: false,
    user: "",
    idUser: "",
    action: false,
  });
  const [openFD, setOpenDialogFD] = useState({
    open: false,
    type: "",
    idUser: "",
    contactName: "",
  });

  const {
    data: dataDetailContact,
    loading: loadingDetailContact,
    error: errorDetailContact,
    refetch: refetchDetailContact,
  } = useQuery(GET_DETAIL_CONTACT, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idContacto: Number.parseInt(id) },
  });

  useEffect(() => {
    if (!openDialogConfirm.open) {
      refetchDetailContact({
        idContacto: Number.parseInt(id),
      });
    }
  }, [openDialogConfirm.open]);

  const handleOpenConfirm = (user: any, idUser: string, action: boolean) => {
    setDialogConfirm({
      open: true,
      user,
      idUser,
      action,
    });
  };

  const handleCloseConfirm = () => {
    setDialogConfirm({
      open: false,
      user: "",
      idUser: "",
      action: false,
    });
  };

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const handleOpenFD = (idUser: any, type: string, contactName: string) => {
    setOpenDialogFD({
      open: true,
      type,
      idUser,
      contactName,
    });
  };

  const handleClosenFD = () => {
    setOpenDialogFD({
      open: false,
      type: "",
      idUser: "",
      contactName: "",
    });
  };

  return (
    <>
      <Loader visible={loadingDetailContact}>Cargando...</Loader>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <Grid container spacing={4}>
        <Grid container item sm={6}>
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.contentCard}>
              <div className={classes.userNameContainer}>
                <Avatar
                  src={avatar(
                    dataDetailContact?.getDetailContact?.data?.nombre
                  )}
                  className={classes.avatarUser}
                />
                <Grid container>
                  <Grid container item xs={8}>
                    <div className={classes.containerUserInfo}>
                      <Typography variant="h5" className={classes.boldText}>
                        {dataDetailContact?.getDetailContact?.data?.nombre}
                      </Typography>
                      <Typography variant="subtitle1">
                        {dataDetailContact?.getDetailContact?.data?.usuario}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    justify="flex-end"
                    alignItems="center"
                  >
                    <div className={classes.headerContainer}>
                      <Chip
                        size="small"
                        color="primary"
                        variant="outlined"
                        label={
                          dataDetailContact?.getDetailContact?.data
                            ?.descripcionTipoUsuario
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.dataUserContainer}>
                <ItemListData
                  data={[
                    {
                      title: "Número de Documento",
                      data: dataDetailContact?.getDetailContact?.data
                        ?.numeroDocumento,
                    },
                    {
                      title: "Teléfono",
                      data: dataDetailContact?.getDetailContact?.data?.telefono,
                    },
                    {
                      title: "Correo Electrónico",
                      data: dataDetailContact?.getDetailContact?.data?.email,
                    },
                    {
                      title: "Dirección",
                      data: dataDetailContact?.getDetailContact?.data
                        ?.direccion,
                    },
                    {
                      title: "Ubicación",
                      data: dataDetailContact?.getDetailContact?.data
                        ?.ubicacion,
                    },
                    {
                      title: "Estado del Contacto",
                      data: dataDetailContact?.getDetailContact?.data
                        ?.estadoContacto
                        ? "Activo"
                        : "Inactivo",
                    },
                  ]}
                />
                <ul className={classes.listContainer}>
                  <li>
                    <div className={classes.itemListData}>
                      <Typography>
                        {dataDetailContact?.getDetailContact?.data
                          ?.idTipoUsuario === 1
                          ? "Granjas Registradas"
                          : "Destinos Registrados"}{" "}
                      </Typography>
                      <Button
                        size="small"
                        variant="contained"
                        className="raleway"
                        color="primary"
                        onClick={() =>
                          handleOpenFD(
                            dataDetailContact?.getDetailContact?.data
                              ?.idUsuarioContacto,
                            dataDetailContact?.getDetailContact?.data
                              ?.idTipoUsuario === 1
                              ? "granjas"
                              : "destinos",
                            dataDetailContact?.getDetailContact?.data?.nombre
                          )
                        }
                      >
                        Ver (
                        {dataDetailContact?.getDetailContact?.data
                          ?.idTipoUsuario === 1
                          ? dataDetailContact?.getDetailContact?.data
                              ?.totalGranjas
                          : dataDetailContact?.getDetailContact?.data
                              ?.totalDestinos}
                        )
                      </Button>
                    </div>
                  </li>
                </ul>
              </div>
              {dataDetailContact?.getDetailContact?.data?.estadoContacto && (
                <Box justifyContent="center" display="flex">
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.fontButton}
                    onClick={() =>
                      handleOpenConfirm(
                        dataDetailContact?.getDetailContact?.data?.usuario,
                        dataDetailContact?.getDetailContact?.data?.idContacto,
                        !dataDetailContact?.getDetailContact?.data
                          ?.estadoContacto
                      )
                    }
                  >
                    <Typography className={classes.fontButton}>
                      Inactivar
                    </Typography>
                  </Button>
                </Box>
              )}
            </ContentCard>
          </Grid>
        </Grid>

        <Grid container item sm={6} alignItems="stretch">
          <Grid item container xs={12} alignItems="stretch">
            <ContentCard className={classes.widthCard}>
              <ContentCard.Header date={"Noviembre"}>
                Promedio General del Cliente
              </ContentCard.Header>

              <Box mt={2} alignItems="center">
                <ContentCard.Content>
                  <Grid
                    container
                    direction="row"
                    className={classes.contetnAverage}
                  >
                    <Grid item xs={3} justify="center">
                      <RadioGroup
                        name="date"
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="today"
                          control={<Radio color="primary" size="small" />}
                          label="Hoy"
                        />
                        <FormControlLabel
                          value="month"
                          control={<Radio color="primary" size="small" />}
                          label="Este Mes"
                        />
                        <FormControlLabel
                          value="Year"
                          control={<Radio color="primary" size="small" />}
                          label="Anual"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                      <Typography className={classes.fontNumber}>04</Typography>
                      <Typography className={classes.fontAverage}>
                        Total Lotes Remisionados Cliente
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.fontNumber}>
                        200
                      </Typography>
                      <Typography className={classes.fontAverage}>
                        Total Anmales Remisionados Cliente
                      </Typography>
                    </Grid>
                  </Grid>
                </ContentCard.Content>
              </Box>
            </ContentCard>
          </Grid>

          <Grid container alignItems="stretch">
            <ContentCard className={classes.widthCard}>
              <ContentCard.Header>Términos y Condiciones</ContentCard.Header>
              <ContentCard.Content>
                <ItemListData
                  data={[
                    {
                      title: "Fecha Invitación Aceptada",
                      data: dayjs(
                        dataDetailContact?.getDetailContact?.data
                          ?.fechaInvitacionAceptada
                      ).format("DD/MM/YYYY"),
                    },
                    {
                      title: "Fecha Aceptación Términos y Condiciones",
                      data: dayjs(
                        dataDetailContact?.getDetailContact?.data
                          ?.terminosYCondiciones
                      ).format("DD/MM/YYYY"),
                    },
                    {
                      title: "Fecha Aceptación Politicas de Privacidad",
                      data: dayjs(
                        dataDetailContact?.getDetailContact?.data
                          ?.politicasPrivacidad
                      ).format("DD/MM/YYYY"),
                    },
                    {
                      title: "Fecha Aceptación Habeas Data",
                      data: dayjs(
                        dataDetailContact?.getDetailContact?.data?.habeasData
                      ).format("DD/MM/YYYY"),
                    },
                  ]}
                />
              </ContentCard.Content>
            </ContentCard>
          </Grid>
        </Grid>
      </Grid>
      <DialogDetailFD
        open={openFD.open}
        idUser={Number.parseInt(openFD.idUser)}
        type={openFD.type}
        contactName={openFD.contactName}
        handleClose={handleClosenFD}
      />
      <DialogDeleteContact
        open={openDialogConfirm.open}
        user={openDialogConfirm.user}
        idUser={openDialogConfirm.idUser}
        action={openDialogConfirm.action}
        handleClose={handleCloseConfirm}
      />
    </>
  );
};

export default Details;
