import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import avatar from 'lib/helpers/dynamic-images/avatars';

import useStyles from './styles';

interface cardProps {
  checked?: boolean;
  name: string;
  ubication?: string;
  document?: string;
  license?: string | number;
  width?: number;
  height?: number;
  onClick?: (event: any) => void;
}

const CardSelectDriver = ({
  checked,
  name,
  ubication,
  document,
  license,
  width,
  height,
  onClick,
}: cardProps) => {
  const classes = useStyles();

  return (
    <>
      <Card
        elevation={2}
        className={clsx({
          [classes.cardItem]: true,
          [classes.cardSelected]: checked,
        })}
        style={{
          width: width ?? 250,
          minWidth: width ?? 250,
          height: height ?? 288,
          minHeight: height ?? 288,
          marginRight: 10,
        }}
      >
        <CardActionArea className={classes.cardActionArea} onClick={onClick}>
          <CardContent>
            <Grid container justifyContent="center">
              <Avatar src={avatar(name)} style={{ width: 70, height: 70 }} />
            </Grid>
            <Grid container justifyContent="center">
              <Box mt={2} mb={1} maxWidth="90%">
                <Typography className={classes.nameInfo}>{name}</Typography>
                <Typography className={classes.ubicationInfo}>
                  {ubication}
                </Typography>
              </Box>
            </Grid>

            {document && (
              <Box>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.dataTitle}>
                      Documento
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.dataInfo}>
                      {document}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {license && (
              <Box mt={1}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.dataTitle}>
                      Numero Licencia de Conducción
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.dataInfo}>
                      {license}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default CardSelectDriver;
