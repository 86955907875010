import React, { useState } from 'react';

import {
  Typography,
  Grid,
  Button,
  TextField,
  CircularProgress,
  MenuItem,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
} from '@material-ui/core';

import { UNSUBSCRIBE } from 'core/graphql/mutations/profile';
import { UNSUBCRIBE_REASONS } from 'core/graphql/queries/profile';
import { useSnackbar } from 'notistack';
import useSignOut from 'lib/hooks/useSignOut';

import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import clsx from 'clsx';

import useStyles from './styles';

const Unsubscribe = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const [IdMotivo, setIdMotivo] = useState(1);
  const [open, setOpen] = useState(false);
  const { signOut } = useSignOut();

  const [setUnsubscribe, { loading: loadingUnsubscribe }] =
    useMutation(UNSUBSCRIBE);
  const { loading, data, error } = useQuery(UNSUBCRIBE_REASONS, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (values: any) => {
    setOpen(true);
    setIdMotivo(values.unsubcribeReason);
  };
  const handleUnsuscribe = () => {
    setOpen(false);
    setUnsubscribe({
      variables: { IdMotivo },
      context: { headers: { 'x-auth-jwt': token } },
    })
      .then(() => {
        enqueueSnackbar('La cuenta se ha desactivado correctamente', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        });
        signOut();
      })
      .catch(() => {
        enqueueSnackbar('No se pudo desactivar la cuenta correctamente', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      unsubcribeReason: 1,
    },
    onSubmit: (values) => {
      handleClickOpen(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography variant="h6" className={classes.title}>
            Darse de baja
          </Typography>
        </Box>
        <Divider />
        <Box marginY={2}>
          <Grid item xs={12}>
            <Typography variant="h6" color="secondary">
              Proceso de Dar de Baja.
            </Typography>
            <Typography color="secondary">
              Darse de Baja de nuestras Plataformas de Información es derecho
              otorgado a las personas por la ley 1581 de 2012, sus Decretos
              reglamentarios y normas modificatorias o complementarias. Y
              acogida por nuestra empresa MERCADEO VIRTUAL S.A. para establecer
              el proceso de registro y creación de Usuarios de nuestras
              Plataformas de Información acorde a la ley y las Buenas Prácticas
              sobre Manejo de Datos Personales.
              <br />
              <br />
              En nuestra{' '}
              <strong>
                POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE LOS DATOS PERSONALES
              </strong>{' '}
              están definidos los procesos que nuestra empresa MERCADEO VIRTUAL
              S.A. y sus Usuarios deben de seguir para cada una de sus
              actuaciones. Artículo 17 – SUPRESIÓN DE DATOS y Artículo 18 –
              REVOCATORIA DE LA AUTORIZACIÓN SOBRE TRATAMIENTO DATOS PERSONALES.
              <br />
              <br />
              El Proceso de Darse de Baja de alguna de nuestras Plataformas de
              Información implica una serie de procesos que usted debe
              considerar:
              <ol>
                <li>
                  Los Datos Personales y datos técnicos que se hayan registrado
                  en las Bases de Datos de las diferentes Plataformas de
                  Información www.infoporcinos.com y relacionadas, serán
                  marcadas y clasificadas como INACTIVO.
                </li>
                <li>
                  La condición de que los Datos Personales y Técnicos se
                  consideren INACTIVO genera que el Usuario pierda el ACCESO a
                  las Plataformas de Información www.infoporcinos.com y otras
                  relacionadas.
                </li>
                <li>
                  La Autorización realizada por el Usuario sobre el TRATAMIENTO
                  DE LOS DATOS PERSONALES será revocada, es decir, nuestra
                  empresa no continuará utilizando ningún tipo de datos
                  referente al Usuario.
                </li>
                <li>
                  Si el Usuario que procede a darse de baja y/o inicia el
                  proceso de revocatoria de la autorización del Tratamiento de
                  los datos personales, es un Usuario catalogado como
                  SUPERUSUARIO, todos los Usuarios relacionados / dependientes a
                  este, serán igualmente procesados como INACTIVO con la
                  revocatoria total de la autorización de los datos personales.
                </li>
                <li>
                  En cualquier momento o lugar, un Usuario INACTIVO podrá volver
                  a las Plataformas de Información www. Infoporcinos.com y otras
                  relacionadas, realizando nuevamente su proceso de registro.
                </li>
              </ol>
            </Typography>
          </Grid>
        </Box>
        <Box marginTop={4} marginBottom={2}>
          <Grid item xs={12}>
            <TextField
              label="Razón para desactivar la cuenta"
              size="small"
              variant="outlined"
              fullWidth
              select
              onChange={formik.handleChange}
              name="unsubcribeReason"
              helperText={formik.errors.unsubcribeReason}
              error={!!formik.errors.unsubcribeReason}
              value={formik.values.unsubcribeReason}
            >
              {loading ? (
                <CircularProgress variant="indeterminate" size={25} />
              ) : error ? (
                <p>Error</p>
              ) : (
                Array.isArray(data?.unsubscribeReason) &&
                data?.unsubscribeReason.map((data: any) => (
                  <MenuItem key={data.id} value={data.id}>
                    <Typography>{data.description}</Typography>
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>
        </Box>

        <Box marginY={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            endIcon={
              loadingUnsubscribe && (
                <CircularProgress color="primary" size={20} />
              )
            }
            disabled={loadingUnsubscribe}
          >
            Darse de baja
          </Button>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        className={classes.dialogContent}
      >
        <DialogContent>
          <i className={clsx('icon-circle-alert', classes.iconAlert)} />
          <Typography variant="h6" className={classes.titleDialog}>
            ¿Está seguro de dar de baja la cuenta?
          </Typography>
          <Box paddingTop="12px ">
            <Typography variant="subtitle1">
              Si presiona aceptar su cuenta será eliminada y no volverá a tener
              acceso a la misma. Esta acción es permanente.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUnsuscribe}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default Unsubscribe;
