// Mensajes que seran mostrados en las validaciones

export default {
  ONLY_NUMBERS: 'Sólo se permiten números',
  ONLY_CHARACTERS: 'Sólo se permiten letras',
  REQUIRED_FIELD: 'Campo requerido',
  INVALID_EMAIL_ADDRESS: 'Ingrese un correo electrónico válido',
  INVALID_URL: 'Dirección URL inválida',
  TERMS_CONDITIONS: 'Debe aceptar los Términos y Condiciones',
  PRIVACY_POLITICS: 'Debe aceptar las Políticas de Privacidad',
  HABEAS_DATA: 'Debe aceptar el acuerdo Habéas Data',
  MARKETER_TYPE: 'Debe seleccionar al menos un Tipo de Comercializador',
  PARAMETERS: 'Debe marcar al menos un parámetro',
};
