import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

interface FirstDialogProps {
  open: boolean;
  onClose?: () => void;
  onClick: () => void;
  modalTitle: string;
  icon: string;
  modalDescription: string;
  discardModal?: boolean;
}

export default function Dialogs({
  open,
  onClose,
  onClick,
  modalTitle,
  icon,
  modalDescription,
  discardModal,
}: FirstDialogProps) {
  const [openDialog, setOpenDialog] = React.useState(false);

  const onClickOpen = () => {
    setOpenDialog(true);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const classes = useStyles();

  return (
    <div>
      {discardModal ? (
        <Dialog
          open={open}
          onClose={onClose}
          className={classes.dialogContainer}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent className={classes.dialogContent}>
            <i className={clsx(icon, classes.iconModal)}></i>
            <Typography variant="h4" className={classes.titleDialog}>
              {modalTitle}
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.descriptionDialog}
            >
              {modalDescription}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.buttonsModal}>
            <Button variant="outlined" type="submit" onClick={onClose}>
              Descartar
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={onClick}
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={onClose}
          className={classes.dialogContainer}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent className={classes.dialogContent}>
            <i className={clsx(icon, classes.iconModal)}></i>
            <Typography variant="h4" className={classes.titleDialog}>
              {modalTitle}
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.descriptionDialog}
            >
              {modalDescription}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.buttonsModal}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={onClick}
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
