import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container:{
    margin: '20px 0px',
    padding: '20px 40px',
    borderRadius: 20, 
    boxShadow: theme.shadows[3], 
  },  
  titleContainer:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    marginBottom: 8, 
  }, 
  title: {
    display:'flex', 
    alignItems:'center',
    fontSize: 24,
    "& i":{
      fontSize: 30, 
      marginLeft: 4
    }
  }, 
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 0px',
    borderRadius: 20,
  },
  img: {
    width: '90%',
    borderRadius: 10,
  },
}));

export default useStyles;
