import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  containerTab:{
    padding: 12
  },
  avatar:{
    marginRight: 8,
    width: 30,
    height: 30, 
    background: '#DD9835',
  }, 
  containerCollapse:{
    padding: 0, 
    border: 'none', 
  }, 
  isOpen:{
    background: '#F7F7F7'
  }, 
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "&:hover":{
      color: '#fff'
    }
  },
}))

export default useStyles