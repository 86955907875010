import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';

import ContentCard from '../ContentCard';
import { ReactComponent as Cavas } from '../../static/img/pigs/cavas.svg';
import { ReactComponent as Corrals } from '../../static/img/pigs/corrals.svg';
import { ReactComponent as NotMessages } from '../../static/img/pigs/not-messages.svg';
import { ReactComponent as Questions } from '../../static/img/pigs/questions.svg';
import { ReactComponent as Transport } from '../../static/img/pigs/transport.svg';
import { ReactComponent as Distribution } from '../../static/img/pigs/distribution.svg';
import { ReactComponent as NotFound } from '../../static/img/pigs/not-found.svg';
import { ReactComponent as TechnicalError } from '../../static/img/pigs/technical-error.svg';

import useStyles from './styles';

interface InformativeScreensProps {
  title?: string;
  subtitle: string;
  info: string;
  type:
    | 'notFound'
    | 'cavas'
    | 'corrals'
    | 'notMessages'
    | 'technicalError'
    | 'transport'
    | 'distribution'
    | 'questions';
  button?: string;
  onClick?: (event: any) => void;
  onlyError?: boolean;
}

const InformativeScreen = ({
  title,
  subtitle,
  info,
  type,
  button,
  onClick,
  onlyError,
}: InformativeScreensProps) => {
  const classes = useStyles();

  return (
    <>
      {onlyError ? (
        <Box className={classes.infoContainer}>
          <Box>
            {type === 'cavas' ? (
              <Cavas />
            ) : type === 'corrals' ? (
              <Corrals />
            ) : type === 'notFound' ? (
              <NotFound />
            ) : type === 'notMessages' ? (
              <NotMessages />
            ) : type === 'transport' ? (
              <Transport />
            ) : type === 'questions' ? (
              <Questions />
            ) : type === 'distribution' ? (
              <Distribution />
            ) : type === 'technicalError' ? (
              <TechnicalError />
            ) : null}
          </Box>
          <Box className={classes.info}>
            <Typography variant="h5" className={classes.subtitle}>
              {subtitle}
            </Typography>
            <Typography className={classes.text}>{info}</Typography>
            {button ? (
              <Button onClick={onClick} variant="contained" color="primary">
                {button}
              </Button>
            ) : null}
          </Box>
        </Box>
      ) : (
        <Box width="100%" display="flex" justifyContent="center">
          <ContentCard className={classes.container}>
            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>
            <Box className={classes.infoContainer}>
              <Box>
                {type === 'cavas' ? (
                  <Cavas />
                ) : type === 'corrals' ? (
                  <Corrals />
                ) : type === 'notFound' ? (
                  <NotFound />
                ) : type === 'notMessages' ? (
                  <NotMessages />
                ) : type === 'transport' ? (
                  <Transport />
                ) : type === 'questions' ? (
                  <Questions />
                ) : type === 'distribution' ? (
                  <Distribution />
                ) : (
                  <TechnicalError />
                )}
              </Box>
              <Box className={classes.info}>
                <Typography variant="h5" className={classes.subtitle}>
                  {subtitle}
                </Typography>
                <Typography className={classes.text}>{info}</Typography>
                {button ? (
                  <Button onClick={onClick} variant="contained" color="primary">
                    {button}
                  </Button>
                ) : null}
              </Box>
            </Box>
          </ContentCard>
        </Box>
      )}
    </>
  );
};

export default InformativeScreen;
