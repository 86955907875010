/** @format */

const whiteEntrance = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAABRCAYAAACqj0o2AAAACXBIWX
MAACxKAAAsSgF3enRNAAAErElEQVR4nO2cT47TSBSHX49YIaF4TtDhBJ3essF9AsKOXTcrlmQuMI
QTTCM2syOwYQmcADcXIOzY4T4B6RNkVNKv0KOmquI/Pydu6X2S5SSu2JXP5XqvylaOttutGP34w/
z1xyQSMIkETCIBk0jAJBIwiQRMIgGTSMAkEjCJBEwiAZNIwCQSMIkETCIBk0jAJBIwiQRyEmciUo
vIdsRLjXoelNyNKlfB45Gd9BjfDi0yJ/E23QY8OuTB77Qo+0pENgPWpSmFiDwfQT1+0VSiE7jYQ3
3aMBqRTaPzGFqgZlT1sRSHgEkkYBIJtInObZiKSIm1IOessB6SqTpmE9aM/pUt0aUflyJyLiLXSt
oUibtLjD9GKl8HZb2IquXxL0TkRYvyNyKy6pt5MCUW+NFufRYRMENl5/ihNxB3gu3XWB/jtVvfH7
j1TpAqFTgBnegjsUCLKvF+hfUscYmsMxWdYtsUol3Zt3u4/D3nWHcS2UeiF1XhtWtRpx37GCdrid
cLtEJGcn+W2VYGl35nkX0vZ9f6Xqj+bt1zf6K6gVmHPjG1r9Q2d/LeqM86ieyb4rjWc4ZLjzWKcC
fiCgGqIO0zhWsET4Nt56pragQjT6zww5k/2PePjJa9i94iWcl2hT4xFLlAShNSBClNGWz3k63HLf
O+rvQSyUpx1khLFipAuFTmn0jlBNL8hO9SpUdztMIwh9wHXljrPpKZJ1ZoPQVk+sg3h5Q1WpXPF6
/U9omaBP6E8rMd0XUIOolkSpyjEjUkPBORu5CxgihRSbVg20TJogzDepISqdOw32BKnEBkDRkP0C
rnqkyJ966f/KzujYxBniYmcoGM4X/1HGIWp1aywhtIfnhV4nIuMfwLA8sYcCJfqnpMUjfE2BMQx6
r1lZGzVuNsOv4Vkfd4XSai+KGpmkxoMCWeQqAPIrHRwjrI/b4H6c6thCmxS2K8j2R6cIaalJVgXn
AzMmFF0L/1GqMPEVjmEPYDEdgtXyEymiLsET+p8VPV7TNy1FXXoStboqvIBzXRqpmgk2aPs5vyN0
7mw0T5867P9jAlLlV2n+OEMMXVljJIV1K4E/2l7TNILInTSCrwChH7PsbPN2rbSZ/p+A68C77yCa
OkP0XkMeZCPfdE5HWrQ7gHmhKLZrPdbmeZsoug/CJSZob9eNaZ/eWWcD9bVXYZ+fxJ8Nkqsu8C9d
FMI5+VsXo1ldiGnJzLHvvNkZIYo0jUbd7gu1GJucv5JrMtR66/G2JUct2gjOdbZozepG7R7+YkXm
a25chFtyEexmwzlR/LGjy76naVynV3/aXLrGE6chFE5tPIAf0tVh/5rgnBJUziY09AhHf1/ko0EN
cSH6n3j4OWl77COnbu4VIGfccm6D9cJ10FZZakY+9aikgg0oGvQLDRVG2OwaxsKMlRRyKcl5zq4I
dYYgFnk6hzMoDsQ2IsTYixK10aaglbW4qLtscf4tLJVbY6kEC/LCOXtqdGmtN6v0P9V9gUExE6KH
0cyUyOnzTWAWjdJ/2yP1wjYE/KEjCJBEwiAZNIwCQSMIkETCIBk0jAJBIwiQRMIgGTSMAkEjCJBE
wiAZNIwCQSMIkETGJfROQ/Nx1K4+WnIIwAAAAASUVORK5CYII=`;

export default whiteEntrance;
