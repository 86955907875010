import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { FORWARD_INVITATIONS } from 'core/graphql/queries/forwardInvitations';
import { RESEND_INVITATION } from 'core/graphql/mutations/resendInvitation';

import Results from 'components/Results';
import ContentCard from 'components/ContentCard/index';
import PaginationGrid from 'components/datagrid-custom-components/TableFooter/index';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import GobackLink from 'components/GobackLink/index';
import MainTitle from 'components/MainTitle';
import InformativeScreen from 'components/InformativeScreens';
import TableOverlay from 'components/datagrid-custom-components/TableOverlay';

import useStyles from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const ForwardingInvitations = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectionModel, setSelectionModel] = useState([]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const pageBreadcrums: BreadcrumbProps[] = [
    {
      link: '/app/network/invitations/forward-invitations',
      title: 'Reenviar Invitaciones',
    },
    {
      link: '/app/network/invitations',
      title: 'Invitaciones',
    },
  ];

  const [
    getResendInvitations,
    {
      data: dataInvitations,
      loading: loadingInvitations,
      error: errorInvitations,
    },
  ] = useLazyQuery(FORWARD_INVITATIONS, {
    fetchPolicy: 'network-only',
  });

  const invitation: any[] = [];
  dataInvitations?.forwardInvitations?.map((inv: any) => {
    invitation.push({
      id: inv?.idInvitacion,
      fechaInvitacion: new Date(inv?.fechaInvitacion).toLocaleString(),
      nombresCompletos: inv?.nombresCompletos.toLocaleLowerCase(),
      tipoDocumento: inv?.tipoDocumento,
      nroDocumento: inv?.nroDocumento,
      email: inv?.email,
      tipoUsuario: inv?.tipoUsuario,
      numeroIntentos: inv?.numeroIntentos,
      estadoInvitacion: inv?.estadoInvitacion,
      ver: 'ver',
    });
  });

  const [resendInvitation, { loading }] = useMutation(RESEND_INVITATION, {
    context: { headers: { 'x-auth-jwt': token } },
    onError(error) {
      enqueueSnackbar(error.message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'error',
      });
    },
  });

  const resend = () => {
    resendInvitation({
      update: (proxy, mutationResult) => {
        const success = mutationResult?.data?.resendInvitations?.success;
        if (success) {
          enqueueSnackbar('Invitaciones Reenviadas con Exito.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
        } else if (!success) {
          enqueueSnackbar('NO se pudo Reenviar las invitaciones', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: { ids: selectionModel },
    });
  };

  const [searchUser, setSearchUser] = useState({
    user: '',
  });

  const handleInputChange = (event: any) => {
    setSearchUser({
      user: event.target.value,
    });
  };

  const filterUser = invitation.filter(
    (user) =>
      user.nombresCompletos.includes(searchUser.user.toLocaleLowerCase()) ||
      user.nroDocumento.includes(searchUser.user)
  );

  useEffect(() => {
    if (!loading) {
      getResendInvitations({
        context: { headers: { 'x-auth-jwt': token } },
      });
    }
  }, [loading]);

  useEffect(() => {
    getResendInvitations({
      context: { headers: { 'x-auth-jwt': token } },
    });
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'fechaInvitacion',
      headerName: 'Fecha',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'nombresCompletos',
      headerName: 'Usuario Invitado',
      cellClassName: classes.fontUppercase,
      flex: 1.4,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
    },
    {
      field: 'nroDocumento',
      headerName: 'Documento',
      cellClassName: classes.fontUppercase,
      flex: 1.1,
      disableColumnMenu: true,
      sortable: false,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <>
          {params.getValue(params.id, 'tipoDocumento') === 1 && (
            <>
              <span className={classes.fontDocument}>CC: </span>
              {params.getValue(params.id, 'nroDocumento')}
            </>
          )}
          {params.getValue(params.id, 'tipoDocumento') === 2 && (
            <>
              <span className={classes.fontDocument}>CE: </span>
              {params.getValue(params.id, 'nroDocumento')}
            </>
          )}
          {params.getValue(params.id, 'tipoDocumento') === 3 && (
            <>
              <span className={classes.fontDocument}>NIT: </span>
              {params.getValue(params.id, 'nroDocumento')}
            </>
          )}
          {params.getValue(params.id, 'tipoDocumento') === 4 && (
            <>
              <span className={classes.fontDocument}>PEP: </span>
              {params.getValue(params.id, 'nroDocumento')}
            </>
          )}
        </>
      ),
    },
    {
      field: 'email',
      headerName: 'Correo Electrónico',
      flex: 1.3,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
    },
    {
      field: 'tipoUsuario',
      headerName: 'Tipo de Usuario',
      flex: 0.9,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'numeroIntentos',
      headerName: 'Invitaciones Enviadas',
      flex: 0.9,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  function CustomPagination() {
    const classes = useStyles();

    return (
      <Box mt={2}>
        <Grid container>
          <Grid
            item
            xs={6}
            alignItems="center"
            className={classes.containerFooter}
          >
            <Grid item>
              <Typography>
                {selectionModel.length} Invitaciones seleccionadas
              </Typography>
            </Grid>
            <Grid item>
              <Box ml={2}>
                <Button
                  variant="contained"
                  className={classes.fontButton}
                  color="primary"
                  disabled={selectionModel.length === 0 || loading}
                  onClick={() => resend()}
                  endIcon={
                    loading && (
                      <CircularProgress
                        className={classes.loadingColor}
                        thickness={5}
                        size={20}
                      />
                    )
                  }
                >
                  {loading
                    ? 'Reenviando Invitación...'
                    : 'Reenviar Invitaciones'}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.contentPagination}>
            <PaginationGrid />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={6}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Link to="../invitations">
            <GobackLink />
          </Link>
        </Grid>

        <Grid
          item
          xs={6}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Breadcrumb breadcrumbs={pageBreadcrums} />
        </Grid>
      </Grid>

      <ContentCard style={{ padding: 40 }}>
        <ContentCard.Content>
          <Grid container>
            <Box marginRight={4}>
              <Grid>
                <MainTitle fontSize="h6">Reenvío de Invitaciones</MainTitle>
              </Grid>
            </Box>
            <Results number={filterUser.length} />
          </Grid>

          <Box borderBottom={1} marginTop={1} className={classes.colorHr}>
            <Grid container>
              <Grid item xs={8}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    label="En espera"
                    className={classes.TabfontText}
                    {...a11yProps(0)}
                  />
                </Tabs>
              </Grid>

              <Grid
                item
                xs={4}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <TextField
                  label="Búsqueda"
                  fullWidth
                  variant="outlined"
                  name="searchUser"
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="i"
                          className="icon-search"
                          fontSize="27px"
                        />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                />
              </Grid>
            </Grid>
          </Box>

          <TabPanel value={value} index={0}>
            <Box
              height="auto"
              width="100%"
              className={
                searchUser.user != '' && filterUser.length == 0
                  ? classes.heightContainer
                  : classes.colorNoRow
              }
            >
              <div style={{ height: 'auto', width: '100%' }}>
                {!loadingInvitations && invitation.length !== 0 && (
                  <DataGrid
                    rows={searchUser.user != '' ? filterUser : invitation}
                    className={
                      searchUser.user != '' && filterUser.length == 0
                        ? clsx(classes.dataGrid, classes.notDataContainer)
                        : classes.dataGrid
                    }
                    autoHeight
                    columns={columns}
                    loading={loadingInvitations}
                    components={{
                      Footer: CustomPagination,
                      NoRowsOverlay: TableOverlay,
                    }}
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    page={page}
                    onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                    onPageChange={(e: any) => setPage(e.page)}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel: any) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    hideFooter={searchUser.user != '' && filterUser.length == 0}
                  />
                )}
                {!loadingInvitations &&
                  !errorInvitations &&
                  invitation.length === 0 && (
                    <Box marginTop={5}>
                      <InformativeScreen
                        type="notMessages"
                        subtitle="¡No hay nada por aquí!"
                        info="Esto significa que no has enviado invitaciones ó
                            las invitaciones que enviaste no han superado el
                            tiempo de espera ó todas las invitaciones que
                            enviaste ya fueron Aceptadas ó Rechazadas."
                        onlyError
                      />
                    </Box>
                  )}
                {errorInvitations && invitation.length === 0 && (
                  <Box marginTop={5}>
                    <InformativeScreen
                      type="technicalError"
                      subtitle="¡Ha ocurrido un error inesperado!"
                      info="Estamos trabajando para resolverlo."
                      onlyError
                    />
                  </Box>
                )}
                {loadingInvitations && (
                  <Box textAlign="center" marginTop={5}>
                    <CircularProgress color="primary" size={60} />
                  </Box>
                )}
              </div>
            </Box>
          </TabPanel>
        </ContentCard.Content>
      </ContentCard>
    </>
  );
};

export default ForwardingInvitations;
