import { SIGN_OUT } from 'core/redux/types/userTypes'
import { REPORTS_TYPE } from 'core/redux/types/dataTypes'

export default (state = {}, action: any) => {
  switch (action.type) {
    case REPORTS_TYPE:
      return {
        ...state,
        [action.payload.name]: {
          ...action.payload.data
        }
      }
    case SIGN_OUT:
      return {}
    default: return state
  }
}