import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import Chart from 'components/Chart';
import ItemListInfo from 'components/ItemListInfo';
import ItemOutlinedInfo from 'components/ItemOutlinedInfo';
import ItemTotalInfo from 'components/ItemTotalInfo';
import { ListItem } from 'material-ui';
import useStyles from './styles';

type DialogSacrificeProps = {
  open: boolean;
  handleClose: () => void;
};

function createData(quantity: number, date: string, hour: string) {
  return {
    quantity,
    date,
    hour,
  };
}

// Datos quemados
const rows = [
  createData(29, '22/01/2019', '10:00:00 AM'),
  createData(16, '22/01/2019', '11:00:00 AM'),
  createData(9, '22/01/2019', '12:00:00 PM'),
];

const datos = [
  {
    title: 'Peso Total Canal Caliente Lote (PCC - Kg)',
    data: '10.044,00',
  },
  {
    title: 'Grasa Dorsal Promedio Lote (m.m.)',
    data: '18,43',
  },
  {
    title: 'Peso Promedio Canal Caliente Lote (Kg)',
    data: '122,00',
  },
  {
    title: 'Magro Canal Caliente Promedio Lote (%)',
    data: '5.291,59',
  },
  {
    title: 'Rendimiento Canal Caliente (%) (PCC/PPB)',
    data: '0,00',
  },
  {
    title: 'Peso Total Carne Magra Canal Caliente Lote (Kg)',
    data: '53,96',
  },
  {
    title: 'Longitud Lomo Promedio Lote ( m.m)',
    data: '75,96',
  },
];

const DialogSacrifice = ({ open, handleClose }: DialogSacrificeProps) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="body"
        className={classes.borderRadiudDialog}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Grid container>
            <Grid item container xs={5} alignItems="center">
              <Avatar className={classes.avatarTitle}>
                <i className="icon-canal-sync" style={{ fontSize: '30px' }}></i>
              </Avatar>
              <Box ml={3}>
                <Typography variant="h6">Proceso de Beneficio</Typography>
              </Box>
            </Grid>

            <Grid item xs={7} container justify="flex-end">
              <Grid item container className={classes.divContainerTitle}>
                <Box mr={2}>
                  <Typography variant="h5" className={classes.dataTitle}>
                    02:31:40
                  </Typography>
                </Box>
                <Typography className={classes.fontTitleInfo}>
                  Duración Proceso
                </Typography>
              </Grid>

              <Box ml={3}>
                <Grid item container className={classes.divContainerTitle2}>
                  <Box mr={2}>
                    <Typography variant="h5" className={classes.dataTitle}>
                      0123
                    </Typography>
                  </Box>
                  <Typography className={classes.fontTitleInfo}>
                    Lote InfoPorcinos
                  </Typography>
                </Grid>
              </Box>
            </Grid>

            <Grid container className={classes.titleContainer}>
              <Typography className={classes.fontTitleContainer}>
                Resumen del Proceso de Beneficio
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item container xs={5}>
              <Box m={1}>
                <ItemOutlinedInfo
                  icon="icon-productor"
                  title="Productor"
                  info="Nombre Productor"
                />
              </Box>
              <Box m={1}>
                <ItemOutlinedInfo
                  icon="icon-comercializador"
                  title="Comercializador"
                  info="Nombre Comercializador"
                />
              </Box>
              <Box m={1}>
                <ItemOutlinedInfo
                  icon="icon-calendar"
                  title="Inicio Beneficio"
                  info="22/01/2019 10:10:00"
                />
              </Box>
              <Box m={1}>
                <ItemOutlinedInfo
                  icon="icon-calendar"
                  title="Final Beneficio"
                  info="22/01/2019 10:30:00"
                />
              </Box>
            </Grid>
            <Grid item container xs={7}>
              <Grid container className={classes.gridContainer}>
                <Grid item container xs={6} justify="center">
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={54}
                    titleInfo="Canales Porcinas Evaluadas"
                    avatarTotalColor="#DD9835"
                    avatarTotalSize={50}
                    classNameLabel={classes.itemInfoLabel}
                  />
                </Grid>
                <Grid item container xs={6} alignItems="center">
                  <Box mr={2}>
                    <Typography variant="h5" className={classes.dataTitle}>
                      08:10:00
                    </Typography>
                  </Box>
                  <Typography className={classes.fontTime}>
                    Tiempo Duración Sacrificio
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container>
                <Grid
                  item
                  container
                  xs={4}
                  justify="center"
                  alignItems="center"
                  direction="column"
                >
                  <Box mb={1}>
                    <Avatar className={classes.avatarColor}>
                      <Box
                        component="i"
                        className="icon-sync"
                        fontSize="30px"
                      />
                    </Avatar>
                  </Box>
                  <Typography className={classes.fontShipping}>
                    Envío Proceso Sacrificio
                  </Typography>
                </Grid>

                <Grid item container xs={8} justify="center">
                  <TableContainer aria-label="simple table">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            Cantidad
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            Fecha
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            Hora
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow key={index} className={classes.tableRow}>
                            <TableCell
                              align="center"
                              className={classes.tableCellData}
                            >
                              {row.quantity}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCellData}
                            >
                              {row.date}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCellData}
                            >
                              {row.hour}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.titleContainer}>
            <Typography className={classes.fontTitleContainer}>
              Datos Inspección Post-Mortem
            </Typography>
          </Grid>

          <Grid container alignItems="center">
            <Grid item container xs={6} className={classes.content}>
              <Grid>
                <ItemOutlinedInfo
                  icon="icon-canales"
                  title="Decomisos Canales"
                />

                <Grid item container>
                  <Grid item container className={classes.mb}>
                    <Grid
                      item
                      container
                      xs={6}
                      alignItems="center"
                      justify="center"
                    >
                      <Typography className={classes.dataConfiscation}>
                        0
                      </Typography>
                      <Typography className={classes.titleConfiscation}>
                        Canales Completas Decomisadas
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={6}
                      alignItems="center"
                      justify="center"
                    >
                      <Typography className={classes.dataConfiscation}>
                        104
                      </Typography>
                      <Typography className={classes.titleConfiscation}>
                        Partes Totales Decomisadas
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item container className={classes.mb}>
                    <Grid
                      item
                      container
                      xs={6}
                      alignItems="center"
                      justify="center"
                    >
                      <Typography className={classes.dataConfiscation}>
                        0
                      </Typography>
                      <Typography className={classes.titleConfiscation}>
                        Canales Retenidas Por Olor Sexual
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={6}
                      alignItems="center"
                      justify="center"
                    >
                      <Typography className={classes.dataConfiscation}>
                        6,43
                      </Typography>
                      <Typography className={classes.titleConfiscation}>
                        Total Carne Decomisada (Kg)
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item container className={classes.mb}>
                    <Grid
                      item
                      container
                      xs={6}
                      alignItems="center"
                      justify="center"
                    >
                      <i
                        className="icon-pig-search"
                        style={{
                          fontSize: '25px',
                          marginRight: '10px',
                          color: '#DD9835',
                        }}
                      ></i>

                      <Typography className={classes.fontResponsable}>
                        Responsable Inspección Veterinaria
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={6}
                      alignItems="center"
                      justify="center"
                    >
                      <Typography className={classes.dataResponsable}>
                        Gabriel Jaime Vergara
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={6}
              className={classes.gridContainerGrapihc}
            >
              <Grid item>
                <Box mt={2}>
                  <Typography className={classes.titleGraphic}>
                    Detalle de Partes Decomisadas
                  </Typography>
                </Box>
              </Grid>
              <Chart
                type="doughnut"
                infoTotalData="Total Partes"
                withoutPorcentage
                data={[
                  {
                    label: 'Pulmón',
                    data: 80,
                    bg: '#FFC676',
                  },
                  {
                    label: 'Viseras Rojas',
                    data: 13,
                    bg: '#F1AE6C',
                  },
                  {
                    label: 'Viseras Blancas',
                    data: 6,
                    bg: '#E29662',
                  },
                  {
                    label: 'Piel',
                    data: 12,
                    bg: '#D47D57',
                  },
                  {
                    label: 'Pezuña',
                    data: 3,
                    bg: '#BE5948',
                  },
                  {
                    label: 'Carne',
                    data: 3,
                    bg: '#B0413E',
                  },
                ]}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.titleContainer}>
            <Typography className={classes.fontTitleContainer}>
              Datos de Medición y Evaluación Técnica de Canales Calientes
              Porcinas
            </Typography>
          </Grid>

          <Grid container>
            {datos.map((index) => (
              <Grid
                item
                container
                xs={6}
                alignItems="center"
                justify="center"
                style={{ marginBottom: '10px' }}
              >
                <Grid item container xs={8}>
                  <Typography className={classes.titleDataMeasurement}>
                    {index.title}
                  </Typography>
                </Grid>
                <Grid item container xs={3} justify="flex-end">
                  <Typography className={classes.SubtitleDataMeasurement}>
                    {index.data}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Grid
              item
              container
              xs={6}
              alignItems="center"
              justify="center"
              style={{ marginBottom: '10px' }}
            >
              <Grid item xs={5}>
                <Typography className={classes.titleDataMeasurement}>
                  Responsable Medición
                </Typography>
              </Grid>
              <Grid item container xs={6} justify="center">
                <Typography className={classes.dataResponsable}>
                  Julian Jaramillo Cano
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item container xs={4}>
                <Grid>
                  <Grid
                    item
                    container
                    style={{ height: '110px', marginTop: '30px' }}
                  >
                    <ItemOutlinedInfo
                      icon="icon-pig"
                      title="Características"
                      variant
                    />
                    <Grid item container justify="center">
                      <Grid className={classes.gridWidth}>
                        <Box mb={2} mt={3}>
                          <li className={classes.li}>
                            <span className={classes.span}>
                              Comprobación de Genero
                            </span>
                          </li>
                          <Typography className={classes.titleFeatures}>
                            AMBOS
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid className={classes.gridWidth}>
                        <li className={classes.li}>
                          <span className={classes.span}>Inmunocastrados</span>
                        </li>
                        <Typography className={classes.titleFeatures}>
                          SI
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={8}
                className={classes.gridContainerGrapihc}
              >
                <Grid item container justify="center">
                  <Box mt={2}>
                    <Typography className={classes.titleGraphic}>
                      Clasificación S.E.U.R.O.P.
                    </Typography>
                  </Box>
                </Grid>
                <Chart
                  type="doughnut"
                  withoutTotalInfo
                  classNameLabel={classes.labelGraphic}
                  data={[
                    {
                      label: 'S - Superior',
                      data: 20,
                      bg: '#FFC676',
                    },
                    {
                      label: 'E - Excelente',
                      data: 13,
                      bg: '#F1AE6C',
                    },
                    {
                      label: 'U - Muy Buena',
                      data: 6,
                      bg: '#E29662',
                    },
                    {
                      label: 'R - Buena',
                      data: 12,
                      bg: '#D47D57',
                    },
                    {
                      label: 'O - Menos Buena',
                      data: 3,
                      bg: '#BE5948',
                    },
                    {
                      label: 'P - Mediocre',
                      data: 3,
                      bg: '#B0413E',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              className="raleway"
              onClick={handleClose}
              color="primary"
            >
              Aceptar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DialogSacrifice;
