import { useParams } from 'react-router';

import CreateCompany from './CreateCompany/index';
import CreateVehicle from './CreateVehicle/index';
import DetailCompany from './DetailCompany/index';
import DetailVehicle from './DetailVehicle/index';
import DetailDriver from './DetailDriver/index';
import RegisterDriverXVehicle from './RegisterDriverXVehicle/index';
import RegisterVehicleXDriver from './RegisterVehicleXDriver/index';

const Forms = () => {
  const { id, idService, form } =
    useParams<{ id: string; idService: string; form: string }>();

  return (
    <>
      {form === 'create-company' && <CreateCompany id={Number(id)} />}
      {form === 'create-vehicle' && <CreateVehicle id={Number(id)} />}
      {form === 'detail-company' && <DetailCompany id={Number(id)} />}
      {form === 'detail-vehicle' && <DetailVehicle id={Number(id)} />}
      {form === 'detail-driver' && <DetailDriver id={Number(id)} />}
      {form === 'register-driver-vehicle' && (
        <RegisterDriverXVehicle id={Number(id)} />
      )}
      {form === 'register-vehicle-driver' && (
        <RegisterVehicleXDriver
          id={Number(id)}
          idTypeService={Number(idService)}
        />
      )}
    </>
  );
};
export default Forms;
