import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Box } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import domtoimage from 'dom-to-image';
import clsx from 'clsx';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableInfo from 'components/TableInfo';
import GobackLink from 'components/GobackLink';
import Chart from 'components/Chart';
import LoteInfoTitle from 'components/LoteInfoTitle';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import ExportToExcel from 'components/ExportToExcel';
import DialogPDFViewer from 'components/DialogPDFViewer';

import {
  ReportLot,
  MyDocPostMortem,
  GraphPM,
  GraphPMTwo,
} from './../../Reports/ReportLot';

import useStyles from './styles';
const index = () => {
  const [openLotPosMotem, setOpenLotPosMotem] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');
  const [imageGraphTwo, setImageGraphTwo] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenLotPM');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraph = () => {
    const graph: any = document.getElementById('graphpm');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraphTwo = () => {
    const graph: any = document.getElementById('graphpmtwo');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraphTwo(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeVisceras = () => {
    setOpenLotPosMotem(true);
  };

  useEffect(() => {
    generateScreenshot();
    screenshotGraphTwo();
    screenshotGraph();
  }, [openLotPosMotem]);

  const columns: GridColDef[] = [
    {
      field: 'canalID',
      headerName: 'Canal ID',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'cantidad',
      headerName: 'Cantidad',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'parteDecomisada',
      headerName: 'Parte Decomisada',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'causa',
      headerName: 'Causa',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'decomiso',
      headerName: 'Decomiso (Kg)',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'origen',
      headerName: 'Origen',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'observaciones',
      headerName: 'Observaciones',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const rows = [
    {
      id: 1,
      canalID: '1234',
      cantidad: '2',
      parteDecomisada: 'Nombre Parte',
      causa: 'Detalle Causa',
      decomiso: '0,00',
      origen: 'LB',
      observaciones: 'Ninguna Observación',
    },
    {
      id: 2,
      canalID: '1234',
      cantidad: '2',
      parteDecomisada: 'Nombre Parte',
      causa: 'Detalle Causa',
      decomiso: '0,00',
      origen: 'LB',
      observaciones: 'Ninguna Observación',
    },
    {
      id: 3,
      canalID: '1234',
      cantidad: '2',
      parteDecomisada: 'Nombre Parte',
      causa: 'Detalle Causa',
      decomiso: '0,00',
      origen: 'LB',
      observaciones: 'Ninguna Observación',
    },
    {
      id: 4,
      canalID: '1234',
      cantidad: '2',
      parteDecomisada: 'Nombre Parte',
      causa: 'Detalle Causa',
      decomiso: '0,00',
      origen: 'LB',
      observaciones: 'Ninguna Observación',
    },
    {
      id: 5,
      canalID: '1234',
      cantidad: '2',
      parteDecomisada: 'Nombre Parte',
      causa: 'Detalle Causa',
      decomiso: '0,00',
      origen: 'LB',
      observaciones: 'Ninguna Observación',
    },
    {
      id: 6,
      canalID: '1234',
      cantidad: '2',
      parteDecomisada: 'Nombre Parte',
      causa: 'Detalle Causa',
      decomiso: '0,00',
      origen: 'LB',
      observaciones: 'Ninguna Observación',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/post-mortem/details',
      title: 'Post-Mortem',
    },
  ];

  const classes = useStyles();

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphPM />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphPMTwo />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <LoteInfoTitle
                lot="7645"
                namereport="Nro. Reporte"
                numberreport="011-65432"
                title="Post-Mortem"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Agentes relacionados
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <TableInfo
              icon="icon-productor"
              title="Productor"
              items={{
                Nombre: 'Nombre productor',
                NIT: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TableInfo
              icon="icon-planta"
              title="Planta Beneficio"
              items={{
                Nombre: 'Nombre Planta',
                NIT: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TableInfo
              icon="icon-granja"
              title="Granja"
              items={{
                Nombre: 'Nombre Granja',
                Responsable: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>

          <Grid xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Resumen Inspección Post-Mortem
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex' }}>
            <div className={classes.center}>
              <div
                className={clsx(classes.centerContainer, classes.midContainer)}
              >
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={4}
                    titleInfo="Canales Completas Decomisadas"
                    avatarTotalColor="#B0413E"
                    avatarTotalSize={75}
                    avatarTextSize={25}
                    titleInfoSize={250}
                  />
                </div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={72}
                    titleInfo="Canales Evaluadas"
                    avatarTotalColor="#E29662"
                    avatarTotalSize={55}
                    avatarTextSize={22}
                    titleInfoSize={250}
                  />
                </div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={0}
                    titleInfo="Sala de Emergencia"
                    avatarTotalColor="#FFC676"
                    avatarTotalSize={40}
                    avatarTextSize={22}
                    titleInfoSize={250}
                  />
                </div>
              </div>
              <div
                className={clsx(classes.centerContainer, classes.minContainer)}
              >
                <div>
                  <Typography className={classes.paperTitles}>2%</Typography>
                </div>
                <div>
                  <Typography className={classes.paperSubtitles}>
                    Porcentaje Total Canales Completas Decomisadas
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.paperTitles}>
                    216 Kg
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.paperSubtitles}>
                    Total (Kgs) Carne Decomisada
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.grayContainerCharts}>
              <div className={classes.chartContainer}>
                <Chart
                  type="doughnut"
                  withoutData={true}
                  data={[
                    {
                      label: 'Pulmón',
                      data: 46,
                      bg: '#FFC676',
                    },
                    {
                      label: 'Piel',
                      data: 23,
                      bg: '#F1AE6C',
                    },
                    {
                      label: 'Vísceras Rojas',
                      data: 11,
                      bg: '#E29662',
                    },
                    {
                      label: 'Pierna',
                      data: 17,
                      bg: '#D47D57',
                    },
                    {
                      label: 'Corazón',
                      data: 6,
                      bg: '#BE5948',
                    },
                    {
                      label: 'Vísceras Blancas',
                      data: 6,
                      bg: '#B0413E',
                    },
                  ]}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Decomisos Línea de Beneficio
              </Typography>
            </div>
            <div
              className={clsx(
                classes.grayContainer,
                classes.grayContainerAlign
              )}
            >
              <div className={classes.tableContainer}>
                <Typography className={classes.paperTitles}>2%</Typography>
              </div>
              <div className={classes.divSeparator}>
                <Typography className={classes.paperSubtitles}>
                  Porcentaje Total Canales Completas Decomisadas
                </Typography>
              </div>
            </div>
            <div
              className={clsx(
                classes.grayContainer,
                classes.grayContainerAlign
              )}
            >
              <div className={classes.tableContainer}>
                <Typography className={classes.paperTitles}>02</Typography>
              </div>

              <div className={classes.divSeparator}>
                <Typography className={classes.paperSubtitles}>
                  Total Canales Completas Decomisadas
                </Typography>
              </div>
            </div>
            <div
              className={clsx(
                classes.grayContainer,
                classes.grayContainerAlign
              )}
            >
              <div className={classes.tableContainer}>
                <Typography className={classes.paperTitles}>216 Kg</Typography>
              </div>
              <div className={classes.divSeparator}>
                <Typography className={classes.paperSubtitles}>
                  Total (Kgs) Carne Decomisada
                </Typography>
              </div>
            </div>
            <div className={classes.divBlock}>
              <div>
                {' '}
                <Typography className={classes.paperSubtitles}>
                  Miguel Ángel Róndale Bedoya
                </Typography>
              </div>
              <div>
                <Typography className={classes.paperSubtitles}>
                  C.C 21345663
                </Typography>
              </div>
              <div>
                {' '}
                <Typography
                  className={clsx(
                    classes.paperSubtitles,
                    classes.paperSubtitlesBold
                  )}
                >
                  Inspector Médico Veterinario
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Decomisos S.B.E
              </Typography>
            </div>
            <div
              className={clsx(
                classes.grayContainer,
                classes.grayContainerAlign
              )}
            >
              <div className={classes.tableContainer}>
                <Typography className={classes.paperTitles}>2%</Typography>
              </div>
              <div className={classes.divSeparator}>
                <Typography className={classes.paperSubtitles}>
                  Porcentaje Total Canales Completas Decomisadas
                </Typography>
              </div>
            </div>
            <div
              className={clsx(
                classes.grayContainer,
                classes.grayContainerAlign
              )}
            >
              <div className={classes.tableContainer}>
                <Typography className={classes.paperTitles}>02</Typography>
              </div>

              <div className={classes.divSeparator}>
                <Typography className={classes.paperSubtitles}>
                  Total Canales Completas Decomisadas
                </Typography>
              </div>
            </div>
            <div
              className={clsx(
                classes.grayContainer,
                classes.grayContainerAlign
              )}
            >
              <div className={classes.tableContainer}>
                <Typography className={classes.paperTitles}>216 Kg</Typography>
              </div>
              <div className={classes.divSeparator}>
                <Typography className={classes.paperSubtitles}>
                  Total (Kgs) Carne Decomisada
                </Typography>
              </div>
            </div>
            <div className={classes.divBlock}>
              <div>
                {' '}
                <Typography className={classes.paperSubtitles}>
                  Miguel Ángel Róndale Bedoya
                </Typography>
              </div>
              <div>
                <Typography className={classes.paperSubtitles}>
                  C.C 21345663
                </Typography>
              </div>
              <div>
                <Typography
                  className={clsx(
                    classes.paperSubtitles,
                    classes.paperSubtitlesBold
                  )}
                >
                  Inspector Médico Veterinario
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Detalles Causas Decomisos Partes
              </Typography>
            </div>
            <div className={classes.center}>
              <Chart
                type="doughnut"
                infoTotalData=""
                withoutData={true}
                position="top"
                data={[
                  {
                    label: 'Broncoaspiración',
                    data: 21,
                    bg: '#FFC676',
                  },
                  {
                    label: 'Congestión',
                    data: 45,
                    bg: '#F1AE6C',
                  },
                  {
                    label: 'Neumonía',
                    data: 45,
                    bg: '#E29662',
                  },
                  {
                    label: 'Adherencias',
                    data: 13,
                    bg: '#D47D57',
                  },
                  {
                    label: 'Absesos',
                    data: 3,
                    bg: '#BE5948',
                  },
                  {
                    label: 'Diagnóstico',
                    data: 1,
                    bg: '#B0413E',
                  },
                ]}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Detalles Decomisos Partes Post-Mortem
              </Typography>
              <Box position="absolute" right={8} m={2}>
                <ExportToExcel
                  data={rows}
                  headers={columns.map((columns: any) => columns.field)}
                  fileName="Detalle-Decomiso-Post-Mortem"
                />
              </Box>
            </div>
            <div>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </ContentCard>
      <Grid container justify="flex-end" alignItems="center">
        <Box mr={2}>
          <Typography className={classes.colorUp}>
            Opciones del Reporte
          </Typography>
        </Box>
        <Button
          startIcon={<i className="icon-eye" />}
          variant="contained"
          className="raleway"
          color="primary"
          onClick={openModalRangeVisceras}
        >
          Previsualizar
        </Button>
      </Grid>
      <DialogPDFViewer
        open={openLotPosMotem}
        onClose={() => setOpenLotPosMotem(false)}
        done={Boolean(imageQr)}
      >
        <MyDocPostMortem
          qr={imageQr}
          graph={imageGraph}
          graphTwo={imageGraphTwo}
        />
      </DialogPDFViewer>
    </div>
  );
};

export default index;
