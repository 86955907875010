import { NestedRoute } from 'lib/interfaces/routes';

import General from './General';
import MarketerType from './MarketerType';
import LegalDocuments from './LegalDocuments';
import Permissions from './Permissions'; 
import Subscription from './Subscription'
import MobileApp from './App';
import Unsubscribe from './Unsubscribe';
import Farms from './Farms';
import CreateFarm from './Farms/Creation'; 
import DetailFam from './Farms/Detail'; 
import PointsOfSale from './PointsOfSale'
import CreateDestiny from './PointsOfSale/Creation';
import DetailDestiny from './PointsOfSale/Detail'; 
import ChangePassword from './ChangePassword';


export const routes: Array<NestedRoute> = [
  {
    route: '/',
    Node: General,
    exact: true, 
    title: 'Datos Generales',
    id: 1, 
    display: true
  },
  {
    route: '/marketer-type',
    Node: MarketerType,
    exact: true,
    title: 'Tipo Comercializador',
    id: 2, 
    display: true
  },

  {
    route: '/points-of-sale',
    Node: PointsOfSale,
    exact: true,
    title: 'Mis Puntos de Venta',
    id: 2, 
    display: true
  },
  {
    route: '/points-of-sale/register/:type',
    Node: CreateDestiny,
    id: 2,
    display: false,
  },
  {
    route: '/points-of-sale/detail/:id',
    Node: DetailDestiny,
    id: 2,
    display: false,
  },
  {
    route: '/farms',
    Node: Farms,
    title: 'Mis Granjas',
    id: 2, 
    exact: true,
    display: true
  },
  {
    route: '/farms/register',
    Node: CreateFarm,
    id: 2,
    display: false,
  },
  {
    route: '/farms/detail/:id',
    Node: DetailFam,
    id: 2,
    display: false,
  },
  {
    route: '/terms',
    Node: LegalDocuments,
    exact: true,
    title: 'Términos de Uso',
    id: 3, 
    display: true
  },
  {
    route: '/privacy-policies',
    Node: LegalDocuments,
    exact: true,
    title: 'Tratamiento de Datos',
    id: 3, 
    display: true
  },
  {
    route: '/titularidad-datos',
    Node: LegalDocuments,
    exact: true,
    title: 'Titularidad Datos',
    id: 3, 
    display: true
  },
  {
    route: '/autorizacion-datos',
    Node: LegalDocuments,
    exact: true,
    title: 'Autorización Datos',
    id: 3, 
    display: true
  },
  {
    route: '/permits',
    Node: Permissions,
    exact: true,
    title: 'Permisos',
    id: 4, 
    display: true
  },
  {
    route: '/change-password',
    Node: ChangePassword,
    exact: true,
    title: 'Ajustes de seguridad',
    id: 4, 
    display: true
  },
  {
    route: '/subscription',
    Node: Subscription,
    exact: true,
    title: 'Suscripción',
    id: 5, 
    display: true
  },

  {
    route: '/mobile-app',
    Node: MobileApp,
    exact: true,
    title: 'Aplicación Móvil',
    id: 6, 
    display: true
  },
  {
    route: '/unsubscribe',
    Node: Unsubscribe,
    exact: true,
    title: 'Darse de baja',
    id: 7, 
    display: true
  },
];
