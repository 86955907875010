import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Switch,
  Divider,
  Button,
  Avatar,
  Fab,
  Tooltip,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";

import MainTitle from "components/MainTitle";
import RegisterRol from "components/PageComponents/Settings/RegisterRol";
import EditRol from "components/PageComponents/Settings/EditRol";
import avatars from "lib/helpers/dynamic-images/avatars";
import LogoIP from "static/img/logos/ip-icon.svg";

import { GET_ROLES } from "core/graphql/queries/settings";
import { CHANGE_ROLE_STATE } from "core/graphql/mutations/settings";

import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import useStyles from "../styles";
import InformativeScreen from "components/InformativeScreens";

const RoleManagement = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState("true");
  const [misRoles, setMisRoles] = useState<any>([]);
  const [rolesSistema, setRolesSistema] = useState<any>([]);

  const { data, refetch, error, loading } = useQuery(GET_ROLES, {
    context: { headers: { "x-auth-jwt": token } },
  });

  const [changeState, { loading: changeStateLoading }] = useMutation(
    CHANGE_ROLE_STATE,
    { context: { headers: { "x-auth-jwt": token } } }
  );

  useEffect(() => {
    if (!loading && data) {
      if (state === "true") {
        setRolesSistema(
          data?.getRoles?.data?.rolesSistema?.filter((i: any) => i.estado)
        );
        setMisRoles(
          data?.getRoles?.data?.misRoles?.filter((i: any) => i.estado)
        );
      } else {
        setMisRoles(
          data?.getRoles?.data?.misRoles?.filter((i: any) => !i.estado)
        );
        setRolesSistema(
          data?.getRoles?.data?.rolesSistema?.filter((i: any) => !i.estado)
        );
      }
    }
  }, [loading, data]);

  const handleChangeFilter = (event: any) => {
    setState(event.target.value);

    if (event.target.value === "true") {
      setRolesSistema(
        data?.getRoles?.data?.rolesSistema?.filter((i: any) => i.estado)
      );
      setMisRoles(data?.getRoles?.data?.misRoles?.filter((i: any) => i.estado));
    } else {
      setMisRoles(
        data?.getRoles?.data?.misRoles?.filter((i: any) => !i.estado)
      );
      setRolesSistema(
        data?.getRoles?.data?.rolesSistema?.filter((i: any) => !i.estado)
      );
    }
  };

  const handleChangeState = (idRol: any, estado: any) => {
    changeState({ variables: { idRol, estado: !estado } })
      .then((data) => {
        enqueueSnackbar(`${data?.data?.putStatusRole?.data?.message}`, {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
        refetch();
      })
      .catch((error) => {
        enqueueSnackbar(`Ha ocurrido un error. ${error}`, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <MainTitle>Gestión de Roles</MainTitle>
        <Box display="flex" alignItems="center">
          <RadioGroup row aria-label="position" name="position">
            <FormControlLabel
              value="true"
              checked={state === "true"}
              onChange={handleChangeFilter}
              control={<Radio color="primary" />}
              label="Activos"
            />
            <FormControlLabel
              value="false"
              checked={state === "false"}
              onChange={handleChangeFilter}
              control={<Radio color="primary" />}
              label="Inactivos"
            />
          </RadioGroup>
          <RegisterRol>
            {({ handleOpenRol }: any) => {
              return (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOpenRol}
                  startIcon={<i className="icon-plus" />}
                >
                  Registrar Rol
                </Button>
              );
            }}
          </RegisterRol>
        </Box>
      </Box>
      {loading ? (
        <Box
          marginY={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={50} />
        </Box>
      ) : error ? (
        <>
          <InformativeScreen
            onlyError
            subtitle="Ups"
            info="Ha ocurrido un error, vuelve más tarde"
            type="technicalError"
          />
        </>
      ) : (
        <>
          {rolesSistema?.length === 0 && misRoles?.length === 0 ? (
            <>
              <InformativeScreen
                onlyError
                subtitle="¡No encontramos resultados!"
                info="La búsqueda no ha arrojado ningún resultado."
                type="notFound"
              />
            </>
          ) : (
            <>
              {rolesSistema?.map((item: any) => {
                return (
                  <Box marginY={1}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      paddingY={2}
                    >
                      <Box display="flex" alignItems="center">
                        <Avatar src={LogoIP} />
                        <Box marginLeft={2}>
                          <Typography variant="h6" color="secondary">
                            {item?.descripcion}
                          </Typography>
                          <Typography variant="body1" color="secondary">
                            Rol creado por defecto en el sistema.
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box marginRight={2}>
                          <EditRol
                            idRole={item?.idRol}
                            rolename={item?.descripcion}
                            uneditable
                          >
                            {({ handleOpen }: any) => {
                              return (
                                <Fab
                                  color="primary"
                                  size="small"
                                  onClick={handleOpen}
                                >
                                  <i
                                    className="icon-eye"
                                    style={{ fontSize: 20 }}
                                  />
                                </Fab>
                              );
                            }}
                          </EditRol>
                        </Box>
                        <Switch
                          checked={item?.estado}
                          className={classes.switch}
                          disabled
                        />
                      </Box>
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
              {misRoles?.map((item: any) => {
                return (
                  <Box marginY={1}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      paddingY={2}
                    >
                      <Box display="flex" alignItems="center">
                        <Avatar src={avatars(`${item?.descripcion}`)} />
                        <Box marginLeft={2}>
                          <Typography variant="h6" color="secondary">
                            {item?.descripcion}
                          </Typography>
                          <Typography variant="body1" color="secondary">
                            Rol creado por mi.
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box marginRight={2}>
                          <EditRol
                            idRole={item?.idRol}
                            rolename={item?.descripcion}
                          >
                            {({ handleOpen }: any) => {
                              return (
                                <Fab
                                  color="primary"
                                  size="small"
                                  onClick={handleOpen}
                                >
                                  <i
                                    className="icon-eye"
                                    style={{ fontSize: 20 }}
                                  />
                                </Fab>
                              );
                            }}
                          </EditRol>
                        </Box>

                        <Tooltip title="Cambiar estado del rol">
                          <Switch
                            checked={item?.estado}
                            className={classes.switch}
                            onChange={() =>
                              handleChangeState(item?.idRol, item?.estado)
                            }
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
};

export default RoleManagement;
