import { gql } from "apollo-boost";

export const CREATE_ROLE = gql`
  mutation createRole(
    $roleName: String!
    $subCategorias: [RolSubcategoriesInput!]!
  ) {
    createRole(input: { nombreRol: $roleName, subCategorias: $subCategorias }) {
      data {
        idRol
        alias
        descripcion
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const EDIT_ROLE = gql`
  mutation putPermissionsRole(
    $idRole: Int!
    $subCategorias: [SubcategoriesInput!]!
  ) {
    putPermissionsRole(
      input: { idRol: $idRole, subCategorias: $subCategorias }
    ) {
      data {
        success
        message
      }
      token {
        tokenNuevo
      }
    }
  }
`;

export const CHANGE_ROLE_STATE = gql`
  mutation putStatusRole($idRol: Int!, $estado: Boolean!) {
    putStatusRole(input: { idRol: $idRol, estado: $estado }) {
      data {
        success
        idRole
        message
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;
