import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthLg": {
      borderRadius: "20px",
      padding: "20px",
    },
  },
  avatar: {
    width: "50px",
    height: "50px",
    backgroundColor: "#DD9835 !important",
    fontWeight: "bolder",
    fontSize: "22px",
  },
  liContainer: {
    listStyle: "none",
  },
  spanTitle: {
    fontWeight: "bolder",
    color: "#767676",
  },
  gridContainer: {
    margin: "15px",
  },
  paperContainer: {
    width: "31%",
    minHeight: "80px",
    margin: "10px",
    display: "flex",
    alignItems: "center",
    padding: "5px",
  },
  fontTitle: {
    fontWeight: "bold",
    fontSize: "24px",
    marginBottom: "10px",
  },
  headerContainer: {
    marginBottom: 30,
  },
  fontName: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
}));

export { useStyles as default };
