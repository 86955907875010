import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  labelsBar:{
    width:'45%', 
    display:'flex',
    flexDirection:'column', 
    justifyContent:'center',
  },
  itemLabels:{
    width: '100%',
    display:'flex', 
    padding: 4
  }, 
  titleLabelBar:{
    width: '100%', 
    textAlign: 'left',
    flexDirection: 'row' 
  },
})
