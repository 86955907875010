import React from 'react';

import { GenerateMultilevelRoutes as Routes } from 'routes';

import routes from './pages';

const Reports = () => {
  return <Routes nested={routes} redirect="/entrance" />;
};

export default Reports;
