import { makeStyles } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  marginBottom: {
    marginBottom: 25,
  },
  color: {
    fontWeight: 'bold',
  },
  colorChipAcepted: {
    '& .MuiChip-root': {
      color: theme.palette.primary.main,
    },
  },
  paddingCardBUS: {
    padding: '2%',
    borderRadius: 10,
  },
  paddingCardGI: {
    padding: '3%',
    borderRadius: 10,
  },
  icon: {
    color: 'white',
  },
  inputSearch: {
    width: '75%',
  },
  buttonSearch: {
    marginLeft: 15,
  },
  colorHr: {
    borderColor: '#AFAFAF',
  },
  dataGrid: {
    border: 'none',
    '& :focus': {
      outline: 'transparent !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      color: 'transparent',
    },
    '& .MuiDataGrid-colCellTitle': {
      color: '#AFAFAF',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none !important',
    },

    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
      outlineOffset: 'none',
    },

    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal',
      color: '#AFAFAF',
      fontWeight: 'bold',
      fontSize: 14,
    },
  },
  textAling: {
    display: 'flex !important',
    justifyContent: 'center !important',
  },
  chipVer: {
    color: 'white',
    backgroundColor: 'gray',
  },
  form: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fontTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#767676',
  },
  fontContent: {
    color: '#AFAFAF',
    fontSize: '16px',
  },
  spacing: {
    marginLeft: '50px',
  },
  sizeImage: {
    width: '240px',
    height: '176px',
  },
  fontUppercase: {
    textTransform: 'capitalize',
  },
  fontNoRow: {
    fontWeight: 'bold',
    fontSize: '22px',
    color: '#212121',
  },
  fontContentNoRow: {
    color: '#AFAFAF',
    fontSize: '14px',
  },
  colorNoRow: {
    backgroundColor: 'transparent !important',
  },
  ButtonGrup: {
    boxShadow: 'none',
  },
  ButtonViewRequest: {
    boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
    fontWeight: 'bold',
  },
  ButtonInvite: {
    fontWeight: 'bold',
  },
  ButtonAccept: {
    backgroundColor: green[500],
    color: 'white',
    fontWeight: 'bold',
    marginRight: '10px',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  ButttonReject: {
    backgroundColor: red[500],
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  TabfontText: {
    color: '#AFAFAF',
    textTransform: 'capitalize',
    fontSize: '14px',
  },
  btnDetails: {
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      color: '#fff',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  heightContainer: {
    '& .MuiDataGrid-root.MuiDataGrid-autoHeight': {
      height: '220px',
    },
  },
  noDataFontTitle: {
    color: '#767676',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  noDataFontSubtitle: {
    color: '#AFAFAF',
    width: '85%',
  },
  notDataContainer: {
    '& .MuiDataGrid-columnsContainer': {
      display: 'none',
    },
  },
  fontDocument: {
    fontWeight: 'bolder',
    marginRight: '5px',
  },

  labelTooltips: {
    fontSize: 14,
    color: '#AFAFAF',
    fontFamily: 'Roboto',
  },

  iconPadding: {
    color: theme.palette.primary.main,
    fontSize: 35,
  },
  radioGrup:{
    justifyContent:'center',
  },
  buttonForward:{
    "& .MuiButtonGroup-root::hover":{
      color: 'red',
    }  
  },
  loadingColor: {
    color: '#767676',
  },

}));

export { useStyles as default };
