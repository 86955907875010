import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Box } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import domtoimage from 'dom-to-image';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableInfo from 'components/TableInfo';
import GobackLink from 'components/GobackLink';
import Chart from 'components/Chart';
import ExportToExcel from 'components/ExportToExcel';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import LoteInfoTitle from 'components/LoteInfoTitle';
import DialogPDFViewer from 'components/DialogPDFViewer';

import {
  MyDocMagro,
  ReportLot,
  GraphMagro,
  GraphTwoMagro,
} from './../../Reports/ReportLot';

import useStyles from './styles';
const index = () => {
  const [openFilterMagro, setOpenFilterMagro] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');
  const [imageGraphTwo, setImageGraphTwo] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenLotMagro');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraph = () => {
    const graph: any = document.getElementById('graphmagro');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraphTwo = () => {
    const graph: any = document.getElementById('graphtwomagro');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraphTwo(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalFilterMagro = () => {
    setOpenFilterMagro(true);
  };

  useEffect(() => {
    generateScreenshot();
    screenshotGraph();
    screenshotGraphTwo();
  }, [openFilterMagro]);

  const columns: GridColDef[] = [
    {
      field: 'canalID',
      headerName: 'Canal ID',
      flex: 1,

      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'brazalete',
      headerName: 'Brazalete',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'intmed',
      headerName: 'Int. Med.',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'SEUROP',
      headerName: 'SEUROP',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'pcc',
      headerName: 'PCC (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'gd',
      headerName: 'GD (m.m.)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ml',
      headerName: 'ML (m.m)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mc',
      headerName: 'MC (%)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mckg',
      headerName: 'MC (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'PSE',
      headerName: 'Indicio (PSE)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'color',
      headerName: 'Color',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <div
          style={{
            display: 'flex',

            alignItems: 'center',
          }}
        >
          <div style={{ marginRight: 15 }}>
            <svg height="15" width="15">
              <circle fill="#B0413E" r="5" cx="10" cy="10"></circle>
            </svg>
          </div>
          <div>{params.value}</div>
        </div>
      ),
    },
    {
      field: 'sexo',
      headerName: 'Sexo',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const rows = [
    {
      id: 1,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 2,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 3,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 4,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 5,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 6,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 7,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
    {
      id: 8,
      canalID: '1234',
      brazalete: '305050',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
      sexo: 'A',
    },
  ];

  const columnsSecond: GridColDef[] = [
    {
      field: 'pesaje',
      headerName: 'Pesaje',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'hour',
      headerName: 'Hora',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'animals',
      headerName: 'Animales',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'sexo',
      headerName: 'Sexo',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'inmunocastrado',
      headerName: 'Inmunocastrado',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'weight',
      headerName: 'Peso(Kg)',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];
  const rowsSecond = [
    {
      id: 1,
      pesaje: '0',
      date: '01/01/2019',
      hour: '02:00:00 pm',
      animals: '0',
      sexo: 'N/D',
      inmunocastrado: 'N/D',
      weight: '0',
    },
    {
      id: 2,
      pesaje: '1',
      date: '01/01/2019',
      hour: '02:00:00 pm',
      animals: '18',
      sexo: 'AMBOS',
      inmunocastrado: 'SI',
      weight: '2191',
    },
    {
      id: 3,
      pesaje: '2',
      date: '01/01/2019',
      hour: '02:00:00 pm',
      animals: '18',
      sexo: 'AMBOS',
      inmunocastrado: 'SI',
      weight: '2191',
    },
    {
      id: 4,
      pesaje: '3',
      date: '01/01/2019',
      hour: '02:00:00 pm',
      animals: '18',
      sexo: 'AMBOS',
      inmunocastrado: 'SI',
      weight: '2191',
    },
    {
      id: 5,
      pesaje: '4',
      date: '01/01/2019',
      hour: '02:00:00 pm',
      animals: '18',
      sexo: 'AMBOS',
      inmunocastrado: 'SI',
      weight: '2191',
    },
  ];
  const columnsThree: GridColDef[] = [
    {
      field: 'magro',
      headerName: '%Magro',
      flex: 0.3,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'nro',
      headerName: 'Nro.',
      flex: 0.1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'percentage',
      headerName: '%',
      flex: 0.08,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const rowsThree = [
    {
      id: 1,
      magro: '%M > 60%',
      nro: '12',
      percentage: '16%',
    },
    {
      id: 2,
      magro: '%55 < %M > 60%',
      nro: '28',
      percentage: '35%',
    },
    {
      id: 3,
      magro: '%50 < %M > 55%',
      nro: '28',
      percentage: '35%',
    },
    {
      id: 4,
      magro: '%45 < %M > 50%',
      nro: '08',
      percentage: '10%',
    },
    {
      id: 5,
      magro: '%40 < %M > 45%',
      nro: '01',
      percentage: '02%',
    },
    {
      id: 5,
      magro: '%40 < %M > 45%',
      nro: '0',
      percentage: '0%',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/magro/details',
      title: 'Reporte Evaluación de Magro',
    },
  ];

  const classes = useStyles();

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphMagro />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphTwoMagro />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <LoteInfoTitle
                lot="7645"
                namereport="Nro. Reporte"
                numberreport="011-65432"
                title="Reporte Evaluación Magro"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Agentes relacionados
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <TableInfo
              icon="icon-productor"
              title="Productor"
              items={{
                Nombre: 'Nombre productor',
                NIT: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TableInfo
              icon="icon-planta"
              title="Planta Beneficio"
              items={{
                Nombre: 'Nombre Planta',
                NIT: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TableInfo
              icon="icon-granja"
              title="Granja"
              items={{
                Nombre: 'Nombre Granja',
                Responsable: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>

          <Grid item xs={12} xl={8}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Resumen del Lote Evaluado
              </Typography>
            </div>
            <div className={classes.center}>
              <div className={classes.flexContainerEvenly}>
                <div>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={72}
                    titleInfo="Total Canales Evaluadas"
                    avatarTotalColor="#B0413E"
                    avatarTotalSize={60}
                    avatarTextSize={22}
                    titleInfoSize={250}
                  />
                </div>
                <div className={classes.flexContainer}>
                  <div>
                    <div className={classes.margin}>
                      <ItemTotalInfo
                        avatarTotalInfo={'101,67 Kg'}
                        titleInfo="Promedio PCC"
                        avatarTotalColor="#D47D57"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        position="right"
                      />
                    </div>
                    <div className={classes.margin}>
                      <ItemTotalInfo
                        avatarTotalInfo={'54,3 %'}
                        titleInfo="Promedio MC%"
                        avatarTotalColor="#E29662"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        position="right"
                      />
                    </div>
                  </div>
                  <div>
                    <div className={classes.margin}>
                      <ItemTotalInfo
                        avatarTotalInfo={'64,67 m.m.'}
                        titleInfo="Promedio LM"
                        avatarTotalColor="#F1AE6C"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        position="right"
                      />
                    </div>
                    <div className={classes.margin}>
                      <ItemTotalInfo
                        avatarTotalInfo={'16,8 m.m.'}
                        titleInfo="Promedio GD"
                        avatarTotalColor="#FFC676"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        position="right"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.center}>
                <div className={classes.flexContainer}>
                  <div>
                    <div className={classes.marginBox}>
                      <ItemTotalInfo
                        avatarTotalInfo={'7.320,00 Kg'}
                        titleInfo="Peso Total Animales en Pie"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        titleInfoSize={200}
                        extended
                        position="right"
                        titleInverted
                      />
                    </div>
                    <div className={classes.marginBox}>
                      <ItemTotalInfo
                        avatarTotalInfo={'4.013,65 Kg'}
                        titleInfo="Total Carne Magra"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        titleInfoSize={200}
                        extended
                        titleInverted
                        position="right"
                      />
                    </div>
                  </div>
                  <div>
                    <div className={classes.marginBox}>
                      <ItemTotalInfo
                        avatarTotalInfo={'6.891,00 Kg'}
                        titleInfo="Peso Total Canales Calientes"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        titleInfoSize={200}
                        position="right"
                        titleInverted
                      />
                    </div>
                    <div className={classes.marginBox}>
                      <ItemTotalInfo
                        avatarTotalInfo={'83.76%'}
                        titleInfo="Rendimiento Pie / Canal"
                        avatarTotalColor="#F2F2F2"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        titleInfoSize={200}
                        position="right"
                        titleInverted
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} xl={4}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Recepción Animales en Pie Corrales
              </Typography>
            </div>
            <div>
              <TableInfo
                withoutBox
                title="Recepción Animales en Pie Corrales"
                items={{
                  'Fecha / Hora Llegada Plata': '01/01/2019 02:00:00 pm',
                  'Fecha / Hora Ingreso Plata': '01/01/2019 02:00:00 pm',
                  'Fecha / Hora Salida Plata': '01/01/2019 02:00:00 pm',
                  'Fecha / Hora Inicio Descargue': '01/01/2019 02:00:00 pm',
                  'Fecha / Hora Final Descargue': '01/01/2019 02:00:00 pm',
                  'Tiquete de Báscula Nro.': '96741',
                  'Remisión Nro.': '765322',
                  'Animales Remisionados': '72',
                  'Animales Recibidos': '72',
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} xl={8}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Pesaje Animales en Pie
              </Typography>
            </div>
            <div>
              <DataGrid
                rows={rowsSecond}
                columns={columnsSecond}
                autoHeight
                className={classes.dataGrid}
              />
            </div>
            <div className={classes.flexContainer}>
              <div className={classes.marginBox}>
                <ItemTotalInfo
                  avatarTotalInfo={'7.320,00 Kg'}
                  titleInfo="Peso Total Animales en Pie"
                  avatarTotalColor="#F2F2F2"
                  avatarTotalSize={35}
                  avatarTextSize={16}
                  extended
                  titleInfoSize={200}
                  position="bottom"
                  titleInverted
                />
              </div>
              <div className={classes.marginBox}>
                <ItemTotalInfo
                  avatarTotalInfo={'120,8 Kg'}
                  titleInfo="Peso Promedio del Lote"
                  avatarTotalColor="#F2F2F2"
                  avatarTotalSize={35}
                  avatarTextSize={16}
                  extended
                  titleInfoSize={200}
                  position="bottom"
                  titleInverted
                />
              </div>
              <div className={classes.marginBox}>
                <ItemTotalInfo
                  avatarTotalInfo={'72'}
                  titleInfo="Total Animales en Pie Pesados"
                  avatarTotalColor="#F2F2F2"
                  avatarTotalSize={35}
                  avatarTextSize={16}
                  extended
                  titleInfoSize={200}
                  position="bottom"
                  titleInverted
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} xl={4}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Resumen Ante - Mortem
              </Typography>
            </div>
            <div>
              <TableInfo
                withoutBox
                title=""
                items={{
                  'Animales Agitados': '0',
                  'Animales Caídos': '0',
                  'Animales Muertos en Transporte': '0',
                  'Animales Muertos en Corrales': '0',
                  'Animales en Observación': '0',
                  'Animales Agitados en Corrales': '0',
                  'Animales Caídos en Corrales': '0',
                  'Animales Muertos en Desembarque': '0',
                  'Animales Muertos en Observación': '0',
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Clasificación SEUROP
              </Typography>
            </div>
            <div className={classes.chartContainer}>
              <Chart
                type="doughnut"
                withoutTotalInfo={true}
                withoutData={true}
                data={[
                  {
                    label: 'S - Superior',
                    data: 40,
                    bg: '#FFC676',
                  },
                  {
                    label: 'E - Excelente',
                    data: 30,
                    bg: '#F1AE6C',
                  },
                  {
                    label: 'U - Muy Buena - Estándar',
                    data: 10,
                    bg: '#E29662',
                  },
                  {
                    label: 'R - Buena',
                    data: 20,
                    bg: '#D47D57',
                  },
                  {
                    label: 'O - Menos Buena',
                    data: 5,
                    bg: '#BE5948',
                  },
                  {
                    label: 'P - Mediocre',
                    data: 5,
                    bg: '#B0413E',
                  },
                ]}
              />
            </div>
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Resumen Decomisos - Post Mortem
              </Typography>
            </div>
            <div className={classes.chartContainer}>
              <Chart
                type="doughnut"
                withoutTotalInfo={true}
                data={[
                  {
                    label: 'Pulmón',
                    data: 102,
                    bg: '#FFC676',
                  },
                  {
                    label: 'Piel',
                    data: 9,
                    bg: '#F1AE6C',
                  },
                  {
                    label: 'Vísceras Rojas',
                    data: 6,
                    bg: '#E29662',
                  },
                  {
                    label: 'Pierna',
                    data: 6,
                    bg: '#D47D57',
                  },
                  {
                    label: 'Corazón',
                    data: 2,
                    bg: '#BE5948',
                  },
                  {
                    label: 'Vísceras Blancas',
                    data: 2,
                    bg: '#B0413E',
                  },
                ]}
              />
            </div>
          </Grid>
          <Grid item xs={12} xl={6}>
            <div className={classes.grayContainer}>
              <DataGrid
                rows={rowsThree}
                columns={columnsThree}
                autoHeight
                className={classes.dataGrid}
              />

              <div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    avatarTotalInfo={'08'}
                    titleInfo="Total Canales con Grasa Dorsal Mayor a  18,5"
                    avatarTotalColor="#FFF"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={180}
                    position="top"
                    titleInverted
                  />
                </div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    avatarTotalInfo={'19,51%'}
                    titleInfo="Porcentaje de Canales con Grasa Dorsal Mayor a 18,5"
                    avatarTotalColor="#FFF"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInfoSize={180}
                    position="top"
                    titleInverted
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item xs={6} spacing={2}>
            <Grid item xs={6}>
              <div className={classes.grayContainer}>
                <Typography className={classes.yellowTypo}>0</Typography>
                <Typography className={classes.labelTypo}>
                  Total Canales Decomisadas
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.grayContainer}>
                <Typography className={classes.yellowTypo}>0%</Typography>
                <Typography className={classes.labelTypo}>
                  Canales Decomisadas
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.grayContainer}>
                <div className={classes.center}>
                  <Typography>Línea de Beneficio</Typography>
                  <TableInfo
                    withoutBox
                    title="Recepción Animales en Pie Corrales"
                    items={{
                      'Canales Decomisadas Completas': '0',
                      'Total (Kgs) Carne Decomisada': '0',
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.grayContainer}>
                <div className={classes.center}>
                  <Typography>Línea Emergencia</Typography>
                  <TableInfo
                    withoutBox
                    title="Recepción Animales en Pie Corrales"
                    items={{
                      'Canales Decomisadas Completas': '0',
                      'Total (Kgs) Carne Decomisada': '0',
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} xl={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Detalle Evaluación de Magro de Canales Calientes Porcinas
              </Typography>
              <Box position="absolute" right={8} m={2}>
                <ExportToExcel
                  data={rows}
                  headers={columns.map((columns: any) => columns.field)}
                  fileName="Detalle-Evaluación-Magro-Canales-Calientes"
                />
              </Box>
            </div>
            <div>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </ContentCard>
      <Grid container justify="flex-end" alignItems="center">
        <Box mr={2}>
          <Typography className={classes.colorUp}>
            Opciones del Reporte
          </Typography>
        </Box>
        <Button
          startIcon={<i className="icon-eye" />}
          variant="contained"
          className="raleway"
          color="primary"
          onClick={openModalFilterMagro}
        >
          Previsualizar
        </Button>
      </Grid>
      <DialogPDFViewer
        open={openFilterMagro}
        onClose={() => setOpenFilterMagro(false)}
        done={Boolean(imageQr)}
      >
        <MyDocMagro qr={imageQr} graph={imageGraph} graphTwo={imageGraphTwo} />
      </DialogPDFViewer>
    </div>
  );
};

export default index;
