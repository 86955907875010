export default (state: number) =>{

  const colors: any = {
    0: '#AFAFAF',
    1: '#767676',
    2: '#57E28C',
    3: '#ED8C2B',
    4: '#E2575C',
  };

  return colors[state] ?? '#fff';
} 