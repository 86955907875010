import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface FooterProps {}

const Footer = ({}: FooterProps) => {
  return (
    <View style={styles.copy}>
      <Text style={{ fontSize: 7, fontFamily: 'Roboto' }}>
        Ⓒ 1995- {new Date().getFullYear()} Todos los derechos están reservados
        de forma exclusiva para Mercadeo Virtual S.A. Los datos suministrados
        por el usuario son de carácter confidencial y reservado, se regirán
        acorde a los términos de la Política de Confidencialidad y Reserva
        definida por la empresa Mercadeo Virtual S.A y la legislacion existente
        en Colombia sobre el registro, manejo y confidencialidad de los datos
        (Habeas Data).
      </Text>
      <Text style={styles.phone}>
        Teléfono (+574) 3228 603 | Correo: soporte@infoporcinos.com | Medellín
        Colombia
      </Text>
      <Text
        style={{ fontFamily: 'Roboto', fontWeight: 'bold', color: 'black' }}
      >
        www.infoporcinos.com
      </Text>
    </View>
  );
};

export default Footer;
