import Main from './Main';
import Details from './Details';
import Search from './Search'; 
import { NestedRoute } from 'lib/interfaces/routes';


const routes: NestedRoute[] = [
];

export {
  Main,
  Search,
  Details, 
  routes
}