import {
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Icon } from 'semantic-ui-react';
import domtoimage from 'dom-to-image';
import moment from 'moment';
import _ from 'lodash';

import { getDetails } from 'api/entranceReportService';

import * as userActions from 'core/redux/actions/userActions';

import DialogPDFViewer from 'components/DialogPDFViewer';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import LoteInfoTitle from 'components/LoteInfoTitle';
import ContentCard from 'components/ContentCard';
import GobackLink from 'components/GobackLink';
import AgentCard from 'components/AgentCard';
import Loader from 'components/Loader';

import formatNumber from 'lib/helpers/formatNumber';
import useSetToken from 'lib/hooks/useSetToken';
import useSnackbar from 'lib/hooks/useSnackbar';
import useSignOut from 'lib/hooks/useSignOut';
import PageInfo from 'lib/ui/PageInfo';

import { ReportLot, MyDoc } from '../../Reports/ReportLot';
import { EntranceReportContent } from './styles';

const Search = ({ signOut }: any) => {
  const {
    params: { id: reportId },
  } = useRouteMatch<{ id: string }>();
  const { selectedUserType, user, token } = useSelector(
    (state: any) => state.user
  );
  const [userType] = useState(selectedUserType.name.toLowerCase());
  const setToken = useSetToken();
  const [qr, setQr] = useState<string>();
  const { expiredSession } = useSignOut();

  const { handleOpenSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(true);

  const [dataEntrancegetData, setDataEntrancegetData] = useState<any>({});
  const [dataGeneralgetData, setDataGeneralgetData] = useState<any>({});
  const [dataCorralsgetData, setDataCorralsgetData] = useState<any>({});
  const openModal = () => {
    generateScreenshot();
    setOpen(true);
  };

  const pageBreadcrums: BreadcrumbProps[] = [
    { link: '/app/reports/entrance', title: 'Portería' },
    { link: '/app/reports', title: 'Mis Reportes' },
  ];

  const generateScreenshot = () => {
    var node: any = document.getElementById('screen');
    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        setQr(dataUrl);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  useEffect(() => {
    getDetails(
      {
        idLoteIP: Number.parseInt(reportId),
        seccion: 'datos porteria',
        idUsuario: user.id,
        tipoUsuario: selectedUserType.name,
      },
      token
    )
      .then((data) => {
        if (expiredSession(data.StatusCode)) {
          setDataEntrancegetData(data.Data);
          getDetails(
            {
              idLoteIP: Number.parseInt(reportId),
              seccion: 'datos generales',
              idUsuario: user.id,
              tipoUsuario: selectedUserType.name,
            },
            token
          )
            .then((data) => {
              setDataGeneralgetData(data.Data);
              getDetails(
                {
                  idLoteIP: Number.parseInt(reportId),
                  seccion: 'corrales',
                  idUsuario: user.id,
                  tipoUsuario: selectedUserType.name,
                },
                token
              )
                .then((data) => {
                  setDataCorralsgetData(data.Data);
                  setToken(data.Token.TokenNuevo);
                })
                .catch((err) => console.error(err))
                .finally(() => {
                  setLoad(false);
                });
            })
            .catch((err) => console.error(err));
        }
      })
      .catch((err: any) => {
        console.error(err);
        setLoad(false);
      });
  }, []);

  return (
    <EntranceReportContent>
      <Loader visible={load}>Cargando</Loader>
      <div>
        <div style={{ position: 'absolute', left: '-1000%' }}>
          {!load && <ReportLot />}
        </div>
        <PageInfo>
          <GobackLink />
          <Breadcrumb breadcrumbs={pageBreadcrums} />
        </PageInfo>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ContentCard padding="2% 3%" marginTop={0}>
              <LoteInfoTitle
                title="Reporte Técnico de Portería"
                lot={dataGeneralgetData?.CodigoLote}
                namereport="Reporte Nro."
                numberreport="1213"
              />
            </ContentCard>
          </Grid>
          <Grid item xs={6}>
            <AgentCard agent={_.startCase(userType)} icon={userType}>
              <Table className="table-agent" size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="right">
                      <b>
                        {dataEntrancegetData &&
                          _.startCase(
                            dataEntrancegetData[
                              userType
                            ]?.nombreCompleto?.toLowerCase()
                          )}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>NIT</TableCell>
                    <TableCell align="right">
                      <b>
                        {dataEntrancegetData &&
                          dataEntrancegetData[userType]?.numeroDocumento}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Teléfono</TableCell>
                    <TableCell align="right">
                      <b>
                        {dataEntrancegetData &&
                          dataEntrancegetData[userType]?.telefono1}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Celular</TableCell>
                    <TableCell align="right">
                      <b>
                        {dataEntrancegetData &&
                          dataEntrancegetData[userType]?.celular1}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">
                      <b>
                        {dataEntrancegetData &&
                          dataEntrancegetData[userType]?.email}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dirección</TableCell>
                    <TableCell align="right">
                      <b>
                        {dataEntrancegetData &&
                          dataEntrancegetData[userType]?.direccion}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ciudad</TableCell>
                    <TableCell align="right">
                      <b>
                        {dataEntrancegetData &&
                          _.startCase(
                            `${dataEntrancegetData[
                              userType
                            ]?.ciudad?.toLowerCase()}, ${dataEntrancegetData[
                              userType
                            ]?.pais?.toLowerCase()}`
                          )}
                      </b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </AgentCard>
          </Grid>
          <Grid item xs={6}>
            <AgentCard agent="Planta Beneficio" icon="planta">
              <Table className="table-agent" size="small">
                <TableBody>
                  {[
                    {
                      column1: 'Nombre',
                      column2: _.startCase(
                        dataEntrancegetData?.planta?.razonSocial?.toLowerCase()
                      ),
                    },
                    {
                      column1: 'NIT',
                      column2: dataEntrancegetData?.planta?.nit,
                    },
                    {
                      column1: 'Teléfono',
                      column2:
                        dataEntrancegetData.planta &&
                        dataEntrancegetData.planta.telefono1 === '0'
                          ? 'N.D'
                          : dataEntrancegetData?.planta?.telefono1,
                    },
                    {
                      column1: 'Celular',
                      column2:
                        dataEntrancegetData.planta &&
                        (dataEntrancegetData.planta.celular1 === '0' ||
                          dataEntrancegetData.planta.celular1.length > 10 ||
                          dataEntrancegetData.planta.celular1.length < 10)
                          ? 'N.D'
                          : dataEntrancegetData?.planta?.celular1,
                    },
                    {
                      column1: 'Email',
                      column2: dataEntrancegetData?.planta?.email?.toLowerCase(),
                    },
                    {
                      column1: 'Dirección',
                      column2: dataEntrancegetData?.planta?.direccion,
                    },
                    {
                      column1: 'Ciudad',
                      column2: `${_.startCase(
                        dataEntrancegetData?.planta?.ciudad?.toLowerCase()
                      )}, ${_.startCase(
                        dataEntrancegetData?.planta?.pais?.toLowerCase()
                      )}`,
                    },
                  ].map((index) => {
                    return (
                      <TableRow>
                        <TableCell>{index.column1}</TableCell>
                        <TableCell align="right">
                          <b>{index.column2}</b>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </AgentCard>
          </Grid>
          <Grid item xs={6}>
            <AgentCard agent="Granja" icon="granja">
              <Table className="table-agent" size="small">
                <TableBody>
                  {[
                    {
                      column1: 'Nombre',
                      column2:
                        dataEntrancegetData.granja &&
                        _.startCase(
                          _.capitalize(dataEntrancegetData.granja.nombre)
                        ),
                    },
                    {
                      column1: 'Responsable',
                      column2:
                        dataEntrancegetData.granja &&
                        dataEntrancegetData.granja.responsableGranja === 'Null'
                          ? 'N.D.'
                          : _.startCase(
                              _.capitalize(
                                dataEntrancegetData.granja &&
                                  dataEntrancegetData.granja.responsableGranja
                              )
                            ),
                    },
                    {
                      column1: 'Teléfono',
                      column2:
                        dataEntrancegetData.granja &&
                        (dataEntrancegetData.granja.telefono === '0' ||
                          dataEntrancegetData.granja.telefono === 'Null')
                          ? 'N.D.'
                          : dataEntrancegetData.granja &&
                            dataEntrancegetData.granja.telefono,
                    },
                    { column1: 'Email', column2: 'N.D.' },
                    {
                      column1: 'Dirección',
                      column2:
                        dataEntrancegetData.granja &&
                        _.startCase(
                          _.capitalize(dataEntrancegetData.granja.direccion)
                        ),
                    },
                    {
                      column1: 'Ciudad',
                      column2:
                        dataEntrancegetData.granja &&
                        _.startCase(
                          dataEntrancegetData.granja.ciudad.toLowerCase()
                        ) +
                          ', ' +
                          _.startCase(
                            dataEntrancegetData.granja.pais.toLowerCase()
                          ),
                    },
                  ].map((index) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <TableRow>
                        <TableCell>{index.column1}</TableCell>
                        <TableCell align="right">
                          <b>{index.column2}</b>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </AgentCard>
          </Grid>
          <Grid item xs={6}>
            <AgentCard agent="Transporte" icon="truck">
              <Table className="table-agent" size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Conductor</TableCell>
                    <TableCell align="right" className="bold">
                      <b>
                        {_.startCase(
                          dataEntrancegetData?.datosGeneralesPorteria?.nombreConductor?.toLowerCase()
                        )}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Documento</TableCell>
                    <TableCell align="right" className="bold">
                      <b>N.D</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Placa Vehículo</TableCell>
                    <TableCell align="right" className="bold">
                      <b>
                        {
                          dataEntrancegetData?.datosGeneralesPorteria
                            ?.placaVehiculo
                        }
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ciudad Placa</TableCell>
                    <TableCell align="right" className="bold">
                      <b>N.D</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Vehiculo</TableCell>
                    <TableCell align="right" className="bold">
                      <b>N.D</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </AgentCard>
          </Grid>
          <Grid item xs={12}>
            <ContentCard padding="2%" marginTop={0}>
              <ContentCard.Header>
                Resumen Transporte Animales en Pie
              </ContentCard.Header>
              <ContentCard.Content>
                <Grid container>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="center"
                    xs={4}
                  >
                    <div className="numbercontainer">
                      <div className="number">
                        {dataGeneralgetData?.cantidadAnimalesLote || 0}
                      </div>
                      <p>Total Animales en Pie</p>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Table className="table-present" size="small">
                      <TableBody>
                        {[
                          {
                            column1: 'Peso Total Lote',
                            column2: !dataCorralsgetData?.DatosPesaje
                              ?.pesoTotalDeTodosLosPesajes
                              ? '0'
                              : formatNumber(
                                  dataCorralsgetData?.DatosPesaje
                                    ?.pesoTotalDeTodosLosPesajes
                                ) +
                                ' ' +
                                'Kg',
                            icon: 'icon-weight',
                          },
                          {
                            column1: 'Peso Promedio',
                            column2: !dataCorralsgetData?.DatosPesaje
                              ?.promedioPesoTotal
                              ? '0'
                              : Number(
                                  dataCorralsgetData?.DatosPesaje
                                    ?.pesoTotalDeTodosLosPesajes /
                                    dataCorralsgetData?.DatosPesaje
                                      ?.cantidadAnimalesPesados
                                ).toFixed(2) + ' Kg',
                            icon: 'icon-pig-right',
                          },
                          {
                            column1: ' Nro. Guía',
                            column2:
                              dataEntrancegetData.GSMI &&
                              dataEntrancegetData.GSMI.numeroGSMI,
                            icon: 'icon-ticket',
                          },
                        ].map((index, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell>
                                <Icon className={index.icon} size="big" />
                                {index.column1}
                              </TableCell>
                              <TableCell align="right">
                                <b>{index.column2}</b>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs={4}>
                    <Table className="table-present" size="small">
                      <TableBody>
                        {[
                          {
                            column1: 'Llegada Planta',
                            column2: moment(
                              dataEntrancegetData.datosGeneralesPorteria &&
                                dataEntrancegetData.datosGeneralesPorteria
                                  .fechaLlegadaPlanta,
                              'YYYY-MM-DD hh:mm:ss'
                            ).format('DD/MM/YYYY hh:mm a'),
                            icon: 'icon-planta-check',
                          },
                          {
                            column1: 'Salida Planta',
                            column2: moment(
                              dataEntrancegetData.datosGeneralesPorteria &&
                                dataEntrancegetData.datosGeneralesPorteria
                                  .fechaSalida,
                              'YYYY-MM-DD hh:mm:ss'
                            ).format('DD/MM/YYYY hh:mm a'),
                            icon: 'icon-sign-out',
                          },
                          {
                            column1: 'Tiempo Total Estancia',
                            column2:
                              dataEntrancegetData.datosGeneralesPorteria &&
                              dataEntrancegetData.datosGeneralesPorteria
                                .tiempoPorteria,
                            icon: 'icon-clock',
                          },
                        ].map((index, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell>
                                <Icon className={index.icon} size="big" />
                                {index.column1}
                              </TableCell>
                              <TableCell align="right">
                                <b>{index.column2}</b>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </ContentCard.Content>
            </ContentCard>
          </Grid>
        </Grid>
        <Box paddingTop={3} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            startIcon={<VisibilityOutlinedIcon />}
            onClick={() => openModal()}
          >
            Previsualizar
          </Button>
          <DialogPDFViewer
            open={open}
            onClose={() => setOpen(false)}
            done={
              qr &&
              dataEntrancegetData &&
              dataGeneralgetData &&
              dataCorralsgetData
            }
          >
            <MyDoc
              qr={qr ?? ''}
              dataGeneral={dataGeneralgetData}
              dataEntrance={dataEntrancegetData}
              dataCorrals={dataCorralsgetData}
              uid={user.id}
            />
          </DialogPDFViewer>
        </Box>
      </div>
    </EntranceReportContent>
  );
};

export default connect(null, userActions)(Search);
