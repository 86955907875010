import React, { useEffect } from 'react';
import {
  Typography,
  Container,
  Grid,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import Chart from 'components/Chart';
import ItemListInfo from 'components/ItemListInfo';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

const Dashboard = () => {
  const classes = useStyles();
  dayjs.extend(isoWeek);
  const [seurop, setSeurop] = React.useState('weekly');
  const [evaluate, setEvaluate] = React.useState('weekly');
  const [measurement, setMeasurement] = React.useState('weekly');

  const handleChangeSeurop = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeurop((event.target as HTMLInputElement).value);
  };
  const handleChangeEvaluate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEvaluate((event.target as HTMLInputElement).value);
  };
  const handlechangeMeasurement = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMeasurement((event.target as HTMLInputElement).value);
  };

  var weekYear = require('dayjs/plugin/weekYear');
  var weekOfYear = require('dayjs/plugin/weekOfYear');
  dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);

  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/dashboard', title: 'Inicio' },
  ];

  return (
    <Container className={classes.root}>
      <div>
        <div className={classes.breadContainer}>
          <Breadcrumb breadcrumbs={pageBreadcrumbs} />
        </div>
        <div>
          <Typography className={classes.subtitleTitle}>
            Resumen Sistema Infoporcinos del día
          </Typography>
        </div>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.containerItemGrid}
          >
            <ContentCard className={classes.contentCard}>
              <div
                className={clsx(
                  classes.centerContainer,
                  classes.centerContainerColumn
                )}
              >
                <i className={clsx('icon-pig-right', classes.icon)}></i>
                <Typography className={classes.labelTitle}>
                  Total Animales Ingresados
                </Typography>
                <Typography className={classes.labelNumber}>1783</Typography>
              </div>
            </ContentCard>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.containerItemGrid}
          >
            <ContentCard className={classes.contentCard}>
              <div
                className={clsx(
                  classes.centerContainer,
                  classes.centerContainerColumn
                )}
              >
                <i className={clsx('icon-ticket', classes.icon)}></i>
                <Typography className={classes.labelTitle}>
                  Total Lotes Ingresados
                </Typography>
                <Typography className={classes.labelNumber}>48</Typography>
              </div>
            </ContentCard>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.containerItemGrid}
          >
            <ContentCard className={classes.contentCard}>
              <div
                className={clsx(
                  classes.centerContainer,
                  classes.centerContainerColumn
                )}
              >
                <i className={clsx('icon-canal', classes.icon)}></i>
                <Typography className={classes.labelTitle}>
                  Total Canales Evaluadas
                </Typography>
                <Typography className={classes.labelNumber}>1753</Typography>
              </div>
            </ContentCard>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.containerItemGrid}
          >
            <ContentCard className={classes.contentCard}>
              <div
                className={clsx(
                  classes.centerContainer,
                  classes.centerContainerColumn
                )}
              >
                <i className={clsx('icon-truck', classes.icon)}></i>
                <Typography className={classes.labelTitle}>
                  Total Canales Despachadas
                </Typography>
                <Typography className={classes.labelNumber}>1600</Typography>
              </div>
            </ContentCard>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.containerItemGrid}
          >
            <ContentCard className={classes.contentCard}>
              <div
                className={clsx(
                  classes.centerContainer,
                  classes.centerContainerColumn
                )}
              >
                <div className={classes.containerItemGrid}>
                  <Typography className={classes.labelNumber}>
                    486.99
                  </Typography>
                  <Typography className={classes.labelNumberBar}>
                    {' ‏‏‎ ‎‎‎‎m.m'}
                  </Typography>
                </div>

                <Typography className={classes.labelTitle}>
                  {'Promedio Porcentaje Magro (% MC)'}
                </Typography>
              </div>
            </ContentCard>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.containerItemGrid}
          >
            <ContentCard className={classes.contentCard}>
              <div
                className={clsx(
                  classes.centerContainer,
                  classes.centerContainerColumn
                )}
              >
                <div className={classes.containerItemGrid}>
                  <Typography className={classes.labelNumber}>
                    288.99
                  </Typography>
                  <Typography className={classes.labelNumberBar}>
                    {' ‏‏‎ ‎‎‎‎m.m'}
                  </Typography>
                </div>

                <Typography className={classes.labelTitle}>
                  {'Promedio Grasa Dorsal (GD)'}
                </Typography>
              </div>
            </ContentCard>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.containerItemGrid}
          >
            <ContentCard className={classes.contentCard}>
              <div
                className={clsx(
                  classes.centerContainer,
                  classes.centerContainerColumn
                )}
              >
                <div className={classes.containerItemGrid}>
                  <Typography className={classes.labelNumber}>36.99</Typography>
                  <Typography className={classes.labelNumberBar}>
                    {' ‏‏‎ ‎‎‎‎m.m'}
                  </Typography>
                </div>
                <Typography className={classes.labelTitle}>
                  {'Promedio Lomo (LM)'}
                </Typography>
              </div>
            </ContentCard>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.containerItemGrid}
          >
            <ContentCard className={classes.contentCard}>
              <div
                className={clsx(
                  classes.centerContainer,
                  classes.centerContainerColumn
                )}
              >
                <div className={classes.containerItemGrid}>
                  <Typography className={classes.labelNumber}>
                    106.10
                  </Typography>
                  <Typography className={classes.labelNumberBar}>
                    {' ‏‏‎ ‎‎‎‎Kg'}
                  </Typography>
                </div>
                <Typography className={classes.labelTitle}>
                  {'Promedio Pesaje (PCC)'}
                </Typography>
              </div>
            </ContentCard>
          </Grid>
        </Grid>
      </div>
      <div className={classes.subContainer}>
        <Typography className={classes.subtitleTitle}>
          Mi Resumen del Día
        </Typography>
      </div>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.iconPadding}>
                  <ItemListInfo
                    titleInfo="Total Animales Ingresados"
                    numberInfo="86"
                    icon="icon-pig"
                  />
                </div>
                <div className={classes.iconPadding}>
                  <ItemListInfo
                    titleInfo="Total Lotes Ingresados"
                    numberInfo="02"
                    icon="icon-ticket"
                  />
                </div>
                <div className={classes.iconPadding}>
                  <ItemListInfo
                    titleInfo="Total Canales Evaluadas"
                    numberInfo="93"
                    icon="icon-canal"
                  />
                </div>
                <div className={classes.iconPadding}>
                  <ItemListInfo
                    titleInfo="Total Canales Despachadas"
                    numberInfo="34"
                    icon="icon-truck"
                  />
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Clasificación SEUROP
                  </Typography>
                  {seurop === 'today' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('dddd, D MMMM YYYY')}
                    </Typography>
                  ) : seurop === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : seurop ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().isoWeek() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <RadioGroup
                    aria-label="timer"
                    name="timer1"
                    value={seurop}
                    onChange={handleChangeSeurop}
                  >
                    <div className={classes.centerContainer}>
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="today"
                        control={<Radio color="primary" />}
                        label="Hoy"
                      />
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="weekly"
                        control={<Radio color="primary" />}
                        label="Semanal"
                      />
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="monthly"
                        control={<Radio color="primary" />}
                        label="Mensual"
                      />
                    </div>
                  </RadioGroup>
                  {seurop === 'today' ? (
                    <div className={classes.chartContainer}>
                      <Chart
                        type="doughnut"
                        withoutTotalInfo={true}
                        data={[
                          {
                            label: 'S - Superior',
                            data: 50,
                            bg: '#FFC676',
                          },
                          {
                            label: 'E - Excelente',
                            data: 20,
                            bg: '#F1AE6C',
                          },
                          {
                            label: 'U - Muy Buena - Estándar',
                            data: 5,
                            bg: '#E29662',
                          },
                          {
                            label: 'R - Buena',
                            data: 10,
                            bg: '#D47D57',
                          },
                          {
                            label: 'O - Menos Buena',
                            data: 2,
                            bg: '#BE5948',
                          },
                          {
                            label: 'P - Mediocre',
                            data: 5,
                            bg: '#B0413E',
                          },
                        ]}
                      />
                      <div className={classes.containerButton}>
                        <Button className={classes.buttonDashboard}>
                          Ver más
                        </Button>
                      </div>
                    </div>
                  ) : seurop === 'monthly' ? (
                    <div className={classes.chartContainer}>
                      <Chart
                        type="doughnut"
                        withoutTotalInfo={true}
                        data={[
                          {
                            label: 'S - Superior',
                            data: 60,
                            bg: '#FFC676',
                          },
                          {
                            label: 'E - Excelente',
                            data: 50,
                            bg: '#F1AE6C',
                          },
                          {
                            label: 'U - Muy Buena - Estándar',
                            data: 30,
                            bg: '#E29662',
                          },
                          {
                            label: 'R - Buena',
                            data: 50,
                            bg: '#D47D57',
                          },
                          {
                            label: 'O - Menos Buena',
                            data: 25,
                            bg: '#BE5948',
                          },
                          {
                            label: 'P - Mediocre',
                            data: 25,
                            bg: '#B0413E',
                          },
                        ]}
                      />
                      <div className={classes.containerButton}>
                        <Button className={classes.buttonDashboard}>
                          Ver más
                        </Button>
                      </div>
                    </div>
                  ) : seurop ? (
                    <div className={classes.chartContainer}>
                      <Chart
                        type="doughnut"
                        withoutTotalInfo={true}
                        data={[
                          {
                            label: 'S - Superior',
                            data: 40,
                            bg: '#FFC676',
                          },
                          {
                            label: 'E - Excelente',
                            data: 30,
                            bg: '#F1AE6C',
                          },
                          {
                            label: 'U - Muy Buena - Estándar',
                            data: 10,
                            bg: '#E29662',
                          },
                          {
                            label: 'R - Buena',
                            data: 20,
                            bg: '#D47D57',
                          },
                          {
                            label: 'O - Menos Buena',
                            data: 5,
                            bg: '#BE5948',
                          },
                          {
                            label: 'P - Mediocre',
                            data: 5,
                            bg: '#B0413E',
                          },
                        ]}
                      />
                      <div className={classes.containerButton}>
                        <Button className={classes.buttonDashboard}>
                          Ver más
                        </Button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Pesaje Canal Caliente
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <Chart
                      type="bar"
                      data={[
                        {
                          label: 'Promedio Pesaje',
                          data: [1, 1, 10, 10, 320, 80, 10],
                          bg: '#E29662',
                        },
                      ]}
                      graphicSize={600}
                      labels={[
                        '70/80 Kg',
                        '80/90 Kg.',
                        '90/100 Kg.',
                        '100/110 Kg.',
                        '110/120 Kg.',
                        '120/130 Kg.',
                        '130/140 Kg.',
                      ]}
                      max={400}
                      position={'top'}
                    />
                  </div>
                </div>
                <div className={classes.containerButton}>
                  <Button className={classes.buttonDashboard}>Ver más</Button>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Lotes y Canales Evaluadas
                  </Typography>
                  {evaluate === 'today' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('dddd, D MMMM YYYY')}
                    </Typography>
                  ) : evaluate === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : evaluate ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().isoWeek() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <RadioGroup
                    aria-label="timer"
                    name="timer2"
                    value={evaluate}
                    onChange={handleChangeEvaluate}
                  >
                    <div className={classes.centerContainer}>
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="today"
                        control={<Radio color="primary" />}
                        label="Hoy"
                      />
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="weekly"
                        control={<Radio color="primary" />}
                        label="Semanal"
                      />
                      <FormControlLabel
                        style={{
                          color: '#AFAFAF',
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        value="monthly"
                        control={<Radio color="primary" />}
                        label="Mensual"
                      />
                    </div>
                  </RadioGroup>
                </div>
                {evaluate === 'today' ? (
                  <div className={classes.chartContainer}>
                    <Chart
                      type="bar"
                      data={[
                        {
                          label: 'Total Canales',
                          data: [20, 55, 75, 75, 50, 90, 75],
                          bg: '#FFC676',
                        },
                        {
                          label: 'Total Lotes',
                          data: [1, 2, 20, 18, 5, 25, 20],
                          bg: '#D47D57',
                        },
                      ]}
                      graphicSize={550}
                      labels={[
                        'Dom.',
                        'Lun.',
                        'Mar.',
                        'Miér.',
                        'Jue.',
                        'Vie.',
                        'Sab.',
                      ]}
                      max={100}
                      position={'top'}
                    />
                  </div>
                ) : evaluate === 'monthly' ? (
                  <div className={classes.chartContainer}>
                    <Chart
                      type="bar"
                      data={[
                        {
                          label: 'Total Canales',
                          data: [10, 25, 95, 95, 25, 45, 75],
                          bg: '#FFC676',
                        },
                        {
                          label: 'Total Lotes',
                          data: [2, 5, 40, 36, 15, 55, 20],
                          bg: '#D47D57',
                        },
                      ]}
                      graphicSize={550}
                      labels={[
                        'Dom.',
                        'Lun.',
                        'Mar.',
                        'Miér.',
                        'Jue.',
                        'Vie.',
                        'Sab.',
                      ]}
                      max={100}
                      position={'top'}
                    />
                  </div>
                ) : evaluate ? (
                  <div className={classes.chartContainer}>
                    <Chart
                      type="bar"
                      data={[
                        {
                          label: 'Total Canales',
                          data: [50, 24, 25, 75, 50, 100, 75],
                          bg: '#FFC676',
                        },
                        {
                          label: 'Total Lotes',
                          data: [10, 20, 2, 2, 5, 25, 12],
                          bg: '#D47D57',
                        },
                      ]}
                      graphicSize={550}
                      labels={[
                        'Dom.',
                        'Lun.',
                        'Mar.',
                        'Miér.',
                        'Jue.',
                        'Vie.',
                        'Sab.',
                      ]}
                      max={100}
                      position={'top'}
                    />
                  </div>
                ) : (
                  ''
                )}
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Resumen Proceso Medición
                  </Typography>
                  {measurement === 'today' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('dddd, D MMMM YYYY')}
                    </Typography>
                  ) : measurement === 'monthly' ? (
                    <Typography className={classes.dateResume}>
                      {dayjs().format('MMMM')}
                    </Typography>
                  ) : measurement ? (
                    <Typography className={classes.dateResume}>
                      {'Semana' +
                        ' ' +
                        dayjs().isoWeek() +
                        ' ' +
                        'del' +
                        ' ' +
                        dayjs().year()}
                    </Typography>
                  ) : (
                    ''
                  )}
                </div>

                <RadioGroup
                  aria-label="timer"
                  name="timer3"
                  value={measurement}
                  onChange={handlechangeMeasurement}
                >
                  <div className={classes.centerContainer}>
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="today"
                      control={<Radio color="primary" />}
                      label="Hoy"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="weekly"
                      control={<Radio color="primary" />}
                      label="Semanal"
                    />
                    <FormControlLabel
                      style={{
                        color: '#AFAFAF',
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      value="monthly"
                      control={<Radio color="primary" />}
                      label="Mensual"
                    />
                  </div>
                </RadioGroup>

                {measurement === 'today' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            268.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (GD)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            425.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            23.56
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (LM)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            105.95
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎Kg'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Pesaje (PPC)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : measurement === 'monthly' ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            298.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (GD)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            487.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            44.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (LM)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            115.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎Kg'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Pesaje (PPC)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : measurement ? (
                  <div className={classes.flexContainer}>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            288.49
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Grasa Dorsal (GD)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            486.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Porcentaje de Magro (MC)'}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.centerContainer,
                        classes.centerContainerColumn
                      )}
                    >
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            34.99
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎m.m'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Lomo (LM)'}
                        </Typography>
                      </div>
                      <div
                        className={clsx(
                          classes.centerContainer,
                          classes.centerContainerColumn
                        )}
                      >
                        <div className={classes.containerItemGrid}>
                          <Typography className={classes.labelNumber}>
                            110.50
                          </Typography>
                          <Typography className={classes.labelNumberBar}>
                            {' ‏‏‎ ‎‎‎‎Kg'}
                          </Typography>
                        </div>
                        <Typography className={classes.labelTitle}>
                          {'Promedio Pesaje (PPC)'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.titleBoxContainer}>
                  <Typography className={classes.subtitleTitleResume}>
                    Ingresos del día
                  </Typography>
                </div>

                <div className={classes.iconPadding}>
                  <ItemListInfo
                    titleInfo="Total Listas de Distribución"
                    numberInfo="34"
                    icon="icon-truck"
                  />
                </div>
                <div className={classes.iconPadding}>
                  <ItemListInfo
                    titleInfo="Total Canales Disponibles"
                    numberInfo="34"
                    icon="icon-truck"
                  />
                </div>
                <div className={classes.iconPadding}>
                  <ItemListInfo
                    titleInfo="Total Listas Despachadas"
                    numberInfo="34"
                    icon="icon-truck"
                  />
                </div>

                <div className={classes.containerButton}>
                  <Button className={classes.buttonDashboard}>Ver más</Button>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
