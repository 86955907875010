import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface ItemTotalCircleProps {
  value: string | number;
  info?: string;
  backgroundColor?: string;
  position?: 'bottom' | 'right';
  widthInfo?: number | string;
  widthCircle?: number | string;
  fontSizeValue?: number | string;
  textAlign?: 'left' | 'center' | 'right';
}

const ItemTotalCircle = ({
  value,
  info,
  backgroundColor,
  position,
  widthInfo,
  widthCircle,
  fontSizeValue,
  textAlign,
}: ItemTotalCircleProps) => {
  return (
    <View
      style={{
        alignItems: 'center',
        flexDirection: position == 'bottom' ? 'column' : 'row',
      }}
    >
      <View
        style={[
          styles.backgroundweigth,
          {
            backgroundColor: `${
              backgroundColor ? (backgroundColor as String) : '#ededed'
            }`,
            width: `${widthCircle ? widthCircle : 30}`,
            height: `${widthCircle ? widthCircle : 30}`,
          },
        ]}
      >
        <Text
          style={{
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            fontSize: fontSizeValue ? fontSizeValue : 15,
          }}
        >
          {value}
        </Text>
      </View>
      {info && (
        <Text
          style={[
            styles.weigthanimals,
            {
              marginLeft: `${position == 'right' ? '10px' : '5px'}`,
              width: `${widthInfo ? widthInfo : '50px'}`,
              textAlign: textAlign ? textAlign : 'center',
            },
          ]}
        >
          {info}
        </Text>
      )}

      {/* <Text
        style={[
          styles.weigthanimals,
          {
            marginLeft: `${position == 'right' ? '10px' : '5px'}`,
            width: `${widthInfo ? widthInfo : '50px'}`,
            textAlign: textAlign ? textAlign : 'center',
          },
        ]}
      >
        {info}
      </Text> */}
    </View>
  );
};

export default ItemTotalCircle;
