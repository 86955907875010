import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
  Grow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

import constants from 'config/constants';

import PhoneNumber from 'components/PhoneNumber';
import DocumentTypes from 'components/DocumentTypes';
import CardIcon from 'components/CardIcon';

import unmaskPhone from 'lib/helpers/unmaskPhone';
import calculateCodVerification from 'lib/helpers/calculateCodVerification';
import {
  sentInvitationBusinessValidations,
  sentInvitationPersonValidations,
} from 'lib/validations/InvitationValidations';
import { INVITE_CLIENT } from 'core/graphql/mutations/inviteClient';

import useStyles from './styles';
import MainTitle from 'components/MainTitle';

type DialogInviteClientProps = {
  open: boolean;
  handleSend: any;
  handleClose: any;
};

const DialogInviteClient = ({
  open,
  handleSend,
  handleClose,
}: DialogInviteClientProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const token = useSelector((state: any) => state.user.token);
  const [stepOne, setStepOne] = useState(0);
  const [stepTwo, setStepTwo] = useState(0);

  const [invitation, { loading }] = useMutation(INVITE_CLIENT, {
    context: { headers: { 'x-auth-jwt': token } },
    onError(error) {
      enqueueSnackbar(error.message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'error',
      });
    },
  });

  const sendInvitation = (data: any) => {
    invitation({
      update: (proxy, mutationResult) => {
        if (
          mutationResult?.data?.postRequestUserInvitation?.data?.success === 1
        ) {
          enqueueSnackbar(
            mutationResult?.data?.postRequestUserInvitation?.data?.message,
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }
          );
          // handleSend();
          handleClose();
        } else {
          enqueueSnackbar(mutationResult.data.postDriver.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        idTipoDocumento: stepTwo === 1 ? data.documentType : 3,
        numeroDocumento:
          stepTwo === 1 ? String(data.document) : String(data.nit),
        digitoVerificacion: stepTwo === 1 ? 0 : data.digitoVerificacion,
        razonSocial: data.businessName ?? '',
        primerNombre: data.firtName ?? '',
        segundoNombre: data.secondName ?? '',
        primerApellido: data.firtSurname ?? '',
        segundoApellido: data.secondSurname ?? '',
        indicativoTelefono: data.indicativePhone ?? 0,
        telefono: data.phone ?? '',
        extension: data.ext ?? '',
        indicativoCelular: data.indicative ?? 0,
        celular: data.mobile ?? '',
        email: data.email ?? '',
        idCiudad: 0,
        idTipoRelacion: stepOne,
      },
    });
  };

  useEffect(() => {
    if (open) {
      setStepOne(0);
      setStepTwo(0);
      formik.resetForm();
      formikCompany.resetForm();
    }
  }, [open]);

  const formik = useFormik({
    initialValues: {
      firtName: '',
      secondName: '',
      firtSurname: '',
      secondSurname: '',
      document: '',
      documentType: 1,
      indicative: constants.DEFAULT_COUNTRY_ID,
      mobile: '',
      email: '',
    },
    validationSchema: sentInvitationPersonValidations,
    onSubmit: (values) => {
      sendInvitation(values);
    },
  });

  const formikCompany = useFormik({
    initialValues: {
      businessName: '',
      nit: '',
      digitoVerificacion: '',
      indicativePhone: constants.DEFAULT_DEPARTMENT_ID,
      phone: '',
      ext: '',
      indicative: constants.DEFAULT_COUNTRY_ID,
      mobile: '',
      email: '',
    },
    validationSchema: sentInvitationBusinessValidations,
    onSubmit: (values) => {
      sendInvitation(values);
    },
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      className={classes.borderRadiudDialog}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Grid container>
          <Grid container justify="flex-end">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container className={classes.title}>
            <MainTitle>Envío de Invitaciones</MainTitle>
          </Grid>
        </Grid>
      </DialogTitle>
      <form
        onSubmit={
          stepTwo === 1 ? formik.handleSubmit : formikCompany.handleSubmit
        }
      >
        <DialogContent>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item container>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <DialogContentText>
                  <Typography className={classes.alignText}>
                    Defina el típo de relación que tiene con el usuario a
                    invitar:
                  </Typography>
                </DialogContentText>
              </Grid>

              <Grow in timeout={800}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Box mr={4} mt={2} mb={2}>
                    <CardIcon
                      icon="icon-productor"
                      title="Productor"
                      chacked={stepOne === 1}
                      onClick={() => setStepOne(1)}
                    />
                  </Box>

                  <Box>
                    <CardIcon
                      icon="icon-comercializador"
                      title="Comercializador"
                      chacked={stepOne === 2}
                      onClick={() => setStepOne(2)}
                    />
                  </Box>
                </Grid>
              </Grow>
            </Grid>

            {stepOne !== 0 && (
              <Grid item container>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Box marginTop={2}>
                    <DialogContentText>
                      <Typography className={classes.alignText}>
                        Defina el típo de usuario:
                      </Typography>
                    </DialogContentText>
                  </Box>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grow in timeout={800}>
                    <Box mr={4} mt={2} mb={2}>
                      <CardIcon
                        icon="icon-user"
                        title="Persona"
                        chacked={stepTwo === 1}
                        onClick={() => setStepTwo(1)}
                      />
                    </Box>
                  </Grow>

                  <Grow in timeout={800} style={{ transitionDelay: '100ms' }}>
                    <Box>
                      <CardIcon
                        icon="icon-planta"
                        title="Empresa"
                        chacked={stepTwo === 2}
                        onClick={() => setStepTwo(2)}
                      />
                    </Box>
                  </Grow>
                </Grid>
              </Grid>
            )}

            {stepTwo === 1 && (
              <Grow in timeout={600}>
                <Grid item container>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Box mt={2} mb={2}>
                      <DialogContentText>
                        <Typography className={classes.alignText}>
                          Ingrese los datos del cliente para enviar invitación a
                          InfoPorcinos:
                        </Typography>
                      </DialogContentText>
                    </Box>
                  </Grid>

                  <Box mb={1} width="100%">
                    <Grid container spacing={1}>
                      <Grid
                        item
                        container
                        xs={6}
                        className={classes.displayTextField}
                      >
                        <TextField
                          fullWidth
                          label="Primer Nombre"
                          variant="outlined"
                          size="small"
                          name="firtName"
                          value={formik.values.firtName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.firtName &&
                            Boolean(formik.errors.firtName)
                          }
                          helperText={
                            formik.touched.firtName && formik.errors.firtName
                          }
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        xs={6}
                        className={classes.displayTextField}
                      >
                        <TextField
                          fullWidth
                          label="Segundo Nombre"
                          variant="outlined"
                          size="small"
                          name="secondName"
                          value={formik.values.secondName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.secondName &&
                            Boolean(formik.errors.secondName)
                          }
                          helperText={
                            formik.touched.secondName &&
                            formik.errors.secondName
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={1} width="100%">
                    <Grid container spacing={1}>
                      <Grid
                        container
                        item
                        xs={6}
                        className={classes.displayTextField}
                      >
                        <TextField
                          fullWidth
                          label="Primer Apellido"
                          variant="outlined"
                          size="small"
                          name="firtSurname"
                          value={formik.values.firtSurname}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.firtSurname &&
                            Boolean(formik.errors.firtSurname)
                          }
                          helperText={
                            formik.touched.firtSurname &&
                            formik.errors.firtSurname
                          }
                          required
                        />
                      </Grid>

                      <Grid
                        container
                        item
                        xs={6}
                        className={classes.displayTextField}
                      >
                        <TextField
                          fullWidth
                          label="Segundo Apellido"
                          variant="outlined"
                          size="small"
                          name="secondSurname"
                          value={formik.values.secondSurname}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.secondSurname &&
                            Boolean(formik.errors.secondSurname)
                          }
                          helperText={
                            formik.touched.secondSurname &&
                            formik.errors.secondSurname
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={2} width="100%">
                    <Grid container item spacing={1}>
                      <Grid item xs={5}>
                        <DocumentTypes
                          name="documentType"
                          value={formik.values.documentType}
                          onChange={formik.handleChange}
                          entity={1}
                          errors={formik.errors.documentType}
                          helperText={formik.errors.documentType}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          fullWidth
                          label="Número de Doumento"
                          variant="outlined"
                          size="small"
                          type="number"
                          name="document"
                          value={formik.values.document}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.document &&
                            Boolean(formik.errors.document)
                          }
                          helperText={
                            formik.touched.document && formik.errors.document
                          }
                          required
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={2} width="100%">
                    <PhoneNumber
                      type="mobile"
                      names={{
                        indicative: 'indicative',
                        phone: 'mobile',
                      }}
                      values={{
                        indicative: formik.values.indicative,
                        phone: (formik.values.mobile = unmaskPhone(
                          formik.values.mobile
                        )),
                      }}
                      onChange={formik.handleChange}
                      errors={{
                        indicative:
                          !!formik.errors.indicative &&
                          !!formik.touched.indicative,
                        phone: formik.errors.mobile as string,
                      }}
                      helperTexts={{
                        indicative: formik.errors.indicative as string,
                        phone: formik.errors.mobile as string,
                      }}
                      required
                    />
                  </Box>

                  <Grid container className={classes.displayTextField}>
                    <Grid>
                      <TextField
                        fullWidth
                        label="Correo Electrónico"
                        variant="outlined"
                        size="small"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grow>
            )}

            {stepTwo === 2 && (
              <Grow in timeout={600}>
                <Grid item container>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Box marginTop={2} marginBottom={2}>
                      <DialogContentText>
                        <Typography className={classes.alignText}>
                          A continuación ingrese los datos del cliente para
                          enviar invitación a InfoPorcinos:
                        </Typography>
                      </DialogContentText>
                    </Box>
                  </Grid>

                  <Box mb={2} width="100%">
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          label="Razón Social"
                          variant="outlined"
                          size="small"
                          name="businessName"
                          value={formikCompany.values.businessName}
                          onChange={formikCompany.handleChange}
                          error={
                            formikCompany.touched.businessName &&
                            Boolean(formikCompany.errors.businessName)
                          }
                          helperText={
                            formikCompany.touched.businessName &&
                            formikCompany.errors.businessName
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="Nit"
                          variant="outlined"
                          size="small"
                          name="nit"
                          value={formikCompany.values.nit}
                          onChange={formikCompany.handleChange}
                          error={
                            formikCompany.touched.nit &&
                            Boolean(formikCompany.errors.nit)
                          }
                          helperText={
                            formikCompany.touched.nit &&
                            formikCompany.errors.nit
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          label="Dig. Verificación"
                          variant="outlined"
                          size="small"
                          name="digitoVerificacion"
                          value={
                            (formikCompany.values.digitoVerificacion =
                              formikCompany.values.nit
                                ? calculateCodVerification(
                                    Number(formikCompany.values.nit)
                                  )
                                : '')
                          }
                          onChange={formikCompany.handleChange}
                          error={
                            formikCompany.touched.digitoVerificacion &&
                            Boolean(formikCompany.errors.digitoVerificacion)
                          }
                          helperText={
                            formikCompany.touched.digitoVerificacion &&
                            formikCompany.errors.digitoVerificacion
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={2} width="100%">
                    <PhoneNumber
                      type="landline"
                      extension
                      names={{
                        indicative: 'indicativePhone',
                        phone: 'phone',
                        ext: 'ext',
                      }}
                      values={{
                        indicative: formikCompany.values.indicativePhone,
                        phone: (formikCompany.values.phone = unmaskPhone(
                          formikCompany.values.phone
                        )),
                        ext: formikCompany.values.ext,
                      }}
                      onChange={formikCompany.handleChange}
                      errors={{
                        indicative:
                          !!formikCompany.errors.indicativePhone &&
                          !!formikCompany.touched.indicativePhone,
                        phone: formikCompany.errors.phone as string,
                        ext: formikCompany.errors.ext as string,
                      }}
                      helperTexts={{
                        indicative: formikCompany.errors
                          .indicativePhone as string,
                        phone: formikCompany.errors.phone as string,
                        ext: formikCompany.errors.ext as string,
                      }}
                      required
                    />
                  </Box>

                  <Box mb={2} width="100%">
                    <PhoneNumber
                      type="mobile"
                      names={{
                        indicative: 'indicative',
                        phone: 'mobile',
                      }}
                      values={{
                        indicative: formikCompany.values.indicative,
                        phone: (formikCompany.values.mobile = unmaskPhone(
                          formikCompany.values.mobile
                        )),
                      }}
                      onChange={formikCompany.handleChange}
                      errors={{
                        indicative:
                          !!formikCompany.errors.indicative &&
                          !!formikCompany.touched.indicative,
                        phone: formikCompany.errors.mobile as string,
                      }}
                      helperTexts={{
                        indicative: formikCompany.errors.indicative as string,
                        phone: formikCompany.errors.mobile as string,
                      }}
                      required
                    />
                  </Box>

                  <Grid container className={classes.displayTextField}>
                    <Grid>
                      <TextField
                        fullWidth
                        label="Correo Electrónico"
                        variant="outlined"
                        size="small"
                        name="email"
                        value={formikCompany.values.email}
                        onChange={formikCompany.handleChange}
                        error={
                          formikCompany.touched.email &&
                          Boolean(formikCompany.errors.email)
                        }
                        helperText={
                          formikCompany.touched.email &&
                          formikCompany.errors.email
                        }
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grow>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginBottom: '15px' }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={stepTwo !== 0 ? false : true}
              type="submit"
              endIcon={
                loading && (
                  <CircularProgress
                    className={classes.loadingColor}
                    thickness={5}
                    size={20}
                  />
                )
              }
            >
              Enviar
            </Button>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default DialogInviteClient;
