import React, { createContext, useEffect } from 'react';
import { notificationSocket as socket } from 'config/socket';

import NotificationContent from 'components/NotificationContent';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import {
  GET_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
} from 'core/graphql/queries/notifications';

const NotificationContext = createContext({
  notifications: [],
  refetch: (values?: any) => {},
  unread: 0,
});

const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { username } = useSelector((state: any) => state.user.user);
  const token = useSelector((state: any) => state.user.token);

  const context = { headers: { 'x-auth-jwt': token } };

  const { data: unread, refetch: refetchUnread } = useQuery(
    GET_UNREAD_NOTIFICATIONS,
    {
      context,
    }
  );
  const { data, loading, error, refetch } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      limit: 5,
    },
    context,
  });

  useEffect(() => {
    if (username) {
      socket.emit('join', username);
      socket.on('notificate_all', (data) => {
        refetch();
        refetchUnread();
        enqueueSnackbar(data.message, {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
          autoHideDuration: 5000,
          content: (key, message) => (
            <NotificationContent
              id={key}
              message={message}
              time={data?.data?.date}
              avatar={data?.avatar}
              {...data?.data}
            />
          ),
        });
      });
    }
  }, []);

  useEffect(() => {
    refetchUnread();
  }, [data]);

  return (
    <NotificationContext.Provider
      value={{
        notifications: data?.notifications ?? [],
        refetch,
        unread: unread?.unreadNotifications ?? 0,
      }}
    >
      <>{children}</>
    </NotificationContext.Provider>
  );
};

export { NotificationContext as default, NotificationProvider };
