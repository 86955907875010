import { gql } from "apollo-boost";

export const INDICATIVE_DEPARTMENTS =gql`
  query ($idCountry: ID!){
    departments(idPais: $idCountry){
      id
      codeArea
      name
      iso2
    }
  }
`