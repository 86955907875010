import React from 'react';
import {
  Card,
  Avatar,
  Typography,
  Icon,
  CircularProgress,
  CardActionArea,
} from '@material-ui/core';
import { REPORT_TYPES } from 'core/graphql/queries/reportTypes';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import InformativeScreen from 'components/InformativeScreens';
import useStyles from './styles';

const CardTypeReports = () => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);

  const { loading, error, data } = useQuery(REPORT_TYPES, {
    context: {
      headers: { 'x-auth-jwt': token },
    },
    variables: { count: true },
  });

  const history = useHistory();
  const value = useSelector(
    (state: any) => state.ui.sharedReports.valueRouteTab
  );
  const handleClickCard = (idReports: string | number) => {
    history.push(`/app/network/shared-reports/details-reports/${idReports}`);
  };

  if (error) {
    return (
      <InformativeScreen
        onlyError
        type="technicalError"
        subtitle="¡Ups!"
        info="Ha ocurrido un error, intentalo más tarde"
      />
    );
  }

  if (loading) {
    return <CircularProgress variant="indeterminate" size={25} />;
  }

  return (
    <>
      <div className={classes.listCard}>
        <CardActionArea className={classes.containerCard}>
          <Card
            className={classes.card}
            onClick={() => {
              handleClickCard('Todos');
            }}
          >
            <div className={classes.child}>
              <Avatar style={{ background: '#DD9835' }}>
                <Icon className={classes.icon}>
                  <i className={clsx('icon-paper-sheet')}></i>
                </Icon>
              </Avatar>
              <Typography className={classes.title}>
                Todos los reportes
              </Typography>
            </div>
            <Avatar className={classes.viewed}>12</Avatar>
          </Card>
        </CardActionArea>
        {data.reportTypes.map((reportTypes: any) => (
          <CardActionArea className={classes.containerCard}>
            <Card
              className={classes.card}
              onClick={() => {
                handleClickCard(reportTypes.name);
              }}
            >
              <div className={classes.child}>
                <Avatar style={{ background: '#DD9835' }}>
                  <Icon className={classes.icon}>
                    <i className={clsx(reportTypes.icon)}></i>
                  </Icon>
                </Avatar>
                <Typography className={classes.title}>
                  {reportTypes.name}
                </Typography>
              </div>
              <Avatar className={classes.viewed}>{reportTypes.count}</Avatar>
            </Card>
          </CardActionArea>
        ))}
      </div>
    </>
  );
};

export default CardTypeReports;
