import React, { useState } from 'react';
import { Box, Grid, Button, Typography, Tooltip, Fab } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import domtoimage from 'dom-to-image';

import FilterReports from 'components/FilterReports';
import ContentCard from 'components/ContentCard';
import ItemTotalInfo from 'components/ItemTotalInfo';
import ItemListInfo from 'components/ItemListInfo';
import PaginationGrid from 'components/datagrid-custom-components/TableFooter/index';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import CurrentDateIndicator from 'components/CurrentDateIndicator';
import DialogPDFViewer from 'components/DialogPDFViewer/index';

import { MyDocGSMI, ReportFilter } from '../../Reports/ReportFilter';

import useStyles from './styles';

// Datos quemados
const rows = [
  {
    id: 1,
    date: '13/07/2021 02:00:00',
    guide: 654987,
    lot: 1234,
    producer: 'Nombre productor',
    farm: 'Nombre Granja',
    license_plate: 'SMV195',
    animals: 25,
  },
  {
    id: 2,
    date: '13/07/2021 02:00:00',
    guide: 789654,
    lot: 7848,
    producer: 'Don Juan',
    farm: 'El Machetico',
    license_plate: 'DJU202',
    animals: 54,
  },
  {
    id: 3,
    date: '13/07/2021 02:00:00',
    guide: 898951,
    lot: 1851,
    producer: 'Sebastian M',
    farm: 'House Porki',
    license_plate: 'SMV195',
    animals: 35,
  },
];

const Main = () => {
  const classes = useStyles();
  const user = useSelector((state: any) => state.user.user);
  const initDate = dayjs().startOf('month').format('YYYY-MM-DDT00:00:00-05:00');
  const current = dayjs().format('YYYY-MM-DDT23:59:59-05:00');
  const currentR = dayjs().format('DD/MM/YYYY');
  const [image, setImage] = useState('a');
  const [openRangeGSMI, setOpenRangeGSMI] = useState(false);

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenQrGSMI');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);

        setImage(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeGSMI = () => {
    generateScreenshot();
    setOpenRangeGSMI(true);
  };

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/mobilization-guides',
      title: 'Guías de Movilización',
    },

    { link: '/app/reports', title: 'Mis Reportes' },
  ];

  const ListGuide: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'guide',
      headerName: 'Nro. Guía',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'lot',
      headerName: 'Lote IP',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'producer',
      headerName: 'Productor',
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'farm',
      headerName: 'Granja Origen',
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'license_plate',
      headerName: 'Placa Vehículo',
      flex: 0.9,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'animals',
      headerName: 'Animales',
      flex: 0.9,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'ver',
      headerName: ' ',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="Ver detalles" arrow>
            <Box mr={1}>
              <Fab
                color="primary"
                className={classes.btnDetails}
                component={Link}
                to={`/app/reports/mobilization-guides/details/${params.getValue(
                  params.id,
                  'id'
                )}`}
              >
                <Box component="i" fontSize="20px" className="icon-eye" />
              </Fab>
            </Box>
          </Tooltip>
        </>
      ),
    },
  ];

  const customPagination = () => {
    return (
      <Box mt={2}>
        <PaginationGrid />
      </Box>
    );
  };

  return (
    <>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportFilter />
      </div>
      <div className={classes.breadContainer}>
        <CurrentDateIndicator initialDate={initDate} finalDate={current} />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <FilterReports to="/app/reports/mobilization-guides/search" />

      <Grid container spacing={3}>
        <Grid item container xs={6}>
          <ContentCard className={classes.cardContainer}>
            <ContentCard.Header>
              <Typography className={classes.title}>
                Resumen de Ingresos
              </Typography>
            </ContentCard.Header>
            <ContentCard.Content>
              <Box mt={2} mb={2}>
                <Grid item container justify="center" alignItems="center">
                  <Grid item container xs={6} justify="center">
                    <ItemTotalInfo
                      avatarTotalInfo={620}
                      titleInfo="Total Animales Movilizados"
                      avatarTotalColor="#FFC676"
                      avatarTotalSize={35}
                      avatarTextSize={16}
                      extended
                      titleInfoSize={200}
                      position="right"
                    />
                  </Grid>

                  <Grid item container xs={6} justify="center">
                    <ItemTotalInfo
                      avatarTotalInfo={12}
                      titleInfo="Total Guías de Movilización"
                      avatarTotalColor="#F1AE6C"
                      avatarTotalSize={35}
                      avatarTextSize={16}
                      extended
                      titleInfoSize={200}
                      position="right"
                    />
                  </Grid>
                </Grid>
              </Box>
            </ContentCard.Content>
          </ContentCard>
        </Grid>
        <Grid item container xs={6}>
          <ContentCard className={classes.cardContainer}>
            <ContentCard.Header>
              <Typography className={classes.title}>
                Resumen Movilización
              </Typography>
            </ContentCard.Header>
            <ContentCard.Content>
              <Grid container>
                <Grid item container xs={6}>
                  <ItemListInfo
                    icon="icon-truck"
                    titleInfo="Camiones Ingresados"
                    numberInfo="06"
                  />
                  <ItemListInfo
                    icon="icon-document"
                    titleInfo="Falta Guía Movilización"
                    numberInfo="0"
                  />
                </Grid>
                <Grid item container xs={6}>
                  <ItemListInfo
                    icon="icon-granja"
                    titleInfo="Granjas Diferentes"
                    numberInfo="08"
                  />
                  <ItemListInfo
                    icon="icon-productor"
                    titleInfo="Productores Diferentes"
                    numberInfo="03"
                  />
                </Grid>
              </Grid>
            </ContentCard.Content>
          </ContentCard>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item container xs={6}>
          <ContentCard className={classes.cardContainer}>
            <ContentCard.Content>
              <Box mb={2} mt={2}>
                <Grid container>
                  <Grid
                    item
                    container
                    xs={8}
                    justify="flex-start"
                    alignContent="center"
                  >
                    <Typography className={classes.title}>
                      Reporte Ingresos del Día
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={4}
                    justify="flex-end"
                    alignContent="center"
                  >
                    <Button
                      startIcon={<i className="icon-eye" />}
                      variant="contained"
                      className="raleway"
                      color="primary"
                      onClick={() => openModalRangeGSMI()}
                    >
                      Visualizar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </ContentCard.Content>
          </ContentCard>
        </Grid>
        <Grid item container xs={6}>
          <ContentCard className={classes.cardContainer}>
            <ContentCard.Content>
              <Box mb={2} mt={2}>
                <Grid container>
                  <Grid
                    item
                    container
                    xs={6}
                    justify="flex-start"
                    alignContent="center"
                  >
                    <Typography className={classes.title}>
                      Planta de Beneficio
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    justify="flex-end"
                    alignContent="center"
                  >
                    <Typography className={classes.data}>
                      Sociedad Central Ganadera S.A.S.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </ContentCard.Content>
          </ContentCard>
        </Grid>
      </Grid>

      <Grid container>
        <ContentCard className={classes.cardContainer}>
          <Grid item container>
            <Grid item container xs={9}>
              <Typography className={classes.title}>
                Listado de Guías de Movilización Ingresadas en el día
              </Typography>
            </Grid>
            <Grid item container xs={3} justify="flex-end" alignItems="center">
              <Box
                component="i"
                className="icon-document"
                fontSize={25}
                color="#767676"
              />
              <Typography className={classes.result}>03 Resultados</Typography>
            </Grid>
          </Grid>
          <DataGrid
            rows={rows}
            columns={ListGuide}
            autoHeight
            pageSize={20}
            className={classes.dataGrid}
            components={{
              Footer: customPagination,
            }}
            hideFooterSelectedRowCount
            rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
          />
        </ContentCard>

        <DialogPDFViewer
          open={openRangeGSMI}
          onClose={() => setOpenRangeGSMI(false)}
          done={Boolean(image)}
        >
          <MyDocGSMI qr={image} range={currentR} />
        </DialogPDFViewer>
      </Grid>
    </>
  );
};

export default Main;
