import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Fab,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
  Tooltip,
  CircularProgress,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridValueGetterParams,
} from "@material-ui/data-grid";
import SearchIcon from "@material-ui/icons/Search";
import dayjs from "dayjs";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

import ContentCard from "components/ContentCard";
import TableOverlay from "components/datagrid-custom-components/TableOverlay";
import Breadcrumb, { BreadcrumbProps } from "components/BreadcrumbBuilder";
import PaginationGrid from "components/datagrid-custom-components/TableFooter/index";
import Results from "components/Results";
import Joyride from "react-joyride";
import InformativeScreen from "components/InformativeScreens";
import MainTitle from "components/MainTitle";

import {
  INVITATIONS,
  INVITATIONS_RECEIVED,
} from "core/graphql/queries/invitations";
import { ACCEPT_INVITATION } from "core/graphql/mutations/invitations";

import DialogSearchUser from "./DialogSearchUser/index";
import DialogInviteClient from "./DialogInviteClient";

import useStyles from "./styles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanelRequest(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function a11yPropsRequest(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const Main = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(false);
  const [value, setValue] = useState(0);
  const [valueRequest, setValueRequest] = useState(0);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [viewRequest, setViewRequest] = useState(0);
  const [acceptButton, setAcceptButton] = useState({
    idInvitacion: 0,
    state: false,
  });

  const [openSearchUser, setOpenSearchUser] = useState({
    open: false,
    search: "",
  });
  const [searchUser, setSearchUser] = useState({
    user: "",
  });
  const [searchUserFilter, setSearchUserFilter] = useState({
    user: "",
  });

  const {
    data: dataInvitations,
    loading: loadingInvitations,
    error: ErrorInvitations,
    refetch: refetchInvitations,
  } = useQuery(INVITATIONS, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { state: value },
  });

  const [
    getInvitationsReceived,
    {
      data: dataInvitationsReceived,
      loading: loadingInvitationsReceived,
      error: errorInvitationsReceived,
    },
  ] = useLazyQuery(INVITATIONS_RECEIVED, {
    fetchPolicy: "network-only",
  });

  const [acceptInvitation, { loading: loadingAcceptInvitation }] = useMutation(
    ACCEPT_INVITATION,
    {
      context: { headers: { "x-auth-jwt": token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        });
      },
    }
  );

  const changeStatusInvitation = (
    idInvitacion: number,
    estadoInvitacion: boolean
  ) => {
    setAcceptButton({
      idInvitacion: idInvitacion,
      state: estadoInvitacion,
    });
    acceptInvitation({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.acceptInvitation.data.success === 1) {
          enqueueSnackbar(
            estadoInvitacion
              ? "La invitación ha sido Aceptada exitosamente"
              : "La invitación ha sido Rechazada exitosamente",
            {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
            }
          );
          getInvitationsReceived({
            context: { headers: { "x-auth-jwt": token } },
            variables: { idEstadoInvitacion: 0 },
          });
          setAcceptButton({
            idInvitacion: 0,
            state: false,
          });
        } else {
          enqueueSnackbar(mutationResult.data.acceptInvitation.data.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          });
        }
      },
      variables: {
        idInvitacion: idInvitacion,
        estado: estadoInvitacion,
      },
    });
  };

  useEffect(() => {
    if (viewRequest === 1) {
      getInvitationsReceived({
        context: { headers: { "x-auth-jwt": token } },
        variables: { idEstadoInvitacion: valueRequest },
      });
    }
  }, [viewRequest, valueRequest]);

  useEffect(() => {
    refetchInvitations({ state: value });
  }, [openSearchUser, value]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSend = () => {
    if (open) {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const invitations: any[] = [];
  dataInvitations?.invitations?.data.map((inv: any) => {
    invitations.push({
      id: inv?.id,
      documento: inv?.nroDocumento,
      tipoDocumento: inv?.tipoDocumento,
      fechaInvitacion: dayjs(new Date(inv?.fechaInvitacion)).format(
        "DD/MM/YYYY HH:mm:ss"
      ),
      diasEspera: dayjs().diff(dayjs(inv?.fechaInvitacion), "days"),
      nombresCompletos: inv?.nombresCompletos.toLocaleLowerCase(),
      email: inv?.email,
      tipoUsuario: inv?.tipoUsuario,
      numeroIntentos: inv?.numeroIntentos,
      estadoInvitacion: inv?.estadoInvitacion,
      ver: "ver",
    });
  });

  const invitationsReceived: any[] = [];
  dataInvitationsReceived?.getInvitationsReceivedXResponse?.data.map(
    (item: any) => {
      if (valueRequest === 0) {
        item?.estadoInvitacion === "En Espera" &&
          invitationsReceived.push({
            id: item?.idInvitacion,
            fechaInvitacion: dayjs(new Date(item?.fechaInvitacion)).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            diasEspera: dayjs().diff(item?.fechaInvitacion, "days"),
            tipoDocumento: item?.idTipoDocumento,
            nombresCompletos: item?.nombre,
            documento: item?.numeroDocumento,
            email: item?.email,
            tipoUsuario: item?.idTipoUsuario,
            numeroIntentos: item?.numeroIntentos,
            estadoInvitacion: item?.estadoInvitacion,
            ver: "ver",
          });
      } else if (valueRequest === 1) {
        item?.estadoInvitacion === "Aceptada" &&
          invitationsReceived.push({
            id: item?.idInvitacion,
            fechaInvitacion: dayjs(new Date(item?.fechaRespuesta)).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            diasEspera: dayjs(item?.fechaRespuesta).diff(
              item?.fechaInvitacion,
              "days"
            ),
            nombresCompletos: item?.nombre,
            tipoDocumento: item?.idTipoDocumento,
            documento: item?.numeroDocumento,
            email: item?.email,
            tipoUsuario: item?.idTipoUsuario,
            numeroIntentos: item?.numeroIntentos,
            estadoInvitacion: item?.estadoInvitacion,
            ver: "ver",
          });
      } else if (valueRequest === 2) {
        item?.estadoInvitacion === "Rechazada" &&
          invitationsReceived.push({
            id: item?.idInvitacion,
            fechaInvitacion: dayjs(new Date(item?.fechaRespuesta)).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            diasEspera: dayjs(item?.fechaRespuesta).diff(
              item?.fechaInvitacion,
              "days"
            ),
            nombresCompletos: item?.nombre,
            tipoDocumento: item?.idTipoDocumento,
            documento: item?.numeroDocumento,
            email: item?.email,
            tipoUsuario: item?.idTipoUsuario,
            numeroIntentos: item?.numeroIntentos,
            estadoInvitacion: item?.estadoInvitacion,
            ver: "ver",
          });
      }
    }
  );

  const filterUserInvitation = invitations.filter(
    (user) =>
      user.nombresCompletos.includes(
        searchUserFilter.user.toLocaleLowerCase()
      ) ||
      user.documento.includes(searchUserFilter.user) ||
      user.email.includes(searchUserFilter.user)
  );

  const filterUserInvitationReceived = invitationsReceived.filter(
    (user) =>
      user.nombresCompletos.includes(
        searchUserFilter.user.toLocaleLowerCase()
      ) ||
      user.documento.includes(searchUserFilter.user) ||
      user.email.includes(searchUserFilter.user)
  );

  const handleInputChange = (event: any) => {
    setSearchUser({
      user: event.target.value.trim(),
    });
    if (event.target.value.trim().length >= 3) {
      setError(false);
    }
  };

  const handleInputChangeSearch = (event: any) => {
    setSearchUserFilter({
      user: event.target.value.trim(),
    });
  };

  const pageBreadcrums: BreadcrumbProps[] = [
    {
      link: "/app/network/invitations",
      title: "Invitaciones",
    },
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeRequest = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setValueRequest(newValue);
  };

  const [state, setState] = useState({
    run: false,
    steps: [
      {
        target: ".mainContentCard",
        content: (
          <Typography className={classes.labelTooltips}>
            Bienvenido a la sección de invitaciones, desde esta sección podrás
            gestionar todas las invitaciones que tengas en el sistema de
            InfoPorcinos.
          </Typography>
        ),
        disableBeacon: true,
        placement: "center" as const,
        title: "Invitaciones",
      },
      {
        target: ".mainContentCard",
        content: (
          <Typography className={classes.labelTooltips}>
            En esta sección puedes manejar las invitaciones con las que has
            interactuado, pudiendo acceder al listado de las mismas.
          </Typography>
        ),
        disableBeacon: true,
        placement: "top" as const,
        title: "Invitaciones",
      },
      {
        target: "#boxRequests",
        content: (
          <div>
            <Typography className={classes.labelTooltips}>
              Aquí puedes cambiar entre el listado de Solicitudes Recibidas y
              Solicitudes Enviadas
            </Typography>
          </div>
        ),
        disableBeacon: true,
        placement: "left" as const,
        title: "Solicitudes",
      },
      {
        target: ".searchContainer",
        content: (
          <Typography className={classes.labelTooltips}>
            Esta es la barra de búsqueda, te permite buscar usuarios en el
            Sistema
          </Typography>
        ),
        disableBeacon: true,
        placement: "bottom" as const,
        title: "Búsqueda de Invitaciones",
      },
      {
        target: "#buttonResend",
        content: (
          <Typography className={classes.labelTooltips}>
            Puedes reenviar las invitaciones que tengas dando Click en este
            botón, recuerda que tienes un límite para reenviar invitaciones.
          </Typography>
        ),
        disableBeacon: true,
        placement: "left" as const,
        title: "Reenviar Invitaciones",
      },
      {
        target: "#buttonSend",
        content: (
          <Typography className={classes.labelTooltips}>
            Desde aquí puedes enviar invitaciones a personas que aún no
            pertenezcan al sistema de InfoPorcinos.
          </Typography>
        ),
        disableBeacon: true,
        placement: "top" as const,
        title: "Invitar Personas",
      },
    ],
  });

  const columns: GridColDef[] = [
    {
      field: "fechaInvitacion",
      headerName: `${
        (value === 0 && "Fecha de envío") ||
        (value === 1 && "Fecha de aceptación") ||
        (value === 2 && "Fecha de rechazo")
      }`,
      type: "date",
      flex: 1,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "diasEspera",
      headerName: "Días en Espera",
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      hide: value != 0 ? true : false,
    },
    {
      field: "nombresCompletos",
      headerName: "Invitado",
      cellClassName: classes.fontUppercase,
      flex: 1.4,
      disableColumnMenu: true,
      headerAlign: "center",
    },
    {
      field: "documento",
      headerName: "Documento",
      cellClassName: classes.fontUppercase,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <>
          {params.getValue(params.id, "tipoDocumento") === 1 && (
            <>
              <span className={classes.fontDocument}>CC: </span>
              {params.getValue(params.id, "documento")}
            </>
          )}
          {params.getValue(params.id, "tipoDocumento") === 2 && (
            <>
              <span className={classes.fontDocument}>CE: </span>
              {params.getValue(params.id, "documento")}
            </>
          )}
          {params.getValue(params.id, "tipoDocumento") === 3 && (
            <>
              <span className={classes.fontDocument}>NIT: </span>
              {params.getValue(params.id, "documento")}
            </>
          )}
          {params.getValue(params.id, "tipoDocumento") === 4 && (
            <>
              <span className={classes.fontDocument}>PEP: </span>
              {params.getValue(params.id, "documento")}
            </>
          )}
        </>
      ),
    },
    {
      field: "email",
      headerName: "Correo Electrónico",
      flex: 1.4,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "tipoUsuario",
      headerName: "Tipo de Relación",
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "numeroIntentos",
      headerName: "Invitaciones Enviadas",
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
  ];

  const columnsRequest: GridColDef[] = [
    {
      field: "fechaInvitacion",
      headerName: `${
        (valueRequest === 0 && "Fecha de Envío") ||
        (valueRequest === 1 && "Fecha de Aceptación") ||
        (valueRequest === 2 && "Fecha de Rechazo")
      }`,
      type: "date",
      flex: valueRequest === 0 ? 1.1 : 0.9,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "diasEspera",
      headerName: "Días en Espera",
      flex: valueRequest === 0 ? 0.8 : 0.6,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nombresCompletos",
      headerName: "Usuario Invitado",
      cellClassName: classes.fontUppercase,
      flex: valueRequest === 0 ? 1.5 : 1.2,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "documento",
      headerName: "Documento",
      flex: valueRequest === 0 ? 1 : 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <>
          {params.getValue(params.id, "tipoDocumento") === 1 && (
            <>
              <span className={classes.fontDocument}>CC: </span>
              {params.getValue(params.id, "documento")}
            </>
          )}
          {params.getValue(params.id, "tipoDocumento") === 2 && (
            <>
              <span className={classes.fontDocument}>CE: </span>
              {params.getValue(params.id, "documento")}
            </>
          )}
          {params.getValue(params.id, "tipoDocumento") === 3 && (
            <>
              <span className={classes.fontDocument}>NIT: </span>
              {params.getValue(params.id, "documento")}
            </>
          )}
          {params.getValue(params.id, "tipoDocumento") === 4 && (
            <>
              <span className={classes.fontDocument}>PEP: </span>
              {params.getValue(params.id, "documento")}
            </>
          )}
        </>
      ),
    },
    {
      field: "email",
      headerName: "Correo Electrónico",
      flex: valueRequest === 0 ? 1.4 : 1.2,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tipoUsuario",
      headerName: "Tipo de Usuario",
      flex: valueRequest === 0 ? 0.9 : 0.6,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <>
          {params.getValue(params.id, "tipoUsuario") === 1 && (
            <span>Productor</span>
          )}
          {params.getValue(params.id, "tipoUsuario") === 2 && (
            <span>Comercializador</span>
          )}
        </>
      ),
    },
    {
      field: "numeroIntentos",
      headerName: "Invitaciones Enviadas",
      flex: valueRequest === 0 ? 1 : 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ver",
      headerName: "Acciones",
      flex: 1.4,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      hide: valueRequest !== 0,
      renderCell: (params: GridValueGetterParams) => (
        <>
          {params.getValue(params.id, "estadoInvitacion") === "En Espera" && (
            <>
              <Button
                variant="contained"
                className={classes.ButtonAccept}
                onClick={() =>
                  changeStatusInvitation(
                    params.getValue(params.id, "id") as number,
                    true
                  )
                }
                disabled={
                  acceptButton.idInvitacion ===
                    Number(params.getValue(params.id, "id")) &&
                  acceptButton.state &&
                  loadingAcceptInvitation
                }
              >
                {acceptButton.idInvitacion ===
                  Number(params.getValue(params.id, "id")) &&
                acceptButton.state &&
                loadingAcceptInvitation
                  ? "Aceptando.."
                  : "Aceptar"}
              </Button>
              <Button
                variant="contained"
                className={classes.ButttonReject}
                onClick={() =>
                  changeStatusInvitation(
                    params.getValue(params.id, "id") as number,
                    false
                  )
                }
                disabled={
                  acceptButton.idInvitacion ===
                    Number(params.getValue(params.id, "id")) &&
                  !acceptButton.state &&
                  loadingAcceptInvitation
                }
              >
                {acceptButton.idInvitacion ===
                  Number(params.getValue(params.id, "id")) &&
                !acceptButton.state &&
                loadingAcceptInvitation
                  ? "Rechazando.."
                  : "Rechazar"}
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  const customPagination = () => {
    return (
      <Box mt={3}>
        <PaginationGrid />
      </Box>
    );
  };

  const enviar = () => {
    if (searchUser?.user.length >= 3) {
      handleOpenSearchUser(searchUser.user);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleChangeRadio = (event: any) => {
    setViewRequest(Number(event.target.value));
  };

  const handleOpenSearchUser = (search: string) => {
    setOpenSearchUser({
      open: true,
      search: search,
    });
  };

  const handleCloseSearchUser = (search: string) => {
    setOpenSearchUser({
      open: false,
      search: search,
    });
  };

  const startTour = () => {
    setState({
      ...state,
      run: true,
    });
  };

  return (
    <>
      <Joyride
        steps={state.steps}
        run={state.run}
        continuous={true}
        disableScrollParentFix={true}
        disableScrolling
        showProgress
        locale={{
          back: "Atrás",
          close: "Cerrar",
          next: "Siguiente",
          last: "Entendido",
        }}
        styles={{
          buttonNext: {
            borderRadius: "50px",
            backgroundColor: "#DD9835",
            color: "#FFF",
            padding: "12px 20px",
            letterSpacing: "0.02857em",
            fontFamily: "Raleway",
            fontWeight: "bold",
            boxShadow: "none",
            fontSize: "0.875rem",
          },
          buttonBack: {
            color: "#767676",
          },
          tooltip: {
            borderRadius: "20px",
          },
          spotlight: {
            borderRadius: "20px",
          },
          tooltipTitle: {
            color: "#DD9835",
          },
          tooltipContent: {
            padding: "20px 10px 5px 10px",
          },

          options: {
            zIndex: 1300,
          },
        }}
      />
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={classes.marginBottom}
      >
        <Breadcrumb breadcrumbs={pageBreadcrums} />
      </Grid>

      <ContentCard style={{ padding: 40 }} className="mainContentCard">
        <ContentCard.Content>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6" color="secondary">
                Buscar Usuarios en el Sistema
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                mb={3}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <TextField
                  className={classes.inputSearch}
                  error={error}
                  fullWidth
                  label="Nombre Completo, la
                  Razón Social, Cedula de ciudadanía o el NIT del cliente a
                  invitar"
                  helperText={
                    error &&
                    "El campo esta vacio o debe ingresar al menos 3 caracteres"
                  }
                  name="searchUser"
                  onChange={handleInputChange}
                  size="small"
                  variant="outlined"
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonSearch}
                  endIcon={
                    <Box
                      component="i"
                      className="icon-search"
                      fontWeight="bold"
                    />
                  }
                  onClick={enviar}
                >
                  Buscar
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} container direction="row" alignItems="center">
              <MainTitle>Gestión de Invitaciones</MainTitle>
              <>
                {viewRequest == 0 ? (
                  <Results number={filterUserInvitation.length} />
                ) : (
                  <Results number={filterUserInvitationReceived.length} />
                )}
              </>
            </Grid>
            <Grid
              item
              xs={6}
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <ButtonGroup
                variant="contained"
                color="primary"
                className={classes.ButtonGrup}
                aria-label="contained primary button group"
              >
                <Button
                  startIcon={
                    <Box
                      component="i"
                      className="icon-plus"
                      fontWeight="bold"
                    />
                  }
                  onClick={handleClickOpen}
                >
                  Invitar Cliente
                </Button>

                <Button
                  color="default"
                  className={classes.buttonForward}
                  onClick={() =>
                    history.push("/app/network/invitations/forward-invitations")
                  }
                >
                  Reenviar Invitaciones
                </Button>
                <DialogInviteClient
                  open={open}
                  handleSend={handleSend}
                  handleClose={handleClose}
                />
              </ButtonGroup>
              <Tooltip title="Ayuda">
                <IconButton onClick={startTour}>
                  <i className={clsx("icon-question", classes.iconPadding)} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Box borderBottom={1} marginTop={2} className={classes.colorHr}>
            <Grid container>
              <Grid item xs={5}>
                {viewRequest === 0 ? (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab
                      className={classes.TabfontText}
                      label="En espera"
                      {...a11yProps(0)}
                    />
                    <Tab
                      className={classes.TabfontText}
                      label="Aceptadas"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className={classes.TabfontText}
                      label="Rechazadas"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                ) : (
                  <Tabs
                    value={valueRequest}
                    onChange={handleChangeRequest}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab
                      className={classes.TabfontText}
                      label="En espera"
                      {...a11yPropsRequest(0)}
                    />
                    <Tab
                      className={classes.TabfontText}
                      label="Aceptadas"
                      {...a11yPropsRequest(1)}
                    />
                    <Tab
                      className={classes.TabfontText}
                      label="Rechazadas"
                      {...a11yPropsRequest(2)}
                    />
                  </Tabs>
                )}
              </Grid>

              <Grid
                item
                xs={7}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Box mr={5}>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    className={classes.radioGrup}
                  >
                    <FormControlLabel
                      checked={viewRequest == 0}
                      onChange={handleChangeRadio}
                      style={{ color: "#707070" }}
                      value={0}
                      control={<Radio color="primary" />}
                      label="Enviadas"
                    />

                    <FormControlLabel
                      checked={viewRequest == 1}
                      onChange={handleChangeRadio}
                      style={{ color: "#707070" }}
                      value={1}
                      control={<Radio color="primary" />}
                      label="Recibidas"
                    />
                  </RadioGroup>
                </Box>
                <Box minWidth="40%">
                  <TextField
                    label="Búsqueda"
                    fullWidth
                    name="searchUser"
                    onChange={handleInputChangeSearch}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component="i"
                            className="icon-search"
                            fontSize="25px"
                          />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          {viewRequest == 0 ? (
            <>
              <TabPanel value={value} index={0}>
                <Box
                  height="auto"
                  width="100%"
                  className={
                    searchUserFilter.user != "" &&
                    filterUserInvitation.length == 0
                      ? classes.heightContainer
                      : classes.colorNoRow
                  }
                >
                  <div style={{ height: "auto", width: "100%" }}>
                    {!loadingInvitations && invitations.length !== 0 && (
                      <DataGrid
                        rows={
                          searchUserFilter.user != ""
                            ? filterUserInvitation
                            : invitations
                        }
                        columns={columns}
                        autoHeight
                        className={
                          searchUserFilter.user != "" &&
                          filterUserInvitation.length == 0
                            ? clsx(classes.dataGrid, classes.notDataContainer)
                            : classes.dataGrid
                        }
                        components={{
                          Footer: customPagination,
                          NoRowsOverlay: TableOverlay,
                        }}
                        hideFooter={
                          searchUserFilter.user != "" &&
                          filterUserInvitation.length == 0
                        }
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        page={page}
                        onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                        onPageChange={(e: any) => setPage(e.page)}
                      />
                    )}
                    {!loadingInvitations &&
                      !ErrorInvitations &&
                      invitations.length === 0 && (
                        <Box marginTop={5}>
                          <InformativeScreen
                            type="notMessages"
                            subtitle="¡No encontramos Invitaciones En Espera!"
                            info="Esto significa que no has enviado
                            inivitaciones ó todas la invitaciones que
                            enviaste ya fueron Aceptadas ó Rechazadas."
                            onlyError
                          />
                        </Box>
                      )}
                    {ErrorInvitations && invitations.length === 0 && (
                      <Box marginTop={5}>
                        <InformativeScreen
                          type="technicalError"
                          subtitle="¡Ha ocurrido un error inesperado!"
                          info="Estamos trabajando para resolverlo."
                          onlyError
                        />
                      </Box>
                    )}
                    {loadingInvitations && (
                      <Box textAlign="center" marginTop={5}>
                        <CircularProgress color="primary" size={60} />
                      </Box>
                    )}
                  </div>
                </Box>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Box
                  height="auto"
                  width="100%"
                  className={
                    searchUserFilter.user != "" &&
                    filterUserInvitation.length == 0
                      ? classes.heightContainer
                      : classes.colorNoRow
                  }
                >
                  <div style={{ height: "auto", width: "100%" }}>
                    {!loadingInvitations && invitations.length !== 0 && (
                      <DataGrid
                        rows={
                          searchUserFilter.user != ""
                            ? filterUserInvitation
                            : invitations
                        }
                        columns={columns}
                        autoHeight
                        pageSize={20}
                        className={
                          searchUserFilter.user != "" &&
                          filterUserInvitation.length == 0
                            ? clsx(classes.dataGrid, classes.notDataContainer)
                            : classes.dataGrid
                        }
                        components={{
                          Footer: customPagination,
                          NoRowsOverlay: TableOverlay,
                        }}
                        hideFooter={
                          searchUserFilter.user != "" &&
                          filterUserInvitation.length == 0
                        }
                        rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                      />
                    )}
                    {!loadingInvitations &&
                      !ErrorInvitations &&
                      invitations.length === 0 && (
                        <Box marginTop={5}>
                          <InformativeScreen
                            type="notMessages"
                            subtitle="¡No encontramos Invitaciones Aceptadas!"
                            info="Esto significa que no has enviado
                            inivitaciones ó todas la invitaciones que
                            enviaste estan En Espera ó han sido
                            Rechazadas."
                            onlyError
                          />
                        </Box>
                      )}
                    {ErrorInvitations && invitations.length === 0 && (
                      <Box marginTop={5}>
                        <InformativeScreen
                          type="technicalError"
                          subtitle="¡Ha ocurrido un error inesperado!"
                          info="Estamos trabajando para resolverlo."
                          onlyError
                        />
                      </Box>
                    )}
                    {loadingInvitations && (
                      <Box textAlign="center" marginTop={5}>
                        <CircularProgress color="primary" size={60} />
                      </Box>
                    )}
                  </div>
                </Box>
              </TabPanel>

              <TabPanel value={value} index={2}>
                <Box
                  height="auto"
                  width="100%"
                  className={
                    searchUserFilter.user != "" &&
                    filterUserInvitation.length == 0
                      ? classes.heightContainer
                      : classes.colorNoRow
                  }
                >
                  <div style={{ height: "auto", width: "100%" }}>
                    {!loadingInvitations && invitations.length !== 0 && (
                      <DataGrid
                        rows={
                          searchUserFilter.user != ""
                            ? filterUserInvitation
                            : invitations.reverse()
                        }
                        columns={columns}
                        autoHeight
                        pageSize={20}
                        className={
                          searchUserFilter.user != "" &&
                          filterUserInvitation.length == 0
                            ? clsx(classes.dataGrid, classes.notDataContainer)
                            : classes.dataGrid
                        }
                        components={{
                          Footer: customPagination,
                          NoRowsOverlay: TableOverlay,
                        }}
                        hideFooter={
                          searchUserFilter.user != "" &&
                          filterUserInvitation.length == 0
                        }
                        rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                      />
                    )}
                    {!loadingInvitations &&
                      !ErrorInvitations &&
                      invitations.length === 0 && (
                        <Box marginTop={5}>
                          <InformativeScreen
                            type="notMessages"
                            subtitle="¡No encontramos Invitaciones Rechazadas!"
                            info="Esto significa que no has enviado
                            inivitaciones ó todas la invitaciones que
                            enviaste estan En Espera ó han sido Aceptadas."
                            onlyError
                          />
                        </Box>
                      )}
                    {ErrorInvitations && invitations.length === 0 && (
                      <Box marginTop={5}>
                        <InformativeScreen
                          type="technicalError"
                          subtitle="¡Ha ocurrido un error inesperado!"
                          info="Estamos trabajando para resolverlo."
                          onlyError
                        />
                      </Box>
                    )}
                    {loadingInvitations && (
                      <Box textAlign="center" marginTop={5}>
                        <CircularProgress color="primary" size={60} />
                      </Box>
                    )}
                  </div>
                </Box>
              </TabPanel>
            </>
          ) : (
            <>
              <TabPanelRequest value={valueRequest} index={0}>
                <Box
                  height="auto"
                  width="100%"
                  className={
                    searchUserFilter.user != "" &&
                    filterUserInvitationReceived.length == 0
                      ? classes.heightContainer
                      : classes.colorNoRow
                  }
                >
                  <div style={{ height: "auto", width: "100%" }}>
                    {!loadingInvitationsReceived &&
                      invitationsReceived.length !== 0 && (
                        <DataGrid
                          rows={
                            searchUserFilter.user != ""
                              ? filterUserInvitationReceived
                              : invitationsReceived
                          }
                          columns={columnsRequest}
                          autoHeight
                          className={
                            searchUserFilter.user != "" &&
                            filterUserInvitationReceived.length == 0
                              ? clsx(classes.dataGrid, classes.notDataContainer)
                              : classes.dataGrid
                          }
                          components={{
                            Footer: customPagination,
                            NoRowsOverlay: TableOverlay,
                          }}
                          hideFooter={
                            searchUserFilter.user != "" &&
                            filterUserInvitationReceived.length == 0
                          }
                          pageSize={pageSize}
                          rowsPerPageOptions={[5, 10, 15, 20]}
                          page={page}
                          onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                          onPageChange={(e: any) => setPage(e.page)}
                        />
                      )}
                    {!loadingInvitationsReceived &&
                      !errorInvitationsReceived &&
                      invitationsReceived.length === 0 && (
                        <Box marginTop={5}>
                          <InformativeScreen
                            type="notMessages"
                            subtitle="¡No hay nada por aquí!"
                            info="Esto significa que no te han enviado invitaciones o todas las invitaciones que te enviaron ya las respondiste y fueron Aceptadas o Rechazadas."
                            onlyError
                          />
                        </Box>
                      )}
                    {errorInvitationsReceived &&
                      invitationsReceived.length === 0 && (
                        <Box marginTop={5}>
                          <InformativeScreen
                            type="technicalError"
                            subtitle="¡Ha ocurrido un error inesperado!"
                            info="Estamos trabajando para resolverlo."
                            onlyError
                          />
                        </Box>
                      )}
                    {loadingInvitationsReceived && (
                      <Box textAlign="center" marginTop={5}>
                        <CircularProgress color="primary" size={60} />
                      </Box>
                    )}
                  </div>
                </Box>
              </TabPanelRequest>

              <TabPanelRequest value={valueRequest} index={1}>
                <Box
                  height="auto"
                  width="100%"
                  className={
                    searchUserFilter.user != "" &&
                    filterUserInvitationReceived.length == 0
                      ? classes.heightContainer
                      : classes.colorNoRow
                  }
                >
                  <div style={{ height: "auto", width: "100%" }}>
                    {!loadingInvitationsReceived &&
                      invitationsReceived.length !== 0 && (
                        <DataGrid
                          rows={
                            searchUserFilter.user != ""
                              ? filterUserInvitationReceived
                              : invitationsReceived
                          }
                          columns={columnsRequest}
                          autoHeight
                          className={
                            searchUserFilter.user != "" &&
                            filterUserInvitationReceived.length == 0
                              ? clsx(classes.dataGrid, classes.notDataContainer)
                              : classes.dataGrid
                          }
                          components={{
                            Footer: customPagination,
                            NoRowsOverlay: TableOverlay,
                          }}
                          hideFooter={
                            searchUserFilter.user != "" &&
                            filterUserInvitationReceived.length == 0
                          }
                          pageSize={pageSize}
                          rowsPerPageOptions={[5, 10, 15, 20]}
                          page={page}
                          onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                          onPageChange={(e: any) => setPage(e.page)}
                        />
                      )}
                    {!loadingInvitationsReceived &&
                      !errorInvitationsReceived &&
                      invitationsReceived.length === 0 && (
                        <Box marginTop={5}>
                          <InformativeScreen
                            type="notMessages"
                            subtitle="¡No hay nada por aquí!"
                            info="Esto significa que no te han enviado invitaciones o todas las invitaciones que te enviaron ya las respondiste y fueron Aceptadas o todavía no le has dado una respuesta y están En Espera."
                            onlyError
                          />
                        </Box>
                      )}
                    {errorInvitationsReceived &&
                      invitationsReceived.length === 0 && (
                        <Box marginTop={5}>
                          <InformativeScreen
                            type="technicalError"
                            subtitle="¡Ha ocurrido un error inesperado!"
                            info="Estamos trabajando para resolverlo."
                            onlyError
                          />
                        </Box>
                      )}
                    {loadingInvitationsReceived && (
                      <Box textAlign="center" marginTop={5}>
                        <CircularProgress color="primary" size={60} />
                      </Box>
                    )}
                  </div>
                </Box>
              </TabPanelRequest>

              <TabPanelRequest value={valueRequest} index={2}>
                <Box
                  height="auto"
                  width="100%"
                  className={
                    searchUserFilter.user != "" &&
                    filterUserInvitationReceived.length == 0
                      ? classes.heightContainer
                      : classes.colorNoRow
                  }
                >
                  <div style={{ height: "auto", width: "100%" }}>
                    {!loadingInvitationsReceived &&
                      invitationsReceived.length !== 0 && (
                        <DataGrid
                          rows={
                            searchUserFilter.user != ""
                              ? filterUserInvitationReceived
                              : invitationsReceived
                          }
                          columns={columnsRequest}
                          autoHeight
                          className={
                            searchUserFilter.user != "" &&
                            filterUserInvitationReceived.length == 0
                              ? clsx(classes.dataGrid, classes.notDataContainer)
                              : classes.dataGrid
                          }
                          components={{
                            Footer: customPagination,
                            NoRowsOverlay: TableOverlay,
                          }}
                          hideFooter={
                            searchUserFilter.user != "" &&
                            filterUserInvitationReceived.length == 0
                          }
                          pageSize={pageSize}
                          rowsPerPageOptions={[5, 10, 15, 20]}
                          page={page}
                          onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                          onPageChange={(e: any) => setPage(e.page)}
                        />
                      )}
                    {!loadingInvitationsReceived &&
                      !errorInvitationsReceived &&
                      invitationsReceived.length === 0 && (
                        <Box marginTop={5}>
                          <InformativeScreen
                            type="notMessages"
                            subtitle="¡No hay nada por aquí!"
                            info="Esto significa que no te han enviado invitaciones o todas las invitaciones que te enviaron ya las respondiste y fueron Rechazadas o todavía no le has dado una respuesta y están En Espera."
                            onlyError
                          />
                        </Box>
                      )}
                    {errorInvitationsReceived &&
                      invitationsReceived.length === 0 && (
                        <Box marginTop={5}>
                          <InformativeScreen
                            type="technicalError"
                            subtitle="¡Ha ocurrido un error inesperado!"
                            info="Estamos trabajando para resolverlo."
                            onlyError
                          />
                        </Box>
                      )}
                    {loadingInvitationsReceived && (
                      <Box textAlign="center" marginTop={5}>
                        <CircularProgress color="primary" size={60} />
                      </Box>
                    )}
                  </div>
                </Box>
              </TabPanelRequest>
            </>
          )}
        </ContentCard.Content>
      </ContentCard>
      <DialogSearchUser
        open={openSearchUser.open}
        data={openSearchUser.search}
        handleClose={() => handleCloseSearchUser(openSearchUser.search)}
      />
    </>
  );
};

export default Main;
