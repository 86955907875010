import React, { useState, useEffect } from "react";

import { Box, Grid } from "@material-ui/core";

import Pig from "components/PageComponents/Profile/MarketerTypes/marketerTypesPig";
import CardSelectType from "components/PageComponents/Profile/MarketerTypes/CardSelectType";

import { MARKETER_TYPE } from "core/graphql/queries/profile";

import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const MarketerTypes = ({ children }: any) => {
  const token = useSelector((state: any) => state.user.token);
  const path = useLocation();
  const [dataTypes, setDataTypes] = useState<any>([]);
  const [typesSelected, setTypesSelected] = useState<any>([]);

  const { data, loading, refetch } = useQuery(MARKETER_TYPE, {
    context: { headers: { "x-auth-jwt": token } },
  });

  useEffect(() => {
    if (!loading && data) {
      setDataTypes(data?.getMarketerType?.data);
      setTypesSelected(
        data?.getMarketerType?.data
          .filter((item: any) => item?.estado)
          ?.map((i: any) => i?.idTipoComercializador)
      );
    }
  }, [loading, data]);

  useEffect(() => {
    refetch();
  }, [path]);

  const handleChange = (id: any) => {
    if (typesSelected.includes(id)) {
      const remove = typesSelected.filter((item: any) => item != id);
      setTypesSelected(remove);
    } else {
      setTypesSelected([...typesSelected, id]);
    }
  };

  return (
    <>
      {children({ typesSelected })}
      <Box>
        <Grid xs={12} spacing={2} container alignItems="center">
          <Grid item xs={8}>
            <Grid item container xs={12} spacing={3}>
              {dataTypes?.map((item: any) => {
                return (
                  <Grid item container xs={6}>
                    <CardSelectType
                      name={item?.idTipoComercializador}
                      title={item?.nombre}
                      description={item?.descripcion}
                      isCheck={typesSelected.includes(
                        item?.idTipoComercializador
                      )}
                      onchange={() => handleChange(item?.idTipoComercializador)}
                      value={item?.idTipoComercializador}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Pig />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MarketerTypes;
