import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  tablecontainer: {
    marginTop: 2,
    marginRight: 18,
  },
  table: {
    display: 'table',
    borderStyle: 'solid',
    marginTop: 2,
  },
  tableCol: {
    width: '50%',
    textAlign: 'left',
  },
  tableCell: {
    marginTop: 3,
    fontSize: 7,
  },
  tableColRigth: {
    width: '50%',
    textAlign: 'right',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
  }
})