import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container:{
    width: 'auto'
  },
  avatar:{
  background: '#767676', 
 },
  icon:{
   fontSize: 24, 
 }, 
 text:{
   fontSize: 20, 
 }, 
 textBold:{
   fontWeight: 'bold'
 }
}))

export { useStyles as default }
