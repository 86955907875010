import { Document, Page, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import userLogo from 'static/img/reportsIcons/userLogo';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';

import styles from './styles/userActivities';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import ItemTotal from 'components/pdf-components/ItemTotal';
import dayjs from 'dayjs';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

interface MyDocUsersActivitiesProps {
  qr: string;
  dataActivity?: any;
  totalUsuarios?: any;
}

export const MyDocUsersActivities = ({
  qr,
  dataActivity,
  totalUsuarios,
}: MyDocUsersActivitiesProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={dayjs().format('DD/MM/YYYY')}
        reportImage={userLogo}
        number="023456789"
        userTitile="Fecha"
        title="Reporte Actividades de los Usuarios"
        code="IA-001"
      />
      <Subtitle title="Resumen de Actividad" widthContainer="100%" />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value={totalUsuarios}
            info="Total Usuarios Registrados"
            position="right"
            textAlign="left"
            backgroundColor="#b0413e"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value={totalUsuarios}
            info="Total Usuarios Activos"
            position="right"
            textAlign="left"
            backgroundColor="#be5948"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value={dataActivity?.data?.length}
            info="Total Actividades"
            position="right"
            widthInfo="55px"
            backgroundColor="#d47d57"
          />
        </View>
        <View
          style={{
            width: '23.5%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="N/D"
            info="Total Tiempo"
            position="right"
            backgroundColor="#f1ae6c"
          />
        </View>
      </View>

      <View style={{ marginTop: 10, marginBottom: 10 }}>
        <Subtitle title="Detalle de la Actividad" widthContainer="100%" />
      </View>

      {dataActivity?.data.length > 0 ? (
        <View>
          <View style={[styles.table, { marginBottom: 10 }]} wrap={false}>
            <View
              wrap={false}
              style={[
                styles.tableRow,
                { marginBottom: '5px', marginTop: '5px', alignItems: 'center' },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Usuario
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 130, width: 130 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Fecha Inicial
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 130, width: 130 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Fecha Final
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 90.5, width: 90.5 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Tiempo Total
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 107.5, width: 107.5 }]}
              >
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Registro
                </Text>
              </View>
            </View>
            {dataActivity?.data?.map((item: any, index: number) => (
              <View
                key={index}
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableColActivities,
                    { maxWidth: 92.5, width: 92.5 },
                  ]}
                >
                  <Text style={[styles.tableActivities, { fontSize: 8 }]}>
                    {item.usuario}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColActivities,
                    { maxWidth: 130, width: 130 },
                  ]}
                >
                  <Text style={[styles.tableActivities, { fontSize: 8 }]}>
                    {item.fechaInicial
                      ? dayjs(new Date(item.fechaInicial)).format(
                          'DD/MM/YYYY - HH:mm:ss'
                        )
                      : 'N/D'}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColActivities,
                    { maxWidth: 130, width: 130 },
                  ]}
                >
                  <Text style={[styles.tableActivities, { fontSize: 8 }]}>
                    {item.fechaFinal
                      ? dayjs(new Date(item.fechaFinal)).format(
                          'DD/MM/YYYY - HH:mm:ss'
                        )
                      : 'N/D'}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColActivities,
                    { maxWidth: 90.5, width: 90.5 },
                  ]}
                >
                  <Text style={[styles.tableActivities, { fontSize: 8 }]}>
                    {item.tiempoTotal ? item.tiempoTotal : 'N/D'}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColActivities,
                    { maxWidth: 110, width: 110 },
                  ]}
                >
                  <Text style={[styles.tableActivities, { fontSize: 8 }]}>
                    {item.registro ? item.registro : 'N/D'}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      ) : (
        <View
          style={{
            marginTop: '5px',
            alignItems: 'center',
          }}
        >
          <Subtitle
            title="No tienes usuarios con actividades registradas."
            heightContainer="24px"
            widthContainer="60%"
          />
        </View>
      )}

      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportUsersActivities = () => {
  return (
    <div id="screenUsersActivities" style={{ width: 500 }}>
      <QRCode
        id="screenUsersActivities"
        size={500}
        value="Reporte Actividades de los Usuarios"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
