import {
  MenuItem,
  TextField,
  Button,
  Box,
  Typography,
  Chip,
} from '@material-ui/core';
import { BreadcrumbProps } from '@infoporcinos/core/dist/BreadcrumbBuilder';
import { BreadcrumbBuilder as Breadcrumb } from '@infoporcinos/core';
import React, { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import { Icon } from 'semantic-ui-react';
import domToImage from 'dom-to-image';
import dayjs, { Dayjs } from 'dayjs';

import usePagination from 'lib/hooks/usePagination';
import FlexContainer from 'lib/ui/FlexContainer';
import PageInfo from 'lib/ui/PageInfo';

import DialogPDFViewer from 'components/DialogPDFViewer';
import ContentCard from 'components/ContentCard';
import GoBackLink from 'components/GobackLink';
import Loader from 'components/Loader';

import useStyles, {
  CardHeaderPaginator,
  PaginationContainer,
  PaginationControls,
  SearchContainerStyle,
} from './styles';
import { useSelector } from 'react-redux';

const filterTypes = ['range', 'week', 'today', 'lote'];

export interface withSearchLayoutParams {
  ReportComponent?: any;
  SchemasComponent?: any;
  breadcrumbs?: BreadcrumbProps[];
  pictureIds?: string[];
  pagination?: boolean;
}

export interface WrappedComponentProps {
  values: {
    initialDate?: Dayjs | string;
    endDate?: Dayjs | string;
    searchByLote: {
      isValid: boolean;
      value: string;
    };
  };
  states: {
    results: {
      get: any;
      set: (prev?: any) => void;
    };
    pictures: {
      get: string[];
      set: (prev?: string[]) => void;
    };
    reportData: {
      get: any;
      set: (prev?: any) => void | any[];
    };
    loading: {
      get: boolean;
      set: (prev?: boolean) => void;
    };
    disableReport: {
      get: boolean;
      set: (prev?: boolean) => void;
    };
    withoutType: {
      get: boolean;
      set: (prev?: boolean) => void;
    };
    schemas: {
      get: any;
      set: (prev?: any) => void;
    };
  };
  pagination: {
    page?: number;
    count?: number;
    perPage?: number;
    showLastButton?: boolean;
    showFirstButton?: boolean;
    data?: any;
  };
  data: any;
}

const withSearchLayout =
  (WrappedComponent: any, options: withSearchLayoutParams = {}) =>
  () => {
    const {
      ReportComponent,
      SchemasComponent,
      breadcrumbs,
      pictureIds,
      pagination = true,
    } = options;
    const classes = useStyles();
    const url = new URLSearchParams(window.location.search);

    const uid = useSelector((state: any) => state.user.user.id);
    const [open, setOpen] = useState(false);
    const [disableReport, setDisableReport] = useState(true);
    const [load, setLoad] = useState(true);
    const [withoutType, setWithoutType] = useState(false);
    const [type] = useState(url.get('type'));
    const [searchByLote] = useState({
      isValid: url.get('type') === 'lote',
      value: url.get('code') ?? '',
    });
    const [initialDate, setInitialDate] = useState<Dayjs>();
    const [endDate, setEndDate] = useState<Dayjs>();

    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(20);

    const [schemas, setSchemas] = useState({});
    const [results, setResults] = useState<any>([]);
    const [reportData, setReportData] = useState<{ loaded: boolean } & any>({
      loaded: false,
    });
    const [reportPictures, setReportPictures] = useState({});
    const count = Math.ceil(results.length / perPage);
    const _DATA = usePagination(results, perPage);

    function handleChangePagination(e: any, p: number) {
      setPage(p);
      _DATA.jump(p);
    }

    useEffect(() => {
      const uri = {
        initialDate: url.get('initialdate'),
        finalDate: url.get('finaldate'),
      };
      if (type && filterTypes.includes(type)) {
        if (uri.initialDate && uri.finalDate) {
          const initial = dayjs(new Date(Number.parseInt(uri.initialDate)));
          const final = dayjs(new Date(Number.parseInt(uri.finalDate)));
          setInitialDate(initial);
          setEndDate(final);
          setLoad(false);
          setReportData((prev: any) => ({
            ...prev,
            initialDate: initial,
            finalDate: final,
            uid,
          }));
        }
      } else {
        setWithoutType(true);
        setLoad(false);
      }
    }, []);

    const getInfoFilter = () => {
      switch (type) {
        case 'lote':
          return (
            <>
              <Typography className={classes.filterType}>Lote</Typography>
              <Typography className={classes.filterValue}>
                <span className="param">{url.get('code')}</span>
              </Typography>
            </>
          );
        case 'today':
          return (
            <>
              <Typography className={classes.filterType}>
                Criterio de búsqueda:
              </Typography>
              <Typography className={classes.filterValue}>
                <span className="param">
                  {dayjs(initialDate).format('DD/MM/YYYY')} (Hoy)
                </span>
              </Typography>
            </>
          );
        case 'week':
          return (
            <>
              <Typography className={classes.filterType}>
                Criterio de búsqueda:
              </Typography>
              <Typography className={classes.filterValue}>
                <span className="param">
                  Semana {dayjs(initialDate).add(2, 'days').week()}
                </span>{' '}
                (Desde el{' '}
                <span className="param">
                  {' '}
                  {dayjs(initialDate).format('dddd DD')}{' '}
                </span>{' '}
                hasta el{' '}
                <span className="param">
                  {dayjs(endDate).format('dddd DD')}
                </span>
                )
              </Typography>
            </>
          );
        case 'range':
          return (
            <>
              <Typography className={classes.filterType}>
                Criterio de búsqueda:
              </Typography>
              <Typography className={classes.filterValue}>
                <span className="param">
                  {dayjs(initialDate).format('YYYY/MM/DD')}
                </span>{' '}
                hasta{' '}
                <span className="param">
                  {dayjs(endDate).format('YYYY/MM/DD')}
                </span>
              </Typography>
            </>
          );
        default:
          return (
            <Typography className={classes.filterType}>
              El tipo de filtro solicitado no existe
            </Typography>
          );
      }
    };

    const getTitleFilter = () => {
      if (withoutType) return 'Indefinido';
      switch (type) {
        case 'range':
          return 'Rango de Fecha';
        case 'week':
          return 'Semana';
        case 'today':
          return 'Hoy';
        case 'lote':
          return 'Lote';
        default:
          return 'Indefinido';
      }
    };

    const generateScreenshot = () => {
      if (pictureIds && Array.isArray(pictureIds)) {
        pictureIds.forEach((picture) => {
          const node = document.getElementById(picture) as Node;
          domToImage
            .toPng(node)
            .then(function (dataUrl) {
              setReportPictures((prev) => ({
                ...prev,
                [picture]: dataUrl,
              }));
            })
            .catch(function (error) {
              console.error('something went wrong', error.toString());
            });
        });
      }
    };

    const handleOpenReport = () => {
      generateScreenshot();
      setOpen(true);
    };

    return (
      <SearchContainerStyle>
        <Loader visible={load}>Cargando</Loader>
        {SchemasComponent && (
          <div style={{ position: 'absolute', left: '-1000%' }}>
            {!load && <SchemasComponent {...schemas} />}
          </div>
        )}
        <div>
          <PageInfo>
            <GoBackLink />
            {breadcrumbs && <Breadcrumb breadcrumbs={breadcrumbs} />}
          </PageInfo>
          <ContentCard padding="2%">
            <CardHeaderPaginator>
              <div>
                <span className="title">Búsqueda: {getTitleFilter()}</span>
                {!searchByLote.isValid && (
                  <Button
                    className="raleway"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenReport}
                    disabled={disableReport}
                  >
                    Generar Reporte
                  </Button>
                )}
              </div>
              {pagination && (
                <Pagination
                  page={page}
                  count={count}
                  variant="outlined"
                  color="primary"
                  showLastButton={results.length / perPage > 3}
                  showFirstButton={results.length / perPage > 3}
                  onChange={handleChangePagination}
                />
              )}
            </CardHeaderPaginator>
            <ContentCard.Content>
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop={2}
                marginBottom={2}
              >
                <FlexContainer className={classes.filter}>
                  {getInfoFilter()}
                </FlexContainer>

                <Chip
                  variant="outlined"
                  color="primary"
                  icon={<Icon className="icon-document" size="big" />}
                  label={`${results.length} resultados`}
                />
              </Box>
              <WrappedComponent
                values={{
                  initialDate,
                  endDate,
                  searchByLote,
                }}
                states={{
                  results: { get: results, set: setResults },
                  pictures: { get: reportPictures, set: setReportPictures },
                  reportData: { get: reportData, set: setReportData },
                  loading: { get: load, set: setLoad },
                  disableReport: { get: disableReport, set: setDisableReport },
                  withoutType: { get: withoutType, set: setWithoutType },
                  schemas: { get: schemas, set: setSchemas },
                }}
                pagination={
                  pagination
                    ? {
                        page,
                        count,
                        perPage,
                        showLastButton: results.length / perPage > 3,
                        showFirstButton: results.length / perPage > 3,
                        data: _DATA.currentData(),
                      }
                    : {}
                }
                data={results}
              />
              <table className="table">
                <tbody>
                  {results.length < 1 && !load && (
                    <tr>
                      <td colSpan={8} style={{ textAlign: 'center' }}>
                        <Box paddingY={3}>No se encontraron resultados</Box>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {pagination && (
                <PaginationContainer>
                  <Pagination
                    page={page}
                    count={count}
                    variant="outlined"
                    color="primary"
                    showLastButton={results.length / perPage > 3}
                    showFirstButton={results.length / perPage > 3}
                    onChange={handleChangePagination}
                  />
                </PaginationContainer>
              )}
            </ContentCard.Content>
          </ContentCard>
        </div>
        {pagination && (
          <PaginationControls>
            <TextField
              label="Resultados por página"
              variant="outlined"
              color="primary"
              size="small"
              value={perPage}
              onChange={(e: any) => setPerPage(e.target.value)}
              style={{ width: 200 }}
              select
            >
              {[20, 50, 100].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </PaginationControls>
        )}
        <DialogPDFViewer
          open={open}
          onClose={() => setOpen(false)}
          done={!reportData.loaded}
        >
          {ReportComponent && (
            <ReportComponent {...reportData} {...reportPictures} />
          )}
        </DialogPDFViewer>
      </SearchContainerStyle>
    );
  };

export default withSearchLayout;
