import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Fab,
  Card,
  CardActionArea,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import AvatarIconInfo from 'components/AvatarIconInfo';
import ExistingUser from 'components/ExistingUser';
import Slider from 'components/Slider';
import MainTitle from 'components/MainTitle';
import SelectDate from 'components/SelectDate';

import dayjs from 'dayjs';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import useStyles from './styles';
interface ShareReportProps {
  children: any;
}

const ShareReport = ({ children }: ShareReportProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [list, setList] = useState<any>([]);
  const [addUser, setAddUser] = useState(false);
  const [listSelected, setListSelected] = useState<any>([]);
  const [listMain, setListMain] = useState<any>([]);
  const [shareUnlimited, setShareUnlimited] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [initialDate, setInitialDate] = useState(dayjs());
  const [finalDate, setFinalDate] = useState(dayjs());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenShare = () => {
    handleClose();
    setOpenShare(true);
    setListSelected(list);
    setListMain(usersToShare);
  };
  const handleCloseShare = () => setOpenShare(false);

  const handleOpenConfir = () => {
    setOpenConfirmation(true);
    handleCloseShare();
  };
  const handleCloseConfir = () => setOpenConfirmation(false);

  const handleChangeShare = (e: number) => {
    if (listSelected.includes(e)) {
      const removed = listSelected.filter((item: any) => item !== e);
      setListSelected(removed);
    } else {
      setListSelected([...listSelected, e]);
    }
  };

  const selected = listMain.filter((user: any) =>
    listSelected.includes(user.id)
  );

  const removeUser = (e: number) => {
    const removed = listSelected.filter((item: any) => item !== e);
    setListSelected(removed);
  };
  const data = {
    name: 'Tiquete de Báscula',
    details: {
      'Lote Infoporcinos': 2040,
      'Nro de Reporte': 203050,
      'Fecha de Creación': dayjs().format('DD-MM-YYYY'),
    },
  };
  const withAccess = [
    {
      name: 'Leidy Marcela Álvarez',
      id: 'CC 1000295214',
      sharedBy: 'Ivan Echeverri',
      since: dayjs().format('DD-MM-YYYY'),
      until: dayjs().date(20).format('DD-MM-YYYY'),
    },
  ];

  const usersToShare = [
    {
      name: 'Leidy Marcela Álvarez',
      info: 'CC 1000295214',
      id: 4,
      profile: 'Comercializador',
    },
    {
      name: 'Juan Sebastian Izquierdo',
      info: 'CC 154782220',
      id: 5,
      profile: 'Productor',
    },
    {
      name: 'Sebastian Mosquera',
      info: 'CC 121478510',
      id: 6,
      profile: 'Comercializador',
    },
    {
      name: 'Paula Andrea Bolivar',
      info: 'CC 19876521',
      id: 7,
      profile: 'Productor - Comercializador',
    },
  ];

  const handleChange = (e: number) => {
    if (list.includes(e)) {
      const removed = list.filter((item: any) => item !== e);
      setList(removed);
    } else {
      setList([...list, e]);
    }
  };

  const usersSelected = usersToShare.filter((user: any) =>
    list.includes(user.id)
  );

  return (
    <>
      {children({ handleOpen })}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="body"
      >
        <DialogTitle>
          <Box display="flex" justifyContent="center">
            <AvatarIconInfo
              icon="icon-ticket"
              text={`Compartir reporte ${data.name}`}
              avatarColor="#DD9835"
              textBold
              textColor="#767676"
            />
          </Box>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <Grid
            container
            xs={12}
            spacing={2}
            className={classes.containerPrimaryInfo}
          >
            {_.map(data.details, (value, key) => (
              <Grid item xs={4}>
                <Box className={classes.primaryInfoContent}>
                  <Typography variant="subtitle1" className={classes.info}>
                    {key}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.info}>
                    {value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box className={classes.subtitleContainer}>
            <Box>
              <MainTitle>Usuarios disponibles para compartir</MainTitle>
              <Typography className={classes.description}>
                A continuación seleccione los usuarios a los cuales va a
                compartir este reporte
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/app/network/invitations"
            >
              Agregar usuario
            </Button>
          </Box>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Slider>
                {usersToShare.map((item: any) => {
                  const [select, setSelect] = useState(false);
                  return (
                    <ExistingUser
                      name={item.name}
                      info={item.info}
                      selected={select}
                      onClick={() => {
                        setSelect(!select);
                        handleChange(item.id);
                      }}
                      column
                    />
                  );
                })}
                <Card className={classes.card}>
                  <CardActionArea
                    className={classes.content}
                    component={Link}
                    to="/app/network/invitations"
                  >
                    <i className="icon-plus" />
                  </CardActionArea>
                </Card>
              </Slider>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Tooltip
                title={usersSelected.map((user: any) => (
                  <p>{user.name}</p>
                ))}
                placement="right"
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={usersSelected.length === 0}
                  onClick={handleOpenShare}
                >
                  Compartir reporte
                  {usersSelected.length === 0
                    ? ''
                    : ` con ${usersSelected.length} ${
                        usersSelected.length === 1 ? 'usuario' : 'usuarios'
                      }`}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={3}>
            <AvatarIconInfo
              icon="icon-users"
              text="Usuarios con Acceso"
              avatarColor="#DD9835"
              textBold
              textColor="#767676"
            />
          </Box>
          <Box width="100%" margin="12px 0px">
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nombre</TableCell>
                  <TableCell align="center">Documento</TableCell>
                  <TableCell align="center">Compartido Por</TableCell>
                  <TableCell align="center">Compartido Desde</TableCell>
                  <TableCell align="center">Compartido Hasta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {withAccess.map((item: any) => {
                  return (
                    <TableRow className={classes.itemGrey}>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">{item.id}</TableCell>
                      <TableCell align="center">{item.sharedBy}</TableCell>
                      <TableCell align="center">{item.since}</TableCell>
                      <TableCell align="center">{item.until}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openShare}
        onClose={handleCloseShare}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="body"
      >
        <DialogTitle>
          <Box display="flex" justifyContent="center">
            <AvatarIconInfo
              icon="icon-ticket"
              text="Compartir reporte Tiquete de Báscula"
              avatarColor="#DD9835"
              textBold
              textColor="#767676"
            />
          </Box>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <Grid
            container
            xs={12}
            spacing={2}
            className={classes.containerPrimaryInfo}
          >
            {_.map(data?.details, (value, key) => (
              <Grid item xs={4}>
                <Box className={classes.primaryInfoContent}>
                  <Typography variant="subtitle1" className={classes.info}>
                    {key}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.info}>
                    {value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.description}>
              A continuación se listan los usuarios seleccionados para compartir
              el reporte.
            </Typography>
          </Box>
          <Box width="100%" margin="12px 0px">
            {addUser || selected.length === 0 ? (
              <Slider>
                {listMain.map((item: any) => {
                  const idSelected = listSelected.map((e: any) => e);
                  return (
                    <ExistingUser
                      name={item.name}
                      info={item.info}
                      selected={idSelected.includes(item.id)}
                      onClick={() => {
                        handleChangeShare(item.id);
                      }}
                      column
                    />
                  );
                })}
                <Card className={classes.card}>
                  <CardActionArea
                    className={classes.content}
                    component={Link}
                    to="/app/network/invitations"
                  >
                    <i className="icon-plus" />
                  </CardActionArea>
                </Card>
              </Slider>
            ) : (
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Documento</TableCell>
                    <TableCell>Perfil</TableCell>
                    <TableCell align="center">No compartir</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selected.map((item: any) => {
                    return (
                      <TableRow className={classes.itemGrey}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.info}</TableCell>
                        <TableCell>{item.profile}</TableCell>
                        <TableCell align="center">
                          <Fab
                            color="primary"
                            className={classes.btnDetails}
                            onClick={() => removeUser(item.id)}
                          >
                            <i
                              className="icon-close"
                              style={{ fontSize: '20px' }}
                            />
                          </Fab>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              margin="16px 0px"
            >
              {selected.length === 0 ? null : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setAddUser(!addUser)}
                >
                  {addUser
                    ? 'Agregar usuarios seleccioandos'
                    : 'Agregar usuario'}
                </Button>
              )}
            </Box>
            <Box mt={3}>
              <MainTitle>Configuración para compartir el reporte</MainTitle>
              <Typography className={classes.description}>
                A continuación tiene la opción de definir un tiempo limite para
                dar acceso a los usuarios seleccionados para ver el reporte, si
                no define un tiempo limite, el reporte se compartirá de manera
                indefinida.
              </Typography>
              <Box width="100%" marginTop={3}>
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={4}>
                    <SelectDate
                      value={initialDate}
                      label="Fecha Inicial"
                      onChange={(e: any) => setInitialDate(e)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid container item xs={1} justifyContent="center">
                    Hasta
                  </Grid>
                  <Grid item xs={4}>
                    <SelectDate
                      label="Fecha Final"
                      onChange={(e: any) => setFinalDate(e)}
                      disabled={shareUnlimited}
                      limitDate={initialDate}
                    />
                  </Grid>
                </Grid>
                <Box marginTop={2} display="flex" justifyContent="center">
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        value={shareUnlimited}
                        name="promotion"
                        color="primary"
                        onChange={() => setShareUnlimited(!shareUnlimited)}
                      />
                    }
                    label="Compartir de manera indefinida"
                    labelPlacement="start"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenConfir}
          >
            Compartir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfir}
        className={classes.dialogContainer}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Box className={classes.contentDialog}>
            <Box
              component="i"
              className={'icon-circle-check'}
              fontSize="60px"
              color="#DD9835"
            />
            <Typography variant="h6" color="primary">
              El reporte ha sido compartido exitosamente
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <Grid
            container
            xs={12}
            spacing={2}
            className={classes.containerPrimaryInfo}
          >
            {_.map(data?.details, (value, key) => (
              <Grid item xs={4}>
                <Box className={classes.primaryInfoContent}>
                  <Typography variant="subtitle1" className={classes.info}>
                    {key}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.info}>
                    {value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box width="100%">
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nombre</TableCell>
                  <TableCell align="center">Documento</TableCell>
                  <TableCell align="center">Compartido Por</TableCell>
                  <TableCell align="center">Compartido Desde</TableCell>
                  <TableCell align="center">Compartido Hasta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selected.map((item: any) => {
                  return (
                    <TableRow className={classes.itemGrey}>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">{item.info}</TableCell>
                      <TableCell align="center">{item.sharedBy}</TableCell>
                      <TableCell align="center">{item.since}</TableCell>
                      <TableCell align="center">{item.until}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseConfir}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShareReport;
