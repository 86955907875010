export const SET_COUNTRIES = 'SET_COUNTRIES'

export const SET_COUNTRY_SELECTED = 'SET_COUNTRY_SELECTED'

export const SET_DEPARTMENTS = 'SET_DEPARTMENTS'

export const SET_RENDER_DEPARTMENTS = 'SET_RENDER_DEPARTMENTS'

export const SET_RENDER_CITIES = 'SET_RENDER_CITIES'

export const SIGN_OUT = 'SIGN_OUT'
