import React, { useState, useRef, useMemo, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import useStyles from './styles';

const autocompleteServive: any = { current: null };

export default (props: any) => {
  const classes = useStyles();
  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const loaded = useRef(false);
  const { handleChange } = props;

  if (typeof window !== 'undefined' && !loaded.current) {
    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle(
        (request: any, callback: any) =>
          autocompleteServive.current?.getPlacePredictions(request, callback),
        200
      ),
    []
  );

  useEffect((): any => {
    let active = true;
    if (!autocompleteServive.current && window.google)
      autocompleteServive.current =
        new window.google.maps.places.AutocompleteService();
    if (!autocompleteServive.current) return undefined;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch({ input: inputValue }, (results: any) => {
      if (active) {
        let newOptions: any[] = [];
        if (value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];
        setOptions(newOptions);
      }
    });
    return () => (active = false);
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      className={classes.autocomplete}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value?.place_id}
      onChange={(_, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        handleChange(newValue?.place_id);
      }}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.input}
          placeholder="Buscar dirección"
          variant="outlined"
          size="small"
          autoFocus
          fullWidth
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part: any, index: any) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
