import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button as MuiButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Grid as MuiGrid,
  Paper,
  Box,
  TextField,
  Chip,
  AppBar,
  Toolbar,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import {
  AccumulationChartComponent,
  Inject,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationTooltip,
} from '@syncfusion/ej2-react-charts';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { Grid, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { ExpandMore } from '@material-ui/icons';
import domtoimage from 'dom-to-image';
import moment from 'moment';
import clsx from 'clsx';
import _ from 'lodash';
import dayjs from 'dayjs';

import { REPORTS_TYPE } from 'core/redux/types/dataTypes';

import { getDateRange } from 'api/entranceReportService';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import CurrentDateIndicator from 'components/CurrentDateIndicator';
import SingleChartValue from 'components/SingleChartValue';
import DialogPDFViewer from 'components/DialogPDFViewer';
import FilterReports from 'components/FilterReports';
import EntranceTable from 'components/EntranceTable';
import ContentCard from 'components/ContentCard';
import Loader from 'components/Loader';
import ExportToExcel from 'components/ExportToExcel';

import usePagination from 'lib/hooks/usePagination';
import formatNumber from 'lib/helpers/formatNumber';
import SpacingElement from 'lib/ui/SpacingElement';
import FlexContainer from 'lib/ui/FlexContainer';
import useSetToken from 'lib/hooks/useSetToken';
import useSnackbar from 'lib/hooks/useSnackbar';
import useSignOut from 'lib/hooks/useSignOut';
import ID from 'lib/helpers/random-id';
import PageInfo from 'lib/ui/PageInfo';

import useStyles, { EntranceReportContent, NumberCircle } from './styles';
import { ReportFilter, MyDoc } from '../../Reports/ReportFilter';

interface DialogFarmsProps {
  open: boolean;
  type: any;
  onClose: (value?: any) => void;
  values: any[];
}

const DialogFarms = ({
  open,
  type,
  onClose,
  values = [],
}: DialogFarmsProps) => {
  const classes: any = useStyles();
  const [expanded, setExpanded] = useState(null);
  const [data, setData] = useState<any[]>([]);

  const handleChangeAccordion = (panel: any) => (_: any, isExpanded: boolean) =>
    setExpanded(isExpanded ? panel : false);

  useEffect(() => {
    const settingId: any[] = values.map((item) => ({
      ...item,
      _id: ID(),
    }));
    if (type === 'farm') {
      setData(settingId);
    } else if (type === 'farmer') {
      const result = settingId.reduce((el, item) => {
        el[item.nombreProductor] = {
          _id: ID(),
          name: item.nombreProductor,
          farms: el[item.nombreProductor]
            ? [...el[item.nombreProductor].farms, item]
            : [item],
        };
        return el;
      }, Object.create(null));
      setData(
        Object.keys(result).map((key) => ({
          ...result[key],
          percent: (result[key].farms.length / settingId.length) * 100,
          animals: result[key].farms.reduce(
            (a: number, b: any) => a + b.cantidad,
            0
          ),
        }))
      );
    }
  }, [values, type]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle>Granjas diferentes</DialogTitle>
      <DialogContent>
        {type === 'farm' &&
          data.map((item) => (
            <Accordion
              key={item._id}
              expanded={expanded === item._id}
              onChange={handleChangeAccordion(item._id)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                classes={{ content: classes.accordionSummary }}
                expandIcon={<ExpandMore />}
              >
                <FlexContainer
                  alignItems="center"
                  className={classes.accordionHeading}
                >
                  <Avatar className={classes.accordionSummaryAvatar}>
                    {item?.nombreGranja?.substr(0, 1)}
                  </Avatar>
                  <Typography>
                    {_.startCase(item?.nombreGranja?.toLowerCase())}
                  </Typography>
                </FlexContainer>
                <Typography color="textSecondary">
                  {Intl.NumberFormat(['ban', 'id']).format(item.cantidad)}{' '}
                  animales
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                className={clsx(
                  classes.accordionDetails,
                  classes.detailsFarmer
                )}
              >
                <MuiGrid container alignItems="center">
                  <MuiGrid item container xs={7}>
                    <MuiGrid item xs={3}>
                      <Avatar className={classes.accordionDetailsAvatar}>
                        <i
                          className={clsx('icon-productor', classes.iconAvatar)}
                        />
                      </Avatar>
                    </MuiGrid>
                    <MuiGrid item container xs={9}>
                      <MuiGrid xs={12}>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          className={classes.noWrapText}
                        >
                          Nombre del Productor
                        </Typography>
                        <Typography
                          className={classes.noWrapText}
                          color="textSecondary"
                        >
                          {_.startCase(item?.nombreProductor?.toLowerCase())}
                        </Typography>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                  <MuiGrid item xs={5}>
                    <FlexContainer justify="flex-end" pr={2}>
                      <SingleChartValue value={item.porcentaje} size={70} />
                    </FlexContainer>
                  </MuiGrid>
                </MuiGrid>
              </AccordionDetails>
            </Accordion>
          ))}
        {type === 'farmer' &&
          _.map(data, (item) => (
            <Accordion
              key={item._id}
              id={item._id}
              expanded={expanded === item._id}
              onChange={handleChangeAccordion(item._id)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                classes={{ content: classes.accordionSummary }}
                expandIcon={<ExpandMore />}
              >
                <FlexContainer
                  alignItems="center"
                  className={classes.accordionHeading}
                >
                  <Avatar className={classes.accordionSummaryAvatar}>
                    {item?.name?.substr(0, 1)}
                  </Avatar>
                  <Typography>
                    {_.startCase(item?.name?.toLowerCase())}
                  </Typography>
                </FlexContainer>
                <Typography color="textSecondary">
                  {Intl.NumberFormat(['ban', 'id']).format(item.animals)}{' '}
                  animales
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Box display="flex" alignItems="center">
                    <Box marginRight={2}>
                      <Avatar>{item?.farms?.length}</Avatar>
                    </Box>
                    <Typography variant="subtitle1" color="textSecondary">
                      Granjas del productor
                    </Typography>
                  </Box>
                  <SingleChartValue value={item.percent} size={70} />
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={onClose} autoFocus color="primary">
          Cerrar
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

interface DialogAnimalsOriginProps {
  open: boolean;
  onClose?: (value?: any) => void;
  values: any;
}

const DialogAnimalsOrigin = ({
  open,
  onClose,
  values,
}: DialogAnimalsOriginProps) => {
  const classes: any = useStyles();
  return (
    <Dialog open={open} fullScreen>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Origen de los Animales
          </Typography>
          <MuiButton autoFocus color="inherit" onClick={onClose}>
            {_.upperCase('Cerrar')}
          </MuiButton>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogChartRoot}>
        <MuiGrid container>
          <MuiGrid item container xs={5} alignItems="center" justify="center">
            <AccumulationChartComponent
              id="dialog-chart"
              background="transparent"
              tooltip={{ enable: true }}
              className={classes.dialogChart}
            >
              <Inject services={[AccumulationTooltip]} />
              <AccumulationSeriesCollectionDirective>
                <AccumulationSeriesDirective
                  dataSource={_.orderBy(values, ['cantidad'], ['desc']).map(
                    (item) => ({
                      x: `${
                        item.porcentaje === 100
                          ? item.porcentaje
                          : Number(item.porcentaje).toFixed(2)
                      }% ${item.cantidad} ${
                        item.nombreGranja
                          ? _.startCase(item?.nombreGranja?.toLowerCase())
                          : 'N.D.'
                      }`,
                      y:
                        item.porcentaje === 100
                          ? item.porcentaje
                          : Number(item.porcentaje).toFixed(2),
                    })
                  )}
                  xName="x"
                  yName="y"
                  innerRadius="65%"
                  border={{
                    width: values?.length > 5 ? 0 : 10,
                    color: 'white',
                  }}
                  palettes={['#FFC676', '#F1AE6C', '#E29662']}
                  legendShape="Circle"
                />
              </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>
          </MuiGrid>
          <MuiGrid item xs={7}>
            <Box padding={3}>
              <MuiGrid
                container
                spacing={2}
                className={classes.animalsOriginScrollSection}
              >
                {_.orderBy(values, ['cantidad'], ['desc']).map(
                  (item, index) => (
                    <MuiGrid key={index} item xs={6}>
                      <Paper>
                        <Box paddingX={2} paddingY={1}>
                          <MuiGrid container alignItems="stretch">
                            <MuiGrid item container xs={4} justify="center">
                              <SingleChartValue
                                value={item.porcentaje}
                                size={70}
                              />
                            </MuiGrid>
                            <MuiGrid item xs={8}>
                              <Typography
                                variant="subtitle1"
                                style={{ color: '#B0413E' }}
                              >
                                {item.nombreGranja}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ color: '#D47D57' }}
                              >
                                {item.nombreProductor}
                              </Typography>
                              <Typography
                                variant="body2"
                                className={classes.totalAnimals}
                              >
                                <i
                                  className={clsx(
                                    'icon-pig-right',
                                    classes.iconOrigin
                                  )}
                                />
                                {item.cantidad} animales
                              </Typography>
                            </MuiGrid>
                          </MuiGrid>
                        </Box>
                      </Paper>
                    </MuiGrid>
                  )
                )}
              </MuiGrid>
            </Box>
          </MuiGrid>
        </MuiGrid>
      </div>
    </Dialog>
  );
};

const Main = () => {
  const classes: any = useStyles();
  const dispatch = useDispatch();
  const setToken = useSetToken();
  const { expiredSession } = useSignOut();
  const { handleOpenSnackbar } = useSnackbar();
  const user = useSelector((state: any) => state.user.user);
  const selectedUserType = useSelector(
    (state: any) => state.user.selectedUserType
  );

  const token = useSelector((state: any) => state.user.token);
  const entranceData = useSelector((state: any) => state.data.entrance);
  const [data, setData] = useState<any>({});
  const [exportData, setExportData] = useState<any>({});
  const [statsDialog, setStatsDialog] = useState({
    open: false,
    values: [],
    type: undefined,
  });
  const [openDialogChart, setOpenDialogChart] = useState(false);
  const [details, setDetails] = useState<any>([]);
  const [load, setLoad] = useState(true);
  const [open, setOpen] = useState(false);
  const pageBreadcrums: BreadcrumbProps[] = [
    { link: '/app/reports/entrance', title: 'Portería' },
    { link: '/app/reports', title: 'Mis Reportes' },
  ];

  const [image, setImage] = useState('');

  const currentDate = new Date();
  const initialDate = moment(
    `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-01`
  ).format('YYYY-MM-DDT00:00:00-05:00');
  const finalDate = moment(currentDate).format('YYYY-MM-DDT23:59:59-05:00');

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const count = Math.ceil(details.length / perPage);

  const _DATA = usePagination(details, perPage);

  const handleChangePaginator = (__: any, activePage: number) => {
    setPage(activePage);
    _DATA.jump(activePage);
  };

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('barcode');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        setImage(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModal = () => {
    generateScreenshot();
    setOpen(true);
  };

  useEffect(() => {
    if (entranceData) {
      setData(entranceData);
      setLoad(false);
    }
    getDateRange(
      {
        fechaInicial: initialDate,
        fechaFinal: finalDate,
        idUsuario: user.id,
        tipoUsuario: selectedUserType.name,
        valorFiltro: '',
        tipoFiltro: 'Ninguno',
        idPLanta: 6,
      },
      token
    )
      .then((data: any) => {
        if (expiredSession(data.StatusCode)) {
          const response = data.Data;
          setData(response);
          setExportData(
            response.detalleDelReportes.map((value: any) => {
              return {
                Granja: value.nombreGranja,
                Productor: value.nombreProductor,
                'Nro. GSMI': value.GSMI,
                'Codigo lote': value.codigoLote,
                'Total animales': value.totalAnimales,
                'Placa Vehículo': value.placa,
                'Responsable Portería': value.responsablePorteria,
                'Llegada Planta': dayjs(value.llegadaPlanta).format(
                  'DD/MM/YYYY HH:mm:ss'
                ),
                'Salida Planta': dayjs(value.salidaPlanta).format(
                  'DD/MM/YYYY HH:mm:ss'
                ),
                'Fin Desembarque': value.finDesembarque,
                'Tiempo Desembarque': value.TiempoDesembarque,
              };
            })
          );
          dispatch({
            type: REPORTS_TYPE,
            payload: {
              name: 'entrance',
              data: response,
            },
          });
          setToken(data.Token.TokenNuevo);
          setDetails(
            _.map(
              _.groupBy(response.detalleDelReportes, 'nombreGranja'),
              (item, name) => ({
                _id: ID(),
                name,
                farmer: item[0].nombreProductor,
                totalAnimals: item.reduce((a, b) => a + b.totalAnimales, 0),
                totalLotes: item.length,
                average: response.promedioGranjaProductors.find(
                  (el: any) => el.nombreGranja === item[0].nombreGranja
                ).pesoPromedio,
                data: item,
                resume: _.map(
                  _(item)
                    .groupBy((element) =>
                      moment(element.llegadaPlanta, 'DD-MM-YYYY').format(
                        'DD-MM-YYYY'
                      )
                    )
                    .mapValues((items) => _.map(items))
                    .value(),
                  (el, date) => ({ date, items: el })
                ),
              })
            )
          );
          setLoad(false);
        }
      })
      .catch((err) => {
        handleOpenSnackbar({
          autoHideDuration: 6000,
          message: err.toString(),
          type: 'ERROR',
        });
        setLoad(false);
      });
  }, []);

  const handleOpenInfoModal = (type: any) => setStatsDialog(type);
  const handleCloseInfoModal = () =>
    setStatsDialog({ open: false, type: undefined, values: [] });

  return (
    <>
      <EntranceReportContent>
        <Loader visible={load}>Cargando...</Loader>
        <div>
          <div style={{ position: 'absolute', left: '-1000%' }}>
            {!load && <ReportFilter data={data} />}
          </div>
          <PageInfo>
            <CurrentDateIndicator
              initialDate={initialDate}
              finalDate={finalDate}
            />
            <Breadcrumb breadcrumbs={pageBreadcrums} />
          </PageInfo>

          <FilterReports
            to="/app/reports/entrance/search"
            filtersActive={['TODAY', 'WEEK', 'RANGE', 'LOTE']}
          />

          <Grid columns={2} stackable>
            <Grid.Column>
              <ContentCard padding="5% 5%">
                <ContentCard.Header>
                  Ingreso Camiones
                  <div className="timeflex">
                    <div className="time">
                      <div className="textalign">
                        {data?.resumenTransporte?.tiempoPromedioEsperaPlanta ??
                          0}
                        <p>hh:mm:ss</p>
                      </div>
                    </div>
                    <p>Tiempo Promedio Espera</p>
                  </div>
                </ContentCard.Header>
                <ContentCard.Content>
                  <div className="margintopcolumns">
                    <Grid columns={2} stackable>
                      <Grid.Column>
                        <div className="numbercars">
                          <Icon className="icon-truck icons" size="big" />
                          <p>Camiones Ingresados</p>
                          <p className="p">
                            <b>
                              {data?.resumenTransporte
                                ?.totalCamionesIngresados ?? 'N.D.'}
                            </b>
                          </p>
                        </div>
                        <div className="numbercars">
                          <Icon className="icon-truck-check icons" size="big" />
                          <p>Número de Ingresos</p>
                          <p className="p">
                            <b>
                              {data?.resumenTransporte?.totalIngresos ?? 'N.D.'}
                            </b>
                          </p>
                        </div>

                        <div className="numbercars">
                          <Icon className="icon-truck-deny icons" size="big" />
                          <p>Ingresos Denegados</p>
                          <p className="p">
                            <b>
                              {data?.resumenTransporte?.ingresosDenegados ??
                                'N.D.'}
                            </b>
                          </p>
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="contentcolumns">
                          <Tooltip
                            title={`... y ${data?.resumenTransporte?.ProductoresDiferentes} más`}
                          >
                            <div
                              className={classes.numberStadistic}
                              style={{ marginBottom: 0 }}
                              onClick={() =>
                                handleOpenInfoModal({
                                  open: true,
                                  type: 'farmer',
                                })
                              }
                            >
                              <Icon
                                className="icon-productor icons"
                                size="big"
                              />
                              <p>Productores Diferentes</p>
                              <p className="p">
                                <b>
                                  {data?.resumenTransporte
                                    ?.ProductoresDiferentes ?? 'N.D'}
                                </b>
                              </p>
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={`${data?.origenAnimalesProductor
                              ?.slice(0, 2)
                              ?.map((item: any) =>
                                _.startCase(item?.nombreGranja?.toLowerCase())
                              )
                              ?.join(', ')} y ${
                              Number.parseInt(
                                data?.resumenTransporte?.granjasDiferentes
                              ) - 2
                            } más...`}
                          >
                            <div
                              className={classes.numberStadistic}
                              style={{ marginBottom: 0 }}
                              onClick={() =>
                                handleOpenInfoModal({
                                  open: true,
                                  type: 'farm',
                                })
                              }
                            >
                              <Icon className="icon-granja icons" size="big" />
                              <p>Granjas Diferentes</p>
                              <p className="p">
                                <b>
                                  {data?.resumenTransporte?.granjasDiferentes ??
                                    'N.D.'}
                                </b>
                              </p>
                            </div>
                          </Tooltip>
                        </div>
                      </Grid.Column>
                    </Grid>
                  </div>
                </ContentCard.Content>
              </ContentCard>

              <ContentCard padding="5% 5%">
                <Box marginBottom={2}>
                  <ContentCard.Header>
                    Origen de los Animales por Granja
                    <Chip
                      size="small"
                      color="primary"
                      variant="outlined"
                      label="Top 10 resultados"
                    />
                  </ContentCard.Header>
                </Box>
                <ContentCard.Content>
                  <SpacingElement pt={2}>
                    <Grid columns={2}>
                      <Grid.Column width={8}>
                        <table className={classes.table} cellSpacing={3}>
                          <tbody>
                            {_.orderBy(
                              data?.origenAnimalesProductor,
                              ['cantidad'],
                              ['desc']
                            )
                              .slice(0, 10)
                              ?.map((item, index) => (
                                <tr key={index}>
                                  <td className={classes.percentCell}>
                                    <div className="badge" />
                                    <Typography>{`${
                                      item.porcentaje === 100
                                        ? item.porcentaje
                                        : Number(item.porcentaje).toFixed(2)
                                    } %`}</Typography>
                                  </td>
                                  <td className={classes.quantityCell}>
                                    {item.cantidad}
                                  </td>
                                  <td className="left">
                                    {_.startCase(
                                      item?.nombreGranja?.toLowerCase()
                                    )}
                                  </td>
                                </tr>
                              ))}
                            <tr>
                              <td colSpan={3}>
                                <SpacingElement pt={2}>
                                  <MuiButton
                                    size="small"
                                    variant="contained"
                                    className="raleway"
                                    color="primary"
                                    onClick={() => setOpenDialogChart(true)}
                                  >
                                    Ver más
                                  </MuiButton>
                                </SpacingElement>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <div className={classes.donutChartContainer}>
                          <p className={classes.chartLabel}>Granjas</p>
                          <AccumulationChartComponent
                            id="charts"
                            background="transparent"
                            tooltip={{ enable: true }}
                            className={classes.chart}
                          >
                            <Inject services={[AccumulationTooltip]} />
                            <AccumulationSeriesCollectionDirective>
                              <AccumulationSeriesDirective
                                dataSource={_.orderBy(
                                  data?.origenAnimalesProductor,
                                  ['cantidad'],
                                  ['desc']
                                )
                                  .slice(0, 10)
                                  .map((item) => ({
                                    x: `${
                                      item.porcentaje === 100
                                        ? item.porcentaje
                                        : Number(item.porcentaje).toFixed(2)
                                    }% ${item.cantidad} ${
                                      item.nombreGranja
                                        ? _.startCase(
                                            item?.nombreGranja?.toLowerCase()
                                          )
                                        : 'N.D.'
                                    }`,
                                    y:
                                      item.porcentaje === 100
                                        ? item.porcentaje
                                        : Number(item.porcentaje).toFixed(2),
                                  }))}
                                xName="x"
                                yName="y"
                                innerRadius="65%"
                                border={{
                                  idth:
                                    data?.origenAnimalesProductor?.length > 5
                                      ? 0
                                      : 10,
                                  color: 'white',
                                }}
                                palettes={['#FFC676', '#F1AE6C', '#E29662']}
                                legendShape="Circle"
                              />
                            </AccumulationSeriesCollectionDirective>
                          </AccumulationChartComponent>
                        </div>
                      </Grid.Column>
                    </Grid>
                  </SpacingElement>
                </ContentCard.Content>
              </ContentCard>
            </Grid.Column>
            <Grid.Column>
              <ContentCard padding="5% 5%">
                <ContentCard.Header>Ingreso de Animales</ContentCard.Header>
                <div className="margintopcolumns1">
                  <ContentCard.Content>
                    <Grid columns={2} stackable>
                      <Grid.Column width={5}>
                        <div className="numbercirclecontainer">
                          <NumberCircle
                            value={data?.resumenTransporte?.totalAnimales}
                          >
                            <h1>
                              {new Intl.NumberFormat().format(
                                data?.resumenTransporte?.totalAnimales
                              )}
                            </h1>
                          </NumberCircle>
                          <p>Total Animales en Pie</p>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={11}>
                        <table className="table1">
                          <tr>
                            <td className="textcenter">
                              <Icon
                                className="icon-document icons"
                                size="big"
                              />
                            </td>
                            <td>Animales Remisionados</td>
                            <td className="textright">
                              <b>
                                {new Intl.NumberFormat().format(
                                  data?.resumenTransporte
                                    ?.totalAnimalesRemisionados
                                )}
                              </b>
                            </td>
                          </tr>{' '}
                          <tr>
                            <td className="textcenter">
                              <Icon className="icon-ticket icons" size="big" />
                            </td>
                            <td>Nro. Lotes Ingresados</td>
                            <td className="textright">
                              <b>
                                {data?.resumenTransporte?.totalLotes ?? 'N.D.'}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td className="textcenter">
                              <Icon className="icon-pig icons" size="big" />
                            </td>
                            <td>Peso Total</td>
                            <td className="textright">
                              <b>
                                {formatNumber(
                                  data?.resumenTransporte?.totalPesoAnimalesPie
                                )}{' '}
                                Kg
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td className="textcenter">
                              <Icon className="icon-weight icons" size="big" />
                            </td>
                            <td>Peso Promedio</td>
                            <td className="textright">
                              <b>
                                {Number(
                                  data?.resumenTransporte
                                    ?.promedioPesoAnimalesEnPie ?? 0
                                ).toFixed(2)}{' '}
                                Kg
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td className="textcenter">
                              <Icon className="icon-ticket icons" size="big" />
                            </td>
                            <td>Total Guías de Movilización</td>
                            <td className="textright">
                              <b>
                                {data?.resumenTransporte
                                  ?.totalGuiasMovilizacion ?? 'N.D.'}
                              </b>
                            </td>
                          </tr>
                        </table>
                      </Grid.Column>
                    </Grid>
                  </ContentCard.Content>
                </div>
              </ContentCard>
              <div className={classes.cardReport}>
                <Typography className={classes.cardTitle}>
                  Planta de Beneficio
                </Typography>
                <Typography color="textSecondary">
                  {_.startCase(data?.planta?.nombre?.toLowerCase())}
                </Typography>
              </div>
              <div className={classes.cardReport}>
                <Box>
                  <Typography className={classes.cardTitle}>
                    Reporte Ingresos a Portería
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Visualización del reporte por rango de fecha del mes actual
                  </Typography>
                </Box>
                <Box>
                  <MuiButton
                    variant="contained"
                    color="primary"
                    className="raleway"
                    onClick={() => openModal()}
                  >
                    Visualizar
                  </MuiButton>
                  <ExportToExcel
                    onlyIcon
                    headers={
                      exportData.length > 0 && Object.keys(exportData[0])
                    }
                    data={Object.values(exportData)}
                    fileName="Reporte-porteria"
                  />
                </Box>
                {/*
                <DialogPDFViewer
                  open={open}
                  onClose={() => setOpen(false)}
                  done={Boolean(image)}
                >
                  <MyDoc
                    initialDate={initialDate}
                    finalDate={finalDate}
                    qr={image}
                    uid={user.id}
                    url={{ data, details }}
                    reportId={0}
                  />
                </DialogPDFViewer>
                */}
              </div>
            </Grid.Column>
          </Grid>
          <ContentCard padding="2% 2%">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" color="textSecondary">
                Detalle de Ingreso de Animales en Pie
              </Typography>
              <Box display="flex" alignItems="center" paddingBottom={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  label="Buscar"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Chip
                  icon={<Icon className="icon-document" size="big" />}
                  label={`${details.length ?? 0} resultados`}
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: 20 }}
                  classes={{ label: classes.chipLabel }}
                />
              </Box>
            </Box>
            <ContentCard.Content>
              <div>
                <EntranceTable data={_DATA.currentData()} />
                {details.length < 1 && (
                  <h1 style={{ textAlign: 'center' }}>No hay datos</h1>
                )}
              </div>
              <Box
                paddingTop={3.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <TextField
                  label="Filas por página"
                  variant="outlined"
                  color="primary"
                  size="small"
                  value={perPage}
                  select
                  style={{ width: 150 }}
                  onChange={(e: any) => setPerPage(e.target.value)}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </TextField>
                <Pagination
                  page={page}
                  count={count}
                  variant="outlined"
                  color="primary"
                  showLastButton={
                    data?.detalleDelReportes?.length / perPage > 3
                  }
                  showFirstButton={
                    data?.detalleDelReportes?.length / perPage > 3
                  }
                  onChange={handleChangePaginator}
                />
              </Box>
            </ContentCard.Content>
          </ContentCard>
        </div>
      </EntranceReportContent>
      <DialogFarms
        {...statsDialog}
        values={data?.origenAnimalesProductor ?? []}
        onClose={handleCloseInfoModal}
      />
      <DialogAnimalsOrigin
        open={openDialogChart}
        onClose={() => setOpenDialogChart(false)}
        values={data?.origenAnimalesProductor ?? []}
      />
    </>
  );
};

export default Main;
