import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Grid,
  Card,
  CardActionArea,
  CircularProgress,
} from "@material-ui/core";
import { WhatsApp, Telegram, CloudUpload } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import MainTitle from "components/MainTitle";
import DocumentTypes from "components/DocumentTypes";
import TextFieldNumber from "components/TextFieldNumber";
import Direction from "components/Direction";
import PhoneNumber from "components/PhoneNumber";
import Slider from "components/Slider";
import ExistingUser from "components/ExistingUser";
import GobackLink from "components/GobackLink";

import constants from "config/constants";
import unmaskPhone from "lib/helpers/unmaskPhone";
import { FARM_MANAGER } from "core/graphql/queries/profile";
import { REGISTER_FARM } from "core/graphql/mutations/profile";
import { useSnackbar } from "notistack";
import { FarmRegister } from "lib/validations/Profile";

import { useFormik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import ES from "date-fns/locale/es";
import dayjs from "dayjs";

import useStyles from "./styles";

const CreateFarm = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const path = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectUser, setSelectUser] = useState(false);
  const [select, setSelect] = useState(0);
  const [selectFile, setSelectFile] = useState<any>("");
  const [file, setFile] = useState<any>();

  const { data, loading, refetch } = useQuery(FARM_MANAGER, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idPersonaResponsable: 0 },
  });

  useEffect(() => {
    refetch();
  }, [path]);

  const [registerFarm, { loading: loadingRegister }] =
    useMutation(REGISTER_FARM);

  const handleChangeSelect = (i: number) => {
    setSelect(i);
    formik.setFieldValue("idResponsable", i);
  };

  const handleChangeForm = () => {
    setSelectUser(!selectUser);
    if (selectUser) formik.setFieldValue("idResponsable", 0);
    else formik.setFieldValue("idResponsable", select);
  };
  const handleObtainFile = (event: any) => {
    if (event.target.files[0]) {
      formik.setFieldValue("farmCertificate", event.target.files[0].name);
      setSelectFile(event.target.files[0].name);
      setFile(URL.createObjectURL(event.target.files[0]));
    }
  };

  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      idResponsable: 0,
      farmCertificate: "",
      firstName: "",
      secondName: "",
      firstLastname: "",
      secondLastname: "",
      idDocument: 1,
      documentNumber: 0,
      email: "",
      indicative: constants.DEFAULT_COUNTRY_ID,
      phone: "",
      whatsappIndicative: constants.DEFAULT_COUNTRY_ID,
      whatsapp: "",
      telegramIndicative: constants.DEFAULT_COUNTRY_ID,
      telegram: "",
      farmEmail: "",
      typeDirection: 1,
      idCountry: constants.DEFAULT_COUNTRY_ID,
      idDeparment: constants.DEFAULT_DEPARTMENT_ID,
      idCity: constants.DEFAULT_CITY_ID,
      viaType: "",
      viaNumber: "",
      apendNumber: "",
      cruceNumber: "",
      apendCruceNumber: "",
      cornerMeters: "",
      aditional: "",
      postalCode: "",
      latitud: "",
      longitud: "",
      direction: "",
      coords: 1,
      validityDate: null,
    },
    validationSchema: FarmRegister,
    onSubmit: (values: any) => {
      const coords = values.coords;
      const directionType = values.typeDirection;
      registerFarm({
        variables: {
          ...values,
          typeDirection: Number(values.typeDirection),
          idCity: Number(values.idCity),
          idDeparment: Number(values.idDeparment),
          idCountry: Number(values.idCountry),
          viaType:
            coords === 1 ? (directionType === 1 ? values.viaType : 1) : 1,
          viaNumber:
            coords === 1 ? (directionType === 1 ? values.viaNumber : 0) : 0,
          apendNumber:
            coords === 1 ? (directionType === 1 ? values.apendNumber : "") : "",
          cruceNumber:
            coords === 1 ? (directionType === 1 ? values.cruceNumber : 0) : 0,
          apendCruceNumber:
            coords === 1
              ? directionType === 1
                ? values.apendCruceNumber
                : ""
              : "",
          cornerMeters:
            coords === 1 ? (directionType === 1 ? values.cornerMeters : 0) : 0,
          adicional: values.aditional,
          postalCode:
            coords === 1 ? (directionType === 1 ? values.postalCode : "") : "",
          whatsappIndicative:
            values.whatsapp != "" ? values.whatsappIndicative : 0,
          telegramIndicative:
            values.whatsapp != "" ? values.telegramIndicative : 0,
          // validityDate:
          //   dayjs(values.validityDate).format('DD/MM/YYYY') !==
          //   dayjs().format('DD/MM/YYYY')
          //     ? values.validityDate
          //     : '0000-00-00T00:00:00.000Z',
        },
        context: { headers: { "x-auth-jwt": token } },
      })
        .then((data) => {
          console.log(data);
          enqueueSnackbar(`La granja se registró exitosamente`, {
            variant: "success",
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
          history.goBack();
        })
        .catch((error) => {
          enqueueSnackbar(`La granja no se pudo registrar`, {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
        });
    },
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <GobackLink arrowMode />
        <Typography variant="h6" className={classes.title}>
          Registro Nueva Granja
        </Typography>
      </Box>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <Box marginY={2}>
          <Box marginY={2}>
            <MainTitle>Datos Básicos de la Granja</MainTitle>
            <Box marginY={2}>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    label="Código Interno de la Granja"
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.code}
                    name="code"
                    helperText={formik.errors.code}
                    error={!!formik.errors.code}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Nombre Granja"
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    name="name"
                    helperText={formik.errors.name}
                    error={!!formik.errors.name}
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ES}>
                    <KeyboardDatePicker
                      label="Fecha Validez Certificado"
                      format="dd/MM/yyyy"
                      value={formik.values.validityDate}
                      onChange={(date: any) =>
                        formik.setFieldValue("validityDate", date)
                      }
                      variant="inline"
                      fullWidth
                      inputVariant="outlined"
                      size="small"
                      InputAdornmentProps={{ position: "end" }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<CloudUpload />}
                  >
                    <label htmlFor="upload-photo" style={{ cursor: "pointer" }}>
                      <input
                        hidden
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={handleObtainFile}
                        accept="application/vnd.ms-excel, application/pdf, image/*"
                      />
                      Adjuntar Certificado de Granja
                    </label>
                  </Button>
                  <a download href={file}>
                    <Typography
                      color="secondary"
                      variant="inherit"
                      style={{ marginLeft: 8 }}
                    >
                      {selectFile}
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginY={2}
            >
              <MainTitle>Datos Responsable de la Granja</MainTitle>
              {data?.getFarmManagerXUser?.data.length > 0 ? (
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleChangeForm}
                >
                  {selectUser ? "Registrar Nuevo" : "Seleccionar Existente"}
                </Button>
              ) : null}
            </Box>
            <Box marginY={2}>
              {data && selectUser ? (
                <Slider>
                  {data?.getFarmManagerXUser?.data?.map((i: any) => (
                    <ExistingUser
                      name={i?.nombreCompleto}
                      info={i?.numeroDocumento}
                      selected={select === i?.idPersonaResponsable}
                      onClick={() =>
                        handleChangeSelect(i?.idPersonaResponsable)
                      }
                      column
                    />
                  ))}
                  <Grid>
                    <Card
                      className={classes.card}
                      onClick={() => setSelectUser(false)}
                    >
                      <CardActionArea className={classes.content}>
                        <i className="icon-plus" />
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Slider>
              ) : (
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      label="Primer Nombre"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      name="firstName"
                      helperText={formik.errors.firstName}
                      error={!!formik.errors.firstName}
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Segundo Nombre"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.secondName}
                      name="secondName"
                      helperText={formik.errors.secondName}
                      error={!!formik.errors.secondName}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Primer Apellido"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.firstLastname}
                      name="firstLastname"
                      helperText={formik.errors.firstLastname}
                      error={!!formik.errors.firstLastname}
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Segundo Apellido"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.secondLastname}
                      name="secondLastname"
                      helperText={formik.errors.secondLastname}
                      error={!!formik.errors.secondLastname}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DocumentTypes
                      entity={1}
                      name="idDocument"
                      helperText={formik.errors.idDocument as string}
                      errors={formik.errors.idDocument as string}
                      value={Number(formik.values.idDocument)}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldNumber
                      label="Nro. Documento"
                      required
                      onChange={formik.handleChange}
                      name="documentNumber"
                      value={formik.values.documentNumber}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Correo Electrónico"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      name="email"
                      helperText={formik.errors.email}
                      error={!!formik.errors.email}
                      required
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>

          <Box marginY={2}>
            <MainTitle>Datos de Contacto de la Granja</MainTitle>
            <Box marginY={2}>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={6} xl={4}>
                  <PhoneNumber
                    type="mobile"
                    names={{
                      indicative: "indicative",
                      phone: "phone",
                    }}
                    values={{
                      indicative: formik.values.indicative,
                      phone: (formik.values.phone = unmaskPhone(
                        formik.values.phone
                      )),
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative:
                        !!formik.errors.indicative &&
                        !!formik.touched.indicative,
                      phone: formik.errors.phone as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.indicative as string,
                      phone: formik.errors.phone as string,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6} xl={4}>
                  <PhoneNumber
                    labelIndicative="Ind. WhatsApp"
                    labelInput="WhatsApp"
                    type="mobile"
                    names={{
                      indicative: "whatsappIndicative",
                      phone: "whatsapp",
                    }}
                    values={{
                      indicative: formik.values.whatsappIndicative,
                      phone: (formik.values.whatsapp = unmaskPhone(
                        formik.values.whatsapp
                      )),
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative: formik.errors.whatsappIndicative,
                      phone: formik.errors.whatsapp as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.whatsappIndicative as string,
                      phone: formik.errors.whatsapp as string,
                    }}
                    icon={<WhatsApp />}
                  />
                </Grid>
                <Grid item xs={6} xl={4}>
                  <PhoneNumber
                    labelInput="Telegram"
                    labelIndicative="Ind. Telegram"
                    type="mobile"
                    names={{
                      indicative: "telegramIndicative",
                      phone: "telegram",
                    }}
                    values={{
                      indicative: formik.values.telegramIndicative,
                      phone: (formik.values.telegram = unmaskPhone(
                        formik.values.telegram
                      )),
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative: !!formik.errors.telegramIndicative,
                      phone: formik.errors.telegram as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.telegramIndicative as string,
                      phone: formik.errors.telegram as string,
                    }}
                    icon={<Telegram />}
                  />
                </Grid>
                <Grid item xs={6} xl={4}>
                  <TextField
                    label="Correo Electrónico"
                    required
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.farmEmail}
                    name="farmEmail"
                    helperText={formik.errors.farmEmail}
                    error={!!formik.errors.farmEmail}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box marginY={2}>
            <MainTitle>Datos Ubicación de la Granja</MainTitle>
            <Box marginY={2}>
              <Grid item xs={12}>
                <Direction
                  withCoordinates
                  names={{
                    directionType: "typeDirection",
                    viaType: "viaType",
                    viaNumber: "viaNumber",
                    apendNumber: "apendNumber",
                    cruceNumber: "cruceNumber",
                    apendCruceNumber: "apendCruceNumber",
                    cornerMeters: "cornerMeters",
                    aditional: "aditional",
                    postalCode: "postalCode",
                    latitude: "latitud",
                    longitude: "longitud",
                    idPais: "idCountry",
                    idDepartamento: "idDeparment",
                    city: "idCity",
                    direction: "direction",
                    coords: "coords",
                  }}
                  values={{
                    directionType: formik.values.typeDirection,
                    viaType: formik.values.viaType,
                    viaNumber: formik.values.viaNumber,
                    apendNumber: formik.values.apendNumber,
                    cruceNumber: formik.values.cruceNumber,
                    apendCruceNumber: formik.values.apendCruceNumber,
                    cornerMeters: formik.values.cornerMeters,
                    aditional: formik.values.aditional,
                    postalCode: formik.values.postalCode,
                    latitude: formik.values.latitud,
                    longitude: formik.values.longitud,
                    idPais: Number(formik.values.idCountry),
                    idDepartamento: Number(formik.values.idDeparment),
                    city: Number(formik.values.idCity),
                    direction: formik.values.direction,
                    coords: formik.values.coords,
                  }}
                  errors={{
                    directionType: formik.errors.typeDirection as string,
                    viaType: formik.errors.viaType as string,
                    viaNumber: formik.errors.viaNumber as string,
                    apendNumber: formik.errors.apendNumber as string,
                    cruceNumber: formik.errors.cruceNumber as string,
                    apendCruceNumber: formik.errors.apendCruceNumber as string,
                    cornerMeters: formik.errors.cornerMeters as string,
                    aditional: formik.errors.aditional as string,
                    postalCode: formik.errors.postalCode as string,
                    latitude: formik.errors.latitud as string,
                    longitude: formik.errors.longitud as string,
                    idPais: formik.errors.idCountry as string,
                    idDepartamento: formik.errors.idDeparment as string,
                    city: formik.errors.idCity as string,
                    direction: formik.errors.direction as string,
                    coords: formik.errors.coords as string,
                  }}
                  setFieldValue={formik.setFieldValue}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched}
                />
              </Grid>
            </Box>
          </Box>
          <Box className={classes.stepperButtons}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={
                loadingRegister && (
                  <CircularProgress style={{ color: "#FFF" }} size={20} />
                )
              }
            >
              {loadingRegister ? "Guardando" : "Guardar"}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default CreateFarm;
