import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 16,
    borderRadius: 10,
    boxShadow: theme.shadows[2],
    width: "100%",
  },
  subtitle: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
