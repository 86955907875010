import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import domtoimage from 'dom-to-image';
import dayjs from 'dayjs';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import GobackLink from 'components/GobackLink';
import ContentCard from 'components/ContentCard';
import LoteInfoTitle from 'components/LoteInfoTitle';
import TableInfo from 'components/TableInfo/index';
import Chart from 'components/Chart';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import ExportToExcel from 'components/ExportToExcel/index';
import DialogPDFViewer from 'components/DialogPDFViewer';

import {
  ReportLot,
  MyDocAnteMortem,
  GraphAM,
  GraphAMT,
} from './../../Reports/ReportLot';

import useStyles from './styles';

const Details = () => {
  const classes = useStyles();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/reports/ante-mortem', title: 'Ante-Mortem' },
    { link: '/app/reports', title: 'Mis Reportes' },
  ];
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [openLotAnteMotem, setOpenLotAnteMotem] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');
  const [imageGraphTwo, setImageGraphTwo] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenLotAm');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraph = () => {
    const graph: any = document.getElementById('grapham');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraphTwo = () => {
    const graph: any = document.getElementById('graphamt');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraphTwo(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeVisceras = () => {
    setOpenLotAnteMotem(true);
  };

  useEffect(() => {
    generateScreenshot();
    screenshotGraphTwo();
    screenshotGraph();
  }, [openLotAnteMotem]);

  const rows = [
    {
      id: 1,
      Fecha: dayjs(new Date()).format('DD/MM/YYYY'),
      'Número de animales': 'CO-04',
      'Canal ID': 0,
      'Estado Animales': 'Caídos en corrales',
      'Temp (°C)': 'NR',
      'Frecuencia Respiratoria': 'NR',
      Dictamen: 'Beneficio con restricciones',
      'Fecha Inspección': dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
      'Fecha Sacrificio': dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      id: 2,
      Fecha: dayjs(new Date()).format('DD/MM/YYYY'),
      'Número de animales': 'CO-04',
      'Canal ID': 0,
      'Estado Animales': 'Caídos en corrales',
      'Temp (°C)': 'NR',
      'Frecuencia Respiratoria': 'NR',
      Dictamen: 'Beneficio con restricciones',
      'Fecha Inspección': dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
      'Fecha Sacrificio': dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      id: 3,
      Fecha: dayjs(new Date()).format('DD/MM/YYYY'),
      'Número de animales': 'CO-04',
      'Canal ID': 0,
      'Estado Animales': 'Caídos en corrales',
      'Temp (°C)': 'NR',
      'Frecuencia Respiratoria': 'NR',
      Dictamen: 'Beneficio con restricciones',
      'Fecha Inspección': dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
      'Fecha Sacrificio': dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
    },
  ];

  const rows2 = [
    {
      id: 1,
      'Fecha Registro': dayjs(new Date()).format('DD/MM/YYYY hh:mm:ss A'),
      'Estado Animales': 'Muerto en Transporte',
      'Operador Corrales': 'Hamilton Lopera Quiroz',
    },
    {
      id: 2,
      'Fecha Registro': dayjs(new Date()).format('DD/MM/YYYY hh:mm:ss A'),
      'Estado Animales': 'Muerto en Desembarque',
      'Operador Corrales': 'Hamilton Lopera Quiroz',
    },
    {
      id: 3,
      'Fecha Registro': dayjs(new Date()).format('DD/MM/YYYY hh:mm:ss A'),
      'Estado Animales': 'Muerto en Transporte',
      'Operador Corrales': 'Hamilton Lopera Quiroz',
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'Fecha',
      headerName: 'Fecha',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Número de animales',
      headerName: 'Número Animal',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Canal ID',
      headerName: 'Canal ID',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Estado Animales',
      headerName: 'Estado Animales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Temp (°C)',
      headerName: 'Temp (°C)',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Frecuencia Respiratoria',
      headerName: 'Frecuencia Respiratoria',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Dictamen',
      headerName: 'Dictamen',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Fecha Inspección',
      headerName: 'Fecha Inspección',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Fecha Sacrificio',
      headerName: 'Fecha Sacrificio',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  const columns2: GridColDef[] = [
    {
      field: 'Fecha Registro',
      headerName: 'Fecha Registro',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Estado Animales',
      headerName: 'Estado Animales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Operador Corrales',
      headerName: 'Operador Corrales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphAM />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphAMT />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard>
        <Box padding="10px">
          <LoteInfoTitle
            lot="7645"
            namereport="Nro. Reporte"
            numberreport="011-65432"
            title="Reporte Antemortem"
          />
        </Box>
        <Box marginY="12px" className={classes.containerReport}>
          <Grid container spacing={1} justify="center">
            <Grid item container sm={9} spacing={1}>
              <Grid component={Box} height="30px" item xs={12}>
                <div className={classes.titleContainer}>
                  <Typography variant="h6" className={classes.title}>
                    Agentes Relacionados
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={4}>
                <TableInfo
                  icon="icon-productor"
                  title="Productor"
                  items={{
                    Nombre: 'Cualquier productor',
                    NIT: '45134132',
                    Teléfono: '+57 314 6543 543',
                    Email: 'info@productor.com',
                    Dirección: 'Calle 105 A 23-23',
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TableInfo
                  icon="icon-planta"
                  title="Planta de beneficio"
                  items={{
                    Nombre: 'Cualquier planta',
                    NIT: '45134132',
                    Teléfono: '+57 314 6543 543',
                    Email: 'info@planta.com',
                    Dirección: 'Calle 105 A 23-23',
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TableInfo
                  icon="icon-truck"
                  title="Transporte"
                  items={{
                    Conductor: 'Cualquier productor',
                    'Placa Vehículo': 'ERW 753',
                    'Llegada Planta': '22/01/2019 09:00:00 a.m.',
                    'Ingreso Planta': '22/01/2019 09:00:00 a.m.',
                    'Salida Planta': '22/01/2019 09:00:00 a.m.',
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container sm={3} spacing={1}>
              <Grid item xs={12}>
                <div className={classes.titleContainer}>
                  <Typography variant="h6" className={classes.title}>
                    Datos Inspección
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TableInfo
                  title="Corrales"
                  icon="icon-corral"
                  items={{
                    Operador: 'Oscar Dario Uribe Cadona',
                    'Número de documento': '452624545',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TableInfo
                  title="Inspección Veterinaria"
                  icon="icon-siringe"
                  items={{
                    Nombre: 'Julian David Mejia Ramirez',
                    'Número Matricula': '123234',
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container sm={12} spacing={1}>
              <Grid item xs={6}>
                <div className={classes.titleContainer}>
                  <Typography variant="h6" className={classes.title}>
                    Resumen de Animales Caídos / Agitados / Muertos en Corrales
                  </Typography>
                </div>
                <Chart
                  type="doughnut"
                  infoTotalData="Animales"
                  data={[
                    { label: 'Animales Agitados', data: 3, bg: '#D47D57' },
                    { label: 'Animales Caidos', data: 2, bg: '#E29662' },
                    { label: 'Animales Muertos', data: 1, bg: '#F1AE6C' },
                  ]}
                  graphicSize={150}
                />
              </Grid>
              <Grid item xs={6}>
                <div className={classes.titleContainer}>
                  <Typography variant="h6" className={classes.title}>
                    Resumen de Animales Muertos en Transporte / Desembarque
                  </Typography>
                </div>
                <Chart
                  type="doughnut"
                  infoTotalData="Animales"
                  data={[
                    { label: 'Muertos Transporte', data: 3, bg: '#D47D57' },
                    { label: 'Muertos Desembarque', data: 2, bg: '#E29662' },
                  ]}
                  graphicSize={150}
                />
              </Grid>
            </Grid>
            <Grid item container sm={12} spacing={1}>
              <Grid item xs={12}>
                <div className={classes.titleContainer}>
                  <Typography variant="h6" className={classes.title}>
                    Detalle Inspección de Animales Caídos / Agitados / Muertos
                    en Corrales
                  </Typography>
                  <Box position="absolute" right={8}>
                    <ExportToExcel
                      data={rows}
                      headers={columns.map((columns: any) => columns.field)}
                      fileName="Inspección-animales-caidos-agitados-muertos-corrales"
                    />
                  </Box>
                </div>
                <DataGrid
                  columns={columns}
                  rows={rows}
                  autoHeight
                  pageSize={pageSize}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  page={page}
                  onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                  onPageChange={(e: any) => setPage(e.page)}
                  components={{
                    Footer: TableFooter,
                  }}
                  className={classes.dataGrid}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.titleContainer}>
                  <Typography variant="h6" className={classes.title}>
                    Detalle Inspección de Animales Muertos en Transporte /
                    Desembarque
                  </Typography>
                  <Box position="absolute" right={8}>
                    <ExportToExcel
                      data={rows2}
                      headers={columns2.map((columns: any) => columns.field)}
                      fileName="Inspección-animales-muertos-transporte-desembarque"
                    />
                  </Box>
                </div>
                <DataGrid
                  columns={columns2}
                  rows={rows2}
                  autoHeight
                  pageSize={pageSize}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  page={page}
                  onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                  onPageChange={(e: any) => setPage(e.page)}
                  components={{
                    Footer: TableFooter,
                  }}
                  className={classes.dataGrid}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ContentCard>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography className={classes.options}>
          Opciones del reporte
        </Typography>
        <Button
          startIcon={<i className="icon-eye" />}
          color="primary"
          variant="contained"
          onClick={openModalRangeVisceras}
        >
          Previsualizar
        </Button>
      </Box>
      <DialogPDFViewer
        open={openLotAnteMotem}
        onClose={() => setOpenLotAnteMotem(false)}
        done={Boolean(imageQr)}
      >
        <MyDocAnteMortem
          qr={imageQr}
          graph={imageGraph}
          graphTwo={imageGraphTwo}
        />
      </DialogPDFViewer>
    </>
  );
};

export default Details;
