import React from 'react';
import { Typography } from '@material-ui/core';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import GobackLink from 'components/GobackLink';
import ContentCard from 'components/ContentCard';
import IndicativeTable from 'components/IndicativeTable';
import Maps from 'components/maps/cavas/central-ganadera';

import useStyles from './styles';

const CavasRealTime = () => {
  const classes = useStyles();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/inventory/cava', title: 'Inventario Cavas' },
  ];
  return (
    <>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard>
        <ContentCard.Header date={new Date().toDateString()}>
          Inventario de Animales en Pie en Corrales de Planta de Beneficio
        </ContentCard.Header>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <div className={classes.tableContainer}>
            <IndicativeTable
              title="Distribución de Cavas"
              item={{
                'Menos de 1 día en Cavas': '#767676',
                '2 días en Cavas': '#57E28C',
                '3 días en Cavas': '#FCD532',
                '4 días en Cavas': '#ED8C2B',
                'Más de 5 días en Cavas': '#E2575C',
              }}
              direction="column"
              width="359px"
            />
          </div>
          <div style={{ height: 580, width: 750 }}>
            <Maps />
          </div>
        </div>
      </ContentCard>
    </>
  );
};

export default CavasRealTime;
