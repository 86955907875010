import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { AuthLayout, GeneralLayout, ReportsLayout } from 'layouts';
import Page404 from 'pages/Errors/404';
import Page401 from 'pages/Errors/401';
import { ValidationTokenProvider } from 'lib/context/ValidationTokenContext';

const MainRoutes = () => {
  return (
    <Router>
      <ValidationTokenProvider>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route path="/app" component={GeneralLayout} />
          <Route path="/viewer" component={ReportsLayout} />
          <Route path="/404" component={Page404} />
          <Route path="/401" component={Page401} />
          <Redirect from="/" to="/auth/login" exact />
          <Redirect from="*" to="/404" />
        </Switch>
      </ValidationTokenProvider>
    </Router>
  );
};

export default MainRoutes;
