import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: '8px 0',
  },
  headerContainer:{
    width: '100%',
    display: 'flex', 
    justifyContent:'space-between', 
    alignItems:'center'
  }, 
  headerTitle:{
    color:'#767676', 
  },
  headerListContainer:{
    width: '60%',
    display:'flex', 
  },
  headerListItem:{
    display:'flex', 
    justifyContent:'space-between', 
    background:'#F2F2F2',
    margin: '0px 8px'
  },
  titleListItem:{
    fontWeight:'bold', 
    color:'#AFAFAF'
  },
  infoListItem:{
    fontWeight:'bold',
    color: theme.palette.primary.main
  }, 
  title:{
    color: '#767676', 
    fontWeight:'bold',
    borderBottom: '1px solid #AFAFAF', 
    textAlign:'center'
  }, 
  infoContainer:{
    padding: 12
  },
  listContainer:{
    color:theme.palette.primary.main
  },
  infoLoteContainer:{
    display:'flex', 
    justifyContent:'space-evenly', 
    margin: '20px 0px'
  }, 
  infoLoteInfo:{
    display:'flex',
    justifyContent:'space-evenly !important',
  }, 
  importantInfoContainer:{
    width:'100%',
    background:'#F2F2F2', 
    display: 'flex',
    justifyContent:'space-evenly' ,
    padding: 20, 
  },
  stateChildren:{
    background:'#F2F2F2',  
  }, 
  stateAnimals:{
    display:'flex',
    alignItems:'center' 
  }, 
  stateIcon:{
    fontSize: 36,
    paddingRight:12,
    color:theme.palette.primary.main
  }, 
  stateTitle:{
    color:'#767676', 
    fontSize:14, 
    fontWeight:'bold'
  }, 
  listContainerWrap:{
    "& ul":{
      display:'flex', 
      flexFlow:'row wrap',
      justifyContent:'space-between',
      color:theme.palette.primary.main, 
      "& li":{
        width: '45%',
      }
    },
  }, 
  stateChild2:{
    "& .MuiTypography-root":{
      width:'200px !important', 
      textAlign: 'left'
    }
  },
  dataGrid:{
    border:'none', 
    display:'flex',
    margin: '20px 0',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator":{
      display:'none'
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal', 
      color: '#AFAFAF',
      fontWeight: 'bold', 
      fontSize: 14
    }
  }, 
  finishedProcess:{
    background:'#6AC759', 
    color: '#fff', 
    fontWeight: 'bold', 
    fontSize: 14, 
    padding: 4
  }, 
  inProcess:{
    background: theme.palette.primary.main , 
    color: '#fff', 
    fontWeight: 'bold', 
    fontSize: 14, 
    padding: 4
  },
  options:{
    color:'#767676', 
    margin: '0px 12px'
  }
}))

export { useStyles as default }