import React, { useState } from 'react';
import {
  TextField,
  InputAdornment,
  Typography,
  Avatar,
  Box,
  Fab,
  MenuItem,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';

import clsx from 'clsx';

import ContentCard from 'components/ContentCard';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import GobackLink from 'components/GobackLink';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import FilterShareReports from 'components/PageComponents/SharedReports/FilterByDateRange';

import dayjs from 'dayjs';

import useStyles from './styles';

const FavoritesDetails = () => {
  const classes = useStyles();
  const [filterType, setFilterType] = useState('Todos');
  const [range, setRange] = useState([dayjs().startOf('M'), dayjs()]);

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/network/shared-reports/favorites',
      title: 'Detalles de Favoritos',
    },
  ];

  const users = [
    {
      id: 1,
      nameType: 'Tiquete de Báscula',
      iconType: 'icon-ticket',
      loteIP: 2041,
      reportNumber: 203050,
      created_at: dayjs().format('DD/MM/YYYY'),
      comercializador: 'Ivan Echeverri',
      productor: 'Paula Andrea Bolivar Uribe',
      sharedSince: dayjs().format('DD/MM/YYYY'),
      sharedUntil: dayjs().date(30).format('DD/MM/YYYY'),
    },
    {
      id: 2,
      nameType: 'Portería',
      iconType: 'icon-porteria',
      loteIP: 2042,
      reportNumber: 203052,
      created_at: dayjs().date(20).format('DD/MM/YYYY'),
      comercializador: 'Ivan Echeverri',
      productor: 'Nestor Angel',
      sharedSince: dayjs().format('DD/MM/YYYY'),
      sharedUntil: dayjs().date(30).format('DD/MM/YYYY'),
    },
    {
      id: 3,
      nameType: 'Viseras Blancas',
      iconType: 'icon-sausage',
      loteIP: 1920,
      reportNumber: 103052,
      created_at: dayjs().date(20).format('DD/MM/YYYY'),
      comercializador: 'Ivan Echeverri',
      productor: 'Nestor Angel',
      sharedSince: dayjs().format('DD/MM/YYYY'),
      sharedUntil: dayjs().date(30).format('DD/MM/YYYY'),
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: 'Tipo de Reporte',
      renderCell: (params: GridValueGetterParams) => (
        <Box display="flex" alignItems="center">
          <Avatar className={classes.avatar}>
            <i className={clsx(params.getValue(params.id, 'iconType'))} />
          </Avatar>

          <Typography>{params.getValue(params.id, 'nameType')}</Typography>
        </Box>
      ),
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 200,
      sortable: false,
      hide: filterType !== 'Todos',
    },
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'reportNumber',
      headerName: 'N° de Reporte',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'productor',
      headerName: 'Productor',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 280,
    },
    {
      field: 'comercializador',
      headerName: 'Comercializador',
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 280,
      sortable: false,
    },
    {
      field: 'Destino',
      headerName: 'Destino',
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 250,
      sortable: false,
      hide: filterType !== 'Distribución y Despachos',
    },
    {
      field: 'created_at',
      headerName: 'Fecha Creación',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'sharedSince',
      headerName: 'Compartido Desde',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'sharedUntil',
      headerName: 'Compartido Hasta',
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard>
        <Box mb={2}>
          <FilterShareReports
            onChangeReportType={(e: any) => setFilterType(e)}
            onChangeRange={(e: any) => setRange(e)}
          />
        </Box>
        <Typography variant="h6" color="secondary">
          {filterType === 'Todos'
            ? `${filterType} los Reportes`
            : `Reportes ${filterType} `}
        </Typography>
        <DataGrid
          columns={columns}
          rows={users}
          autoHeight
          className={classes.dataGrid}
          components={{
            Footer: TableFooter,
          }}
        />
      </ContentCard>
    </>
  );
};

export default FavoritesDetails;
