import React from 'react'
import { Grid, Box, Typography } from '@material-ui/core'
import moment from 'moment'

import useStyles, {LotNumberDateTitleContainer} from './styles'

const LoteInfoTitle = ({title, lot, numberreport, namereport}) => {
  const classes = useStyles()
  return (
    <Box width='100%'>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='h6' color='textSecondary'>{ title }</Typography>
        </Grid>
        <Grid item justify='flex-end' classes={{ root: classes.chipsContainer }} spacing={4} xs={6}>
          <div className={classes.infoChip}>
            <Typography className={classes.chipTitle}>Lote InfoPorcinos</Typography>
            <Typography className={classes.chipDescription} style={{ marginLeft: 20 }}>{ lot }</Typography>
          </div>
          <div className={classes.infoChip}>
            <Typography className={classes.chipTitle}>{ namereport }</Typography>
            <Typography className={classes.chipDescription}>{ numberreport }</Typography>
          </div>
          <div className={classes.infoChip}>
            <Typography className={classes.chipTitle}>Fecha</Typography>
            <Typography className={classes.chipDescription}>{ moment().format('DD/MM/YYYY') }</Typography>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoteInfoTitle