import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  borderRadiudDialog: {
    textAlign: 'center',
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px',
    }
  },
  titleContainer:{
    marginTop: 10,
  },
  fontTitle:{
    fontWeight: 'bold',
    fontSize: 17
  },
  fontDescription:{
  },
  fontData:{
    color: theme.palette.primary.main,
  },
  icon:{
    color: theme.palette.primary.main,
    fontSize: 80
  }
}))

export { useStyles as default }