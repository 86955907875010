import React, { useState } from 'react';

import { Grid, Box, Typography, Chip } from '@material-ui/core';

import ContentCard from 'components/ContentCard';
import MainTitle from 'components/MainTitle';
import ItemListData from 'components/ItemListData';
import AddressPreview from 'components/AddressPreview';

import documentTypeById from 'lib/helpers/documentTypeById';
import constants from 'config/constants';

import dayjs from 'dayjs';
import clsx from 'clsx';

import useStyles from './styles';

const Detail = ({ data }: any) => {
  const classes = useStyles();
  const days = constants.DAYS;
  const schedule: any = data?.horariosDestino;
  const typeDestiny = Number(data?.idTipoDestino);
  const type = () => {
    switch (typeDestiny) {
      case 1:
        return 'Punto de Venta';
      case 2:
        return 'Planta de Desposte';
      case 3:
        return 'Planta de Acondicionamiento';
      default:
        return '';
    }
  };

  return (
    <Grid container xs={12}>
      <Grid item container xs={12} xl={6} justifyContent="center">
        <ContentCard className={classes.containerLeft}>
          <Box display="flex" justifyContent="space-between">
            <MainTitle>Datos {type()}</MainTitle>
            <Chip
              size="medium"
              color="primary"
              variant="outlined"
              label={
                data?.estado
                  ? 'Activo'
                  : `Inactivo desde ${dayjs(data?.fechaInactivacion).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}`
              }
            />
          </Box>
          <Box marginTop={2}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={8}>
                {typeDestiny === 2 || typeDestiny === 3 ? (
                  <ItemListData
                    data={[
                      {
                        title: 'Nombre',
                        data: data?.nombreDestino,
                      },
                      {
                        title: 'Código',
                        data: data?.codigoDestino,
                      },
                      {
                        title: 'NIT',
                        data: `${data?.nit} - ${data?.digitoVerificacion} `,
                      },
                      {
                        title: 'Fecha de Registro',
                        data: dayjs(data?.fechaCreacion).format(
                          'DD/MM/YYYY HH:mm:ss'
                        ),
                      },
                    ]}
                  />
                ) : (
                  <ItemListData
                    data={[
                      {
                        title: 'Nombre',
                        data: data?.nombreDestino,
                      },
                      {
                        title: 'Código',
                        data:
                          data?.codigoDestino != ''
                            ? data?.codigoDestino
                            : 'N.D',
                      },

                      {
                        title: 'Fecha de Registro',
                        data: dayjs(data?.fechaCreacion).format(
                          'DD/MM/YYYY HH:mm:ss'
                        ),
                      },
                    ]}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-end">
                  <img
                    style={{ maxWidth: 150 }}
                    src={`data:image/png;base64, ${data?.codigoQR}`}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <MainTitle>Datos del Responsable</MainTitle>
          <Box marginY={2}>
            <Grid container xs={12} spacing={2}>
              <Box width="100%">
                <ItemListData
                  data={[
                    {
                      title: 'Nombre',
                      data: `${data?.primerNombre} ${data?.segundoNombre} ${data?.primerApellido} ${data?.segundoApellido}`,
                    },
                    {
                      title: 'Documento de Identidad',
                      data: `${documentTypeById(data?.idTipoDocumento, 1)} ${
                        data?.numeroDocumento
                      }`,
                    },

                    {
                      title: 'Correo Electrónico',
                      data: data?.emailResponsable,
                    },
                  ]}
                />
              </Box>
            </Grid>
          </Box>
        </ContentCard>
      </Grid>
      <Grid item container xs={12} xl={6} justifyContent="center">
        <ContentCard className={classes.containerRight}>
          <MainTitle>Datos de Contacto</MainTitle>
          <Box marginY={2}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={6}>
                <ItemListData
                  data={[
                    {
                      title: 'Correo Electrónico',
                      data: data?.email,
                    },
                    {
                      title: 'Celular',
                      data: `(+${data?.indicativoCelular}) ${data?.numeroCelular}`,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <ItemListData
                  data={[
                    {
                      title: 'WhatsApp',
                      data: data?.numeroWhatsapp
                        ? `(+${data?.indicativoWhatsapp}) ${data?.numeroWhatsapp}`
                        : 'N.D',
                    },
                    {
                      title: 'Teléfono',
                      data: data?.numeroTelefono
                        ? `(+${data?.indicativoTelefono}) ${
                            data?.numeroTelefono
                          } Ext.(${data?.extensionTelefono ?? ''})`
                        : 'N.D',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
          <MainTitle>Datos de Ubicación - Dirección Geolocalizada</MainTitle>
          <Box marginY={1} minHeight="180px" display="flex" alignItems="center">
            <Grid container xs={12} spacing={2}>
              <AddressPreview
                directionType={data?.tipoDireccion}
                country={data?.pais}
                department={data?.departamento}
                city={data?.ciudad}
                roadType={data?.tipoVia}
                numberVia={data?.numeroVia}
                viaAppend={data?.apendiceVia}
                crossingAppend={data?.apendiceCruce}
                numberCrossing={data?.numeroCruce}
                cornerMeters={data?.metrosEsquina}
                additional={data?.adicional}
                postalCode={data?.codigoPostal}
                longitude={data?.longitud}
                latitude={data?.latitud}
                direction={data?.direccion}
              />
            </Grid>
          </Box>
        </ContentCard>
      </Grid>

      <Grid item container xs={12} xl={6} justifyContent="center">
        <ContentCard className={classes.containerLeft}>
          <MainTitle>Horarios De Atención</MainTitle>
          <Box marginY={2}>
            <Grid container justifyContent="center">
              {days.map((day: any) => {
                const sc = schedule?.filter((i: any) => i?.idTipoHorario === 1);
                const daysh = sc?.map((i: any) => i?.dia);
                return (
                  <Grid item container xs={2} xl={3}>
                    <Box className={classes.flexColumn}>
                      <Box
                        className={clsx({
                          [classes.scheduleTitle]: true,
                          [classes.scheduleDisabledTitle]:
                            !daysh?.includes(day),
                        })}
                      >
                        {day}
                      </Box>
                      <Box className={classes.scheduleContent}>
                        {daysh?.includes(day) ? (
                          sc?.map((h: any) => {
                            return h?.dia === day ? (
                              <>
                                <Typography color="secondary">
                                  {h?.horaInicial.slice(0, 5)} -{' '}
                                  {h?.horaFinal.slice(0, 5)}
                                </Typography>
                              </>
                            ) : null;
                          })
                        ) : (
                          <Typography color="secondary">Cerrado</Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </ContentCard>
      </Grid>
      <Grid item container xs={12} xl={6} justifyContent="center">
        <ContentCard className={classes.containerRight}>
          <MainTitle>Horarios De Cargue / Descargue</MainTitle>
          <Box marginY={2}>
            <Grid container justifyContent="center">
              {days.map((day: any) => {
                const sc = schedule?.filter((i: any) => i?.idTipoHorario === 2);
                const daysh = sc?.map((i: any) => i?.dia);
                return (
                  <Grid item container xs={2} xl={3}>
                    <Box className={classes.flexColumn}>
                      <Box
                        className={clsx({
                          [classes.scheduleTitle]: true,
                          [classes.scheduleDisabledTitle]:
                            !daysh?.includes(day),
                        })}
                      >
                        {day}
                      </Box>
                      <Box className={classes.scheduleContent}>
                        {daysh?.includes(day) ? (
                          sc?.map((h: any) => {
                            return h?.dia === day ? (
                              <>
                                <Typography color="secondary">
                                  {h?.horaInicial.slice(0, 5)} -{' '}
                                  {h?.horaFinal.slice(0, 5)}
                                </Typography>
                              </>
                            ) : null;
                          })
                        ) : (
                          <Typography color="secondary">Cerrado</Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </ContentCard>
      </Grid>
    </Grid>
  );
};

export default Detail;
