export default (type: any) => {
  const icon = type.toLocaleLowerCase();
  switch (icon) {
    case 'portería':
      return 'icon-porteria';
    case 'guias de movilización':
      return 'icon-truck';
    case 'tiquete de báscula':
      return 'icon-ticket';
    case 'inmunocastración':
      return 'icon-siringe';
    case 'antemortem':
      return 'icon-pig';
    case 'visceras blancas':
      return 'icon-sausage';
    case 'postmortem':
      return 'icon-canales';
    case 'evaluación de magro':
      return 'icon-gp4';
    case 'distribución y despacho':
      return 'icon-organs';
    default:
      return 'icon-paper-sheet';
  }
};