import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  generalContainer:{
    display:'flex', 
    justifyContent:'space-evenly',
    alignItems:'center', 
    padding: '8px 0',
    "&.top":{
      flexDirection: 'column',
    },
    "&.bottom":{
      flexDirection: 'column-reverse',
    },
    "&.left":{
      flexDirection: 'row',
    },
    "&.right":{
      flexDirection: 'row-reverse',
    }
  },
  generalContainerBar:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center', 
    padding: '8px 0',
    "&.top":{
      flexDirection: 'column',
    },
    "&.bottom":{
      flexDirection: 'column-reverse',
    },
    "&.left":{
      flexDirection: 'row',
    },
    "&.right":{
      flexDirection: 'row-reverse',
    }
  },
  graphContainer:{
    display:'flex', 
    justifyContent:'center',
    alignItems:'center', 
    position:'relative', 
  },
  graphic:{ 
    zIndex: 2
  },
  infoGraphicContainer:{
    display:'flex', 
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center', 
    position: 'absolute', 
    color: '#767676', 
    fontWeight: 'bolder', 
    zIndex:1
  },
  withoutTotalInfo:{
    display: 'none'
  },
  infoGraphic:{
    color: '#767676', 
    fontWeight: 'bolder'
  },  
  totalAnimalsContainer:{
    display:'flex', 
    justifyContent:'space-evenly', 
    marginTop: 12,
    alignItems: 'center'
  },
  labels:{
    width:'100%', 
    margin:'0px 20px', 
    "&.top":{
      width:'100%',
    },
    "&.bottom":{
      width:'100%',
    },
  }, 
  labelsBar:{
    width:'40%', 
    display:'flex',
    flexDirection:'column', 
    justifyContent:'center',
    "&.top":{
      width:'100%',
       flexFlow:'row wrap', 
       
    },
    "&.bottom":{
      width:'100%',
       flexFlow:'row wrap', 
    },
    "& li":{
      margin: '0px 12px'
    }
  }, 
  itemLabels:{
    width: '100%',
    display:'flex',
    color: '#767676', 
    padding: 4
  }, 
  porcentage:{
    width: '20%', 
    margin: '0px 8px'
  },
  withoutPorcentage:{
    display: 'none'
  },
  withoutData:{
    display: 'none'
  },
  titleLabel:{
    width: '60%', 
    textAlign: 'left', 
    margin: '0px 8px',
  },
  titleLabelBar:{
    width: '100%', 
    textAlign: 'left', 
  },
  dataLabel:{
    width: '10%',
    textAlign: 'right', 
    fontWeight: 'bold', 
    margin: '0px 8px'
  },
  withoutLabel:{
    display: 'none'
  }
}))

export { useStyles as default }
