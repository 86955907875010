import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container:{
    margin: '20px 0px',
    padding: '20px 40px',
    borderRadius: 20, 
    boxShadow: theme.shadows[3], 
  },  
  titleContainer:{
    display:"flex",
    alignItems:"center",
    marginBottom: 8, 
  }, 
  title: {
    display:'flex', 
    alignItems:'center',
    fontSize: 24,
    "& i":{
      fontSize: 30, 
      marginLeft: 4
    }
  }, 
  stepperContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      fontSize: '1.8rem',
    },
    '& .MuiStepLabel-active': {
      color: '#DD9835',
    },
    '& .MuiStepIcon-active': {
      color: '#DD9535',
    },
    '& .Mui-disabled .MuiStepIcon-root': { color: '#AFAFAF', opacity: '85%' },
  },
  stepperButtons:{
    width: '100%', 
    display: 'flex', 
    justifyContent: 'center'
  },
  card: {
    minWidth: 200,
    borderRadius: 10,
    boxShadow: theme.shadows[2],
    margin: 12,
  },
  content: {
    height: 120,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
  },
}));

export default useStyles;
