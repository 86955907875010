import { gql } from '@apollo/client';

export interface comercializador {
  puntosVenta: any;
  parametros: any;
  tipoComercializador: any;
  finalizado: any;
}

export interface productor {
  granjas: any;
  finalizado: any;
}

export interface InitialStepsAttributes {
  comercializador: comercializador;
  productor: productor;
}

export interface GetInitialSteps {
  data: InitialStepsAttributes;
}

export interface GetInitialStepsProps {
  initialSteps: GetInitialSteps;
}
export const GetInitialStepsQuery = gql`
  query initialSteps {
    initialSteps {
      data {
        comercializador {
          puntosVenta
          parametros
          tipoComercializador
          finalizado
        }
        productor {
          granjas
          finalizado
        }
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;
