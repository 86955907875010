import { gql } from "apollo-boost";
export const GET_ALL_INTERNAL_USERS = gql`
  query($idUserType: Int!){
    getAllInternalUsers(input: {
    IdTipoUsuario: $idUserType
  }){
      data{
        idUsuario
        idPersona
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        idTipoDocumento
        tipoDocumento
        numeroDocumento
        idCargo
        cargo
        idPais
        celular
        email
        username
        fechaRegistro
        estado
        idRol, 
        rol
      }
      token{
        tokenNuevo
      }
    }
  }
`;

export const INTERNAL_USER_WITHOUT_USERTYPE = gql`
  query{
    getUsersInternal{
      data{
        idUsuario
        idPersona
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        idTipoDocumento
        tipoDocumento
        numeroDocumento
        idCargo
        cargo
        idRol
        rol
        idPais
        celular
        email
        username
        fechaRegistro
        estado
        ultimaConexion
        tiempoActivo
      }
      token{
        fechaExpiracion
        tokenNuevo
      }
    }
  }
`;