import React, { useState } from "react";

import {
  Card,
  Box,
  CardContent,
  Typography,
  List,
  ListItem,
  Button,
} from "@material-ui/core";

import SponsoredCode from "./SponsoredCode";
import thousandsFormat from "lib/helpers/thousandsFormat";

import clsx from "clsx";

import useStyles from "./styles";

interface CardPlanProps {
  sponsored?: Boolean;
  planName?: string;
  price?: number;
  time?: string;
  benefits?: Array<any>;
}

const CardPlan = ({
  sponsored,
  planName,
  price,
  time,
  benefits,
}: CardPlanProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {sponsored ? (
        <Card className={clsx(classes.container, classes.sponsored)}>
          <div>
            <Box className={classes.titleContainer}>
              <Typography variant="h5" className={classes.title}>
                Usuario Patrocinado
              </Typography>
            </Box>
            <CardContent>
              <Box className={classes.filterContainer}>
                <Typography variant="h6" color="secondary">
                  Acceso Gratuito
                </Typography>
              </Box>
              <Box className={classes.infoContainer}>
                <Typography variant="h6" className={classes.subtitle}>
                  Beneficios
                </Typography>
                <List dense={true}>
                  {benefits?.map((item: any) => (
                    <ListItem className={classes.listItem}>
                      <i
                        className="icon-circle-check"
                        style={{
                          color: "#68CE70",
                          fontSize: 20,
                          marginRight: 4,
                        }}
                      />
                      <Typography color="secondary">{item}</Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </CardContent>
          </div>
          <SponsoredCode open={open} close={handleClose} />
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Activar patrocinio
            </Button>
          </Box>
        </Card>
      ) : (
        <Card className={classes.container}>
          <div>
            <Box className={classes.titleContainer}>
              <Typography variant="h5" className={classes.title}>
                {planName}
              </Typography>
            </Box>
            <CardContent>
              <Box className={classes.filterContainer}>
                <Typography variant="h6" color="secondary">
                  $ {thousandsFormat(price ?? 0)} COP + IVA{" "}
                  <Typography
                    variant="inherit"
                    style={{ color: "#AFAFAF", fontWeight: "normal" }}
                  >
                    /{time}
                  </Typography>
                </Typography>
              </Box>
              <Box className={classes.infoContainer}>
                <Typography variant="h6" className={classes.subtitle}>
                  Beneficios
                </Typography>
                <List dense={true}>
                  {benefits?.map((item: any) => (
                    <ListItem className={classes.listItem}>
                      <i
                        className="icon-circle-check"
                        style={{
                          color: "#68CE70",
                          fontSize: 20,
                          marginRight: 4,
                        }}
                      />
                      <Typography color="secondary">{item}</Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </CardContent>
          </div>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary">
              Suscribirse
            </Button>
          </Box>
        </Card>
      )}
    </>
  );
};

export default CardPlan;
