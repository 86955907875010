import { gql } from 'apollo-boost';

export const COUNTRIES = gql`
  query {
    countries {
      name
      id
    }
  }
`;

export const DEPARTMENTS = gql`
  query ($idCountry: ID!) {
    departments(idPais: $idCountry) {
      departmentName: name
      idDepartment: id
    }
  }
`;

export const CITIES = gql`
  query ($idState: ID!) {
    cities(idState: $idState) {
      idCity
      nameCity
    }
  }
`;

export const CITIES_BY_ID = gql`
  query citiesById($idCity: ID!) {
    citiesById(idCity: $idCity) {
      nameCity
      idCity
    }
  }
`;

export const DEPARTMENTS_BY_ID = gql`
  query departmentsById($IdDepart: ID!){
  	departmentsById(IdDepart: $IdDepart){
      name
  }
}
`;
