import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '20px',
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      textAlign: 'center'
    },
  },
  iconDialogCheck:{
    fontSize:60,
    color:theme.palette.primary.main
  }, 
  title:{
    fontWeight: 600, 
    fontSize:24,
  },
  captionGray:{
    color:'#AFAFAF',
    padding:12,
    paddingLeft:24
  },
  dialogFooter:{
    display:'flex', 
    justifyContent:'center', 
    padding: 16
  }, 
}));

export default useStyles;
