import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  MenuItem,
  TextField,
  TextFieldProps,
  Grow,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Fab,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import ES from 'date-fns/locale/es';
import dayjs from 'dayjs';

import unmaskPhone from 'lib/helpers/unmaskPhone';
import {
  DriverValidation,
  PutDriverValidation,
} from 'lib/validations/TransportValidation';
import constants from 'config/constants';

import PhoneNumber from 'components/PhoneNumber';
import DocumentTypes from 'components/DocumentTypes';
import Direction from 'components/Direction/index';
import GobackLink from 'components/GobackLink';
import ContentCard from 'components/ContentCard';
import MainTitle from 'components/MainTitle';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import { useHistory } from 'react-router';

import DialogConfirmData from '../../Details/DialogConfirmData/index';
import { UPDATE_DRIVER } from 'core/graphql/mutations/transport';
import {
  GET_DRIVER,
  GET_TYPE_LICENSE_CATEGORY,
} from 'core/graphql/queries/transport';
import Loader from 'components/Loader';

import useStyles from './styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

type CompononentProps = {
  id: number;
};

const DetailDriver = ({ id }: CompononentProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [openNotificationConfirm, setOpenNotificationConfirm] = useState({
    open: false,
    icon: '',
    title: '',
    message: '',
  });
  const [selectedDate, setSelectedDate] = useState<any>(dayjs());
  const [licenseCategory, setLicenseCategory] = useState([
    { idCategoriaLicencia: '', fechaVigencia: dayjs().format('YYYY/MM/DD') },
  ]);
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '#',
      title: 'Detalle Conductor',
    },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];

  const { data, loading, error, refetch } = useQuery(GET_DRIVER, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idConductor: id },
  });

  const {
    data: dataTypeLicenseCategory,
    loading: loadingTypeLicenseCategory,
    error: errorTypeLicenseCategory,
  } = useQuery(GET_TYPE_LICENSE_CATEGORY, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const result = data?.getDetailDriver?.data;

  const [putDriver, { loading: loadingPutDriver }] = useMutation(
    UPDATE_DRIVER,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const updateDriver = (data: any) => {
    putDriver({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.putDriver.data.success === 1) {
          enqueueSnackbar('Datos actualizados exitosamente.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
        } else {
          enqueueSnackbar(mutationResult.data.putDriver.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        idConductor: result.idConductor,
        primerNombre: result.primerNombre,
        segundoNombre: result.segundoNombre,
        primerApellido: result.primerApellido,
        segundoApellido: result.segundoApellido,
        numeroLicencia: result.numeroLicenciaConduccion,
        esConductorReemplazo: data.replacement,
        indicativoTelefono: Number(data.indicativePhone),
        telefono: data.phone,
        indicativoCelular: Number(data.indicativeMobile),
        celular: data.mobile,
        email: data.email,
        tipoDireccion: Number(data.directionType),
        idTipoVia: data.viaType,
        idCiudad: Number(data.city),
        numeroVia: String(data.viaNumber),
        apendiceVia: data.apendNumber,
        numeroCruce: String(data.cruceNumber),
        apendiceCruce: data.apendCruceNumber,
        metrosEsquina: String(data.cornerMeters),
        adicional: data.aditional,
        codigoPostal: data.postalCode,
      },
    });
  };

  const formikDriver = useFormik({
    initialValues: {
      documentType: 1,
      document: '',
      firstName: '',
      secondName: '',
      surname: '',
      secondSurname: '',
      licenseNumber: '',
      expeditionDate: dayjs(),
      licenseCategory: [
        {
          idCategoriaLicencia: '',
          fechaVigencia: dayjs().format('YYYY/MM/DD'),
        },
      ],
      replacement: false,
      indicativePhone: constants.DEFAULT_DEPARTMENT_ID,
      phone: '',
      indicativeMobile: constants.DEFAULT_COUNTRY_ID,
      mobile: '',
      email: '',
      directionType: 1,
      country: constants.DEFAULT_COUNTRY_ID,
      departament: constants.DEFAULT_DEPARTMENT_ID,
      city: constants.DEFAULT_CITY_ID,
      viaType: '',
      viaNumber: '',
      apendNumber: '',
      cruceNumber: '',
      apendCruceNumber: '',
      cornerMeters: '',
      aditional: '',
      postalCode: '',
    },
    validationSchema: PutDriverValidation,
    validateOnChange: false,
    onSubmit: (values) => {
      updateDriver(values);
    },
  });

  useEffect(() => {
    if (!loading) {
      formikDriver.setValues({
        documentType: result?.tipoDocumento,
        document: result?.numeroDocumento,
        firstName: result?.primerNombre,
        secondName: result?.segundoNombre,
        surname: result?.primerApellido,
        secondSurname: result?.segundoApellido,
        licenseNumber: result?.numeroLicenciaConduccion,
        replacement: result?.esConductordeReemplazo,
        expeditionDate: dayjs(result?.fechaExpedicionLicencia),
        licenseCategory: result?.categoriasLicencia,
        indicativePhone: constants.DEFAULT_DEPARTMENT_ID,
        phone: result?.telefono,
        indicativeMobile: result?.indicativoCelular,
        mobile: result?.celular,
        email: result?.correo,
        directionType: Number(result?.tipoDireccion),
        country: result?.idPais,
        departament: result?.idDepartamento,
        city: result?.idCiudad,
        viaType: result?.idTipoVia,
        viaNumber: result?.numeroVia,
        apendNumber: result?.apendiceVia,
        cruceNumber: result?.numeroCruce,
        apendCruceNumber: result?.apendiceCruce,
        cornerMeters: result?.metrosEsquina,
        aditional: result?.adicional,
        postalCode: result?.codigoPostal,
      });
      result?.categoriasLicencia?.map((item: any, index: number) => {
        licenseCategory.push({
          idCategoriaLicencia: item?.idCategoriaLicencia,
          fechaVigencia: item?.fechaVigencia,
        });
      });
      licenseCategory.splice(0, 1);
    }
  }, [loading]);

  const initialData = {
    replacement: result?.esConductordeReemplazo,
    indicativePhone: Number(result?.indicativoTelefono),
    phone: result?.telefono,
    indicativeMobile: Number(result?.indicativoCelular),
    mobile: result?.celular,
    email: result?.correo,
    directionType: Number(result?.tipoDireccion),
    country: result?.idPais,
    departament: result?.idDepartamento,
    city: Number(result?.idCiudad),
    viaType: Number(result?.idTipoVia),
    viaNumber: result?.numeroVia,
    apendNumber: result?.apendiceVia,
    cruceNumber: result?.numeroCruce,
    apendCruceNumber: result?.apendiceCruce,
    cornerMeters: result?.metrosEsquina,
    aditional: result?.adicional,
    postalCode: result?.codigoPostal,
  };

  const dataUpdate = {
    replacement: formikDriver.values.replacement,
    indicativePhone: Number(formikDriver.values.indicativePhone),
    phone: formikDriver.values.phone,
    indicativeMobile: Number(formikDriver.values.indicativeMobile),
    mobile: formikDriver.values.mobile,
    email: formikDriver.values.email,
    directionType: Number(formikDriver.values.directionType),
    country: formikDriver.values.country,
    departament: formikDriver.values.departament,
    city: Number(formikDriver.values.city),
    viaType: Number(formikDriver.values.viaType),
    viaNumber: formikDriver.values.viaNumber,
    apendNumber: formikDriver.values.apendNumber,
    cruceNumber: formikDriver.values.cruceNumber,
    apendCruceNumber: formikDriver.values.apendCruceNumber,
    cornerMeters: formikDriver.values.cornerMeters,
    aditional: formikDriver.values.aditional,
    postalCode: formikDriver.values.postalCode,
  };

  useEffect(() => {
    if (!loadingPutDriver) {
      refetch();
    }
  }, [loadingPutDriver]);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    formikDriver.handleChange;
    formikDriver.values.expeditionDate = date;
  };

  const handleFormChange = (index: number, event: any, name: string) => {
    const data = [...licenseCategory];
    if (name === 'idCategoriaLicencia') {
      data[index].idCategoriaLicencia = event.target.value;
    } else {
      data[index].fechaVigencia = dayjs(event).format('YYYY/MM/DD');
    }
    setLicenseCategory(data);
    formikDriver.values.licenseCategory = data;
    formikDriver.handleChange;
  };

  const addFields = () => {
    let newfield = {
      idCategoriaLicencia: '',
      fechaVigencia: dayjs().format('YYYY/MM/DD'),
    };
    setLicenseCategory([...licenseCategory, newfield]);
  };

  const removeFields = (index: number) => {
    let data = [...licenseCategory];
    data.splice(index, 1);
    setLicenseCategory(data);
    formikDriver.values.licenseCategory = data;
  };

  const renderInput = (props: TextFieldProps): any => (
    <TextField
      label="Fecha de Vigencia"
      size="small"
      fullWidth
      variant="outlined"
      onClick={props.onClick}
      value={props.value}
      onChange={props.onChange}
    />
  );

  return (
    <>
      <Loader visible={loading}>Cargando...</Loader>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Box mb={2}>
          <Grid container justifyContent="center">
            <MainTitle>Detalle Conductor</MainTitle>
          </Grid>
        </Box>
        <Grow in timeout={800}>
          <form onSubmit={formikDriver.handleSubmit} style={{ padding: 40 }}>
            <Box marginBottom={3}>
              <MainTitle fontSize="h6">Datos Generales del Conductor</MainTitle>
            </Box>

            <Box mb={2}>
              <Grid container spacing={3}>
                <Grid container item spacing={1}>
                  <Grid item xs={6}>
                    <DocumentTypes
                      name="documentType"
                      value={formikDriver.values.documentType}
                      onChange={formikDriver.handleChange}
                      entity={1}
                      errors={formikDriver.errors.documentType}
                      helperText={formikDriver.errors.documentType}
                      disable
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Doumento"
                      variant="outlined"
                      size="small"
                      name="document"
                      type="number"
                      value={formikDriver.values.document}
                      onChange={formikDriver.handleChange}
                      error={
                        formikDriver.touched.document &&
                        Boolean(formikDriver.errors.document)
                      }
                      helperText={
                        formikDriver.touched.document &&
                        formikDriver.errors.document
                      }
                      required
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Grid>
              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Primer Nombre"
                      variant="outlined"
                      size="small"
                      name="firstName"
                      value={formikDriver.values.firstName}
                      onChange={formikDriver.handleChange}
                      error={
                        formikDriver.touched.firstName &&
                        Boolean(formikDriver.errors.firstName)
                      }
                      helperText={
                        formikDriver.touched.firstName &&
                        formikDriver.errors.firstName
                      }
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Segundo Nombre"
                      variant="outlined"
                      size="small"
                      name="secondName"
                      value={formikDriver.values.secondName}
                      onChange={formikDriver.handleChange}
                      error={
                        formikDriver.touched.secondName &&
                        Boolean(formikDriver.errors.secondName)
                      }
                      helperText={
                        formikDriver.touched.secondName &&
                        formikDriver.errors.secondName
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Primer Apellido"
                      variant="outlined"
                      size="small"
                      name="surname"
                      value={formikDriver.values.surname}
                      onChange={formikDriver.handleChange}
                      error={
                        formikDriver.touched.surname &&
                        Boolean(formikDriver.errors.surname)
                      }
                      helperText={
                        formikDriver.touched.surname &&
                        formikDriver.errors.surname
                      }
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Segundo Apellido"
                      variant="outlined"
                      size="small"
                      name="secondSurname"
                      value={formikDriver.values.secondSurname}
                      onChange={formikDriver.handleChange}
                      error={
                        formikDriver.touched.secondSurname &&
                        Boolean(formikDriver.errors.secondSurname)
                      }
                      helperText={
                        formikDriver.touched.secondSurname &&
                        formikDriver.errors.secondSurname
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2.5} mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Número de Licencia"
                      variant="outlined"
                      size="small"
                      name="licenseNumber"
                      value={formikDriver.values.licenseNumber}
                      onChange={formikDriver.handleChange}
                      error={
                        formikDriver.touched.licenseNumber &&
                        Boolean(formikDriver.errors.licenseNumber)
                      }
                      helperText={
                        formikDriver.touched.licenseNumber &&
                        formikDriver.errors.licenseNumber
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ES}>
                      <DatePicker
                        label="Fecha Expedición"
                        format="dd/MM/yyyy"
                        autoOk
                        value={formikDriver.values.expeditionDate}
                        onChange={handleDateChange}
                        variant="inline"
                        fullWidth
                        inputVariant="outlined"
                        size="small"
                        labelFunc={() =>
                          dayjs(formikDriver.values.expeditionDate).format(
                            'DD/MM/YYYY'
                          )
                        }
                        maxDate={dayjs()}
                        minDate={dayjs().subtract(10, 'year')}
                        minDateMessage="La fecha final debe ser mayor a la inicial"
                        maxDateMessage="La fecha final debe ser mayor a la inicial"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="replacement"
                          color="primary"
                          onChange={formikDriver.handleChange}
                          checked={formikDriver.values.replacement}
                        />
                      }
                      label="Reemplazo"
                    />
                    <Tooltip
                      title='Selecciona esta opción si deseas que este conductor sea "Conductor de Reemplazo" del Vehículo que se le va a asociar.'
                      aria-label="add"
                      arrow
                    >
                      <Box
                        component="i"
                        className="icon-question"
                        fontSize="22px"
                        style={{ cursor: 'pointer', marginTop: -2 }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>

              {licenseCategory.map((item: any, index: number) => (
                <Grow in timeout={500} key={index}>
                  <Box mb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          label="Categoría de Licencia"
                          select
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="idCategoriaLicencia"
                          value={item.idCategoriaLicencia}
                          onChange={(event: any) =>
                            handleFormChange(
                              index,
                              event,
                              'idCategoriaLicencia'
                            )
                          }
                          required
                        >
                          {loadingTypeLicenseCategory ? (
                            <CircularProgress
                              variant="indeterminate"
                              size={25}
                            />
                          ) : errorTypeLicenseCategory ? (
                            <p>Error</p>
                          ) : (
                            dataTypeLicenseCategory?.getTypeLicenseCategory?.map(
                              (item: any) => (
                                <MenuItem
                                  key={item.idTipoCategoria}
                                  value={item.idTipoCategoria}
                                >
                                  {item.description}
                                </MenuItem>
                              )
                            )
                          )}
                        </TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={ES}
                        >
                          <DatePicker
                            autoOk
                            label="Fecha de Vigencia"
                            format="DD/MM/YYYY"
                            variant="inline"
                            inputVariant="outlined"
                            fullWidth
                            value={item.fechaVigencia}
                            onChange={(event: any) =>
                              handleFormChange(index, event, 'fechaVigencia')
                            }
                            labelFunc={() =>
                              dayjs(item.fechaVigencia).format('DD/MM/YYYY')
                            }
                            TextFieldComponent={renderInput}
                            maxDate={dayjs()}
                            minDate={formikDriver.values.expeditionDate}
                            required
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid container item xs={2} spacing={2}>
                        {licenseCategory.length <= 2 && (
                          <Grid item>
                            <Tooltip title="Agregar Categoría" arrow>
                              <Fab
                                color="default"
                                className={classes.btnAddCategory}
                                onClick={addFields}
                              >
                                <Box
                                  component="i"
                                  fontSize="20px"
                                  className="icon-plus"
                                />
                              </Fab>
                            </Tooltip>
                          </Grid>
                        )}
                        {licenseCategory.length > 1 && (
                          <Grid item>
                            <Tooltip title="Eliminar Categoría" arrow>
                              <Fab
                                className={classes.btnDeleteCategory}
                                onClick={() => removeFields(index)}
                              >
                                <Box
                                  component="i"
                                  fontSize="20px"
                                  className="icon-close"
                                />
                              </Fab>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grow>
              ))}

              <Box marginBottom={3} marginTop={1}>
                <MainTitle fontSize="h6">Datos de Contacto</MainTitle>
              </Box>

              <Box mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PhoneNumber
                      type="landline"
                      names={{
                        indicative: 'indicativePhone',
                        phone: 'phone',
                        ext: 'ext',
                      }}
                      values={{
                        indicative: formikDriver.values.indicativePhone,
                        phone: (formikDriver.values.phone = unmaskPhone(
                          formikDriver.values.phone
                        )),
                      }}
                      onChange={formikDriver.handleChange}
                      errors={{
                        indicative:
                          !!formikDriver.errors.indicativePhone &&
                          !!formikDriver.touched.indicativePhone,
                        phone: formikDriver.errors.phone as string,
                      }}
                      helperTexts={{
                        indicative: formikDriver.errors
                          .indicativePhone as string,
                        phone: formikDriver.errors.phone as string,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PhoneNumber
                      type="mobile"
                      names={{
                        indicative: 'indicativeMobile',
                        phone: 'mobile',
                      }}
                      values={{
                        indicative: formikDriver.values.indicativeMobile,
                        phone: (formikDriver.values.mobile = unmaskPhone(
                          formikDriver.values.mobile
                        )),
                      }}
                      onChange={formikDriver.handleChange}
                      errors={{
                        indicative:
                          !!formikDriver.errors.indicativeMobile &&
                          !!formikDriver.touched.indicativeMobile,
                        phone: formikDriver.errors.mobile as string,
                      }}
                      helperTexts={{
                        indicative: formikDriver.errors
                          .indicativeMobile as string,
                        phone: formikDriver.errors.mobile as string,
                      }}
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={3}>
                <Grid container>
                  <TextField
                    fullWidth
                    label="Correo Electrónico"
                    variant="outlined"
                    size="small"
                    type="email"
                    name="email"
                    value={formikDriver.values.email}
                    onChange={formikDriver.handleChange}
                    error={
                      formikDriver.touched.email &&
                      Boolean(formikDriver.errors.email)
                    }
                    helperText={
                      formikDriver.touched.email && formikDriver.errors.email
                    }
                    required
                  />
                </Grid>
              </Box>

              <Box marginBottom={3} marginTop={1}>
                <MainTitle fontSize="h6">Datos de Ubicación</MainTitle>
              </Box>
            </Grid>

            <Direction
              names={{
                directionType: 'directionType',
                viaType: 'viaType',
                viaNumber: 'viaNumber',
                apendNumber: 'apendNumber',
                cruceNumber: 'cruceNumber',
                apendCruceNumber: 'apendCruceNumber',
                cornerMeters: 'cornerMeters',
                aditional: 'aditional',
                postalCode: 'postalCode',
                idPais: 'country',
                idDepartamento: 'departament',
                city: 'city',
              }}
              values={{
                directionType: formikDriver.values.directionType,
                viaType: formikDriver.values.viaType,
                viaNumber: formikDriver.values.viaNumber,
                apendNumber: formikDriver.values.apendNumber,
                cruceNumber: formikDriver.values.cruceNumber,
                apendCruceNumber: formikDriver.values.apendCruceNumber,
                cornerMeters: formikDriver.values.cornerMeters,
                aditional: formikDriver.values.aditional,
                postalCode: formikDriver.values.postalCode,
                idPais: Number(formikDriver.values.country),
                idDepartamento: Number(formikDriver.values.departament),
                city: Number(formikDriver.values.city),
              }}
              errors={{
                directionType: formikDriver.errors.directionType,
                viaType: formikDriver.errors.viaType,
                viaNumber: formikDriver.errors.viaNumber,
                apendNumber: formikDriver.errors.apendNumber,
                cruceNumber: formikDriver.errors.cruceNumber,
                apendCruceNumber: formikDriver.errors.apendCruceNumber,
                cornerMeters: formikDriver.errors.cornerMeters,
                aditional: formikDriver.errors.aditional,
                postalCode: formikDriver.errors.postalCode,
                idPais: formikDriver.errors.country as string,
                idDepartamento: formikDriver.errors.departament as string,
                city: formikDriver.errors.city as string,
              }}
              setFieldValue={formikDriver.setFieldValue}
              handleChange={formikDriver.handleChange}
              handleBlur={formikDriver.handleBlur}
              touched={formikDriver.touched}
            />

            <Grid component={Box} container justify="center" mt={3}>
              <Box mr={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Volver
                </Button>
              </Box>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                endIcon={
                  loadingPutDriver && (
                    <CircularProgress
                      className={classes.loadingColor}
                      thickness={5}
                      size={20}
                    />
                  )
                }
                disabled={
                  JSON.stringify(initialData) === JSON.stringify(dataUpdate)
                }
              >
                {loadingPutDriver ? 'Actualizando...' : 'Actualizar'}
              </Button>
            </Grid>
          </form>
        </Grow>
      </ContentCard>
    </>
  );
};
export default DetailDriver;
