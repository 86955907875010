import React from "react";

import {
  Box,
  FormControlLabel,
  Typography,
  Checkbox,
  Grid,
} from "@material-ui/core";

import useStyles from "./styles";

interface CardSelectProps {
  name?: any;
  title?: string;
  description?: string;
  icon?: string;
  isCheck: boolean;
  value: any;
  onchange: any;
}

const CardSelectType = ({
  name,
  title,
  description,
  icon,
  isCheck,
  value,
  onchange,
}: CardSelectProps) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.card}>
        <FormControlLabel
          name={name}
          label={
            <Typography
              className={classes.subtitle}
              color="secondary"
              variant="subtitle1"
            >
              {title}
              <i className={icon} style={{ fontSize: 30, marginLeft: 4 }} />
            </Typography>
          }
          control={
            <Checkbox
              checked={isCheck}
              color="primary"
              onChange={onchange}
              name="idChecked"
              value={value}
            />
          }
        />
        <Typography color="secondary">{description}</Typography>
      </Box>
    </>
  );
};

export default CardSelectType;
