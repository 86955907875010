import { gql } from "apollo-boost";

export const POSITIONS = gql`
  query {
    positions{
      id
      description
      area{
        id
        description
      }
    }
  }
`;
