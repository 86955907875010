import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import ItemOutlinedInfo from 'components/ItemOutlinedInfo';
import ItemTotalInfo from 'components/ItemTotalInfo';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';

type DialogArrivalProps = {
  open: boolean;
  handleClose: () => void;
};

const datos2 = [
  {
    title: 'Fecha Remisión',
    data: '22/01/2019 ',
  },
  {
    title: 'Nro. Remisión',
    data: '4321452-01',
  },
  {
    title: 'Nro. Animales Remisionados',
    data: '54',
  },
  {
    title: 'Granja Origen',
    data: 'Nombre de la Granja',
  },
  {
    title: 'Ciudad Origen',
    data: 'Ciudad',
  },
  {
    title: 'Vereda',
    data: 'N.D.',
  },
  {
    title: 'Nombre Granja',
    data: 'Nombre de la Granja',
  },
  {
    title: 'Placa Vehículo',
    data: '543 JKL',
  },
  {
    title: 'Conductor',
    data: 'Juan Fernando Lopez',
  },
];

const DialogArrival = ({ open, handleClose }: DialogArrivalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      className={classes.borderRadiudDialog}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Grid container>
          <Grid item container xs={5} alignItems="center">
            <Avatar className={classes.avatarTitle}>
              <i className="icon-planta-check" style={{ fontSize: '30px' }}></i>
            </Avatar>
            <Box ml={3}>
              <Typography variant="h6">Arribo Sala de Beneficio</Typography>
            </Box>
          </Grid>

          <Grid item xs={7} container justify="flex-end">
            <Grid item container className={classes.divContainerTitle}>
              <Box mr={2}>
                <Typography variant="h5" className={classes.dataTitle}>
                  02:31:40
                </Typography>
              </Box>
              <Typography className={classes.fontTitleInfo}>
                Duración Proceso
              </Typography>
            </Grid>

            <Box ml={3}>
              <Grid item container className={classes.divContainerTitle2}>
                <Box mr={2}>
                  <Typography variant="h5" className={classes.dataTitle}>
                    0123
                  </Typography>
                </Box>
                <Typography className={classes.fontTitleInfo}>
                  Lote InfoPorcinos
                </Typography>
              </Grid>
            </Box>
          </Grid>

          <Grid container className={classes.titleContainer}>
            <Typography className={classes.fontTitleContainer}>
              Resumen del Proceso
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item container xs={6}>
            <Grid item container spacing={2} xs={8}>
              <Grid item className={classes.gridItemContainer}>
                <ItemOutlinedInfo
                  icon="icon-calendar"
                  title="Fecha Llegada Planta"
                  info="22/01/2019 09:00:00"
                />
              </Grid>
              <Grid item className={classes.gridItemContainer}>
                <ItemOutlinedInfo
                  icon="icon-calendar"
                  title="Fecha Ingreso Planta"
                  info="22/01/2019 10:10:00"
                />
              </Grid>
              <Grid item className={classes.gridItemContainer}>
                <ItemOutlinedInfo
                  icon="icon-calendar"
                  title="Fecha Salida Planta"
                  info="22/01/2019 10:30:00"
                />
              </Grid>
            </Grid>

            <Grid item container xs={4} direction="column" justify="center">
              <Grid item container justify="center">
                <div className={classes.iconContainer}>
                  <i
                    className="icon-truck-time"
                    style={{ fontSize: '30px' }}
                  ></i>
                </div>
              </Grid>
              <Grid item container justify="center">
                <Typography className={classes.fontDate}>01:10:00</Typography>
              </Grid>
              <Grid item container justify="center">
                <Typography className={classes.titleIconContainer}>
                  Tiempo Permanencia Vehículo Planta
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={6}>
            <Grid item container className={classes.gridContainer}>
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={54}
                titleInfo="Total Animales Ingresados"
                avatarTotalColor="transparent"
                avatarTotalSize={60}
                classNameLabel={classes.itemInfoLabel}
                titleInfoSize={120}
                avatarTextColor="#DD9835"
                titleInverted
              />
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={54}
                titleInfo="Total Animales Remisionados"
                avatarTotalColor="transparent"
                avatarTotalSize={60}
                classNameLabel={classes.itemInfoLabel}
                titleInfoSize={120}
                avatarTextColor="#DD9835"
                titleInverted
              />
            </Grid>
            <Grid item container className={classes.gridContainerGuide}>
              <Grid item container xs={6} justify="center">
                <Typography className={classes.titleIconContainer}>
                  Guía de Movilización
                </Typography>
              </Grid>
              <Grid item container xs={6} justify="center">
                <Typography className={classes.fontDate}>
                  019-533242432
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item container xs={8} alignItems="center" justify="center">
                <LibraryBooksOutlinedIcon className={classes.icon} />
                <Typography className={classes.titleIconContainer}>
                  Estado de la Documentación
                </Typography>
              </Grid>
              <Grid xs={4} container alignItems="center" justify="center">
                <Chip className={classes.chip} label="Completa" size="small" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.titleContainer}>
          <Typography className={classes.fontTitleContainer}>
            Datos de la Remisión
          </Typography>
        </Grid>

        <Grid container>
          {datos2.map((item, index) => (
            <Grid item container xs={6}>
              <Grid
                item
                container
                xs={7}
                className={classes.fontTitleGridContainer}
              >
                <Typography className={classes.fontTitle}>
                  {item.title}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={5}
                justify="flex-end"
                className={classes.fontSubtitleGridContainer}
              >
                <Typography className={classes.fontSubtitle}>
                  {item.data}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            className="raleway"
            onClick={handleClose}
            color="primary"
          >
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DialogArrival;
