import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  backgroundweigth: {
    backgroundColor: '#ededed',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    fontSize: 15
  },
  weigthanimals: {
    fontSize: 7,
    fontFamily: 'Roboto',
    marginTop: 4
  },
})