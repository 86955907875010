import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Grid,
  Card,
  CardActionArea,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import { WhatsApp, Telegram, ExpandMore } from "@material-ui/icons";

import MainTitle from "components/MainTitle";
import DocumentTypes from "components/DocumentTypes";
import TextFieldNumber from "components/TextFieldNumber";
import Direction from "components/Direction";
import PhoneNumber from "components/PhoneNumber";
import Slider from "components/Slider";
import ExistingUser from "components/ExistingUser";
import GobackLink from "components/GobackLink";
import SchedulePicker from "components/Schedule";
import ExistingDestiny from "components/PageComponents/Profile/PointOfSale/ExistingDestiny";

import constants from "config/constants";
import unmaskPhone from "lib/helpers/unmaskPhone";
import {
  DESTINATION_MANAGER,
  SEARCH_DESTINATION_BY_NIT,
} from "core/graphql/queries/profile";
import { REGISTER_DESTINY } from "core/graphql/mutations/profile";
import { useSnackbar } from "notistack";
import { DestinyRegister } from "lib/validations/Profile";
import calculateCodVerification from "lib/helpers/calculateCodVerification";

import { useFormik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";

import useStyles from "./styles";

const CreateDestiny = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const path = useLocation();
  let params: any = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectUser, setSelectUser] = useState(false);
  const [select, setSelect] = useState(0);
  const typeRegister = Number(params.type);
  const [valuesHorarios, setValuesHorarios] = useState<any>([]);
  const [valuesRestricciones, setValuesRestricciones] = useState<any>([]);
  const [nit, setNit] = useState("");
  const [exist, setExist] = useState(0);
  const [open, setOpen] = useState(false);

  const type = () => {
    switch (typeRegister) {
      case 1:
        return "Punto de Venta";
      case 2:
        return "Planta de Desposte";
      case 3:
        return "Planta de Acondicionamiento";
      default:
        return "";
    }
  };

  const { data, loading, refetch } = useQuery(DESTINATION_MANAGER, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idPersonaResponsable: 0 },
  });
  const { data: dataNit } = useQuery(SEARCH_DESTINATION_BY_NIT, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { nit },
  });

  useEffect(() => {
    refetch();
  }, [path]);

  const [registerDestiny, { loading: loadingRegister }] =
    useMutation(REGISTER_DESTINY);

  const handleChangeSelect = (i: number) => {
    setSelect(i);
    formik.setFieldValue("idResponsable", i);
  };

  const handleChangeForm = () => {
    setSelectUser(!selectUser);
    if (selectUser) formik.setFieldValue("idResponsable", 0);
    else formik.setFieldValue("idResponsable", select);
  };

  useEffect(() => {
    const atencion = valuesHorarios.map((x: any) => {
      return {
        ...x,
        horaInicial: dayjs(x.horaInicial).format("HH:mm:ssZ"),
        horaFinal: dayjs(x.horaFinal).format("HH:mm:ssZ"),
      };
    });
    const restriccion = valuesRestricciones.map((x: any) => {
      return {
        ...x,
        horaInicial: dayjs(x.horaInicial).format("HH:mm:ssZ"),
        horaFinal: dayjs(x.horaFinal).format("HH:mm:ssZ"),
      };
    });
    if ([...atencion, ...restriccion].length > 0) {
      formik.setFieldValue("schedule", [...atencion, ...restriccion]);
    }
  }, [valuesHorarios, valuesRestricciones]);

  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      nit: "",
      digitoVerificacion: 0,
      idResponsable: 0,
      idDestinyType: typeRegister,
      firstName: "",
      secondName: "",
      firstLastname: "",
      secondLastname: "",
      idDocument: 1,
      documentNumber: 0,
      email: "",
      indicativePhone: constants.DEFAULT_DEPARTMENT_ID,
      phone: "",
      extension: "",
      indicativeMobile: constants.DEFAULT_COUNTRY_ID,
      cellphone: "",
      whatsappIndicative: constants.DEFAULT_COUNTRY_ID,
      whatsapp: "",
      telegramIndicative: constants.DEFAULT_COUNTRY_ID,
      telegram: "",
      destinyEmail: "",
      typeDirection: 1,
      idCountry: constants.DEFAULT_COUNTRY_ID,
      idDeparment: constants.DEFAULT_DEPARTMENT_ID,
      idCity: constants.DEFAULT_CITY_ID,
      viaType: "",
      viaNumber: "",
      viaApend: "",
      cruceNumber: "",
      cruceApend: "",
      cornerMeters: "",
      aditional: "",
      postalCode: "",
      latitud: "",
      longitud: "",
      direction: "",
      stratum: 0,
      coords: 1,
      schedule: [],
    },
    validationSchema: DestinyRegister,
    onSubmit: (values: any) => {
      if (exist === 1) {
        setOpen(true);
      } else {
        const coords = values.coords;
        const directionType = values.typeDirection;
        registerDestiny({
          variables: {
            ...values,
            idCity: Number(values.idCity),
            typeDirection: Number(values.typeDirection),
            viaType:
              coords === 1 ? (directionType === 1 ? values.viaType : 1) : 1,
            viaNumber:
              coords === 1 ? (directionType === 1 ? values.viaNumber : 0) : 0,
            apendNumber:
              coords === 1 ? (directionType === 1 ? values.viaApend : "") : "",
            cruceNumber:
              coords === 1 ? (directionType === 1 ? values.cruceNumber : 0) : 0,
            apendCruceNumber:
              coords === 1
                ? directionType === 1
                  ? values.cruceApend
                  : ""
                : "",
            cornerMeters:
              coords === 1
                ? directionType === 1
                  ? values.cornerMeters
                  : 0
                : 0,
            adicional: values.aditional,
            codigoPostal:
              coords === 1
                ? directionType === 1
                  ? values.postalCode
                  : ""
                : "",
            indicativePhone: Number(values.indicativePhone),
            indicativeMobile: Number(values.indicativeMobile),
            whatsappIndicative:
              values.whatsapp != "" ? Number(values.whatsappIndicative) : 0,
            telegramIndicative:
              values.whatsapp != "" ? Number(values.telegramIndicative) : 0,
            schedule: values.schedule
              .filter((item: any) => item.id)
              .filter((items: any) => delete items.id),
          },
          context: { headers: { "x-auth-jwt": token } },
        })
          .then((data) => {
            enqueueSnackbar(`${type()} se registró exitosamente`, {
              variant: "success",
              autoHideDuration: 5000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
            });
            history.goBack();
          })
          .catch((error) => {
            enqueueSnackbar(`${type()} no se pudo registrar`, {
              variant: "error",
              autoHideDuration: 5000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
            });
          });
      }
    },
  });

  useEffect(() => {
    setExist(dataNit?.validateDestination?.data?.success);
    setNit(formik.values.nit);
  }, [formik.values.nit, dataNit]);

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <GobackLink arrowMode />
        <Typography variant="h6" className={classes.title}>
          Registro {type()}
        </Typography>
      </Box>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <Box marginY={2}>
          <Box marginY={2}>
            <MainTitle>Datos Básicos {type()}</MainTitle>
            <Box marginY={2}>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    label={`Código ${type()}`}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.code}
                    name="code"
                    helperText={formik.errors.code}
                    error={!!formik.errors.code}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={`Nombre ${type()}`}
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    name="name"
                    helperText={formik.errors.name}
                    error={!!formik.errors.name}
                    required
                  />
                </Grid>
                {typeRegister === 2 || typeRegister === 3 ? (
                  <>
                    <Grid item xs={2}>
                      <ExistingDestiny
                        data={dataNit?.validateDestination?.data?.data}
                        open={open}
                        onClose={() => setOpen(false)}
                      />

                      <TextField
                        label="NIT"
                        required
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="nit"
                        value={formik.values.nit}
                        onChange={formik.handleChange}
                        helperText={formik.errors.nit}
                        error={Boolean(formik.errors.nit)}
                        onBlur={() =>
                          exist === 1 ? setOpen(true) : setOpen(false)
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        label="Dig. Verificación"
                        variant="outlined"
                        size="small"
                        type="number"
                        name="digitoVerificacion"
                        value={
                          (formik.values.digitoVerificacion = formik.values.nit
                            ? calculateCodVerification(
                                Number(formik.values.nit)
                              )
                            : "")
                        }
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.digitoVerificacion)}
                        helperText={formik.errors.digitoVerificacion}
                        required
                        disabled
                      />
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={4}>
                  <TextField
                    label="Correo Electrónico"
                    required
                    size="small"
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.destinyEmail}
                    name="destinyEmail"
                    helperText={formik.errors.destinyEmail}
                    error={!!formik.errors.destinyEmail}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginY={2}
            >
              <MainTitle>Datos Responsable {type()}</MainTitle>
              {data ? (
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleChangeForm}
                >
                  {selectUser ? "Registrar Nuevo" : "Seleccionar Existente"}
                </Button>
              ) : null}
            </Box>
            <Box marginY={2}>
              {data && selectUser ? (
                <Slider>
                  {data?.getDestinationManagerXUser?.data?.map((i: any) => (
                    <ExistingUser
                      name={i?.nombreCompleto}
                      info={i?.numeroDocumento}
                      selected={select === i?.idPersonaResponsable}
                      onClick={() =>
                        handleChangeSelect(i?.idPersonaResponsable)
                      }
                      column
                    />
                  ))}
                  <Grid>
                    <Card
                      className={classes.card}
                      onClick={() => setSelectUser(false)}
                    >
                      <CardActionArea className={classes.content}>
                        <i className="icon-plus" />
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Slider>
              ) : (
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      label="Primer Nombre"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      name="firstName"
                      helperText={formik.errors.firstName}
                      error={!!formik.errors.firstName}
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Segundo Nombre"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.secondName}
                      name="secondName"
                      helperText={formik.errors.secondName}
                      error={!!formik.errors.secondName}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Primer Apellido"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.firstLastname}
                      name="firstLastname"
                      helperText={formik.errors.firstLastname}
                      error={!!formik.errors.firstLastname}
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Segundo Apellido"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.secondLastname}
                      name="secondLastname"
                      helperText={formik.errors.secondLastname}
                      error={!!formik.errors.secondLastname}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DocumentTypes
                      entity={1}
                      name="idDocument"
                      helperText={formik.errors.idDocument as string}
                      errors={formik.errors.idDocument as string}
                      value={Number(formik.values.idDocument)}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFieldNumber
                      label="Nro. Documento"
                      required
                      onChange={formik.handleChange}
                      name="documentNumber"
                      value={formik.values.documentNumber}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Correo Electrónico"
                      size="small"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      name="email"
                      helperText={formik.errors.email}
                      error={!!formik.errors.email}
                      required
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>

          <Box marginY={2}>
            <MainTitle>Datos de Contacto {type()}</MainTitle>
            <Box marginY={2}>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={6} xl={4}>
                  <PhoneNumber
                    type="mobile"
                    names={{
                      indicative: "indicativeMobile",
                      phone: "cellphone",
                    }}
                    values={{
                      indicative: formik.values.indicativeMobile,
                      phone: (formik.values.cellphone = unmaskPhone(
                        formik.values.cellphone
                      )),
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative: !!formik.errors.indicativeMobile,
                      phone: formik.errors.cellphone as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.indicativeMobile as string,
                      phone: formik.errors.cellphone as string,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6} xl={4}>
                  <PhoneNumber
                    type="landline"
                    names={{
                      indicative: "indicativePhone",
                      phone: "phone",
                      ext: "extension",
                    }}
                    values={{
                      indicative: formik.values.indicativePhone,
                      phone: Number(formik.values.phone),
                      ext: formik.values.extension,
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative: !!formik.errors.indicativePhone,
                      phone: formik.errors.phone as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.indicativePhone as string,
                      phone: formik.errors.phone as string,
                    }}
                    extension
                  />
                </Grid>
                <Grid item xs={6} xl={4}>
                  <PhoneNumber
                    labelIndicative="Ind. WhatsApp"
                    labelInput="WhatsApp"
                    type="mobile"
                    names={{
                      indicative: "whatsappIndicative",
                      phone: "whatsapp",
                    }}
                    values={{
                      indicative: formik.values.whatsappIndicative,
                      phone: (formik.values.whatsapp = unmaskPhone(
                        formik.values.whatsapp
                      )),
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative: formik.errors.whatsappIndicative,
                      phone: formik.errors.whatsapp as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.whatsappIndicative as string,
                      phone: formik.errors.whatsapp as string,
                    }}
                    icon={<WhatsApp />}
                  />
                </Grid>
                <Grid item xs={6} xl={4}>
                  <PhoneNumber
                    labelInput="Telegram"
                    labelIndicative="Ind. Telegram"
                    type="mobile"
                    names={{
                      indicative: "telegramIndicative",
                      phone: "telegram",
                    }}
                    values={{
                      indicative: formik.values.telegramIndicative,
                      phone: (formik.values.telegram = unmaskPhone(
                        formik.values.telegram
                      )),
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative: !!formik.errors.telegramIndicative,
                      phone: formik.errors.telegram as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.telegramIndicative as string,
                      phone: formik.errors.telegram as string,
                    }}
                    icon={<Telegram />}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box marginY={2}>
            <MainTitle>Datos Ubicación {type()}</MainTitle>
            <Box marginY={2}>
              <Grid item xs={12}>
                <Direction
                  withCoordinates
                  names={{
                    directionType: "typeDirection",
                    viaType: "viaType",
                    viaNumber: "viaNumber",
                    apendNumber: "viaApend",
                    cruceNumber: "cruceNumber",
                    apendCruceNumber: "cruceApend",
                    cornerMeters: "cornerMeters",
                    aditional: "aditional",
                    postalCode: "postalCode",
                    latitude: "latitud",
                    longitude: "longitud",
                    idPais: "idCountry",
                    idDepartamento: "idDeparment",
                    city: "idCity",
                    direction: "direction",
                    coords: "coords",
                  }}
                  values={{
                    directionType: formik.values.typeDirection,
                    viaType: formik.values.viaType,
                    viaNumber: formik.values.viaNumber,
                    apendNumber: formik.values.viaApend,
                    cruceNumber: formik.values.cruceNumber,
                    apendCruceNumber: formik.values.cruceApend,
                    cornerMeters: formik.values.cornerMeters,
                    aditional: formik.values.aditional,
                    postalCode: formik.values.postalCode,
                    latitude: formik.values.latitud,
                    longitude: formik.values.longitud,
                    idPais: Number(formik.values.idCountry),
                    idDepartamento: Number(formik.values.idDeparment),
                    city: Number(formik.values.idCity),
                    direction: formik.values.direction,
                    coords: formik.values.coords,
                  }}
                  errors={{
                    directionType: formik.errors.typeDirection as string,
                    viaType: formik.errors.viaType as string,
                    viaNumber: formik.errors.viaNumber as string,
                    apendNumber: formik.errors.viaApend as string,
                    cruceNumber: formik.errors.cruceNumber as string,
                    apendCruceNumber: formik.errors.cruceApend as string,
                    cornerMeters: formik.errors.cornerMeters as string,
                    aditional: formik.errors.aditional as string,
                    postalCode: formik.errors.postalCode as string,
                    latitude: formik.errors.latitud as string,
                    longitude: formik.errors.longitud as string,
                    idPais: formik.errors.idCountry as string,
                    idDepartamento: formik.errors.idDeparment as string,
                    city: formik.errors.idCity as string,
                    direction: formik.errors.direction as string,
                    coords: formik.errors.coords as string,
                  }}
                  setFieldValue={formik.setFieldValue}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  touched={formik.touched}
                />
              </Grid>
            </Box>
          </Box>

          <Box marginY={2}>
            <MainTitle>Horarios {type()}</MainTitle>
            <Box marginY={2}>
              <Accordion className={classes.accordionContainer} defaultExpanded>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ExpandMore />}
                >
                  <Typography>{`Horario de Atención ${type()}`}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <SchedulePicker type={1}>
                    {({ allValues }: any) => {
                      setValuesHorarios(allValues);
                    }}
                  </SchedulePicker>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordionContainer}>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ExpandMore />}
                >
                  <Typography>Horario de Cargue / Descargue</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <SchedulePicker type={2}>
                    {({ allValues }: any) => {
                      setValuesRestricciones(allValues);
                    }}
                  </SchedulePicker>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>

          <Box className={classes.stepperButtons}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={
                loadingRegister && (
                  <CircularProgress style={{ color: "#FFF" }} size={20} />
                )
              }
            >
              {loadingRegister ? "Guardando" : "Guardar"}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default CreateDestiny;
