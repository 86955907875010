import React, { useState, useEffect } from 'react';
import { Typography, Button, Grid, Tooltip, Fab, Box } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import domtoimage from 'dom-to-image';
import dayjs from 'dayjs';

import TableFooter from 'components/datagrid-custom-components/TableFooter';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import DialogPDFViewer from 'components/DialogPDFViewer/index';
import LevelIndicator from 'components/LevelIndicator';
import ContentCard from 'components/ContentCard';
import GobackLink from 'components/GobackLink';

import { ReportDate, MyDocCavaDate } from './../Reports/ReportDate';

import useStyles from './styles';

const Search = () => {
  const classes = useStyles();
  const url = new URLSearchParams(window.location.search);
  const [type] = useState(url.get('type'));
  const [openReportDate, setOpenReportDate] = useState(false);
  const [imageQr, setImageQr] = useState('a');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenqrcavadate');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalReportDate = () => {
    setOpenReportDate(true);
  };

  useEffect(() => {
    generateScreenshot();
  }, []);

  const getDateFilter = () => {
    switch (type) {
      case 'lote':
        return url.get('code');
      case 'today':
        return dayjs().format('DD/MM/YYYY');
      default:
        return 'El tipo de filtro solicitado no existe';
    }
  };

  const getInfoFilter = () => {
    switch (type) {
      case 'lote':
        return (
          <>
            <Typography className={classes.filterType}>Lote:</Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{url.get('code')}</span>
            </Typography>
          </>
        );
      case 'today':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">
                {dayjs().format('dddd, D MMMM YYYY')} (Hoy)
              </span>
            </Typography>
          </>
        );
      default:
        return (
          <Typography className={classes.filterType}>
            El tipo de filtro solicitado no existe
          </Typography>
        );
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'canalesLote',
      headerName: 'Total Canales',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'weight',
      headerName: 'Peso Total (kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'mc',
      headerName: 'Prom. MC (%)',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'lm',
      headerName: 'Prom. LM (m.m)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'gd',
      headerName: 'Prom. GD (m.m)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'nroCava',
      headerName: 'Nro. Cava',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'canvasHours',
      headerName: 'Horas en Canvas',
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <div style={{ margin: 'auto' }}>
          <LevelIndicator
            value={11}
            levels={[[0, 12], [12, 24], [24, 36], [36]]}
          />
        </div>
      ),
    },
    {
      field: '',
      headerName: '',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/inventory/cava/details/lote/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      loteIP: '6543',
      canalesLote: '50',
      weight: '4.224,00',
      pcc: '123,00',
      mc: '53,2',
      lm: '64,2',
      gd: '64,2',
      nroCava: 'C12',
    },
    {
      id: 2,
      loteIP: '6543',
      canalesLote: '48',
      weight: '4.224,00',
      pcc: '123,00',
      mc: '53,2',
      lm: '64,2',
      gd: '64,2',
      nroCava: 'C12',
    },
    {
      id: 3,
      loteIP: '6543',
      canalesLote: '48',
      weight: '4.224,00',
      pcc: '123,00',
      mc: '53,2',
      lm: '64,2',
      gd: '64,2',
      nroCava: 'C12',
    },
    {
      id: 4,
      loteIP: '6543',
      canalesLote: '48',
      weight: '4.224,00',
      pcc: '123,00',
      mc: '53,2',
      lm: '64,2',
      gd: '64,2',
      nroCava: 'C12',
    },
    {
      id: 5,
      loteIP: '6543',
      canalesLote: '48',
      weight: '4.224,00',
      pcc: '123,00',
      mc: '53,2',
      lm: '64,2',
      gd: '64,2',
      nroCava: 'C12',
    },
    {
      id: 6,
      loteIP: '6543',
      canalesLote: '48',
      weight: '4.224,00',
      pcc: '123,00',
      mc: '53,2',
      lm: '64,2',
      gd: '64,2',
      nroCava: 'C12',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/inventory/cava/search', title: 'Inventario Cavas' },
  ];

  return (
    <>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportDate />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <Grid container>
        <ContentCard className={classes.cardContainer}>
          <Grid item container>
            <Grid item container>
              <Box mr={5}>
                <Typography className={classes.title}>Búsqueda</Typography>
              </Box>
              <Button
                variant="contained"
                className="raleway"
                color="primary"
                onClick={openModalReportDate}
              >
                Generar Reporte
              </Button>
            </Grid>
          </Grid>
          <Box borderBottom={1} mt={3} mb={3} className={classes.colorHr}>
            <Grid item container>
              <Grid item container xs={6} alignItems="flex-end">
                {getInfoFilter()}
              </Grid>
              <Grid
                item
                container
                xs={6}
                justify="flex-end"
                alignItems="center"
              >
                <Box
                  component="i"
                  className="icon-document"
                  fontSize={25}
                  color="#767676"
                />
                <Typography className={classes.result}>
                  09 Resultados
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box paddingLeft={3} paddingRight={3}>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              className={classes.dataGrid}
              components={{
                Footer: TableFooter,
              }}
            />
          </Box>
        </ContentCard>
      </Grid>
      <DialogPDFViewer
        open={openReportDate}
        onClose={() => setOpenReportDate(false)}
        done={Boolean(imageQr)}
      >
        <MyDocCavaDate qr={imageQr} fecha={getDateFilter()} />
      </DialogPDFViewer>
    </>
  );
};

export default Search;
