import React, { useEffect, useState } from 'react';
import { StyledSegment, Title, Stadistics } from './styles';
import { Header } from 'semantic-ui-react';
import { Chip } from '@material-ui/core';

export interface ContentCardProps {
  children: React.ReactNode;
  className?: String;
  marginTop?: number | string;
  padding?: number | string;
  style?: Object;
}
const ContentCard = ({
  children,
  className,
  padding,
  marginTop,
  style = {},
  ...props
}: ContentCardProps) => (
  <StyledSegment
    className={className}
    marginTop={marginTop}
    padding={padding}
    style={style}
    {...props}
  >
    {children}
  </StyledSegment>
);

interface ContentCardHeaderProps {
  children: React.ReactNode;
  date?: string | Date;
}

ContentCard.Header = ({ children, date }: ContentCardHeaderProps) => {
  const [datePrint, setDatePrint] = useState<string | Date | undefined>(date);

  useEffect(() => {
    if (date) {
      const d = new Date();
      setDatePrint(`${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`);
    }
  }, [date]);

  return (
    <Title>
      <Header>{children}</Header>
      {typeof date === 'object' && (
        <Chip
          size="small"
          color="primary"
          variant="outlined"
          label={datePrint}
        />
      )}
      {typeof date === 'string' && (
        <Chip size="small" color="primary" variant="outlined" label={date} />
      )}
    </Title>
  );
};

ContentCard.Stadistics = ({
  mainFact,
  secondaryFact,
}: {
  mainFact: any;
  secondaryFact: string;
}) => (
  <Stadistics>
    <h1>{mainFact}</h1>
    <p>{secondaryFact}</p>
  </Stadistics>
);

ContentCard.Content = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);

export default ContentCard;
