import React, { useState, useEffect } from 'react';

import {
  TextField,
  Box,
  Grid,
  Button,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import { WhatsApp, Telegram } from '@material-ui/icons';

import MainTitle from 'components/MainTitle';
import PhoneNumber from 'components/PhoneNumber';
import DocumentType from 'components/DocumentTypes';
import Positions from 'components/Positions';
import Direction from 'components/Direction';
import ExistingUser from 'components/ExistingUser';

import { UPDATE_PROFILE_DATA } from 'core/graphql/mutations/profile';
import { EXISTING_MANAGER } from 'core/graphql/queries/profile';
import { GeneralDataCompany, GeneralDataPerson } from 'lib/validations/Profile';
import unmaskPhone from 'lib/helpers/unmaskPhone';
import documentTypeById from 'lib/helpers/documentTypeById';
import { useSelector } from 'react-redux';

import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

import useStyles from './styles';
import Slider from 'components/Slider';

const Edit = ({ data }: any) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const token = useSelector((state: any) => state.user.token);
  const entity = useSelector((state: any) => state.user.user.typeEntity);
  const path = useLocation();
  const [form, setForm] = useState(0);
  const [select, setSelect] = useState(data?.idRepresentanteLegal);

  const [updateData, { loading }] = useMutation(UPDATE_PROFILE_DATA);

  const {
    data: dataUsers,
    loading: loadingUsers,
    refetch,
  } = useQuery(EXISTING_MANAGER, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const handleChangeForm = (type: number) => {
    setForm(type);
    if (type === 0) {
      formik.setFieldValue('idRepresentanteLegal', data?.idRepresentanteLegal);
      formik.setFieldValue('primerNombreRL', data?.primerNombreRL);
      formik.setFieldValue('segundoNombreRL', data?.segundoNombreRL);
      formik.setFieldValue('primerApellidoRL', data?.primerApellidoRL);
      formik.setFieldValue('segundoApellidoRL', data?.segundoApellidoRL);
      formik.setFieldValue('numeroDocumentoRL', data?.numeroDocumentoRL);
      formik.setFieldValue('emailRL', data?.emailRL);
      formik.setFieldValue('celularRL', data?.celularRL);
    } else if (type === 1) {
      formik.setFieldValue('idRepresentanteLegal', 0);
      formik.setFieldValue('primerNombreRL', '');
      formik.setFieldValue('segundoNombreRL', '');
      formik.setFieldValue('primerApellidoRL', '');
      formik.setFieldValue('segundoApellidoRL', '');
      formik.setFieldValue('numeroDocumentoRL', '');
      formik.setFieldValue('emailRL', '');
      formik.setFieldValue('celularRL', '');
    } else {
      formik.setFieldValue('idRepresentanteLegal', select);
      formik.setFieldValue('primerNombreRL', data?.primerNombreRL);
      formik.setFieldValue('segundoNombreRL', data?.segundoNombreRL);
      formik.setFieldValue('primerApellidoRL', data?.primerApellidoRL);
      formik.setFieldValue('segundoApellidoRL', data?.segundoApellidoRL);
      formik.setFieldValue('numeroDocumentoRL', data?.numeroDocumentoRL);
      formik.setFieldValue('emailRL', data?.emailRL);
      formik.setFieldValue('celularRL', data?.celularRL);
    }
  };

  useEffect(() => {
    refetch();
  }, [path]);

  const handleChangeSelect = (i: number) => {
    setSelect(i);
    formik.setFieldValue('idRepresentanteLegal', i);
  };

  const formik = useFormik({
    initialValues: {
      ...data,
      metrosEsquina: Number(data?.metrosEsquina),
      numeroVia: Number(data?.numeroVia),
      numeroCruce: Number(data?.numeroCruce),
    },
    enableReinitialize: true,
    validationSchema:
      entity === 'person' ? GeneralDataPerson : GeneralDataCompany,
    onSubmit: (values) => {
      const value = Number(values.idTipoDireccion);
      const idRL = values.idRepresentanteLegal;
      updateData({
        variables: {
          ...values,
          apendiceCruce: value === 1 ? values.apendiceCruce : '',
          apendiceVia: value === 1 ? values.apendiceVia : '',
          idTipoVia: value === 1 ? values.idTipoVia : 1,
          metrosEsquina: value === 1 ? `${values.metrosEsquina}` : '',
          numeroCruce: value === 1 ? `${values.numeroCruce}` : '',
          numeroVia: value === 1 ? `${values.numeroVia}` : '',
          indicativoTelefono: Number(values.idIndicativoTelefono),
          indicativoCelular: Number(values.idIndicativoCelular),
          indicativoCelularRL: Number(values.idIndicativoCelularRL),
          indicativoWhatsapp: Number(values.idIndicativoWhatsapp),
          indicativoTelegram: Number(values.idIndicativoTelegram),
          whatsapp: values.whatsapp,
          idCiudad: Number(values.idCiudad),
          usuarioFacebook: '',
          idTipoDireccion: Number(values.idTipoDireccion),
          numeroDocumentoRL:
            idRL === 0
              ? values.numeroDocumentoRL
              : idRL !== data?.idRepresentanteLegal
              ? ''
              : values.numeroDocumentoRL,
          emailRL:
            idRL === 0
              ? values.emailRL
              : idRL !== data?.idRepresentanteLegal
              ? ''
              : values.emailRL,
          celularRL:
            idRL === 0
              ? values.celularRL
              : idRL !== data?.idRepresentanteLegal
              ? ''
              : values.celularRL,
        },
        context: { headers: { 'x-auth-jwt': token } },
      })
        .then((response) => {
          if (response?.data?.putGeneralDataProfile?.data?.success === 1) {
            enqueueSnackbar('Datos actualizados correctamente.', {
              variant: 'success',
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            });
          } else {
            enqueueSnackbar(
              `${response?.data?.putGeneralDataProfile?.data?.message}`,
              {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top',
                },
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.message);
          enqueueSnackbar(
            'Ocurrió un error no se pudieron Actualizar los Datos.',
            {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }
          );
          const message = error.message.split(':');
          const field = message[0];
          const value = message[1];
          formik.setStatus(formik.setErrors({ [field ?? '']: value }));
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box className={classes.container}>
          {entity === 'person' ? (
            <Box>
              <Grid container xs={12}>
                <Box marginTop={2} width="100%">
                  <MainTitle>Datos de Usuario</MainTitle>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className={classes.gridContainer}
                  >
                    <Grid item xs={3}>
                      <TextField
                        label="Usuario"
                        disabled
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.username)}
                        helperText={formik.errors.username}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Correo Electrónico"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.email)}
                        helperText={formik.errors.email}
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Fecha Registro"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={dayjs(data?.fechaRegistroUsuario).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Fecha Última Actualización"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={dayjs(data?.fechaRegistroUsuario).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box marginTop={2} width="100%">
                  <MainTitle>Datos de la Persona</MainTitle>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className={classes.gridContainer}
                  >
                    <Grid item xs={3}>
                      <TextField
                        label="Primer Nombre"
                        required
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.primerNombre}
                        name="primerNombre"
                        helperText={formik.errors.primerNombre}
                        error={!!formik.errors.primerNombre}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Segundo Nombre"
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.segundoNombre}
                        name="segundoNombre"
                        helperText={formik.errors.segundoNombre}
                        error={!!formik.errors.segundoNombre}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Primer Apellido"
                        required
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.primerApellido}
                        name="primerApellido"
                        helperText={formik.errors.primerApellido}
                        error={!!formik.errors.primerApellido}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Segundo Apellido"
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.segundoApellido}
                        name="segundoApellido"
                        helperText={formik.errors.segundoApellido}
                        error={!!formik.errors.segundoApellido}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DocumentType
                        onChange={formik.handleChange}
                        value={formik.values.idTipoDocumento}
                        name="idTipoDocumento"
                        entity={1}
                        disable
                        errors={formik.errors.idTipoDocumento as string}
                        helperText={formik.errors.idTipoDocumento as string}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Número de Documento"
                        required
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.numeroDocumento}
                        name="numeroDocumento"
                        helperText={formik.errors.numeroDocumento}
                        error={!!formik.errors.numeroDocumento}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box marginTop={2} width="100%">
                  <MainTitle>Datos de Ubicación</MainTitle>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className={classes.gridContainer}
                  >
                    <Grid item xs={12}>
                      <Direction
                        names={{
                          directionType: 'idTipoDireccion',
                          viaType: 'idTipoVia',
                          viaNumber: 'numeroVia',
                          apendNumber: 'apendiceVia',
                          cruceNumber: 'numeroCruce',
                          apendCruceNumber: 'apendiceCruce',
                          cornerMeters: 'metrosEsquina',
                          aditional: 'adicional',
                          postalCode: 'codigoPostal',
                          idPais: 'idPais',
                          idDepartamento: 'idDepartamento',
                          city: 'idCiudad',
                        }}
                        values={{
                          directionType: formik.values.idTipoDireccion,
                          viaType: formik.values.idTipoVia,
                          viaNumber: formik.values.numeroVia,
                          apendNumber: formik.values.apendiceVia,
                          cruceNumber: formik.values.numeroCruce,
                          apendCruceNumber: formik.values.apendiceCruce,
                          cornerMeters: formik.values.metrosEsquina,
                          aditional: formik.values.adicional,
                          postalCode: formik.values.codigoPostal,
                          idPais: Number(formik.values.idPais),
                          idDepartamento: Number(formik.values.idDepartamento),
                          city: Number(formik.values.idCiudad),
                        }}
                        errors={{
                          directionType: formik.errors
                            .idTipoDireccion as string,
                          viaType: formik.errors.idTipoVia as string,
                          viaNumber: formik.errors.numeroVia as string,
                          apendNumber: formik.errors.apendiceVia as string,
                          cruceNumber: formik.errors.numeroCruce as string,
                          apendCruceNumber: formik.errors
                            .apendiceCruce as string,
                          cornerMeters: formik.errors.metrosEsquina as string,
                          aditional: formik.errors.adicional as string,
                          postalCode: formik.errors.codigoPostal as string,
                          idPais: formik.errors.idPais as string,
                          idDepartamento: formik.errors
                            .idDepartamento as string,
                          city: formik.errors.idCiudad as string,
                        }}
                        setFieldValue={formik.setFieldValue}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box marginTop={2} width="100%">
                  <MainTitle>Datos Contacto</MainTitle>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className={classes.gridContainer}
                  >
                    <Grid item xs={6} xl={3}>
                      <PhoneNumber
                        type="mobile"
                        names={{
                          indicative: 'idIndicativoCelular',
                          phone: 'celular',
                        }}
                        values={{
                          indicative: formik.values.idIndicativoCelular,
                          phone: (formik.values.celular = unmaskPhone(
                            formik.values.celular
                          )),
                        }}
                        onChange={formik.handleChange}
                        errors={{
                          indicative: !!formik.errors.idIndicativoCelular,
                          phone: formik.errors.celular as string,
                        }}
                        helperTexts={{
                          indicative: formik.errors
                            .idIndicativoCelular as string,
                          phone: formik.errors.celular as string,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} xl={3}>
                      <PhoneNumber
                        type="landline"
                        names={{
                          indicative: 'idIndicativoTelefono',
                          phone: 'telefono',
                        }}
                        values={{
                          indicative: formik.values.idIndicativoTelefono,
                          phone: formik.values.telefono,
                        }}
                        onChange={formik.handleChange}
                        errors={{
                          indicative: formik.errors
                            .idIndicativoTelefono as string,
                          phone: formik.errors.telefono as string,
                        }}
                        helperTexts={{
                          indicative: formik.errors
                            .idIndicativoTelefono as string,
                          phone: formik.errors.telefono as string,
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} xl={3}>
                      <PhoneNumber
                        type="mobile"
                        names={{
                          indicative: 'idIndicativoWhatsapp',
                          phone: 'whatsapp',
                        }}
                        values={{
                          indicative: formik.values.idIndicativoWhatsapp,
                          phone: (formik.values.whatsapp = unmaskPhone(
                            formik.values.whatsapp
                          )),
                        }}
                        onChange={formik.handleChange}
                        errors={{
                          indicative: !!formik.errors.idIndicativoWhatsapp,
                          phone: formik.errors.whatsapp as string,
                        }}
                        helperTexts={{
                          indicative: formik.errors
                            .idIndicativoWhatsapp as string,
                          phone: formik.errors.whatsapp as string,
                        }}
                        icon={<WhatsApp />}
                        labelIndicative="Ind WhatsApp"
                        labelInput="WhatsApp"
                      />
                    </Grid>
                    <Grid item xs={6} xl={3}>
                      <PhoneNumber
                        type="mobile"
                        names={{
                          indicative: 'idIndicativoTelegram',
                          phone: 'telegram',
                        }}
                        values={{
                          indicative: formik.values.idIndicativoTelegram,
                          phone: (formik.values.telegram = unmaskPhone(
                            formik.values.telegram
                          )),
                        }}
                        onChange={formik.handleChange}
                        errors={{
                          indicative: !!formik.errors.idIndicativoTelegram,
                          phone: formik.errors.telegram as string,
                        }}
                        helperTexts={{
                          indicative: formik.errors
                            .idIndicativoTelegram as string,
                          phone: formik.errors.telegram as string,
                        }}
                        icon={<Telegram />}
                        labelIndicative="Ind Telegram"
                        labelInput="Telegram"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Grid container xs={12}>
                <Box marginTop={2} width="100%">
                  <MainTitle>Datos de Usuario</MainTitle>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className={classes.gridContainer}
                  >
                    <Grid item xs={3}>
                      <TextField
                        label="Usuario"
                        disabled
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.username)}
                        helperText={formik.errors.username}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Correo Electrónico"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.email)}
                        helperText={formik.errors.email}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Fecha Registro"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={dayjs(data?.fechaRegistroUsuario).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Fecha Última Actualización"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="email"
                        value={dayjs(data?.fechaRegistroUsuario).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )}
                        disabled
                      />
                    </Grid>{' '}
                  </Grid>
                </Box>
                <Box marginTop={2} width="100%">
                  <MainTitle>Datos de la Empresa</MainTitle>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className={classes.gridContainer}
                  >
                    <Grid item xs={3}>
                      <TextField
                        label="NIT"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        disabled
                        label="Número de documento"
                        required
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={`${formik.values.numeroDocumento} - ${formik.values.digitoVerificacion}`}
                        name="numeroDocumento"
                        helperText={formik.errors.numeroDocumento}
                        error={
                          !!(
                            formik.errors.numeroDocumento &&
                            formik.touched.numeroDocumento
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Razón Social"
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="razonSocial"
                        value={formik.values.razonSocial}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.razonSocial)}
                        helperText={formik.errors.razonSocial}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box marginTop={2} width="100%">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginY={2}
                  >
                    <MainTitle>Datos del Representante Legal</MainTitle>
                    <Box display="flex">
                      {form === 0 ? (
                        <>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => handleChangeForm(1)}
                          >
                            Registrar Nuevo
                          </Button>
                          {dataUsers ? (
                            <>
                              <Divider orientation="vertical" flexItem />
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => handleChangeForm(2)}
                              >
                                Seleccionar Existente
                              </Button>
                            </>
                          ) : loadingUsers ? (
                            <CircularProgress size={20} />
                          ) : null}
                        </>
                      ) : form === 1 ? (
                        <>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => handleChangeForm(0)}
                          >
                            Descartar
                          </Button>
                          {dataUsers ? (
                            <>
                              <Divider orientation="vertical" flexItem />
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => handleChangeForm(2)}
                              >
                                Seleccionar Existente
                              </Button>
                            </>
                          ) : loadingUsers ? (
                            <CircularProgress size={20} />
                          ) : null}
                        </>
                      ) : form === 2 ? (
                        <>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => handleChangeForm(0)}
                          >
                            Descartar
                          </Button>
                          <Divider orientation="vertical" flexItem />
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => handleChangeForm(1)}
                          >
                            Registrar Nuevo
                          </Button>
                        </>
                      ) : null}
                    </Box>
                  </Box>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className={classes.gridContainer}
                  >
                    {form === 0 || form === 1 ? (
                      <>
                        <Grid item xs={3}>
                          <TextField
                            label="Primer Nombre"
                            required
                            size="small"
                            variant="outlined"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.primerNombreRL}
                            name="primerNombreRL"
                            helperText={formik.errors.primerNombreRL}
                            error={!!formik.errors.primerNombreRL}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Segundo Nombre"
                            size="small"
                            variant="outlined"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.segundoNombreRL}
                            name="segundoNombreRL"
                            helperText={formik.errors.segundoNombreRL}
                            error={!!formik.errors.segundoNombreRL}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Primer Apellido"
                            required
                            size="small"
                            variant="outlined"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.primerApellidoRL}
                            name="primerApellidoRL"
                            helperText={formik.errors.primerApellidoRL}
                            error={!!formik.errors.primerApellidoRL}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Segundo Apellido"
                            size="small"
                            variant="outlined"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.segundoApellidoRL}
                            name="segundoApellidoRL"
                            helperText={formik.errors.segundoApellidoRL}
                            error={!!formik.errors.segundoApellidoRL}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <DocumentType
                            onChange={formik.handleChange}
                            value={formik.values.idTipoDocumentoRL}
                            name="idTipoDocumentoRL"
                            entity={1}
                            disable={form === 0}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Número de Documento"
                            required
                            size="small"
                            variant="outlined"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.numeroDocumentoRL}
                            name="numeroDocumentoRL"
                            helperText={formik.errors.numeroDocumentoRL}
                            error={!!formik.errors.numeroDocumentoRL}
                            disabled={form === 0}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Positions
                            onChange={formik.handleChange}
                            value={formik.values.idCargoRL}
                            name="idCargoRL"
                            disable
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Correo Electrónico"
                            size="small"
                            variant="outlined"
                            fullWidth
                            name="emailRL"
                            value={formik.values.emailRL}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors.emailRL)}
                            helperText={formik.errors.emailRL}
                            required
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <PhoneNumber
                            type="mobile"
                            names={{
                              indicative: 'idIndicativoCelularRL',
                              phone: 'celularRL',
                            }}
                            values={{
                              indicative: formik.values.idIndicativoCelularRL,
                              phone: (formik.values.celularRL = unmaskPhone(
                                formik.values.celularRL
                              )),
                            }}
                            onChange={formik.handleChange}
                            errors={{
                              indicative: formik.errors
                                .idIndicativoCelularRL as string,
                              phone: formik.errors.celularRL as string,
                            }}
                            helperTexts={{
                              indicative: formik.errors
                                .idIndicativoCelularRL as string,
                              phone: formik.errors.celularRL as string,
                            }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Slider>
                          {dataUsers?.getManagers?.data?.map((i: any) => (
                            <ExistingUser
                              name={`${i?.primerNombre} ${i?.segundoNombre} ${i?.primerApellido} ${i?.segundoApellido}`}
                              info={`${documentTypeById(
                                i?.idTipoDocumento,
                                1
                              )} - ${i?.numeroDocumento}`}
                              selected={select === i?.idEmpleado}
                              onClick={() => {
                                handleChangeSelect(i?.idEmpleado);
                              }}
                              column
                            />
                          ))}
                        </Slider>
                      </>
                    )}
                  </Grid>
                </Box>

                <Box marginTop={2} width="100%">
                  <MainTitle>Datos de Ubicación</MainTitle>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className={classes.gridContainer}
                  >
                    <Grid item xs={12}>
                      <Direction
                        names={{
                          directionType: 'idTipoDireccion',
                          viaType: 'idTipoVia',
                          viaNumber: 'numeroVia',
                          apendNumber: 'apendiceVia',
                          cruceNumber: 'numeroCruce',
                          apendCruceNumber: 'apendiceCruce',
                          cornerMeters: 'metrosEsquina',
                          aditional: 'adicional',
                          postalCode: 'codigoPostal',
                          idPais: 'idPais',
                          idDepartamento: 'idDepartamento',
                          city: 'idCiudad',
                        }}
                        values={{
                          directionType: formik.values.idTipoDireccion,
                          viaType: formik.values.idTipoVia,
                          viaNumber: formik.values.numeroVia,
                          apendNumber: formik.values.apendiceVia,
                          cruceNumber: formik.values.numeroCruce,
                          apendCruceNumber: formik.values.apendiceCruce,
                          cornerMeters: formik.values.metrosEsquina,
                          aditional: formik.values.adicional,
                          postalCode: formik.values.codigoPostal,
                          idPais: Number(formik.values.idPais),
                          idDepartamento: Number(formik.values.idDepartamento),
                          city: Number(formik.values.idCiudad),
                        }}
                        errors={{
                          directionType: formik.errors
                            .idTipoDireccion as string,
                          viaType: formik.errors.idTipoVia as string,
                          viaNumber: formik.errors.numeroVia as string,
                          apendNumber: formik.errors.apendiceVia as string,
                          cruceNumber: formik.errors.numeroCruce as string,
                          apendCruceNumber: formik.errors
                            .apendiceCruce as string,
                          cornerMeters: formik.errors.metrosEsquina as string,
                          aditional: formik.errors.adicional as string,
                          postalCode: formik.errors.codigoPostal as string,
                          idPais: formik.errors.idPais as string,
                          idDepartamento: formik.errors
                            .idDepartamento as string,
                          city: formik.errors.idCiudad as string,
                        }}
                        setFieldValue={formik.setFieldValue}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box marginTop={2} width="100%">
                  <MainTitle>Datos Contacto Empresa</MainTitle>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className={classes.gridContainer}
                  >
                    <Grid item xs={6} xl={3}>
                      <PhoneNumber
                        type="mobile"
                        names={{
                          indicative: 'idIndicativoCelular',
                          phone: 'celular',
                        }}
                        values={{
                          indicative: formik.values.idIndicativoCelular,
                          phone: (formik.values.celular = unmaskPhone(
                            formik.values.celular
                          )),
                        }}
                        onChange={formik.handleChange}
                        errors={{
                          indicative: !!formik.errors.idIndicativoCelular,
                          phone: formik.errors.celular as string,
                        }}
                        helperTexts={{
                          indicative: formik.errors
                            .idIndicativoCelular as string,
                          phone: formik.errors.celular as string,
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} xl={3}>
                      <PhoneNumber
                        type="landline"
                        names={{
                          indicative: 'idIndicativoTelefono',
                          phone: 'telefono',
                          ext: 'extension',
                        }}
                        values={{
                          indicative: formik.values.idIndicativoTelefono,
                          phone: formik.values.telefono,
                          ext: formik.values.extension,
                        }}
                        onChange={formik.handleChange}
                        errors={{
                          indicative: formik.errors
                            .idIndicativoTelefono as string,
                          phone: formik.errors.telefono as string,
                        }}
                        helperTexts={{
                          indicative: formik.errors
                            .idIndicativoTelefono as string,
                          phone: formik.errors.telefono as string,
                        }}
                        extension
                      />
                    </Grid>

                    <Grid item xs={6} xl={3}>
                      <PhoneNumber
                        type="mobile"
                        names={{
                          indicative: 'idIndicativoWhatsapp',
                          phone: 'whatsapp',
                        }}
                        values={{
                          indicative: formik.values.idIndicativoWhatsapp,
                          phone: (formik.values.whatsapp = unmaskPhone(
                            formik.values.whatsapp
                          )),
                        }}
                        onChange={formik.handleChange}
                        errors={{
                          indicative: !!formik.errors.idIndicativoWhatsapp,
                          phone: formik.errors.whatsapp as string,
                        }}
                        helperTexts={{
                          indicative: formik.errors
                            .idIndicativoWhatsapp as string,
                          phone: formik.errors.whatsapp as string,
                        }}
                        icon={<WhatsApp />}
                        labelIndicative="Ind WhatsApp"
                        labelInput="WhatsApp"
                      />
                    </Grid>
                    <Grid item xs={6} xl={3}>
                      <PhoneNumber
                        type="mobile"
                        names={{
                          indicative: 'idIndicativoTelegram',
                          phone: 'telegram',
                        }}
                        values={{
                          indicative: formik.values.idIndicativoTelegram,
                          phone: (formik.values.telegram = unmaskPhone(
                            formik.values.telegram
                          )),
                        }}
                        onChange={formik.handleChange}
                        errors={{
                          indicative: !!formik.errors.idIndicativoTelegram,
                          phone: formik.errors.telegram as string,
                        }}
                        helperTexts={{
                          indicative: formik.errors
                            .idIndicativoTelegram as string,
                          phone: formik.errors.telegram as string,
                        }}
                        icon={<Telegram />}
                        labelIndicative="Ind Telegram"
                        labelInput="Telegram"
                      />
                    </Grid>

                    <Grid item xs={6} xl={3}>
                      <TextField
                        label="Página Web"
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.paginaWeb}
                        name="paginaWeb"
                        helperText={formik.errors.paginaWeb}
                        error={!!formik.errors.paginaWeb}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          )}
          <Box width="100%" display="flex" justifyContent="center" marginY={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={
                loading && (
                  <CircularProgress style={{ color: '#FFF' }} size={20} />
                )
              }
            >
              {loading ? 'Actualizando' : 'Actualizar'}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default Edit;
