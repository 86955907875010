import { useState } from 'react';
import {
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Avatar,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  ListItem,
  Hidden,
  List,
} from '@material-ui/core';
import Clock from 'react-live-clock';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import MenuIcon from '@material-ui/icons/Menu';

import useNavigation from 'lib/hooks/useNavigation';
import useSignOut from 'lib/hooks/useSignOut';

import Notifications from 'components/Notifications';
import useStyles from './styles';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';

interface ToolbarLayoutProps {
  setOpen?: (value: boolean) => void;
  role: any;
  user: any;
  typeUsers: any[];
  subscriptionType: any;
}

const ToolbarLayout = ({
  setOpen,
  role,
  user,
  typeUsers,
  subscriptionType,
}: ToolbarLayoutProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const { signOut } = useSignOut();
  dayjs.extend(isoWeek);
  dayjs.extend(localizedFormat);

  const { switchNavigation } = useNavigation();

  const handleOpenSwitch = (event: any) => setAnchorMenu(event.currentTarget);
  const handleCloseSwitch = () => setAnchorMenu(null);

  const getIcon = (idUserType?: number) => {
    switch (idUserType) {
      case 1:
        return 'icon-productor';
      case 2:
        return 'icon-comercializador';
      default:
        return 'icon-sync';
    }
  };

  return (
    <>
      <AppBar className={classes.navbar} position="static">
        <Toolbar className={classes.toolbar}>
          <div>
            <Hidden lgUp>
              <IconButton
                onClick={() => setOpen && setOpen(true)}
                edge="start"
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </div>
          <div className={classes.leftItems}>
            <Button
              className={classes.btn}
              startIcon={<i className="icon-clock"></i>}
            >
              <Clock format="hh:mm a" ticking interval={1000} />
            </Button>
            <Button
              className={classes.btn}
              startIcon={<i className="icon-calendar"></i>}
            >
              {dayjs().format('LL')}
            </Button>
            <Button
              className={classes.btn}
              startIcon={<i className="icon-calendar"></i>}
            >
              Semana {dayjs().isoWeek()}
            </Button>
            <div className={classes.iconBtnContainer}>
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </div>

            <div className={classes.iconBtnContainer}>
              <Notifications />
            </div>
            <ListItem
              button
              classes={{ root: classes.roleItem }}
              onClick={handleOpenSwitch}
            >
              <ListItemIcon>
                <Avatar color="primary" className={classes.roleAvatar}>
                  <i className={clsx(getIcon(role.id), classes.roleIcon)}></i>
                </Avatar>
              </ListItemIcon>
              <List className={classes.textTitle}>
                <ListItem className={classes.nameTitle}>
                  {user.bussinesName
                    ? user.bussinesName
                    : `${user.name} ${user.lastName}`}
                </ListItem>
                <ListItem className={classes.others}>
                  {subscriptionType}
                </ListItem>
                <ListItem className={classes.roleTitle}>
                  {`${role.name} - ${role.role}`}
                </ListItem>
              </List>
              {Boolean(anchorMenu) ? (
                <ExpandLess className={classes.lowIcon} />
              ) : (
                <ExpandMore className={classes.lowIcon} />
              )}
            </ListItem>
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        keepMounted
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={handleCloseSwitch}
        className={classes.switcher}
        classes={{ paper: classes.switcherPaper }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
      >
        {typeUsers.length > 1 && (
          <div>
            <MenuList>
              <ListSubheader className={classes.switcherSubheader}>
                Cambio de Usuario
              </ListSubheader>
              {typeUsers
                .filter((type: any) => type.id !== role.id)
                .map((type: any, i: number) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      handleCloseSwitch();
                      history.push('/app/dashboard');
                      switchNavigation(type.idUserType);
                    }}
                  >
                    <ListItemIcon>
                      <Avatar className={classes.roleAvatar}>
                        <i
                          className={clsx(getIcon(type.id), classes.roleIcon)}
                        />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText primary={type.name} secondary={type.role} />
                  </MenuItem>
                ))}
            </MenuList>
            <Divider />
          </div>
        )}
        <ListSubheader className={classes.switcherSubheader}>
          Acciones
        </ListSubheader>
        <MenuItem
          component={Link}
          to="/app/profile"
          className={classes.profileLink}
          onClick={() => handleCloseSwitch()}
        >
          {location.pathname.includes('/app/profile') ? (
            <b>Mi Perfil</b>
          ) : (
            'Mi Perfil'
          )}
        </MenuItem>
        <MenuItem onClick={() => signOut()}>Cerrar Sesión</MenuItem>
      </Menu>
    </>
  );
};

export default ToolbarLayout;
