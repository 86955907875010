import React, { useState } from 'react';

import {} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import ES from 'date-fns/locale/es';
import dayjs from 'dayjs';

import useStyles from './styles';

interface SelectDateProps {
  label: string;
  onChange: (value: any) => void;
  disabled?: boolean;
  limitDate?: any;
  value?: any;
}

const SelectDate = ({
  label,
  onChange,
  disabled,
  limitDate,
  value,
}: SelectDateProps) => {
  const [selectedDate, setSelectedDate] = useState<any>(dayjs());

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    onChange(date);
  };
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ES}>
        <DatePicker
          label={label}
          format="dd/MM/yyyy"
          value={value ?? selectedDate}
          onChange={handleDateChange}
          variant="inline"
          fullWidth
          inputVariant="outlined"
          size="small"
          disabled={disabled}
          labelFunc={() => dayjs(value ?? selectedDate).format('DD/MM/YYYY')}
          minDate={limitDate}
          minDateMessage="La fecha final debe ser mayor a la inicial"
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default SelectDate;
