import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import styles from './styles';

interface ListDataProps {
  items: object;
  widthContainer?: number | string;
  widthtTitle?: number | string;
  widthValue?: number | string;
}

const ListData = ({
  items,
  widthContainer,
  widthtTitle,
  widthValue,
}: ListDataProps) => {
  return (
    <View
      style={[
        styles.tablecontainer,
        { width: `${widthContainer ? (widthContainer as String) : '31.2%'}` },
      ]}
    >
      <View style={styles.table}>
        {_.map(items, (value, key) => (
          <View style={styles.tableRow}>
            <View
              style={[
                styles.tableCol,
                { width: widthtTitle ? widthtTitle : '40%' },
              ]}
            >
              <View style={styles.tableCell}>
                <Text
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  {key}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableColRigth,
                { width: widthValue ? widthValue : '60%' },
              ]}
            >
              <Text style={styles.tableCell}>{value}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default ListData;
