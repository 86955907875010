import { Document, Page, Image, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import immunocastrationLogo from 'static/img/reportsIcons/immunocastrationLogo';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';

import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import ItemTotal from 'components/pdf-components/ItemTotal';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import DetailInmuneTable from 'components/pdf-components/DetailInmuneTable/index';

import styles from './styles/filter';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const detalles = [
  {
    fecha: '10/11/2019',
    nroCertificado: '45143324',
    lote: 1234,
    loteCeba: 45678,
    granja: 'Nombre del Granja',
    totalAnimales: 52,
  },
  {
    fecha: '10/11/2019',
    nroCertificado: '45143324',
    lote: 1234,
    loteCeba: 45678,
    granja: 'Nombre del Granja',
    totalAnimales: 52,
  },
  {
    fecha: '10/11/2019',
    nroCertificado: '45143324',
    lote: 1234,
    loteCeba: 45678,
    granja: 'Nombre del Granja',
    totalAnimales: 52,
  },
  {
    fecha: '10/11/2019',
    nroCertificado: '45143324',
    lote: 1234,
    loteCeba: 45678,
    granja: 'Nombre del Granja',
    totalAnimales: 52,
  },
  {
    fecha: '10/11/2019',
    nroCertificado: '45143324',
    lote: 1234,
    loteCeba: 45678,
    granja: 'Nombre del Granja',
    totalAnimales: 52,
  },
  {
    fecha: '10/11/2019',
    nroCertificado: '45143324',
    lote: 1234,
    loteCeba: 45678,
    granja: 'Nombre del Granja',
    totalAnimales: 52,
  },
];

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
  range?: null | number | string;
}

export const MyDocInmune = ({ qr, uid, range }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        range={range}
        reportImage={immunocastrationLogo}
        number="023456789"
        title="Certificado de Inmunocastración"
        code="CI-001"
      />

      <Subtitle
        title="Resumen Certificados de Inmunocastración"
        widthContainer="100%"
      />

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <View
          style={{
            width: '31.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="10"
            info="Total Certificados de la búsqueda"
            position="right"
            textAlign="left"
            backgroundColor="#ffc676"
            widthInfo="55px"
            widthCircle={30}
          />
        </View>
        <View
          style={{
            width: '31.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="3"
            info="Productores Diferentes"
            position="right"
            textAlign="left"
            backgroundColor="#f1ae6c"
            widthInfo="55px"
            widthCircle={30}
          />
        </View>
        <View
          style={{
            width: '31.2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotal
            value="520"
            info="Total Animales Todos los Lotes"
            position="right"
            widthValue={60}
            widthInfo="100px"
            fontSizeValue={12}
            backgroundColor="#d47d57"
          />
        </View>
      </View>

      <Subtitle
        title="Lista de Certificados de Inmunocastración"
        widthContainer="100%"
      />

      <DetailInmuneTable
        razonSocial="Intercomercializadora Meat Packing S.A.S"
        totalLotes={6}
        totalAnimales={324}
        data={detalles}
      />
      <DetailInmuneTable
        razonSocial="Intercomercializadora Meat Packing S.A.S"
        totalLotes={6}
        totalAnimales={324}
        data={detalles}
      />
      <DetailInmuneTable
        razonSocial="Intercomercializadora Meat Packing S.A.S"
        totalLotes={6}
        totalAnimales={324}
        data={detalles}
      />

      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportFilter = () => {
  return (
    <div id="screenQrFilterInmune" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte Certificado de Inmunocastración"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
