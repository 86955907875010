import { NestedRoute } from 'lib/interfaces/routes';

import Reports from './Reports'
import Frequent from './Frequent'
import ClientsDetails from './DetailsClients';
import ReportsDetails from './DetailsReports';
import FrequentDetails from './DetailsFrequent';

export {default as Main} from './Main'

export const routes: NestedRoute[] = [
  {
    route: '/reports',
    Node: Reports
  },
  {
    route: '/details-client/:id/:type',  
    Node: ClientsDetails
  },
  {
    route: '/details-reports/:type',  
    Node: ReportsDetails
  },
  {
    route: '/frequent',
    Node: Frequent
  },
  {
    route: '/frequent-details/:id/:type',
    Node: FrequentDetails
  },
]