import React from 'react';
import clsx from 'clsx';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import useSnackbar from 'lib/hooks/useSnackbar';

import useStyles from './styles';
import { TransitionProps } from '@material-ui/core/transitions/transition';

const Alert = (props: any) => (
  <MuiAlert elevation={6} variant="filled" {...props} />
);

export default ({
  children,
}: {
  children: React.ElementType | React.ReactNode;
}) => {
  const classes = useStyles();
  const {
    open,
    type,
    action,
    actionText,
    closeBtn,
    message,
    placement,
    autoHideDuration,
    handleClose,
  } = useSnackbar();

  const getDirection = () => {
    if (placement.vertical === 'top' && placement.horizontal === 'center')
      return 'down';
    if (placement.vertical === 'top' && placement.horizontal === 'left')
      return 'right';
    if (placement.vertical === 'top' && placement.horizontal === 'right')
      return 'left';
    if (placement.vertical === 'bottom' && placement.horizontal === 'center')
      return 'up';
    if (placement.vertical === 'bottom' && placement.horizontal === 'left')
      return 'right';
    if (placement.vertical === 'bottom' && placement.horizontal === 'right')
      return 'left';
    return 'left';
  };

  const rootClasses = clsx({
    [classes.message]: true,
    [classes.topSnack]: placement.vertical === 'top',
    [classes.errorSnack]: type === 'ERROR',
  });

  return (
    <>
      <Snackbar
        open={open}
        message={message}
        onClose={handleClose}
        anchorOrigin={placement}
        autoHideDuration={autoHideDuration}
        classes={{ root: rootClasses }}
        action={
          <>
            {closeBtn && (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </>
        }
      >
        {type !== 'DEFAULT' && typeof action !== 'function' ? (
          <Alert onClose={handleClose} severity={type.toLowerCase()}>
            {message}
          </Alert>
        ) : (
          <></>
        )}
      </Snackbar>
      {children}
    </>
  );
};
