import { makeStyles } from "@material-ui/core";
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  alignText: {
    textAlign: 'center',
  },
  borderRadiusDialogSm: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: '20px',
    }
  },
  borderRadiudDialogLg: {
    "& .MuiDialog-paperWidthLg": {
      borderRadius: '20px',
    }
  },
  fontTitle: {
    fontWeight: 'bold',
    color: '#767676'
  },
  cardItem: {
    width: 120,
    height: 150,
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      },
    },
  },
  cardItemAddDriver: {
    width: 210,
    height: 240,
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#FFF4E5',
    },
  },
  cardSelected: {
    backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardActionArea: {
    height: '100%',
  },
  roleIcon: {
    fontSize: 45,
    color: '#767676',
    margin: '0px 20px',
  },
  iconCard: {
    fontSize: 75,
    color: '#767676',
  },
  mainText: {
    color: '#767676',
    fontSize: 13,
    fontWeight: 'bolder',
    textAlign: 'center'
  },
  secondaryText: {
    color: '#AFAFAF',
    fontSize: 14,
  },
  fontContent: {
    color: '#AFAFAF',
    fontSize: '16px',
  },
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& :hover":{
      color: '#fff'
    }
  },

  cardContainer: {
    borderRadius: '20px',
    minWidth: 200, 
    maxWidth: 300,
    "& .MuiCardHeader-root":{
      padding: "15px 15px 0px 0px",
    },
  },
  nameInfo:{
    fontWeight: 'bolder',
    textAlign: 'center'
  },
  ubicationInfo:{
    textAlign: 'center',
    color: '#AFAFAF',
    fontSize: '12px',
  },
  dataTitle:{
    color: '#AFAFAF',
    fontSize: '12px'
  },
  dataInfo:{
    fontWeight: 'bolder'
  },
  buttonColorGreen:{
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
  buttonColorRed:{
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900],
    },
  },
  circularLoading: {
    color: '#AFAFAF',
  },
  fontPlate:{
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  marginContainer:{
    marginBottom: 10,
    marginTop: 5
  },
  carouselContainerDriver:{
    "& .rec":{
      height: "270px",
      width: '85%',
    }
  },
}))

export { useStyles as default }