import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Box,
  DialogActions,
  Button,
  Grow,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import MainTitle from 'components/MainTitle';
import CardSelectVehicle from 'components/TransportComponents/CardSelectVehicle';

import {
  GET_UNASSOCIATED_VEHICLE,
  TYPE_SERVICE,
} from 'core/graphql/queries/transport';
import { POST_DRIVER_VEHICLE } from 'core/graphql/mutations/transport';

import useStyles from './styles';
import DialogTypeService from './DialogTypeService/index';
import Slider from './../../../../../../../../components/Slider/index';

type DialogProps = {
  open: boolean;
  data: any;
  isCompnay?: boolean;
  handleClose: (event: any) => void;
};

const DialogAddVehicle = ({
  open,
  data,
  isCompnay,
  handleClose,
}: DialogProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [selectOption, setSelectOption] = useState(0);
  const [selectVehicle, setSelectVehicle] = useState<number>();
  const [selectTypeService, setSelectTypeService] = useState(0);
  const [openDialogTS, setOpenDialogTS] = useState({
    open: false,
    id: 0,
  });

  const {
    data: dataTypeService,
    loading: loadingTypeService,
    error: errorTypeService,
  } = useQuery(TYPE_SERVICE, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const openDialogTypeService = (id: number) => {
    setOpenDialogTS({
      open: true,
      id: id,
    });
  };

  const closeDialogTypeService = () => {
    setOpenDialogTS({
      open: false,
      id: 0,
    });
  };

  const [
    getVehicles,
    { data: dataVehicles, loading: loadingVehicles, error: errorVehicles },
  ] = useLazyQuery(GET_UNASSOCIATED_VEHICLE, {
    fetchPolicy: 'network-only',
  });

  const vehicles = dataVehicles?.getUnassociatedVehicles.data;

  useEffect(() => {
    if (open && selectOption === 1) {
      getVehicles({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idConductor: data.id },
      });
    } else if (open && selectOption === 1 && isCompnay) {
      getVehicles({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idConductor: 0 },
      });
    }

    if (!open) {
      setSelectOption(0);
      setSelectTypeService(0);
    }
  }, [selectOption, open, isCompnay]);

  const [postDriverXVehicle, { loading: loadingDriverVehicle }] = useMutation(
    POST_DRIVER_VEHICLE,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const associateDriverXVehicle = (
    idDriver: number,
    idVehicle: number,
    event: any
  ) => {
    postDriverXVehicle({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.postDriverXVehicle.data.success === 1) {
          enqueueSnackbar('Conductor creado exitosamente.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
          handleClose(event);
        } else {
          enqueueSnackbar(mutationResult.data.postDriverXVehicle.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        idConductor: idDriver,
        idVehiculo: idVehicle,
      },
    });
  };

  const breakPointsSlider = [
    { width: 1, itemsToShow: 1 },
    { width: 450, itemsToShow: 2, itemsToScroll: 2 },
    { width: 700, itemsToShow: 3 },
    { width: 1050, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6, itemsToScroll: 3 },
  ];

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={selectOption !== 1 ? 'sm' : 'lg'}
        className={clsx({
          [classes.borderRadiusDialogSm]: selectOption !== 1,
          [classes.borderRadiudDialogLg]: selectOption === 1,
          [classes.hiddenDialog]: openDialogTS.open,
        })}
        scroll="body"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid container justify="flex-end">
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container className={classes.title}>
              <MainTitle>
                {isCompnay
                  ? 'Agregar Vehículo a la Empresa:'
                  : 'Agregar Vehículo al Conductor:'}
              </MainTitle>
            </Grid>
            <Grid container className={classes.titleContainer}>
              <Typography className={classes.fontPlate}>{data.name}</Typography>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ overflowY: 'hidden' }}>
          {selectOption !== 1 && (
            <>
              <MainTitle fontSize="h6">Seleccione la opción deseada</MainTitle>

              <Grid container justifyContent="center">
                <Grid item style={{ margin: 20 }}>
                  <Grow in timeout={800} style={{ transitionDelay: '100ms' }}>
                    <Card
                      elevation={2}
                      className={clsx({
                        [classes.cardItem]: true,
                        [classes.cardSelected]: selectOption === 1,
                      })}
                    >
                      <CardActionArea
                        onClick={() => setSelectOption(1)}
                        className={classes.cardActionArea}
                      >
                        <CardContent className={classes.cardContent}>
                          <Box
                            component="i"
                            className={clsx(
                              'icon-truck-search',
                              classes.roleIcon
                            )}
                          />
                          <Typography className={classes.mainText}>
                            Buscar Vehículo
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                </Grid>

                <Grid item style={{ margin: 20 }}>
                  <Grow in timeout={800} style={{ transitionDelay: '200ms' }}>
                    <Card
                      elevation={2}
                      className={clsx({
                        [classes.cardItem]: true,
                        [classes.cardSelected]: selectOption === 2,
                      })}
                    >
                      <CardActionArea
                        onClick={() => setSelectOption(2)}
                        className={classes.cardActionArea}
                      >
                        <CardContent className={classes.cardContent}>
                          <Box
                            component="i"
                            className={clsx('icon-truck', classes.roleIcon)}
                          />
                          <Typography className={classes.mainText}>
                            Registrar Vehículo
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                </Grid>
              </Grid>
            </>
          )}

          {selectOption === 2 && (
            <>
              <Grid item container alignItems="flex-start">
                <Grow in timeout={800}>
                  <Box marginBottom={3} marginTop={1}>
                    <MainTitle fontSize="h6">
                      Seleccione el tipo de servicio
                    </MainTitle>
                  </Box>
                </Grow>
              </Grid>

              <Grid container justifyContent="center" spacing={3}>
                {dataTypeService?.getTypeService.map(
                  (option: any, ind: number) => (
                    <Grid item key={ind}>
                      <Grow
                        in
                        timeout={800}
                        style={{ transitionDelay: `${ind * 100}ms` }}
                      >
                        <Card
                          elevation={2}
                          className={clsx({
                            [classes.cardItem]: true,
                            [classes.cardSelected]:
                              selectTypeService === option.id,
                          })}
                        >
                          <CardActionArea
                            onClick={() => {
                              setSelectTypeService(option.id);
                            }}
                            className={classes.cardActionArea}
                            component={Link}
                            to={`/app/network/transports/forms/register-vehicle-driver/${data.id}/${option.id}`}
                          >
                            <CardContent className={classes.cardContent}>
                              <Box
                                component="i"
                                className={clsx(
                                  option.id === 1
                                    ? 'icon-truck'
                                    : option.id === 2
                                    ? 'icon-truck-time'
                                    : 'icon-truck-sync',
                                  classes.roleIcon
                                )}
                              />
                              <Typography className={classes.mainText}>
                                {option.description}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grow>
                    </Grid>
                  )
                )}
              </Grid>
            </>
          )}

          {selectOption === 1 && !loadingVehicles && vehicles && (
            <>
              <Grid container justifyContent="center">
                <Box
                  mt={1}
                  mb={4}
                  maxWidth="70%"
                  textAlign="center"
                  color="#afafaf"
                >
                  <Typography>
                    {vehicles
                      ? 'Seleccione el Vehículo que desea asociar al Conductor anteriormente registrado. Si desea registrar un Vehículo, seleccione la opción Registrar Vehículo.'
                      : 'No se encontraron Vehículos para asociar, esto significa que los vehículos registrados ya estan asociados a este Conductor o no tienes Conductores registrados, para registrar uno nuevo seleccione la opción Registrar Conductor.'}
                  </Typography>
                  <Box mt={2} mb={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => openDialogTypeService(data.id)}
                    >
                      Registrar Vehículo
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid
                container
                spacing={5}
                justifyContent="center"
                alignItems="center"
              >
                <Slider
                  breakPoints={breakPointsSlider}
                  className={classes.carouselContainerVehicle}
                >
                  {vehicles?.map((item: any, ind: number) => {
                    return (
                      <CardSelectVehicle
                        plate={item?.placa}
                        ubication="Medellín"
                        vehicleType={item?.tipoVehiculo}
                        capacity={item?.capacidadCarga}
                        onClick={() => setSelectVehicle(item?.idVehiculo)}
                        checked={selectVehicle === item?.idVehiculo}
                      />
                    );
                  })}
                  <Card
                    elevation={2}
                    className={clsx({
                      [classes.cardItemAddVehicle]: true,
                    })}
                  >
                    <CardActionArea
                      className={classes.cardActionArea}
                      onClick={() => openDialogTypeService(data.id)}
                    >
                      <CardContent>
                        <Grid container justifyContent="center">
                          <Box
                            component="i"
                            className={clsx(
                              'icon-circle-plus',
                              classes.iconCard
                            )}
                          />
                          <Typography className={classes.mainText}>
                            Registrar Vehículo
                          </Typography>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Slider>
              </Grid>
            </>
          )}
        </DialogContent>

        <DialogActions className={classes.marginContainer}>
          <Grid container justify="center" spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cerrar
              </Button>
            </Grid>

            {selectOption === 1 && vehicles?.length !== 0 && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) =>
                    associateDriverXVehicle(data.id, Number(selectVehicle), e)
                  }
                  endIcon={
                    loadingDriverVehicle && (
                      <CircularProgress
                        className={classes.loadingColor}
                        thickness={5}
                        size={20}
                      />
                    )
                  }
                  disabled={
                    selectVehicle === 0 ||
                    selectVehicle === undefined ||
                    loadingDriverVehicle
                  }
                >
                  Asignar Vehículo
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
      <DialogTypeService
        open={openDialogTS.open}
        id={openDialogTS.id}
        handleClose={closeDialogTypeService}
      />
    </>
  );
};
export default DialogAddVehicle;
