import { Document, Image, Page, View, Font, Text } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import magroLogo from 'static/img/reportsIcons/magroLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';
import checkIcon from 'static/img/reportsIcons/checkIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ListData from 'components/pdf-components/ListData';
import NumberTotal from 'components/pdf-components/NumberTotal/index';
import Footer from 'components/pdf-components/Footer/index';

import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import ItemTotal from 'components/pdf-components/ItemTotal';
import ItemTotalLeft from 'components/pdf-components/ItemTotalLeft/index';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF/index';
import ChartList from 'components/pdf-components/ChartList/index';
import Paginationpdf from 'components/pdf-components/Pagination';
import HorizontalTableInfo from 'components/pdf-components/HorizontalTableInfo/index';

import styles from './styles/lote';

const item = [2, 2, 2, 2, 2];

const item2 = [
  2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
  2, 2, 2, 2, 2, 2, 2, 2, 2,
];

const itemS = [
  {
    chart: 'S',
    magro: '%M > 60%',
    no: '12',
    percentage: '16%',
  },
  {
    chart: 'E',
    magro: '%55 < %M > 60%',
    no: '28',
    percentage: '35%',
  },
  {
    chart: 'U',
    magro: '%50 < %M > 55%',
    no: '28',
    percentage: '35%',
  },
  {
    chart: 'R',
    magro: '%45 < %M > 50%',
    no: '08',
    percentage: '10%',
  },
  {
    chart: 'O',
    magro: '%40 < %M > 45%',
    no: '01',
    percentage: '02%',
  },
  {
    chart: 'P',
    magro: '%M < 40%',
    no: '01',
    percentage: '02%',
  },
];

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

interface MyDocMagroProps {
  qr: string;
  graph: string;
  graphTwo: string;
  range?: null | number | string;
}

export const MyDocRangeMagroCanal = ({
  qr,
  graph,
  graphTwo,
  range,
}: MyDocMagroProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        range={range}
        reportImage={magroLogo}
        number="023456789"
        title="Reporte Técnico Evaluación de Magro"
        code="EM-001"
      />
      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ width: '100%' }}>
        <HorizontalTableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            Nit: '34123412-2',
            Telefono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <View
          style={{
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: '#767676',
            marginTop: '10px',
          }}
        ></View>
        <HorizontalTableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ marginTop: '5px' }}>
        <Subtitle title="Resumen del Lote Evaluado" widthContainer="100%" />
      </View>

      <View
        style={{
          flexDirection: 'row',
          marginTop: '5px',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '60%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ItemTotalCircle
            value="70"
            info="Canales Evaluadas"
            position="bottom"
            backgroundColor="#be5948"
            widthCircle={35}
            widthInfo={60}
          />
          <ItemTotalCircle
            value="101,67 Kg"
            info="Promedio PCC"
            position="bottom"
            backgroundColor="#d47d57"
            widthCircle={35}
            widthInfo={60}
            fontSizeValue={8.5}
          />
          <ItemTotalCircle
            value="54,84 %"
            info="Promedio MC%"
            position="bottom"
            backgroundColor="#e29662"
            widthCircle={35}
            widthInfo={60}
            fontSizeValue={8.5}
          />
          <ItemTotalCircle
            value="69,68 m.m."
            info="Promedio LM"
            position="bottom"
            backgroundColor="#f1ae6c"
            widthCircle={35}
            widthInfo={60}
            fontSizeValue={8.5}
          />
          <ItemTotalCircle
            value="16,08 m.m."
            info="Promedio GD"
            position="bottom"
            backgroundColor="#ffc676"
            widthCircle={35}
            widthInfo={60}
            fontSizeValue={8.5}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            width: '35%',
            justifyContent: 'space-between',
          }}
        >
          <View style={{ flexDirection: 'column', width: '52.3%' }}>
            <NumberTotal
              value="7.320,00 Kg"
              info="Peso Total Animales en Pie Lote"
              position="bottom"
              widthInfo="120px"
              widthValue="120px"
              justifyValue="left"
              justifyInfo="left"
              colorValue="#373737"
              fontSizeValue={10}
            />
            <NumberTotal
              value="6.891,87 Kg"
              info="Peso Total Canales Calientes"
              position="bottom"
              widthInfo="120px"
              widthValue="120px"
              justifyValue="left"
              justifyInfo="left"
              colorValue="#373737"
              fontSizeValue={10}
            />
          </View>
          <View
            style={{ flexDirection: 'column', width: '48.3%', marginLeft: 10 }}
          >
            <NumberTotal
              value="4.013,65 Kg"
              info="Total Carne Magra"
              position="bottom"
              widthInfo="100px"
              widthValue="100px"
              justifyValue="left"
              justifyInfo="left"
              colorValue="#373737"
              fontSizeValue={10}
            />
            <NumberTotal
              value="83,76%"
              info="Rendimiento Pie / Canal"
              position="bottom"
              widthInfo="100px"
              widthValue="100px"
              justifyValue="left"
              justifyInfo="left"
              colorValue="#373737"
              fontSizeValue={10}
            />
          </View>
        </View>
      </View>

      <View style={{ marginTop: '5px' }}>
        <Subtitle
          title="Resúmen del Proceso de Ingreso / Desembarque / Inspección"
          widthContainer="100%"
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          marginTop: '5px',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            width: '48.3%',
          }}
        >
          <Subtitle
            title="Recepción Animales en Pie Corrales"
            widthContainer="100%"
          />
          <ListData
            widthContainer="100%"
            items={{
              'Fecha / Hora Llegada a Planta': '01/10/2019 02:00:00 p.m.',
              'Fecha / Hora Ingreso Planta': '01/10/2019 02:30:00 p.m.',
              'Fecha / Hora Salida Planta': '01/10/2019 02:30:00 p.m.',
              'Fecha / Hora Inicio Descargue': '01/10/2019 02:00:00 p.m.',
              'Fecha / Hora Final Descargue': '01/10/2019 02:30:00 p.m.',
            }}
          />

          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '5px',
            }}
          >
            <View
              style={{
                width: '45.3%',
              }}
            >
              <View style={{ marginBottom: '2px' }}>
                <ItemTotalLeft
                  value="72"
                  info="Animales Remisionados"
                  backgroundColor="#373737"
                  heightValue={15}
                  fontSizeValue={8}
                  widthValue={30}
                  widthInfo="75px"
                  fontWeight
                  alignInfo="left"
                />
              </View>
              <View>
                <ItemTotalLeft
                  value="72"
                  info="Animales Recibidos"
                  backgroundColor="#373737"
                  heightValue={15}
                  fontSizeValue={8}
                  widthValue={30}
                  widthInfo="75px"
                  fontWeight
                  alignInfo="left"
                />
              </View>
            </View>
            <View
              style={{
                width: '45.3%',
              }}
            >
              <View style={{ marginBottom: '2px' }}>
                <ItemTotalLeft
                  value="96741"
                  info="Tiquete Báscula Nro."
                  backgroundColor="#373737"
                  heightValue={15}
                  fontSizeValue={8}
                  widthValue={30}
                  widthInfo="75px"
                  fontWeight
                  alignInfo="left"
                />
              </View>

              <View>
                <ItemTotalLeft
                  value="7656"
                  info="Remisión Nro."
                  backgroundColor="#373737"
                  heightValue={15}
                  fontSizeValue={8}
                  widthValue={30}
                  widthInfo="75px"
                  fontWeight
                  alignInfo="left"
                />
              </View>
            </View>
          </View>

          <View style={{ marginTop: '5px' }}>
            <Subtitle title="Pesaje de Animales en Pie" widthContainer="100%" />
          </View>

          <View style={{ width: '48.3%' }}>
            <View
              style={[styles.tableMagro, { marginBottom: 10 }]}
              wrap={false}
            >
              <View
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    marginBottom: '5px',
                    marginTop: '5px',
                    alignItems: 'center',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 25, width: 25 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Pesaje
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Fecha
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 39, width: 39 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>Hora</Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 38, width: 38 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Animales
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 35, width: 35 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>Sexo</Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 58, width: 58 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Inmuncastrado
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 30, width: 30 }]}>
                  <Text style={[styles.tableCell, styles.tableHead]}>
                    Peso (Kg)
                  </Text>
                </View>
              </View>
              {item.map((item: any, index: number) => (
                <View
                  key={index}
                  wrap={false}
                  style={[
                    styles.tableRow,
                    {
                      backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                    },
                  ]}
                >
                  <View style={[styles.tableCol, { maxWidth: 25, width: 25 }]}>
                    <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                      {index}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                    <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                      01/10/2019
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 39, width: 39 }]}>
                    <Text
                      style={
                        (styles.tableCellPost, { fontSize: 7, marginTop: 5 })
                      }
                    >
                      4:10 a.m.
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 35, width: 35 }]}>
                    <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                      18
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 38, width: 38 }]}>
                    <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                      AMBOS
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 58, width: 58 }]}>
                    <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                      NO
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 30, width: 30 }]}>
                    <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                      2191
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>

          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                width: '65%',
              }}
            >
              <View
                style={{
                  height: '60px',
                  justifyContent: 'center',
                }}
              >
                <ItemTotalLeft
                  value="72"
                  info="Total Animales en Pie Pesados"
                  backgroundColor="#373737"
                  heightValue={15}
                  fontSizeValue={8}
                  widthValue={50}
                  widthInfo="100px"
                  alignInfo="left"
                />

                <ItemTotalLeft
                  value="8.739,00 Kg"
                  info="Peso Total Animales en Pie (Kg)"
                  backgroundColor="#373737"
                  heightValue={15}
                  fontSizeValue={8}
                  widthValue={50}
                  widthInfo="100px"
                  alignInfo="left"
                />

                <ItemTotalLeft
                  value="120,8 Kg"
                  info="Peso Promedio del Lote (Kg)"
                  backgroundColor="#373737"
                  heightValue={15}
                  fontSizeValue={8}
                  widthValue={50}
                  widthInfo="100px"
                  alignInfo="left"
                />
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            width: '48.3%',
          }}
        >
          <Subtitle
            title="Reporte de Inspección Veterinaria"
            widthContainer="100%"
          />
          <View style={{ marginTop: '5px' }}>
            <Subtitle title="Ante - Mortem" widthContainer="100%" />
          </View>

          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View style={{ width: '48.3%' }}>
              <ListData
                widthContainer="100%"
                widthtTitle="80%"
                widthValue="20%"
                items={{
                  Agitados: '0',
                  Caídos: '0',
                  'Muertos Transporte': '0',
                  'Muertos Corrales': '0',
                  Observación: '0',
                }}
              />
            </View>
            <View style={{ width: '48.3%' }}>
              <ListData
                widthContainer="100%"
                widthtTitle="80%"
                widthValue="20%"
                items={{
                  'Agitados Corrales': '0',
                  'Caídos Corrales': '0',
                  'Muertos Desembarque': '0',
                  'Muertos en Observacióne': '0',
                  'Animales Decomisados': '0',
                }}
              />
            </View>
          </View>

          <View style={{ marginTop: '5px' }}>
            <Subtitle
              title="Resumen Decomisos del Lote"
              widthContainer="100%"
            />
          </View>

          <View
            style={{
              height: '225px',
              marginTop: '5px',
              padding: 5,
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  height: '120px',
                  width: '50%',
                  marginTop: 10,
                  marginBottom: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image
                  src={graph}
                  style={{ height: '150px', width: '150px' }}
                />
              </View>

              <View
                style={{
                  width: '60%',
                  marginLeft: 10,
                }}
              >
                <View style={{ width: '80%' }}>
                  <Text style={{ fontWeight: 'bold' }}>Partes Decomisadas</Text>
                </View>
                <ChartList
                  data={[
                    {
                      label: 'Pulmón',
                      data: 101,
                      bg: '#ffc676',
                    },
                    {
                      label: 'Piel',
                      data: 6,
                      bg: '#e29662',
                    },
                    {
                      label: 'Visceras Rojas',
                      data: 6,
                      bg: '#d47d57',
                    },
                    {
                      label: 'Pierna',
                      data: 2,
                      bg: '#be5948',
                    },
                    {
                      label: 'Corazón',
                      data: 2,
                      bg: '#b0413e',
                    },
                  ]}
                />
              </View>
            </View>

            <View style={{ marginTop: -35 }}>
              <View style={{ textAlign: 'center' }}>
                <Text style={{ fontWeight: 'bold' }}>Post - Mortem</Text>
                <Text>Línea y Emergencia</Text>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <NumberTotal
                  value="00%"
                  info="Canales Decomisadas"
                  position="right"
                  widthValue={60}
                  widthInfo={100}
                />
                <View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: '5px',
                      marginTop: '5px',
                    }}
                  >
                    <View style={{ width: '45.3%' }}>
                      <ItemTotal
                        value="0"
                        info="Canales Decomisadas Completas (línea)"
                        backgroundColor="#373737"
                        heightValue={15}
                        fontSizeValue={8}
                        widthValue={20}
                        widthInfo="85px"
                        position="left"
                        alignFontInfo="left"
                      />
                    </View>
                    <View style={{ width: '51.3%' }}>
                      <ItemTotal
                        value="0"
                        info="Total (Kgs) Carne Decomisada (línea)"
                        backgroundColor="#373737"
                        heightValue={15}
                        fontSizeValue={8}
                        widthValue={20}
                        widthInfo="100px"
                        position="left"
                        alignFontInfo="left"
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: '5px',
                      marginTop: '5px',
                    }}
                  >
                    <View style={{ width: '45.3%' }}>
                      <ItemTotal
                        value="0"
                        info="Canales Decomisadas Completas (Emergencia)"
                        backgroundColor="#373737"
                        heightValue={15}
                        fontSizeValue={8}
                        widthValue={20}
                        widthInfo="85px"
                        position="left"
                        alignFontInfo="left"
                      />
                    </View>
                    <View style={{ width: '51.3%' }}>
                      <ItemTotal
                        value="0"
                        info="Total (Kgs) Carne Decomisada (Emergencia)"
                        backgroundColor="#373737"
                        heightValue={15}
                        fontSizeValue={8}
                        widthValue={20}
                        widthInfo="100px"
                        position="left"
                        alignFontInfo="left"
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          width: '100%',
          alignItems: 'center',
          marginBottom: 70,
        }}
      >
        <View
          style={{
            width: '60%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={{ textAlign: 'center' }}>
            Los datos de sexo son tomados desde la remisión enviada desde la
            granja. Se verifica y se confirma en el puesto de Medición y
            Clasificación de Canales Calientes.
          </Text>
        </View>
      </View>

      <View>
        <Subtitle
          title="Distribución de Canales Procinas según Clasificación S.E.U.R.O.P."
          widthContainer="100%"
        />

        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          <View
            style={{
              width: '58.3%',
              height: '150px',
              backgroundColor: '#ededed',
              marginTop: '5px',
              justifyContent: 'center',
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View
                style={{
                  height: '120px',
                  width: '50%',
                  marginTop: 10,
                  marginBottom: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 15,
                }}
              >
                <Image
                  src={graphTwo}
                  style={{ height: '150px', width: '150px' }}
                />
              </View>

              <View
                style={{
                  width: '60%',
                  marginRight: 25,
                }}
              >
                <ChartList
                  data={[
                    {
                      label: 'S - Superior',
                      data: 12,
                      bg: '#FFC676',
                    },
                    {
                      label: 'E - Excelente',
                      data: 28,
                      bg: '#F1AE6C',
                    },
                    {
                      label: 'U - Muy Buena',
                      data: 28,
                      bg: '#E29662',
                    },
                    {
                      label: 'R - Menos Buena',
                      data: 8,
                      bg: '#D47D57',
                    },
                    {
                      label: 'O - Aceptable',
                      data: 1,
                      bg: '#BE5948',
                    },
                    {
                      label: 'P - Pobre',
                      data: 1,
                      bg: '#B0413E',
                    },
                  ]}
                />
              </View>
            </View>
          </View>
          <View style={{ width: '38.3%' }}>
            <View style={{ width: '48.3%' }}>
              <View style={styles.tableMagro}>
                <View
                  style={[
                    styles.tableRow,
                    {
                      marginBottom: '5px',
                      marginTop: '5px',
                      alignItems: 'center',
                    },
                  ]}
                >
                  <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                    <Text style={[styles.tableCell, styles.tableHead]}></Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 75, width: 75 }]}>
                    <Text style={[styles.tableCell, styles.tableHead]}>
                      % Magro
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                    <Text style={[styles.tableCell, styles.tableHead]}>
                      No.
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { maxWidth: 50, width: 50 }]}>
                    <Text style={[styles.tableCell, styles.tableHead]}>%</Text>
                  </View>
                </View>
                {itemS.map((item: any, index: number) => (
                  <View
                    key={index}
                    wrap={false}
                    style={[
                      styles.tableRow,
                      {
                        backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                      },
                    ]}
                  >
                    <View
                      style={[styles.tableCol, { maxWidth: 40, width: 40 }]}
                    >
                      <Text
                        style={[
                          styles.tableCellPost,
                          { fontSize: 8, fontWeight: 'bold' },
                        ]}
                      >
                        {item.chart}
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 75, width: 75 }]}
                    >
                      <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                        {item.magro}
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 45, width: 45 }]}
                    >
                      <Text
                        style={
                          (styles.tableCellPost, { fontSize: 7, marginTop: 5 })
                        }
                      >
                        {item.no}
                      </Text>
                    </View>
                    <View
                      style={[styles.tableCol, { maxWidth: 50, width: 50 }]}
                    >
                      <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                        {item.percentage}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <ItemTotalLeft
              value="08"
              info="Total Canales con Grasa Dorsal Mayor a 18,5"
              backgroundColor="#373737"
              heightValue={12}
              fontSizeValue={8}
              widthValue={50}
              widthInfo="150px"
              alignInfo="left"
            />
            <ItemTotalLeft
              value="19,51%"
              info="Porcentaje Canales Grasa Dorsal Mayor a 18,5"
              backgroundColor="#373737"
              heightValue={12}
              fontSizeValue={8}
              widthValue={50}
              widthInfo="150px"
              alignInfo="left"
            />
          </View>
        </View>
      </View>

      <View style={{ marginTop: '5px' }}>
        <Subtitle
          title="Detalle de Evaluación de Magro de Canales Calientes Porcinas"
          widthContainer="100%"
        />
      </View>

      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'right',
          marginTop: '5px',
        }}
      >
        <View
          style={{
            width: '60%',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '60%',
              marginRight: '15px',
              height: '35',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginTop: '5px',
            }}
          >
            <Text>
              El proceso de medición se estableció con la utilización del equipo
              <Text style={{ fontWeight: 'bold' }}> HGS GP4</Text> y aplicando
              la siguiente fórmula:
            </Text>
          </View>
          <View
            style={{
              height: '35',
              backgroundColor: '#b0413e',
              marginTop: '5px',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '45%',
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                borderRadius: 100,
                width: 25,
                height: 25,
                backgroundColor: 'white',
                marginRight: '10px',
              }}
            >
              <Image src={checkIcon} />
            </View>
            <View style={{ width: '65%' }}>
              <Text style={{ color: 'white', fontWeight: 'bold' }}>
                Fórmula Estimación Magro PorkColombia 2012 (P1)
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={{ width: '100%' }}>
        <View style={[styles.tableMagro, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '10px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canal ID</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 47, width: 47 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Brazalete
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>SEUROP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>PCC (Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Inst. Med.
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>MC (%)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Gd (M.M)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>LM (M.M)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>MC (Kg)</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Indicio (PSE)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 43, width: 43 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Color</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Fecha</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Sexo</Text>
            </View>
          </View>
          {item2.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  306001
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 47, width: 47 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  30600101
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>E</Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  100,2
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>GP4</Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  70,60
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  11,60
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  70,60
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 43, width: 43 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  70,60
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 45, width: 45 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>-</Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 40, width: 40 }]}>
                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={{
                      borderRadius: 100,
                      width: 6,
                      height: 6,
                      backgroundColor: '#b0413e',
                      marginTop: 6.5,
                    }}
                  ></View>
                  <Text
                    style={[
                      styles.tableCellPost,
                      { fontSize: 8, marginRight: 8 },
                    ]}
                  >
                    00,00
                  </Text>
                </View>
              </View>
              <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  04/04/2019
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 42, width: 42 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>M</Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 42,
                width: 42,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 89, width: 89, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 42, width: 42, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              100,2
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 42, width: 42, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 42, width: 42, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              70,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 42, width: 42, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              11,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 42, width: 42, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              70,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 42, width: 42, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              70,60
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 170, width: 170, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
        </View>
      </View>

      <View style={{ marginTop: '15px' }}>
        <Subtitle
          title="Detalle Decomisos Partes Post-Mortem"
          widthContainer="100%"
        />
      </View>

      <View>
        <View style={[styles.table, { marginBottom: 10 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              { marginBottom: '5px', marginTop: '5px', alignItems: 'center' },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 88, width: 88 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canal ID</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 70, width: 70 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Cantidad</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 88, width: 88 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Parte Decomisada
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 105, width: 105 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Causa</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 88, width: 88 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Decomiso (Kg)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 110, width: 110 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Observaciones
              </Text>
            </View>
          </View>
          {item.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 88, width: 88 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  45143324
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 70, width: 70 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>2</Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 88, width: 88 }]}>
                <Text
                  style={(styles.tableCellPost, { fontSize: 7, marginTop: 5 })}
                >
                  Pulmón
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 105, width: 105 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  Broncoaspiración
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 88, width: 88 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  0,00
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 110, width: 110 }]}>
                <Text style={[styles.tableCellPost, { fontSize: 8 }]}>
                  Ninguna Observación.
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 42,
                width: 42,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 48, width: 48, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 70, width: 70, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              64
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 193, width: 193, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 88, width: 88, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellPost,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              0,00
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 110, width: 110, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
        </View>
      </View>

      <View style={styles.conventions} wrap={false}>
        <View style={styles.backgroundMagro}>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <View style={{ width: '23.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PCC (Kg)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Peso Canal Caliente</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * GD (m.m)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Grasa Dorsal</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * MC (%)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Porcentaje de Magro Canal</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '23.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * MC (Kg)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Peso Magro Canal</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * LM (m.m)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Profundidad del Lomo</Text>
                </View>
              </View>

              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (M)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Macho</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '26.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (H)
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Hembra</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroC}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * Inst. Med.
                  </Text>
                </View>
                <View style={styles.textMagro}>
                  <Text>Instrumento de Medición</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroCE}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * PSE
                  </Text>
                </View>
                <View style={styles.textMagroC}>
                  <Text>Pálido, Suave, Exudativo</Text>
                </View>
              </View>
            </View>

            <View style={{ width: '23.5%', alignItems: 'center' }}>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (*)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Si tiene PSE</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (.)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>No tiene indicio PSE</Text>
                </View>
              </View>
              <View style={styles.textconventionsST}>
                <View style={styles.gmagroST}>
                  <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                    * (?)
                  </Text>
                </View>
                <View style={styles.textST}>
                  <Text>Probable PSE</Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              marginLeft: '10px',
            }}
          >
            <View style={styles.textconventions}>
              <View style={styles.gmagroS}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * Clasificación Canal
                </Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * S
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Superior</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * E
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Excelente</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * U
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Muy Buena</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * R
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Menos Buena</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * O
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Aceptable</Text>
              </View>
            </View>

            <View style={styles.textconventions}>
              <View style={styles.gmagroSEUROP}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * P
                </Text>
              </View>
              <View style={styles.textMagroSEUROP}>
                <Text>Pobre</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.borderMagro}>
          <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
            Convenciones
          </Text>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportFilterCanal = () => {
  return (
    <div id="screenfiltercanal" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte por Lote Evaluación de Magro - Canales"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphRangeMagroCanal = () => {
  return (
    <div id="graphrangemagrocanal" style={{ width: 500, marginTop: -120 }}>
      <Graphicpdfsf
        textTotalData="Partes Decomisadas"
        topTextTotalData={-445}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        data={[
          {
            label: 'Pulmón',
            data: 101,
            bg: '#ffc676',
          },
          {
            label: 'Piel',
            data: 6,
            bg: '#e29662',
          },
          {
            label: 'Visceras Rojas',
            data: 6,
            bg: '#d47d57',
          },
          {
            label: 'Pierna',
            data: 2,
            bg: '#be5948',
          },
          {
            label: 'Corazón',
            data: 2,
            bg: '#b0413e',
          },
        ]}
      />
    </div>
  );
};

export const GraphFilterMagroCanalTwo = () => {
  return (
    <div id="graphfiltermagrocanaltwo" style={{ width: 500, marginTop: -20 }}>
      <Graphicpdfsf
        textTotalData="Total"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        startAngle={-90}
        endAngle={270}
        data={[
          {
            label: 'S - Superior',
            data: 12,
            bg: '#FFC676',
          },
          {
            label: 'E - Excelente',
            data: 28,
            bg: '#F1AE6C',
          },
          {
            label: 'U - Muy Buena',
            data: 28,
            bg: '#E29662',
          },
          {
            label: 'R - Menos Buena',
            data: 8,
            bg: '#D47D57',
          },
          {
            label: 'O - Aceptable',
            data: 1,
            bg: '#BE5948',
          },
          {
            label: 'P - Pobre',
            data: 1,
            bg: '#B0413E',
          },
        ]}
      />
    </div>
  );
};
