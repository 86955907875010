import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Box } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import domtoimage from 'dom-to-image';
import clsx from 'clsx';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableInfo from 'components/TableInfo';
import GobackLink from 'components/GobackLink';
import Chart from 'components/Chart';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import ExportToExcel from 'components/ExportToExcel';
import LoteInfoTitle from 'components/LoteInfoTitle';
import DialogPDFViewer from 'components/DialogPDFViewer';

import {
  ReportLot,
  MyDocVisceras,
  GraphVisceras,
} from './../../Reports/ReportLot';

import useStyles from './styles';

const index = () => {
  const [openFilterVisceras, setOpenFilterVisceras] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenLotVisceras');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraph = () => {
    const graph: any = document.getElementById('graphvisceras');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeVisceras = () => {
    setOpenFilterVisceras(true);
  };

  useEffect(() => {
    generateScreenshot();
    screenshotGraph();
  }, [openFilterVisceras]);

  const columns: GridColDef[] = [
    {
      field: 'canalID',
      headerName: 'Canal ID',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalVisceras',
      headerName: 'Total Vísceras',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'viscerasDecomisadas',
      headerName: 'Vísceras Decomisadas',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'viscerasAprobadas',
      headerName: 'Vísceras Aprobadas',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'observaciones',
      headerName: 'Observaciones',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const rows = [
    {
      id: 1,
      canalID: '1234 ',
      totalVisceras: '4',
      viscerasDecomisadas: '2',
      viscerasAprobadas: '2',
      observaciones: 'Descripción Observación',
    },
    {
      id: 2,
      canalID: '1234 ',
      totalVisceras: '4',
      viscerasDecomisadas: '2',
      viscerasAprobadas: '2',
      observaciones: 'Descripción Observación',
    },
    {
      id: 3,
      canalID: '1234 ',
      totalVisceras: '4',
      viscerasDecomisadas: '2',
      viscerasAprobadas: '2',
      observaciones: 'Descripción Observación',
    },
    {
      id: 4,
      canalID: '1234 ',
      totalVisceras: '4',
      viscerasDecomisadas: '2',
      viscerasAprobadas: '2',
      observaciones: 'Descripción Observación',
    },
    {
      id: 5,
      canalID: '1234 ',
      totalVisceras: '4',
      viscerasDecomisadas: '2',
      viscerasAprobadas: '2',
      observaciones: 'Descripción Observación',
    },
    {
      id: 6,
      canalID: '1234 ',
      totalVisceras: '4',
      viscerasDecomisadas: '2',
      viscerasAprobadas: '2',
      observaciones: 'Descripción Observación',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/viscera/details',
      title: 'Vísceras Blancas',
    },
  ];

  const classes = useStyles();

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphVisceras />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoteInfoTitle
              lot="7645"
              namereport="Nro. Reporte"
              numberreport="011-65432"
              title="Vísceras Blancas"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Agentes relacionados
              </Typography>
            </div>
          </Grid>

          <Grid item xs={4}>
            <TableInfo
              icon="icon-productor"
              title="Productor"
              items={{
                Nombre: 'Cualquier productor',
                NIT: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TableInfo
              icon="icon-planta"
              title="Planta Beneficio"
              items={{
                Nombre: 'Nombre Planta',
                NIT: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TableInfo
              icon="icon-granja"
              title="Granja"
              items={{
                Nombre: 'Nombre Granja',
                Responsable: 'Nombre Responsable',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>

          <Grid xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Resumen del Lote
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} xl={7} style={{ display: 'flex' }}>
            <div
              className={clsx(
                classes.flexContainer,
                classes.paddingContainer,
                classes.flexEvenly
              )}
            >
              <div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={54}
                    titleInfo="Total Vísceras Blancas Lote"
                    avatarTotalColor="#B0413E"
                    avatarTotalSize={60}
                    avatarTextSize={22}
                    titleInfoSize={250}
                  />
                </div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={58}
                    titleInfo="Total Animales Lote"
                    avatarTotalColor="#E29662"
                    avatarTotalSize={60}
                    avatarTextSize={22}
                    titleInfoSize={250}
                  />
                </div>
              </div>
              <div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={10}
                    titleInfo="Decomisados en SBE"
                    avatarTotalColor="#F1AE6C"
                    avatarTotalSize={35}
                    avatarTextSize={22}
                    extended
                    titleInfoSize={150}
                  />
                </div>
                <div className={classes.marginTopContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={10}
                    titleInfo="Decomisados en Línea de Benefício"
                    avatarTotalColor="#FFC676"
                    avatarTotalSize={35}
                    avatarTextSize={22}
                    extended
                    titleInfoSize={150}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} xl={5}>
            <div className={classes.grayContainer}>
              <Chart
                type="doughnut"
                infoTotalData=""
                data={[
                  {
                    label: 'Aprobadas',
                    data: 165,
                    bg: '#D47D57',
                  },
                  {
                    label: 'Decomisadas',
                    data: 35,
                    bg: '#FFC676',
                  },
                ]}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.grayContainer}>
              <Typography className={classes.paperTitles}>
                Detalle del Reporte
              </Typography>
              <Box position="absolute" right={8} m={2}>
                <ExportToExcel
                  data={rows}
                  headers={columns.map((columns: any) => columns.field)}
                  fileName="Detalle-Reporte-Visceras"
                />
              </Box>
            </div>
            <div>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </ContentCard>
      <Grid container justify="flex-end" alignItems="center">
        <Box mr={2}>
          <Typography className={classes.fontSubtitle}>
            Opciones del Reporte
          </Typography>
        </Box>
        <Button
          startIcon={<i className="icon-eye" />}
          variant="contained"
          className="raleway"
          color="primary"
          onClick={openModalRangeVisceras}
        >
          Previsualizar
        </Button>
      </Grid>
      <DialogPDFViewer
        open={openFilterVisceras}
        onClose={() => setOpenFilterVisceras(false)}
        done={Boolean(imageQr)}
      >
        <MyDocVisceras qr={imageQr} graph={imageGraph} />
      </DialogPDFViewer>
    </div>
  );
};

export default index;
