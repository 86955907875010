import React, { useState } from 'react';

import { Tabs, Tab, Box } from '@material-ui/core';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import GobackLink from 'components/GobackLink';
import Shared from './Views/shared';
import SharedWithMe from './Views/sharedWithMe';
import FilterShareReports from 'components/PageComponents/SharedReports/FilterByDateRange';

import { useParams } from 'react-router';
import dayjs from 'dayjs';

import useStyles from './styles';

const TabPanel = ({ children, value, index }: any) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

const ClientsDetails = () => {
  const classes = useStyles();
  let params: any = useParams();
  const [value, setValue] = useState(0);
  const [filterType, setFilterType] = useState(params.type);
  const [range, setRange] = useState([dayjs().startOf('M'), dayjs()]);
  const handleTabChange = (_: any, value: number) => {
    setValue(value);
  };

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/network/shared-reports/details-client/1/Todos',
      title: 'Detalle por cliente',
    },
    {
      link: '/app/network/shared-reports',
      title: 'Gestion de reportes',
    },
  ];

  return (
    <>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard>
        <Box mb={2}>
          <FilterShareReports
            onChangeReportType={(e: any) => setFilterType(e)}
            onChangeRange={(e: any) => setRange(e)}
          />
        </Box>
        <div>
          <Tabs
            value={value}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {/* <Tab label="Todos" value={0} className={classes.tabSmall} /> */}
            <Tab label="Compartidos" value={0} className={classes.tabSmall} />
            <Tab
              label="Compartidos conmigo"
              value={1}
              className={classes.tabSmall}
            />
            {/* <Tab
              label="Solicitudes enviadas"
              value={3}
              className={classes.tabSmall}
            />
            <Tab
              label="Solicitudes recibidas"
              value={4}
              className={classes.tabSmall}
            /> */}
          </Tabs>
        </div>
        {/* <TabPanel value={value} index={0}>
          <Clients />
        </TabPanel> */}
        <TabPanel value={value} index={0}>
          <Shared type={filterType} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SharedWithMe type={filterType} />
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <RequestsSent />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <RequestsReceived />
        </TabPanel> */}
      </ContentCard>
    </>
  );
};

export default ClientsDetails;
