import React from 'react';
import {
  Typography,
  Tooltip,
  Fab,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
} from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import useStyles from './styles';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import Chart from 'components/Chart';
import clsx from 'clsx';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import { Link } from 'react-router-dom';
import LevelIndicator from 'components/LevelIndicator';
import ItemListInfo from 'components/ItemListInfo';
import FilterReports from 'components/FilterReports';

const index = () => {
  const columns: GridColDef[] = [
    {
      field: 'nroCava',
      headerName: 'Nro. Cava',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'nroLotes',
      headerName: 'Nro. Lotes',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'lotesCava',
      headerName: 'Lotes en la Cava',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalCanales',
      headerName: 'Total Canales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'canalesDespachadas',
      headerName: 'Canales Despachadas',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'canalesDisponibles',
      headerName: 'Canales Disponibles',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'canvasHours',
      headerName: 'Horas en Cavas',
      flex: 2,
      resizable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <div className={classes.levelIndicatorContainer}>
          <LevelIndicator
            value={11}
            levels={[[0, 12], [12, 24], [24, 36], [36]]}
          />
        </div>
      ),
    },
    {
      field: '',
      headerName: '',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`cava/details/${params.getValue(params.id, 'id')}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      nroCava: 'C30',
      nroLotes: '1',
      lotesCava: '1234, 4321',
      totalCanales: '54',
      canalesDespachadas: '0',
      canalesDisponibles: '54',
    },
    {
      id: 2,
      nroCava: 'C28',
      nroLotes: '1',
      lotesCava: '1234, 4321',
      totalCanales: '42',
      canalesDespachadas: '0',
      canalesDisponibles: '42',
    },
    {
      id: 3,
      nroCava: 'C29',
      nroLotes: '2',
      lotesCava: '1234, 4321',
      totalCanales: '83',
      canalesDespachadas: '0',
      canalesDisponibles: '83',
    },
    {
      id: 4,
      nroCava: 'C30',
      nroLotes: '1',
      lotesCava: '1234',
      totalCanales: '56',
      canalesDespachadas: '0',
      canalesDisponibles: '56',
    },
    {
      id: 5,
      nroCava: 'C42',
      nroLotes: '2',
      lotesCava: '1234, 4321',
      totalCanales: '87',
      canalesDespachadas: '0',
      canalesDisponibles: '87',
    },
    {
      id: 6,
      nroCava: 'C14',
      nroLotes: '1',
      lotesCava: '1234',
      totalCanales: '53',
      canalesDespachadas: '0',
      canalesDisponibles: '53',
    },
  ];

  const classes = useStyles();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/inventory/cava', title: 'Inventario Cavas' },
  ];

  return (
    <div>
      <div className={classes.breadContainer}>
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <Grid container spacing={2} justify="center">
        <Grid container spacing={4} item sm={12}>
          <Grid item container xs={12}>
            <FilterReports to="cava/search" />
          </Grid>
        </Grid>
        <Grid container spacing={4} item xl={6} xs={12}>
          <Grid item xs={12} sm={12} alignItems="stretch">
            <ContentCard className={classes.contentCard}>
              <div className={classes.dateContainer}>
                <Typography className={classes.paperTitles}>
                  Resumen Disponibilidad Inventario en Cavas
                </Typography>
                <Typography className={classes.dateResume}>
                  {'14/07/19'}
                </Typography>
              </div>

              <div className={classes.spaceBetweenContainer}>
                <div
                  className={clsx(
                    classes.spaceBetweenContainer,
                    classes.totalCavas
                  )}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography className={classes.roundNumber}>08</Typography>
                  </div>

                  <Typography className={classes.label}>
                    Total Cavas Ocupadas
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.columnContainer,
                    classes.cavasInventory
                  )}
                >
                  <div className={classes.spaceBetweenContainer}>
                    <Typography className={classes.label}>
                      <i className={clsx('icon-ticket', classes.iconLines)}></i>
                      Nro. Lotes
                    </Typography>
                    <Typography className={classes.labelBold}>10</Typography>
                  </div>
                  <div className={classes.spaceBetweenContainer}>
                    <Typography className={classes.label}>
                      <i
                        className={clsx('icon-canales', classes.iconLines)}
                      ></i>
                      Total Canales
                    </Typography>
                    <Typography className={classes.labelBold}>460</Typography>
                  </div>
                  <div className={classes.spaceBetweenContainer}>
                    <Typography className={classes.label}>
                      <i className={clsx('icon-weight', classes.iconLines)}></i>
                      {'Peso Total PCC'}
                    </Typography>
                    <Typography className={classes.labelBold}>
                      {'56.000,00'}
                    </Typography>
                  </div>
                </div>
              </div>
            </ContentCard>
          </Grid>
          <Grid item xs={12} xl={12}>
            <ContentCard className={classes.contentCard}>
              <div className={classes.dateContainer}>
                <Typography className={classes.paperTitles}>
                  Promedios en Cavas
                </Typography>
                <Typography className={classes.dateResume}>
                  {'14/07/19'}
                </Typography>
              </div>
              <div>
                <div className={classes.flexContainer}>
                  <ItemListInfo titleInfo="Promedio %Magro" numberInfo="60%" />
                  <ItemListInfo
                    titleInfo="Rendimiento CC"
                    numberInfo="56,66%"
                  />
                </div>
                <div className={classes.flexContainer}>
                  <ItemListInfo
                    titleInfo="Promedio LM"
                    numberInfo="42,22 m.m"
                  />
                  <ItemListInfo
                    titleInfo="Tiempo Prom. Espera"
                    numberInfo="24:00:00"
                  />
                </div>
                <div className={classes.flexContainer}>
                  <ItemListInfo
                    titleInfo="Promedio GD"
                    numberInfo="42,22 m.m"
                  />
                  <ItemListInfo
                    titleInfo="Temperatura Prom."
                    numberInfo="14 °C"
                  />
                </div>
              </div>
            </ContentCard>
          </Grid>
        </Grid>
        <Grid container item xl={6} xs={12}>
          <Grid item xs={12} sm={12} alignItems="stretch">
            <ContentCard className={classes.contentCard}>
              <div className={classes.dateContainer}>
                <Typography className={classes.paperTitles}>
                  Clasificación Canales Según SEUROP
                </Typography>
                <Typography className={classes.dateResume}>
                  {'14/07/19'}
                </Typography>
              </div>
              <div>
                <Chart
                  type="doughnut"
                  infoTotalData="Canales"
                  data={[
                    {
                      label: 'S - Superior',
                      data: 114,
                      bg: '#FFC676',
                    },
                    {
                      label: 'E - Excelente',
                      data: 71,
                      bg: '#F1AE6C',
                    },
                    {
                      label: 'U - Muy Buena - Estándar',
                      data: 28,
                      bg: '#E29662',
                    },
                    {
                      label: 'R - Buena',
                      data: 43,
                      bg: '#D47D57',
                    },
                    {
                      label: 'O - Menos Buena',
                      data: 14,
                      bg: '#BE5948',
                    },
                    {
                      label: 'R - Buena',
                      data: 14,
                      bg: '#B0413E',
                    },
                  ]}
                />
              </div>
            </ContentCard>
          </Grid>
          <Grid item container xs={12} sm={12}>
            <ContentCard className={classes.contentCard}>
              <div className={classes.spaceBetweenContainer}>
                <Typography className={classes.paperTitles}>
                  Inventario de Canales Refrigeradas
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="cava/real-time"
                >
                  Ver
                </Button>
              </div>
            </ContentCard>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={12}>
          <ContentCard className={classes.contentCard}>
            <div className={classes.spaceBetweenContainer}>
              <Typography className={classes.paperTitles}>
                Inventario de Canales Refrigeradas
              </Typography>
              <Typography>08 Resultados</Typography>
            </div>
            <div className={classes.dataGridContainer}>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
              />
            </div>
          </ContentCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default index;
