import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  TextField,
  Grow,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router';

import unmaskPhone from 'lib/helpers/unmaskPhone';
import { CompanyValidation } from 'lib/validations/TransportValidation';
import constants from 'config/constants';

import PhoneNumber from 'components/PhoneNumber';
import Direction from 'components/Direction/index';
import GobackLink from 'components/GobackLink';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import MainTitle from 'components/MainTitle';
import Loader from 'components/Loader';

import { UPDATE_COMPANY } from 'core/graphql/mutations/transport';
import { GET_COMPANY } from 'core/graphql/queries/transport';

import useStyles from './styles';

type CompononentProps = {
  id: number;
};

const DetailCompany = ({ id }: CompononentProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '#',
      title: 'Detalle Empresa Transportadora',
    },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];

  const {
    data: dataCompany,
    loading: loadingCompany,
    error: errorCompany,
    refetch,
  } = useQuery(GET_COMPANY, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idTransportadora: id },
  });

  const result = dataCompany?.getDetailCompanyConveyor?.data;

  const [putCompany, { loading: loadingPutCompany }] = useMutation(
    UPDATE_COMPANY,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const updateCompany = (data: any) => {
    putCompany({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.putCompanyConveyor.data.success === 1) {
          enqueueSnackbar('Datos actualizados exitosamente.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
        } else {
          enqueueSnackbar(mutationResult.data.putCompanyConveyor.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        IdEmpresaTransportadora: result.idEmpresaTransportadora,
        indicativoTelefono: Number(data.indicativePhone),
        telefono: data.phone,
        extension: data.ext,
        indicativoCelular: Number(data.indicativeMobile),
        celular: data.mobile,
        email: data.email,
        tipoDireccion: Number(data.directionType),
        idTipoVia: Number(data.viaType),
        idCiudad: Number(data.city),
        numeroVia: String(data.viaNumber),
        apendiceVia: data.apendNumber,
        numeroCruce: String(data.cruceNumber),
        apendiceCruce: data.apendCruceNumber ?? '',
        metrosEsquina: String(data.cornerMeters),
        adicional: data.aditional,
        codigoPostal: data.postalCode,
      },
    });
  };

  const formikCompany = useFormik({
    initialValues: {
      companyName: '',
      nit: '',
      verificationDigit: '',
      country: constants.DEFAULT_COUNTRY_ID,
      departament: constants.DEFAULT_DEPARTMENT_ID,
      city: constants.DEFAULT_CITY_ID,
      indicativePhone: constants.DEFAULT_DEPARTMENT_ID,
      phone: '',
      ext: '',
      indicativeMobile: constants.DEFAULT_COUNTRY_ID,
      mobile: '',
      email: '',
      directionType: 1,
      viaType: '',
      viaNumber: '',
      apendNumber: '',
      cruceNumber: '',
      apendCruceNumber: '',
      cornerMeters: '',
      aditional: '',
      postalCode: '',
    },
    validationSchema: CompanyValidation,
    validateOnChange: false,
    onSubmit: (values) => {
      updateCompany(values);
    },
  });

  useEffect(() => {
    if (!loadingCompany) {
      formikCompany.setValues({
        companyName: result?.razonSocial,
        nit: result?.nit,
        verificationDigit: result?.digitoVerificacion,
        country: result?.idPais,
        departament: result?.idDepartamento,
        city: result?.idCiudad,
        indicativePhone: result?.indicativoTelefono,
        phone: result?.telefono,
        ext: result?.extension,
        indicativeMobile: result?.indicativoCelular,
        mobile: result?.celular,
        email: result?.email,
        directionType: result?.tipoDireccion,
        viaType: result?.idTipoVia,
        viaNumber: result?.numeroVia,
        apendNumber: result?.apendiceVia,
        cruceNumber: result?.numeroCruce,
        apendCruceNumber: result?.apendiceCruce ?? '',
        cornerMeters: result?.metrosEsquina,
        aditional: result?.adicional,
        postalCode: result?.codigoPostal,
      });
    }
  }, [loadingCompany]);

  const initialData = {
    city: Number(result?.idCiudad),
    indicativePhone: Number(result?.indicativoTelefono),
    phone: result?.telefono,
    ext: result?.extension,
    indicativeMobile: Number(result?.indicativoCelular),
    mobile: result?.celular,
    email: result?.email,
    directionType: Number(result?.tipoDireccion),
    viaType: Number(result?.idTipoVia),
    viaNumber: String(result?.numeroVia),
    apendNumber: result?.apendiceVia,
    cruceNumber: String(result?.numeroCruce),
    apendCruceNumber: result?.apendiceCruce ?? '',
    cornerMeters: String(result?.metrosEsquina),
    aditional: result?.adicional,
    postalCode: result?.codigoPostal,
  };

  const dataUpdate = {
    city: Number(formikCompany.values.city),
    indicativePhone: Number(formikCompany.values.indicativePhone),
    phone: formikCompany.values.phone,
    ext: formikCompany.values.ext,
    indicativeMobile: Number(formikCompany.values.indicativeMobile),
    mobile: formikCompany.values.mobile,
    email: formikCompany.values.email,
    directionType: Number(formikCompany.values.directionType),
    viaType: Number(formikCompany.values.viaType),
    viaNumber: String(formikCompany.values.viaNumber),
    apendNumber: formikCompany.values.apendNumber,
    cruceNumber: String(formikCompany.values.cruceNumber),
    apendCruceNumber: formikCompany.values.apendCruceNumber,
    cornerMeters: String(formikCompany.values.cornerMeters),
    aditional: formikCompany.values.aditional,
    postalCode: formikCompany.values.postalCode,
  };

  useEffect(() => {
    if (!loadingPutCompany) {
      refetch();
    }
  }, [loadingPutCompany]);

  return (
    <>
      <Loader visible={loadingCompany}>Cargando...</Loader>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Box mb={2}>
          <Grid container justifyContent="center">
            <MainTitle>Detalle Empresa de Transporte</MainTitle>
          </Grid>
        </Box>

        <Grow in timeout={800}>
          <form onSubmit={formikCompany.handleSubmit} style={{ padding: 40 }}>
            <Box marginBottom={2}>
              <MainTitle fontSize="h6">Datos Generales de la Empresa</MainTitle>
            </Box>

            <Grid>
              <Box mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Razón Social"
                      variant="outlined"
                      size="small"
                      name="companyName"
                      value={formikCompany.values.companyName}
                      onChange={formikCompany.handleChange}
                      error={
                        formikCompany.touched.companyName &&
                        Boolean(formikCompany.errors.companyName)
                      }
                      helperText={
                        formikCompany.touched.companyName &&
                        formikCompany.errors.companyName
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="NIT"
                      variant="outlined"
                      size="small"
                      type="number"
                      name="nit"
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 9);
                      }}
                      value={formikCompany.values.nit}
                      onChange={formikCompany.handleChange}
                      error={
                        formikCompany.touched.nit &&
                        Boolean(formikCompany.errors.nit)
                      }
                      helperText={
                        formikCompany.touched.nit && formikCompany.errors.nit
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Dig. Verificación"
                      variant="outlined"
                      size="small"
                      name="verificationDigit"
                      value={formikCompany.values.verificationDigit}
                      onChange={formikCompany.handleChange}
                      error={
                        formikCompany.touched.verificationDigit &&
                        Boolean(formikCompany.errors.verificationDigit)
                      }
                      helperText={
                        formikCompany.touched.verificationDigit &&
                        formikCompany.errors.verificationDigit
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box marginBottom={3} marginTop={2}>
                <MainTitle fontSize="h6">Datos de Contacto</MainTitle>
              </Box>

              <Box mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PhoneNumber
                      type="landline"
                      extension
                      names={{
                        indicative: 'indicativePhone',
                        phone: 'phone',
                        ext: 'ext',
                      }}
                      values={{
                        indicative: formikCompany.values.indicativePhone,
                        phone: (formikCompany.values.phone = unmaskPhone(
                          formikCompany.values.phone
                        )),
                        ext: formikCompany.values.ext,
                      }}
                      onChange={formikCompany.handleChange}
                      errors={{
                        indicative:
                          !!formikCompany.errors.indicativePhone &&
                          !!formikCompany.touched.indicativePhone,
                        phone: formikCompany.errors.phone as string,
                        ext: formikCompany.errors.ext as string,
                      }}
                      helperTexts={{
                        indicative: formikCompany.errors
                          .indicativePhone as string,
                        phone: formikCompany.errors.phone as string,
                        ext: formikCompany.errors.ext as string,
                      }}
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PhoneNumber
                      type="mobile"
                      names={{
                        indicative: 'indicativeMobile',
                        phone: 'mobile',
                      }}
                      values={{
                        indicative: formikCompany.values.indicativeMobile,
                        phone: (formikCompany.values.mobile = unmaskPhone(
                          formikCompany.values.mobile
                        )),
                      }}
                      onChange={formikCompany.handleChange}
                      errors={{
                        indicative:
                          !!formikCompany.errors.indicativeMobile &&
                          !!formikCompany.touched.indicativeMobile,
                        phone: formikCompany.errors.mobile as string,
                      }}
                      helperTexts={{
                        indicative: formikCompany.errors
                          .indicativeMobile as string,
                        phone: formikCompany.errors.mobile as string,
                      }}
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2}>
                <Grid container spacing={1}>
                  <TextField
                    fullWidth
                    label="Correo Electrónico"
                    variant="outlined"
                    size="small"
                    type="email"
                    name="email"
                    value={formikCompany.values.email}
                    onChange={formikCompany.handleChange}
                    error={
                      formikCompany.touched.email &&
                      Boolean(formikCompany.errors.email)
                    }
                    helperText={
                      formikCompany.touched.email && formikCompany.errors.email
                    }
                    required
                  />
                </Grid>
              </Box>

              <Box marginTop={2}>
                <MainTitle fontSize="h6">Datos de Ubicación</MainTitle>
              </Box>

              <Box mt={3}>
                <Direction
                  names={{
                    directionType: 'directionType',
                    viaType: 'viaType',
                    viaNumber: 'viaNumber',
                    apendNumber: 'apendNumber',
                    cruceNumber: 'cruceNumber',
                    apendCruceNumber: 'apendCruceNumber',
                    cornerMeters: 'cornerMeters',
                    aditional: 'aditional',
                    postalCode: 'postalCode',
                    idPais: 'country',
                    idDepartamento: 'departament',
                    city: 'city',
                  }}
                  values={{
                    directionType: formikCompany.values.directionType,
                    viaType: formikCompany.values.viaType,
                    viaNumber: formikCompany.values.viaNumber,
                    apendNumber: formikCompany.values.apendNumber,
                    cruceNumber: formikCompany.values.cruceNumber,
                    apendCruceNumber: formikCompany.values.apendCruceNumber,
                    cornerMeters: formikCompany.values.cornerMeters,
                    aditional: formikCompany.values.aditional,
                    postalCode: formikCompany.values.postalCode,
                    idPais: Number(formikCompany.values.country),
                    idDepartamento: Number(formikCompany.values.departament),
                    city: Number(formikCompany.values.city),
                  }}
                  errors={{
                    directionType: formikCompany.errors.directionType,
                    viaType: formikCompany.errors.viaType,
                    viaNumber: formikCompany.errors.viaNumber,
                    apendNumber: formikCompany.errors.apendNumber,
                    cruceNumber: formikCompany.errors.cruceNumber,
                    apendCruceNumber: formikCompany.errors.apendCruceNumber,
                    cornerMeters: formikCompany.errors.cornerMeters,
                    aditional: formikCompany.errors.aditional,
                    postalCode: formikCompany.errors.postalCode,
                    idPais: formikCompany.errors.country as string,
                    idDepartamento: formikCompany.errors.departament as string,
                    city: formikCompany.errors.city as string,
                  }}
                  setFieldValue={formikCompany.setFieldValue}
                  handleChange={formikCompany.handleChange}
                  handleBlur={formikCompany.handleBlur}
                  touched={formikCompany.touched}
                />
              </Box>
            </Grid>

            <Grid
              container
              justify="center"
              spacing={2}
              style={{ marginTop: 10 }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.goBack()}
                >
                  Volver
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  endIcon={
                    loadingPutCompany && (
                      <CircularProgress
                        className={classes.loadingColor}
                        thickness={5}
                        size={20}
                      />
                    )
                  }
                  disabled={
                    JSON.stringify(initialData) === JSON.stringify(dataUpdate)
                  }
                >
                  {loadingPutCompany ? 'Actualizando...' : 'Actualizar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grow>
      </ContentCard>
    </>
  );
};
export default DetailCompany;
