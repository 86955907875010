import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: '8px 0',
  }, 
  cardHeader:{
    display:'flex', 
    justifyContent:'space-between'
  }, 
  title:{
    fontWeight:'bold'
  }, 
  search:{
    width:'30%'
  },
  containerTab:{
    padding: 12
  },
  tabSmall:{
    fontWeight:900,
    minWidth: 50,
  },
  tabsChild:{
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }, 
  tableGrid:{
    height: 300,  
    marginTop:24,      
  },
  dataGrid:{
    border:'none', 
    display:'flex',
    "& .MuiBox-root":{
      margin:'20px 0'
    },
    "& .MuiDataGrid-columnSeparator":{
      display:'none'
    },
  },
  headerTableGrid:{
    fontSize:14, 
    color: '#AFAFAF',
    fontFamily:'Roboto',
  },
  avatar:{
    width: '30px', 
    height: '30px', 
    fontSize: '12px', 
    marginRight: 8
  }, 
  btnDetails:{
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "&:hover":{
      color: '#fff'
    }
  },
  shareBtn:{
    background:theme.palette.primary.main,
    color: '#fff',
    fontWeight:'bold',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  }
}))

export default useStyles