import { gql } from "apollo-boost";

export const INVITE_CLIENT = gql`
  mutation postRequestUserInvitation(
    $idTipoDocumento: Int!,
    $numeroDocumento: String!,
    $digitoVerificacion: Int!,
    $razonSocial: String!,
    $primerNombre: String!, 
    $segundoNombre: String, 
    $primerApellido: String!,
    $segundoApellido: String,
    $indicativoTelefono: Int!,
    $telefono: String!,
    $extension: String,
    $indicativoCelular: Int!,
    $celular: String!
    $email: String!,
    $idCiudad: Int!,
    $idTipoRelacion: Int!
  ) {
    postRequestUserInvitation(input: {
      idTipoDocumento: $idTipoDocumento,
      numeroDocumento: $numeroDocumento,
      digitoVerificacion: $digitoVerificacion,
      razonSocial: $razonSocial,
      primerNombre: $primerNombre,
      segundoNombre: $segundoNombre,
      primerApellido: $primerApellido,
      segundoApellido: $segundoApellido,
      indicativoTelefono: $indicativoTelefono,
      telefono: $telefono,
      extension: $extension,
      indicativoCelular: $indicativoCelular,
      celular: $celular,
      email: $email,
      idCiudad: $idCiudad,
      idTipoRelacion: $idTipoRelacion
    }){
      data{
        message
        success
        idSolicitud
      }
    }
  }
`;