import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import config from 'config/secrets';

export default new ApolloClient({
  //@ts-ignore
  link: createUploadLink({
    uri: config.BACKEND.dev.graphqlUri,
  }),
  cache: new InMemoryCache(),
});
