import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 50px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  email_check_img: {
    width: 80,
  },
  alert_content: {
    textAlign: 'center',
    padding: theme.spacing(3, 2),
    '& .MuiTypography-h5': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontWeight: 'bold',
      fontFamily: 'Raleway',
      fontSize: 16,
    },
    '& .MuiTypography-colorTextSecondary': {
      marginBottom: theme.spacing(0.7),
    },
    '& .MuiButton-colorPrimary': {
      fontWeight: 'bold',
    },
  },
}));

export { useStyles as default };
