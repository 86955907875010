import React, { useState } from 'react';

import { Image, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import whiteTicket from './../../../static/img/reportsIcons/whiteTicket';

import styles from './styles';
import ListData from '../ListData';

interface Props {
  loteIP: string;
  fecha: string;
  responsableLB?: string;
  responsableSBE?: string;
  data: any;
}

const DetailPostMTable = ({
  loteIP,
  fecha,
  responsableLB,
  responsableSBE,
  data,
}: Props) => {
  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '5px',
        }}
      >
        <View style={{ width: '48.3%', justifyContent: 'center' }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '5px',
            }}
          >
            <View style={{ width: '48.3%' }}>
              <View style={styles.backgroundresumetitleImage}>
                <View style={styles.imageicon}>
                  <Image src={whiteTicket} />
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <View style={{ width: '58.3%', alignItems: 'center' }}>
                    <Text>LoteIP</Text>
                  </View>
                  <View style={{ width: '38.3%', fontWeight: 'normal' }}>
                    <Text>{loteIP}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: '48.3%' }}>
              <View style={styles.backgroundresumetitleImage}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <View style={{ width: '35.3%', alignItems: 'center' }}>
                    <Text>Fecha</Text>
                  </View>
                  <View style={{ width: '50.3%', fontWeight: 'normal' }}>
                    <Text>{fecha}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{ width: '48.3%', paddingLeft: '40px', paddingRight: '15px' }}
        >
          <ListData
            widthContainer="100%"
            items={{
              'Responsable LB': 'Paula Andrea Bolivar Uribe',
              'Responsable SBE': 'Paula Andrea Bolivar Uribe',
            }}
          />
        </View>
      </View>

      <View style={{ width: '100%', marginTop: '5px' }}>
        <View style={[styles.tableMagro, { marginBottom: 5 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '5px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Canal ID</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Cantidad</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Parte Decomisada
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Causa</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Decomiso (Kg)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Observaciones
              </Text>
            </View>
          </View>
          {data?.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.canal}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.cantidad}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.parte}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.causa}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.decomiso}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 92.5, width: 92.5 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.observaciones}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={{ flexDirection: 'row', width: '100%', marginBottom: '10px' }}
        >
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 92.5,
                width: 92.5,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 277.5, width: 277.5, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 92.5, width: 92.5, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              0,00
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 92.5, width: 92.5, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
        </View>
      </View>
    </View>
  );
};

export default DetailPostMTable;
