import React from 'react';

import { GenerateRoutes as Routes } from 'routes';

import PagesContainer from 'components/PagesContainer';

import { Main, Search, routes } from './views';

const Invitations = () => {
  return (
    <PagesContainer>
      <Routes MainPage={Main} SearchPage={Search} nested={routes} />
    </PagesContainer>
  );
};

export default Invitations;
