import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  navbar: {
    background: '#F2F2F2',
    position: 'relative',
    display: 'flex'
  },
  toolbar: {
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  brand: {
    '& img': {
      width: 150
    }
  },
  leftItems: {
    display: 'flex',
    '& > *': {
      marginLeft: theme.spacing(0.5)
    }
  },
  switcher: {
    width: 300
  },
  switcherSubheader: {
    lineHeight: 'inherit'
  },
  switcherPaper: {
    width: 250
  },
  roleItem: {
    width: 'auto'
  },
  roleAvatar: {
    background: theme.palette.primary.main
  },
  roleIcon: {
    fontSize: 28
  },
  nameTitle: {
    color: theme.palette.text.hint,
    fontWeight: 'bold',
    fontSize: 13
  },
  roleTitle: {
    color: theme.palette.primary.main,
    fontSize: 11
  },
  others:{
    color: '#767676', 
    fontSize: 11
  },
  textTitle: {
    marginRight: theme.spacing(1.5), 
    "& .MuiListItem-root":{
      padding: 0, 
    }
  },
  profileLink: {
    marginTop: theme.spacing(1),
    '&:hover': {
      color: theme.palette.common.black
    }
  },
  lowIcon: {
    color: 'rgba(0, 0, 0, .54)'
  },
  iconBtnContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  btn: {
    padding: theme.spacing(0, 2),
    borderRadius: 0,
    textTransform: 'inherit',
    fontSize: 12, 
    fontFamily: 'Roboto', 
    fontWeight: 'normal',
    textAlign: 'center'
  },
  notifications: {
    width: 300
  },
  adminNotifications: {
    borderRadius: 50,
    textTransform: 'inherit',
    padding: theme.spacing(0.5, 3)
  },
  centerItem: {
    justifyContent: 'center',
    paddingTop: theme.spacing(1.5),
    '&:hover': {
      background: theme.palette.common.white
    }
  },
}))

export default useStyles