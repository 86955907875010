

import { makeStyles } from "@material-ui/core";
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  borderRadiudDialog: {
    "& .MuiDialog-paperWidthLg": {
      borderRadius: '20px',
      padding: '20px'
    }
  },
  liContainer:{
    listStyle: 'none',
  },
  spanTitle: {
    fontWeight: 'bolder',
    color: '#767676',
  },
  gridContainer:{
    margin: '15px'
  },
  paperContainer: {
     width: '31%',
     minHeight: '80px',
     margin: '10px',
     display: 'flex',
     alignItems: 'center',
     padding: '5px'
  },
  fontTitle: {
    fontWeight: 'bold',
  },
  root: {
    maxWidth: 410
  },
  media: {
    height: 140
  },
  avatar: {
    width: 55,
    height: 55,
    marginRight: 15,
    // marginLeft: 10,
  },
  cardTitle: {
    fontWeight: "bold",
    fontSize: 22,
    borderBottom: '2px solid #DD9835'
  },
  title: {
    fontWeight: "bold",
    color: "#AFAFAF",
    fontSize: 17,
  },
  subtitle: {
    fontWeight: "bold",
    color: "#AFAFAF",
    fontSize: 15,
    marginRight: 5
  },
  closeButton: {
    width: 10,
    height: 10,
    margin: 10
  },
  cardM: {
    maxWidth: 350,
    margin: 15,
    borderRadius: '20px',
    cursor: 'pointer',
  },
  elevationActive: {
    margin: 15,
    borderRadius: '20px',
    cursor: 'pointer',
  },
  fabIconAdd:{
    width: '35px',
    height: '36px',
    marginLeft: 10,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
  fabIconDelete:{
    width: '35px',
    height: '36px',
    marginLeft: 10,
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900],
    },
  },
  divContainerPlate:{
    marginTop: "10px"
  },
  fontName:{
    color: '#DD9835',
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  gridButton: {
    justifyContent: 'flex-end', 
    display: 'flex'
  },
  hiddenDialog: {
    '& .MuiDialog-container':{
      display: 'none'
    },
    '& .MuiBackdrop-root':{
      display: 'none'
    }
  },
  cardGridContainer:{
    padding: '0px 20px'
  }

}));

export { useStyles as default }