import {
  AppBar,
  Hidden,
  Theme,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import useManageUI from 'lib/hooks/useManageUI';
import constants from 'config/constants';
import useSignOut from 'lib/hooks/useSignOut';

import VirtualScrollbars from 'components/VirtualScrollbars';
import PinCheckbox from 'components/PinCheckbox';

import UserNavbarHeader from './UserNavbarHeader';
import Logo from './Logo';
import NavigationContent from './NavigationContent';
import useStlyes from '../styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'shrink',
    height: '100%',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  header: {
    height: 64,
    minHeight: 64,
    maxHeight: 64,
    background: '#404040',
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: '1.2rem',
  },
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    background:
      'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 10px',
    backgroundAttachment: 'local, scroll',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  controls: {
    height: 64,
    maxHeight: 64,
  },
  controls_container: {
    position: 'absolute',
  },
}));

const NavbarLayout = ({
  className,
  withoutPin = false,
}: {
  className?: string;
  withoutPin?: boolean;
}) => {
  const { folded, width } = useSelector((state: any) => state.ui.sidebar);
  const classes = useStyles({ width });
  const otherclasses = useStlyes();
  const { signOut } = useSignOut();
  const { toggleSidebarFolded } = useManageUI();

  return (
    <div className={clsx(classes.root, className)}>
      <AppBar position="static" elevation={0} className={classes.header}>
        <Hidden mdDown>
          <Logo />
        </Hidden>
        <Hidden lgUp>
          <Logo size="large" />
        </Hidden>
        {!withoutPin && (
          <PinCheckbox onClick={toggleSidebarFolded} value={!folded} />
        )}
      </AppBar>
      <VirtualScrollbars className={classes.content}>
        <div>
          <UserNavbarHeader />
          <NavigationContent />
        </div>
        <Box display="flex" flexDirection="column">
          <Box className={otherclasses.btns}>
            <Button
              variant="outlined"
              color="inherit"
              className={otherclasses.down}
              component={Link}
              to="/app/profile/unsubscribe"
            >
              Darse de baja
            </Button>
            <Button
              variant="contained"
              className={otherclasses.logout}
              onClick={() => signOut()}
            >
              Cerrar sesión
            </Button>
          </Box>
          <Typography variant="caption" className={otherclasses.version}>
            © Infoporcinos V - {constants.VERSION}
          </Typography>
        </Box>
      </VirtualScrollbars>
    </div>
  );
};

export default NavbarLayout;
