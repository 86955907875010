import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  Tooltip,
  Chip,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import domtoimage from 'dom-to-image';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import ContentCard from 'components/ContentCard';
import PaginationGrid from 'components/datagrid-custom-components/TableFooter/index';
import DialogNotification from './DialogNotification/index';
import GobackLink from 'components/GobackLink';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import DialogPDFViewer from './../../../../../../components/DialogPDFViewer/index';

import { ReportFilter, MyDocGSMI } from '../../Reports/ReportFilter';

import useStyles from './styles';

const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

// Datos quemados
const rows = [
  {
    id: 1,
    date: '13/07/2021 02:00:00',
    guide: 654987,
    lot: 1234,
    producer: 'Nombre productor',
    farm: 'Nombre Granja',
    license_plate: 'SMV195',
    animals: 25,
  },
  {
    id: 2,
    date: '13/07/2021 02:00:00',
    guide: 789654,
    lot: 7848,
    producer: 'Don Juan',
    farm: 'El Machetico',
    license_plate: 'DJU202',
    animals: 54,
  },
  {
    id: 3,
    date: '13/07/2021 02:00:00',
    guide: 898951,
    lot: 1851,
    producer: 'Sebastian M',
    farm: 'House Porki',
    license_plate: 'SMV195',
    animals: 35,
  },
  {
    id: 4,
    date: '13/07/2021 02:00:00',
    guide: 898951,
    lot: 1851,
    producer: 'Sebastian M',
    farm: 'House Porki',
    license_plate: 'SMV195',
    animals: 35,
  },
  {
    id: 5,
    date: '13/07/2021 02:00:00',
    guide: 898951,
    lot: 1851,
    producer: 'Sebastian M',
    farm: 'House Porki',
    license_plate: 'SMV195',
    animals: 35,
  },
  {
    id: 6,
    date: '13/07/2021 02:00:00',
    guide: 898951,
    lot: 1851,
    producer: 'Sebastian M',
    farm: 'House Porki',
    license_plate: 'SMV195',
    animals: 35,
  },
  {
    id: 7,
    date: '13/07/2021 02:00:00',
    guide: 898951,
    lot: 1851,
    producer: 'Sebastian M',
    farm: 'House Porki',
    license_plate: 'SMV195',
    animals: 35,
  },
  {
    id: 8,
    date: '13/07/2021 02:00:00',
    guide: 898951,
    lot: 1851,
    producer: 'Sebastian M',
    farm: 'House Porki',
    license_plate: 'SMV195',
    animals: 35,
  },
  {
    id: 9,
    date: '13/07/2021 02:00:00',
    guide: 898951,
    lot: 1851,
    producer: 'Sebastian M',
    farm: 'House Porki',
    license_plate: 'SMV195',
    animals: 35,
  },
];

const pageBreadcrumbs: BreadcrumbProps[] = [
  {
    link: '/app/reports/mobilization-guides/search',
    title: 'Guías de Movilización',
  },
  { link: '/app/reports/mobilization-guides', title: 'Mis Resportes' },
];

const Search = () => {
  const classes = useStyles();
  const url = new URLSearchParams(window.location.search);
  const [notification, setNotification] = useState(false);
  const initialDate: any = React.useState(url.get('initialdate'));
  const finalDate: any = React.useState(url.get('finaldate'));
  const [type] = useState(url.get('type'));
  const [openFilterGSMI, setOpenFilterGSMI] = useState(false);
  const [imageQr, setImageQr] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenQrGSMI');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeGSMI = () => {
    // generateScreenshot();
    // screenshotGraph();
    // screenshotGraphTwo();
    setOpenFilterGSMI(true);
  };

  useEffect(() => {
    generateScreenshot();
  }, [openFilterGSMI]);

  const getDateFilter = () => {
    switch (type) {
      case 'lote':
        return url.get('code');
      case 'today':
        return dayjs().format('DD/MM/YYYY');
      case 'week':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      case 'range':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      default:
        return 'El tipo de filtro solicitado no existe';
    }
  };

  const getInfoFilter = () => {
    switch (type) {
      case 'lote':
        return (
          <>
            <Typography className={classes.filterType}>Lote:</Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{url.get('code')}</span>
            </Typography>
          </>
        );
      case 'today':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">
                {dayjs().format('dddd, D MMMM YYYY')} (Hoy)
              </span>
            </Typography>
          </>
        );
      case 'week':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{'Semana' + ' ' + dayjs().week()}</span>{' '}
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      case 'range':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      default:
        return (
          <Typography className={classes.filterType}>
            El tipo de filtro solicitado no existe
          </Typography>
        );
    }
  };

  const handleNotification = () => {
    setNotification(!notification);
  };

  const ListGuide: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 1,
    },
    {
      field: 'guide',
      headerName: 'Nro. Guía',
      flex: 0.8,
    },
    {
      field: 'lot',
      headerName: 'Lote IP',
      flex: 0.8,
    },
    {
      field: 'producer',
      headerName: 'Productor',
      flex: 1.2,
    },
    {
      field: 'farm',
      headerName: 'Granja Origen',
      flex: 1.2,
    },
    {
      field: 'license_plate',
      headerName: 'Placa Vehículo',
      flex: 1,
    },
    {
      field: 'animals',
      headerName: 'Animales',
      flex: 0.9,
    },
    {
      field: 'ver',
      headerName: ' ',
      flex: 0.8,
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="Ver detalles" arrow>
            <Box mr={1}>
              <Chip
                color="primary"
                label="Ver"
                size="small"
                clickable
                className={classes.colorChip}
                component={Link}
                to={`/app/reports/mobilization-guides/details/${params.getValue(
                  params.id,
                  'id'
                )}`}
              />
            </Box>
          </Tooltip>
        </>
      ),
    },
  ];

  const customPagination = () => {
    return (
      <Box mt={2}>
        <PaginationGrid />
      </Box>
    );
  };

  return (
    <>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportFilter />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <Grid container>
        <ContentCard className={classes.cardContainer}>
          <Grid item container>
            <Grid item container>
              <Box mr={5}>
                <Typography className={classes.title}>
                  Búsqueda: Fecha Puntual
                </Typography>
              </Box>
              {!url.get('code') && (
                <Button
                  variant="contained"
                  className="raleway"
                  color="primary"
                  onClick={openModalRangeGSMI}
                >
                  Generar Reporte
                </Button>
              )}
            </Grid>
          </Grid>
          <Box borderBottom={1} mt={3} mb={3} className={classes.colorHr}>
            <Grid item container>
              <Grid item container xs={6} alignItems="flex-end">
                {getInfoFilter()}
              </Grid>
              <Grid
                item
                container
                xs={6}
                justify="flex-end"
                alignItems="center"
              >
                <Box
                  component="i"
                  className="icon-document"
                  fontSize={25}
                  color="#767676"
                />
                <Typography className={classes.result}>
                  09 Resultados
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box paddingLeft={3} paddingRight={3}>
            <DataGrid
              rows={rows}
              columns={ListGuide}
              autoHeight
              pageSize={20}
              className={classes.dataGrid}
              components={{
                Footer: customPagination,
              }}
              hideFooterSelectedRowCount
              rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
            />
          </Box>
        </ContentCard>
      </Grid>

      <DialogNotification
        icon="success"
        title="¡Impresión Realizada!"
        message="Se ha impreso el formato correctamente."
        open={notification}
        handleClose={handleNotification}
      />
      <DialogPDFViewer
        open={openFilterGSMI}
        onClose={() => setOpenFilterGSMI(false)}
        done={Boolean(imageQr)}
      >
        <MyDocGSMI qr={imageQr} range={getDateFilter()} />
      </DialogPDFViewer>
    </>
  );
};

export default Search;
