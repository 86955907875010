import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Tooltip,
  Fab,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import domtoimage from 'dom-to-image';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import FilterReports from 'components/FilterReports';
import ItemListInfo from 'components/ItemListInfo';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import CurrentDateIndicator from 'components/CurrentDateIndicator';
import Chart from 'components/Chart';
import Results from 'components/Results';

import DialogPDFViewer from './../../../../../../components/DialogPDFViewer/index';

import {
  MyDocFilterBalanceTicket,
  ReportFilter,
  GraphRangeTicket,
  GraphRangeTicketTwo,
} from './../../Reports/ReportFilter';

import useStyles from './styles';

const index = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [openFilterTicket, setOpenFilterTicket] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');
  const [imageGraphTwo, setImageGraphTwo] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenQrTicketFilter');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraph = () => {
    const graph: any = document.getElementById('graprangeticket');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraphTwo = () => {
    const graph: any = document.getElementById('graprangeticketwo');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraphTwo(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeTicket = () => {
    setOpenFilterTicket(true);
  };

  useEffect(() => {
    generateScreenshot();
    screenshotGraph();
    screenshotGraphTwo();
  }, [openFilterTicket]);

  const columns: GridColDef[] = [
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'productor',
      headerName: 'Productor',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'animals',
      headerName: 'Animales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'cam',
      headerName: 'Caídos / Agitados / Muertos',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'weight',
      headerName: 'Peso Total (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'weightprom',
      headerName: 'Peso Prom. (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: '',
      headerName: '',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/bascule/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 2,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 3,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 4,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 5,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 6,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
    {
      id: 7,
      loteIP: '1234',
      date: '01/10/2019',
      productor: 'Nombre Productor',
      animals: '24',
      cam: '0',
      weight: '0.00',
      weightprom: '0.00',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/bascule',
      title: 'Tiquete de Báscula',
    },

    { link: '/app/reports', title: 'Mis Reportes' },
  ];

  const initDate = dayjs().startOf('month').format('YYYY-MM-DDT00:00:00-05:00');
  const current = dayjs().format('YYYY-MM-DDT23:59:59-05:00');
  const currentR = dayjs().format('DD/MM/YYYY');

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportFilter />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphRangeTicket />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphRangeTicketTwo />
      </div>

      <div className={classes.breadContainer}>
        <CurrentDateIndicator initialDate={initDate} finalDate={current} />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <FilterReports to="/app/reports/bascule/search" />

      <Grid container spacing={2} direction="row">
        <Grid item xs={12} xl={6} alignItems="stretch">
          <Grid container direction="column">
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Resumen Pesaje del día
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.paddingContainer
                  )}
                >
                  <div>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={302}
                      titleInfo="Total Animales en Pie Pesados"
                      avatarTotalColor="#FFC676"
                      avatarTotalSize={65}
                      avatarTextSize={22}
                      titleInfoSize={250}
                    />
                  </div>
                  <div className={classes.width}>
                    <div>
                      <ItemListInfo
                        icon={'icon-weight'}
                        titleInfo="Peso Promedio"
                        numberInfo="102,65 Kg"
                      />
                    </div>
                    <div>
                      <ItemListInfo
                        icon={'icon-pig'}
                        titleInfo="Peso Total"
                        numberInfo="42.0039,00 Kg"
                      />
                    </div>
                  </div>
                </div>
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Distribución en corrales
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.paddingContainer
                  )}
                >
                  <div>
                    <ItemTotalInfo
                      avatarTotalInfo={284}
                      titleInfo="Animales en Corral de Reposo"
                      avatarTotalColor="#D47D57"
                      avatarTotalSize={35}
                      avatarTextSize={16}
                      extended
                      titleInfoSize={200}
                      position="right"
                    />
                  </div>
                  <div>
                    <ItemTotalInfo
                      avatarTotalInfo={18}
                      titleInfo="Animales en Corral de Observación"
                      avatarTotalColor="#D47D57"
                      avatarTotalSize={35}
                      avatarTextSize={16}
                      extended
                      titleInfoSize={200}
                      position="right"
                    />
                  </div>
                </div>
              </ContentCard>
            </Grid>
            <Grid item alignItems="stretch">
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Tipos de Cerdos
                  </Typography>
                </div>

                <Chart
                  type="doughnut"
                  data={[
                    {
                      label: 'Cerdos de Engorde',
                      data: 71,
                      bg: '#BE5948',
                    },
                    {
                      label: 'Cerdos de Descarte',
                      data: 21,
                      bg: '#D47D57',
                    },
                    {
                      label: 'Cerdos 150',
                      data: 10,
                      bg: '#FFC676',
                    },
                  ]}
                />
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} xl={6}>
          <Grid container direction="column">
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Estado Animales Ingreso Corrales
                  </Typography>
                </div>

                <Chart
                  type="doughnut"
                  data={[
                    {
                      label: 'Animales Agitados',
                      data: 3,
                      bg: '#BE5948',
                    },
                    {
                      label: 'Animales Caídos',
                      data: 2,
                      bg: '#D47D57',
                    },
                    {
                      label: 'Muertos en Transporte',
                      data: 1,
                      bg: '#E29662',
                    },
                    {
                      label: 'Muertos Desembarque',
                      data: 1,
                      bg: '#FFC676',
                    },
                  ]}
                />
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div>
                  <Typography className={classes.paperTitles}>
                    Animales Inmunocastrados
                  </Typography>
                </div>

                <Chart
                  withoutTotalInfo={true}
                  type="doughnut"
                  data={[
                    {
                      label: 'Inmunocastrados',
                      data: 71,
                      bg: '#BE5948',
                    },

                    {
                      label: 'Sin Inmunocastrar',
                      data: 31,
                      bg: '#FFC676',
                    },
                  ]}
                />
              </ContentCard>
            </Grid>
            <Grid item>
              <ContentCard className={classes.contentCard}>
                <div className={classes.evenlyContainer}>
                  <Typography className={classes.paperTitles}>
                    Generar Reporte del Día
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={openModalRangeTicket}
                  >
                    Ver
                  </Button>
                </div>
              </ContentCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ContentCard>
            <div className={classes.headerContent}>
              <div>
                <Typography className={classes.paperTitles}>
                  Detalle del Pesaje de Animales en Pie del día
                </Typography>
              </div>
              <div>
                <Results number={rows.length} />
              </div>
            </div>
            <div>
              <DataGrid
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[5, 10, 15, 20]}
                page={page}
                onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                onPageChange={(e: any) => setPage(e.page)}
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
                autoHeight
              />
            </div>
          </ContentCard>
        </Grid>
      </Grid>
      <DialogPDFViewer
        open={openFilterTicket}
        onClose={() => setOpenFilterTicket(false)}
        done={Boolean(imageQr)}
      >
        <MyDocFilterBalanceTicket
          qr={imageQr}
          graph={imageGraph}
          graphTwo={imageGraphTwo}
          range={currentR}
        />
      </DialogPDFViewer>
    </div>
  );
};

export default index;
