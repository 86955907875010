import { makeStyles } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
  },
  fontTitle: {
    fontWeight: 'bold',
  },
  gridMargin:{
    margin: '0px 24px'
  },
  loadingColor: {
    color: '#E2E2E2'
  },
  plateContainer:{
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 40px 10px 40px'
  },
  buttonBack: {
    backgroundColor: theme.palette.secondary.main
  },
  btnAddCategory:{
    width: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: green[400],
    color: '#fff',
    "& .MuiFab-root:hover":{
      color: 'yellow',
      display: 'none',
    }
  },
  btnDeleteCategory:{
    width: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: red[400],
    color: '#fff',
    // "& :hover":{
    //   backgroundColor: '#68ce70',
    // }
  },

}))

export { useStyles as default }