import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Divider,
  FormControlLabel,
  Checkbox,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";

import Breadcrumb, { BreadcrumbProps } from "components/BreadcrumbBuilder";
import ContentCard from "components/ContentCard";
import GobackLink from "components/GobackLink";
import MainTitle from "components/MainTitle";
import DocumentTypes from "components/DocumentTypes";
import TextFieldNumber from "components/TextFieldNumber";
import Positions from "components/Positions";
import PhoneNumber from "components/PhoneNumber";
import RegisterRol from "components/PageComponents/Settings/RegisterRol";

import { NewInternalUserValidations } from "lib/validations/NewInternalUserValidations";
import unmaskPhone from "lib/helpers/unmaskPhone";

import constants from "config/constants";

import { GET_ROLES } from "core/graphql/queries/settings";
import { ALL_NAVIGATION } from "core/graphql/queries/allNavigation";
import { CREATE_USER } from "core/graphql/mutations/internalUsers";

import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import clsx from "clsx";

import useStyles from "./styles";

const RegisterInternalUser = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const idTipoUsuario = useSelector(
    (state: any) => state.user.selectedUserType.idUserType
  );
  const { enqueueSnackbar } = useSnackbar();
  const [rolesList, setRolesList] = useState<any>([]);
  const [permissions, setPermissions] = useState<any>([]);
  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: "/app/users/register-user", title: "Registrar Usuario" },
    { link: "/app/users", title: "Mis usuarios" },
  ];
  const [viewPass, setViewPass] = useState(false);

  const [
    createInteralUser,
    { loading: loadingCreateUser, data: dataCreateUser },
  ] = useMutation(CREATE_USER, {
    context: { headers: { "x-auth-jwt": token } },
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      secondName: "",
      firstSurname: "",
      secondSurname: "",
      documentType: 1,
      documentNumber: null,
      idPosition: null,
      indicativePhone: constants.DEFAULT_COUNTRY_ID,
      phoneNumber: "",
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      idRole: 3,
    },
    validationSchema: NewInternalUserValidations,
    onSubmit: (values) => {
      console.log(values);
      createInteralUser({
        variables: {
          ...values,
          documentNumber: String(values.documentNumber),
          idTipoUsuario,
        },
      })
        .then((data) => {
          if (data?.data?.postInternalUser?.data?.success === 0) {
            enqueueSnackbar(
              `No se ha podido crear el usuario. ${data?.data?.postInternalUser?.data?.message}`,
              {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: {
                  horizontal: "right",
                  vertical: "top",
                },
              }
            );
          } else {
            enqueueSnackbar(`Usuario registrado exitosamente`, {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                horizontal: "right",
                vertical: "top",
              },
            });
          }
          console.log("data-->", data, dataCreateUser);
        })
        .catch((error) => {
          console.log("error-->", error);
        });
    },
  });

  const {
    loading: loadingRoles,
    error: errorRoles,
    data: dataRoles,
  } = useQuery(GET_ROLES, {
    context: { headers: { "x-auth-jwt": token } },
  });

  const { loading, error, data, refetch } = useQuery(ALL_NAVIGATION, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idRol: formik.values.idRole },
  });

  useEffect(() => {
    if (!loading && data) {
      setPermissions(data?.getAllNavigation?.data);
    }
  }, [loading, data]);

  useEffect(() => {
    refetch();
    setPermissions(data?.getAllNavigation?.data);
  }, [formik.values.idRole]);

  useEffect(() => {
    if (!loadingRoles && dataRoles) {
      const misRoles = dataRoles?.getRoles?.data?.misRoles?.map(
        (item: any) => item
      );

      const rolesSistema = dataRoles?.getRoles?.data?.rolesSistema?.map(
        (item: any) => item
      );
      setRolesList(
        [...rolesSistema, ...misRoles].filter((item: any) => item.estado)
      );
    }
  }, [loadingRoles, dataRoles]);

  return (
    <>
      <Box className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </Box>
      <ContentCard style={{ width: "100%" }}>
        <Box marginBottom={1}>
          <Typography variant="h6" style={{ fontSize: 24 }}>
            Registro Usuario Interno
          </Typography>
        </Box>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Box marginY={2}>
            <MainTitle>Datos Generales</MainTitle>
            <Box marginY={4}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    label="Primer Nombre"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.firstName)}
                    helperText={formik.errors.firstName}
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Segundo Nombre"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="secondName"
                    value={formik.values.secondName}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Primer Apellido"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="firstSurname"
                    value={formik.values.firstSurname}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.firstSurname)}
                    helperText={formik.errors.firstSurname}
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Segundo Apellido"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="secondSurname"
                    value={formik.values.secondSurname}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DocumentTypes
                    entity={1}
                    name="documentType"
                    value={formik.values.documentType}
                    onChange={formik.handleChange}
                    errors={formik.errors.documentType}
                    helperText={formik.errors.documentType}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFieldNumber
                    label="Número de documento"
                    name="documentNumber"
                    error={formik.errors.documentNumber}
                    value={formik.values.documentNumber}
                    helperText={formik.errors.documentNumber}
                    onChange={formik.handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <Positions
                    name="idPosition"
                    value={formik.values.idPosition}
                    onChange={formik.handleChange}
                    errors={formik.errors.idPosition}
                    helperText={formik.errors.idPosition}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Correo Electrónico"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.email)}
                    helperText={formik.errors.email}
                    required
                  />
                </Grid>
                <Grid item xs={6} xl={4}>
                  <PhoneNumber
                    type="mobile"
                    names={{
                      indicative: "indicativePhone",
                      phone: "phoneNumber",
                    }}
                    values={{
                      indicative: formik.values.indicativePhone,
                      phone: (formik.values.phoneNumber = unmaskPhone(
                        formik.values.phoneNumber
                      )),
                    }}
                    onChange={formik.handleChange}
                    errors={{
                      indicative:
                        !!formik.errors.indicativePhone &&
                        !!formik.touched.indicativePhone,
                      phone: formik.errors.phoneNumber as string,
                    }}
                    helperTexts={{
                      indicative: formik.errors.indicativePhone as string,
                      phone: formik.errors.phoneNumber as string,
                    }}
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <MainTitle>Datos de Usuario</MainTitle>
            <Box marginY={4}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    label="Nombre de usuario"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.username)}
                    helperText={formik.errors.username}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Contraseña"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.password)}
                    helperText={formik.errors.password}
                    type={viewPass ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setViewPass(!viewPass)}>
                            {!viewPass ? (
                              <VisibilityOutlined />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Confirmar contraseña"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.confirmPassword)}
                    helperText={formik.errors.confirmPassword}
                    type={viewPass ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setViewPass(!viewPass)}>
                            {!viewPass ? (
                              <VisibilityOutlined />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <MainTitle>Permisos</MainTitle>
            <Box marginTop={1}>
              <Typography color="secondary">
                A continuación seleccione el rol del nuevo usuario, podrás
                seleccionar uno de los ya registrados en el sistema o crear uno
                nuevo con los permisos que desea otorgarle al usuario.
              </Typography>
            </Box>
            <Box marginY={4}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginY={3}
              >
                <Box width={250}>
                  <TextField
                    label="Rol para el usuario"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="idRole"
                    value={formik.values.idRole}
                    onChange={formik.handleChange}
                    error={!!formik.errors.idRole}
                    helperText={formik.errors.idRole}
                    select
                    required
                  >
                    {rolesList?.map((item: any) => (
                      <MenuItem key={item.idRol} value={item.idRol}>
                        {item?.descripcion}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <RegisterRol>
                  {({ handleOpenRol }: any) => (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<i className="icon-plus" />}
                      onClick={handleOpenRol}
                    >
                      Registrar Rol
                    </Button>
                  )}
                </RegisterRol>
              </Box>
              {permissions?.map((item: any) => {
                return (
                  <>
                    <Box marginY={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                            color="secondary"
                          >
                            {item?.name}
                          </Typography>
                        </Grid>
                        <Grid item container xs={9}>
                          {item?.children?.length !== 0 ? (
                            item?.children?.map((subcategory: any) => (
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={subcategory?.state}
                                      color="primary"
                                      disabled
                                    />
                                  }
                                  label={subcategory?.name}
                                />
                              </Grid>
                            ))
                          ) : (
                            <Grid item xs={2}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={item?.state}
                                    color="primary"
                                    disabled
                                  />
                                }
                                label={item?.name}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                  </>
                );
              })}
            </Box>
          </Box>
          <Box marginY={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={
                loadingCreateUser && (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                )
              }
            >
              Guardar
            </Button>
          </Box>
        </form>
      </ContentCard>
    </>
  );
};

export default RegisterInternalUser;
