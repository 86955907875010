import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, Typography, Tooltip, Fab } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import domtoimage from 'dom-to-image';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import ContentCard from 'components/ContentCard';
import PaginationGrid from 'components/datagrid-custom-components/TableFooter/index';
import GobackLink from 'components/GobackLink';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import Results from 'components/Results';

import {
  ReportFilter,
  MyDocRangeAnteMortem,
  GraphRangeAnteMortem,
  GraphRangeAnteMortemTwo,
} from './../../Reports/ReportFilter';

import useStyles from './styles';
import DialogPDFViewer from './../../../../../../components/DialogPDFViewer/index';

const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

// Datos quemados
const rows = [
  {
    id: 1,
    lot: 6075,
    producer: 'Nombre productor',
    farm: 'Nombre Granja',
    animals: 25,
    fallen: 1,
    agitated: 0,
    diedOnCorrals: 0,
    diedOnTransport: 0,
    diedOnDisembarkation: 2,
  },
  {
    id: 2,
    lot: 6075,
    producer: 'Nombre productor',
    farm: 'Nombre Granja',
    animals: 25,
    fallen: 0,
    agitated: 2,
    diedOnCorrals: 0,
    diedOnTransport: 2,
    diedOnDisembarkation: 0,
  },
  {
    id: 3,
    lot: 6075,
    producer: 'Nombre productor',
    farm: 'Nombre Granja',
    animals: 25,
    fallen: 2,
    agitated: 0,
    diedOnCorrals: 1,
    diedOnTransport: 0,
    diedOnDisembarkation: 2,
  },
  {
    id: 4,
    lot: 6075,
    producer: 'Nombre productor',
    farm: 'Nombre Granja',
    animals: 25,
    fallen: 1,
    agitated: 3,
    diedOnCorrals: 0,
    diedOnTransport: 1,
    diedOnDisembarkation: 2,
  },
];

const pageBreadcrumbs: BreadcrumbProps[] = [
  {
    link: '/app/reports/ante-mortem/search',
    title: 'Ante-Mortem',
  },
  { link: '/app/reports/ante-mortem', title: 'Mis Resportes' },
];

const Search = () => {
  const classes = useStyles();
  const url = new URLSearchParams(window.location.search);
  const [notification, setNotification] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const [type] = useState(url.get('type'));
  const initialDate: any = React.useState(url.get('initialdate'));
  const finalDate: any = React.useState(url.get('finaldate'));
  const [openFilterAnteMortem, setOpenFilterAnteMortem] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageGraph, setImageGraph] = useState('');
  const [imageGraphTwo, setImageGraphTwo] = useState('');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenQrAnteMortenFilter');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraph = () => {
    const graph: any = document.getElementById('graphrangeantemortem');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const screenshotGraphTwo = () => {
    const graph: any = document.getElementById('graphrangeantemortemtwo');
    domtoimage
      .toPng(graph)
      .then((dataUrl) => {
        console.log(dataUrl);
        setImageGraphTwo(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalRangeAnteMortem = () => {
    // generateScreenshot();
    // screenshotGraph();
    // screenshotGraphTwo();
    setOpenFilterAnteMortem(true);
  };

  useEffect(() => {
    generateScreenshot();
    screenshotGraph();
    screenshotGraphTwo();
  }, [openFilterAnteMortem]);

  const getDateFilter = () => {
    switch (type) {
      case 'lote':
        return url.get('code');
      case 'today':
        return dayjs().format('DD/MM/YYYY');
      case 'week':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      case 'range':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      default:
        return 'El tipo de filtro solicitado no existe';
    }
  };

  const getInfoFilter = () => {
    switch (type) {
      case 'lote':
        return (
          <>
            <Typography className={classes.filterType}>Lote:</Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{url.get('code')}</span>
            </Typography>
          </>
        );
      case 'today':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">
                {dayjs().format('dddd, D MMMM YYYY')} (Hoy)
              </span>
            </Typography>
          </>
        );
      case 'week':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{'Semana' + ' ' + dayjs().week()}</span>{' '}
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      case 'range':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      default:
        return (
          <Typography className={classes.filterType}>
            El tipo de filtro solicitado no existe
          </Typography>
        );
    }
  };

  const handleNotification = () => {
    setNotification(!notification);
  };

  const ListGuide: GridColDef[] = [
    {
      field: 'lot',
      headerName: 'Nro. Lote',
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'producer',
      headerName: 'Productor',
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'farm',
      headerName: 'Granja',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'animals',
      headerName: 'Animales Lote',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'fallen',
      headerName: 'Caídos',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'agitated',
      headerName: 'Agitados',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'diedOnCorrals',
      headerName: 'Muertos en corrales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'diedOnTransport',
      headerName: 'Muertos en Transporte',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'diedOnDisembarkation',
      headerName: 'Muertos en Desembarque',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'ver',
      headerName: ' ',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/ante-mortem/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.btnDetails}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const customPagination = () => {
    return (
      <Box mt={2}>
        <PaginationGrid />
      </Box>
    );
  };

  return (
    <>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportFilter />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphRangeAnteMortem />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphRangeAnteMortemTwo />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <Grid container>
        <ContentCard className={classes.cardContainer}>
          <Grid item container>
            <Grid item container>
              <Box mr={5}>
                <Typography className={classes.title}>
                  Búsqueda: Fecha Puntual
                </Typography>
              </Box>
              {!url.get('code') && (
                <Button
                  variant="contained"
                  className="raleway"
                  color="primary"
                  onClick={openModalRangeAnteMortem}
                >
                  Generar Reporte
                </Button>
              )}
            </Grid>
          </Grid>
          <Box borderBottom={1} mt={3} mb={3} className={classes.colorHr}>
            <Grid item container>
              <Grid item container xs={6} alignItems="flex-end">
                {getInfoFilter()}
              </Grid>
              <Grid
                item
                container
                xs={6}
                justify="flex-end"
                alignItems="center"
              >
                <Results number={rows.length} />
              </Grid>
            </Grid>
          </Box>
          <Box minHeight={500}>
            <DataGrid
              rows={rows}
              columns={ListGuide}
              autoHeight
              className={classes.dataGrid}
              components={{
                Footer: customPagination,
              }}
              hideFooterSelectedRowCount
              pageSize={pageSize}
              rowsPerPageOptions={[5, 10, 15, 20]}
              page={page}
              onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
              onPageChange={(e: any) => setPage(e.page)}
            />
          </Box>
        </ContentCard>
      </Grid>
      <DialogPDFViewer
        open={openFilterAnteMortem}
        onClose={() => setOpenFilterAnteMortem(false)}
        done={Boolean(imageQr)}
      >
        <MyDocRangeAnteMortem
          qr={imageQr}
          graph={imageGraph}
          graphTwo={imageGraphTwo}
          range={getDateFilter()}
        />
      </DialogPDFViewer>
    </>
  );
};

export default Search;
