import { makeStyles } from '@material-ui/core';

const DEFAULT_SIZE = 60;
const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiSelect-root': {
      '& .itemName': {
        display: 'none',
      },
    },
    '& .MuiSelect-selectMenu': {
      alignItems: 'center',
      display: 'flex',
      height: ' 1.1876em',
    },
  },
  categories: {
    background: '#fff',
  },
  containerMenu: {
    paddingRight: 8,
    maxWidth: '50%',
    overflow: 'hidden',
    whiteSspace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.between('sm', 'lg')]: {
      display: 'none',
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: '10px',
  },

  inputIcon: {
    '&.MuiInputAdornment-root': {
      color: theme.palette.primary.main,
    },
  },
}));

export { useStyles as default };
