import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container:{
    "& .MuiTableCell-root":{
      border: 'none',
      textTransform: 'capitalize'
    },
  },
  containerHorizontal:{
    display: 'flex', 
  },
  itemsHorizontal:{
    display:'flex', 
    alignItems: 'center', 
    fontSize: 20, 
    padding:0

  },
  contaienrItem:{
    display:'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between',
    fontSize: 20, 
    padding:0, 
    margin: '8px 0px'
  },
  label:{
    display:'flex', 
    alignItems: 'center', 
    color: '#AFAFAF',
    "& i":{
      fontSize: 20, 
      marginRight: 12
    }
  },
  iconCheck:{
    fontWeight: 'bold', 
    color: '#68CE70',
    marginLeft: 12
  },
  iconClose:{
    fontWeight: 'bold', 
    color: '#E2575C',
    marginLeft: 12
  },

 
}));

export default useStyles;
