import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  indicator: {
    color: '#FFF',
    height: 23,
    position: 'relative',
    minWidth: 40,
    lineHeight: 1,
    background: '#D6D6D6',
    padding: '2px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(0.5),
    },
    '&:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
    '& > span:not(:last-child):after': {
      content: '"-"',
    },
  },
  active: {
    '&:before': {
      content: '" "',
      width: 8,
      height: 5,
      background: '#767676',
      position: 'absolute',
      top: -10,
      left: 'calc(50% - 4px)',
      clipPath: 'polygon(0 0, 100% 0, 50% 100%)',
    },
  },
}));

export default useStyles;
