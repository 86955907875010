import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  ListItemText,
  Divider,
  Table,
  TableBody,
  CircularProgress,
} from "@material-ui/core";

import Breadcrumb, { BreadcrumbProps } from "components/BreadcrumbBuilder";
import InformativeScreen from "components/InformativeScreens";
import ContentCard from "components/ContentCard";
import MainTitle from "components/MainTitle";
import Results from "components/Results";
import Row from "./components/row";

import { DATA_INTERNAL_USERS } from "core/graphql/queries/internalUsers";

import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import clsx from "clsx";

import useStyles from "./styles";

const InteralUsers = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const path = useLocation();
  const [usersData, setUsersData] = useState<any>({});
  const userType = useSelector(
    (state: any) => state.user.selectedUserType.idUserType
  );
  const { data, loading, error, refetch } = useQuery(DATA_INTERNAL_USERS, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { userType },
  });
  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: "/app/users", title: "Mis usuarios" },
  ];
  const [valueHistory, setValueHistory] = useState("thisweek");
  const [activityHistoy, setActivityHistory] = useState([
    `${dayjs().startOf("week")}`,
    `${dayjs().endOf("week")}`,
  ]);
  const [state, setState] = useState("true");

  const handleChangeState = (event: any) => {
    setState(event.target.value);
  };

  useEffect(() => {
    if (valueHistory === "today") {
      setActivityHistory([`${dayjs().startOf("d")}`, `${dayjs().endOf("d")}`]);
    } else if (valueHistory === "thisweek") {
      setActivityHistory([`${dayjs().startOf("w")}`, `${dayjs()}`]);
    } else {
      setActivityHistory([`${dayjs().startOf("M")}`, `${dayjs()}`]);
    }
  }, [valueHistory]);

  useEffect(() => {
    refetch();
  }, [path]);

  useEffect(() => {
    if (!loading && data) setUsersData(data?.getGeneralDataInternalUsers?.data);
  }, [data, loading]);

  return (
    <>
      <Box className={classes.breadContainer}>
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </Box>
      <Grid container spacing={2}>
        <Grid item container xs={7}>
          <ContentCard style={{ width: "100%" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" color="secondary">
                Resumen Plan de Suscripción
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={"/app/profile/subscription"}
              >
                Ver Suscripción
              </Button>
            </Box>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ListItemText
                    primary="Nombre del Plan"
                    secondary="Plan Premium"
                    classes={{
                      root: clsx(classes.containerItemSubs, "center"),
                      primary: classes.titleSubs,
                      secondary: classes.subtitleSubs,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText
                    primary="Vigencia del plan"
                    secondary="20/04/2022"
                    classes={{
                      root: clsx(classes.containerItemSubs, "center"),
                      primary: classes.titleSubs,
                      secondary: classes.subtitleSubs,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText
                    primary="Usuarios del Plan"
                    secondary="2"
                    classes={{
                      root: clsx(classes.containerItemSubs, "center"),
                      primary: classes.titleSubs,
                      secondary: classes.subtitleSubs,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText
                    primary="Usuarios Adicionales"
                    secondary="3"
                    classes={{
                      root: clsx(classes.containerItemSubs, "center"),
                      primary: classes.titleSubs,
                      secondary: classes.subtitleSubs,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </ContentCard>
        </Grid>
        <Grid item container xs={5}>
          <ContentCard style={{ width: "100%" }}>
            <Typography variant="h6" color="secondary">
              Historial de actividad de todos mis usuarios
            </Typography>
            <Box marginTop={2}>
              <RadioGroup
                row
                value={valueHistory}
                className={classes.filterHistorial}
                onChange={(event: any) => {
                  setValueHistory(event.target.value);
                }}
              >
                <FormControlLabel
                  value="today"
                  control={<Radio color="primary" />}
                  label="Hoy"
                />
                <FormControlLabel
                  value="thisweek"
                  control={<Radio color="primary" />}
                  label="Semana Actual"
                />
                <FormControlLabel
                  value="thismonth"
                  control={<Radio color="primary" />}
                  label="Mes Actual"
                />
              </RadioGroup>
              <div className={classes.containerButton}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/app/users/details/activity/0/${valueHistory}/${dayjs(
                    activityHistoy[0]
                  ).valueOf()}/${dayjs(activityHistoy[1]).valueOf()}`}
                >
                  Previsualizar
                </Button>
              </div>
            </Box>
          </ContentCard>
        </Grid>
      </Grid>

      <ContentCard>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Box display="flex" alignItems="center">
            <MainTitle>Gestión usuarios</MainTitle>
            <Results
              number={
                state === "true"
                  ? usersData?.usuariosActivos
                  : usersData?.usuariosInactivos
              }
            />
          </Box>
          <Box display="flex" alignItems="center">
            <RadioGroup row aria-label="position" name="position">
              <FormControlLabel
                value="true"
                checked={state === "true"}
                onChange={handleChangeState}
                control={<Radio color="primary" />}
                label="Activos"
              />
              <FormControlLabel
                value="false"
                checked={state === "false"}
                onChange={handleChangeState}
                control={<Radio color="primary" />}
                label="Inactivos"
              />
            </RadioGroup>
            <Button
              variant="contained"
              color="primary"
              startIcon={<i className="icon-plus" />}
              component={Link}
              to="/app/users/register-user"
            >
              Registrar Usuario
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box minHeight={300} paddingBottom={2}>
          {loading ? (
            <Box
              width="100%"
              height={280}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={50} />
            </Box>
          ) : error ? (
            <Box
              width="100%"
              height={280}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <InformativeScreen
                onlyError
                subtitle="¡Ups! Ha ocurrido un error"
                info="No se ha podido realizar la consulta por que ha ocurrido un error interno, vuelva a intentarlo más tarde."
                type="technicalError"
              />
            </Box>
          ) : (
            <Table>
              <TableBody>
                {usersData?.roles?.map((row: any) => (
                  <Row row={row} filter={state} />
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </ContentCard>
    </>
  );
};

export default InteralUsers;
