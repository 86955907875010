import { List, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useSelector } from "react-redux";

import NavigationCollapse from "./NavigationCollapse";
import NavigationItem from "./NavigationItem";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    whiteSpace: "nowrap",
  },
}));

const NavigationContent = () => {
  const classes = useStyles();
  const navigation = useSelector((state: any) => state.user.navigation);
  const modules = navigation?.filter((items: any) => items?.state);

  return (
    <List className={clsx("navigation", classes.root)}>
      <NavigationItem
        item={{
          name: "Inicio",
          icon: "mv__home",
          url: "/app/dashboard",
        }}
      />
      {modules?.map((item: any, key: number) => {
        if (item?.children?.length !== 0)
          return <NavigationCollapse key={key} item={item} />;
        return <NavigationItem key={key} item={item} />;
      })}
      <NavigationItem
        item={{
          name: "Ajustes",
          icon: "mv__settings",
          url: "/app/settings",
        }}
      />
    </List>
  );
};

export default NavigationContent;
