import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  backgroundresumetitle: {
    backgroundColor: '#ededed',
    fontSize: 7,
    justifyContent: 'center',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    paddingTop: 3,
    paddingBottom: 3,
  },
})