import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { PUT_STATUS_CONTACT } from "core/graphql/queries/contacts";

import useStyles from "./styles";

type DialogProps = {
  open: any;
  user: string;
  idUser: string;
  action: boolean;
  handleClose: any;
};

const DialogStatusContact = ({
  open,
  user,
  idUser,
  action,
  handleClose,
}: DialogProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();

  const [changeStatusContact, { loading: loadingStatusContact }] = useMutation(
    PUT_STATUS_CONTACT,
    {
      context: { headers: { "x-auth-jwt": token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        });
      },
    }
  );

  const statusContact = (idUser: string, state: boolean) => {
    changeStatusContact({
      update: (proxy, mutationResult) => {
        if (mutationResult?.data?.putStatusContact?.data?.success) {
          enqueueSnackbar(
            mutationResult?.data?.putStatusContact?.data?.message,
            {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
            }
          );
          handleClose();
        } else {
          enqueueSnackbar(
            mutationResult?.data?.putStatusContact?.data?.message,
            {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
            }
          );
        }
      },
      variables: { idContacto: Number.parseInt(idUser), estado: state },
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      className={classes.borderRadiudDialog}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Grid className={classes.icon}>
          <Box
            component="i"
            fontSize="70px"
            color="primary"
            className="icon-user-alert"
          />
        </Grid>

        <Grid>
          <Typography className={classes.title}>
            {action ? (
              <>
                ¿Esta seguro de desea ACTIVAR al contacto{" "}
                <span style={{ color: "#dd9835" }}>{user}</span>?
              </>
            ) : (
              <>
                ¿Esta seguro de desea INACTIVAR al contacto{" "}
                <span style={{ color: "#dd9835" }}>{user}</span> de su Red de
                Negocios?
              </>
            )}
          </Typography>
          <DialogContentText>
            <Box mt={1}>
              {action ? (
                <Typography className={classes.message}>
                  Este contacto volvera a hacer parte de tu Red de Negocios.
                </Typography>
              ) : (
                <Typography className={classes.message}>
                  Para poder realizar conexiones con este cotacto deberá
                  activarlo de nuevo.
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginBottom: "15px" }}
        >
          <Box mr={2}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => statusContact(idUser, action)}
            disabled={loadingStatusContact}
            endIcon={
              loadingStatusContact && (
                <CircularProgress
                  className={classes.loadingColor}
                  thickness={5}
                  size={20}
                />
              )
            }
          >
            Inactivar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default DialogStatusContact;
