import React, { useEffect } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';

import MainTitle from 'components/MainTitle';
import Results from 'components/Results';
import CardDetailDriver from 'components/TransportComponents/CardDetailDriver/index';
import InformativeScreen from 'components/InformativeScreens';

import { GET_DRIVERS_COMPANY } from 'core/graphql/queries/transport';

import useStyles from './styles';

type DialogNotificationProps = {
  open: any;
  data: any;
  handleClose: () => void;
};

const DialogViewDrivers = ({
  open,
  data,
  handleClose,
}: DialogNotificationProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);

  const [
    getDrivers,
    { data: dataDrivers, loading: loadingDrivers, error: errorDrivers },
  ] = useLazyQuery(GET_DRIVERS_COMPANY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (open) {
      getDrivers({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idEmpresaTransportadora: data.idCompany },
      });
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      className={classes.borderRadiudDialog}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Grid container style={{ marginBottom: '5px' }}>
          <Grid item container xs={8} alignItems="center">
            <MainTitle>Conductores asociados a la empresa:</MainTitle>
            <Typography>
              <span className={classes.fontName}>{data.companyName}</span>
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.gridButton} alignItems="center">
            <Box mr={5}>
              <Results
                number={dataDrivers?.getDriversXCompany?.data?.length ?? 0}
              />
            </Box>
          </Grid>
        </Grid>

        {!loadingDrivers && !errorDrivers && (
          <Grid container className={classes.cardGridContainer}>
            {dataDrivers?.getDriversXCompany?.data.map(
              (item: any, index: number) =>
                item?.estado && (
                  <Grid item key={index} style={{ margin: 18 }}>
                    <CardDetailDriver
                      key={index}
                      id={item?.idConductor}
                      name={item?.nombreCompleto}
                      ubication="Medellín"
                      document={item?.numeroDocumento}
                    />
                  </Grid>
                )
            )}
          </Grid>
        )}

        {loadingDrivers && (
          <Box mt={5} mb={3}>
            <Grid container justifyContent="center">
              <CircularProgress color="primary" thickness={5} size={60} />
            </Grid>
            <Grid container justifyContent="center">
              <Box mt={1}>
                <Typography>Cargando ...</Typography>
              </Box>
            </Grid>
          </Box>
        )}

        {errorDrivers && !loadingDrivers && (
          <InformativeScreen
            type="transport"
            subtitle="¡No encontramos nada!"
            info="Esta Empresa no tiene conductores inactivos asociados."
            onlyError
          />
        )}
      </DialogContent>

      <DialogActions>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginBottom: '15px' }}
        >
          <Button variant="contained" onClick={handleClose} color="primary">
            Aceptar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default DialogViewDrivers;
