import { makeStyles } from "@material-ui/core";


export default makeStyles(theme => ({
 input:{
   "&  input[type=number]": {  
      "&::-webkit-inner-spin-button":{
        display: 'none !important'
      }
  }
 }
}))