import worldInitialState from '../initialStates/worldInitialState';

import {
  SET_COUNTRIES,
  SET_COUNTRY_SELECTED,
  SET_DEPARTMENTS,
  SET_RENDER_DEPARTMENTS,
  SET_RENDER_CITIES,
  SIGN_OUT,
} from '../types/worldTypes';

export default (state: any = worldInitialState, action: any) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return {
        ...state,
        data: action.payload,
      };
    case SET_COUNTRY_SELECTED:
      return {
        ...state,
        selectedCountry: action.payload,
      };

    case SET_DEPARTMENTS:
      return {
        ...state,
        data: action.payload.data,
        renderDepartments: state.data[action.payload.index].departments,
        renderCities: [],
      };
    case SET_RENDER_DEPARTMENTS:
      return {
        ...state,
        renderDepartments: action.payload.data,
      };
    case SET_RENDER_CITIES:
      return {
        ...state,
        renderCities: action.payload,
      };
    case SIGN_OUT:
      return {
        ...worldInitialState,
      };

    default:
      return state;
  }
};
