import React, { useState, useEffect } from "react";

import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Grid,
  Box,
  IconButton,
  Collapse,
} from "@material-ui/core";

import MainTitle from "components/MainTitle";

import validations from "lib/validations/messages";
import { ALL_NAVIGATION } from "core/graphql/queries/allNavigation";
import { CREATE_ROLE } from "core/graphql/mutations/settings";
import { GET_ROLES } from "core/graphql/queries/settings";

import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as yup from "yup";
import clsx from "clsx";

import useStyles from "./styles";
import InformativeScreen from "components/InformativeScreens";

const RegisterRol = ({ children }: any) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const [permissions, setPermissions] = useState<any>([]);
  const [open, setOpen] = useState<any>();
  const options: any[] = [];

  const NewRole = yup.object({
    roleName: yup
      .string()
      .required(validations.REQUIRED_FIELD)
      .min(3, "El nombre debe tener al menos 3 caracteres"),
    subCategorias: yup
      .array()
      .required(validations.REQUIRED_FIELD)
      .test(
        "min-length",
        "El Rol debe tener almenos 1 permiso asignado",
        (value: any) =>
          value?.filter((item: any) => item.estadoSubCategoria)?.length !== 0
      ),
  });

  const [createRole, { loading: createRolLoading }] = useMutation(CREATE_ROLE, {
    refetchQueries: [
      { query: GET_ROLES, context: { headers: { "x-auth-jwt": token } } },
    ],
  });

  const { loading, error, data, refetch } = useQuery(ALL_NAVIGATION, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idRol: 0 },
  });

  const navigation = data?.getAllNavigation?.data?.map((item: any) =>
    item?.children?.length !== 0
      ? item?.children.map((child: any) => ({
          cat: child?.idCategory,
          idSubCategoria: child?.idSubCategory,
          estadoSubCategoria: child?.state,
        }))
      : [
          {
            cat: item?.idCategory,
            idSubCategoria: item?.idSubCategory,
            estadoSubCategoria: item?.state,
          },
        ]
  );

  navigation?.map((item: any) =>
    item.map((i: any) => {
      options.push(i);
    })
  );

  useEffect(() => {
    if (!loading && data) {
      setPermissions(options);
    }
  }, [data, loading]);

  const [openRol, setOpenRol] = useState(false);
  const handleOpenRol = () => {
    setOpenRol(true);
    refetch({ idRol: 0 });
  };
  const handleCloseRol = () => setOpenRol(false);

  const formik = useFormik({
    initialValues: {
      roleName: "",
      subCategorias: permissions,
    },
    validationSchema: NewRole,
    onSubmit: (values) => {
      values.subCategorias = permissions.map((item: any) => ({
        idSubCategoria: item.idSubCategoria,
        estadoSubCategoria: item.estadoSubCategoria,
      }));
      createRole({
        variables: {
          roleName: values.roleName,
          subCategorias: values.subCategorias,
        },
        context: { headers: { "x-auth-jwt": token } },
      })
        .then(() => {
          enqueueSnackbar("Se ha registrado correctamente el Rol", {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
          formik.resetForm();
          setPermissions(options);
          handleCloseRol();
        })
        .catch((error) => {
          console.log(error.message);
          enqueueSnackbar(`No se pudo crear el Rol, ${error.message}`, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
        });
    },
  });

  if (loading) {
    return <CircularProgress variant="indeterminate" size={25} />;
  }

  const addPermission = (category: number, subCategory: number) => {
    const getState = permissions
      .filter((item: any) => item.idSubCategoria === subCategory)
      .map((i: any) => i.estadoSubCategoria);
    const remove = permissions.filter(
      (item: any) => item.idSubCategoria !== subCategory
    );

    if (getState[0]) {
      remove.push({
        cat: category,
        idSubCategoria: subCategory,
        estadoSubCategoria: false,
      });
      setPermissions(remove);
      formik.setFieldValue("subCategorias", remove);
    } else {
      remove.push({
        cat: category,
        idSubCategoria: subCategory,
        estadoSubCategoria: true,
      });
      setPermissions(remove);
      formik.setFieldValue("subCategorias", remove);
    }
  };

  const addAll = (category: number, subCategory: any, type: any) => {
    if (type === 1) {
      const getState = permissions
        .filter((item: any) => item.cat === category)
        .filter((i: any) => i.estadoSubCategoria);
      const remove = permissions.filter((item: any) => item.cat !== category);
      if (getState.length === 0) {
        const newState = subCategory.map((item: any) => ({
          cat: category,
          idSubCategoria: item.idSubCategory,
          estadoSubCategoria: true,
        }));
        remove.push(...newState);
        setPermissions(remove);
        formik.setFieldValue("subCategorias", remove);
      } else {
        const newState = subCategory.map((item: any) => ({
          cat: category,
          idSubCategoria: item.idSubCategory,
          estadoSubCategoria: false,
        }));
        remove.push(...newState);
        setPermissions(remove);
        formik.setFieldValue("subCategorias", remove);
      }
    } else {
      const getState = permissions
        .filter((item: any) => item.idSubCategoria === subCategory)
        .map((i: any) => i.estadoSubCategoria);
      const remove = permissions.filter(
        (item: any) => item.idSubCategoria !== subCategory
      );

      if (getState[0]) {
        remove.push({
          cat: category,
          idSubCategoria: subCategory,
          estadoSubCategoria: false,
        });
        setPermissions(remove);
        formik.setFieldValue("subCategorias", remove);
      } else {
        remove.push({
          cat: category,
          idSubCategoria: subCategory,
          estadoSubCategoria: true,
        });
        setPermissions(remove);
        formik.setFieldValue("subCategorias", remove);
      }
    }
  };

  return (
    <div>
      {children({ handleOpenRol, openRol })}
      <Dialog
        open={openRol}
        onClose={handleCloseRol}
        className={clsx(classes.dialogContainer)}
        maxWidth="md"
        fullWidth
        scroll="body"
      >
        {error || !data ? (
          <>
            <Box
              minHeight={300}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <InformativeScreen
                onlyError
                type="technicalError"
                subtitle="¡Ups!"
                info="Ha ocurrido un error, vuelve a intentarlo más tarde."
              />
            </Box>
            <DialogActions className={classes.dialogFooter}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseRol}
              >
                Cancelar
              </Button>
            </DialogActions>
          </>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>
              <Typography variant="h6" color="secondary" align="center">
                Registrar Rol
              </Typography>
              <Typography variant="subtitle1" color="secondary" align="center">
                A continuación defina el nombre y seleccione los permisos que
                desea otorgarle al nuevo rol.
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.dataContainer}>
              <MainTitle>Nombre para el Rol</MainTitle>
              <Box marginY={2}>
                <TextField
                  label="Nombre del rol"
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="roleName"
                  value={formik.values.roleName}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.roleName)}
                  helperText={formik.errors.roleName}
                  required
                />
              </Box>
              <MainTitle>Permsios</MainTitle>
              <Box marginY={2}>
                <Grid container spacing={2}>
                  {data?.getAllNavigation?.data?.map((category: any) => {
                    return (
                      <Grid item xs={4}>
                        <Box className={classes.containerPermiss}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  permissions
                                    .filter(
                                      (i: any) => i.cat === category?.idCategory
                                    )
                                    .filter((c: any) => c.estadoSubCategoria)
                                    .length !== 0
                                }
                                onChange={() =>
                                  addAll(
                                    category?.idCategory,
                                    category?.children?.length !== 0
                                      ? category?.children
                                      : category?.idSubCategory,
                                    category?.children?.length !== 0 ? 1 : 2
                                  )
                                }
                                color="primary"
                              />
                            }
                            label={category?.name}
                          />
                          <IconButton
                            onClick={() => {
                              if (open === category?.idCategory) {
                                setOpen(null);
                              } else setOpen(category?.idCategory);
                            }}
                          >
                            <i
                              className={
                                open === category?.idCategory
                                  ? "icon-circle-arrow-top"
                                  : "icon-circle-arrow-bottom"
                              }
                              style={{ fontSize: 20 }}
                            />
                          </IconButton>
                        </Box>
                        <Collapse
                          in={open === category?.idCategory}
                          className={classes.containerSubcategories}
                        >
                          <Box display="flex" flexDirection="column">
                            {category?.children?.length !== 0 ? (
                              category?.children?.map((item: any) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          permissions
                                            .filter(
                                              (i: any) =>
                                                i.idSubCategoria ===
                                                item?.idSubCategory
                                            )
                                            .map(
                                              (it: any) => it.estadoSubCategoria
                                            )[0]
                                        }
                                        onChange={() =>
                                          addPermission(
                                            category?.idCategory,
                                            item?.idSubCategory
                                          )
                                        }
                                        color="primary"
                                      />
                                    }
                                    label={item?.name}
                                  />
                                );
                              })
                            ) : (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      permissions
                                        .filter(
                                          (item: any) =>
                                            item.idSubCategoria ===
                                            category?.idSubCategory
                                        )
                                        .map(
                                          (i: any) => i.estadoSubCategoria
                                        )[0]
                                    }
                                    onChange={() =>
                                      addPermission(
                                        category?.idCategory,
                                        category?.idSubCategory
                                      )
                                    }
                                    color="primary"
                                  />
                                }
                                label={category?.name}
                              />
                            )}
                          </Box>
                        </Collapse>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Typography color="error" align="center">
                {formik.errors.subCategorias}
              </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseRol}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                endIcon={
                  createRolLoading && (
                    <CircularProgress size={20} style={{ color: "#FFF" }} />
                  )
                }
              >
                Guardar
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </div>
  );
};
export default RegisterRol;
