import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
  },
  divContainerTitle2 : {
    backgroundColor: '#F2F2F2',
    height: '34px',
    display: 'flex',
    fontSize: '15px',
    alignItems: 'center',
    width: '280px',
    justifyContent: 'center',
  },
  title:{
    fontWeight: 'bold',
    color: '#767676',
    fontSize: '19px',
  },
  dataTitle:{
    color: '#dd9835',
    fontWeight: 'bolder',
    fontSize: '16px'
  },
  fontTitleInfo:{
    color: '#AFAFAF', 
  },
  titleContainer: {
    backgroundColor: '#f2f2f2',
    textAlign: 'center',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
    color: '#767676'
  },
  subtitleContainer: {
    height: '35px',
    marginBottom: '15px',
  },
  fontSubtitle:{
    color: '#AFAFAF'
  },
  gridTitleTransport:{
    paddingLeft: '5px',
    marginBottom: '10px'
  },
  gridSubtitleTransport:{
    paddingRight: '5px', 
    marginBottom: '10px'
  },
  fontTitleList: {
    fontSize: '14px',
    fontWeight: 'bolder',
    color: '#AFAFAF',
  },
  fontSubtitleList: {
    fontSize: '14px',
    color: '#767676',
  },
  cardContainer:{
    width: '100%',
    padding: '35px !important'
  },
  gridContainer:{
    marginTop: '15px'
  }
}))

export { useStyles as default }