import { Document, Page, Image, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import postmortenLogo from 'static/img/reportsIcons/postmortenLogo';
import plantIcon from 'static/img/reportsIcons/plantIcon';
import marketerIcon from 'static/img/reportsIcons/marketerIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotalCircle from 'components/pdf-components/ItemTotalCircle';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import HorizontalTableInfo from 'components/pdf-components/HorizontalTableInfo/index';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF/index';
import ChartList from 'components/pdf-components/ChartList/index';
import NumberTotal from 'components/pdf-components/NumberTotal';
import DetailPostMTable from 'components/pdf-components/DetailPostMTable/index';

import styles from './styles/filter';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

const detallesCausa = [
  {
    cantidad: '68',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
  },
  {
    cantidad: '20',
    parte: 'Pulmones',
    causa: 'Congestión',
  },
  {
    cantidad: '6',
    parte: 'Pulmones',
    causa: 'Neumonía',
  },
  {
    cantidad: '12',
    parte: 'Brazuelos',
    causa: 'Adherencias',
  },
  {
    cantidad: '6',
    parte: 'Piel',
    causa: 'Abcesos',
  },
  {
    cantidad: '21',
    parte: 'Parte',
    causa: 'Causa',
  },
];

const detallesDecomiso = [
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
  {
    canal: '006',
    cantidad: '02',
    parte: 'Pulmones',
    causa: 'Broncoaspiración',
    decomiso: '0,00',
    observaciones: 'Ninguna Observación',
  },
];

interface MyDocProps {
  qr: string;
  uid?: null | number | string;
  graph?: string;
  range?: null | number | string;
}

export const MyDocRangePostMortem = ({ qr, uid, graph, range }: MyDocProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        range={range}
        reportImage={postmortenLogo}
        number="023456789"
        title="Inspección Médico Veterinaria - Post Mortem"
        code="PM-001"
      />

      <Subtitle
        title="Agentes Relacionados con el Negocio"
        widthContainer="100%"
      />

      <View style={{ width: '100%' }}>
        <HorizontalTableInfo
          icon={plantIcon}
          title="Planta de Beneficio"
          items={{
            Nombre: 'Sociedad Central Ganadera S.A.',
            Nit: '34123412-2',
            Telefono: '+57 045 2323 322',
            Email: 'info@centralganadera.com',
            Dirección: 'Calle 105 fa 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
        <View
          style={{
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: '#767676',
            marginTop: '10px',
          }}
        ></View>
        <HorizontalTableInfo
          icon={marketerIcon}
          title="Comercializador"
          items={{
            Nombre: 'Nombre comercializador',
            NIT: '45134132',
            Teléfono: '+57 314 6543 543',
            Email: 'info@comercializador.com',
            Dirección: 'Calle 105 A 23-23',
            Ciudad: 'Medellín, Colombia',
          }}
        />
      </View>

      <View style={{ marginTop: '10px' }}>
        <Subtitle title="Resumen Post-Mortem" widthContainer="100%" />
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '5px',
        }}
      >
        <View style={{ width: '36%' }}>
          <Subtitle title="Resumen Decomiso de Canales" widthContainer="100%" />
          <View
            style={{
              height: 66,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#ededed',
              marginTop: '5px',
            }}
          >
            <ItemTotalCircle
              value="4"
              info="Total Canales Completas Decomisadas"
              position="right"
              textAlign="left"
              backgroundColor="#be5948"
              widthInfo="120px"
              widthCircle={30}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '5px',
            }}
          >
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ededed',
                height: 80,
              }}
            >
              <NumberTotal
                value="2%"
                info="Porcentaje Total Decomisos"
                position="bottom"
                widthValue={70}
                widthInfo={60}
                justifyInfo="center"
              />
            </View>
            <View
              style={{
                width: '48.3%',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ededed',
                height: 80,
              }}
            >
              <NumberTotal
                value="516,00 Kg"
                info="Total Carne Decomisada (Kgs)"
                position="bottom"
                widthValue={70}
                widthInfo={50}
                justifyInfo="center"
              />
            </View>
          </View>
        </View>
        <View style={{ width: '60.6%' }}>
          <Subtitle
            title="Detalle Decomiso de Canales Calientes Porcinas"
            widthContainer="100%"
          />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '5px',
            }}
          >
            <View style={{ width: '48.3%' }}>
              <Subtitle
                title="Decomisos Línea de Beneficio"
                widthContainer="100%"
              />
              <View style={{ marginTop: '5px' }}>
                <View
                  style={{
                    backgroundColor: '#ededed',
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NumberTotal
                    value="4"
                    info="Total Cananales Completas Decomisadas"
                    position="right"
                    widthValue={35}
                    widthInfo={100}
                    justifyInfo="left"
                  />
                </View>
                <View
                  style={{
                    marginTop: '5px',
                    backgroundColor: '#ededed',
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NumberTotal
                    value="2%"
                    info="Porcentaje de Cananales Completas Decomisadas"
                    position="right"
                    widthValue={35}
                    widthInfo={100}
                    justifyInfo="left"
                  />
                </View>
                <View
                  style={{
                    marginTop: '5px',
                    backgroundColor: '#ededed',
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NumberTotal
                    value="416,00 Kg"
                    info="Total Carne Decomisada (Kgs)"
                    position="right"
                    widthValue={80}
                    widthInfo={50}
                    justifyInfo="left"
                  />
                </View>
              </View>
            </View>

            <View style={{ width: '48.3%' }}>
              <Subtitle title="Decomisos Sala S.B.E." widthContainer="100%" />
              <View style={{ marginTop: '5px' }}>
                <View
                  style={{
                    backgroundColor: '#ededed',
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NumberTotal
                    value="1"
                    info="Total Cananales Completas Decomisadas"
                    position="right"
                    widthValue={35}
                    widthInfo={100}
                    justifyInfo="left"
                  />
                </View>
                <View
                  style={{
                    marginTop: '5px',
                    backgroundColor: '#ededed',
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NumberTotal
                    value="0,5%"
                    info="Porcentaje de Cananales Completas Decomisadas"
                    position="right"
                    widthValue={40}
                    widthInfo={100}
                    justifyInfo="left"
                  />
                </View>
                <View
                  style={{
                    marginTop: '5px',
                    backgroundColor: '#ededed',
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NumberTotal
                    value="100,00 Kg"
                    info="Total Carne Decomisada (Kgs)"
                    position="right"
                    widthValue={80}
                    widthInfo={50}
                    justifyInfo="left"
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <View style={{ width: '48.3%' }}>
          <Subtitle title="Resumen Partes Decomisadas" widthContainer="100%" />

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '5px',
            }}
          >
            <View
              style={{
                height: '100px',
                marginTop: 5,
                marginBottom: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image src={graph} style={{ height: '150px', width: '150px' }} />
            </View>

            <View>
              <ChartList
                data={[
                  {
                    label: 'Pulmón',
                    data: 101,
                    bg: '#f1ae6c',
                  },
                  {
                    label: 'Piel',
                    data: 6,
                    bg: '#e29662',
                  },
                  {
                    label: 'Visceras Rojas',
                    data: 6,
                    bg: '#d47d57',
                  },
                  {
                    label: 'Pierna',
                    data: 2,
                    bg: '#be5948',
                  },
                  {
                    label: 'Corazón',
                    data: 2,
                    bg: '#b0413e',
                  },
                ]}
              />
            </View>
          </View>
        </View>
        <View style={{ width: '48.3%' }}>
          <Subtitle title="Detalle Causas Decomisos" widthContainer="100%" />

          <View style={[styles.tableMagro, { marginBottom: 10 }]} wrap={false}>
            <View
              wrap={false}
              style={[
                styles.tableRow,
                {
                  marginBottom: '5px',
                  marginTop: '5px',
                  alignItems: 'center',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 89, width: 89 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Cantidad
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 89, width: 89 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Parte Decomisada
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 89, width: 89 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Causa de Decomiso
                </Text>
              </View>
            </View>
            {detallesCausa.map((item: any, index: number) => (
              <View
                key={index}
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                  },
                ]}
              >
                <View style={[styles.tableCol, { maxWidth: 89, width: 89 }]}>
                  <Text style={[styles.tableCellRPost, { fontSize: 8 }]}>
                    {item.cantidad}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 89, width: 89 }]}>
                  <Text style={[styles.tableCellRPost, { fontSize: 8 }]}>
                    {item.parte}
                  </Text>
                </View>
                <View style={[styles.tableCol, { maxWidth: 89, width: 89 }]}>
                  <Text style={[styles.tableCellRPost, { fontSize: 8 }]}>
                    {item.causa}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>

      <Subtitle title="Detalle Decomisos Post-Mortem" widthContainer="100%" />
      <DetailPostMTable
        loteIP="1234"
        fecha="22/01/2020"
        data={detallesDecomiso}
      />
      <DetailPostMTable
        loteIP="1234"
        fecha="22/01/2020"
        data={detallesDecomiso}
      />

      <View
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View style={[styles.conventions, { width: '60%' }]} wrap={false}>
          <View style={styles.backgroundguide}>
            <View style={styles.textconventions}>
              <View style={styles.gpost}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * SBE
                </Text>
              </View>
              <View style={styles.textpost}>
                <Text>Sala de Beneficio de Emergencia</Text>
              </View>
            </View>
            <View style={styles.textconventions}>
              <View style={styles.gpost}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
                  * LB
                </Text>
              </View>
              <View style={styles.textpost}>
                <Text>Línea de Beneficio</Text>
              </View>
            </View>
          </View>
          <View style={styles.borderPM}>
            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>
              Convenciones
            </Text>
          </View>
        </View>
      </View>
      <Footer />
      <Paginationpdf />
    </Page>
  </Document>
);

export const ReportFilter = () => {
  return (
    <div id="screenQrPostMortenFilter" style={{ width: 500 }}>
      <QRCode
        id="screen"
        size={500}
        value="Reporte Inspección Médico Veterinaria - Post Mortem"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};

export const GraphRangePostMortem = () => {
  return (
    <div id="graphrangepostmortem" style={{ width: 500, marginTop: -20 }}>
      <Graphicpdfsf
        textTotalData="Visceras Decomisadas"
        topTextTotalData={-420}
        fontNumberSizeTotal={50}
        fontSizeTotal={30}
        startAngle={-60}
        endAngle={300}
        data={[
          {
            label: 'Pulmón',
            data: 101,
            bg: '#f1ae6c',
          },
          {
            label: 'Piel',
            data: 6,
            bg: '#e29662',
          },
          {
            label: 'Visceras Rojas',
            data: 6,
            bg: '#d47d57',
          },
          {
            label: 'Pierna',
            data: 2,
            bg: '#be5948',
          },
          {
            label: 'Corazón',
            data: 2,
            bg: '#b0413e',
          },
        ]}
      />
    </div>
  );
};
