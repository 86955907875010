import React, { useState } from 'react';

import { Image, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import marketerIcon from './../../../static/img/reportsIcons/marketerIcon';

import styles from './styles';

import ItemTotalCircle from './../ItemTotalCircle/index';
import ItemTotal from './..//ItemTotal/index';

interface Props {
  razonSocial: string;
  totalLotes: number;
  totalAnimales: number;
  data: any;
}

const DetailTable = ({
  razonSocial,
  totalLotes,
  totalAnimales,
  data,
}: Props) => {
  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '5px',
        }}
      >
        <View style={{ width: '58.3%', justifyContent: 'center' }}>
          <View style={styles.backgroundresumetitleImage}>
            <View style={styles.imageicon}>
              <Image src={marketerIcon} />
            </View>

            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <View style={{ width: '28.3%', alignItems: 'center' }}>
                <Text>Productor</Text>
              </View>
              <View style={{ width: '58.3%', fontWeight: 'normal' }}>
                <Text>{razonSocial}</Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '38.3%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              width: '48.3%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ItemTotalCircle
              value={totalLotes}
              info="Total Lotes Productor"
              widthInfo="40px"
              position="right"
              textAlign="left"
              backgroundColor="#ffc676"
            />
          </View>
          <View
            style={{
              width: '48.3%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ItemTotal
              value={totalAnimales}
              info="Total Animales Productor"
              widthValue={50}
              fontSizeValue={12}
              position="right"
              backgroundColor="#e29662"
            />
          </View>
        </View>
      </View>

      <View style={{ width: '100%', marginTop: '5px' }}>
        <View style={[styles.tableMagro, { marginBottom: 5 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '5px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 35.4, width: 35.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote IP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 55.4, width: 55.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Fecha</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 100.4, width: 100.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Granja</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 60.4, width: 60.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Total Animales
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 40.4, width: 40.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Caidos</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 40.4, width: 40.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Agitados</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 40.4, width: 40.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Muertos</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 40.4, width: 40.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Sexo</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 40.4, width: 40.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Inmuno.</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 50.4, width: 50.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Peso Total (Kg)
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 50.4, width: 50.4 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Peso Prom. (Kg)
              </Text>
            </View>
          </View>
          {data?.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 35.4, width: 35.4 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.lote}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 55.4, width: 55.4 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.fecha}
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 100.4, width: 100.4 }]}
              >
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.granja}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 60.4, width: 60.4 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.totalAnimales}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 40.4, width: 40.4 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.caidos}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 40.4, width: 40.4 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.agitados}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 40.4, width: 40.4 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.muertos}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 40.4, width: 40.4 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.sexo}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 40.4, width: 40.4 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.inmuno}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 50.4, width: 50.4 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.pesoTotal}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 50.4, width: 50.4 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.pesoProm}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={{ flexDirection: 'row', width: '100%', marginBottom: '10px' }}
        >
          <View
            style={[
              styles.tableCol,
              {
                maxWidth: 63.1,
                width: 63.1,
                backgroundColor: '#F69A35',
                color: 'white',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              Totales
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 128.1, width: 128.1, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 60.4, width: 60.4, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              270
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 40.4, width: 40.4, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              0
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 40.4, width: 40.4, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              12
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 40.4, width: 40.4, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              0
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 80.8, width: 80.8, backgroundColor: '#F2F2F2' },
            ]}
          ></View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 50.4, width: 50.4, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              5.328,00
            </Text>
          </View>
          <View
            style={[
              styles.tableCol,
              { maxWidth: 50.4, width: 50.4, backgroundColor: '#F2F2F2' },
            ]}
          >
            <Text
              style={[
                styles.tableCellDetail,
                { fontSize: 9, fontWeight: 'bold' },
              ]}
            >
              110,23
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default DetailTable;
