import React from 'react';
import { Typography, Box } from '@material-ui/core';

const textPersonalData = () => {
  return (
    <div>
      <Typography variant="h6" color="secondary" align="center">
        POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE LOS DATOS PERSONALES
      </Typography>
      <Box
        width="50%"
        display="flex"
        justifyContent="space-between"
        margin="12px auto"
      >
        <Typography variant="subtitle2">
          Fecha de versión: 01 Julio 2022
        </Typography>
        <Typography variant="subtitle2">
          Código: 2022 - POLPRITRADATOS
        </Typography>
      </Box>

      <Box margin="12px 0px">
        <Typography variant="h6" color="secondary" align="center">
          CAPÍTULO I
        </Typography>

        <Typography variant="h6" color="secondary" align="center">
          DISPOSICIONES GENERALES
        </Typography>

        <Typography style={{ padding: '8px 0px' }}>
          <strong>ARTÍCULO 1. LEGISLACIÓN APLICABLE.</strong> Este documento fue
          elaborado teniendo en cuenta las disposiciones contenidas en los
          artículos 15 y 20 de la Constitución Política, la Ley 1581 de 2012
          “Por la cual se dictan disposiciones generales para la protección de
          datos personales” y el Decreto 1377 de 2013 “Por el cual se reglamenta
          parcialmente la Ley 1581 de 2012”. A estas Políticas le serán
          aplicables todas las demás normas que complementen o sustituyan las
          anteriores.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          Debido a lo anterior, MERCADEO VIRTUAL S.A. expide la presente
          POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE LOS DATOS PERSONALES
          (2022-POLPRITRADATOS) que reposan en sus bases de datos de sus
          diferentes sitios web, entre los que se encuentra{' '}
          <strong>InfoPorcinos™</strong> (en adelante la “Política”), los cuales
          pertenecen a las personas naturales y jurídicas Titulares de la
          información, que han autorizado a <strong>InfoPorcinos™</strong> para
          manejarlos de conformidad con los lineamientos corporativos y la
          presente Política.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 2. AMBITO DE APLICACIÓN.</strong> Este documento se
          aplica al Tratamiento de los datos de carácter personal que recoja y
          maneje <strong>InfoPorcinos™</strong>, domiciliada en la CALLE 48 NO.
          77 – 78 SECTOR ESTADIO, MEDELLIN (ANTIOQUIA) COLOMBIA con el correo
          electrónico: info@infoporcinos.co y en el teléfono: +57 604 3228603 en
          la ciudad de Medellín.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 3. BASES DE DATOS.</strong> Las políticas y
          procedimientos contenidos en el presente documento aplican a las Bases
          de Datos que maneja <strong>InfoPorcinos™</strong>, las cuales se
          encuentran registradas en el Registro Nacional de Base de Datos de la
          Superintendencia de Industria y Comercio.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 4. OBJETO.</strong>
          La presente política garantiza el cumplimiento de la Constitución
          Política de Colombia, la Ley 1581 de 2012, específicamente el literal
          k) del artículo 17, el cual regula los deberes que asisten a los
          Responsables del Tratamiento de Datos Personales, dentro de los cuales
          se encuentra el de adoptar un manual interno de políticas y
          procedimientos para garantizar el adecuado cumplimiento de la ley y en
          especial, para la atención de consultas y reclamos, así como lo
          estipulado por el artículo 13 del Decreto 1377 de 2013 que establece
          la obligatoriedad por parte de los Responsables del Tratamiento de
          desarrollar sus políticas para el Tratamiento de los Datos Personales
          y velar porque los Encargados del Tratamiento den cabal cumplimiento a
          las mismas. Así mismo tiene la finalidad de regular los procedimientos
          de recolección, manejo y Tratamiento de los datos de carácter personal
          que realiza <strong>InfoPorcinos™</strong>, a fin de garantizar y
          proteger el derecho fundamental de habeas data.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 5. DEFINICIONES.</strong> Para efectos de la
          aplicación de las reglas contenidas en el presente documento y de
          acuerdo con lo establecido en la Ley 1266 de 2008 y en la Ley 1581 de
          2012, se entiende por:
          <br />
          <ol style={{ listStyle: 'lower-latin' }}>
            <li>
              <strong>Administrador de la Base de Datos Personales: </strong>
              Área encargada o Encargado que tiene a cargo y realiza Tratamiento
              a una o más Bases de Datos que tiene información personal.
            </li>
            <li>
              <strong>Autorización: </strong>
              Consentimiento previo, expreso e informado del Titular para llevar
              a cabo el Tratamiento de Datos Personales.
            </li>
            <li>
              <strong>Aviso de Privacidad: </strong>
              Comunicación verbal o escrita generada por el Responsable,
              dirigida al Titular para el Tratamiento de sus Datos Personales,
              mediante la cual se le informa acerca de la existencia de las
              políticas de Tratamiento de información que le serán aplicables,
              la forma de acceder a las mismas y las finalidades del Tratamiento
              que se pretende dar a los Datos Personales.
            </li>
            <li>
              <strong>Base de Datos: </strong>
              Conjunto organizado de Datos Personales que sea objeto de
              Tratamiento.
            </li>
            <li>
              <strong>Dato Personal: </strong>
              Cualquier información vinculada o que pueda asociarse a una o
              varias personas naturales, determinadas o determinables.
            </li>
            <li>
              <strong>
                Datos Personales de los Niños, Niñas y Adolescentes:
              </strong>
              De forma general, los Usuarios de <strong>InfoPorcinos™</strong>{' '}
              deben ser mayores de edad. Los Datos personales de los menores de
              edad, sobre los que se encuentra prohibido su Tratamiento, excepto
              cuando el fin que persiga con dicho Tratamiento responda al
              interés de los niños, niñas y adolescentes y se asegure sin
              excepción alguna el respeto a sus derechos prevalentes.
            </li>
            <li>
              <strong>Dato Público: </strong>
              Es el dato que no sea semiprivado, privado o sensible. Son
              considerados datos públicos, entre otros, los datos relativos al
              estado civil de las personas, a su profesión u oficio y a su
              calidad de comerciante o de servidor público. Por su naturaleza,
              los datos públicos pueden estar contenidos, entre otros, en
              registros públicos, documentos públicos, gacetas y boletines
              oficiales y sentencias judiciales debidamente ejecutoriadas que no
              estén sometidas a reserva.
            </li>
            <li>
              <strong> Dato Privado: </strong>
              Es el dato que por su naturaleza íntima o reservada solo es
              relevante para el Titular.
            </li>
            <li>
              <strong>Dato Semiprivado: </strong>
              El dato que no tiene naturaleza íntima, reservada, ni pública y
              cuyo conocimiento o divulgación puede interesar no solo a su
              Titular, sino a cierto sector o grupo de personas o a la sociedad
              en general, como el dato financiero y crediticio de actividad
              comercial o de servicios a que se refiere el Título IV de la Ley
              1266 de 2008.
            </li>
            <li>
              <strong>Datos Sensibles: </strong>
              Se entiende por Datos Sensibles aquellos que afectan la intimidad
              del Titular o cuyo uso indebido puede generar su discriminación,
              tales como aquellos que revelen el origen racial o étnico, la
              orientación política, las convicciones religiosas o filosóficas,
              la pertenencia a sindicatos, organizaciones sociales, de derechos
              humanos o que promueva intereses de cualquier partido político o
              que garanticen los derechos y garantías de partidos políticos de
              oposición, así como los datos relativos a la salud, a la vida
              sexual y los datos biométricos.
            </li>
            <li>
              <strong>Encargado del Tratamiento: </strong>
              Persona natural o jurídica, pública o privada, que por sí misma o
              en asocio con otros, realice el Tratamiento de Datos Personales
              por cuenta del Responsable del Tratamiento.
            </li>
            <li>
              <strong>Entidades Autorizadas: InfoPorcinos™, </strong>
              las sociedades subordinadas a ella o vinculadas, su matriz o
              controlante.
            </li>
            <li>
              <strong>Responsable del Tratamiento: </strong>
              Persona natural o jurídica, pública o privada, que por sí misma o
              en asocio con otros, decida sobre la Base de Datos y/o el
              Tratamiento de los datos.
            </li>
            <li>
              <strong>Titular: </strong>
              Persona natural o jurídica cuyos Datos Personales sean objeto de
              Tratamiento.
            </li>
            <li>
              <strong>Transferencia: </strong>
              La Transferencia de datos tiene lugar cuando el Responsable y/o
              Encargado del Tratamiento de Datos Personales, ubicado en
              Colombia, envía la información o los Datos Personales a un
              receptor, que a su vez es Responsable del Tratamiento y se
              encuentra dentro o fuera del país.
            </li>
            <li>
              <strong>Transmisión: </strong>
              Tratamiento de Datos Personales que implica la comunicación de los
              mismos dentro o fuera del territorio de la República de Colombia,
              cuando tenga por objeto la realización de un Tratamiento por el
              Encargado por cuenta del Responsable.
            </li>
            <li>
              <strong>Tratamiento: </strong>
              Cualquier operación o conjunto de operaciones sobre Datos
              Personales, tales como la recolección, almacenamiento, uso,
              circulación o supresión de los mismos. Estas definiciones se
              mantendrán cuando se refiera en singular y plural, y se entenderán
              modificadas cuando la ley y/o reglamentación aplicable y/o las
              interpretaciones de las autoridades competentes, las modifiquen.
            </li>
          </ol>
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 6. PRINCIPIOS.</strong> Los principios que se
          establecen a continuación, constituyen los parámetros generales que
          serán respetados por <strong>InfoPorcinos™</strong> en los procesos de
          recolección, uso y tratamiento de Datos Personales.
          <br />
          <ol style={{ listStyle: 'lower-latin' }}>
            <li>
              <strong>
                Principio de legalidad en materia de Tratamiento de datos:{' '}
              </strong>
              El Tratamiento a que se refiere la presente ley es una actividad
              reglada que debe sujetarse a lo establecido en ella y en las demás
              disposiciones que la desarrollen.
            </li>
            <li>
              <strong>Principio de finalidad: </strong>
              El Tratamiento de los Datos Personales recogidos por{' '}
              <strong>InfoPorcinos™</strong> debe obedecer a una finalidad
              legítima de la cual debe ser informada al Titular.
            </li>
            <li>
              <strong>Principio de libertad: </strong>
              El Tratamiento solo puede llevarse a cabo con el consentimiento,
              previo, expreso e informado del Titular. Los Datos Personales no
              podrán ser obtenidos o divulgados sin previa autorización, o en
              ausencia de mandato legal o judicial que releve el consentimiento.
            </li>
            <li>
              <strong> Principio de veracidad o calidad: </strong>
              La información sujeta a Tratamiento debe ser veraz, completa,
              exacta, actualizada, comprobable y comprensible. Se prohíbe el
              Tratamiento de datos parciales, incompletos, fraccionados o que
              induzcan a error.
            </li>
            <li>
              <strong> Principio de transparencia: </strong>
              En el Tratamiento debe garantizarse el derecho del Titular a
              obtener de <strong>InfoPorcinos™</strong> en cualquier momento y
              sin restricciones, información acerca de la existencia de datos
              que le conciernan.
            </li>
            <li>
              <strong>Principio de acceso y circulación restringida:</strong>
              El Tratamiento solo podrá hacerse por las personas autorizadas por
              el Titular y/o por las personas previstas en la Ley. Los Datos
              Personales, salvo la información pública, no podrán estar
              disponibles en Internet u otros medios de divulgación o
              comunicación masiva, salvo que el acceso sea técnicamente
              controlable para brindar un conocimiento restringido solo a los
              Titulares o terceros autorizados.
            </li>
            <li>
              <strong>Principio de seguridad: </strong>
              La información sujeta a Tratamiento por parte de{' '}
              <strong>InfoPorcinos™</strong>, se deberá proteger mediante el uso
              de las medidas técnicas, humanas y administrativas que sean
              necesarias para otorgar seguridad a los registros, evitando su
              adulteración, pérdida, consulta, uso o acceso no autorizado o
              fraudulento;
            </li>
            <li>
              <strong> Principio de confidencialidad: </strong>
              Todas las personas que intervengan en el Tratamiento de Datos
              Personales están obligadas a garantizar la reserva de la
              información, inclusive después de finalizada su relación con
              alguna de las labores que comprende el Tratamiento.
            </li>
          </ol>
        </Typography>
      </Box>

      <Box margin="12px 0px">
        <Typography variant="h6" color="secondary" align="center">
          CAPÍTULO II
        </Typography>
        <Typography variant="h6" color="secondary" align="center">
          AUTORIZACIÓN
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 7. AUTORIZACIÓN.</strong>
          La recolección, almacenamiento, uso, circulación y en general el
          Tratamiento de los Datos Personales que reposen en las Bases de Datos
          de <strong>InfoPorcinos™</strong> requieren del consentimiento libre,
          previo, expreso e informado de los Titulares de los mismos.{' '}
          <strong>InfoPorcinos™</strong>, en su condición de Responsable del
          Tratamiento de Datos Personales, ha dispuesto los mecanismos
          necesarios para obtener la Autorización de los Titulares de los datos,
          con anterioridad a la recolección de sus datos, garantizando en todo
          caso que sea posible verificar y probar el otorgamiento de dicha
          Autorización. Los datos personales de los Titulares se mantendrán en
          las Bases de Datos de <strong>InfoPorcinos™</strong> durante el tiempo
          que los mismos sean utilizados para los fines autorizados, a menos que
          el Titular solicite su eliminación.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            ARTÍCULO 8. FORMA Y MECANISMOS PARA OTORGAR LA AUTORIZACIÓN.
          </strong>
          La Autorización puede constar en un documento físico, electrónico,
          mensaje de datos (correo electrónico), Chat de WhatsApp, Internet,
          sitio web o también de manera verbal o telefónica o en cualquier otro
          formato que permita garantizar su posterior consulta; o mediante una
          conducta inequívoca del Titular que permita concluir de forma
          razonable que otorgó la autorización; o a través de un mecanismo
          técnico o tecnológico idóneo mediante el cual se pueda concluir de
          manera inequívoca, que de no haber obtenido el consentimiento del
          Titular, los datos nunca hubieren sido recolectados y almacenados en
          la Base de Datos. Con el procedimiento de autorización consentida se
          garantiza que se ha puesto en conocimiento del Titular de los Datos
          Personales, que su información personal será recogida y utilizada para
          fines determinados y conocidos de acuerdo con la presente Política y
          el correspondiente Aviso de Privacidad y el derecho que le asiste para
          solicitar el acceso, la actualización, rectificación y eliminación de
          sus Datos Personales en cualquier momento, a través de los mecanismos
          puestos a su disposición por <strong>InfoPorcinos™</strong>. Lo
          anterior con el fin de que el Titular tome decisiones informadas con
          relación a sus Datos Personales y controle el uso de su información
          personal.
          <br />
          La Autorización es una declaración que informa al Titular de los Datos
          Personales:
          <br />
          <ol style={{ listStyle: 'lower-latin' }}>
            <li>Quién recopila su información personal.</li>
            <li>Qué recopila (datos que se recaban).</li>
            <li>
              Para qué recoge los datos (las finalidades del Tratamiento).
            </li>
            <li>
              Cómo ejercer derechos de acceso, corrección, actualización o
              supresión de los Datos Personales suministrados.
            </li>
            <li>
              Informar al Titular que por tratarse de Datos Sensibles (si
              aplica), no está obligado a autorizar su Tratamiento.
            </li>
          </ol>
          Esta declaración se realiza a través del Aviso de Privacidad, según se
          define más adelante.
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 9. PRUEBA DE LA AUTORIZACIÓN.</strong>
          <strong>InfoPorcinos™</strong> adoptará las medidas necesarias para
          mantener registros o mecanismos técnicos o tecnológicos idóneos de
          cuándo y cómo obtuvo la Autorización por parte de los titulares de
          Datos Personales para el Tratamiento de los mismos.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 10. AVISO DE PRIVACIDAD:</strong>
          El Aviso de Privacidad es el documento físico, electrónico o en
          cualquier otro formato, que es puesto a disposición del Titular para
          que éste se informe del Tratamiento que le va a dar{' '}
          <strong>InfoPorcinos™</strong> a sus Datos Personales, con
          anterioridad al momento que se autorice la recolección de los Datos
          Personales. A través de este documento se informa al Titular la
          existencia de las Políticas de Tratamiento de información que le serán
          aplicables, la forma de acceder a las mismas y las características del
          Tratamiento que se pretende dar a los Datos Personales.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            ARTÍCULO 11. CONTENIDO MÍNIMO DEL AVISO DE PRIVACIDAD.
          </strong>
          El Aviso de Privacidad, como mínimo, deberá contener la siguiente
          información:
          <br />
          <ol style={{ listStyle: 'lower-latin' }}>
            <li>
              La identidad, domicilio y datos de contacto del Responsable del
              Tratamiento.
            </li>
            <li>
              El tipo de Tratamiento al cual serán sometidos los datos y la
              finalidad del mismo.
            </li>
            <li>Los derechos que le asisten al Titular.</li>
            <li>
              Los mecanismos generales dispuestos por el Responsable para que el
              Titular conozca la política de Tratamiento de la información y los
              cambios sustanciales que se produzcan en ella o en el Aviso de
              Privacidad correspondiente. En todos los casos, debe informar al
              Titular cómo acceder o consultar la política de Tratamiento de
              información.
            </li>
            <li>
              No obstante lo anterior, cuando se recolecten Datos Personales
              Sensibles, el Aviso de Privacidad señalará expresamente el
              carácter facultativo de la respuesta a las preguntas que versen
              sobre este tipo de datos.
            </li>
          </ol>
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            ARTÍCULO 12. AVISO DE PRIVACIDAD Y LAS POLÍTICAS DE TRATAMIENTO DE
            LA INFORMACIÓN. InfoPorcinos™
          </strong>
          conservará el modelo del Aviso de Privacidad que se transmitió a los
          Titulares mientras se lleve a cabo el Tratamiento de Datos Personales
          y perduren las obligaciones que de éste se deriven. Para el
          almacenamiento del modelo, <strong>InfoPorcinos™</strong> podrá
          emplear medios informáticos, electrónicos o cualquier otra tecnología.
          <br />
        </Typography>
      </Box>

      <Box margin="12px 0px">
        <Typography variant="h6" color="secondary" align="center">
          CAPÍTULO III
        </Typography>

        <Typography variant="h6" color="secondary" align="center">
          DERECHOS Y DEBERES
        </Typography>

        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            ARTÍCULO 13. DERECHOS DE LOS TITULARES DE LA INFORMACIÓN.
          </strong>
          De conformidad con lo establecido en el artículo 8 de la Ley 1581 de
          2012 y los artículos 21 y 22 del Decreto 1377 de 2013 el Titular de
          los Datos Personales tiene los siguientes derechos:
          <br />
          <ol style={{ listStyle: 'lower-latin' }}>
            <li>
              Conocer, actualizar y rectificar sus Datos Personales frente a{' '}
              <strong>InfoPorcinos™</strong>, en su condición de Responsable del
              Tratamiento.
            </li>
            <li>
              Solicitar prueba de la Autorización otorgada a{' '}
              <strong>InfoPorcinos™</strong>, en su condición de Responsable del
              Tratamiento.
            </li>
            <li>
              Ser informado por <strong>InfoPorcinos™</strong>, previa
              solicitud, respecto del uso que le ha dado a sus Datos Personales.
            </li>
            <li>
              Presentar ante la Superintendencia de Industria y Comercio de
              Colombia quejas por infracciones a lo dispuesto en la Ley 1581 de
              2012 y las demás normas que la modifiquen, adicionen o
              complementen, una vez haya agotado el trámite de consulta o
              reclamo ante el Responsable del Tratamiento.
            </li>
            <li>
              Revocar la Autorización y/o solicitar la supresión del dato cuando
              en el Tratamiento no se respeten los principios, derechos y
              garantías constitucionales y legales.
            </li>
            <li>
              Acceder en forma gratuita a sus Datos Personales que hayan sido
              objeto de Tratamiento. <strong>InfoPorcinos™</strong> mantendrá
              habilitados medios de contacto para que los Titulares de datos
              puedan ejercer sus derechos y dar aplicación a los procedimientos
              previstos en esta Política, los cuales serán informados y puestos
              a disposición en el Aviso de Privacidad.
            </li>
          </ol>
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>
            ARTÍCULO 14. DEBERES DE InfoPorcinos™ COMO RESPONSABLE EN RELACIÓN
            CON EL TRATAMIENTO DE LOS DATOS PERSONALES. InfoPorcinos™
          </strong>{' '}
          tendrá presente, en todo momento, que los Datos Personales son
          propiedad de las personas a las que se refieren y que sólo ellas
          pueden decidir sobre los mismos. En este sentido, hará uso de ellos
          sólo para aquellas finalidades para las que se encuentra facultado
          debidamente y respetando en todo caso la Ley 1581 de 2012, el Decreto
          1377 de 2013 y las demás normas aplicables sobre protección de Datos
          Personales.
          <br />
          De conformidad con lo establecido en el artículo 17 de la Ley 1581 de
          2012 y los artículos 21 y 22 del Decreto 1377 de 2013,{' '}
          <strong>InfoPorcinos™ </strong>
          se compromete a cumplir en forma permanente con los siguientes deberes
          en lo relacionado con en el Tratamiento de Datos Personales:
          <br />
          <ol style={{ listStyle: 'lower-latin' }}>
            <li>
              Garantizar al Titular, en todo tiempo, el pleno y efectivo
              ejercicio del derecho de hábeas data.
            </li>
            <li>
              Conservar la información bajo las condiciones de seguridad
              necesarias para impedir su adulteración, pérdida, consulta, uso o
              acceso no autorizado o fraudulento.
            </li>
            <li>
              Informar debidamente al Titular sobre la finalidad de la
              recolección y los derechos que le asisten por virtud de la
              Autorización otorgada.
            </li>
            <li>
              Garantizar que la información que se suministre al Encargado del
              Tratamiento sea veraz, completa, exacta, actualizada, comprobable
              y comprensible.
            </li>
            <li>
              Realizar oportunamente, esto es en los términos previstos en los
              artículos 14 y 15 de la Ley 1581 de 2012, la actualización,
              rectificación o supresión de los datos.
            </li>
            <li>
              Suministrar al Encargado del Tratamiento, según el caso,
              únicamente datos cuyo Tratamiento esté previamente autorizado de
              conformidad con lo previsto en la presente ley.
            </li>
            <li>
              Exigir al Encargado del Tratamiento, en todo momento, el respeto a
              las condiciones de seguridad y privacidad de la información del
              Titular.
            </li>
            <li>
              Informar a solicitud del Titular sobre el uso dado a sus datos.
            </li>
            <li>
              Tramitar las consultas y los reclamos formulados por los Titulares
              en los términos señalados en los artículos 14 y 15 de la Ley 1581
              de 2012.
            </li>
            <li>
              Insertar en la Base de Datos la leyenda "información en discusión
              judicial" una vez notificado por parte de la autoridad competente
              sobre procesos judiciales relacionados con la calidad o detalles
              del Dato Personal.
            </li>
            <li>
              Insertar en la Base de Datos la leyenda “reclamo en trámite” y el
              motivo del mismo, en un término no mayor a dos (2) días hábiles de
              recibido el reclamo completo.
            </li>
            <li>
              Abstenerse de circular información que esté siendo controvertida
              por el Titular y cuyo bloqueo haya sido ordenado por la
              Superintendencia de Industria y Comercio.
            </li>
            <li>
              Permitir el acceso a la información únicamente a las personas que
              pueden tener acceso a ella.
            </li>
            <li>
              Informar a la Superintendencia de Industria y Comercio cuando se
              presenten violaciones a los códigos de seguridad y existan riesgos
              en la administración de la información de los Titulares.
            </li>
            <li>
              Designar a un área que asuma la función de protección de Datos
              Personales, que dará trámite a las solicitudes de los Titulares,
              para el ejercicio de los derechos a que se refiere la Ley 1581 de
              2012 y el Decreto 1377 de 2013.
            </li>
            <li>
              Cumplir las instrucciones y requerimientos que imparta la
              Superintendencia de Industria y Comercio.
            </li>
          </ol>
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 15. DERECHO DE ACCESO.</strong>
          El poder de disposición o decisión que tiene el Titular sobre la
          información que le concierne, conlleva necesariamente el derecho de
          acceder y consultar si su información personal está siendo objeto de
          Tratamiento, así como el alcance, condiciones y generalidades de dicho
          Tratamiento. De esta manera, <strong>InfoPorcinos™</strong> debe
          garantizar al Titular su derecho de acceso en tres vías:
          <br />
          <ol style={{ listStyle: 'lower-latin' }}>
            <li>
              La primera implica que el Titular pueda conocer la efectiva
              existencia del Tratamiento a que son sometidos sus Datos
              Personales.
            </li>
            <li>
              La segunda, que el Titular pueda tener acceso a sus Datos
              Personales que están en posesión del Responsable.
            </li>
            <li>
              La tercera, supone el derecho a conocer las circunstancias
              esenciales del Tratamiento, lo cual se traduce en el deber de
              <strong> InfoPorcinos™</strong> de informar al Titular sobre el
              tipo de Datos Personales tratados y todas y cada una de las
              finalidades que justifican el Tratamiento.
            </li>
          </ol>
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>PARAGRAFO: InfoPorcinos™ </strong>
          garantizará el derecho de acceso cuando, previa acreditación de la
          identidad del Titular o calidad de su representante, se ponga a
          disposición de este, de manera gratuita, el detalle de los Datos
          Personales a través de los medios habilitados para el efecto.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 16. RECTIFICACIÓN Y ACTUALIZACIÓN DE DATOS.</strong>
          El Titular del dato tiene derecho a solicitar la actualización o
          rectificación de sus Datos Personales. <strong>InfoPorcinos™ </strong>
          tiene la obligación de rectificar y actualizar, a solicitud del
          Titular, la información de este que resulte ser incompleta o inexacta,
          de conformidad con el procedimiento señalado en la presente Política.
          En las solicitudes de rectificación y actualización de Datos
          Personales, el Titular debe indicar las correcciones a realizar, para
          lo cual en algunos casos se solicitará la documentación que avale su
          petición.
          <br />
          <br />
          <strong>InfoPorcinos™</strong> tiene plena libertad de habilitar
          mecanismos que le faciliten el ejercicio de este derecho, siempre y
          cuando éstos beneficien al Titular. En consecuencia, se podrán
          habilitar medios electrónicos u otros que considere pertinentes.
          <br />
          <br />
          <strong>InfoPorcinos™</strong> podrá establecer formularios, sistemas
          y otros métodos simplificados, mismos que deben ser informados y que
          se pondrán a disposición de los interesados en la página web.
          <br />
          <br />
          Cada vez que <strong>InfoPorcinos™</strong> ponga a disposición una
          herramienta nueva para facilitar el ejercicio de sus derechos por
          parte de los Titulares de información o modifique las existentes, lo
          informará a través de su página web.
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 17. SUPRESIÓN DE DATOS.</strong>
          El Titular tiene el derecho, en todo momento, a solicitar a
          <strong> InfoPorcinos™</strong> la supresión (eliminación) de sus
          Datos Personales cuando:
          <br />
          <ol style={{ listStyle: 'lower-latin' }}>
            <li>
              Desee que sus datos sean eliminados de las Bases de Datos de
              <strong> InfoPorcinos™</strong>.
            </li>
            <li>
              Considere que los mismos no están siendo tratados conforme a los
              principios, deberes y obligaciones previstas en la Ley 1581 de
              2012 y el Decreto 1377 de 2013.
            </li>
            <li>
              Hayan dejado de ser necesarios o pertinentes para la finalidad
              para la cual fueron recabados.
            </li>
            <li>
              Se haya superado el periodo necesario para el cumplimiento de los
              fines para los que fueron recabados.
            </li>
          </ol>
          La supresión implica la eliminación total o parcial de la información
          personal de acuerdo con lo solicitado por el Titular en los registros,
          archivos, Bases de Datos o Tratamientos realizados por{' '}
          <strong>InfoPorcinos™</strong>. Es importante tener en cuenta que el
          derecho de cancelación no es absoluto y el Responsable puede negar el
          ejercicio de este cuando:
          <br />
          <ol style={{ listStyle: 'lower-roman' }}>
            <li>
              El Titular tenga un deber legal o contractual de permanecer en la
              Base de Datos.
            </li>
            <li>
              La eliminación de datos obstaculice actuaciones judiciales o
              administrativas vinculadas a obligaciones fiscales, la
              investigación y persecución de delitos o la actualización de
              sanciones administrativas.
            </li>
            <li>
              Los datos sean necesarios para proteger los intereses
              jurídicamente tutelados del Titular; para realizar una acción en
              función del interés público, o para cumplir con una obligación
              legalmente adquirida por el Titular.
            </li>
          </ol>
          En caso de resultar procedente la cancelación de los Datos Personales,
          <strong> InfoPorcinos™</strong> debe realizar operativamente la
          supresión, de tal manera que la eliminación no permita la recuperación
          de la información.
          <br />
        </Typography>
        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 18. REVOCATORIA DE LA AUTORIZACIÓN.</strong>
          Los Titulares de los Datos Personales pueden revocar el consentimiento
          al Tratamiento de sus Datos Personales en cualquier momento, siempre y
          cuando no lo impida una disposición legal o contractual. Para ello,
          <strong> InfoPorcinos™</strong> deberá establecer mecanismos
          sencillos, de fácil acceso y gratuitos que permitan al Titular revocar
          su consentimiento, al menos por el mismo medio por el que lo otorgó y
          en los términos estipulados en la ley 1581 de 2012, sus Decretos
          reglamentarios y normas modificatorias o complementarias.
          <br />
          <br />
          Se deberá tener en cuenta que existen dos modalidades en las que la
          revocación del consentimiento puede darse. La primera, puede ser sobre
          la totalidad de las finalidades consentidas, esto es, que
          <strong> InfoPorcinos™</strong> deba dejar de tratar por completo los
          Datos del Titular; la segunda, puede ocurrir sobre tipos de
          Tratamiento determinados, como por ejemplo para fines publicitarios o
          de estudios de mercado. Con la segunda modalidad, esto es, la
          revocación parcial del consentimiento, se mantienen a salvo otros
          fines del Tratamiento que el Responsable, de conformidad con la
          Autorización otorgada, puede llevar a cabo y con los que el Titular
          está de acuerdo.
          <br />
          <br />
          Por lo anterior, será necesario que el Titular al momento de elevar la
          solicitud de revocatoria, indique en ésta si la revocación que
          pretende realizar es total o parcial. En la segunda hipótesis se
          deberá indicar con cuál Tratamiento el Titular no está conforme.
          <br />
          <br />
          Habrá casos en que el consentimiento, por su carácter necesario en la
          relación entre Titular y Responsable por el cumplimiento de un
          contrato, por disposición legal no podrá ser revocado.
          <br />
          <br />
          Los mecanismos o procedimientos que <strong>InfoPorcinos™</strong>
          establezca para atender las solicitudes de revocatoria del
          consentimiento otorgado no podrán exceder los plazos previstos para
          atender las reclamaciones conforme se señala en el artículo 15 de la
          Ley 1581 de 2012.
        </Typography>
      </Box>

      <Box margin="12px 0px">
        <Typography variant="h6" color="secondary" align="center">
          CAPÍTULO IV
        </Typography>

        <Typography variant="h6" color="secondary" align="center">
          PROCEDIMIENTOS PARA EL EJERCICIO DE LOS DERECHOS
        </Typography>

        <Typography style={{ padding: '12px 0px' }}>
          <strong>ARTÍCULO 19. CONSULTAS.</strong>
          De conformidad con lo establecido en el artículo 14 de la Ley 1581 de
          2012 y el artículo 21 del Decreto 1377 de 2013, los Titulares o sus
          causahabientes podrán consultar la información personal del Titular
          que repose en cualquier Base de Datos. En consecuencia, InfoPorcinos™
          garantizará el derecho de consulta, suministrando a los Titulares,
          toda la información contenida en el registro individual o que esté
          vinculada con la identificación del Titular, bajo las siguientes
          reglas:
          <br />
          <ol style={{ listStyle: 'lower-latin' }}>
            <li>
              El Titular podrá consultar de forma gratuita sus datos personales:
              al menos una vez cada mes calendario, y cada vez que existan
              modificaciones sustanciales de las políticas de Tratamiento de la
              información que motiven nuevas consultas.
            </li>
            <li>
              El derecho de consulta se podrá ejercer por: (i) El Titular o sus
              causahabientes, previa acreditación de su identidad, o a través de
              instrumentos electrónicos que le permitan identificarse. (ii) Por
              el representante y/o apoderado del Titular, previa acreditación de
              la representación o apoderamiento. (iii) Por estipulación a favor
              de otro o para otro. (iv) Los derechos de los niños, niñas o
              adolescentes se ejercerán por las personas que estén facultadas
              para representarlos. Cuando la solicitud sea formulada por persona
              distinta del Titular y no se acredite que la misma actúa en
              representación de aquél, se tendrá por no presentada.
            </li>
            <li>
              Información mínima (i) El nombre y domicilio del Titular o
              cualquier otro medio para recibir la respuesta. (ii) Los
              documentos que acrediten la identidad o la personalidad de su
              representante. (iii) La descripción clara y precisa de los Datos
              Personales respecto de los cuales el Titular busca ejercer alguno
              de los derechos. (iv) En caso dado, otros elementos o documentos
              que faciliten la localización de los Datos Personales.
            </li>
            <li>
              Se deberán utilizar los medios de comunicación que se han
              habilitado para las consultas, como son: Calle 48 No. 77 – 78
              Medellín, Teléfono + 57 604 3228603 correo electrónico:
              info@infoporcinos.com.
            </li>
            <li>
              En cualquier caso, independientemente del mecanismo implementado
              para la atención de solicitudes de consulta, las mismas serán
              atendidas en un término máximo de diez (10) días hábiles contados
              a partir de la fecha de su recibo. Cuando no fuere posible atender
              la consulta dentro de dicho término, se informará al interesado
              antes del vencimiento de los diez (10) días, expresando los
              motivos de la demora y señalando la fecha en que se atenderá su
              consulta, la cual en ningún caso podrá superar los cinco (5) días
              hábiles siguientes al vencimiento del primer plazo.
            </li>
          </ol>
        </Typography>
      </Box>
      <Box>
        <Typography>
          [1] Decreto 1377 De 2013: “Por el cual se reglamenta parcialmente la
          Ley 1581 de 2012” <br />
          [2] Las definiciones que se emplean en el presente artículo, son
          elementos indispensables para la protección del derecho de habeas
          data, y permiten una correcta y apropiada interpretación del presente
          documento y de las disposiciones contenidas en la Ley 1266 de 2008, la
          Ley 1581 de 2012, sus Decretos reglamentarios y demás normas
          vinculantes, y contribuyen a determinar las responsabilidades de los
          involucrados en el tratamiento de datos personales.
        </Typography>
        <br />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <strong>MERCADEO VIRTUAL S.A.</strong>
          <br />
          Calle 48 No. 77 – 78 Sector Estadio Velódromo <br />
          Zona Postal 050034
          <br /> Medellín – Colombia <br />
          Pbx + 57 604 3228603
          <br />
          <a href="http://www.mercadeo-virtual.com" target="_blank">
            www.mercadeo-virtual.com
          </a>{' '}
          /{' '}
          <a
            href="http://www.infoporcinos.com/sitio/Principal.aspx"
            target="_blank"
          >
            www.infoporcinos.com
          </a>
          <br />
          Correo: info@infoporcinos.com
        </Box>
        <Box marginRight={2}>
          <img src="https://raw.githubusercontent.com/repositoriosmv/infoporcinos-images/main/logo-mv1.png" />
        </Box>
      </Box>
    </div>
  );
};

export default textPersonalData;
