import React, { useEffect, useState } from "react";

import {
  Grid,
  MenuItem,
  TextField,
  Box,
  ButtonGroup,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Fab,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";

import AddressShowcase from "components/AddressShowcase";
import SelectCountry from "components/SelectCountry";
import CoordsForm from "./CoordsForm";

import { COUNTRIES, DEPARTMENTS, CITIES } from "core/graphql/queries/countries";
import { GET_ROUTES } from "core/graphql/queries/getRoutes";
import config from "config/secrets";

import { GoogleApiWrapper } from "google-maps-react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import _ from "lodash";

import useStyles from "./styles";

export interface SelectDirectionProps {
  names: {
    directionType: string;
    viaType: string;
    viaNumber: string;
    apendNumber: string;
    cruceNumber: string;
    apendCruceNumber: string;
    cornerMeters: string;
    aditional: string;
    postalCode: string;
    latitude?: string;
    longitude?: string;
    direction?: string;
    idPais: string;
    idDepartamento: string;
    city: string;
    coords?: string;
    stratum?: string;
  };
  values: {
    directionType: string | number | any;
    viaType: string | number | any;
    viaNumber: string | number | any;
    apendNumber: string | number | any;
    cruceNumber: string | number | any;
    apendCruceNumber: string | number | any;
    cornerMeters: string | number | any;
    aditional: string | number | any;
    postalCode: string | number | any;
    latitude?: string | number | any;
    longitude?: string | number | any;
    direction?: string;
    idPais: string | number | any;
    idDepartamento: string | number | any;
    city: string | number | any;
    coords?: string | number | any;
    stratum?: string | number | any;
  };
  errors: {
    directionType?: string;
    viaType?: string;
    viaNumber?: string;
    apendNumber?: string;
    cruceNumber?: string;
    apendCruceNumber?: string;
    cornerMeters?: string;
    aditional?: string;
    postalCode?: string;
    latitude?: string;
    longitude?: string;
    direction?: string;
    idPais?: string;
    idDepartamento?: string;
    city?: string;
    coords?: string | number | any;
    stratum?: string | number | any;
  };
  google: any;
  setFieldValue?: any;
  handleChange: any;
  handleBlur?: any;
  touched?: any;
  disable?: boolean;
  withCoordinates?: boolean;
  stratum?: boolean;
}

const Direction = ({
  names,
  values,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  touched,
  disable,
  withCoordinates,
  stratum,
}: SelectDirectionProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const geocoder = new window.google.maps.Geocoder();
  const [selectDirection, setSelectDirection] = useState(values?.coords ?? 1);
  const [selectDirectionType, setSelectDierectioType] = useState(
    values?.directionType ?? 1
  );
  const [address, setAddress] = useState("");
  const estratos = [0, 1, 2, 3, 4, 5, 6, "Rural", "Comercial"];
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSelectionDirectionType = (event: any) => {
    setSelectDierectioType(Number(event.target.value));
    handleChange(event);
  };

  const { data: dataViaTypes } = useQuery(GET_ROUTES, {
    context: {
      headers: {
        "x-auth-jwt": token,
      },
    },
  });

  const { data: dataCountry } = useQuery(COUNTRIES, {
    context: { headers: { "x-auth-jwt": token } },
  });

  const { data: dataDepartment } = useQuery(DEPARTMENTS, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idCountry: values?.idPais },
  });

  const { data: dataCity } = useQuery(CITIES, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idState: values?.idDepartamento },
  });

  const nameCountry = dataCountry?.countries?.find(
    (el: any) => el.id === `${values?.idPais}`
  )?.name;

  const nameDepartment = dataDepartment?.departments?.find(
    (el: any) => el.idDepartment === `${values?.idDepartamento}`
  )?.departmentName;

  const nameCity = dataCity?.cities?.find(
    (el: any) => el.idCity === `${values?.city}`
  )?.nameCity;

  const datosPro = _.filter(dataViaTypes?.roads, {
    idTipoVia: values.viaType,
  });

  useEffect(() => {
    if (nameCity && datosPro[0]?.descripcion) {
      setAddress(
        `${
          datosPro[0]?.descripcion +
          " " +
          values?.viaNumber +
          values?.apendNumber +
          " #" +
          values?.cruceNumber +
          values?.apendCruceNumber.replace(/\s/g, "") +
          " " +
          values?.cornerMeters
        } ${nameCity}, ${nameDepartment}, ${nameCountry}`
      );
    }
  }, [
    datosPro,
    values?.viaNumber,
    values?.apendNumber,
    values?.cruceNumber,
    values?.apendCruceNumber,
    values?.cornerMeters,
    nameCity,
  ]);

  const setPostalCode = () => {
    setIsLoading(true);
    geocoder
      .geocode({
        address: address,
      })
      .then((data) => {
        setIsLoading(false);
        const result = data.results[0];
        for (var j = 0; j < result.address_components.length; j++) {
          for (var k = 0; k < result.address_components[j].types.length; k++) {
            if (result.address_components[j].types[k] == "postal_code") {
              const zipcode = result.address_components[j].long_name;
              setFieldValue(names.postalCode, zipcode);
            }
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.message);
      });
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginY={3}
      >
        {withCoordinates ? (
          <ButtonGroup disableElevation variant="contained" color="primary">
            <Button
              variant="contained"
              color={selectDirection === 1 ? "primary" : "default"}
              onClick={() => {
                setSelectDirection(1);
                setFieldValue(names.coords, 1);
              }}
            >
              Dirección Manual
            </Button>
            <Button
              variant="contained"
              color={selectDirection === 2 ? "primary" : "default"}
              onClick={() => {
                setSelectDirection(2);
                setFieldValue(names.coords, 2);
              }}
            >
              Coordenadas GD
            </Button>
          </ButtonGroup>
        ) : null}

        {selectDirection === 1 && (
          <Box marginLeft={2}>
            <FormControl className={classes.formCheck}>
              <RadioGroup
                name={names.directionType}
                value={Number(selectDirectionType) ?? 1}
                onChange={handleChangeSelectionDirectionType}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label={"Urbano"}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label={"Rural"}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
      </Box>

      {withCoordinates && Number(selectDirection) === 2 ? (
        <>
          <div className={classes.coordinatesSeparate}>
            <CoordsForm
              latProp={values.latitude}
              lngPro={values.longitude}
              values={values}
              names={names}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
          </div>
        </>
      ) : Number(values?.directionType) === 1 ? (
        <>
          {dataViaTypes?.roads.map((data: any) => {
            if (data.idTipoVia === values.viaType) {
              return (
                <Box className={classes.boxBorder}>
                  <AddressShowcase
                    id={data.idTipoVia}
                    roadType={data.descripcion}
                    numberVia={values.viaNumber}
                    viaAppend={values.apendNumber}
                    numberCrossing={values.cruceNumber}
                    crossingAppend={values.apendCruceNumber}
                    cornerMeters={values.cornerMeters}
                    additional={values.aditional}
                  />
                </Box>
              );
            }
          })}
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectCountry
                  disable={disable}
                  names={{
                    country: names.idPais,
                    departament: names.idDepartamento,
                    city: names.city,
                  }}
                  values={{
                    country: values.idPais,
                    departament: values.idDepartamento,
                    city: values.city,
                  }}
                  errors={{
                    country: errors.idPais,
                    departament: errors.idDepartamento,
                    city: errors.city,
                  }}
                  onChange={handleChange}
                  helperText={{
                    country: errors.idPais,
                    departament: errors.idDepartamento,
                    city: errors.city,
                  }}
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  label="Tipo Vía"
                  required
                  size="small"
                  variant="outlined"
                  fullWidth
                  select
                  name={names.viaType}
                  value={values.viaType}
                  error={!!errors.viaType}
                  helperText={errors.viaType}
                  onChange={handleChange}
                  disabled={disable}
                >
                  {Array.isArray(dataViaTypes?.roads) &&
                    dataViaTypes?.roads.map((data: any) => (
                      <MenuItem key={data.idTipoVia} value={data.idTipoVia}>
                        {data.descripcion}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  label="Nro.Vía"
                  required
                  size="small"
                  variant="outlined"
                  type="number"
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 3);
                  }}
                  fullWidth
                  name={names.viaNumber}
                  value={values.viaNumber}
                  error={!!errors.viaNumber}
                  helperText={errors.viaNumber}
                  onChange={handleChange}
                  disabled={disable}
                />
              </Grid>

              <Grid item xs={4} md={2}>
                <TextField
                  label="Ap.Vía"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    maxlength: 10,
                  }}
                  name={names.apendNumber}
                  value={values.apendNumber}
                  error={!!errors.apendNumber}
                  helperText={errors.apendNumber}
                  onChange={handleChange}
                  disabled={disable}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4} md={2}>
                <TextField
                  label="Nro.Cruce"
                  required
                  size="small"
                  variant="outlined"
                  type="number"
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 3);
                  }}
                  fullWidth
                  name={names.cruceNumber}
                  value={values.cruceNumber}
                  error={!!errors.cruceNumber}
                  helperText={errors.cruceNumber}
                  onChange={handleChange}
                  disabled={disable}
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  label="Ap.Cruce"
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxlength: 10,
                  }}
                  name={names.apendCruceNumber}
                  value={values.apendCruceNumber}
                  error={!!errors.apendCruceNumber}
                  helperText={errors.apendCruceNumber}
                  onChange={handleChange}
                  disabled={disable}
                />
              </Grid>

              <Grid item xs={4} md={2}>
                <TextField
                  label="M.Esquina"
                  required
                  size="small"
                  variant="outlined"
                  type="number"
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 3);
                  }}
                  fullWidth
                  name={names.cornerMeters}
                  value={values.cornerMeters}
                  error={!!errors.cornerMeters}
                  helperText={errors.cornerMeters}
                  onChange={handleChange}
                  disabled={disable}
                />
              </Grid>
              <Grid item xs={stratum ? 6 : 8}>
                <TextField
                  label="Adicional"
                  size="small"
                  variant="outlined"
                  fullWidth
                  name={names.aditional}
                  value={values.aditional}
                  error={!!errors.aditional}
                  helperText={errors.aditional}
                  onChange={handleChange}
                  disabled={disable}
                />
              </Grid>
              {stratum ? (
                <Grid item xs={2}>
                  <TextField
                    label="Estrato"
                    size="small"
                    variant="outlined"
                    fullWidth
                    select
                    name={names.stratum}
                    value={values.stratum}
                    error={!!errors.stratum}
                    helperText={errors.stratum}
                    onChange={handleChange}
                    disabled={disable}
                    required
                  >
                    {estratos.map((stratum: any) => (
                      <MenuItem key={stratum} value={stratum}>
                        {stratum}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : null}
              <Grid item xs={4}>
                <Box display="flex" alignItems="center">
                  <TextField
                    label="Zona Postal"
                    size="small"
                    variant="outlined"
                    className={classes.postalCode}
                    fullWidth
                    name={names.postalCode}
                    value={values.postalCode}
                    error={!!errors.postalCode}
                    helperText={errors.postalCode}
                    onChange={handleChange}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {isLoading && (
                            <CircularProgress size={20} color="primary" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box marginLeft={1}>
                    <Tooltip title="Calcular código postal">
                      <Fab size="small" color="primary" onClick={setPostalCode}>
                        <i className="icon-search" style={{ fontSize: 20 }} />
                      </Fab>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectCountry
              disable={disable}
              names={{
                country: names.idPais,
                departament: names.idDepartamento,
                city: names.city,
              }}
              values={{
                country: values.idPais,
                departament: values.idDepartamento,
                city: values.city,
              }}
              errors={{
                country: errors.idPais,
                departament: errors.idDepartamento,
                city: errors.city,
              }}
              onChange={handleChange}
              helperText={{
                country: errors.idPais,
                departament: errors.idDepartamento,
                city: errors.city,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Dirección"
              size="small"
              variant="outlined"
              fullWidth
              required
              name={names.aditional}
              value={values.aditional}
              error={!!errors.aditional}
              helperText={errors.aditional}
              onChange={handleChange}
              disabled={disable}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: config.API_KEY,
})<SelectDirectionProps>(Direction);
