import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  contentCard: {
    width: '100%',
    padding: '24px !important',
  },
  contentCardPlacas: {
    width: '100%',
    padding: '24px !important',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  userNameContainer: {
    display: 'flex',
    margin: 20
  },
  avatarUser: {
    width: 100,
    height: 100,
  },
  containerUserInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: "#767676",
    marginLeft: 30
  },
  boldText: {
    fontWeight: 'bolder'
  },
  dataUserContainer: {
    width: '100%',
    margin: 12
  },
  itemListData: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    "& .MuiTypography-root": {
      color: "#767676",
    }
  },
  itemList: {
    display: 'flex',
    justifyContent: 'space-between',
    color: "#767676",
  },
  switch: {
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      background: theme.palette.success.main,
      opacity: 1
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "#fff"
    }
  },

  sizeAvatar: {
    width: '125px',
    height: '125px'
  },
  fontColor: {
    color: '#767676',
  },
  colorHr: {
    borderColor: '#E2E2E2',
  },
  subTitileColor: {
    color: '#767676',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  margin: {
    marginRight: '10px'
  },
  fontInfo: {
    color: '#AFAFAF',
    fontWeight: 'bold',
  },
  widthCard: {
    padding: '25px 50px 25px 50px !important',
  },
  chipContainer: {
    marginTop: '25px',
  },
  paper: {
    padding: theme.spacing(2),
  },
  gridContainer: {
    transition: 'transform 250ms',
    '& :hover': {
      transform: 'translateY(-10px)'
    }
  },
  tabContainer: {
    cursor: 'pointer',
    minHeight: '110px',
    whiteSpace: 'normal'
  },
  tabsContainer: {
    alignItems: 'center',
    [theme.breakpoints.between( 0, 600)]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.between( 600, 1790)]: {
      maxWidth: '350px',
    },
    [theme.breakpoints.between( 1790, 2050)]: {
      maxWidth: '85%',
    },
    "& .MuiTabs-indicator": {
      display: 'none'
    },
    "& .MuiTabScrollButton-root":{
      background: theme.palette.primary.main,
      borderRadius: '50px',
      width: '40px',
      height: '40px',
      alignItems: 'center',
      color: 'white',
      whiteSpace: 'normal',
      "& .MuiSvgIcon-fontSizeSmall":{
        fontSize: '25px'
      }
    },
  },
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: 5
  }, 
  driverSelected: {
    transform: 'translateY(-10px)',
    transition: 'transform 300ms',
  },
  fontPlateColor:{
    color: '#dd9835',
    fontWeight: 'bold'
  },

  nameInfo:{
    fontWeight: 'bolder',
    textAlign: 'center',
  },
  ubicationInfo:{
    textAlign: 'center',
    color: '#AFAFAF',
  },
  dataTitle:{
    color: '#AFAFAF',
    fontSize: '12px'
  },
  dataInfo:{
    fontWeight: 'bolder',
    fontSize: '15px',
    color: '#767676',
  },
  // Test
  tabsContainer2: {
    "& .MuiTabs-indicator": {
      display: 'none'
    },
  },
  listContainer: {
    display: 'flex',
    margin: 0,
    flexDirection: 'column',
    color: theme.palette.primary.main,
    '&.column': {
      flexDirection: 'column',
    },
    '&.row': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  itemChild: {
    fontWeight: 'bolder',
    paddingLeft: 12,
  },
  carouselContainer:{
    "& .rec":{
      height: "190px"
    }
  },
}))
export { useStyles as default }