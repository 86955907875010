import React, { useState, useEffect, ComponentProps } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { RESTART_CONTROLS } from "core/redux/types/userTypes";

import { AuthBannerImage } from "components/AuthBannerImage";
import constants from "config/constants";

import { Auth as Routes } from "routes";

import { Footer, useStyles } from "./styles";

const AuthLayout = (props: ComponentProps<any>) => {
  const history = useHistory();
  const { selectedUserType, isLogged } = useSelector(
    (state: any) => state.user
  );
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch({
      type: RESTART_CONTROLS,
    });
    if (isLogged && selectedUserType.id) history.push("/app/dashboard");
  }, []);

  if (isLogged && selectedUserType.id) return null;

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={1} lg={3} className={classes.containerBanner}>
          <AuthBannerImage />
        </Grid>
        <Grid item xs={12} lg={9}>
          <Routes />
        </Grid>
      </Grid>
      <Footer>{constants.FOOTER}</Footer>
    </div>
  );
};

export default AuthLayout;
