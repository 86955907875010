import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  Tab,
  Tabs,
  Typography,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';

import { CHANGE_TAB_ROUTE } from 'core/redux/types/uiTypes';
import { useHistory } from 'react-router-dom';

import GobackLink from 'components/GobackLink';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import useStyles from './styles';

const SharedReportsLayout = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const valueTab = useSelector(
    (state: any) => state.ui.sharedReports.valueRouteTab
  );
  const dispatch = useDispatch();
  const history = useHistory();

  function setCurrentTab(currentTab: string) {
    dispatch({
      type: CHANGE_TAB_ROUTE,
      payload: currentTab,
    });
  }

  useEffect(() => {
    const separated = pathname.split('/');
    if (separated[4]) {
      if (separated[4] === '') {
        setCurrentTab('clients');
        return;
      }
      setCurrentTab(separated[4]);
    } else {
      setCurrentTab('clients');
    }
  }, [pathname]);

  const handleTabChange = (_: any, value: string) => {
    setCurrentTab(value);
    history.push(
      `/app/network/shared-reports/${value === 'clients' ? '' : value}`
    );
  };
  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/network/shared-reports', title: 'Gestión de Reportes' },
  ];

  return (
    <>
      <div className={classes.breadContainer}>
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard className={classes.cardContainer}>
        <div className={classes.cardHeader}>
          <Typography variant="h4" className={classes.title}>
            Gestión de Reportes
          </Typography>
        </div>
        <div className={classes.containerTab}>
          <Tabs
            value={valueTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label="Clientes"
              id="clients"
              value="clients"
              className={classes.tabSmall}
            />
            <Tab
              label="Reportes"
              id="reports"
              value="reports"
              className={classes.tabSmall}
            />
            <Tab
              label="Frecuentes"
              id="frequent"
              value="frequent"
              className={classes.tabSmall}
            />
          </Tabs>
        </div>
        {children}
      </ContentCard>
    </>
  );
};

export default SharedReportsLayout;
