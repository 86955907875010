import React from 'react';
import { Button, Fab } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import iconExcel from '../../static/img/icons/excel.svg';

import useStyles from './styles';

interface ExportToExcelProps {
  data: any;
  headers: any;
  fileName: string;
  onlyIcon?: boolean;
}

const ExportToExcel = ({
  data,
  headers,
  fileName,
  onlyIcon,
}: ExportToExcelProps) => {
  const classes = useStyles();
  return (
    <CSVLink filename={`${fileName}.csv`} data={data} headers={headers}>
      {onlyIcon ? (
        <Fab size="small" className={classes.exportButton}>
          <img src={iconExcel} width={20} />
        </Fab>
      ) : (
        <Button
          variant="contained"
          disableElevation
          size="small"
          className={classes.exportButton}
        >
          <img src={iconExcel} width={20} />
          Exportar a Excel
        </Button>
      )}
    </CSVLink>
  );
};

export default ExportToExcel;
