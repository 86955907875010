import { NestedRoute } from 'lib/interfaces/routes'

import Main from './Main'
import Search from './Search'
import ForwardingInvitations from "./ForwardingInvitations/index"

const routes: NestedRoute[] = [
  {
    route: '/forward-invitations',
    Node: ForwardingInvitations,
    exact: true
  }
]

export {
  Main,
  Search,
  routes
}