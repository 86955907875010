import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    textAlign: 'center',
    padding: 30,
    fontSize: 8,
    fontFamily: 'Roboto',
    fontWeight: 300
  },
  textBold: {
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
  row: {
    width: '10px',
    flex: 2,
    flexDirection: 'row',
    flexGrow: 2,
    fontSize: 10,
    position: 'relative',
    top: 50
  },
  sectionweitgh: {
    justifyContent: 'space-around',
    flexDirection: 'row',
    marginTop: 10,
    fontSize: 10,
  },
  sectionresume: {
    flexDirection: 'row',
    fontSize: 10,
    marginTop: 10,
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  sectionnumbers: {
    marginLeft: 5,
    flexDirection: 'row'
  },
  widthsectionnumbers: {
    width: 47,
    fontSize: 8,
    justifyContent: 'center'
  },
  sectionnumberstext: {
    width: '100%',
    textAlign: 'left',
    fontSize: 6.6,
    marginLeft: 5,
    fontFamily: 'Roboto'
  },
  weigthanimals: {
    width: 90,
    fontSize: 7,
    fontFamily: 'Roboto',
    marginTop: 4
  },
  column: {
    marginLeft: 250
  },
  table: {
    display: 'table',
    width: '100%'
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableCol: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 2
  },
  tableHead: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
  },
  tableCell: {
    fontSize: 7,
    textAlign: 'left',
    textAlign: 'center',
    width: '100%'
  },
  detailsplant: {height: 160},
  background: {
    backgroundColor: '#ededed'
  },
  backgroundresumetitle: {
    backgroundColor: '#ededed',
    height: 17,
    fontSize: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10
  },
  backgroundweigth: {
    backgroundColor: '#ededed',
    width: 70,
    height: 15,
    display: 'flex',
    justifyContent: 'center',
    color: 'white'
  },
  backgroundorigin: {
    width: '100%',
    marginRight: 20
  },
  backgroundplant: {
    backgroundColor: '#ededed',
    width: '100%',
    fontSize: 10,
    height: 17,
    justifyContent: 'center'
  },
  resumedetail: {
    backgroundColor: '#b0413e',
    color: '#ffffff',
    borderRadius: 50,
    width: 35,
    height: 35,
    justifyContent: 'center',
    padding: 5
  },
  resumedetailsm: {
    color: '#ffffff',
    borderRadius: 50,
    width: 25,
    height: 25,
    justifyContent: 'center'
  },
  imageplant: {
    backgroundColor: '#DD9835',
    borderRadius: 50,
    width: 25,
    height: 25,
    display: 'flex',
    marginLeft: 2,
    justifyContent: 'center',
    position: 'absolute',
    padding: 3.5
  },
  backgroundguide: {
    backgroundColor: '#ededed',
    height: 30,
    width: 230,
    margin: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
    justifyContent: 'center'
  },
  gsmi: {top: 8, left: 10, width: 30, height: 20},
  textgsmi: {
    left: 10,
    width: 185,
    top: 8,
    height: 20
  },
  textconventions: {flex: 2, flexDirection: 'row', flexGrow: 2, marginTop: 3},
  backgroundimage: {
    backgroundColor: '#ededed',
    height: 70,
    width: 70,
    marginRight: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableplant: {
    display: 'table',
    marginTop: 10
  },
  tableRowplant: {
    flexDirection: 'row'
  },
  tableColplant: {
    width: 108
  },
  tableColplantRigth: {
    textAlign: 'right'
  },
  tableCellplant: {
    marginTop: 3,
    fontSize: 10,
    textAlign: 'left',
    fontSize: 8
  },
  tableCellplantRigth: {
    marginTop: 3,
    fontSize: 8
  },
  image: {
    height: '45px',
    width: '45px'
  },
  tablecontainer: {
    marginTop: 5,
    width: '30%',
    marginRight: 15
  },
  conventions: {
    marginTop: 10
  },
  border: {
    border: '1 solid black',
    width: 70,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    backgroundColor: '#ffffff',
    top: -45
  },
  copyContainer: {
    padding: 15,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  copy: {position: 'absolute', bottom: 25, textAlign: 'center', fontSize: 6, marginLeft: 43, marginTop: 100},
  datereport: {
    left: 3,
    textAlign: 'left',
    justifyContent: 'space-between'
  },
  datemargin: {
    marginBottom: 5,
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {fontSize: 12},
  phone: {marginTop: 7, marginBottom: 7},
  titleFlex: {
    backgroundColor: '#EDEDED',
    padding: 7,
    fontSize: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  origincontainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 20
  },
  originitem: {
    width: 173,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 7,
    borderTopRightRadius: 7,
    border: 1,
    borderColor: '#DDD',
    marginBottom: 7,
    padding: 3,
    flexDirection: 'row',
    alignItems: 'center'
  },
  originpercent: {
    borderRadius: 50,
    color: '#FFF',
    width: 25,
    height: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5
  }
})
