import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { Logo } from 'static/img/logos';

import useStyles from './styles';

interface AuthFormContainerProps {
  children: React.ReactNode;
  onSubmit: (values: any, methods?: any) => void;
  title: string;
  subtitle?: string;
}

const AuthFormContainer = ({
  children,
  onSubmit,
  title,
  subtitle,
}: AuthFormContainerProps) => {
  const classes = useStyles();

  return (
    <form noValidate onSubmit={onSubmit} className={classes.root}>
      <img src={Logo} alt="logo" className={classes.logo} />
      <Typography
        variant="h4"
        className={clsx({
          [classes.header]: true,
          [classes.header_margin]: !subtitle,
        })}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography color="textSecondary" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
      {children}
    </form>
  );
};

export default AuthFormContainer;
