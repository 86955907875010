import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  cardItem: {
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#FFF4E5',
    },
  },
  cardSelected: {
    backgroundColor: '#FFF4E5',
  },
  cardActionArea: {
    height: '100%',
  },
  nameInfo:{
    fontWeight: 'bolder',
    textAlign: 'center',
  },
  ubicationInfo:{
    textAlign: 'center',
    color: '#AFAFAF',
    fontSize: '12px',
  },
  placaSelected: {
    transform: 'translateY(-15px)',
    transition: 'transform 500ms',
  },
  dataTitle:{
    color: '#AFAFAF',
    fontSize: '12px'
  },
  dataInfo:{
    fontWeight: 'bolder'
  },
}));

export { useStyles as default }