import { gql } from "apollo-boost";

export const GET_CONTACTS = gql`
  query getContacts {
    getContacts {
      data {
        idUsuarioContacto
        usuario
        idTipoUsuario
        nombre
        idTipoDocumento
        numeroDocumento
        ubicacion
        totalGranjas
        totalDestinos
        idContacto
        estadoContacto
      }
    }
  }
`;

export const GET_DETAIL_CONTACT = gql`
  query getDetailContact($idContacto: Int!) {
    getDetailContact(input: { idContacto: $idContacto }) {
      data {
        idUsuarioContacto
        usuario
        idTipoUsuario
        descripcionTipoUsuario
        nombre
        idTipoDocumento
        numeroDocumento
        indicativoTelefono
        telefono
        indicativoCelular
        celular
        email
        direccion
        ubicacion
        totalGranjas
        totalDestinos
        fechaInvitacionAceptada
        terminosYCondiciones
        politicasPrivacidad
        habeasData
        idContacto
        estadoContacto
      }
    }
  }
`;

export const GET_FARMS_BY_USER = gql`
  query getFarmsXContact($idUsuarioContacto: Int) {
    getFarmsXContact(input: { idUsuarioContacto: $idUsuarioContacto }) {
      data {
        idGranja
        codigoGranja
        nombreGranja
        numeroCelular
        estado
        direccion
        email
        ubicacion
        numeroWhatsapp
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;

export const GET_DESTINATIONS_BY_USER = gql`
  query getDestinationsXContact($idUsuarioContacto: Int) {
    getDestinationsXContact(input: { idUsuarioContacto: $idUsuarioContacto }) {
      data {
        idDestino
        codigoDestino
        nombreDestino
        indicativoTelefono
        numeroTelefono
        extensionTelefono
        telefono
        indicativoWhatsapp
        numeroWhatsapp
        whatsapp
        email
        direccion
        ubicacion
        estado
        tipoDestino
      }
    }
  }
`;

export const PUT_STATUS_CONTACT = gql`
  mutation putStatusContact($idContacto: Int, $estado: Boolean) {
    putStatusContact(input: { idContacto: $idContacto, estado: $estado }) {
      data {
        success
        message
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;
