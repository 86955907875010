import { GenerateRoutes as Routes } from 'routes';

import PagesContainer from 'components/PagesContainer';

import { Main, routes } from './views';
import useStyles from './styles';

const SharedReports = () => {
  const classes = useStyles();

  return (
    <PagesContainer>
      <Routes MainPage={Main} nested={routes} />
    </PagesContainer>
  );
};

export default SharedReports;
