import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  backgroundweigth: {
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    fontSize: 15,
    borderRadius: 50,
  },
  weigthanimals: {
    width: 40,
    fontSize: 7,
    fontFamily: 'Roboto',
    marginTop: 4
  },
})