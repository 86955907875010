import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: '8px 0',
  }, 
  cardHeader:{
    display:'flex', 
    justifyContent:'space-between'
  }, 
  title:{
    fontWeight:'bold'
  }, 
  search:{
    width:'30%'
  },
  containerTab:{
    padding: 12
  },
  tabSmall:{
    fontWeight:900,
    minWidth: 50,
  },
})); 
export default useStyles;
