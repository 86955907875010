import React, { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Tabs,
  Tab,
  TextField,
  Typography,
  Tooltip,
  Fab,
} from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useQuery } from "@apollo/react-hooks";

import avatar from "lib/helpers/dynamic-images/avatars";
import removeAccents from "lib/helpers/removeAccents";
import { GET_CONTACTS } from "core/graphql/queries/contacts";

import Breadcrumb, { BreadcrumbProps } from "components/BreadcrumbBuilder";
import ContentCard from "components/ContentCard";
import PaginationGrid from "components/datagrid-custom-components/TableFooter/index";
import MainTitle from "components/MainTitle";
import Results from "components/Results";
import TableOverlay from "components/datagrid-custom-components/TableOverlay";
import InformativeScreen from "components/InformativeScreens";

import DialogDetailFD from "../Details/DialogDetailFD";
import DialogStatusContact from "../Details/DialogStatusContact";

import useStyles from "./styles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const Main = () => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const [selectedValue, setSelectedValue] = useState("active");
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [openConfirmDelete, setOpenConfirmDelete] = useState({
    open: false,
    user: "",
    idUser: "",
    action: false,
  });
  const [openFD, setOpenDialogFD] = useState({
    open: false,
    type: "",
    idUser: "",
    contactName: "",
  });
  const pageBreadcrums: BreadcrumbProps[] = [
    {
      link: "/app/network/contacts/",
      title: "Mis Contactos",
    },
  ];

  const {
    data: dataContacts,
    loading: loadingContacts,
    error: errorContacts,
    refetch: refetchContacts,
  } = useQuery(GET_CONTACTS, {
    context: { headers: { "x-auth-jwt": token } },
  });

  useEffect(() => {
    if (!openConfirmDelete.open) {
      refetchContacts({
        context: { headers: { "x-auth-jwt": token } },
      });
    }
  }, [openConfirmDelete.open]);

  const contacts: any[] = [];
  dataContacts?.getContacts?.data.map((item: any) => {
    if (value === 0 && selectedValue === "active") {
      item?.idTipoUsuario === 1 &&
        item?.estadoContacto &&
        contacts.push({
          id: item?.idContacto,
          usuario: item?.usuario,
          idUsuarioContacto: item?.idUsuarioContacto,
          nombre: item?.nombre,
          idTipoDocumento: item?.idTipoDocumento,
          numeroDocumento: item?.numeroDocumento,
          totalGranjas: item?.totalGranjas,
          ubicacion: item?.ubicacion,
          estadoContacto: item?.estadoContacto,
          ver: "ver",
        });
    } else if (value === 0 && selectedValue === "inactive") {
      item?.idTipoUsuario === 1 &&
        !item?.estadoContacto &&
        contacts.push({
          id: item?.idContacto,
          usuario: item?.usuario,
          idUsuarioContacto: item?.idUsuarioContacto,
          nombre: item?.nombre,
          idTipoDocumento: item?.idTipoDocumento,
          numeroDocumento: item?.numeroDocumento,
          totalGranjas: item?.totalGranjas,
          ubicacion: item?.ubicacion,
          estadoContacto: item?.estadoContacto,
          ver: "ver",
        });
    } else if (value === 1 && selectedValue === "active") {
      item?.idTipoUsuario === 2 &&
        item?.estadoContacto &&
        contacts.push({
          id: item?.idContacto,
          usuario: item?.usuario,
          idUsuarioContacto: item?.idUsuarioContacto,
          nombre: item?.nombre,
          idTipoDocumento: item?.idTipoDocumento,
          numeroDocumento: item?.numeroDocumento,
          totalDestinos: item?.totalDestinos,
          ubicacion: item?.ubicacion,
          estadoContacto: item?.estadoContacto,
          ver: "ver",
        });
    } else if (value === 1 && selectedValue === "inactive") {
      item?.idTipoUsuario === 2 &&
        !item?.estadoContacto &&
        contacts.push({
          id: item?.idContacto,
          usuario: item?.usuario,
          idUsuarioContacto: item?.idUsuarioContacto,
          nombre: item?.nombre,
          idTipoDocumento: item?.idTipoDocumento,
          numeroDocumento: item?.numeroDocumento,
          totalDestinos: item?.totalDestinos,
          ubicacion: item?.ubicacion,
          estadoContacto: item?.estadoContacto,
          ver: "ver",
        });
    }
  });

  const [searchFilter, setSearchFilter] = useState({
    search: "",
  });

  const filterContacts = contacts.filter((item) => {
    return (
      item?.nombre?.includes(searchFilter.search.toUpperCase()) ||
      item?.usuario?.includes(searchFilter.search) ||
      item?.numeroDocumento?.includes(searchFilter.search)
    );
  });

  const handleInputSearch = (event: any) => {
    setSearchFilter({
      search: event.target.value,
    });
  };

  useEffect(() => {
    setSearchFilter({
      search: "",
    });
  }, [value]);

  const handleOpenConfirm = (user: any, idUser: string, action: boolean) => {
    setOpenConfirmDelete({
      open: true,
      user,
      idUser,
      action,
    });
  };

  const handleCloseConfirm = () => {
    setOpenConfirmDelete({
      open: false,
      user: "",
      idUser: "",
      action: false,
    });
  };

  const handleOpenFD = (idUser: any, type: string, contactName: string) => {
    setOpenDialogFD({
      open: true,
      type,
      idUser,
      contactName,
    });
  };

  const handleClosenFD = () => {
    setOpenDialogFD({
      open: false,
      type: "",
      idUser: "",
      contactName: "",
    });
  };

  const handleChangeRadio = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const columnsProductor: GridColDef[] = [
    {
      field: "image",
      headerName: " ",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Avatar
            src={avatar(
              removeAccents(params.getValue(params.id, "nombre") as string)
            )}
          />
        </>
      ),
    },
    {
      field: "usuario",
      headerName: "Usuario",
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nombre",
      headerName: "Razón Social / Nombres",
      flex: 1.3,
      disableColumnMenu: true,
      headerAlign: "center",
    },
    {
      field: "numeroDocumento",
      headerName: "Documento",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <>
          {params.getValue(params.id, "idTipoDocumento") === 1 && (
            <>
              <span className={classes.fontDocument}>CC: </span>
              {params.getValue(params.id, "numeroDocumento")}
            </>
          )}
          {params.getValue(params.id, "idTipoDocumento") === 2 && (
            <>
              <span className={classes.fontDocument}>CE: </span>
              {params.getValue(params.id, "numeroDocumento")}
            </>
          )}
          {params.getValue(params.id, "idTipoDocumento") === 3 && (
            <>
              <span className={classes.fontDocument}>NIT: </span>
              {params.getValue(params.id, "numeroDocumento")}
            </>
          )}
          {params.getValue(params.id, "idTipoDocumento") === 4 && (
            <>
              <span className={classes.fontDocument}>PEP: </span>
              {params.getValue(params.id, "numeroDocumento")}
            </>
          )}
        </>
      ),
    },
    {
      field: "ubicacion",
      headerName: "Ubicación",
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalGranjas",
      headerName: "Nro. Granjas",
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="Granjas Registradas" arrow>
            <IconButton
              className={classes.buttonContainer}
              onClick={() => {
                handleOpenFD(
                  params.getValue(params.id, "idUsuarioContacto"),
                  "granjas",
                  params.getValue(params.id, "nombre") as string
                );
              }}
            >
              <Box mt={1}>
                <Badge
                  badgeContent={params.getValue(params.id, "totalGranjas")}
                  color="primary"
                >
                  <Box component="i" className="icon-granja" fontSize="28px" />
                </Badge>
              </Box>
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: "ver",
      headerName: " ",
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="Ver detalles" arrow>
            <Box mr={1}>
              <Fab
                color="primary"
                className={classes.btnDetails}
                component={Link}
                to={`/app/network/contacts/details/${params.getValue(
                  params.id,
                  "id"
                )}`}
              >
                <Box component="i" fontSize="20px" className="icon-eye" />
              </Fab>
            </Box>
          </Tooltip>

          {params.getValue(params.id, "estadoContacto") ? (
            <Tooltip title="Inactivar Contacto" arrow>
              <Fab
                size="small"
                aria-label="delete"
                color="secondary"
                className={classes.fabIconDelete}
                onClick={() =>
                  handleOpenConfirm(
                    params.getValue(params.id, "usuario"),
                    params.getValue(params.id, "id") as string,
                    false
                  )
                }
              >
                <Box component="i" fontSize="20px" className="icon-trash" />
              </Fab>
            </Tooltip>
          ) : (
            <Tooltip title="Activar contacto" arrow>
              <Fab
                size="small"
                aria-label="add"
                color="primary"
                className={classes.fabIconAdd}
                onClick={() =>
                  handleOpenConfirm(
                    params.getValue(params.id, "usuario"),
                    params.getValue(params.id, "id") as string,
                    true
                  )
                }
              >
                <Box component="i" fontSize="20px" className="icon-plus" />
              </Fab>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const columnsComercializador: GridColDef[] = [
    {
      field: "image",
      headerName: " ",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <div>
          <Avatar
            src={avatar(
              removeAccents(params.getValue(params.id, "nombre") as string)
            )}
          />
        </div>
      ),
    },
    {
      field: "usuario",
      headerName: "Usuario",
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nombre",
      headerName: "Razón Social / Nombres",
      flex: 1.3,
      disableColumnMenu: true,
      headerAlign: "center",
    },
    {
      field: "numeroDocumento",
      headerName: "Documento",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <>
          {params.getValue(params.id, "idTipoDocumento") === 1 && (
            <>
              <span className={classes.fontDocument}>CC: </span>
              {params.getValue(params.id, "numeroDocumento")}
            </>
          )}
          {params.getValue(params.id, "idTipoDocumento") === 2 && (
            <>
              <span className={classes.fontDocument}>CE: </span>
              {params.getValue(params.id, "numeroDocumento")}
            </>
          )}
          {params.getValue(params.id, "idTipoDocumento") === 3 && (
            <>
              <span className={classes.fontDocument}>NIT: </span>
              {params.getValue(params.id, "numeroDocumento")}
            </>
          )}
          {params.getValue(params.id, "idTipoDocumento") === 4 && (
            <>
              <span className={classes.fontDocument}>PEP: </span>
              {params.getValue(params.id, "numeroDocumento")}
            </>
          )}
        </>
      ),
    },
    {
      field: "ubicacion",
      headerName: "Ubicación",
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalDestinos",
      headerName: "Nro. Puntos de Venta",
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="Puntos de Venta Registrados" arrow>
            <IconButton
              className={classes.buttonContainer}
              onClick={() => {
                handleOpenFD(
                  params.getValue(params.id, "idUsuarioContacto"),
                  "destinos",
                  params.getValue(params.id, "nombre") as string
                );
              }}
            >
              <Box mt={1}>
                <Badge
                  badgeContent={params.getValue(params.id, "totalDestinos")}
                  color="primary"
                >
                  <Box component="i" className="icon-home" fontSize="28px" />
                </Badge>
              </Box>
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: "ver",
      headerName: " ",
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridValueGetterParams) => (
        <>
          <Tooltip title="Ver deatlles" arrow>
            <Box mr={1}>
              <Fab
                component={Link}
                to={`/app/network/contacts/details/${params.getValue(
                  params.id,
                  "id"
                )}`}
                color="primary"
                className={classes.btnDetails}
              >
                <Box component="i" fontSize="20px" className="icon-eye" />
              </Fab>
            </Box>
          </Tooltip>

          {params.getValue(params.id, "estadoContacto") ? (
            <Tooltip title="Inactivar Contacto" arrow>
              <Fab
                size="small"
                aria-label="delete"
                color="secondary"
                className={classes.fabIconDelete}
                onClick={() =>
                  handleOpenConfirm(
                    params.getValue(params.id, "usuario"),
                    params.getValue(params.id, "id") as string,
                    false
                  )
                }
              >
                <Box component="i" fontSize="20px" className="icon-trash" />
              </Fab>
            </Tooltip>
          ) : (
            <Tooltip title="Activar contacto" arrow>
              <Fab
                size="small"
                aria-label="delete"
                color="primary"
                className={classes.fabIconAdd}
                onClick={() =>
                  handleOpenConfirm(
                    params.getValue(params.id, "usuario"),
                    params.getValue(params.id, "id") as string,
                    true
                  )
                }
              >
                <Box component="i" fontSize="20px" className="icon-plus" />
              </Fab>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const customPagination = () => {
    return (
      <Box mt={2}>
        <PaginationGrid />
      </Box>
    );
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Breadcrumb breadcrumbs={pageBreadcrums} />
        </Grid>
      </Grid>

      <ContentCard>
        <ContentCard.Content>
          <Grid container>
            <Grid item xs={6} container direction="row" alignItems="center">
              <MainTitle>Mis Contactos en la Red de Negocios</MainTitle>
              <Box ml={5}>
                <Results number={contacts.length} />
              </Box>
            </Grid>

            <Grid container item xs={6} alignItems="center">
              <Grid container item xs={5} justify="center">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                >
                  <FormControlLabel
                    checked={selectedValue === "active"}
                    onChange={handleChangeRadio}
                    color="secundary"
                    value="active"
                    control={<Radio color="primary" />}
                    label="Activos"
                  />
                  <FormControlLabel
                    checked={selectedValue === "inactive"}
                    onChange={handleChangeRadio}
                    color="secundary"
                    value="inactive"
                    control={<Radio color="primary" />}
                    label="Inactivos"
                  />
                </RadioGroup>
              </Grid>

              <Grid container item xs={7}>
                <TextField
                  label="Búsqueda"
                  variant="outlined"
                  fullWidth
                  onChange={handleInputSearch}
                  value={searchFilter.search}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="i"
                          fontSize="20px"
                          className="icon-search"
                        />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>

          <Box borderBottom={1} marginTop={1} className={classes.colorHr}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label="Productores"
                {...a11yProps(0)}
                className={classes.tabSmall}
              />
              <Tab
                label="Comercializadores"
                {...a11yProps(1)}
                className={classes.tabSmall}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Box
              height="auto"
              width="100%"
              className={
                searchFilter.search != "" && filterContacts.length == 0
                  ? classes.heightContainer
                  : classes.colorNoRow
              }
            >
              <div style={{ height: "auto", width: "100%" }}>
                {!loadingContacts && contacts.length !== 0 && (
                  <DataGrid
                    rows={searchFilter.search != "" ? filterContacts : contacts}
                    columns={columnsProductor}
                    className={
                      searchFilter.search != "" && filterContacts.length == 0
                        ? clsx(classes.dataGrid, classes.notDataContainer)
                        : classes.dataGrid
                    }
                    components={{
                      Footer: customPagination,
                      NoRowsOverlay: TableOverlay,
                    }}
                    hideFooter={
                      searchFilter.search != "" && filterContacts.length == 0
                    }
                    autoHeight
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    page={page}
                    onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                    onPageChange={(e: any) => setPage(e.page)}
                    loading={loadingContacts}
                  />
                )}
                {!loadingContacts && !errorContacts && contacts.length === 0 && (
                  <Box mt={2}>
                    <InformativeScreen
                      type="notFound"
                      subtitle="¡No hay nada por aquí!"
                      info={
                        selectedValue === "active"
                          ? "No posee ninguna invitacion o todas se encuentran inactivas."
                          : "No posee ninguna invitacion o todas se encuentran activas."
                      }
                      onlyError
                    />
                  </Box>
                )}
                {errorContacts && contacts.length === 0 && (
                  <Box mt={2}>
                    <InformativeScreen
                      type="technicalError"
                      subtitle="¡Ha ocurrido un error inesperado!"
                      info="Estamos trabajando para resolverlo."
                      onlyError
                    />
                  </Box>
                )}
                {loadingContacts && (
                  <Box textAlign="center" marginTop={5}>
                    <CircularProgress color="primary" size={60} />
                  </Box>
                )}
              </div>
            </Box>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Box
              height="auto"
              width="100%"
              className={
                searchFilter.search != "" && filterContacts.length == 0
                  ? classes.heightContainer
                  : classes.colorNoRow
              }
            >
              <div style={{ height: "auto", width: "100%" }}>
                {!loadingContacts && contacts.length !== 0 && (
                  <DataGrid
                    rows={searchFilter.search != "" ? filterContacts : contacts}
                    columns={columnsComercializador}
                    className={
                      searchFilter.search != "" && filterContacts.length == 0
                        ? clsx(classes.dataGrid, classes.notDataContainer)
                        : classes.dataGrid
                    }
                    components={{
                      Footer: customPagination,
                      NoRowsOverlay: TableOverlay,
                    }}
                    hideFooter={
                      searchFilter.search != "" && filterContacts.length == 0
                    }
                    autoHeight
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    page={page}
                    onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
                    onPageChange={(e: any) => setPage(e.page)}
                    loading={loadingContacts}
                  />
                )}
                {!loadingContacts && !errorContacts && contacts.length === 0 && (
                  <Box mt={2}>
                    <InformativeScreen
                      type="notFound"
                      subtitle="¡No hay nada por aquí!"
                      info={
                        selectedValue === "active"
                          ? "No posee ninguna invitacion o todas se encuentran inactivas."
                          : "No posee ninguna invitacion o todas se encuentran activas."
                      }
                      onlyError
                    />
                  </Box>
                )}
                {errorContacts && contacts.length === 0 && (
                  <Box mt={2}>
                    <InformativeScreen
                      type="technicalError"
                      subtitle="¡Ha ocurrido un error inesperado!"
                      info="Estamos trabajando para resolverlo."
                      onlyError
                    />
                  </Box>
                )}
                {loadingContacts && (
                  <Box textAlign="center" marginTop={5}>
                    <CircularProgress color="primary" size={60} />
                  </Box>
                )}
              </div>
            </Box>
          </TabPanel>
          <DialogStatusContact
            open={openConfirmDelete.open}
            user={openConfirmDelete.user}
            idUser={openConfirmDelete.idUser}
            action={openConfirmDelete.action}
            handleClose={handleCloseConfirm}
          />
          <DialogDetailFD
            open={openFD.open}
            idUser={Number.parseInt(openFD.idUser)}
            type={openFD.type}
            contactName={openFD.contactName}
            handleClose={handleClosenFD}
          />
        </ContentCard.Content>
      </ContentCard>
    </>
  );
};

export default Main;
