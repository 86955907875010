import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  TextField,
  TextFieldProps,
  StepIconProps,
  Grow,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Fab,
  Link,
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ES from 'date-fns/locale/es';
import dayjs from 'dayjs';

import unmaskPhone from 'lib/helpers/unmaskPhone';
import {
  CompanyValidationStepOne,
  CompanyValidationStepThree,
  CompanyValidationStepTwo,
  DriverValidationStepOne,
  DriverValidationStepThree,
  DriverValidationStepTwo,
  VehicleValidationStepOne,
  VehicleValidationStepTwo,
} from 'lib/validations/TransportValidation';
import constants from 'config/constants';

import PhoneNumber from 'components/PhoneNumber';
import SelectCountry from 'components/SelectCountry/index';
import DocumentTypes from 'components/DocumentTypes';
import InputPlate from 'components/InputPlate/index';
import Direction from 'components/Direction/index';
import GobackLink from 'components/GobackLink';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import calculateCodVerification from 'lib/helpers/calculateCodVerification';
import MainTitle from 'components/MainTitle';
import CardSelectVehicle from 'components/TransportComponents/CardSelectVehicle/index';
import CardSelectDriver from 'components/TransportComponents/CardSelectDriver';
import Slider from 'components/Slider/index';

import DialogConfirmData from '../../Details/DialogConfirmData/index';
import {
  CREATE_COMPANY,
  CREATE_DRIVER,
  CREATE_VEHICLE,
  POST_COMPANY_VEHICLE,
  UPDATE_COMPANY,
  UPDATE_DRIVER,
} from 'core/graphql/mutations/transport';
import {
  GET_COMPANY,
  GET_COMPANY_EXISTS,
  GET_DRIVERS,
  GET_DRIVERS_EXISTS,
  GET_TYPE_LICENSE_CATEGORY,
  GET_TYPE_LOAD,
  GET_VEHICLES,
  GET_VEHICLES_EXISTS,
  TYPE_SERVICE,
  TYPE_VEHICLE,
} from 'core/graphql/queries/transport';

import useStyles from './styles';

type CompononentProps = {
  id: number;
};

const CreateCompany = ({ id }: CompononentProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const userType = useSelector(
    (state: any) => state.user.selectedUserType.idUserType
  );
  const [openNotificationConfirm, setOpenNotificationConfirm] = useState({
    open: false,
    icon: '',
    title: '',
    message: '',
  });
  const [companyUpdate, setCompanyUpdate] = useState(false);
  const [driverUpdate, setDriverUpdate] = useState(false);
  const [idCompany, setIdCompany] = useState();
  const [idVehicle, setIdVehicle] = useState();
  const [conf, setConf] = useState(false);
  const [typeLoad, setTypeLoad] = useState('');
  const [typeVehicle, setTypeVehicle] = useState('');
  const [serviceVehicle, setServiceVehichle] = useState('');
  const [stepFormCompany, setStepFormCompany] = useState(0);
  const [stepFormVehicle, setStepFormVehicle] = useState(0);
  const [stepFormDriver, setStepFormDriver] = useState(0);
  const [selectVehicle, setSelectVehicle] = useState<number>();
  const [selectDriver, setSelectDriver] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<any>(dayjs());
  const [licenseCategory, setLicenseCategory] = useState([
    { idCategoriaLicencia: '', fechaVigencia: dayjs().format('YYYY/MM/DD') },
  ]);
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '#',
      title: 'Registrar Empresa Transportadora',
    },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];

  function getSteps() {
    return [
      'Registrar Empresa Transportadora',
      'Asignar o Registrar Vehículo',
      'Asignar o Registrar Conductor',
    ];
  }

  const [activeStep, setActiveStep] = useState(2);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function ColorlibStepIcon(props: StepIconProps) {
    const classes = useStyles();
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <Box component="i" className="icon-planta" fontSize="22px" />,
      2: <Box component="i" className="icon-truck" fontSize="22px" />,
      3: <Box component="i" className="icon-user" fontSize="22px" />,
    };

    return (
      <div
        className={clsx(classes.rootStepper, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  const {
    data: dataTypeVehicle,
    loading: loadingTypeVehicle,
    error: errorTypeVehicle,
  } = useQuery(TYPE_VEHICLE, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const {
    data: dataTypeLoad,
    loading: loadingTypeLoad,
    error: errorTypeLoad,
  } = useQuery(GET_TYPE_LOAD, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const {
    data: dataTypeLicenseCategory,
    loading: loadingTypeLicenseCategory,
    error: errorTypeLicenseCategory,
  } = useQuery(GET_TYPE_LICENSE_CATEGORY, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const service = [
    {
      id: 1,
      description: 'PUBLICO',
    },
    {
      id: 2,
      description: 'PRIVADO',
    },
  ];

  const [
    getVehicles,
    { data: dataVhicles, error: errorVehicle, loading: loadingVehicles },
  ] = useLazyQuery(GET_VEHICLES);

  const vehicles = dataVhicles?.getVehicles.data;

  const [
    getDrivers,
    { data: dataDrivers, error: errorDrivers, loading: loadingDrivers },
  ] = useLazyQuery(GET_DRIVERS);

  const drivers = dataDrivers?.getDrivers.data;

  useEffect(() => {
    switch (activeStep) {
      case 1:
        getVehicles({
          context: { headers: { 'x-auth-jwt': token } },
        });
        break;
      case 2:
        getDrivers({
          context: { headers: { 'x-auth-jwt': token } },
        });
        break;
      default:
        break;
    }
  }, [activeStep]);

  const handleChangeTypeLoad = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeLoad(event.target.value);
  };

  const handleChangeTypeVehichle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTypeVehicle(event.target.value);
  };

  const handleChangeServiceVehicle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setServiceVehichle(event.target.value);
  };

  const [createCompany, { loading: loadingCompany }] = useMutation(
    CREATE_COMPANY,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const [createVehicle, { loading: loadingVehicle }] = useMutation(
    CREATE_VEHICLE,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const [createDriver, { loading: loadingDriver }] = useMutation(
    CREATE_DRIVER,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const [postCompanyXVehicle, { loading: loadingPostCompanyXVehicle }] =
    useMutation(POST_COMPANY_VEHICLE, {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    });

  const postCompany = (data: any) => {
    createCompany({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.postCompanyConveyor.data.success === 1) {
          enqueueSnackbar('Empresa transportadora creada exitosamente.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
          setIdCompany(mutationResult.data.postCompanyConveyor.data.id);
          handleNext();
        } else if (mutationResult.data.postCompanyConveyor.data.success === 2) {
          enqueueSnackbar(
            'La Empresa Transportadora se asigno correctamente.',
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'info',
            }
          );
          setIdCompany(mutationResult.data.postCompanyConveyor.data.id);
          handleNext();
        } else {
          enqueueSnackbar(
            mutationResult.data.postCompanyConveyor.data.message,
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }
          );
        }
      },
      variables: {
        idTipoDocumento: 3,
        numeroDocumento: String(data.nit),
        digitoVerificacion: data.digitoVerificacion,
        razonSocial: data.companyName,
        tipoDireccion: data.directionType,
        idCiudad: Number(data.city),
        idTipoVia: Number(data.viaType),
        numeroVia: Number(data.viaNumber),
        apendiceVia: data.apendNumber,
        numeroCruce: Number(data.cruceNumber),
        apendiceCruce: data.apendCruceNumber,
        metrosEsquina: Number(data.cornerMeters),
        adicional: data.aditional,
        codigoPostal: data.postalCode,
        longitud: data.latitude,
        latitud: data.longitude,
        indicativoTelefono: Number(data.indicativePhone),
        telefono: data.phone,
        extension: data.ext,
        indicativoCelular: Number(data.indicativeMobile),
        celular: data.mobile,
        email: data.email,
      },
    });
  };

  const postVehicle = (data: any) => {
    createVehicle({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.postVehicle.data.success === 1) {
          enqueueSnackbar('Vehículo creado exitosamente.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
          setIdVehicle(mutationResult.data.postVehicle.data.id);
          handleNext();
        } else if (mutationResult.data.postVehicle.data.success === 2) {
          enqueueSnackbar(
            'El vehículo ingresado ya existe, quedara asociado.',
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'info',
            }
          );
          setIdVehicle(mutationResult.data.postVehicle.data.id);
          handleNext();
        } else {
          enqueueSnackbar(mutationResult.data.postVehicle.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        numeroLicenciaTransito: Number(data.licenseNumber),
        numeroPlaca: data.lettersPlate.toUpperCase() + data.numberPlate,
        idPaisPlaca: data.country,
        idDepartamentoPlaca: data.departament,
        idCiudadPlaca: data.city,
        marca: data.vehicleBrand,
        modelo: data.vehicleModel,
        color: data.color,
        idEmpresaTransportadora: idCompany ?? 0,
        idTipoVehiculo: data.vehicleType,
        servicio: data.service,
        idTipoCarga: data.loadType,
        capacidadCarga: data.capacity,
        numeroPisos: Number(data.numberFloors),
        idTipoDocumentoPropietario: Number(data.documentType),
        numeroDocumentoPropietario: Number(data.documentOwner),
        primerNombrePropietario: data.firstNameOwner,
        segundoNombrePropietario: data.secondNameOwner,
        primerApellidoPropietario: data.surnameOwner,
        segundoApellidoPropietario: data.secondSurnameOwner,
        idIndicativoCelularPropietario: Number(data.indicativeMobile),
        celularPropietario: data.mobile,
        emailPropietario: data.email,
        idTipoServicio: id,
        idTipoUsuario: Number(userType),
        idConductor: 0,
      },
    });
  };

  const postDriver = (data: any) => {
    createDriver({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.postDriver.data.success === 1) {
          enqueueSnackbar('Conductor creado exitosamente.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
          formikCompany.resetForm();
          formikVehicle.resetForm();
          formikDriver.resetForm();
          history.push('/app/network/transports');
        } else if (mutationResult.data.postDriver.data.success === 2) {
          enqueueSnackbar(
            'El conductor ingresado ya existe, quedara asociado.',
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'info',
            }
          );
          formikCompany.resetForm();
          formikVehicle.resetForm();
          formikDriver.resetForm();
          history.push('/app/network/transports');
        } else {
          enqueueSnackbar(mutationResult.data.postDriver.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        numeroLicenciaConduccion: Number(data.licenseNumber),
        fechaExpedicionLicencia: data.expeditionDate,
        categoriasLicencia: data.licenseCategory,
        idTipoDocumento: data.documentType,
        numeroDocumento: Number(data.document),
        primerNombre: data.firstName,
        segundoNombre: data.secondName,
        primerApellido: data.surname,
        segundoApellido: data.secondSurname,
        idTipoDireccion: data.directionType,
        idPais: data.country,
        idDepartamento: data.departament,
        idCiudad: data.city,
        idTipoVia: Number(data.viaType),
        numeroVia: Number(data.viaNumber),
        apendiceVia: data.apendNumber,
        numeroCruce: Number(data.cruceNumber),
        apendiceCruce: data.apendCruceNumber,
        metrosEsquina: Number(data.cornerMeters),
        adicional: data.aditional,
        codigoPostal: data.postalCode,
        indicativoTelefono: Number(data.indicativePhone),
        telefono: data.phone,
        indicativoCelular: Number(data.indicativeMobile),
        celular: data.mobile,
        email: data.email,
        esConductorReemplazo: data.replacement,
        idVehiculo:
          selectVehicle !== 0 && selectVehicle !== undefined
            ? selectVehicle
            : idVehicle,
        idEmpresaTransportadora: idCompany ?? 0,
      },
    });
  };

  const assignCompanyXVehicle = (
    idCompany: number,
    idVehicle: number,
    idDriver: number
  ) => {
    postCompanyXVehicle({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.postCompanyXVehicle.data.success === 1) {
          enqueueSnackbar('Datos guardados exitosamente', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
          formikCompany.resetForm();
          formikVehicle.resetForm();
          formikDriver.resetForm();
          history.push('/app/network/transports');
        } else {
          enqueueSnackbar(
            mutationResult.data.postCompanyXVehicle.data.message,
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }
          );
        }
      },
      variables: {
        idEmpresaTransportadora: idCompany,
        idVehiculo:
          selectVehicle !== 0 && selectVehicle !== undefined
            ? selectVehicle
            : idVehicle,
        idConductor: idDriver,
      },
    });
  };

  useEffect(() => {
    setConf(false);
  }, [openNotificationConfirm]);

  const handleNConfirm = (data: any) => {
    setOpenNotificationConfirm({
      open: false,
      icon: '',
      title: '',
      message: '',
    });
    setConf(true);
  };

  const handleOpenNConfirm = (
    icon: 'alert' | 'success' | 'error',
    title: string,
    message: string
  ) => {
    setOpenNotificationConfirm({
      open: true,
      icon: icon,
      title: title,
      message: message,
    });
  };

  const handleCloseNConfirm = () => {
    setOpenNotificationConfirm({
      open: false,
      icon: '',
      title: '',
      message: '',
    });
    formikCompany.resetForm();
  };

  const formikCompany = useFormik({
    initialValues: {
      companyName: '',
      nit: '',
      digitoVerificacion: '',
      country: constants.DEFAULT_COUNTRY_ID,
      departament: constants.DEFAULT_DEPARTMENT_ID,
      city: constants.DEFAULT_CITY_ID,
      indicativePhone: constants.DEFAULT_DEPARTMENT_ID,
      phone: '',
      ext: '',
      indicativeMobile: constants.DEFAULT_COUNTRY_ID,
      mobile: '',
      email: '',
      directionType: 1,
      viaType: '',
      viaNumber: '',
      apendNumber: '',
      cruceNumber: '',
      apendCruceNumber: '',
      cornerMeters: '',
      aditional: '',
      postalCode: '',
      latitude: '',
      longitude: '',
    },
    validationSchema:
      stepFormCompany === 0
        ? CompanyValidationStepOne
        : stepFormCompany === 1
        ? CompanyValidationStepTwo
        : CompanyValidationStepThree,
    validateOnChange: false,
    onSubmit: (values) => {
      switch (stepFormCompany) {
        case 0:
          setStepFormCompany(stepFormCompany + 1);
          break;
        case 1:
          setStepFormCompany(stepFormCompany + 1);
          break;
        case 2:
          postCompany(values);
          break;
        default:
          break;
      }
    },
  });

  const formikVehicle = useFormik({
    initialValues: {
      licenseNumber: '',
      lettersPlate: '',
      numberPlate: '',
      country: constants.DEFAULT_COUNTRY_ID,
      departament: constants.DEFAULT_DEPARTMENT_ID,
      city: constants.DEFAULT_CITY_ID,
      vehicleBrand: '',
      vehicleModel: '',
      color: '',
      vehicleType: '',
      service: '',
      numberFloors: '',
      loadType: '',
      capacity: '',
      firstNameOwner: '',
      secondNameOwner: '',
      surnameOwner: '',
      secondSurnameOwner: '',
      documentType: 1,
      documentOwner: '',
      indicativeMobile: constants.DEFAULT_COUNTRY_ID,
      mobile: '',
      email: '',
    },
    validationSchema:
      stepFormVehicle === 0
        ? VehicleValidationStepOne
        : VehicleValidationStepTwo,
    validateOnChange: false,
    onSubmit: (values) => {
      switch (stepFormVehicle) {
        case 0:
          setStepFormVehicle(stepFormVehicle + 1);
          break;
        case 1:
          postVehicle(values);
          break;
        default:
          break;
      }
    },
  });

  const formikDriver = useFormik({
    initialValues: {
      documentType: 1,
      document: '',
      firstName: '',
      secondName: '',
      surname: '',
      secondSurname: '',
      licenseNumber: '',
      expeditionDate: dayjs(),
      licenseCategory: [
        {
          idCategoriaLicencia: '',
          fechaVigencia: dayjs().format('YYYY/MM/DD'),
        },
      ],
      replacement: false,
      indicativePhone: constants.DEFAULT_DEPARTMENT_ID,
      phone: '',
      ext: '',
      indicativeMobile: constants.DEFAULT_COUNTRY_ID,
      mobile: '',
      email: '',
      directionType: 1,
      country: constants.DEFAULT_COUNTRY_ID,
      departament: constants.DEFAULT_DEPARTMENT_ID,
      city: constants.DEFAULT_CITY_ID,
      viaType: '',
      viaNumber: '',
      apendNumber: '',
      cruceNumber: '',
      apendCruceNumber: '',
      cornerMeters: '',
      aditional: '',
      postalCode: '',
      latitude: '',
      longitude: '',
    },
    validationSchema:
      stepFormDriver === 0
        ? DriverValidationStepOne
        : stepFormDriver === 1
        ? DriverValidationStepTwo
        : DriverValidationStepThree,
    validateOnChange: false,
    onSubmit: (values) => {
      switch (stepFormDriver) {
        case 0:
          setStepFormDriver(stepFormDriver + 1);
          break;
        case 1:
          setStepFormDriver(stepFormDriver + 1);
          break;
        case 2:
          postDriver(values);
          break;
        default:
          break;
      }
    },
  });

  const [
    getDataComany,
    { data: dataCompany, error: errorCompany, loading: loadingDataCompany },
  ] = useLazyQuery(GET_COMPANY_EXISTS);

  const result = dataCompany?.getExistCompanyConveyor?.data;

  useEffect(() => {
    if (dataCompany) {
      handleOpenNConfirm(
        'alert',
        'La Empresa con el nit ingresdo ya existe',
        '¿Desea utilizar los datos guardados de esta empresa?'
      );
    }
  }, [dataCompany]);

  const dataFormik = {
    razonSocial: formikCompany.values.companyName,
    nit: String(formikCompany.values.nit),
    digitoIdentificador: formikCompany.values.digitoVerificacion,
    idPais: Number(formikCompany.values.country),
    idDepartamento: Number(formikCompany.values.departament),
    idCiudad: Number(formikCompany.values.city),
    indicativoTelefono: formikCompany.values.indicativePhone,
    telefono: formikCompany.values.phone,
    extension: formikCompany.values.ext,
    indicativoCelular: formikCompany.values.indicativeMobile,
    celular: formikCompany.values.mobile,
    email: formikCompany.values.email,
    idTipoVia: formikCompany.values.viaType,
    numeroVia: formikCompany.values.viaNumber,
    apendiceVia: formikCompany.values.apendNumber,
    numeroCruce: formikCompany.values.cruceNumber,
    apendiceCruce: formikCompany.values.apendCruceNumber,
    metrosEsquina: formikCompany.values.cornerMeters,
    adicional: formikCompany.values.aditional,
    codigoPostal: formikCompany.values.postalCode,
  };

  const dataGQL = {
    razonSocial: result?.razonSocial,
    nit: result?.nit,
    digitoIdentificador: result?.digitoIdentificador,
    idPais: result?.idPais,
    idDepartamento: result?.idDepartamento,
    idCiudad: result?.idCiudad,
    indicativoTelefono: result?.indicativoTelefono,
    telefono: result?.telefono,
    extension: result?.extension,
    indicativoCelular: result?.indicativoCelular,
    celular: result?.celular,
    email: result?.email,
    idTipoVia: result?.idTipoVia,
    numeroVia: result?.numeroVia,
    apendiceVia: result?.apendiceVia,
    numeroCruce: result?.numeroCruce,
    apendiceCruce: result?.apendiceCruce,
    metrosEsquina: result?.metrosEsquina,
    adicional: result?.adicional,
    codigoPostal: result?.codigoPostal,
  };

  if (dataCompany && conf) {
    formikCompany.values.companyName = result?.razonSocial;
    formikCompany.values.digitoVerificacion = result?.digitoIdentificador;
    formikCompany.values.country = result?.idPais;
    formikCompany.values.departament = result?.idDepartamento;
    formikCompany.values.city = result?.idCiudad;
    formikCompany.values.indicativePhone = result?.indicativoTelefono;
    formikCompany.values.phone = result?.telefono;
    formikCompany.values.ext = result?.extension;
    formikCompany.values.indicativeMobile = result?.indicativoCelular;
    formikCompany.values.mobile = result?.celular;
    formikCompany.values.email = result?.email;
    formikCompany.values.viaType = result?.idTipoVia;
    formikCompany.values.viaNumber = result?.numeroVia;
    formikCompany.values.apendNumber = result?.apendiceVia;
    formikCompany.values.cruceNumber = result?.numeroCruce;
    formikCompany.values.apendCruceNumber = result?.apendiceCruce;
    formikCompany.values.cornerMeters = result?.metrosEsquina;
    formikCompany.values.aditional = result?.adicional;
    formikCompany.values.postalCode = result?.codigoPostal;
  }

  const verifyCompany = (e: any) => {
    getDataComany({
      variables: { nit: e.target.value },
      context: { headers: { 'x-auth-jwt': token } },
    });
  };

  const [
    getDataDriverExists,
    { data: dataDriverE, error: errorDriverE, loading: loadingDriverE },
  ] = useLazyQuery(GET_DRIVERS_EXISTS);

  const resultDriverE = dataDriverE?.getExistDriver?.data;

  useEffect(() => {
    if (dataDriverE) {
      handleOpenNConfirm(
        'alert',
        'El Conductor con el documento ingresdo ya existe',
        '¿Desea utilizar los datos guardados de este Conductor?'
      );
    }
  }, [dataDriverE]);

  const dataFormikDriver = {
    primerNombre: formikDriver.values.firstName,
    segundoNombre: formikDriver.values.secondName,
    primerApellido: formikDriver.values.surname,
    segundoApellido: formikDriver.values.secondSurname,
    tipoDocumento: formikDriver.values.documentType,
    numeroDocumento: formikDriver.values.document,
    numeroLicencia: formikDriver.values.licenseNumber,
    celular: formikDriver.values.mobile,
    correo: formikDriver.values.email,
  };

  const dataGQLDriver = {
    primerNombre: resultDriverE?.primerNombre,
    segundoNombre: resultDriverE?.segundoNombre,
    primerApellido: resultDriverE?.primerApellido,
    segundoApellido: resultDriverE?.segundoApellido,
    tipoDocumento: resultDriverE?.tipoDocumento,
    numeroDocumento: resultDriverE?.numeroDocumento,
    numeroLicencia: String(resultDriverE?.numeroLicencia),
    indicativoCelular: resultDriverE?.indicativoCelular,
    celular: resultDriverE?.celular,
    correo: resultDriverE?.correo,
  };

  if (dataDriverE && conf) {
    formikDriver.values.documentType = resultDriverE?.tipoDocumento;
    formikDriver.values.document = resultDriverE?.numeroDocumento;
    formikDriver.values.firstName = resultDriverE?.primerNombre;
    formikDriver.values.secondName = resultDriverE?.segundoNombre;
    formikDriver.values.surname = resultDriverE?.primerApellido;
    formikDriver.values.secondSurname = resultDriverE?.segundoApellido;
    formikDriver.values.licenseNumber = resultDriverE?.numeroLicenciaConduccion;
    formikDriver.values.replacement = resultDriverE?.esConductordeReemplazo;
    formikDriver.values.indicativeMobile = resultDriverE?.indicativoCelular;
    formikDriver.values.mobile = resultDriverE?.celular;
    formikDriver.values.email = resultDriverE?.correo;
    formikDriver.values.directionType = resultDriverE?.tipoDireccion;
    formikDriver.values.country = resultDriverE?.idPais;
    formikDriver.values.departament = resultDriverE?.idDepartamento;
    formikDriver.values.city = resultDriverE?.idCiudad;
    formikDriver.values.viaType = resultDriverE?.idTipoVia;
    formikDriver.values.viaNumber = resultDriverE?.numeroVia;
    formikDriver.values.apendNumber = resultDriverE?.apendiceVia;
    formikDriver.values.cruceNumber = resultDriverE?.numeroCruce;
    formikDriver.values.apendCruceNumber = resultDriverE?.apendiceCruce;
    formikDriver.values.cornerMeters = resultDriverE?.metrosEsquina;
    formikDriver.values.aditional = resultDriverE?.adicional;
    formikDriver.values.postalCode = resultDriverE?.codigoPostal;
    formikDriver.values.latitude = resultDriverE?.longitud;
    formikDriver.values.longitude = resultDriverE?.latitud;
  }

  const verifyDriver = (e: any) => {
    getDataDriverExists({
      variables: { numeroDocumento: e.target.value },
      context: { headers: { 'x-auth-jwt': token } },
    });
  };

  const [
    getDataVehicleExists,
    { data: dataVehicleE, error: errorVehicleE, loading: loadingVehicleE },
  ] = useLazyQuery(GET_VEHICLES_EXISTS);

  const resultVehicleE = dataVehicleE?.getExistVehicle?.data;

  useEffect(() => {
    if (dataVehicleE) {
      handleOpenNConfirm(
        'alert',
        'El Vehículo con la placa ingresada ya existe',
        '¿Desea utilizar los datos guardados de este Vehículo?'
      );
    }
  }, [dataVehicleE]);

  if (dataVehicleE && conf) {
    formikVehicle.values.licenseNumber = resultVehicleE?.numeroLicenciaTransito;
    formikVehicle.values.country = resultVehicleE?.idPaisPlaca;
    formikVehicle.values.departament = resultVehicleE?.idDepartamentoPlaca;
    formikVehicle.values.city = resultVehicleE?.idCiudadPlaca;
    formikVehicle.values.vehicleBrand = resultVehicleE?.marca;
    formikVehicle.values.vehicleModel = resultVehicleE?.modelo;
    formikVehicle.values.color = resultVehicleE?.color;
    formikVehicle.values.vehicleType = resultVehicleE?.idTipoVehiculo;
    formikVehicle.values.service = resultVehicleE?.servicio;
    formikVehicle.values.numberFloors = resultVehicleE?.numeroPisos;
    formikVehicle.values.loadType = resultVehicleE?.idTipoCarga;
    formikVehicle.values.capacity = resultVehicleE?.capacidadCarga;
    formikVehicle.values.firstNameOwner =
      resultVehicleE?.primerNombrePropietario;
    formikVehicle.values.secondNameOwner =
      resultVehicleE?.segundoNombrePropietario;
    formikVehicle.values.surnameOwner =
      resultVehicleE?.primerApellidoPropietario;
    formikVehicle.values.secondSurnameOwner =
      resultVehicleE?.segundoApellidoPropietario;
    formikVehicle.values.documentType =
      resultVehicleE?.idTipoDocumentoPropietario;
    formikVehicle.values.documentOwner =
      resultVehicleE?.numeroDocumentoPropietario;
    formikVehicle.values.indicativeMobile =
      resultVehicleE?.idIndicativoCelularPropietario;
    formikVehicle.values.mobile = resultVehicleE?.celularPropietario;
    formikVehicle.values.email = resultVehicleE?.emailPropietario;
  }

  const verifyVehicle = (numeroPlaca: string) => {
    getDataVehicleExists({
      variables: { numeroPlaca: numeroPlaca },
      context: { headers: { 'x-auth-jwt': token } },
    });
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    formikDriver.handleChange;
    formikDriver.values.expeditionDate = date;
  };

  const handleFormChange = (index: number, event: any, name: string) => {
    const data = [...licenseCategory];
    if (name === 'idCategoriaLicencia') {
      data[index].idCategoriaLicencia = event.target.value;
    } else {
      data[index].fechaVigencia = dayjs(event).format('YYYY/MM/DD');
    }
    setLicenseCategory(data);
    formikDriver.values.licenseCategory = data;
    formikDriver.handleChange;
  };

  const addFields = () => {
    let newfield = {
      idCategoriaLicencia: '',
      fechaVigencia: dayjs().format('YYYY/MM/DD'),
    };
    setLicenseCategory([...licenseCategory, newfield]);
  };

  const removeFields = (index: number) => {
    let data = [...licenseCategory];
    data.splice(index, 1);
    setLicenseCategory(data);
    formikDriver.values.licenseCategory = data;
  };

  const renderInput = (props: TextFieldProps): any => (
    <TextField
      label="Fecha de Vigencia"
      size="small"
      fullWidth
      variant="outlined"
      onClick={props.onClick}
      value={props.value}
      onChange={props.onChange}
    />
  );

  const breakPointsSlider = [
    { width: 1, itemsToShow: 1 },
    { width: 450, itemsToShow: 2, itemsToScroll: 2 },
    { width: 700, itemsToShow: 3 },
    { width: 1050, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6, itemsToScroll: 3 },
  ];

  return (
    <>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Box mb={2}>
          <Grid container justifyContent="center">
            <MainTitle>Registro Empresa de Transporte</MainTitle>
          </Grid>
        </Box>

        <div className={classes.containerStepper}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepper}
          >
            {steps?.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        {activeStep === 0 && (
          <Grow in timeout={800}>
            <form onSubmit={formikCompany.handleSubmit}>
              <Grid container>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={1}
                >
                  <Fab
                    color="primary"
                    onClick={() => setStepFormCompany(stepFormCompany - 1)}
                    className={clsx({
                      [classes.btnStep]: true,
                      [classes.hiddenButton]: stepFormCompany === 0,
                    })}
                  >
                    <Box
                      component="i"
                      fontSize="20px"
                      fontWeight="bold"
                      className="icon-arrow-left"
                    />
                  </Fab>
                </Grid>
                <Grid item xs={10}>
                  {stepFormCompany === 0 && (
                    <Grow in timeout={800}>
                      <div>
                        <Box marginBottom={2}>
                          <MainTitle fontSize="h6">
                            Datos Generales de la Empresa
                            <span className={classes.fontInfoStep}>
                              (Paso {stepFormCompany + 1} de 3)
                            </span>
                          </MainTitle>
                        </Box>

                        <Grid
                          container
                          spacing={2}
                          style={{ marginBottom: '10px' }}
                        >
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Razón Social"
                              variant="outlined"
                              size="small"
                              name="companyName"
                              value={formikCompany.values.companyName}
                              onChange={formikCompany.handleChange}
                              error={
                                formikCompany.touched.companyName &&
                                Boolean(formikCompany.errors.companyName)
                              }
                              helperText={
                                formikCompany.touched.companyName &&
                                formikCompany.errors.companyName
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="NIT"
                              variant="outlined"
                              size="small"
                              type="number"
                              name="nit"
                              onBlur={(e) => verifyCompany(e)}
                              onInput={(e: any) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 9);
                              }}
                              value={formikCompany.values.nit}
                              onChange={formikCompany.handleChange}
                              error={
                                formikCompany.touched.nit &&
                                Boolean(formikCompany.errors.nit)
                              }
                              helperText={
                                formikCompany.touched.nit &&
                                formikCompany.errors.nit
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Dig. Verificación"
                              variant="outlined"
                              size="small"
                              type="number"
                              name="digitoVerificacion"
                              value={
                                (formikCompany.values.digitoVerificacion =
                                  formikCompany.values.nit
                                    ? calculateCodVerification(
                                        Number(formikCompany.values.nit)
                                      )
                                    : '')
                              }
                              onChange={formikCompany.handleChange}
                              error={
                                formikCompany.touched.digitoVerificacion &&
                                Boolean(formikCompany.errors.digitoVerificacion)
                              }
                              helperText={
                                formikCompany.touched.digitoVerificacion &&
                                formikCompany.errors.digitoVerificacion
                              }
                              required
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grow>
                  )}

                  {stepFormCompany === 1 && (
                    <Grow in timeout={800}>
                      <div>
                        <Box marginBottom={3} marginTop={2}>
                          <MainTitle fontSize="h6">
                            Datos de Contacto
                            <span className={classes.fontInfoStep}>
                              (Paso {stepFormCompany + 1} de 3)
                            </span>
                          </MainTitle>
                        </Box>

                        <Box mb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <PhoneNumber
                                type="landline"
                                extension
                                names={{
                                  indicative: 'indicativePhone',
                                  phone: 'phone',
                                  ext: 'ext',
                                }}
                                values={{
                                  indicative:
                                    formikCompany.values.indicativePhone,
                                  phone: (formikCompany.values.phone =
                                    unmaskPhone(formikCompany.values.phone)),
                                  ext: formikCompany.values.ext,
                                }}
                                onChange={formikCompany.handleChange}
                                errors={{
                                  indicative:
                                    !!formikCompany.errors.indicativePhone &&
                                    !!formikCompany.touched.indicativePhone,
                                  phone: formikCompany.errors.phone as string,
                                  ext: formikCompany.errors.ext as string,
                                }}
                                helperTexts={{
                                  indicative: formikCompany.errors
                                    .indicativePhone as string,
                                  phone: formikCompany.errors.phone as string,
                                  ext: formikCompany.errors.ext as string,
                                }}
                                required
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <PhoneNumber
                                type="mobile"
                                names={{
                                  indicative: 'indicativeMobile',
                                  phone: 'mobile',
                                }}
                                values={{
                                  indicative:
                                    formikCompany.values.indicativeMobile,
                                  phone: (formikCompany.values.mobile =
                                    unmaskPhone(formikCompany.values.mobile)),
                                }}
                                onChange={formikCompany.handleChange}
                                errors={{
                                  indicative:
                                    !!formikCompany.errors.indicativeMobile &&
                                    !!formikCompany.touched.indicativeMobile,
                                  phone: formikCompany.errors.mobile as string,
                                }}
                                helperTexts={{
                                  indicative: formikCompany.errors
                                    .indicativeMobile as string,
                                  phone: formikCompany.errors.mobile as string,
                                }}
                                required
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mt={2} mb={2}>
                          <Grid container spacing={1}>
                            <TextField
                              fullWidth
                              label="Correo Electrónico"
                              variant="outlined"
                              size="small"
                              type="email"
                              name="email"
                              value={formikCompany.values.email}
                              onChange={formikCompany.handleChange}
                              error={
                                formikCompany.touched.email &&
                                Boolean(formikCompany.errors.email)
                              }
                              helperText={
                                formikCompany.touched.email &&
                                formikCompany.errors.email
                              }
                              required
                            />
                          </Grid>
                        </Box>
                      </div>
                    </Grow>
                  )}

                  {stepFormCompany === 2 && (
                    <Grow in timeout={800}>
                      <div>
                        <Box marginTop={2}>
                          <MainTitle fontSize="h6">
                            Datos de Ubicación
                            <span className={classes.fontInfoStep}>
                              (Paso {stepFormCompany + 1} de 3)
                            </span>
                          </MainTitle>
                        </Box>

                        <Box mt={3} mb={1}>
                          <Direction
                            names={{
                              directionType: 'directionType',
                              viaType: 'viaType',
                              viaNumber: 'viaNumber',
                              apendNumber: 'apendNumber',
                              cruceNumber: 'cruceNumber',
                              apendCruceNumber: 'apendCruceNumber',
                              cornerMeters: 'cornerMeters',
                              aditional: 'aditional',
                              postalCode: 'postalCode',
                              latitude: 'latitud',
                              longitude: 'longitud',
                              idPais: 'country',
                              idDepartamento: 'departament',
                              city: 'city',
                            }}
                            values={{
                              directionType: formikCompany.values.directionType,
                              viaType: formikCompany.values.viaType,
                              viaNumber: formikCompany.values.viaNumber,
                              apendNumber: formikCompany.values.apendNumber,
                              cruceNumber: formikCompany.values.cruceNumber,
                              apendCruceNumber:
                                formikCompany.values.apendCruceNumber,
                              cornerMeters: formikCompany.values.cornerMeters,
                              aditional: formikCompany.values.aditional,
                              postalCode: formikCompany.values.postalCode,
                              latitude: formikCompany.values.latitude,
                              longitude: formikCompany.values.longitude,
                              idPais: Number(formikCompany.values.country),
                              idDepartamento: Number(
                                formikCompany.values.departament
                              ),
                              city: Number(formikCompany.values.city),
                            }}
                            errors={{
                              directionType: formikCompany.errors.directionType,
                              viaType: formikCompany.errors.viaType,
                              viaNumber: formikCompany.errors.viaNumber,
                              apendNumber: formikCompany.errors.apendNumber,
                              cruceNumber: formikCompany.errors.cruceNumber,
                              apendCruceNumber:
                                formikCompany.errors.apendCruceNumber,
                              cornerMeters: formikCompany.errors.cornerMeters,
                              aditional: formikCompany.errors.aditional,
                              postalCode: formikCompany.errors.postalCode,
                              latitude: formikCompany.errors.latitude,
                              longitude: formikCompany.errors.longitude,
                              idPais: formikCompany.errors.country as string,
                              idDepartamento: formikCompany.errors
                                .departament as string,
                              city: formikCompany.errors.city as string,
                            }}
                            setFieldValue={formikCompany.setFieldValue}
                            handleChange={formikCompany.handleChange}
                            handleBlur={formikCompany.handleBlur}
                            touched={formikCompany.touched}
                          />
                        </Box>
                      </div>
                    </Grow>
                  )}

                  <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={stepFormCompany !== 2}
                        endIcon={
                          loadingCompany && (
                            <CircularProgress
                              className={classes.loadingColor}
                              thickness={5}
                              size={20}
                            />
                          )
                        }
                      >
                        Siguiente
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={1}
                >
                  <Fab
                    color="primary"
                    type="submit"
                    className={clsx({
                      [classes.btnStep]: true,
                      [classes.hiddenButton]: stepFormCompany === 2,
                    })}
                  >
                    <Box
                      component="i"
                      fontSize="20px"
                      fontWeight="bold"
                      className="icon-arrow-right"
                    />
                  </Fab>
                </Grid>
              </Grid>
            </form>
          </Grow>
        )}

        {activeStep === 1 && (
          <>
            {selectVehicle !== 0 && !loadingVehicles && vehicles && (
              <>
                <Grid container justifyContent="center">
                  <Box
                    mt={1}
                    mb={4}
                    maxWidth="50%"
                    textAlign="center"
                    color="#afafaf"
                  >
                    <Typography>
                      Seleccione el vehículo que desea asociar a la empresa
                      anteriormente registrado, tenga en cuenta que estos
                      vehículos no tiene una empresa asociada. Si desea
                      registrar un Vehículo, seleccione la opción Registrar
                      Vehículo.
                    </Typography>
                    <Box mt={2} mb={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSelectVehicle(0)}
                      >
                        Registrar Vehículo
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  container
                  spacing={5}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Slider
                    breakPoints={breakPointsSlider}
                    className={classes.carouselContainerVehicle}
                  >
                    {vehicles?.map((item: any, ind: number) => {
                      return (
                        item.empresaTransportadora === 'N.D' && (
                          <CardSelectVehicle
                            plate={item?.placa}
                            ubication="Medellín"
                            vehicleType={item?.tipoVehiculo}
                            capacity={item?.capacidadCarga}
                            onClick={() => setSelectVehicle(item?.idVehiculo)}
                            checked={selectVehicle === item?.idVehiculo}
                          />
                        )
                      );
                    })}
                    <Card
                      elevation={2}
                      className={clsx({
                        [classes.cardItemAddVehicle]: true,
                      })}
                    >
                      <CardActionArea
                        className={classes.cardActionArea}
                        onClick={() => setSelectVehicle(0)}
                      >
                        <CardContent>
                          <Grid container justifyContent="center">
                            <Box
                              component="i"
                              className={clsx(
                                'icon-circle-plus',
                                classes.iconCard
                              )}
                            />
                            <Typography className={classes.mainText}>
                              Registrar Vehículo
                            </Typography>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Slider>
                </Grid>
              </>
            )}
            {selectVehicle !== 0 && vehicles && (
              <Box mt={4} mb={2}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleBack()}
                    >
                      Atrás
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        selectVehicle === 0 ||
                        selectVehicle === undefined ||
                        loadingVehicles
                      }
                      onClick={() => handleNext()}
                    >
                      Siguiente
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
            {(selectVehicle === 0 || !vehicles) && (
              <form onSubmit={formikVehicle.handleSubmit}>
                <Grid container>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={1}
                  >
                    <Fab
                      color="primary"
                      onClick={() => setStepFormVehicle(stepFormVehicle - 1)}
                      className={clsx({
                        [classes.btnStep]: true,
                        [classes.hiddenButton]: stepFormVehicle === 0,
                      })}
                    >
                      <Box
                        component="i"
                        fontSize="20px"
                        fontWeight="bold"
                        className="icon-arrow-left"
                      />
                    </Fab>
                  </Grid>
                  <Grid item xs={10}>
                    {stepFormVehicle === 0 && (
                      <Grow in timeout={800}>
                        <div>
                          {vehicles && (
                            <Grid
                              container
                              className={classes.backButton}
                              onClick={() => setSelectVehicle(undefined)}
                            >
                              <Grid item>
                                <ArrowBackIcon fontSize="small" />
                                <Typography>Seleccionar Vehículo</Typography>
                              </Grid>
                            </Grid>
                          )}
                          <Box marginBottom={3} marginTop={1}>
                            <MainTitle fontSize="h6">
                              Datos Generales del Vehículo
                              <span className={classes.fontInfoStep}>
                                (Paso {stepFormVehicle + 1} de 2)
                              </span>
                            </MainTitle>
                          </Box>

                          <Grid container>
                            <TextField
                              fullWidth
                              label="Número de Licencia"
                              variant="outlined"
                              size="small"
                              name="licenseNumber"
                              value={formikVehicle.values.licenseNumber}
                              onChange={formikVehicle.handleChange}
                              error={
                                formikVehicle.touched.licenseNumber &&
                                Boolean(formikVehicle.errors.licenseNumber)
                              }
                              helperText={
                                formikVehicle.touched.licenseNumber &&
                                formikVehicle.errors.licenseNumber
                              }
                              required
                            />
                          </Grid>

                          <Box mt={2}>
                            <Grid spacing={2}>
                              <Box mb={1}>
                                <Grid container spacing={2}>
                                  <Grid
                                    container
                                    item
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Grid
                                      item
                                      xs={4}
                                      className={classes.plateContainer}
                                    >
                                      <InputPlate
                                        names={{
                                          letters: 'lettersPlate',
                                          numbers: 'numberPlate',
                                        }}
                                        values={{
                                          letters:
                                            formikVehicle.values.lettersPlate,
                                          numbers:
                                            formikVehicle.values.numberPlate,
                                        }}
                                        errors={{
                                          letters:
                                            formikVehicle.errors.lettersPlate,
                                          numbers:
                                            formikVehicle.errors.numberPlate,
                                        }}
                                        helperText={{
                                          letters: formikVehicle.errors
                                            .lettersPlate as string,
                                          numbers: formikVehicle.errors
                                            .numberPlate as string,
                                        }}
                                        onChange={formikVehicle.handleChange}
                                        onBlur={() =>
                                          verifyVehicle(
                                            `${
                                              formikVehicle.values
                                                .lettersPlate +
                                              formikVehicle.values.numberPlate
                                            }`
                                          )
                                        }
                                        ciudad={Number(
                                          formikVehicle.values.city
                                        )}
                                        width={350}
                                      />
                                    </Grid>

                                    <Grid item xs={8}>
                                      <SelectCountry
                                        direction="vertical"
                                        names={{
                                          country: 'country',
                                          departament: 'departament',
                                          city: 'city',
                                        }}
                                        values={{
                                          country: formikVehicle.values.country,
                                          departament:
                                            formikVehicle.values.departament,
                                          city: formikVehicle.values.city,
                                        }}
                                        errors={{
                                          country: formikVehicle.errors.country,
                                          departament:
                                            formikVehicle.errors.departament,
                                          city: formikVehicle.errors.city,
                                        }}
                                        onChange={formikVehicle.handleChange}
                                        helperText={{
                                          country: formikVehicle.errors.country,
                                          departament:
                                            formikVehicle.errors.departament,
                                          city: formikVehicle.errors.city,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box mb={1.5}>
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <TextField
                                      fullWidth
                                      label="Marca del Vehículo"
                                      variant="outlined"
                                      size="small"
                                      name="vehicleBrand"
                                      value={formikVehicle.values.vehicleBrand}
                                      onChange={formikVehicle.handleChange}
                                      error={
                                        formikVehicle.touched.vehicleBrand &&
                                        Boolean(
                                          formikVehicle.errors.vehicleBrand
                                        )
                                      }
                                      helperText={
                                        formikVehicle.touched.vehicleBrand &&
                                        formikVehicle.errors.vehicleBrand
                                      }
                                      required
                                    />
                                  </Grid>

                                  <Grid item xs={4}>
                                    <TextField
                                      fullWidth
                                      label="Modelo del Vehículo"
                                      variant="outlined"
                                      size="small"
                                      name="vehicleModel"
                                      type="number"
                                      value={formikVehicle.values.vehicleModel}
                                      onChange={formikVehicle.handleChange}
                                      error={
                                        formikVehicle.touched.vehicleModel &&
                                        Boolean(
                                          formikVehicle.errors.vehicleModel
                                        )
                                      }
                                      helperText={
                                        formikVehicle.touched.vehicleModel &&
                                        formikVehicle.errors.vehicleModel
                                      }
                                      onInput={(e: any) => {
                                        e.target.value = Math.max(
                                          0,
                                          parseInt(e.target.value)
                                        )
                                          .toString()
                                          .slice(0, 9);
                                      }}
                                      required
                                    />
                                  </Grid>

                                  <Grid item xs={4}>
                                    <TextField
                                      fullWidth
                                      label="Color del Vehículo"
                                      variant="outlined"
                                      size="small"
                                      name="color"
                                      value={formikVehicle.values.color}
                                      onChange={formikVehicle.handleChange}
                                      error={
                                        formikVehicle.touched.color &&
                                        Boolean(formikVehicle.errors.color)
                                      }
                                      helperText={
                                        formikVehicle.touched.color &&
                                        formikVehicle.errors.color
                                      }
                                      required
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box mb={1.5}>
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <TextField
                                      fullWidth
                                      label="Tipo de Vehículo"
                                      variant="outlined"
                                      size="small"
                                      name="vehicleType"
                                      select
                                      value={formikVehicle.values.vehicleType}
                                      onChange={(e: any) => {
                                        formikVehicle.handleChange(e);
                                        handleChangeTypeVehichle(e);
                                      }}
                                      error={
                                        formikVehicle.touched.vehicleType &&
                                        Boolean(
                                          formikVehicle.errors.vehicleType
                                        )
                                      }
                                      helperText={
                                        formikVehicle.touched.vehicleType &&
                                        formikVehicle.errors.vehicleType
                                      }
                                      required
                                    >
                                      {loadingTypeVehicle ? (
                                        <CircularProgress
                                          variant="indeterminate"
                                          size={25}
                                        />
                                      ) : errorTypeVehicle ? (
                                        <p>Error</p>
                                      ) : (
                                        dataTypeVehicle?.getTypeVehicle.map(
                                          (item: any) => (
                                            <MenuItem
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.description}
                                            </MenuItem>
                                          )
                                        )
                                      )}
                                    </TextField>
                                  </Grid>

                                  <Grid item xs={4}>
                                    <TextField
                                      label="Tipo de Servicio"
                                      select
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      name="service"
                                      value={formikVehicle.values.service}
                                      onChange={(e: any) => {
                                        formikVehicle.handleChange(e);
                                        handleChangeServiceVehicle(e);
                                      }}
                                      error={
                                        formikVehicle.touched.service &&
                                        Boolean(formikVehicle.errors.service)
                                      }
                                      helperText={
                                        formikVehicle.touched.service &&
                                        formikVehicle.errors.service
                                      }
                                      required
                                    >
                                      {service.map((item: any) => (
                                        <MenuItem key={item.id} value={item.id}>
                                          {item.description}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>

                                  <Grid item xs={4}>
                                    <TextField
                                      fullWidth
                                      label="Número de Pisos"
                                      variant="outlined"
                                      size="small"
                                      name="numberFloors"
                                      value={formikVehicle.values.numberFloors}
                                      onChange={formikVehicle.handleChange}
                                      error={
                                        formikVehicle.touched.numberFloors &&
                                        Boolean(
                                          formikVehicle.errors.numberFloors
                                        )
                                      }
                                      helperText={
                                        formikVehicle.touched.numberFloors &&
                                        formikVehicle.errors.numberFloors
                                      }
                                      required
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box mb={2}>
                                <Grid container spacing={2}>
                                  <Grid item xs={5}>
                                    <TextField
                                      label="Tipo de Carga"
                                      select
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      name="loadType"
                                      value={formikVehicle.values.loadType}
                                      onChange={(e: any) => {
                                        formikVehicle.handleChange(e);
                                        handleChangeTypeLoad(e);
                                      }}
                                      error={
                                        formikVehicle.touched.loadType &&
                                        Boolean(formikVehicle.errors.loadType)
                                      }
                                      helperText={
                                        formikVehicle.touched.loadType &&
                                        formikVehicle.errors.loadType
                                      }
                                      required
                                    >
                                      {loadingTypeLoad ? (
                                        <CircularProgress
                                          variant="indeterminate"
                                          size={25}
                                        />
                                      ) : errorTypeLoad ? (
                                        <p>Error</p>
                                      ) : (
                                        dataTypeLoad?.getTypeLoad?.map(
                                          (item: any) => (
                                            <MenuItem
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.description}
                                            </MenuItem>
                                          )
                                        )
                                      )}
                                    </TextField>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      fullWidth
                                      label="Capacidad"
                                      variant="outlined"
                                      size="small"
                                      name="capacity"
                                      value={formikVehicle.values.capacity}
                                      onChange={formikVehicle.handleChange}
                                      error={
                                        formikVehicle.touched.capacity &&
                                        Boolean(formikVehicle.errors.capacity)
                                      }
                                      helperText={
                                        formikVehicle.touched.capacity &&
                                        formikVehicle.errors.capacity
                                      }
                                      required
                                    />
                                  </Grid>

                                  <Grid item xs={4}>
                                    <TextField
                                      label="Unidad"
                                      select
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      name="loadType"
                                      value={formikVehicle.values.loadType}
                                      onChange={formikVehicle.handleChange}
                                      required
                                    >
                                      {loadingTypeLoad ? (
                                        <CircularProgress
                                          variant="indeterminate"
                                          size={25}
                                        />
                                      ) : errorTypeLoad ? (
                                        <p>Error</p>
                                      ) : (
                                        dataTypeLoad?.getTypeLoad?.map(
                                          (item: any) =>
                                            formikVehicle.values.loadType ===
                                              item.id && (
                                              <MenuItem
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {item.loadUnit}
                                              </MenuItem>
                                            )
                                        )
                                      )}
                                    </TextField>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>
                        </div>
                      </Grow>
                    )}

                    {stepFormVehicle === 1 && (
                      <Grow in timeout={800}>
                        <div>
                          <Grid spacing={2}>
                            <Box marginBottom={3} marginTop={1}>
                              <MainTitle fontSize="h6">
                                Datos de Generales del Propietario
                                <span className={classes.fontInfoStep}>
                                  (Paso {stepFormVehicle + 1} de 2)
                                </span>
                              </MainTitle>
                            </Box>

                            <Box mb={2.5}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    label="Primer Nombre"
                                    variant="outlined"
                                    size="small"
                                    name="firstNameOwner"
                                    value={formikVehicle.values.firstNameOwner}
                                    onChange={formikVehicle.handleChange}
                                    error={
                                      formikVehicle.touched.firstNameOwner &&
                                      Boolean(
                                        formikVehicle.errors.firstNameOwner
                                      )
                                    }
                                    helperText={
                                      formikVehicle.touched.firstNameOwner &&
                                      formikVehicle.errors.firstNameOwner
                                    }
                                    required
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    label="Segundo Nombre"
                                    variant="outlined"
                                    size="small"
                                    name="secondNameOwner"
                                    value={formikVehicle.values.secondNameOwner}
                                    onChange={formikVehicle.handleChange}
                                    error={
                                      formikVehicle.touched.secondNameOwner &&
                                      Boolean(
                                        formikVehicle.errors.secondNameOwner
                                      )
                                    }
                                    helperText={
                                      formikVehicle.touched.secondNameOwner &&
                                      formikVehicle.errors.secondNameOwner
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                            <Box mb={2.5}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    label="Primer Apellido"
                                    variant="outlined"
                                    size="small"
                                    name="surnameOwner"
                                    value={formikVehicle.values.surnameOwner}
                                    onChange={formikVehicle.handleChange}
                                    error={
                                      formikVehicle.touched.surnameOwner &&
                                      Boolean(formikVehicle.errors.surnameOwner)
                                    }
                                    helperText={
                                      formikVehicle.touched.surnameOwner &&
                                      formikVehicle.errors.surnameOwner
                                    }
                                    required
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    label="Segundo Apellido"
                                    variant="outlined"
                                    size="small"
                                    name="secondSurnameOwner"
                                    value={
                                      formikVehicle.values.secondSurnameOwner
                                    }
                                    onChange={formikVehicle.handleChange}
                                    error={
                                      formikVehicle.touched
                                        .secondSurnameOwner &&
                                      Boolean(
                                        formikVehicle.errors.secondSurnameOwner
                                      )
                                    }
                                    helperText={
                                      formikVehicle.touched
                                        .secondSurnameOwner &&
                                      formikVehicle.errors.secondSurnameOwner
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                            <Box mb={2}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <DocumentTypes
                                    name="documentType"
                                    entity={1}
                                    value={formikVehicle.values.documentType}
                                    onChange={formikVehicle.handleChange}
                                    errors={formikVehicle.errors.documentType}
                                    helperText={
                                      formikVehicle.errors.documentType
                                    }
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    label="Documneto"
                                    variant="outlined"
                                    size="small"
                                    name="documentOwner"
                                    value={formikVehicle.values.documentOwner}
                                    onChange={formikVehicle.handleChange}
                                    error={
                                      formikVehicle.touched.documentOwner &&
                                      Boolean(
                                        formikVehicle.errors.documentOwner
                                      )
                                    }
                                    helperText={
                                      formikVehicle.touched.documentOwner &&
                                      formikVehicle.errors.documentOwner
                                    }
                                    required
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                            <Box mb={3}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <PhoneNumber
                                    type="mobile"
                                    names={{
                                      indicative: 'indicativeMobile',
                                      phone: 'mobile',
                                    }}
                                    values={{
                                      indicative: Number(
                                        formikVehicle.values.indicativeMobile
                                      ),
                                      phone: (formikVehicle.values.mobile =
                                        unmaskPhone(
                                          formikVehicle.values.mobile
                                        )),
                                    }}
                                    onChange={formikVehicle.handleChange}
                                    errors={{
                                      indicative:
                                        !!formikVehicle.errors
                                          .indicativeMobile &&
                                        !!formikVehicle.touched
                                          .indicativeMobile,
                                      phone: formikVehicle.errors
                                        .mobile as string,
                                    }}
                                    helperTexts={{
                                      indicative: formikVehicle.errors
                                        .indicativeMobile as string,
                                      phone: formikVehicle.errors
                                        .mobile as string,
                                    }}
                                    required
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                            <Box mb={2}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    label="Correo Electrónico"
                                    variant="outlined"
                                    size="small"
                                    type="email"
                                    name="email"
                                    value={formikVehicle.values.email}
                                    onChange={formikVehicle.handleChange}
                                    error={
                                      formikVehicle.touched.email &&
                                      Boolean(formikVehicle.errors.email)
                                    }
                                    helperText={
                                      formikVehicle.touched.email &&
                                      formikVehicle.errors.email
                                    }
                                    required
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </div>
                      </Grow>
                    )}

                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleBack()}
                        >
                          Atrás
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={stepFormVehicle !== 1}
                          endIcon={
                            loadingVehicle && (
                              <CircularProgress
                                className={classes.loadingColor}
                                thickness={5}
                                size={20}
                              />
                            )
                          }
                        >
                          Siguiente
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={1}
                  >
                    <Fab
                      color="primary"
                      type="submit"
                      className={clsx({
                        [classes.btnStep]: true,
                        [classes.hiddenButton]: stepFormVehicle === 1,
                      })}
                    >
                      <Box
                        component="i"
                        fontSize="20px"
                        fontWeight="bold"
                        className="icon-arrow-right"
                      />
                    </Fab>
                  </Grid>
                </Grid>
              </form>
            )}
          </>
        )}

        {activeStep === 2 && (
          <>
            {selectDriver !== 0 && !loadingDrivers && drivers && (
              <>
                <Grid container justifyContent="center">
                  <Box
                    mt={1}
                    mb={4}
                    maxWidth="50%"
                    textAlign="center"
                    color="#afafaf"
                  >
                    <Typography>
                      Seleccione el Conductor que desea asociar al Vehículo
                      anteriormente registrado o seleccionado. Si desea
                      registrar un Conductor, seleccione la opción Registrar
                      Conductor.
                    </Typography>
                    <Box mt={2} mb={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSelectDriver(0)}
                      >
                        Registrar Conductor
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  container
                  spacing={5}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Slider
                    breakPoints={breakPointsSlider}
                    className={classes.carouselContainerDriver}
                  >
                    {drivers?.map((item: any, ind: number) => {
                      return (
                        <CardSelectDriver
                          name={item?.nombreConductor}
                          ubication={item?.ciudad}
                          document={item?.numeroDocumento}
                          width={220}
                          height={250}
                          onClick={() => setSelectDriver(item?.idConductor)}
                          checked={selectDriver === item?.idConductor}
                        />
                      );
                    })}
                    <Card
                      elevation={2}
                      className={clsx({
                        [classes.cardItemAddDriver]: true,
                      })}
                    >
                      <CardActionArea
                        className={classes.cardActionArea}
                        onClick={() => setSelectDriver(0)}
                      >
                        <CardContent>
                          <Grid container justifyContent="center">
                            <Box
                              component="i"
                              className={clsx(
                                'icon-circle-plus',
                                classes.iconCard
                              )}
                            />
                            <Typography className={classes.mainText}>
                              Registrar Conductor
                            </Typography>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Slider>
                </Grid>
              </>
            )}
            {selectDriver !== 0 && drivers && (
              <Box mt={4} mb={2}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleBack()}
                    >
                      Atrás
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        selectDriver === 0 ||
                        selectDriver === undefined ||
                        loadingDrivers
                      }
                      onClick={() =>
                        assignCompanyXVehicle(
                          Number(idCompany),
                          Number(selectVehicle ?? idVehicle),
                          Number(selectDriver)
                        )
                      }
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
            {(selectDriver === 0 || !drivers) && (
              <form onSubmit={formikDriver.handleSubmit}>
                <Grid container>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={1}
                  >
                    <Fab
                      color="primary"
                      onClick={() => setStepFormDriver(stepFormDriver - 1)}
                      className={clsx({
                        [classes.btnStep]: true,
                        [classes.hiddenButton]: stepFormDriver === 0,
                      })}
                    >
                      <Box
                        component="i"
                        fontSize="20px"
                        fontWeight="bold"
                        className="icon-arrow-left"
                      />
                    </Fab>
                  </Grid>
                  <Grid item xs={10}>
                    {stepFormDriver === 0 && (
                      <Grow in timeout={800}>
                        <div>
                          {drivers && (
                            <Grid
                              container
                              className={classes.backButton}
                              onClick={() => setSelectDriver(undefined)}
                            >
                              <Grid item>
                                <ArrowBackIcon fontSize="small" />
                                <Typography>Seleccionar Conductor</Typography>
                              </Grid>
                            </Grid>
                          )}
                          <Box marginBottom={3} marginTop={1}>
                            <MainTitle fontSize="h6">
                              Datos Generales del Conductor
                              <span className={classes.fontInfoStep}>
                                (Paso {stepFormDriver + 1} de 3)
                              </span>
                            </MainTitle>
                          </Box>

                          <Box mb={2}>
                            <Grid container spacing={3}>
                              <Grid container item spacing={1}>
                                <Grid item xs={6}>
                                  <DocumentTypes
                                    name="documentType"
                                    value={formikDriver.values.documentType}
                                    onChange={formikDriver.handleChange}
                                    entity={1}
                                    errors={formikDriver.errors.documentType}
                                    helperText={
                                      formikDriver.errors.documentType
                                    }
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    label="Doumento"
                                    variant="outlined"
                                    size="small"
                                    name="document"
                                    type="number"
                                    onBlur={(e) => verifyDriver(e)}
                                    value={formikDriver.values.document}
                                    onChange={formikDriver.handleChange}
                                    error={
                                      formikDriver.touched.document &&
                                      Boolean(formikDriver.errors.document)
                                    }
                                    helperText={
                                      formikDriver.touched.document &&
                                      formikDriver.errors.document
                                    }
                                    required
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box mb={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Primer Nombre"
                                  variant="outlined"
                                  size="small"
                                  name="firstName"
                                  value={formikDriver.values.firstName}
                                  onChange={formikDriver.handleChange}
                                  error={
                                    formikDriver.touched.firstName &&
                                    Boolean(formikDriver.errors.firstName)
                                  }
                                  helperText={
                                    formikDriver.touched.firstName &&
                                    formikDriver.errors.firstName
                                  }
                                  required
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Segundo Nombre"
                                  variant="outlined"
                                  size="small"
                                  name="secondName"
                                  value={formikDriver.values.secondName}
                                  onChange={formikDriver.handleChange}
                                  error={
                                    formikDriver.touched.secondName &&
                                    Boolean(formikDriver.errors.secondName)
                                  }
                                  helperText={
                                    formikDriver.touched.secondName &&
                                    formikDriver.errors.secondName
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>

                          <Box mb={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Primer Apellido"
                                  variant="outlined"
                                  size="small"
                                  name="surname"
                                  value={formikDriver.values.surname}
                                  onChange={formikDriver.handleChange}
                                  error={
                                    formikDriver.touched.surname &&
                                    Boolean(formikDriver.errors.surname)
                                  }
                                  helperText={
                                    formikDriver.touched.surname &&
                                    formikDriver.errors.surname
                                  }
                                  required
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Segundo Apellido"
                                  variant="outlined"
                                  size="small"
                                  name="secondSurname"
                                  value={formikDriver.values.secondSurname}
                                  onChange={formikDriver.handleChange}
                                  error={
                                    formikDriver.touched.secondSurname &&
                                    Boolean(formikDriver.errors.secondSurname)
                                  }
                                  helperText={
                                    formikDriver.touched.secondSurname &&
                                    formikDriver.errors.secondSurname
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>

                          <Box mt={2.5} mb={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={5}>
                                <TextField
                                  fullWidth
                                  label="Número de Licencia"
                                  variant="outlined"
                                  size="small"
                                  name="licenseNumber"
                                  value={formikDriver.values.licenseNumber.toUpperCase()}
                                  onChange={formikDriver.handleChange}
                                  error={
                                    formikDriver.touched.licenseNumber &&
                                    Boolean(formikDriver.errors.licenseNumber)
                                  }
                                  helperText={
                                    formikDriver.touched.licenseNumber &&
                                    formikDriver.errors.licenseNumber
                                  }
                                  required
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <MuiPickersUtilsProvider
                                  utils={DateFnsUtils}
                                  locale={ES}
                                >
                                  <DatePicker
                                    label="Fecha Expedición"
                                    format="dd/MM/yyyy"
                                    value={formikDriver.values.expeditionDate}
                                    onChange={handleDateChange}
                                    variant="inline"
                                    fullWidth
                                    inputVariant="outlined"
                                    size="small"
                                    labelFunc={() =>
                                      dayjs(selectedDate).format('DD/MM/YYYY')
                                    }
                                    maxDate={dayjs()}
                                    minDate={dayjs().subtract(10, 'year')}
                                    minDateMessage="La fecha final debe ser mayor a la inicial"
                                    maxDateMessage="La fecha final debe ser mayor a la inicial"
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={2}
                                alignItems="center"
                                justifyContent="center"
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="replacement"
                                      color="primary"
                                      onChange={formikDriver.handleChange}
                                      checked={formikDriver.values.replacement}
                                    />
                                  }
                                  label="Reemplazo"
                                />
                                <Tooltip
                                  title='Selecciona esta opción si deseas que este conductor sea "Conductor de Reemplazo" del Vehículo que se le va a asociar.'
                                  aria-label="add"
                                  arrow
                                >
                                  <Box
                                    component="i"
                                    className="icon-question"
                                    fontSize="22px"
                                    style={{ cursor: 'pointer', marginTop: -2 }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Box>

                          {licenseCategory.map((item: any, index: number) => (
                            <Grow in timeout={500} key={index}>
                              <Box mb={2}>
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <TextField
                                      label="Categoría de Licencia"
                                      select
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      name="IdCategoriaLicencia"
                                      value={item.IdCategoriaLicencia}
                                      onChange={(event: any) =>
                                        handleFormChange(
                                          index,
                                          event,
                                          'IdCategoriaLicencia'
                                        )
                                      }
                                      required
                                    >
                                      {loadingTypeLicenseCategory ? (
                                        <CircularProgress
                                          variant="indeterminate"
                                          size={25}
                                        />
                                      ) : errorTypeLicenseCategory ? (
                                        <p>Error</p>
                                      ) : (
                                        dataTypeLicenseCategory?.getTypeLicenseCategory?.map(
                                          (item: any) => (
                                            <MenuItem
                                              key={item.idTipoCategoria}
                                              value={item.idTipoCategoria}
                                            >
                                              {item.description}
                                            </MenuItem>
                                          )
                                        )
                                      )}
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                      locale={ES}
                                    >
                                      <DatePicker
                                        autoOk
                                        label="Fecha de Vigencia"
                                        format="DD/MM/YYYY"
                                        variant="inline"
                                        inputVariant="outlined"
                                        fullWidth
                                        value={item.FechaVigencia}
                                        onChange={(event: any) =>
                                          handleFormChange(
                                            index,
                                            event,
                                            'FechaVigencia'
                                          )
                                        }
                                        labelFunc={() =>
                                          dayjs(item.FechaVigencia).format(
                                            'DD/MM/YYYY'
                                          )
                                        }
                                        TextFieldComponent={renderInput}
                                        maxDate={dayjs()}
                                        minDate={
                                          formikDriver.values.expeditionDate
                                        }
                                        required
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Grid>

                                  <Grid container item xs={2} spacing={2}>
                                    {licenseCategory.length <= 2 && (
                                      <Grid item>
                                        <Tooltip
                                          title="Agregar Categoría"
                                          arrow
                                        >
                                          <Fab
                                            color="default"
                                            className={classes.btnAddCategory}
                                            onClick={addFields}
                                          >
                                            <Box
                                              component="i"
                                              fontSize="20px"
                                              className="icon-plus"
                                            />
                                          </Fab>
                                        </Tooltip>
                                      </Grid>
                                    )}
                                    {licenseCategory.length > 1 && (
                                      <Grid item>
                                        <Tooltip
                                          title="Eliminar Categoría"
                                          arrow
                                        >
                                          <Fab
                                            className={
                                              classes.btnDeleteCategory
                                            }
                                            onClick={() => removeFields(index)}
                                          >
                                            <Box
                                              component="i"
                                              fontSize="20px"
                                              className="icon-close"
                                            />
                                          </Fab>
                                        </Tooltip>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grow>
                          ))}
                        </div>
                      </Grow>
                    )}

                    {stepFormDriver === 1 && (
                      <Grow in timeout={800}>
                        <div>
                          <Box marginBottom={3} marginTop={1}>
                            <MainTitle fontSize="h6">
                              Datos de Contacto{' '}
                              <span className={classes.fontInfoStep}>
                                (Paso {stepFormDriver + 1} de 3)
                              </span>
                            </MainTitle>
                          </Box>

                          <Box mb={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <PhoneNumber
                                  type="landline"
                                  extension
                                  names={{
                                    indicative: 'indicativePhone',
                                    phone: 'phone',
                                    ext: 'ext',
                                  }}
                                  values={{
                                    indicative:
                                      formikDriver.values.indicativePhone,
                                    phone: (formikDriver.values.phone =
                                      unmaskPhone(formikDriver.values.phone)),
                                    ext: formikDriver.values.ext,
                                  }}
                                  onChange={formikDriver.handleChange}
                                  errors={{
                                    indicative:
                                      !!formikDriver.errors.indicativePhone &&
                                      !!formikDriver.touched.indicativePhone,
                                    phone: formikDriver.errors.phone as string,
                                    ext: formikDriver.errors.ext as string,
                                  }}
                                  helperTexts={{
                                    indicative: formikDriver.errors
                                      .indicativePhone as string,
                                    phone: formikDriver.errors.phone as string,
                                    ext: formikDriver.errors.ext as string,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>

                          <Box mb={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <PhoneNumber
                                  type="mobile"
                                  names={{
                                    indicative: 'indicativeMobile',
                                    phone: 'mobile',
                                  }}
                                  values={{
                                    indicative:
                                      formikDriver.values.indicativeMobile,
                                    phone: (formikDriver.values.mobile =
                                      unmaskPhone(formikDriver.values.mobile)),
                                  }}
                                  onChange={formikDriver.handleChange}
                                  errors={{
                                    indicative:
                                      !!formikDriver.errors.indicativeMobile &&
                                      !!formikDriver.touched.indicativeMobile,
                                    phone: formikDriver.errors.mobile as string,
                                  }}
                                  helperTexts={{
                                    indicative: formikDriver.errors
                                      .indicativeMobile as string,
                                    phone: formikDriver.errors.mobile as string,
                                  }}
                                  required
                                />
                              </Grid>
                            </Grid>
                          </Box>

                          <Box mb={3}>
                            <Grid container>
                              <TextField
                                fullWidth
                                label="Correo Electrónico"
                                variant="outlined"
                                size="small"
                                type="email"
                                name="email"
                                value={formikDriver.values.email}
                                onChange={formikDriver.handleChange}
                                error={
                                  formikDriver.touched.email &&
                                  Boolean(formikDriver.errors.email)
                                }
                                helperText={
                                  formikDriver.touched.email &&
                                  formikDriver.errors.email
                                }
                                required
                              />
                            </Grid>
                          </Box>
                        </div>
                      </Grow>
                    )}

                    {stepFormDriver === 2 && (
                      <Grow in timeout={800}>
                        <div>
                          <Box marginBottom={3} marginTop={1}>
                            <MainTitle fontSize="h6">
                              Datos de Ubicación
                              <span className={classes.fontInfoStep}>
                                (Paso {stepFormDriver + 1} de 3)
                              </span>
                            </MainTitle>
                          </Box>

                          <Direction
                            names={{
                              directionType: 'directionType',
                              viaType: 'viaType',
                              viaNumber: 'viaNumber',
                              apendNumber: 'apendNumber',
                              cruceNumber: 'cruceNumber',
                              apendCruceNumber: 'apendCruceNumber',
                              cornerMeters: 'cornerMeters',
                              aditional: 'aditional',
                              postalCode: 'postalCode',
                              latitude: 'latitud',
                              longitude: 'longitud',
                              idPais: 'country',
                              idDepartamento: 'departament',
                              city: 'city',
                            }}
                            values={{
                              directionType: formikDriver.values.directionType,
                              viaType: formikDriver.values.viaType,
                              viaNumber: formikDriver.values.viaNumber,
                              apendNumber: formikDriver.values.apendNumber,
                              cruceNumber: formikDriver.values.cruceNumber,
                              apendCruceNumber:
                                formikDriver.values.apendCruceNumber,
                              cornerMeters: formikDriver.values.cornerMeters,
                              aditional: formikDriver.values.aditional,
                              postalCode: formikDriver.values.postalCode,
                              latitude: formikDriver.values.latitude,
                              longitude: formikDriver.values.longitude,
                              idPais: Number(formikDriver.values.country),
                              idDepartamento: Number(
                                formikDriver.values.departament
                              ),
                              city: Number(formikDriver.values.city),
                            }}
                            errors={{
                              directionType: formikDriver.errors.directionType,
                              viaType: formikDriver.errors.viaType,
                              viaNumber: formikDriver.errors.viaNumber,
                              apendNumber: formikDriver.errors.apendNumber,
                              cruceNumber: formikDriver.errors.cruceNumber,
                              apendCruceNumber:
                                formikDriver.errors.apendCruceNumber,
                              cornerMeters: formikDriver.errors.cornerMeters,
                              aditional: formikDriver.errors.aditional,
                              postalCode: formikDriver.errors.postalCode,
                              latitude: formikDriver.errors.latitude,
                              longitude: formikDriver.errors.longitude,
                              idPais: formikDriver.errors.country as string,
                              idDepartamento: formikDriver.errors
                                .departament as string,
                              city: formikDriver.errors.city as string,
                            }}
                            setFieldValue={formikDriver.setFieldValue}
                            handleChange={formikDriver.handleChange}
                            handleBlur={formikDriver.handleBlur}
                            touched={formikDriver.touched}
                          />
                        </div>
                      </Grow>
                    )}

                    <Grid
                      component={Box}
                      container
                      justify="center"
                      mb="15px"
                      mt="20px"
                    >
                      <Box mr={1}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleBack()}
                        >
                          Atrás
                        </Button>
                      </Box>

                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={stepFormDriver !== 2}
                        endIcon={
                          loadingDriver && (
                            <CircularProgress
                              className={classes.loadingColor}
                              thickness={5}
                              size={20}
                            />
                          )
                        }
                      >
                        {loadingDriver ? 'Guardando...' : 'Guardar'}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={1}
                  >
                    <Fab
                      color="primary"
                      type="submit"
                      className={clsx({
                        [classes.btnStep]: true,
                        [classes.hiddenButton]: stepFormDriver === 2,
                      })}
                    >
                      <Box
                        component="i"
                        fontSize="20px"
                        fontWeight="bold"
                        className="icon-arrow-right"
                      />
                    </Fab>
                  </Grid>
                </Grid>
              </form>
            )}
          </>
        )}
      </ContentCard>
      <DialogConfirmData
        open={openNotificationConfirm.open}
        icon={openNotificationConfirm.icon}
        title={openNotificationConfirm.title}
        message={openNotificationConfirm.message}
        handleAccept={handleNConfirm}
        handleClose={handleCloseNConfirm}
      />
    </>
  );
};
export default CreateCompany;
