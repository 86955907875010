import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import {
  Typography,
  Button,
  Divider,
  Tooltip,
  Box,
  Fab,
} from '@material-ui/core';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import domtoimage from 'dom-to-image';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import dayjs from 'dayjs';

import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import GobackLink from 'components/GobackLink';
import DialogPDFViewer from 'components/DialogPDFViewer/index';

import {
  ReportFilter,
  MyDocRangeSacrifice,
} from './../../Reports/ReportFilter';

import useStyles from './styles';
import Graphicpdfsf from 'components/pdf-components/ChartPDFSF';

const Search = () => {
  const classes = useStyles();
  const url = new URLSearchParams(window.location.search);
  const [type]: any = React.useState(url.get('type'));
  const [filter]: any = React.useState(url.get('filter'));
  const initialDate: any = React.useState(url.get('initialdate'));
  const finalDate: any = React.useState(url.get('finaldate'));
  const [openFilterDistribution, setOpenFilterDistribution] = useState(false);
  const [imageQr, setImageQr] = useState('');
  const [imageQrs, setImageQrs] = useState('');
  const [image, setImage] = React.useState<any[]>([]);

  const array = [
    [
      {
        label: 'Pulmón',
        data: 101,
        bg: '#ffc676',
      },
      {
        label: 'Piel',
        data: 6,
        bg: '#e29662',
      },
      {
        label: 'Carne',
        data: 6,
        bg: '#d47d57',
      },
      {
        label: 'Visceras Blancas',
        data: 2,
        bg: '#be5948',
      },
      {
        label: 'Visceras Rojas',
        data: 2,
        bg: '#b0413e',
      },
    ],
    [
      {
        label: 'Pulmón',
        data: 20,
        bg: '#ffc676',
      },
      {
        label: 'Piel',
        data: 20,
        bg: '#e29662',
      },
      {
        label: 'Carne',
        data: 20,
        bg: '#d47d57',
      },
      {
        label: 'Visceras Blancas',
        data: 20,
        bg: '#be5948',
      },
      {
        label: 'Visceras Rojas',
        data: 20,
        bg: '#b0413e',
      },
    ],
    [
      {
        label: 'Pulmón',
        data: 20,
        bg: '#ffc676',
      },
      {
        label: 'Piel',
        data: 20,
        bg: '#e29662',
      },
    ],
    [
      {
        label: 'Pulmón',
        data: 20,
        bg: '#ffc676',
      },
      {
        label: 'Piel',
        data: 20,
        bg: '#e29662',
      },
      {
        label: 'Visceras Blancas',
        data: 20,
        bg: '#be5948',
      },
      {
        label: 'Visceras Rojas',
        data: 20,
        bg: '#b0413e',
      },
    ],
  ];

  const testImage = (array: any) => {
    return (
      <div>
        {array.map((item: any, index: any) => (
          <div id={`test${index}`}>
            <Graphicpdfsf
              textTotalData="Partes Decomisadas"
              topTextTotalData={-440}
              fontNumberSizeTotal={50}
              fontSizeTotal={30}
              data={item}
            />
          </div>
        ))}
      </div>
    );
  };

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenQrDistribution');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        // console.log(dataUrl);
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const invitationP: any[] = [];
  const generateScreenshotTs = (array: any) => {
    array.map((item: any, index: any) => {
      var graph: any = document.getElementById(`test${index}`);
      domtoimage
        .toPng(graph)
        .then((dataUrl) => {
          // console.log(dataUrl);
          setImage((data) => [...data, { id: index, data: dataUrl }]);
        })
        .catch((error) => console.error('oops, something went wrong!', error));
    });
  };

  const openModalRangeAnteMortem = () => {
    setOpenFilterDistribution(true);
  };

  useEffect(() => {
    generateScreenshotTs(array);
  }, []);

  useEffect(() => {
    generateScreenshot();
    // console.log(invitationP);
  }, [openFilterDistribution]);

  const columns: GridColDef[] = [
    {
      field: 'list',
      headerName: 'Lista de Distribución',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'destinie',
      headerName: 'Destino',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'canalesEnviadas',
      headerName: 'Canales Enviadas',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'certificate',
      headerName: 'Nro. Certificado',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'news',
      headerName: 'Novedades',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: '',
      headerName: '',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/transport-sacrifice/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
    {
      id: 2,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
    {
      id: 3,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
    {
      id: 4,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
    {
      id: 5,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
    {
      id: 6,
      list: '22453-2',
      destinie: 'Nombre Completo del Destino',
      canalesEnviadas: '48',
      certificate: '22453-2',
      news: 'NO',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/transport-sacrifice',
      title: 'Distribución y Despacho',
    },

    { link: '/app/reports', title: 'Mis Reportes' },
  ];

  const getDateFilter = () => {
    switch (type) {
      case 'lote':
        return url.get('code');
      case 'canal':
        return url.get('code');
      case 'today':
        return dayjs().format('DD/MM/YYYY');
      case 'week':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      case 'range':
        return `${dayjs
          .unix(initialDate[0] / 1000)
          .format('DD/MM/YYYY')} - ${dayjs
          .unix(finalDate[0] / 1000)
          .format('DD/MM/YYYY')}`;
      default:
        return 'El tipo de filtro solicitado no existe';
    }
  };

  const getInfoFilter = () => {
    switch (type) {
      case 'lote':
        return (
          <>
            <Typography className={classes.filterType}>Lote:</Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{url.get('code')}</span>
            </Typography>
          </>
        );
      case 'canal':
        return (
          <>
            <Typography className={classes.filterType}>Canal:</Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{url.get('code')}</span>
            </Typography>
          </>
        );
      case 'today':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">
                {dayjs().format('dddd, D MMMM YYYY')} (Hoy)
              </span>
            </Typography>
          </>
        );
      case 'week':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              <span className="param">{'Semana' + ' ' + dayjs().week()}</span>{' '}
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      case 'range':
        return (
          <>
            <Typography className={classes.filterType}>
              Criterio de búsqueda:
            </Typography>
            <Typography className={classes.filterValue}>
              (Desde el{' '}
              <span className="param">
                {dayjs.unix(initialDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>{' '}
              hasta el{' '}
              <span className="param">
                {dayjs.unix(finalDate[0] / 1000).format('dddd, D MMMM YYYY')}
              </span>
              )
            </Typography>
          </>
        );
      default:
        return (
          <Typography className={classes.filterType}>
            El tipo de filtro solicitado no existe
          </Typography>
        );
    }
  };

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportFilter />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        {testImage(array)}
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard className={classes.contentCard}>
        <div className={classes.root}>
          <div className={classes.flex}>
            <Typography className={classes.title}>Búsqueda:</Typography>
            {!url.get('code') && (
              <Button
                variant="contained"
                color="primary"
                onClick={openModalRangeAnteMortem}
              >
                Generar Reporte
              </Button>
            )}
          </div>
          <div className={classes.flexContainer}>
            <div className={classes.flex}>
              <Typography className={classes.labelOrange}>
                {getInfoFilter()}
              </Typography>
            </div>
            <div className={clsx(classes.result, classes.flex)}>
              <LibraryBooksOutlinedIcon style={{ marginRight: 20 }} />
              <Typography>{'200 resultados'}</Typography>
            </div>
          </div>

          <Divider />

          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            className={classes.dataGrid}
            components={{
              Footer: TableFooter,
            }}
          />
        </div>
      </ContentCard>
      <DialogPDFViewer
        open={openFilterDistribution}
        onClose={() => setOpenFilterDistribution(false)}
        done={Boolean(imageQr)}
      >
        <MyDocRangeSacrifice
          qr={imageQr}
          graph={image.find((item) => item.id == 0)?.data}
          // arr={image}
          range={getDateFilter()}
        />
      </DialogPDFViewer>
    </div>
  );
};

export default Search;
