import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Fab,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import SelectDate from 'components/SelectDate';

import dayjs from 'dayjs';
import _ from 'lodash';
import clsx from 'clsx';

import useStyles from './styles';

interface EditAccessProps {
  children: any;
}

const EditAccess = ({ children }: EditAccessProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openConfir, setOpenConfir] = useState(false);
  const [openReschedule, setOpenReschedule] = useState(false);
  const [idUserDelete, setIdUserDelete] = useState(0);
  const [shareUnlimited, setShareUnlimited] = useState(false);
  const [initialDate, setInitialDate] = useState(dayjs().day(1));
  const [finalDate, setFinalDate] = useState(dayjs());
  const data = {
    name: 'Tiquete de Báscula',
    details: {
      'Lote Infoporcinos': 2040,
      'Nro de Reporte': 203050,
      'Fecha de Creación': dayjs().format('DD-MM-YYYY'),
    },
  };

  const [users, setUsers] = useState([
    {
      name: 'Leidy Marcela Álvarez',
      document: 'CC 1000295214',
      id: 4,
      profile: 'Comercializador',
      since: dayjs().format('DD-MM-YYYY'),
      until: dayjs().date(20).format('DD-MM-YYYY'),
    },
    {
      name: 'Juan Sebastian Izquierdo',
      document: 'CC 154782220',
      id: 5,
      profile: 'Productor',
      since: dayjs().format('DD-MM-YYYY'),
      until: dayjs().date(20).format('DD-MM-YYYY'),
    },
    {
      name: 'Sebastian Mosquera',
      document: 'CC 121478510',
      id: 6,
      profile: 'Comercializador',
      since: dayjs().format('DD-MM-YYYY'),
      until: dayjs().date(20).format('DD-MM-YYYY'),
    },
    {
      name: 'Paula Andrea Bolivar',
      document: 'CC 19876521',
      id: 7,
      profile: 'Productor - Comercializador',
      since: dayjs().format('DD-MM-YYYY'),
      until: dayjs().date(20).format('DD-MM-YYYY'),
    },
  ]);

  const removeUser = (e: number) => {
    const removed = users.filter((item: any) => item.id !== e);
    setUsers(removed);
    handleCloseConfir();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenConfir = (id: number) => {
    handleClose();
    setOpenConfir(true);
    setIdUserDelete(id);
  };
  const handleCloseConfir = () => {
    setOpenConfir(false);
    handleOpen();
  };

  const handleOpenReschedule = () => {
    handleClose();
    setOpenReschedule(true);
  };
  const handleCloseReschedule = () => {
    setOpenReschedule(false);
    handleOpen();
  };

  return (
    <>
      {children({ handleOpen })}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            Editar Usuarios con Acceso al reporte
          </Typography>
          <Typography color="secondary" align="center">
            Este reporte se compartió con los siguientes usuarios, a
            continuación puede editar el acceso del reporte
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <Grid
            container
            xs={12}
            spacing={2}
            className={classes.containerPrimaryInfo}
          >
            {_.map(data.details, (value, key) => (
              <Grid item xs={4}>
                <Box className={classes.primaryInfoContent}>
                  <Typography variant="subtitle1" className={classes.info}>
                    {key}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.info}>
                    {value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box width="100%" margin="12px 0px">
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Documento</TableCell>
                  <TableCell align="center">Compartido Desde</TableCell>
                  <TableCell align="center">Compartido Hasta</TableCell>
                  <TableCell>Reprogramar Acceso</TableCell>
                  <TableCell>Denegar Acceso</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((item: any) => {
                  return (
                    <TableRow className={classes.itemGrey}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.document}</TableCell>
                      <TableCell align="center">{item.since}</TableCell>
                      <TableCell align="center">{item.until}</TableCell>

                      <TableCell align="center">
                        <Fab
                          size="small"
                          color="primary"
                          onClick={handleOpenReschedule}
                        >
                          <i
                            className="icon-calendar"
                            style={{ fontSize: '20px' }}
                          />
                        </Fab>
                      </TableCell>
                      <TableCell align="center">
                        <Fab
                          size="small"
                          color="primary"
                          onClick={() => handleOpenConfir(item.id)}
                        >
                          <i
                            className="icon-close"
                            style={{ fontSize: '20px' }}
                          />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfir}
        onClose={handleCloseConfir}
        className={classes.dialogContainer}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.contentDialog}>
          <i className={clsx('icon-user-alert', classes.iconDialogCheck)} />
          <Typography variant="h6">
            {users
              .filter((item: any) => item.id == idUserDelete)
              .map((user: any) => user.name)}
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            ¿Quiere dejar de compartir el reporte{' '}
            {data.details['Nro de Reporte']} con este usuario?
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            onClick={handleCloseConfir}
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => removeUser(idUserDelete)}
            variant="contained"
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openReschedule}
        onClose={handleCloseReschedule}
        className={classes.dialogContainer}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h6" color="secondary" align="center">
            Reprogramar fecha final de Acceso al reporte
          </Typography>
          <Typography color="secondary" align="center">
            A continuación tiene la opción de definir un tiempo limite para dar
            acceso a los usuarios seleccionados para ver el reporte, si no
            define un tiempo limite, el reporte se compartirá de manera
            indefinida.
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <Box width="100%" marginTop={2}>
            <Grid
              container
              xs={12}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={4}>
                <SelectDate
                  value={initialDate}
                  label="Fecha Inicial"
                  onChange={(e: any) => setInitialDate(e)}
                  disabled={true}
                />
              </Grid>
              <Grid container item xs={1} justifyContent="center">
                Hasta
              </Grid>
              <Grid item xs={4}>
                <SelectDate
                  label="Fecha Final"
                  onChange={(e: any) => setFinalDate(e)}
                  disabled={shareUnlimited}
                  limitDate={initialDate}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={2}>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  value={shareUnlimited}
                  name="promotion"
                  color="primary"
                  onChange={() => setShareUnlimited(!shareUnlimited)}
                />
              }
              label="Compartir de manera indefinida"
              labelPlacement="start"
            />
          </Box>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogFooter }}>
          <Button
            onClick={handleCloseReschedule}
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleCloseReschedule}
            variant="contained"
            color="primary"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditAccess;
