import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  Typography,
  Button,
  Box,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import Breadcrumb, { BreadcrumbProps } from "components/BreadcrumbBuilder";
import ContentCard from "components/ContentCard";
import TableFooter from "components/datagrid-custom-components/TableFooter";
import TableLoading from "components/datagrid-custom-components/TableLoading";
import TableOverlay from "components/datagrid-custom-components/TableOverlay";
import GobackLink from "components/GobackLink";
import DialogPDFViewer from "components/DialogPDFViewer/index";
import Results from "components/Results";
import domtoimage from "dom-to-image";
import {
  MyDocUserActivity,
  ReportUserActivity,
} from "./Reports/ReportUserActivity";
import { GET_INTERNAL_USER } from "core/graphql/queries/getInternalUser";
import { ACTIVITY_USERS } from "core/graphql/queries/getActivityUsers";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import {
  MyDocUsersActivities,
  ReportUsersActivities,
} from "./Reports/ReportUsersActivities";
import useStyles from "./styles";

const DetailsActivity = () => {
  const classes = useStyles();
  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: "/app/users/details/activity/1", title: "Actividad Usuario" },
    { link: "/app/users", title: "Mis usuarios" },
  ];
  const [openReportUserActivity, setReportUserActivity] = useState(false);
  const [openReportUsersActivities, setReportUsersActivities] = useState(false);
  const [qrUser, setQrUser] = useState("a");
  const [qrUsers, setQrUsers] = useState("a");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const params = useParams<{
    id: string;
    initialDate: string;
    finalDate: string;
    type: string;
  }>();
  const token = useSelector((state: any) => state.user.token);
  const [initialValue, setInitialValue] = useState(
    parseInt(params.initialDate)
  );
  const [finalValue, setFinalValue] = useState(parseInt(params.finalDate));
  const [filterType, setFilterType] = useState(params.type);

  const { loading, error, data } = useQuery(GET_INTERNAL_USER, {
    context: {
      headers: { "x-auth-jwt": token },
    },
    variables: {
      uid: Number(params.id),
      idUserType: useSelector(
        (state: any) => state.user.selectedUserType.idUserType
      ),
    },
  });

  const userData = {
    idUser: data?.getInternalUser?.data?.idUsuario,
    userName: data?.getInternalUser?.data?.username
      ? `@${data?.getInternalUser?.data?.username}`
      : undefined,
    name: data?.getInternalUser?.data?.primerNombre
      ? data?.getInternalUser?.data?.primerNombre +
        " " +
        data?.getInternalUser?.data?.segundoNombre +
        " " +
        data?.getInternalUser?.data?.primerApellido +
        " " +
        data?.getInternalUser?.data?.segundoApellido
      : undefined,
    phone: data?.getInternalUser?.data?.celular,
    email: data?.getInternalUser?.data?.email,
    position: data?.getInternalUser?.data?.cargo,
    RegistrationDate: data?.getInternalUser?.data?.fechaRegistro,
    rol: data?.getInternalUser?.data?.rol,
  };

  const {
    loading: loadingAc,
    error: errorAc,
    data: dataAc,
  } = useQuery(ACTIVITY_USERS, {
    context: {
      headers: { "x-auth-jwt": token },
    },
    variables: {
      uid: Number(params.id),
      idUserType: useSelector(
        (state: any) => state.user.selectedUserType.idUserType
      ),
      fechaInicial: `${dayjs(initialValue).format("YYYY/MM/DD HH:mm:ss")}`,
      fechaFinal: `${dayjs(finalValue).format("YYYY/MM/DD HH:mm:ss")}`,
    },
  });
  const activity = dataAc?.getActivityUsers?.data;

  const activityData = {
    activityDone: dataAc?.getActivityUsers?.data?.length,
    data: dataAc?.getActivityUsers?.data,
    lastDate: dataAc?.getActivityUsers?.data[activity?.length - 1]?.fechaFinal,
    lastSection: dataAc?.getActivityUsers?.data[activity?.length - 1]?.registro,
  };

  const usuarios: any[] = [];
  activity?.map((item: any) => usuarios.push(item.registro));

  const activities: any[] = [];
  activity?.map((act: any, index: any) => {
    activities.push({
      id: index,
      usuario: act?.usuario ?? "N/D",
      rol: act?.rol ?? "N/D",
      fechaInicial: act?.fechaInicial
        ? dayjs(act?.fechaInicial).format("DD/MM/YYYY HH:mm:ss")
        : "N/D",
      fechaFinal: act?.fechaFinal
        ? dayjs(act?.fechaFinal).format("DD/MM/YYYY HH:mm:ss")
        : "N/D",
      hora: act?.tiempoTotal ?? "N/D",
      descripcion: act?.registro ?? "N/D",
    });
  });

  const columns: GridColDef[] = [
    {
      field: "usuario",
      headerName: "Usuario",
      sortable: false,
      width: 150,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: false,
      hide: params.id === "0" ? false : true,
    },
    {
      field: "rol",
      headerName: "Rol",
      sortable: false,
      headerClassName: classes.headerTableGrid,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      hide: params.id === "0" ? false : true,
    },
    {
      field: "fechaInicial",
      headerName: "Fecha Inicial",
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fechaFinal",
      headerName: "Fecha Final",
      sortable: false,
      headerClassName: classes.headerTableGrid,
      width: 150,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "hora",
      headerName: "Tiempo de conexión",
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 180,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "descripcion",
      headerName: "Registro",
      disableColumnMenu: true,
      headerClassName: classes.headerTableGrid,
      width: 200,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
  ];

  const generateScreenshotQrUser = () => {
    const qrCode: any = document.getElementById("screenUserActivity");
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        setQrUser(dataUrl);
      })
      .catch((error) => console.error("oops, something went wrong!", error));
  };

  const generateScreenshotQrUsers = () => {
    const qrCode: any = document.getElementById("screenUsersActivities");
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        setQrUsers(dataUrl);
      })
      .catch((error) => console.error("oops, something went wrong!", error));
  };

  const openUserActivitiesUser = () => {
    generateScreenshotQrUser();
    setReportUserActivity(true);
  };

  const openUserActivitiesUsers = () => {
    generateScreenshotQrUsers();
    setReportUsersActivities(true);
  };

  const filterBy = () => {
    if (filterType === "today")
      return (
        <Typography variant="subtitle1" className={classes.filterType}>
          {`${dayjs().format("DD/MM/YYYY")} (Hoy)`}
        </Typography>
      );
    else if (filterType === "thisweek")
      return (
        <Typography variant="subtitle1" className={classes.filterType}>
          {dayjs(initialValue).format("DD/MM/YYYY")}{" "}
          <span className={classes.textUsersGray}>hasta</span>{" "}
          {dayjs(finalValue).format("DD/MM/YYYY")} <span>(Semana Actual)</span>
        </Typography>
      );
    return (
      <Typography variant="subtitle1" className={classes.filterType}>
        {dayjs(initialValue).format("DD/MM/YYYY")}{" "}
        <span className={classes.textUsersGray}>hasta</span>{" "}
        {dayjs(finalValue).format("DD/MM/YYYY")} <span>(Mes Actual)</span>
      </Typography>
    );
  };

  const filters = [
    {
      id: 1,
      filter: "today",
      name: "Hoy",
    },
    {
      id: 2,
      filter: "thisweek",
      name: "Semana Actual",
    },
    {
      id: 3,
      filter: "thismonth",
      name: "Mes Actual",
    },
  ];

  const handleFilter = (event: any) => {
    setFilterType(event.target.value);
  };
  useEffect(() => {
    if (filterType === "today") {
      setInitialValue(dayjs().startOf("d").valueOf());
      setFinalValue(dayjs().endOf("d").valueOf());
    } else if (filterType === "thisweek") {
      setInitialValue(dayjs().startOf("w").valueOf());
      setFinalValue(dayjs().valueOf());
    } else {
      setInitialValue(dayjs().startOf("M").valueOf());
      setFinalValue(dayjs().valueOf());
    }
  }, [filterType]);

  return (
    <div>
      <Box position="absolute" left="-1000%">
        {params.id === "0" ? <ReportUsersActivities /> : <ReportUserActivity />}
      </Box>
      <div className={classes.generalContainer}>
        <div className={classes.breadContainer}>
          <GobackLink />
          <Breadcrumb breadcrumbs={pageBreadcrumbs} />
        </div>
        <ContentCard>
          <div className={classes.contentTableHeader}>
            {params.id !== "0" ? (
              <Box display="flex">
                <Box mr={2}>
                  <Typography variant="h4" className={classes.titleHeaderTable}>
                    Registro de actividad del usuario:
                    <span className={classes.fontUserName}>
                      {userData.userName}
                    </span>
                  </Typography>
                  <Box display="flex">
                    <Typography
                      variant="subtitle1"
                      className={classes.textUsersGray}
                    >
                      Criterio de búsqueda:
                    </Typography>
                    {filterBy()}
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.textBold}
                    onClick={openUserActivitiesUser}
                  >
                    Generar Reporte
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box display="flex">
                <Box mr={2}>
                  <Typography variant="h4" className={classes.titleHeaderTable}>
                    Registro de actividad de mis usuarios
                  </Typography>
                  <Box display="flex">
                    <Typography
                      variant="subtitle1"
                      className={classes.textUsersGray}
                    >
                      Criterio de búsqueda:
                    </Typography>
                    {filterBy()}
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.textBold}
                    onClick={openUserActivitiesUsers}
                  >
                    Generar Reporte
                  </Button>
                </Box>
              </Box>
            )}
            <div className={classes.contentHeaderChild1}>
              <Results number={activity?.length} />
              <Box width={200}>
                <TextField
                  label="Filtrar por"
                  size="small"
                  variant="outlined"
                  select
                  fullWidth
                  value={filterType}
                  onChange={handleFilter}
                >
                  {filters.map((filter: any) => (
                    <MenuItem key={filter.filter} value={filter.filter}>
                      <Typography>{filter.name}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </div>
          </div>
          <Box minHeight={550}>
            <DataGrid
              rows={activities}
              columns={columns}
              className={
                activities.length === 0 ? classes.noData : classes.dataGrid
              }
              pageSize={pageSize}
              rowsPerPageOptions={[10, 15, 20, 25]}
              page={page}
              onPageSizeChange={(e: any) => setPageSize(e.pageSize)}
              onPageChange={(e: any) => setPage(e.page)}
              components={{
                Footer: TableFooter,
                NoRowsOverlay: TableOverlay,
                LoadingOverlay: TableLoading,
              }}
              autoHeight
              loading={loadingAc}
              hideFooter={activities.length === 0}
            />
          </Box>
        </ContentCard>
        <DialogPDFViewer
          open={openReportUserActivity}
          onClose={() => setReportUserActivity(false)}
          done={Boolean(qrUser)}
        >
          <MyDocUserActivity
            qr={qrUser}
            dataUser={userData}
            dataActivity={activityData}
          />
        </DialogPDFViewer>
        <DialogPDFViewer
          open={openReportUsersActivities}
          onClose={() => setReportUsersActivities(false)}
          done={Boolean(qrUsers)}
        >
          <MyDocUsersActivities
            qr={qrUsers}
            dataActivity={activityData}
            totalUsuarios={[...new Set(usuarios)].length}
          />
        </DialogPDFViewer>
      </div>
    </div>
  );
};

export default DetailsActivity;
