import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Fullscreen, FullscreenExit } from '@material-ui/icons';
import { PDFViewer } from '@react-pdf/renderer';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './styles';

interface DialogPDFViewerProps {
  children: any;
  open: boolean;
  onClose: (value: any) => void;
  done: boolean;
}

const DialogPDFViewer = ({
  children,
  open = false,
  onClose = () => {},
  done = true,
}: DialogPDFViewerProps) => {
  const classes = useStyles();
  const [fullScreen, setFullScreen] = useState(false);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      classes={{
        container: clsx({
          [classes.dialogContainer]: true,
          [classes.dialogContainerFullScreen]: fullScreen,
        }),
        paper: clsx({
          [classes.dialogPaper]: true,
          [classes.dialogFullScreen]: fullScreen,
        }),
      }}
    >
      <DialogTitle
        classes={{
          root: clsx({
            [classes.dialogTitle]: true,
            [classes.dialogTitleFullScreen]: fullScreen,
          }),
        }}
      >
        <span>Previsualizar reporte</span>
        <IconButton onClick={() => setFullScreen(!fullScreen)}>
          {fullScreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {done && (
          <PDFViewer className={classes.pdfViewer}>{children}</PDFViewer>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={clsx(classes.closeBtn, 'raleway')}
          onClick={(e) => {
            onClose(e);
            setFullScreen(false);
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogPDFViewer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DialogPDFViewer;
