import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Grid,
  Avatar,
  Chip,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import clsx from 'clsx';
import domtoimage from 'dom-to-image';

import GobackLink from 'components/GobackLink';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import ItemListData from 'components/ItemListData';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import LevelIndicator from 'components/LevelIndicator';
import DialogPDFViewer from 'components/DialogPDFViewer';

import { ReportLot, MyDocCorralLote } from '../Reports/ReportLot';

import useStyles from './styles';

const Details = () => {
  const classes = useStyles();
  const [openReportLot, setOpenReportLot] = useState(false);
  const [imageQr, setImageQr] = useState('a');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenqrcorrallote');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalLotCorral = () => {
    setOpenReportLot(true);
  };

  useEffect(() => {
    generateScreenshot();
  }, []);

  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: 'corral/details/1', title: 'Inventario Corrales' },
  ];

  const rows = [
    {
      id: 1,
      totalAnimals: 27,
      corralType: 'Reposo',
      corralNumber: 'CR10',
      dieCorral: 0,
      process: true,
    },
    {
      id: 2,
      totalAnimals: 4,
      corralType: 'Observación',
      corralNumber: 'CO',
      dieCorral: 0,
      process: false,
    },
  ];
  const columns: GridColDef[] = [
    {
      field: 'totalAnimals',
      headerName: 'Total Animales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'corralType',
      headerName: 'Tipo Corral',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'corralNumber',
      headerName: 'Nro. Corral',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'dieCorral',
      headerName: 'Muertos Corrales',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'hours',
      headerName: 'Horas en Corrales',
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: () => (
        <LevelIndicator value={11} levels={[[4], [4, 8], [8, 12], [12]]} />
      ),
    },
    {
      field: 'process',
      headerName: 'Proceso',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridValueGetterParams) =>
        params.getValue(params.id, 'process') === true ? (
          <Chip
            size="small"
            label="Finalizado"
            className={classes.finishedProcess}
          />
        ) : (
          <Chip
            size="small"
            label="En progreso"
            className={classes.inProcess}
          />
        ),
    },
  ];

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard>
        <div className={classes.headerContainer}>
          <Typography variant="h6" className={classes.headerTitle}>
            Inventario del lote en Corrales
          </Typography>
          <List className={classes.headerListContainer}>
            <ListItem className={classes.headerListItem}>
              <Typography className={classes.titleListItem}>
                Lote Inforporcinos
              </Typography>
              <Typography className={classes.infoListItem}>6700</Typography>
            </ListItem>
            <ListItem className={classes.headerListItem}>
              <Typography className={classes.titleListItem}>
                Reporte Nro.
              </Typography>
              <Typography className={classes.infoListItem}>
                011-65432
              </Typography>
            </ListItem>
            <ListItem className={classes.headerListItem}>
              <Typography className={classes.titleListItem}>Fecha</Typography>
              <Typography className={classes.infoListItem}>
                22/06/2021
              </Typography>
            </ListItem>
          </List>
        </div>
        <div>
          <Grid container spacing={2} justify="center">
            <Grid item xs={6}>
              <div className={classes.infoContainer}>
                <Typography variant="h6" className={classes.title}>
                  Agentes Relacionados
                </Typography>
                <div className={classes.listContainer}>
                  <ItemListData
                    data={[
                      {
                        title: 'Productor',
                        data: 'Nombre del productor',
                      },
                      {
                        title: 'Granja',
                        data: 'Nombre de la granja',
                      },
                      {
                        title: 'Planta de Sacrificio',
                        data: 'Nombre de la Planta de Sacrificio',
                      },
                      {
                        title: 'Responsable de Corrales',
                        data: 'Nombre del Responsable',
                      },
                      {
                        title: 'Médico Veterinario',
                        data: 'Nombre del Médico Veterinario',
                      },
                    ]}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.infoContainer}>
                <Typography variant="h6" className={classes.title}>
                  Resumen del lote
                </Typography>
                <div className={classes.listContainer}>
                  <ItemListData
                    data={[
                      {
                        title: 'Ingreso plata de Beneficio',
                        data: '22/06/2021 - 09:00:00',
                      },
                      {
                        title: 'Ingreso a Corrales',
                        data: '22/06/2021 - 09:00:00',
                      },
                    ]}
                  />
                </div>
                <div
                  className={clsx(
                    classes.infoLoteInfo,
                    classes.importantInfoContainer
                  )}
                >
                  <ItemTotalInfo
                    avatarTotalInfo={58}
                    titleInfo="Total Animales"
                    avatarTotalColor="#E29662"
                    position="bottom"
                  />
                  <ItemTotalInfo
                    avatarTotalInfo={54}
                    titleInfo="En Reposo"
                    avatarTotalColor="#F1AE6C"
                    position="bottom"
                  />
                  <ItemTotalInfo
                    avatarTotalInfo={4}
                    titleInfo="En Observación"
                    avatarTotalColor="#FFC676"
                    position="bottom"
                  />
                </div>
              </div>
            </Grid>
            <Grid item container spacing={2} xs={12} alignItems="stretch">
              <Grid item container xs={7} alignItems="stretch">
                <Grid
                  item
                  spacing={2}
                  alignItems="center"
                  className={classes.importantInfoContainer}
                >
                  <div>
                    <div className={classes.stateAnimals}>
                      <i
                        className={clsx('icon-pig-right', classes.stateIcon)}
                      />
                      <Typography className={classes.stateTitle}>
                        Estado de los animales
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.listContainerWrap}>
                    <ItemListData
                      data={[
                        {
                          title: 'Animales Caidos',
                          data: 2,
                        },
                        {
                          title: 'Muertos Trasnporte',
                          data: 2,
                        },
                        {
                          title: 'Animales Agitados',
                          data: 2,
                        },
                        {
                          title: 'Muertos Desembarque',
                          data: 2,
                        },
                      ]}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item container xs={5} alignItems="stretch">
                <Grid
                  item
                  xs={12}
                  className={clsx(
                    classes.importantInfoContainer,
                    classes.stateChild2
                  )}
                >
                  <ItemTotalInfo
                    position="right"
                    titleInfo="Total Animales enviados a Sacrificio de Emergencia"
                    avatarTotalInfo={0}
                    avatarTotalColor="#E29662"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography variant="h6" className={classes.title}>
                  Detalle del Inventario Animales en Pie
                </Typography>
              </div>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                components={{
                  Footer: TableFooter,
                }}
                className={classes.dataGrid}
              />
            </Grid>
          </Grid>
        </div>
      </ContentCard>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography className={classes.options}>
          Opciones del reporte
        </Typography>
        <Button
          startIcon={<i className="icon-eye" />}
          color="primary"
          variant="contained"
          onClick={openModalLotCorral}
        >
          Previsualizar
        </Button>
      </Box>
      <DialogPDFViewer
        open={openReportLot}
        onClose={() => setOpenReportLot(false)}
        done={Boolean(imageQr)}
      >
        <MyDocCorralLote qr={imageQr} />
      </DialogPDFViewer>
    </div>
  );
};
export default Details;
