import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core';

import AvatarIconInfo from 'components/AvatarIconInfo';
import ItemListData from 'components/ItemListData';
import ItemTotalInfo from 'components/ItemTotalInfo';
import ItemListInfo from 'components/ItemListInfo';
import LevelIndicator from 'components/LevelIndicator';

import useStyles from './styles';

interface ModalCRProps {
  openCR: boolean;
  closeCR: () => void;
}

const ModalCR = ({ openCR, closeCR }: ModalCRProps) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={openCR}
        onClose={closeCR}
        maxWidth="sm"
        fullWidth
        className={classes.dialogContainer}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <div className={classes.titleContainer}>
          <AvatarIconInfo
            text="Inventario del corral Reposo"
            icon="icon-corral"
            iconSize={32}
            avatarColor="#DD9835"
            textBold
            avatarSize={50}
          />
        </div>
        <DialogContent>
          <div className={classes.subtitleInfo}>
            <ItemListData
              data={[
                {
                  title: 'Nro. Corral',
                  data: 'CR12',
                  colorData: '#767676',
                },
                {
                  title: 'Lote IP',
                  data: '3421',
                  colorData: '#767676',
                },
                {
                  title: 'Tiempo Corrales',
                  data: '18:00:00',
                  colorData: '#DD9835',
                },
              ]}
              direction="row"
              withoutListStyle
            />
          </div>
          <div className={classes.generalInfoContainer}>
            <ItemTotalInfo
              position="right"
              avatarTotalInfo={26}
              avatarTotalColor="#FFC676"
              titleInfo="Total Animales en el corral"
              titleInfoSize={200}
            />
            <div className={classes.listTotalContainer}>
              <ItemListInfo
                icon="icon-pig-right"
                titleInfo="Peso total"
                numberInfo="2.663,96 Kg"
              />
              <ItemListInfo
                icon="icon-weight"
                titleInfo="Peso promedio"
                numberInfo="102,65 Kg"
              />
            </div>
          </div>
          <div>
            <div className={classes.timeContainer}>
              <ItemListData
                withoutListStyle
                data={[
                  {
                    title: 'Ingreso Corrales',
                    data: '5/12/2019 09:00:00',
                  },
                ]}
              />
              <div className={classes.hoursContainer}>
                <Typography>Horas en corrales</Typography>
                <div className={classes.levelContianer}>
                  <LevelIndicator
                    value={11}
                    levels={[[4], [4, 8], [8, 12], [12]]}
                  />
                </div>
              </div>
            </div>
            <div className={classes.specificInfoContainer}>
              <div className={classes.totalInfo}>
                <ItemListData
                  withoutListStyle
                  data={[
                    {
                      title: 'Total Animales Lote',
                      data: 53,
                      colorData: '#DD9835',
                    },
                    {
                      title: 'Animales en Reposo',
                      data: 24,
                    },
                    {
                      title: 'Animales en Observación',
                      data: 3,
                    },
                  ]}
                />
              </div>
              <div className={classes.specificInfoItem}>
                <Typography className={classes.specificTitle}>
                  Distribución en corrales
                </Typography>
                <ItemListData
                  withoutListStyle
                  data={[
                    {
                      title: 'Corrales Reposo',
                      data: 'CR12,CR13',
                    },
                    {
                      title: 'Corrales Observación',
                      data: 'CO4',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button variant="contained" color="primary" onClick={closeCR}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalCR;
