import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5%',
    paddingTop: '2.5%',
    maxWidth: 'inherit',
  },

  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  breadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 0',
  },

  contentCard: {
    width: '100%',
  },

  headerContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  chartContainer: {
    padding: '30px 10px',
  },

  spacingTf: {
    margin: '0px 20px',
  },

  paperTitles: {
    fontSize: 20,
    fontWeight: 'bolder',
    color: '#767676',
  },

  titlePage: {
    color: '#767676',
    fontWeight: 'bolder',
    fontSize: 30,
  },

  descriptionPage: {
    fontSize: 16,
    fontWeight: 'lighter',
    color: '#AFAFAF',
  },

  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  marginTop: {
    margin: '10px 0px',
  },

  iconResume: {
    fontSize: 30,
    padding: 4,
    color: 'white',
    background: theme.palette.primary.main,
    borderRadius: '100%',
    marginRight: 20,
  },

  icon: {
    fontSize: 30,
    padding: 5,
    color: 'white',
    background: theme.palette.primary.main,
    borderRadius: '100%',
    margin: 'auto 10px',
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  width: {
    width: '55%',
  },

  evenlyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  paddingContainer: {
    padding: '10px 0px 0px 0px',
  },

  viewButton: {
    width: '35px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover': {
      color: '#fff',
    },
  },

  saveButton: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: '#DD9835',
    borderRadius: 25,
    margin: '0px 10px 0px 10px',
    padding: '5px 30px 5px 30px',
    '&:hover': {
      backgroundColor: '#dd9835',
    },
  },

  dataGrid: {
    border: 'none',
    display: 'flex',
    padding: '20px 45px',

    '& .MuiBox-root': {
      margin: '20px 0',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      lineHeight: 'normal',
      color: '#AFAFAF',
      fontWeight: 'bold',
      fontSize: 14,
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 1,
    },
  },
}));

export default useStyles;
