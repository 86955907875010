import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import useStyles from './styles';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import clsx from 'clsx';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import LevelIndicator from 'components/LevelIndicator';
import ItemTotalInfo from 'components/ItemTotalInfo';
import GobackLink from 'components/GobackLink';
import TableInfo from 'components/TableInfo';

const index = () => {
  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'canalID',
      headerName: 'Canal ID',
      flex: 1,

      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'intmed',
      headerName: 'Int. Med.',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'SEUROP',
      headerName: 'SEUROP',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'pcc',
      headerName: 'PCC (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'gd',
      headerName: 'GD (m.m.)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ml',
      headerName: 'ML (m.m)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mc',
      headerName: 'MC (%)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mckg',
      headerName: 'MC (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'PSE',
      headerName: 'Indicio (PSE)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'color',
      headerName: 'Color',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <div
          style={{
            display: 'flex',

            alignItems: 'center',
          }}
        >
          <div style={{ marginRight: 15 }}>
            <svg height="15" width="15">
              <circle fill="#B0413E" r="5" cx="10" cy="10"></circle>
            </svg>
          </div>
          <div>{params.value}</div>
        </div>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      canalID: '1234',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
    },
    {
      id: 2,
      canalID: '1234',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
    },
    {
      id: 3,
      canalID: '1234',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
    },
    {
      id: 4,
      canalID: '1234',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
    },
    {
      id: 5,
      canalID: '1234',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
    },
    {
      id: 6,
      canalID: '1234',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
    },
    {
      id: 7,
      canalID: '1234',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
    },
    {
      id: 8,
      canalID: '1234',
      SEUROP: 'E',
      pcc: '100,2',
      intmed: 'GP4',
      gd: '12,4',
      ml: '53,2',
      mc: '70,4',
      mckg: '70,4',
      PSE: '.',
      color: '00,00',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/inventory/cava/details/lote', title: 'Inventario Cavas' },
  ];

  return (
    <div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard className={classes.contentCard}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={12} lg={7} xl={7}>
            <Typography className={classes.mainTitle}>
              Inventario de Canales Refrigeradas
            </Typography>
          </Grid>
          <Grid item sm={6} md={3} lg={3} xl={3}>
            <div
              className={clsx(classes.grayContainer, classes.subtitleContainer)}
            >
              <Typography className={classes.subtitle1}>
                Lote Infoporcinos
              </Typography>
              <Typography className={classes.subtitle2}>6700</Typography>
            </div>
          </Grid>
          <Grid item sm={6} md={2} lg={2} xl={2}>
            <div
              className={clsx(classes.grayContainer, classes.subtitleContainer)}
            >
              <Typography className={classes.subtitle1}>Fecha</Typography>
              <Typography className={classes.subtitle2}>
                {'22/01/2019'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              className={clsx(classes.grayContainer, classes.titleContainer)}
            >
              <Typography className={classes.secondTitle}>
                Agentes Relacionados
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} lg={4} xl={4}>
            <TableInfo
              icon="icon-productor"
              title="Productor"
              items={{
                Nombre: 'Cualquier productor',
                NIT: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} xl={4}>
            <TableInfo
              icon="icon-planta"
              title="Planta Beneficio"
              items={{
                Nombre: 'Nombre Planta',
                NIT: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@planta.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} xl={4}>
            <TableInfo
              icon="icon-granja"
              title="Granja"
              items={{
                Nombre: 'Nombre Planta',
                Responsable: '45134132',
                Teléfono: '+57 314 6543 543',
                Email: 'info@productor.com',
                Dirección: 'Calle 105 A 23-23',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              className={clsx(classes.grayContainer, classes.titleContainer)}
            >
              <Typography className={classes.secondTitle}>
                Resumen del Lote
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} lg={5} xl={5}>
            <div className={classes.grayContainer}>
              <div
                className={clsx(
                  classes.columnContainer,
                  classes.widthContainer
                )}
              >
                <Box mb={1.3}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={58}
                    titleInfo="Total Canales"
                    avatarTotalColor="#BE5948"
                    avatarTotalSize={60}
                  />
                </Box>

                <Box mb={1.3}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={0}
                    titleInfo="Canales Despachadas"
                    avatarTotalColor="#FFC676"
                    avatarTotalSize={60}
                  />
                </Box>
              </div>
              <div className={classes.columnContainerFlexStart}>
                <div className={classes.itemContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={'54,3 %'}
                    titleInfo="Promedio MC%"
                    avatarTotalColor="#E29662"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                  />
                </div>

                <div className={classes.itemContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={'64,67 m.m.'}
                    titleInfo="Promedio LM"
                    avatarTotalColor="#F1AE6C"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                  />
                </div>

                <div className={classes.itemContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={'16,8 m.m.'}
                    titleInfo="Promedio GD"
                    avatarTotalColor="#FFC676"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                  />
                </div>

                <div className={classes.itemContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={'101,67 Kg'}
                    titleInfo="Promedio PCC"
                    avatarTotalColor="#D47D57"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={7} xl={7}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px 15px',
              }}
            >
              <div className={classes.columnContainerFlexStart}>
                <div className={classes.itemContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={'7,320.00 Kg'}
                    titleInfo="Peso Total Animales en Pie"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInverted
                    titleInfoSize={180}
                  />
                </div>
                <div className={classes.itemContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={'4.013,65 Kg'}
                    titleInfo="Total Carne Magra"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInverted
                    titleInfoSize={180}
                  />
                </div>
              </div>
              <div className={classes.columnContainerFlexStart}>
                <div className={classes.itemContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={'6.891,00 Kg'}
                    titleInfo="Peso Total Canales Calientes"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInverted
                    titleInfoSize={180}
                  />
                </div>
                <div className={classes.itemContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={'89,76%'}
                    titleInfo="Rendimiento Pie / Canal"
                    avatarTotalColor="#F2F2F2"
                    avatarTotalSize={35}
                    avatarTextSize={16}
                    extended
                    titleInverted
                    titleInfoSize={180}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className={clsx(classes.grayContainer, classes.titleContainer)}
              >
                <Typography className={classes.secondTitle}>
                  Tiempo En Cavas
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px 15px',
                }}
              >
                <div
                  className={clsx(
                    classes.columnContainer,
                    classes.widthContainer2
                  )}
                >
                  <Typography className={classes.label}>
                    Fecha Hora de Ingreso Cavas
                  </Typography>
                  <Typography className={classes.labelBold}>
                    01/01/2021 2:00:00 p.m.
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.flexContainer,
                    classes.widthContainer3
                  )}
                >
                  <Typography
                    className={clsx(classes.labelBold, classes.widthContainer4)}
                  >
                    Horas en Cavas
                  </Typography>
                  <LevelIndicator
                    value={11}
                    levels={[[0, 12], [12, 24], [24, 36], [36]]}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div
              className={clsx(classes.grayContainer, classes.titleContainer)}
            >
              <Typography className={classes.secondTitle}>
                Detalle Canales en Cavas
              </Typography>
            </div>
            <Box width="100%">
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </ContentCard>
    </div>
  );
};

export default index;
