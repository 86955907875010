import React, { useState, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Grid,
  FormControlLabel,
  Collapse,
  Checkbox,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import { ALL_NAVIGATION } from "core/graphql/queries/allNavigation";
import { EDIT_ROLE } from "core/graphql/mutations/settings";
import validations from "lib/validations/messages";

import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as yup from "yup";
import _ from "lodash";

import useStyles from "./styles";

interface EditRolProps {
  children: any;
  idRole: number;
  rolename: string;
  uneditable?: boolean;
}

const EditRol = ({ children, idRole, rolename, uneditable }: EditRolProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [permissions, setPermissions] = useState<any>([]);
  const options: any[] = [];

  const [EditRole, { loading: editRolLoading }] = useMutation(EDIT_ROLE, {
    context: { headers: { "x-auth-jwt": token } },
  });

  const { loading, error, data, refetch } = useQuery(ALL_NAVIGATION, {
    context: { headers: { "x-auth-jwt": token } },
    variables: { idRol: idRole },
  });

  const navigation = data?.getAllNavigation?.data?.map((item: any) =>
    item?.children?.length !== 0
      ? item?.children.map((child: any) => ({
          cat: child?.idCategory,
          idSubCategoria: child?.idSubCategory,
          estadoSubCategoria: child?.state,
        }))
      : [
          {
            cat: item?.idCategory,
            idSubCategoria: item?.idSubCategory,
            estadoSubCategoria: item?.state,
          },
        ]
  );

  navigation?.map((item: any) =>
    item.map((i: any) => {
      options.push(i);
    })
  );

  useEffect(() => {
    if (!loading && data) {
      setPermissions(options);
    }
  }, [data, loading]);

  const NewRole = yup.object({
    subCategorias: yup
      .array()
      .required(validations.REQUIRED_FIELD)
      .test(
        "min-length",
        "El Rol debe tener almenos 1 permiso asignado",
        (value: any) =>
          value?.filter((item: any) => item.estadoSubCategoria)?.length !== 0
      ),
  });

  const formik = useFormik({
    initialValues: {
      subCategorias: permissions,
    },
    validationSchema: NewRole,
    onSubmit: (values) => {
      values.subCategorias = permissions.map((item: any) => ({
        idSubCategoria: item.idSubCategoria,
        estadoSubCategoria: item.estadoSubCategoria,
      }));
      EditRole({
        variables: {
          idRole,
          subCategorias: values.subCategorias,
        },
        context: { headers: { "x-auth-jwt": token } },
      })
        .then((data) => {
          enqueueSnackbar(`${data?.data?.putPermissionsRole?.data?.message}`, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
          refetch({ idRol: idRole });
          setPermissions(options);
          handleClose();
        })
        .catch((error) => {
          console.log(error.message);
          enqueueSnackbar(`Ha ocurrido un error. ${error.message}`, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
        });
    },
  });

  const handleOpen = () => {
    setOpen(true);
    refetch({ idRol: idRole });
  };
  const handleClose = () => {
    setOpen(false);
    setOpenCollapse(null);
  };

  const addPermission = (category: number, subCategory: number) => {
    const getState = permissions
      .filter((item: any) => item.idSubCategoria === subCategory)
      .map((i: any) => i.estadoSubCategoria);
    const remove = permissions.filter(
      (item: any) => item.idSubCategoria !== subCategory
    );

    if (getState[0]) {
      remove.push({
        cat: category,
        idSubCategoria: subCategory,
        estadoSubCategoria: false,
      });
      setPermissions(remove);
      formik.setFieldValue("subCategorias", remove);
    } else {
      remove.push({
        cat: category,
        idSubCategoria: subCategory,
        estadoSubCategoria: true,
      });
      setPermissions(remove);
      formik.setFieldValue("subCategorias", remove);
    }
  };

  const addAll = (category: number, subCategory: any, type: any) => {
    if (type === 1) {
      const getState = permissions
        .filter((item: any) => item.cat === category)
        .filter((i: any) => i.estadoSubCategoria);
      const remove = permissions.filter((item: any) => item.cat !== category);
      if (getState.length === 0) {
        const newState = subCategory.map((item: any) => ({
          cat: category,
          idSubCategoria: item.idSubCategory,
          estadoSubCategoria: true,
        }));
        remove.push(...newState);
        setPermissions(remove);
        formik.setFieldValue("subCategorias", remove);
      } else {
        const newState = subCategory.map((item: any) => ({
          cat: category,
          idSubCategoria: item.idSubCategory,
          estadoSubCategoria: false,
        }));
        remove.push(...newState);
        setPermissions(remove);
        formik.setFieldValue("subCategorias", remove);
      }
    } else {
      const getState = permissions
        .filter((item: any) => item.idSubCategoria === subCategory)
        .map((i: any) => i.estadoSubCategoria);
      const remove = permissions.filter(
        (item: any) => item.idSubCategoria !== subCategory
      );

      if (getState[0]) {
        remove.push({
          cat: category,
          idSubCategoria: subCategory,
          estadoSubCategoria: false,
        });
        setPermissions(remove);
        formik.setFieldValue("subCategorias", remove);
      } else {
        remove.push({
          cat: category,
          idSubCategoria: subCategory,
          estadoSubCategoria: true,
        });
        setPermissions(remove);
        formik.setFieldValue("subCategorias", remove);
      }
    }
  };

  return (
    <>
      {children({ handleOpen })}
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialogContainer}
        maxWidth="md"
        fullWidth
        scroll="body"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Typography variant="h6" color="secondary" align="center">
              Editar Rol{" "}
              <Typography variant="inherit" color="primary">
                {rolename}
              </Typography>
            </Typography>
            <Typography variant="subtitle1" color="secondary" align="center">
              A continuación podrá cambiar los permisos de acceso a las
              diferentes secciones que tiene el rol seleccionado.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box marginY={2}>
              <Grid container spacing={2}>
                {data?.getAllNavigation?.data?.map((category: any) => {
                  return (
                    <Grid item xs={4}>
                      <Box className={classes.containerPermiss}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                permissions
                                  .filter(
                                    (i: any) => i.cat === category?.idCategory
                                  )
                                  .filter((c: any) => c.estadoSubCategoria)
                                  .length !== 0
                              }
                              onChange={() =>
                                addAll(
                                  category?.idCategory,
                                  category?.children?.length !== 0
                                    ? category?.children
                                    : category?.idSubCategory,
                                  category?.children?.length !== 0 ? 1 : 2
                                )
                              }
                              color="primary"
                            />
                          }
                          label={category?.name}
                        />
                        <IconButton
                          onClick={() => {
                            if (openCollapse === category?.idCategory) {
                              setOpenCollapse(null);
                            } else setOpenCollapse(category?.idCategory);
                            console.log(category?.idCategory);
                          }}
                        >
                          <i
                            className={
                              openCollapse === category?.idCategory
                                ? "icon-circle-arrow-top"
                                : "icon-circle-arrow-bottom"
                            }
                            style={{ fontSize: 20 }}
                          />
                        </IconButton>
                      </Box>
                      <Collapse
                        in={openCollapse === category?.idCategory}
                        className={classes.containerSubcategories}
                      >
                        <Box display="flex" flexDirection="column">
                          {category?.children?.length !== 0 ? (
                            category?.children?.map((child: any) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        permissions
                                          .filter(
                                            (i: any) =>
                                              i.idSubCategoria ===
                                              child?.idSubCategory
                                          )
                                          .map(
                                            (it: any) => it.estadoSubCategoria
                                          )[0]
                                      }
                                      onChange={() =>
                                        addPermission(
                                          category?.idCategory,
                                          child?.idSubCategory
                                        )
                                      }
                                      color="primary"
                                    />
                                  }
                                  label={child?.name}
                                />
                              );
                            })
                          ) : (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    permissions
                                      .filter(
                                        (item: any) =>
                                          item.idSubCategoria ===
                                          category?.idSubCategory
                                      )
                                      .map((i: any) => i.estadoSubCategoria)[0]
                                  }
                                  onChange={() =>
                                    addPermission(
                                      category?.idCategory,
                                      category?.idSubCategory
                                    )
                                  }
                                  color="primary"
                                />
                              }
                              label={category?.name}
                            />
                          )}
                        </Box>
                      </Collapse>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            <Typography color="error" align="center">
              {formik.errors.subCategorias}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              {uneditable ? "Cerrar" : "Cancelar"}
            </Button>
            {uneditable ? null : (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                endIcon={
                  editRolLoading && (
                    <CircularProgress size={20} style={{ color: "#FFF" }} />
                  )
                }
              >
                Actualizar
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default EditRol;
