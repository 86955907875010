import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
    margin: '8px 0',
  }, 
  cardHeader:{
    display:'flex',
    justifyContent:'space-between', 
    alignItems: 'center', 
    padding: '12px 0px'
  }, 
  tabSmall:{
    fontWeight:900,
    minWidth: 50,
    textTransform:'capitalize', 
    fontSize:14
  },
}))

export default useStyles