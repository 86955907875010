import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Fab,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import AvatarIconInfo from 'components/AvatarIconInfo';
import TableInfo from 'components/TableInfo';

import dayjs from 'dayjs';
import _ from 'lodash';

import useStyles from './styles';

interface LotDetailProps {
  children: any;
}

const LotDetail = ({ children }: LotDetailProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const data = {
    'Lote Infoporcinos': 2040,
    'Nro de Reporte': 203050,
    'Fecha de Creación': dayjs().format('DD-MM-YYYY'),
  };
  const productorData = {
    Nombre: 'Nestor Angel',
    NIT: 12345678,
    Celular: 3105986081,
    'Correo Electrónico': 'nestor@gmail.com',
    Dirección: 'Calle 43 # 32 C 21',
  };
  const comercializadorData = {
    Nombre: 'Ivan Echeverri',
    NIT: 98745612,
    Celular: 3105986081,
    'Correo Electrónico': 'ivan@gmail.com',
    Dirección: 'Calle 43 # 32 C 21',
  };

  const withAccess = [
    {
      name: 'Leidy Marcela Álvarez',
      id: 'CC 1000295214',
      sharedBy: 'Ivan Echeverri',
      since: dayjs().format('DD-MM-YYYY'),
      until: dayjs().date(20).format('DD-MM-YYYY'),
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {children({ handleOpen })}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        className={classes.dialogContainer}
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <Box display="flex" justifyContent="center">
            <AvatarIconInfo
              icon="icon-ticket"
              text="Tiquete de Báscula"
              avatarColor="#DD9835"
              textBold
              textColor="#767676"
            />
          </Box>
        </DialogTitle>
        <DialogContent className={classes.contentDialog}>
          <Grid
            container
            xs={12}
            spacing={2}
            className={classes.containerPrimaryInfo}
          >
            {_.map(data, (value, key) => (
              <Grid item xs={4}>
                <Box className={classes.primaryInfoContent}>
                  <Typography variant="subtitle1" className={classes.info}>
                    {key}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.info}>
                    {value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            xs={12}
            spacing={2}
            className={classes.containerPrimaryInfo}
          >
            <Grid item xs={6}>
              <TableInfo
                icon="icon-productor"
                title="Productor"
                items={productorData}
              />
            </Grid>
            <Grid item xs={6}>
              <TableInfo
                icon="icon-comercializador"
                title="Comercializador"
                items={comercializadorData}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button variant="contained" color="primary">
                  Ver Reporte
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center">
            <AvatarIconInfo
              icon="icon-users"
              text="Usuarios con Acceso"
              avatarColor="#DD9835"
              textBold
              textColor="#767676"
            />
          </Box>
          <Box width="100%" margin="12px 0px">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Documento</TableCell>
                  <TableCell>Compartido Por</TableCell>
                  <TableCell align="center">Compartido Desde</TableCell>
                  <TableCell align="center">Compartido Hasta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {withAccess.map((item: any) => {
                  return (
                    <TableRow className={classes.itemGrey}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.sharedBy}</TableCell>
                      <TableCell align="center">{item.since}</TableCell>
                      <TableCell align="center">{item.until}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LotDetail;
