import { gql } from "apollo-boost";

export const INVITATIONS = gql`
   query ($state: Int!) {
    invitations(state: $state) {
      data {
        id: idInvitacion
        nombresCompletos
        imagenPerfil
        fechaInvitacion
        tipoDocumento
        nroDocumento
        email
        tipoUsuario
        numeroIntentos
        estadoInvitacion
        idEstadoInvitacion
      }
      token{
        value: tokenNuevo
      }
    }
  } 
`;

export const DETAIL_INVITATION = gql`
  query invitation ($id: Int!) {
    invitation (id: $id) {
      data {
        idInvitacion
        nombresCompletos
        primerNombre
        segundoNombre
        primerApellido
        segundoApellido
        tipoDocumento
        numeroDocumento
        digitoVerificacion
        tipoUsuario
        idEstadoInvitacion
        fechaInvitacion
        razonSocial
        indicativoCelular
        celular
        indicativoTelefono
        telefono
        extension
        email
        numeroIntentos
        estadoInvitacion
        fechaRespuesta
      }
    }
  }
`;

export const INVITATIONS_RECEIVED = gql`
  query getInvitationsReceivedXResponse($idEstadoInvitacion: Int) {
    getInvitationsReceivedXResponse(input: {
      idEstadoInvitacion: $idEstadoInvitacion
    }) {
      data {
        idInvitacion
        idUsuarioInvitante
        idTipoUsuario
        numeroIntentos
        usuario
        nombre
        email
        idTipoDocumento
        numeroDocumento
        digitoIdentificador
        indicativoCelular
        celular
        fechaInvitacion
        fechaRespuesta
        estadoInvitacion
      }
      token {
        tokenNuevo
        fechaExpiracion
      }
    }
  }
`;
