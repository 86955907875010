/** @format */

const truckLogo = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABJCAYAAABxcwvcAAAACXBIWX
MAACxKAAAsSgF3enRNAAACzklEQVR4nO2bz43TQBSHH4grIh1sOiANIIUOwo0b5sKVpQLSAaEBFD
rwhbu3AkIHpoNsBUYPPUujJ4/9G9tr72p+nzSXyWjm7bd+88eTPGuaRkg/z+lnGEoCoCQASgKgJA
BKAqAkAEoCoCQASgKgJABKAqAkAEoCoCQASgKgJIAXkSY7ETmKyOYRxVqLyK2IXJceOPb6VgO6WT
oYgC8iclp60Fi6PUZBymGNQWPpFvLHHvM10HQ/i8irlcb/DyJJBVULxBJD0+vriuNzdUOgJABKAq
AkgKcsqQFLPXV1zuFJ0j3fN9tKjCKndPswVhSyT3oKvI3EuLEz6OtAlFLkKKlvs1tZGS0qh3TTtw
Z7O161JKVeLnPSJFE5TdyjReW2mRwlKscdd0xU9GVerseSLlGfRWTb1Tjns1sr6j6oo6QOVNRlqF
HukiAoCWDOY8nWbjPaGw19lEsrS9+VbYJY2rvDypb6Ork3vXfrKCH7SJuwHJs4ddM0O6APpO8qqA
8J27+xMWMcXftq6G+dI92OA7cZ+j7nt90KPzQ6xq+Be0ON9UdKHFMl7ZwgXU6/i8g7Efnp2pYTx0
LQMV4G7TSGjxZTuNRr3Xu4VyDdzj2pcA7a6SO+cZ8Xrq9DYqppfxcw3T65uqKjrzANzzYNXIfSDZ
GE4oNqSzmyvy5ikjxlJBb/T/MkzUn3kfo+YqvG4GZthjHQMdNXtp45acztQueWvqc+lb8J3ygZE8
td9A3nhKUZmZN27nGOpeQc5eDG8tsOP7/1zbXQnISWvQtMRd1a/clNivUDCmqL3x+dLJai4zNk/z
eLJLFAEFJXtjn+aTFOKf3OFVyfqOtCgtpycE+wJ0mQljl/zb21CT/cWZd2Xlrj7Fa4b8ZdbOJPXu
H4k3cAvioBoCQASgKgJABKAqAkAEoCoCQASgKgJABKAqAkAEoCoCQASgKgJABKGkJE/gERzwMDqi
TuhAAAAABJRU5ErkJggg==`;

export default truckLogo;
