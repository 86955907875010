import React from "react";
import {
  ListItem,
  Typography,
  Avatar,
  ListItemAvatar,
} from "@material-ui/core";
import clsx from "clsx";

import useStyles from "./styles";

interface AvatarIconInfoProps {
  icon: string;
  iconSize?: number;
  iconColor?: string;
  text: string;
  textBold?: boolean;
  textColor?: string;
  textSize?: number;
  avatarColor?: string;
  avatarSize?: number;
}

const AvatarIconInfo = ({
  icon,
  iconSize,
  iconColor,
  text,
  textBold,
  textSize,
  textColor,
  avatarColor,
  avatarSize,
}: AvatarIconInfoProps) => {
  const classes = useStyles();
  const size = avatarSize
    ? {
        height: avatarSize,
        width: avatarSize,
      }
    : {};

  return (
    <ListItem className={classes.container}>
      <ListItemAvatar>
        <Avatar
          className={classes.avatar}
          style={{ background: avatarColor, ...size }}
        >
          <i
            className={clsx(`${icon}`, classes.icon)}
            style={{ fontSize: iconSize, color: iconColor }}
          />
        </Avatar>
      </ListItemAvatar>
      <Typography
        style={{ fontSize: textSize, color: textColor }}
        className={clsx(classes.text, {
          [classes.textBold]: textBold,
        })}
      >
        {text}
      </Typography>
    </ListItem>
  );
};

export default AvatarIconInfo;
