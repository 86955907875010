import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import avatar from 'lib/helpers/dynamic-images/avatars';

import useStyles from './styles';

interface cardProps {
  action?: 'activar' | 'inactivar';
  id?: string | number;
  name: string;
  ubication: string;
  document?: string;
  license?: string;
  mobile?: string;
  isReplacement?: boolean;
  actionFuncion?: (event: any) => void;
}

const CardDetailDriver = ({
  action,
  id,
  name,
  ubication,
  document,
  license,
  mobile,
  isReplacement,
  actionFuncion,
}: cardProps) => {
  const classes = useStyles();

  return (
    <Grid>
      <Card elevation={4} className={classes.cardContainer}>
        <CardContent className={classes.cardContent}>
          <Grid container justifyContent="center">
            <Avatar src={avatar(name)} className={classes.avatarContainer} />
          </Grid>
          <Grid
            container
            justifyContent="center"
            className={classes.dataContainer}
          >
            <Box mt={2} mb={1} maxWidth="70%">
              <Typography className={classes.nameInfo}>{name}</Typography>
              <Typography className={classes.ubicationInfo}>
                {ubication}
              </Typography>
            </Box>
          </Grid>

          {document && (
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.dataTitle}>Documento</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dataInfo}>{document}</Typography>
              </Grid>
            </Grid>
          )}

          {license && (
            <Box mt={1}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.dataTitle}>
                    Numero Licencia de Conducción
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataInfo}>
                    {license}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          {mobile && (
            <Box mt={1}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.dataTitle}>Celular</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.dataInfo}>{mobile}</Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          {isReplacement && (
            <Box mt={1}>
              <Grid container direction="column">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!isReplacement}
                        color="primary"
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={
                      isReplacement
                        ? 'Es Conductor de Reemplazo'
                        : 'No es Conductor de Reemplazo'
                    }
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>

        <CardActions style={{ marginBottom: 5 }}>
          <Grid container justify="center">
            {action && (
              <Box mr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  className={
                    action === 'activar'
                      ? classes.buttonColorGreen
                      : classes.buttonColorRed
                  }
                  onClick={actionFuncion}
                >
                  {action === 'activar' ? 'Activar' : 'Inactivar'}
                </Button>
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={`/app/network/transports/details/${id}`}
            >
              Ver detalle
            </Button>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default CardDetailDriver;
