import React, { useState, useEffect } from 'react';
import { Button, Typography, Fab, Box, Grid, Tooltip } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import domtoimage from 'dom-to-image';

import TableFooter from 'components/datagrid-custom-components/TableFooter';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import DialogPDFViewer from 'components/DialogPDFViewer';
import LevelIndicator from 'components/LevelIndicator';
import ItemTotalInfo from 'components/ItemTotalInfo';
import ContentCard from 'components/ContentCard';
import GobackLink from 'components/GobackLink';

import { ReportLot, GraphCavaLot, MyDocCavaLot } from '../Reports/ReportLot';

import useStyles from './styles';

const Details = () => {
  const classes = useStyles();
  const [openReportLot, setOpenReportLot] = useState(false);
  const [imageQr, setImageQr] = useState('a');
  const [imageGraph, setImageGraph] = useState('a');

  const generateScreenshot = () => {
    const qrCode: any = document.getElementById('screenqrcavalote');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        setImageQr(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const generateScreenshotGraph = () => {
    const qrCode: any = document.getElementById('graphcavalot');
    domtoimage
      .toPng(qrCode)
      .then((dataUrl) => {
        setImageGraph(dataUrl);
      })
      .catch((error) => console.error('oops, something went wrong!', error));
  };

  const openModalLotCava = () => {
    setOpenReportLot(true);
  };

  useEffect(() => {
    generateScreenshot();
    generateScreenshotGraph();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.tableHeader,
    },
    {
      field: 'canalesLote',
      headerName: 'Canales Lote',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.tableHeader,
    },
    {
      field: 'weight',
      headerName: 'Peso Total (kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.tableHeader,
    },
    {
      field: 'pcc',
      headerName: 'Prom. PCC (Kg)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.tableHeader,
    },
    {
      field: 'mc',
      headerName: 'Prom. MC (%)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.tableHeader,
    },
    {
      field: 'lm',
      headerName: 'Prom. LM (m.m)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.tableHeader,
    },
    {
      field: 'gd',
      headerName: 'Prom. GD (m.m)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.tableHeader,
    },
    {
      field: 'rl',
      headerName: 'Rendimiendo Lote (%)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.tableHeader,
    },

    {
      field: 'canvasHours',
      headerName: 'Horas en Canvas',
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.tableHeader,

      renderCell: (params: GridCellParams) => (
        <div style={{ margin: 'auto' }}>
          <LevelIndicator
            value={11}
            levels={[[0, 12], [12, 24], [24, 36], [36]]}
          />
        </div>
      ),
    },
    {
      field: '',
      headerName: '',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.tableHeader,

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/inventory/cava/details/lote/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      loteIP: '6543',
      canalesLote: '50',
      weight: '4.224,00',
      pcc: '123,00',
      mc: '53,2',
      lm: '64,2',
      gd: '64,2',
      rl: '83,76',
    },
    {
      id: 2,
      loteIP: '6543',
      canalesLote: '48',
      weight: '4.224,00',
      pcc: '123,00',
      mc: '53,2',
      lm: '64,2',
      gd: '64,2',
      rl: '83,76',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    { link: '/app/inventory/cava/details', title: 'Inventario Cavas' },
  ];

  return (
    <div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <ReportLot />
      </div>
      <div style={{ position: 'absolute', left: '-1000%' }}>
        <GraphCavaLot />
      </div>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>
      <ContentCard className={classes.contentCard}>
        <Grid
          container
          spacing={2}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid item sm={12} md={12} lg={7} xl={7}>
            <Typography className={classes.mainTitle}>
              Inventario de Canales Refrigeradas
            </Typography>
          </Grid>
          <Grid item sm={6} md={3} lg={3} xl={3}>
            <div
              className={clsx(classes.grayContainer, classes.subtitleContainer)}
            >
              <Typography className={classes.subtitle1}>
                Canales Disponibles
              </Typography>
              <Typography className={classes.subtitle2}>98</Typography>
            </div>
          </Grid>
          <Grid item sm={6} md={2} lg={2} xl={2}>
            <div
              className={clsx(classes.grayContainer, classes.subtitleContainer)}
            >
              <Typography className={classes.subtitle1}>Cava</Typography>
              <Typography className={classes.subtitle2}>C12</Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={5}
            xl={5}
            direction="column"
          >
            <div
              className={clsx(classes.grayContainer, classes.titleContainer)}
            >
              <Typography className={classes.secondTitle}>
                Resumen Disponibilidad
              </Typography>
            </div>

            <div className={clsx(classes.grayContainer, classes.containerRow)}>
              <div className={classes.resumeAvailableContainer}>
                <div className={classes.availableContainer}>
                  <ItemTotalInfo
                    position="right"
                    avatarTotalInfo={98}
                    titleInfo="Canales Disponibles"
                    avatarTotalColor="#BE5948"
                    avatarTotalSize={60}
                  />
                </div>
                <div className={classes.canalesContainer}>
                  <div className={classes.itemContainer}>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={98}
                      titleInfo="Total Canales Del Lote"
                      avatarTotalColor="#F1AE6C"
                      avatarTotalSize={40}
                    />
                  </div>
                  <div className={classes.itemContainer}>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={0}
                      titleInfo="Canales Despachadas"
                      avatarTotalColor="#FFC676"
                      avatarTotalSize={40}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={clsx(classes.grayContainer, classes.titleContainer)}
            >
              <Typography className={classes.secondTitle}>
                Tiempo en Canvas
              </Typography>
            </div>

            <div className={classes.flexContainer}>
              <div>
                <Typography className={classes.cavasHours}>
                  Horas en Cavas
                </Typography>
              </div>

              <div style={{ margin: '0px 10px' }}>
                <LevelIndicator
                  value={25}
                  levels={[[0, 12], [12, 24], [24, 36], [36]]}
                />
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} xl={7}>
            <Grid item xs={12}>
              <div
                className={clsx(classes.grayContainer, classes.titleContainer)}
              >
                <Typography className={classes.secondTitle}>
                  Resumen Calidad de los Lotes en la Cava
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div
                className={clsx(
                  classes.grayContainer,
                  classes.fullWidhtContainer
                )}
              >
                <div className={classes.cavaLotes}>
                  <ItemTotalInfo
                    avatarTotalInfo={2}
                    titleInfo="Total Lotes En Cava"
                    avatarTotalColor="#B0413E"
                    position="bottom"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px 15px',
                  }}
                >
                  <div className={classes.columnContainerFlexStart}>
                    <div className={classes.itemContainer}>
                      <ItemTotalInfo
                        avatarTotalInfo={'54,3%'}
                        titleInfo="Promedio MC%"
                        avatarTotalColor="#E29662"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        position="left"
                      />
                    </div>
                    <div className={classes.itemContainer}>
                      <ItemTotalInfo
                        avatarTotalInfo={'16,8 m.m.'}
                        titleInfo="Promedio GD"
                        avatarTotalColor="#FFC676"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        position="left"
                      />
                    </div>
                  </div>
                  <div className={classes.columnContainerFlexStart}>
                    <div className={classes.itemContainer}>
                      <ItemTotalInfo
                        avatarTotalInfo={'64,67 m.m.'}
                        titleInfo="Promedio LM"
                        avatarTotalColor="#F1AE6C"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        position="left"
                      />
                    </div>
                    <div className={classes.itemContainer}>
                      <ItemTotalInfo
                        avatarTotalInfo={'101,67 Kg'}
                        titleInfo="Promedio PCC"
                        avatarTotalColor="#D47D57"
                        avatarTotalSize={35}
                        avatarTextSize={16}
                        extended
                        position="left"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '10px 15px',
                }}
              >
                <div className={classes.columnContainerFlexStart}>
                  <div className={classes.itemContainer}>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={'7,320.00 Kg'}
                      titleInfo="Peso Total Animales en Pie"
                      avatarTotalColor="#F2F2F2"
                      avatarTotalSize={35}
                      avatarTextSize={16}
                      extended
                      titleInverted
                      titleInfoSize={200}
                    />
                  </div>
                  <div className={classes.itemContainer}>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={'4.013,65 Kg'}
                      titleInfo="Total Carne Magra"
                      avatarTotalColor="#F2F2F2"
                      avatarTotalSize={35}
                      avatarTextSize={16}
                      extended
                      titleInverted
                      titleInfoSize={200}
                    />
                  </div>
                </div>
                <div className={classes.columnContainerFlexStart}>
                  <div className={classes.itemContainer}>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={'6.891,00 Kg'}
                      titleInfo="Peso Total Canales Calientes"
                      avatarTotalColor="#F2F2F2"
                      avatarTotalSize={35}
                      avatarTextSize={16}
                      extended
                      titleInverted
                      titleInfoSize={200}
                    />
                  </div>
                  <div className={classes.itemContainer}>
                    <ItemTotalInfo
                      position="right"
                      avatarTotalInfo={'89,76%'}
                      titleInfo="Rendimiento Pie / Canal"
                      avatarTotalColor="#F2F2F2"
                      avatarTotalSize={35}
                      avatarTextSize={16}
                      extended
                      titleInverted
                      titleInfoSize={200}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div className={clsx(classes.grayContainer, classes.titleContainer)}>
            <Typography className={classes.secondTitle}>
              Detalle Lotes Cava
            </Typography>
          </div>
          <div>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              className={classes.dataGrid}
              components={{
                Footer: TableFooter,
              }}
            />
          </div>
        </Grid>
      </ContentCard>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography className={classes.options}>
          Opciones del reporte
        </Typography>
        <Button
          startIcon={<i className="icon-eye" />}
          color="primary"
          variant="contained"
          onClick={openModalLotCava}
        >
          Previsualizar
        </Button>
      </Box>
      <DialogPDFViewer
        open={openReportLot}
        onClose={() => setOpenReportLot(false)}
        done={Boolean(imageQr)}
      >
        <MyDocCavaLot qr={imageQr} graph={imageGraph} />
      </DialogPDFViewer>
    </div>
  );
};

export default Details;
