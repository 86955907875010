import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import jwt from "jwt-decode";
import dayjs from "dayjs";
import useSignOut from "lib/hooks/useSignOut";
import AcceptDialog from "components/PageComponents/Profile/AcceptDialog";
import { LOGIN_TOKEN_MUTATION } from "core/graphql/mutations/loginToken";
import { useMutation } from "@apollo/client";
import useSetToken from "lib/hooks/useSetToken";

const ValidationTokenContext = createContext({});

const ValidationTokenProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { signOut } = useSignOut();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const setToken = useSetToken();

  const [updateToken, {}] = useMutation(LOGIN_TOKEN_MUTATION);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(false);
    updateToken({ context: { headers: { "x-auth-jwt": token } } }).then(
      (response) => {
        enqueueSnackbar("Hora de expiración actualizada", {
          variant: "success",
          autoHideDuration: 5000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
        setToken(response.data?.loginToken?.token);
        handleClose();
      }
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (token) {
        const decode: any = jwt(token);
        const dateDecode: any = dayjs(decode?.exp * 1000);
        const expired = dayjs(dateDecode).subtract(5, "minutes");
        const exced = dayjs(expired).add(2, "minute");
        const now = dayjs();
        if (expired < now) {
          handleOpen();
          signOut();
          if (now > exced) {
            signOut();
          }
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      handleClose();
    };
  }, [token]);

  return (
    <ValidationTokenContext.Provider value>
      <AcceptDialog
        open={open}
        onClick={handleClick}
        icon="icon-circle-check"
        modalTitle="¡Su sesión expira pronto!"
        modalDescription="Presione el botón de aceptar si está utilizando el sistema, de lo contrario, su sesión se cerrará."
      />
      {children}
    </ValidationTokenContext.Provider>
  );
};

export { ValidationTokenContext as default, ValidationTokenProvider };
