import { MenuItem, TextField } from '@material-ui/core';
import { useGridSlotComponentProps } from '@material-ui/data-grid';
import { Pagination } from '@material-ui/lab';

import useStyles from './styles';

const TablePagination = () => {
  const { state, apiRef } = useGridSlotComponentProps();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TextField
        label="Resultados por página"
        variant="outlined"
        size="small"
        defaultValue={state.options.pageSize}
        onChange={(e: any) => apiRef.current.setPageSize(e.target.value)}
        className={classes.pageSizeInput}
        InputProps={{
          classes: {
            input: classes.inputDense,
            root: classes.roundedInput,
          },
        }}
        select
      >
        {state.options.rowsPerPageOptions?.map((item: number) => (
          <MenuItem value={item}>{item}</MenuItem>
        ))}
      </TextField>
      <Pagination
        className={classes.root}
        color="primary"
        variant="outlined"
        page={state.pagination.page + 1}
        count={state.pagination.pageCount}
        showFirstButton={state.pagination.pageCount > 5}
        showLastButton={state.pagination.pageCount > 5}
        onChange={(__, value) => apiRef.current.setPage(value - 1)}
      />
    </div>
  );
};

export default TablePagination;
