import React, { useState } from 'react';

import { Image, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import marketerIcon from './../../../static/img/reportsIcons/marketerIcon';

import styles from './styles';

import ItemTotalCircle from './../ItemTotalCircle/index';
import ItemTotal from './..//ItemTotal/index';

interface Props {
  razonSocial: string;
  totalLotes: number;
  totalAnimales: number;
  data: any;
}

const DetailGSMITable = ({
  razonSocial,
  totalLotes,
  totalAnimales,
  data,
}: Props) => {
  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '5px',
        }}
      >
        <View style={{ width: '58.3%', justifyContent: 'center' }}>
          <View style={styles.backgroundresumetitleImage}>
            <View style={styles.imageicon}>
              <Image src={marketerIcon} />
            </View>

            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <View style={{ width: '28.3%', alignItems: 'center' }}>
                <Text>Productor</Text>
              </View>
              <View style={{ width: '58.3%', fontWeight: 'normal' }}>
                <Text>{razonSocial}</Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '38.3%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              width: '48.3%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ItemTotalCircle
              value={totalLotes}
              info="Total Lotes Productor"
              widthInfo="40px"
              position="right"
              textAlign="left"
              backgroundColor="#ffc676"
            />
          </View>
          <View
            style={{
              width: '48.3%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ItemTotal
              value={totalAnimales}
              info="Total Animales Productor"
              widthValue={50}
              fontSizeValue={12}
              position="right"
              backgroundColor="#e29662"
            />
          </View>
        </View>
      </View>

      <View style={{ width: '100%', marginTop: '5px' }}>
        <View style={[styles.tableMagro, { marginBottom: 5 }]} wrap={false}>
          <View
            wrap={false}
            style={[
              styles.tableRow,
              {
                marginBottom: '5px',
                marginTop: '5px',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Fecha</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 79.2, width: 79.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Nro. Guía
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Lote IP</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 98.2, width: 98.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>Origen</Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 79.2, width: 79.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Placa Vehículo
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 98.2, width: 98.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Conductor
              </Text>
            </View>
            <View style={[styles.tableCol, { maxWidth: 79.2, width: 79.2 }]}>
              <Text style={[styles.tableCell, styles.tableHead]}>
                Total Animales
              </Text>
            </View>
          </View>
          {data?.map((item: any, index: number) => (
            <View
              key={index}
              wrap={false}
              style={[
                styles.tableRow,
                {
                  backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.fecha}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 79.2, width: 79.2 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.nroGuia}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 60.2, width: 60.2 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.lote}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 98.2, width: 98.2 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.origen}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 79.2, width: 79.2 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.placa}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 98.2, width: 98.2 }]}>
                <Text style={[styles.tableCellDetail, { fontSize: 8 }]}>
                  {item.conductor}
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 79.2, width: 79.2 }]}>
                <Text
                  style={[
                    styles.tableCellDetail,
                    { fontSize: 8, fontWeight: 'bold' },
                  ]}
                >
                  {item.totalAnimales}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

export default DetailGSMITable;
