import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Grow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import CardIcon from 'components/CardIcon';
import MainTitle from 'components/MainTitle';

import { SEND_INVITATION } from 'core/graphql/queries/sendInvitation';

import useStyles from './styles';

type DialogInviteProps = {
  open: boolean;
  data: any;
  handleInvite: any;
  handleClose: any;
};

const DialogInvite = ({
  open,
  data: dataDialog,
  handleInvite,
  handleClose,
}: DialogInviteProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const [stepOne, setStepOne] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open) {
      setStepOne(0);
    }
  }, [open]);

  const [send, { loading }] = useMutation(SEND_INVITATION, {
    context: { headers: { 'x-auth-jwt': token } },
    onError(error) {
      enqueueSnackbar(error.message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'error',
      });
    },
  });

  const sendInvitation = () => {
    send({
      update: (proxy, mutationResult) => {
        if (mutationResult?.data?.sendInvitation?.data?.success) {
          enqueueSnackbar(mutationResult?.data?.sendInvitation?.data?.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
          handleInvite();
        } else {
          enqueueSnackbar(mutationResult?.data?.sendInvitation?.data?.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: { idUser: dataDialog, idUserType: stepOne },
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        className={classes.borderRadiudDialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid container justify="flex-end">
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container className={classes.title}>
              <MainTitle>Envío de Invitaciones</MainTitle>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Box>
              <Grid item container>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <DialogContentText>
                    <Typography className={classes.alignText}>
                      Defina el típo de relación que desea tener con el usuario
                      a invitar:
                    </Typography>
                  </DialogContentText>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grow in timeout={800}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Box mr={4} mt={2} mb={2}>
                        <CardIcon
                          icon="icon-productor"
                          title="Productor"
                          chacked={stepOne === 1}
                          onClick={() => setStepOne(1)}
                        />
                      </Box>

                      <Box>
                        <CardIcon
                          icon="icon-comercializador"
                          title="Comercializador"
                          chacked={stepOne === 2}
                          onClick={() => setStepOne(2)}
                        />
                      </Box>
                    </Grid>
                  </Grow>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </DialogContent>

        <Box mb={2}>
          <DialogActions>
            <Grid item container justify="center" alignItems="center">
              <Button
                variant="contained"
                onClick={sendInvitation}
                color="primary"
                disabled={stepOne === 0 || loading}
                endIcon={
                  loading && (
                    <CircularProgress
                      className={classes.loadingColor}
                      thickness={5}
                      size={20}
                    />
                  )
                }
              >
                Invitar a Mi Red
              </Button>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};
export default DialogInvite;
