import { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";

import { ALL_NAVIGATION } from "core/graphql/queries/allNavigation";

import {
  GET_NAVIGATION_MENU,
  SET_USER_TYPE,
  STORE_NAVIGATION_MENU,
} from "core/redux/types/userTypes";

export default () => {
  const dispatch = useDispatch();
  const [currentNavigation, setCurrentNavigation] = useState([]);
  const [getNavigation, { data, error, loading }] =
    useLazyQuery(ALL_NAVIGATION);
  const { idRole: idRol, name } = useSelector(
    (state: any) => state.user.selectedUserType
  );

  const userTypes = useSelector((state: any) => state.user.typeUsers);
  const navigationStore = useSelector(
    (state: any) => state.user.navigationStore
  );
  const token = useSelector((state: any) => state.user.token);
  const switchNavigation = (idUserType: number) => {
    const rolFound = userTypes.find(
      (value: any) => value.idUserType === idUserType
    ).idRole;
    Promise.resolve()
      .then(() =>
        dispatch({
          type: SET_USER_TYPE,
          payload: idUserType,
        })
      )
      .then(() =>
        getNavigation({
          variables: { idRol: rolFound },
          context: {
            headers: {
              "x-auth-jwt": token,
            },
          },
        })
      );
  };

  useEffect(() => {
    if (navigationStore[name]) {
      setCurrentNavigation(navigationStore[name]);
    } else {
      getNavigation({
        variables: { idRol: idRol },
        context: {
          headers: {
            "x-auth-jwt": token,
          },
        },
      });
    }
  }, [name]);

  useEffect(() => {
    if (data && !loading && !error) {
      setCurrentNavigation(data?.getAllNavigation?.data);
      dispatch({
        type: GET_NAVIGATION_MENU,
        payload: data?.getAllNavigation?.data,
      });
      dispatch({
        type: STORE_NAVIGATION_MENU,
        payload: {
          name,
          menu: data?.getAllNavigation?.data,
        },
      });
    }
  }, [data, loading, error]);

  return { currentNavigation, switchNavigation };
};
