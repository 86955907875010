import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const SearchContainerStyle = styled.div`
  color: #767676;
  font-family: Roboto, sans-serif;
  
  & .flexdate {
    display: flex;
    margin-left: 10px;
    & .date {
      font-weight: bold;
    }
    & .contentdate {
      color: #dd9835;
      font-weight: bold;
      margin-left: 20px;
    }
  }
  
  & .table {
    text-align: center;
    width: 100%;
    margin-top: 20px;
    & .cardstable {
      display: flex;
      align-items: center;
      justify-content: center;
      & .container {
        border-right: 1px solid #707070;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & .backgroundcard {
        background-color: #d6d6d6;
        color: white;
        margin-right: 4px;
        margin-left: 4px;
        width: 30px;
        font-size: 10px;
      }
    }

    & td {
      border-bottom: 1px solid #eaeaea;
      height: 40px;
    }
    & .button {
      background-color: #767676;
      color: white;
    }
    & .textleft {
      text-align: left;
    }
  }
  & .header {
    width: 100%;
    & .button {
      margin-left: 40px;
      background-color: #dd9835;
      color: white;
    }
  }
`

export const CardHeaderPaginator = styled.div`
  font-size: 1.28571429em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;

  & .title {
    margin-right: 20px;
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px;
`

export const PaginationControls = styled.div`
  width: 100%;
  text-align: right;
  padding: 10px 0;
`

export default makeStyles(theme => ({
  filter: {
    '& *': {
      fontWeight: 'bold'
    }
  },
  filterType: {
    marginRight: theme.spacing(1.5)
  },
  filterValue: {
    color: theme.palette.text.hint,
    '& > .param': {
      color: theme.palette.primary.main
    }
  },
  dialogContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 'inherit'
  },
  dialogPaper: {
    minWidth: 800,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  },
  dialogTitle: {
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    }),
    '& .MuiTypography-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    }
  },
  dialogActions: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  dialogContent: {
    padding: '0px 12px'
  },
  pdfViewer: {
    width: '100%',
    height: 'calc(100% - 53px)',
    border: '1px solid #ddd',
    borderRadius: 10,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  },
  dialogFullScreen: {
    minWidth: '100%',
    margin: 0,
    borderRadius: 0,
    height: '100vh',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  },
  dialogTitleFullScreen: {
    padding: '8px 24px',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  }
}))
