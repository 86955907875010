import { Document, Image, Page, Text, View, Font } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';

import userLogo from 'static/img/reportsIcons/userLogo';
import userIcon from 'static/img/reportsIcons/userIcon';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';
import NunitoRegular from 'static/fonts/Nunito-Regular.ttf';
import NunitoBold from 'static/fonts/Nunito-Bold.ttf';
import NunitoItalic from 'static/fonts/Nunito-Italic.ttf';

import PDFHeader from 'components/pdf-components/PDFHeader';

import styles from './styles/userActivity';
import Footer from 'components/pdf-components/Footer';
import Paginationpdf from 'components/pdf-components/Pagination';
import Subtitle from 'components/pdf-components/Subtitle/index';
import ItemTotal from 'components/pdf-components/ItemTotal';
import ListData from 'components/pdf-components/ListData';
import dayjs from 'dayjs';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 'normal',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 'normal',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
    },
    {
      src: NunitoItalic,
      fontStyle: 'italic',
    },
  ],
});

interface MyDocUserActivityProps {
  qr: string;
  dataUser?: any;
  dataActivity?: any;
}

export const MyDocUserActivity = ({
  qr,
  dataUser,
  dataActivity,
}: MyDocUserActivityProps) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <PDFHeader
        qr={qr}
        lote={dataUser?.userName}
        userTitile="Usuario"
        reportImage={userLogo}
        number="023456789"
        title="Reporte Actividad de Usuario"
        code="AU-001"
      />
      <Subtitle title="Datos del Usuario" widthContainer="100%" />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 10,
        }}
      >
        <View
          style={{
            width: '30.3%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View style={styles.imageicon}>
            <Image src={userIcon} />
          </View>
          <View style={{ marginRight: 30 }}>
            <Text style={{ fontWeight: 'bold' }}>Usuario</Text>
          </View>
        </View>
        <View
          style={{
            width: '33.3%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ListData
            widthContainer="90%"
            widthtTitle="30%"
            widthValue="70%"
            items={{
              Usuario: dataUser?.userName ?? 'N/D',
              Nombre: dataUser?.name ?? 'N/D',
              Teléfono: dataUser?.phone ?? 'N/D',
            }}
          />
        </View>
        <View
          style={{
            width: '33.3%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ListData
            widthContainer="90%"
            items={{
              Email: dataUser?.email ?? 'N/D',
              'Rol Usuario': dataUser?.rol ?? 'N/D',
              'Activo Desde': dataUser?.RegistrationDate
                ? dayjs(new Date(dataUser?.RegistrationDate)).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )
                : 'N/D',
            }}
          />
        </View>
      </View>

      <View style={{ marginTop: 10, marginBottom: 10 }}>
        <Subtitle title="Resumen de Actividad" widthContainer="100%" />
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            width: '56.5%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              width: '50%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ItemTotal
              value={dataActivity?.activityDone ?? 'N/D'}
              fontSizeValue={12}
              info="Actividades Realizadas"
              position="right"
              widthInfo="40px"
              backgroundColor="#b0413e"
            />
          </View>
          <View
            style={{
              width: '50%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ItemTotal
              value="N/D"
              fontSizeValue={12}
              info="Tiempo Activo Última Sesión"
              position="right"
              widthInfo="55px"
              backgroundColor="#be5948"
            />
          </View>
        </View>
        <View style={{ width: '40.5%' }}>
          <ListData
            widthContainer="90%"
            items={{
              'Fecha Última Sesión': dataActivity?.lastDate
                ? dayjs(new Date(dataActivity?.lastDate)).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )
                : 'N/D',
              'Última Accion': dataActivity?.lastSection ?? 'N/D',
            }}
          />
        </View>
      </View>

      <View style={{ marginTop: 10, marginBottom: 10 }}>
        <Subtitle title="Detalle de la Actividad" widthContainer="100%" />
      </View>

      {dataActivity?.activityDone > 0 ? (
        <View>
          <View style={[styles.table, { marginBottom: 10 }]} wrap={false}>
            <View
              wrap={false}
              style={[
                styles.tableRow,
                {
                  marginBottom: '5px',
                  marginTop: '5px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: 555,
                },
              ]}
            >
              <View style={[styles.tableCol, { maxWidth: 100, width: 100 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Fecha Inicial
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 100, width: 100 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Fecha Final
                </Text>
              </View>
              <View style={[styles.tableCol, { maxWidth: 80, width: 80 }]}>
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Tiempo Total
                </Text>
              </View>
              <View
                style={[styles.tableCol, { maxWidth: 107.5, width: 107.5 }]}
              >
                <Text style={[styles.tableCell, styles.tableHead]}>
                  Registro
                </Text>
              </View>
            </View>
            {dataActivity?.data?.map((item: any, index: number) => (
              <View
                key={index}
                wrap={false}
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: index % 2 ? '#FFF' : '#F2F2F2',
                    justifyContent: 'space-between',
                    width: 555,
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableColActivities,
                    { maxWidth: 100, width: 100 },
                  ]}
                >
                  <Text style={[styles.tableActivities, { fontSize: 8 }]}>
                    {item.fechaInicial
                      ? dayjs(item.fechaInicial).format('DD/MM/YYYY - HH:mm:ss')
                      : 'N/D'}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColActivities,
                    { maxWidth: 100, width: 100 },
                  ]}
                >
                  <Text style={[styles.tableActivities, { fontSize: 8 }]}>
                    {item.fechaFinal
                      ? dayjs(item.fechaFinal).format('DD/MM/YYYY - HH:mm:ss')
                      : 'N/D'}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColActivities,
                    { maxWidth: 80, width: 80 },
                  ]}
                >
                  <Text style={[styles.tableActivities, { fontSize: 8 }]}>
                    {item.tiempoTotal ?? 'N/D'}
                  </Text>
                </View>

                <View
                  style={[
                    styles.tableColActivities,
                    { maxWidth: 107.5, width: 107.5 },
                  ]}
                >
                  <Text style={[styles.tableActivities, { fontSize: 8 }]}>
                    {item.registro ?? 'N/D'}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      ) : (
        <View
          style={{
            marginTop: '5px',
            alignItems: 'center',
          }}
        >
          <Subtitle
            title="Este usuario no tiene actividades registradas."
            heightContainer="24px"
            widthContainer="60%"
          />
        </View>
      )}

      <Footer />
      <Paginationpdf uid={388} />
    </Page>
  </Document>
);

export const ReportUserActivity = () => {
  return (
    <div id="screenUserActivity" style={{ width: 500 }}>
      <QRCode
        id="screenUserActivity"
        size={500}
        value="Reporte Actividad de Usuario"
        renderAs="svg"
        level="H"
      />
    </div>
  );
};
