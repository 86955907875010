import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container:{
    margin: '20px 0px',
    padding: '20px 40px',
    borderRadius: 20, 
    boxShadow: theme.shadows[3], 
  },  
  titleContainer:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    marginBottom: 8, 
  }, 
  title: {
    display:'flex', 
    alignItems:'center',
    fontSize: 24,
    "& i":{
      fontSize: 30, 
      marginLeft: 4
    }
  }, 
  dialogContent: {
    textAlign: 'center',
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '20px',
    },
  },
  dialogContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleDialog: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  iconAlert: {
    fontSize: 70,
    color: '#B0413E',
  },
  dialogFooter:{
    justifyContent: 'center', 
    margin: '12px 0px'
  }
}));

export default useStyles;
