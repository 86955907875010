import { Box } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";

interface ReportsLayoutProps {
  children: React.ElementType;
}

export default (props: ReportsLayoutProps) => {
  const { children } = props;
  return (
    <>
      <Helmet>
        <title>InfoPorcinos - PDF Render</title>
      </Helmet>
      <div>{children}</div>
    </>
  );
};
