import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export const InfotrakingContainer = styled.div`
  width: 90%;

  font-family: Roboto, sans-serif;

  & table {
    color: #afafaf;
    width: 100%;
    & td {
      height: 32px;
    }
  }
  & .tablebig {
    color: #afafaf;
    width: 100%;
    text-align: center;

    & td {
      border-bottom: 1px solid #e2e2e2;
      height: 70px;
    }
    & .containerstates {
      display: flex;
      width: 100%;
      justify-content: space-around;
      height: 67px;
      align-items: center;
      & .line {
        width: 45%;
        height: 2px;
        background-color: #afafaf;
        position: absolute;
      }
      & .containericon {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        border: 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: white;
        z-index: 1;
      }
    }
    & .process {
      background-color: #e2e2e2;
      color: #767676;
      font-weight: bold;
      border-radius: 50px;
      width: 106px;
      margin: 0 auto;
      cursor: pointer;
    }
    & h2 {
      color: #f1ae6c;
      font-family: Roboto, sans-serif;
    }
    & p {
      color: #767676;
      font-weight: bold;
    }
  }
`;
export const CardContainer = styled.div`
  background-color: white;
  border-radius: 20px;
  display: flex;
  padding: 20px 35px;
  width: 100%;
  margin-top: 30px;
  align-items: center;
  & h4 {
    margin-bottom: 0;
  }
  & .selects {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    & .searchcontainer {
      margin-left: 15px;
    }
  }
  & .iconcontainer {
    background-color: #dd9835;
    margin-left: 10px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const BreadcrumbContainerStyle = styled.div`
  padding: 33px 0;
  color: #767676;
  text-align: right;
`;

export const SpacingContainer = styled.section<SpaceProps>(space);

const indicatorColor = (state: 'finish' | 'process' | 'error' | string) => {
  const colors: any = {
    finish: `#68CE70`,
    process: `#F1AE6C`,
    error: `#E2575C`,
  };

  return colors[state] ?? `#767676`;
};

export const Stadistics = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  color: #afafaf;
  width: 112px;
  height: 67px;

  & h1 {
    margin-bottom: 0;
    color: #f1ae6c;
    font-family: Roboto, sans-serif;
    margin-right: 20px;
    font-size: 45px;
  }
  & p {
    width: 69px;
  }
`;

export const Indicator = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  ${({ state = 'wait' }: { state: any }) => {
    const color = indicatorColor(state);
    return css`
      border: 1px solid ${color};
      color: ${color};
    `;
  }}
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  z-index: 1;
`;

const Td = styled.td<any>`
  height: 21px !important;
  text-align: left;
  line-height: 20px;
  ${({ state }: { state: any }) =>
    state &&
    css`
      color: ${indicatorColor(state)};
      text-align: right;
      padding-right: 10px;
    `}
`;

export const DetailsTable = ({ process = 0, finish = 0, wait = 0 }) => (
  <table>
    <tbody>
      <tr>
        <Td state="wait">
          <b>{wait}</b>
        </Td>
        <Td>Lotes en espera</Td>
      </tr>
      <tr>
        <Td state="process">
          <b>{process}</b>
        </Td>
        <Td>Lotes en proceso</Td>
      </tr>
      <tr>
        <Td state="finish">
          <b>{finish}</b>
        </Td>
        <Td>Lotes finalizados</Td>
      </tr>
    </tbody>
  </table>
);

export const States = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
  width: 60%;
  padding: 20px 50px;
`;

export const State = styled.div`
  display: flex;
  align-items: center;
`;

export const StateIndicator = styled.div<any>`
  background: ${({ state }: { state: any }) => indicatorColor(state)};
  width: 8px;
  height: 8px;
  border-radius: 50px;
  margin-right: 15px;
`;

export default useStyles;
