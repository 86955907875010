import React from 'react';
import { Typography, Grid, Tooltip, Fab, Box } from '@material-ui/core';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from '@material-ui/data-grid';
import useStyles from './styles';
import clsx from 'clsx';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import ContentCard from 'components/ContentCard';
import FilterReports from 'components/FilterReports';
import { Link } from 'react-router-dom';
import ItemListInfo from 'components/ItemListInfo';
import ItemTotalInfo from 'components/ItemTotalInfo';
import TableFooter from 'components/datagrid-custom-components/TableFooter';
import CurrentDateIndicator from 'components/CurrentDateIndicator';
import moment from 'moment';
import dayjs from 'dayjs';
import Results from 'components/Results';

const index = () => {
  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Fecha',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'certificatenumber',
      headerName: 'Nro. Certificado',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'loteIP',
      headerName: 'Lote IP',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: 'loteceba',
      headerName: 'Lote de Ceba',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'productor',
      headerName: 'Productor',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'origenGranja',
      headerName: 'Granja Origen',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'animals',
      headerName: 'Animales',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },

    {
      field: '',
      headerName: '',
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params: GridCellParams) => (
        <Tooltip title="Ver detalles" arrow>
          <Box mr={1}>
            <Fab
              component={Link}
              to={`/app/reports/inmunecastration/details/${params.getValue(
                params.id,
                'id'
              )}`}
              color="primary"
              className={classes.viewButton}
            >
              <Box component="i" fontSize="20px" className="icon-eye" />
            </Fab>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
    {
      id: 2,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
    {
      id: 3,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
    {
      id: 4,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
    {
      id: 5,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
    {
      id: 6,
      date: '14/07/2019',
      certificatenumber: '654372',
      loteIP: '1234',
      loteceba: '1234',
      productor: 'Nombre productor',
      origenGranja: 'Nombre Granja',
      animals: '24',
    },
  ];

  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '/app/reports/inmunecastration',
      title: 'Certificados de Inmunocastración',
    },

    { link: '/app/reports', title: 'Mis Reportes' },
  ];

  const initDate = dayjs().startOf('month').format('YYYY-MM-DDT00:00:00-05:00');
  const current = dayjs().format('YYYY-MM-DDT23:59:59-05:00');

  return (
    <div>
      <div className={classes.breadContainer}>
        <CurrentDateIndicator initialDate={initDate} finalDate={current} />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <FilterReports to="/app/reports/inmunecastration/search" />

      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <ContentCard className={classes.contentCard}>
            <div>
              <Typography className={classes.paperTitles}>
                Resumen de Ingresos
              </Typography>
            </div>
            <div
              className={clsx(classes.flexContainer, classes.paddingContainer)}
            >
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={620}
                titleInfo="Total Animales Ingresados"
                avatarTotalColor="#FFC676"
                avatarTotalSize={35}
                avatarTextSize={22}
                extended
                titleInfoSize={200}
              />
              <ItemTotalInfo
                position="right"
                avatarTotalInfo={12}
                titleInfo="Total Certificados Ingresados"
                avatarTotalColor="#F1AE6C"
                avatarTotalSize={35}
                avatarTextSize={22}
                extended
                titleInfoSize={200}
              />
            </div>
          </ContentCard>
        </Grid>
        <Grid item xs={12} xl={6}>
          <ContentCard className={classes.contentCard}>
            <div>
              <Typography className={classes.paperTitles}>
                Resumen Movilización
              </Typography>
            </div>
            <div className={classes.flexContainer}>
              <ItemListInfo
                icon="icon-truck"
                titleInfo="Camiones ingresados"
                numberInfo="06"
                width="50%"
              />
              <ItemListInfo
                icon="icon-granja"
                titleInfo="Granjas Diferentes"
                numberInfo="08"
                width="50%"
              />
            </div>
            <div className={classes.flexContainer}>
              <ItemListInfo
                icon="icon-document"
                titleInfo="Falta Certificado"
                numberInfo="0"
                width="50%"
              />
              <ItemListInfo
                icon="icon-productor"
                titleInfo="Productores Diferentes"
                numberInfo="03"
                width="50%"
              />
            </div>
          </ContentCard>
        </Grid>
        <Grid item xs={12}>
          <ContentCard>
            <div className={classes.headerContent}>
              <div>
                <Typography className={classes.paperTitles}>
                  Listado de Certificados de Inmunocastración Ingresadas en el
                  día
                </Typography>
              </div>
              <div>
                <Results number={rows.length} />
              </div>
            </div>
            <div>
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                className={classes.dataGrid}
                components={{
                  Footer: TableFooter,
                }}
              />
            </div>
          </ContentCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default index;
