import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
  },
  fontTitle: {
    fontWeight: 'bold',
  },
  containerStepper:{
    width:'100%', 
    display:'flex',
    justifyContent: 'center'
  },
  stepper:{
    width:'70%',
    "& .MuiStepConnector-line":{
      marginLeft: '7px',
      marginRight: '7px'
    }
  },
  rootStepper: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    width: 40,
    height: 40,
    marginTop: '-6px',
    backgroundColor: '#dd9835',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    width: 30,
    height: 30,
    backgroundColor: '#68ce70',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  cardItem: {
    width: 120,
    height: 150,
    borderRadius: 20,
    backgroundColor: '#F3F3F3',
    '&:hover': {
      backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      },
    },
  },
  cardSelected: {
    backgroundColor: '#dd9835',
      color: '#FFF',
      '& i, p': {
        color: '#fff',
      }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardActionArea: {
    height: '100%',
  },
  iconCard: {
    fontSize: 45,
    color: '#767676',
    margin: '0px 20px',
  },
  mainText: {
    color: '#767676',
    fontSize: 13,
    fontWeight: 'bolder',
    textAlign: 'center'
  },
  gridMargin:{
    margin: '0px 24px'
  },
  subTitileColor:{
    color: '#AFAFAF',
    fontWeight: 'bold',
  },
  subTitleGray:{
    width:'100%',
    fontWeight: 600,
    margin: '24px 0px', 
    color:'#AFAFAF',
    borderBottom: '1px solid #eaeaf0',
    paddingLeft:12, 
  },
  colorHr: {
    borderColor: '#E2E2E2'
  },
  loadingColor: {
    color: '#E2E2E2'
  },
  plateContainer:{
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 40px 10px 40px'
  },
  buttonBack: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  }
}))

export { useStyles as default }