import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';
import { space } from 'styled-system';

export const StyledSegment = styled(Segment).attrs({
  basic: true,
})`
  background: #ffffff !important;
  border-radius: 20px !important;
  padding: 24px !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  ${space}
  ${({ padding }) => (padding ? `padding: ${padding} !important;` : '')};
  ${({ marginTop }) =>
    `margin-top: ${marginTop ? marginTop : '30px'} !important;`};
  @media screen and (max-width: 900px) {
    padding: 15% 15% !important;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .header {
    margin: 0 !important;
    color: #767676 !important;
    font-family: 'Roboto', arial !important;
  }
  @media screen and (max-width: 636px) {
    display: block;
    text-align: center;
    & > .header {
      display: block !important;
      margin-bottom: 20px !important;
    }
  }
`;

export const TitleDate = styled.div`
  color: #dd9835;
  border: 1px #dd9835 solid;
  padding: 5px 10px;
  border-radius: 50px;

  & > span {
    font-weight: bold;
    line-height: 10px;
  }
`;

export const Stadistics = styled.div`
  text-align: center;
  & > h1 {
    font-family: 'Roboto', arial;
    width: 100%;
    font-size: 40px;
    line-height: 40px;
    margin-top: 10px;
    color: #767676;
  }

  & > p {
    color: #afafaf;
    font-size: 16px;
    font-family: 'Roboto', Arial;
  }
`;
