import { useState, useEffect } from 'react';
import useStyles from './styles';
import constants from 'config/constants';
import { Typography, Switch, Tooltip, Box, Fab, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dayjs from 'dayjs';
import _ from 'lodash';

interface ScheduleProps {
  children: any;
  type?: number;
  data?: any;
  disabled?: boolean;
}

const index = ({ children, type, data, disabled }: ScheduleProps) => {
  const classes = useStyles();
  const days = constants.DAYS;
  const [allSelected, setAllSelected] = useState(false);
  const [allValues, setAllValues] = useState<any>([]);
  const [values, setValues] = useState<any>([]);
  const [valuesExtended, setValuesExtended] = useState<any>([]);
  const valueDia = values.map((d: any) => d.dia);
  const valueDiaExt = valuesExtended.map((d: any) => d.dia);
  const Data = data?.map((items: any) => {
    return {
      ...items,
      horaInicial: `${new Date().toDateString()} ${items?.horaInicial}`,
      horaFinal: `${new Date().toDateString()} ${items?.horaFinal}`,
      id: data.length + Math.floor(Math.random() * (9512 - 2316 + 1) + 2316),
    };
  });
  const finalData = _.groupBy(
    Data?.filter((items: any) => items.idTipoHorario === type),
    'dia'
  );

  const dataxday = days
    .map((day) => finalData[day])
    .filter((items: any) => items !== undefined);

  useEffect(() => {
    if (data) {
      setValues(dataxday?.map((x: any) => x[0]));
      setValuesExtended(
        dataxday
          ?.map((x: any) => x[1])
          ?.filter((item: any) => item !== undefined)
      );
    }
  }, [data]);

  useEffect(() => {
    setAllValues([...values, ...valuesExtended]);
    setAllSelected(values.length === 8);
  }, [values, valuesExtended]);

  const addAll = () => {
    const day = days.map((day: any) => {
      return {
        dia: day,
        horaInicial: dayjs().hour(8).minute(0).second(0).format(),
        horaFinal: dayjs().hour(17).minute(0).second(0).format(),
        id:
          values.length + Math.floor(Math.random() * (9512 - 2316 + 1) + 2316),
        idTipoHorario: type,
      };
    });
    if (allSelected) {
      setAllSelected(false);
      setValues([]);
      setValuesExtended([]);
    } else {
      setAllSelected(true);
      setValues([...day]);
    }
  };

  const addItem = (e: any) => {
    if (valueDia.includes(e)) {
      setAllSelected(false);
      const remove = values.filter((item: any) => item.dia != e);
      const removeExt = valuesExtended.filter((item: any) => item.dia != e);
      setValues(remove);
      setValuesExtended(removeExt);
    } else {
      setValues([
        ...values,
        {
          dia: e,
          horaInicial: dayjs().hour(8).minute(0).second(0).format(),
          horaFinal: dayjs().hour(17).minute(0).second(0).format(),
          id:
            values.length +
            Math.floor(Math.random() * (9512 - 2316 + 1) + 2316),
          idTipoHorario: type,
        },
      ]);
    }
  };

  const addExtended = (e: any) => {
    if (valueDiaExt.includes(e)) {
      const remove = valuesExtended.filter((item: any) => item.dia != e);
      setValuesExtended(remove);
    } else {
      setValuesExtended([
        ...valuesExtended,
        {
          dia: e,
          horaInicial: dayjs().hour(8).minute(0).second(0).format(),
          horaFinal: dayjs().hour(17).minute(0).second(0).format(),
          id:
            values.length +
            Math.floor(Math.random() * (9512 - 2316 + 1) + 2316),
          idTipoHorario: type,
        },
      ]);
    }
  };

  const handleChangeHour = (event: any, id: any, type: any) => {
    const hi = values.filter((item: any) => item.id == id);
    if (type === 1) {
      const change = hi.map((x: any) => {
        return { ...x, horaInicial: event };
      });
      const remove = values.filter((item: any) => item.id !== id);
      setValues([...remove, ...change]);
    } else {
      const change = hi.map((x: any) => {
        return { ...x, horaFinal: event };
      });
      const remove = values.filter((item: any) => item.id !== id);
      setValues([...remove, ...change]);
    }
  };

  const handleChangeHourExtended = (event: any, id: any, type: any) => {
    const hi = valuesExtended.filter((item: any) => item.id == id);
    if (type === 1) {
      const change = hi.map((x: any) => {
        return { ...x, horaInicial: event };
      });
      const remove = valuesExtended.filter((item: any) => item.id !== id);
      setValuesExtended([...remove, ...change]);
    } else {
      const change = hi.map((x: any) => {
        return { ...x, horaFinal: event };
      });
      const remove = valuesExtended.filter((item: any) => item.id !== id);
      setValuesExtended([...remove, ...change]);
    }
  };

  return (
    <>
      {children({ allValues })}
      {data ? (
        <div>
          <div className={classes.flexDivSwitchesxAll}>
            <Typography className={classes.daysStyles}>
              Seleccionar Todos
            </Typography>
            <Switch
              prefix="Activo"
              checked={allSelected}
              onChange={() => addAll()}
              className={classes.switch}
              disabled={disabled}
            />
          </div>
          <div className={classes.flexDivColumn}>
            {days.map((item: any) => {
              return (
                <div className={classes.flexDiv}>
                  <div className={classes.flexDivSwitchesxDay}>
                    <Typography className={classes.daysStyles}>
                      {item}
                    </Typography>
                    <Switch
                      prefix="Activo"
                      name={item}
                      checked={valueDia.includes(item)}
                      onChange={() => addItem(item)}
                      className={classes.switch}
                      disabled={disabled}
                    />
                  </div>
                  {!valueDia.includes(item) ? (
                    <Box marginLeft={2}>
                      <Typography color="secondary">
                        {type === 1 ? 'Cerrado' : 'Sin Restricciones'}
                      </Typography>
                    </Box>
                  ) : null}

                  <div className={classes.flexDivSchedule}>
                    {values.map((h: any) => {
                      return h.dia.includes(item) &&
                        h.idTipoHorario === type ? (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div className={classes.flexDivSwitches}>
                            <TimePicker
                              label="Hora Inicio"
                              inputVariant="outlined"
                              size="small"
                              ampm={false}
                              name={'name'}
                              value={h.horaInicial}
                              onChange={(event) =>
                                handleChangeHour(event, h.id, 1)
                              }
                              className={classes.pickerMargins}
                              disabled={disabled}
                            />
                            -
                            <TimePicker
                              label="Hora Fin"
                              inputVariant="outlined"
                              size="small"
                              ampm={false}
                              name={'name'}
                              value={h.horaFinal}
                              onChange={(event) =>
                                handleChangeHour(event, h.id, 2)
                              }
                              className={classes.pickerMargins}
                              disabled={disabled}
                            />
                          </div>

                          {
                            <>
                              {valuesExtended.map((ext: any) => {
                                return (
                                  <>
                                    {h.dia === ext.dia &&
                                    ext.idTipoHorario === type ? (
                                      <div className={classes.flexDivSwitches}>
                                        <TimePicker
                                          label="Hora Inicio extendido"
                                          format={'HH:mm:ss'}
                                          inputVariant="outlined"
                                          size="small"
                                          ampm={false}
                                          name={'namex'}
                                          value={ext.horaInicial}
                                          onChange={(event) =>
                                            handleChangeHourExtended(
                                              event,
                                              ext.id,
                                              1
                                            )
                                          }
                                          disabled={disabled}
                                          className={classes.pickerMargins}
                                        />
                                        -
                                        <TimePicker
                                          label="Hora Fin extendido"
                                          format={'HH:mm:ss'}
                                          inputVariant="outlined"
                                          size="small"
                                          ampm={false}
                                          name={'namex'}
                                          value={ext.horaFinal}
                                          onChange={(event) =>
                                            handleChangeHourExtended(
                                              event,
                                              ext.id,
                                              2
                                            )
                                          }
                                          disabled={disabled}
                                          className={classes.pickerMargins}
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })}
                              <Tooltip
                                title={
                                  valueDiaExt.includes(h.dia)
                                    ? 'Quitar Horario Extendido'
                                    : 'Agregar Horario'
                                }
                                arrow
                              >
                                <Box mr={1}>
                                  <Fab
                                    onClick={() => addExtended(h.dia)}
                                    color="primary"
                                    className={classes.viewButton}
                                    disabled={disabled}
                                  >
                                    <Box
                                      component="i"
                                      fontSize="20px"
                                      className={
                                        valueDiaExt.includes(h.dia) &&
                                        h.idTipoHorario === type
                                          ? 'icon-close'
                                          : 'icon-plus'
                                      }
                                    />
                                  </Fab>
                                </Box>
                              </Tooltip>
                            </>
                          }
                        </MuiPickersUtilsProvider>
                      ) : null;
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div>
          <div className={classes.flexDivSwitchesxAll}>
            <Typography className={classes.daysStyles}>
              Seleccionar Todos
            </Typography>
            <Switch
              prefix="Activo"
              checked={allSelected}
              onChange={() => addAll()}
              className={classes.switch}
            />
          </div>
          <div className={classes.flexDivColumn}>
            {days.map((item: any) => {
              return (
                <div className={classes.flexDiv}>
                  <div className={classes.flexDivSwitchesxDay}>
                    <Typography className={classes.daysStyles}>
                      {item}
                    </Typography>
                    <Switch
                      prefix="Activo"
                      name={item}
                      checked={valueDia.includes(item)}
                      onChange={() => addItem(item)}
                      className={classes.switch}
                    />
                  </div>
                  <div className={classes.flexDivSchedule}>
                    {values.map((h: any) => {
                      return h.dia.includes(item) ? (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div className={classes.flexDivSwitches}>
                            <TimePicker
                              label="Hora Inicio"
                              inputVariant="outlined"
                              size="small"
                              ampm={false}
                              name={'name'}
                              value={h.horaInicial}
                              onChange={(event) =>
                                handleChangeHour(event, h.id, 1)
                              }
                              className={classes.pickerMargins}
                            />
                            -
                            <TimePicker
                              label="Hora Fin"
                              inputVariant="outlined"
                              size="small"
                              ampm={false}
                              name={'name'}
                              value={h.horaFinal}
                              onChange={(event) =>
                                handleChangeHour(event, h.id, 2)
                              }
                              className={classes.pickerMargins}
                            />
                          </div>

                          {
                            <>
                              {valuesExtended.map((ext: any) => {
                                return (
                                  <>
                                    {h.dia === ext.dia ? (
                                      <div className={classes.flexDivSwitches}>
                                        <TimePicker
                                          label="Hora Inicio extendido"
                                          format={'HH:mm:ss'}
                                          inputVariant="outlined"
                                          size="small"
                                          ampm={false}
                                          name={'namex'}
                                          value={ext.horaInicial}
                                          onChange={(event) =>
                                            handleChangeHourExtended(
                                              event,
                                              ext.id,
                                              1
                                            )
                                          }
                                          className={classes.pickerMargins}
                                        />
                                        -
                                        <TimePicker
                                          label="Hora Fin extendido"
                                          format={'HH:mm:ss'}
                                          inputVariant="outlined"
                                          size="small"
                                          ampm={false}
                                          name={'namex'}
                                          value={ext.horaFinal}
                                          onChange={(event) =>
                                            handleChangeHourExtended(
                                              event,
                                              ext.id,
                                              2
                                            )
                                          }
                                          className={classes.pickerMargins}
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })}
                              <Tooltip
                                title={
                                  valueDiaExt.includes(h.dia)
                                    ? 'Quitar Horario Extendido'
                                    : 'Agregar Horario'
                                }
                                arrow
                              >
                                <Box mr={1}>
                                  <Fab
                                    onClick={() => addExtended(h.dia)}
                                    color="primary"
                                    className={classes.viewButton}
                                  >
                                    <Box
                                      component="i"
                                      fontSize="20px"
                                      className={
                                        valueDiaExt.includes(h.dia)
                                          ? 'icon-close'
                                          : 'icon-plus'
                                      }
                                    />
                                  </Fab>
                                </Box>
                              </Tooltip>
                            </>
                          }
                        </MuiPickersUtilsProvider>
                      ) : null;
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default index;
