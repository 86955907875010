import React from 'react';
import { Avatar, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

interface ItemTotalInfoProps {
  avatarTotalInfo: number | string;
  titleInfo: string;
  extended?: Boolean;
  position: 'top' | 'right' | 'bottom' | 'left';
  avatarTotalColor?: string;
  avatarTotalSize?: number;
  avatarTextSize?: number;
  titleInverted?: Boolean;
  classNameLabel?: string;
  titleInfoSize?: number;
  avatarTextColor?: string;
}

const ItemTotalInfo = ({
  avatarTotalInfo,
  titleInfo,
  extended,
  position,
  avatarTotalColor,
  avatarTotalSize,
  titleInverted,
  avatarTextSize,
  classNameLabel,
  titleInfoSize,
  avatarTextColor,
}: ItemTotalInfoProps) => {
  const classes = useStyles();
  const size = !extended
    ? {
        height: avatarTotalSize,
        width: avatarTotalSize,
      }
    : {
        height: avatarTotalSize,
      };

  const sizeInfo = { width: titleInfoSize ?? 100 };

  return (
    <div className={clsx(classes.resumeChild, position)}>
      <Avatar
        style={{
          background: avatarTotalColor,
          fontSize: avatarTextSize,
          ...size,
          color: avatarTextColor,
        }}
        className={clsx(classes.avatar, {
          [classes.extended_avatar]: extended,
          [classes.color_inverted]: titleInverted,
        })}
      >
        {avatarTotalInfo < 10 ? `0${avatarTotalInfo}` : avatarTotalInfo}
      </Avatar>

      <Typography
        style={{ ...sizeInfo }}
        className={clsx(classes.resumeInfo, classNameLabel)}
      >
        {titleInfo}
      </Typography>
    </div>
  );
};
export default ItemTotalInfo;
