import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  header: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5
  },
  headerImageContainer: {
    backgroundColor: '#EDEDED',
    height: 60,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerDetail: {
    width: '100%',
    height: 17,
    backgroundColor: '#EDEDED',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  textBold: {
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  },
})