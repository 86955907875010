import React, { useState } from "react";

import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Divider,
  Grid,
} from "@material-ui/core";

import GiveUpRole from "components/PageComponents/Profile/GiveUpRole";
import Conventions from "components/PageComponents/Profile/Conventions";

import { useSelector } from "react-redux";

import useStyles from "./styles";

const Permissions = () => {
  const classes = useStyles();
  const userType = useSelector(
    (state: any) => state.user.selectedUserType.name
  );
  const idRole = useSelector(
    (state: any) => state.user.selectedUserType.idRole
  );
  const role = useSelector((state: any) => state.user.selectedUserType.role);
  const permissions = useSelector((state: any) => state.user.navigation);

  console.log(permissions);

  return (
    <form>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography variant="h6" className={classes.title}>
            Permisos
            <i className="icon-circle-check" style={{ color: "#68CE70  " }} />
          </Typography>
          {idRole === 1 ? (
            <GiveUpRole>
              {({ handleOpenGiveUpRole }: any) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenGiveUpRole}
                >
                  Transferir Rol
                </Button>
              )}
            </GiveUpRole>
          ) : null}
        </Box>
        <Divider />
        <Box marginY={2}>
          <Typography variant="h6" color="primary">
            {`${userType} - ${role}`}
          </Typography>
          <br />
          <Typography color="secondary">
            A continuación se encuestran listados todos los permisos de acceso a
            la plataforma de acuerdo al rol asignado a este perfil.
          </Typography>
        </Box>
        <Box marginY={5}>
          <Grid container xs={12} spacing={2}>
            {permissions?.map((item: any) => {
              return (
                <>
                  {item?.children?.length !== 0 ? (
                    item?.children?.map((child: any) => (
                      <Grid item container xs={2}>
                        <Box width="100%" display="flex" alignItems="center">
                          <i
                            className={
                              child?.state
                                ? "icon-circle-check"
                                : "icon-circle-close"
                            }
                            style={{
                              color: child?.state ? "#68CE70" : "#E2575C",
                              fontSize: 20,
                              fontWeight: "bold",
                              marginRight: 12,
                            }}
                          />
                          <Typography>{child?.name}</Typography>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Grid item container xs={2}>
                      <Box width="100%" display="flex" alignItems="center">
                        <i
                          className={
                            item?.state
                              ? "icon-circle-check"
                              : "icon-circle-close"
                          }
                          style={{
                            color: item?.state ? "#68CE70" : "#E2575C",
                            fontSize: 20,
                            fontWeight: "bold",
                            marginRight: 12,
                          }}
                        />
                        <Typography>{item?.name}</Typography>
                      </Box>
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        </Box>
        <Divider />
        <Box marginY={2} width="600px">
          <Typography variant="h6" color="secondary">
            Convenciones
          </Typography>
          <Conventions
            horizontal
            items={[
              {
                label: "Usuario con Permisos para la sección ",
                state: true,
              },
              {
                label: "Usuario sin Permisos para la sección ",
                state: false,
              },
            ]}
          />
        </Box>
      </Box>
    </form>
  );
};

export default Permissions;
