import React from 'react';
import { ListItem, Typography, Card, CardActionArea } from '@material-ui/core';
import clsx from 'clsx';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import useStyles from './styles';

interface ButtonCounterProps {
  icon?: string;
  titleInfo: string;
  numberInfo: string | number;
  width?: string;
  onClick?: (event: any) => void;
}

const ButtonCounter = ({
  icon,
  titleInfo,
  numberInfo,
  width,
  onClick,
}: ButtonCounterProps) => {
  const classes = useStyles();
  return (
    <Card
      onClick={onClick}
      style={{ width: width ?? '100%' }}
      className={classes.itemList}
    >
      <CardActionArea className={classes.child}>
        {icon ? (
          <i className={clsx(`${icon}`, classes.listIcon)} />
        ) : (
          <FiberManualRecordIcon className={classes.listDefaultIcon} />
        )}
        <div className={classes.infoListContainer}>
          <Typography>{titleInfo}</Typography>
          <Typography className={classes.listNumber}>{numberInfo}</Typography>
        </div>
      </CardActionArea>
    </Card>
  );
};

export default ButtonCounter;
