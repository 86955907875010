import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  ShowcaseContainer: {
    width: "100%",
    fontFamily: "Roboto, arial",
    display: "flex",
    justifyContent: "center",
    alignItems: "top",
  },
  AddressAppend: {
    height: "100 %",
    margin: "10px 8px",
    fontSize: "19px",
  },
  AddressItem: {
    margin: "10px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
  AddressField: {
    fontSize: "19px",
    textTransform: "uppercase",
  },
  AddressUnderline: {
    width: "100%",
    height: "4px",
    border: "2px #DD9835 solid",
    borderTop: "0",
  },
  AddressLabel: {
    marginTop: "3px",
    fontSize: "10px",
    color: "#AFAFAF",
    lineHeight: "11px",
  },
}));
