import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  message: {
    fontSize: 20
  },
  topSnack: {
    top: 90
  },
  errorSnack: {
    '& .MuiPaper-root': {
      backgroundColor: '#f44336 !important'
    }
  }
}))