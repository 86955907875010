import { makeStyles } from "@material-ui/core";
import WelcomeBG from "static/img/backgrounds/WelcomeBG.svg";

const useStylesTutorial = makeStyles((theme) => ({
  card: {
    padding: 16,
    boxShadow: theme.shadows[2],
    borderRadius: 20,
    minWidth: 450,
  },
  cardTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  cardTitle: {
    margin: "0px 12px",
  },
  dataContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: 30,
  },
}));

export default useStylesTutorial;
