import { connect, useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import NotificationContext from 'lib/context/NotificationContext';

import { userSocket as socket } from 'config/socket';

import GeneralLayoutComponent from './GeneralLayoutComponent';
import { NEW_AVATAR } from 'core/redux/types/userTypes';

interface GeneralLayoutProps {
  user: any;
  selectedUserType: any;
  isLogged: boolean;
  typeUsers: any;
  signOut: any;
  token: string;
}

const GeneralLayout = (props: GeneralLayoutProps) => {
  const { user, selectedUserType, isLogged, typeUsers, token } = props;
  const { refetch: getNotifications } = useContext(NotificationContext);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isLogged || !selectedUserType.id) history.push('/auth/login');
  }, []);

  useEffect(() => {
    if (token) {
      socket.emit('join', user.username);
      socket.on('update_profile_photo', ({ uri }) => {
        dispatch({
          type: NEW_AVATAR,
          payload: uri,
        });
      });
      getNotifications({
        context: {
          headers: { 'x-auth-jwt': token },
        },
      });
    }
  }, [token]);

  if (!isLogged) return null;
  const passedProps = {
    user,
    role: selectedUserType,
    typeUsers,
  };
  return <GeneralLayoutComponent {...passedProps} />;
};

const mapStateToProps = (reducers: any) => reducers.user;
export default connect(mapStateToProps, null)(GeneralLayout);
