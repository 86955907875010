import React, { useState } from "react";

import {
  Grid,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

import CardPlan from "components/PageComponents/Profile/Suscriptions/Subscribe/components/CardPlan";
import MainTitle from "components/MainTitle";

import useStyles from "./styles";

const Subscription = () => {
  const classes = useStyles();
  const [valueHistory, setValueHistory] = useState(1);

  const priceByTime = (time: any) => {
    switch (time) {
      case 1:
        return 195000;
      case 2:
        return 230000;
      case 3:
        return 280500;
      default:
        return 0;
    }
  };

  const time = (time: any) => {
    switch (time) {
      case 1:
        return "Mes";
      case 2:
        return "Trimestre";
      case 3:
        return "Semestre";
      default:
        return "Indefinido";
    }
  };

  return (
    <>
      <Box>
        <MainTitle>Plan de Suscripción</MainTitle>
        <Typography color="secondary" style={{ marginTop: 8 }}>
          Este servicio de información permite a los Comercializadores o
          Productores el acceso a la plataforma de información InfoPorcinos para
          la consulta y descarga de los Reportes del Proceso de Beneficio
          Porcinos en la Planta de Beneficio de CENTRAL GANADERA S.A
        </Typography>
        <Box marginTop={2} marginBottom={4}>
          <RadioGroup
            row
            value={valueHistory}
            className={classes.filter}
            onChange={(event: any) => {
              setValueHistory(Number(event.target.value));
            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label="Mensual"
            />
            <FormControlLabel
              value={2}
              control={<Radio color="primary" />}
              label="Trimestral"
            />
            <FormControlLabel
              value={3}
              control={<Radio color="primary" />}
              label="Semestral"
            />
          </RadioGroup>
        </Box>
      </Box>
      <Grid container xs={12} justifyContent="center">
        <Grid item container xs={4} xl={3}>
          <CardPlan
            planName=""
            price={0}
            sponsored
            benefits={[
              "Acceso a todos los reportes del sistema",
              "1 Súper usuario",
              "2 Usuarios internos",
              "Solo estará activos 3 usuarios, si requiere más usuarios activos, deberá pagar por usuario adicional.",
            ]}
          />
        </Grid>
        <Grid item container xs={4} xl={3}>
          <CardPlan
            planName="Plan Básico"
            price={priceByTime(valueHistory)}
            time={time(valueHistory)}
            benefits={[
              "Acceso a todos los reportes del sistema",
              "1 Súper usuario",
              "2 Usuarios internos",
              "Solo estará activos 3 usuarios, si requiere más usuarios activos, deberá pagar por usuario adicional.",
            ]}
          />
        </Grid>
        <Grid item container xs={4} xl={3}>
          <CardPlan
            planName="Plan Premium"
            price={195000}
            benefits={[
              "Acceso a todos los reportes del sistema",
              "1 Súper usuario",
              "2 Usuarios internos",
              "Solo estará activos 3 usuarios, si requiere más usuarios activos, deberá pagar por usuario adicional.",
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Subscription;
