import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  contentCard:{
    padding: '25px 50px 25px 50px !important', 
  },
  paperContainer:{
    backgroundColor: '#F2F2F2 !important',
    padding: '5px'
  },
  paperTitle:{
    backgroundColor: '#F2F2F2 !important',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  fontPaper:{
    fontWeight: 'bold',
    color:'#AFAFAF'
  },
  datePaper:{
    fontWeight: 'bold',
    color:'#DD9835'
  },
  fontTitlePaper:{
    fontWeight: 'bold',
    color: '#767676'
  },
  dataUserContainer:{
    margin: 12
  },
  listContainer:{
    color:theme.palette.primary.main
  },
  itemListData:{
    display:'flex',
    justifyContent:'space-between',
    padding:'4px 0',
    "& .MuiTypography-root":{
      color:"#767676", 
    }
  },
  itemChild:{
    fontWeight:'bold'
  },
  headTable:{
    background: '#F2F2F2',
    "& .MuiTableCell-head":{
      color: '#767676',
      fontSize: 'smaller',
    }
  },
  rowTable:{
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
  },
  breadContainer:{
    display:'flex',
    justifyContent:'space-between',
  }, 

}));

export { useStyles as default }