import { GET_GENERAL_INFO } from '../types/userProfileTypes';
import { SIGN_OUT } from '../types/userTypes';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_GENERAL_INFO:
      return {
        ...state,
        generalInfo: {
          completed: action.payload.completo,
          data: {
            ...action.payload.data,
            norte: action.payload.data.norte ? 1 : 2,
            este: action.payload.data.este ? 1 : 2,
          },
        },
      };
    case SIGN_OUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
