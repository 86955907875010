import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

import {
  GET_VEHICLES_COMPANY,
  GET_VEHICLES_DRIVER,
} from 'core/graphql/queries/transport';
import { PUT_STATUS_VEHICLE } from 'core/graphql/mutations/transport';

import Results from 'components/Results';
import CardDetailVehicle from 'components/TransportComponents/CardDetailVehicle/index';
import InformativeScreen from 'components/InformativeScreens';
import AcceptDialog from 'components/PageComponents/Profile/AcceptDialog';
import MainTitle from 'components/MainTitle';

import DialogAddVehicle from './DialogAddVehicle/index';

import useStyles from './styles';

type DialogProps = {
  open: boolean;
  data: any;
  state: string;
  isCompany?: boolean;
  handleClose: () => void;
};

const DialogDetailDriver = ({
  open,
  data,
  state,
  isCompany,
  handleClose,
}: DialogProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const [openAddVehicle, setOpenAddVehicle] = useState({
    open: false,
    data: {},
  });
  const [openNotification, setOpenNotification] = useState({
    open: false,
    vehicle: '',
    action: false,
    idVehicle: 0,
    message: '',
  });

  const [
    getVehiclesD,
    { data: dataVehicle, loading: loadingVehicle, error: errorVehicle },
  ] = useLazyQuery(GET_VEHICLES_DRIVER, {
    fetchPolicy: 'network-only',
  });

  const [
    getVehiclesC,
    {
      data: dataVehiclesCompany,
      loading: loadingVehiclesCompany,
      error: errorVehiclesCompany,
    },
  ] = useLazyQuery(GET_VEHICLES_COMPANY, {
    fetchPolicy: 'network-only',
  });

  const [putStatusVehicle, { loading: loadingStatusVehicle }] = useMutation(
    PUT_STATUS_VEHICLE,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const activeVehicles: number[] = [];
  const inactiveVehicles: number[] = [];
  dataVehicle?.getVehiclesXDriver?.data?.map((item: any) => {
    if (item?.estado) {
      activeVehicles.push(item);
    } else {
      inactiveVehicles.push(item);
    }
  });

  const activeVehiclesCompany: number[] = [];
  const inactiveVehiclesCompany: number[] = [];
  dataVehiclesCompany?.getVehiclesXCompany?.data.map((item: any) => {
    if (item?.estado) {
      activeVehiclesCompany.push(item);
    } else {
      inactiveVehiclesCompany.push(item);
    }
  });

  const changeStatusVehicle = (
    idDriver: number,
    idVehicle: number,
    stateVehicle: boolean
  ) => {
    putStatusVehicle({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.putVehicleStatus.data.success === 1) {
          enqueueSnackbar(
            state
              ? 'Vehículo Activado Exitosamente'
              : 'Vehículo Inactivado Exitosamente',
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }
          );
          handleCloseDialogNotification();
          if (state === 'active' && activeVehicles.length === 1) {
            handleClose();
          } else if (state === 'inactive' && inactiveVehicles.length === 1) {
            handleClose();
          }
        } else {
          enqueueSnackbar(mutationResult.data.putVehicleStatus.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        idConductor: idDriver,
        idVehiculo: idVehicle,
        estadoConductor: stateVehicle,
      },
    });
  };

  const handleOpenDialogAddVehicle = (data: any) => {
    setOpenAddVehicle({
      open: true,
      data: data,
    });
  };

  const handleCloseDialogAddVehicle = () => {
    setOpenAddVehicle({
      open: false,
      data: {},
    });
  };

  const handleOpenDialogNotification = (
    vehicle: string,
    action: boolean,
    idVehicle: number,
    message: string
  ) => {
    setOpenNotification({
      open: true,
      vehicle: vehicle,
      action: action,
      idVehicle: idVehicle,
      message: message,
    });
  };

  const handleCloseDialogNotification = () => {
    setOpenNotification({
      open: false,
      vehicle: '',
      action: false,
      idVehicle: 0,
      message: '',
    });
  };

  useEffect(() => {
    if (!openAddVehicle.open && !isCompany && open) {
      getVehiclesD({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idConductor: data.id },
      });
    } else if (!openAddVehicle.open && isCompany && open) {
      getVehiclesC({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idEmpresaTransportadora: data.id },
      });
    }
  }, [openAddVehicle.open, isCompany, open]);

  useEffect(() => {
    if (open && !isCompany) {
      getVehiclesD({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idConductor: data.id },
      });
    } else if (open && isCompany) {
      getVehiclesC({
        context: { headers: { 'x-auth-jwt': token } },
        variables: { idEmpresaTransportadora: data.id },
      });
    }
  }, [isCompany, open]);

  const message = (placa: any) => {
    if (!isCompany && state === 'active' && activeVehicles.length === 1) {
      return `El vehículo con la placa ${placa} es el único asociado al conductor ${data.name}, si lo inactiva tambien se inactivara este conductor, ¿Esta seguro que desea inactiva este vehículo?`;
    } else if (
      !isCompany &&
      state === 'inactive' &&
      inactiveVehicles.length === 1
    ) {
      return `¿Está seguro que desea activar el Vehículo ${placa}?`;
    } else if (!isCompany && state === 'active' && activeVehicles.length > 1) {
      return `¿Está seguro que desea inactivar el Vehículo ${placa}?`;
    } else if (
      !isCompany &&
      state === 'inactive' &&
      inactiveVehicles.length > 1
    ) {
      return `¿Está seguro que desea activar el Vehículo ${placa}?`;
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        scroll="body"
        className={clsx({
          [classes.borderRadiudDialog]: true,
          [classes.hiddenDialog]: openAddVehicle.open,
        })}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dia
        log-title"
      >
        <DialogContent>
          <Grid
            container
            justifyContent="flex-start"
            style={{ marginBottom: '5px' }}
          >
            <Grid item container xs={8} alignItems="center">
              <MainTitle>
                {isCompany
                  ? 'Vehículos asociados a la empresa:'
                  : 'Vehículos asociados al conductor:'}
              </MainTitle>
              <Typography>
                <span className={classes.fontName}>{data.name}</span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.gridButton}
              alignItems="center"
            >
              <Box mr={5}>
                <Results
                  number={
                    isCompany
                      ? state === 'active'
                        ? activeVehiclesCompany.length
                        : inactiveVehiclesCompany.length
                      : state === 'active'
                      ? activeVehicles.length
                      : inactiveVehicles.length
                  }
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialogAddVehicle(data)}
              >
                Agregar Vehículo
              </Button>
            </Grid>
          </Grid>

          {isCompany ? (
            <Grid container className={classes.cardGridContainer}>
              {state === 'active' &&
                activeVehiclesCompany.length !== 0 &&
                activeVehiclesCompany.map(
                  (item: any, index: number) =>
                    item?.estado && (
                      <Grid item key={index} style={{ margin: 18 }}>
                        <CardDetailVehicle
                          id={item?.idVehiculo}
                          plate={item?.placa}
                          ubication={item?.ciudad}
                          vehicleType={item?.descripcionTipoVehiculo}
                          loadType={item?.descripcionTipoCarga}
                          capacity={item?.capacidadCarga}
                        />
                      </Grid>
                    )
                )}
              {state === 'inactive' &&
                inactiveVehiclesCompany.length !== 0 &&
                inactiveVehiclesCompany.map(
                  (item: any, index: number) =>
                    !item?.estado && (
                      <Grid item key={index} style={{ margin: 18 }}>
                        <CardDetailVehicle
                          id={item?.idVehiculo}
                          plate={item?.placa}
                          ubication={item?.ciudad}
                          vehicleType={item?.descripcionTipoVehiculo}
                          loadType={item?.descripcionTipoCarga}
                          capacity={item?.capacidadCarga}
                        />
                      </Grid>
                    )
                )}
            </Grid>
          ) : (
            <Grid container className={classes.cardGridContainer}>
              {state === 'active' &&
                activeVehicles.map(
                  (item: any, index: number) =>
                    item?.estado && (
                      <Grid item key={index} style={{ margin: 18 }}>
                        <CardDetailVehicle
                          state="inactive"
                          id={item?.idVehiculo}
                          plate={item?.placa}
                          ubication={item?.ciudad}
                          vehicleType={item?.descripcionTipoVehiculo}
                          loadType={item?.descripcionTipoCarga}
                          capacity={item?.capacidadCarga}
                          actionFuncion={() =>
                            handleOpenDialogNotification(
                              item?.placa,
                              false,
                              item?.idVehiculo,
                              String(message(item?.placa))
                            )
                          }
                        />
                      </Grid>
                    )
                )}
              {state === 'inactive' &&
                inactiveVehicles.map(
                  (item: any, index: number) =>
                    !item?.estado && (
                      <Grid item key={index} style={{ margin: 18 }}>
                        <CardDetailVehicle
                          state="active"
                          id={item?.idVehiculo}
                          plate={item?.placa}
                          ubication={item?.ciudad}
                          vehicleType={item?.descripcionTipoVehiculo}
                          loadType={item?.descripcionTipoCarga}
                          capacity={item?.capacidadCarga}
                          actionFuncion={() =>
                            handleOpenDialogNotification(
                              item?.placa,
                              true,
                              item?.idVehiculo,
                              String(message(item?.placa))
                            )
                          }
                        />
                      </Grid>
                    )
                )}
            </Grid>
          )}

          {isCompany &&
            state === 'inactive' &&
            inactiveVehiclesCompany.length === 0 &&
            !loadingVehiclesCompany && (
              <InformativeScreen
                type="transport"
                subtitle="¡No encontramos nada!"
                info="Esta Empresa no tiene vehículos inactivos asociados."
                onlyError
              />
            )}

          {isCompany &&
            state === 'active' &&
            activeVehiclesCompany.length === 0 &&
            !loadingVehiclesCompany && (
              <InformativeScreen
                type="transport"
                subtitle="¡No encontramos nada!"
                info="Esta Empresa no tiene vehículos activos asociados."
                onlyError
              />
            )}

          {!isCompany &&
            state === 'inactive' &&
            inactiveVehicles.length === 0 &&
            !loadingVehicle && (
              <InformativeScreen
                type="transport"
                subtitle="¡No encontramos nada!"
                info="Este conductor no tiene vehículos inactivos asociados."
                onlyError
              />
            )}

          {!isCompany &&
            state === 'active' &&
            activeVehicles.length === 0 &&
            !loadingVehicle && (
              <InformativeScreen
                type="transport"
                subtitle="¡No encontramos nada!"
                info="Este conductor no tiene vehículos activos asociados."
                onlyError
              />
            )}

          {isCompany
            ? loadingVehiclesCompany && (
                <Box mt={5} mb={3}>
                  <Grid container justifyContent="center">
                    <CircularProgress color="primary" thickness={5} size={60} />
                  </Grid>
                  <Grid container justifyContent="center">
                    <Box mt={1}>
                      <Typography>Cargando ...</Typography>
                    </Box>
                  </Grid>
                </Box>
              )
            : loadingVehicle && (
                <Box mt={5} mb={3}>
                  <Grid container justifyContent="center">
                    <CircularProgress color="primary" thickness={5} size={60} />
                  </Grid>
                  <Grid container justifyContent="center">
                    <Box mt={1}>
                      <Typography>Cargando ...</Typography>
                    </Box>
                  </Grid>
                </Box>
              )}
        </DialogContent>

        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button variant="contained" onClick={handleClose} color="primary">
              Aceptar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <DialogAddVehicle
        open={openAddVehicle.open}
        data={openAddVehicle.data}
        isCompnay={isCompany}
        handleClose={handleCloseDialogAddVehicle}
      />
      <AcceptDialog
        onClick={() =>
          changeStatusVehicle(
            data.id,
            openNotification.idVehicle,
            openNotification.action
          )
        }
        discardModal={true}
        open={openNotification.open}
        onClose={() => handleCloseDialogNotification()}
        icon="icon-circle-alert"
        modalTitle={openNotification.message}
        modalDescription="Este cambio es reversible, puede volver a activar este Vehículo cuando desee desde esta misma opción."
      />
    </>
  );
};
export default DialogDetailDriver;
