import { makeStyles } from "@material-ui/core";
import WelcomeBG from "static/img/backgrounds/WelcomeBG.svg";

const useStylesTutorial = makeStyles((theme) => ({
  containerWelcome: {
    width: "100%",
    height: "100vh",
    background: `url(${WelcomeBG})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    position: "relative",
  },
  contentWelcome: {
    width: "45%",
    marginTop: "25vh",
    padding: "40px",
  },
  titleWelcome: {
    fontWeight: "bold",
    marginBottom: "24px",
  },
  textWelcome: {
    fontSize: 16,
    marginBottom: "24px",
  },
  steps: {
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    "& .MuiStepLabel-completed": {
      color: "#AFAFAF",
    },
  },
  rootStepper: {
    backgroundColor: "#AFAFAF",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-8px",
  },
  active: {
    backgroundColor: "#dd9835",
    boxShadow: theme.shadows[3],
  },
  completed: {
    backgroundColor: "#68ce70",
  },
  containerSteps: {
    display: "flex",
    justifyContent: "center",
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  pigContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));

export default useStylesTutorial;
