import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import client from 'config/client';
import theme from 'config/theme';

import { NotificationProvider } from 'lib/context/NotificationContext';

import { GlobalStyles, FormsGlobalStyles } from 'core/styles/_globals';
import { ButtonsGlobalStyles } from 'core/styles/_buttons';

import { SnackbarLayout } from 'layouts';

import { Main as Routes } from 'routes';

import 'moment/locale/es';
import moment from 'moment';

function App() {
  moment.locale('es');

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <NotificationProvider>
            <GlobalStyles />
            <FormsGlobalStyles />
            <ButtonsGlobalStyles />
            <SnackbarLayout>
              <Routes />
            </SnackbarLayout>
          </NotificationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
