import axios from "axios";

import config from "config/secrets"

const activeUser = async (IdUsuario: any) => {
  return await axios
  
    .post(
      `${config.AUTH}${config.APP_URI}${config.APP_VERSION}/activate-sistem-user/${IdUsuario}`, 
    )
    .then((response) => response.data)
    .catch((error) => error);
};

export default activeUser;
