import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const AuthLayoutContent = styled.div`
  display: inline-block;
  width: 100%;
`;

export const Footer = styled.div`
  width: 100%;
  text-align: center;
  background: #121212;
  color: #ffffff;
  padding: 15px 5px;
  font-family: 'Roboto', sans-serif;
  position: absolute;
`;

export const useStyles = makeStyles((theme: Theme) => ({
  containerBanner: {
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'none',
    },
  },
}));
