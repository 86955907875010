import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  TextField,
  StepIconProps,
  Grow,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Fab,
  Card,
  Link,
  Typography,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import clsx from 'clsx';
import { useHistory } from 'react-router';

import unmaskPhone from 'lib/helpers/unmaskPhone';
import {
  DriverValidationStepOne,
  DriverValidationStepThree,
  DriverValidationStepTwo,
  VehicleValidationStepOne,
  VehicleValidationStepTwo,
} from 'lib/validations/TransportValidation';
import constants from 'config/constants';

import PhoneNumber from 'components/PhoneNumber';
import SelectCountry from 'components/SelectCountry/index';
import DocumentTypes from 'components/DocumentTypes';
import InputPlate from 'components/InputPlate/index';
import Direction from 'components/Direction/index';
import GobackLink from 'components/GobackLink';
import ContentCard from 'components/ContentCard';
import MainTitle from 'components/MainTitle';
import Breadcrumb, { BreadcrumbProps } from 'components/BreadcrumbBuilder';
import CardSelectDriver from 'components/TransportComponents/CardSelectDriver';

import DialogConfirmData from '../../Details/DialogConfirmData/index';
import { CREATE_VEHICLE } from 'core/graphql/mutations/transport';
import {
  GET_DRIVER,
  GET_TYPE_LOAD,
  GET_VEHICLES_EXISTS,
  TYPE_VEHICLE,
} from 'core/graphql/queries/transport';

import useStyles from './styles';
import Loader from 'components/Loader';

type CompononentProps = {
  id: number;
  idTypeService: number;
};

const RegisterVehicleXDriver = ({ id, idTypeService }: CompononentProps) => {
  const classes = useStyles();
  const token = useSelector((state: any) => state.user.token);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const userType = useSelector(
    (state: any) => state.user.selectedUserType.idUserType
  );
  const [openNotificationConfirm, setOpenNotificationConfirm] = useState({
    open: false,
    icon: '',
    title: '',
    message: '',
  });

  const [conf, setConf] = useState(false);
  const [typeLoad, setTypeLoad] = useState('');
  const [typeVehicle, setTypeVehicle] = useState('');
  const [serviceVehicle, setServiceVehichle] = useState('');
  const [stepFormVehicle, setStepFormVehicle] = useState(0);
  const pageBreadcrumbs: BreadcrumbProps[] = [
    {
      link: '#',
      title: 'Registrar Conductor',
    },
    { link: '/app/network/transports', title: 'Gestión de Transporte' },
  ];

  const {
    data: dataTypeVehicle,
    loading: loadingTypeVehicle,
    error: errorTypeVehicle,
  } = useQuery(TYPE_VEHICLE, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const {
    data: dataTypeLoad,
    loading: loadingTypeLoad,
    error: errorTypeLoad,
  } = useQuery(GET_TYPE_LOAD, {
    context: { headers: { 'x-auth-jwt': token } },
  });

  const service = [
    {
      id: 1,
      description: 'PUBLICO',
    },
    {
      id: 2,
      description: 'PRIVADO',
    },
  ];

  const handleChangeTypeLoad = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeLoad(event.target.value);
  };

  const handleChangeTypeVehichle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTypeVehicle(event.target.value);
  };

  const handleChangeServiceVehicle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setServiceVehichle(event.target.value);
  };

  const { data, loading, error, refetch } = useQuery(GET_DRIVER, {
    context: { headers: { 'x-auth-jwt': token } },
    variables: { idConductor: id },
  });

  const [createVehicle, { loading: loadingVehicle }] = useMutation(
    CREATE_VEHICLE,
    {
      context: { headers: { 'x-auth-jwt': token } },
      onError(error) {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      },
    }
  );

  const postVehicle = (data: any) => {
    createVehicle({
      update: (proxy, mutationResult) => {
        if (mutationResult.data.postVehicle.data.success === 1) {
          enqueueSnackbar('Vehículo creado exitosamente.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
          history.push('/app/network/transports');
        } else if (mutationResult.data.postVehicle.data.success === 2) {
          enqueueSnackbar(
            'El vehículo ingresado ya existe, quedara asociado.',
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'info',
            }
          );
          history.push('/app/network/transports');
        } else {
          enqueueSnackbar(mutationResult.data.postVehicle.data.message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'error',
          });
        }
      },
      variables: {
        numeroLicenciaTransito: Number(data.licenseNumber),
        numeroPlaca: data.lettersPlate.toUpperCase() + data.numberPlate,
        idPaisPlaca: Number(data.country),
        idDepartamentoPlaca: Number(data.departament),
        idCiudadPlaca: Number(data.city),
        marca: data.vehicleBrand,
        modelo: data.vehicleModel,
        color: data.color,
        idEmpresaTransportadora: 0,
        idTipoVehiculo: Number(data.vehicleType),
        servicio: data.service,
        idTipoCarga: data.loadType,
        capacidadCarga: data.capacity,
        numeroPisos: Number(data.numberFloors),
        idTipoDocumentoPropietario: Number(data.documentType),
        numeroDocumentoPropietario: Number(data.documentOwner),
        primerNombrePropietario: data.firstNameOwner,
        segundoNombrePropietario: data.secondNameOwner,
        primerApellidoPropietario: data.surnameOwner,
        segundoApellidoPropietario: data.secondSurnameOwner,
        idIndicativoCelularPropietario: Number(data.indicativeMobile),
        celularPropietario: data.mobile,
        emailPropietario: data.email,
        idTipoServicio: idTypeService,
        idTipoUsuario: Number(userType),
        idConductor: Number(id),
      },
    });
  };

  useEffect(() => {
    setConf(false);
  }, [openNotificationConfirm]);

  const handleNConfirm = (data: any) => {
    setOpenNotificationConfirm({
      open: false,
      icon: '',
      title: '',
      message: '',
    });
    setConf(true);
  };

  const handleOpenNConfirm = (
    icon: 'alert' | 'success' | 'error',
    title: string,
    message: string
  ) => {
    setOpenNotificationConfirm({
      open: true,
      icon: icon,
      title: title,
      message: message,
    });
  };

  const handleCloseNConfirm = () => {
    setOpenNotificationConfirm({
      open: false,
      icon: '',
      title: '',
      message: '',
    });
  };

  const formikVehicle = useFormik({
    initialValues: {
      licenseNumber: '',
      lettersPlate: '',
      numberPlate: '',
      country: constants.DEFAULT_COUNTRY_ID,
      departament: constants.DEFAULT_DEPARTMENT_ID,
      city: constants.DEFAULT_CITY_ID,
      vehicleBrand: '',
      vehicleModel: '',
      color: '',
      vehicleType: '',
      service: '',
      numberFloors: '',
      loadType: '',
      capacity: '',
      firstNameOwner: '',
      secondNameOwner: '',
      surnameOwner: '',
      secondSurnameOwner: '',
      documentType: 1,
      documentOwner: '',
      indicativeMobile: constants.DEFAULT_COUNTRY_ID,
      mobile: '',
      email: '',
    },
    validationSchema:
      stepFormVehicle === 0
        ? VehicleValidationStepOne
        : VehicleValidationStepTwo,
    validateOnChange: false,
    onSubmit: (values) => {
      switch (stepFormVehicle) {
        case 0:
          setStepFormVehicle(stepFormVehicle + 1);
          break;
        case 1:
          postVehicle(values);
          break;
        default:
          break;
      }
    },
  });

  const [
    getDataVehicleExists,
    { data: dataVehicleE, error: errorVehicleE, loading: loadingVehicleE },
  ] = useLazyQuery(GET_VEHICLES_EXISTS);

  const resultVehicleE = dataVehicleE?.getExistVehicle?.data;

  useEffect(() => {
    if (dataVehicleE) {
      handleOpenNConfirm(
        'alert',
        'El Vehículo con la placa ingresada ya existe',
        '¿Desea utilizar los datos guardados de este Vehículo?'
      );
    }
  }, [dataVehicleE]);

  if (dataVehicleE && conf) {
    formikVehicle.values.licenseNumber = resultVehicleE?.numeroLicenciaTransito;
    formikVehicle.values.country = resultVehicleE?.idPaisPlaca;
    formikVehicle.values.departament = resultVehicleE?.idDepartamentoPlaca;
    formikVehicle.values.city = resultVehicleE?.idCiudadPlaca;
    formikVehicle.values.vehicleBrand = resultVehicleE?.marca;
    formikVehicle.values.vehicleModel = resultVehicleE?.modelo;
    formikVehicle.values.color = resultVehicleE?.color;
    formikVehicle.values.vehicleType = resultVehicleE?.idTipoVehiculo;
    formikVehicle.values.service = resultVehicleE?.servicio;
    formikVehicle.values.numberFloors = resultVehicleE?.numeroPisos;
    formikVehicle.values.loadType = resultVehicleE?.idTipoCarga;
    formikVehicle.values.capacity = resultVehicleE?.capacidadCarga;
    formikVehicle.values.firstNameOwner =
      resultVehicleE?.primerNombrePropietario;
    formikVehicle.values.secondNameOwner =
      resultVehicleE?.segundoNombrePropietario;
    formikVehicle.values.surnameOwner =
      resultVehicleE?.primerApellidoPropietario;
    formikVehicle.values.secondSurnameOwner =
      resultVehicleE?.segundoApellidoPropietario;
    formikVehicle.values.documentType =
      resultVehicleE?.idTipoDocumentoPropietario;
    formikVehicle.values.documentOwner =
      resultVehicleE?.numeroDocumentoPropietario;
    formikVehicle.values.indicativeMobile =
      resultVehicleE?.idIndicativoCelularPropietario;
    formikVehicle.values.mobile = resultVehicleE?.celularPropietario;
    formikVehicle.values.email = resultVehicleE?.emailPropietario;
  }

  const verifyVehicle = (numeroPlaca: string) => {
    getDataVehicleExists({
      variables: { numeroPlaca: numeroPlaca },
      context: { headers: { 'x-auth-jwt': token } },
    });
  };

  return (
    <>
      <Loader visible={loading}>Cargando...</Loader>
      <div className={classes.breadContainer}>
        <GobackLink />
        <Breadcrumb breadcrumbs={pageBreadcrumbs} />
      </div>

      <ContentCard>
        <Box mb={2}>
          <Grid container justifyContent="center">
            <MainTitle>Registrar Vehículo al Conductor:</MainTitle>
            <Typography>
              <span className={classes.fontPlate}>
                {data?.getDetailDriver?.data?.primerNombre +
                  ' ' +
                  data?.getDetailDriver?.data?.segundoNombre +
                  ' ' +
                  data?.getDetailDriver?.data?.primerApellido +
                  ' ' +
                  data?.getDetailDriver?.data?.segundoApellido}
              </span>
            </Typography>
          </Grid>
        </Box>

        <form onSubmit={formikVehicle.handleSubmit}>
          <Grid container>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={1}
            >
              <Fab
                color="primary"
                onClick={() => setStepFormVehicle(stepFormVehicle - 1)}
                className={clsx({
                  [classes.btnStep]: true,
                  [classes.hiddenButton]: stepFormVehicle === 0,
                })}
              >
                <Box
                  component="i"
                  fontSize="20px"
                  fontWeight="bold"
                  className="icon-arrow-left"
                />
              </Fab>
            </Grid>
            <Grid item xs={10}>
              {stepFormVehicle === 0 && (
                <Grow in timeout={800}>
                  <div>
                    <Box marginBottom={3} marginTop={1}>
                      <MainTitle fontSize="h6">
                        Datos Generales del Vehículo
                        <span className={classes.fontInfoStep}>
                          (Paso {stepFormVehicle + 1} de 2)
                        </span>
                      </MainTitle>
                    </Box>

                    <Grid container>
                      <TextField
                        fullWidth
                        label="Número de Licencia"
                        variant="outlined"
                        size="small"
                        name="licenseNumber"
                        value={formikVehicle.values.licenseNumber}
                        onChange={formikVehicle.handleChange}
                        error={
                          formikVehicle.touched.licenseNumber &&
                          Boolean(formikVehicle.errors.licenseNumber)
                        }
                        helperText={
                          formikVehicle.touched.licenseNumber &&
                          formikVehicle.errors.licenseNumber
                        }
                        required
                      />
                    </Grid>

                    <Box mt={2}>
                      <Grid spacing={2}>
                        <Box mb={1}>
                          <Grid container spacing={2}>
                            <Grid
                              container
                              item
                              spacing={2}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                                className={classes.plateContainer}
                              >
                                <InputPlate
                                  names={{
                                    letters: 'lettersPlate',
                                    numbers: 'numberPlate',
                                  }}
                                  values={{
                                    letters: formikVehicle.values.lettersPlate,
                                    numbers: formikVehicle.values.numberPlate,
                                  }}
                                  errors={{
                                    letters: formikVehicle.errors.lettersPlate,
                                    numbers: formikVehicle.errors.numberPlate,
                                  }}
                                  helperText={{
                                    letters: formikVehicle.errors
                                      .lettersPlate as string,
                                    numbers: formikVehicle.errors
                                      .numberPlate as string,
                                  }}
                                  onChange={formikVehicle.handleChange}
                                  onBlur={() =>
                                    verifyVehicle(
                                      `${
                                        formikVehicle.values.lettersPlate +
                                        formikVehicle.values.numberPlate
                                      }`
                                    )
                                  }
                                  ciudad={Number(formikVehicle.values.city)}
                                  width={350}
                                />
                              </Grid>

                              <Grid item xs={8}>
                                <SelectCountry
                                  direction="vertical"
                                  names={{
                                    country: 'country',
                                    departament: 'departament',
                                    city: 'city',
                                  }}
                                  values={{
                                    country: formikVehicle.values.country,
                                    departament:
                                      formikVehicle.values.departament,
                                    city: formikVehicle.values.city,
                                  }}
                                  errors={{
                                    country: formikVehicle.errors.country,
                                    departament:
                                      formikVehicle.errors.departament,
                                    city: formikVehicle.errors.city,
                                  }}
                                  onChange={formikVehicle.handleChange}
                                  helperText={{
                                    country: formikVehicle.errors.country,
                                    departament:
                                      formikVehicle.errors.departament,
                                    city: formikVehicle.errors.city,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mb={1.5}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="Marca del Vehículo"
                                variant="outlined"
                                size="small"
                                name="vehicleBrand"
                                value={formikVehicle.values.vehicleBrand}
                                onChange={formikVehicle.handleChange}
                                error={
                                  formikVehicle.touched.vehicleBrand &&
                                  Boolean(formikVehicle.errors.vehicleBrand)
                                }
                                helperText={
                                  formikVehicle.touched.vehicleBrand &&
                                  formikVehicle.errors.vehicleBrand
                                }
                                required
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="Modelo del Vehículo"
                                variant="outlined"
                                size="small"
                                name="vehicleModel"
                                type="number"
                                value={formikVehicle.values.vehicleModel}
                                onChange={formikVehicle.handleChange}
                                error={
                                  formikVehicle.touched.vehicleModel &&
                                  Boolean(formikVehicle.errors.vehicleModel)
                                }
                                helperText={
                                  formikVehicle.touched.vehicleModel &&
                                  formikVehicle.errors.vehicleModel
                                }
                                onInput={(e: any) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 9);
                                }}
                                required
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="Color del Vehículo"
                                variant="outlined"
                                size="small"
                                name="color"
                                value={formikVehicle.values.color}
                                onChange={formikVehicle.handleChange}
                                error={
                                  formikVehicle.touched.color &&
                                  Boolean(formikVehicle.errors.color)
                                }
                                helperText={
                                  formikVehicle.touched.color &&
                                  formikVehicle.errors.color
                                }
                                required
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mb={1.5}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="Tipo de Vehículo"
                                variant="outlined"
                                size="small"
                                name="vehicleType"
                                select
                                value={formikVehicle.values.vehicleType}
                                onChange={(e: any) => {
                                  formikVehicle.handleChange(e);
                                  handleChangeTypeVehichle(e);
                                }}
                                error={
                                  formikVehicle.touched.vehicleType &&
                                  Boolean(formikVehicle.errors.vehicleType)
                                }
                                helperText={
                                  formikVehicle.touched.vehicleType &&
                                  formikVehicle.errors.vehicleType
                                }
                                required
                              >
                                {loadingTypeVehicle ? (
                                  <CircularProgress
                                    variant="indeterminate"
                                    size={25}
                                  />
                                ) : errorTypeVehicle ? (
                                  <p>Error</p>
                                ) : (
                                  dataTypeVehicle?.getTypeVehicle.map(
                                    (item: any) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.description}
                                      </MenuItem>
                                    )
                                  )
                                )}
                              </TextField>
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                label="Tipo de Servicio"
                                select
                                fullWidth
                                variant="outlined"
                                size="small"
                                name="service"
                                value={formikVehicle.values.service}
                                onChange={(e: any) => {
                                  formikVehicle.handleChange(e);
                                  handleChangeServiceVehicle(e);
                                }}
                                error={
                                  formikVehicle.touched.service &&
                                  Boolean(formikVehicle.errors.service)
                                }
                                helperText={
                                  formikVehicle.touched.service &&
                                  formikVehicle.errors.service
                                }
                                required
                              >
                                {service.map((item: any) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.description}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="Número de Pisos"
                                variant="outlined"
                                size="small"
                                name="numberFloors"
                                value={formikVehicle.values.numberFloors}
                                onChange={formikVehicle.handleChange}
                                error={
                                  formikVehicle.touched.numberFloors &&
                                  Boolean(formikVehicle.errors.numberFloors)
                                }
                                helperText={
                                  formikVehicle.touched.numberFloors &&
                                  formikVehicle.errors.numberFloors
                                }
                                required
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mb={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              <TextField
                                label="Tipo de Carga"
                                select
                                fullWidth
                                variant="outlined"
                                size="small"
                                name="loadType"
                                value={formikVehicle.values.loadType}
                                onChange={(e: any) => {
                                  formikVehicle.handleChange(e);
                                  handleChangeTypeLoad(e);
                                }}
                                error={
                                  formikVehicle.touched.loadType &&
                                  Boolean(formikVehicle.errors.loadType)
                                }
                                helperText={
                                  formikVehicle.touched.loadType &&
                                  formikVehicle.errors.loadType
                                }
                                required
                              >
                                {loadingTypeLoad ? (
                                  <CircularProgress
                                    variant="indeterminate"
                                    size={25}
                                  />
                                ) : errorTypeLoad ? (
                                  <p>Error</p>
                                ) : (
                                  dataTypeLoad?.getTypeLoad?.map(
                                    (item: any) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.description}
                                      </MenuItem>
                                    )
                                  )
                                )}
                              </TextField>
                            </Grid>

                            <Grid item xs={3}>
                              <TextField
                                fullWidth
                                label="Capacidad"
                                variant="outlined"
                                size="small"
                                name="capacity"
                                value={formikVehicle.values.capacity}
                                onChange={formikVehicle.handleChange}
                                error={
                                  formikVehicle.touched.capacity &&
                                  Boolean(formikVehicle.errors.capacity)
                                }
                                helperText={
                                  formikVehicle.touched.capacity &&
                                  formikVehicle.errors.capacity
                                }
                                required
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                label="Unidad"
                                select
                                fullWidth
                                variant="outlined"
                                size="small"
                                name="loadType"
                                value={formikVehicle.values.loadType}
                                onChange={formikVehicle.handleChange}
                                required
                              >
                                {loadingTypeLoad ? (
                                  <CircularProgress
                                    variant="indeterminate"
                                    size={25}
                                  />
                                ) : errorTypeLoad ? (
                                  <p>Error</p>
                                ) : (
                                  dataTypeLoad?.getTypeLoad?.map(
                                    (item: any) =>
                                      formikVehicle.values.loadType ===
                                        item.id && (
                                        <MenuItem key={item.id} value={item.id}>
                                          {item.loadUnit}
                                        </MenuItem>
                                      )
                                  )
                                )}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  </div>
                </Grow>
              )}

              {stepFormVehicle === 1 && (
                <Grow in timeout={800}>
                  <div>
                    <Grid spacing={2}>
                      <Box marginBottom={3} marginTop={1}>
                        <MainTitle fontSize="h6">
                          Datos de Generales del Propietario
                          <span className={classes.fontInfoStep}>
                            (Paso {stepFormVehicle + 1} de 2)
                          </span>
                        </MainTitle>
                      </Box>

                      <Box mb={2.5}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Primer Nombre"
                              variant="outlined"
                              size="small"
                              name="firstNameOwner"
                              value={formikVehicle.values.firstNameOwner}
                              onChange={formikVehicle.handleChange}
                              error={
                                formikVehicle.touched.firstNameOwner &&
                                Boolean(formikVehicle.errors.firstNameOwner)
                              }
                              helperText={
                                formikVehicle.touched.firstNameOwner &&
                                formikVehicle.errors.firstNameOwner
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Segundo Nombre"
                              variant="outlined"
                              size="small"
                              name="secondNameOwner"
                              value={formikVehicle.values.secondNameOwner}
                              onChange={formikVehicle.handleChange}
                              error={
                                formikVehicle.touched.secondNameOwner &&
                                Boolean(formikVehicle.errors.secondNameOwner)
                              }
                              helperText={
                                formikVehicle.touched.secondNameOwner &&
                                formikVehicle.errors.secondNameOwner
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mb={2.5}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Primer Apellido"
                              variant="outlined"
                              size="small"
                              name="surnameOwner"
                              value={formikVehicle.values.surnameOwner}
                              onChange={formikVehicle.handleChange}
                              error={
                                formikVehicle.touched.surnameOwner &&
                                Boolean(formikVehicle.errors.surnameOwner)
                              }
                              helperText={
                                formikVehicle.touched.surnameOwner &&
                                formikVehicle.errors.surnameOwner
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Segundo Apellido"
                              variant="outlined"
                              size="small"
                              name="secondSurnameOwner"
                              value={formikVehicle.values.secondSurnameOwner}
                              onChange={formikVehicle.handleChange}
                              error={
                                formikVehicle.touched.secondSurnameOwner &&
                                Boolean(formikVehicle.errors.secondSurnameOwner)
                              }
                              helperText={
                                formikVehicle.touched.secondSurnameOwner &&
                                formikVehicle.errors.secondSurnameOwner
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mb={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <DocumentTypes
                              name="documentType"
                              entity={1}
                              value={formikVehicle.values.documentType}
                              onChange={formikVehicle.handleChange}
                              errors={formikVehicle.errors.documentType}
                              helperText={formikVehicle.errors.documentType}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Documneto"
                              variant="outlined"
                              size="small"
                              name="documentOwner"
                              value={formikVehicle.values.documentOwner}
                              onChange={formikVehicle.handleChange}
                              error={
                                formikVehicle.touched.documentOwner &&
                                Boolean(formikVehicle.errors.documentOwner)
                              }
                              helperText={
                                formikVehicle.touched.documentOwner &&
                                formikVehicle.errors.documentOwner
                              }
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mb={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <PhoneNumber
                              type="mobile"
                              names={{
                                indicative: 'indicativeMobile',
                                phone: 'mobile',
                              }}
                              values={{
                                indicative: Number(
                                  formikVehicle.values.indicativeMobile
                                ),
                                phone: (formikVehicle.values.mobile =
                                  unmaskPhone(formikVehicle.values.mobile)),
                              }}
                              onChange={formikVehicle.handleChange}
                              errors={{
                                indicative:
                                  !!formikVehicle.errors.indicativeMobile &&
                                  !!formikVehicle.touched.indicativeMobile,
                                phone: formikVehicle.errors.mobile as string,
                              }}
                              helperTexts={{
                                indicative: formikVehicle.errors
                                  .indicativeMobile as string,
                                phone: formikVehicle.errors.mobile as string,
                              }}
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mb={2}>
                        <Grid container>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Correo Electrónico"
                              variant="outlined"
                              size="small"
                              type="email"
                              name="email"
                              value={formikVehicle.values.email}
                              onChange={formikVehicle.handleChange}
                              error={
                                formikVehicle.touched.email &&
                                Boolean(formikVehicle.errors.email)
                              }
                              helperText={
                                formikVehicle.touched.email &&
                                formikVehicle.errors.email
                              }
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </div>
                </Grow>
              )}

              <Grid
                item
                container
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={stepFormVehicle !== 1}
                    endIcon={
                      loadingVehicle && (
                        <CircularProgress
                          className={classes.loadingColor}
                          thickness={5}
                          size={20}
                        />
                      )
                    }
                  >
                    {loadingVehicle ? 'Guardando...' : 'Guardar'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={1}
            >
              <Fab
                color="primary"
                type="submit"
                className={clsx({
                  [classes.btnStep]: true,
                  [classes.hiddenButton]: stepFormVehicle === 1,
                })}
              >
                <Box
                  component="i"
                  fontSize="20px"
                  fontWeight="bold"
                  className="icon-arrow-right"
                />
              </Fab>
            </Grid>
          </Grid>
        </form>
      </ContentCard>
      <DialogConfirmData
        open={openNotificationConfirm.open}
        icon={openNotificationConfirm.icon}
        title={openNotificationConfirm.title}
        message={openNotificationConfirm.message}
        handleAccept={handleNConfirm}
        handleClose={handleCloseNConfirm}
      />
    </>
  );
};
export default RegisterVehicleXDriver;
